import { Configuration } from "@azure/msal-browser";

const CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
const TENANT = process.env.REACT_APP_AZURE_TENANT;
const FRONT_HOST = process.env.REACT_APP_FRONT_HOST;
const SCOPE = process.env.REACT_APP_AZURE_SCOPE;

export const msalConfig: Configuration = {
  auth: {
    clientId: `${CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${TENANT}`,
    redirectUri: FRONT_HOST,
    postLogoutRedirectUri: FRONT_HOST,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [`api://${CLIENT_ID}/${SCOPE}`],
  redirectUri: FRONT_HOST,
};
