import { TableCell, Typography } from "@material-ui/core";
import { CellProps } from "interfaces";

import React from "react";

export const CustomTableCell = <T extends unknown>({
  className,
  value,
  align,
  dataKey,
}: Pick<CellProps<T>, "value" | "align" | "dataKey" | "className">) => {
  return (
    <TableCell
      key={`table-${dataKey}-cell`}
      className={className}
      component="td"
      align={align}
      scope="row"
    >
      {typeof value === "string" || typeof value === "number" ? (
        <Typography variant="body1">{value}</Typography>
      ) : (
        value
      )}
    </TableCell>
  );
};
