import {
  Typography,
  Link,
  makeStyles,
  useTheme,
  Button,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { FallbackProps } from "react-error-boundary";
import { ErrorDarkIcon, ErrorLightIcon } from "./icons";

const FRONT_HOST = process.env.REACT_APP_FRONT_HOST;

export const ErrorScreen: React.FC<FallbackProps> = () => {
  const classes = useStyles();
  const theme = useTheme();

  const Icon = useMemo(() => {
    return theme.palette.type === "dark" ? ErrorDarkIcon : ErrorLightIcon;
  }, [theme.palette.type]);

  return (
    <div className={classes.layout}>
      <Icon className={classes.icon} />
      <Typography className={classes.title} variant="h4">
        <b>Lo sentimos, algo salió mal...</b>
      </Typography>
      <Typography variant="h5" className={classes.contactText}>
        Contacta a tu equipo de soporte interno ó a <b>Service Desk</b> al +188
        800 201 253 / +56 44 208 1228
      </Typography>
      <Link
        className={classes.link}
        variant="h5"
        href="https://bhp.service-now.com/sp?id=sc_cat_item&sys_id=4b63e79adb6a43004b46fc1dbf9619ec"
        target={"_blank"}
      >
        Crear un ticket en Service Now
      </Link>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        href={FRONT_HOST}
      >
        <Typography variant="h6">Volver al inicio</Typography>
      </Button>
    </div>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  layout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    justifyContent: "center",
    backgroundColor:
      palette.type === "light"
        ? palette.background.paper
        : palette.background.default,
  },
  icon: {
    width: 420,
    height: 420,
  },
  title: {
    padding: 15,
  },
  contactText: {
    color:
      palette.type === "light" ? palette.text.secondary : palette.common.white,
  },
  link: {
    color: "#0099CC",
  },
  button: {
    margin: 40,
    minHeight: 36,
    color: palette.common.white,
    marginBottom: 70,
  },
}));
