import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const CircleChangeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 41 42"
      height="41px"
      viewBox="0 0 41 42"
      width="42px"
      fill="#none"
      {...props}
    >
      <path
        d="M18 0.416626C8.34 0.416626 0.5 8.06996 0.5 17.5C0.5 26.93 8.34 34.5833 18 34.5833C27.66 34.5833 35.5 26.93 35.5 17.5C35.5 8.06996 27.66 0.416626 18 0.416626ZM18 31.1666C10.2825 31.1666 4 25.0337 4 17.5C4 9.96621 10.2825 3.83329 18 3.83329C25.7175 3.83329 32 9.96621 32 17.5C32 25.0337 25.7175 31.1666 18 31.1666ZM25.2975 22.215L23.3725 20.3358C24.615 18.0637 24.3 15.1937 22.34 13.2804C21.1325 12.1016 19.575 11.5208 18 11.5208C17.9475 11.5208 17.895 11.5379 17.8425 11.5379L19.75 13.4L17.895 15.2108L12.9425 10.3762L17.895 5.54163L19.75 7.35246L18.07 8.99246C20.2925 9.00954 22.4975 9.81246 24.195 11.4525C27.17 14.3737 27.5375 18.9008 25.2975 22.215ZM23.0575 24.6237L18.105 29.4583L16.25 27.6475L17.9125 26.0245C15.7075 26.0075 13.5025 25.1704 11.8225 23.5304C8.83 20.6091 8.4625 16.0991 10.7025 12.785L12.6275 14.6641C11.385 16.9362 11.7 19.8062 13.66 21.7195C14.885 22.9154 16.5125 23.4962 18.14 23.445L16.25 21.6L18.105 19.7891L23.0575 24.6237Z"
        fill="#EE8105"
      />
    </SvgIcon>
  );
};
