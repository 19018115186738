import { CrewEnum, CrewHash, PracticeEnum } from "interfaces";
import { OperatorPracticeCheckBoxHash } from "reducers";

export const getPractices = (
  practicesHash: OperatorPracticeCheckBoxHash
): PracticeEnum[] => {
  const { ALL, ...otherPractices } = practicesHash;
  return ALL
    ? Object.values(PracticeEnum)
    : (Object.keys(otherPractices) as PracticeEnum[]).filter(
        (key) => otherPractices[key]
      );
};

export const getCrews = (crewsHash: CrewHash): CrewEnum[] =>
  Object.entries(crewsHash)
    .filter(([, val]) => val)
    .map(([key]) => key as CrewEnum);
