import React from "react";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { SliceTooltipProps } from "@nivo/line";
import { EnhancedPoint, MetricGroupType, MetricsPoint } from "interfaces";
import { LineGraphPointTooltipContent } from "./LineGraphPointTooltipContent";
import { LineGraphChangesTooltipContent } from "./LineGraphChangesTooltipContent";
import { formatLocalizedDate } from "utils";

interface Props extends SliceTooltipProps {
  measurement: string | null;
  groupType: MetricGroupType;
}

export const LineGraphTooltip: React.FC<Props> = ({
  slice,
  measurement,
  groupType,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.tooltip}>
      {(slice.points as EnhancedPoint[]).map((point, i) => (
        <div key={point.id} className={classes.tooltipInfoContainer}>
          {point.serieId === "CHANGES" ? (
            <div className={classes.changesDate}>
              <Typography variant="subtitle2">
                {formatLocalizedDate(point.data.x as Date, "HH:mm 'hrs.'")}
              </Typography>
            </div>
          ) : (
            <LineGraphPointTooltipContent
              point={point}
              measurement={measurement}
            />
          )}
          {point.data.changeEvents.length > 0 && (
            <>
              <Typography className={classes.title} variant="subtitle2">
                <b>Gestiones</b>
              </Typography>
              <LineGraphChangesTooltipContent
                point={point as MetricsPoint}
                groupType={groupType}
              />
            </>
          )}
        </div>
      ))}
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  tooltip: {
    width: "max-content",
    padding: 10,
  },
  row: {
    display: "flex",
  },
  tooltipIndex: {
    marginRight: 5,
  },
  tooltipInfoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginTop: 15,
  },
  changesDate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
