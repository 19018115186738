import React, { createContext } from "react";
import { ErrorMsg, KpiData } from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { DataContextInterface, initialDataContextState } from "interfaces";
import { STANDARD_POLLING } from "App";
import { ContextProviderProps } from "contexts";
import { AppRoute } from "utils";

export const TruckAssignmentKpisContext = createContext<
  DataContextInterface<KpiData[]>
>(initialDataContextState);

export const TruckAssignmentKpisProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { refetch, ...other } = useData<KpiData[]>(
    {
      config: "/truck-assignment/kpi",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_KPIS
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetch(), [AppRoute.TA_BALANCE_SCREEN]);

  return (
    <TruckAssignmentKpisContext.Provider value={{ refetch, ...other }}>
      {children}
    </TruckAssignmentKpisContext.Provider>
  );
};
