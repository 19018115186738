import React from "react";
import { Channel, ChannelBox } from "planby";
import {
  Box,
  Grid,
  makeStyles,
  alpha,
  Typography,
  Tooltip,
} from "@material-ui/core";

interface ChannelItemProps {
  channel: Channel;
  getGroup: (value: number) => { operator: string; caex: string | null }[];
  Modal?: React.JSX.Element;
  openModal?: ConfirmationDialogProps;
  setOpenModal?: (value: ConfirmationDialogProps) => void;
}

interface ConfirmationDialogProps {
  open: boolean;
  data?: { operator: string; caex: string | null }[];
  group?: number;
  relayId?: number;
}

export const ChannelItem = ({
  channel,
  getGroup,
  Modal,
  setOpenModal,
}: ChannelItemProps) => {
  const { position } = channel;
  const classes = useStyles();

  return (
    <ChannelBox
      {...position}
      style={{ marginBottom: 10, left: 0, width: 150}}
      className={classes.chanelBox}
    >
      <Grid
        style={{ width: 150 }}
        onClick={() =>
          setOpenModal &&
          setOpenModal({
            open: true,
            data: getGroup(channel.group),
            group: channel.group,
            relayId: channel?.relayId,
          })
        }
      >
        <Box
          className={classes.boxContainer}
          style={{
            background: alpha(channel.color, 0.5),
            borderLeft: `10px solid ${channel.color}`,
          }}
        >
          <Tooltip
            title={channel?.title}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            placement="top"
            interactive
            arrow
          >
            <Typography
              variant="body1"
              className={
                channel?.title?.length > 10 && !channel?.title?.includes(" ")
                  ? classes.adjustedTextSize
                  : classes.multiline
              }
            >
              <strong>{ channel?.title?.length > 23 ? channel?.title.substring(0, 23)+"..." : channel?.title}</strong>
            </Typography>
          </Tooltip>
        </Box>
      </Grid>
      {Modal}
    </ChannelBox>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    adjustedTextSize: {
      display: "block",
      width: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    multiline: {
      whiteSpace: "normal",
    },
    boxContainer: {
      cursor: "pointer",
      borderRadius: 4,
      fontWeight: 700,
      minHeight: 60,
      width: 140,
      padding: "10px 5px 6px 8px",
      fontSize: 16,
      color:
        palette.type === "dark"
          ? palette.text.primary
          : palette.primary.contrastText,
    },
    chanelBox: {
      background: palette.background.paper,
    },
    tooltip: {
      background: palette.secondary.main,
      borderRadius: "4px",
      boxShadow:
        "0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
      padding: "6px",
      fontSize: 16,
    },
    arrow: {
      color: palette.secondary.main,
    },
    icon: {
      fill: palette.error.main,
      marginRight: 8,
    },
    modalDialogTitle: {
      color: palette.error.main,
    },
  };
});
