import React from "react";
import { makeStyles, Divider, Theme } from "@material-ui/core";
import { MineMovements, OtherClient } from "interfaces";
import { SicKpiDetail } from "./SicKpiDetail";

interface Props {
  movements: MineMovements[];
}

export const SicKpis: React.FC<Props> = ({ movements }) => {
  const classes = useStyles();

  return (
    <div className={classes.kpiContainer}>
      {movements
        .filter((mov) => mov.origin !== OtherClient.PENDING)
        .map((movement, i) => (
          <React.Fragment key={i}>
            <SicKpiDetail {...movement} />
            <Divider
              orientation="vertical"
              variant="middle"
              className={classes.divider}
            />
          </React.Fragment>
        ))}
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return {
    kpiInfoRoot: {
      display: "flex",
      alignItems: "center",
    },
    divider: {
      height: 50,
      width: 1,
      alignSelf: "auto",
      "&:last-child": {
        width: 0,
      },
    },
    projectionValue: {
      fontWeight: 500,
    },
    kpiContainer: {
      backgroundColor: theme.palette.background.default,
      width: "100%",
      height: 80,
      borderRadius: "4px",
      display: "flex",
      paddingTop: 15,
      paddingLeft: 32,
    },
  };
});
