import React, { createContext } from "react";
import { ErrorMsg } from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { DataContextInterface, initialDataContextState } from "interfaces";
import { STANDARD_POLLING } from "App";
import { ContextProviderProps } from "contexts";
import { AppRoute } from "utils";

interface Filters {
  excavs: string[],
  phases: string[],
}

export const TruckAssigmentCycleTimeContext = createContext<
  DataContextInterface<Filters>
>(initialDataContextState);

export const TruckAssigmentCycleTimeProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { refetch, ...other } = useData<Filters>(
    {
      config: "/truck-assignment-performance/filters",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_PHASES,
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetch(), [AppRoute.TA_PERFORMANCE_SCREEN]);

  return (
    <TruckAssigmentCycleTimeContext.Provider value={{ refetch, ...other }}>
      {children}
    </TruckAssigmentCycleTimeContext.Provider>
  );
};
