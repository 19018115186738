import { alpha, Theme } from "@material-ui/core";
import { CardAlertType } from "interfaces";

export const getAlertColor = (theme: Theme, alertType?: CardAlertType) =>
  alertType === CardAlertType.ALERT
    ? theme.palette.error.main
    : alertType === CardAlertType.WARNING
    ? theme.palette.warning.main
    : theme.palette.text.primary;

export const getAlertContrastColor = (theme: Theme, alertType: CardAlertType) =>
  alertType === CardAlertType.ALERT
    ? theme.palette.error.contrastText
    : alertType === CardAlertType.WARNING
    ? theme.palette.warning.contrastText
    : theme.palette.text.primary;

export const getAlertPaperColor = (theme: Theme, alertType: CardAlertType) => {
  const alphaValPaper = theme.palette.type === "light" ? 0.1 : 0.3;

  return alertType === CardAlertType.ALERT
    ? alpha(theme.palette.alertPaper.alert, alphaValPaper)
    : alertType === CardAlertType.WARNING
    ? alpha(theme.palette.alertPaper.warning, alphaValPaper)
    : theme.palette.background.paper;
};

export const getAlertCardColor = (theme: Theme, alertType: CardAlertType) => {
  const alphaValCard = theme.palette.type === "light" ? 0.1 : 1;

  return alertType === CardAlertType.ALERT
    ? alpha(theme.palette.alertCard.alert, alphaValCard)
    : alertType === CardAlertType.WARNING
    ? alpha(theme.palette.alertCard.warning, alphaValCard)
    : theme.palette.background.default;
};

export const getAlertTextColor = (theme: Theme, alertType: CardAlertType) =>
  alertType !== CardAlertType.DEFAULT
    ? theme.palette.alertText.primary
    : theme.palette.text.primary;

export const getAlertTimeTextColor = (theme: Theme, alertType: CardAlertType) =>
  alertType === CardAlertType.ALERT
    ? theme.palette.alertText.alert
    : alertType === CardAlertType.WARNING
    ? theme.palette.alertText.warning
    : theme.palette.text.primary;
