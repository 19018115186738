import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppRoute } from "utils";
import { usePreviousValue } from "./usePreviousValue";

const smartRouteComparisson = (
  appRoute: AppRoute,
  pathname: string
): boolean => {
  const pathnameSegments = pathname.split("/");
  const appRouteSegments = appRoute.split("/");

  if (pathnameSegments.length !== appRouteSegments.length) return false;

  return pathnameSegments.every((segment, i) =>
    appRouteSegments[i].includes(":")
      ? !!segment
      : segment === appRouteSegments[i]
  );
};

export const usePathnameChange = (
  action: () => void,
  targetRoutes?: AppRoute[]
) => {
  const { pathname: currentPathname } = useLocation();
  const prevPathname = usePreviousValue(currentPathname);

  useEffect(() => {
    // Do not trigger on first route being mounted
    if (
      prevPathname &&
      prevPathname !== currentPathname &&
      (!targetRoutes ||
        targetRoutes.some((r) => smartRouteComparisson(r, currentPathname)))
    ) {
      action();
    }
  }, [action, currentPathname, prevPathname, targetRoutes]);
};
