import React, { useContext } from "react";
import { Navigate } from "react-router";
import { Module, Can } from "interfaces";
import { UserContext } from "contexts";

interface Props {
  to: Can;
  at: Module;
  children: React.ReactNode;
}

export const ProtectedScreen: React.FC<Props> = ({ to, at, children }) => {
  const { isAllowedTo, loadingUser } = useContext(UserContext);
  // Esto es algo penca. Muestra la página en blanco mientras carga el usuario.
  // Podríamos decir que da lo mismo ya que solo ocurre en la 1ra carga.
  // O bien mostrar el componente mientras carga el usuario
  if (loadingUser) return null;
  return <>{isAllowedTo(to, at) ? children : <Navigate to="/" />}</>;
};
