import React, { useMemo, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { CustomTable } from "components";
import {
  HeaderCellProps,
  moduleTranslation,
  NestedKeyOf,
  AppParam,
  CellProps,
  Can,
  Module,
} from "interfaces";
import { formatLocalizedDate } from "utils";
import { AppParamsTableInput } from "./AppParamsTableInput";
import { AppParamsContext, UserContext } from "contexts";

export const AppParamsTable: React.FC = () => {
  const classes = useStyles();
  const { appParams, loadingAppParams } = useContext(AppParamsContext);
  const { isAllowedTo } = useContext(UserContext);
  const canManageParams = useMemo(
    () => isAllowedTo(Can.WRITE, Module.ADMINISTRATION_PANEL),
    [isAllowedTo]
  );

  const headCells = useMemo<HeaderCellProps<NestedKeyOf<AppParam>>[]>(
    () => [
      {
        dataKey: "module",
        label: "Módulo",
        align: "left",
        sortable: true,
      },
      {
        dataKey: "title",
        label: "Variable",
        align: "left",
        sortable: true,
      },
      {
        dataKey: "value",
        label: "Valor",
        align: "center",
        sortable: false,
      },
      {
        dataKey: "updatedBy",
        label: "Actualizado por",
        align: "left",
        sortable: false,
      },
      {
        dataKey: "updatedAt",
        label: "Actualizado el",
        align: "center",
        sortable: false,
      },
    ],
    []
  );

  const rowDetails = useCallback(
    (datum: AppParam): CellProps<NestedKeyOf<AppParam>>[] => [
      {
        dataKey: "module",
        value: moduleTranslation[datum.module],
        align: "left",
        className: classes.headerS,
      },
      {
        dataKey: "title",
        value: datum.title,
        align: "left",
      },
      {
        dataKey: "value",
        value: <AppParamsTableInput data={datum} disabled={!canManageParams} />,
        align: "center",
        className: classes.headerM,
      },
      {
        dataKey: "updatedBy",
        value: datum.updatedBy,
        align: "left",
        className: classes.headerS,
      },
      {
        dataKey: "updatedAt",
        value: formatLocalizedDate(datum.updatedAt, "dd/MM/yyyy"),
        align: "center",
      },
    ],
    [canManageParams, classes.headerM, classes.headerS]
  );

  return (
    <div className={classes.table}>
      <CustomTable
        tableKey="app-params"
        data={appParams ?? []}
        renderCells={rowDetails}
        headers={headCells}
        loadingData={loadingAppParams}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  headerS: {
    width: 240,
    height: 36,
    paddingTop: 8,
    paddingBottom: 8,
  },
  headerM: {
    width: 300,
    height: 36,
    paddingTop: 8,
    paddingBottom: 8,
  },
  headerL: {
    maxWidth: 852,
    height: 36,
    paddingTop: 8,
    paddingBottom: 8,
  },
  table: {
    display: "flex",
    height: "100%",
  },
}));
