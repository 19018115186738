import React from "react";
import { makeStyles } from "@material-ui/core";
import { ChipSelectorOptions, Chip } from ".";
import clsx from "clsx";
export interface CustomClasses {
  chipGroupRoot: string;
}

type ChipGroupProps = {
  value?: string | number ;
  options: ChipSelectorOptions[];
  onChange: (value: string | number) => void;
  classes?: CustomClasses;
};

export const ChipGroup: React.FC<ChipGroupProps> = ({
  value,
  options,
  onChange,
  classes: propClasses,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(propClasses?.chipGroupRoot, classes.chipGroupRoot)}>
      {options.map((opt, i) => (
        <Chip key={i} value={value} option={opt} onChange={onChange} />
      ))}
    </div>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    chipGroupRoot: {
      display: "flex",
      gap: 10,
    },
  };
});
