import React from "react";
import {
  TimelineWrapper,
  TimelineBox,
  TimelineTime,
  TimelineDivider,
  TimelineDividers,
  useTimeline,
} from "planby";
import { useTheme } from "@material-ui/core";

interface TimelineProps {
  isBaseTimeFormat: boolean;
  isSidebar: boolean;
  dayWidth: number;
  hourWidth: number;
  numberOfHoursInDay: number;
  offsetStartHoursRange: number;
  sidebarWidth: number;
}

export const Timeline = ({
  isBaseTimeFormat,
  isSidebar,
  dayWidth,
  hourWidth,
  numberOfHoursInDay,
  offsetStartHoursRange,
  sidebarWidth,
}: TimelineProps) => {
  const { time, dividers, formatTime } = useTimeline(
    numberOfHoursInDay,
    isBaseTimeFormat
  );

  const theme = useTheme();

  const changeH = (value: number) => {
    switch (value) {
      case 25:
        return "1:00am";
      case 26:
        return "2:00am";
      case 27:
        return "3:00am";
      case 28:
        return "4:00am";
      case 29:
        return "5:00am";
      case 30:
        return "6:00am";
      case 31:
        return "7:00am";
      case 32:
        return "8:00am";

      default:
        return formatTime(value).toLowerCase();
    }
  };

  const renderTime = (index: number) => {
    // formatTime( index + offsetStartHoursRange).toLowerCase()
    return (
      <TimelineBox
        key={index}
        width={hourWidth}
        style={{
          left: 100,
          background:
            theme.palette.type === "light"
              ? theme.palette.grey[50]
              : theme.palette.background.paper,
        }}
      >
        <TimelineTime>{changeH(index + offsetStartHoursRange)}</TimelineTime>
        <TimelineDividers>{renderDividers()}</TimelineDividers>
      </TimelineBox>
    );
  };

  const renderDividers = () =>
    dividers.map((_, index) => (
      <TimelineDivider key={index} width={hourWidth} />
    ));

  return (
    <TimelineWrapper
      dayWidth={dayWidth}
      sidebarWidth={sidebarWidth}
      isSidebar={isSidebar}
      style={{ zIndex: 100 }}
    >
      {time.map((_, index) => renderTime(index))}
    </TimelineWrapper>
  );
};
