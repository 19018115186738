import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { TruckProjectionType, CardAlertType } from "interfaces";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import { getAlertTextColor } from "styles";

interface TruckProjectionStripProp {
  type: TruckProjectionType | null;
  alertType: CardAlertType;
}

export const CornerLabel: React.FC<TruckProjectionStripProp> = ({
  type,
  alertType,
}) => {
  const classes = useStyles({ alertType });
  if (!type) return null;

  return (
    <div className={classes.diagonalStrip}>
      <div className={classes.projectionTypeLabel}>
        {type === TruckProjectionType.MANUAL ? (
          <PersonRoundedIcon className={classes.projectionIcon} />
        ) : type === TruckProjectionType.ASSETS_HEALTH ? (
          <Typography className={classes.projectionText} variant="overline">
            <strong>SA</strong>
          </Typography>
        ) : null}
      </div>
    </div>
  );
};

interface StyleProps {
  alertType: CardAlertType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  projectionTypeLabel: {
    transform: "rotate(-45deg) translate(3px, 21px)",
    backgroundColor: ({ alertType }) => getAlertTextColor(theme, alertType),
    width: 40,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    paddingBottom: 12,
    paddingRight: 3,
  },
  diagonalStrip: {
    color: ({ alertType }) =>
      theme.palette.getContrastText(getAlertTextColor(theme, alertType)),
    right: 0,
    bottom: 0,
    display: "flex",
    position: "absolute",
    overflow: "hidden",
    borderRadius: 4,
  },
  projectionIcon: {
    transform: "rotate(45deg)",
    marginTop: 3,
    marginBottom: 10,
    height: 14,
    width: 14,
  },
  projectionText: {
    transform: "rotate(45deg)",
  },
}));
