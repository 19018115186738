import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { AttributeChange, ToolChangeEvent } from "interfaces";
import { StopRounded } from "@material-ui/icons";
import { groupBy } from "lodash";
import { capitalize } from "utils/parsing";

interface Props {
  serieColor: string;
  x: Date;
  events: ToolChangeEvent[];
}

export const ToolChangesTooltipContent: React.FC<Props> = ({
  serieColor,
  events,
  x,
}) => {
  const classes = useStyles();
  const groupedEvents = groupBy(events, "user");

  return (
    <>
      {Object.entries<ToolChangeEvent[]>(groupedEvents).map(
        ([user, events]) => (
          <div key={user}>
            <div className={classes.row}>
              <StopRounded className={classes.squareStyle} />
              <Typography variant="subtitle2" className={classes.tooltipTitle}>
                {`Saturación | ${capitalize(user)}`}
              </Typography>
            </div>
            <div className={classes.eventsDescription}>
              {events.map((e, index) => (
                <Typography key={index} variant="body2">
                  {getTooltipTexts(e)}
                </Typography>
              ))}
            </div>
          </div>
        )
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    marginTop: 10,
  },
  tooltipTitle: {
    marginTop: 2,
  },
  squareStyle: {
    width: 25,
    height: 25,
    color:
      theme.palette.type === "light"
        ? `${theme.palette.secondary.main} !important`
        : `${theme.palette.common.white} !important`,
  },
  eventsDescription: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    whiteSpace: "pre-line",
    marginLeft: 25,
    marginBottom: 5,
  },
}));

const getTooltipTexts = (data: ToolChangeEvent): string => {
  const { attributeChange, prevPriority, priority, inclusion } = data;

  const inclusionChange = `Pala ${
    inclusion ? "" : "no"
  } incluída en asignación`;

  const sufix = priority > 1 ? "s" : "";
  const priorityChange = prevPriority
    ? `Prioridad de ${prevPriority} a ${priority} estrella${sufix}`
    : `Prioridad en ${priority} estrella${sufix}`;

  if (attributeChange === AttributeChange.INCLUSION) {
    return inclusionChange;
  }

  if (attributeChange === AttributeChange.PRIORITY) {
    return priorityChange;
  }

  return `${inclusionChange} \n ${priorityChange}`;
};
