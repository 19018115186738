import React, { createContext, useCallback, useMemo, useState } from "react";
import { uniq } from "lodash";
import { Loader } from "components";
import { ContextProviderProps } from "contexts";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core";
import {
  CheckCircleOutlineOutlined,
  ErrorOutline,
  InfoOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";

interface FeedbackContextInterface {
  loading: boolean;
  toggleLoader: (loading: boolean, name: string) => void;
}

export const FeedbackContext = createContext({} as FeedbackContextInterface);

export const FeedbackProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [loaders, setLoaders] = useState<string[]>([]);
  const classes = useStyles();

  const toggleLoader = useCallback((loading: Boolean, name: string) => {
    if (loading) {
      setLoaders((prev) => uniq([...prev, name]));
    } else {
      setLoaders((prev) => prev.filter((key) => key !== name));
    }
  }, []);

  const contextState = useMemo(
    () => ({
      loading: loaders.length > 0,
      toggleLoader,
    }),
    [loaders.length, toggleLoader]
  );

  return (
    <FeedbackContext.Provider value={contextState}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={4000}
        preventDuplicate
        classes={{
          variantError: classes.error,
          variantWarning: classes.warning,
          variantSuccess: classes.success,
          variantInfo: classes.info,
        }}
        iconVariant={{
          error: <ErrorOutline className={classes.icon} />,
          warning: <ReportProblemOutlined className={classes.icon} />,
          success: <CheckCircleOutlineOutlined className={classes.icon} />,
          info: <InfoOutlined className={classes.icon} />,
        }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        {children}
        <Loader open={loaders.length > 0} />
      </SnackbarProvider>
    </FeedbackContext.Provider>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    fontSize: "20px",
    marginInlineEnd: "8px",
  },
  error: {
    backgroundColor: palette.error.main,
  },
  warning: {
    backgroundColor: palette.warning.main,
  },
  success: {
    backgroundColor: palette.success.main,
  },
  info: {
    backgroundColor: palette.info.main,
  },
}));
