import { Avatar, makeStyles, Typography } from "@material-ui/core";
import { TruckSummary } from "interfaces";
import React from "react";
import { YellowTruckIcon } from "./icons";
import clsx from "clsx";

interface TruckAvatarClasses {
  avatar?: string;
  icon?: string;
  ppmAvatar?: string;
  ppmAvatarTitle?: string;
  ppmAvatarSubTitle?: string;
  caText?: string;
}

interface TruckAvatarProps {
  truck: TruckSummary;
  classes?: TruckAvatarClasses;
}

export const TruckAvatar: React.FC<TruckAvatarProps> = ({
  truck,
  classes: propClasses,
}) => {
  const classes = useStyles();

  if (truck.isCa) {
    return (
      <Avatar className={clsx(classes.avatar, propClasses?.avatar)}>
        <Typography variant="body1" className={clsx(propClasses?.caText)}>
          <strong>EC</strong>
        </Typography>
      </Avatar>
    );
  }

  if (truck.hasPrePm) {
    return (
      <Avatar
        className={clsx(
          [classes.avatar, propClasses?.avatar],
          [classes.ppmAvatar, propClasses?.ppmAvatar]
        )}
      >
        <Typography
          className={clsx(classes.ppmAvatarTitle, propClasses?.ppmAvatarTitle)}
        >
          <strong>PRE</strong>
        </Typography>
        <Typography
          className={clsx(
            classes.ppmAvatarSubTitle,
            propClasses?.ppmAvatarSubTitle
          )}
        >
          PM
        </Typography>
      </Avatar>
    );
  }

  return <YellowTruckIcon className={clsx(classes.icon, propClasses?.icon)} />;
};

const useStyles = makeStyles(({ palette }) => ({
  avatar: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 8,
    marginRight: 10,
    width: 30,
    height: 30,
    color: palette.getContrastText(palette.avatar.background),
    backgroundColor: palette.avatar.background,
  },
  ppmAvatarTitle: {
    fontSize: 9,
    lineHeight: "11px",
  },
  ppmAvatarSubTitle: {
    fontSize: 12,
    lineHeight: "13px",
    fontWeight: "lighter",
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: 8,
  },
  ppmAvatar: {
    width: 32,
    height: 32,
  },
}));
