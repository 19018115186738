import { Grid, Typography, makeStyles } from '@material-ui/core';
import { CardComponent } from 'components/CardComponent/CardComponent';
import { KpisCardComponent } from 'components/CardComponent/KpisCardComponent';
import { WithoutData } from 'components/WithoutData';
import { GpsIcon } from 'components/icons/GpsIcon';
import { TruckPerformancePhasesContext } from 'contexts/TruckPerformancePhasesContext';
import React, { useContext, useEffect } from 'react';
import { useMinutesCounter } from "../../../hooks";
import { InfoOutlined } from '@material-ui/icons';
import { Tooltip as TooltipComponent } from "components";

interface Props {
  dateReport: (value: string | null) => void;
  setFirstLoading: (status: boolean) => void;
  setLastUpdate: (status: number | null) => void;
}

export const TruckPerformanceKpi: React.FC<Props> = ({
  dateReport,
  setFirstLoading,
  setLastUpdate
}) => {
  const { data, firstLoading } = useContext(TruckPerformancePhasesContext);
  const classes = useStyles();

  const [lastUpdate] = useMinutesCounter(
    data.lastUpdate ? data.lastUpdate : null
  );

  useEffect(() => {
    setFirstLoading(firstLoading)
  }, [firstLoading, setFirstLoading])

  useEffect(() => {
    dateReport(data.currentShift ?? null);
    setLastUpdate(lastUpdate);
  }, [data, dateReport, lastUpdate, setLastUpdate])

  const infoTooltip = {
    title: "Los movimientos(kt) y los tiempos de ciclo(min) son obtenidos de DUMPS"
  }
  
  return (
    <KpisCardComponent loading={firstLoading}>
      <Grid container className={classes.mainContainer} >
        <Grid item xs={12}>
          <Grid container xs={4} justifyContent='flex-start' alignItems="center">
            <Grid item xs={4} className={classes.title}>
              <Typography variant='h6'>
                  {"RESUMEN POR FASE"}
              </Typography>
            </Grid>
              <TooltipComponent
                  title={
                    <>
                      <Typography
                        variant="subtitle1"
                        style={{ paddingBottom: 20, fontWeight: 700 }}
                      >
                        {infoTooltip.title}
                      </Typography>
                    </>
                  }
                  arrow
                  placement="right"
              >
                <InfoOutlined className={classes.infoIconTooltip} />
              </TooltipComponent>
          </Grid>
        </Grid>
        {data?.data?.length 
        ? <CardComponent 
            cardData={data}
            icon={<GpsIcon className={classes.icon} />}
          />
        : <WithoutData title='No se encontraron datos'/>
        }
      </Grid>
    </KpisCardComponent>
  )
}

const useStyles = makeStyles(({ palette }) => {
  return {
    icon: {
      height: "40px",
      width: "40px"
    },
    mainContainer: {
      background: palette.type === "light"
        ? palette.background.default
        : palette.background.paper,
      boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
      borderRadius: "4px",
      marginBottom: "25px",
      padding: "8px 14px 14px 14px"
    },
    title: {
      color: palette.type === "light"
        ? palette.text.secondary
        : palette.text.primary
    },
    infoIconTooltip: {
      lineHeight: "24px",
      marginRight: 10,
      width: 20,
      height: 20,
    },
  }
})    
