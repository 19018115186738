import { Grid, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const SkeletonMapsComponent = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12}>
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "10px" }}>
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "10px" }}>
          <Skeleton variant="rect" width="100%">
            <div style={{ paddingTop: "57%" }} />
          </Skeleton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    gridContainer: {
      background: palette.background.default,
      borderRadius: "4px",
      padding: "8px",
    },
  };
});
