import {
  AuthenticationResult,
  AccountInfo,
  IPublicClientApplication,
  AuthError,
} from "@azure/msal-browser";
import { IdTokenClaims } from "@azure/msal-common";
import { loginRequest } from "services";

export const acquireToken = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null
): Promise<AuthenticationResult | null> => {
  if (!instance || !account) return null;
  const tokenRequest = {
    account: account ?? undefined,
    scopes: loginRequest["scopes"],
  };
  try {
    const tokenResponse = await instance.acquireTokenSilent(tokenRequest);
    return tokenResponse;
  } catch (e) {
    if (
      e instanceof AuthError &&
      ["consent_required", "interaction_required", "login_required"].includes(
        e.errorCode
      )
    ) {
      const tokenResponse = await instance.acquireTokenPopup(tokenRequest);
      return tokenResponse;
    }
  }
  return null;
};

export const logout = (
  instance: IPublicClientApplication,
  account: AccountInfo | null
) => {
  instance.logoutRedirect({
    logoutHint: (account?.idTokenClaims as IdTokenClaims)?.login_hint,
  });
};
