import React, { useContext, useEffect } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { BubbleChartRounded } from "@material-ui/icons";
import { InfoAccordion } from "./InfoAccordion";
import { FeedbackContext, InfoSourceContext } from "contexts";
import { Header, ScreenContainer } from "components";

export const SourceInfoScreen: React.FC = () => {
  const classes = useStyles();
  const { sourceData, firstLoadingSourceInfo } = useContext(InfoSourceContext);
  const { toggleLoader } = useContext(FeedbackContext);

  useEffect(() => {
    toggleLoader(
      firstLoadingSourceInfo,
      "[SourceInfoStatus] Loading database status"
    );
  }, [firstLoadingSourceInfo, toggleLoader]);

  return (
    <>
      <Header />
      <div className={classes.background}>
        <ScreenContainer className={classes.screenRoot} maxWidth="md">
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            className={classes.sourceGrid}
          >
            <Grid item>
              <BubbleChartRounded className={classes.bubbleIcon} />
            </Grid>
            <Grid item>
              <Typography variant="h5">FUENTES DE DATOS</Typography>
            </Grid>
          </Grid>
          {sourceData && <InfoAccordion data={sourceData} />}
        </ScreenContainer>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  screenRoot: {
    // width: "100%",
  },
  bubbleIcon: { marginRight: 5 },
  sourceGrid: { paddingTop: 30, paddingBottom: 30 },
}));
