import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ChipGroup, Header, ScreenContainer } from "components";
import { TruckPerformancetKpis } from "components/kpi/TruckPerformanceKpis";
import { Routes } from "./Routes";
import { Caex } from "./Caex";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { SunIcon } from "components/icons/SunIcon";
import { MoonIcon } from "components/icons/MoonIcon";
import { MoonSunIcon } from "components/icons/MoonSunIcon";
import {
  FiltersVelocity,
  TruckRouteState,
  TruckVelocityActionType,
  truckVelocityReducer,
} from "reducers/truck-velocity";
import { RoutesSummary } from "./RoutesSummary";
import { DetailsCard, RoutesData } from "interfaces/velocity.interface";
import { KpiCard } from "components/CardComponent/KpiCard";
import { ErrorMsg, KpiTypePerformanceTranslation } from "interfaces";
import { TruckAssignmentVelocityContext } from "contexts/TruckAssignmentVelocityContext";
import { TruckAssignmentFiltersVelContext } from "contexts/TruckAssignmentFiltersVelContext";
import { CaexSearch } from "./CaexSearch";
import { useData, useMinutesCounter } from "hooks";
import { DATA_REFETCH_MINUTES } from "App";
import { TruckAssigmentStatusVelocityContext } from "contexts/TruckAssigmentStatusVelocityContext";
import { NoDataPerformanceScreen } from "components/NoDataPerformanceScreen";
import { CardsContainer } from "./CardsContainer";

interface FilterPeriod {
  id: string;
  name: string;
}
export const TruckVelocityScreen = () => {
  const classes = useStyles();
  const [routeFilter, setRouteFilter] = useState<string>("");
  const [shiftFilter, setShiftFilter] = useState<string>(
    FiltersVelocity.LAST_3H
  );
  const [selectedCaex, setSelectedCaex] = useState<string | null>(null);

  const {
    data: dataSummary,
    state: stateSummary,
    dispatch: dispatchSummary,
    firstLoading: summaryLoading
  } = useContext(TruckAssignmentVelocityContext);

  const { data: filters, firstLoading: loadingFilters } = useContext(
    TruckAssignmentFiltersVelContext
  );

  const { data: dataStatus, firstLoading: loadingStatus } = useContext(
    TruckAssigmentStatusVelocityContext
  );

  const [lastUpdate] = useMinutesCounter(
    dataSummary.lastUpdate ? dataSummary.lastUpdate : null
  );

  const PeriodType: FilterPeriod[] = [
    { id: FiltersVelocity.LAST_48H, name: "Últimas 48 horas" },
    { id: FiltersVelocity.PREV_SHIFT, name: "Turno anterior" },
  ];

  const ShiftType: FilterPeriod[] = [
    { id: FiltersVelocity.LAST_3H, name: "Últimas 3 horas" },
    { id: FiltersVelocity.CURR_WORKDAY, name: "Jornada actual" },
  ];

  const periodOptions = Object.values(PeriodType).map((value) => ({
    id: value.id,
    name: value.name,
  }));

  const shiftOptions = Object.values(ShiftType).map((value) => ({
    id: value.id,
    name: value.name,
  }));

  const initialRouteWithSummaryState: TruckRouteState = {
    route: "",
    subPeriod: FiltersVelocity.LAST_3H,
  };

  const [stateDetails, dispatchRoute] = useReducer(
    truckVelocityReducer,
    initialRouteWithSummaryState
  );

  const params = useMemo<TruckRouteState>(
    () => ({
      period: stateSummary.period,
      subPeriod: stateDetails.subPeriod,
      workday: stateSummary.workday,
      route: stateDetails.route,
    }),
    [stateDetails, stateSummary]
  );

  const initialState: RoutesData = {
    graph: [],
    congestion: { full: [], empty: [] },
    cards: [],
    caexFast: [],
    caexSlow: [],
    caexRoute: [],
  };

  const { data: graphSection, firstLoading: loadingCAEX, refetching: refetchingCaex} = useData<RoutesData>(
    {
      config: {
        url: "/truck-assignment-velocity/routes",
        method: "GET",
        params: params,
      },
      options: {
        useCache: false,
      },
      polling: {
        minutes: DATA_REFETCH_MINUTES,
        silent: true,
      },
    },
    ErrorMsg.GENERIC_GET,
    initialState
  );

  const loadingSectionRoutes = useMemo(() => {
    return loadingCAEX || refetchingCaex || (loadingFilters && routeFilter === "");
  }, [loadingCAEX, loadingFilters, routeFilter, refetchingCaex]);

  //change period filter
  const onChipChange = useCallback(
    (value: string | number) => {
      dispatchSummary({
        type: TruckVelocityActionType.PERIOD_CHANGE,
        period: value.toString(),
      });
      setSelectedCaex(null);
    },
    [dispatchSummary]
  );

  useEffect(() => {
    if (filters?.routes?.length) {
      if (routeFilter === "") {
        setRouteFilter(filters.routes[0]);
        dispatchRoute({
          type: TruckVelocityActionType.ROUTE_CHANGE,
          route: filters.routes[0],
        });
        setSelectedCaex(null);
      }
    }
  }, [routeFilter, filters, dispatchRoute]);

  const onShiftChange = useCallback(
    (value: string | number) => {
      setShiftFilter(value.toString());
      dispatchRoute({
        type: TruckVelocityActionType.SUB_PERIOD_CHANGE,
        subPeriod: value.toString(),
        state: stateSummary,
      });
      setSelectedCaex(null);
    },
    [dispatchRoute, stateSummary]
  );
  // workday filter
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    dispatchSummary({
      type: TruckVelocityActionType.WORKDAY_CHANGE,
      workday: value,
    });
    setSelectedCaex(null);
  };

  interface CardDataProps {
    cardData: DetailsCard;
  }

  const handleRouteChange = (event: any) => {
    setRouteFilter(event.value);
    dispatchRoute({
      type: TruckVelocityActionType.ROUTE_CHANGE,
      route: event.value.toString(),
    });
    setSelectedCaex(null);
  };

  const CardComponent = ({ cardData }: CardDataProps) => {
    return (
      <Grid
        item
        xs={3}
        sm={4}
        md={3}
        lg={4}
        xl={4}
        className={classes.gridCardContent}
      >
        <Card elevation={2}>
          <CardContent className={classes.cardContent}>
            <Tooltip
              title={KpiTypePerformanceTranslation[cardData.type] ?? ""}
              arrow
              placement="top"
              interactive
            >
              <Typography
                variant="caption"
                className={classes.adjustedTextSize}
              >
                {KpiTypePerformanceTranslation[cardData.type] ?? ""}
              </Typography>
            </Tooltip>
            <KpiCard values={cardData} />
          </CardContent>
        </Card>
      </Grid>
    );
  };

  if (
    dataStatus &&
    (dataStatus.status === "NOT_DATA_AVAILABLE_BY_HOUR" ||
      dataStatus.status === "NOT_DATA_AVAILABLE")
  ) {
    return (
      <>
        <Header loading={loadingStatus} />
        <ScreenContainer className={classes.screenContainer}>
          <NoDataPerformanceScreen
            status={dataStatus.status}
            viewName="Velocidad"
          />
        </ScreenContainer>
      </>
    );
  }

  return (
    <>
      <Header elapsedMinutes={lastUpdate} loading={summaryLoading} minutesToShowWarning={90}/>
      <ScreenContainer className={classes.screenContainer}>
        <TruckPerformancetKpis />
        <Grid container style={{ margin: "20px 0px" }}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h6">
                  Rendimiento de Velocidad | {dataSummary?.currentShift}
                </Typography>
              </Grid>
              <Grid item xs={4}>
              <a href={filters?.urlMaps} target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  outline: "none"
                }}
                >
                <Typography variant="h6">
                  Plano Escondida
                </Typography>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item xs={12} style={{ display: "contents" }}>
                <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                  FILTROS
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 700, margin: "0px 6px 0px 21px" }}
                >
                  Comparación
                </Typography>
                <ChipGroup
                  value={stateSummary.period}
                  options={periodOptions}
                  onChange={onChipChange}
                />
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 700, margin: "0px 12px 0px 21px" }}
                >
                  Jornada
                </Typography>
                <ToggleButtonGroup
                  value={stateSummary.workday}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value={FiltersVelocity.COMPLETE_DAY}
                    aria-label="left aligned"
                    className={classes.ToogleButton}
                  >
                    <MoonSunIcon className={classes.ToogleIcon} />
                  </ToggleButton>

                  <ToggleButton
                    value={FiltersVelocity.NIGHT}
                    aria-label="centered"
                    className={classes.ToogleButton}
                  >
                    <MoonIcon className={classes.ToogleIcon} />
                  </ToggleButton>

                  <ToggleButton
                    value={FiltersVelocity.DAY}
                    aria-label="right aligned"
                    className={classes.ToogleButton}
                  >
                    <SunIcon className={classes.ToogleIcon} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <RoutesSummary />
        <Grid container style={{ marginBottom: 30 }}>
          <Grid item xs={4}>
            <Grid container alignItems="center">
              <Grid item spacing={2} xs={4}>
                {" "}
                <Grid container alignItems="center">
                <Typography variant="h6">
                  <strong> Ruta selecionada </strong>
                </Typography>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <Select
                    className={classes.selectContainer}
                    value={routeFilter}
                    onChange={(e) => handleRouteChange(e.target)}
                    label=""
                    classes={{ root: classes.selector }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {filters.routes?.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} style={{ display: "flex" }}>
            <Grid container alignItems="center">
              <Grid item xs={8}>
                <CardsContainer loading={loadingSectionRoutes} numberCards={3}>
                  <Grid container spacing={3} style={{ marginLeft: "10px" }}>
                    {graphSection?.cards?.map(
                      (data: DetailsCard, index: number) => (
                        <CardComponent cardData={data} key={index} />
                      )
                    )}
                  </Grid>
                </CardsContainer>
              </Grid>
              <Grid item xs={4}>
                <Grid container justifyContent="flex-end" alignItems="flex-end">
                  <Grid item xs={12} style={{ display: "contents" }}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: 700,
                        margin: "0px 6px 0px 21px",
                      }}
                    >
                      Actual
                    </Typography>
                    <ChipGroup
                      value={shiftFilter}
                      options={shiftOptions}
                      onChange={onShiftChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Paper className={classes.performancePaper} elevation={4}>
          <Routes
            selectedRoute={routeFilter}
            graphSection={graphSection}
            loading={loadingSectionRoutes}
          />
          <Caex data={graphSection} firstLoading={loadingSectionRoutes} />
        </Paper>
        <Paper
          className={classes.performancePaper}
          elevation={4}
          style={{ marginTop: "21px" }}
        >
          <CaexSearch
            listCaex={filters?.caex}
            availableModels={filters?.availableModels}
            notAvailableModels={filters?.notAvailableModels}
            loadingCaex={loadingFilters}
            params={params}
            selectedCaex={selectedCaex}
            setSelectedCaex={setSelectedCaex}
          />
        </Paper>
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  footer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "auto",
    paddingTop: 24,
  },
  screenContainer: {
    overflowX: "hidden",
  },
  footerIcon: {
    marginRight: 10,
  },
  performancePaper: {
    padding: 12,
    width: "100%",
    background:
      palette.type === "light" ? palette.grey[50] : palette.background.paper,
  },
  ToogleIcon: {
    width: "32px",
    height: "32px",
  },
  ToogleButton: {
    "&&.MuiToggleButton-root": {
      padding: "0px",
    },
  },
  gridCardContent: {
    padding: "2px",
  },
  cardContent: {
    height: "64px",
    padding: "4px 8px",
    paddingTop: "8px",
    "&.MuiCardContent-root": {
      paddingBottom: "4px",
    },
    background:
      palette.type === "light" ? palette.grey[50] : palette.background.paper,
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.20), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14)",
  },
  adjustedTextSize: {
    display: "block",
    width: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  infoIconTooltip: {
    lineHeight: "24px",
    marginLeft: 10,
    width: 20,
    height: 20,
  },
  selector: {
    "&&.MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      "&&:focus": {
        borderRadius: 4,
        border: "1px solid #ced4da",
      },
    },
  },
  selectContainer: {
    margin: "10px 0px",
  },
}));
