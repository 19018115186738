import React, { useContext, useRef } from "react";
import { CSVLink } from "react-csv";
import { GetApp } from "@material-ui/icons";
import { Button, makeStyles, alpha} from "@material-ui/core";
import { formatLocalizedDate } from "utils";
import { RelieveRecommendationExportContext } from "contexts/RelieveRecommendationExportContext";


export const RelieveRecommendationExport = () => {
    const classes = useStyles();
    // here we are going to export all the recommendation info that we have
    // we are going to aggrupate the data by location
    // and we are going to send the respective information with all the format
    // of the respective database
    const headers = [
        {label: "Grupo", key:"groupId"},
        {label: "Pala", key:"excavId"},
        {label: "Comedor", key:"dinner"},
        {label: "Banca/Prestado", key:"operator"},
        {label: "CAEX 1", key:"truck1"},
        {label: "Operador 1", key:"operNameOut1"},
        {label: "CAEX 2", key:"truck2"},
        {label: "Operador 2", key:"operNameOut2"},
    ]
  
    const { data: relayRecommendation } = useContext(RelieveRecommendationExportContext);
    let timestamp = formatLocalizedDate(new Date());
    const FILENAME = `Relay_Recommendations_${timestamp}.csv`;  
    const csvLinkEl = useRef(null)
    // const downloadRecommendations = () => {
    //     csvLinkEl.current?.link.click()
    // }

    return (
        <>
            <CSVLink
                    headers={headers}
                    filename={FILENAME}
                    data={relayRecommendation}
                    separator={";"}
                    enclosingCharacter={`'`}
                    asyncOnClick={true}
                    ref={csvLinkEl}
            >
                <Button className={classes.download}
                    disabled ={relayRecommendation.length === 0}
                >
                    <GetApp style={{ paddingRight: 3}} /> Descargar .CSV
                </Button>
            </CSVLink>
        </>
    )

}

const useStyles = makeStyles(({ palette }) => ({
    screenContainer: {
      overflowX: "hidden",
    },
    mainContainer: {
      background:
        palette.type === "light" ? palette.grey[50] : palette.background.paper,
      padding: "12px 18px",
      boxShadow:
        "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
    },
    mainContainer2: {
      background:
        palette.type === "light" ? palette.grey[100] : palette.background.default,
      minHeight: 700,
    },
    button: {
      width: 36,
      height: 36,
      borderRadius: "100%",
      position: "absolute",
      top: "50%",
      right: 0,
      transform: "translate(50%, -50%)",
      background: palette.background.paper,
      minWidth: "unset",
      filter:
        "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20))",
    },
    download: {
      background:
        palette.type === "light" ? palette.secondary.main : palette.common.white,
      color: palette.chip.contrastText,
      "&:hover": {
        background: alpha(palette.action.active, 0.6),
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      },
      "&:disabled": {
        background: palette.action.disabled
      }
    },
  }));
  
