import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

interface FooterProps {
  className?: string;
  children: React.ReactNode;
}

export const Footer: React.FC<FooterProps> = ({
  children,
  className: propClassBane,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.footerRoot, propClassBane)}>{children}</div>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  footerRoot: {
    display: "flex",
    padding: "15px 24px",
    color: palette.text.primary,
    backgroundColor:
      palette.type === "light"
        ? palette.background.paper
        : palette.background.default,
    marginLeft: -24,
    marginRight: -24,
    marginBottom: -24,
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
  },
}));
