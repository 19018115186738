import React from "react";
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  Theme,
  alpha,
} from "@material-ui/core";
import { CardAlertType, TruckSummary } from "interfaces";
import { getAlertPaperColor, getAlertTextColor } from "styles";
import { TruckMaintenanceTooltip } from "./TruckMaintenanceTooltip";
import { TruckAvatar } from "components";

interface TruckInfoCardProp {
  truck: TruckSummary;
  alertType: CardAlertType;
  showReasonId: boolean;
}

export const TruckInfoCard: React.FC<TruckInfoCardProp> = ({
  truck,
  alertType,
  showReasonId,
}) => {
  const classes = useStyles({ alertType });
  return (
    <TruckMaintenanceTooltip truck={truck}>
      <Paper elevation={0} className={classes.truckInfoPaper}>
        <TruckAvatar truck={truck} />
        <Grid
          container
          direction="column"
          className={classes.truckInfoGridContainer}
        >
          <Grid item xs>
            <Typography className={classes.title} noWrap variant="body1">
              <strong>{truck.id}</strong>
              {showReasonId && " | " + truck.reasonId}
            </Typography>
            <Typography className={classes.subTitle} noWrap variant="body1">
              {truck.location?.name ?? "-"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </TruckMaintenanceTooltip>
  );
};

interface StyleProps {
  alertType: CardAlertType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  truckInfoPaper: {
    backgroundColor: ({ alertType }) => getAlertPaperColor(theme, alertType),
    display: "flex",
    height: 54,
    alignItems: "center",
    marginBottom: 4,
    marginRight: 4,
  },
  title: {
    fontWeight: "normal",
    color: ({ alertType }) => getAlertTextColor(theme, alertType),
  },
  subTitle: {
    color: ({ alertType }) => alpha(getAlertTextColor(theme, alertType), 0.6),
  },
  truckInfoGridContainer: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
}));
