import { MetricGroupType, DisplayMetric } from "interfaces";

export enum TruckAssignmentMetricsActionType {
  INIT = "INIT",
  RESET = "RESET",
  GROUP_TYPE_CHANGE = "GROUP_TYPE_CHANGE",
  ENTITY_CHANGE = "GROUP_CHANGE",
  METRIC_CHANGE = "METRIC_CHANGE",
}
export type TruckAssignmentMetricsAction =
  | {
      type: TruckAssignmentMetricsActionType.INIT;
      payload: TruckAssignmentMetricsState;
    }
  | {
      type: TruckAssignmentMetricsActionType.RESET;
      payload: TruckAssignmentMetricsState;
    }
  | {
      type: TruckAssignmentMetricsActionType.GROUP_TYPE_CHANGE;
      selectedEntity: string;
      groupType: MetricGroupType;
    }
  | {
      type: TruckAssignmentMetricsActionType.ENTITY_CHANGE;
      selectedEntity: string;
    }
  | {
      type: TruckAssignmentMetricsActionType.METRIC_CHANGE;
      metric: DisplayMetric;
    };

export interface TruckAssignmentMetricsState {
  groupType: MetricGroupType;
  selectedEntity: string;
  selectedMetric: DisplayMetric;
}

export const initialTruckAssignmentMetricsState: TruckAssignmentMetricsState = {
  groupType: MetricGroupType.MINE,
  selectedEntity: "",
  selectedMetric: DisplayMetric.ORIGIN_QUEUE,
};

export const truckAssignmentMetricsReducer = (
  state: TruckAssignmentMetricsState,
  action: TruckAssignmentMetricsAction
): TruckAssignmentMetricsState => {
  switch (action.type) {
    case TruckAssignmentMetricsActionType.GROUP_TYPE_CHANGE: {
      return {
        ...state,
        selectedEntity: action.selectedEntity ?? state.selectedEntity,
        groupType: action.groupType,
        selectedMetric:
          action.groupType !== MetricGroupType.MINE &&
          state.selectedMetric === DisplayMetric.MINE_UTILIZATION
            ? DisplayMetric.ORIGIN_QUEUE
            : state.selectedMetric,
      };
    }
    case TruckAssignmentMetricsActionType.ENTITY_CHANGE: {
      return {
        ...state,
        selectedEntity: action.selectedEntity,
      };
    }
    case TruckAssignmentMetricsActionType.METRIC_CHANGE:
      return {
        ...state,
        selectedMetric: action.metric,
      };
    case TruckAssignmentMetricsActionType.INIT:
      return action.payload;
    case TruckAssignmentMetricsActionType.RESET:
      return action.payload;
    default:
      return state;
  }
};
