import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Radio,
  RadioGroup,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  Theme,
  InputLabel,
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import {
  MetricGroupType,
  metricsGroupTypeTranslation,
  EntityFields,
  DisplayMetric,
  Pit,
  PitTranslation,
  accumulatedDataMapping,
  metricTranslation,
  metricUnit,
  AccumulatedMetric,
} from "interfaces";
import {
  TruckAssignmentMetricsAction,
  TruckAssignmentMetricsActionType,
} from "reducers";
import clsx from "clsx";
import { ExcavatorContext, TruckAssignmentMetricsContext } from "contexts";
import { useSearchParams } from "react-router-dom";
import { localizeNumber } from "utils";

interface MenuProps {
  dispatch: React.Dispatch<TruckAssignmentMetricsAction>;
}

interface MetricRadioProps {
  displayMetric: DisplayMetric;
  checked: boolean;
  label: string;
  lastData?: number;
  lastDataPlan?: number;
  measurement: string | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface GroupAndMetricType {
  group: MetricGroupType;
  metric: DisplayMetric;
}

interface Lastdata {
  averageList:  AccumulatedMetric[];
  metricsKey: DisplayMetric;
}

const MetricRadio: React.FC<MetricRadioProps> = ({
  displayMetric,
  checked,
  label,
  lastData,
  lastDataPlan,
  measurement,
  onChange,
}) => {
  return (
    <FormControlLabel
      key={displayMetric}
      control={
        <Radio
          checked={checked}
          onChange={onChange}
          name={displayMetric}
          color="primary"
        />
      }
      label={
        lastData !== undefined  && lastDataPlan !== undefined ? (
          <Typography>
            <strong>{label + ": "}</strong>
            {`${localizeNumber(lastData)}/${localizeNumber(lastDataPlan)}`}
          </Typography>
        ) : lastData !== undefined  ? (
          <Typography>
          <strong>{label + ": "}</strong>
          {`${localizeNumber(lastData)} ${measurement}`}
        </Typography>
        ) :
        (
          <Typography>
           { measurement !== "" 
           ? <strong>{label + ` [${measurement}]`}</strong> 
           : <strong>{label}</strong>}
          </Typography>
        )
      }
    />
  );
};

export const Menu: React.FC<MenuProps> = ({ dispatch }) => {
  const classes = useStyles();
  const { menuState: state } = useContext(TruckAssignmentMetricsContext);

  const [searchParams, setSearchParams] = useSearchParams();

  // Context
  const { metricSelectorOptions } = useContext(ExcavatorContext);
  const { setPendingChanges, parsedAverages } = useContext(
    TruckAssignmentMetricsContext
  );

  // Get screen size:
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const toggleButtonClasses = useMemo(() => {
    return {
      root: clsx(classes.toggleButton, classes.toggleButtonFilters),
      selected: classes.toggleButtonSelected,
    };
  }, [classes]);

  const getFirstEntity = useCallback(
    (groupType: MetricGroupType) => {
      if (groupType === MetricGroupType.PIT) {
        return Object.values(Pit)[0];
      }
      return metricSelectorOptions[groupType][0] ?? "";
    },
    [metricSelectorOptions]
  );

  const toggleGroupTypeChange = useCallback(
    (newGroupType: MetricGroupType) => {
      searchParams.set("groupType", newGroupType);
      searchParams.delete("entityId");
      const firstEntity = getFirstEntity(newGroupType);
      if (firstEntity) {
        searchParams.set("entityId", firstEntity);
      }
      setSearchParams(searchParams);
      dispatch({
        type: TruckAssignmentMetricsActionType.GROUP_TYPE_CHANGE,
        selectedEntity: firstEntity,
        groupType: newGroupType,
      });
      setPendingChanges(true);
    },
    [dispatch, getFirstEntity, searchParams, setPendingChanges, setSearchParams]
  );

  const onSelectorChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      const newGroupType = e.target.value as MetricGroupType;
      toggleGroupTypeChange(newGroupType);
    },
    [toggleGroupTypeChange]
  );

  const onGroupTypeChange = useCallback(
    (e: React.MouseEvent<HTMLElement>, newGroupType: MetricGroupType) => {
      toggleGroupTypeChange(newGroupType);
    },
    [toggleGroupTypeChange]
  );

  const onEntityChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      searchParams.set("entityId", e.target.value as EntityFields["id"]);
      setSearchParams(searchParams);
      dispatch({
        type: TruckAssignmentMetricsActionType.ENTITY_CHANGE,
        selectedEntity: (e.target.value as EntityFields["id"]) || "",
      });
      setPendingChanges(true);
    },
    [dispatch, searchParams, setSearchParams, setPendingChanges]
  );

  // Default values if no searchParams are given
  useEffect(() => {
    const urlGroupType =
      (searchParams.get("groupType") as MetricGroupType) ??
      MetricGroupType.MINE;
    dispatch({
      type: TruckAssignmentMetricsActionType.GROUP_TYPE_CHANGE,
      groupType: urlGroupType,
      selectedEntity:
        searchParams.get("entityId") ?? getFirstEntity(urlGroupType),
    });
  }, [dispatch, getFirstEntity, metricSelectorOptions, searchParams]);

  const onMetricsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: TruckAssignmentMetricsActionType.METRIC_CHANGE,
        metric: e.target.name as DisplayMetric,
      }),
    [dispatch]
  );

  const displayMetricsEvaluation = ({group, metric}: GroupAndMetricType): boolean => {
    const dontShow = {
      [MetricGroupType.MINE]: [DisplayMetric.CAEX_PLAN],
      [MetricGroupType.PIT]: [
        DisplayMetric.MINE_UTILIZATION,
        DisplayMetric.CAEX_PLAN
      ],
      [MetricGroupType.PHASE]: [
        DisplayMetric.MINE_UTILIZATION,
        DisplayMetric.CAEX_PLAN
      ],
      [MetricGroupType.VIRTUAL_REGION]: [
        DisplayMetric.MINE_UTILIZATION,
        DisplayMetric.CAEX_PLAN
      ],
      [MetricGroupType.EXCAV]: [DisplayMetric.MINE_UTILIZATION, DisplayMetric.CAEX_PLAN],
    }
    return !dontShow[group].includes(metric)
  }

  const getLastData = ({averageList, metricsKey}: Lastdata): number | undefined => {
    return averageList.find(
      ({ metric }) =>
        metric === accumulatedDataMapping[metricsKey]
    )?.value
  }

  return (
    <Grid container className={classes.menuContainer}>
      <Grid className={classes.toggleFiltersContainer} container>
        <Grid className={classes.filtersContainer} item xs={12}>
          {!isSmall ? (
            <ToggleButtonGroup
              value={state.groupType}
              exclusive
              onChange={onGroupTypeChange}
              className={classes.groupToggle}
            >
              {Object.values(MetricGroupType).map((option, x) => (
                <ToggleButton
                  key={x}
                  classes={toggleButtonClasses}
                  value={option}
                >
                  {metricsGroupTypeTranslation[option]}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          ) : (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Origen
              </InputLabel>
              <Select
                native
                value={state.groupType}
                onChange={onSelectorChange}
                label="Origen"
              >
                {Object.values(MetricGroupType).map((option, x) => (
                  <option key={x} value={option}>
                    {metricsGroupTypeTranslation[option]}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.viewOptionsContainer} container>
        <Grid className={classes.viewOptions} item xs={12}>
          <FormControl className={classes.excavatorSelector} variant="standard">
            {metricSelectorOptions[state.groupType].length ? (
              <Select
                value={state.selectedEntity}
                onChange={onEntityChange}
                label="Entity"
              >
                {state.groupType === MetricGroupType.PIT
                  ? Object.values(Pit).map((pit) => (
                      <MenuItem key={pit} value={pit}>
                        {PitTranslation[pit]}
                      </MenuItem>
                    ))
                  : metricSelectorOptions[state.groupType].map((id) => (
                      <MenuItem key={id} value={id}>
                        {id}
                      </MenuItem>
                    ))}
              </Select>
            ) : (
              <div className={classes.spacer}></div>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Divider className={classes.divider} />
      </Grid>
      <RadioGroup className={classes.metricsContainer}>
        {Object.entries(DisplayMetric).map(([_, metricsKey]) => (
          <div key={`${metricsKey}`}>
            {metricsKey === DisplayMetric.LOADTONS && (
              <Divider className={classes.divider} />
            )}
            {/* Only show Mine Utilization Metric on Mine GroupType */}
            {/* Only show CAEX Metric on Excav GroupType */}
            {displayMetricsEvaluation({group: state.groupType, metric: metricsKey}) && (
              <MetricRadio
                displayMetric={metricsKey}
                checked={state.selectedMetric === metricsKey}
                label={metricTranslation[metricsKey]}
                lastData={getLastData({averageList:parsedAverages, metricsKey: metricsKey})}
                lastDataPlan = { metricsKey === DisplayMetric.CAEX 
                  ? getLastData({averageList:parsedAverages, metricsKey: DisplayMetric.CAEX_PLAN})
                  : undefined}
                measurement={metricUnit[metricsKey]}
                onChange={onMetricsChange}
              />
            )}
          </div>
        ))}
      </RadioGroup>
    </Grid>
  );
};

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: palette.background.default,
    padding: "10px 25px !important",
    alignItems: "center",
  },
  groupToggle: {
    width: "100%",
    height: "40px",
    [breakpoints.between("md", "lg")]: {
      height: "130px",
      display: "grid",
    },
  },
  toggleFiltersContainer: {
    alignItems: "center",
    margin: "12px 0px",
  },
  filtersContainer: {
    display: "flex",
    [breakpoints.between("md", "lg")]: {
      justifyContent: "center",
    },
  },
  viewOptionsContainer: {
    alignItems: "center",
    margin: "12px 0px",
  },
  viewOptions: {
    display: "flex",
    justifyContent: "space-between",
  },
  toggleButton: {
    "&$toggleButtonSelected": {
      backgroundColor: palette.secondary.main,
      color: palette.getContrastText(palette.secondary.main),
      "&:hover": {
        backgroundColor: palette.secondary.main,
      },
    },
  },
  //!This most go in order to '&$toggleButtonSelected' works
  toggleButtonSelected: {
    width: "auto",
    height: "auto",
  },
  toggleButtonFilters: {
    width: "auto",
    height: "auto",
    [breakpoints.between("md", "lg")]: {
      height: "30px",
    },
    [breakpoints.between("xs", "sm")]: {
      height: "20px",
    },
  },
  excavatorContainer: {},
  excavatorSelector: {
    alignSelf: "flex-end",
    [breakpoints.between("md", "lg")]: {
      marginTop: 8,
    },
  },
  divider: {
    width: "100%",
    margin: "12px 0px",
    [breakpoints.between("md", "lg")]: {
      margin: "8px 0px",
    },
  },
  metricsContainer: {
    width: "100%",
    margin: "0px",
    marginBottom: "12px",
  },
  spacer: {
    height: 32,
  },
  formControl: {
    width: "100%",
  },
}));
