import {
  CircularProgress,
  Grid,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { EmptyTruckVelocityIcon } from "components/icons/EmptyTruckVelocityIcon";
import { FullTruckVelocityIcon } from "components/icons/FullTruckVelocityIcon";
import { Size, SquareChip } from "components/SquareChip";
import { RoutesData } from "interfaces/velocity.interface";
import { Maps } from "./Maps";
import { KpiMapsContainer } from "./KpiMapsContainer";
import { WithoutData } from "components/WithoutData";
import { BarGraph } from "./BarGraph";

interface Props {
  selectedRoute: string;
  graphSection: RoutesData;
  loading: boolean;
}
export const Routes: React.FC<Props> = ({
  selectedRoute,
  graphSection,
  loading,
}) => {
  const classes = useStyles();
  return (
    <Grid container style={{ marginBottom: 20 }}>
      <Grid item xs={4} className={classes.routeContainer}>
        <KpiMapsContainer loading={loading}>
          <Grid item xs={12}>
            <Typography variant="subtitle1"> Congestión por tramo</Typography>
          </Grid>
          <Grid item xs={12} className={classes.containerChips}>
            <Grid container>
              <Grid item xs={12} style={{ display: "contents" }}>
                <FullTruckVelocityIcon className={classes.icon} />
                {graphSection?.congestion?.full?.length ? (
                  graphSection?.congestion?.full?.map(
                    ({ title, level, tooltip }, index) => (
                      <SquareChip
                        title={title}
                        level={level}
                        size={Size.small}
                        tooltipData={tooltip}
                        key={index}
                      />
                    )
                  )
                ) : (
                  <Grid item xs={6} style={{ alignSelf: "center" }}>
                    <Typography variant="caption">
                      Sin información de congestión
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.containerChips}>
            <Grid container>
              <Grid item xs={12} style={{ display: "contents" }}>
                <EmptyTruckVelocityIcon className={classes.icon} />
                {graphSection?.congestion?.empty?.length ? (
                  graphSection?.congestion?.empty?.map(
                    ({ title, level, tooltip }, index) => (
                      <SquareChip
                        title={title}
                        level={level}
                        size={Size.small}
                        tooltipData={tooltip}
                        key={index}
                      />
                    )
                  )
                ) : (
                  <Grid item xs={6} style={{ alignSelf: "center" }}>
                    <Typography variant="caption">
                      Sin información de congestión
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Maps selectedRoute={selectedRoute} />
        </KpiMapsContainer>
      </Grid>
      {loading ? (
        <div className={classes.infoContainer}>
          <CircularProgress
            style={{ marginBottom: 24 }}
            size={120}
            color="primary"
          />
        </div>
      ) : (
        <Grid item xs={8}>
          {graphSection && graphSection.graph?.length ? (
            <>
              <BarGraph dataGraph={graphSection} />
            </>
          ) : (
            <Grid container alignContent="center" style={{ height: 300 }}>
              <Grid item xs={12}>
                <WithoutData title="No se encontraron datos" />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  mainContainer: {},
  infoContainer: {
    width: "100%",
    height: "500px",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    paddingBottom: 30,
    paddingTop: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  routeContainer: {
    background:
      palette.type === "light"
        ? palette.background.default
        : palette.background.default,
    padding: "16px",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.20), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14)",
  },
  containerChips: {
    background:
      palette.type === "light" ? palette.grey[50] : palette.background.paper,
    marginBottom: "5px",
    padding: "2px",
    borderRadius: "4px",
  },
  icon: {
    width: "30px",
    height: "28px",
    margin: "0px 15px",
  },
}));
