import React from "react";
import { Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CardAlertType } from "interfaces";

interface TruckCardInfoSectionSkeletonProps {
  alertType: CardAlertType;
}

export const TruckCardInfoSectionSkeleton: React.FC<
  TruckCardInfoSectionSkeletonProps
> = ({ alertType }) => {
  const classes = useStyles({ alertType });

  return (
    <Paper elevation={0} className={classes.paper}>
      <Skeleton variant="circle" className={classes.icon} />
      <Grid container direction="column">
        <Grid item>
          <Skeleton className={classes.field} variant="rect" />
        </Grid>
        <Grid item>
          <Skeleton className={classes.field} variant="rect" width="50%" />
        </Grid>
      </Grid>
    </Paper>
  );
};

interface StyleProps {
  alertType: CardAlertType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 54,
    alignItems: "center",
    marginBottom: 4,
    marginRight: 4,
  },
  field: {
    marginLeft: 15,
    marginRight: 15,
    height: "0.8rem",
    marginBottom: 5,
    marginTop: 5,
    borderRadius: "5%",
  },
  icon: {
    width: 30,
    height: 30,
    marginLeft: 5,
    minWidth: 30,
  },
}));
