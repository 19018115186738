import { Chip, Theme, Typography, makeStyles } from '@material-ui/core';
import { BaseElement } from 'components/List';
import { LoadStatus } from 'interfaces';
import React, { useMemo } from 'react'
import { loadStatusColor } from 'styles/sicStatusColor';
import { parseNumber } from 'utils';

interface Props {
    projectedLoadtons: number | null;
    plannedLoadtons: number | null;
    spacing: string[];
    status: LoadStatus | null
  }

export const BalanceListProjectedCell:  React.FC<Props> = ({
    projectedLoadtons,
    plannedLoadtons,
    spacing,
    status
}) => {
    const classes = useStyles({
        colorChip: status
    });
    
    const movementText = useMemo(() => {
        const loadTons = projectedLoadtons === null || projectedLoadtons === 0
        ? "-" : parseNumber(projectedLoadtons);

        if (plannedLoadtons !== null) {
          if(status === null || loadTons === "-") return `${loadTons} (${parseNumber(plannedLoadtons)})`
          return (
          <>
          <div>
            <Chip 
              label={
                <Typography variant="subtitle1">
                  {loadTons}
                </Typography>
              } 
              classes={{root: classes.statusChip}} /> 
            ({parseNumber(plannedLoadtons)})
         </div>
          </>)
        }
        return `${loadTons}`;
      }, [projectedLoadtons, plannedLoadtons, classes.statusChip, status]);

  return (
    <BaseElement classNames={[...spacing]}>
      <Typography variant="subtitle1">
        {movementText ?? "-"}
      </Typography>
    </BaseElement>
  )
}

interface StyleProps {
  colorChip: LoadStatus | null;
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  statusChip:{
      background: ({colorChip}) =>
      loadStatusColor({
        theme,
        statusType: colorChip,
        color: false,
        opacityColor: true
      }), 
      color: ({colorChip}) =>
      loadStatusColor({
        theme,
        statusType: colorChip,
        color: true
      }), 
      marginRight: 5, 
      "& .MuiChip-label": {
      paddingLeft: 8,
      paddingRight: 8,
    }
  }
}))