import { ListHeaderCellProps, SpacingData } from "interfaces";
import { reduce } from "lodash";

/**
 * Transforms an array of ListHeaderCellProps into SpacingData
 * Used to generate spacing data from header definition, and use that
 * spacing data on a custom row within the Custom List.
 *
 * @param headCellData List of header data
 * @returns SpacingData
 */
export const headToSpacing = <T>(
  headCellData: ListHeaderCellProps<T>[]
): SpacingData<T> => {
  return reduce(
    headCellData,
    (prev, curr) => ({
      ...prev,
      [curr.dataKey]: curr.classNames,
    }),
    {} as SpacingData<T>
  );
};
