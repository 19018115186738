import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const AssignedTruckIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="51"
      viewBox="0 0 55 51"
      fill="none"
      {...props}
    >
      <g style={{ mixBlendMode: "multiply" }} opacity="0.18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2355 46.3493L15.1104 44.5451L37.8742 31.4022L40.9994 33.2064L18.2355 46.3493Z"
          fill="#91BED4"
        />
      </g>
      <path
        d="M18.4577 45.0928L17.8517 44.7403L17.7606 12.5707L18.3666 12.9227L18.4577 45.0928Z"
        fill="#E58A2E"
      />
      <path
        d="M18.3668 12.9223L17.7608 12.5704L36.2392 1.90179L36.8452 2.25377L18.3668 12.9223Z"
        fill="#F5B147"
      />
      <path
        d="M36.8454 2.25458L36.9365 34.4246L18.4581 45.0932L18.3671 12.9232L36.8454 2.25458Z"
        fill="url(#paint0_linear_4613_145507)"
      />
      <path
        d="M35.7014 5.07092L19.7523 14.2831L19.8319 42.3723L35.7851 33.1619L35.7055 5.07275L35.7014 5.07092Z"
        fill="#F8F6EE"
      />
      <path
        d="M30.667 7.84945L30.0928 7.51587C30.107 7.51038 30.6661 7.849 30.678 7.85129C30.6743 7.85083 30.6706 7.85037 30.667 7.84991V7.84945Z"
        fill="#2D2D2E"
      />
      <path
        d="M30.678 7.85134L30.1038 7.51776C30.129 7.51272 30.6757 7.85042 30.6972 7.85592C30.6913 7.85409 30.6848 7.85271 30.6784 7.85134H30.678Z"
        fill="#2C2C2D"
      />
      <path
        d="M30.6976 7.8561L30.1234 7.52252C30.1445 7.52252 30.6966 7.85564 30.7159 7.86252C30.7099 7.86023 30.704 7.85793 30.6976 7.8561Z"
        fill="#2B2A2C"
      />
      <path
        d="M30.7153 7.86184L30.1411 7.52826C30.1613 7.53513 30.7134 7.86092 30.7331 7.87055C30.7276 7.86734 30.7217 7.86459 30.7157 7.86184H30.7153Z"
        fill="#2A292A"
      />
      <path
        d="M30.7329 7.87044L30.1588 7.53687C30.1588 7.53687 30.7338 7.8709 30.7352 7.87182L30.7334 7.8709L30.7329 7.87044Z"
        fill="#282829"
      />
      <path
        d="M29.5629 9.22279L28.9887 8.88921L29.0515 8.5011L29.6256 8.83468L29.5629 9.22279Z"
        fill="#262527"
      />
      <path
        d="M29.2301 8.96704L28.6566 8.63383L29.0511 8.5011L29.6246 8.83431L29.2301 8.96704Z"
        fill="#CBCBCB"
      />
      <path
        d="M29.5155 9.23493L28.9414 8.90135L28.9891 8.88531L29.5632 9.21889L29.5155 9.23493Z"
        fill="#373637"
      />
      <path
        d="M29.2162 8.96548L28.642 8.6319H28.6439L29.218 8.96502H29.2162V8.96548Z"
        fill="#B7B8B8"
      />
      <path
        d="M29.2181 8.9653L28.644 8.63172C28.6481 8.6308 28.6522 8.62943 28.6568 8.62805L29.2309 8.96163C29.2268 8.96301 29.2227 8.96438 29.2181 8.9653Z"
        fill="#BCBDBD"
      />
      <path
        d="M29.2167 8.97038L28.6427 8.63717L30.2607 7.703L30.8347 8.03621L29.2167 8.97038Z"
        fill="#383739"
      />
      <path
        d="M28.7784 9.22172L28.2045 8.88852L28.642 8.63586L29.216 8.96907L28.7784 9.22172Z"
        fill="#CFD0D0"
      />
      <path
        d="M29.1331 8.96998L28.5596 8.63677L30.2499 7.66095L30.8234 7.99416L29.1331 8.96998Z"
        fill="#DBDBDB"
      />
      <path
        d="M29.0355 8.78419L28.4616 8.45098L30.0901 7.51086L30.664 7.84407L29.0355 8.78419Z"
        fill="#383739"
      />
      <path
        d="M30.5932 7.85226L30.0191 7.51868C30.0241 7.51776 30.0292 7.51685 30.0337 7.51593L30.6079 7.84951C30.6028 7.85043 30.5978 7.85134 30.5932 7.85226Z"
        fill="#C2C2C2"
      />
      <path
        d="M30.608 7.85089L30.0339 7.51731C30.0417 7.51593 30.0495 7.51547 30.0572 7.51501L30.6314 7.84859C30.6236 7.84859 30.6158 7.84951 30.608 7.85089Z"
        fill="#BDBDBD"
      />
      <path
        d="M30.6319 7.84834L30.0578 7.51477C30.0866 7.49598 30.6296 7.84743 30.653 7.8488C30.6461 7.8488 30.6392 7.84835 30.6319 7.8488V7.84834Z"
        fill="#B8B8B8"
      />
      <path
        d="M30.652 7.84873L30.0779 7.51515C30.0907 7.50736 30.6525 7.84873 30.663 7.8501C30.6593 7.8501 30.6557 7.84918 30.652 7.84918V7.84873Z"
        fill="#B3B3B3"
      />
      <path
        d="M30.8342 8.03369C30.9568 8.68592 29.9874 8.99258 29.5856 9.26537C29.5627 9.2553 29.5389 9.24477 29.5146 9.2347L29.5622 9.21868L29.6249 8.83101C29.6075 8.8365 29.2336 8.96374 29.2157 8.96786L30.8337 8.03369H30.8342Z"
        fill="#323133"
      />
      <path
        d="M28.8885 9.34127L28.3144 9.00769C28.3144 9.00769 28.889 9.34173 28.8917 9.3431L28.8885 9.34127Z"
        fill="#282829"
      />
      <path
        d="M28.8919 9.34316L28.3177 9.00958C28.3269 9.01462 28.3361 9.01921 28.3457 9.02333L28.9198 9.35691C28.9102 9.35278 28.9011 9.3482 28.8919 9.34316Z"
        fill="#2A292A"
      />
      <path
        d="M28.9198 9.35775L28.3457 9.02417C28.3553 9.02829 28.3649 9.0315 28.375 9.03471L28.9491 9.36829C28.9391 9.36554 28.9294 9.36187 28.9198 9.35775Z"
        fill="#2B2A2C"
      />
      <path
        d="M28.9485 9.36727L28.3744 9.03369C28.3835 9.03644 28.3931 9.03873 28.4028 9.04056L28.9769 9.37414C28.9673 9.37231 28.9577 9.37002 28.9485 9.36727Z"
        fill="#2C2C2D"
      />
      <path
        d="M28.7612 9.18419L28.1873 8.85098L28.5598 8.63586L29.1333 8.96907L28.7612 9.18419Z"
        fill="#DBDBDB"
      />
      <path
        d="M29.1092 8.95718L28.5351 8.6236C28.5351 8.6236 29.1097 8.95763 29.1106 8.95809L29.1092 8.95718Z"
        fill="#959696"
      />
      <path
        d="M29.1107 8.95815L28.5365 8.62457C28.5503 8.62961 29.1098 8.95769 29.123 8.96411C29.1189 8.96228 29.1148 8.96044 29.1107 8.95815Z"
        fill="#9A9B9B"
      />
      <path
        d="M29.1226 8.96389L28.5485 8.63031C28.5627 8.63123 29.1212 8.96297 29.1341 8.96801C29.1299 8.96664 29.1263 8.96526 29.1226 8.96389Z"
        fill="#9FA0A0"
      />
      <path
        d="M29.1085 8.95844C29.0224 8.87871 28.4349 8.65006 28.4638 8.48969L29.0379 8.82326C29.0425 8.88833 29.0682 8.93507 29.108 8.95798L29.1085 8.95844Z"
        fill="#222122"
      />
      <path
        d="M29.0389 8.82486L28.4643 8.49082L28.4616 8.45233L29.0362 8.78591L29.0389 8.82486Z"
        fill="#222122"
      />
      <path
        d="M28.8896 9.33861C28.7906 9.26484 28.2462 9.01098 28.2054 8.88818L28.7796 9.22176C28.8071 9.27308 28.8442 9.31249 28.8896 9.33861Z"
        fill="#7D7E7E"
      />
      <path
        d="M28.7624 9.18359L28.1882 8.85001C28.1704 8.8051 28.1589 8.75332 28.1548 8.69513L28.7289 9.02871C28.7331 9.0869 28.7445 9.13868 28.7624 9.18359Z"
        fill="#7D7E7E"
      />
      <path
        d="M28.7282 9.0317L28.1536 8.69812L28.1495 8.63397L28.7236 8.96755L28.7282 9.0317Z"
        fill="#7E7E7E"
      />
      <path
        d="M26.8147 10.3556L26.2412 10.0224L28.2038 8.88916L28.7778 9.22237L26.8147 10.3556Z"
        fill="#383739"
      />
      <path
        d="M26.8329 10.2982L26.2589 9.965L28.1872 8.85187L28.7612 9.18507L26.8329 10.2982Z"
        fill="#DBDBDB"
      />
      <path
        d="M26.8697 10.0377L26.2957 9.70408L28.1499 8.63397L28.7234 8.96718L26.8697 10.0377Z"
        fill="#383739"
      />
      <path
        d="M28.723 8.96587L28.1495 8.63267L28.4617 8.45233L29.0356 8.78554L28.723 8.96587Z"
        fill="#CFD0D0"
      />
      <path
        d="M26.7172 10.819C26.6366 10.7667 26.0936 10.4717 26.0391 10.408C26.1261 10.4565 26.6526 10.7617 26.7172 10.819Z"
        fill="#222122"
      />
      <path
        d="M26.6121 10.7426L26.038 10.409C26.0481 10.3957 26.0577 10.3824 26.0673 10.3687L26.6415 10.7022C26.6318 10.716 26.6222 10.7293 26.6121 10.7426Z"
        fill="#302F31"
      />
      <path
        d="M26.6418 10.7026L26.0676 10.369C26.0786 10.3534 26.0896 10.3374 26.0997 10.3209L26.6738 10.6544C26.6633 10.6705 26.6528 10.6865 26.6418 10.7026Z"
        fill="#2F2E30"
      />
      <path
        d="M26.6733 10.6548L26.0992 10.3212C26.1092 10.3057 26.1189 10.2901 26.128 10.274L26.7022 10.6076C26.693 10.6237 26.6834 10.6392 26.6733 10.6548Z"
        fill="#2D2D2E"
      />
      <path
        d="M26.7025 10.6066L26.1283 10.273C26.1375 10.2574 26.1462 10.2418 26.1544 10.2263L26.7286 10.5598C26.7203 10.5754 26.7116 10.5915 26.7025 10.6066Z"
        fill="#2C2C2D"
      />
      <path
        d="M26.7283 10.5592L26.1541 10.2257C26.1624 10.2101 26.1706 10.194 26.1779 10.1785L26.7521 10.512C26.7443 10.5281 26.7365 10.5437 26.7283 10.5592Z"
        fill="#2B2A2C"
      />
      <path
        d="M26.7526 10.5124L26.1785 10.1788C26.1863 10.1628 26.1936 10.1467 26.2005 10.1307L26.7746 10.4643C26.7678 10.4803 26.7604 10.4968 26.7526 10.5124Z"
        fill="#2A292A"
      />
      <path
        d="M26.7742 10.4664L26.2 10.1328C26.2073 10.1163 26.2142 10.0998 26.2206 10.0829L26.7948 10.4165C26.7884 10.433 26.7815 10.4499 26.7742 10.4664Z"
        fill="#282829"
      />
      <path
        d="M26.7947 10.4137L26.2206 10.0801C26.2274 10.0627 26.2334 10.0453 26.2393 10.0274L26.8135 10.361C26.8075 10.3784 26.8016 10.3958 26.7947 10.4137Z"
        fill="#272628"
      />
      <path
        d="M26.8138 10.3626L26.2397 10.0291L26.242 10.0226L26.8161 10.3562C26.8161 10.3562 26.8147 10.3608 26.8138 10.3626Z"
        fill="#262526"
      />
      <path
        d="M26.8323 10.2955L26.2581 9.96197C26.2632 9.94318 26.2678 9.92485 26.2719 9.90607L26.846 10.2396C26.8419 10.2584 26.8373 10.2768 26.8323 10.2955Z"
        fill="#242425"
      />
      <path
        d="M26.8457 10.2397L26.2715 9.90611C26.277 9.87999 26.2816 9.85433 26.2848 9.82867L26.8589 10.1623C26.8557 10.1879 26.8512 10.2136 26.8457 10.2397Z"
        fill="#232324"
      />
      <path
        d="M26.8595 10.163L26.2854 9.82944C26.2881 9.80927 26.29 9.78911 26.2909 9.76941L26.865 10.103C26.8637 10.1227 26.8618 10.1429 26.8595 10.163Z"
        fill="#222122"
      />
      <path
        d="M26.865 10.1063L26.2904 9.77268L26.2946 9.70349L26.8692 10.0375L26.865 10.1063Z"
        fill="#242324"
      />
      <path
        d="M26.3779 10.6085L26.7881 10.1785H26.8716L26.816 10.3555L26.3779 10.6085Z"
        fill="#F1F2F2"
      />
      <path
        d="M26.4601 10.5118L25.8866 10.1786L26.2587 9.9635L26.8327 10.2967L26.4601 10.5118Z"
        fill="#DBDBDB"
      />
      <path
        d="M26.3638 10.6201L25.7896 10.2865C25.7947 10.2824 25.7992 10.2782 25.8043 10.2741L26.3784 10.6077C26.3738 10.6118 26.3688 10.6159 26.3638 10.6201Z"
        fill="#C1C2C2"
      />
      <path
        d="M26.4628 10.5137L25.8886 10.1802C25.8918 10.1751 25.8955 10.1701 25.8987 10.165L26.4729 10.4986C26.4697 10.5037 26.4664 10.5087 26.4628 10.5137Z"
        fill="#AEAEAE"
      />
      <path
        d="M26.4723 10.4982L25.8982 10.1646C25.9023 10.1577 25.9069 10.1509 25.9106 10.144L26.4847 10.4776C26.4806 10.4844 26.4765 10.4913 26.4723 10.4982Z"
        fill="#A9A9A9"
      />
      <path
        d="M26.4857 10.4781L25.9116 10.1445C25.9157 10.1376 25.9194 10.1308 25.923 10.1239L26.4972 10.4575C26.4935 10.4644 26.4899 10.4712 26.4857 10.4781Z"
        fill="#A4A5A5"
      />
      <path
        d="M26.4972 10.4561L25.9231 10.1225C25.9267 10.1157 25.9304 10.1088 25.9336 10.1019L26.5077 10.4355C26.5045 10.4424 26.5009 10.4493 26.4972 10.4561Z"
        fill="#9FA0A0"
      />
      <path
        d="M26.5063 10.4365L25.9321 10.1029C25.9353 10.0961 25.9385 10.0887 25.9418 10.0818L26.5159 10.4154C26.5127 10.4223 26.5095 10.4296 26.5063 10.4365Z"
        fill="#9A9B9B"
      />
      <path
        d="M26.5173 10.4154L25.9431 10.0819C25.9463 10.0745 25.9491 10.0672 25.9523 10.0599L26.5264 10.3935C26.5237 10.4008 26.5205 10.4081 26.5173 10.4154Z"
        fill="#959696"
      />
      <path
        d="M26.5254 10.3934L25.9513 10.0598C25.954 10.052 25.9568 10.0447 25.9595 10.0369L26.5337 10.3705C26.5309 10.3783 26.5282 10.3856 26.5254 10.3934Z"
        fill="#919191"
      />
      <path
        d="M26.534 10.3704L25.9599 10.0368C25.9626 10.0286 25.9649 10.0203 25.9672 10.0121L26.5413 10.3457C26.5391 10.3539 26.5368 10.3622 26.534 10.3704Z"
        fill="#8C8C8C"
      />
      <path
        d="M26.5421 10.3464L25.968 10.0128C25.9707 10.0037 25.973 9.99452 25.9749 9.98535L26.549 10.3189C26.5472 10.3281 26.5449 10.3373 26.5421 10.3464Z"
        fill="#878888"
      />
      <path
        d="M26.5479 10.3174L25.9737 9.98386C25.976 9.9724 25.9783 9.96141 25.9797 9.94995L26.5538 10.2835C26.5524 10.2945 26.5506 10.306 26.5479 10.3174Z"
        fill="#828383"
      />
      <path
        d="M26.5541 10.2843L25.9799 9.95077C25.9808 9.94206 25.9818 9.93336 25.9822 9.92511L26.5564 10.2587C26.5559 10.2674 26.555 10.2756 26.5541 10.2843Z"
        fill="#7D7E7E"
      />
      <path
        d="M26.5549 10.2585L25.9808 9.92488L25.9831 9.88318L26.5577 10.2168L26.5549 10.2585Z"
        fill="#858585"
      />
      <path
        d="M26.5571 10.2175L25.9832 9.88428L26.2958 9.70349L26.8697 10.0372L26.5571 10.2175Z"
        fill="#CFD0D0"
      />
      <path
        d="M26.0354 11.2565L25.4608 10.9229L25.4947 10.8954L26.0688 11.229L26.0354 11.2565Z"
        fill="#373738"
      />
      <path
        d="M26.0355 11.2575L25.4609 10.924L25.3963 10.6096L25.9704 10.9432L26.0355 11.2575Z"
        fill="#797A7A"
      />
      <path
        d="M25.9698 10.9434L25.3963 10.6102L25.7899 10.2875L26.3639 10.6207L25.9698 10.9434Z"
        fill="#CDCECE"
      />
      <path
        d="M24.7497 11.5508L24.1762 11.2171L25.8047 10.277L26.3782 10.6102L24.7497 11.5508Z"
        fill="#383739"
      />
      <path
        d="M24.7587 11.494L24.1848 11.1608L25.8861 10.1785L26.4596 10.5117L24.7587 11.494Z"
        fill="#DBDBDB"
      />
      <path
        d="M24.9174 11.1629L24.3439 10.8292L25.983 9.88318L26.5569 10.2164L24.9174 11.1629Z"
        fill="#383739"
      />
      <path
        d="M30.5934 7.77948V7.84905C30.6181 7.84356 30.6419 7.84264 30.6639 7.84539C28.8971 8.86561 26.6841 10.1431 24.9174 11.1633C24.9394 11.1354 24.9632 11.1093 24.9874 11.0859V11.0163C26.7107 10.0213 28.8701 8.77452 30.5934 7.77948Z"
        fill="#C5C5C5"
      />
      <path
        d="M30.6637 7.84637C30.7383 7.85461 30.7964 7.90725 30.8234 7.99467L29.1331 8.97049C29.0658 8.95539 29.0333 8.86568 29.0352 8.78649L30.6637 7.84637Z"
        fill="#323133"
      />
      <path
        d="M29.0279 8.68401L28.4544 8.35034L30.0197 7.44684L30.5932 7.78005L29.0279 8.68401Z"
        fill="#DBDBDB"
      />
      <path
        d="M28.7163 8.86312L28.1423 8.52946L28.4549 8.34912L29.0284 8.68279L28.7163 8.86312Z"
        fill="#DBDBDB"
      />
      <path
        d="M28.8721 6.42063L28.2986 6.08742L30.0132 5.09741L30.5867 5.43062L28.8721 6.42063Z"
        fill="#383739"
      />
      <path
        d="M28.8654 6.31919L28.2914 5.98598L30.0129 4.99231L30.5868 5.32552L28.8654 6.31919Z"
        fill="#DBDBDB"
      />
      <path
        d="M28.8267 5.76389L28.2532 5.43068L30.0113 4.41595L30.5848 4.74916L28.8267 5.76389Z"
        fill="#383739"
      />
      <path
        d="M28.82 5.66315L28.2465 5.32994L29.9977 4.31842L30.5716 4.65209L28.82 5.66315Z"
        fill="#DBDBDB"
      />
      <path
        d="M30.3657 4.60386L29.7916 4.27028C29.8021 4.26432 29.8122 4.25928 29.8223 4.2547L30.3964 4.58828C30.3864 4.5924 30.3758 4.59744 30.3657 4.60386Z"
        fill="#343335"
      />
      <path
        d="M30.3957 4.58917L29.8216 4.25559C29.8303 4.25193 29.839 4.24918 29.8473 4.24689L30.4214 4.58047C30.4131 4.58276 30.4044 4.58551 30.3957 4.58917Z"
        fill="#333233"
      />
      <path
        d="M30.4216 4.57983L29.8474 4.24625C29.8538 4.24442 29.8602 4.24304 29.8667 4.24213L30.4408 4.57571C30.4344 4.57662 30.428 4.578 30.4216 4.57983Z"
        fill="#313132"
      />
      <path
        d="M30.4411 4.57565L29.867 4.24207C29.8725 4.24115 29.878 4.24069 29.8835 4.24023L30.4576 4.57381C30.4521 4.57381 30.4466 4.57473 30.4411 4.57565Z"
        fill="#302F31"
      />
      <path
        d="M30.4579 4.57421L29.8837 4.24063C29.9007 4.22689 30.4583 4.57421 30.4725 4.57467C30.468 4.57467 30.4629 4.57467 30.4583 4.57467L30.4579 4.57421Z"
        fill="#2F2E30"
      />
      <path
        d="M30.4717 4.57396L29.8976 4.24038C29.9127 4.23213 30.4722 4.57396 30.485 4.57579C30.4809 4.57488 30.4763 4.57442 30.4717 4.57442V4.57396Z"
        fill="#2D2D2E"
      />
      <path
        d="M30.4841 4.57573L29.91 4.24215C29.9256 4.23894 30.4832 4.57527 30.4965 4.57894C30.4924 4.57756 30.4883 4.57665 30.4841 4.57619V4.57573Z"
        fill="#2C2C2D"
      />
      <path
        d="M30.4966 4.5776L29.9224 4.24402C29.9371 4.24493 30.4952 4.57668 30.5085 4.58172C30.5048 4.58035 30.5007 4.57897 30.497 4.5776H30.4966Z"
        fill="#2B2A2C"
      />
      <path
        d="M30.5095 4.58242L29.9353 4.24884C29.9482 4.25342 30.5086 4.58196 30.5205 4.58792C30.5168 4.58609 30.5131 4.58425 30.5095 4.58242Z"
        fill="#2A292A"
      />
      <path
        d="M30.52 4.5881L29.9459 4.25452C29.9459 4.25452 30.5205 4.58855 30.5214 4.58901H30.52V4.5881Z"
        fill="#282829"
      />
      <path
        d="M28.7779 9.22226C28.8214 9.30373 28.8905 9.35545 28.9761 9.37147C29.0095 9.76921 27.056 10.6791 26.8148 10.8663C26.7237 10.8311 26.6583 10.7862 26.6116 10.7418C26.7004 10.6251 26.7713 10.4906 26.8153 10.3551L28.7779 9.2218V9.22226Z"
        fill="#323133"
      />
      <path
        d="M28.7278 9.03063C28.7319 9.08876 28.7433 9.14048 28.7612 9.18533L26.8329 10.2985C26.8576 10.2106 26.8658 10.1241 26.8695 10.0367L28.7232 8.96655L28.7278 9.03063Z"
        fill="#323133"
      />
      <path
        d="M26.8759 9.92669L26.3019 9.59349L28.1423 8.5307L28.7163 8.86437L26.8759 9.92669Z"
        fill="#DBDBDB"
      />
      <path
        d="M29.0282 8.68327L28.4547 8.34961L28.2986 6.08765L28.8721 6.42085L29.0282 8.68327Z"
        fill="#222122"
      />
      <path
        d="M28.7161 8.86492L28.1421 8.53125L27.9861 6.26929L28.5596 6.60249L28.7161 8.86492Z"
        fill="#7E7E7E"
      />
      <path
        d="M28.5596 6.60119L27.9861 6.26798L28.2987 6.08765L28.8722 6.42085L28.5596 6.60119Z"
        fill="#CFD0D0"
      />
      <path
        d="M27.0188 7.49272L26.4448 7.15906L27.9864 6.26929L28.5599 6.60249L27.0188 7.49272Z"
        fill="#383739"
      />
      <path
        d="M26.8759 9.9273L26.3019 9.59409L26.4447 7.15912L27.0187 7.49278L26.8759 9.9273Z"
        fill="#242324"
      />
      <path
        d="M26.5624 10.1067L25.9889 9.77299L26.301 9.59265L26.875 9.92586L26.5624 10.1067Z"
        fill="#DBDBDB"
      />
      <path
        d="M26.5624 10.1065L25.9889 9.77287L26.1312 7.33789L26.7052 7.67156L26.5624 10.1065Z"
        fill="#858585"
      />
      <path
        d="M26.7057 7.67312L26.1318 7.33945L26.4444 7.15912L27.0183 7.49278L26.7057 7.67312Z"
        fill="#CFD0D0"
      />
      <path
        d="M24.981 8.66752L24.407 8.33431L26.1321 7.33789L26.7061 7.67156L24.981 8.66752Z"
        fill="#383739"
      />
      <path
        d="M30.5864 5.32587V5.43205C28.8586 6.42984 26.7083 7.67113 24.9805 8.66892V8.56274C26.7083 7.56495 28.8586 6.3232 30.5864 5.32587Z"
        fill="#C5C5C5"
      />
      <path
        d="M29.0277 8.68438L28.8716 6.42196L30.5862 5.43195L30.5931 7.78041L29.0277 8.68438Z"
        fill="#323133"
      />
      <path
        d="M28.5534 6.50033L27.9799 6.16666L28.292 5.98633L28.866 6.31954L28.5534 6.50033Z"
        fill="#DBDBDB"
      />
      <path
        d="M28.8652 6.31897L28.2912 5.98577L28.2532 5.43195L28.8267 5.76515L28.8652 6.31897Z"
        fill="#222122"
      />
      <path
        d="M28.5146 5.94595L27.9407 5.61228L28.2533 5.43195L28.8268 5.76515L28.5146 5.94595Z"
        fill="#CFD0D0"
      />
      <path
        d="M28.5526 6.50009L27.9791 6.16643L27.9407 5.61261L28.5146 5.94627L28.5526 6.50009Z"
        fill="#7E7E7E"
      />
      <path
        d="M27.025 7.37978L26.451 7.04657L27.9793 6.16412L28.5528 6.49779L27.025 7.37978Z"
        fill="#DBDBDB"
      />
      <path
        d="M26.7124 7.56182L26.1385 7.22861L26.4511 7.04828L27.025 7.38149L26.7124 7.56182Z"
        fill="#DBDBDB"
      />
      <path
        d="M27.0599 6.78616L26.4864 6.45249L27.941 5.61261L28.5149 5.94627L27.0599 6.78616Z"
        fill="#383739"
      />
      <path
        d="M27.025 7.38046L26.451 7.04725L26.4863 6.45087L27.0598 6.78453L27.025 7.38046Z"
        fill="#242324"
      />
      <path
        d="M26.7124 7.56256L26.1385 7.22935L26.1737 6.63342L26.7472 6.96663L26.7124 7.56256Z"
        fill="#858585"
      />
      <path
        d="M26.7469 6.96487L26.1734 6.63166L26.486 6.45087L27.0595 6.78453L26.7469 6.96487Z"
        fill="#CFD0D0"
      />
      <path
        d="M24.9805 8.56217L24.407 8.2285L26.1385 7.22888L26.7125 7.56209L24.9805 8.56217Z"
        fill="#DBDBDB"
      />
      <path
        d="M24.9786 7.98776L24.4051 7.6541L26.1737 6.63342L26.7472 6.96663L24.9786 7.98776Z"
        fill="#383739"
      />
      <path
        d="M24.9872 11.0164L24.4137 10.6832L25.9896 9.77332L26.5631 10.107L24.9872 11.0164Z"
        fill="#DBDBDB"
      />
      <path
        d="M24.9869 11.0159L24.9805 8.66741L26.7056 7.67145L26.5628 10.1064L24.9869 11.0159Z"
        fill="#323133"
      />
      <path
        d="M24.9793 7.96857L24.4052 7.63499C24.4052 7.62079 24.4061 7.60658 24.4079 7.59192L24.9821 7.9255C24.9802 7.94016 24.9793 7.95482 24.9793 7.96857Z"
        fill="#858585"
      />
      <path
        d="M24.9827 7.92419L24.4085 7.59061C24.4099 7.58053 24.4117 7.57044 24.4136 7.56036L24.9877 7.89394C24.9854 7.90402 24.9836 7.9141 24.9827 7.92419Z"
        fill="#8A8A8A"
      />
      <path
        d="M24.9878 7.89421L24.4137 7.56063C24.4151 7.55375 24.4169 7.54734 24.4187 7.54047L24.9929 7.87405C24.991 7.88092 24.9897 7.88733 24.9878 7.89421Z"
        fill="#8F8F8F"
      />
      <path
        d="M30.5709 4.65054C30.5819 4.67617 30.5855 4.71599 30.5842 4.74895C28.8577 5.74582 26.7042 6.98894 24.9778 7.98582C24.9769 7.94783 24.9801 7.91075 24.9906 7.87185C26.7093 6.87955 28.8522 5.64238 30.5704 4.65009L30.5709 4.65054Z"
        fill="#C5C5C5"
      />
      <path
        d="M28.8272 5.76521L30.5852 4.75049L30.587 5.32536L28.8656 6.31903L28.8272 5.76521Z"
        fill="#323133"
      />
      <path
        d="M28.809 5.50511L28.2355 5.17145L29.7922 4.27252L30.3661 4.60619L28.809 5.50511Z"
        fill="#383739"
      />
      <path
        d="M28.8207 5.66262L28.2465 5.32904L28.2355 5.17004L28.8097 5.50408L28.8207 5.66262Z"
        fill="#222122"
      />
      <path
        d="M28.5065 5.84325L27.933 5.51005L28.2456 5.32971L28.8191 5.66292L28.5065 5.84325Z"
        fill="#DBDBDB"
      />
      <path
        d="M28.7868 5.1729L28.2126 4.83932L28.1916 4.53827L28.7657 4.87185L28.7868 5.1729Z"
        fill="#787879"
      />
      <path
        d="M29.2534 4.11682C29.2772 3.95937 28.647 3.70855 28.5486 3.61975C28.9358 3.81244 28.3106 4.31271 28.1916 4.53699L28.7651 4.87019C28.9275 4.62624 29.1912 4.4102 29.253 4.11682H29.2534Z"
        fill="#787879"
      />
      <path
        d="M27.0661 6.6766L26.4926 6.34339L27.9339 5.51129L28.5074 5.8445L27.0661 6.6766Z"
        fill="#DBDBDB"
      />
      <path
        d="M28.5077 5.84381L27.9335 5.51023L27.9225 5.35168L28.4967 5.68526L28.5077 5.84381Z"
        fill="#7E7E7E"
      />
      <path
        d="M27.1521 6.46116L26.5782 6.12796L27.8103 5.41669L28.3838 5.75035L27.1521 6.46116Z"
        fill="#383739"
      />
      <path
        d="M28.6958 3.85502L28.1216 3.52144L28.118 3.46967L28.6926 3.80325L28.6958 3.85502Z"
        fill="#787879"
      />
      <path
        d="M27.0192 7.4921L28.5603 6.60187L28.7168 8.86429L26.8764 9.92662L27.0192 7.4921Z"
        fill="#323133"
      />
      <path
        d="M27.0668 6.67533L26.4926 6.34175L26.5027 6.17084L27.0768 6.50488L27.0668 6.67533Z"
        fill="#242324"
      />
      <path
        d="M27.0777 6.50486L26.5036 6.17082L26.5797 6.12683L27.1543 6.46041L27.0777 6.50486Z"
        fill="#383739"
      />
      <path
        d="M28.7489 3.59143L28.1748 3.25785C28.2192 3.2519 28.2628 3.24823 28.3049 3.2464L28.879 3.57998C28.8369 3.58181 28.7934 3.58548 28.7489 3.59143Z"
        fill="#AAABAC"
      />
      <path
        d="M28.8789 3.57865L28.3048 3.24507C28.4143 3.18275 28.9197 3.54016 29.0118 3.58002C28.9687 3.57727 28.9247 3.57681 28.8789 3.57911V3.57865Z"
        fill="#A6A6A7"
      />
      <path
        d="M29.0113 3.57883L28.4371 3.24525C28.5214 3.20859 29.0488 3.55775 29.1235 3.59211C29.0873 3.5857 29.0502 3.58158 29.0117 3.57883H29.0113Z"
        fill="#A1A2A2"
      />
      <path
        d="M29.1241 3.59241L28.5499 3.25883C28.615 3.23729 29.1598 3.58645 29.2217 3.61486C29.1901 3.6057 29.1575 3.59837 29.1241 3.59241Z"
        fill="#9C9D9E"
      />
      <path
        d="M29.2206 3.61543L28.6465 3.28185C28.6946 3.27452 29.2555 3.62047 29.3081 3.64659C29.2797 3.63468 29.2509 3.6246 29.2206 3.61589V3.61543Z"
        fill="#989899"
      />
      <path
        d="M29.309 3.64498L28.7349 3.3114C28.7693 3.31736 29.342 3.65919 29.3892 3.68439C29.3636 3.66972 29.337 3.65689 29.309 3.64544V3.64498Z"
        fill="#939494"
      />
      <path
        d="M29.3884 3.68417L28.8142 3.35059C28.8252 3.357 29.3861 3.68279 29.3971 3.68921C29.3943 3.68737 29.3916 3.686 29.3884 3.68417Z"
        fill="#8F8F90"
      />
      <path
        d="M28.6936 3.80685L28.1195 3.47327C28.1195 3.46136 28.1209 3.44899 28.1222 3.43616L28.6964 3.76974C28.6945 3.78257 28.6936 3.79494 28.6936 3.80685Z"
        fill="#787879"
      />
      <path
        d="M28.6965 3.76916L28.1223 3.43558C28.1237 3.42367 28.126 3.4113 28.1288 3.39893L28.7029 3.73251C28.7002 3.74534 28.6979 3.75725 28.6965 3.76916Z"
        fill="#7C7D7D"
      />
      <path
        d="M28.7037 3.73382L28.1295 3.40024C28.1318 3.38879 28.135 3.37687 28.1382 3.3645L28.7124 3.69808C28.7092 3.71045 28.7059 3.72237 28.7037 3.73382Z"
        fill="#818182"
      />
      <path
        d="M28.7113 3.69739L28.1371 3.36381C28.1408 3.35144 28.1445 3.33861 28.1491 3.32532L28.7232 3.6589C28.7186 3.67219 28.715 3.68502 28.7113 3.69739Z"
        fill="#858687"
      />
      <path
        d="M28.7242 3.65796L28.1501 3.32438C28.1546 3.31018 28.1601 3.29552 28.1661 3.2804L28.7402 3.61398C28.7343 3.6291 28.7292 3.64376 28.7242 3.65796Z"
        fill="#8A8B8B"
      />
      <path
        d="M28.7395 3.61439L28.1653 3.28082C28.1681 3.27303 28.1713 3.26524 28.1745 3.25745L28.7487 3.59103C28.7454 3.59882 28.7422 3.60661 28.7395 3.61439Z"
        fill="#8F8F90"
      />
      <path
        d="M26.7531 6.85592L26.1796 6.52225L26.4922 6.34192L27.0657 6.67513L26.7531 6.85592Z"
        fill="#DBDBDB"
      />
      <path
        d="M27.0777 6.5051L26.5036 6.17106L26.5261 5.78845L27.1002 6.12249L27.0777 6.5051Z"
        fill="#7F7F80"
      />
      <path
        d="M28.4691 5.28872L27.8952 4.95552L27.8064 3.66846L28.3803 4.00212L28.4691 5.28872Z"
        fill="#7E7E7E"
      />
      <path
        d="M29.2605 2.01028C29.3016 1.5091 28.6215 1.2926 28.2979 1.07245C28.7039 1.3164 28.8018 1.67845 28.5226 2.08901C28.2736 2.60667 27.8393 3.07581 27.8054 3.66991L28.3789 4.00312C28.4219 3.25386 29.0842 2.71697 29.26 2.00982L29.2605 2.01028Z"
        fill="#7D7E7E"
      />
      <path
        d="M27.1777 4.5906L26.6035 4.25702C26.593 4.18553 26.5706 4.12551 26.5385 4.07373L27.1126 4.40731C27.1447 4.45909 27.1672 4.51911 27.1777 4.5906Z"
        fill="#787879"
      />
      <path
        d="M26.7537 6.85691L26.1796 6.52287L26.1896 6.35242L26.7638 6.686L26.7537 6.85691Z"
        fill="#858585"
      />
      <path
        d="M24.992 7.87418L24.418 7.54097L26.1802 6.5235L26.7537 6.85716L24.992 7.87418Z"
        fill="#DBDBDB"
      />
      <path
        d="M25.197 7.5905L24.623 7.25729L26.1906 6.35242L26.7641 6.68562L25.197 7.5905Z"
        fill="#383739"
      />
      <path
        d="M26.8733 4.859L26.2992 4.52542C26.2919 4.371 26.1718 4.2954 26.0421 4.21704C26.241 4.37512 26.855 4.5662 26.8733 4.859Z"
        fill="#7D7E7E"
      />
      <path
        d="M27.8745 3.8817C28.0576 3.79062 28.2471 3.71098 28.443 3.65376C28.2516 3.54254 28.0603 3.43178 27.8695 3.32056C27.3774 3.4597 26.917 3.74851 26.538 4.07485L27.1115 4.40806C27.3408 4.21994 27.5962 4.02038 27.8749 3.8817H27.8745Z"
        fill="#B8B9BA"
      />
      <path
        d="M26.6046 4.03835L26.0305 3.70477C26.0424 3.71165 26.0547 3.71852 26.0667 3.72539L26.6408 4.05897C26.6289 4.0521 26.6165 4.04523 26.6046 4.03835Z"
        fill="#959696"
      />
      <path
        d="M26.6414 4.05849L26.0672 3.72491C26.1795 3.78357 26.6844 4.07911 26.8027 4.14785C26.7495 4.11806 26.695 4.08919 26.6414 4.05895V4.05849Z"
        fill="#9A9B9B"
      />
      <path
        d="M26.8024 4.14834L26.2283 3.81476C26.2388 3.82072 26.2494 3.82667 26.2599 3.83309L26.8341 4.16667C26.8235 4.16071 26.813 4.15429 26.8024 4.14834Z"
        fill="#959696"
      />
      <path
        d="M26.7843 6.36342L26.2097 6.02938L26.2294 5.69672L26.8035 6.03075L26.7843 6.36342Z"
        fill="#858585"
      />
      <path
        d="M26.3327 5.93856L25.7585 5.60498C25.7585 5.60498 26.3331 5.93902 26.3341 5.93948L26.3327 5.93856Z"
        fill="#8F8F90"
      />
      <path
        d="M26.3346 5.93954L25.7605 5.60596C25.7728 5.61008 26.3351 5.93954 26.3474 5.94595C26.3429 5.94412 26.3387 5.94183 26.3346 5.93954Z"
        fill="#939494"
      />
      <path
        d="M26.347 5.94604L25.7729 5.61246C25.7697 5.59047 26.3855 5.95704 26.3979 5.96254C26.381 5.9575 26.364 5.95246 26.3475 5.94604H26.347Z"
        fill="#989899"
      />
      <path
        d="M26.3977 5.96164L25.8235 5.62806C25.928 5.61615 26.4426 5.9424 26.5356 5.9928C26.4884 5.98456 26.4426 5.97493 26.3981 5.96164H26.3977Z"
        fill="#9C9D9E"
      />
      <path
        d="M26.5358 5.99317L25.9617 5.65959C26.1999 5.63714 26.6137 5.91985 26.8062 6.03028C26.7168 6.01791 26.6252 6.00875 26.5363 5.99317H26.5358Z"
        fill="#A1A2A2"
      />
      <path
        d="M26.8023 6.02986L26.2288 5.6962L26.2956 4.55835L26.8691 4.89201L26.8023 6.02986Z"
        fill="#858585"
      />
      <path
        d="M26.8699 4.89135L26.2957 4.55732L26.2975 4.52203L26.8717 4.85561L26.8699 4.89135Z"
        fill="#858585"
      />
      <path
        d="M30.3666 4.60531L28.8095 5.50423L28.8205 5.6626L30.5721 4.65153C30.541 4.57327 30.4609 4.5513 30.3666 4.60531Z"
        fill="#323133"
      />
      <path
        d="M28.3848 5.75033L27.8107 5.41629L27.9229 5.35168L28.4971 5.68526L28.3848 5.75033Z"
        fill="#383739"
      />
      <path
        d="M28.4964 5.68541L27.9223 5.35183L27.8948 4.95593L28.4694 5.28951L28.4964 5.68541Z"
        fill="#7E7E7E"
      />
      <path
        d="M28.3855 5.75069L27.8114 5.41666L27.7939 5.26086L28.3681 5.59444L28.3855 5.75069Z"
        fill="#767777"
      />
      <path
        d="M28.3681 5.59409L27.7939 5.26051L27.7935 5.25226L28.3676 5.5863L28.3681 5.59409Z"
        fill="#787979"
      />
      <path
        d="M28.3679 5.58673L27.7938 5.25315C27.7938 5.23987 27.7947 5.22612 27.7965 5.21191L28.3707 5.54549C28.3688 5.5597 28.3679 5.57299 28.3679 5.58673Z"
        fill="#787879"
      />
      <path
        d="M28.3698 5.5451L27.7957 5.21152C27.7971 5.20144 27.7989 5.1909 27.8012 5.18036L28.3753 5.51394C28.373 5.52448 28.3712 5.53502 28.3698 5.5451Z"
        fill="#7C7D7D"
      />
      <path
        d="M28.376 5.51567L27.8019 5.18209C27.8037 5.17293 27.806 5.16377 27.8088 5.1546L28.3829 5.48818C28.3801 5.49735 28.3779 5.50651 28.376 5.51567Z"
        fill="#818182"
      />
      <path
        d="M28.3818 5.48705L27.8076 5.15347C27.8099 5.14476 27.8127 5.1356 27.8159 5.12689L28.39 5.46047C28.3868 5.46964 28.3845 5.47834 28.3818 5.48705Z"
        fill="#858687"
      />
      <path
        d="M28.3909 5.46019L27.8167 5.12661C27.8199 5.11745 27.8231 5.10829 27.8268 5.09912L28.4009 5.4327C28.3973 5.44187 28.3941 5.45103 28.3909 5.46019Z"
        fill="#8A8B8B"
      />
      <path
        d="M28.4009 5.43288L27.8268 5.0993C27.8304 5.08968 27.8345 5.0796 27.8387 5.06952L28.4128 5.4031C28.4087 5.41318 28.4046 5.4228 28.4009 5.43288Z"
        fill="#8F8F90"
      />
      <path
        d="M28.4128 5.40453L27.8387 5.07096C27.8433 5.05996 27.8483 5.04896 27.8538 5.03796L28.4279 5.37154C28.4224 5.38254 28.4174 5.39354 28.4128 5.40453Z"
        fill="#939494"
      />
      <path
        d="M28.4272 5.37033L27.853 5.03675C27.859 5.02392 27.8659 5.01109 27.8727 4.9978L28.4469 5.33138C28.44 5.34467 28.4331 5.3575 28.4272 5.37033Z"
        fill="#989899"
      />
      <path
        d="M28.4477 5.33154L27.8736 4.99797C27.8809 4.98422 27.8887 4.97047 27.8965 4.95673L28.4706 5.29031C28.4624 5.30405 28.455 5.3178 28.4477 5.33154Z"
        fill="#9C9D9E"
      />
      <path
        d="M25.0846 11.8636C24.8566 12.0064 24.7019 11.7849 24.7491 11.5464L26.378 10.6058C26.3597 10.6228 25.9876 10.9253 25.9693 10.9409L26.0343 11.2549L26.0677 11.2274C26.0961 11.3285 25.1445 11.8142 25.0846 11.8636Z"
        fill="#323133"
      />
      <path
        d="M24.8522 11.8871L24.2781 11.5535C24.1906 11.5122 24.1516 11.3477 24.1736 11.2254L24.7477 11.559C24.7257 11.6813 24.7647 11.8458 24.8522 11.8871Z"
        fill="#222122"
      />
      <path
        d="M24.7485 11.558L24.1744 11.2245C24.1744 11.2213 24.1753 11.2181 24.1758 11.2148L24.7499 11.5484C24.7494 11.5516 24.749 11.5548 24.7485 11.558Z"
        fill="#232324"
      />
      <path
        d="M24.7503 11.5479L24.1762 11.2138L24.1858 11.1611L24.7604 11.4947L24.7503 11.5479Z"
        fill="#979797"
      />
      <path
        d="M24.7593 11.4947L24.7492 11.5478C24.7519 11.53 24.7551 11.5126 24.7593 11.4947Z"
        fill="#323133"
      />
      <path
        d="M24.7509 11.5525L24.1767 11.2189C24.179 11.2047 24.1813 11.1905 24.1845 11.1763L24.7587 11.5098C24.7555 11.5241 24.7532 11.5383 24.7509 11.5525Z"
        fill="#232324"
      />
      <path
        d="M24.7585 11.5099L24.1844 11.1763C24.1853 11.1726 24.1858 11.1694 24.1867 11.1658L24.7608 11.4994C24.7599 11.503 24.7594 11.5062 24.7585 11.5099Z"
        fill="#242425"
      />
      <path
        d="M24.9179 11.169L24.3438 10.8354C24.3511 10.8258 24.3589 10.8167 24.3667 10.8075L24.9408 11.1411C24.933 11.1502 24.9253 11.1594 24.9179 11.169Z"
        fill="#C2C2C2"
      />
      <path
        d="M24.9413 11.1399L24.3672 10.8063C24.3791 10.7926 24.391 10.7798 24.4034 10.7674L24.9775 11.101C24.9652 11.1133 24.9528 11.1262 24.9413 11.1399Z"
        fill="#C7C7C7"
      />
      <path
        d="M24.9772 11.1019L24.403 10.7684C24.4067 10.7647 24.4104 10.7615 24.4136 10.7578L24.9877 11.0914C24.9841 11.0946 24.9804 11.0983 24.9772 11.1019Z"
        fill="#CCCCCC"
      />
      <path
        d="M24.9883 11.0865L24.4137 10.7529V10.6832L24.9878 11.0168L24.9883 11.0865Z"
        fill="#888888"
      />
      <path
        d="M24.7594 11.4964L24.1852 11.1628C24.188 11.15 24.1912 11.1376 24.1948 11.1248L24.769 11.4584C24.7653 11.4712 24.7621 11.4836 24.7594 11.4964Z"
        fill="#242425"
      />
      <path
        d="M24.7694 11.4582L24.1953 11.1246C24.1989 11.1118 24.203 11.0985 24.2072 11.0856L24.7813 11.4192C24.7772 11.432 24.7731 11.4453 24.7694 11.4582Z"
        fill="#262526"
      />
      <path
        d="M24.7809 11.4205L24.2067 11.0869C24.2108 11.075 24.215 11.0631 24.2196 11.0512L24.7937 11.3848C24.7891 11.3967 24.785 11.4086 24.7809 11.4205Z"
        fill="#272628"
      />
      <path
        d="M24.7933 11.3848L24.2191 11.0512C24.2233 11.0402 24.2279 11.0288 24.2329 11.0178L24.807 11.3513C24.8025 11.3623 24.7979 11.3733 24.7933 11.3848Z"
        fill="#282829"
      />
      <path
        d="M24.8076 11.3495L24.2335 11.0159C24.2381 11.0054 24.2431 10.9949 24.2482 10.9843L24.8223 11.3179C24.8173 11.3284 24.8122 11.339 24.8076 11.3495Z"
        fill="#2A292A"
      />
      <path
        d="M24.8219 11.3189L24.2478 10.9854C24.2528 10.9753 24.2579 10.9647 24.2634 10.9547L24.8375 11.2882C24.832 11.2983 24.827 11.3084 24.8219 11.3189Z"
        fill="#2B2A2C"
      />
      <path
        d="M24.8373 11.2893L24.2631 10.9557C24.2686 10.9457 24.2741 10.9356 24.2796 10.926L24.8538 11.2595C24.8483 11.2692 24.8423 11.2792 24.8373 11.2893Z"
        fill="#2C2C2D"
      />
      <path
        d="M24.8535 11.2593L24.2794 10.9257C24.2853 10.9156 24.2913 10.906 24.2972 10.8964L24.8714 11.2299C24.8654 11.2396 24.859 11.2496 24.8535 11.2593Z"
        fill="#2D2D2E"
      />
      <path
        d="M24.8712 11.2301L24.297 10.8965C24.3034 10.8864 24.3099 10.8768 24.3167 10.8667L24.8909 11.2003C24.884 11.2099 24.8776 11.22 24.8712 11.2301Z"
        fill="#2F2E30"
      />
      <path
        d="M24.8922 11.2009L24.3181 10.8673C24.3254 10.8568 24.3327 10.8467 24.3405 10.8371L24.9147 11.1707C24.9069 11.1808 24.8996 11.1908 24.8922 11.2009Z"
        fill="#302F31"
      />
      <path
        d="M24.9147 11.1695L24.3405 10.8359C24.3405 10.8359 24.3428 10.8327 24.3442 10.8314L24.9184 11.1649L24.9147 11.1695Z"
        fill="#313132"
      />
      <path
        d="M24.7596 11.4961C24.6882 11.1121 26.351 10.3958 26.557 10.2178C26.556 10.3198 26.524 10.4215 26.4604 10.5139L24.7591 11.4961H24.7596Z"
        fill="#323133"
      />
      <path
        d="M28.5153 5.94617L28.5533 6.49999L27.0255 7.38198L27.0603 6.78605L28.5153 5.94617Z"
        fill="#323133"
      />
      <path
        d="M28.4969 5.6857L28.5079 5.84406L27.0666 6.67617L27.0766 6.50544C27.1906 6.43999 28.3934 5.7452 28.4969 5.68524V5.6857Z"
        fill="#323133"
      />
      <path
        d="M26.7642 6.68592L26.1901 6.35234L26.2089 6.02747L26.7835 6.3615L26.7642 6.68592Z"
        fill="#858585"
      />
      <path
        d="M24.9874 11.0165L24.4139 10.6832L24.407 8.33478L24.981 8.66798L24.9874 11.0165Z"
        fill="#232223"
      />
      <path
        d="M24.9816 8.66807L24.407 8.33449V8.22772L24.9812 8.56176L24.9816 8.66807Z"
        fill="#888888"
      />
      <path
        d="M24.9804 8.5618L24.4069 8.22813L24.4051 7.65326L24.9786 7.98692L24.9804 8.5618Z"
        fill="#232223"
      />
      <path
        d="M24.98 8.5611L24.9781 7.98622L26.7467 6.96509L26.7119 7.56102L24.98 8.5611Z"
        fill="#323133"
      />
      <path
        d="M24.9792 7.9879L24.4051 7.65386V7.63416L24.9792 7.96773V7.9879Z"
        fill="#888888"
      />
      <path
        d="M24.9915 7.8728C24.9846 7.56293 26.5719 6.84983 26.7632 6.68506L26.7532 6.85578L24.991 7.87325L24.9915 7.8728Z"
        fill="#323133"
      />
      <path
        d="M24.9934 7.87359L24.4192 7.54001L24.4201 7.53589L24.9943 7.86947L24.9934 7.87359Z"
        fill="#242425"
      />
      <path
        d="M24.9943 7.868L24.4202 7.53442C24.4225 7.52708 24.4243 7.51975 24.427 7.51196L25.0012 7.84554C24.9989 7.85287 24.9966 7.86021 24.9943 7.868Z"
        fill="#262526"
      />
      <path
        d="M25.001 7.84608L24.4268 7.5125C24.4291 7.50563 24.4314 7.49876 24.4342 7.49188L25.0083 7.82546C25.0056 7.83234 25.0033 7.83921 25.001 7.84608Z"
        fill="#272628"
      />
      <path
        d="M25.0077 7.82606L24.4336 7.49248C24.4363 7.48607 24.4386 7.47919 24.4418 7.47278L25.016 7.80636C25.0132 7.81277 25.0105 7.81919 25.0077 7.82606Z"
        fill="#282829"
      />
      <path
        d="M25.0168 7.80604L24.4426 7.47246C24.4454 7.46605 24.4481 7.45963 24.4513 7.45367L25.0255 7.78725C25.0223 7.79367 25.0195 7.79963 25.0168 7.80604Z"
        fill="#2A292A"
      />
      <path
        d="M25.0244 7.78694L24.4503 7.45336C24.4535 7.44694 24.4562 7.44099 24.4599 7.43457L25.0341 7.76815C25.0308 7.77411 25.0276 7.78052 25.0244 7.78694Z"
        fill="#2B2A2C"
      />
      <path
        d="M25.034 7.76927L24.4598 7.43569C24.463 7.42927 24.4667 7.42332 24.4699 7.41736L25.0441 7.75094C25.0404 7.75689 25.0372 7.76331 25.034 7.76927Z"
        fill="#2C2C2D"
      />
      <path
        d="M25.0445 7.75108L24.4704 7.4175C24.474 7.41108 24.4777 7.40513 24.4818 7.39917L25.056 7.73275C25.0523 7.73871 25.0482 7.74512 25.0445 7.75108Z"
        fill="#2D2D2E"
      />
      <path
        d="M25.056 7.73054L24.4818 7.39696C24.4859 7.39055 24.4901 7.38413 24.4946 7.37817L25.0688 7.71175C25.0647 7.71771 25.0601 7.72413 25.056 7.73054Z"
        fill="#2F2E30"
      />
      <path
        d="M25.0689 7.71235L24.4947 7.37877C24.4993 7.3719 24.5043 7.36548 24.5094 7.35907L25.0835 7.69265C25.0785 7.69906 25.0739 7.70548 25.0689 7.71235Z"
        fill="#302F31"
      />
      <path
        d="M25.0832 7.69215L24.5091 7.35857C24.5146 7.35124 24.5205 7.34391 24.5269 7.33704L25.1011 7.67062C25.0951 7.67749 25.0892 7.68482 25.0832 7.69215Z"
        fill="#313132"
      />
      <path
        d="M25.1004 7.67195L24.5263 7.33837C24.5341 7.32967 24.5419 7.32096 24.5501 7.31317L25.1242 7.64675C25.116 7.655 25.1082 7.66325 25.1004 7.67195Z"
        fill="#333233"
      />
      <path
        d="M25.1243 7.6462L24.5502 7.31262C24.5731 7.29016 24.5978 7.27092 24.6235 7.2558L25.1976 7.58938C25.172 7.60404 25.1472 7.62329 25.1243 7.6462Z"
        fill="#343335"
      />
      <path
        d="M26.0333 6.18527C25.7527 5.82048 26.5555 4.97328 26.8041 4.69179L26.2306 4.35858C25.9225 4.66936 25.6612 5.02591 25.4635 5.41679C25.393 5.55501 25.3312 5.777 25.4918 5.87586L26.0653 6.20907C26.0539 6.20221 26.0434 6.19442 26.0333 6.18573V6.18527Z"
        fill="#989899"
      />
      <path
        d="M27.9552 1.05972C28.3159 0.908683 28.7534 0.834077 29.1068 1.04187C28.9109 0.949418 28.4729 0.629027 28.2582 0.602938C27.1716 0.448234 26.3285 1.44374 25.8383 2.29552C25.6292 2.76146 25.2049 3.36288 25.5129 3.86407C25.6845 4.05767 26.1715 4.27691 26.3844 4.41926C25.7852 4.17073 25.9761 3.52903 26.1958 3.06949C26.5592 2.25387 27.13 1.44969 27.9552 1.05972Z"
        fill="#959696"
      />
      <path
        d="M29.0392 8.82456C29.0447 8.902 29.0805 8.95378 29.1345 8.97027L28.7615 9.18563C28.7354 9.12652 28.7272 9.03809 28.7235 8.96661L29.0365 8.78607L29.0392 8.82456Z"
        fill="#F1F2F2"
      />
      <path
        d="M29.2312 8.96485L29.6262 8.83197L29.5634 9.22008C29.3311 9.2801 28.9338 9.52937 28.779 9.22191C28.7968 9.21595 29.2111 8.96439 29.2317 8.96485H29.2312Z"
        fill="#F1F2F2"
      />
      <path
        d="M26.5545 10.2585L26.5568 10.2169L26.8698 10.0363C26.8661 10.1238 26.8583 10.2104 26.8331 10.2984L26.4601 10.5138C26.5138 10.4354 26.549 10.3424 26.5541 10.259L26.5545 10.2585Z"
        fill="#F1F2F2"
      />
      <path
        d="M28.8722 6.42139L29.0283 8.68381L28.7161 8.86414L28.5596 6.60172L28.8722 6.42139Z"
        fill="#F1F2F2"
      />
      <path
        d="M26.3639 10.6186C26.3771 10.5994 26.7987 10.3674 26.8161 10.3536C26.7145 10.7399 26.3309 11.0187 26.0348 11.2553L25.9698 10.9413L26.3639 10.6186Z"
        fill="#F1F2F2"
      />
      <path
        d="M26.5634 10.1072L26.7062 7.67222L27.0188 7.49188L26.876 9.9264L26.5634 10.1072Z"
        fill="#F1F2F2"
      />
      <path
        d="M29.4918 3.75442C29.788 4.16909 28.9714 4.81079 28.7865 5.171L28.7659 4.87029C29.1097 4.42129 29.6648 3.89447 28.6959 3.85511C28.6849 3.76448 28.705 3.694 28.7472 3.58781C29.0529 3.54753 29.3046 3.60154 29.4918 3.75442Z"
        fill="#F1F2F2"
      />
      <path
        d="M28.443 3.65332C28.4137 3.73937 28.3931 3.82313 28.3844 3.90368C27.9876 3.9966 27.4905 4.30417 27.1765 4.59069C27.166 4.51929 27.1436 4.45933 27.1115 4.40761C27.4694 4.09134 27.999 3.77049 28.443 3.65332Z"
        fill="#F1F2F2"
      />
      <path
        d="M29.5022 1.44924C28.3506 -0.0950433 26.4493 2.03419 26.0484 3.44345C25.9303 3.77803 25.9573 4.08744 26.118 4.23161C26.3482 4.48976 26.8567 4.51585 26.8681 4.89116C26.8375 5.4134 26.783 6.34345 26.7528 6.85608L27.0654 6.67574C27.0704 6.57322 27.0924 6.23269 27.0979 6.12284L27.1821 4.68108C27.1917 4.38952 26.9688 4.23024 26.7244 4.10666C26.4663 3.96569 26.1715 3.83296 26.3262 3.44666C26.6365 2.56649 27.1093 1.92892 27.7726 1.4973C28.4358 1.16318 28.9113 1.25335 29.2253 1.7733C29.4011 2.33444 28.5648 3.11391 28.4426 3.65491C28.3081 3.88651 28.4706 5.02756 28.4687 5.29028C28.476 5.3983 28.4998 5.74066 28.5072 5.8441L28.8198 5.66376C28.8042 5.4372 28.7809 5.09942 28.7653 4.87194L28.6953 3.85676L28.6916 3.80504C28.7992 3.09789 29.8588 2.20811 29.5031 1.4497L29.5022 1.44924Z"
        fill="#F1F2F2"
      />
      <path
        d="M28.8276 5.76477L28.8661 6.31921L28.5532 6.5002L28.5151 5.94577L28.8276 5.76477Z"
        fill="#F1F2F2"
      />
      <path
        d="M28.3665 5.58568C28.3665 5.49954 28.4059 5.39919 28.4696 5.28876L28.4971 5.68465L28.3848 5.74972L28.3674 5.59393V5.58568H28.3665Z"
        fill="#F1F2F2"
      />
      <path
        d="M26.713 7.56174L26.7478 6.96514L27.0607 6.78461L27.0259 7.3812L26.713 7.56174Z"
        fill="#F1F2F2"
      />
      <path
        d="M27.0766 6.5048L27.0991 6.12219C27.1999 6.18313 27.1614 6.36184 27.1532 6.46081L27.0766 6.5048Z"
        fill="#F1F2F2"
      />
      <path
        d="M26.0383 5.75053C26.2329 5.36514 26.4892 5.0118 26.8054 4.69232C26.8535 4.74633 26.8787 4.81773 26.8695 4.89188C26.6466 5.13492 26.4631 5.40084 26.3162 5.6924C26.1422 6.04346 26.6004 5.97984 26.8027 6.02973L26.783 6.36202C26.4612 6.24393 25.7198 6.37072 26.0379 5.75053H26.0383Z"
        fill="#F1F2F2"
      />
      <mask
        id="mask0_4613_145507"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="21"
        y="11"
        width="14"
        height="19"
      >
        <path
          d="M34.5196 24.7613C34.6667 27.3423 31.8158 29.5747 28.152 29.7476C24.4881 29.9205 21.3987 27.9684 21.2516 25.3874C21.1045 22.8064 21.1059 11.9839 26.8667 11.9839C36.0045 11.5527 34.3725 22.1804 34.5196 24.7613Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4613_145507)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0042 18.7773C26.0042 18.7773 26.4687 19.6756 26.4282 19.9843C26.4282 19.9843 26.8461 19.1189 26.7973 19.1298C26.7484 19.1402 26.0042 18.7773 26.0042 18.7773Z"
          fill="#212220"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.065 20.1085C26.065 20.1085 26.5132 19.7644 27.1278 19.6805L26.2835 21.4782L26.0511 20.8066L26.065 20.1085Z"
          fill="#232422"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6342 29.2024C27.5047 27.9636 25.7514 21.1449 25.7514 21.1449C26.3855 20.7013 26.5627 19.1303 26.5627 19.1303C27.8833 18.8881 28.7586 20.0109 29.3688 20.509C29.7649 20.8347 29.9811 21.1232 29.9811 21.1232C30.6175 22.8125 30.6342 29.2024 30.6342 29.2024Z"
          fill="#E1AB7B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.3875 20.9546C32.3875 20.9546 33.1046 22.5398 33.2877 24.7316C33.4705 26.9234 35.1256 32.8961 35.1256 32.8961L32.9361 33.0454L31.6751 29.0241L32.3875 20.9546Z"
          fill="#232422"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6354 29.2017V29.8969L30.4674 30.1373L27.285 23.5984C27.7125 24.1425 28.2409 24.735 28.6688 25.1764C30.4388 27.0121 30.6354 29.2017 30.6354 29.2017Z"
          fill="#3E4053"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9431 28.5343L24.9359 28.5104C24.9335 28.2151 24.9141 27.9287 24.8688 27.6385C24.8231 27.3496 24.7702 27.0615 24.6979 26.7752C24.6165 26.4911 24.5251 26.2091 24.4099 25.9336C24.3042 25.6737 24.1794 25.4156 24.0472 25.1644L24.0257 25.1228L24.0352 25.1163L24.0591 25.1578L24.0638 25.1644C24.2081 25.4091 24.3451 25.6585 24.4651 25.9141C24.5922 26.1874 24.6979 26.4668 24.7893 26.7532C24.8783 27.0373 24.955 27.3279 24.9864 27.6255L24.9887 27.6753C25.0126 27.9573 25.0054 28.257 24.9431 28.5343Z"
          fill="#24222D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.7224 33.4045C32.5857 33.8315 32.1932 34.2353 31.5789 34.5613C31.5281 34.592 31.4685 34.6156 31.4085 34.6387C31.1101 34.7862 30.7772 34.9026 30.4276 34.9883C28.8749 35.3761 26.964 35.2366 25.6759 34.5613C25.1725 34.2895 24.8142 33.9714 24.6267 33.6297C24.55 33.4822 24.4987 33.3423 24.4733 33.1952C24.4308 32.9469 24.4391 32.6208 24.5158 32.3337C24.6183 31.4332 24.2 26.4966 23.5091 25.4717C23.3132 25.1847 23.0915 24.8897 22.8611 24.6027C22.7073 24.4241 22.5536 24.2455 22.3831 24.0669L22.3148 24.106C22.4173 23.974 22.9632 23.4072 23.6457 22.7785C23.8933 22.5533 24.1575 22.3284 24.4308 22.0956C24.5925 21.9713 24.7546 21.8393 24.9167 21.715C25.0021 21.6528 25.0875 21.591 25.1642 21.5288L25.1725 21.5208C25.4201 21.3426 26.0514 20.8069 26.0514 20.8069L26.1793 21.3191C26.2389 21.7696 26.3756 22.2041 26.5973 22.6079C26.9385 23.2597 27.9453 24.4317 28.6703 25.1767C30.4359 27.0088 30.6322 29.2053 30.6322 29.2053C30.6322 29.2053 30.6151 23.2832 30.0521 21.3347C30.0946 21.3191 30.1205 21.3036 30.1376 21.2804C30.163 21.2414 30.2059 21.1796 30.2655 21.1019V21.0943C30.3251 21.0086 30.1888 20.8813 30.2655 20.7179C30.3392 20.5918 30.4959 20.6673 30.513 20.6287L31.5535 20.8069L32.3811 20.9544L32.3894 20.9699C32.4665 21.0787 32.9099 21.8856 32.5857 25.1069C32.5599 25.4797 32.4665 25.899 32.2532 26.3256C32.2532 26.3256 32.3044 29.12 32.5941 30.8277C32.7053 31.4799 32.7565 32.1783 32.782 32.799V32.8301C32.7903 32.9158 32.7649 33.3037 32.7224 33.4045Z"
          fill="url(#paint1_linear_4613_145507)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.986 22.206C25.137 22.0784 25.5579 21.6936 25.6739 21.5996C25.6143 21.3982 25.5549 21.2911 25.5478 21.2213C25.5068 21.2495 25.216 21.4928 25.1751 21.5232L25.1656 21.5318C25.131 21.5568 25.0969 21.5817 25.0619 21.607C25.0206 21.6381 24.9781 21.6692 24.9356 21.7003C24.9296 21.7046 24.924 21.709 24.9185 21.7133C24.7592 21.8348 24.5991 21.9631 24.4405 22.0947C24.5216 22.0947 24.5947 22.0951 24.6523 22.1102C24.7679 22.1323 24.8791 22.1623 24.986 22.206Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.0458 26.3061C25.0458 26.3061 25.0458 26.3412 25.0435 26.4044C25.0315 26.7128 24.9954 27.6896 24.9354 28.5091C24.9088 28.8894 24.8774 29.2368 24.8416 29.4663C24.7121 30.2902 24.3398 32.1848 24.1833 33.8853C24.0371 35.5088 23.8543 37.3618 23.8543 37.6286L23.8571 37.6569C23.8571 37.8011 23.751 37.9453 23.5425 38.0545C23.2445 38.2121 22.8094 38.258 22.4352 38.1902C22.2814 38.164 22.1396 38.1181 22.0172 38.0545C21.8059 37.9453 21.7002 37.7993 21.7002 37.6551V31.2428L22.0534 24.7325C22.0534 24.6909 22.0558 24.6493 22.0578 24.6059C22.082 24.3959 22.1634 24.2321 22.291 24.123C22.322 24.0944 22.3534 24.0705 22.3895 24.0507C22.5862 23.9328 22.8555 23.9108 23.1674 24.0004C23.2902 24.0355 23.4173 24.0857 23.5496 24.1555C24.3756 24.5907 25.0458 25.552 25.0458 26.3061Z"
          fill="url(#paint2_linear_4613_145507)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.1021 22.814L29.7771 22.6994L31.269 22.7569L31.5916 28.2203L31.2023 29.4295L30.5284 29.5936L28.8959 28.0648L27.6905 26.6141L25.9722 23.8154V22.831L26.8932 22.266L28.1935 23.0212L29.1021 22.814Z"
          fill="url(#paint3_linear_4613_145507)"
        />
        <g style={{ mixBlendMode: "darken" }} opacity="0.4">
          <g style={{ mixBlendMode: "darken" }} opacity="0.4">
            <path
              d="M25.5467 29.1658C28.6736 28.7623 31.3046 27.0644 32.2292 26.2658C32.3133 26.9383 32.4814 28.384 32.4814 28.7875C32.4814 29.191 30.464 30.3005 29.4553 30.8049C26.8496 30.4266 22.4198 29.5692 25.5467 29.1658Z"
              fill="#BB6517"
            />
          </g>
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.1685 20.7687C26.4534 21.5391 27.2484 22.2075 29.1022 22.8155L28.6798 24.1871C28.6798 24.1871 26.1455 23.1304 25.4685 21.2907L26.0648 20.1097C26.0648 20.1097 26.1308 20.6675 26.1685 20.7687Z"
          fill="url(#paint4_linear_4613_145507)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.593 35.0722L30.5088 35.0592L30.5096 34.9811C30.5545 34.7371 30.3769 33.4509 30.1747 32.0436C29.897 30.1113 29.5509 27.707 29.6343 26.8061C29.7762 25.2806 29.1079 22.8386 29.1016 22.814L29.1938 22.7974C29.2001 22.822 29.8616 25.2748 29.7194 26.8126C29.6367 27.7055 29.982 30.1052 30.2593 32.0331C30.4715 33.5058 30.6407 34.7389 30.5938 34.9938L30.593 35.0722Z"
          fill="#C97720"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.4961 23.7572L29.7773 22.6998C29.8882 22.5704 29.9007 21.6198 29.8411 20.9059L29.8753 20.8979C29.9178 20.8824 29.9436 20.8668 29.9607 20.8437C29.9861 20.8047 30.029 20.7429 30.0886 20.6651V20.6575C30.1399 20.5798 30.2078 20.479 30.2678 20.3391C30.4466 20.8513 30.9883 22.6365 30.4961 23.7572Z"
          fill="#232422"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.8868 24.8588H26.0338C26.0338 24.2146 25.4108 23.3376 24.6691 22.9338C24.4557 22.8174 24.1315 22.7321 23.8074 22.7553C23.7732 22.7632 23.7136 22.7708 23.6453 22.7788C23.8928 22.5535 24.157 22.3287 24.4303 22.0959C24.5153 22.0959 24.592 22.0959 24.652 22.1114C24.8137 22.1425 24.9675 22.1888 25.1125 22.2665C26.1105 22.8099 26.8868 23.9507 26.8868 24.8588Z"
          fill="#EAE9F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.8883 24.8582L27.315 30.6802C27.025 30.6491 26.735 30.6104 26.4616 30.5482L26.0353 24.8582H26.8883Z"
          fill="url(#paint5_linear_4613_145507)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.0393 22.8017V23.19H31.1863V22.8017C31.1863 22.2273 31.0325 21.2183 30.4696 20.7292C30.4866 20.6981 30.2126 20.5919 30.5125 20.6287L31.553 20.8069C31.9026 21.4435 32.0393 22.2273 32.0393 22.8017Z"
          fill="#EAE9F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.0373 22.8003V29.4989C31.7473 29.4678 31.4572 29.8926 31.1843 29.8304V22.8003H32.0373Z"
          fill="url(#paint6_linear_4613_145507)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8329 37.9285C21.8333 37.9282 21.8337 37.9275 21.8337 37.9267C21.7451 37.8425 21.701 37.7482 21.701 37.6549V36.1047C21.7006 36.104 21.7006 36.1033 21.7002 36.1025V37.6564C21.7002 37.7494 21.7443 37.844 21.8329 37.9285ZM21.701 35.3177V31.2425L22.0542 24.7322C22.0542 24.6906 22.0566 24.649 22.0586 24.6057C22.0701 24.5063 22.0943 24.4173 22.1305 24.3392C22.0939 24.4177 22.0693 24.507 22.0578 24.6071C22.0558 24.6509 22.0534 24.6924 22.0534 24.734L21.7002 31.2443V35.3137C21.7006 35.3152 21.7006 35.3166 21.701 35.3177ZM22.2862 24.1263L22.2886 24.1252C22.2898 24.1245 22.2906 24.1234 22.2918 24.1227C22.3017 24.1133 22.3116 24.105 22.322 24.0967C22.3736 24.032 22.5071 23.8877 22.6974 23.694C22.467 23.9253 22.3164 24.0865 22.2862 24.1263Z"
          fill="#2B2B44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.3215 24.0965C22.3429 24.0791 22.3652 24.0636 22.3898 24.0502C22.505 23.9812 22.6453 23.945 22.8046 23.945C22.917 23.945 23.0386 23.9627 23.1677 24C23.1681 24 23.1681 24 23.1681 24C23.4522 23.6218 23.882 23.1906 24.3103 22.7983C24.1868 22.7683 24.0525 22.7506 23.9158 22.7506C23.8797 22.7506 23.8435 22.7517 23.8074 22.7542C23.7752 22.7615 23.7208 22.769 23.6576 22.7763C23.2794 23.1208 22.9428 23.4472 22.6969 23.6938C22.5066 23.8875 22.3731 24.0318 22.3215 24.0965Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4344 38.1901C22.4475 37.7395 22.4582 37.1905 22.4682 36.5741C22.293 36.5379 22.1293 36.4801 21.9934 36.4006C21.8412 36.3131 21.7435 36.2111 21.7002 36.1049V37.655C21.7002 37.7483 21.7443 37.8426 21.8329 37.9269C21.8814 37.973 21.9425 38.0161 22.0172 38.0545C22.1392 38.118 22.281 38.1639 22.4344 38.1901ZM22.4785 35.8533C22.5337 31.9035 22.5703 25.9691 22.8567 24.6073C22.8762 24.5111 22.898 24.437 22.9219 24.3889C22.9795 24.2707 23.0613 24.1395 23.1666 24.0018C23.167 24.0014 23.1674 24.0011 23.1678 24.0003C23.1678 24.0003 23.1678 24.0003 23.1674 24.0003C23.0383 23.9631 22.9167 23.9454 22.8043 23.9454C22.645 23.9454 22.5047 23.9815 22.3895 24.0506C22.3649 24.0639 22.3426 24.0795 22.3212 24.0969C22.3108 24.1051 22.3009 24.1135 22.291 24.1229C22.2898 24.1236 22.289 24.1247 22.2878 24.1254L22.2902 24.1243C22.2234 24.1814 22.1694 24.2537 22.1297 24.3394C22.0935 24.4175 22.0693 24.5064 22.0578 24.6058C22.0558 24.6492 22.0534 24.6908 22.0534 24.7324L21.7002 31.2427V35.3179C21.724 35.4314 21.8023 35.546 21.9493 35.6458L22.0069 35.6801C22.142 35.7589 22.3049 35.8171 22.4785 35.8533Z"
          fill="url(#paint7_linear_4613_145507)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9356 21.7018C24.9782 21.6707 25.0208 21.6395 25.0621 21.6084C25.0204 21.6388 24.9782 21.6699 24.9356 21.7018Z"
          fill="#2B2B44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.3109 22.7994C24.5445 22.5853 24.7778 22.3833 24.9863 22.2072C24.8795 22.1635 24.7682 22.1335 24.6526 22.1114C24.595 22.0962 24.5219 22.0959 24.4408 22.0959C24.1711 22.3193 23.9057 22.5521 23.6582 22.7773C23.7213 22.7701 23.7758 22.7625 23.808 22.7553C23.8441 22.7527 23.8803 22.7517 23.9164 22.7517C24.0531 22.7517 24.1874 22.7694 24.3109 22.7994Z"
          fill="#EAE9F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.0578 18.0985C30.8786 18.5876 30.8953 18.9364 30.8953 18.9364C30.8274 19.7361 30.6736 20.2718 30.5119 20.629C30.4948 20.6832 30.469 20.7295 30.4436 20.7761C30.3836 20.916 30.3156 21.0169 30.2644 21.0946V21.1022C30.2048 21.1799 30.1619 21.2417 30.1365 21.2808C30.1194 21.3039 30.0935 21.3195 30.051 21.335L30.0169 21.343C29.6843 21.4127 28.7713 21.2417 28.1913 21.0245C27.5175 20.7685 27.0995 20.1088 27.0995 20.1088C26.3657 19.1772 26.6732 18.8048 25.6152 17.5707C25.8794 14.5977 28.3021 14.846 28.3021 14.846C31.1686 14.846 31.0578 18.0985 31.0578 18.0985Z"
          fill="#F4BE8A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.1329 16.7718C30.9983 16.7038 30.8278 16.7519 30.7055 16.8351C30.5827 16.9204 30.4893 17.036 30.3666 17.1214C30.0352 17.3527 29.5668 17.3202 29.1612 17.2305C28.7047 17.1301 27.9697 16.9225 27.5326 17.1738C27.4222 17.189 27.3718 17.3072 27.357 17.4051C27.3094 17.72 27.3718 18.0392 27.3189 18.3537C27.2661 18.6534 27.2827 18.8161 27.2827 18.8161L26.949 18.6928C26.8295 18.7776 26.7398 18.8412 26.6801 18.8837C26.5818 18.9534 26.4344 19.058 26.2378 19.1975C26.2378 19.1975 25.5728 18.0327 25.4599 17.5649C25.3471 17.0971 25.4933 16.0723 26.2331 15.3861C26.9705 14.6975 27.9287 14.6823 28.3348 14.6823C29.6463 14.6823 30.8278 15.2636 31.0872 16.518C31.0944 16.5531 31.1135 16.6514 31.1329 16.7718Z"
          fill="#212220"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.9521 18.6965C26.9005 18.5374 26.856 18.3769 26.8274 18.2131C26.79 17.9988 26.6609 17.6308 26.2131 17.7059C25.5886 17.8112 26.2291 19.0103 26.4523 19.1158C26.676 19.2217 26.9521 18.6965 26.9521 18.6965Z"
          fill="#F4BE8A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.8859 21.354C29.8879 21.4219 29.8879 21.4873 29.8859 21.5528C29.2614 21.6797 28.5509 21.8327 27.8803 21.3582C27.2097 20.8837 26.5892 19.1976 26.5892 19.1976C26.5892 19.1976 27.5224 20.7683 28.1946 21.0239C28.704 21.2209 29.4847 21.376 29.8859 21.354Z"
          fill="#D59E73"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.5354 14.3755C26.5354 14.3755 25.6774 14.6272 25.4074 15.3745C25.4074 15.3745 25.0304 16.1479 25.5601 16.8541C26.0899 17.5606 27.097 18.0079 27.097 18.0079C27.097 18.0079 27.732 18.2814 28.5192 18.4243L29.5308 18.6601C29.5308 18.6601 30.0662 18.7426 30.553 18.5441C31.0397 18.3452 31.3757 18.0747 31.3757 18.0747C31.3757 18.0747 32.1109 17.553 31.7664 16.8677C31.4219 16.1821 30.7056 15.3984 30.426 15.1937L26.5354 14.3755Z"
          fill="#DE892F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4972 14.3802C26.4972 14.3802 25.6523 14.6272 25.3852 15.3576C25.3852 15.3576 25.1168 16.0183 25.6375 16.7079C26.1577 17.3972 27.1489 17.8329 27.1489 17.8329C27.1489 17.8329 27.7736 18.0994 28.5483 18.2377L29.5438 18.4672C29.5438 18.4672 30.0705 18.5476 30.5502 18.3526C31.0295 18.1578 31.3606 17.8934 31.3606 17.8934C31.3606 17.8934 32.0852 17.3826 31.7466 16.7135C31.4083 16.0441 30.5034 15.3825 30.2292 15.1827L26.4972 14.3802Z"
          fill="#EB9B3C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4473 15.5732C25.523 15.1139 25.7894 14.5744 26.2084 14.2075C26.3347 14.0285 26.7955 13.5956 27.4542 13.5705L28.1199 13.4737C28.1199 13.4737 28.7947 13.2603 29.8224 13.8114C30.8501 14.3621 31.1232 15.7073 31.1232 15.7073C31.1232 15.7073 31.2659 16.4964 31.0736 16.9936C31.0736 16.9936 30.8847 17.7579 29.6166 17.9741C28.3488 18.1902 27.2099 17.7247 27.2099 17.7247C25.6889 17.0542 25.3568 16.1213 25.4473 15.5732Z"
          fill="#EBAB3C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.7447 14.9356C26.3799 15.5754 28.1456 16.6402 29.4185 17.1675L25.7447 14.9356Z"
          fill="#DE892F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3053 14.2259C26.3053 14.2259 26.7926 13.815 27.5877 14.0857C28.3826 14.3557 28.9193 14.7009 28.9193 14.7009C28.9193 14.7009 29.8667 15.5973 30.2293 16.328C30.2293 16.328 30.249 15.5744 29.5286 14.7864C28.716 13.8976 27.4265 13.6653 27.4265 13.6653C27.4265 13.6653 26.705 13.7593 26.3053 14.2259Z"
          fill="url(#paint8_linear_4613_145507)"
          fillOpacity="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1701 13.5366C29.2922 13.5611 30.4214 14.2041 30.9175 15.7433C30.9175 15.7433 30.7558 14.8024 30.1912 14.2495C29.7465 13.8137 28.9983 13.4247 28.1701 13.5366Z"
          fill="#FBBB4D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.2867 14.2583C26.2867 14.2583 26.6917 13.9374 27.4868 14.2081C28.2818 14.4781 28.8697 14.9179 28.8697 14.9179C28.8697 14.9179 29.8851 15.6782 30.2478 16.4089C30.2478 16.4089 30.215 16.007 29.4946 15.219C28.7742 14.431 27.5381 14.0263 27.5381 14.0263C27.5381 14.0263 26.6864 13.7918 26.2867 14.2583Z"
          fill="#DE892F"
          fillOpacity="0.4"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6228 38.8979C8.51164 37.68 6.79028 38.4122 6.79028 40.5275C6.79028 42.6429 8.51164 45.3611 10.6228 46.579C12.7329 47.7969 14.4542 47.0647 14.4542 44.95C14.4542 42.834 12.7329 40.1158 10.6228 38.8979Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45707 40.5228C6.34587 39.305 4.62451 40.0371 4.62451 42.1525C4.62451 44.2678 6.34587 46.9861 8.45707 48.2039C10.5671 49.4218 12.2884 48.6896 12.2884 46.5749C12.2884 44.459 10.5671 41.7407 8.45707 40.5228Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45744 41.814C7.05577 41.0055 5.9126 41.4912 5.9126 42.896C5.9126 44.3002 7.05577 46.1054 8.45744 46.9145C9.85852 47.723 11.0017 47.2367 11.0017 45.8325C11.0017 44.4277 9.85852 42.6225 8.45744 41.814Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56368 42.0008C7.32635 41.2866 6.31693 41.7158 6.31693 42.9559C6.31693 44.196 7.32635 45.7896 8.56368 46.5031C9.80042 47.2173 10.8098 46.7881 10.8098 45.548C10.8098 44.3084 9.80042 42.7143 8.56368 42.0008Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56367 42.3248C7.50506 41.714 6.64139 42.0813 6.64139 43.1423C6.64139 44.2033 7.50506 45.5672 8.56367 46.1779C9.62227 46.7887 10.4853 46.4214 10.4853 45.3604C10.4853 44.2994 9.62227 42.9361 8.56367 42.3248Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56361 42.7156C7.71853 42.2281 7.02939 42.5208 7.02939 43.3678C7.02939 44.2142 7.71853 45.3028 8.56361 45.7903C9.4081 46.2772 10.0972 45.9845 10.0972 45.1381C10.0972 44.2911 9.4081 43.2031 8.56361 42.7156Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56359 42.9099C7.82527 42.4837 7.22369 42.7398 7.22369 43.4792C7.22369 44.2186 7.82527 45.1695 8.56359 45.5951C9.30131 46.0207 9.90289 45.7646 9.90289 45.0253C9.90289 44.2859 9.30131 43.3355 8.56359 42.9099Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0017 48.6598C11.5095 48.6419 12.408 48.0199 13.6973 46.7939L12.861 44.2626L11.5157 46.8709C10.6652 48.0814 10.4938 48.6777 11.0017 48.6598Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.09473 38.4874V38.5462L7.90457 40.0141L5.1257 40.5262C7.10505 38.9926 8.09473 38.2903 8.09473 38.4196C8.09473 38.5489 8.09473 38.5715 8.09473 38.4874Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3587 38.8979C31.2475 37.68 29.5262 38.4122 29.5262 40.5275C29.5262 42.6429 31.2475 45.3611 33.3587 46.579C35.4687 47.7969 37.1901 47.0647 37.1901 44.95C37.1901 42.834 35.4687 40.1158 33.3587 38.8979Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1936 40.5228C29.0824 39.305 27.3611 40.0371 27.3611 42.1525C27.3611 44.2678 29.0824 46.9861 31.1936 48.2039C33.3036 49.4218 35.025 48.6896 35.025 46.5749C35.025 44.459 33.3036 41.7407 31.1936 40.5228Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1934 41.814C29.7917 41.0055 28.6485 41.4912 28.6485 42.896C28.6485 44.3002 29.7917 46.1054 31.1934 46.9145C32.5944 47.723 33.7376 47.2367 33.7376 45.8325C33.7376 44.4277 32.5944 42.6225 31.1934 41.814Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2996 41.9995C30.0623 41.2854 29.0529 41.7146 29.0529 42.9547C29.0529 44.1948 30.0623 45.7884 31.2996 46.5019C32.5364 47.216 33.5458 46.7868 33.5458 45.5467C33.5458 44.3072 32.5364 42.7131 31.2996 41.9995Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2996 42.3248C30.241 41.714 29.3773 42.0813 29.3773 43.1423C29.3773 44.2033 30.241 45.5672 31.2996 46.1779C32.3582 46.7887 33.2213 46.4214 33.2213 45.3604C33.2213 44.2994 32.3582 42.9361 31.2996 42.3248Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2995 42.7156C30.4545 42.2281 29.7653 42.5208 29.7653 43.3678C29.7653 44.2142 30.4545 45.3028 31.2995 45.7903C32.144 46.2772 32.8332 45.9845 32.8332 45.1381C32.8332 44.2911 32.144 43.2031 31.2995 42.7156Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2995 42.9111C30.5612 42.4849 29.9596 42.741 29.9596 43.4803C29.9596 44.2197 30.5612 45.1707 31.2995 45.5963C32.0372 46.0219 32.6388 45.7658 32.6388 45.0264C32.6388 44.287 32.0372 43.3367 31.2995 42.9111Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7375 48.6598C34.2454 48.6419 35.1439 48.0199 36.4331 46.7939L35.5969 44.2626L34.2516 46.8709C33.4011 48.0814 33.2297 48.6777 33.7375 48.6598Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8306 38.4874V38.5462L30.6404 40.0141L27.8616 40.5262C29.8409 38.9926 30.8306 38.2903 30.8306 38.4196C30.8306 38.5489 30.8306 38.5715 30.8306 38.4874Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.16 40.6987L34.3218 42.3361V44.8837L37.16 43.2516V40.6987Z"
        fill="#EB922D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3251 38.7576L34.1867 42.2519L34.3217 42.3312L37.1598 40.6992L37.02 40.6162L36.9918 40.6L31.1626 37.1201L28.3251 38.7576Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.7834 42.0696V42.634C36.7834 42.741 36.7258 42.8396 36.6334 42.8931L35.0362 43.8387C34.9547 43.8856 34.8533 43.826 34.8533 43.7317V43.6643L36.2862 42.8144C36.3779 42.7615 36.4361 42.6623 36.4361 42.5559V42.0588L36.6004 41.9644C36.682 41.9175 36.7834 41.977 36.7834 42.0696Z"
        fill="#121311"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4361 42.0594V42.5565C36.4361 42.6629 36.3779 42.7621 36.2862 42.815L34.8533 43.665V43.1691C34.8533 43.0608 34.9109 42.9623 35.0032 42.9088L36.4361 42.0594Z"
        fill="#FFF3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4962 52.7231L19.2831 47.2607V40.5558L28.4962 46.0182V52.7231Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.2805 42.1005L28.4964 46.0174V52.7223L35.2805 48.83V42.1005Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2837 40.5534L28.495 46.0181L35.2803 42.1235L26.0653 36.6371L19.2837 40.5534Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6997 50.4096L23.9404 49.9726L23.4228 47.1088L24.6997 47.862V50.4096Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9684 39.2329L19.4041 40.6389V46.9861L16.9684 45.5891V39.2329Z"
        fill="#502D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3142 46.7978L17.309 45.6202V41.6384L15.3142 42.816V46.7978Z"
        fill="#8D4528"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8784 41.4108L15.3141 42.8168V46.7986L12.8784 45.4017V41.4108Z"
        fill="#6D3822"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.309 41.6383L15.5246 42.6921L15.3141 42.8171L12.8784 41.4189L13.0967 41.2891L13.1405 41.2638L14.8745 40.2329L17.309 41.6383Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9656 43.4495L8.45622 39.6072L0.441434 30.3103L0 27.892L19.1556 39.2092L14.9656 43.4495Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6833 39.4006L14.817 42.3004L9.04836 38.8944L2.04779 30.7769L1.92303 30.0905L17.6833 39.4006Z"
        fill="#874226"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9381 32.3179L19.1555 39.2091L14.9655 43.4494L26.7481 36.5829L30.9381 32.3179Z"
        fill="#CA722E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0012207 27.8906L19.155 39.2097L30.9382 32.3413L11.7814 21L0.0012207 27.8906Z"
        fill="#ECC530"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1326 32.3348L23.4275 35.6614L19.1559 38.15L15.9735 36.2685L4.08056 29.2397L1.80441 27.895L11.7781 22.0617L29.1326 32.3348Z"
        fill="#E2832D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2189 24.4794L4.08056 29.2397L1.80441 27.895L11.7781 22.0617L12.2189 24.4794Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4273 35.6612L19.1557 38.1499L15.9733 36.2684L20.2359 33.7767L23.4273 35.6612Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2348 33.7751L23.4274 35.6602L29.1331 32.3348L11.778 22.0605L12.2194 24.4782L20.2348 33.7751Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1835 39.2286L18.222 38.6551V37.0297L19.1835 37.6031V39.2286Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.416 43.7375L21.8763 39.2959L24.544 36.8589L27.8638 38.8186L29.416 43.7375Z"
        fill="#212220"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.916 36.48L27.8639 38.8196L29.4161 43.7385L33.4682 41.4134L31.916 36.48Z"
        fill="#434341"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.545 36.8584L27.8636 38.8193L31.9163 36.4929L28.5953 34.5189L24.545 36.8584Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0467 47.3659L34.7296 44.0802L34.8651 44.1553L29.0467 50.8326V47.3659Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0467 47.3658L29.1822 47.4409V50.9076L29.0467 50.8324V47.3658Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1823 47.441L34.8651 44.1553V47.5648L29.1823 50.9076V47.441Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 44.4773V47.4576L33.8832 47.9265L33.7626 47.9974L32.307 48.854L32.1864 48.9256L30.7272 49.7822L30.6096 49.8537L30.4951 49.9192L29.3675 50.5835V47.9818L30.3745 47.4017L30.6096 47.2683V47.1439L30.3745 47.2773L29.3675 47.858V47.5472L30.3745 46.9671L30.7272 46.7621L31.9537 46.0516L32.1864 45.9176L32.307 45.8496L33.5305 45.1415L33.7626 45.0081L33.8832 44.9372L34.6791 44.4773Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 45.7714V45.8953L33.8832 46.3515L33.7626 46.423L33.5305 46.5565L32.307 47.258L32.1864 47.3295L31.9537 47.463L30.7272 48.1675L30.6096 48.236L30.3745 48.3695L29.3675 48.9501V48.8257L30.3745 48.245L30.6096 48.1116L30.7272 48.0436L31.9537 47.3391L32.1864 47.2051L32.307 47.1342L33.5305 46.4327L33.7626 46.2992L33.8832 46.2277L34.6791 45.7714Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 48.603V48.7245L30.3745 48.8579L29.3675 49.438V49.3172L30.3745 48.7365L30.6096 48.603Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 46.2593V46.3831L33.8831 46.8394L33.7626 46.9109L33.5305 47.0443L32.3069 47.7458L32.1864 47.8174L31.9537 47.9508L30.7271 48.6553L30.6096 48.7239V48.6024L30.7271 48.5345L31.9537 47.8264L32.1864 47.6929L32.3069 47.625L33.5305 46.9199L33.7626 46.7865L33.8831 46.7185L34.679 46.2593Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 49.0909V49.2147L30.3745 49.3488L29.3675 49.9288V49.805L30.3745 49.2243L30.6096 49.0909Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 49.5776V49.7014L30.3745 49.8349L29.3675 50.4155V50.2911L30.3745 49.711L30.6096 49.5776Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 47.2375V47.3583L33.8831 47.8182L33.7626 47.8861L33.5305 48.0195L32.3069 48.7247L32.1864 48.7926L31.9537 48.926L30.7271 49.6341L30.6096 49.7021V49.5782L30.7271 49.5097L31.9537 48.8052L32.1864 48.6718L32.3069 48.6002L33.5305 47.8987L33.7626 47.7653L33.8831 47.6937L34.679 47.2375Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5513 45.723L34.6791 45.7723L33.8832 46.2286L33.7626 46.3001L33.5305 46.4335L32.307 47.135L32.1864 47.206L31.9537 47.34L30.7272 48.0445L30.6096 48.1125L30.3745 48.2459L29.3675 48.8266V48.7058L30.3745 48.1251L30.6096 47.9916L30.7272 47.9231L31.9537 47.2156L32.1864 47.0822L32.307 47.0136L33.5305 46.3091L33.7626 46.1757L33.8832 46.1071L34.5513 45.723Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5513 46.2133L34.6791 46.2596L33.8832 46.7189L33.7626 46.7868L33.5305 46.9202L32.307 47.6253L32.1864 47.6933L31.9537 47.8267L30.7272 48.5348L30.6096 48.6028L30.3745 48.7362L29.3675 49.3169V49.1925L30.3745 48.6124L30.6096 48.4789L30.7272 48.4104L31.9537 47.7059L32.1864 47.5724L32.307 47.5009L33.5305 46.7994L33.7626 46.666L33.8832 46.5944L34.5513 46.2133Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 47.1442V47.2687L30.3745 47.4021L29.3675 47.9822V47.8584L30.3745 47.2777L30.6096 47.1442Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 44.8005V44.9244L33.8831 45.3836L33.7626 45.4521L33.5305 45.5856L32.3069 46.2901L32.1864 46.3586L31.9537 46.4921L30.7271 47.1996L30.6096 47.2681V47.1437L30.7271 47.0758L31.9537 46.3677L32.1864 46.2342L32.3069 46.1663L33.5305 45.4612L33.7626 45.3277L33.8831 45.2598L34.679 44.8005Z"
        fill="#2D2E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 45.2908V45.4123L33.8832 45.8715L33.7626 45.94L33.5305 46.0735L32.307 46.778L32.1864 46.8459L31.9537 46.98L30.7272 47.6875L30.6096 47.7554L30.3745 47.8895L29.3675 48.4695V48.3457L30.3745 47.765L30.6096 47.6316L30.7272 47.5631L31.9537 46.8585L32.1864 46.7251L32.307 46.6536L33.5305 45.9521L33.7626 45.8186L33.8832 45.7471L34.6791 45.2908Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5513 44.7545L34.6791 44.8008L33.8832 45.2601L33.7626 45.328L33.5305 45.4614L32.307 46.1665L32.1864 46.2345L31.9537 46.3679L30.7272 47.076L30.6096 47.144L30.3745 47.2774L29.3675 47.8581V47.7337L30.3745 47.1536L30.6096 47.0201L30.7272 46.9516L31.9537 46.2471L32.1864 46.1136L32.307 46.0421L33.5305 45.3406L33.7626 45.2072L33.8832 45.1356L34.5513 44.7545Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5513 45.2411L34.6791 45.2904L33.8832 45.7467L33.7626 45.8182L33.5305 45.9517L32.307 46.6532L32.1864 46.7247L31.9537 46.8582L30.7272 47.5627L30.6096 47.6312L30.3745 47.7646L29.3675 48.3453V48.2239L30.3745 47.6432L30.6096 47.5104L30.7272 47.4418L31.9537 46.7337L32.1864 46.6003L32.307 46.5324L33.5305 45.8278L33.7626 45.6944L33.8832 45.6259L34.5513 45.2411Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8832 44.9373V47.9267L33.7626 47.9976V45.0082L33.8832 44.9373Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 45.0075V45.3273L33.5308 45.3399V45.141L33.7629 45.0075Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 45.4518V45.8179L33.5308 45.8305V45.5853L33.7629 45.4518Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 45.9397V46.2998L33.5308 46.3118V46.0731L33.7629 45.9397Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 46.424V46.787L33.5308 46.7996V46.5574L33.7629 46.424Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 46.7496V46.871L33.8831 47.3303L33.7626 47.3988L33.5305 47.5322L32.3069 48.2367L32.1864 48.3053L31.9537 48.4387L30.7271 49.1462L30.6096 49.2142V49.0903L30.7271 49.0218L31.9537 48.3173L32.1864 48.1838L32.3069 48.1123L33.5305 47.4108L33.7626 47.2774L33.8831 47.2058L34.679 46.7496Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 46.9118V47.2779L33.5308 47.2905V47.0453L33.7629 46.9118Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 47.3985V47.7646L33.5308 47.7772V47.5319L33.7629 47.3985Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 47.8851V47.9969L33.5308 48.1334V48.0186L33.7629 47.8851Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.307 45.8501V48.8545L32.1864 48.926V45.918L32.307 45.8501Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 45.9179V46.2347L31.9539 46.2473V46.052L32.1867 45.9179Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 46.3586V46.7247L31.9539 46.7373V46.492L32.1867 46.3586Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 46.8452V47.2053L31.9539 47.2179V46.9793L32.1867 46.8452Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 47.3295V47.6925L31.9539 47.7052V47.4629L32.1867 47.3295Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 47.8173V48.1834L31.9539 48.196V47.9508L32.1867 47.8173Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 48.3052V48.6713L31.9539 48.6833V48.4387L32.1867 48.3052Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 48.7919V48.9253L31.9539 49.0618V48.9253L32.1867 48.7919Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7271 46.7617V49.7817L30.6096 49.8533V46.8302L30.7271 46.7617Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 46.8307V47.1439L30.3747 47.1535V46.9671L30.6098 46.8307Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 47.2678V47.6308L30.3747 47.6429V47.4012L30.6098 47.2678Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 47.7544V48.1115L30.3747 48.1241V47.8884L30.6098 47.7544Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 48.2362V48.6023L30.3747 48.6149V48.3697L30.6098 48.2362Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 48.7241V49.0902L30.3747 49.1022V48.8576L30.6098 48.7241Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 49.2144V49.578L30.3747 49.5901V49.3484L30.6098 49.2144Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3747 49.8345L30.6098 49.701V49.8531L30.4953 49.9187H30.3747V49.8345Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0467 46.5064L34.7296 43.2207L34.8651 43.2958L29.0467 49.7699V46.5064Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0467 46.5063L29.1822 46.5814V49.8449L29.0467 49.7698V46.5063Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1823 46.5814L34.8651 43.2957V46.5021L29.1823 49.8449V46.5814Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 43.6177V46.5975L33.8832 47.0664L33.7626 47.1373L32.307 47.9945L32.1864 48.0654L31.9537 48.2025L30.7272 48.9226L30.6096 48.9935L30.4951 49.0591L29.3675 49.7233V47.1223L30.3745 46.5416L30.6096 46.4081V46.2837L30.3745 46.4171L29.3675 46.9978V46.6876L30.3745 46.107L30.6096 45.9705L30.7272 45.902L31.9537 45.1915L32.1864 45.058L32.307 44.9895L33.5305 44.282L33.7626 44.1485L33.8832 44.077L34.6791 43.6177Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 44.9131V45.0375L33.8832 45.4932L33.7626 45.5647L33.5305 45.6981L32.307 46.3997L32.1864 46.4712L31.9537 46.6046L30.7272 47.3091L30.6096 47.3777L30.3745 47.5111L29.3675 48.0918V47.9674L30.3745 47.3873L30.6096 47.2532L30.7272 47.1853L31.9537 46.4808L32.1864 46.3474L32.307 46.2758L33.5305 45.5743L33.7626 45.4409L33.8832 45.3693L34.6791 44.9131Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 47.7435V47.865L30.3745 47.9984L29.3675 48.5791V48.4577L30.3745 47.877L30.6096 47.7435Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 45.3998V45.5236L33.8831 45.9799L33.7626 46.0514L33.5305 46.1848L32.3069 46.8863L32.1864 46.9579L31.9537 47.0913L30.7271 47.7958L30.6096 47.8644V47.7429L30.7271 47.675L31.9537 46.9669L32.1864 46.8334L32.3069 46.7655L33.5305 46.0604L33.7626 45.9276L33.8831 45.859L34.679 45.3998Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 48.2302V48.3546L30.3745 48.488L29.3675 49.0681V48.9443L30.3745 48.3636L30.6096 48.2302Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 48.718V48.8418L30.3745 48.9753L29.3675 49.556V49.4315L30.3745 48.8515L30.6096 48.718Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 46.3779V46.4994L33.8831 46.9586L33.7626 47.0271L33.5305 47.1606L32.3069 47.8651L32.1864 47.933L31.9537 48.0665L30.7271 48.7746L30.6096 48.8425V48.7187L30.7271 48.6502L31.9537 47.9456L32.1864 47.8122L32.3069 47.7407L33.5305 47.0392L33.7626 46.9057L33.8831 46.8342L34.679 46.3779Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 46.2835V46.4079L30.3745 46.5414L29.3675 47.1221V46.9976L30.3745 46.417L30.6096 46.2835Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 43.941V44.0648L33.8831 44.5241L33.7626 44.5926L33.5305 44.726L32.3069 45.4306L32.1864 45.4991L31.9537 45.6325L30.7271 46.34L30.6096 46.4086V46.2841L30.7271 46.2162L31.9537 45.5081L32.1864 45.3746L32.3069 45.3067L33.5305 44.6022L33.7626 44.4688L33.8831 44.4002L34.679 43.941Z"
        fill="#2D2E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 44.4313V44.5527L33.8832 45.012L33.7626 45.0805L33.5305 45.2139L32.307 45.9184L32.1864 45.987L31.9537 46.1204L30.7272 46.8279L30.6096 46.8965L30.3745 47.0299L29.3675 47.61V47.4862L30.3745 46.9055L30.6096 46.772L30.7272 46.7035L31.9537 45.999L32.1864 45.8655L32.307 45.794L33.5305 45.0925L33.7626 44.9591L33.8832 44.8881L34.6791 44.4313Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8832 44.0778V47.0671L33.7626 47.138V44.1493L33.8832 44.0778Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 44.1492V44.469L33.5308 44.481V44.2827L33.7629 44.1492Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 44.5923V44.9584L33.5308 44.971V44.7257L33.7629 44.5923Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 45.0801V45.4402L33.5308 45.4522V45.2136L33.7629 45.0801Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 45.5644V45.9281L33.5308 45.9401V45.6978L33.7629 45.5644Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 45.8901V46.0115L33.8831 46.4708L33.7626 46.5393L33.5305 46.6727L32.3069 47.3772L32.1864 47.4458L31.9537 47.5792L30.7271 48.2867L30.6096 48.3553V48.2308L30.7271 48.1623L31.9537 47.4578L32.1864 47.3243L32.3069 47.2528L33.5305 46.5513L33.7626 46.4179L33.8831 46.3469L34.679 45.8901Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 46.0511V46.4172L33.5308 46.4298V46.1845L33.7629 46.0511Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 46.5389V46.905L33.5308 46.9176V46.6724L33.7629 46.5389Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 47.0268V47.138L33.5308 47.2751V47.1602L33.7629 47.0268Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.307 44.9906V47.9956L32.1864 48.0665V45.0591L32.307 44.9906Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 45.0583V45.3745L31.9539 45.3872V45.1918L32.1867 45.0583Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 45.499V45.8651L31.9539 45.8777V45.6325L32.1867 45.499Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 45.9869V46.347L31.9539 46.359V46.1204L32.1867 45.9869Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 46.47V46.833L31.9539 46.8457V46.6034L32.1867 46.47Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 46.9578V47.3239L31.9539 47.3365V47.0913L32.1867 46.9578Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 47.4457V47.8118L31.9539 47.8244V47.5791L32.1867 47.4457Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 47.9324V48.0658L31.9539 48.2029V48.0658L32.1867 47.9324Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7271 45.9022V48.9228L30.6096 48.9937V45.9707L30.7271 45.9022Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 45.9712V46.2844L30.3747 46.294V46.1076L30.6098 45.9712Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 46.4082V46.7713L30.3747 46.7839V46.5417L30.6098 46.4082Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 46.8961V47.2525L30.3747 47.2651V47.0295L30.6098 46.8961Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 47.3767V47.7428L30.3747 47.7554V47.5102L30.6098 47.3767Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 47.8646V48.2306L30.3747 48.2433V47.998L30.6098 47.8646Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 48.3549V48.7179L30.3747 48.73V48.4883L30.6098 48.3549Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3747 48.9749L30.6098 48.8415V48.9936L30.4953 49.0591H30.3747V48.9749Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9497 53.5409L27.5358 53.2969V52.4709L27.9497 52.715V53.5409Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3761 47.873L27.9499 52.7151V53.541L36.3761 48.7008V47.873Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5358 52.4705L27.9497 52.7145L36.3759 47.8743L36.3303 47.8473L36.3213 47.8425L35.9621 47.6285L27.5358 52.4705Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9141 39.8293L18.222 37.0299V36.2911L22.9141 39.0899V39.8293Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6986 41.4925L4.1669 34.0597V33.3203L16.6986 40.7531V41.4925Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6688 32.1969L22.9144 39.0899V39.8293L34.6688 32.9363V32.1969Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.222 36.2904L22.8757 39.0676L22.9141 39.0898L34.6685 32.1968L29.9764 29.3956L18.222 36.2904Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37149 33.4704L16.4587 40.6393L16.6669 40.4283L4.61799 33.2816L4.30551 33.3934L4.37149 33.4704Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0732 45.9339C20.962 44.716 19.2407 45.4482 19.2407 47.5635C19.2407 49.6788 20.962 52.3971 23.0732 53.6149C25.1832 54.8328 26.9046 54.1006 26.9046 51.9859C26.9046 49.87 25.1832 47.1517 23.0732 45.9339Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9081 47.5573C18.7969 46.3394 17.0755 47.0716 17.0755 49.1869C17.0755 51.3022 18.7969 54.0205 20.9081 55.2384C23.0181 56.4562 24.7394 55.7241 24.7394 53.6093C24.7394 51.4934 23.0181 48.7751 20.9081 47.5573Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9077 48.8472C19.5061 48.0386 18.3629 48.5244 18.3629 49.9292C18.3629 51.3334 19.5061 53.1385 20.9077 53.9476C22.3088 54.7561 23.452 54.2698 23.452 52.8656C23.452 51.4608 22.3088 49.6557 20.9077 48.8472Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.014 49.0339C19.7767 48.3198 18.7672 48.749 18.7672 49.9891C18.7672 51.2292 19.7767 52.8227 21.014 53.5363C22.2507 54.2504 23.2602 53.8212 23.2602 52.5811C23.2602 51.3416 22.2507 49.7474 21.014 49.0339Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.014 49.3591C19.9554 48.7484 19.0917 49.1157 19.0917 50.1766C19.0917 51.2376 19.9554 52.6016 21.014 53.2123C22.0726 53.823 22.9356 53.4557 22.9356 52.3948C22.9356 51.3338 22.0726 49.9705 21.014 49.3591Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0139 49.75C20.1688 49.2624 19.4797 49.5552 19.4797 50.4022C19.4797 51.2485 20.1688 52.3372 21.0139 52.8247C21.8584 53.3116 22.5475 53.0188 22.5475 52.1725C22.5475 51.3255 21.8584 50.2375 21.0139 49.75Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0139 49.9455C20.2756 49.5193 19.674 49.7754 19.674 50.5148C19.674 51.2541 20.2756 52.2051 21.0139 52.6307C21.7516 53.0563 22.3532 52.8002 22.3532 52.0608C22.3532 51.3215 21.7516 50.3711 21.0139 49.9455Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4521 55.6958C23.9599 55.6778 24.8584 55.0559 26.1477 53.8299L25.3114 51.2986L23.9661 53.9068C23.1156 55.1174 22.9442 55.7137 23.4521 55.6958Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5451 45.5221V45.5809L20.3549 47.0488L17.5761 47.561C19.5554 46.0273 20.5451 45.3251 20.5451 45.4543C20.5451 45.5836 20.5451 45.6062 20.5451 45.5221Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7027 44.7356L24.5649 48.2293L24.6992 48.3093L27.5373 46.6767L27.3976 46.5937L27.37 46.5775L18.7027 44.2896V44.7356Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1937 45.248L18.7025 44.7346L21.5401 43.0972L16.1937 44.8025V45.248Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7027 44.2891L24.5649 47.7834L24.6992 47.8628L27.5373 46.2307L27.3976 46.1478L27.37 46.1315L21.5402 42.6517L18.7027 44.2891Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1937 44.8031L18.7025 44.2891L21.5401 42.6517L19.0306 43.1656L16.1937 44.8031Z"
        fill="#A44C2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5369 46.2254L24.6994 47.8628V50.4104L27.5369 48.7784V46.2254Z"
        fill="#EB922D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0532 47.5814V48.1465C27.0532 48.2529 26.9956 48.3521 26.9032 48.405L25.306 49.3505C25.2245 49.3974 25.1231 49.3379 25.1231 49.2441V49.1768L26.556 48.3268C26.6477 48.2733 26.7059 48.1747 26.7059 48.0678V47.5706L26.8703 47.4769C26.9518 47.4294 27.0532 47.4895 27.0532 47.5814Z"
        fill="#121311"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7059 47.5704V48.0675C26.7059 48.1745 26.6477 48.2731 26.556 48.3266L25.1231 49.1766V48.6806C25.1231 48.5724 25.1807 48.4739 25.2731 48.4204L26.7059 47.5704Z"
        fill="#FFF3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6997 50.4099L23.9404 49.9729L23.4228 47.1091L24.6997 47.8623V50.4099Z"
        fill="#D16F2D"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4613_145507"
          x1="19.1779"
          y1="43.1186"
          x2="36.5605"
          y2="3.54541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEB331" />
          <stop offset="1" stopColor="#FFD169" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4613_145507"
          x1="25.8658"
          y1="22.231"
          x2="28.1294"
          y2="28.5452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEA32D" />
          <stop offset="0.553923" stopColor="#E2832D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4613_145507"
          x1="26.4519"
          y1="29.7561"
          x2="23.4093"
          y2="30.389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212220" />
          <stop offset="1" stopColor="#434341" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4613_145507"
          x1="27.473"
          y1="22.9868"
          x2="28.2096"
          y2="25.8106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC9D2E" />
          <stop offset="1" stopColor="#E3842E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4613_145507"
          x1="28.2083"
          y1="23.7448"
          x2="24.4631"
          y2="21.4977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#232422" />
          <stop offset="1" stopColor="#626262" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4613_145507"
          x1="26.6752"
          y1="24.8582"
          x2="26.6752"
          y2="30.6802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAE9F4" />
          <stop offset="1" stopColor="#D5DDE7" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4613_145507"
          x1="31.6108"
          y1="22.8003"
          x2="31.6108"
          y2="29.8366"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAE9F4" />
          <stop offset="1" stopColor="#D5DDE7" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4613_145507"
          x1="21.3349"
          y1="22.8117"
          x2="22.9865"
          y2="22.7098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D6D6D" />
          <stop offset="1" stopColor="#373735" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4613_145507"
          x1="28.1272"
          y1="14.5127"
          x2="28.9365"
          y2="16.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBBB4D" />
          <stop offset="1" stopColor="#FBBB4D" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

