import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SmallBox from "./SmallBox";
import { Page, StatusInterface } from "contexts/ShifChangeContext";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import { IData } from "./ShiftChangeScreen";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ShiftChangePDF from "./ShiftChangePDF";
import ShiftChangeCsvExport from "./ShiftChangeCsvExport";
import ShiftChangeBusPDF from "./ShiftChangeBusPDF";
import { ErrorMsg } from "interfaces";
import { RefetchFunctionType, useData } from "hooks";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

interface IObjectNotAsigned {
  type: string;
  data: IDataNotAsigned[];
}

interface IDataNotAsigned {
  name: string;
  bus: string;
  ubication: string;
  equipType: string;
  orderColumn: number;
}

interface IDataProps {
  shift: string;
  dateFormat: string;
  maxPage: number;
  pages: Page[];
  page?: number;
  paginator?: boolean;
  dataOperators?: IData;
  data?: IObjectNotAsigned[];
  status: string;
  refetchStatus?: RefetchFunctionType<StatusInterface, unknown>
}

const DUMMY_DATA = [
  {
    type: "",
    data: [],
  },
];

const TopInfo: React.FC<IDataProps> = ({
  shift,
  dateFormat,
  maxPage,
  pages,
  page,
  data = DUMMY_DATA,
  paginator = true,
  dataOperators = [],
  status,
  refetchStatus= () => {console.log('refetch')}
}) => {
  const classes = useStyles();

  const [time, setTime] = useState(new Date());

  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day} de ${month} del ${year}`;
  }

    const { refetch: dataUpdate } = useData<string[]>(
      {
        config: {
          url: "/shift-change/visualization/active",
          method: "PUT",
        },
        options: {
          manual: true,
        },
      },
      ErrorMsg.GET_STATUS_SHIFT_CHANGE
    );
  
    const handleUpdateStatus = useCallback(async () => {
      await dataUpdate({})
      await refetchStatus()
    }, [])

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    console.log(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const options = ["Descargar PDF", "Con asignación", "Sin asignación"];

  useEffect(() => {
    const tick = () => {
      setTime(new Date());
    };
    const intervalId = setInterval(tick, 60000);
    return () => clearInterval(intervalId);
  }, []);
  const formatTime = (date: Date) => {
    return new Intl.DateTimeFormat("es-ES", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    }).format(date);
  };
  return (
    <>
    <Grid container className={classes.container}>
      <Grid item className={classes.smallContainer}>
        <Box className={classes.shiftBox}>
          <SmallBox title={"Turno"} value={shift} icon={"SHIFT"} small={true} />
        </Box>
        <Box className={classes.dateBox}>
          <SmallBox
            title={"Fecha de hoy"}
            value={formatDate(dateFormat)}
            icon={"DATE"}
            small={false}
          />
        </Box>
        <Box className={classes.dateBox}>
          <SmallBox
            title={"Hora Actual"}
            value={formatTime(time)}
            icon={"HOUR"}
            small={true}
          />
        </Box>
      </Grid>
      {paginator ? (
        <Grid item className={classes.paginator}>
          <Grid container className={classes.paginatorContainer}>
            {pages &&
              pages.length > 0 &&
              pages.map((pageData, index) =>
                pageData.page === page ? (
                  <Grid
                    key={`${pageData.page}-${index}`}
                    className={classes.page}
                  >
                    {pageData.page}
                  </Grid>
                ) : (
                  <Grid
                    className={classes.pageWithoutColor}
                    key={`${pageData.page}-${index}`}
                  >
                    {pageData.page}
                  </Grid>
                )
              )}
          </Grid>
        </Grid>
      ) : (<></>)}
      </Grid>
      {!paginator && status!== 'UPDATING'? 
      (
        <Grid container className={classes.container}>
        <Grid item className={classes.containerButton}>
          <Grid item
          >
            <Button disabled={status==='ACTIVE'? true: false}
            className={[classes.whieButton, status==='ACTIVE' ? classes.desactivatedButton : ''].join(' ')}
            onClick={handleUpdateStatus}
            >  
              PUBLICAR ASIGNACIÓN</Button>
          </Grid>
          <Grid item>
            <Button className={classes.orangeButton}>
              <ShiftChangeCsvExport />
            </Button>
          </Grid>
    <PDFDownloadLink
      document={
        <ShiftChangeBusPDF
          shift={shift}
          dateFormat={dateFormat}
          dataOperators={data}
        />
      }
      style={{textDecoration: 'none'}}
      fileName={`BHP_Sin_Asignacion_Equipo_${dateFormat}_Turno-${shift}.pdf`}
    >
      {({ loading }) => (
        <Grid
          container
          className={classes.pdfButton}
        >
          {loading
            ? <>
              <Grid item>
                <SaveAltOutlinedIcon />
              </Grid>
              <Grid item style={{textDecoration: 'none'}}>
                Generando PDF
              </Grid>
            </>
            : <>
            <Grid item>
              <SaveAltOutlinedIcon />
            </Grid>
            <Grid item style={{textDecoration: 'none'}}>
              DESCARGA PDF SIN ASIGNACIÓN
            </Grid>
          </>}
        </Grid>
      )}
    </PDFDownloadLink>
          <PDFDownloadLink
            document={
              <ShiftChangePDF
                shift={shift}
                dateFormat={dateFormat}
                dataOperators={dataOperators}
              />
            }
            style={{textDecoration: 'none'}}
            fileName={`BHP_Con_Asignacion_Equipo_${dateFormat}_Turno-${shift}.pdf`}
          >
          {({ loading }) => (
            <Grid
              item
              className={classes.pdfButton}
            >
          {loading
            ? <>
              <Grid item>
                <SaveAltOutlinedIcon />
              </Grid>
              <Grid item style={{textDecoration: 'none'}}>
                Generando PDF
              </Grid>
            </>
            : <>
            <Grid item>
              <SaveAltOutlinedIcon />
            </Grid>
            <Grid item style={{textDecoration: 'none'}}>
              DESCARGA PDF CON ASIGNACIÓN
            </Grid>
          </>}
            </Grid>
          )}
        </PDFDownloadLink>
    </Grid>
    </Grid>
      )
      
      : (<></>)}
      
    </>
  );
};

export default TopInfo;

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  const { type } = palette;
  return {
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingLeft: 34,
      paddingRight: 22,
      marginTop: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        width: '100%',
        height: 200,
        gap: '16px',
        paddingLeft: '4px',
        paddingRight: '4px',
        marginTop: 8
      }
    },
    smallContainer: {
      flexDirection: "row",
      minWidth: 500,
      [theme.breakpoints.down('lg')]: {
        width: 725
      },
      width: 1132,
      height: 79,
      display: "inline-flex",
      alignItems: "flex-start",
      gap: "30px",
      [theme.breakpoints.down('md')]: {
        gap: "12px"
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        width: '100%',
        height: 200,
        gap: '16px'
      }
    },
    shiftBox: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      borderRadius: "4px",
      width: 230,
      [theme.breakpoints.down('md')]: {
        width: 150
      },
      height: 79,
      boxShadow: "none",
      [theme.breakpoints.down('sm')]: {
        height: 50
      }
    },
    dateBox: {
      display: "flex",
      justifyContent: "space-between",
      width: 524,
      [theme.breakpoints.down('md')]: {
        width: 380
      },
      height: 79,
      borderRadius: "6px",
      boxShadow: "none",
      [theme.breakpoints.down('sm')]: {
        height: 50,
        width: 330
      }
    },
    hourBox: {
      display: "flex",
      justifyContent: "space-between",
      width: 280,
      [theme.breakpoints.down('md')]: {
        width: 200,
      },
      height: 79,
      borderRadius: "6px",
      boxShadow: "none",
      [theme.breakpoints.down('sm')]: {
        width: 340,
        height: 50
      }
    },
    containerButton: {
      flex: "1 0 auto",
      paddingBottom: 18,
      paddingTop: 19,
      display: "flex",
      flexDirection: "row",
      justifyContent: 'flex-start',
      gap: "24.5px",
      [theme.breakpoints.down('md')]: {
        gap: "12px",
        paddingLeft: "0px",
        justifyContent: 'flex-start'
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '24px',
        paddingTop: '60px',
        alignItems: 'left',
        paddingLeft: '0px',
      }
    },
    buttonGroup: {
      width: 216,
      height: 42,
      color: "#EE8105",
      border: "1px solid #EE8105",
      flex: "1 1 0",
      [theme.breakpoints.down('md')]: {
        fontSize: "12px",
        width: 180
      },
    },
    paginatorContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    paginator: {
      flex: "1 1 0",
      paddingBottom: 12,
      paddingTop: 12,
      [theme.breakpoints.down('md')]: {
        paddingTop: 20
      },
    },
    page: {
      backgroundColor: "#F57C00",
      borderRadius: "27.5px",
      [theme.breakpoints.down('md')]: {
        width: '40px',
        height: '40px',
      },
      height: "55px",
      width: "55px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "18px",
      fontStyle: "normal",
      fontFamily: "Roboto",
      fontWeight: "normal",
    },
    pageWithoutColor: {
      [theme.breakpoints.down('md')]: {
        width: '40px',
        height: '40px',
      },
      height: "55px",
      width: "55px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "18px",
      fontStyle: "normal",
      fontFamily: "Roboto",
      fontWeight: "normal",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: "24px",
    },
    desactivatedButton: {
      border: `0.5px solid ${palette.text.disabled}`,
    },
    whieButton: {
      color: palette.text.primary,
      border: `0.5px solid ${palette.text.primary}`,
      width: 240,
      height: 42,
      borderRadius: "4px",
      textAlign: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Roboto",
      fontWeight: "normal",
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 22,
      paddingRight: 22,
      textDecoration: "none",
      cursor: "pointer",
      [theme.breakpoints.down('md')]: {
        fontSize: "13px",
        width: 240
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      '&:disabled': {
        border: `0.5px solid ${palette.text.disabled}`,
        color: palette.text.disabled,
      }
    },
    pdfButton: {
      color: palette.text.primary,
      border: `0.5px solid ${palette.text.primary}`,
      width: 350,
      height: 42,
      borderRadius: "4px",
      textAlign: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Roboto",
      fontWeight: "normal",
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 22,
      paddingRight: 22,
      textDecoration: "none",
      cursor: "pointer",
      gap: '12px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        flex: '1 1 auto',
        height: 80,
        paddingTop: 25,
        paddingBottom: 25
      },
      [theme.breakpoints.down('md')]: {
        fontSize: "13px",
        width: 320,
      },
    },
    orangeButton: {
      color: palette.text.primary,
      border: `0.5px solid ${palette.text.primary}`,
      width: 170,
      height: 42,
      borderRadius: "4px",
      textAlign: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "15px",
      fontStyle: "normal",
      fontFamily: "Roboto",
      fontWeight: "normal",
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 22,
      paddingRight: 22,
      textDecoration: "none",
      cursor: "pointer",
      [theme.breakpoints.down('md')]: {
        fontSize: "13px",
        width: 150
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
  };
});
