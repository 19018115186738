import React from "react";
import { makeStyles, SvgIconProps, Typography } from "@material-ui/core";
import { Label } from "components";
import clsx from "clsx";

interface TruckDetailItemClassesProps {
  root?: string;
}

interface TruckDetailItemProps {
  text?: string;
  label: string;
  icon: React.FC<SvgIconProps<"svg", {}>>;
  classes?: TruckDetailItemClassesProps;
  children?: React.ReactNode;
}

export const TruckDetailItem: React.FC<TruckDetailItemProps> = ({
  label,
  text,
  icon: Icon,
  classes: propClasses,
  children,
}) => {
  const classes = useStyles();
  //Hooks

  return (
    <div className={clsx(classes.truckItemRoot, propClasses?.root)}>
      <Label
        classes={{ text: classes.itemLabelText }}
        text={label}
        icon={Icon}
      />
      <Typography className={classes.itemText}>{text ?? "-"}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    truckItemRoot: {
      display: "flex",
      alignItems: "center",
      marginTop: 8,
      marginBottom: 8,
      marginRight: 60,
      maxWidth: 397,
      minWidth: 250,
      width: "100%",
      "&:last-child": {
        marginRight: 0,
      },
    },
    itemLabelText: {
      fontWeight: 700,
      width: 125,
      marginRight: 5,
    },
    itemText: {},
  };
});
