import { AutocompleteChangeReason } from "@material-ui/lab";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  CloseOnClick,
  CustomDialog,
  CustomDialogActionProps,
  AutoComplete,
} from "components";
import { makeStyles, TextField } from "@material-ui/core";
import { ErrorMsg, Truck, UnplannedParkedTruck } from "interfaces";
import { differenceBy } from "lodash";
import { useData } from "hooks";

interface AddUnplannedParkedTruckDialogProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
  unplannedParkedTrucks: UnplannedParkedTruck[];
}

type SelectedTrucks = Pick<UnplannedParkedTruck, "id">[];

type UpdateRequestBody = {
  id: UnplannedParkedTruck["id"];
  lost: boolean;
  comment: string;
}[];

export const AddUnplannedParkedTruckDialog: React.FC<
  AddUnplannedParkedTruckDialogProps
> = ({ open, onClose, onComplete, unplannedParkedTrucks }) => {
  const classes = useStyles();

  const {
    data: trucks,
    firstLoading,
    refetch: refetchTrucks,
  } = useData<Pick<Truck, "id">[]>(
    {
      config: "/operator-assignment/trucks",
    },
    ErrorMsg.GET_CAEXS
  );

  const { refetch: updateUnplannedTrucks } = useData<
    unknown,
    UpdateRequestBody
  >(
    {
      config: {
        url: "/operator-assignment/lost-trucks",
        method: "PATCH",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.GENERIC_UPDATE
  );

  const [selectedTrucks, setSelectedTrucks] = useState<SelectedTrucks>([]);
  const [comment, setComment] = useState("");

  const onCommentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value),
    []
  );

  const handleOnChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: SelectedTrucks,
      _reason: AutocompleteChangeReason
    ) => setSelectedTrucks(value),
    [setSelectedTrucks]
  );

  const handleOnComplete = useCallback(async () => {
    await updateUnplannedTrucks({
      data: selectedTrucks.map((t) => ({
        id: t.id,
        lost: true,
        comment,
      })),
    });
    onComplete();
    setSelectedTrucks([]);
    setComment("");
  }, [selectedTrucks, comment, onComplete, updateUnplannedTrucks]);

  const onCancelDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "CANCELAR",
      onClick: () => {
        setSelectedTrucks([]);
        setComment("");
      },
      closeOnClick: CloseOnClick.BEFORE,
    }),
    []
  );

  const onCompleteDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "AGREGAR",
      onClick: handleOnComplete,
      closeOnClick: CloseOnClick.BEFORE,
      disabled: !selectedTrucks.length,
    }),
    [handleOnComplete, selectedTrucks]
  );

  useEffect(() => {
    if (open) {
      refetchTrucks();
    }
  }, [refetchTrucks, open]);

  return (
    <CustomDialog
      classes={{ dialog: { paperFullWidth: classes.paper } }}
      open={open}
      onClose={onClose}
      title="Agregar CAEX y comentario"
      onCancelInfo={onCancelDialogProp}
      onCompleteInfo={onCompleteDialogProp}
    >
      <div className={classes.contentRoot}>
        <AutoComplete
          classes={{ root: classes.autoComplete }}
          value={selectedTrucks}
          options={differenceBy(trucks, unplannedParkedTrucks, "id") ?? []}
          onChange={handleOnChange}
          loading={firstLoading}
          loadingText="Cargando CAEXs"
          noOptionsText="No se encontraron CAEXs"
        />
        <TextField
          className={classes.commentInput}
          value={comment}
          onChange={onCommentChange}
          multiline
          maxRows={2}
          label="Agrega un comentario"
          helperText="Ej. Problemas de GPS, ubicación actual EST CH5"
        />
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 562,
  },
  contentRoot: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  autoComplete: {
    marginBottom: 25,
    marginLeft: 18,
    marginRight: 18,
    width: "100%",
    maxWidth: 480,
  },
  commentInput: {
    marginBottom: 37,
    marginLeft: 18,
    marginRight: 18,
    maxWidth: 480,
  },
}));
