import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ShiftChangeOperatorIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      {...props}
    >
      <path d="M17.0884 11.5842L18.5099 12.9808L22.5003 8.99041L18.5099 5L17.1133 6.39664L18.6845 7.9928H14.5195V9.98801H18.6845L17.0884 11.5842Z" />
      <path d="M12.0308 16.0986L10.6092 17.4952L6.6188 13.5048L10.6092 9.5144L12.0058 10.911L10.4346 12.5072H14.5996V14.5024H10.4346L12.0308 16.0986Z" />
      <path
        d="M2.9944 21.0306H6.44503L4.03569 23.4356H5.80021L8.20519 21.0306H22.4434L24.8484 23.4356H26.6085L24.2036 21.0306H27.0921C27.9156 21.0306 28.5865 20.3597 28.5865 19.5362V3.05861C28.5865 2.23516 27.9156 1.56421 27.0921 1.56421H2.9944C2.17096 1.56421 1.5 2.23516 1.5 3.05861V19.5362C1.5 20.3597 2.17096 21.0306 2.9944 21.0306ZM2.74606 3.05861C2.74606 2.92355 2.85934 2.81027 2.9944 2.81027H27.0965C27.2316 2.81027 27.3448 2.92355 27.3448 3.05861V19.5362C27.3448 19.6713 27.2316 19.7846 27.0965 19.7846H2.9944C2.85934 19.7846 2.74606 19.6713 2.74606 19.5362V3.05861Z"
        stroke-width="1.35433"
      />
    </SvgIcon>
  );
};
