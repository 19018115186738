import {
  Chip,
  Typography,
  ListItem,
  Theme,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import {
  Operator,
  OperatorEditableInfo,
  OperatorStatusType,
  statusTranslation,
  Can,
  Module,
} from "interfaces";
import React, { useMemo, CSSProperties, useState, useCallback } from "react";
import {
  FavoriteBorder,
  CheckCircle,
  WarningRounded,
  LocationOnOutlined,
  DeleteOutlineRounded,
} from "@material-ui/icons";
import { ArticleOutlined, SmartToyIcon, TruckIcon } from "components/icons";
import { operatorStatusColors } from "styles";
import clsx from "clsx";
import { Label, Restricted, Selector, SelectorOptions } from "components";
import { OperatorAlert } from "./OperatorAlert";

interface OperatorListEditableItemProps {
  operator: Operator;
  className?: string;
  style: CSSProperties;
  locations: SelectorOptions[];
  buses: SelectorOptions[];
  onChange: (payload: OperatorEditableInfo) => void;
  onDelete: (id: string, name: string) => void;
}

export const OperatorListEditableItem: React.FC<
  OperatorListEditableItemProps
> = ({ operator, onChange, onDelete, style, locations, buses }) => {
  //Hooks
  const classes = useStyles({
    operStatus: operator.status,
  });

  //Local state
  const [operEditableInfo, setOperEditableInfo] =
    useState<OperatorEditableInfo>({
      operId: operator.id,
      locationId: operator?.location?.id,
      busId: operator?.bus?.id,
      isInstructor: operator?.isInstructor ?? false,
    });

  //Logic
  const entryClasses = useMemo(() => {
    return {
      root: classes.detailInfo,
      icon: classes.icon,
      text: classes.infoText,
    };
  }, [classes]);

  const truckText = useMemo(
    () =>
      operator.preassembledEquip?.id ??
      `${operator.currentEquip?.id ?? "-"} | ${
        operator.currentEquip?.reasonId ?? "-"
      }`,
    [operator]
  );

  const isLocationAndBusEditable = useMemo(
    () => operator.status !== OperatorStatusType.ASSEMBLED,
    [operator]
  );

  const isDeletable = useMemo(
    () =>
      ![
        OperatorStatusType.ASSEMBLED,
        OperatorStatusType.PRE_ASSEMBLED,
        OperatorStatusType.RELAY,
      ].includes(operator.status),
    [operator]
  );
  const operatorLocationIcon = useMemo(
    () => (operator.location?.isAutomatic ? SmartToyIcon : LocationOnOutlined),
    [operator]
  );

  const onLocationChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      setOperEditableInfo((prev) => ({
        ...prev,
        locationId: e.target.value as number,
      }));
      onChange({ ...operEditableInfo, locationId: e.target.value as number });
    },
    [onChange, operEditableInfo]
  );

  // const onBusChange = useCallback(
  //   (e: React.ChangeEvent<{ value: unknown }>) => {
  //     setOperEditableInfo((prev) => ({
  //       ...prev,
  //       busId: e.target.value as number,
  //     }));
  //     onChange({ ...operEditableInfo, busId: e.target.value as number });
  //   },
  //   [onChange, operEditableInfo]
  // );

  const onInstructorChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOperEditableInfo((prev) => ({
        ...prev,
        isInstructor: e.target.checked,
      }));
      onChange({ ...operEditableInfo, isInstructor: e.target.checked });
    },
    [onChange, operEditableInfo]
  );

  return (
    <ListItem className={classes.listItemOperDetail} style={style}>
      <Paper elevation={0} className={clsx(classes.operatorDetailPaper)}>
        <div className={clsx(classes.cellSizeXL, classes.cell)}>
          <Typography className={classes.text} noWrap variant="body1">
            {operator.name ?? "-"}
          </Typography>
        </div>
        <div className={clsx(classes.alertContainer, classes.cellSizeS, classes.cell)}> 
          <OperatorAlert operator={ operator } />
        </div>
        <div className={clsx(classes.cellSizeM, classes.cell)}>
          {isLocationAndBusEditable ? (
            <Selector
              onChange={onLocationChange}
              icon={operatorLocationIcon}
              value={operEditableInfo.locationId}
              options={locations}
              classes={{
                selector: classes.selector,
                root: classes.selectorRoot,
              }}
            />
          ) : (
            <Label
              classes={entryClasses}
              icon={operatorLocationIcon}
              text={operator?.location?.name ?? "-"}
            />
          )}
        </div>
        <div className={clsx(classes.cellSizeM, classes.cell)}>
          <Label classes={entryClasses} icon={TruckIcon} text={truckText} />
        </div>
        <div className={clsx(classes.cellSizeM, classes.cell)}>
          <Label
            classes={entryClasses}
            icon={FavoriteBorder}
            text={operator.preferredEquip?.id ?? "-"}
          />
        </div>
        <div className={clsx(classes.cellSizeL, classes.cell)}>
          <Label
            classes={entryClasses}
            icon={ArticleOutlined}
            text={operator.area ?? "-"}
          />
        </div>
        <div className={clsx(classes.cellSizeM, classes.cell)}>
          <Chip
            classes={{
              root: classes.statusChip,
              icon: classes.chipIcon,
            }}
            icon={
              operator.status === OperatorStatusType.AVAILABLE ? (
                <CheckCircle />
              ) : (
                <WarningRounded />
              )
            }
            label={
              <Typography variant="body2">
                <strong>{statusTranslation[operator.status] ?? "-"}</strong>
              </Typography>
            }
          />
        </div>
        <div className={clsx(classes.cellSizeS, classes.cell)}>
          {isDeletable && (
            <Checkbox
              checked={operEditableInfo.isInstructor}
              onChange={onInstructorChange}
              color={"primary"}
            />
          )}
        </div>
        {/* <div className={clsx(classes.cellSizeM, classes.cell)}>
          {isLocationAndBusEditable && (
            <Selector
              onChange={onBusChange}
              icon={LocationOnOutlined}
              value={operEditableInfo.busId}
              options={buses}
              classes={{
                selector: classes.selector,
                root: classes.selectorRoot,
              }}
            />
          )}
        </div> */}
        <Restricted to={Can.WRITE} at={Module.OPERATOR_ASSIGNMENT}>
          <div className={clsx(classes.cellSizeS, classes.cell)}>
            {isDeletable && (
              <IconButton
                classes={{ root: classes.iconButton }}
                aria-label="delete operator"
                component="span"
                onClick={() => onDelete(operator.id, operator.name)}
              >
                <DeleteOutlineRounded />
              </IconButton>
            )}
          </div>
        </Restricted>
      </Paper>
    </ListItem>
  );
};

interface StyleProps {
  operStatus?: OperatorStatusType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  const { palette } = theme;
  return {
    operatorDetailPaper: {
      display: "flex",
      backgroundColor: palette.background.paper,
      width: "100%",
      alignItems: "center",
      padding: 6,
      gap: 0,
      border: "2px solid transparent",
    },
    radioButton: {
      maxWidth: 40,
      flexGrow: 1,
    },
    cell: {
      display: "flex",
      paddingRight: 10,
    },
    cellSizeXL: {
      width: "100%",
      maxWidth: 300,
      justifyContent: "space-between",
    },
    cellSizeL: {
      width: "100%",
      maxWidth: 219,
    },
    cellSizeM: {
      width: "100%",
      maxWidth: 200,
    },
    cellSizeS: {
      maxWidth: 120,
      justifyContent: "center",
      width: "100%",
    },
    text: {
      fontWeight: 700,
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 8,
    },
    iconButton: {
      padding: 6,
    },
    detailInfo: {
      display: "flex",
      alignItems: "center",
      marginBottom: 6,
      marginTop: 4,
      maxWidth: 200,
    },
    statusChip: {
      backgroundColor: ({ operStatus }) =>
        operatorStatusColors({
          theme,
          statusType: operStatus,
          opacityColor: true,
        }),
      color: ({ operStatus }) =>
        operatorStatusColors({
          theme,
          statusType: operStatus,
          color: true,
        }),
      width: "fit-content",
      alignSelf: "end",
    },
    chipIcon: {
      color: ({ operStatus }) =>
        operatorStatusColors({
          theme,
          statusType: operStatus,
          color: true,
        }),
      fontSize: theme.typography.body1.fontSize,
    },
    infoText: {
      fontWeight: "normal",
    },
    listItemOperDetail: {
      padding: "4px 8px",
      "&:last-child": {
        paddingBottom: 8,
      },
    },
    paperClicked: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    paperHover: {
      "&:hover": {
        border: `2px solid ${theme.palette.secondary.light}`,
      },
    },
    selector: {
      width: "inherit",
    },
    selectorRoot: {
      width: "100%",
      maxWidth: 160,
    },
  };
});
