import React, { useCallback, useState } from "react";
import {
  Grid,
  alpha,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  ColumnData,
  Config,
  HeatMapData,
  TooltiCycleTime,
  TooltipValues,
  FiltersAutocomplete,
  TooltipUnit,
} from "interfaces/heatMap.interface";
import { AutoComplete } from "components/AutoComplete";
import { AutocompleteChangeReason } from "@material-ui/lab";
import clsx from "clsx";
import { WithoutData } from "components/WithoutData";
import { InfoOutlined } from "@material-ui/icons";
import { Tooltip as TooltipComponent } from "components";
import { parseNumber } from "utils";

export interface HeatMapProps {
  dataTable: HeatMapData[];
  columnConfig: Config[];
  initialColumns: string[];
  filtersAutocomplete: FiltersAutocomplete[];
  openModal?: () => void;
  updateSelectedFilters: (items: FiltersAutocomplete[]) => void;
  selectedFilters: FiltersAutocomplete[];
  filtersTitle: string;
  title?: string;
  tooltipTitle?: InfoTooltipText;
}
interface selectedFilter {
  id: number;
  name: string;
}

export type InfoTooltipText = {
  title: string;
  values: {
    section: string;
    text: string;
  }[];
  footer: {
    section: string;
    text: string;
  };
};

export const TableHeatMapComponent: React.FC<HeatMapProps> = ({
  dataTable,
  columnConfig,
  initialColumns,
  filtersAutocomplete,
  openModal,
  selectedFilters,
  updateSelectedFilters,
  filtersTitle,
  title,
  tooltipTitle: infoTooltipTexts,
}) => {
  const classes = useStyles();
  const [colorRow, setColorRow] = useState<null | number>(null);
  const [colorCol, setColorCol] = useState<null | number>(null);

  //sets the opacity of cells that are not selected with the cursor
  const setOpacity: number = 0.25;
  const defaultOpacity: number = 1;

  const handleCellClick = (cell: ColumnData) => {
    openModal && openModal();
  };

  const handleMouseEnter = (rowIndex: number, colIndex: number) => {
    setColorRow(rowIndex);
    setColorCol(colIndex);
  };
  const handleMouseLeave = () => {
    setColorRow(null);
    setColorCol(null);
  };

  const findSpaces = (array: Config[]) => {
    return array
      .map((item: Config, index: number) =>
        item?.hasSpaceBefore ? index : null
      )
      .filter((index: number | null) => index !== null);
  };

  const indexWithSpace = findSpaces(columnConfig);
  const theme = useTheme();

  const legend = [
    { color: theme.palette.heatMap["VERY_GOOD"], title: "Cumple" },
    { color: theme.palette.heatMapLegend.other, title: "Fuera de Plan" },
    { color: theme.palette.heatMap["REGULAR"], title: "Precaución" },
    { color: theme.palette.heatMap["BAD"], title: "No Cumple" },
  ];

  const handleOnChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: selectedFilter[],
      _reason: AutocompleteChangeReason
    ) => {
      updateSelectedFilters(value);
    },
    [updateSelectedFilters]
  );

  const tooltipContent = (
    data: ColumnData,
    title: string,
    tooltip: TooltipValues[]
  ) => {
    const subtitle = TooltiCycleTime[data?.id] ?? "";
    const unit = TooltipUnit[data?.id] ?? "";
    return (
      <>
        <Grid container className={classes.tooltipContainer}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <strong>{title}</strong>
            </Typography>
            <Typography variant="subtitle2">
              <strong>{subtitle}</strong>
            </Typography>
          </Grid>
          {tooltip.map((item, index) => (
            <Grid key={index} item xs={12}>
              <Typography variant="subtitle2">
                {item.variableName !== null ? (
                  <>
                    <b>
                      {" "}
                      {item.value !== undefined && item.value === null
                        ? `${item.variableName}: `
                        : item.value === undefined ? `${item.variableName} `: `${item.variableName}: ` }
                    </b>
                    {typeof item.value === "number"
                      ? `${parseNumber(item.value, 1)}`
                      : item.value === null
                      ? "-"
                      : item.value}
                    {typeof item.value === "number" && (item.value !== null && item.value !== undefined) ? item.unit ? ` ${item.unit}` : ` ${unit}`: ""}
                  </>
                ) : (
                  <b>{item.value}</b>
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Paper className={classes.performancePaper} elevation={4}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" style={{ paddingBottom: 30 }}>
            <Grid item xs={4}>
              <div className={classes.contentHeaderSections}>
                {title && (
                  <div className={classes.contentHeader}>
                    <Typography className={classes.sectionTitle} variant="h6">
                      <strong>{title}</strong>
                    </Typography>
                    {infoTooltipTexts && (
                      <TooltipComponent
                        title={
                          <>
                            <Typography
                              variant="subtitle1"
                              style={{ paddingBottom: 20, fontWeight: 700 }}
                            >
                              {infoTooltipTexts.title}
                            </Typography>
                            {infoTooltipTexts.values.map(
                              ({ text, section }) => (
                                <Typography key={text} variant="body1">
                                  <strong>{`${section}: `}</strong>
                                  {`${text}.`}
                                </Typography>
                              )
                            )}
                            <Typography style={{ paddingTop: 20 }}>
                              <b>{infoTooltipTexts.footer.section} :</b>{" "}
                              {infoTooltipTexts.footer.text}
                            </Typography>
                          </>
                        }
                        arrow
                        placement="right"
                        maxWidth="620px"
                      >
                        <InfoOutlined className={classes.infoIconTooltip} />
                      </TooltipComponent>
                    )}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={4}>
              <Grid container alignItems="center">
                {legend.map(({ color, title }, index) => (
                  <div key={index} className={classes.legend}>
                    <div
                      className={classes.colorSquare}
                      style={{ background: color }}
                    ></div>
                    <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                      {title}
                    </Typography>
                  </div>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item xs={4} className={classes.filterTitle}>
                  {filtersTitle}
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <AutoComplete
                      classes={{
                        root: classes.autoCompleteRoot,
                        tag: classes.tagsAutocomplete,
                      }}
                      size="small"
                      value={selectedFilters}
                      options={filtersAutocomplete}
                      onChange={handleOnChange}
                      loading={false}
                      loadingText="Cargando filtros"
                      noOptionsText="No se encontraron filtros"
                      fuzzySearch
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer
            className={clsx(classes.mainContainer, classes.customScrollbar)}
          >
            {dataTable.length ? (
              <Table stickyHeader className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {initialColumns.map((columnName, index) => (
                      <TableCell
                        key={`def-index-${index}`}
                        className={classes.columnName}
                      >
                        <Typography
                          variant="subtitle1"
                          className={classes.boldText}
                        >
                          {columnName}
                        </Typography>
                      </TableCell>
                    ))}
                    {columnConfig.map((row, index) => (
                      <React.Fragment key={`${index}`}>
                        {indexWithSpace.includes(index) && (
                          <TableCell
                            key={index}
                            className={classes.emptyCellValue}
                          ></TableCell>
                        )}
                        <TableCell
                          key={`column-title-${index}`}
                          className={classes.columnName}
                        >
                          <Typography
                            key={index}
                            variant="subtitle1"
                            className={classes.boldText}
                          >
                            {row.name}
                          </Typography>
                        </TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable?.map((item, rowIndex) => (
                    <TableRow key={`Row-${rowIndex}`}>
                      <TableCell
                        key={`Col-${rowIndex}`}
                        className={classes.rowName}
                        onMouseEnter={() => handleMouseEnter(rowIndex, -1)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          opacity:
                            colorCol === null ||
                            colorCol === -1 ||
                            colorRow === null ||
                            colorRow === rowIndex
                              ? defaultOpacity
                              : setOpacity,
                          background: theme.palette.heatMap["GOOD"],
                          color: theme.palette.common.black,
                        }}
                      >
                        <Typography
                          key={rowIndex}
                          variant="subtitle1"
                          className={classes.colorText}
                        >
                          {item.id}
                        </Typography>
                      </TableCell>
                      <TableCell
                        key={`Col-des-${rowIndex}`}
                        className={classes.rowName}
                        onMouseEnter={() => handleMouseEnter(rowIndex, -2)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          opacity:
                            colorCol === null ||
                            colorCol === -2 ||
                            colorRow === null ||
                            colorRow === rowIndex
                              ? defaultOpacity
                              : setOpacity,
                          background: theme.palette.heatMap["GOOD"],
                          color: theme.palette.common.black,
                        }}
                      >
                        <Typography
                          key={rowIndex}
                          variant="subtitle1"
                          className={classes.colorText}
                        >
                          {item.destination}
                        </Typography>
                      </TableCell>
                      {item.data.map((data, colIndex) => (
                        <React.Fragment key={`Frag-${colIndex}`}>
                          {indexWithSpace.includes(colIndex) && (
                            <TableCell
                              key={`${rowIndex}_${colIndex}`}
                              className={classes.emptyCellValue}
                            ></TableCell>
                          )}
                          <TableCell
                            key={`Row-${rowIndex}-Col-${colIndex}`}
                            className={classes.cellValue}
                            onClick={() => handleCellClick(data)}
                            onMouseEnter={() =>
                              handleMouseEnter(rowIndex, colIndex)
                            }
                            onMouseLeave={handleMouseLeave}
                            style={{
                              opacity:
                                colorCol === null ||
                                colorCol === colIndex ||
                                colorRow === null ||
                                colorRow === rowIndex
                                  ? defaultOpacity
                                  : setOpacity,
                              background: theme.palette.heatMap[data.color],
                              color: theme.palette.heatMapText[data.color],
                            }}
                          >
                            {data.tooltip ? (
                              <Tooltip
                                title={tooltipContent(
                                  data,
                                  item.id,
                                  data.tooltip
                                )}
                                classes={{
                                  tooltip: classes.tooltip,
                                  arrow: classes.arrow,
                                }}
                                arrow
                                interactive
                                placement="bottom"
                              >
                                <Typography variant="subtitle1">
                                  {data.target !== null && data.value
                                    ? typeof data.target === "number"
                                      ? `${data.value} (${parseNumber(
                                          data.target,
                                          1
                                        )}) ${data.referencialPlan ? '*': ''}` 
                                      : data.value
                                    : data.value}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography variant="subtitle1">
                                {data.target !== null && data.value
                                  ? `${data.value} (${data.target})`
                                  : data.value}
                              </Typography>
                            )}
                          </TableCell>
                        </React.Fragment>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <WithoutData
                title={`No se encontraron datos para generar esta visualización.`}
                subtitle={"Intente modificar los filtros seleccionados"}
              />
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    rowName: {
      height: "48px",
      color: palette.secondary.main,
      border: "1px solid",
      borderColor: alpha(palette.divider, 0.12),
      fontSize: "12px",
      textAlign: "left",
      padding: "0px 8px 0px 8px",
    },
    boldText: {
      fontWeight: 600,
      color: palette.text.primary,
    },
    colorText: {
      color: palette.text.primary,
    },
    columnName: {
      height: "30px",
      color: palette.text.primary,
      padding: "0px 8px 0px 8px",
      textAlign: "center",
      border: "1px solid",
      borderColor: alpha(palette.divider, 0.12),
      background: palette.background.paper,
    },
    cellValue: {
      textAlign: "center",
      border: "1px solid",
      borderColor: alpha(palette.divider, 0.12),
      height: "48px",
      padding: "0px 8px 0px 8px",
      borderSpacing: "0px",
      cursor: "pointer",
    },
    emptyCellValue: {
      background: palette.background.paper,
      border: "unset",
      minWidth: "10px",
      padding: "0px",
    },
    table: {
      borderSpacing: "0px",
      width: "100%",
      overflowY: "auto",
    },
    fontCell: {
      fontSize: "12px",
    },
    sectionTitle: {
      lineHeight: "24px",
      paddingRight: "10px",
    },
    contentHeaderSections: {
      display: "flex",
      marginRight: 25,
      alignItems: "center",
    },
    performancePaper: {
      padding: 24,
      width: "100%",
    },
    mainContainer: {
      maxHeight: "328px",
    },
    tooltip: {
      background: palette.secondary.main,
      borderRadius: "4px",
      boxShadow:
        "0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
      padding: "16px",
    },
    arrow: {
      color: palette.secondary.main,
    },
    tooltipContainer: {
      display: "inline-flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    tooltipBody: {
      color: palette.text.primary,
    },
    tableHead: {
      border: "1px solid",
      borderColor: alpha(palette.divider, 0.12),
      height: "40px",
      opacity: "100%",
    },
    customScrollbar: {
      "&::-webkit-scrollbar": {
        with: "20px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "red",
      },
    },
    legend: {
      display: "flex",
      marginRight: 30,
    },
    colorSquare: {
      width: 20,
      height: 20,
      marginRight: 6,
    },
    autoCompleteRoot: {
      // width: "280px",
      marginBottom: 0,
      marginTop: 0,
      "& input": {
        height: 20,
      },
      padding: 4,
    },
    tagsAutocomplete: {
      height: 25,
      fontSize: 13,
    },
    filterTitle: {
      justifyContent: "flex-end",
      display: "flex",
      paddingRight: "15px",
      textTransform: "uppercase",
      letterSpacing: 1,
      fontSize: 12,
      fontWeight: 400,
      color: palette.text.secondary,
    },
    infoIconTooltip: {
      lineHeight: "24px",
      marginRight: 10,
      width: 20,
      height: 20,
    },
    contentHeader: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: 23,
      marginBottom: 23,
    },
  };
});
