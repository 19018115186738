import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const NoOperatorTruckIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="56"
      viewBox="0 0 42 56"
      fill="none"
      {...props}
    >
      <g style={{ mixBlendMode: "multiply" }} opacity="0.18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2355 46.3493L16.1104 44.5451L38.8742 31.4022L41.9994 33.2064L19.2355 46.3493Z"
          fill="#91BED4"
        />
      </g>
      <path
        d="M19.4577 45.0928L18.8517 44.7403L18.7606 12.5707L19.3666 12.9227L19.4577 45.0928Z"
        fill="#E58A2E"
      />
      <path
        d="M19.3668 12.9223L18.7608 12.5704L37.2392 1.90179L37.8452 2.25377L19.3668 12.9223Z"
        fill="#F5B147"
      />
      <path
        d="M37.8454 2.25458L37.9365 34.4246L19.4581 45.0932L19.3671 12.9232L37.8454 2.25458Z"
        fill="url(#paint0_linear_4613_146066)"
      />
      <path
        d="M36.7014 5.07092L20.7523 14.2831L20.8319 42.3723L36.7851 33.1619L36.7055 5.07275L36.7014 5.07092Z"
        fill="#F8F6EE"
      />
      <path
        d="M31.667 7.84945L31.0928 7.51587C31.107 7.51038 31.6661 7.849 31.678 7.85129C31.6743 7.85083 31.6706 7.85037 31.667 7.84991V7.84945Z"
        fill="#2D2D2E"
      />
      <path
        d="M31.678 7.85134L31.1038 7.51776C31.129 7.51272 31.6757 7.85042 31.6972 7.85592C31.6913 7.85409 31.6848 7.85271 31.6784 7.85134H31.678Z"
        fill="#2C2C2D"
      />
      <path
        d="M31.6976 7.8561L31.1234 7.52252C31.1445 7.52252 31.6966 7.85564 31.7159 7.86252C31.7099 7.86023 31.704 7.85793 31.6976 7.8561Z"
        fill="#2B2A2C"
      />
      <path
        d="M31.7152 7.86184L31.1411 7.52826C31.1613 7.53513 31.7134 7.86092 31.7331 7.87055C31.7276 7.86734 31.7217 7.86459 31.7157 7.86184H31.7152Z"
        fill="#2A292A"
      />
      <path
        d="M31.7329 7.87044L31.1588 7.53687C31.1588 7.53687 31.7338 7.8709 31.7352 7.87182L31.7334 7.8709L31.7329 7.87044Z"
        fill="#282829"
      />
      <path
        d="M30.5629 9.22279L29.9887 8.88921L30.0515 8.5011L30.6256 8.83468L30.5629 9.22279Z"
        fill="#262527"
      />
      <path
        d="M30.2301 8.96704L29.6566 8.63383L30.0511 8.5011L30.6246 8.83431L30.2301 8.96704Z"
        fill="#CBCBCB"
      />
      <path
        d="M30.5155 9.23493L29.9414 8.90135L29.9891 8.88531L30.5632 9.21889L30.5155 9.23493Z"
        fill="#373637"
      />
      <path
        d="M30.2162 8.96548L29.642 8.6319H29.6439L30.218 8.96502H30.2162V8.96548Z"
        fill="#B7B8B8"
      />
      <path
        d="M30.2181 8.9653L29.644 8.63172C29.6481 8.6308 29.6522 8.62943 29.6568 8.62805L30.2309 8.96163C30.2268 8.96301 30.2227 8.96438 30.2181 8.9653Z"
        fill="#BCBDBD"
      />
      <path
        d="M30.2167 8.97038L29.6427 8.63717L31.2607 7.703L31.8347 8.03621L30.2167 8.97038Z"
        fill="#383739"
      />
      <path
        d="M29.7784 9.22172L29.2045 8.88852L29.642 8.63586L30.216 8.96907L29.7784 9.22172Z"
        fill="#CFD0D0"
      />
      <path
        d="M30.1331 8.96998L29.5596 8.63677L31.2498 7.66095L31.8234 7.99416L30.1331 8.96998Z"
        fill="#DBDBDB"
      />
      <path
        d="M30.0356 8.78419L29.4616 8.45098L31.0901 7.51086L31.6641 7.84407L30.0356 8.78419Z"
        fill="#383739"
      />
      <path
        d="M31.5932 7.85226L31.0191 7.51868C31.0241 7.51776 31.0291 7.51685 31.0337 7.51593L31.6079 7.84951C31.6028 7.85043 31.5978 7.85134 31.5932 7.85226Z"
        fill="#C2C2C2"
      />
      <path
        d="M31.608 7.85089L31.0339 7.51731C31.0417 7.51593 31.0495 7.51547 31.0572 7.51501L31.6314 7.84859C31.6236 7.84859 31.6158 7.84951 31.608 7.85089Z"
        fill="#BDBDBD"
      />
      <path
        d="M31.6319 7.84834L31.0578 7.51477C31.0866 7.49598 31.6296 7.84743 31.653 7.8488C31.6461 7.8488 31.6392 7.84835 31.6319 7.8488V7.84834Z"
        fill="#B8B8B8"
      />
      <path
        d="M31.652 7.84873L31.0778 7.51515C31.0907 7.50736 31.6524 7.84873 31.663 7.8501C31.6593 7.8501 31.6556 7.84918 31.652 7.84918V7.84873Z"
        fill="#B3B3B3"
      />
      <path
        d="M31.8342 8.03369C31.9568 8.68592 30.9874 8.99258 30.5856 9.26537C30.5627 9.2553 30.5389 9.24477 30.5146 9.2347L30.5622 9.21868L30.6249 8.83101C30.6075 8.8365 30.2336 8.96374 30.2157 8.96786L31.8337 8.03369H31.8342Z"
        fill="#323133"
      />
      <path
        d="M29.8885 9.34127L29.3144 9.00769C29.3144 9.00769 29.889 9.34173 29.8917 9.3431L29.8885 9.34127Z"
        fill="#282829"
      />
      <path
        d="M29.8919 9.34316L29.3177 9.00958C29.3269 9.01462 29.3361 9.01921 29.3457 9.02333L29.9198 9.35691C29.9102 9.35278 29.901 9.3482 29.8919 9.34316Z"
        fill="#2A292A"
      />
      <path
        d="M29.9198 9.35775L29.3457 9.02417C29.3553 9.02829 29.3649 9.0315 29.375 9.03471L29.9491 9.36829C29.9391 9.36554 29.9294 9.36187 29.9198 9.35775Z"
        fill="#2B2A2C"
      />
      <path
        d="M29.9485 9.36727L29.3743 9.03369C29.3835 9.03644 29.3931 9.03873 29.4028 9.04056L29.9769 9.37414C29.9673 9.37231 29.9577 9.37002 29.9485 9.36727Z"
        fill="#2C2C2D"
      />
      <path
        d="M29.7612 9.18419L29.1873 8.85098L29.5598 8.63586L30.1333 8.96907L29.7612 9.18419Z"
        fill="#DBDBDB"
      />
      <path
        d="M30.1092 8.95718L29.5351 8.6236C29.5351 8.6236 30.1097 8.95763 30.1106 8.95809L30.1092 8.95718Z"
        fill="#959696"
      />
      <path
        d="M30.1107 8.95815L29.5365 8.62457C29.5503 8.62961 30.1097 8.95769 30.123 8.96411C30.1189 8.96228 30.1148 8.96044 30.1107 8.95815Z"
        fill="#9A9B9B"
      />
      <path
        d="M30.1226 8.96389L29.5485 8.63031C29.5627 8.63123 30.1212 8.96297 30.1341 8.96801C30.1299 8.96664 30.1263 8.96526 30.1226 8.96389Z"
        fill="#9FA0A0"
      />
      <path
        d="M30.1085 8.95844C30.0224 8.87871 29.4349 8.65006 29.4638 8.48969L30.038 8.82326C30.0425 8.88833 30.0682 8.93507 30.1081 8.95798L30.1085 8.95844Z"
        fill="#222122"
      />
      <path
        d="M30.0389 8.82486L29.4643 8.49082L29.4616 8.45233L30.0362 8.78591L30.0389 8.82486Z"
        fill="#222122"
      />
      <path
        d="M29.8895 9.33861C29.7906 9.26484 29.2462 9.01098 29.2054 8.88818L29.7796 9.22176C29.8071 9.27308 29.8442 9.31249 29.8895 9.33861Z"
        fill="#7D7E7E"
      />
      <path
        d="M29.7624 9.18359L29.1882 8.85001C29.1703 8.8051 29.1589 8.75332 29.1548 8.69513L29.7289 9.02871C29.733 9.0869 29.7445 9.13868 29.7624 9.18359Z"
        fill="#7D7E7E"
      />
      <path
        d="M29.7282 9.0317L29.1536 8.69812L29.1495 8.63397L29.7237 8.96755L29.7282 9.0317Z"
        fill="#7E7E7E"
      />
      <path
        d="M27.8147 10.3556L27.2412 10.0224L29.2039 8.88916L29.7778 9.22237L27.8147 10.3556Z"
        fill="#383739"
      />
      <path
        d="M27.8329 10.2982L27.2589 9.965L29.1872 8.85187L29.7612 9.18507L27.8329 10.2982Z"
        fill="#DBDBDB"
      />
      <path
        d="M27.8697 10.0377L27.2957 9.70408L29.1499 8.63397L29.7234 8.96718L27.8697 10.0377Z"
        fill="#383739"
      />
      <path
        d="M29.723 8.96587L29.1495 8.63267L29.4617 8.45233L30.0356 8.78554L29.723 8.96587Z"
        fill="#CFD0D0"
      />
      <path
        d="M27.7172 10.819C27.6366 10.7667 27.0936 10.4717 27.0391 10.408C27.1261 10.4565 27.6526 10.7617 27.7172 10.819Z"
        fill="#222122"
      />
      <path
        d="M27.6121 10.7426L27.038 10.409C27.0481 10.3957 27.0577 10.3824 27.0673 10.3687L27.6415 10.7022C27.6318 10.716 27.6222 10.7293 27.6121 10.7426Z"
        fill="#302F31"
      />
      <path
        d="M27.6418 10.7026L27.0676 10.369C27.0786 10.3534 27.0896 10.3374 27.0997 10.3209L27.6738 10.6544C27.6633 10.6705 27.6528 10.6865 27.6418 10.7026Z"
        fill="#2F2E30"
      />
      <path
        d="M27.6733 10.6548L27.0992 10.3212C27.1092 10.3057 27.1189 10.2901 27.128 10.274L27.7022 10.6076C27.693 10.6237 27.6834 10.6392 27.6733 10.6548Z"
        fill="#2D2D2E"
      />
      <path
        d="M27.7025 10.6066L27.1283 10.273C27.1375 10.2574 27.1462 10.2418 27.1544 10.2263L27.7286 10.5598C27.7203 10.5754 27.7116 10.5915 27.7025 10.6066Z"
        fill="#2C2C2D"
      />
      <path
        d="M27.7283 10.5592L27.1541 10.2257C27.1624 10.2101 27.1706 10.194 27.178 10.1785L27.7521 10.512C27.7443 10.5281 27.7365 10.5437 27.7283 10.5592Z"
        fill="#2B2A2C"
      />
      <path
        d="M27.7526 10.5124L27.1785 10.1788C27.1863 10.1628 27.1936 10.1467 27.2005 10.1307L27.7746 10.4643C27.7678 10.4803 27.7604 10.4968 27.7526 10.5124Z"
        fill="#2A292A"
      />
      <path
        d="M27.7742 10.4664L27.2 10.1328C27.2073 10.1163 27.2142 10.0998 27.2206 10.0829L27.7948 10.4165C27.7884 10.433 27.7815 10.4499 27.7742 10.4664Z"
        fill="#282829"
      />
      <path
        d="M27.7947 10.4137L27.2206 10.0801C27.2274 10.0627 27.2334 10.0453 27.2393 10.0274L27.8135 10.361C27.8075 10.3784 27.8016 10.3958 27.7947 10.4137Z"
        fill="#272628"
      />
      <path
        d="M27.8138 10.3626L27.2397 10.0291L27.242 10.0226L27.8161 10.3562C27.8161 10.3562 27.8147 10.3608 27.8138 10.3626Z"
        fill="#262526"
      />
      <path
        d="M27.8323 10.2955L27.2582 9.96197C27.2632 9.94318 27.2678 9.92485 27.2719 9.90607L27.8461 10.2396C27.8419 10.2584 27.8373 10.2768 27.8323 10.2955Z"
        fill="#242425"
      />
      <path
        d="M27.8457 10.2397L27.2715 9.90611C27.277 9.87999 27.2816 9.85433 27.2848 9.82867L27.859 10.1623C27.8558 10.1879 27.8512 10.2136 27.8457 10.2397Z"
        fill="#232324"
      />
      <path
        d="M27.8595 10.163L27.2854 9.82944C27.2881 9.80927 27.29 9.78911 27.2909 9.76941L27.865 10.103C27.8637 10.1227 27.8618 10.1429 27.8595 10.163Z"
        fill="#222122"
      />
      <path
        d="M27.8651 10.1063L27.2905 9.77268L27.2946 9.70349L27.8692 10.0375L27.8651 10.1063Z"
        fill="#242324"
      />
      <path
        d="M27.3779 10.6085L27.7881 10.1785H27.8716L27.816 10.3555L27.3779 10.6085Z"
        fill="#F1F2F2"
      />
      <path
        d="M27.4601 10.5118L26.8866 10.1786L27.2587 9.9635L27.8327 10.2967L27.4601 10.5118Z"
        fill="#DBDBDB"
      />
      <path
        d="M27.3637 10.6201L26.7896 10.2865C26.7946 10.2824 26.7992 10.2782 26.8043 10.2741L27.3784 10.6077C27.3738 10.6118 27.3688 10.6159 27.3637 10.6201Z"
        fill="#C1C2C2"
      />
      <path
        d="M27.4628 10.5137L26.8886 10.1802C26.8918 10.1751 26.8955 10.1701 26.8987 10.165L27.4729 10.4986C27.4697 10.5037 27.4664 10.5087 27.4628 10.5137Z"
        fill="#AEAEAE"
      />
      <path
        d="M27.4724 10.4982L26.8982 10.1646C26.9023 10.1577 26.9069 10.1509 26.9106 10.144L27.4847 10.4776C27.4806 10.4844 27.4765 10.4913 27.4724 10.4982Z"
        fill="#A9A9A9"
      />
      <path
        d="M27.4857 10.4781L26.9116 10.1445C26.9157 10.1376 26.9194 10.1308 26.923 10.1239L27.4972 10.4575C27.4935 10.4644 27.4899 10.4712 27.4857 10.4781Z"
        fill="#A4A5A5"
      />
      <path
        d="M27.4972 10.4561L26.923 10.1225C26.9267 10.1157 26.9304 10.1088 26.9336 10.1019L27.5077 10.4355C27.5045 10.4424 27.5009 10.4493 27.4972 10.4561Z"
        fill="#9FA0A0"
      />
      <path
        d="M27.5063 10.4365L26.9321 10.1029C26.9353 10.0961 26.9385 10.0887 26.9418 10.0818L27.5159 10.4154C27.5127 10.4223 27.5095 10.4296 27.5063 10.4365Z"
        fill="#9A9B9B"
      />
      <path
        d="M27.5173 10.4154L26.9431 10.0819C26.9463 10.0745 26.9491 10.0672 26.9523 10.0599L27.5264 10.3935C27.5237 10.4008 27.5205 10.4081 27.5173 10.4154Z"
        fill="#959696"
      />
      <path
        d="M27.5254 10.3934L26.9512 10.0598C26.954 10.052 26.9567 10.0447 26.9595 10.0369L27.5336 10.3705C27.5309 10.3783 27.5281 10.3856 27.5254 10.3934Z"
        fill="#919191"
      />
      <path
        d="M27.534 10.3704L26.9599 10.0368C26.9626 10.0286 26.9649 10.0203 26.9672 10.0121L27.5413 10.3457C27.539 10.3539 27.5367 10.3622 27.534 10.3704Z"
        fill="#8C8C8C"
      />
      <path
        d="M27.5421 10.3464L26.968 10.0128C26.9707 10.0037 26.973 9.99452 26.9749 9.98535L27.549 10.3189C27.5472 10.3281 27.5449 10.3373 27.5421 10.3464Z"
        fill="#878888"
      />
      <path
        d="M27.5479 10.3174L26.9737 9.98386C26.976 9.9724 26.9783 9.96141 26.9797 9.94995L27.5538 10.2835C27.5524 10.2945 27.5506 10.306 27.5479 10.3174Z"
        fill="#828383"
      />
      <path
        d="M27.5541 10.2843L26.9799 9.95077C26.9809 9.94206 26.9818 9.93336 26.9822 9.92511L27.5564 10.2587C27.5559 10.2674 27.555 10.2756 27.5541 10.2843Z"
        fill="#7D7E7E"
      />
      <path
        d="M27.5549 10.2585L26.9808 9.92488L26.983 9.88318L27.5577 10.2168L27.5549 10.2585Z"
        fill="#858585"
      />
      <path
        d="M27.5571 10.2175L26.9832 9.88428L27.2958 9.70349L27.8697 10.0372L27.5571 10.2175Z"
        fill="#CFD0D0"
      />
      <path
        d="M27.0354 11.2565L26.4608 10.9229L26.4947 10.8954L27.0689 11.229L27.0354 11.2565Z"
        fill="#373738"
      />
      <path
        d="M27.0355 11.2575L26.4609 10.924L26.3963 10.6096L26.9704 10.9432L27.0355 11.2575Z"
        fill="#797A7A"
      />
      <path
        d="M26.9698 10.9434L26.3963 10.6102L26.7899 10.2875L27.3639 10.6207L26.9698 10.9434Z"
        fill="#CDCECE"
      />
      <path
        d="M25.7497 11.5508L25.1762 11.2171L26.8047 10.277L27.3782 10.6102L25.7497 11.5508Z"
        fill="#383739"
      />
      <path
        d="M25.7587 11.494L25.1848 11.1608L26.8861 10.1785L27.4596 10.5117L25.7587 11.494Z"
        fill="#DBDBDB"
      />
      <path
        d="M25.9174 11.1629L25.3439 10.8292L26.9829 9.88318L27.5569 10.2164L25.9174 11.1629Z"
        fill="#383739"
      />
      <path
        d="M31.5934 7.77948V7.84905C31.6181 7.84356 31.6419 7.84264 31.6639 7.84539C29.8971 8.86561 27.6841 10.1431 25.9174 11.1633C25.9394 11.1354 25.9632 11.1093 25.9874 11.0859V11.0163C27.7107 10.0213 29.8701 8.77452 31.5934 7.77948Z"
        fill="#C5C5C5"
      />
      <path
        d="M31.6637 7.84637C31.7383 7.85461 31.7964 7.90725 31.8234 7.99467L30.1331 8.97049C30.0658 8.95539 30.0333 8.86568 30.0352 8.78649L31.6637 7.84637Z"
        fill="#323133"
      />
      <path
        d="M30.0279 8.68401L29.4544 8.35034L31.0198 7.44684L31.5933 7.78005L30.0279 8.68401Z"
        fill="#DBDBDB"
      />
      <path
        d="M29.7163 8.86312L29.1423 8.52946L29.455 8.34912L30.0285 8.68279L29.7163 8.86312Z"
        fill="#DBDBDB"
      />
      <path
        d="M29.8721 6.42063L29.2986 6.08742L31.0132 5.09741L31.5867 5.43062L29.8721 6.42063Z"
        fill="#383739"
      />
      <path
        d="M29.8654 6.31919L29.2915 5.98598L31.0129 4.99231L31.5868 5.32552L29.8654 6.31919Z"
        fill="#DBDBDB"
      />
      <path
        d="M29.8267 5.76389L29.2532 5.43068L31.0113 4.41595L31.5848 4.74916L29.8267 5.76389Z"
        fill="#383739"
      />
      <path
        d="M29.82 5.66315L29.2465 5.32994L30.9977 4.31842L31.5717 4.65209L29.82 5.66315Z"
        fill="#DBDBDB"
      />
      <path
        d="M31.3658 4.60386L30.7916 4.27028C30.8021 4.26432 30.8122 4.25928 30.8223 4.2547L31.3965 4.58828C31.3864 4.5924 31.3758 4.59744 31.3658 4.60386Z"
        fill="#343335"
      />
      <path
        d="M31.3958 4.58917L30.8216 4.25559C30.8303 4.25193 30.839 4.24918 30.8473 4.24689L31.4214 4.58047C31.4132 4.58276 31.4045 4.58551 31.3958 4.58917Z"
        fill="#333233"
      />
      <path
        d="M31.4216 4.57983L30.8474 4.24625C30.8538 4.24442 30.8602 4.24304 30.8667 4.24213L31.4408 4.57571C31.4344 4.57662 31.428 4.578 31.4216 4.57983Z"
        fill="#313132"
      />
      <path
        d="M31.4412 4.57565L30.867 4.24207C30.8725 4.24115 30.878 4.24069 30.8835 4.24023L31.4577 4.57381C31.4522 4.57381 31.4467 4.57473 31.4412 4.57565Z"
        fill="#302F31"
      />
      <path
        d="M31.4579 4.57421L30.8837 4.24063C30.9007 4.22689 31.4583 4.57421 31.4725 4.57467C31.468 4.57467 31.4629 4.57467 31.4583 4.57467L31.4579 4.57421Z"
        fill="#2F2E30"
      />
      <path
        d="M31.4717 4.57396L30.8976 4.24038C30.9127 4.23213 31.4722 4.57396 31.485 4.57579C31.4809 4.57488 31.4763 4.57442 31.4717 4.57442V4.57396Z"
        fill="#2D2D2E"
      />
      <path
        d="M31.4842 4.57573L30.91 4.24215C30.9256 4.23894 31.4832 4.57527 31.4965 4.57894C31.4924 4.57756 31.4883 4.57665 31.4842 4.57619V4.57573Z"
        fill="#2C2C2D"
      />
      <path
        d="M31.4966 4.5776L30.9225 4.24402C30.9371 4.24493 31.4952 4.57668 31.5085 4.58172C31.5048 4.58035 31.5007 4.57897 31.4971 4.5776H31.4966Z"
        fill="#2B2A2C"
      />
      <path
        d="M31.5095 4.58242L30.9353 4.24884C30.9482 4.25342 31.5086 4.58196 31.5205 4.58792C31.5168 4.58609 31.5132 4.58425 31.5095 4.58242Z"
        fill="#2A292A"
      />
      <path
        d="M31.52 4.5881L30.9459 4.25452C30.9459 4.25452 31.5205 4.58855 31.5214 4.58901H31.52V4.5881Z"
        fill="#282829"
      />
      <path
        d="M29.7779 9.22226C29.8214 9.30373 29.8905 9.35545 29.9761 9.37147C30.0095 9.76921 28.056 10.6791 27.8148 10.8663C27.7237 10.8311 27.6583 10.7862 27.6116 10.7418C27.7004 10.6251 27.7713 10.4906 27.8153 10.3551L29.7779 9.2218V9.22226Z"
        fill="#323133"
      />
      <path
        d="M29.7278 9.03063C29.7319 9.08876 29.7433 9.14048 29.7612 9.18533L27.8329 10.2985C27.8576 10.2106 27.8658 10.1241 27.8695 10.0367L29.7232 8.96655L29.7278 9.03063Z"
        fill="#323133"
      />
      <path
        d="M27.8759 9.92669L27.3019 9.59349L29.1423 8.5307L29.7163 8.86437L27.8759 9.92669Z"
        fill="#DBDBDB"
      />
      <path
        d="M30.0282 8.68327L29.4547 8.34961L29.2986 6.08765L29.8721 6.42085L30.0282 8.68327Z"
        fill="#222122"
      />
      <path
        d="M29.7161 8.86492L29.1421 8.53125L28.9861 6.26929L29.5596 6.60249L29.7161 8.86492Z"
        fill="#7E7E7E"
      />
      <path
        d="M29.5596 6.60119L28.9861 6.26798L29.2987 6.08765L29.8722 6.42085L29.5596 6.60119Z"
        fill="#CFD0D0"
      />
      <path
        d="M28.0188 7.49272L27.4448 7.15906L28.9863 6.26929L29.5598 6.60249L28.0188 7.49272Z"
        fill="#383739"
      />
      <path
        d="M27.8759 9.9273L27.3019 9.59409L27.4447 7.15912L28.0187 7.49278L27.8759 9.9273Z"
        fill="#242324"
      />
      <path
        d="M27.5624 10.1067L26.9889 9.77299L27.301 9.59265L27.875 9.92586L27.5624 10.1067Z"
        fill="#DBDBDB"
      />
      <path
        d="M27.5624 10.1065L26.9889 9.77287L27.1312 7.33789L27.7052 7.67156L27.5624 10.1065Z"
        fill="#858585"
      />
      <path
        d="M27.7057 7.67312L27.1318 7.33945L27.4444 7.15912L28.0183 7.49278L27.7057 7.67312Z"
        fill="#CFD0D0"
      />
      <path
        d="M25.981 8.66752L25.407 8.33431L27.1321 7.33789L27.706 7.67156L25.981 8.66752Z"
        fill="#383739"
      />
      <path
        d="M31.5864 5.32587V5.43205C29.8586 6.42984 27.7083 7.67113 25.9805 8.66892V8.56274C27.7083 7.56495 29.8586 6.3232 31.5864 5.32587Z"
        fill="#C5C5C5"
      />
      <path
        d="M30.0277 8.68438L29.8716 6.42196L31.5862 5.43195L31.593 7.78041L30.0277 8.68438Z"
        fill="#323133"
      />
      <path
        d="M29.5534 6.50033L28.9799 6.16666L29.292 5.98633L29.866 6.31954L29.5534 6.50033Z"
        fill="#DBDBDB"
      />
      <path
        d="M29.8652 6.31897L29.2912 5.98577L29.2532 5.43195L29.8267 5.76515L29.8652 6.31897Z"
        fill="#222122"
      />
      <path
        d="M29.5146 5.94595L28.9407 5.61228L29.2533 5.43195L29.8268 5.76515L29.5146 5.94595Z"
        fill="#CFD0D0"
      />
      <path
        d="M29.5526 6.50009L28.9791 6.16643L28.9407 5.61261L29.5146 5.94627L29.5526 6.50009Z"
        fill="#7E7E7E"
      />
      <path
        d="M28.025 7.37978L27.451 7.04657L28.9793 6.16412L29.5528 6.49779L28.025 7.37978Z"
        fill="#DBDBDB"
      />
      <path
        d="M27.7124 7.56182L27.1385 7.22861L27.4511 7.04828L28.025 7.38149L27.7124 7.56182Z"
        fill="#DBDBDB"
      />
      <path
        d="M28.0599 6.78616L27.4864 6.45249L28.941 5.61261L29.5149 5.94627L28.0599 6.78616Z"
        fill="#383739"
      />
      <path
        d="M28.025 7.38046L27.451 7.04725L27.4863 6.45087L28.0598 6.78453L28.025 7.38046Z"
        fill="#242324"
      />
      <path
        d="M27.7124 7.56256L27.1385 7.22935L27.1737 6.63342L27.7472 6.96663L27.7124 7.56256Z"
        fill="#858585"
      />
      <path
        d="M27.7469 6.96487L27.1734 6.63166L27.486 6.45087L28.0595 6.78453L27.7469 6.96487Z"
        fill="#CFD0D0"
      />
      <path
        d="M25.9805 8.56217L25.407 8.2285L27.1385 7.22888L27.7124 7.56209L25.9805 8.56217Z"
        fill="#DBDBDB"
      />
      <path
        d="M25.9786 7.98776L25.4051 7.6541L27.1737 6.63342L27.7472 6.96663L25.9786 7.98776Z"
        fill="#383739"
      />
      <path
        d="M25.9872 11.0164L25.4137 10.6832L26.9896 9.77332L27.5631 10.107L25.9872 11.0164Z"
        fill="#DBDBDB"
      />
      <path
        d="M25.9869 11.0159L25.9805 8.66741L27.7056 7.67145L27.5628 10.1064L25.9869 11.0159Z"
        fill="#323133"
      />
      <path
        d="M25.9793 7.96857L25.4052 7.63499C25.4052 7.62079 25.4061 7.60658 25.4079 7.59192L25.9821 7.9255C25.9802 7.94016 25.9793 7.95482 25.9793 7.96857Z"
        fill="#858585"
      />
      <path
        d="M25.9827 7.92419L25.4085 7.59061C25.4099 7.58053 25.4117 7.57044 25.4136 7.56036L25.9877 7.89394C25.9854 7.90402 25.9836 7.9141 25.9827 7.92419Z"
        fill="#8A8A8A"
      />
      <path
        d="M25.9878 7.89421L25.4137 7.56063C25.4151 7.55375 25.4169 7.54734 25.4187 7.54047L25.9929 7.87405C25.991 7.88092 25.9897 7.88733 25.9878 7.89421Z"
        fill="#8F8F8F"
      />
      <path
        d="M31.5709 4.65054C31.5819 4.67617 31.5855 4.71599 31.5842 4.74895C29.8577 5.74582 27.7042 6.98894 25.9778 7.98582C25.9769 7.94783 25.9801 7.91075 25.9906 7.87185C27.7093 6.87955 29.8522 5.64238 31.5704 4.65009L31.5709 4.65054Z"
        fill="#C5C5C5"
      />
      <path
        d="M29.8272 5.76521L31.5852 4.75049L31.5871 5.32536L29.8656 6.31903L29.8272 5.76521Z"
        fill="#323133"
      />
      <path
        d="M29.809 5.50511L29.2355 5.17145L30.7922 4.27252L31.3661 4.60619L29.809 5.50511Z"
        fill="#383739"
      />
      <path
        d="M29.8207 5.66262L29.2465 5.32904L29.2355 5.17004L29.8097 5.50408L29.8207 5.66262Z"
        fill="#222122"
      />
      <path
        d="M29.5065 5.84325L28.933 5.51005L29.2456 5.32971L29.8191 5.66292L29.5065 5.84325Z"
        fill="#DBDBDB"
      />
      <path
        d="M29.7868 5.1729L29.2127 4.83932L29.1916 4.53827L29.7657 4.87185L29.7868 5.1729Z"
        fill="#787879"
      />
      <path
        d="M30.2534 4.11682C30.2772 3.95937 29.647 3.70855 29.5486 3.61975C29.9358 3.81244 29.3106 4.31271 29.1916 4.53699L29.7651 4.87019C29.9276 4.62624 30.1912 4.4102 30.253 4.11682H30.2534Z"
        fill="#787879"
      />
      <path
        d="M28.0661 6.6766L27.4926 6.34339L28.9339 5.51129L29.5074 5.8445L28.0661 6.6766Z"
        fill="#DBDBDB"
      />
      <path
        d="M29.5076 5.84381L28.9335 5.51023L28.9225 5.35168L29.4966 5.68526L29.5076 5.84381Z"
        fill="#7E7E7E"
      />
      <path
        d="M28.1521 6.46116L27.5781 6.12796L28.8103 5.41669L29.3838 5.75035L28.1521 6.46116Z"
        fill="#383739"
      />
      <path
        d="M29.6958 3.85502L29.1216 3.52144L29.118 3.46967L29.6926 3.80325L29.6958 3.85502Z"
        fill="#787879"
      />
      <path
        d="M28.0192 7.4921L29.5602 6.60187L29.7168 8.86429L27.8764 9.92662L28.0192 7.4921Z"
        fill="#323133"
      />
      <path
        d="M28.0667 6.67533L27.4926 6.34175L27.5027 6.17084L28.0768 6.50488L28.0667 6.67533Z"
        fill="#242324"
      />
      <path
        d="M28.0777 6.50486L27.5036 6.17082L27.5797 6.12683L28.1543 6.46041L28.0777 6.50486Z"
        fill="#383739"
      />
      <path
        d="M29.7489 3.59143L29.1748 3.25785C29.2192 3.2519 29.2627 3.24823 29.3049 3.2464L29.879 3.57998C29.8369 3.58181 29.7933 3.58548 29.7489 3.59143Z"
        fill="#AAABAC"
      />
      <path
        d="M29.8789 3.57865L29.3047 3.24507C29.4143 3.18275 29.9197 3.54016 30.0118 3.58002C29.9687 3.57727 29.9247 3.57681 29.8789 3.57911V3.57865Z"
        fill="#A6A6A7"
      />
      <path
        d="M30.0113 3.57883L29.4371 3.24525C29.5214 3.20859 30.0488 3.55775 30.1235 3.59211C30.0873 3.5857 30.0502 3.58158 30.0117 3.57883H30.0113Z"
        fill="#A1A2A2"
      />
      <path
        d="M30.1241 3.59241L29.5499 3.25883C29.615 3.23729 30.1598 3.58645 30.2217 3.61486C30.19 3.6057 30.1575 3.59837 30.1241 3.59241Z"
        fill="#9C9D9E"
      />
      <path
        d="M30.2206 3.61543L29.6465 3.28185C29.6946 3.27452 30.2554 3.62047 30.3081 3.64659C30.2797 3.63468 30.2508 3.6246 30.2206 3.61589V3.61543Z"
        fill="#989899"
      />
      <path
        d="M30.309 3.64498L29.7349 3.3114C29.7692 3.31736 30.342 3.65919 30.3892 3.68439C30.3635 3.66972 30.337 3.65689 30.309 3.64544V3.64498Z"
        fill="#939494"
      />
      <path
        d="M30.3884 3.68417L29.8142 3.35059C29.8252 3.357 30.3861 3.68279 30.3971 3.68921C30.3943 3.68737 30.3916 3.686 30.3884 3.68417Z"
        fill="#8F8F90"
      />
      <path
        d="M29.6936 3.80685L29.1194 3.47327C29.1194 3.46136 29.1208 3.44899 29.1222 3.43616L29.6963 3.76974C29.6945 3.78257 29.6936 3.79494 29.6936 3.80685Z"
        fill="#787879"
      />
      <path
        d="M29.6965 3.76916L29.1223 3.43558C29.1237 3.42367 29.126 3.4113 29.1287 3.39893L29.7029 3.73251C29.7001 3.74534 29.6978 3.75725 29.6965 3.76916Z"
        fill="#7C7D7D"
      />
      <path
        d="M29.7036 3.73382L29.1295 3.40024C29.1318 3.38879 29.135 3.37687 29.1382 3.3645L29.7123 3.69808C29.7091 3.71045 29.7059 3.72237 29.7036 3.73382Z"
        fill="#818182"
      />
      <path
        d="M29.7113 3.69739L29.1371 3.36381C29.1408 3.35144 29.1445 3.33861 29.149 3.32532L29.7232 3.6589C29.7186 3.67219 29.7149 3.68502 29.7113 3.69739Z"
        fill="#858687"
      />
      <path
        d="M29.7242 3.65796L29.15 3.32438C29.1546 3.31018 29.1601 3.29552 29.1661 3.2804L29.7402 3.61398C29.7343 3.6291 29.7292 3.64376 29.7242 3.65796Z"
        fill="#8A8B8B"
      />
      <path
        d="M29.7395 3.61439L29.1653 3.28082C29.1681 3.27303 29.1713 3.26524 29.1745 3.25745L29.7486 3.59103C29.7454 3.59882 29.7422 3.60661 29.7395 3.61439Z"
        fill="#8F8F90"
      />
      <path
        d="M27.7531 6.85592L27.1796 6.52225L27.4922 6.34192L28.0657 6.67513L27.7531 6.85592Z"
        fill="#DBDBDB"
      />
      <path
        d="M28.0777 6.5051L27.5036 6.17106L27.5261 5.78845L28.1002 6.12249L28.0777 6.5051Z"
        fill="#7F7F80"
      />
      <path
        d="M29.4691 5.28872L28.8952 4.95552L28.8064 3.66846L29.3803 4.00212L29.4691 5.28872Z"
        fill="#7E7E7E"
      />
      <path
        d="M30.2605 2.01028C30.3016 1.5091 29.6215 1.2926 29.2979 1.07245C29.7039 1.3164 29.8018 1.67845 29.5226 2.08901C29.2736 2.60667 28.8393 3.07581 28.8054 3.66991L29.3789 4.00312C29.4219 3.25386 30.0842 2.71697 30.26 2.00982L30.2605 2.01028Z"
        fill="#7D7E7E"
      />
      <path
        d="M28.1777 4.5906L27.6035 4.25702C27.593 4.18553 27.5706 4.12551 27.5385 4.07373L28.1126 4.40731C28.1447 4.45909 28.1672 4.51911 28.1777 4.5906Z"
        fill="#787879"
      />
      <path
        d="M27.7537 6.85691L27.1796 6.52287L27.1896 6.35242L27.7638 6.686L27.7537 6.85691Z"
        fill="#858585"
      />
      <path
        d="M25.992 7.87418L25.418 7.54097L27.1802 6.5235L27.7537 6.85716L25.992 7.87418Z"
        fill="#DBDBDB"
      />
      <path
        d="M26.197 7.5905L25.623 7.25729L27.1906 6.35242L27.7641 6.68562L26.197 7.5905Z"
        fill="#383739"
      />
      <path
        d="M27.8733 4.859L27.2992 4.52542C27.2919 4.371 27.1718 4.2954 27.0421 4.21704C27.241 4.37512 27.855 4.5662 27.8733 4.859Z"
        fill="#7D7E7E"
      />
      <path
        d="M28.8745 3.8817C29.0576 3.79062 29.2471 3.71098 29.443 3.65376C29.2516 3.54254 29.0603 3.43178 28.8695 3.32056C28.3774 3.4597 27.917 3.74851 27.538 4.07485L28.1115 4.40806C28.3408 4.21994 28.5962 4.02038 28.875 3.8817H28.8745Z"
        fill="#B8B9BA"
      />
      <path
        d="M27.6046 4.03835L27.0305 3.70477C27.0424 3.71165 27.0547 3.71852 27.0667 3.72539L27.6408 4.05897C27.6289 4.0521 27.6165 4.04523 27.6046 4.03835Z"
        fill="#959696"
      />
      <path
        d="M27.6414 4.05849L27.0672 3.72491C27.1795 3.78357 27.6844 4.07911 27.8027 4.14785C27.7495 4.11806 27.695 4.08919 27.6414 4.05895V4.05849Z"
        fill="#9A9B9B"
      />
      <path
        d="M27.8024 4.14834L27.2283 3.81476C27.2388 3.82072 27.2494 3.82667 27.2599 3.83309L27.834 4.16667C27.8235 4.16071 27.813 4.15429 27.8024 4.14834Z"
        fill="#959696"
      />
      <path
        d="M27.7843 6.36342L27.2097 6.02938L27.2294 5.69672L27.8035 6.03075L27.7843 6.36342Z"
        fill="#858585"
      />
      <path
        d="M27.3327 5.93856L26.7585 5.60498C26.7585 5.60498 27.3331 5.93902 27.334 5.93948L27.3327 5.93856Z"
        fill="#8F8F90"
      />
      <path
        d="M27.3346 5.93954L26.7604 5.60596C26.7728 5.61008 27.335 5.93954 27.3474 5.94595C27.3428 5.94412 27.3387 5.94183 27.3346 5.93954Z"
        fill="#939494"
      />
      <path
        d="M27.347 5.94604L26.7729 5.61246C26.7697 5.59047 27.3855 5.95704 27.3979 5.96254C27.381 5.9575 27.364 5.95246 27.3475 5.94604H27.347Z"
        fill="#989899"
      />
      <path
        d="M27.3977 5.96164L26.8235 5.62806C26.928 5.61615 27.4426 5.9424 27.5356 5.9928C27.4884 5.98456 27.4426 5.97493 27.3981 5.96164H27.3977Z"
        fill="#9C9D9E"
      />
      <path
        d="M27.5358 5.99317L26.9617 5.65959C27.1999 5.63714 27.6137 5.91985 27.8061 6.03028C27.7168 6.01791 27.6251 6.00875 27.5363 5.99317H27.5358Z"
        fill="#A1A2A2"
      />
      <path
        d="M27.8023 6.02986L27.2288 5.6962L27.2956 4.55835L27.8691 4.89201L27.8023 6.02986Z"
        fill="#858585"
      />
      <path
        d="M27.8698 4.89135L27.2957 4.55732L27.2975 4.52203L27.8717 4.85561L27.8698 4.89135Z"
        fill="#858585"
      />
      <path
        d="M31.3666 4.60531L29.8095 5.50423L29.8205 5.6626L31.5721 4.65153C31.541 4.57327 31.4609 4.5513 31.3666 4.60531Z"
        fill="#323133"
      />
      <path
        d="M29.3848 5.75033L28.8107 5.41629L28.9229 5.35168L29.4971 5.68526L29.3848 5.75033Z"
        fill="#383739"
      />
      <path
        d="M29.4964 5.68541L28.9223 5.35183L28.8948 4.95593L29.4694 5.28951L29.4964 5.68541Z"
        fill="#7E7E7E"
      />
      <path
        d="M29.3855 5.75069L28.8114 5.41666L28.7939 5.26086L29.3681 5.59444L29.3855 5.75069Z"
        fill="#767777"
      />
      <path
        d="M29.3681 5.59409L28.7939 5.26051L28.7935 5.25226L29.3676 5.5863L29.3681 5.59409Z"
        fill="#787979"
      />
      <path
        d="M29.3679 5.58673L28.7938 5.25315C28.7938 5.23987 28.7947 5.22612 28.7965 5.21191L29.3707 5.54549C29.3688 5.5597 29.3679 5.57299 29.3679 5.58673Z"
        fill="#787879"
      />
      <path
        d="M29.3698 5.5451L28.7957 5.21152C28.797 5.20144 28.7989 5.1909 28.8012 5.18036L29.3753 5.51394C29.373 5.52448 29.3712 5.53502 29.3698 5.5451Z"
        fill="#7C7D7D"
      />
      <path
        d="M29.376 5.51567L28.8019 5.18209C28.8037 5.17293 28.806 5.16377 28.8088 5.1546L29.3829 5.48818C29.3801 5.49735 29.3779 5.50651 29.376 5.51567Z"
        fill="#818182"
      />
      <path
        d="M29.3818 5.48705L28.8076 5.15347C28.8099 5.14476 28.8127 5.1356 28.8159 5.12689L29.39 5.46047C29.3868 5.46964 29.3845 5.47834 29.3818 5.48705Z"
        fill="#858687"
      />
      <path
        d="M29.3908 5.46019L28.8167 5.12661C28.8199 5.11745 28.8231 5.10829 28.8268 5.09912L29.4009 5.4327C29.3973 5.44187 29.394 5.45103 29.3908 5.46019Z"
        fill="#8A8B8B"
      />
      <path
        d="M29.4009 5.43288L28.8267 5.0993C28.8304 5.08968 28.8345 5.0796 28.8387 5.06952L29.4128 5.4031C29.4087 5.41318 29.4045 5.4228 29.4009 5.43288Z"
        fill="#8F8F90"
      />
      <path
        d="M29.4128 5.40453L28.8387 5.07096C28.8433 5.05996 28.8483 5.04896 28.8538 5.03796L29.4279 5.37154C29.4224 5.38254 29.4174 5.39354 29.4128 5.40453Z"
        fill="#939494"
      />
      <path
        d="M29.4272 5.37033L28.853 5.03675C28.859 5.02392 28.8659 5.01109 28.8727 4.9978L29.4469 5.33138C29.44 5.34467 29.4331 5.3575 29.4272 5.37033Z"
        fill="#989899"
      />
      <path
        d="M29.4477 5.33154L28.8736 4.99797C28.8809 4.98422 28.8887 4.97047 28.8965 4.95673L29.4706 5.29031C29.4624 5.30405 29.455 5.3178 29.4477 5.33154Z"
        fill="#9C9D9E"
      />
      <path
        d="M26.0846 11.8636C25.8566 12.0064 25.7019 11.7849 25.7491 11.5464L27.378 10.6058C27.3597 10.6228 26.9876 10.9253 26.9693 10.9409L27.0343 11.2549L27.0677 11.2274C27.0961 11.3285 26.1445 11.8142 26.0846 11.8636Z"
        fill="#323133"
      />
      <path
        d="M25.8522 11.8871L25.2781 11.5535C25.1906 11.5122 25.1516 11.3477 25.1736 11.2254L25.7477 11.559C25.7257 11.6813 25.7647 11.8458 25.8522 11.8871Z"
        fill="#222122"
      />
      <path
        d="M25.7485 11.558L25.1744 11.2245C25.1744 11.2213 25.1753 11.2181 25.1758 11.2148L25.7499 11.5484C25.7494 11.5516 25.749 11.5548 25.7485 11.558Z"
        fill="#232324"
      />
      <path
        d="M25.7503 11.5479L25.1762 11.2138L25.1858 11.1611L25.7604 11.4947L25.7503 11.5479Z"
        fill="#979797"
      />
      <path
        d="M25.7593 11.4947L25.7492 11.5478C25.7519 11.53 25.7551 11.5126 25.7593 11.4947Z"
        fill="#323133"
      />
      <path
        d="M25.7509 11.5525L25.1767 11.2189C25.179 11.2047 25.1813 11.1905 25.1845 11.1763L25.7586 11.5098C25.7554 11.5241 25.7531 11.5383 25.7509 11.5525Z"
        fill="#232324"
      />
      <path
        d="M25.7585 11.5099L25.1844 11.1763C25.1853 11.1726 25.1857 11.1694 25.1866 11.1658L25.7608 11.4994C25.7599 11.503 25.7594 11.5062 25.7585 11.5099Z"
        fill="#242425"
      />
      <path
        d="M25.9179 11.169L25.3438 10.8354C25.3511 10.8258 25.3589 10.8167 25.3667 10.8075L25.9408 11.1411C25.9331 11.1502 25.9253 11.1594 25.9179 11.169Z"
        fill="#C2C2C2"
      />
      <path
        d="M25.9414 11.1399L25.3672 10.8063C25.3791 10.7926 25.391 10.7798 25.4034 10.7674L25.9776 11.101C25.9652 11.1133 25.9528 11.1262 25.9414 11.1399Z"
        fill="#C7C7C7"
      />
      <path
        d="M25.9772 11.1019L25.4031 10.7684C25.4067 10.7647 25.4104 10.7615 25.4136 10.7578L25.9877 11.0914C25.9841 11.0946 25.9804 11.0983 25.9772 11.1019Z"
        fill="#CCCCCC"
      />
      <path
        d="M25.9883 11.0865L25.4137 10.7529V10.6832L25.9878 11.0168L25.9883 11.0865Z"
        fill="#888888"
      />
      <path
        d="M25.7594 11.4964L25.1852 11.1628C25.188 11.15 25.1912 11.1376 25.1948 11.1248L25.769 11.4584C25.7653 11.4712 25.7621 11.4836 25.7594 11.4964Z"
        fill="#242425"
      />
      <path
        d="M25.7694 11.4582L25.1953 11.1246C25.1989 11.1118 25.203 11.0985 25.2072 11.0856L25.7813 11.4192C25.7772 11.432 25.7731 11.4453 25.7694 11.4582Z"
        fill="#262526"
      />
      <path
        d="M25.7809 11.4205L25.2067 11.0869C25.2108 11.075 25.215 11.0631 25.2195 11.0512L25.7937 11.3848C25.7891 11.3967 25.785 11.4086 25.7809 11.4205Z"
        fill="#272628"
      />
      <path
        d="M25.7933 11.3848L25.2191 11.0512C25.2233 11.0402 25.2279 11.0288 25.2329 11.0178L25.807 11.3513C25.8025 11.3623 25.7979 11.3733 25.7933 11.3848Z"
        fill="#282829"
      />
      <path
        d="M25.8076 11.3495L25.2335 11.0159C25.2381 11.0054 25.2431 10.9949 25.2481 10.9843L25.8223 11.3179C25.8172 11.3284 25.8122 11.339 25.8076 11.3495Z"
        fill="#2A292A"
      />
      <path
        d="M25.822 11.3189L25.2478 10.9854C25.2529 10.9753 25.2579 10.9647 25.2634 10.9547L25.8375 11.2882C25.832 11.2983 25.827 11.3084 25.822 11.3189Z"
        fill="#2B2A2C"
      />
      <path
        d="M25.8372 11.2893L25.2631 10.9557C25.2686 10.9457 25.2741 10.9356 25.2796 10.926L25.8537 11.2595C25.8482 11.2692 25.8423 11.2792 25.8372 11.2893Z"
        fill="#2C2C2D"
      />
      <path
        d="M25.8535 11.2593L25.2794 10.9257C25.2853 10.9156 25.2913 10.906 25.2972 10.8964L25.8714 11.2299C25.8654 11.2396 25.859 11.2496 25.8535 11.2593Z"
        fill="#2D2D2E"
      />
      <path
        d="M25.8712 11.2301L25.297 10.8965C25.3035 10.8864 25.3099 10.8768 25.3167 10.8667L25.8909 11.2003C25.884 11.2099 25.8776 11.22 25.8712 11.2301Z"
        fill="#2F2E30"
      />
      <path
        d="M25.8922 11.2009L25.3181 10.8673C25.3254 10.8568 25.3327 10.8467 25.3405 10.8371L25.9147 11.1707C25.9069 11.1808 25.8995 11.1908 25.8922 11.2009Z"
        fill="#302F31"
      />
      <path
        d="M25.9147 11.1695L25.3405 10.8359C25.3405 10.8359 25.3428 10.8327 25.3442 10.8314L25.9183 11.1649L25.9147 11.1695Z"
        fill="#313132"
      />
      <path
        d="M25.7595 11.4961C25.6881 11.1121 27.351 10.3958 27.5569 10.2178C27.556 10.3198 27.524 10.4215 27.4604 10.5139L25.7591 11.4961H25.7595Z"
        fill="#323133"
      />
      <path
        d="M29.5153 5.94617L29.5533 6.49999L28.0255 7.38198L28.0603 6.78605L29.5153 5.94617Z"
        fill="#323133"
      />
      <path
        d="M29.4969 5.6857L29.5079 5.84406L28.0666 6.67617L28.0766 6.50544C28.1906 6.43999 29.3935 5.7452 29.4969 5.68524V5.6857Z"
        fill="#323133"
      />
      <path
        d="M27.7642 6.68592L27.1901 6.35234L27.2089 6.02747L27.7835 6.3615L27.7642 6.68592Z"
        fill="#858585"
      />
      <path
        d="M25.9874 11.0165L25.4139 10.6832L25.407 8.33478L25.981 8.66798L25.9874 11.0165Z"
        fill="#232223"
      />
      <path
        d="M25.9816 8.66807L25.407 8.33449V8.22772L25.9811 8.56176L25.9816 8.66807Z"
        fill="#888888"
      />
      <path
        d="M25.9804 8.5618L25.4069 8.22813L25.4051 7.65326L25.9786 7.98692L25.9804 8.5618Z"
        fill="#232223"
      />
      <path
        d="M25.9799 8.5611L25.9781 7.98622L27.7467 6.96509L27.7119 7.56102L25.9799 8.5611Z"
        fill="#323133"
      />
      <path
        d="M25.9792 7.9879L25.4051 7.65386V7.63416L25.9792 7.96773V7.9879Z"
        fill="#888888"
      />
      <path
        d="M25.9915 7.8728C25.9846 7.56293 27.5719 6.84983 27.7632 6.68506L27.7532 6.85578L25.991 7.87325L25.9915 7.8728Z"
        fill="#323133"
      />
      <path
        d="M25.9934 7.87359L25.4192 7.54001L25.4201 7.53589L25.9943 7.86947L25.9934 7.87359Z"
        fill="#242425"
      />
      <path
        d="M25.9943 7.868L25.4202 7.53442C25.4225 7.52708 25.4243 7.51975 25.427 7.51196L26.0012 7.84554C25.9989 7.85287 25.9966 7.86021 25.9943 7.868Z"
        fill="#262526"
      />
      <path
        d="M26.001 7.84608L25.4269 7.5125C25.4292 7.50563 25.4314 7.49876 25.4342 7.49188L26.0083 7.82546C26.0056 7.83234 26.0033 7.83921 26.001 7.84608Z"
        fill="#272628"
      />
      <path
        d="M26.0077 7.82606L25.4335 7.49248C25.4363 7.48607 25.4386 7.47919 25.4418 7.47278L26.0159 7.80636C26.0132 7.81277 26.0104 7.81919 26.0077 7.82606Z"
        fill="#282829"
      />
      <path
        d="M26.0168 7.80604L25.4426 7.47246C25.4454 7.46605 25.4481 7.45963 25.4513 7.45367L26.0255 7.78725C26.0223 7.79367 26.0195 7.79963 26.0168 7.80604Z"
        fill="#2A292A"
      />
      <path
        d="M26.0244 7.78694L25.4503 7.45336C25.4535 7.44694 25.4562 7.44099 25.4599 7.43457L26.0341 7.76815C26.0308 7.77411 26.0276 7.78052 26.0244 7.78694Z"
        fill="#2B2A2C"
      />
      <path
        d="M26.034 7.76927L25.4598 7.43569C25.463 7.42927 25.4667 7.42332 25.4699 7.41736L26.0441 7.75094C26.0404 7.75689 26.0372 7.76331 26.034 7.76927Z"
        fill="#2C2C2D"
      />
      <path
        d="M26.0445 7.75108L25.4704 7.4175C25.474 7.41108 25.4777 7.40513 25.4818 7.39917L26.0559 7.73275C26.0523 7.73871 26.0482 7.74512 26.0445 7.75108Z"
        fill="#2D2D2E"
      />
      <path
        d="M26.056 7.73054L25.4818 7.39696C25.486 7.39055 25.4901 7.38413 25.4947 7.37817L26.0688 7.71175C26.0647 7.71771 26.0601 7.72413 26.056 7.73054Z"
        fill="#2F2E30"
      />
      <path
        d="M26.0689 7.71235L25.4947 7.37877C25.4993 7.3719 25.5043 7.36548 25.5094 7.35907L26.0835 7.69265C26.0785 7.69906 26.0739 7.70548 26.0689 7.71235Z"
        fill="#302F31"
      />
      <path
        d="M26.0832 7.69215L25.5091 7.35857C25.5146 7.35124 25.5205 7.34391 25.5269 7.33704L26.1011 7.67062C26.0951 7.67749 26.0892 7.68482 26.0832 7.69215Z"
        fill="#313132"
      />
      <path
        d="M26.1004 7.67195L25.5263 7.33837C25.5341 7.32967 25.5419 7.32096 25.5501 7.31317L26.1242 7.64675C26.116 7.655 26.1082 7.66325 26.1004 7.67195Z"
        fill="#333233"
      />
      <path
        d="M26.1243 7.6462L25.5502 7.31262C25.5731 7.29016 25.5978 7.27092 25.6235 7.2558L26.1976 7.58938C26.172 7.60404 26.1472 7.62329 26.1243 7.6462Z"
        fill="#343335"
      />
      <path
        d="M27.0333 6.18527C26.7527 5.82048 27.5556 4.97328 27.8041 4.69179L27.2306 4.35858C26.9226 4.66936 26.6612 5.02591 26.4635 5.41679C26.393 5.55501 26.3312 5.777 26.4919 5.87586L27.0654 6.20907C27.0539 6.20221 27.0434 6.19442 27.0333 6.18573V6.18527Z"
        fill="#989899"
      />
      <path
        d="M28.9552 1.05972C29.3159 0.908683 29.7535 0.834077 30.1068 1.04187C29.9109 0.949418 29.4729 0.629027 29.2582 0.602938C28.1716 0.448234 27.3285 1.44374 26.8383 2.29552C26.6292 2.76146 26.2049 3.36288 26.5129 3.86407C26.6846 4.05767 27.1716 4.27691 27.3844 4.41926C26.7853 4.17073 26.9761 3.52903 27.1958 3.06949C27.5592 2.25387 28.13 1.44969 28.9552 1.05972Z"
        fill="#959696"
      />
      <path
        d="M30.0392 8.82456C30.0447 8.902 30.0805 8.95378 30.1345 8.97027L29.7615 9.18563C29.7354 9.12652 29.7272 9.03809 29.7235 8.96661L30.0365 8.78607L30.0392 8.82456Z"
        fill="#F1F2F2"
      />
      <path
        d="M30.2312 8.96485L30.6262 8.83197L30.5634 9.22008C30.3311 9.2801 29.9338 9.52937 29.7789 9.22191C29.7968 9.21595 30.211 8.96439 30.2317 8.96485H30.2312Z"
        fill="#F1F2F2"
      />
      <path
        d="M27.5545 10.2585L27.5568 10.2169L27.8698 10.0363C27.8661 10.1238 27.8583 10.2104 27.8331 10.2984L27.4601 10.5138C27.5137 10.4354 27.549 10.3424 27.5541 10.259L27.5545 10.2585Z"
        fill="#F1F2F2"
      />
      <path
        d="M29.8722 6.42139L30.0283 8.68381L29.7161 8.86414L29.5596 6.60172L29.8722 6.42139Z"
        fill="#F1F2F2"
      />
      <path
        d="M27.3639 10.6186C27.3772 10.5994 27.7987 10.3674 27.8161 10.3536C27.7145 10.7399 27.3309 11.0187 27.0348 11.2553L26.9698 10.9413L27.3639 10.6186Z"
        fill="#F1F2F2"
      />
      <path
        d="M27.5634 10.1072L27.7062 7.67222L28.0188 7.49188L27.876 9.9264L27.5634 10.1072Z"
        fill="#F1F2F2"
      />
      <path
        d="M30.4918 3.75442C30.788 4.16909 29.9714 4.81079 29.7865 5.171L29.7659 4.87029C30.1097 4.42129 30.6648 3.89447 29.6959 3.85511C29.6849 3.76448 29.705 3.694 29.7472 3.58781C30.0529 3.54753 30.3046 3.60154 30.4918 3.75442Z"
        fill="#F1F2F2"
      />
      <path
        d="M29.443 3.65332C29.4137 3.73937 29.3931 3.82313 29.3844 3.90368C28.9876 3.9966 28.4905 4.30417 28.1765 4.59069C28.166 4.51929 28.1436 4.45933 28.1115 4.40761C28.4694 4.09134 28.999 3.77049 29.443 3.65332Z"
        fill="#F1F2F2"
      />
      <path
        d="M30.5022 1.44924C29.3507 -0.0950433 27.4494 2.03419 27.0484 3.44345C26.9303 3.77803 26.9573 4.08744 27.118 4.23161C27.3482 4.48976 27.8567 4.51585 27.8682 4.89116C27.8375 5.4134 27.783 6.34345 27.7528 6.85608L28.0654 6.67574C28.0705 6.57322 28.0924 6.23269 28.0979 6.12284L28.1821 4.68108C28.1918 4.38952 27.9689 4.23024 27.7244 4.10666C27.4663 3.96569 27.1715 3.83296 27.3262 3.44666C27.6366 2.56649 28.1094 1.92892 28.7726 1.4973C29.4358 1.16318 29.9113 1.25335 30.2253 1.7733C30.4011 2.33444 29.5649 3.11391 29.4427 3.65491C29.3081 3.88651 29.4706 5.02756 29.4687 5.29028C29.4761 5.3983 29.4999 5.74066 29.5072 5.8441L29.8198 5.66376C29.8042 5.4372 29.7809 5.09942 29.7653 4.87194L29.6953 3.85676L29.6916 3.80504C29.7992 3.09789 30.8588 2.20811 30.5031 1.4497L30.5022 1.44924Z"
        fill="#F1F2F2"
      />
      <path
        d="M29.8277 5.76477L29.8661 6.31921L29.5532 6.5002L29.5152 5.94577L29.8277 5.76477Z"
        fill="#F1F2F2"
      />
      <path
        d="M29.3665 5.58568C29.3665 5.49954 29.4059 5.39919 29.4696 5.28876L29.4971 5.68465L29.3848 5.74972L29.3674 5.59393V5.58568H29.3665Z"
        fill="#F1F2F2"
      />
      <path
        d="M27.713 7.56174L27.7478 6.96514L28.0607 6.78461L28.0259 7.3812L27.713 7.56174Z"
        fill="#F1F2F2"
      />
      <path
        d="M28.0766 6.5048L28.0991 6.12219C28.1999 6.18313 28.1614 6.36184 28.1532 6.46081L28.0766 6.5048Z"
        fill="#F1F2F2"
      />
      <path
        d="M27.0383 5.75053C27.2329 5.36514 27.4892 5.0118 27.8054 4.69232C27.8535 4.74633 27.8787 4.81773 27.8695 4.89188C27.6466 5.13492 27.4631 5.40084 27.3162 5.6924C27.1422 6.04346 27.6004 5.97984 27.8027 6.02973L27.783 6.36202C27.4612 6.24393 26.7198 6.37072 27.0379 5.75053H27.0383Z"
        fill="#F1F2F2"
      />
      <g opacity="0.4">
        <mask
          id="mask0_4613_146066"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="22"
          y="12"
          width="14"
          height="18"
        >
          <path
            d="M35.5058 24.7902C35.6529 27.3712 32.802 29.6036 29.1382 29.7765C25.4743 29.9494 22.385 27.9972 22.2379 25.4163C22.0908 22.8353 22.0921 12.0127 27.8529 12.0128C36.9907 11.5816 35.3587 22.2092 35.5058 24.7902Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_4613_146066)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.9898 18.8057C26.9898 18.8057 27.4543 19.704 27.4138 20.0127C27.4138 20.0127 27.8317 19.1473 27.7829 19.1581C27.734 19.1686 26.9898 18.8057 26.9898 18.8057Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.0508 20.137C27.0508 20.137 27.499 19.7929 28.1136 19.709L27.2694 21.5067L27.0369 20.8351L27.0508 20.137Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.6197 29.231C28.4902 27.9921 26.7369 21.1734 26.7369 21.1734C27.371 20.7298 27.5482 19.1588 27.5482 19.1588C28.8688 18.9166 29.7441 20.0394 30.3543 20.5375C30.7504 20.8632 30.9665 21.1517 30.9665 21.1517C31.603 22.841 31.6197 29.231 31.6197 29.231Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.3731 20.9834C33.3731 20.9834 34.0902 22.5686 34.2734 24.7604C34.4561 26.9522 36.1113 32.9249 36.1113 32.9249L33.9218 33.0742L32.6607 29.0529L33.3731 20.9834Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.621 29.2302V29.9254L31.4529 30.1658L28.2706 23.627C28.6981 24.171 29.2265 24.7635 29.6544 25.2049C31.4243 27.0406 31.621 29.2302 31.621 29.2302Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.9289 28.5628L25.9218 28.5389C25.9194 28.2436 25.8999 27.9573 25.8546 27.667C25.8089 27.3781 25.7561 27.09 25.6838 26.8037C25.6023 26.5196 25.5109 26.2376 25.3957 25.9621C25.29 25.7022 25.1653 25.4441 25.033 25.1929L25.0116 25.1513L25.0211 25.1448L25.0449 25.1863L25.0497 25.1929C25.1939 25.4376 25.331 25.687 25.451 25.9426C25.5781 26.2159 25.6838 26.4953 25.7751 26.7817C25.8642 27.0658 25.9408 27.3564 25.9722 27.654L25.9746 27.7038C25.9984 27.9858 25.9913 28.2855 25.9289 28.5628Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.708 33.4331C33.5713 33.86 33.1788 34.2638 32.5645 34.5899C32.5137 34.6206 32.4541 34.6441 32.3941 34.6672C32.0957 34.8147 31.7628 34.9311 31.4132 35.0168C29.8605 35.4047 27.9496 35.2652 26.6615 34.5899C26.1581 34.318 25.7998 33.9999 25.6123 33.6583C25.5356 33.5108 25.4843 33.3709 25.4589 33.2238C25.4164 32.9754 25.4247 32.6493 25.5014 32.3623C25.6039 31.4618 25.1856 26.5251 24.4946 25.5003C24.2988 25.2132 24.0771 24.9182 23.8467 24.6312C23.6929 24.4526 23.5392 24.2741 23.3687 24.0955L23.3004 24.1345C23.4029 24.0026 23.9488 23.4357 24.6313 22.8071C24.8789 22.5819 25.1431 22.357 25.4164 22.1242C25.5781 21.9999 25.7402 21.8679 25.9023 21.7435C25.9877 21.6814 26.0731 21.6195 26.1498 21.5574L26.1581 21.5494C26.4057 21.3712 27.0369 20.8354 27.0369 20.8354L27.1649 21.3477C27.2245 21.7981 27.3612 22.2327 27.5828 22.6365C27.9241 23.2882 28.9309 24.4602 29.6559 25.2053C31.4215 27.0374 31.6178 29.2339 31.6178 29.2339C31.6178 29.2339 31.6007 23.3117 31.0377 21.3632C31.0802 21.3477 31.1061 21.3322 31.1232 21.309C31.1486 21.27 31.1915 21.2082 31.2511 21.1304V21.1228C31.3107 21.0372 31.1744 20.9099 31.2511 20.7465C31.3248 20.6204 31.4815 20.6959 31.4986 20.6572L32.5391 20.8354L33.3667 20.9829L33.375 20.9985C33.4521 21.1073 33.8955 21.9142 33.5713 25.1355C33.5455 25.5082 33.4521 25.9276 33.2388 26.3542C33.2388 26.3542 33.29 29.1485 33.5796 30.8563C33.6909 31.5084 33.7421 32.2069 33.7676 32.8276V32.8586C33.7759 32.9443 33.7505 33.3322 33.708 33.4331Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.9719 22.2347C26.1228 22.1071 26.5437 21.7222 26.6597 21.6283C26.6001 21.4269 26.5408 21.3198 26.5336 21.25C26.4927 21.2782 26.2019 21.5215 26.161 21.5518L26.1514 21.5605C26.1169 21.5855 26.0827 21.6104 26.0477 21.6357C26.0064 21.6668 25.9639 21.6979 25.9214 21.729C25.9154 21.7333 25.9099 21.7377 25.9043 21.742C25.745 21.8635 25.5849 21.9918 25.4264 22.1234C25.5074 22.1234 25.5805 22.1237 25.6381 22.1389C25.7538 22.161 25.865 22.191 25.9719 22.2347Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.0313 26.3343C26.0313 26.3343 26.0313 26.3694 26.0289 26.4327C26.017 26.741 25.9808 27.7178 25.9208 28.5373C25.8942 28.9176 25.8628 29.265 25.8271 29.4946C25.6975 30.3184 25.3253 32.2131 25.1687 33.9136C25.0225 35.5371 24.8398 37.3901 24.8398 37.6569L24.8426 37.6851C24.8426 37.8294 24.7365 37.9736 24.5279 38.0828C24.2299 38.2404 23.7949 38.2863 23.4206 38.2185C23.2669 38.1922 23.125 38.1463 23.0027 38.0828C22.7913 37.9736 22.6856 37.8276 22.6856 37.6833V31.271L23.0388 24.7607C23.0388 24.7191 23.0412 24.6776 23.0432 24.6342C23.0674 24.4241 23.1489 24.2604 23.2764 24.1512C23.3074 24.1227 23.3388 24.0988 23.3749 24.0789C23.5716 23.9611 23.841 23.939 24.1529 24.0287C24.2756 24.0637 24.4028 24.114 24.535 24.1838C25.361 24.619 26.0313 25.5802 26.0313 26.3343Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.088 22.8429L30.763 22.7284L32.2549 22.7858L32.5775 28.2492L32.1881 29.4584L31.5143 29.6226L29.8818 28.0938L28.6764 26.6431L26.9581 23.8443V22.8599L27.879 22.2949L29.1794 23.0501L30.088 22.8429Z"
            fill="#9C9C9C"
          />
          <g style={{ mixBlendMode: "darken" }} opacity="0.4">
            <g style={{ mixBlendMode: "darken" }} opacity="0.4">
              <path
                d="M26.5326 29.1939C29.6595 28.7904 32.2905 27.0925 33.2151 26.2939C33.2992 26.9664 33.4673 28.4122 33.4673 28.8156C33.4673 29.2191 31.4499 30.3287 30.4412 30.833C27.8355 30.4547 23.4057 29.5974 26.5326 29.1939Z"
                fill="#9C9C9C"
              />
            </g>
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.1542 20.7967C27.4391 21.5671 28.2341 22.2355 30.0879 22.8435L29.6655 24.2151C29.6655 24.2151 27.1312 23.1584 26.4542 21.3187L27.0505 20.1377C27.0505 20.1377 27.1165 20.6955 27.1542 20.7967Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.5785 35.101L31.4942 35.088L31.495 35.0099C31.5399 34.7659 31.3623 33.4796 31.1601 32.0723C30.8824 30.1401 30.5363 27.7357 30.6198 26.8349C30.7616 25.3093 30.0934 22.8674 30.087 22.8428L30.1792 22.8262C30.1856 22.8508 30.847 25.3035 30.7048 26.8414C30.6222 27.7343 30.9674 30.1339 31.2447 32.0618C31.4569 33.5346 31.6262 34.7677 31.5793 35.0225L31.5785 35.101Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.4815 23.7853L30.7628 22.7279C30.8736 22.5985 30.8861 21.648 30.8266 20.934L30.8607 20.9261C30.9032 20.9105 30.9291 20.895 30.9461 20.8719C30.9716 20.8328 31.0145 20.771 31.0741 20.6933V20.6857C31.1253 20.608 31.1933 20.5071 31.2533 20.3672C31.432 20.8794 31.9738 22.6647 31.4815 23.7853Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.8725 24.887H27.0195C27.0195 24.2428 26.3965 23.3658 25.6548 22.962C25.4414 22.8456 25.1172 22.7603 24.793 22.7834C24.7589 22.7913 24.6993 22.7989 24.631 22.8069C24.8785 22.5817 25.1427 22.3568 25.416 22.124C25.501 22.124 25.5777 22.124 25.6377 22.1396C25.7994 22.1706 25.9532 22.2169 26.0982 22.2946C27.0962 22.838 27.8725 23.9789 27.8725 24.887Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.8741 24.8867L28.3008 30.7087C28.0108 30.6776 27.7207 30.6389 27.4474 30.5768L27.0211 24.8867H27.8741Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.0251 22.8302V23.2185H32.1722V22.8302C32.1722 22.2558 32.0184 21.2468 31.4554 20.7577C31.4725 20.7266 31.1984 20.6204 31.4983 20.6572L32.5389 20.8354C32.8885 21.472 33.0251 22.2558 33.0251 22.8302Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.023 22.8291V29.5277C32.733 29.4967 32.4429 29.9214 32.17 29.8592V22.8291H33.023Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.8183 37.9571C22.8187 37.9569 22.8191 37.9561 22.8191 37.9554C22.7305 37.8712 22.6864 37.7768 22.6864 37.6835V36.1334C22.686 36.1326 22.686 36.1319 22.6856 36.1312V37.6851C22.6856 37.7781 22.7297 37.8727 22.8183 37.9571ZM22.6864 35.3464V31.2712L23.0396 24.7609C23.0396 24.7193 23.042 24.6777 23.044 24.6344C23.0555 24.5349 23.0798 24.446 23.1159 24.3679C23.0794 24.4464 23.0547 24.5357 23.0432 24.6358C23.0412 24.6795 23.0388 24.7211 23.0388 24.7627L22.6856 31.273V35.3424C22.686 35.3439 22.686 35.3453 22.6864 35.3464ZM23.2716 24.155L23.274 24.1539C23.2752 24.1532 23.276 24.1521 23.2772 24.1514C23.2871 24.142 23.2971 24.1337 23.3074 24.1253C23.3591 24.0606 23.4925 23.9164 23.6829 23.7227C23.4524 23.954 23.3018 24.1152 23.2716 24.155Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.3072 24.1252C23.3287 24.1078 23.3509 24.0923 23.3756 24.0789C23.4908 24.0099 23.631 23.9737 23.7903 23.9737C23.9028 23.9737 24.0243 23.9914 24.1535 24.0286C24.1539 24.0286 24.1539 24.0286 24.1539 24.0286C24.4379 23.6505 24.8678 23.2193 25.2961 22.827C25.1725 22.797 25.0382 22.7793 24.9016 22.7793C24.8654 22.7793 24.8293 22.7804 24.7931 22.7829C24.7609 22.7901 24.7065 22.7977 24.6433 22.805C24.2651 23.1495 23.9286 23.4759 23.6827 23.7225C23.4924 23.9162 23.3589 24.0605 23.3072 24.1252Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.4198 38.2184C23.433 37.7678 23.4437 37.2188 23.4536 36.6024C23.2784 36.5662 23.1147 36.5084 22.9788 36.4288C22.8267 36.3414 22.7289 36.2394 22.6856 36.1331V37.6833C22.6856 37.7766 22.7297 37.8709 22.8183 37.9551C22.8668 38.0013 22.928 38.0444 23.0027 38.0827C23.1247 38.1463 23.2665 38.1922 23.4198 38.2184ZM23.4639 35.8815C23.5192 31.9317 23.5557 25.9973 23.8422 24.6356C23.8616 24.5394 23.8835 24.4653 23.9073 24.4172C23.9649 24.299 24.0468 24.1678 24.1521 24.03C24.1525 24.0297 24.1529 24.0293 24.1533 24.0286C24.1533 24.0286 24.1533 24.0286 24.1529 24.0286C24.0237 23.9913 23.9022 23.9736 23.7897 23.9736C23.6304 23.9736 23.4902 24.0098 23.375 24.0788C23.3503 24.0922 23.3281 24.1077 23.3066 24.1251C23.2963 24.1334 23.2864 24.1417 23.2764 24.1511C23.2752 24.1518 23.2744 24.1529 23.2732 24.1537L23.2756 24.1526C23.2089 24.2097 23.1548 24.282 23.1151 24.3677C23.079 24.4457 23.0547 24.5347 23.0432 24.6341C23.0412 24.6775 23.0388 24.719 23.0388 24.7606L22.6856 31.2709V35.3461C22.7095 35.4596 22.7877 35.5742 22.9347 35.674L22.9924 35.7084C23.1274 35.7872 23.2903 35.8454 23.4639 35.8815Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.9215 21.7301C25.964 21.699 26.0066 21.6678 26.048 21.6367C26.0062 21.6671 25.964 21.6982 25.9215 21.7301Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.2964 22.8275C25.53 22.6135 25.7632 22.4114 25.9718 22.2354C25.8649 22.1916 25.7537 22.1616 25.6381 22.1396C25.5805 22.1244 25.5074 22.124 25.4263 22.124C25.1566 22.3474 24.8912 22.5802 24.6436 22.8054C24.7068 22.7982 24.7612 22.7906 24.7934 22.7834C24.8296 22.7809 24.8657 22.7798 24.9019 22.7798C25.0385 22.7798 25.1729 22.7975 25.2964 22.8275Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.0432 18.1276C31.864 18.6168 31.8807 18.9656 31.8807 18.9656C31.8128 19.7653 31.6591 20.301 31.4973 20.6582C31.4803 20.7124 31.4544 20.7587 31.429 20.8053C31.369 20.9452 31.3011 21.0461 31.2498 21.1238V21.1314C31.1902 21.2091 31.1473 21.2709 31.1219 21.31C31.1048 21.3331 31.079 21.3486 31.0365 21.3642L31.0023 21.3721C30.6698 21.4419 29.7568 21.2709 29.1767 21.0536C28.5029 20.7977 28.085 20.138 28.085 20.138C27.3512 19.2064 27.6587 18.834 26.6007 17.5999C26.8649 14.6269 29.2876 14.8752 29.2876 14.8752C32.1541 14.8752 32.0432 18.1276 32.0432 18.1276Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.1187 16.8004C31.984 16.7325 31.8136 16.7805 31.6912 16.8637C31.5684 16.949 31.4751 17.0647 31.3523 17.15C31.021 17.3813 30.5526 17.3488 30.1469 17.2592C29.6904 17.1587 28.9554 16.9512 28.5184 17.2024C28.4079 17.2176 28.3575 17.3358 28.3428 17.4338C28.2951 17.7486 28.3575 18.0679 28.3046 18.3824C28.2518 18.682 28.2685 18.8447 28.2685 18.8447L27.9348 18.7214C27.8152 18.8063 27.7256 18.8699 27.6658 18.9123C27.5676 18.982 27.4201 19.0866 27.2236 19.2261C27.2236 19.2261 26.5585 18.0613 26.4457 17.5935C26.3329 17.1258 26.4791 16.1009 27.2188 15.4148C27.9562 14.7261 28.9145 14.7109 29.3205 14.7109C30.632 14.7109 31.8136 15.2922 32.073 16.5466C32.0802 16.5817 32.0992 16.68 32.1187 16.8004Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.9379 18.7251C27.8863 18.5661 27.8418 18.4055 27.8132 18.2418C27.7758 18.0274 27.6467 17.6594 27.199 17.7346C26.5744 17.8398 27.2149 19.0389 27.4381 19.1445C27.6618 19.2504 27.9379 18.7251 27.9379 18.7251Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.8715 21.3829C30.8735 21.4509 30.8735 21.5163 30.8715 21.5818C30.247 21.7086 29.5365 21.8617 28.8659 21.3872C28.1953 20.9127 27.5748 19.2266 27.5748 19.2266C27.5748 19.2266 28.508 20.7973 29.1802 21.0529C29.6896 21.2499 30.4703 21.405 30.8715 21.3829Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.5209 14.4043C27.5209 14.4043 26.6629 14.656 26.3928 15.4033C26.3928 15.4033 26.0159 16.1767 26.5456 16.8829C27.0753 17.5894 28.0824 18.0367 28.0824 18.0367C28.0824 18.0367 28.7174 18.3102 29.5046 18.4531L30.5163 18.6889C30.5163 18.6889 31.0517 18.7714 31.5385 18.5729C32.0252 18.374 32.3611 18.1035 32.3611 18.1035C32.3611 18.1035 33.0963 17.5818 32.7518 16.8965C32.4073 16.2109 31.691 15.4272 31.4115 15.2225L27.5209 14.4043Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.483 14.4092C27.483 14.4092 26.6381 14.6561 26.371 15.3865C26.371 15.3865 26.1026 16.0472 26.6233 16.7368C27.1435 17.4261 28.1347 17.8618 28.1347 17.8618C28.1347 17.8618 28.7594 18.1283 29.5341 18.2666L30.5296 18.4961C30.5296 18.4961 31.0563 18.5765 31.536 18.3816C32.0153 18.1867 32.3464 17.9224 32.3464 17.9224C32.3464 17.9224 33.071 17.4115 32.7324 16.7425C32.3941 16.0731 31.4892 15.4115 31.215 15.2117L27.483 14.4092Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.4327 15.602C26.5084 15.1428 26.7748 14.6033 27.1938 14.2363C27.3201 14.0573 27.7809 13.6245 28.4396 13.5993L29.1053 13.5026C29.1053 13.5026 29.7801 13.2892 30.8078 13.8403C31.8355 14.391 32.1086 15.7361 32.1086 15.7361C32.1086 15.7361 32.2513 16.5253 32.059 17.0225C32.059 17.0225 31.8701 17.7868 30.602 18.003C29.3342 18.2191 28.1953 17.7535 28.1953 17.7535C26.6744 17.083 26.3422 16.1502 26.4327 15.602Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.7306 14.9639C27.3657 15.6037 29.1314 16.6684 30.4043 17.1957L26.7306 14.9639Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.2911 14.2549C27.2911 14.2549 27.7784 13.8441 28.5734 14.1148C29.3684 14.3848 29.9051 14.73 29.9051 14.73C29.9051 14.73 30.8525 15.6264 31.2151 16.357C31.2151 16.357 31.2348 15.6034 30.5144 14.8155C29.7018 13.9266 28.4122 13.6943 28.4122 13.6943C28.4122 13.6943 27.6908 13.7884 27.2911 14.2549Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.1559 13.5653C30.2779 13.5898 31.4072 14.2329 31.9032 15.772C31.9032 15.772 31.7415 14.8311 31.1769 14.2783C30.7322 13.8424 29.984 13.4534 29.1559 13.5653Z"
            fill="#9C9C9C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.2726 14.2871C27.2726 14.2871 27.6776 13.9661 28.4727 14.2368C29.2676 14.5068 29.8556 14.9466 29.8556 14.9466C29.8556 14.9466 30.871 15.7069 31.2336 16.4376C31.2336 16.4376 31.2009 16.0357 30.4805 15.2477C29.7601 14.4598 28.5239 14.055 28.5239 14.055C28.5239 14.055 27.6722 13.8205 27.2726 14.2871Z"
            fill="#9C9C9C"
          />
        </g>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6229 38.8979C8.51165 37.68 6.7903 38.4122 6.7903 40.5275C6.7903 42.6429 8.51165 45.3611 10.6229 46.579C12.7329 47.7969 14.4542 47.0647 14.4542 44.95C14.4542 42.834 12.7329 40.1158 10.6229 38.8979Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45709 40.5228C6.34588 39.305 4.62453 40.0371 4.62453 42.1525C4.62453 44.2678 6.34588 46.9861 8.45709 48.2039C10.5671 49.4218 12.2884 48.6896 12.2884 46.5749C12.2884 44.459 10.5671 41.7407 8.45709 40.5228Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45746 41.814C7.05578 41.0055 5.91261 41.4912 5.91261 42.896C5.91261 44.3002 7.05578 46.1054 8.45746 46.9145C9.85853 47.723 11.0017 47.2367 11.0017 45.8325C11.0017 44.4277 9.85853 42.6225 8.45746 41.814Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56371 42.0008C7.32638 41.2866 6.31696 41.7158 6.31696 42.9559C6.31696 44.196 7.32638 45.7896 8.56371 46.5031C9.80045 47.2173 10.8099 46.7881 10.8099 45.548C10.8099 44.3084 9.80045 42.7143 8.56371 42.0008Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56368 42.3248C7.50508 41.714 6.6414 42.0813 6.6414 43.1423C6.6414 44.2033 7.50508 45.5672 8.56368 46.1779C9.62228 46.7887 10.4854 46.4214 10.4854 45.3604C10.4854 44.2994 9.62228 42.9361 8.56368 42.3248Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56361 42.7156C7.71853 42.2281 7.02939 42.5208 7.02939 43.3678C7.02939 44.2142 7.71853 45.3028 8.56361 45.7903C9.4081 46.2772 10.0972 45.9845 10.0972 45.1381C10.0972 44.2911 9.4081 43.2031 8.56361 42.7156Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56359 42.9099C7.82527 42.4837 7.22369 42.7398 7.22369 43.4792C7.22369 44.2186 7.82527 45.1695 8.56359 45.5951C9.30131 46.0207 9.90289 45.7646 9.90289 45.0253C9.90289 44.2859 9.30131 43.3355 8.56359 42.9099Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0017 48.6598C11.5095 48.6419 12.4081 48.0199 13.6973 46.7939L12.861 44.2626L11.5157 46.8709C10.6652 48.0814 10.4939 48.6777 11.0017 48.6598Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.09474 38.4874V38.5462L7.90459 40.0141L5.12572 40.5262C7.10507 38.9926 8.09474 38.2903 8.09474 38.4196C8.09474 38.5489 8.09474 38.5715 8.09474 38.4874Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3587 38.8979C31.2475 37.68 29.5262 38.4122 29.5262 40.5275C29.5262 42.6429 31.2475 45.3611 33.3587 46.579C35.4687 47.7969 37.1901 47.0647 37.1901 44.95C37.1901 42.834 35.4687 40.1158 33.3587 38.8979Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1936 40.5228C29.0824 39.305 27.3611 40.0371 27.3611 42.1525C27.3611 44.2678 29.0824 46.9861 31.1936 48.2039C33.3036 49.4218 35.025 48.6896 35.025 46.5749C35.025 44.459 33.3036 41.7407 31.1936 40.5228Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1934 41.814C29.7917 41.0055 28.6485 41.4912 28.6485 42.896C28.6485 44.3002 29.7917 46.1054 31.1934 46.9145C32.5944 47.723 33.7376 47.2367 33.7376 45.8325C33.7376 44.4277 32.5944 42.6225 31.1934 41.814Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2996 41.9995C30.0623 41.2854 29.0529 41.7146 29.0529 42.9547C29.0529 44.1948 30.0623 45.7884 31.2996 46.5019C32.5364 47.216 33.5458 46.7868 33.5458 45.5467C33.5458 44.3072 32.5364 42.7131 31.2996 41.9995Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2996 42.3248C30.241 41.714 29.3773 42.0813 29.3773 43.1423C29.3773 44.2033 30.241 45.5672 31.2996 46.1779C32.3582 46.7887 33.2213 46.4214 33.2213 45.3604C33.2213 44.2994 32.3582 42.9361 31.2996 42.3248Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2995 42.7156C30.4545 42.2281 29.7653 42.5208 29.7653 43.3678C29.7653 44.2142 30.4545 45.3028 31.2995 45.7903C32.144 46.2772 32.8332 45.9845 32.8332 45.1381C32.8332 44.2911 32.144 43.2031 31.2995 42.7156Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2995 42.9111C30.5612 42.4849 29.9596 42.741 29.9596 43.4803C29.9596 44.2197 30.5612 45.1707 31.2995 45.5963C32.0372 46.0219 32.6388 45.7658 32.6388 45.0264C32.6388 44.287 32.0372 43.3367 31.2995 42.9111Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7375 48.6598C34.2454 48.6419 35.1439 48.0199 36.4331 46.7939L35.5969 44.2626L34.2516 46.8709C33.4011 48.0814 33.2297 48.6777 33.7375 48.6598Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8306 38.4874V38.5462L30.6404 40.0141L27.8616 40.5262C29.8409 38.9926 30.8306 38.2903 30.8306 38.4196C30.8306 38.5489 30.8306 38.5715 30.8306 38.4874Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.16 40.6987L34.3218 42.3361V44.8837L37.16 43.2516V40.6987Z"
        fill="#EB922D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3251 38.7576L34.1867 42.2519L34.3217 42.3312L37.1598 40.6992L37.02 40.6162L36.9918 40.6L31.1626 37.1201L28.3251 38.7576Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.7834 42.0696V42.634C36.7834 42.741 36.7258 42.8396 36.6334 42.8931L35.0362 43.8387C34.9546 43.8856 34.8533 43.826 34.8533 43.7317V43.6643L36.2861 42.8144C36.3779 42.7615 36.4361 42.6623 36.4361 42.5559V42.0588L36.6004 41.9644C36.682 41.9175 36.7834 41.977 36.7834 42.0696Z"
        fill="#121311"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4361 42.0594V42.5565C36.4361 42.6629 36.3779 42.7621 36.2861 42.815L34.8533 43.665V43.1691C34.8533 43.0608 34.9109 42.9623 35.0032 42.9088L36.4361 42.0594Z"
        fill="#FFF3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4962 52.7231L19.2831 47.2607V40.5558L28.4962 46.0182V52.7231Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.2805 42.1005L28.4964 46.0174V52.7223L35.2805 48.83V42.1005Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2837 40.5534L28.495 46.0181L35.2803 42.1235L26.0653 36.6371L19.2837 40.5534Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6997 50.4096L23.9404 49.9726L23.4227 47.1088L24.6997 47.862V50.4096Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9685 39.2329L19.4041 40.6389V46.9861L16.9685 45.5891V39.2329Z"
        fill="#502D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3142 46.7978L17.309 45.6202V41.6384L15.3142 42.816V46.7978Z"
        fill="#8D4528"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8784 41.4108L15.3141 42.8168V46.7986L12.8784 45.4017V41.4108Z"
        fill="#6D3822"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.309 41.6383L15.5246 42.6921L15.3141 42.8171L12.8784 41.4189L13.0968 41.2891L13.1405 41.2638L14.8745 40.2329L17.309 41.6383Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9656 43.4495L8.45622 39.6072L0.441434 30.3103L0 27.892L19.1556 39.2092L14.9656 43.4495Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6833 39.4006L14.817 42.3004L9.04837 38.8944L2.0478 30.7769L1.92305 30.0905L17.6833 39.4006Z"
        fill="#874226"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9381 32.3179L19.1555 39.2091L14.9655 43.4494L26.7481 36.5829L30.9381 32.3179Z"
        fill="#CA722E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00120544 27.8906L19.155 39.2097L30.9382 32.3413L11.7814 21L0.00120544 27.8906Z"
        fill="#ECC530"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1326 32.3348L23.4275 35.6614L19.1559 38.15L15.9735 36.2685L4.08056 29.2397L1.80441 27.895L11.7781 22.0617L29.1326 32.3348Z"
        fill="#E2832D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2189 24.4794L4.08056 29.2397L1.80441 27.895L11.7781 22.0617L12.2189 24.4794Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4273 35.6612L19.1557 38.1499L15.9733 36.2684L20.2359 33.7767L23.4273 35.6612Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2348 33.7751L23.4274 35.6602L29.1331 32.3348L11.778 22.0605L12.2194 24.4782L20.2348 33.7751Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1835 39.2286L18.222 38.6551V37.0297L19.1835 37.6031V39.2286Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.416 43.7375L21.8763 39.2959L24.544 36.8589L27.8638 38.8186L29.416 43.7375Z"
        fill="#212220"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.916 36.48L27.8639 38.8196L29.4161 43.7385L33.4682 41.4134L31.916 36.48Z"
        fill="#434341"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.545 36.8584L27.8636 38.8193L31.9163 36.4929L28.5953 34.5189L24.545 36.8584Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0467 47.3659L34.7295 44.0802L34.8651 44.1553L29.0467 50.8326V47.3659Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0467 47.3658L29.1822 47.4409V50.9076L29.0467 50.8324V47.3658Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1823 47.441L34.8651 44.1553V47.5648L29.1823 50.9076V47.441Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 44.4773V47.4576L33.8832 47.9265L33.7626 47.9974L32.307 48.854L32.1864 48.9256L30.7272 49.7822L30.6096 49.8537L30.4951 49.9192L29.3675 50.5835V47.9818L30.3745 47.4017L30.6096 47.2683V47.1439L30.3745 47.2773L29.3675 47.858V47.5472L30.3745 46.9671L30.7272 46.7621L31.9537 46.0516L32.1864 45.9176L32.307 45.8496L33.5305 45.1415L33.7626 45.0081L33.8832 44.9372L34.6791 44.4773Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 45.7714V45.8953L33.8832 46.3515L33.7626 46.423L33.5305 46.5565L32.307 47.258L32.1864 47.3295L31.9537 47.463L30.7272 48.1675L30.6096 48.236L30.3745 48.3695L29.3675 48.9501V48.8257L30.3745 48.245L30.6096 48.1116L30.7272 48.0436L31.9537 47.3391L32.1864 47.2051L32.307 47.1342L33.5305 46.4327L33.7626 46.2992L33.8832 46.2277L34.6791 45.7714Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 48.603V48.7245L30.3745 48.8579L29.3675 49.438V49.3172L30.3745 48.7365L30.6096 48.603Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 46.2593V46.3831L33.8831 46.8394L33.7626 46.9109L33.5305 47.0443L32.3069 47.7458L32.1864 47.8174L31.9537 47.9508L30.7271 48.6553L30.6096 48.7239V48.6024L30.7271 48.5345L31.9537 47.8264L32.1864 47.6929L32.3069 47.625L33.5305 46.9199L33.7626 46.7865L33.8831 46.7185L34.679 46.2593Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 49.0909V49.2147L30.3745 49.3488L29.3675 49.9288V49.805L30.3745 49.2243L30.6096 49.0909Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 49.5776V49.7014L30.3745 49.8349L29.3675 50.4155V50.2911L30.3745 49.711L30.6096 49.5776Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 47.2375V47.3583L33.8831 47.8182L33.7626 47.8861L33.5305 48.0195L32.3069 48.7247L32.1864 48.7926L31.9537 48.926L30.7271 49.6341L30.6096 49.7021V49.5782L30.7271 49.5097L31.9537 48.8052L32.1864 48.6718L32.3069 48.6002L33.5305 47.8987L33.7626 47.7653L33.8831 47.6937L34.679 47.2375Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5513 45.723L34.6791 45.7723L33.8832 46.2286L33.7626 46.3001L33.5305 46.4335L32.307 47.135L32.1864 47.206L31.9537 47.34L30.7272 48.0445L30.6096 48.1125L30.3745 48.2459L29.3675 48.8266V48.7058L30.3745 48.1251L30.6096 47.9916L30.7272 47.9231L31.9537 47.2156L32.1864 47.0822L32.307 47.0136L33.5305 46.3091L33.7626 46.1757L33.8832 46.1071L34.5513 45.723Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5513 46.2133L34.6791 46.2596L33.8832 46.7189L33.7626 46.7868L33.5305 46.9202L32.307 47.6253L32.1864 47.6933L31.9537 47.8267L30.7272 48.5348L30.6096 48.6028L30.3745 48.7362L29.3675 49.3169V49.1925L30.3745 48.6124L30.6096 48.4789L30.7272 48.4104L31.9537 47.7059L32.1864 47.5724L32.307 47.5009L33.5305 46.7994L33.7626 46.666L33.8832 46.5944L34.5513 46.2133Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 47.1442V47.2687L30.3745 47.4021L29.3675 47.9822V47.8584L30.3745 47.2777L30.6096 47.1442Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 44.8005V44.9244L33.8831 45.3836L33.7626 45.4521L33.5305 45.5856L32.3069 46.2901L32.1864 46.3586L31.9537 46.4921L30.7271 47.1996L30.6096 47.2681V47.1437L30.7271 47.0758L31.9537 46.3677L32.1864 46.2342L32.3069 46.1663L33.5305 45.4612L33.7626 45.3277L33.8831 45.2598L34.679 44.8005Z"
        fill="#2D2E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 45.2908V45.4123L33.8832 45.8715L33.7626 45.94L33.5305 46.0735L32.307 46.778L32.1864 46.8459L31.9537 46.98L30.7272 47.6875L30.6096 47.7554L30.3745 47.8895L29.3675 48.4695V48.3457L30.3745 47.765L30.6096 47.6316L30.7272 47.5631L31.9537 46.8585L32.1864 46.7251L32.307 46.6536L33.5305 45.9521L33.7626 45.8186L33.8832 45.7471L34.6791 45.2908Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5513 44.7545L34.6791 44.8008L33.8832 45.2601L33.7626 45.328L33.5305 45.4614L32.307 46.1665L32.1864 46.2345L31.9537 46.3679L30.7272 47.076L30.6096 47.144L30.3745 47.2774L29.3675 47.8581V47.7337L30.3745 47.1536L30.6096 47.0201L30.7272 46.9516L31.9537 46.2471L32.1864 46.1136L32.307 46.0421L33.5305 45.3406L33.7626 45.2072L33.8832 45.1356L34.5513 44.7545Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5513 45.2411L34.6791 45.2904L33.8832 45.7467L33.7626 45.8182L33.5305 45.9517L32.307 46.6532L32.1864 46.7247L31.9537 46.8582L30.7272 47.5627L30.6096 47.6312L30.3745 47.7646L29.3675 48.3453V48.2239L30.3745 47.6432L30.6096 47.5104L30.7272 47.4418L31.9537 46.7337L32.1864 46.6003L32.307 46.5324L33.5305 45.8278L33.7626 45.6944L33.8832 45.6259L34.5513 45.2411Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8832 44.9373V47.9267L33.7626 47.9976V45.0082L33.8832 44.9373Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 45.0075V45.3273L33.5308 45.3399V45.141L33.7629 45.0075Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 45.4518V45.8179L33.5308 45.8305V45.5853L33.7629 45.4518Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 45.9397V46.2998L33.5308 46.3118V46.0731L33.7629 45.9397Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 46.424V46.787L33.5308 46.7996V46.5574L33.7629 46.424Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 46.7496V46.871L33.8831 47.3303L33.7626 47.3988L33.5305 47.5322L32.3069 48.2367L32.1864 48.3053L31.9537 48.4387L30.7271 49.1462L30.6096 49.2142V49.0903L30.7271 49.0218L31.9537 48.3173L32.1864 48.1838L32.3069 48.1123L33.5305 47.4108L33.7626 47.2774L33.8831 47.2058L34.679 46.7496Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 46.9118V47.2779L33.5308 47.2905V47.0453L33.7629 46.9118Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 47.3985V47.7646L33.5308 47.7772V47.5319L33.7629 47.3985Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 47.8851V47.9969L33.5308 48.1334V48.0186L33.7629 47.8851Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3069 45.8501V48.8545L32.1864 48.926V45.918L32.3069 45.8501Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 45.9179V46.2347L31.9539 46.2473V46.052L32.1867 45.9179Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 46.3586V46.7247L31.9539 46.7373V46.492L32.1867 46.3586Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 46.8452V47.2053L31.9539 47.2179V46.9793L32.1867 46.8452Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 47.3295V47.6925L31.9539 47.7052V47.4629L32.1867 47.3295Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 47.8173V48.1834L31.9539 48.196V47.9508L32.1867 47.8173Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 48.3052V48.6713L31.9539 48.6833V48.4387L32.1867 48.3052Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 48.7919V48.9253L31.9539 49.0618V48.9253L32.1867 48.7919Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7271 46.7617V49.7817L30.6096 49.8533V46.8302L30.7271 46.7617Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 46.8307V47.1439L30.3747 47.1535V46.9671L30.6098 46.8307Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 47.2678V47.6308L30.3747 47.6429V47.4012L30.6098 47.2678Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 47.7544V48.1115L30.3747 48.1241V47.8884L30.6098 47.7544Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 48.2362V48.6023L30.3747 48.6149V48.3697L30.6098 48.2362Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 48.7241V49.0902L30.3747 49.1022V48.8576L30.6098 48.7241Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 49.2144V49.578L30.3747 49.5901V49.3484L30.6098 49.2144Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3747 49.8345L30.6098 49.701V49.8531L30.4953 49.9187H30.3747V49.8345Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0467 46.5064L34.7295 43.2207L34.8651 43.2958L29.0467 49.7699V46.5064Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0467 46.5063L29.1822 46.5814V49.8449L29.0467 49.7698V46.5063Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1823 46.5814L34.8651 43.2957V46.5021L29.1823 49.8449V46.5814Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 43.6177V46.5975L33.8832 47.0664L33.7626 47.1373L32.307 47.9945L32.1864 48.0654L31.9537 48.2025L30.7272 48.9226L30.6096 48.9935L30.4951 49.0591L29.3675 49.7233V47.1223L30.3745 46.5416L30.6096 46.4081V46.2837L30.3745 46.4171L29.3675 46.9978V46.6876L30.3745 46.107L30.6096 45.9705L30.7272 45.902L31.9537 45.1915L32.1864 45.058L32.307 44.9895L33.5305 44.282L33.7626 44.1485L33.8832 44.077L34.6791 43.6177Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 44.9131V45.0375L33.8832 45.4932L33.7626 45.5647L33.5305 45.6981L32.307 46.3997L32.1864 46.4712L31.9537 46.6046L30.7272 47.3091L30.6096 47.3777L30.3745 47.5111L29.3675 48.0918V47.9674L30.3745 47.3873L30.6096 47.2532L30.7272 47.1853L31.9537 46.4808L32.1864 46.3474L32.307 46.2758L33.5305 45.5743L33.7626 45.4409L33.8832 45.3693L34.6791 44.9131Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 47.7435V47.865L30.3745 47.9984L29.3675 48.5791V48.4577L30.3745 47.877L30.6096 47.7435Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 45.3998V45.5236L33.8831 45.9799L33.7626 46.0514L33.5305 46.1848L32.3069 46.8863L32.1864 46.9579L31.9537 47.0913L30.7271 47.7958L30.6096 47.8644V47.7429L30.7271 47.675L31.9537 46.9669L32.1864 46.8334L32.3069 46.7655L33.5305 46.0604L33.7626 45.9276L33.8831 45.859L34.679 45.3998Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 48.2302V48.3546L30.3745 48.488L29.3675 49.0681V48.9443L30.3745 48.3636L30.6096 48.2302Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 48.718V48.8418L30.3745 48.9753L29.3675 49.556V49.4315L30.3745 48.8515L30.6096 48.718Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 46.3779V46.4994L33.8831 46.9586L33.7626 47.0271L33.5305 47.1606L32.3069 47.8651L32.1864 47.933L31.9537 48.0665L30.7271 48.7746L30.6096 48.8425V48.7187L30.7271 48.6502L31.9537 47.9456L32.1864 47.8122L32.3069 47.7407L33.5305 47.0392L33.7626 46.9057L33.8831 46.8342L34.679 46.3779Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6096 46.2835V46.4079L30.3745 46.5414L29.3675 47.1221V46.9976L30.3745 46.417L30.6096 46.2835Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 43.941V44.0648L33.8831 44.5241L33.7626 44.5926L33.5305 44.726L32.3069 45.4306L32.1864 45.4991L31.9537 45.6325L30.7271 46.34L30.6096 46.4086V46.2841L30.7271 46.2162L31.9537 45.5081L32.1864 45.3746L32.3069 45.3067L33.5305 44.6022L33.7626 44.4688L33.8831 44.4002L34.679 43.941Z"
        fill="#2D2E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6791 44.4313V44.5527L33.8832 45.012L33.7626 45.0805L33.5305 45.2139L32.307 45.9184L32.1864 45.987L31.9537 46.1204L30.7272 46.8279L30.6096 46.8965L30.3745 47.0299L29.3675 47.61V47.4862L30.3745 46.9055L30.6096 46.772L30.7272 46.7035L31.9537 45.999L32.1864 45.8655L32.307 45.794L33.5305 45.0925L33.7626 44.9591L33.8832 44.8881L34.6791 44.4313Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8832 44.0778V47.0671L33.7626 47.138V44.1493L33.8832 44.0778Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 44.1492V44.469L33.5308 44.481V44.2827L33.7629 44.1492Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 44.5923V44.9584L33.5308 44.971V44.7257L33.7629 44.5923Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 45.0801V45.4402L33.5308 45.4522V45.2136L33.7629 45.0801Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 45.5644V45.9281L33.5308 45.9401V45.6978L33.7629 45.5644Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.679 45.8901V46.0115L33.8831 46.4708L33.7626 46.5393L33.5305 46.6727L32.3069 47.3772L32.1864 47.4458L31.9537 47.5792L30.7271 48.2867L30.6096 48.3553V48.2308L30.7271 48.1623L31.9537 47.4578L32.1864 47.3243L32.3069 47.2528L33.5305 46.5513L33.7626 46.4179L33.8831 46.3469L34.679 45.8901Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 46.0511V46.4172L33.5308 46.4298V46.1845L33.7629 46.0511Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 46.5389V46.905L33.5308 46.9176V46.6724L33.7629 46.5389Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7629 47.0268V47.138L33.5308 47.2751V47.1602L33.7629 47.0268Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3069 44.9906V47.9956L32.1864 48.0665V45.0591L32.3069 44.9906Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 45.0583V45.3745L31.9539 45.3872V45.1918L32.1867 45.0583Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 45.499V45.8651L31.9539 45.8777V45.6325L32.1867 45.499Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 45.9869V46.347L31.9539 46.359V46.1204L32.1867 45.9869Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 46.47V46.833L31.9539 46.8457V46.6034L32.1867 46.47Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 46.9578V47.3239L31.9539 47.3365V47.0913L32.1867 46.9578Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 47.4457V47.8118L31.9539 47.8244V47.5791L32.1867 47.4457Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1867 47.9324V48.0658L31.9539 48.2029V48.0658L32.1867 47.9324Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7271 45.9022V48.9228L30.6096 48.9937V45.9707L30.7271 45.9022Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 45.9712V46.2844L30.3747 46.294V46.1076L30.6098 45.9712Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 46.4082V46.7713L30.3747 46.7839V46.5417L30.6098 46.4082Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 46.8961V47.2525L30.3747 47.2651V47.0295L30.6098 46.8961Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 47.3767V47.7428L30.3747 47.7554V47.5102L30.6098 47.3767Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 47.8646V48.2306L30.3747 48.2433V47.998L30.6098 47.8646Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6098 48.3549V48.7179L30.3747 48.73V48.4883L30.6098 48.3549Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3747 48.9749L30.6098 48.8415V48.9936L30.4953 49.0591H30.3747V48.9749Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9497 53.5409L27.5358 53.2969V52.4709L27.9497 52.715V53.5409Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3761 47.873L27.9499 52.7151V53.541L36.3761 48.7008V47.873Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5358 52.4705L27.9497 52.7145L36.3759 47.8743L36.3303 47.8473L36.3213 47.8425L35.9621 47.6285L27.5358 52.4705Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9141 39.8293L18.222 37.0299V36.2911L22.9141 39.0899V39.8293Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6986 41.4925L4.1669 34.0597V33.3203L16.6986 40.7531V41.4925Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6687 32.1969L22.9144 39.0899V39.8293L34.6687 32.9363V32.1969Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.222 36.2904L22.8757 39.0676L22.9141 39.0898L34.6685 32.1968L29.9764 29.3956L18.222 36.2904Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.3715 33.4704L16.4588 40.6393L16.6669 40.4283L4.61801 33.2816L4.30553 33.3934L4.3715 33.4704Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0733 45.9339C20.962 44.716 19.2407 45.4482 19.2407 47.5635C19.2407 49.6788 20.962 52.3971 23.0733 53.6149C25.1833 54.8328 26.9046 54.1006 26.9046 51.9859C26.9046 49.87 25.1833 47.1517 23.0733 45.9339Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9081 47.5573C18.7969 46.3394 17.0755 47.0716 17.0755 49.1869C17.0755 51.3022 18.7969 54.0205 20.9081 55.2384C23.0181 56.4562 24.7394 55.7241 24.7394 53.6093C24.7394 51.4934 23.0181 48.7751 20.9081 47.5573Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9078 48.8472C19.5061 48.0386 18.3629 48.5244 18.3629 49.9292C18.3629 51.3334 19.5061 53.1385 20.9078 53.9476C22.3088 54.7561 23.452 54.2698 23.452 52.8656C23.452 51.4608 22.3088 49.6557 20.9078 48.8472Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.014 49.0339C19.7767 48.3198 18.7673 48.749 18.7673 49.9891C18.7673 51.2292 19.7767 52.8227 21.014 53.5363C22.2507 54.2504 23.2602 53.8212 23.2602 52.5811C23.2602 51.3416 22.2507 49.7474 21.014 49.0339Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.014 49.3591C19.9554 48.7484 19.0917 49.1157 19.0917 50.1766C19.0917 51.2376 19.9554 52.6016 21.014 53.2123C22.0726 53.823 22.9356 53.4557 22.9356 52.3948C22.9356 51.3338 22.0726 49.9705 21.014 49.3591Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0139 49.75C20.1688 49.2624 19.4797 49.5552 19.4797 50.4022C19.4797 51.2485 20.1688 52.3372 21.0139 52.8247C21.8584 53.3116 22.5475 53.0188 22.5475 52.1725C22.5475 51.3255 21.8584 50.2375 21.0139 49.75Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0139 49.9455C20.2756 49.5193 19.674 49.7754 19.674 50.5148C19.674 51.2541 20.2756 52.2051 21.0139 52.6307C21.7516 53.0563 22.3532 52.8002 22.3532 52.0608C22.3532 51.3215 21.7516 50.3711 21.0139 49.9455Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4521 55.6958C23.9599 55.6778 24.8584 55.0559 26.1477 53.8299L25.3114 51.2986L23.9661 53.9068C23.1156 55.1174 22.9442 55.7137 23.4521 55.6958Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5451 45.5221V45.5809L20.355 47.0488L17.5761 47.561C19.5554 46.0273 20.5451 45.3251 20.5451 45.4543C20.5451 45.5836 20.5451 45.6062 20.5451 45.5221Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7027 44.7356L24.5648 48.2293L24.6992 48.3093L27.5373 46.6767L27.3976 46.5937L27.37 46.5775L18.7027 44.2896V44.7356Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1937 45.248L18.7025 44.7346L21.5401 43.0972L16.1937 44.8025V45.248Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7027 44.2891L24.5648 47.7834L24.6992 47.8628L27.5373 46.2307L27.3976 46.1478L27.37 46.1315L21.5402 42.6517L18.7027 44.2891Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1937 44.8031L18.7025 44.2891L21.5401 42.6517L19.0306 43.1656L16.1937 44.8031Z"
        fill="#A44C2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5369 46.2254L24.6994 47.8628V50.4104L27.5369 48.7784V46.2254Z"
        fill="#EB922D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0532 47.5814V48.1465C27.0532 48.2529 26.9956 48.3521 26.9032 48.405L25.306 49.3505C25.2245 49.3974 25.1231 49.3379 25.1231 49.2441V49.1768L26.556 48.3268C26.6477 48.2733 26.7059 48.1747 26.7059 48.0678V47.5706L26.8703 47.4769C26.9518 47.4294 27.0532 47.4895 27.0532 47.5814Z"
        fill="#121311"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7059 47.5704V48.0675C26.7059 48.1745 26.6477 48.2731 26.556 48.3266L25.1231 49.1766V48.6806C25.1231 48.5724 25.1807 48.4739 25.2731 48.4204L26.7059 47.5704Z"
        fill="#FFF3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6997 50.4099L23.9404 49.9729L23.4228 47.1091L24.6997 47.8623V50.4099Z"
        fill="#D16F2D"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4613_146066"
          x1="20.1779"
          y1="43.1186"
          x2="37.5605"
          y2="3.54541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEB331" />
          <stop offset="1" stopColor="#FFD169" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
