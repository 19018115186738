import React, { useContext, useMemo } from "react";
import {
  Theme,
  makeStyles,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { TruckIcon } from "components";
import { BaseElement } from "components/List";
import { Error, WarningRounded } from "@material-ui/icons";
import { ExcavatorContext } from "contexts";
import { TAParam } from "interfaces";

interface Props {
  value: number;
  minutesSinceModularChange: number | null;
  variant: TypographyProps["variant"];
  spacing: string[];
  grayOut: boolean;
}

enum AlertLevel {
  WARNING = "WARNING",
  ERROR = "ERROR",
  NONE = "NONE",
}

export const BalanceTableTruckCell: React.FC<Props> = ({
  value,
  minutesSinceModularChange,
  variant,
  spacing,
  grayOut,
}) => {
  const {
    data: { params },
  } = useContext(ExcavatorContext);

  const warningLevel = useMemo(() => {
    if (
      (minutesSinceModularChange ?? 0) <
      params[TAParam.TA_LAST_MANAGEMENT_WARNING_ELAPSED_MINUTES]
    )
      return AlertLevel.NONE;
    if (value >= params[TAParam.TA_ALERT_DELTA_CAEX]) return AlertLevel.ERROR;
    if (value >= params[TAParam.TA_WARNING_DELTA_CAEX])
      return AlertLevel.WARNING;
    return AlertLevel.NONE;
  }, [minutesSinceModularChange, params, value]);

  const classes = useStyles({ warningLevel });
  return (
    <BaseElement
      classNames={[...spacing, classes.root, grayOut ? classes.grayOut : ""]}
    >
      {value === 0 ? (
        <></>
      ) : (
        <div className={classes.alignmentDiv}>
          <TruckIcon />
          <Typography
            variant={variant}
            component="span"
            className={classes.number}
          >
            {value}
          </Typography>
          {warningLevel === AlertLevel.WARNING ? (
            <WarningRounded />
          ) : warningLevel === AlertLevel.ERROR ? (
            <Error />
          ) : (
            <></>
          )}
        </div>
      )}
    </BaseElement>
  );
};

interface UseStylesProps {
  warningLevel: AlertLevel;
}

const useStyles = makeStyles<Theme, UseStylesProps>(({ palette }) => ({
  alignmentDiv: {
    display: "flex",
    alignItems: "center",
  },
  number: {
    margin: "0 10px",
  },
  root: {
    color: ({ warningLevel }) => {
      switch (warningLevel) {
        case AlertLevel.WARNING:
          return palette.warning.main;
        case AlertLevel.ERROR:
          return palette.error.main;
        default:
          return "";
      }
    },
    alignItems: "center",
  },
  grayOut: {
    opacity: 0.6,
  },
}));
