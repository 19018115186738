import React from "react";
import { Divider, makeStyles, Theme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

export const KpiCardSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.kpiRoot}>
        <Skeleton variant="circle" className={classes.icon} />
        <div className={classes.kpiDetails}>
          <Skeleton variant="text" className={classes.kpiTitle} />
          <div className={classes.kpiInfoRoot}>
            <Skeleton variant="text" className={classes.kpiValue} />
          </div>
        </div>
      </div>
      <Divider
        className={classes.divider}
        orientation="vertical"
        variant="middle"
        flexItem
        light
      />
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  kpiRoot: {
    display: "flex",
    width: "100%",
  },
  kpiDetails: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 171,
    width: "100%",
  },
  kpiInfoRoot: {
    maxWidth: "70%",
    marginTop: 10,
  },
  kpiValue: {
    height: 40,
  },
  kpiTitle: {
    height: 20,
  },
  divider: {
    "&:last-child": {
      display: "none",
    },
  },
  icon: {
    width: 68,
    height: 68,
    marginRight: 16,
  },
}));
