import { useEffect, useMemo } from "react";
import { useAxios, UsePollingAxiosParams } from "./useAxios";
import { DataContextInterface } from "interfaces";
import { useSnackbar } from "notistack";
import { logError } from "services";

/**
 * Hook to retrieve data from API.
 * If the target endpoint does not answer with data as an array,
 * a `defaultValue` should must given to get correct typings.
 *
 *
 * @param axiosConfig useAxios configuration object
 * @param errorMessage optional:
 *  Message that appears on error snackbar
 * @param defaultValue optional:
 *  If given, it will be the output when no data is received.
 * @param dataDummy
 * If given, it will be the output when data dummy in required
 * @returns DataContextInterface for type given
 */
export const useData = <T, P = unknown>(
  axiosConfig: UsePollingAxiosParams,
  errorMessage?: string,
  defaultValue?: T
): DataContextInterface<T, P> => {
  const { enqueueSnackbar } = useSnackbar();

  const [
    { data, firstLoading, refetchLoading, pollingLoading, error },
    refetch,
    manualCancel,
  ] = useAxios<T>(axiosConfig);

  useEffect(() => {
    if (errorMessage && error && error?.code !== "ERR_CANCELED") {
      enqueueSnackbar(errorMessage, { variant: "error" });
      logError("USE-DATA", errorMessage);
    }
  }, [enqueueSnackbar, errorMessage, error]);

  const dataState = useMemo<DataContextInterface<T>>(() => {
    // By default this hook assumes the initial value of data is an empty array. (most use cases)
    // A different defaultValue can be provided

    const exportedData = error === null 
      ? data ?? (typeof defaultValue === "undefined" ? [] : defaultValue) 
      : defaultValue ?? [];
   
    return {
      data: exportedData as T,
      firstLoading,
      refetching: refetchLoading,
      polling: pollingLoading,
      error,
      refetch,
      manualCancel,
    };
  }, [
    data,
    defaultValue,
    firstLoading,
    pollingLoading,
    refetchLoading,
    error,
    refetch,
    manualCancel
  ]);

  return dataState;
};
