import React, { useContext } from "react";
import { Module, Can } from "interfaces";
import { UserContext } from "contexts";

interface Props {
  to: Can | boolean;
  at?: Module;
  children?: React.ReactNode;
}

export const Restricted: React.FC<Props> = ({ to, at, children }) => {
  const { isAllowedTo } = useContext(UserContext);
  if (typeof to === "boolean") {
    return <>{to && children}</>;
  }
  if (!at) {
    throw new Error(
      "The 'at' prop must be provided when using 'to' as 'Enum' type at 'Restricted' component"
    );
  }
  return <>{isAllowedTo(to, at) && children}</>;
};
