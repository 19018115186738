import { AutocompleteChangeReason } from "@material-ui/lab";
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { map } from "lodash";
import { ErrorMsg, PerformanceRatingOperator } from "interfaces";
import { makeStyles } from "@material-ui/core";
import { useData } from "hooks";
import {
  AutoComplete,
  CloseOnClick,
  CustomDialog,
  CustomDialogActionProps,
} from "components";
import { UserContext } from "contexts";
import { useSnackbar } from "notistack";

interface OperatorAssignmentDialogProps {
  open: boolean;
  onClose: () => void;
}

export const OperatorAssignmentDialog: React.FC<
  OperatorAssignmentDialogProps
> = ({ open, onClose }) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: operators,
    firstLoading,
    refetching,
    refetch: refetchOperators,
  } = useData<PerformanceRatingOperator[]>(
    {
      config: {
        method: "GET",
        url: "/performance-ratings/supervisors/operators",
      },
      options: {
        useCache: false,
      },
    },
    ErrorMsg.GET_OPERATORS
  );

  const { refetch: updateSupervisorOperators } = useData<
    unknown,
    PerformanceRatingOperator["id"][]
  >(
    {
      config: {
        method: "PATCH",
        url: "/performance-ratings/operators",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.UPDATE_OPERATORS
  );

  const hasSomeOperator = useMemo<boolean>(
    () => operators.some((o) => o.assignedToMe),
    [operators]
  );

  const [selectedOperators, setSelectedOperators] = useState<
    PerformanceRatingOperator[]
  >([]);

  const onChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: PerformanceRatingOperator[],
      _reason: AutocompleteChangeReason
    ) => {
      setSelectedOperators(value);
    },
    []
  );

  const handleOnComplete = useCallback(async () => {
    await updateSupervisorOperators({
      data: map(selectedOperators, "id"),
    });
    enqueueSnackbar("Cambios guardados exitosamente", { variant: "success" });
  }, [selectedOperators, updateSupervisorOperators, enqueueSnackbar]);

  const onCancelDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "CANCELAR",
      onClick: () => null,
      closeOnClick: CloseOnClick.BEFORE,
    }),
    []
  );

  const onCompleteDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: hasSomeOperator ? "GUARDAR" : "AGREGAR",
      onClick: handleOnComplete,
      closeOnClick: CloseOnClick.BEFORE,
    }),
    [hasSomeOperator, handleOnComplete]
  );

  useEffect(() => {
    if (open) {
      refetchOperators().then(({ data }) => {
        if (!data) return;
        const myOpers = data.filter((oper) => oper.assignedToMe);
        setSelectedOperators(myOpers);
      });
    }
  }, [open, refetchOperators]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={`${hasSomeOperator ? "Actualizar" : "Agregar"} operadores de ${
        user?.name || user?.email
      } `}
      onCancelInfo={onCancelDialogProp}
      onCompleteInfo={onCompleteDialogProp}
    >
      <div className={classes.contentRoot}>
        <AutoComplete
          value={selectedOperators}
          options={operators ?? []}
          onChange={onChange}
          loading={refetching || firstLoading}
          loadingText="Cargando operadores"
          noOptionsText="No se encontraron operadores"
          limitTags={10}
        />
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    contentRoot: {
      display: "flex",
      justifyContent: "center",
    },
  };
});
