import React from "react";
import { makeStyles, Box } from "@material-ui/core";

interface TruckGroupProps {
  children?: React.ReactNode;
}
export const TruckGroup: React.FC<TruckGroupProps> = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.childrenBox}>{children}</Box>;
};

const useStyles = makeStyles((theme) => ({
  truckGroupRoot: {
    width: "100%",
  },
  childrenBox: {
    display: "flex",
    width: "100%",
    marginRight: 10,
    "&:last-child": {
      justifyContent: "flex-end",
      marginLeft: 10,
      marginRight: 0,
    },
  },
  title: {
    marginTop: 24,
    marginBottom: 12,
    fontWeight: "bold",
  },
}));
