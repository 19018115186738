import React, { useContext } from "react";
import {
  InfoSourceContext,
  OperatorsContext,
  OperatorAssignmentKpisContext,
} from "contexts";
import {
  Header,
  OperatorAssignmentKpis,
  OperatorsTable,
  ScreenContainer,
} from "components";
import { useRefetchOperatorsWithSearch, useSearch } from "hooks";
import { Operator } from "interfaces";
import Fuse from "fuse.js";

const filterOptions: Fuse.IFuseOptions<Operator> = {
  keys: ["name"],
  threshold: 0.4,
};

export const OperatorsScreen: React.FC = () => {
  const { operatorsElapsedMinutes } = useContext(InfoSourceContext);
  const {
    data: operators,
    firstLoading: firstLoadingOperators,
    refetching: refetchingOperators,
    refetch: refetchOperators,
  } = useContext(OperatorsContext);
  const { refetch: refetchKpis } = useContext(OperatorAssignmentKpisContext);

  const [searchValue, setSearchValue, , searchResults, isTyping] =
    useSearch<Operator>(operators ?? null, filterOptions);

  // Wrap the function to distinguish the reason to refetch and apply some logic.
  const [refetchOperatorsWrapper] = useRefetchOperatorsWithSearch({
    refetchOperators,
    setSearchValue,
    searchResultsLength: searchResults.length,
  });

  return (
    <>
      <Header
        elapsedMinutes={operatorsElapsedMinutes}
        searchValue={searchValue}
        onSearchQueryChange={setSearchValue}
        searchPlaceholder="Buscar por nombre de operador"
      />
      <ScreenContainer>
        <OperatorAssignmentKpis />
        <OperatorsTable
          operators={searchResults}
          refetchOperators={refetchOperatorsWrapper}
          refetchKpis={refetchKpis}
          alwaysEditable
          title="Gestión de operadores"
          clearFilter={isTyping}
          loadingData={firstLoadingOperators || refetchingOperators}
        />
      </ScreenContainer>
    </>
  );
};
