import { Chip, Radio, Typography, ListItem, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import {
  Operator,
  OperatorStatusType,
  Can,
  Module,
  statusTranslation,
} from "interfaces";
import React, { useMemo, useContext, CSSProperties } from "react";
import {
  PersonPinCircleOutlined,
  FavoriteBorder,
  CheckCircle,
  WarningRounded,
  AddCircle,
} from "@material-ui/icons";
import { ArticleOutlined, TruckIcon } from "components/icons";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
//import DirectionsBusRoundedIcon from "@material-ui/icons/DirectionsBusRounded";
import { operatorStatusColors } from "styles";
import { UserContext, OperatorAssignmentContext } from "contexts";
import clsx from "clsx";
import { Label, Restricted } from "components";
import { OperatorAlert } from "./OperatorAlert";

interface OperatorListItemProps {
  operator: Operator;
  truckModel?: string;
  className?: string;
  style: CSSProperties;
  loadingData?: boolean;
  preAssemble: boolean;
}

export const OperatorListItem: React.FC<OperatorListItemProps> = ({
  operator,
  truckModel,
  className,
  style,
  loadingData,
  preAssemble = false
}) => {
  //Hooks
  const { selectedOperator, setSelectedOperator } = useContext(
    OperatorAssignmentContext
  );
  const { isAllowedTo } = useContext(UserContext);
  const canSelect = isAllowedTo(Can.WRITE, Module.OPERATOR_ASSIGNMENT);
  
  const classes = useStyles({
    operStatus: operator.status,
    isInstructor: operator.isInstructor,
  });

  //Logic
  const entryClasses = useMemo(() => {
    return {
      root: classes.detailInfo,
      icon: classes.icon,
      text: classes.infoText,
    };
  }, [classes]);

  const disabledByStatus = useMemo(
    () =>
      operator.isInstructor || operator.status === OperatorStatusType.ASSEMBLED,
    [operator]
  );

  const truckText = useMemo(
    () =>
      operator.preassembledEquip?.id ??
      `${operator.currentEquip?.id ?? "-"} | ${
        operator.currentEquip?.reasonId ?? "-"
      }`,
    [operator]
  );

  const selected = useMemo(
    () => selectedOperator?.id === operator.id,
    [operator, selectedOperator]
  );

  return (
    <ListItem
      disabled={disabledByStatus}
      component="div"
      className={clsx(classes.listItemOperDetail, className)}
      style={style}
      onClick={
        !disabledByStatus && canSelect
          ? () => setSelectedOperator(operator)
          : undefined
      }
    >
      <Paper
        elevation={0}
        className={clsx(
          classes.operatorDetailPaper,
          canSelect && classes.selectable,
          selected && classes.paperClicked,
          !selected && !disabledByStatus && canSelect && classes.paperHover
        )}
      >
        <div className={clsx(classes.cellSizeXL, classes.cell)}>
          <div className={classes.operatorNameContainer}>
            <Restricted to={canSelect}>
              <div className={classes.radioButton}>
                <Radio
                  color="primary"
                  value={operator.id}
                  disabled={disabledByStatus}
                />
              </div>
            </Restricted>
            <Typography className={classes.text} noWrap variant="body1">
              {operator.name ?? "-"}
            </Typography>
          </div>
        </div>
        <div className={clsx(classes.alertContainer, classes.cellSizeS, classes.cell)}> 
          <OperatorAlert operator={ operator } truckModel = { truckModel } loadingData = {loadingData}  />
        </div>
        <div className={clsx(classes.cellSizeM, classes.cell)}>
          <Label
            classes={entryClasses}
            icon={PersonPinCircleOutlined}
            text={operator.location?.name ?? "-"}
          />
        </div>        
        <div className={clsx(classes.cellSizeM, classes.cell)}>
          <Label classes={entryClasses} icon={TruckIcon} text={truckText} />
        </div>
        <div className={clsx(classes.cellSizeM, classes.cell)}>
          <Label
            classes={entryClasses}
            icon={FavoriteBorder}
            text={operator.preferredEquip?.id ?? "-"}
          />
        </div>
        <div className={clsx(classes.cellSizeL, classes.cell)}>
          <Label
            classes={entryClasses}
            icon={ArticleOutlined}
            text={operator.area ?? "-"}
          />
        </div>
        <div className={clsx(classes.cellSizeM, classes.cell)}>
          <Chip
            classes={{
              root: classes.statusChip,
              icon: classes.chipIcon,
            }}
            icon={
              operator.status === OperatorStatusType.AVAILABLE ? (
                <CheckCircle />
              ) : operator.status === OperatorStatusType.MANUAL ? (
                <AddCircle />
              ) : (
                <WarningRounded />
              )
            }
            label={
              <Typography variant="body2">
                <strong>{statusTranslation[operator.status] ?? "-"}</strong>
              </Typography>
            }
          />
        </div>
        {preAssemble ?
          <div className={clsx(classes.cellSizeM, classes.cell)}>
            <Typography variant="body2">
              {operator.bus?.id}
            </Typography>
          </div>
        : null}
        <div className={clsx(classes.cellSizeS, classes.cell)}>
          <Typography className={classes.text} noWrap variant="body1">
            {operator.isInstructor ? <CheckRoundedIcon /> : null}
          </Typography>
        </div>
        {/* <div className={clsx(classes.cellSizeM, classes.cell)}>
          <Label
            classes={entryClasses}
            icon={DirectionsBusRoundedIcon}
            text={operator.bus?.radioId ?? "-"}
          />
        </div> */}
      </Paper>
    </ListItem>
  );
};

interface StyleProps {
  operStatus?: OperatorStatusType;
  isInstructor?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  const { palette } = theme;
  return {
    operatorDetailPaper: {
      display: "flex",
      backgroundColor: palette.background.paper,
      width: "100%",
      alignItems: "center",
      padding: 6,
      gap: 0,
      border: "2px solid transparent",
    },
    selectable: {
      "&:hover": {
        cursor: ({ isInstructor }) => (!isInstructor ? "pointer" : "unset"),
      },
    },
    operatorNameContainer: {
      display: "flex",
      alignItems: "center",
    },
    radioButton: {
      maxWidth: 40,
      flexGrow: 1,
    },
    cell: {
      display: "flex",
      paddingRight: 10,
      alignItems: "center",
    },
    cellSizeXL: {
      maxWidth: 290,
      width: "100%",
      justifyContent: "space-between",
    },
    cellSizeL: {
      maxWidth: 220,
      width: "100%",
    },
    cellSizeM: {
      maxWidth: 200,
      width: "100%",
    },
    cellSizeS: {
      justifyContent: "center",
      maxWidth: 120,
      width: "100%",
    },
    alertContainer: {
      justifyContent: "left"
    },
    text: {
      fontWeight: 700,
    },
    icon: {
      width: 24,
      height: 24,
      marginRight: 8,
    },
    detailInfo: {
      display: "flex",
      alignItems: "center",
      marginBottom: 6,
      marginTop: 4,
      maxWidth: 200,
    },
    statusChip: {
      backgroundColor: ({ operStatus }) =>
        operatorStatusColors({
          theme,
          statusType: operStatus,
          opacityColor: true,
        }),
      color: ({ operStatus }) =>
        operatorStatusColors({
          theme,
          statusType: operStatus,
          color: true,
        }),
      width: "fit-content",
      alignSelf: "end",
    },
    chipIcon: {
      color: ({ operStatus }) =>
        operatorStatusColors({
          theme,
          statusType: operStatus,
          color: true,
        }),
      fontSize: theme.typography.body1.fontSize,
    },
    infoText: {
      fontWeight: "normal",
    },
    listItemOperDetail: {
      paddingTop: 4,
      paddingBottom: 4,
      "&:first-child": {
        paddingTop: 8,
      },
      "&:last-child": {
        paddingBottom: 8,
      },
    },
    paperClicked: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    paperHover: {
      "&:hover": {
        border: `2px solid ${theme.palette.secondary.light}`,
      },
    },
  };
});
