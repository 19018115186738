export enum Shift {
  DAY = "DAY",
  NIGHT = "NIGHT",
}

export interface ShiftInfo {
  dailyShiftStartDate: string;
  dailyShiftEndDate: string;
  crew: string;
}

export const shiftTranslation: Record<Shift, string> = {
  [Shift.DAY]: "Turno día",
  [Shift.NIGHT]: "Turno noche",
};
