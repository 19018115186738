import { Client } from "./excavator.interface";

export enum SicClients {
  EXPIT = "Expit",
  CAEX = "Utilización CAEX",
  ORIGIN_QUEUE = "Cola de origen (global)",
  QUEUE_CHANCADO = "Cola Chancado Súlfuro",
  OXIDE_QUEUE = "Cola Chancado Óxido",
  OTHER_SQUEUE = "Cola Otros destinos",
  SAFETY = "Seguridad",
  CYCLE_TIME = "Tiempo de ciclo",
  SHOVEL_AVAILABILITY = "Disponibilidad de palas",
  LAW = "Ley"
}

export enum SicFollowUpStatus {
  PENDING = "PENDING",
  STARTED = "STARTED",
  RESOLVED = "RESOLVED",
  DISCARDED = "DISCARDED"
}

export interface ValueWithContext {
  label: string;
  loadtons: number | null;
  target: number | null;
}

export interface Sic {
  id: number;
  targetEntity: string;
  loadtons: number | null;
  target: number | null;
  risk: string;
  action: string;
  followUpStatus: string;
  responsible: string;
  createdAt: string;
}

export interface MineMovements {
  origin: Client;
  loadtons: number;
  target: number | null;
}

export interface SicRegistryPayload {
  targetEntity: string;
  responsible: string;
  risk: string;
  action: string;
  loadtons: number | null | undefined;
  target: number | null | undefined;
}

export interface SicUpdatePayload {
  follow_up: string,
  id: number
}