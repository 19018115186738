import React, { useContext } from "react";
import { OperatorAssignmentKpisContext } from "contexts";
import { KpisPaper } from "./KpisPaper";
import { KpiCard } from ".";

export const OperatorAssignmentKpis: React.FC = () => {
  const { data, firstLoading } = useContext(OperatorAssignmentKpisContext);
  return (
    <KpisPaper loading={firstLoading}>
      {data.map((entry, i) => (
        <KpiCard key={`${entry.type}-${i}`} {...entry} />
      ))}
    </KpisPaper>
  );
};
