import { UsePollingAxiosResult } from "hooks";

export const initialDataContextState: DataContextInterface<any> = {
  data: [],
  firstLoading: false,
  refetching: false,
  polling: false,
  error: null,
  refetch: () => new Promise(() => []),
  manualCancel: () => new Promise(() => []),
};

export interface DataContextInterface<T, P = unknown> {
  data: T;
  firstLoading: UsePollingAxiosResult<T>[0]["firstLoading"];
  refetching: UsePollingAxiosResult<T>[0]["refetchLoading"];
  polling: UsePollingAxiosResult<T>[0]["pollingLoading"];
  error: UsePollingAxiosResult<T>[0]["error"];
  refetch: UsePollingAxiosResult<T, P>[1];
  manualCancel: UsePollingAxiosResult<T>[2];
}
