import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const CalendarIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 35 39"
      height="39px"
      viewBox="0 0 35 39"
      width="35px"
      fill="#none"
      {...props}
    >
      <path
        d="M27.2073 6.5H25.796V3.25H22.9734V6.5H11.6832V3.25H8.86066V6.5H7.44939C5.88287 6.5 4.64094 7.9625 4.64094 9.75L4.62683 32.5C4.62683 34.2875 5.88287 35.75 7.44939 35.75H27.2073C28.7597 35.75 30.0298 34.2875 30.0298 32.5V9.75C30.0298 7.9625 28.7597 6.5 27.2073 6.5ZM27.2073 32.5H7.44939V16.25H27.2073V32.5ZM27.2073 13H7.44939V9.75H27.2073V13ZM13.0945 22.75H10.2719V19.5H13.0945V22.75ZM18.7396 22.75H15.917V19.5H18.7396V22.75ZM24.3847 22.75H21.5622V19.5H24.3847V22.75ZM13.0945 29.25H10.2719V26H13.0945V29.25ZM18.7396 29.25H15.917V26H18.7396V29.25ZM24.3847 29.25H21.5622V26H24.3847V29.25Z"
        fill="#EE8105"
      />
    </SvgIcon>
  );
};
