import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ExcelIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2263 2.76953H14.769V5.52399H17.5669V7.39876H14.769V9.23107H17.5383V11.0772H14.769V12.9234H17.5383V14.7695H14.769V16.6157H17.5383V18.4618H14.769V21.2311H23.2263C23.6518 21.2311 23.9998 20.8701 23.9998 20.428V3.57261C23.9998 3.13045 23.6518 2.76953 23.2263 2.76953ZM22.1537 18.4614H18.4614V16.6152H22.1537V18.4614ZM18.4614 14.77H22.1537V12.9238H18.4614V14.77ZM22.1537 11.0766H18.4614V9.23047H22.1537V11.0766ZM18.4614 7.38522H22.1537V5.53906H18.4614V7.38522Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 21.3342V2.66677L13.8462 0V24L0 21.3342ZM7.14762 13.6434L8.75839 16.6887L11.2553 16.6877L8.37346 11.9736L11.1768 7.33789H8.83039L7.34423 10.1154C7.20115 10.4764 7.10331 10.7477 7.05069 10.9305H7.02485C6.94177 10.6268 6.84854 10.3637 6.74423 10.1422L5.40762 7.33881H2.84515L5.571 12.0142L2.59131 16.6887H5.10115L6.71746 13.6702C6.831 13.3785 6.903 13.1607 6.93346 13.0176H6.95839C7.02392 13.3213 7.08669 13.5299 7.14762 13.6434Z"
      />
    </SvgIcon>
  );
};
