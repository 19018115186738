import React, { createContext, useMemo, useReducer } from "react";
import { ErrorMsg, PhasesCard } from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { DataContextInterface, initialDataContextState } from "interfaces";
import { STANDARD_POLLING } from "App";
import { ContextProviderProps } from "contexts";
import { AppRoute } from "utils";
import {
  TruckSummaryRouteAction,
  TruckSummaryRouteState,
  initialSummaryRouteState,
  truckSummaryRouteReducer,
} from "reducers/truck-velocity";

interface TruckVelocityInterface extends DataContextInterface<PhasesCard> {
  state: TruckSummaryRouteState;
  dispatch: React.Dispatch<TruckSummaryRouteAction>;
}

const initialState: any = {
  data: [],
};

export const TruckAssignmentVelocityContext =
  createContext<TruckVelocityInterface>({
    state: initialSummaryRouteState,
    dispatch: () => null,
    ...initialDataContextState,
    data: initialState,
  });

export const TruckAssignmentVelocityProvider: React.FC<
  ContextProviderProps
> = ({ children }) => {
  const [state, dispatch] = useReducer(truckSummaryRouteReducer, initialSummaryRouteState);

  const params = useMemo<TruckSummaryRouteState>(
    () => ({
      period: state.period,
      workday: state.workday,
    }),
    [state]
  );

  const { data, polling, firstLoading, refetching, refetch, ...metricsOther } =
    useData<PhasesCard>(
      {
        config: {
          url: "/truck-assignment-velocity/summary",
          method: "GET",
          params: params,
        },
        options: {
          useCache: false,
        },
        ...STANDARD_POLLING,
      },
      ErrorMsg.GET_ROUTES_DATA,
      undefined,
    );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetch(), [AppRoute.TA_PERFORMANCE_SCREEN]);

  const contextState = useMemo<TruckVelocityInterface>(
    () => ({
      state,
      dispatch,
      data,
      polling,
      firstLoading,
      refetching,
      refetch,
      ...metricsOther,
    }),
    [data, firstLoading, state, metricsOther, polling, refetching, refetch]
  );

  return (
    <TruckAssignmentVelocityContext.Provider value={contextState}>
      {children}
    </TruckAssignmentVelocityContext.Provider>
  );
};
