import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography, LinearProgress } from "@material-ui/core";
import { ListHeaderCellProps } from "interfaces";
import { BaseElement } from "./BaseElement";
import clsx from "clsx";

interface CustomListHeaderProps<T> {
  loading?: boolean;
  headerKey: string;
  headCells: ListHeaderCellProps<T>[];
  className?: string;
}

export const CustomListHeader = <T extends unknown>({
  loading,
  headerKey,
  headCells,
  className: propClassName,
}: CustomListHeaderProps<T>) => {
  const classes = useStyles();
  return (
    <div className={classes.headerContainer}>
      <div
        className={clsx(classes.headerRow, propClassName)}
        key={`${headerKey}-header-root`}
      >
        {headCells.map((headCell, i) => (
          <BaseElement
            key={`${headerKey}-${headCell.dataKey}-headcell`}
            classNames={headCell.classNames}
          >
            <Typography variant="subtitle1">
              <b>{headCell.label}</b>
            </Typography>
          </BaseElement>
        ))}
      </div>
      <div className={classes.loaderContainer}>
        {loading && <LinearProgress className={classes.linearLoader} />}
      </div>
    </div>
  );
};

const useStyles = makeStyles(({ palette }) => {
  const cellBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;
  return createStyles({
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
    headerRow: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: cellBackground,
      color: palette.getContrastText(cellBackground),
      paddingTop: 10,
      paddingBottom: 9,
      position: "sticky",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      top: 0,
      zIndex: 1,
    },
    loaderContainer: {
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
    linearLoader: {
      height: 5,
      position: "absolute",
      width: "100%",
      zIndex: 2,
    },
  });
});
