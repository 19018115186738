import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";

interface Props {
  excavsData: string[];
  switches: { [key: string]: boolean };
  setSwitches: (value: {}) => void;
}

export const RelieveExcavs: React.FC<Props> = ({
  excavsData = [],
  switches,
  setSwitches,
}) => {
  useEffect(() => {
    if (!excavsData.length && Object.keys(switches).length) {
      setSwitches({});
    }
    if (
      (excavsData.length && !Object.keys(switches).length) ||
      (excavsData.length > 1 &&
        excavsData.length !== Object.keys(switches).length)
    ) {
      const newData: any = {};
      excavsData.forEach((excav) => {
        newData[excav] = true;
      });
      setSwitches(newData);
    }
  }, [excavsData, switches, setSwitches]);

  const handleSwitchChange = (switchName: string) => {
    setSwitches((prevValues: any) => ({
      ...prevValues,
      [switchName]: !prevValues[switchName],
    }));
  };

  return (
    <Grid container style={{ marginBottom: 10 }}>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row style={{ alignItems: "center" }}>
          {excavsData?.length ? (
            <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
              Filtros
            </Typography>
          ) : null}
          {excavsData?.map((name, index) => (
            <FormControlLabel
              value={name}
              control={
                <Switch
                  size="medium"
                  onChange={() => handleSwitchChange(name)}
                  checked={switches[name] || false}
                />
              }
              label={name}
              labelPlacement="start"
              key={index}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};
