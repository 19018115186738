import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ArrowIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_11589_20279)">
        <path
          d="M8.77502 3.75H5.40002L9.15002 9L5.40002 14.25H8.77502L12.525 9L8.77502 3.75Z"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_11589_20279">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
