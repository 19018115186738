import React, { createContext } from "react";
import {
  DataContextInterface,
  ErrorMsg,
  initialDataContextState,
  TruckSummary,
} from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { ContextProviderProps } from "contexts";
import { STANDARD_POLLING } from "App";
import { AppRoute } from "utils";

export const TrucksDashboardContext = createContext<
  DataContextInterface<TruckSummary[]>
>(initialDataContextState);

export const TrucksDashboardProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { refetch, ...other } = useData<TruckSummary[]>(
    {
      config: "/operator-assignment/trucks/dashboard",
      options: { useCache: false },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_DASHBOARD_CAEXS
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetch(), [AppRoute.TRUCKS_DASHBOARD]);

  return (
    <TrucksDashboardContext.Provider value={{ refetch, ...other }}>
      {children}
    </TrucksDashboardContext.Provider>
  );
};
