export interface TruckSummaryRouteState {
  period: string; //last_48h or prev_shift
  workday: string; // day, night or complete day
}

export interface TruckRouteState {
  route?: string;
  subPeriod: string; //last_3h or curr_workday
}

export enum TruckVelocityActionType {
  ROUTE_CHANGE = "ROUTE_CHANGE",
  PERIOD_CHANGE = "PERIOD_CHANGE",
  SUB_PERIOD_CHANGE = "SUB_PERIOD_CHANGE",
  WORKDAY_CHANGE = "WORKDAY_CHANGE",
}

export enum FiltersVelocity {
  LAST_48H = "LAST_48H",
  LAST_3H = "LAST_3H",
  PREV_SHIFT = "PREV_SHIFT",
  CURR_WORKDAY = "CURR_WORKDAY",
  DAY = "DAY",
  NIGHT = "NIGHT",
  COMPLETE_DAY = "COMPLETE_DAY",
}

export const initialSummaryRouteState: TruckSummaryRouteState = {
  period: FiltersVelocity.LAST_48H,
  workday: FiltersVelocity.COMPLETE_DAY,
};

export const initialRouteState: TruckRouteState = {
  route: "",
  subPeriod: FiltersVelocity.LAST_3H,
};

export type TruckSummaryRouteAction =
  | {
      type: TruckVelocityActionType.PERIOD_CHANGE;
      period: string;
    }
  | {
      type: TruckVelocityActionType.WORKDAY_CHANGE;
      workday: string;
    };

export type TruckRouteAction =
  | {
      type: TruckVelocityActionType.ROUTE_CHANGE;
      route: string;
    }
  | {
      type: TruckVelocityActionType.SUB_PERIOD_CHANGE;
      subPeriod: string;
      state: TruckSummaryRouteState;
    };

export const truckSummaryRouteReducer = (
  state: TruckSummaryRouteState,
  action: TruckSummaryRouteAction
): TruckSummaryRouteState => {
  switch (action.type) {
    case TruckVelocityActionType.PERIOD_CHANGE: {
      return {
        ...state,
        period: action.period,
      };
    }
    case TruckVelocityActionType.WORKDAY_CHANGE: {
      return {
        ...state,
        workday: action.workday,
      };
    }
    default:
      return state;
  }
};

export const truckVelocityReducer = (
  state: TruckRouteState,
  action: TruckRouteAction
): TruckRouteState => {
  switch (action.type) {
    case TruckVelocityActionType.ROUTE_CHANGE: {
      return {
        ...state,
        route: action.route,
      };
    }
    case TruckVelocityActionType.SUB_PERIOD_CHANGE: {
      return {
        ...state,
        subPeriod: action.subPeriod,
      };
    }

    default:
      return state;
  }
};
