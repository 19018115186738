import React, { useCallback, useContext, useMemo } from "react";
import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import { ChipGroup } from "components";
import { makeStyles } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { PeriodEnum, periodOptionsTranslation, ScreenType } from "interfaces";
import { PerformanceRatingActionType, screenTypesKeys } from "reducers";
import { PerformanceRatingsContext } from "contexts";

interface PickersCommonProps
  extends Pick<
    DatePickerProps,
    | "InputProps"
    | "className"
    | "format"
    | "animateYearScrolling"
    | "disableFuture"
    | "variant"
    | "inputVariant"
  > {}

interface SelectPeriodOption {
  id: PeriodEnum;
  name: string;
}
interface Props {
  screen: ScreenType;
}

export const PerformanceRatingPeriodsOptions: React.FC<Props> = ({
  screen,
}) => {
  const classes = useStyles();
  const { menuState, dispatch } = useContext(PerformanceRatingsContext);
  const onStartTimeChange = useCallback(
    (startDate: MaterialUiPickersDate) =>
      dispatch({
        type: PerformanceRatingActionType.CHANGE_DATES,
        dates: {
          startDate,
        },
        screen,
      }),
    [dispatch, screen]
  );

  const onEndTimeChange = useCallback(
    (endDate: MaterialUiPickersDate) =>
      dispatch({
        type: PerformanceRatingActionType.CHANGE_DATES,
        dates: {
          endDate,
        },
        screen,
      }),
    [dispatch, screen]
  );

  const onChipOptionChange = useCallback(
    (value: string | number): void => {
      dispatch({
        type: PerformanceRatingActionType.PERIOD_OPTION_CHANGE,
        periodName: value as PeriodEnum,
        screen,
      });
    },
    [dispatch, screen]
  );

  const pickersCommonProps: PickersCommonProps = useMemo(
    () => ({
      InputProps: { className: classes.datePickerInput },
      className: classes.datePickerRoot,
      format: "yyyy-MM-dd",
      animateYearScrolling: true,
      disableFuture: true,
      variant: "inline",
      inputVariant: "outlined",
      minDate: "2022-08-01T08:00:00.000",
    }),
    [classes.datePickerInput, classes.datePickerRoot]
  );

  return (
    <>
      <ChipGroup
        classes={{ chipGroupRoot: classes.chipGroupRoot }}
        onChange={onChipOptionChange}
        value={menuState[screenTypesKeys[screen]].name}
        options={periodOptions}
      />
      {menuState[screenTypesKeys[screen]].name === PeriodEnum.CUSTOM && (
        <div className={classes.datePickersContainer}>
          <DatePicker
            label="Desde"
            onChange={onStartTimeChange}
            value={menuState[screenTypesKeys[screen]].startDate}
            {...pickersCommonProps}
          />
          <DatePicker
            label="Hasta"
            onChange={onEndTimeChange}
            value={menuState[screenTypesKeys[screen]].endDate}
            {...pickersCommonProps}
          />
        </div>
      )}
    </>
  );
};

const periodOptions: SelectPeriodOption[] = Object.values(PeriodEnum)
  .filter((period) => period !== PeriodEnum.LAST_3_MONTHS)
  .map((periodName) => ({
    id: periodName,
    name: periodOptionsTranslation[periodName],
  }));

const useStyles = makeStyles(() => ({
  chipGroupRoot: {
    marginLeft: "auto",
  },
  chipExceedEvents: {
    width: "100%",
    height: 24,
    maxWidth: 48,
  },
  datePickerInput: {
    height: 40,
    width: "100%",
    maxWidth: 110,
  },
  datePickerRoot: {
    "&:first-child": {
      marginRight: 10,
    },
  },
  datePickersContainer: {
    margin: "0px 10px",
  },
}));
