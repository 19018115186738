import React from "react";
import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  useProgram,
  ProgramItem as ProgramItemProps,
} from "planby";
import { YellowTruckIcon } from "components/icons";
import { FoodIcon } from "components/icons/FoodIcon";
import { Grid, alpha, useTheme } from "@material-ui/core";


export const ProgramItem = ({ program, ...rest }: ProgramItemProps) => {
  const { styles, formatTime, set12HoursTimeFormat, isLive } = useProgram({
    program,
    ...rest,
  });

  const { data } = program;
  const { image, title, since, till } = data;

  const theme = useTheme();
  let position = styles.position;
  position.left = position.left + 100;

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();
  const programContent = {
    borderRadius: 4,
    background:
      theme.palette.type === "light" && image === "lunch"
        ? "#EAF2EA"
        : theme.palette.type === "dark" && image === "lunch"
        ? alpha("#66BB6A", 0.34)
        : theme.palette.type === "light"
        ? theme.palette.grey[100]
        : theme.palette.background.default,
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
    padding: "10px 10px 6px 10px",
    color: theme.palette.text.primary,
    cursor: "unset"
  };

   return (
    <ProgramBox width={styles.width} style={{ ...position }}>
      <ProgramContent
        width={styles.width}
        isLive={isLive}
        style={programContent}
        // onClick={() => setOpenModal({ open: true})}
      >
        <ProgramFlex>
          {image === "lunch" ? (
            <Grid
              container
              alignContent="center"
              alignItems="center"
              style={{ justifyContent: "center" }}
            >
              <FoodIcon
                style={{
                  height: 50,
                  width: 50,
                  fill:
                    theme.palette.type === "light"
                      ? theme.palette.background.paper
                      : theme.palette.background.default,
                }}
              />
            </Grid>
          ) : (
            <>
              <YellowTruckIcon
                style={{ height: 44, width: 44, marginRight: 10 }}
              />
              <ProgramStack>
                <ProgramTitle
                  style={{ color: theme.palette.text.primary, fontSize: 16 }}
                >
                  {title}
                </ProgramTitle>
                <ProgramText
                  style={{ color: theme.palette.text.primary, fontSize: 14 }}
                >
                  {sinceTime} - {tillTime}
                </ProgramText>
              </ProgramStack>
            </>
          )}
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
  );
};
