import React, {
  useMemo,
  createContext,
  useReducer,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { UserContext, ContextProviderProps } from "contexts";
import {
  DataViewOption,
  initialPerformanceRatingState,
  PerformanceRatingAction,
  PerformanceRatingActionType,
  performanceRatingsReducer,
  PerformanceRatingState,
} from "reducers";
import { CrewEnum, Shift, OperatorPracticeOptions } from "interfaces";
import { useCurrentShift } from "hooks";
import { getCrews } from "utils";

type PerformanceRatingsInterface = {
  menuState: PerformanceRatingState;
  dispatch: React.Dispatch<PerformanceRatingAction>;
  onOperatorScreenPracticeChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onSupervisorScreenPracticeChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onViewOptionChange: (
    e: React.MouseEvent<HTMLElement>,
    newOption: DataViewOption | null
  ) => void;
  onCrewChange: (
    e: React.MouseEvent<HTMLElement>,
    newCrews: CrewEnum[]
  ) => void;
};

export const PerformanceRatingsContext =
  createContext<PerformanceRatingsInterface>({
    menuState: initialPerformanceRatingState,
    dispatch: () => null,
    onOperatorScreenPracticeChange: () => null,
    onSupervisorScreenPracticeChange: () => null,
    onViewOptionChange: () => null,
    onCrewChange: () => null,
  });

export const PerformanceRatingsProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { user } = useContext(UserContext);
  const currentShift = useCurrentShift();

  const [menuState, dispatch] = useReducer(
    performanceRatingsReducer,
    initialPerformanceRatingState
  );

  const onOperatorScreenPracticeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: PerformanceRatingActionType.OPERATOR_SCREEN_PRACTICE_CHANGE,
        practice: e.target.name as OperatorPracticeOptions,
        value: e.target.checked,
        currentShift,
      }),
    [dispatch, currentShift]
  );

  const onSupervisorScreenPracticeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: PerformanceRatingActionType.SUPERVISOR_SCREEN_PRACTICE_CHANGE,
        practice: e.target.name as OperatorPracticeOptions,
        value: e.target.checked,
        currentShift,
      }),
    [dispatch, currentShift]
  );

  const onViewOptionChange = useCallback(
    (e: React.MouseEvent<HTMLElement>, option: DataViewOption | null) =>
      option &&
      dispatch({
        type: PerformanceRatingActionType.VIEW_OPTION_CHANGE,
        option,
      }),
    [dispatch]
  );

  const onCrewChange = useCallback(
    (_e: React.MouseEvent<HTMLElement>, crews: CrewEnum[]) => {
      dispatch({
        type: PerformanceRatingActionType.CREW_CHANGE,
        crews,
      });
    },
    [dispatch]
  );

  const contextState = useMemo<PerformanceRatingsInterface>(
    () => ({
      menuState,
      dispatch,
      onOperatorScreenPracticeChange,
      onSupervisorScreenPracticeChange,
      onViewOptionChange,
      onCrewChange,
    }),
    [
      menuState,
      onOperatorScreenPracticeChange,
      onSupervisorScreenPracticeChange,
      onViewOptionChange,
      onCrewChange,
    ]
  );

  useEffect(() => {
    if (user) {
      dispatch({
        type: PerformanceRatingActionType.INIT,
        payload: {
          ...initialPerformanceRatingState,
          operPractices: {
            ...initialPerformanceRatingState.operPractices,
            [OperatorPracticeOptions.LUNCH]: currentShift === Shift.DAY,
            [OperatorPracticeOptions.DINNER]: currentShift === Shift.NIGHT,
          },
          selectedSupervisor: user.isOperationalSupervisor ? user.id : null,
          crews: getCrews(user.crews),
        },
      });
    }
  }, [currentShift, user]);

  return (
    <PerformanceRatingsContext.Provider value={contextState}>
      {children}
    </PerformanceRatingsContext.Provider>
  );
};
