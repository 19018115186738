import React, { useMemo } from "react";
import { animated } from "@react-spring/web";
import { useAnimatedPath } from "@nivo/core";
import { CustomLayerProps } from "@nivo/line";

type BarPoint = {
  x: number;
  y: number;
};
interface Props {
  id: string | number;
  lineGenerator: CustomLayerProps["lineGenerator"];
  points: BarPoint[];
  fill?: string;
  color: string | undefined;
  [key: string]: any;
}
export const AnimatedCustomLine: React.FC<Props> = ({
  id,
  lineGenerator,
  points,
  fill = "none",
  color,
  ...props
}) => {
  const path = useMemo(
    () => lineGenerator(points as unknown as BarPoint[][]),
    [lineGenerator, points]
  );
  const animatedPath = useAnimatedPath(path ?? "");
  return (
    <animated.path
      key={id}
      d={animatedPath}
      fill={fill}
      stroke={color}
      style={props.style}
    />
  );
};
