import { AutocompleteChangeReason } from "@material-ui/lab";
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { AutoComplete } from "./AutoComplete";
import {
  CloseOnClick,
  CustomDialog,
  CustomDialogActionProps,
} from "./CustomDialog";
import { map } from "lodash";
import { ErrorMsg, Operator } from "interfaces";
import { makeStyles } from "@material-ui/core";
import { OperatorAssignmentKpisContext } from "contexts";
import { useData } from "hooks";

interface AddOperatorDialogProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

type SelectOperator = Pick<Operator, "id" | "name">;

export const AddOperatorDialog: React.FC<AddOperatorDialogProps> = ({
  open,
  onClose,
  onComplete,
}) => {
  const classes = useStyles();
  const { refetch: refetchKpis } = useContext(OperatorAssignmentKpisContext);
  const {
    data: operators,
    firstLoading,
    refetching: refetchLoading,
    refetch: refetchOperators,
  } = useData<SelectOperator[]>(
    {
      config: {
        url: "/operator-assignment/operators",
        method: "GET",
        params: {
          // To allow to add an available operator to convert it to "MANUAL"
          filterShiftCrew: false,
        },
      },
    },
    ErrorMsg.GET_OPERATORS
  );

  const { refetch: updateShiftCrew } = useData<unknown, Operator["id"][]>(
    {
      config: {
        url: "/operator-assignment/shift-crew/operators",
        method: "PUT",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.UPDATE_SHIFT_CREW_OPERATORS
  );

  const [selectedOperators, setSelectedOperators] = useState<SelectOperator[]>(
    []
  );
  const noSelectedOperators = !selectedOperators.length;

  // Hooks
  useEffect(() => {
    if (open) refetchOperators();
  }, [open, refetchOperators]);

  const handleOnChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: SelectOperator[],
      _reason: AutocompleteChangeReason
    ) => {
      setSelectedOperators(value);
    },
    [setSelectedOperators]
  );
  const handleOnComplete = useCallback(async () => {
    await updateShiftCrew({
      data: map(selectedOperators, "id"),
    });
    setSelectedOperators([]);
    refetchKpis();
    onComplete();
  }, [onComplete, refetchKpis, selectedOperators, updateShiftCrew]);

  const onCancelDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "CANCELAR",
      onClick: () => setSelectedOperators([]),
      closeOnClick: CloseOnClick.BEFORE,
    }),
    [setSelectedOperators]
  );
  const onCompleteDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "AGREGAR",
      onClick: handleOnComplete,
      disabled: noSelectedOperators,
      closeOnClick: CloseOnClick.BEFORE,
    }),
    [handleOnComplete, noSelectedOperators]
  );

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="Agregar Operadores"
      onCancelInfo={onCancelDialogProp}
      onCompleteInfo={onCompleteDialogProp}
    >
      <div className={classes.contentRoot}>
        <AutoComplete
          value={selectedOperators}
          options={operators ?? []}
          onChange={handleOnChange}
          loading={firstLoading || refetchLoading}
          loadingText="Cargando operadores"
          noOptionsText="No se encontraron operadores"
          fuzzySearch
        />
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    contentRoot: {
      display: "flex",
      justifyContent: "center",
    },
  };
});
