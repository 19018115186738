import React, {
  useMemo,
  createContext,
  useReducer,
  useEffect,
  useState,
  SetStateAction,
} from "react";
import { ContextProviderProps } from "contexts";
import {
  initialTruckAssignmentMetricsState,
  TruckAssignmentMetricsAction,
  truckAssignmentMetricsReducer,
  TruckAssignmentMetricsState,
} from "reducers";
import {
  accumulatedDataParser,
  AccumulatedMetric,
  DataContextInterface,
  emptyMetrics,
  ErrorMsg,
  initialDataContextState,
  Metric,
  metricParser,
  MetricsAPI,
  TruckAssignmentMetricsParams,
} from "interfaces";
import { useData, useMinutesCounter, usePreviousValue } from "hooks";
import { STANDARD_POLLING } from "App";

interface TruckAssignmentMetricsInterface
  extends DataContextInterface<MetricsAPI> {
  menuState: TruckAssignmentMetricsState;
  dispatch: React.Dispatch<TruckAssignmentMetricsAction>;
  lastMetricsUpdateMinutes: number | null;
  parsedMetrics: MetricsAPI["metrics"];
  parsedAverages: AccumulatedMetric[];
  pendingChanges: boolean;
  setPendingChanges: React.Dispatch<SetStateAction<boolean>>;
}

const metricInitialState: MetricsAPI = {
  metrics: emptyMetrics,
  currentShift: "",
  lastMetricsUpdate: null,
  changeEvents: [],
};

export const TruckAssignmentMetricsContext =
  createContext<TruckAssignmentMetricsInterface>({
    menuState: initialTruckAssignmentMetricsState,
    dispatch: () => null,
    lastMetricsUpdateMinutes: null,
    ...initialDataContextState,
    data: metricInitialState,
    parsedMetrics: metricInitialState.metrics,
    parsedAverages: [],
    pendingChanges: false,
    setPendingChanges: () => {},
  });

export const TruckAssignmentMetricsProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [menuState, dispatch] = useReducer(
    truckAssignmentMetricsReducer,
    initialTruckAssignmentMetricsState
  );

  const [pendingChanges, setPendingChanges] = useState(true);

  // Memo
  const truckAssignmentMetricParams = useMemo<TruckAssignmentMetricsParams>(
    () => ({
      groupType: menuState.groupType,
      entity: menuState.selectedEntity,
    }),
    [menuState]
  );

  const {
    data: metricsData,
    polling: pollingMetrics,
    firstLoading: firstLoadingMetrics,
    refetching: refetchingMetrics,
    ...metricsOther
  } = useData<MetricsAPI>(
    {
      config: {
        url: "/truck-assignment/metrics",
        method: "GET",
        params: truckAssignmentMetricParams,
      },
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GENERIC_GET,
    metricInitialState
  );

  const {
    data: metricAverages,
    polling: pollingMetricAverages,
    firstLoading: firstLoadingMetricAverages,
  } = useData<AccumulatedMetric[]>(
    {
      config: {
        url: "/truck-assignment/metrics/accumulated_averages",
        method: "GET",
        params: truckAssignmentMetricParams,
      },
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GENERIC_GET
  );

  const loading = pollingMetrics || firstLoadingMetrics || refetchingMetrics;
  const prevLoading = usePreviousValue<boolean>(loading);

  useEffect(() => {
    if (prevLoading && !loading) {
      setPendingChanges(false);
    }
  }, [loading, prevLoading]);

  const parsedMetrics = useMemo<MetricsAPI["metrics"]>(
    () =>
      // Reconstruct the same data structure, but parsing the value conditionally
      Object.assign(
        {},
        ...Object.values(Metric).map((type) => ({
          [type]:
            metricsData.metrics[type]?.map((e) => ({
              ...e,
              value: metricParser[type]?.(e.value) ?? e.value,
            })) ?? [],
        }))
      ),
    [metricsData]
  );

  const parsedAverages = useMemo<AccumulatedMetric[]>(
    () =>
      pollingMetricAverages || firstLoadingMetricAverages
        ? []
        : metricAverages.map((avgData) => ({
            ...avgData,
            value: accumulatedDataParser[avgData.metric]?.(avgData.value),
          })),
    [metricAverages, firstLoadingMetricAverages, pollingMetricAverages]
  );

  const [lastMetricsUpdateMinutes] = useMinutesCounter(
    metricsData.lastMetricsUpdate
  );

  const contextState = useMemo<TruckAssignmentMetricsInterface>(
    () => ({
      menuState,
      dispatch,
      lastMetricsUpdateMinutes,
      data: metricsData,
      polling: pollingMetrics,
      firstLoading: firstLoadingMetrics,
      refetching: refetchingMetrics,
      ...metricsOther,
      parsedMetrics,
      parsedAverages,
      pendingChanges,
      setPendingChanges,
    }),
    [
      metricsData,
      firstLoadingMetrics,
      lastMetricsUpdateMinutes,
      menuState,
      metricsOther,
      parsedMetrics,
      parsedAverages,
      pollingMetrics,
      refetchingMetrics,
      pendingChanges,
    ]
  );

  return (
    <TruckAssignmentMetricsContext.Provider value={contextState}>
      {children}
    </TruckAssignmentMetricsContext.Provider>
  );
};
