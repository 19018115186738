import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import { ErrorDarkIcon, ErrorLightIcon } from "components/icons";
import React, { useMemo } from "react";

export const NotFoundRelays = () => {
  const classes = useStyles();
  const theme = useTheme();

  const Icon = useMemo(() => {
    return theme.palette.type === "dark" ? ErrorDarkIcon : ErrorLightIcon;
  }, [theme.palette.type]);

  return (
    <Grid container alignContent="center" style={{ height: 500 }}>
      <Grid container className={classes.infoContainer}>
        <Grid container style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <Icon className={classes.infoIcon} />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography
            color="textPrimary"
            variant="h6"
            style={{ fontWeight: 700, marginBottom: 20 }}
          >
            Lo sentimos, no se pudo realizar la programación...
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography color="textPrimary" variant="subtitle1">
            No se pudo programar un relevo con los elementos seleccionados ya
            que no cumplen con ningún pre-requisito. Intente de nuevo cambiando
            los operadores y CAEX seleccionados.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  infoIcon: {
    width: 186,
    height: 200,
  },
  link: {
    color: "#0099CC",
  },
}));
