import React from "react";
import {
  makeStyles,
  Tooltip as MUITooltip,
  Theme,
  TooltipProps,
} from "@material-ui/core";

interface PropsTooltip extends TooltipProps {
  maxWidth?: string;
}
// This component does not accept classes from parent.
// A clases merging logic should be implemented if needed
export const Tooltip: React.FC<PropsTooltip> = ({ maxWidth, children, ...props }) => {
  const classes = useStyles({maxWidth: maxWidth ? maxWidth : "unset"});

  return (
    <MUITooltip
      {...props}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
    >
      {children}
    </MUITooltip>
  );
};

interface StyleProps {
  maxWidth: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  const backgroundColor = theme.palette.secondary.main;
  return {
    arrow: {
      color: backgroundColor,
    },
    tooltip: {
      padding: 8,
      backgroundColor,
      maxWidth: ({maxWidth}) => (maxWidth ? maxWidth : "unset"),
    },
  };
});
