import {
  Button,
  Chip,
  Grid,
  Theme,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { CustomDialog } from "components";
import { FormStepProps, Location } from "interfaces/relieve.interface";
import React, { useEffect, useState } from "react";
import { getDate } from "utils";
import { Warning, Edit, Check } from "@material-ui/icons";
import clsx from "clsx";

interface Props {
  formData: FormStepProps[];
  selectedLocation: number;
  setcountLocation: (value: number) => void;
  setCurrentStep: (value: number) => void;
  setCurrentHour: (value: string[]) => void;
  setDeleteLocation: (value: string) => void;
  setCurrentChangeLocation: (value: string) => void;
  handleEmptyForm: (location: number) => void;
  canWriteUser?: boolean;
  setSwitches: (value: {}) => void;
}

interface ConfirmationDialogProps {
  open: boolean;
  value?: string;
  type?: string;
}

export const RelieveFilters: React.FC<Props> = ({
  formData,
  selectedLocation,
  canWriteUser,
  setcountLocation,
  setCurrentStep,
  setDeleteLocation,
  setCurrentChangeLocation,
  handleEmptyForm,
  setCurrentHour,
  setSwitches,
}) => {
  const classes = useStyles({ canWrite: canWriteUser });
  const theme = useTheme();

  // const { refetch: refreshFilters } = useContext(RelieveFiltersContext);

  const [locationList, setLocationList] = useState<Location[]>();
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [deletionDialogData, setDeletionDialogData] =
    useState<ConfirmationDialogProps>({
      open: false,
    });
  const [changeDialogData, setChangeDialogData] =
    useState<ConfirmationDialogProps>({
      open: false,
    });

  const handleLocation = (
    id: string,
    type: string,
    emptyForm: boolean = false
  ) => {
    if (emptyForm) handleEmptyForm(selectedLocation);

    if (type === "change") {
      const index = formData.findIndex((item) => item.location.id === id);
      setcountLocation(index);
      setCurrentStep(1);
      setSwitches({});
    }
    if (type === "new") {
      setcountLocation(formData.length);
      setCurrentStep(0);
      setCurrentChangeLocation("");
      setCurrentHour([]);
    }
    // if (formData[selectedLocation]?.statusForm?.hasChanges) reloadFilters();
  };

  const handleNewLocation = () => {
    if (formData[selectedLocation]?.statusForm?.hasChanges) {
      setChangeDialogData({
        open: true,
        value: formData[selectedLocation]?.location?.id,
        type: "new",
      });
    } else {
      handleLocation("", "new");
    }
  };

  const handleDeleteLocation = (id: string) => {
    setDeletionDialogData({ open: true, value: id });
  };

  const handleChangeLocation = (id: string) => {
    if (formData[selectedLocation]?.statusForm?.hasChanges) {
      setChangeDialogData({ open: true, value: id });
    } else {
      handleLocation(id, "change");
    }
  };

  const getLocationName = (id: string) => {
    const loc = locationList?.find((item) => item?.id === id);
    return loc ? loc.name : "";
  };

  const Modal = () => {
    return (
      <CustomDialog
        title={`Eliminar ${getLocationName(deletionDialogData.value ?? "")}`}
        icon={<Warning className={classes.icon} fontSize="large" />}
        open={deletionDialogData.open}
        onCompleteInfo={{
          text: "CONFIRMAR",
          onClick: () => {
            setDeleteLocation(deletionDialogData.value ?? "");
            setDeletionDialogData({ open: false });
          },
        }}
        onCancelInfo={{
          text: "CANCELAR",
          onClick: () => setDeletionDialogData({ open: false }),
        }}
        onClose={() => setDeletionDialogData({ open: false })}
        classes={{
          actions: {
            completeActionButton: classes.button,
            dialogTitle: classes.modalDialogTitle,
          },
          dialog: {
            paper: classes.assigmentDialogRoot,
          },
        }}
        keepMounted
      >
        <Typography className={classes.fontColorModal}>
          Al eliminar este comedor, se perderá toda su información del
          formulario y la programación de relevo.
        </Typography>
      </CustomDialog>
    );
  };

  const ChangeActionModal = () => {
    return (
      <CustomDialog
        title={`Existen cambios sin guardar en ${getLocationName(
          formData[selectedLocation]?.location?.id ?? ""
        )}`}
        icon={<Warning className={classes.icon} fontSize="large" />}
        open={changeDialogData.open}
        onCompleteInfo={{
          text: "CANCELAR",
          onClick: () => {
            setChangeDialogData({ open: false });
          },
        }}
        onCancelInfo={{
          text: "CONTINUAR",
          onClick: () => {
            setChangeDialogData({ open: false });
            handleLocation(
              changeDialogData.value ? changeDialogData.value : "",
              changeDialogData?.type ? changeDialogData?.type : "change",
              true
            );
          },
        }}
        onClose={() => setChangeDialogData({ open: false })}
        classes={{
          actions: {
            completeActionButton: classes.button,
            dialogTitle: classes.modalDialogTitle,
          },
          dialog: {
            paper: classes.assigmentDialogRoot,
          },
        }}
        keepMounted
      >
        <Typography className={classes.fontColorModal}>
          Continuar sin guardar eliminará la información del formulario para el
          comedor seleccionado.
        </Typography>
      </CustomDialog>
    );
  };

  useEffect(() => {
    const locations: Location[] = [];
    formData?.forEach((item) => {
      if (
        !canWriteUser &&
        (item.statusForm.status === "FINISHED" ||
          item.statusForm.status === "ACCEPTED")
      ) {
        locations.push({
          id: item?.location?.id,
          name: item?.location?.name.replace("COMEDOR", "").trim(),
        });
      } else {
        if (canWriteUser) {
          locations.push({
            id: item?.location?.id,
            name: item?.location?.name.replace("COMEDOR", "").trim(),
          });
        }
      }
    });

    setLocationList(locations);
    if (isFirstRender && locations.length) {
      setCurrentStep(1);
      setIsFirstRender(false);
    }
  }, [formData, isFirstRender, setCurrentStep, canWriteUser]);

  const getStatusForm = (locationId: string) => {
    const location = formData.find((item) => item?.location?.id === locationId);
    return location?.statusForm.status === "FINISHED" ||
      location?.statusForm.status === "ACCEPTED"
      ? true
      : false;
  };

  useEffect(() => {}, [selectedLocation]);

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      className={classes.mainContainer}
    >
      <Typography variant="h6" style={{ marginRight: 36 }}>
        <strong>Relevo | {getDate()}</strong>
      </Typography>
      {locationList?.map((item, index) => (
        <Chip
          key={index}
          label={item?.name}
          onClick={() => {
            handleChangeLocation(item?.id);
          }}
          variant={theme.palette.type === "dark" ? "outlined" : "default"}
          className={clsx(
            classes.chipLocations,
            formData[selectedLocation]?.location?.id === item?.id
              ? classes.selectedChip
              : classes.chip
          )}
          icon={getStatusForm(item?.id) ? <Check /> : <Edit />}
          onDelete={() =>
            canWriteUser ? handleDeleteLocation(item?.id) : undefined
          }
          // deleteIcon={getStatusForm(item?.id) ? <></> : undefined} 
    
        />
      ))}
      {canWriteUser && (
        <Button onClick={() => handleNewLocation()}>
          <AddCircle className={classes.newLocation} />
        </Button>
      )}
      <Modal />
      <ChangeActionModal />
    </Grid>
  );
};

interface StyleProps {
  canWrite?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  const { palette } = theme;

  return {
    mainContainer: {
      marginBottom: 10,
      marginTop: 26,
    },
    newLocation: {
      fill: palette.primary.main,
    },
    selectedChip: ({ canWrite }) => ({
      color: palette.chip.contrastText,
      background:
        palette.type === "light"
          ? palette.secondary.main
          : palette.common.white,

      "& .MuiChip-deleteIcon": {
        color: !canWrite
          ? "transparent"
          : palette.type === "light"
          ? palette.chip.avatar
          : palette.secondary.light,
      },
      "& .MuiChip-icon": {
        color:
          palette.type === "light"
            ? palette.common.white
            : palette.secondary.light,
      },
      "&&:hover,&&:focus": {
        backgroundColor:
          palette.type === "light"
            ? palette.secondary.main
            : palette.common.white,
        color: palette.chip.contrastText,
      },
    }),

    chip: ({ canWrite }) => ({
      color:
        palette.type === "light"
          ? palette.text.primary
          : palette.secondary.contrastText,
      background:
        palette.type === "light" ? "#e0e0e0" : palette.background.default,
      "& .MuiChip-deleteIcon": {
        color: !canWrite ? "transparent" : palette.secondary.light,
      },
      "& .MuiChip-icon": {
        color: palette.secondary.light,
      },
      "&&:hover": {
        backgroundColor:
          palette.type === "light"
            ? palette.secondary.main
            : palette.common.white,
        color: palette.chip.contrastText,
      },
      "&&:focus": {
        backgroundColor:
          palette.type === "light" ? "#e0e0e0" : palette.background.default,
      },
    }),
    chipLocations: {
      marginRight: 4,
    },
    button: {
      minHeight: 42,
    },
    modalDialogTitle: {
      color: palette.error.main,
    },
    fontColorModal: {
      color: palette.text.secondary,
    },
    paddingModal: {
      paddingTop: 20,
    },
    assigmentDialogRoot: {
      maxWidth: 450,
    },
    icon: {
      marginRight: 15,
    },
  };
});
