import React, { createContext } from "react";
import { ErrorMsg } from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { DataContextInterface, initialDataContextState } from "interfaces";
import { STANDARD_POLLING } from "App";
import { ContextProviderProps } from "contexts";
import { AppRoute } from "utils";

interface Filters {
  caex: string[],
  routes: string[],
  availableModels: string,
  notAvailableModels: string,
  urlMaps: string;
}

export const TruckAssignmentFiltersVelContext = createContext<
  DataContextInterface<Filters>
>(initialDataContextState);

export const TruckAssignmentFiltersVelProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { refetch, ...other } = useData<Filters>(
    {
      config: "/truck-assignment-velocity/filters",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_PHASES,
    undefined
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetch(), [AppRoute.TA_PERFORMANCE_SCREEN]);

  return (
    <TruckAssignmentFiltersVelContext.Provider value={{ refetch, ...other }}>
      {children}
    </TruckAssignmentFiltersVelContext.Provider>
  );
};
