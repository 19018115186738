import React, { createContext } from "react";
import { ErrorMsg, PhasesCard } from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { DataContextInterface, initialDataContextState } from "interfaces";
import { STANDARD_POLLING } from "App";
import { ContextProviderProps } from "contexts";
import { AppRoute } from "utils";

export const TruckPerformancePhasesContext = createContext<
  DataContextInterface<PhasesCard>
>(initialDataContextState);

export const TruckPerformancePhasesProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { refetch, ...other } = useData<PhasesCard>(
    {
      config: "/truck-assignment-performance/phases",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_PHASES
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetch(), [AppRoute.TA_PERFORMANCE_SCREEN]);

  return (
    <TruckPerformancePhasesContext.Provider value={{ refetch, ...other }}>
      {children}
    </TruckPerformancePhasesContext.Provider>
  );
};
