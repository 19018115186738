import React from "react";
import {
  Theme,
  makeStyles,
  Typography,
  Avatar,
  Chip,
  alpha,
  Divider,
} from "@material-ui/core";
import { ExcavatorIconLogo } from "components";
import { BaseElement } from "components/List";
import { Error } from "@material-ui/icons";

interface Props {
  excav: string;
  reasonId: number;
  isCa: boolean;
  spacing: string[];
  grayOut: boolean;
}

export const BalanceListOriginCell: React.FC<Props> = ({
  excav,
  reasonId,
  isCa,
  spacing,
  grayOut,
}) => {
  const classes = useStyles();
  return (
    <BaseElement classNames={[...spacing, grayOut ? classes.grayOut : ""]}>
      {isCa ? (
        <Avatar className={classes.avatar}>
          <Typography variant="body1">
            <strong>EC</strong>
          </Typography>
        </Avatar>
      ) : (
        <ExcavatorIconLogo />
      )}
      <Typography
        variant="subtitle1"
        className={classes.excav}
        component="span"
      >
        {excav}
      </Typography>
      <Divider orientation="vertical" className={classes.divider} flexItem />
 
      {[5000, 5010, 5020, 3000].includes(reasonId) ? (
        <Chip
          classes={{
            root: classes.chip,
            icon: classes.chipIcon,
          }}
          icon={<Error />}
          label={
            <Typography variant="subtitle1" component="span">
              <b>{reasonId}</b>
            </Typography>
          }
        />
      ) : (
        <Typography variant="subtitle1" component="span">
          {reasonId}
        </Typography>
      )}
    </BaseElement>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  const avatarBackgroundColor: string =
    theme.palette.type === "dark"
      ? theme.palette.common.white
      : theme.palette.avatar.background;
  return {
    excav: {
      fontWeight: 700,
      marginLeft: "10px",
    },
    avatar: {
      width: 24,
      height: 24,
      backgroundColor: avatarBackgroundColor,
      color: theme.palette.getContrastText(avatarBackgroundColor),
      marginBottom: 10,
    },
    divider: {
      margin: "5px 5px",
    },
    chip: {
      backgroundColor: alpha(theme.palette.error.main, 0.08),
      color: theme.palette.error.main,
    },
    chipIcon: {
      color: theme.palette.error.main,
    },
    grayOut: {
      opacity: 0.6,
    },
  };
});
