import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const LostTruckIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="54"
      height="52"
      viewBox="0 0 54 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.8">
        <mask
          id="mask0_5157_30469"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="54"
          height="52"
        >
          <path
            d="M0 0H52.3386C53.2286 0 53.95 0.721437 53.95 1.61137V50.3886C53.95 51.2786 53.2286 52 52.3386 52H0V0Z"
            fill="#F5F5F5"
          />
        </mask>
        <g mask="url(#mask0_5157_30469)">
          <path
            d="M23.8912 35.2641C20.4421 33.2691 20.4237 30.0236 23.8503 28.0286C27.2769 26.0336 32.8701 26.0336 36.3193 28.0286C39.7672 30.023 39.7861 33.2691 36.3595 35.2641C32.9335 37.2591 27.3397 37.2585 23.8918 35.2641H23.8912ZM38.5835 26.7099C36.5537 25.5359 33.9804 24.8685 31.3354 24.7065L31.5112 25.4789L29.9237 25.5976L28.3256 25.7174L28.3173 25.683L28.1077 24.7611C25.709 24.9883 23.4116 25.6379 21.5701 26.7099C20.1963 27.5095 19.2282 28.4569 18.6657 29.466L20.1561 29.8189L20.3959 29.8759L19.7931 30.7408L19.1921 31.6022L18.0902 31.3411C17.9794 32.9357 18.8043 34.5505 20.5617 35.8799L21.6186 35.2647L23.8983 36.5834L22.8413 37.1986C25.1915 38.2379 28.052 38.7089 30.8617 38.6123L30.8563 37.6815V37.5439H34.068L34.0727 38.2445C35.7431 37.9105 37.3099 37.357 38.638 36.5834C39.943 35.8235 40.8822 34.9307 41.4547 33.9786C40.5458 33.9691 40.0449 33.9187 39.9886 33.9127L40.5583 32.0785L40.5458 32.0773C40.5594 32.0785 41.0941 32.1272 42.1043 32.1123C42.3145 30.1725 41.1403 28.1893 38.5835 26.7104V26.7099Z"
            fill="#E5E5E5"
          />
          <path
            d="M23.8989 36.5822L21.6192 35.2634L20.5623 35.8786L3.55592 45.7801L1.08381 47.2192L-0.882629 48.3641L1.39704 49.6829L9.13668 45.1768L11.4276 43.8432L22.8425 37.1973L23.8989 36.5822Z"
            fill="#DADADA"
          />
          <path
            d="M20.397 29.8753L20.1566 29.8184L18.6669 29.466L-0.122395 25.0192L-3.12387 24.3085L-29.3608 18.0999L-31.817 19.5301L-15.019 23.5053L-15.2724 23.1327L-12.2834 22.4541L-10.9091 24.4776L18.0907 31.3406L19.1927 31.601L19.7937 30.7402L20.397 29.8753Z"
            fill="#DBDBDB"
          />
          <path
            d="M50.5829 31.0603C50.4497 31.0888 50.3177 31.1161 50.188 31.1422C46.4434 31.9074 43.6894 32.0878 42.1049 32.1109C41.0948 32.1257 40.5601 32.0771 40.5464 32.0759L40.5589 32.0771L39.9893 33.9113C40.0455 33.9173 40.5464 33.9677 41.4554 33.9772C43.1974 33.995 46.4381 33.8609 50.9181 32.9711C51.3243 32.8904 51.7322 32.805 52.1426 32.7154C51.5889 32.1613 51.0697 31.6096 50.5829 31.0603ZM78.9267 21.7853L76.647 20.4666C70.8987 23.8129 65.7217 26.187 61.2684 27.8711C61.7486 28.4128 62.2726 28.9609 62.8399 29.5132C62.9234 29.4817 63.0069 29.4503 63.0903 29.4189C68.2667 27.4583 73.5946 24.8902 78.9261 21.7859L78.9267 21.7853Z"
            fill="#DBDBDB"
          />
          <path
            d="M34.0692 37.5427H30.8563V37.6804L30.8622 38.6111L30.9126 47.6939L30.9232 49.5833L30.9392 52.2892H34.1426L34.0721 38.2433L34.0674 37.5427H34.0692Z"
            fill="#E0E0E0"
          />
          <path
            d="M30.9132 47.6944C23.2725 48.5011 14.7062 45.2532 11.427 43.8438L9.13611 45.1773C10.4903 45.7753 12.9251 46.7725 15.9657 47.6795C18.7178 48.5005 21.3996 49.0777 23.938 49.3951C26.4071 49.7036 28.7472 49.7659 30.9233 49.5832L30.9132 47.6944Z"
            fill="#DBDBDB"
          />
          <path
            d="M26.5137 7.5606L17.9338 2.59771L15.6542 1.27898L7.56637 -3.39917L5.3009 -2.08044L8.22066 -0.39213L8.45988 -0.912978L11.5656 -0.42713L10.8503 1.12948L24.5336 9.04424L25.4609 13.1209L27.5268 13.2757L27.126 15.1248L25.8961 15.0328L28.1083 24.7611L28.3179 25.6829L28.3262 25.7173L29.9243 25.5981L31.5112 25.4794L31.3354 24.7065L27.8276 9.28272L26.506 9.50459L25.6036 7.71365L26.5137 7.5606Z"
            fill="#DADADA"
          />
          <path
            d="M50.6716 5.44334L48.0687 3.93774L37.5449 5.70673L38.0162 5.97902L35.7513 7.29775L34.0229 6.29877L26.5136 7.56055L25.6035 7.7136L26.5059 9.50454L27.8275 9.28267L50.6716 5.44334Z"
            fill="#DBDBDB"
          />
          <path
            d="M38.0163 5.97881L37.5449 5.70652L14.6606 -7.53003L12.3957 -6.2113L20.4835 -1.53315L21.6162 -2.19222L23.8959 -0.873491L22.7638 -0.214421L34.023 6.29856L35.7514 7.29754L38.0163 5.97881Z"
            fill="url(#paint0_linear_5157_30469)"
          />
          <path
            d="M27.5268 13.2756L25.4609 13.1207L6.01265 11.6626L5.17834 13.4796L25.8961 15.0327L27.126 15.1246L27.5268 13.2756Z"
            fill="#DBDBDB"
          />
        </g>
      </g>
      <g opacity="0.16">
        <path
          d="M24.8062 36.2714C26.5586 35.2575 29.3915 35.2575 31.1328 36.2714C32.8742 37.2852 32.8652 38.9288 31.1122 39.9426C29.3591 40.9565 26.5269 40.9565 24.7855 39.9426C23.0441 38.9288 23.0532 37.2852 24.8062 36.2707V36.2714Z"
          fill="#DD5900"
        />
      </g>
      <path
        d="M25.7257 35.3477L27.9592 36.6479C27.8958 36.6109 27.8428 36.552 27.8033 36.4684L25.5699 35.1682C25.6093 35.2518 25.6623 35.3114 25.7257 35.3477Z"
        fill="#E2832D"
      />
      <path
        d="M26.2075 22.8574C24.6775 23.7424 23.2549 24.0287 22.0735 23.7942C22.8178 24.2276 23.5621 24.661 24.307 25.0944C25.4884 25.3295 26.9116 25.0426 28.4409 24.1576C27.771 23.7676 26.878 23.2474 26.2075 22.8574Z"
        fill="#A95C2E"
      />
      <path
        d="M22.0729 23.7941C21.6694 23.7137 21.2944 23.5732 20.953 23.3743L23.1864 24.6745C23.5278 24.8733 23.9035 25.0139 24.3064 25.0943C23.9352 24.8779 23.5634 24.6622 23.1922 24.4458C23.1483 24.4199 23.0894 24.3862 23.0455 24.3603C22.8082 24.2223 22.5715 24.0843 22.3342 23.9463C22.2559 23.901 22.1576 23.8265 22.0729 23.7941Z"
        fill="#C4C4C4"
      />
      <path
        d="M27.0358 22.3251C26.7642 22.5175 26.4875 22.6956 26.2068 22.8576L28.4403 24.1578C28.7203 23.9958 28.997 23.817 29.2693 23.6253C32.957 21.0178 35.8151 15.7554 35.8028 11.3813C35.7964 9.03807 34.9667 7.39258 33.6308 6.6249L31.3973 5.32471C32.7333 6.09239 33.5622 7.73788 33.5694 10.0811C33.5816 14.4552 30.7235 19.7176 27.0358 22.3251Z"
        fill="#A95C2E"
      />
      <path
        d="M19.3803 25.61L17.1469 24.3098L25.5699 35.1681L27.8033 36.4683L19.3803 25.61Z"
        fill="#E2832D"
      />
      <path
        d="M20.951 23.373L23.1844 24.6732C21.8582 23.901 21.0363 22.2594 21.0292 19.9259L18.7958 18.6257C18.8022 20.9592 19.6248 22.6008 20.951 23.373Z"
        fill="white"
      />
      <path
        d="M33.6483 6.63455L31.4148 5.33436C30.2547 4.65932 28.7099 4.64895 26.988 5.42376C26.7157 5.5462 26.439 5.68807 26.159 5.85003L28.3924 7.15022C28.6724 6.98827 28.9498 6.84639 29.2214 6.72395C30.9434 5.94915 32.4888 5.95951 33.6483 6.63455Z"
        fill="white"
      />
      <path
        d="M35.4517 3.52467L33.2182 2.22447C31.4122 1.17304 28.911 1.3214 26.1505 2.91765L28.384 4.21784C31.1445 2.62094 33.6456 2.47324 35.4517 3.52467Z"
        fill="#EFA42E"
      />
      <path
        d="M28.3924 7.15029L26.1589 5.8501C22.0793 8.21014 18.7828 13.9305 18.7957 18.6266L21.0292 19.9268C21.0156 15.2307 24.3128 9.51034 28.3924 7.15029Z"
        fill="white"
      />
      <path
        d="M28.3841 4.21799C22.898 7.39106 18.4654 15.0834 18.4835 21.3984C18.488 23.0828 18.8087 24.4983 19.3804 25.61L27.8034 36.4683C27.9295 36.7378 28.203 36.7514 28.4759 36.5933C28.7488 36.4352 29.0216 36.1061 29.1458 35.6922L37.4796 15.1417C38.0428 13.3738 38.3545 11.5922 38.3499 9.90853C38.3318 3.59348 33.8707 1.04556 28.3847 4.21863L28.3841 4.21799ZM28.4403 24.1576C24.3607 26.517 21.0429 24.6227 21.0293 19.9259C21.0157 15.2298 24.3129 9.50946 28.3925 7.14942C28.6725 6.98746 28.9499 6.84558 29.2214 6.72315C32.9118 5.0634 35.7906 7.0069 35.8028 11.3804C35.8151 15.7545 32.957 21.0169 29.2693 23.6244C28.9977 23.8168 28.7209 23.9949 28.4403 24.1569V24.1576Z"
        fill="url(#paint1_linear_5157_30469)"
      />
      <path
        d="M21.0293 19.9267C21.0357 22.2531 21.8537 23.8914 23.1722 24.6662C24.4454 25.1171 26.0652 24.8923 27.8273 23.8727C28.1073 23.71 28.384 23.5319 28.6556 23.3395C32.3434 20.732 35.2015 15.4696 35.1892 11.0955C35.1827 8.76978 34.3654 7.13077 33.0462 6.35661C31.9754 5.97633 30.6595 6.07545 29.2201 6.72328C28.9485 6.84572 28.6718 6.9876 28.3911 7.14956C24.3115 9.5096 21.015 15.2293 21.0286 19.9261L21.0293 19.9267Z"
        fill="url(#paint2_linear_5157_30469)"
      />
      <path
        d="M28.384 4.21816L26.1506 2.91797C20.6646 6.09104 16.2319 13.7834 16.25 20.0984C16.2545 21.7828 16.5753 23.1983 17.1469 24.31L19.3803 25.6102C18.8087 24.4991 18.488 23.083 18.4835 21.3986C18.4654 15.0836 22.898 7.39124 28.384 4.21816Z"
        fill="url(#paint3_linear_5157_30469)"
      />
      <g clipPath="url(#clip0_5157_30469)">
        <path
          d="M32.5 9.34936C32.5 12.2423 29.2027 13.8362 29.2027 16.0097V16.6193L27.0349 17.8534V17.1539C27.0349 14.081 30.0587 12.6071 30.0587 10.9183C30.0587 10.1288 29.4762 9.99391 28.5291 10.5335C27.7086 10.9982 26.9083 11.8326 26.2144 13.0368L24.7 12.732C25.7231 10.9632 27.0349 9.55421 28.7266 8.58489C31.2236 7.16588 32.5 7.82043 32.5 9.34936ZM29.6383 19.5722C29.6383 20.3617 28.9799 21.4009 28.1442 21.8806C27.3236 22.3503 26.6855 22.0455 26.6855 21.256C26.6855 20.4666 27.3236 19.4373 28.1442 18.9676C28.9849 18.488 29.6383 18.7628 29.6383 19.5722Z"
          fill="#DD5900"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5157_30469"
          x1="35.8917"
          y1="6.43207"
          x2="26.2828"
          y2="1.14043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5157_30469"
          x1="28.2126"
          y1="-1.75869"
          x2="28.2127"
          y2="41.9568"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4D251" />
          <stop offset="1" stopColor="#EEA32D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5157_30469"
          x1="30.875"
          y1="15.8797"
          x2="24.0414"
          y2="11.0077"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E8E8E8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5157_30469"
          x1="24.0255"
          y1="3.76111"
          x2="19.8233"
          y2="25.8368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEA32D" />
          <stop offset="1" stopColor="#E2832D" />
        </linearGradient>
        <clipPath id="clip0_5157_30469">
          <rect
            width="7.8"
            height="14.3"
            fill="white"
            transform="translate(24.7 7.80029)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
