import React, { useCallback, useContext, useMemo } from "react";
import {
  HeaderCellProps,
  NestedKeyOf,
  PerformanceRatingEvent,
  PerformanceRatingOperator,
  getPracticesTranslation,
  RowProps,
  ScreenType,
} from "interfaces";
import { Chip, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { AppRoute, parseTimeDelta } from "utils";
import { VirtualizedTable } from "components";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PerformanceRatingActionType, DataViewOption } from "reducers";
import { PerformanceRatingsContext } from "contexts";
import clsx from "clsx";

interface Props {
  data: PerformanceRatingEvent[];
  loading: boolean;
}

export const PerformanceRatingSupervisorTable: React.FC<Props> = ({
  data,
  loading,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { dispatch } = useContext(PerformanceRatingsContext);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const onRowClick = useCallback(
    (selectedOperator: PerformanceRatingOperator) => {
      dispatch({
        type: PerformanceRatingActionType.OPERATOR_CHANGE,
        selectedOperator,
      });
      dispatch({
        type: PerformanceRatingActionType.VIEW_OPTION_CHANGE,
        option: DataViewOption.GRAPH,
      });
      navigate({
        pathname: AppRoute.PERFORMANCE_RATINGS_SUMMARY,
        search: `?${createSearchParams({ operatorId: selectedOperator.id })}`,
      });
    },
    [dispatch, navigate]
  );

  const headCells = useMemo<
    HeaderCellProps<NestedKeyOf<PerformanceRatingEvent>>[]
  >(
    () => [
      {
        dataKey: "operator.name",
        label: "Operador",
        align: "left",
        sortable: true,
        className: classes.headerCell,
      },
      {
        dataKey: "operatorPractice",
        label: isSmall ? "Evento" : "Tipo de evento",
        align: "center",
        sortable: true,
        className: classes.headerCell,
      },
      {
        dataKey: "exceededEventsCount",
        label: isSmall ? "N° demoras" : "Cantidad demoras",
        align: "center",
        sortable: true,
        className: classes.headerCell,
      },
      {
        dataKey: "accummulatedEventDuration",
        label: "Acumulado",
        align: "center",
        sortable: true,
        className: classes.headerCell,
      },
      {
        dataKey: "averageEventDuration",
        label: isSmall ? "Demora prom" : "Demora promedio",
        align: "center",
        sortable: true,
        className: classes.headerCell,
      },
      {
        dataKey: "supervisorName",
        label: "Supervisor",
        align: "center",
        sortable: true,
        className: classes.headerCell,
      },
    ],
    [classes, isSmall]
  );

  const rowDetails = useCallback(
    (
      datum: PerformanceRatingEvent
    ): RowProps<NestedKeyOf<PerformanceRatingEvent>> => {
      const warningExceededEvents =
        datum.exceededEventsCount >= 3 && datum.exceededEventsCount < 5;
      const alertExceededEvents = datum.exceededEventsCount >= 5;
      return {
        onClick: () => onRowClick(datum.operator),
        cellsData: [
          {
            dataKey: "operator.name",
            value: datum.operator.name,
            align: "left",
          },
          {
            dataKey: "operatorPractice",
            value: getPracticesTranslation(
              datum.operatorPractice,
              ScreenType.SUPERVISOR
            ),
            align: "center",
          },
          {
            dataKey: "exceededEventsCount",
            value: datum.exceededEventsCount ? (
              <Chip
                className={clsx(
                  classes.chipExceedEvents,
                  warningExceededEvents && classes.chipExceedEventsWarning,
                  alertExceededEvents && classes.chipExceedEventsError
                )}
                label={datum.exceededEventsCount}
              />
            ) : (
              "-"
            ),
            align: "center",
          },
          {
            dataKey: "accummulatedEventDuration",
            value: datum.accummulatedEventDuration
              ? parseTimeDelta(datum.accummulatedEventDuration)
              : "-",
            align: "center",
          },
          {
            dataKey: "averageEventDuration",
            value: datum.averageEventDuration
              ? parseTimeDelta(datum.averageEventDuration)
              : "-",
            align: "center",
          },
          {
            dataKey: "supervisorName",
            value: datum.supervisorName ?? "-",
            align: "center",
            className: classes.supervisorNameCell,
          },
        ],
      };
    },
    [
      classes.chipExceedEvents,
      classes.chipExceedEventsError,
      classes.chipExceedEventsWarning,
      classes.supervisorNameCell,
      onRowClick,
    ]
  );

  return (
    <VirtualizedTable
      loadingData={loading}
      data={data}
      renderCells={rowDetails}
      headers={headCells}
    />
  );
};

const useStyles = makeStyles(({ palette }) => ({
  headerCell: {
    width: "100%",
  },
  chipExceedEvents: {
    width: "100%",
    height: 24,
    maxWidth: 48,
    cursor: "inherit",
  },
  chipExceedEventsWarning: {
    backgroundColor: palette.warning.main,
    color: palette.common.white,
  },
  chipExceedEventsError: {
    backgroundColor: palette.error.main,
    color: palette.common.white,
  },
  supervisorNameCell: {
    textAlign: "center",
  },
}));
