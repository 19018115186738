import React, { createContext } from "react";
import {
  DataContextInterface,
  initialDataContextState,
  Operator,
  ErrorMsg,
} from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { ContextProviderProps } from "contexts";
import { STANDARD_POLLING } from "App";
import { AppRoute } from "utils";
export const OperatorsContext = createContext<DataContextInterface<Operator[]>>(
  initialDataContextState
);

export const OperatorsProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { refetch, ...other } = useData<Operator[]>(
    {
      config: "/operator-assignment/shift-crew/operators",
      options: { useCache: false },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_OPERATORS
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(
    () => refetch(),
    [AppRoute.OPERATORS_SCREEN]
  );

  return (
    <OperatorsContext.Provider value={{ ...other, refetch }}>
      {children}
    </OperatorsContext.Provider>
  );
};
