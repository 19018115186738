import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, Snackbar } from "@material-ui/core";

export interface SnackbarData {
  text: string;
  severity: AlertColor;
}

export type AlertColor = "warning" | "error" | "info" | "success";

type CustomSnackbarProps = {
  open: boolean;
  data: SnackbarData | null;
  onClose: () => void;
  autoHideDuration?: number;
};

export const CustomSnackbar: React.FC<CustomSnackbarProps> = ({
  open,
  data,
  onClose,
  autoHideDuration,
}) => {
  const classes = useStyles();
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      classes={{ root: classes.snackbar }}
      autoHideDuration={autoHideDuration ? autoHideDuration : null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={data?.severity ?? undefined}
        classes={{ root: classes.alert }}
      >
        {data?.text}
      </MuiAlert>
    </Snackbar>
  );
};

const useStyles = makeStyles((theme) => ({
  snackbar: {
    zIndex: 10001,
  },
  alert: {
    width: "100%",
  },
}));
