import React, { createContext } from "react";
import { DataContextInterface, ErrorMsg, initialDataContextState } from "interfaces";
import { ContextProviderProps } from "contexts";
import { useData, usePathnameChange } from "hooks";
import { STANDARD_POLLING } from "App";
import { AppRoute } from "utils";

interface StatusInterface {
    status: string
}

export const TruckAssignmentPerformanceStatusContext = createContext<
    DataContextInterface<StatusInterface>
>(initialDataContextState);

export const TruckAssignmentPerformanceStatusProvider: React.FC<ContextProviderProps> = ({
    children,
  }) => {
    const { refetch, ...other } = useData<StatusInterface>(
      {
        config: "/truck-assignment-performance/status",
        options: {
          useCache: false,
        },
        ...STANDARD_POLLING,
      },
      ErrorMsg.GET_STATUS_PERFORMANCE_VIEW,
    );

    usePathnameChange(() => refetch(), [AppRoute.TA_PERFORMANCE_SCREEN]);

  return (
    <TruckAssignmentPerformanceStatusContext.Provider value={{ refetch, ...other }}>
      {children}
    </TruckAssignmentPerformanceStatusContext.Provider>
  );
};