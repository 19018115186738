import { Grid, Typography, makeStyles } from "@material-ui/core";
import { FoodIcon } from "components/icons/FoodIcon";
import React from "react";

export const InitView = () => {
  const classes = useStyles();
  return (
    <Grid container alignContent="center" style={{ height: "100%" }}>
      <Grid container className={classes.infoContainer}>
        <Grid container style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <FoodIcon className={classes.infoIcon} />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography color="textPrimary" variant="h6">
            Para comenzar, selecciona el comedor donde
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography color="textPrimary" variant="h6">
            se programará el relevo y el rango de horario
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography color="textPrimary" variant="h6">
            del almuerzo.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  infoIcon: {
    width: 90,
    height: 90,
    fill:
    theme.palette.type === "light"
      ? theme.palette.background.paper
      : theme.palette.background.default,
  },
}));
