import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const YellowTruckIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="60px"
      height="60px"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.853 30.6566C17.2984 29.1829 15.2156 30.0688 15.2156 32.6284C15.2156 35.188 17.2984 38.4771 19.853 39.9507C22.4061 41.4244 24.489 40.5384 24.489 37.9796C24.489 35.4193 22.4061 32.1302 19.853 30.6566Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2329 32.6224C14.6783 31.1487 12.5955 32.0347 12.5955 34.5942C12.5955 37.1538 14.6783 40.4429 17.2329 41.9166C19.786 43.3902 21.8689 42.5043 21.8689 39.9454C21.8689 37.3851 19.786 34.096 17.2329 32.6224Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2328 34.1837C15.5368 33.2054 14.1536 33.7931 14.1536 35.4929C14.1536 37.192 15.5368 39.3763 17.2328 40.3553C18.9282 41.3336 20.3114 40.7452 20.3114 39.0461C20.3114 37.3462 18.9282 35.162 17.2328 34.1837Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3614 34.4097C15.8642 33.5456 14.6428 34.0649 14.6428 35.5654C14.6428 37.066 15.8642 38.9942 17.3614 39.8576C18.8579 40.7217 20.0793 40.2023 20.0793 38.7018C20.0793 37.202 18.8579 35.273 17.3614 34.4097Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3613 34.8022C16.0803 34.0632 15.0353 34.5076 15.0353 35.7914C15.0353 37.0752 16.0803 38.7256 17.3613 39.4646C18.6422 40.2035 19.6865 39.7591 19.6865 38.4753C19.6865 37.1916 18.6422 35.5419 17.3613 34.8022Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3613 35.2756C16.3387 34.6857 15.5049 35.04 15.5049 36.0648C15.5049 37.0889 16.3387 38.4062 17.3613 38.9961C18.3831 39.5852 19.217 39.231 19.217 38.2069C19.217 37.182 18.3831 35.8655 17.3613 35.2756Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3613 35.5112C16.4679 34.9955 15.74 35.3054 15.74 36.2C15.74 37.0947 16.4679 38.2454 17.3613 38.7603C18.2539 39.2753 18.9818 38.9654 18.9818 38.0708C18.9818 37.1761 18.2539 36.0262 17.3613 35.5112Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3115 42.4681C20.926 42.4464 22.0132 41.6938 23.5732 40.2104L22.5613 37.1475L20.9335 40.3035C19.9044 41.7682 19.697 42.4898 20.3115 42.4681Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.794 30.1593V30.2304L16.5639 32.0066L13.2014 32.6264C15.5964 30.7706 16.794 29.9209 16.794 30.0773C16.794 30.2337 16.794 30.2611 16.794 30.1593Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.364 30.6566C44.8094 29.1829 42.7266 30.0688 42.7266 32.6284C42.7266 35.188 44.8094 38.4771 47.364 39.9507C49.9171 41.4244 52 40.5384 52 37.9796C52 35.4193 49.9171 32.1302 47.364 30.6566Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.7439 32.6214C42.1893 31.1478 40.1064 32.0337 40.1064 34.5933C40.1064 37.1528 42.1893 40.4419 44.7439 41.9156C47.297 43.3892 49.3799 42.5033 49.3799 39.9444C49.3799 37.3841 47.297 34.095 44.7439 32.6214Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.7438 34.1832C43.0478 33.2049 41.6646 33.7926 41.6646 35.4924C41.6646 37.1915 43.0478 39.3758 44.7438 40.3548C46.4391 41.3331 47.8224 40.7447 47.8224 39.0456C47.8224 37.3457 46.4391 35.1615 44.7438 34.1832Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.8723 34.4087C43.3751 33.5446 42.1537 34.0639 42.1537 35.5645C42.1537 37.065 43.3751 38.9932 44.8723 39.8566C46.3687 40.7207 47.5901 40.2014 47.5901 38.7008C47.5901 37.201 46.3687 35.2721 44.8723 34.4087Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.8722 34.8012C43.5913 34.0622 42.5463 34.5066 42.5463 35.7904C42.5463 37.0742 43.5913 38.7246 44.8722 39.4636C46.1532 40.2026 47.1975 39.7582 47.1975 38.4744C47.1975 37.1906 46.1532 35.5409 44.8722 34.8012Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.8723 35.2746C43.8497 34.6848 43.0159 35.039 43.0159 36.0638C43.0159 37.0879 43.8497 38.4052 44.8723 38.9951C45.8941 39.5842 46.728 39.23 46.728 38.2059C46.728 37.181 45.8941 35.8645 44.8723 35.2746Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.8723 35.5107C43.9789 34.995 43.251 35.3049 43.251 36.1995C43.251 37.0942 43.9789 38.2449 44.8723 38.7598C45.7649 39.2748 46.4928 38.9649 46.4928 38.0703C46.4928 37.1756 45.7649 36.0257 44.8723 35.5107Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8225 42.4681C48.437 42.4464 49.5242 41.6938 51.0842 40.2104L50.0723 37.1475L48.4445 40.3035C47.4153 41.7682 47.208 42.4898 47.8225 42.4681Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3048 30.1593V30.2304L44.0747 32.0066L40.7123 32.6264C43.1073 30.7706 44.3048 29.9209 44.3048 30.0773C44.3048 30.2337 44.3048 30.2611 44.3048 30.1593Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.9635 32.835L48.5293 34.8163V37.8988L51.9635 35.924V32.835Z"
        fill="#EB922D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2733 30.4862L48.3659 34.7143L48.5292 34.8103L51.9634 32.8356L51.7943 32.7352L51.7601 32.7156L44.7068 28.5049L41.2733 30.4862Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.5076 34.4943V35.1773C51.5076 35.3067 51.438 35.426 51.3262 35.4908L49.3936 36.6349C49.2949 36.6916 49.1722 36.6196 49.1722 36.5054V36.424L50.906 35.3955C51.0171 35.3315 51.0874 35.2115 51.0874 35.0827V34.4812L51.2863 34.367C51.385 34.3103 51.5076 34.3823 51.5076 34.4943Z"
        fill="#121311"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.0874 34.4814V35.083C51.0874 35.2117 51.0171 35.3317 50.906 35.3957L49.1722 36.4242V35.8241C49.1722 35.6932 49.2419 35.5739 49.3537 35.5092L51.0874 34.4814Z"
        fill="#FFF3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.4803 47.3841L30.3323 40.7746V32.6616L41.4803 39.2711V47.3841Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.689 34.5308L41.4802 39.2702V47.3832L49.689 42.6735V34.5308Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.333 32.6596L41.4788 39.272L49.689 34.5595L38.5389 27.9209L30.333 32.6596Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8863 44.5857L35.9675 44.0569L35.3412 40.5918L36.8863 41.5032V44.5857Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5317 31.0615L30.4789 32.7628V40.443L27.5317 38.7526V31.0615Z"
        fill="#502D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5302 40.2161L27.9439 38.7912V33.9731L25.5302 35.398V40.2161Z"
        fill="#8D4528"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5829 33.6968L25.5301 35.3981V40.2161L22.5829 38.5257V33.6968Z"
        fill="#6D3822"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9439 33.9725L25.7848 35.2476L25.5301 35.3989L22.5829 33.7071L22.8471 33.5499L22.9 33.5194L24.9981 32.272L27.9439 33.9725Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1085 36.1641L17.2321 31.5148L7.53414 20.2655L7 17.3394L30.1784 31.0333L25.1085 36.1641Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3967 31.2643L24.9285 34.7731L17.9484 30.6519L9.47761 20.8297L9.32666 19.999L28.3967 31.2643Z"
        fill="#874226"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.4355 22.6943L30.1785 31.0328L25.1085 36.1635L39.3655 27.8549L44.4355 22.6943Z"
        fill="#CA722E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00085 17.3377L30.1771 31.0339L44.4349 22.7231L21.255 9L7.00085 17.3377Z"
        fill="#ECC530"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.2503 22.7147L35.3471 26.7399L30.1784 29.7512L26.3277 27.4746L11.9371 18.9696L9.18298 17.3425L21.2512 10.2842L42.2503 22.7147Z"
        fill="#E2832D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7846 13.2096L11.9371 18.9696L9.18298 17.3425L21.2512 10.2842L21.7846 13.2096Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.347 26.7402L30.1784 29.7515L26.3276 27.4749L31.4854 24.46L35.347 26.7402Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.484 24.4584L35.3471 26.7394L42.251 22.7157L21.2512 10.2837L21.7854 13.2091L31.484 24.4584Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2117 31.0572L29.0483 30.3633V28.3965L30.2117 29.0904V31.0572Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5934 36.5131L33.4702 31.1387L36.6983 28.1899L40.7152 30.5611L42.5934 36.5131Z"
        fill="#212220"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.6184 27.7305L40.7153 30.5613L42.5935 36.5133L47.4966 33.6999L45.6184 27.7305Z"
        fill="#434341"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6991 28.1888L40.7146 30.5614L45.6183 27.7466L41.6 25.3579L36.6991 28.1888Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1465 40.9025L49.0228 36.9268L49.1868 37.0177L42.1465 45.0972V40.9025Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1465 40.9028L42.3105 40.9938V45.1884L42.1465 45.0975V40.9028Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3104 40.9938L49.1867 37.0181V41.1436L42.3104 45.1885V40.9938Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9619 37.4072V41.0135L47.9988 41.5808L47.853 41.6666L46.0916 42.7031L45.9457 42.7897L44.18 43.8262L44.0378 43.9127L43.8992 43.992L42.5348 44.7957V41.6477L43.7533 40.9458L44.0378 40.7843V40.6338L43.7533 40.7953L42.5348 41.4979V41.1218L43.7533 40.4199L44.18 40.1719L45.6642 39.3122L45.9457 39.15L46.0916 39.0678L47.5721 38.211L47.853 38.0495L47.9988 37.9637L48.9619 37.4072Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9619 38.9741V39.124L47.9988 39.676L47.853 39.7626L47.5721 39.924L46.0916 40.7729L45.9457 40.8594L45.6642 41.0209L44.18 41.8734L44.0378 41.9563L43.7533 42.1178L42.5348 42.8204V42.6698L43.7533 41.9672L44.0378 41.8057L44.18 41.7235L45.6642 40.8711L45.9457 40.7089L46.0916 40.623L47.5721 39.7742L47.853 39.6127L47.9988 39.5262L48.9619 38.9741Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 42.3994V42.5463L43.7533 42.7078L42.5348 43.4097V43.2635L43.7533 42.5609L44.0378 42.3994Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9618 39.564V39.7138L47.9988 40.2659L47.8529 40.3524L47.572 40.5139L46.0915 41.3627L45.9457 41.4493L45.6641 41.6107L44.18 42.4632L44.0377 42.5461V42.3992L44.18 42.317L45.6641 41.4602L45.9457 41.2987L46.0915 41.2165L47.572 40.3633L47.8529 40.2019L47.9988 40.1197L48.9618 39.564Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 42.9897V43.1396L43.7533 43.3018L42.5348 44.0037V43.8538L43.7533 43.1512L44.0378 42.9897Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 43.5796V43.7294L43.7533 43.8909L42.5348 44.5935V44.443L43.7533 43.7411L44.0378 43.5796Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9618 40.7471V40.8933L47.9988 41.4497L47.8529 41.5319L47.572 41.6934L46.0915 42.5466L45.9457 42.6287L45.6641 42.7902L44.18 43.647L44.0377 43.7292V43.5794L44.18 43.4965L45.6641 42.644L45.9457 42.4825L46.0915 42.396L47.572 41.5472L47.8529 41.3857L47.9988 41.2991L48.9618 40.7471Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.8073 38.9146L48.9619 38.9742L47.9988 39.5263L47.853 39.6128L47.5721 39.7743L46.0916 40.6231L45.9457 40.7089L45.6642 40.8711L44.18 41.7236L44.0378 41.8058L43.7533 41.9673L42.5348 42.6699V42.5237L43.7533 41.8211L44.0378 41.6596L44.18 41.5767L45.6642 40.7206L45.9457 40.5591L46.0916 40.4762L47.5721 39.6237L47.853 39.4623L47.9988 39.3793L48.8073 38.9146Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.8073 39.5078L48.9619 39.5638L47.9988 40.1195L47.853 40.2017L47.5721 40.3632L46.0916 41.2164L45.9457 41.2986L45.6642 41.46L44.18 42.3169L44.0378 42.3991L43.7533 42.5605L42.5348 43.2632V43.1126L43.7533 42.4107L44.0378 42.2492L44.18 42.1663L45.6642 41.3138L45.9457 41.1524L46.0916 41.0658L47.5721 40.217L47.853 40.0555L47.9988 39.969L48.8073 39.5078Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 40.6343V40.7848L43.7533 40.9463L42.5348 41.6482V41.4984L43.7533 40.7958L44.0378 40.6343Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9618 37.7993V37.9492L47.9988 38.5049L47.8529 38.5878L47.572 38.7492L46.0915 39.6017L45.9457 39.6846L45.6641 39.8461L44.18 40.7022L44.0377 40.7851V40.6346L44.18 40.5524L45.6641 39.6955L45.9457 39.5341L46.0915 39.4519L47.572 38.5987L47.8529 38.4372L47.9988 38.355L48.9618 37.7993Z"
        fill="#2D2E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9619 38.3916V38.5385L47.9988 39.0942L47.853 39.1771L47.5721 39.3386L46.0916 40.1911L45.9457 40.2733L45.6642 40.4355L44.18 41.2916L44.0378 41.3738L43.7533 41.536L42.5348 42.2379V42.088L43.7533 41.3854L44.0378 41.2239L44.18 41.141L45.6642 40.2886L45.9457 40.1271L46.0916 40.0405L47.5721 39.1917L47.853 39.0302L47.9988 38.9437L48.9619 38.3916Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.8073 37.7432L48.9619 37.7992L47.9988 38.3549L47.853 38.4371L47.5721 38.5985L46.0916 39.4517L45.9457 39.5339L45.6642 39.6954L44.18 40.5522L44.0378 40.6344L43.7533 40.7959L42.5348 41.4985V41.3479L43.7533 40.646L44.0378 40.4846L44.18 40.4017L45.6642 39.5492L45.9457 39.3877L46.0916 39.3012L47.5721 38.4523L47.853 38.2909L47.9988 38.2043L48.8073 37.7432Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.8073 38.3325L48.9619 38.3922L47.9988 38.9442L47.853 39.0308L47.5721 39.1923L46.0916 40.0411L45.9457 40.1276L45.6642 40.2891L44.18 41.1416L44.0378 41.2245L43.7533 41.386L42.5348 42.0886V41.9417L43.7533 41.239L44.0378 41.0783L44.18 40.9954L45.6642 40.1385L45.9457 39.9771L46.0916 39.8949L47.5721 39.0424L47.853 38.8809L47.9988 38.798L48.8073 38.3325Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9989 37.9639V41.581L47.853 41.6668V38.0497L47.9989 37.9639Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 38.0498V38.4368L47.572 38.452V38.2113L47.8529 38.0498Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 38.5874V39.0304L47.572 39.0456V38.7489L47.8529 38.5874Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 39.1772V39.6129L47.572 39.6275V39.3387L47.8529 39.1772Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 39.7627V40.202L47.572 40.2173V39.9242L47.8529 39.7627Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9618 40.1572V40.3042L47.9988 40.8599L47.8529 40.9428L47.572 41.1042L46.0915 41.9567L45.9457 42.0396L45.6641 42.2011L44.18 43.0572L44.0377 43.1394V42.9896L44.18 42.9066L45.6641 42.0542L45.9457 41.8927L46.0915 41.8061L47.572 40.9573L47.8529 40.7958L47.9988 40.7093L48.9618 40.1572Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 40.353V40.796L47.572 40.8113V40.5145L47.8529 40.353Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 40.9429V41.3858L47.572 41.4011V41.1043L47.8529 40.9429Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 41.5317V41.667L47.572 41.8321V41.6932L47.8529 41.5317Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.0916 39.0684V42.7037L45.9457 42.7903V39.1506L46.0916 39.0684Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 39.1504V39.5337L45.6642 39.549V39.3126L45.9458 39.1504Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 39.6836V40.1266L45.6642 40.1418V39.8451L45.9458 39.6836Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 40.2734V40.7091L45.6642 40.7244V40.4356L45.9458 40.2734Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 40.8594V41.2987L45.6642 41.314V41.0208L45.9458 40.8594Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 41.4497V41.8927L45.6642 41.9079V41.6112L45.9458 41.4497Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 42.0391V42.482L45.6642 42.4966V42.2005L45.9458 42.0391Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 42.6289V42.7904L45.6642 42.9555V42.7904L45.9458 42.6289Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.18 40.1724V43.8266L44.0377 43.9132V40.2553L44.18 40.1724Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 40.2549V40.6338L43.7533 40.6455V40.42L44.0378 40.2549Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 40.7847V41.224L43.7533 41.2385V40.9461L44.0378 40.7847Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 41.3735V41.8056L43.7533 41.8209V41.5357L44.0378 41.3735Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 41.9565V42.3995L43.7533 42.4148V42.118L44.0378 41.9565Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 42.5469V42.9898L43.7533 43.0044V42.7083L44.0378 42.5469Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 43.1396V43.5797L43.7533 43.5942V43.3019L44.0378 43.1396Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.7533 43.8905L44.0378 43.729V43.913L43.8992 43.9923H43.7533V43.8905Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1465 39.8625L49.0228 35.8867L49.1868 35.9776L42.1465 43.8113V39.8625Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1465 39.8628L42.3105 39.9537V43.9025L42.1465 43.8116V39.8628Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3104 39.9533L49.1867 35.9775V39.8573L42.3104 43.9021V39.9533Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9619 36.3682V39.9737L47.9988 40.541L47.853 40.6268L46.0916 41.6641L45.9457 41.7499L45.6642 41.9157L44.18 42.7871L44.0378 42.8729L43.8992 42.9522L42.5348 43.7559V40.6087L43.7533 39.906L44.0378 39.7446V39.594L43.7533 39.7555L42.5348 40.4581V40.0828L43.7533 39.3802L44.0378 39.215L44.18 39.1321L45.6642 38.2724L45.9457 38.1109L46.0916 38.028L47.5721 37.1719L47.853 37.0104L47.9988 36.9239L48.9619 36.3682Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9619 37.9346V38.0851L47.9988 38.6365L47.853 38.723L47.5721 38.8845L46.0916 39.7333L45.9457 39.8199L45.6642 39.9814L44.18 40.8338L44.0378 40.9167L43.7533 41.0782L42.5348 41.7808V41.6303L43.7533 40.9284L44.0378 40.7662L44.18 40.684L45.6642 39.8315L45.9457 39.67L46.0916 39.5835L47.5721 38.7347L47.853 38.5732L47.9988 38.4866L48.9619 37.9346Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 41.3599V41.5068L43.7533 41.6683L42.5348 42.3709V42.224L43.7533 41.5213L44.0378 41.3599Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9618 38.5244V38.6743L47.9988 39.2263L47.8529 39.3129L47.572 39.4743L46.0915 40.3232L45.9457 40.4097L45.6641 40.5712L44.18 41.4237L44.0377 41.5066V41.3597L44.18 41.2775L45.6641 40.4206L45.9457 40.2592L46.0915 40.177L47.572 39.3238L47.8529 39.163L47.9988 39.0801L48.9618 38.5244Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 41.9492V42.0998L43.7533 42.2613L42.5348 42.9632V42.8133L43.7533 42.1107L44.0378 41.9492Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 42.5391V42.6889L43.7533 42.8504L42.5348 43.553V43.4024L43.7533 42.7005L44.0378 42.5391Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9618 39.707V39.854L47.9988 40.4097L47.8529 40.4926L47.572 40.6541L46.0915 41.5065L45.9457 41.5887L45.6641 41.7502L44.18 42.607L44.0377 42.6892V42.5394L44.18 42.4564L45.6641 41.604L45.9457 41.4425L46.0915 41.356L47.572 40.5071L47.8529 40.3457L47.9988 40.2591L48.9618 39.707Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 39.5938V39.7443L43.7533 39.9058L42.5348 40.6084V40.4578L43.7533 39.7552L44.0378 39.5938Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9618 36.7593V36.9091L47.9988 37.4648L47.8529 37.5477L47.572 37.7092L46.0915 38.5617L45.9457 38.6446L45.6641 38.8061L44.18 39.6622L44.0377 39.7451V39.5945L44.18 39.5123L45.6641 38.6555L45.9457 38.494L46.0915 38.4118L47.572 37.5594L47.8529 37.3979L47.9988 37.315L48.9618 36.7593Z"
        fill="#2D2E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9619 37.3521V37.499L47.9988 38.0547L47.853 38.1376L47.5721 38.2991L46.0916 39.1515L45.9457 39.2345L45.6642 39.3959L44.18 40.252L44.0378 40.3349L43.7533 40.4964L42.5348 41.1983V41.0485L43.7533 40.3459L44.0378 40.1844L44.18 40.1015L45.6642 39.249L45.9457 39.0875L46.0916 39.001L47.5721 38.1521L47.853 37.9907L47.9988 37.9048L48.9619 37.3521Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9989 36.9238V40.541L47.853 40.6268V37.0104L47.9989 36.9238Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 37.0107V37.3977L47.572 37.4122V37.1722L47.8529 37.0107Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 37.5474V37.9903L47.572 38.0056V37.7088L47.8529 37.5474Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 38.1367V38.5724L47.572 38.587V38.2982L47.8529 38.1367Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 38.7231V39.1632L47.572 39.1777V38.8846L47.8529 38.7231Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9618 39.1167V39.2636L47.9988 39.8193L47.8529 39.9022L47.572 40.0637L46.0915 40.9162L45.9457 40.9991L45.6641 41.1606L44.18 42.0167L44.0377 42.0996V41.949L44.18 41.8661L45.6641 41.0136L45.9457 40.8522L46.0915 40.7656L47.572 39.9168L47.8529 39.7553L47.9988 39.6695L48.9618 39.1167Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 39.3125V39.7555L47.572 39.7707V39.474L47.8529 39.3125Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 39.9028V40.3458L47.572 40.3611V40.0643L47.8529 39.9028Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8529 40.4922V40.6267L47.572 40.7926V40.6537L47.8529 40.4922Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.0916 38.0283V41.6644L45.9457 41.7502V38.1112L46.0916 38.0283Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 38.1104V38.4929L45.6642 38.5082V38.2718L45.9458 38.1104Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 38.6445V39.0875L45.6642 39.1028V38.806L45.9458 38.6445Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 39.2339V39.6696L45.6642 39.6841V39.3954L45.9458 39.2339Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 39.8193V40.2587L45.6642 40.2739V39.9808L45.9458 39.8193Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 40.4097V40.8526L45.6642 40.8679V40.5711L45.9458 40.4097Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 40.9995V41.4425L45.6642 41.4577V41.161L45.9458 40.9995Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9458 41.5889V41.7503L45.6642 41.9162V41.7503L45.9458 41.5889Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.18 39.1323V42.7873L44.0377 42.8731V39.2152L44.18 39.1323Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 39.2153V39.5943L43.7533 39.6059V39.3804L44.0378 39.2153Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 39.7446V40.184L43.7533 40.1992V39.9061L44.0378 39.7446Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 40.334V40.7653L43.7533 40.7806V40.4955L44.0378 40.334Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 40.9165V41.3595L43.7533 41.3747V41.078L44.0378 40.9165Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 41.5063V41.9493L43.7533 41.9646V41.6678L44.0378 41.5063Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0378 42.1001V42.5394L43.7533 42.554V42.2616L44.0378 42.1001Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.7533 42.8504L44.0378 42.689V42.873L43.8992 42.9523H43.7533V42.8504Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8191 48.3743L40.3184 48.079V47.0796L40.8191 47.3749V48.3743Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.015 41.5161L40.8192 47.375V48.3744L51.015 42.5177V41.5161Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3184 47.0801L40.8191 47.3754L51.0149 41.5187L50.9598 41.4859L50.9489 41.4801L50.5142 41.2212L40.3184 47.0801Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7257 31.7837L29.0483 28.3964V27.5024L34.7257 30.889V31.7837Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2051 33.7961L12.0416 24.8024V23.9077L27.2051 32.9015V33.7961Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9487 22.5488L34.7258 30.8894V31.7841L48.9487 23.4435V22.5488Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0483 27.502L34.6793 30.8624L34.7257 30.8893L48.9487 22.5487L43.2712 19.1592L29.0483 27.502Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2892 24.0897L26.9149 32.7642L27.1667 32.5089L12.5875 23.8613L12.2094 23.9966L12.2892 24.0897Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9186 39.1712C32.364 37.6976 30.2811 38.5835 30.2811 41.1431C30.2811 43.7026 32.364 46.9918 34.9186 48.4654C37.4717 49.939 39.5545 49.0531 39.5545 46.4942C39.5545 43.9339 37.4717 40.6448 34.9186 39.1712Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2984 41.136C29.7439 39.6624 27.661 40.5483 27.661 43.1079C27.661 45.6675 29.7439 48.9566 32.2984 50.4302C34.8516 51.9039 36.9344 51.0179 36.9344 48.4591C36.9344 45.8988 34.8516 42.6097 32.2984 41.136Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2984 42.6974C30.6024 41.7191 29.2191 42.3068 29.2191 44.0066C29.2191 45.7057 30.6024 47.89 32.2984 48.869C33.9937 49.8473 35.377 49.2588 35.377 47.5597C35.377 45.8599 33.9937 43.6756 32.2984 42.6974Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4268 42.9233C30.9297 42.0592 29.7083 42.5786 29.7083 44.0791C29.7083 45.5796 30.9297 47.5079 32.4268 48.3712C33.9233 49.2353 35.1447 48.716 35.1447 47.2155C35.1447 45.7157 33.9233 43.7867 32.4268 42.9233Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4267 43.3159C31.1458 42.5769 30.1007 43.0213 30.1007 44.3051C30.1007 45.5889 31.1458 47.2392 32.4267 47.9782C33.7076 48.7172 34.7519 48.2728 34.7519 46.989C34.7519 45.7052 33.7076 44.0556 32.4267 43.3159Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4269 43.7893C31.4043 43.1994 30.5704 43.5536 30.5704 44.5785C30.5704 45.6026 31.4043 46.9198 32.4269 47.5097C33.4487 48.0989 34.2826 47.7447 34.2826 46.7205C34.2826 45.6957 33.4487 44.3792 32.4269 43.7893Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4268 44.0249C31.5335 43.5092 30.8055 43.819 30.8055 44.7137C30.8055 45.6083 31.5335 46.759 32.4268 47.274C33.3195 47.789 34.0474 47.4791 34.0474 46.5845C34.0474 45.6898 33.3195 44.5399 32.4268 44.0249Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3771 50.9827C35.9916 50.9611 37.0788 50.2085 38.6388 48.725L37.6268 45.6621L35.999 48.8181C34.9699 50.2829 34.7626 51.0044 35.3771 50.9827Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8595 38.674V38.7451L31.6294 40.5213L28.267 41.141C30.662 39.2852 31.8595 38.4356 31.8595 38.592C31.8595 38.7484 31.8595 38.7757 31.8595 38.674Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6299 37.7199L36.7232 41.9473L36.8858 42.044L40.3199 40.0685L40.1508 39.9681L40.1174 39.9485L29.6299 37.1802V37.7199Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.594 38.3408L29.6297 37.7196L33.0632 35.7383L26.594 37.8018V38.3408Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6299 37.1805L36.7232 41.4087L36.8858 41.5047L40.3199 39.5299L40.1508 39.4295L40.1174 39.4099L33.0633 35.1992L29.6299 37.1805Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.594 37.8024L29.6297 37.1805L33.0632 35.1992L30.0267 35.8211L26.594 37.8024Z"
        fill="#A44C2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3199 39.5234L36.8865 41.5048V44.5873L40.3199 42.6125V39.5234Z"
        fill="#EB922D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7341 41.1638V41.8475C39.7341 41.9763 39.6644 42.0963 39.5527 42.1603L37.62 43.3044C37.5213 43.3611 37.3987 43.2891 37.3987 43.1757V43.0942L39.1325 42.0657C39.2435 42.001 39.3139 41.8817 39.3139 41.7522V41.1507L39.5127 41.0372C39.6114 40.9798 39.7341 41.0525 39.7341 41.1638Z"
        fill="#121311"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3139 41.1504V41.7519C39.3139 41.8814 39.2435 42.0007 39.1325 42.0654L37.3987 43.0939V42.4938C37.3987 42.3629 37.4684 42.2436 37.5801 42.1789L39.3139 41.1504Z"
        fill="#FFF3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8863 44.5867L35.9675 44.0579L35.3412 40.5928L36.8863 41.5042V44.5867Z"
        fill="#D16F2D"
      />{" "}
    </SvgIcon>
  );
};
