export const logDebug = (section: string, msg: unknown) => {
  // Problem diagnosis, detailed
  if (process.env.NODE_ENV !== "production") {
    console.log("[DEBUG]\t[" + section.toUpperCase() + "]\t" + msg);
  }
};

export const logInfo = (section: string, msg: unknown) => {
  // Indicates the correct behavior of the system
  console.info("[INFO]\t[" + section.toUpperCase() + "]\t" + msg);
};

export const logWarn = (section: string, msg: unknown) => {
  // The system behaves correctly but there's an unespacted situation or future problem
  console.warn("[WARNING]\t[" + section.toUpperCase() + "]\t" + msg);
};

export const logError = (section: string, msg: unknown) => {
  // Function didn't work due a problem
  console.error("[ERROR]\t[" + section.toUpperCase() + "]\t" + msg);
};

export const logCritical = (section: string, msg: unknown) => {
  // Something went wrong and the app must be restarted"
  console.error("[CRITICAL]\t[" + section.toUpperCase() + "]\t" + msg);
};

export const logTrack = (
  page: string,
  action: string = "click",
  detail: string = ""
) => {
  // let url = window.location.pathname;
  //let localUser = getOnLocalStorage("user").user;
  // console.info(
  //   `[TRACK]\t[${url}][${page ? page.toUpperCase() : "UNDEFINED_PAGE"}]\t[${
  //     action ? action.toUpperCase() : "UNDEFINED_ACTION"
  //   }] (${
  //     localUser ? localUser.username.toUpperCase() : "USER_NOT_FOUND"
  //   }) ${detail.toUpperCase()}`
  // );
};

export const TRACK_ACTIONS = {
  CLICK: "click",
  REFRESH: "refresh",
  GO_TO_PAGE: "go_to_page",
  LOAD_PAGE: "load_page",
  FORM_INPUT: "form_input",
  FORM_SUBMIT: "form_submit",
};
