import React from "react";
import { makeStyles, MenuItem, Select, SvgIconProps } from "@material-ui/core";
import { Label } from "components";
import { Location } from "interfaces";
import clsx from "clsx";

interface TruckDetailItemSelectorClassesProps {
  root?: string;
}

interface TruckDetailItemSelectorProps {
  label: string;
  icon: React.FC<SvgIconProps<"svg", {}>>;
  value?: number;
  options: Location[];
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
  classes?: TruckDetailItemSelectorClassesProps;
  children?: React.ReactNode;
}

export const TruckDetailItemSelector: React.FC<
  TruckDetailItemSelectorProps
> = ({
  label,
  icon: Icon,
  value,
  options,
  onChange,
  classes: propClasses,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.truckSelectorItemRoot, propClasses?.root)}>
      <Label
        classes={{ text: classes.itemLabelText }}
        text={label}
        icon={Icon}
      />
      <Select
        className={classes.selector}
        value={value ?? ""}
        onChange={onChange}
      >
        {options.map(({ name, id }) => (
          <MenuItem key={`${name}-${id}`} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    truckSelectorItemRoot: {
      display: "flex",
      marginTop: 6,
      marginBottom: 6,
      alignItems: "center",
      marginRight: 60,
      "&:last-child": {
        marginRight: 0,
      },
    },
    itemLabelText: {
      fontWeight: 700,
      width: 125,
    },
    itemText: {},
    selector: {
      minWidth: 238,
      maxHeight: 28,
    },
  };
});
