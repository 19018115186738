import React from "react";
import { ListHeaderCellProps } from "interfaces";
import { makeStyles, Theme, Paper } from "@material-ui/core";
import { AutoSizer } from "components";
import { CustomListHeader } from "./CustomListHeader";
import clsx from "clsx";

export interface CustomListClasses {
  header?: string;
  table?: string;
}

interface CustomListProps<T, K> {
  data: T[];
  headers: ListHeaderCellProps<K>[];
  renderRows: (entities: T[]) => React.ReactNode | React.ReactNode[];
  loadingData: boolean;
  disableHeight?: boolean;
  listKey: string;
  classes?: CustomListClasses;
}

export const CustomList = <T extends unknown, K>({
  data,
  headers,
  loadingData,
  renderRows,
  disableHeight,
  listKey,
  classes: propClasses,
}: CustomListProps<T, K>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AutoSizer disableWidth disableHeight={disableHeight}>
        {() => (
          <Paper
            elevation={0}
            className={clsx(classes.backgroundPaper, propClasses?.table)}
            key={`${listKey}-backPaper`}
          >
            <CustomListHeader
              loading={loadingData}
              headCells={headers}
              headerKey={listKey}
              key={`${listKey}-header`}
              className={propClasses?.header}
            />
            {renderRows(data)}
          </Paper>
        )}
      </AutoSizer>
    </div>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => {
  const cellBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;
  return {
    root: {
      height: "100%",
      overflowY: "scroll",
      backgroundColor: palette.background.default,
    },
    backgroundPaper: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: palette.background.default,
      flex: "1",
    },
    tableHeadRoot: {
      position: "sticky",
      zIndex: 1,
      top: 0,
    },
    tableCellHeader: {
      backgroundColor: cellBackground,
      color: palette.getContrastText(cellBackground),
      paddingTop: 10,
      paddingBottom: 9,
    },
    sortLabel: {
      color: palette.getContrastText(cellBackground),
    },
    sortIcon: {
      color: `${palette.getContrastText(cellBackground)} !important`,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    linearLoader: {
      height: 5,
      position: "absolute",
      width: "100%",
      zIndex: 2,
    },
    cell: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    emptyRow: {
      height: 53,
    },
  };
});
