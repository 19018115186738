import React from "react";
import { Divider, makeStyles, Theme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

export const PrKpiCardSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.kpiRoot}>
        <div className={classes.firstSeccion}>
          <Skeleton variant="circle" className={classes.icon} />
          <div className={classes.kpiDetails}>
            <Skeleton variant="text" className={classes.kpiTitle} />
            <Skeleton variant="text" className={classes.kpiSubTitle} />
          </div>
        </div>
        <div className={classes.secondSeccion}>
          <Skeleton variant="text" className={classes.kpiCard} />
          <Skeleton variant="text" className={classes.kpiCard} />
          <Skeleton variant="text" className={classes.kpiCard} />
        </div>
      </div>
      <Divider
        className={classes.divider}
        orientation="vertical"
        variant="middle"
        flexItem
        light
      />
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  kpiRoot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 300,
    minWidth: 250,
    height: 136,
  },
  firstSeccion: {
    display: "flex",
    marginRight: "auto",
  },
  secondSeccion: {
    display: "flex",
  },
  kpiTitle: {
    height: 20,
    width: 80,
    marginBottom: 6,
  },
  kpiSubTitle: {
    height: 15,
    width: 45,
  },
  kpiCard: {
    marginRight: 8,
    padding: 4,
    height: 80,
    // height: "100%",
    width: "100%",
  },
  divider: {
    "&:last-child": {
      display: "none",
    },
  },
  icon: {
    width: 68,
    height: 68,
    marginRight: 16,
  },
}));
