import { Grid, Typography, makeStyles } from "@material-ui/core";
import { ExcavatorIcon, OperatorIcon, YellowTruckIcon } from "components/icons";
import { DataTaget } from "interfaces/relieve.interface";
import React from "react";

export interface Target {
  operators: number;
  caex: number;
  excavs: number;
}
interface Props {
  formData: DataTaget;
  targetValues: Target
}
export const ValidationView: React.FC<Props> = ({ formData, targetValues }) => {
  const classes = useStyles();
  
  return (
    <Grid container alignContent="center" style={{ height: "100%" }}>
      <Grid container className={classes.infoContainer}>
        <Grid container style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <Grid
              container
              style={{ justifyContent: "center", marginBottom: 28 }}
            >
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={4}>
                    <Grid container style={{ justifyContent: "center" }}>
                      <ExcavatorIcon className={classes.icon} />
                      <Typography color="textPrimary" variant="h6">
                        {formData?.excavs?.current ?? 0} /
                      </Typography>
                      <Typography
                        variant="h6"
                        color={
                          formData?.excavs?.current < targetValues.excavs
                            ? "error"
                            : "textSecondary"
                        }
                        style={{ paddingLeft: 4 }}
                      >
                        {targetValues.excavs}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container style={{ justifyContent: "center" }}>
                      <OperatorIcon className={classes.icon} />
                      <Typography color="textPrimary" variant="h6">
                        {formData?.operators?.current ?? 0} /
                      </Typography>
                      <Typography
                        variant="h6"
                        color={
                          formData?.operators?.current < targetValues.operators
                            ? "error"
                            : "textSecondary"
                        }
                        style={{ paddingLeft: 4 }}
                      >
                        {targetValues.operators}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container style={{ justifyContent: "center" }}>
                      <YellowTruckIcon className={classes.icon} />
                      <Typography color="textPrimary" variant="h6">
                        {formData?.caex?.current ?? 0} /
                      </Typography>
                      <Typography
                        variant="h6"
                        color={
                          formData?.caex?.current < targetValues.caex
                            ? "error"
                            : "textSecondary"
                        }
                        style={{ paddingLeft: 4 }}
                      >
                        {targetValues.caex}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography color="textPrimary" variant="h6">
            Agrega palas, operadores y CAEX en la lista
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography color="textPrimary" variant="h6">
            para crear una sugerencia de relevo.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    width: 38,
    height: 38,
    marginRight: 8,
  },
}));
