import React, { useMemo } from "react";
import { PL1N } from "components/icons/PL1N";
import { PL1S } from "components/icons/PL1S";
import { CInt } from "components/icons/CInt";
import { N17S } from "components/icons/N17S";
import { N17I } from "components/icons/N17I";
import { PSU } from "components/icons/PSU";
import { PNO } from "components/icons/PNO";
import { N11S1 } from "components/icons/N11S1";
import { N11S2 } from "components/icons/N11S2";
import { N11S3 } from "components/icons/N11S3";
import { PSECO } from "components/icons/PSECO";
import { Theme, makeStyles, Grid, useTheme } from "@material-ui/core";
import { PL1NDark } from "components/icons/PL1NDark";
import { PL1SDark } from "components/icons/PL1SDark";
import { CIntDark } from "components/icons/CIntDark";
import { N17SDark } from "components/icons/N17SDark";
import { N17IDark } from "components/icons/N17IDark";
import { PSUDark } from "components/icons/PSUDark";
import { PNODark } from "components/icons/PNODark";
import { N11S1Dark } from "components/icons/N11S1Dark";
import { N11S2Dark } from "components/icons/N11S2Dark";
import { N11S3Dark } from "components/icons/N11S3Dark";
import { PSECODark } from "components/icons/PSECODark";

interface Props {
  selectedRoute: string;
}

export const Maps: React.FC<Props> = ({ selectedRoute }) => {
  const classes = useStyles({});
  const theme = useTheme();

  const ImageRoute = useMemo(() => {
    const imagePaths = [
      {
        routeName: "PL1 Rampa Norte",
        icon:
          theme.palette.type === "light" ? (
            <PL1N className={classes.image} />
          ) : (
            <PL1NDark className={classes.image} />
          ),
      },
      {
        routeName: "PL1 Rampa Sur",
        icon:
          theme.palette.type === "light" ? (
            <PL1S className={classes.image} />
          ) : (
            <PL1SDark className={classes.image} />
          ),
      },
      {
        routeName: "Camino Internacional",
        icon:
          theme.palette.type === "light" ? (
            <CInt className={classes.image} />
          ) : (
            <CIntDark className={classes.image} />
          ),
      },
      {
        routeName: "N17 Superior",
        icon:
          theme.palette.type === "light" ? (
            <N17S className={classes.image} />
          ) : (
            <N17SDark className={classes.image} />
          ),
      },
      {
        routeName: "N17 Inferior",
        icon:
          theme.palette.type === "light" ? (
            <N17I className={classes.image} />
          ) : (
            <N17IDark className={classes.image} />
          ),
      },
      {
        routeName: "Pila Sur",
        icon:
          theme.palette.type === "light" ? (
            <PSU className={classes.image} />
          ) : (
            <PSUDark className={classes.image} />
          ),
      },
      {
        routeName: "Pila Norte",
        icon:
          theme.palette.type === "light" ? (
            <PNO className={classes.image} />
          ) : (
            <PNODark className={classes.image} />
          ),
      },
      {
        routeName: "N11 Sector 1",
        icon:
          theme.palette.type === "light" ? (
            <N11S1 className={classes.image} />
          ) : (
            <N11S1Dark className={classes.image} />
          ),
      },
      {
        routeName: "N11 Sector 2",
        icon:
          theme.palette.type === "light" ? (
            <N11S2 className={classes.image} />
          ) : (
            <N11S2Dark className={classes.image} />
          ),
      },
      {
        routeName: "N11 Sector 3",
        icon:
          theme.palette.type === "light" ? (
            <N11S3 className={classes.image} />
          ) : (
            <N11S3Dark className={classes.image} />
          ),
      },
      {
        routeName: "Pasto Seco",
        icon:
          theme.palette.type === "light" ? (
            <PSECO className={classes.image} />
          ) : (
            <PSECODark className={classes.image} />
          ),
      },
    ];

    if (selectedRoute !== "") {
      const image = imagePaths.find((item) => item.routeName === selectedRoute);

      return image?.icon;
    }
  }, [selectedRoute, classes.image, theme]);

  return (
    <Grid item xs={12} className={classes.conteinerImage}>
      {ImageRoute}
    </Grid>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  image: { width: "100%", height: 432, fill: "none" },
  conteinerImage: {
    background:
      palette.type === "light" ? palette.grey[50] : palette.background.paper,
      marginTop: "10px"
  },
}));
