import React from "react";
import { IconButton, IconButtonProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

interface HeaderBackButtonProps extends IconButtonProps {}

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = ({
  className,
  onClick,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      className={`${className} ${classes.root}`}
      onClick={onClick}
      disabled={disabled}
    >
      <ArrowBackIosRoundedIcon className={classes.icon} />
    </IconButton>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
  icon: {},
}));
