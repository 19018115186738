import React, { useCallback, useState, useEffect, useMemo } from "react";
import { makeStyles, Typography, alpha } from "@material-ui/core";
import { logError } from "services";
import { CustomDialog } from "./CustomDialog";
import { AssignmentAlert, ErrorMsg } from "interfaces";
import { formatLocalizedDate } from "utils";
import { STANDARD_POLLING } from "App";
import clsx from "clsx";
import { useData } from "hooks";

export const AssignmentAlertDialog: React.FC = () => {
  const classes = useStyles();

  const {
    data: assignmentAlerts,
    refetching: refetchLoading,
    refetch: refetchAssignmentAlerts,
  } = useData<AssignmentAlert[]>(
    {
      config: "/operator-assignment/alerts",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_OPERATOR_ASSIGMENT_ALERTS
  );

  const currentAlert = useMemo(() => assignmentAlerts?.[0], [assignmentAlerts]);

  const [openDialog, setOpenDialog] = useState(false);

  const { refetching: mutationLoading, refetch: markAsRead } = useData<
    unknown,
    undefined
  >(
    {
      config: {
        method: "PATCH",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.MARK_ASSIGMENT_ALERT
  );

  const handleMarkAsRead = useCallback(
    async (alertId: number) => {
      try {
        setOpenDialog(false);
        await markAsRead({
          url: `/operator-assignment/alerts/${alertId}`,
        });
        await refetchAssignmentAlerts();
      } catch (e) {
        logError("ALERT-ASSIGNMENT-CONTEXT", `Error: ${e}`);
      }
    },
    [markAsRead, refetchAssignmentAlerts]
  );

  useEffect(() => {
    if (currentAlert && !refetchLoading && !mutationLoading) {
      setOpenDialog(true);
    }
  }, [currentAlert, refetchLoading, mutationLoading]);

  return (
    <CustomDialog
      title={`Posible doble asignación en CAEX ${currentAlert?.equipId}`}
      open={openDialog}
      onCompleteInfo={{
        text: "OK",
        onClick: () => handleMarkAsRead(currentAlert?.id ?? 0),
      }}
      onClose={() => setOpenDialog(true)}
      classes={{
        dialog: { paper: classes.assigmentDialogRoot },
        actions: { completeActionButton: classes.confirmButton },
      }}
      disableEscapeKeyDown
      keepMounted
      transitionDuration={200}
    >
      <Typography className={classes.infoText}>
        Operador <b>{currentAlert?.magnetOper.name}</b> armado en modular es
        distinto al operador pre-armado{" "}
        <b>{currentAlert?.assignmentOper.name}</b>.
      </Typography>

      <Typography className={clsx(classes.infoText, classes.extraText)}>
        <i>
          prearmado el{" "}
          {formatLocalizedDate(
            currentAlert?.assignmentCreatedAt,
            "dd 'de' MMMM 'a las' HH:mm' hrs.'"
          )}{" "}
          por {currentAlert?.user.name ?? currentAlert?.user.email}
        </i>
      </Typography>
    </CustomDialog>
  );
};

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === "dark";
  return {
    assigmentDialogRoot: {
      backgroundColor: isDarkMode
        ? theme.palette.error.dark
        : theme.palette.error.main,
      color: theme.palette.common.white,
    },
    confirmButton: {
      backgroundColor: "#22232F",
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: alpha("#22232F", 0.9),
      },
    },
    infoText: {
      color: theme.palette.common.white,
      marginBottom: 8,
      "&:last-child": {
        marginBottom: 0,
      },
    },
    extraText: {
      marginTop: 20,
      textAlign: "right",
    },
  };
});
