import React, { useMemo } from "react";
import {
  MetricGroupType,
  ToolChangeEvent,
  MetricsPoint,
  ChangeType,
  ModularChangeEvent,
} from "interfaces";
import { ModularChangesTooltipContent } from "./ModularChangesTooltipContent";
import { ToolChangesTooltipContent } from "./ToolChangesTooltipContent";

interface Props {
  point: MetricsPoint;
  groupType: MetricGroupType;
}

export const LineGraphChangesTooltipContent: React.FC<Props> = ({
  point,
  groupType,
}) => {
  const { x, changeEvents } = point.data;

  const { modularChangeEvents, toolChangeEvents } = useMemo(() => {
    const modularChangeEvents: ModularChangeEvent[] = [];
    const toolChangeEvents: ToolChangeEvent[] = [];
    changeEvents.forEach((event) => {
      if (event.type === ChangeType.MODULAR) modularChangeEvents.push(event);
      else if (event.type === ChangeType.TOOL) toolChangeEvents.push(event);
    });
    return { modularChangeEvents, toolChangeEvents };
  }, [changeEvents]);

  return (
    <>
      <ModularChangesTooltipContent
        groupType={groupType}
        x={x}
        events={modularChangeEvents}
      />
      <ToolChangesTooltipContent
        serieColor={point.serieColor}
        x={x}
        events={toolChangeEvents}
      />
    </>
  );
};
