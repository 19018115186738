import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const OperatorIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="60px"
      height="58px"
      viewBox="0 0 60 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_424_37463"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="11"
        y="3"
        width="38"
        height="49"
      >
        <path
          d="M48.2832 38.065C48.6848 45.1107 40.9022 51.205 30.9004 51.6769C20.8985 52.1489 12.4649 46.8198 12.0634 39.7741C11.6618 32.7284 11.6655 3.18418 27.3917 3.18421C52.3368 2.00718 47.8817 31.0193 48.2832 38.065Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_424_37463)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.2042 25.3694C25.2042 25.3694 26.4276 24.4299 28.1055 24.2009L25.8008 29.1086L25.1663 27.275L25.2042 25.3694Z"
          fill="#30414F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.0837 21.5925C25.0837 21.5925 26.3515 24.0449 26.2409 24.8876C26.2409 24.8876 27.2344 22.9948 27.101 23.0245C26.9676 23.0531 25.0837 21.5925 25.0837 21.5925Z"
          fill="#212220"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6802 50.1937C29.137 46.8117 24.3508 28.1977 24.3508 28.1977C26.0818 26.9868 26.5655 22.6979 26.5655 22.6979C30.1706 22.0367 32.5599 25.1019 34.2258 26.4618C35.3071 27.3509 35.8971 28.1385 35.8971 28.1385C37.6346 32.75 37.6802 50.1937 37.6802 50.1937Z"
          fill="#E1AB7B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.0381 16.2606L38.8396 19.4136L38.1824 15.9705L39.0381 16.2606Z"
          fill="#3F292A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.4641 27.6797C42.4641 27.6797 44.4218 32.007 44.9218 37.9903C45.4207 43.9736 49.939 60.2785 49.939 60.2785L43.9619 60.686L40.5195 49.7083L42.4641 27.6797Z"
          fill="#30414F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6802 50.1937V52.0914L37.2214 52.7477L28.534 34.8975C29.701 36.3827 31.1435 38.0001 32.3116 39.2051C37.1433 44.2163 37.6802 50.1937 37.6802 50.1937Z"
          fill="#3E4053"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.8361 19.8804C38.3469 21.2156 38.3925 22.1679 38.3925 22.1679C38.207 24.3509 37.7873 25.8134 37.3459 26.7884C37.2993 26.9364 37.2288 27.0627 37.1593 27.1901C36.9956 27.572 36.8101 27.8473 36.6702 28.0595V28.0802C36.5075 28.2924 36.3904 28.4611 36.321 28.5677C36.2743 28.6309 36.2038 28.6733 36.0878 28.7157L35.9945 28.7374C35.0867 28.9279 32.5944 28.4611 31.0109 27.868C29.1715 27.1693 28.0305 25.3683 28.0305 25.3683C26.0273 22.8252 26.8668 21.8087 23.9786 18.4396C24.6998 10.3237 31.3135 11.0017 31.3135 11.0017C39.1387 11.0017 38.8361 19.8804 38.8361 19.8804Z"
          fill="#F4BE8A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.0381 16.2607C38.6704 16.0751 38.2051 16.2064 37.8711 16.4334C37.536 16.6663 37.2811 16.9821 36.946 17.2149C36.0414 17.8465 34.7627 17.7577 33.6554 17.513C32.4092 17.2386 30.4028 16.6722 29.2097 17.358C28.9082 17.3995 28.7705 17.7222 28.7303 17.9896C28.6002 18.8492 28.7705 19.7206 28.6262 20.5791C28.482 21.3972 28.5275 21.8413 28.5275 21.8413L27.6165 21.5048L25.6751 22.8825C25.6751 22.8825 23.8595 19.7028 23.5515 18.4258C23.2435 17.1488 23.6426 14.3511 25.6621 12.478C27.6751 10.5981 30.291 10.5566 31.3995 10.5566C34.9796 10.5566 38.2051 12.1435 38.9134 15.5679C38.9329 15.6636 38.9849 15.932 39.0381 16.2607Z"
          fill="#212220"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6272 21.5149C27.4862 21.0807 27.3647 20.6425 27.2866 20.1955C27.1847 19.6103 26.8322 18.6056 25.6099 18.8109C23.905 19.0981 25.6533 22.3715 26.2628 22.6596C26.8734 22.9488 27.6272 21.5149 27.6272 21.5149Z"
          fill="#F4BE8A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6358 28.7691C35.6412 28.9546 35.6412 29.1332 35.6358 29.3119C33.9309 29.6583 31.2227 28.8767 29.9375 27.8385C28.6132 26.7766 28.0297 25.3694 28.0297 25.3694C28.0297 25.3694 29.1837 27.1704 31.0188 27.8681C32.4092 28.4059 34.5404 28.8293 35.6358 28.7691Z"
          fill="#D59E73"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.1416 48.3671L22.1221 48.302C22.1156 47.4957 22.0624 46.7141 21.9388 45.9217C21.8141 45.1332 21.6698 44.3466 21.4724 43.5651C21.2501 42.7894 21.0007 42.0196 20.6861 41.2677C20.3976 40.5581 20.0571 39.8535 19.6959 39.1676L19.6373 39.0541L19.6634 39.0364L19.7285 39.1499L19.7415 39.1676C20.1352 39.8357 20.5093 40.5167 20.8369 41.2144C21.1839 41.9604 21.4724 42.7233 21.7219 43.5049C21.9648 44.2805 22.1742 45.074 22.2598 45.8861L22.2663 46.0223C22.3314 46.7921 22.3119 47.6102 22.1416 48.3671Z"
          fill="#24222D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.3774 61.665C43.0043 62.8305 41.9328 63.9328 40.256 64.8229C40.1172 64.9068 39.9545 64.971 39.7908 65.0341C38.9763 65.4368 38.0674 65.7545 37.113 65.9884C32.8745 67.0473 27.6577 66.6664 24.1415 64.8229C22.7674 64.0808 21.7891 63.2124 21.2772 62.2798C21.0679 61.8772 20.9279 61.4953 20.8585 61.0936C20.7425 60.4157 20.7653 59.5255 20.9746 58.7419C21.2544 56.2837 20.1124 42.8072 18.2263 40.0095C17.6916 39.2259 17.0864 38.4206 16.4574 37.6371C16.0376 37.1496 15.6179 36.6621 15.1526 36.1746L14.9661 36.2811C15.2459 35.9209 16.7361 34.3735 18.5994 32.6574C19.2751 32.0426 19.9963 31.4288 20.7425 30.7932C21.1839 30.4538 21.6264 30.0936 22.0689 29.7541C22.3021 29.5843 22.5353 29.4156 22.7446 29.2458L22.7674 29.2241C23.4431 28.7376 25.1664 27.2751 25.1664 27.2751L25.5157 28.6735C25.6784 29.9031 26.0515 31.0893 26.6566 32.1916C27.5883 33.9709 30.3366 37.1703 32.3159 39.2042C37.1357 44.2056 37.6715 50.2017 37.6715 50.2017C37.6715 50.2017 37.6249 34.035 36.0881 28.7159C36.2041 28.6735 36.2746 28.631 36.3212 28.5679C36.3906 28.4613 36.5078 28.2925 36.6705 28.0804V28.0596C36.8332 27.8258 37.0197 27.509 37.229 27.0629C37.2757 26.9781 37.2995 26.8942 37.3462 26.7886L40.1866 27.2751L42.4458 27.6777L42.4686 27.7202C42.679 28.0172 43.8894 30.2199 43.0043 39.0137C42.9338 40.0312 42.679 41.1759 42.0966 42.3404C42.0966 42.3404 42.2365 49.9688 43.0271 54.6307C43.3308 56.411 43.4707 58.3176 43.5401 60.012V60.0969C43.5629 60.3308 43.4935 61.3897 43.3774 61.665Z"
          fill="#E2832D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.3306 47.8774L42.4575 50.1344C42.4575 51.6186 41.3871 53.1018 39.2212 54.2456C37.0781 55.3687 34.2376 55.941 31.4199 55.941C28.6022 55.941 25.7606 55.3687 23.6186 54.2456C21.4527 53.1018 20.3583 51.6186 20.3583 50.1344V48.4567C20.8703 49.3883 21.8485 50.2567 23.2227 50.9989C26.7389 52.8423 31.9546 53.2242 36.1931 52.1643C37.1486 51.9314 38.0563 51.6137 38.8709 51.211C39.0346 51.1469 39.1973 51.0837 39.3372 50.9989C41.014 50.1087 41.9586 49.0429 42.3306 47.8774Z"
          fill="#DFDAFB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4238 42.2879C22.4238 42.2879 22.4238 42.3836 22.4173 42.5563C22.3848 43.3981 22.2861 46.0646 22.1223 48.3018C22.0497 49.34 21.964 50.2884 21.8664 50.915C21.5128 53.164 20.4965 58.3362 20.0692 62.9783C19.6701 67.4103 19.1712 72.4689 19.1712 73.1972L19.1788 73.2742C19.1788 73.6679 18.8892 74.0617 18.3198 74.3597C17.5064 74.79 16.3188 74.9153 15.2971 74.7298C14.8774 74.6587 14.4902 74.5334 14.1562 74.3597C13.5792 74.0617 13.2907 73.663 13.2907 73.2692V55.7644L14.2549 37.9921C14.2549 37.8787 14.2614 37.7652 14.2668 37.6467C14.3329 37.0734 14.5553 36.6263 14.9034 36.3283C14.988 36.2503 15.0737 36.1852 15.1724 36.1309C15.7093 35.8092 16.4446 35.749 17.296 35.9938C17.6311 36.0895 17.9782 36.2267 18.3393 36.4171C20.5942 37.6053 22.4238 40.2293 22.4238 42.2879Z"
          fill="#475D6E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.4961 32.7558L35.3388 32.443L39.4113 32.5999L40.292 47.5143L39.2291 50.8153L37.3897 51.2633L32.9332 47.0899L29.6426 43.1297L24.9519 35.4894V32.8022L27.4659 31.2598L31.0157 33.3213L33.4961 32.7558Z"
          fill="#E2832D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4876 27.1684C26.2653 29.2714 28.4355 31.0961 33.4961 32.756L32.3432 36.5001C32.3432 36.5001 25.4247 33.6155 23.5766 28.5934L25.2046 25.3694C25.2046 25.3694 25.3846 26.8921 25.4876 27.1684Z"
          fill="#475D6E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5674 66.2191L37.3375 66.1836L37.3397 65.9704C37.4622 65.3043 36.9774 61.7931 36.4254 57.9512C35.6673 52.6765 34.7226 46.1129 34.9504 43.6537C35.3376 39.4892 33.5133 32.823 33.496 32.7558L33.7476 32.7104C33.7649 32.7776 35.5707 39.4734 35.1825 43.6714C34.9569 46.109 35.8994 52.6597 36.6564 57.9226C37.2356 61.9431 37.6976 65.3092 37.5696 66.005L37.5674 66.2191Z"
          fill="#C97720"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.3004 35.3298L35.3384 32.4432C35.641 32.0899 36.1573 30.6866 35.9946 28.7376L36.0879 28.7159C36.2039 28.6734 36.2744 28.631 36.3211 28.5678C36.3905 28.4613 36.5076 28.2925 36.6703 28.0803V28.0596C36.8102 27.8474 36.9957 27.5721 37.1594 27.1902C37.6475 28.5886 38.6442 32.2705 37.3004 35.3298Z"
          fill="#30414F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.4485 38.3355H25.1199C25.1199 36.5769 23.4193 34.1828 21.3944 33.0805C20.812 32.7627 19.927 32.5298 19.042 32.593C18.9487 32.6147 18.786 32.6354 18.5995 32.6571C19.2752 32.0423 19.9964 31.4285 20.7426 30.793C20.9747 30.793 21.184 30.793 21.3478 30.8354C21.7892 30.9203 22.2089 31.0466 22.6048 31.2588C25.3292 32.742 27.4485 35.8565 27.4485 38.3355Z"
          fill="#DFDAFB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.4528 38.3337L28.6177 54.227C27.8259 54.1422 27.0342 54.0366 26.288 53.8668L25.1243 38.3337H27.4528Z"
          fill="#DFDAFB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.5139 32.7206V33.7804H39.1853V32.7206C39.1853 31.1524 38.7656 28.3981 37.2288 27.0629C37.2754 26.9781 37.2993 26.8932 37.3459 26.7886L40.1864 27.2751C41.1408 29.0129 41.5139 31.1524 41.5139 32.7206Z"
          fill="#DFDAFB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.5083 32.7175V51.004C40.7166 50.9191 39.9248 52.0786 39.1797 51.9089V32.7175H41.5083Z"
          fill="#DFDAFB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6507 74.0164C13.6518 74.0154 13.6529 74.0134 13.6529 74.0114C13.411 73.7815 13.2906 73.5239 13.2906 73.2693V69.0377C13.2896 69.0357 13.2896 69.0337 13.2885 69.0318V73.2733C13.2885 73.5279 13.4089 73.7854 13.6507 74.0164ZM13.2906 66.8893V55.7645L14.2548 37.9922C14.2548 37.8787 14.2613 37.7652 14.2668 37.6468C14.2982 37.3754 14.3644 37.1327 14.4631 36.9195C14.3633 37.1336 14.296 37.3774 14.2646 37.6508C14.2592 37.7702 14.2527 37.8837 14.2527 37.9971L13.2885 55.7694V66.8784C13.2896 66.8824 13.2896 66.8863 13.2906 66.8893ZM14.8882 36.3382L14.8947 36.3353C14.898 36.3333 14.9001 36.3303 14.9034 36.3284C14.9305 36.3027 14.9576 36.28 14.9858 36.2573C15.1268 36.0807 15.4912 35.6869 16.0107 35.158C15.3817 35.7895 14.9706 36.2297 14.8882 36.3382Z"
          fill="#2B2B44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9857 36.2573C15.0443 36.2099 15.105 36.1675 15.1722 36.131C15.4868 35.9425 15.8696 35.8438 16.3045 35.8438C16.6115 35.8438 16.9433 35.8922 17.2958 35.9938C17.2969 35.9938 17.2969 35.9938 17.2969 35.9938C18.0724 34.9616 19.2459 33.7843 20.415 32.7135C20.0777 32.6316 19.7112 32.5833 19.3381 32.5833C19.2394 32.5833 19.1407 32.5862 19.042 32.5931C18.9541 32.6129 18.8055 32.6336 18.6331 32.6533C17.6006 33.5938 16.682 34.4849 16.0106 35.158C15.4911 35.6869 15.1267 36.0807 14.9857 36.2573Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2948 74.7298C15.3306 73.5002 15.3598 72.0011 15.387 70.3185C14.9087 70.2198 14.4618 70.062 14.0909 69.8448C13.6755 69.606 13.4087 69.3277 13.2905 69.0376V73.2692C13.2905 73.5238 13.4109 73.7814 13.6527 74.0114C13.785 74.1377 13.9521 74.2551 14.156 74.3597C14.4889 74.5334 14.8761 74.6587 15.2948 74.7298ZM15.4151 68.3507C15.5659 57.5684 15.6657 41.3682 16.4477 37.6507C16.5008 37.3882 16.5605 37.1859 16.6255 37.0546C16.7828 36.7319 17.0062 36.3737 17.2936 35.9977C17.2947 35.9967 17.2958 35.9957 17.2969 35.9938C17.2969 35.9938 17.2969 35.9938 17.2958 35.9938C16.9433 35.8921 16.6114 35.8438 16.3045 35.8438C15.8696 35.8438 15.4867 35.9424 15.1722 36.1309C15.105 36.1674 15.0442 36.2099 14.9857 36.2572C14.9575 36.2799 14.9303 36.3026 14.9032 36.3283C14.9 36.3303 14.8978 36.3332 14.8946 36.3352L14.9011 36.3322C14.7189 36.4882 14.5714 36.6855 14.4629 36.9194C14.3642 37.1326 14.298 37.3753 14.2666 37.6467C14.2612 37.7652 14.2547 37.8786 14.2547 37.9921L13.2905 55.7644V66.8892C13.3556 67.1991 13.5692 67.5119 13.9705 67.7843L14.1278 67.878C14.4965 68.0932 14.9412 68.2521 15.4151 68.3507Z"
          fill="#5A7387"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.1209 29.7165C22.237 29.6317 22.353 29.5468 22.4658 29.4619C22.3519 29.5448 22.237 29.6297 22.1209 29.7165Z"
          fill="#2B2B44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2585 31.0972C22.6706 30.7488 23.0481 30.4389 23.3647 30.1824C23.2021 29.6327 23.1359 29.1797 23.1164 28.9893C23.0047 29.0662 22.8865 29.1501 22.7747 29.233L22.7487 29.2567C22.6544 29.3248 22.5611 29.3929 22.4656 29.462C22.3528 29.5468 22.2368 29.6317 22.1207 29.7166C22.1045 29.7284 22.0893 29.7403 22.0741 29.7521C21.6392 30.0837 21.2021 30.434 20.7694 30.7932C20.9906 30.7932 21.1902 30.7942 21.3474 30.8357C21.6631 30.8959 21.9667 30.9778 22.2585 31.0972Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4148 32.7134C21.0525 32.1292 21.6891 31.5775 22.2585 31.0969C21.9668 30.9775 21.6631 30.8956 21.3475 30.8354C21.1902 30.794 20.9907 30.793 20.7694 30.793C20.033 31.4028 19.3085 32.0384 18.6328 32.6532C18.8053 32.6335 18.9539 32.6127 19.0417 32.593C19.1404 32.5861 19.2391 32.5831 19.3378 32.5831C19.7109 32.5831 20.0775 32.6315 20.4148 32.7134Z"
          fill="#DCD6FB"
        />
        <g clipPath="url(#clip0_424_37463)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.4893 9.71952C26.4893 9.71952 24.1471 10.4067 23.4099 12.4466C23.4099 12.4466 22.3809 14.5581 23.8269 16.4858C25.2731 18.4145 28.0224 19.6355 28.0224 19.6355C28.0224 19.6355 29.7558 20.3822 31.9047 20.7722L34.6663 21.4161C34.6663 21.4161 36.1279 21.6412 37.4568 21.0992C38.7855 20.5562 39.7025 19.8179 39.7025 19.8179C39.7025 19.8179 41.7095 18.3937 40.7692 16.5229C39.8287 14.6513 37.8733 12.512 37.1102 11.9531L26.4893 9.71952Z"
            fill="#DE892F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.3851 9.73556C26.3851 9.73556 24.0787 10.4097 23.3496 12.4035C23.3496 12.4035 22.6169 14.2072 24.0382 16.0896C25.4583 17.9714 28.1641 19.1608 28.1641 19.1608C28.1641 19.1608 29.8695 19.8882 31.9843 20.2659L34.7021 20.8925C34.7021 20.8925 36.1397 21.1118 37.4493 20.5797C38.7579 20.0477 39.6615 19.3261 39.6615 19.3261C39.6615 19.3261 41.6397 17.9315 40.7153 16.1051C39.7918 14.2778 37.3215 12.4716 36.573 11.9262L26.3851 9.73556Z"
            fill="#EB9B3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.5191 12.9879C23.7257 11.7341 24.4528 10.2614 25.5966 9.25966C25.9415 8.77103 27.1993 7.58934 28.9975 7.52071L30.8149 7.25656C30.8149 7.25656 32.6569 6.67402 35.4625 8.1784C38.2678 9.68186 39.0136 13.3539 39.0136 13.3539C39.0136 13.3539 39.4029 15.5082 38.8781 16.8656C38.8781 16.8656 38.3625 18.952 34.9006 19.5421C31.4398 20.1321 28.3307 18.8611 28.3307 18.8611C24.1786 17.0308 23.2721 14.4842 23.5191 12.9879Z"
            fill="#EBAB3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.3315 11.2505C26.0655 12.9972 30.8855 15.9037 34.3605 17.3433L24.3315 11.2505Z"
            fill="#DE892F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.8619 9.30521C25.8619 9.30521 27.192 8.1837 29.3624 8.92266C31.5325 9.65978 32.9977 10.6022 32.9977 10.6022C32.9977 10.6022 35.584 13.0491 36.5739 15.0438C36.5739 15.0438 36.6276 12.9866 34.661 10.8355C32.4427 8.40908 28.9224 7.77494 28.9224 7.77494C28.9224 7.77494 26.953 8.03164 25.8619 9.30521Z"
            fill="url(#paint0_linear_424_37463)"
            fillOpacity="0.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.9512 7.42554C34.0143 7.49241 37.0969 9.24785 38.4512 13.4495C38.4512 13.4495 38.0096 10.8809 36.4684 9.37185C35.2543 8.18197 33.212 7.12003 30.9512 7.42554Z"
            fill="#FBBB4D"
          />
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.8103 9.39897C25.8103 9.39897 26.9161 8.52283 29.0865 9.26179C31.2566 9.99891 32.8617 11.1995 32.8617 11.1995C32.8617 11.1995 35.6337 13.275 36.6235 15.2696C36.6235 15.2696 36.5341 14.1725 34.5676 12.0214C32.601 9.87039 29.2264 8.76548 29.2264 8.76548C29.2264 8.76548 26.9014 8.1254 25.8103 9.39897Z"
              fill="#DE892F"
              fillOpacity="0.4"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_424_37463"
          x1="30.8352"
          y1="8.54008"
          x2="34.6609"
          y2="15.8089"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBBB4D" />
          <stop offset="1" stopColor="#FBBB4D" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_424_37463">
          <rect
            width="18.1074"
            height="15.5415"
            fill="white"
            transform="translate(21.5256 8.36353) rotate(-10.2447)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
