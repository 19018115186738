import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Typography,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { AppRoute } from "utils";
import {
  ChartIcon,
  BalanceIcon,
  DataBaseIcon,
  OperatorHelmetIcon,
  TruckIcon,
  MetricsIcon,
  DailyPlanIcon,
} from "components/icons";
import clsx from "clsx";
import {
  ChevronLeft,
  ChevronRight,
  ExitToApp,
  Info,
  LocationOnRounded,
  SettingsOutlined,
} from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { ExcavatorProvider, UserContext } from "contexts";
import { Module, Can } from "interfaces";
import { DrawerItemData, MenuDrawerItem } from "./MenuDrawerItem";
import { groupBy } from "lodash";
import { LegalTextDialog } from "components/LegalTextDialog";
import { SicRegistryDialog } from "screens/TA/TruckAssignmentBalance/SicRegistryDialog";
import { SicIcon } from "components/icons/SicIcon";
import { PerformanceIcon } from "components/icons/PerformanceIcon";
import { RelieveMenuIcon } from "components/icons/RelieveMenuIcon";
import { VelocityIcon } from "components/icons/VelocityIcon";
import { ShiftChangeIcon } from "components/icons/ShiftChangeIcon";
import { ShiftChangeOperatorIcon } from "components/icons/ShiftChangeOperatorsIcon";


const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

type Props = {
  open: boolean;
  onClose: () => void;
  onItemClick: (route: AppRoute) => void;
};

export const MenuDrawer: React.FC<Props> = ({ open, onClose, onItemClick }) => {
  //Hooks
  const { pathname } = useLocation();
  const { logout, isAllowedTo } = useContext(UserContext);
  const theme = useTheme();
  
  const [openedLegalTextDialog, setOpenedLegalTextDialog] =
  useState<boolean>(false);
  const [widthDimension, setWidthDimension] = useState<number>(
    window.innerWidth
  )
  const classes = useStyles({openedLegalTextDialog});
  //SIC
  const [sicDialogOpened, setSicDialogOpen] = useState(false);
  const openSicDialog = useCallback(() => setSicDialogOpen(true), []);
  const closeSicDialog = useCallback(() => setSicDialogOpen(false), []);
  
  const moduleItems: Array<DrawerItemData> = useMemo(
    () => [
      {
        text: "Monitor Cambio de Turno",
        icon: <ShiftChangeOperatorIcon />,
        route: AppRoute.SC_SHIFT_CHANGE_OPERATOR_SCREEN,
        module: Module.SHIFT_CHANGE,
      },
      {
        text: "Cambio de Turno",
        icon: <ShiftChangeIcon />,
        route: AppRoute.SC_SHIFT_CHANGE_NOT_OPERATOR_SCREEN,
        module: Module.OPERATOR_ASSIGNMENT,
      },
      {
        text: "Armado",
        icon: <TruckIcon />,
        route: AppRoute.TRUCKS_DASHBOARD,
        module: Module.OPERATOR_ASSIGNMENT,
      },
      {
        text: "Relevo",
        icon: <RelieveMenuIcon />,
        route: AppRoute.RELIEVE,
        module: Module.OPERATOR_ASSIGNMENT,
      },
      {
        text: "Operadores",
        icon: <OperatorHelmetIcon />,
        route: AppRoute.OPERATORS_SCREEN,
        module: Module.OPERATOR_ASSIGNMENT,
      },
      {
        text: "Camión Perdido",
        icon: <LocationOnRounded />,
        route: AppRoute.UNPLANNED_LOCATIONS_SCREEN,
        module: Module.OPERATOR_ASSIGNMENT,
      },

      {
        text: "Asignación",
        icon: <BalanceIcon className={classes.balanceSICIcon} />,
        route: AppRoute.TA_BALANCE_SCREEN,
        module: Module.TRUCK_ASSIGNMENT,
      },
      {
        text: "Registro SIC",
        icon: <SicIcon />,
        route: AppRoute.SIC,
        module: Module.TRUCK_ASSIGNMENT,
        isModal: true
      },
      {
        text: "Métricas",
        icon: <MetricsIcon />,
        route: AppRoute.TA_METRICS_SCREEN,
        module: Module.TRUCK_ASSIGNMENT,
      },
      {
        text: "Rendimiento",
        icon: <PerformanceIcon />,
        route: AppRoute.TA_PERFORMANCE_SCREEN,
        module: Module.TRUCK_ASSIGNMENT,
      },
      {
        text: "Velocidad",
        icon: <VelocityIcon style={{height:"1.1em", width:"1.1em"}} />,
        route: AppRoute.TA_VELOCITY_SCREEN,
        module: Module.TRUCK_ASSIGNMENT,
      },
      {
        text: "Plan Diario",
        icon: <DailyPlanIcon />,
        route: AppRoute.TA_DAILY_PLAN_SCREEN,
        module: Module.TRUCK_ASSIGNMENT,
      },
      {
        text: "Prácticas Operacionales",
        icon: <ChartIcon />,
        route: AppRoute.PERFORMANCE_RATINGS,
        module: Module.PERFORMANCE_RATINGS,
      },
    ],
    [classes.balanceSICIcon]
  );

  const allowedDrawerItems: DrawerItemData[][] = useMemo(
    () =>
      Object.values(
        groupBy(
          moduleItems.filter(({ module: mod }) => isAllowedTo(Can.READ, mod)),
          "module"
        )
      ),
    [isAllowedTo, moduleItems]
  );

  const bottomDrawerItems = useMemo(
    () => [
      {
        text: "Panel de administración",
        icon: <SettingsOutlined />,
        route: AppRoute.ADMIN,
        allowed: isAllowedTo(Can.READ, Module.ADMINISTRATION_PANEL),
        module: Module.ADMINISTRATION_PANEL,
      },
      {
        text: "Fuentes de datos",
        icon: <DataBaseIcon />,
        route: AppRoute.SOURCE_INFO_STATUS,
        allowed: true,
        module: Module.ADMINISTRATION_PANEL,
      },
    ],
    [isAllowedTo]
  );
  
  const hasOpenModal = useMemo(() => openedLegalTextDialog || sicDialogOpened
  ,[openedLegalTextDialog, sicDialogOpened])

  useEffect(() => {if(hasOpenModal) {onClose()}} ,[hasOpenModal, onClose])

  const openLegalTextDialog = useCallback(() => {
    setOpenedLegalTextDialog(true);
  }, [setOpenedLegalTextDialog]);

  const closeLegalTextDialog = useCallback(() => {
    setOpenedLegalTextDialog(false);
  }, [setOpenedLegalTextDialog]);

  return (
    <>
      <Drawer
        className={clsx(classes.root, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
        onClose={onClose}
        variant="permanent"
      >
        <div className={classes.toolbar}>
          <IconButton className={classes.icon} onClick={onClose}>
            {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <List className={classes.listRoot}>
          {allowedDrawerItems.map((moduleRoutes, i) => (
            <React.Fragment key={i}>
              {moduleRoutes.map((item) => (
                <MenuDrawerItem
                  key={item.route}
                  isActive={
                    item.isModal && sicDialogOpened ? sicDialogOpened :
                    (item.route === pathname && !hasOpenModal )
                  }
                  item={item}
                  onClick={onItemClick}
                  isModal={item.isModal}
                  openModal={openSicDialog}
                />
              ))}
              <Divider className={classes.moduleDivider} />
            </React.Fragment>
          ))}
        </List>
        <List className={classes.bottomListRoot}>
          {bottomDrawerItems.map(
            (item) =>
              item.allowed && (
                <MenuDrawerItem
                  key={item.route}
                  isActive={item.route === pathname && !hasOpenModal}
                  item={item}
                  onClick={onItemClick}
                />
              )
          )}
          <ListItem
            className={classes.item}
            button
            key={"legal-info-button"}
            onClick={openLegalTextDialog}
          >
            <ListItemIcon className={classes.iconLegalText}>
              <Info />
            </ListItemIcon>
            <ListItemText
              className={classes.iconLegalText}
              primary="Información legal"
            />
          </ListItem>
          <ListItem
            className={classes.item}
            button
            key={"logout-button"}
            onClick={logout}
          >
            <ListItemIcon className={classes.icon}>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText className={classes.icon} primary="Cerrar Sesión" />
          </ListItem>
        </List>
        <Typography className={classes.versionText} variant="caption">
          {`v${REACT_APP_VERSION} `}
        </Typography>
      </Drawer>
      <LegalTextDialog
        open={openedLegalTextDialog}
        onClose={closeLegalTextDialog}
      />
      <ExcavatorProvider>
        <SicRegistryDialog open={sicDialogOpened} onClose={closeSicDialog} />
      </ExcavatorProvider>
    </>
  );
};

interface StyleProps {
  openedLegalTextDialog: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowX: "hidden",
  },
  drawerOpen: {
    width: 270,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.grey[100],
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: 270
    },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    [theme.breakpoints.down("sm")]: {
      display: 'none',
      width: 0
    },
    backgroundColor: theme.palette.grey[100],
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  listRoot: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  bottomListRoot: {
    display: "flex",
    flexDirection: "column",
  },
  item: {
    paddingLeft: 24,
  },
  icon: {
    color: "#727272",
  },
  versionText: {
    textAlign: "center",
    opacity: 0.5,
    marginBottom: 5,
    color: theme.palette.getContrastText(theme.palette.grey[100]),
  },
  balanceSICIcon: {
    width: "inherit",
    height: "inherit",
  },
  moduleDivider: {
    "&:last-child": {
      display: "none",
    },
  },
  iconLegalText : {
    color: ({ openedLegalTextDialog }) => (openedLegalTextDialog ? "#F77F00" : "#727272"),
  }
}));
