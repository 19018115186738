import React, {
  SVGAttributes,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  FormControlLabel,
  FormGroup,
  makeStyles,
  Paper,
  Switch,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { AxisProps, AxisTickProps } from "@nivo/axes";
import { Layer, Line, Point, Serie } from "@nivo/line";
import { AutoSizer, CustomMesh, TriangleIcon } from "components";
import {
  PracticeEnum,
  PerformanceRatingOperatorLast6Shifts,
  Evaluation,
  PointTypeEnum,
} from "interfaces";
import { animated } from "@react-spring/web";
import grey from "@material-ui/core/colors/grey";
import { ClassNameMap } from "@material-ui/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import clsx from "clsx";
import { LineGraphTooltip } from ".";
import {
  GraphDataType,
  PerformanceRatingActionType,
  graphDataTypeTranslation,
} from "reducers";
import { UserContext, PerformanceRatingsContext } from "contexts";
import { map, max } from "lodash";
import { patternDotsDef } from "@nivo/core";
import { CustomSymbol } from "./CustomSymbol";
import { format, parse } from "date-fns";

interface Props {
  data: PerformanceRatingOperatorLast6Shifts;
  evaluations: Evaluation[];
  onEvaluationClick: (id: number) => void;
}

interface CustomPoint extends Omit<Point, "data"> {
  data: Point["data"] & { id: number };
}

type WeeklyShiftSummary = Record<string, { count: number; maxValue: number }>;

export const PerformanceRatingsLineGraph: React.FC<Props> = ({
  data,
  evaluations,
  onEvaluationClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { menuState, dispatch } = useContext(PerformanceRatingsContext);
  const { user } = useContext(UserContext);

  const evaluationsIconDistance = 0.1; // TO-DO: ajustar con la coni

  const [displayEvaluations, setDisplayEvaluations] = useState<boolean>(
    Boolean(user?.isOperationalSupervisor)
  );

  const { series, maxYValue, maxYEvalsValue } = useMemo<{
    series: Serie[];
    maxYValue: number;
    maxYEvalsValue: number | null;
  }>(() => {
    if (Object.keys(data).length === 0) {
      return { series: [], maxYValue: 0, maxYEvalsValue: 0 };
    }
    // TODO: Case when operator latests 6 shifts changes, summaryByDate gets undefined
    const summaryByDate: WeeklyShiftSummary = Object.values(data).reduce(
      (prev, array) => {
        return array.reduce((p, c) => {
          const newValue: WeeklyShiftSummary = {
            ...p,
            [c.weeklyShiftStartDate]: {
              count: (p[c.weeklyShiftStartDate]?.count ?? 0) + c.eventsCount,
              maxValue:
                max([
                  p[c.weeklyShiftStartDate]?.maxValue ?? 0,
                  menuState.graphDataType === GraphDataType.AVG
                    ? c.meanDuration
                    : c.eventsCount * c.meanDuration,
                ]) ?? 0,
            },
          };
          return newValue;
        }, prev);
      },
      {} as WeeklyShiftSummary
    );

    const series = Object.entries(data).map(([key, value]) => ({
      id: key,
      data: value.map((event) => ({
        x: `${event.weeklyShiftStartDate} ${event.weeklyShiftEndDate} ${
          summaryByDate[event.weeklyShiftStartDate]?.count ?? 0
        }`,
        y:
          menuState.graphDataType === GraphDataType.AVG
            ? event.meanDuration
            : event.eventsCount * event.meanDuration,
      })),
    }));
    const maxYValue = max(map(Object.values(summaryByDate), "maxValue")) ?? 0;

    const evalsWeeklyCounter: Record<string, number> = {};
    const evaluationsSerie = {
      id: PointTypeEnum.EVALUATION,
      data: evaluations.map((e) => {
        const verticalGap =
          maxYValue *
          evaluationsIconDistance *
          (evalsWeeklyCounter[e.weeklyShiftStartDate] ?? 0);

        evalsWeeklyCounter[e.weeklyShiftStartDate] =
          (evalsWeeklyCounter[e.weeklyShiftStartDate] ?? 0) + 1;

        return {
          id: e.id,
          x: `${e.weeklyShiftStartDate} ${e.weeklyShiftEndDate} ${
            summaryByDate[e.weeklyShiftStartDate]?.count ?? 0
          }`,
          y:
            (summaryByDate[e.weeklyShiftStartDate]?.maxValue ?? 0) +
            maxYValue * evaluationsIconDistance +
            verticalGap,
          type: PointTypeEnum.EVALUATION,
        };
      }),
    };
    if (displayEvaluations) {
      series.push(evaluationsSerie);
    }
    const maxYEvalsValue = max(map(evaluationsSerie.data, "y")) ?? null;

    return { series, maxYValue, maxYEvalsValue };
  }, [data, displayEvaluations, menuState.graphDataType, evaluations]);

  const bottomAxisProps: AxisProps = useMemo(
    () => ({
      tickSize: 0,
      tickPadding: 16,
      renderTick: (props) => renderBottomAxis({ ...props, theme, classes }),
    }),
    [classes, theme]
  );

  const layers: Layer[] = [
    "grid",
    "markers",
    "axes",
    "areas",
    "crosshair",
    "lines",
    "points",
    CustomMesh,
    "legends",
  ];

  const onDataTypeChange = useCallback(
    (_e: React.MouseEvent<HTMLElement>, option: GraphDataType | null) => {
      dispatch({
        type: PerformanceRatingActionType.GRAPH_DATA_TYPE_CHANGE,
        option,
      });
    },
    [dispatch]
  );

  const onEvaluationPointClick = useCallback(
    (p: Point) => {
      if (p.serieId === PointTypeEnum.EVALUATION) {
        onEvaluationClick((p as unknown as CustomPoint).data.id);
      }
    },
    [onEvaluationClick]
  );

  return (
    <Paper elevation={1} className={classes.graphContainer}>
      <div className={classes.graphHeader}>
        <Typography className={classes.title} variant="subtitle1">
          <b>minutos</b>
        </Typography>
        <FormGroup className={classes.toggleEvaluations}>
          <FormControlLabel
            control={
              <Switch
                onClick={() => setDisplayEvaluations((prev) => !prev)}
                size="small"
                classes={{
                  switchBase: classes.switchBase,
                  track: classes.track,
                  checked: classes.checked,
                }}
                checked={displayEvaluations}
              />
            }
            label={
              <div className={classes.switchLabel}>
                <TriangleIcon color="inherit" />
                Evaluaciones
              </div>
            }
            labelPlacement="start"
          />
        </FormGroup>
        <ToggleButtonGroup
          className={classes.toggleButtonGroup}
          value={menuState.graphDataType}
          onChange={onDataTypeChange}
          size="small"
          exclusive
        >
          {Object.values(GraphDataType).map((option, x) => (
            <ToggleButton
              key={x}
              classes={{
                root: clsx(classes.toggleButton, classes.toggleButtonView),
                selected: classes.toggleButtonSelected,
              }}
              value={option}
            >
              {graphDataTypeTranslation[option]}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>

      <AutoSizer>
        {({ width, height }) => (
          <Line
            height={height}
            width={width}
            theme={theme.graph}
            enableArea
            layers={layers}
            onClick={onEvaluationPointClick}
            colors={({ id }) =>
              theme.palette.performanceRatingsColors[id as PracticeEnum]
            }
            data={series}
            margin={{ top: 10, bottom: 125, left: 62, right: 55 }}
            yScale={{
              type: "linear",
              min: 0,
              max: max([maxYEvalsValue, maxYValue]) ?? 0,
              stacked: false,
            }}
            xScale={{
              type: "point",
            }}
            axisLeft={leftAxisProps}
            axisBottom={bottomAxisProps}
            pointSize={8}
            tooltip={LineGraphTooltip}
            defs={[
              patternDotsDef("noArea", {
                background: "transparent",
                color: "transparent",
              }),
            ]}
            fill={[{ match: { id: PointTypeEnum.EVALUATION }, id: "noArea" }]}
            isInteractive
            useMesh
            enableSlices={false}
            pointSymbol={CustomSymbol}
          />
        )}
      </AutoSizer>
    </Paper>
  );
};

const leftAxisProps: AxisProps = {
  tickSize: 8.5,
  tickPadding: 5,
  tickRotation: 0,
};

interface RenderBottomAxisProps extends AxisTickProps<any> {
  theme: Theme;
  classes: ClassNameMap;
}

const renderBottomAxis = ({
  value,
  textAnchor,
  textBaseline,
  animatedProps,
  theme,
  classes,
}: RenderBottomAxisProps) => {
  const [startDateString, endDateString, eventCountString] = value.split(" ");
  const eventCount = +eventCountString;

  const range = `${format(
    parse(startDateString, "yyyy-MM-dd", new Date()),
    "dd/MM"
  )} al ${format(parse(endDateString, "yyyy-MM-dd", new Date()), "dd/MM")}`;

  const eventCounterColor =
    eventCount > 5
      ? theme.palette.error.main
      : eventCount >= 3
      ? theme.palette.warning.main
      : grey[300];

  return (
    <animated.g
      transform={animatedProps.transform}
      style={{
        opacity: animatedProps.opacity,
      }}
    >
      <animated.text
        dominantBaseline={
          textBaseline as SVGAttributes<SVGTextElement>["alignmentBaseline"]
        }
        textAnchor={textAnchor}
        transform={animatedProps.textTransform}
        style={theme.graph.axis?.ticks?.text}
      >
        <tspan x={0} dy={0}>
          TURNO
        </tspan>
        <tspan x={0} dy={20}>
          {range}
        </tspan>
      </animated.text>
      <animated.g
        transform="translate(-28, 60)"
        className={classes.chipContainer}
      >
        <animated.svg width="56" height="24" viewBox="0 0 58 24">
          <animated.rect
            x="0"
            y="0"
            width="56"
            height="24"
            fill={eventCounterColor}
            rx="12"
          />
          <animated.text
            className={classes.chipText}
            x="50%"
            y="50%"
            fill={theme.palette.getContrastText(eventCounterColor)}
          >
            {eventCount}
          </animated.text>
        </animated.svg>
      </animated.g>
    </animated.g>
  );
};

const useStyles = makeStyles(({ palette }) => {
  const toggleButtonBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;

  return {
    graphContainer: {
      flex: "1 1 auto",
      maxHeight: 540,
      padding: 24,
      display: "flex",
      flexDirection: "column",
    },
    graphHeader: {
      display: "flex",
      alignItems: "end",
      marginBottom: 10,
    },
    title: { lineHeight: 0.75 },
    chipContainer: {
      textAnchor: "middle",
      dominantBaseline: "middle",
    },
    chipText: {
      fontWidth: 400,
      fontSize: "1rem",
    },
    toggleEvaluations: {
      marginLeft: "auto",
    },
    toggleButtonGroup: {
      marginLeft: "auto",
      maxHeight: 30,
    },
    toggleButton: {
      padding: "8px 12px",
      "&$toggleButtonSelected": {
        backgroundColor: toggleButtonBackground,
        color: palette.getContrastText(toggleButtonBackground),
        "&:hover": {
          backgroundColor: toggleButtonBackground,
        },
      },
    },
    //!This most go in order to '&$toggleButtonSelected' works
    toggleButtonSelected: {},
    toggleButtonView: {
      width: "auto",
    },
    switchBase: {
      "&$checked": {
        color: palette.text.primary,
      },
      "&$checked + $track": {
        backgroundColor: palette.text.primary,
      },
    },
    checked: {},
    track: {},
    switchLabel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 4,
      color: palette.text.primary,
    },
  };
});
