import React, { useCallback, useContext, useEffect } from "react";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import {
  CrewEnum,
  ErrorMsg,
  OperatorPracticeOptions,
  Supervisor,
  getPracticesTranslation,
  ScreenType,
} from "interfaces";
import { PerformanceRatingActionType, DataViewOption } from "reducers";
import clsx from "clsx";
import { useData } from "hooks";
import { PerformanceRatingsContext } from "contexts";

export const PerformanceRatingSupervisorMenu: React.FC = () => {
  const classes = useStyles();

  const {
    menuState: menuOptions,
    dispatch,
    onSupervisorScreenPracticeChange,
    onViewOptionChange,
    onCrewChange,
  } = useContext(PerformanceRatingsContext);
  const theme = useTheme();

  const {
    data: supervisors,
    firstLoading,
    refetching,
  } = useData<Supervisor[]>(
    {
      config: {
        url: "/performance-ratings/supervisors",
        method: "GET",
        params: { crews: menuOptions.crews },
      },
    },
    ErrorMsg.GET_SUPERVISORS
  );

  const loadingSupervisor = firstLoading || refetching;

  const onSupervisorChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) =>
      dispatch({
        type: PerformanceRatingActionType.SUPERVISOR_CHANGE,
        selectedSupervisor: (e.target.value as number) || null,
      }),
    [dispatch]
  );

  useEffect(() => {
    if (!loadingSupervisor) {
      dispatch({
        type: PerformanceRatingActionType.CHECK_SUPERVISOR_OPTIONS,
        supervisors,
      });
    }
  }, [dispatch, loadingSupervisor, supervisors]);
  return (
    <Grid container className={classes.menuContainer}>
      <Grid className={classes.viewOptionContainer} item xs={12}>
        <ToggleButtonGroup
          value={menuOptions.viewOption}
          onChange={onViewOptionChange}
          size="small"
          exclusive
        >
          {Object.values(DataViewOption).map((option, x) => (
            <ToggleButton
              key={x}
              classes={{
                root: clsx(classes.toggleButton, classes.toggleButtonView),
                selected: classes.toggleButtonSelected,
              }}
              value={option}
            >
              {option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>
      <Grid className={classes.toggleCrewContainer} container>
        <Grid item xs={2}>
          <Typography variant="body1">Grupo</Typography>
        </Grid>
        <Grid className={classes.crewContainer} item xs={10}>
          <ToggleButtonGroup
            value={menuOptions.crews}
            onChange={onCrewChange}
            size="small"
          >
            {Object.values(CrewEnum).map((option, x) => (
              <ToggleButton
                key={x}
                classes={{
                  root: clsx(classes.toggleButton, classes.toggleButtonCrew),
                  selected: classes.toggleButtonSelected,
                }}
                value={option}
              >
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid className={classes.supervisorContainer} container>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="selected-supervisors-label">Supervisor</InputLabel>
          <Select
            className={classes.supervisorSelector}
            value={
              supervisors.find((s) => s.id === menuOptions.selectedSupervisor)
                ?.id ?? 0
            }
            onChange={onSupervisorChange}
            labelId="selected-supervisors-label"
            label="Supervisor"
          >
            <MenuItem value={0}>Todos</MenuItem>
            {supervisors.map(({ name, id }) => (
              <MenuItem key={`${name}-${id}`} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid container>
        <Divider className={classes.divider} />
      </Grid>
      <FormGroup className={classes.practicesContainer}>
        {(
          Object.keys(OperatorPracticeOptions) as OperatorPracticeOptions[]
        ).map((operPracticesKey) => (
          <FormControlLabel
            key={operPracticesKey}
            control={
              <Checkbox
                className={classes.practicesCheck}
                checked={menuOptions.operPractices[operPracticesKey]}
                onChange={onSupervisorScreenPracticeChange}
                name={operPracticesKey}
                style={{
                  color:
                    theme.palette.performanceRatingsColors[operPracticesKey],
                }}
              />
            }
            label={
              <Typography key={`${operPracticesKey}-label`} variant="body1">
                {getPracticesTranslation(
                  operPracticesKey,
                  ScreenType.SUPERVISOR,
                  true
                )}
              </Typography>
            }
          />
        ))}
      </FormGroup>
    </Grid>
  );
};

const useStyles = makeStyles(({ palette, breakpoints }) => {
  const toggleButtonBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;

  return {
    menuContainer: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      backgroundColor:
        palette.type === "light"
          ? palette.background.default
          : palette.background.paper,
      maxWidth: 356,
      padding: "24px 29px !important",
      alignItems: "center",
      marginRight: 24,
      borderRadius: 4,
      maxHeight: 500,
      alignContent: "start",
      [breakpoints.between("md", "lg")]: {
        width: 260,
        marginRight: 10,
        padding: "24px 14px !important",
      },
    },
    performanceContent: {
      display: "flex",
    },
    crewContainer: {
      display: "flex",
      justifyContent: "end",
      margin: "12px 0px",
      [breakpoints.between("md", "lg")]: {
        justifyContent: "start",
      },
    },
    viewOptionContainer: {
      display: "flex",
      margin: "0px 0px 12px 0px",
      alignItems: "center",
      justifyContent: "center",
    },
    toggleButton: {
      padding: "8px 12px",
      "&$toggleButtonSelected": {
        backgroundColor: toggleButtonBackground,
        color: palette.getContrastText(toggleButtonBackground),
        "&:hover": {
          backgroundColor: toggleButtonBackground,
        },
      },
    },
    //!This most go in order to '&$toggleButtonSelected' works
    toggleButtonSelected: {},
    toggleButtonView: {
      width: 150,
      [breakpoints.between("md", "lg")]: {
        width: 116,
        fontSize: 10,
      },
    },
    toggleButtonCrew: {
      width: 58.5,
      height: 35.5,
    },
    toggleCrewContainer: {
      alignItems: "center",
      margin: "12px 0px",
      [breakpoints.between("md", "lg")]: {
        display: "inline-block",
        margin: 0,
        justifyContent: "center",
      },
    },
    supervisorContainer: {
      width: "100%",
      margin: "12px 0px",
      [breakpoints.between("md", "lg")]: {
        margin: "5px 0px",
      },
    },
    supervisorSelector: {
      height: 40,
      [breakpoints.between("md", "lg")]: {
        width: "auto",
      },
    },
    divider: {
      width: "100%",
      margin: "12px 0px",
    },
    practicesContainer: {
      width: "100%",
      margin: "0px",
      marginBottom: "12px",
      [breakpoints.between("md", "lg")]: {
        width: 260,
      },
    },
    practicesCheck: {
      padding: 7,
    },
  };
});
