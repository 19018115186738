import { PhasesCard } from "./kpi.interface";

export interface Config {
	name: string;
	hasSpaceBefore?: boolean;
};

export interface TooltipValues {
	variableName: string | null;
	value: string | number;
	unit?: string;
};

export interface ColumnData {
	id: CycleTimeKpis;
	value: number | null;
	color: string;
	target: string | number | null;
	tooltip?: TooltipValues[]
	referencialPlan?: boolean;
};

export interface HeatMapData {
	id: string;
	destination?: string;
	data: ColumnData[]
};
export interface CycleTimeData {
	table: HeatMapData[];
	caex: PhasesCard;
}

export interface FiltersAutocomplete {
    id: number;
    name: string;
	label?: string
};

export enum CycleTimeKpis {
	QUEUE_TIME = "QUEUE_TIME",
	SPOT_TIME = "SPOT_TIME",
	LOADING_TIME = "LOADING_TIME",
	FULL_HAUL_DISTANCE = "FULL_HAUL_DISTANCE",
	IDLE_TIME = "IDLE_TIME",
	DUMPING_TIME = "DUMPING_TIME",
	CYCLE_TIME = "CYCLE_TIME",
	MOVEMENT = "MOVEMENT",
	IMPACT_TC = "IMPACT_TC",
  	ADHERENCE = "ADHERENCE",
};
export enum ColorStatus {
	BAD = "BAD",
	REGULAR = "REGULAR",
	GOOD = "GOOD",
	VERY_GOOD = "VERY_GOOD"
}

export const TooltiCycleTime: Record<CycleTimeKpis, string>={
	[CycleTimeKpis.QUEUE_TIME]: "Cola de Origen",
	[CycleTimeKpis.SPOT_TIME]: "Aculatamiento",
	[CycleTimeKpis.LOADING_TIME]: "Tiempo Carguío",
	[CycleTimeKpis.FULL_HAUL_DISTANCE]: "Cola Destino",
	[CycleTimeKpis.IDLE_TIME]: "Descarga Destino",
	[CycleTimeKpis.DUMPING_TIME]: "Viaje Lleno + Vacio",
	[CycleTimeKpis.CYCLE_TIME]: "Tiempo de Ciclo",
	[CycleTimeKpis.IMPACT_TC]: "Impacto al TC",
	[CycleTimeKpis.MOVEMENT]: "Movimiento",
	[CycleTimeKpis.ADHERENCE]: "Cumplimiento",
}

export const TooltipUnit: Record<CycleTimeKpis, string>={
	[CycleTimeKpis.QUEUE_TIME]: "",
	[CycleTimeKpis.SPOT_TIME]: "",
	[CycleTimeKpis.LOADING_TIME]: "",
	[CycleTimeKpis.FULL_HAUL_DISTANCE]: "",
	[CycleTimeKpis.IDLE_TIME]: "",
	[CycleTimeKpis.DUMPING_TIME]: "",
	[CycleTimeKpis.CYCLE_TIME]: "",
	[CycleTimeKpis.IMPACT_TC]: "",
	[CycleTimeKpis.MOVEMENT]: "kt",
	[CycleTimeKpis.ADHERENCE]: "%",
}

