import React, { useMemo, useContext, useState } from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { TruckAssignmentMetricsLineGraph } from "./TruckAssignmentMetricsLineGraph";
import { Menu } from "./Menu";
import {
  ChangeType,
  DisplayMetric,
  Metric,
  MetricDataWithEvents,
  MetricGroupType,
  metricsGroupTypeTranslation,
  metricsMapping,
  metricTranslation,
} from "interfaces";
import { MetricsFilters } from "./MetricsFilters";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { TruckAssignmentMetricsContext } from "contexts";
import { MetricsBarGraph } from "./MetricsBarGraph";
import { MetricsDoubleLineGraph } from "./MetricsDoubleLineGraph";
import { flatten, map } from "lodash";
import { Tooltip } from "components";
import { InfoOutlined } from "@material-ui/icons";

type InfoTooltipText = {
  section: string;
  text: string;
};

export const TruckAssignmentMetrics: React.FC = () => {
  // Hooks
  const classes = useStyles();

  const {
    menuState: state,
    dispatch,
    parsedMetrics,
    firstLoading,
    refetching,
    polling,
    data,
    pendingChanges,
  } = useContext(TruckAssignmentMetricsContext);

  const [seriesFilter, setSeriesFilter] = useState<Record<ChangeType, boolean>>(
    {
      [ChangeType.TOOL]: true,
      [ChangeType.MODULAR]: true,
    }
  );

  const loadingData = firstLoading || refetching || polling;

  const metricDataWithEvents = useMemo<MetricDataWithEvents[]>(
    () =>
      parsedMetrics[metricsMapping[state.selectedMetric]].map((e) => ({
        ...e,
        changeEvents: e.changeEvents.map((idx) => data.changeEvents[idx]),
      })),
    [parsedMetrics, data.changeEvents, state.selectedMetric]
  );

  const unassociatedChanges = useMemo(() => {
    const associatedEventsIndexes = new Set(
      flatten(
        map(parsedMetrics[metricsMapping[state.selectedMetric]], "changeEvents")
      )
    );
    return data.changeEvents.filter((_, i) => !associatedEventsIndexes.has(i));
  }, [data.changeEvents, parsedMetrics, state.selectedMetric]);

  const changeTypeCounter = useMemo<Record<ChangeType, number>>(
    () =>
      Object.values(ChangeType).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]:
            metricDataWithEvents.filter(({ changeEvents }) =>
              changeEvents.some(({ type }) => type === curr)
            ).length +
            unassociatedChanges.filter(({ type }) => type === curr).length,
        }),
        {} as Record<ChangeType, number>
      ),
    [unassociatedChanges, metricDataWithEvents]
  );

  const filteredData = useMemo<MetricDataWithEvents[]>(
    () =>
      metricDataWithEvents.map((e) => ({
        ...e,
        changeEvents: e.changeEvents.filter(({ type }) => seriesFilter[type]),
      })),
    [metricDataWithEvents, seriesFilter]
  );

  const filteredUnassociatedChanges = useMemo(
    () => unassociatedChanges.filter(({ type }) => seriesFilter[type]),
    [unassociatedChanges, seriesFilter]
  );

  const noDataAlert = useMemo(
    () =>
      (state.selectedEntity || state.groupType === MetricGroupType.MINE) &&
      !filteredData.length &&
      !pendingChanges &&
      !loadingData,
    [
      filteredData.length,
      loadingData,
      state.groupType,
      state.selectedEntity,
      pendingChanges,
    ]
  );

  const noDataMessage = useMemo(() => {
    const label = metricTranslation[state.selectedMetric];
    const groupType = metricsGroupTypeTranslation[state.groupType];
    const entity = state.selectedEntity || "";
    return `Actualmente no tenemos datos de ${label} para ${groupType} ${entity}`;
  }, [state]);

  const shownChangeTypes = useMemo<ChangeType[]>(
    () =>

      state.selectedMetric === DisplayMetric.LOADTONS || state.selectedMetric === DisplayMetric.CAEX
        ? []
        : state.groupType === MetricGroupType.VIRTUAL_REGION
        ? [ChangeType.MODULAR]
        : state.groupType === MetricGroupType.EXCAV
        ? [ChangeType.TOOL, ChangeType.MODULAR]
        : [],
    [state.groupType, state.selectedMetric]
  );
  const infoTooltipTexts = useMemo<InfoTooltipText[]>(
    () => [
      {
        section: "Asignación CAEX",
        text: `Se consideran los códigos 1000, 2000, 2010, 2011, 2012, 2020, 2040, 2060, 
        2090, 4010, 4020, 4030, 4050, 4080, 4100, 4110 y 4170 
        durante la hora observada para la estimación del conteo de camiones por pala`,
      },
    ],
    []
  );

  return (
    <>
      <div className={classes.contentHeader}>
        <Typography className={classes.sectionTitleTooltip} variant="h6">
          <strong>Métricas acumuladas</strong>
        </Typography>
        <Tooltip
          title={infoTooltipTexts.map(({ section, text }) => (
            <Typography key={text} variant="body1">
              <strong>{`${section}: `}</strong>
              {`${text}.`}
            </Typography>
          ))}
          arrow
          placement="right"
          maxWidth="620px"
        >
          <InfoOutlined className={classes.infoIconTooltip} />
        </Tooltip>
        <Typography className={classes.sectionTitle} variant="h6">
          <strong> | {data.currentShift}</strong>
        </Typography>
      </div>
      <Paper className={classes.performancePaper} elevation={3}>
        <Grid className={classes.performanceContainer} container>
          <Grid className={classes.menuContent} item xs={3}>
            <Menu dispatch={dispatch} />
          </Grid>
          <Grid item xs={9}>
            {shownChangeTypes.length > 0 ? (
              <MetricsFilters
                state={seriesFilter}
                shownChangeTypes={shownChangeTypes}
                changeTypeCounter={changeTypeCounter}
                onChange={setSeriesFilter}
              />
            ) : (
              <div className={classes.spacer}></div>
            )}
            {loadingData ? (
              <div className={classes.infoContainer}>
                <CircularProgress size={120} color="primary" />
              </div>
            ) : noDataAlert ? (
              <div className={classes.infoContainer}>
                <InfoOutlinedIcon
                  color="disabled"
                  className={classes.infoIcon}
                />
                <Typography color="textSecondary" variant="h5">
                  {noDataMessage}
                </Typography>
              </div>
            ) : state.selectedMetric === DisplayMetric.LOADTONS ? (
              <MetricsBarGraph
                movementData={filteredData}
                planData={parsedMetrics[Metric.MOVEMENT_TARGET_FROM_DAILY_PLAN]}
                menuState={state}
              />
            ) : state.selectedMetric === DisplayMetric.MINE_UTILIZATION ? (
              <MetricsDoubleLineGraph
                utilizationData={filteredData}
                targetData={parsedMetrics[Metric.UTILIZATION_TARGET]}
                menuState={state}
              />
            ) : state.selectedMetric === DisplayMetric.CAEX ? (
                //TUB2: CAEX
                <MetricsBarGraph
                  movementData={filteredData}
                  planData={parsedMetrics[Metric.CAEX_DAILY_PLAN]}
                  menuState={state}
                />
            ) : (
              <TruckAssignmentMetricsLineGraph
                data={filteredData}
                menuState={state}
                unassociatedChanges={filteredUnassociatedChanges}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const useStyles = makeStyles(() => {
  return {
    contentHeader: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: 23,
      marginBottom: 23,
    },
    sectionTitle: {
      lineHeight: "24px",
      paddingRight: "20px",
    },
    sectionTitleTooltip: {
      lineHeight: "24px",
      paddingRight: "10px",
    },
    performancePaper: {
      padding: 15,
    },
    menuContent: {
      paddingRight: "20px",
    },
    spacer: {
      height: 38,
    },
    performanceContainer: {
      display: "flex",
      height: 500,
    },
    infoContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      flex: "1",
      paddingBottom: 24,
      paddingTop: 24,
      alignItems: "center",
      justifyContent: "center",
    },
    infoIcon: {
      width: "4em",
      height: "4em",
      marginBottom: 15,
    },
    infoIconTooltip: {
      lineHeight: "24px",
      marginRight: 10,
      width: 20,
      height: 20,
    },
  };
});
