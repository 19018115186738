import React, {
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useReducer,
  useState,
} from "react";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  CloseOnClick,
  CustomDialog,
  CustomDialogActionProps,
} from "components";
import { User, CrewEnum, Role, ErrorMsg } from "interfaces";
import { useData } from "hooks";
import { logError } from "services";
import { UserContext } from "contexts";
import {
  DialogActions,
  UserEditPermissionSwitches,
  UserEditNotificationSwitches,
} from ".";
import {
  userInfoReducer,
  initialUserInfoState,
  UserActionType,
} from "reducers";
import { useSnackbar } from "notistack";
import { WarningRounded } from "@material-ui/icons";

interface UserEditDialogProps {
  open: boolean;
  onClose: () => void;
  action: DialogActions;
  user: User | null;
  refetchUsers: () => void;
}

type UpdateUserRequestBody = Pick<
  User,
  "email" | "crews" | "permissions" | "notifications"
> & { roleId: User["role"]["id"] };

export const UserEditDialog: React.FC<UserEditDialogProps> = ({
  open,
  action,
  onClose,
  user,
  refetchUsers,
}) => {
  const classes = useStyles();
  const [userEditableInfo, dispatch] = useReducer(
    userInfoReducer,
    initialUserInfoState
  );

  const { enqueueSnackbar } = useSnackbar();

  const { user: actualUser } = useContext(UserContext);

  const [notValidEmail, setNotValidEmail] = useState(false);

  const { refetch: createUser } = useData<unknown, UpdateUserRequestBody>(
    {
      config: {
        url: "/administration/users",
        method: "POST",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.CREATE_USER
  );

  const { refetch: updateUser } = useData<unknown, UpdateUserRequestBody>(
    {
      config: {
        method: "PATCH",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.UPDATE_USER
  );

  const { data: roles } = useData<Role[]>(
    {
      config: "/administration/users/roles",
    },
    ErrorMsg.GET_USERS_ROLES
  );

  const handleOnComplete = useCallback(async () => {
    try {
      const roleId = userEditableInfo.role.id;
      const { id, role, updatedBy, ...parseUserInfo } = userEditableInfo;
      const payload: UpdateUserRequestBody = { ...parseUserInfo, roleId };
      if (action === DialogActions.ADD) {
        await createUser({ data: payload });
        enqueueSnackbar(`Usuario creado con éxito`, { variant: "success" });
      } else {
        await updateUser({
          url: `/administration/users/${id ?? ""}`,
          data: payload,
        });
        enqueueSnackbar(`Usuario actualizado con éxito`, {
          variant: "success",
        });
      }
      refetchUsers();
      dispatch({
        type: UserActionType.RESET,
        payload: initialUserInfoState,
      });
    } catch (error) {
      logError("UserEditionDialog", error);
      refetchUsers();
    }
  }, [
    action,
    refetchUsers,
    enqueueSnackbar,
    createUser,
    updateUser,
    userEditableInfo,
  ]);

  const validateEmail = useCallback(
    (e: string) =>
      setNotValidEmail(e !== "" && !Boolean(e.match(/(@bhp.com)(?!.*\1)+$/gi))),
    []
  );

  const handleOnCancel = useCallback(() => {
    dispatch({
      type: UserActionType.RESET,
      payload: initialUserInfoState,
    });
    setNotValidEmail(false);
    refetchUsers();
  }, [refetchUsers]);

  const handleEmailOnBlur = useCallback(
    () => validateEmail(userEditableInfo?.email),
    [userEditableInfo, validateEmail]
  );

  const missingInputs = useMemo(
    () =>
      !userEditableInfo.name ||
      !userEditableInfo.email ||
      !userEditableInfo.role.id,
    [userEditableInfo]
  );

  const missingPermissions = useMemo(
    () =>
      Object.values(userEditableInfo.permissions).every(
        (permission) => !permission.CAN_READ
      ),
    [userEditableInfo]
  );

  const onCancelDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "CANCELAR",
      onClick: handleOnCancel,
      closeOnClick: CloseOnClick.BEFORE,
    }),
    [handleOnCancel]
  );

  const onCompleteDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "GUARDAR",
      onClick: handleOnComplete,
      closeOnClick: CloseOnClick.BEFORE,
      disabled: !(!missingInputs && !notValidEmail),
    }),
    [handleOnComplete, missingInputs, notValidEmail]
  );

  useEffect(() => {
    if (user) {
      dispatch({
        type: UserActionType.INIT,
        payload: user,
      });
    }
  }, [user]);

  return (
    <CustomDialog
      classes={{
        dialog: { paper: classes.dialogPaper },
      }}
      open={open}
      onClose={onClose}
      title={`${action} usuario`}
      onCancelInfo={onCancelDialogProp}
      onCompleteInfo={onCompleteDialogProp}
    >
      <Grid container spacing={4} className={classes.inputsContainer}>
        <Grid item className={classes.gridItemSubtitle} xs={12}>
          <Typography variant="body1">
            <strong>Datos</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            required
            label="Nombre"
            variant="outlined"
            value={userEditableInfo?.name}
            onChange={(e) =>
              dispatch({
                type: UserActionType.NAME_CHANGE,
                name: e.target.value,
              })
            }
          />
        </Grid>
        <Grid className={classes.gridItemInputs} item xs={6}>
          <form noValidate>
            <TextField
              disabled={actualUser?.id === userEditableInfo.id}
              required
              error={notValidEmail}
              className={classes.formControl}
              label="Correo electrónico"
              variant="outlined"
              value={userEditableInfo?.email}
              onChange={(e) => {
                dispatch({
                  type: UserActionType.EMAIL_CHANGE,
                  email: e.target.value,
                });
              }}
              onBlur={handleEmailOnBlur}
              helperText={notValidEmail ? "Debe terminar en '@bhp.com'" : ""}
            />
          </form>
        </Grid>
        <Grid className={classes.gridItemInputs} item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-outlined-label">Rol</InputLabel>
            <Select
              required
              disabled={actualUser?.id === userEditableInfo.id}
              labelId="select-outlined-label"
              value={userEditableInfo?.role.id}
              onChange={(e) =>
                dispatch({
                  type: UserActionType.ROL_CHANGE,
                  role: roles.find(
                    (role) => role.id === e.target.value
                  ) as Role,
                })
              }
              label="Rol"
            >
              {roles.map((role, idx) => (
                <MenuItem key={idx} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container className={classes.gridItemCrewSelectors} item>
          {Object.values(CrewEnum).map((crew) => (
            <FormControlLabel
              key={`${crew}-label`}
              className={classes.switchLabel}
              control={
                <Switch
                  color="primary"
                  checked={userEditableInfo.crews[crew]}
                  onChange={(e) =>
                    dispatch({
                      type: UserActionType.CREW_CHANGE,
                      crew,
                      value: e.target.checked,
                    })
                  }
                  name={crew}
                />
              }
              label={`Turno ${crew}`}
              labelPlacement="start"
            />
          ))}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={4} className={classes.switchesContainer}>
        <UserEditPermissionSwitches
          dispatch={dispatch}
          userEditableInfo={userEditableInfo}
        />
        <UserEditNotificationSwitches
          dispatch={dispatch}
          userEditableInfo={userEditableInfo}
        />
        {missingPermissions && (
          <Grid className={classes.missingPermissions} item xs={12}>
            <WarningRounded className={classes.warningIcon} />
            <Typography variant="body2">
              Este usuario no tendrá acceso a la aplicación
            </Typography>
          </Grid>
        )}
      </Grid>
    </CustomDialog>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  dialogPaper: {
    display: "flex",
    minHeight: 340,
    justifyContent: "center",
    maxWidth: 644,
  },
  autoCompleteRoot: {
    width: "100%",
    marginBottom: 25,
  },
  gridItem: {
    marginBottom: 7,
  },
  gridItemInputs: {
    paddingTop: "0px !important",
    minHeight: 95,
  },
  gridItemSubtitle: {
    marginBottom: 8,
    paddingBottom: "0px !important",
  },
  gridItemCrewSelectors: {
    justifyContent: "space-between",
    paddingTop: "8px !important",
    paddingBottom: "24px !important",
  },
  inputsContainer: {
    margin: 0,
    width: "100%",
  },
  switchesContainer: {
    margin: 0,
    width: "100%",
    minHeight: 270,
  },
  formControl: {
    width: "100%",
    minWidth: 262,
  },
  switchLabel: {
    marginLeft: 0,
  },
  missingPermissions: {
    display: "flex",
    alignItems: "center",
    padding: "0px !important",
    paddingLeft: "16px !important",
    color: palette.warning.main,
  },
  gridPermissionsSwitchesContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    height: 28,
  },
  warningIcon: {
    marginRight: 5,
    marginBottom: 8,
  },
}));
