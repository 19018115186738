import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const BhpLogo: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 91 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.152 29.6951C21.262 31.2317 18.337 32 14.378 32H0V0H14.244C16.119 0 17.742 0.2014 19.111 0.6042C20.48 1.007 21.597 1.5814 22.46 2.3273C23.323 3.0732 23.963 3.9534 24.38 4.9678C24.797 5.9823 25.005 7.1161 25.005 8.3692V8.5483C25.005 10.3683 24.558 11.8527 23.665 13.0014C22.772 14.1501 21.418 14.9632 19.602 15.4406C21.567 15.7688 23.122 16.5371 24.268 17.7455C25.414 18.9538 25.987 20.6769 25.987 22.9147V23.0937C25.987 25.958 25.042 28.1585 23.152 29.6951ZM8.93 12.7105H12.413C13.842 12.7105 14.854 12.4494 15.449 11.9273C16.045 11.4051 16.342 10.5772 16.342 9.4434V9.2643C16.342 8.1604 16.037 7.3548 15.427 6.8476C14.817 6.3403 13.812 6.0867 12.413 6.0867H8.93V12.7105ZM12.726 25.8685H8.93V18.3049H12.458C14.065 18.3049 15.219 18.6107 15.918 19.2224C16.618 19.834 16.968 20.7515 16.968 21.9748V22.1538C16.968 23.3473 16.625 24.2648 15.941 24.9063C15.256 25.5478 14.184 25.8685 12.726 25.8685ZM39.874 19.379V32H30.676V0H39.874V12.2182H50.367V0H59.521V32H50.367V19.379H39.874ZM75.149 32V21.214H78.587C82.576 21.214 85.642 20.3338 87.785 18.5734C89.928 16.8131 91 14.2471 91 10.8755V10.6965C91 7.2951 89.958 4.662 87.874 2.7972C85.791 0.9324 82.561 0 78.185 0H66.04V32H75.149ZM75.149 14.9483H77.783C79.301 14.9483 80.432 14.6126 81.177 13.9413C81.921 13.2699 82.293 12.2629 82.293 10.9203V10.7413C82.293 9.3688 81.906 8.3841 81.132 7.7874C80.358 7.1907 79.257 6.8923 77.828 6.8923H75.149V14.9483Z"
      />
    </SvgIcon>
  );
};
