import { Theme, alpha } from "@material-ui/core";
import { LoadStatus } from "interfaces";

interface LoadStatusColorsProps {
    theme: Theme;
    statusType: LoadStatus | null;
    color?: boolean;
    opacityColor?: boolean;
}

export const loadStatusColor = ({
    theme,
    statusType,
    color,
    opacityColor,
  }: LoadStatusColorsProps): string => {
    if (color) {
      return statusType === LoadStatus.UP
        ? theme.palette.success.main
        : statusType === LoadStatus.DOWN
        ? theme.palette.error.main
        : "";
    }
    if (opacityColor) {
      return statusType === LoadStatus.UP
        ? alpha(theme.palette.success.main, 0.1)
        : statusType === LoadStatus.DOWN
        ? alpha(theme.palette.error.main, 0.1)
        : "";
    }
    return "";
  };