import "regenerator-runtime/runtime.js";
import { useEffect, useRef } from "react";
import throttle from "lodash/throttle";

export const useThrottledCallback = (
  callback: (...args: any[]) => any,
  throttleTime: number
) => {
  const executableCallback = useRef(callback);
  useEffect(() => {
    executableCallback.current = callback;
  });
  const throttleRef = useRef(
    throttle(
      (...args) => {
        executableCallback.current(...args);
      },
      throttleTime,
      { leading: false }
    )
  );
  return throttleRef.current;
};
