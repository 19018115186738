import { STANDARD_POLLING } from "App";
import { ContextProviderProps } from "contexts";
import { useData } from "hooks";
import { DataContextInterface, ErrorMsg, initialDataContextState } from "interfaces";
import { CycleTimeData } from "interfaces/heatMap.interface";
import React, { createContext, useMemo, useReducer } from "react";
import { TruckPerformaceAction, TruckPerformanceState, initialPerformanceState, truckPerformaceReducer } from "reducers/truck-performace";


interface TruckPerformaceInterface extends DataContextInterface<CycleTimeData> {
  state: TruckPerformanceState;
  dispatch: React.Dispatch<TruckPerformaceAction>;
}

const initialState: CycleTimeData = {
  table: [],
  caex: {
		currentShift:"",
		lastUpdate: "",
		data: []},
};

export const TruckPerformanceContext = createContext<TruckPerformaceInterface>({
  state: initialPerformanceState,
  dispatch: () => null,
  ...initialDataContextState,
  data: initialState,
});

export const TruckPerformanceProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    truckPerformaceReducer,
    initialPerformanceState
  );

  const truckPerformanceParams = useMemo<TruckPerformanceState>(
    () => ({
      period: state.period,
      phase: state.phase,
    }),
    [state]
  );

  const {
    data,
    polling,
    firstLoading,
    refetching,
    ...metricsOther
  } = useData<CycleTimeData>(
    {
      config: {
        url: "/truck-assignment-performance/cycle_time",
        method: "GET",
        params: truckPerformanceParams,
      },
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GENERIC_GET,
    initialState
  );

	const contextState = useMemo<TruckPerformaceInterface>(
    () => ({
      state,
      dispatch,
      data,
      polling,
      firstLoading,
      refetching,
      ...metricsOther,

    }),
    [
      data,
      firstLoading,
			state,
      metricsOther,
      polling,
      refetching,
    ]
  );

  return (
    <TruckPerformanceContext.Provider value={contextState}>
      {children}
    </TruckPerformanceContext.Provider>
  );
};
