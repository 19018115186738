import { Grid, Link, Typography, makeStyles, useTheme } from "@material-ui/core";
import { StopTruckDarkIcon } from "components/icons/StopTruckDarkIcon";
import { StopTruckIcon } from "components/icons/StopTruckIcon";
import React, { useMemo } from "react";

export const WithoutProgramming = () => {
  const classes = useStyles();
  const theme = useTheme();
  
  const Icon = useMemo(() => {
    return theme.palette.type === "dark" ? StopTruckDarkIcon : StopTruckIcon;
  }, [theme.palette.type]); 

  return (
    <Grid container alignContent="center" style={{ height: 500 }}>
      <Grid container className={classes.infoContainer}>
        <Grid container style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <Icon className={classes.infoIcon} />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography
            color="textPrimary"
            variant="h6"
            style={{ fontWeight: 700, marginBottom: 20 }}
          >
            Generando la propuesta de relevos para este comedor...
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography color="textPrimary" variant="subtitle1">
            Mientras tanto, puedes comenzar a programar otro comedor o regresar
            más tarde. Si el procesamiento excede los 15 minutos,
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography color="textPrimary" variant="subtitle1">
            {`contacta a tu equipo de soporte interno o `}
            <Link
              className={classes.link}
              href="https://bhp.service-now.com/sp?id=sc_cat_item&sys_id=4b63e79adb6a43004b46fc1dbf9619ec"
              target={"_blank"}
            >
              crea un ticket en Service Now.
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  infoIcon: {
    width: 187,
    height: 200,
    fill: theme.palette.kpi.background,
  },
  link: {
    color: "#0099CC",
  },
}));
