import { Theme, alpha, makeStyles, useTheme } from "@material-ui/core";
import { BarCustomLayerProps, BarDatum, ResponsiveBar } from "@nivo/bar";
import React, { SVGAttributes } from "react";
import { useAnimatedPath } from "@nivo/core";
import { animated } from "@react-spring/web";
import { line } from "d3-shape";
import { AxisTickProps } from "@nivo/axes";

export interface TooltipProps {
  data: BarDatum;
}

export interface Point {
  x: number;
  y: number;
}

export interface LinesProps {
  line1: Point[];
  line2: Point[];
}

interface Props {
  barsKeys: string[];
  data: BarDatum[];
  Tooltip: ({ data }: TooltipProps) => React.JSX.Element;
  linesData: LinesProps;
  maxValue: number;
}

interface TargetLayerProps extends BarCustomLayerProps<BarDatum> {
  trendline: { x: number; y: number }[];
  position: number;
  color: string;
}

interface PointProps {
  cx: number;
  cy: number;
  r: number;
  fill: string;
}

interface CircleProps {
  circleAnimations: PointProps[];
}

interface RenderAxisProps extends AxisTickProps<any> {
  theme: Theme;
  show: boolean;
}

export const GroupedBarGraph: React.FC<Props> = ({
  data,
  Tooltip,
  linesData,
  barsKeys,
  maxValue
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const Points = ({ circleAnimations }: CircleProps) => {
    return (
      <g>
        {circleAnimations.map((item, index) => (
          <circle
            key={index}
            cx={item.cx}
            cy={item.cy}
            r={6}
            fill={item.fill}
            style={{ pointerEvents: "none" }}
          />
        ))}
      </g>
    );
  };

  const Lines: React.FC<TargetLayerProps> = ({
    yScale,
    xScale,
    trendline,
    position,
    color,
  }) => {
    const lineGenerator = line<BarDatum>()
      .x((data) => {
        return xScale(data.x as number) + position;
      })
      .y((data) => yScale((data.y as number) ?? 0));

    const point = trendline.map(({ x, y }) => ({
      cx: xScale(x as number) + position,
      cy: yScale(y as number),
      r: 6,
      fill: color,
    }));

    const animatedPath = useAnimatedPath(lineGenerator(trendline) ?? "");
    return (
      <g>
        <animated.path
          d={animatedPath}
          fill="none"
          stroke={color}
          strokeDasharray={"10,10"}
          style={{ pointerEvents: "none", strokeWidth: 4 }}
        />
        <Points circleAnimations={point} />
      </g>
    );
  };

  const renderBottomAxis = ({
    value,
    textAnchor,
    textBaseline,
    animatedProps,
    theme,
    show,
  }: RenderAxisProps) => {
    return show ? (
      <animated.g
        transform={animatedProps.transform}
        style={{
          opacity: animatedProps.opacity,
        }}
      >
        <animated.text
          dominantBaseline={
            textBaseline as SVGAttributes<SVGTextElement>["alignmentBaseline"]
          }
          textAnchor={textAnchor}
          transform={animatedProps.textTransform}
          style={theme.graph.axis?.ticks?.text}
        >
          T{value}
        </animated.text>
      </animated.g>
    ) : (
      <></>
    );
  };

  return (
    <div className={classes.graphContainer}>
      <ResponsiveBar
        theme={theme.graph}
        margin={{ top: 25, bottom: 55, right: 45, left: 65 }}
        groupMode="grouped"
        padding={0.5}
        maxValue={maxValue}
        data={data}
        indexBy="id"
        valueScale={{ type: "linear"}}
        indexScale={{ type: "band", round: true }}
        keys={barsKeys}
        enableLabel={false}
        layers={[
          "grid",
          "axes",
          "bars",
          (props) => (
            <>
              {linesData?.line1?.length && (
                <Lines
                  {...props}
                  trendline={linesData?.line1}
                  position={props?.bars[0]?.width / 2}
                  color={theme.palette.graphColor.orange}
                />
              )}
              {linesData?.line2?.length && (
                <Lines
                  {...props}
                  trendline={linesData?.line2}
                  position={(props?.bars[0]?.width / 2) * 3}
                  color={theme.palette.graphColor.blue}
                />
              )}
            </>
          ),
        ]}
        colors={[
          alpha(theme.palette.graphColor.orange, 0.5),
          alpha(theme.palette.graphColor.blue, 0.5),
        ]}
        axisBottom={{
          tickPadding: 15,
          tickRotation: 0,
          renderTick: (props) =>
            renderBottomAxis({
              ...props,
              value: props.value,
              show: true,
              theme,
            }),
        }}
        tooltip={({ data }) => <Tooltip data={data} />}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    height: "506px",
  },
}));
