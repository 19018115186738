import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import {
  CloseOnClick,
  CustomDialog,
  CustomDialogActionProps,
} from "components";
import { useData } from "hooks";
import {
  Can,
  ErrorMsg,
  Evaluation,
  EvaluationRequestBody,
  Module,
} from "interfaces";
import { PerformanceRatingState } from "reducers";
import { useSnackbar } from "notistack";
import { formatLocalizedDate } from "utils";
import { UserContext } from "contexts";

interface AddOperatorDialogProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
  evaluation: Evaluation | null;
  operator: PerformanceRatingState["selectedOperator"];
}

export const OperatorEvaluationDialog: React.FC<AddOperatorDialogProps> = ({
  open,
  onClose,
  onComplete,
  evaluation,
  operator,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, isAllowedTo } = useContext(UserContext);
  const userValidationPermissions =
    !user?.isOperationalSupervisor ||
    !isAllowedTo(Can.WRITE, Module.PERFORMANCE_RATINGS);

  // Hooks
  const [currentEvaluationContent, setcurrentEvaluationContent] =
    useState<string>("");

  const { refetch: updateEvaluation } = useData<unknown, EvaluationRequestBody>(
    {
      config: {
        method: "PATCH",
        url: `/performance-ratings/operators/evaluations/${evaluation?.id}`,
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.UPDATE_EVALUATIONS
  );

  const { refetch: createEvaluation } = useData<unknown, EvaluationRequestBody>(
    {
      config: {
        method: "POST",
        url: `/performance-ratings/operators/${operator?.id}/evaluations`,
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.UPDATE_EVALUATIONS
  );

  const onEvaluationChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setcurrentEvaluationContent(e.target.value),
    []
  );

  const handleOnComplete = useCallback(async () => {
    if (evaluation) {
      await updateEvaluation({ data: { content: currentEvaluationContent } });
    } else {
      await createEvaluation({ data: { content: currentEvaluationContent } });
    }
    enqueueSnackbar(
      `Evaluación ${evaluation ? "editada" : "creada"} con éxito`,
      { variant: "success" }
    );
    onComplete();
    setcurrentEvaluationContent("");
  }, [
    createEvaluation,
    currentEvaluationContent,
    updateEvaluation,
    evaluation,
    onComplete,
    enqueueSnackbar,
  ]);

  const onCancelDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "CANCELAR",
      onClick: () => setcurrentEvaluationContent(""),
      closeOnClick: CloseOnClick.AFTER,
    }),
    [setcurrentEvaluationContent]
  );

  const onCompleteDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: `${evaluation ? "Editar" : "Crear"} evaluación`,
      onClick: handleOnComplete,
      disabled: !currentEvaluationContent?.length || userValidationPermissions,
      closeOnClick: CloseOnClick.AFTER,
    }),
    [
      evaluation,
      handleOnComplete,
      currentEvaluationContent,
      userValidationPermissions,
    ]
  );

  useEffect(() => {
    if (evaluation) setcurrentEvaluationContent(evaluation.content);
  }, [evaluation]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={`Evalúa al operador: ${operator?.name}`}
      onCancelInfo={onCancelDialogProp}
      onCompleteInfo={onCompleteDialogProp}
    >
      <div className={classes.contentRoot}>
        <div className={classes.subtitles}>
          {evaluation && (
            <>
              <Typography variant="caption" color="secondary">
                {`Creado por ${
                  evaluation.createdBy.name || evaluation.createdBy.email
                } el día ${formatLocalizedDate(
                  evaluation.createdAt,
                  "dd-MM-yyyy 'a las' HH:mm"
                )}`}
              </Typography>
              <br />
              {evaluation.updatedBy && (
                <Typography variant="caption" color="secondary">
                  {`Actualizado por ${
                    evaluation.updatedBy.name || evaluation.updatedBy.email
                  } el día ${formatLocalizedDate(
                    evaluation.updatedAt,
                    "dd-MM-yyyy 'a las' HH:mm"
                  )}`}
                </Typography>
              )}
            </>
          )}
        </div>

        <TextField
          label={`${evaluation ? "Editar evaluación" : "Escribe aquí"} `}
          value={currentEvaluationContent}
          onChange={onEvaluationChange}
          variant="outlined"
          minRows={4}
          maxRows={4}
          multiline
          disabled={userValidationPermissions}
        />
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    contentRoot: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    subtitles: {
      marginBottom: 25,
      fontStyle: "italic",
    },
  };
});
