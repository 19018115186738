import { Shift } from "interfaces";
import { inRange } from "lodash";

/**
 * @returns Current shift data.
 */
export const useCurrentShift = (): Shift => {
  const currentHour = Number(
    new Date().toLocaleString("es-CL", {
      timeZone: "America/Santiago",
      hour: "numeric",
    })
  );

  return inRange(currentHour, 8, 20) ? Shift.DAY : Shift.NIGHT;
};
