import React from "react";
import { Card, CardContent, makeStyles, Theme } from "@material-ui/core";
import { CardAlertType } from "interfaces";
import { TruckCardTimeSectionSkeleton, TruckCardInfoSectionSkeleton } from ".";

interface TruckCardSkeletonProps {
  alertType: CardAlertType;
}

export const TruckCardSkeleton: React.FC<TruckCardSkeletonProps> = ({
  alertType,
}) => {
  const classes = useStyles({ alertType });

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.cardInfoRoot}>
          <TruckCardInfoSectionSkeleton alertType={alertType} />
          <TruckCardInfoSectionSkeleton alertType={alertType} />
        </div>
        <div className={classes.cardTimeRoot}>
          <TruckCardTimeSectionSkeleton alertType={alertType} />
        </div>
      </CardContent>
    </Card>
  );
};

interface StyleProps {
  alertType: CardAlertType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: {
    backgroundColor: theme.palette.background.default,
    maxWidth: 361,
    marginLeft: 16,
    marginRight: 15,
    marginBottom: 15,
    [theme.breakpoints.down(1441)]: {
      marginLeft: 7,
      marginRight: 7,
    },
  },
  cardContent: {
    display: "flex",
    padding: 4,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardInfoRoot: {
    maxWidth: 212,
    width: "100%",
  },
  cardTimeRoot: {
    maxWidth: 140,
    width: "100%",
    marginBottom: 4,
  },
}));
