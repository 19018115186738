import { Grid, Link, Typography, makeStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";

export const ErrorLoadData = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.infoContainer}>
      <Grid container style={{ textAlign: "center" }}>
        <Grid item xs={12}>
          <InfoOutlinedIcon color="disabled" className={classes.infoIcon} />
        </Grid>
      </Grid>
      <Grid xs={12} style={{textAlign: "center"}}>
        <Typography color="textSecondary" variant="h6">
          Las fuentes de datos están desactualizadas.
        </Typography>
      </Grid>

      <Typography
        color="textSecondary"
        variant="h6"
        style={{ paddingBottom: 20 }}
      >
        {`Contacta a tu equipo de soporte interno o `}
        <Link
          className={classes.link}
          href="https://bhp.service-now.com/sp?id=sc_cat_item&sys_id=4b63e79adb6a43004b46fc1dbf9619ec"
          target={"_blank"}
        >
          crea un ticket en Service Now.
        </Link>
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 200,
  },
  infoIcon: {
    width: 100,
    height: 100,
  },
  link: {
    color: "#0099CC",
  },
}));
