import React, {
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from "react";
import {
  AppBar,
  Button,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  Collapse,
  Slide,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomSnackbar,
  OperatorsTable,
  SimplerHeader,
  ScreenContainer,
  Restricted,
  CustomDialog,
} from "components";
import { AppRoute, parseTimeDelta } from "utils";
import {
  OperatorAssignmentContext,
  InfoSourceContext,
  TrucksDashboardContext,
  OperatorFilterByTruckContext,
  OperatorPreArmadoFilterByTruckContext
} from "contexts";
import { useData, useRefetchOperatorsWithSearch, useSearch } from "hooks";
import {
  Operator,
  OperatorStatusType,
  Can,
  Module,
  TruckSummary,
  ErrorMsg,
  TruckEditionRequestBody,
  Truck,
  OperatorAlertType,
} from "interfaces";
import { logDebug, logError } from "services";
import { useMsal } from "@azure/msal-react";
import { TruckDetailsCard } from "./TruckDetailsCard";
import Fuse from "fuse.js";
import { TruckDetailsCardSkeleton } from "./TruckDetailsCardSkeleton";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { STANDARD_POLLING } from "App";
import { useSnackbar } from "notistack";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import clsx from "clsx";

interface ActionsText {
  buttonText: string;
  snackText: { success: string; error: string };
}

interface AssignmentRequestBody {
  operId: Operator["id"];
  equipId: Truck["id"];
  reasonId: Truck["reasonId"];
}
interface RelayRequestBody {
  oper_id: Operator["id"];
}

const filterOptions: Fuse.IFuseOptions<Operator> = {
  keys: ["name"],
  threshold: 0.4,
};

interface TruckScreenProps {
  preAssemble?: boolean;
}


export const TruckScreen: React.FC<TruckScreenProps> = ({ preAssemble = false }) => {
  const [editingOperators, setEditMode] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const { setSelectedOperator, selectedOperator } = useContext(
    OperatorAssignmentContext
  );
  const { operatorsElapsedMinutes } = useContext(InfoSourceContext);
  const { refetch: refetchTrucksDashboard } = useContext(
    TrucksDashboardContext
  );

  const {
    data: operators,
    firstLoading: firstLoadingOperators,
    refetching: refetchingOperators,
    refetch: refetchOperators,
  } = useContext(OperatorFilterByTruckContext);

  const {
    data: operatorsPreAssembled,
    firstLoading: firstLoadingOperatorsPreAssembled,
    refetching: refetchingOperatorsPreAssembled,
    refetch: refetchOperatorsPreAssembled,
  } = useContext(OperatorPreArmadoFilterByTruckContext);

  const { equipId } = useParams<"equipId">();
  const { accounts } = useMsal();
  const navigate = useNavigate();

  const { firstLoading: updatingTruck, refetch: updateTruck } = useData<
    unknown,
    TruckEditionRequestBody
  >(
    {
      config: {
        url: `/operator-assignment/trucks/${equipId ?? ""}`,
        method: "PATCH",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.UPDATE_CAEX
  );

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down(1441));

  const { refetching: assignLoading, refetch: assignOperId } = useData<
    unknown,
    AssignmentRequestBody
  >(
    {
      config: {
        url: "/operator-assignment/assignments",
        method: "POST",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.ASSIGN_OPERATOR
  );

  const { refetching: assignLoadingPre, refetch: assignOperIdPre } = useData<
  unknown,
  AssignmentRequestBody
>(
  {
    config: {
      url: "/operator-assignment/assignments/pre-assignment",
      method: "POST",
    },
    options: {
      manual: true,
    },
  },
  ErrorMsg.ASSIGN_OPERATOR
);

  const { refetch: relay, refetching: refetchingRelay } = useData<
    unknown,
    RelayRequestBody
  >(
    {
      config: {
        url: `/operator-assignment/relay/${selectedOperator?.id}`,
        method: "DELETE",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.ASSIGN_OPERATOR
  );

  const {
    data: truck,
    firstLoading: loadingTruckData,
    refetch: refetchTruck,
  } = useData<TruckSummary | null>(
    {
      config: `/operator-assignment/trucks/${equipId ?? ""}`,
      ...STANDARD_POLLING,
      options: { useCache: false },
    },
    ErrorMsg.GET_CAEX,
    null
  );

  const withFooter = !truck?.currentOper && !editingOperators;
  const classes = useStyles({ withFooter });

  // Keep manually added, assigned, pre-assigned at this equip and qualificated operators.
  const qualificatedOperators = useMemo<Operator[]>(
    () =>
      operators?.filter(
        (oper) =>
          !truck ||
          [OperatorStatusType.MANUAL, OperatorStatusType.ASSEMBLED].includes(
            oper.status
          ) ||
          oper.id === truck.preassembledOper?.id ||
          oper.qualifs?.models?.find(
            (modelType) => modelType.model === truck.model
          )
      ) ?? [],
    [truck, operators]
  );

  const qualificatedOperatorsPreAssembled = useMemo<Operator[]>(
    () =>
      operatorsPreAssembled?.filter(
        (oper) =>
          !truck ||
          [OperatorStatusType.MANUAL, OperatorStatusType.ASSEMBLED].includes(
            oper.status
          ) ||
          oper.id === truck.preassembledOper?.id ||
          oper.qualifs?.models?.find(
            (modelType) => modelType.model === truck.model
          )
      ) ?? [],
    [truck, operatorsPreAssembled]
  );

  const [searchValue, setSearchValue, , searchResults, isTyping] =
    useSearch<Operator>(qualificatedOperators, filterOptions);

  const [searchPreArmValue, setSearchPreArmValue, ,searchPreArmResults, isTypingPre] =
    useSearch<Operator>(qualificatedOperatorsPreAssembled, filterOptions);

  // Wrap the function to distinguish the reason to refetch and apply some logic.
  const [refetchOperatorsWrapper] = useRefetchOperatorsWithSearch({
    refetchOperators,
    setSearchValue,
    searchResultsLength: searchResults.length,
  });

  const [refetchOperatorsPreAssembledWrapper] = useRefetchOperatorsWithSearch({
    refetchOperators,
    setSearchValue: setSearchPreArmValue,
    searchResultsLength: searchPreArmResults.length,
  });

  const { refetching: disarmLoading, refetch: disarmAssignment } = useData<
    unknown,
    AssignmentRequestBody
  >(
    {
      config: {
        url: "/operator-assignment/assignments",
        method: "DELETE",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.DELETE_ASSIGN_OPERATOR
  );

  // const shownOperators = useMemo(() => {
  //   if (!operators) return [];

  //   return sortFirstOperator(
  //     searchResults,
  //     truck?.preassembledOper?.id ?? truck?.lastOper?.id
  //   );
  // }, [truck, operators, searchResults]);

  const actionTexts = useMemo<ActionsText>(
    () => getActionText(truck, selectedOperator),
    [truck, selectedOperator]
  );

  const disableActionButton = useMemo(
    () =>
      !truck ||
      truck?.isCa ||
      !!truck?.currentOper ||
      !selectedOperator ||
      assignLoading ||
      disarmLoading,
    [assignLoading, disarmLoading, selectedOperator, truck]
  );

  const handleAction = useCallback(async () => {
    if (!truck || !selectedOperator) return;
    try {
      const params: AssignmentRequestBody = {
        operId: selectedOperator.id,
        equipId: truck.id,
        reasonId: truck.reasonId,
      };
      if (selectedOperator.id === truck.preassembledOper?.id) {
        await disarmAssignment({
          data: params,
        });
        setSelectedOperator(null);
      } else {
        await !preAssemble ? assignOperId({
          data: params,
        }): assignOperIdPre({
          data: params
        });
      }
      setAssignDialogData({ open: false });
      refetchTrucksDashboard();
      enqueueSnackbar(actionTexts.snackText.success, { variant: "success" });
      refetchOperators();
      refetchTruck();
      navigate(AppRoute.TRUCKS_DASHBOARD);
    } catch (error) {
      setAssignDialogData({ open: false });
      logError(
        "TruckDialog",
        `El usuario "${accounts[0].username}" no pudo ${actionTexts.snackText.error} ${selectedOperator?.id}, con el CAEX ${truck.id}`
      );
    }
  }, [
    selectedOperator,
    truck,
    enqueueSnackbar,
    actionTexts.snackText.success,
    actionTexts.snackText.error,
    refetchTrucksDashboard,
    refetchOperators,
    navigate,
    refetchTruck,
    disarmAssignment,
    setSelectedOperator,
    assignOperId,
    accounts,
  ]);

  const handleActionRelay = useCallback(async () => {
    if (!truck || !selectedOperator) return;
    try {
      await relay();
      setOpenModalStatus({ open: false });
      handleAction();
    } catch (error) {
      setOpenModalStatus({ open: false });
      logError(
        "TruckDialog",
        `El usuario "${accounts[0].username}" no pudo asignar operador ${selectedOperator?.id} con status relevo, al CAEX ${truck.id}`
      );
    }
  }, [selectedOperator, accounts, truck, relay, handleAction]);

  interface ConfirmationDialogProps {
    open: boolean;
    content?: React.JSX.Element;
  }

  const [deletionDialogData, setAssignDialogData] =
    useState<ConfirmationDialogProps>({
      open: false,
    });

  const [openModalStatus, setOpenModalStatus] =
    useState<ConfirmationDialogProps>({
      open: false,
    });

  const AssignOperatorModal = () => {
    return (
      <CustomDialog
        title="Operador sin manejo reciente"
        icon={<AssignmentLateIcon className={classes.icon} fontSize="large" />}
        open={deletionDialogData.open}
        onCompleteInfo={{
          text: "CONFIRMAR",
          onClick: () => handleAction(),
        }}
        onCancelInfo={{
          text: "CANCELAR",
          onClick: () => setAssignDialogData({ open: false }),
        }}
        onClose={() => setAssignDialogData({ open: false })}
        classes={{
          actions: {
            completeActionButton: classes.button,
            dialogTitle: classes.modalDialogTitle,
          },
          dialog: {
            paper: classes.assigmentDialogRoot,
          },
        }}
        keepMounted
      >
        <Typography className={classes.fontColorModal}>
          Hace <strong>6 meses o más</strong> que{" "}
          <strong>{selectedOperator?.name}</strong> operó el modelo
          seleccionado. Recomendamos se ponga en contacto con el/la operador/a,
          y verifique si este se encuentra en condiciones de operar este modelo
          de equipo.
        </Typography>
        <Typography
          className={clsx(classes.fontColorModal, classes.paddingModal)}
        >
          <strong>¿Confirma pre-armado?</strong>
        </Typography>
      </CustomDialog>
    );
  };

  const StatusModal = () => {
    return (
      <CustomDialog
        title="Confirmación de Prearmado"
        icon={<AssignmentLateIcon className={classes.icon} fontSize="large" />}
        open={openModalStatus.open}
        onCompleteInfo={{
          text: "CONFIRMAR",
          onClick: () => handleActionRelay(),
          disabled: refetchingRelay,
        }}
        onCancelInfo={{
          text: "CANCELAR",
          onClick: () => setOpenModalStatus({ open: false }),
          disabled: refetchingRelay,
        }}
        onClose={() => setOpenModalStatus({ open: false })}
        classes={{
          actions: {
            completeActionButton: classes.button,
            dialogTitle: classes.modalDialogTitle,
          },
          dialog: {
            paper: classes.assigmentDialogRoot,
          },
        }}
        keepMounted
      >
        {openModalStatus.content}
        <Typography
          className={clsx(classes.fontColorModal, classes.paddingModal)}
        >
          <strong>¿Confirmas para continuar?</strong>
        </Typography>
      </CustomDialog>
    );
  };

  const verifyOperatorModal = () => {
    const buttonWithModal = ["PRE-ARMAR", "REEMPLAZAR", "ACTUALIZAR"];
    const isCorrectAction = buttonWithModal.includes(actionTexts.buttonText);

    const textDoubleAlert = (
      <Typography className={classes.fontColorModal}>
        Estás a punto de <strong>prearmar con {selectedOperator?.name} </strong>
        , quien está actualmente en <strong>relevo</strong>. Al proceder, el grupo se{" "}
        <strong>eliminará de manera permanente</strong>, esta acción es{" "}
        <strong>irreversible</strong>. Además,{" "}
        <strong> {selectedOperator?.name} </strong> usó este modelo hace 6 meses
        o más. Te recomendamos confirmar con él/ella si puede operarlo
      </Typography>
    );

    const textAlertStatus = (
      <Typography className={classes.fontColorModal}>
        Estás a punto de <strong>pre-armar con {selectedOperator?.name} </strong>
        , actualmente <strong>asignado en el proceso de relevo</strong>. Al
        continuar, el grupo asociado a este relevo se eliminará de forma
        permanente. <strong>Esta acción no se puede deshacer</strong>.
      </Typography>
    );

    // Qualifs Modal
    const findAlertTruck = selectedOperator?.qualifs?.models.find(
      (model) => model.model === truck?.model
    );
    const hasAlertQualifs =
      findAlertTruck?.alert === OperatorAlertType.UNABLE && isCorrectAction;

    // Relay Modal
    const hasStatusAlert =
      selectedOperator?.status === OperatorStatusType.RELAY && isCorrectAction;

    //does not have warning
    if (!hasAlertQualifs && !hasStatusAlert) {
      handleAction();
      return;
    }

    if (hasAlertQualifs && hasStatusAlert) {
      setOpenModalStatus({ open: true, content: textDoubleAlert });
      return;
    }

    if (hasStatusAlert) {
      setOpenModalStatus({ open: true, content: textAlertStatus });
    } else {
      if (hasAlertQualifs) setAssignDialogData({ open: true });
    }
  };

  // auto select the preassembled operator
  useEffect(() => {
    const targetOperId = truck?.preassembledOper?.id;
    if (!targetOperId) return;
    const operator = operators?.find((oper) => oper.id === targetOperId);

    if (operator) setSelectedOperator(operator);
    else setSelectedOperator(null);
  }, [operators, setSelectedOperator, truck?.preassembledOper?.id]);

  useEffect(() => {
    if (!loadingTruckData && !truck) {
      navigate(AppRoute.TRUCKS_DASHBOARD);
      enqueueSnackbar(`CAEX ${equipId} ya no es gestionable`, {
        variant: "info",
      });
      logDebug("TruckScreen", `CAEX ${equipId} ya no es gestionable`);
    }
  }, [navigate, truck, loadingTruckData, enqueueSnackbar, equipId]);

  return (
    <div className={classes.trucksDetailsBackground}>
      <ScreenContainer className={classes.trucksDetailsRoot}>
        <SimplerHeader
          backRoute={`${AppRoute.TRUCKS_DASHBOARD}?tab=2`}
          disableBackButton={updatingTruck}
          searchValue={preAssemble? searchPreArmValue : searchValue}
          onSearchQueryChange={preAssemble? setSearchPreArmValue :setSearchValue}
          searchPlaceholder="Buscar por nombre de operador"
          disableSearch={!!truck?.currentOper}
        />
        <>
          <Collapse
            className={classes.truckDetailsCardCollapse}
            in={!editingOperators}
          >
            {truck ? (
              <TruckDetailsCard truck={truck} updateTruck={updateTruck} />
            ) : (
              <TruckDetailsCardSkeleton />
            )}
          </Collapse>
          {truck?.currentOper ? (
            <div className={classes.currentOperInfo}>
              <InfoOutlinedIcon color="disabled" className={classes.infoIcon} />
              <Typography color="textSecondary" variant="h5">
                El operador <b>{truck.currentOper.name}</b> viene asignado
                directamente desde <b>Modular</b>.
              </Typography>
              <br />
              <Typography color="textSecondary" variant="h5">
                Para pre-armar este CAEX o liberar el operador, primero se debe
                liberar el operador actual en Modular.
              </Typography>
            </div>
          ) : 
            preAssemble ?
              <OperatorsTable
                preAssemble={preAssemble}
                operators={searchPreArmResults}
                truckModel={truck?.model}
                onChange={setEditMode}
                title="Operadores para prearmado"
                refetchOperators={refetchOperatorsPreAssembledWrapper}
                clearFilter={isTypingPre}
                loadingData={firstLoadingOperatorsPreAssembled || refetchingOperatorsPreAssembled}
              />
              :
              <OperatorsTable
              preAssemble={preAssemble}
              operators={searchResults}
              truckModel={truck?.model}
              onChange={setEditMode}
              title="Operadores para prearmado"
              refetchOperators={refetchOperatorsWrapper}
              clearFilter={isTyping}
              loadingData={firstLoadingOperators || refetchingOperators}
            />
            
          }
        </>
        <Slide direction="up" in={withFooter} mountOnEnter unmountOnExit>
          <AppBar position="fixed" className={classes.footerRoot}>
            <Toolbar disableGutters>
              <div className={classes.footerContainer}>
                <div className={classes.footerLeftContainer}>
                  <AccessTimeIcon />
                  <Typography noWrap variant="h6">
                    {isSmall
                      ? "Actualizado"
                      : "Última actualización operadores"}
                    <strong>{` hace ${
                      operatorsElapsedMinutes != null
                        ? parseTimeDelta(operatorsElapsedMinutes)
                        : "-"
                    }`}</strong>
                  </Typography>
                </div>
                <Restricted to={Can.WRITE} at={Module.OPERATOR_ASSIGNMENT}>
                  <div className={classes.footerRightContainer}>
                    <Button
                      className={classes.saveButton}
                      variant="contained"
                      color="primary"
                      onClick={() => verifyOperatorModal()}
                      disabled={disableActionButton}
                    >
                      {actionTexts.buttonText}
                    </Button>
                    <AssignOperatorModal />
                    <StatusModal />
                  </div>
                </Restricted>
              </div>
            </Toolbar>
          </AppBar>
        </Slide>
        <CustomSnackbar
          open={!!truck?.isCa}
          onClose={() => {}}
          data={{
            severity: "info",
            text: `No es posible "PRE-ARMAR" camiones de empresa contratista`,
          }}
        />
      </ScreenContainer>
    </div>
  );
};

// const sortFirstOperator = (
//   operators: Operator[],
//   firstOperId?: string
// ): Operator[] => {
//   if (!firstOperId) return operators;
//   const index = operators.findIndex((op) => op.id === firstOperId);
//   if (index < 0) return operators;
//   const results = [...operators];
//   const firstOperator = results.splice(index, 1)[0];
//   results.unshift(firstOperator);
//   return results;
// };

const getActionText = (
  truck: TruckSummary | null | undefined,
  selectedOperator: Operator | null
): ActionsText => {
  if (truck?.preassembledOper) {
    if (selectedOperator?.preassembledEquip?.id === truck?.id) {
      return {
        buttonText: "LIBERAR",
        snackText: {
          success: "Operador liberado",
          error: "liberar el operador",
        },
      };
    }
    return {
      buttonText: "REEMPLAZAR",
      snackText: {
        success: "Operador reemplazado",
        error: "reemplazar el operador",
      },
    };
  }
  if (selectedOperator?.status === OperatorStatusType.PRE_ASSEMBLED) {
    return {
      buttonText: "ACTUALIZAR",
      snackText: {
        success: "Operador actualizado",
        error: "actualizar el operador",
      },
    };
  }
  return {
    buttonText: "PRE-ARMAR",
    snackText: {
      success: "Operador asignado",
      error: "asignar el operador",
    },
  };
};

interface StyleProps {
  withFooter: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  const { palette } = theme;
  return {
    trucksDetailsBackground: {
      display: "flex",
      width: "100%",
      backgroundColor: palette.background.paper,
    },
    trucksDetailsRoot: {
      marginBottom: ({ withFooter }) => (withFooter ? 64 : 0),
    },
    truckDetailsCardCollapse: {
      width: "100%",
    },
    footerRoot: {
      position: "fixed",
      color: theme.palette.text.primary,
      bottom: 0,
      top: "auto",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.background.paper
          : theme.palette.background.default,
      paddingLeft: 56,
      paddingRight: 56,
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
    },
    footerContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    footerRightContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      gap: 10,
      alignItems: "center",
    },
    footerLeftContainer: {
      display: "flex",
      width: "100%",
      gap: 10,
      alignItems: "center",
    },
    saveButton: {
      color: theme.palette.common.white,
      minHeight: 42,
    },
    button: {
      minHeight: 42,
    },
    currentOperInfo: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    infoIcon: {
      width: "4em",
      height: "4em",
      marginBottom: 15,
    },
    modalDialogTitle: {
      color: theme.palette.error.main,
    },
    fontColorModal: {
      color: theme.palette.text.secondary,
    },
    paddingModal: {
      paddingTop: 20,
    },
    assigmentDialogRoot: {
      maxWidth: 550,
    },
    icon: {
      marginRight: 15,
    },
  };
});
