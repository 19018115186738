import { useCallback } from "react";
import {
  Operator,
  RefetchOperatorsFunctionType,
  RefetchOperatorsReason,
} from "interfaces";
import { RefetchFunctionType, UpdateSearchValueType } from "hooks";

interface HookParams {
  refetchOperators: RefetchFunctionType<Operator[]>;
  setSearchValue: UpdateSearchValueType;
  searchResultsLength: number;
}

export const useRefetchOperatorsWithSearch = ({
  refetchOperators,
  setSearchValue,
  searchResultsLength,
}: HookParams): [RefetchOperatorsFunctionType] => {
  // Wrap the function to distinguish the reason to refetch and apply some logic. Dismiss axios params.
  const refetchOperatorsWrapper = useCallback(
    async (reason: RefetchOperatorsReason) => {
      if (reason === RefetchOperatorsReason.ADDED) setSearchValue("");
      else if (
        reason === RefetchOperatorsReason.DELETED &&
        searchResultsLength === 1
      )
        setSearchValue("");
      await refetchOperators();
    },
    [setSearchValue, refetchOperators, searchResultsLength]
  );

  return [refetchOperatorsWrapper];
};
