import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import {
  MetricGroupType,
  ModularChangeEvent,
  modularChangeDescriptionText,
  Setting,
  modularChangeTranslationType,
  ModularChangeSettingCategory,
} from "interfaces";
import { PlayArrowRounded } from "@material-ui/icons";
import { groupBy } from "lodash";

interface Props {
  groupType: MetricGroupType;
  events: ModularChangeEvent[];
  x: Date;
}

export const ModularChangesTooltipContent: React.FC<Props> = ({
  events,
  groupType,
  x,
}) => {
  const classes = useStyles();
  const groupedEvents = groupBy(events, "settingCategory");
  return (
    <>
      {Object.entries<ModularChangeEvent[]>(groupedEvents).map(
        ([type, events]) => (
          <div key={type}>
            <div className={classes.row}>
              <PlayArrowRounded className={classes.triangleStyle} />
              <Typography className={classes.tooltipIndex} variant="subtitle2">
                {
                  modularChangeTranslationType[
                    type as ModularChangeSettingCategory
                  ]
                }
              </Typography>
            </div>
            <div className={classes.eventsDescription}>
              {events.map((e, index) => (
                <Typography key={index} variant="body2">
                  {getTooltipTexts(e, groupType)}
                </Typography>
              ))}
            </div>
          </div>
        )
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltipIndex: {
    marginRight: 5,
    marginTop: 3,
  },

  row: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 10,
  },
  triangleStyle: {
    width: 25,
    height: 25,
    color: theme.palette.movementTarget,
    transform: "rotate(-90deg)",
    marginTop: 2,
  },
  eventsDescription: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    marginLeft: 25,
    marginBottom: 5,
  },
}));

const getTooltipTexts = (
  data: ModularChangeEvent,
  groupType: MetricGroupType
): string => {
  const { prevValue, value, setting, entity, virtualRegion } = data;

  const isVirtualRegion = groupType === MetricGroupType.VIRTUAL_REGION;

  const excav_sufix = isVirtualRegion ? `en pala ${entity}` : "";

  if (setting === Setting.max_excav_rate) {
    return `Tasa máxima de excavación de ${prevValue} a ${value} ton/h ${excav_sufix}`;
  }

  if (setting === Setting.priority) {
    return `Prioridad de ${prevValue} a ${value} ${excav_sufix}`;
  }

  if (setting === Setting.disabled) {
    const verb = value === "NO" ? "habilitó" : "deshabilitó";
    return `Se ${verb} la pala ${entity}`;
  }

  if (setting === Setting.coverage_ratio) {
    return `Ratio de cobertura de ${prevValue}% a ${value}% ${excav_sufix}`;
  }

  //  the following if's correspond to the settings of GLOBAL_PARAMETERS change type
  if (setting === Setting.two_way_dump_lock) {
    const verb = value === "SI" ? "" : "no ";
    return `Asignaciones fijas pala-a-botadero ${verb}se consideran doble sentido`;
  }

  //  the following if's correspond to the settings of REASIGNATION_BEACONS change type
  if (setting === Setting.pos) {
    if (prevValue === null) {
      return `Se incorporó la baliza ${entity} en la región virtual ${virtualRegion}`;
    } else if (value === null) {
      return `Se deshabilitó la baliza ${entity} de la región virtual ${virtualRegion}`;
    }
    return `Se cambió la ubicación de la baliza ${entity}`;
  }

  //the following if's correspond to the settings of VIRTUAL_REGION_ASSIGNATION change type
  if (setting === Setting.virtual_region) {
    return isVirtualRegion
      ? `Se reasignó la pala ${entity} de la región virtual ${prevValue} a ${value}`
      : `Se modificó la región virtual de ${prevValue} a ${value}`;
  }

  //the following if's correspond to the settings of DESTINATION_CONTROL change type
  if (setting === Setting.max_queue) {
    return `Cola máxima en ${entity} pasó de ${prevValue} a ${value} CAEXs`;
  }
  if (setting === Setting.feed_rate) {
    return `Tasa de alimentación en ${entity} pasó de ${prevValue} a ${value}`;
  }
  if (setting === Setting.max_feed_rate) {
    return `Tasa máxima de alimentación en ${entity} pasó de ${prevValue} a ${value}`;
  }

  //the following if's correspond to the settings of SHOVEL_DESTINATION change type
  if (setting === Setting.cycle_time) {
    const excav = isVirtualRegion ? ` ${entity}` : "";
    const verb = prevValue < value ? "aumentando" : "disminuyendo";
    return `Cambiaron los destinos de la pala${excav}, ${verb} su tiempo de ciclo`;
  }

  const prefix = value === "NO" ? "no " : "";
  const rest = modularChangeDescriptionText[setting] ?? "";
  if (!rest) return "";
  const description = prefix + rest;
  return description.charAt(0).toUpperCase() + description.slice(1);
};
