import React, { useMemo } from "react";
import {
  makeStyles,
  Theme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  DialogProps,
  IconButton,
  Chip,
  Grid,
} from "@material-ui/core";
import clsx from "clsx";
import { CloseRounded } from "@material-ui/icons";

export enum CloseOnClick {
  AFTER = "AFTER",
  BEFORE = "BEFORE",
}

interface CustomDialogActionsClasses {
  completeActionButton?: string;
  cancelActionButton?: string;
  dialogTitle?: string;
}

interface CustomDialogClasses {
  actions?: CustomDialogActionsClasses;
  dialog?: DialogProps["classes"];
}

interface CustomBackground {
  backProps?: DialogProps["classes"];
}

export interface CustomDialogActionProps {
  text: string;
  onClick: () => Promise<unknown> | void | null;
  disabled?: boolean;
  closeOnClick?: CloseOnClick;
}

interface CustomDialogProps extends Omit<DialogProps, "classes"> {
  title: string;
  icon?: JSX.Element;
  onCancelInfo?: CustomDialogActionProps;
  onCompleteInfo?: CustomDialogActionProps;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  classes?: CustomDialogClasses;
  withCloseButton?: boolean;
  countChip?: number | string;
  backdropProps?: CustomBackground;
}

type DialogActionsInfo = Pick<
  CustomDialogProps,
  "onCancelInfo" | "onCompleteInfo"
>;

export const CustomDialog: React.FC<CustomDialogProps> = ({
  title,
  icon,
  children,
  onClose,
  onCancelInfo,
  onCompleteInfo,
  keepMounted = true,
  withCloseButton,
  countChip,
  classes: propClasses,
  backdropProps,
  ...props
}) => {
  const classes = useStyles();

  const actions = useMemo<DialogActionsInfo>(
    () => ({
      onCancelInfo: onCancelInfo
        ? {
            ...onCancelInfo,
            onClick: async () => {
              onCancelInfo.closeOnClick === CloseOnClick.BEFORE && onClose();
              await onCancelInfo.onClick();
              onCancelInfo.closeOnClick === CloseOnClick.AFTER && onClose();
            },
          }
        : undefined,
      onCompleteInfo: onCompleteInfo
        ? {
            ...onCompleteInfo,
            onClick: async () => {
              onCompleteInfo.closeOnClick === CloseOnClick.BEFORE && onClose();
              await onCompleteInfo.onClick();
              onCompleteInfo.closeOnClick === CloseOnClick.AFTER && onClose();
            },
          }
        : undefined,
    }),
    [onCancelInfo, onCompleteInfo, onClose]
  );

  return (
    <Dialog
      fullWidth
      keepMounted={keepMounted}
      transitionDuration={0}
      onClose={onClose}
      classes={{ ...propClasses?.dialog }}
      BackdropProps={{ classes: { ...backdropProps?.backProps } }}
      {...props}
    >
      <DialogTitle
        className={clsx(classes.dialogTitle, propClasses?.actions?.dialogTitle)}
        disableTypography
      >
        <Grid className={classes.iconContainer}>
          {icon ? icon : ""}
          <Typography className={classes.dialogTitleText} variant="h5">
            {title}
          </Typography>
        </Grid>
        {countChip !== undefined && (
          <Chip
            className={classes.countingChip}
            label={
              <Typography color="textPrimary" variant="h6">
                <strong>{countChip}</strong>
              </Typography>
            }
          />
        )}
        {withCloseButton && (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseRounded />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
      <DialogActions>
        {actions.onCancelInfo && (
          <Button
            className={clsx(
              classes.button,
              propClasses?.actions?.cancelActionButton
            )}
            color="primary"
            onClick={actions.onCancelInfo.onClick}
            disabled={actions.onCancelInfo.disabled}
          >
            {actions.onCancelInfo.text}
          </Button>
        )}
        {actions.onCompleteInfo && (
          <Button
            className={clsx(
              [classes.confirmButton, classes.button],
              propClasses?.actions?.completeActionButton
            )}
            variant="contained"
            color="primary"
            onClick={actions.onCompleteInfo.onClick}
            disabled={actions.onCompleteInfo.disabled}
          >
            {actions.onCompleteInfo.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;
  const { type } = palette;
  return {
    dialogPaper: {
      maxWidth: 1464,
      background:
        type === "light"
          ? palette.background.paper
          : palette.background.default,
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
    },
    button: {
      minHeight: 42,
    },
    confirmButton: {
      color: theme.palette.common.white,
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    dialogTitleText: {
      fontWeight: 500,
    },
    countingChip: {
      marginLeft: 8,
      marginRight: 8,
      paddingRight: 4,
      paddingLeft: 4,
    },
    closeButton: {
      marginLeft: "auto",
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
    },
  };
});
