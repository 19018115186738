import React from "react";
import { useTheme } from "@material-ui/core";
import { PointSymbolProps } from "@nivo/line";

export const CustomTriangleSymbol: React.FC<Pick<PointSymbolProps, "size">> = ({
  size,
}) => {
  const theme = useTheme();
  const defaultSizePx = 11;
  // Use nivo line chart's size argument as scaling factor for the default svg size
  const scaleFactor = size * 1.4;
  const translation = -(scaleFactor * defaultSizePx) / 2;

  return (
    <g
      transform={`translate(${translation},${translation}) scale(${scaleFactor})`}
    >
      <path
        d="M1.625 8.79199L5.70833 1.79199L10.375 8.79199H1.625Z"
        fill={theme.palette.movementTarget}
        stroke={theme.palette.movementTarget}
        strokeLinejoin="round"
      />
    </g>
  );
};
