import { Grid, Theme, Typography, alpha, makeStyles } from "@material-ui/core";
import React from "react";
import { ArrowIcon } from "./icons/ArrowIcon";
import { DobleArrowIcon } from "./icons/DobleArrowIcon";
import { Tooltip } from "./Tooltip";
import { Level, Tooltip as TooltipProps } from "interfaces/velocity.interface";
import { parseNumber } from "utils";

export enum Size {
  medium = "medium",
  small = "small",
}

interface Props {
  size: Size;
  title: string;
  level: Level;
  tooltipData?: TooltipProps;
}
export const SquareChip: React.FC<Props> = ({
  title,
  level,
  size,
  tooltipData,
}) => {
  const classes = useStyles({
    level: level,
    size: size,
  });

  const TooltipTitle = ({ title, subtitle, items }: TooltipProps) => {
    return (
      <Grid container style={{ padding: "10px 12px", maxWidth: "176px" }}>
        <Grid item xs={12} style={{ marginBottom: "15px" }}>
          <Typography variant="subtitle1">
            <strong>{title}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <strong>{subtitle}</strong>
          </Typography>
        </Grid>
        <ul className={classes.list}>
          {items?.map(({ variableName, value }, index) => (
            <li key={index}>
              <Typography variant="subtitle1">
                {variableName && (
                  <strong>{`${variableName}: ${
                    value !== null && typeof value === "number"
                      ? `${parseNumber(value, 1)}`
                      : value !== null && typeof value === "string"
                      ? value
                      : "-"
                  }`}</strong>
                )}
              </Typography>
            </li>
          ))}
        </ul>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        {tooltipData ? (
          <Tooltip
            title={
              <TooltipTitle
                title={tooltipData.title}
                subtitle={tooltipData.subtitle}
                items={tooltipData.items}
              />
            }
            arrow
            placeholder="bottom"
            interactive
          >
            <Typography
              style={{
                display: "flex",
                fontSize: "13px",
                alignItems: "center",
              }}
            >
              <strong>{title}</strong>
              {level && level === Level.MEDIUM ? (
                <ArrowIcon className={classes.icon} />
              ) : level === Level.HIGH ? (
                <DobleArrowIcon className={classes.icon} />
              ) : null}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            style={{ display: "flex", fontSize: "13px", alignItems: "center" }}
          >
            <strong>{title}</strong>
            {level && level === Level.MEDIUM ? (
              <ArrowIcon className={classes.icon} />
            ) : level === Level.HIGH ? (
              <DobleArrowIcon className={classes.icon} />
            ) : null}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

interface StylesProps {
  level: string;
  size: string;
}

const setColor = (level: string, theme: Theme) => {
  switch (level) {
    case Level.LOW:
      return theme.palette.success.main;
    case Level.MEDIUM:
      return theme.palette.warning.main;
    case Level.HIGH:
      return theme.palette.error.main;
    default:
      return theme.palette.secondary.light;
  }
};

const setBackgroundColor = (level: string, theme: Theme) => {
  switch (level) {
    case Level.LOW:
      return theme.palette.success.main;
    case Level.MEDIUM:
      return theme.palette.type === "light"
        ? theme.palette.warning.main
        : "#F57C0033";
    case Level.HIGH:
      return theme.palette.type === "light"
        ? theme.palette.error.main
        : "#D32F2F33";
    default:
      return theme.palette.secondary.light;
  }
};

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  mainContainer: {
    alignItems: "center",
    borderColor: ({ level }) => alpha(setColor(level, theme), 0.5),
    borderWidth: "2px",
    borderStyle: "solid",
    borderRadius: "4px",
    color: ({ level }) => setColor(level, theme),
    fontWeight: 700,
    background: ({ level }) => alpha(setBackgroundColor(level, theme), 0.1),
    justifyContent: "center",
    marginRight: "4px",
    marginTop: "2px",
    padding: "3px 2px 3px 6px",
    height: "32px",
    maxWidth: ({ size }) => (size === "small" ? "48px" : "unset"),
  },
  icon: {
    fill: ({ level }) => alpha(setColor(level, theme), 0.5),
  },
  list: {
    paddingInlineStart: "30px",
    marginTop: "0px",
    marginBottom: "5px",
  },
}));
