import React from "react";
import { makeStyles, MenuItem, Select, SvgIconProps } from "@material-ui/core";
import { Label } from "components";
import clsx from "clsx";

export interface SelectorOptions {
  id: number | string;
  name: string;
}

interface SelectorClassesProps {
  root?: string;
  selector?: string;
}

interface SelectorProps<T> {
  label?: string;
  icon?: React.FC<SvgIconProps<"svg", {}>>;
  value?: number;
  options: T[];
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
  classes?: SelectorClassesProps;
  children?: React.ReactNode;
}

export const Selector = <T extends SelectorOptions>({
  label,
  icon: Icon,
  value,
  options,
  onChange,
  classes: propClasses,
  children,
}: SelectorProps<T>) => {
  const classes = useStyles();

  return (
    <div className={clsx(propClasses?.root, classes.selectorRoot)}>
      <Label
        classes={{ text: classes.itemLabelText }}
        text={label}
        icon={Icon}
      />
      <Select
        className={clsx(classes.selector, propClasses?.selector)}
        value={value ?? ""}
        onChange={onChange}
      >
        {options.map(({ name, id }) => (
          <MenuItem key={`${name}-${id}`} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    selectorRoot: {
      display: "flex",
      marginTop: 6,
      marginBottom: 6,
      alignItems: "center",
    },
    itemLabelText: {
      fontWeight: 700,
      width: 125,
    },
    itemText: {},
    selector: {
      maxHeight: 28,
    },
  };
});
