import { localizeNumber } from "./localize";

export const truncateWithDecimals = (value: number, decimalPlaces = 1) => {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.floor(value * factorOfTen) / factorOfTen;
};

export const roundWithDecimals = (value: number, decimalPlaces = 1) => {
  return +(Math.round(+(value + "e+" + decimalPlaces)) + "e-" + decimalPlaces);
};

export const parseNumber = (value: number, decimalPlaces = 1) =>
  localizeNumber(roundWithDecimals(value, decimalPlaces));
