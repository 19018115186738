import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  Footer,
  PerformanceRatingKpis,
  ScreenContainer,
  SimplerHeader,
} from "components";
import {
  ErrorMsg,
  PerformanceRatingOperatorLast6Shifts,
  PerformanceRatingOperatorLastShiftParams,
  PerformanceRatingOperatorEvent,
  PerformanceRatingOperatorEventParams,
  PeriodEnum,
  Evaluation,
  Can,
  Module,
  ScreenType,
} from "interfaces";
import { DataViewOption, PerformanceRatingActionType } from "reducers";
import { useData } from "hooks";
import { AppRoute, formatLocalizedDate, getPractices } from "utils";
import {
  PerformanceRatingsLineGraph,
  PerformanceRatingOperatorMenu,
  PerformanceRatingOperatorEventsTable,
} from ".";
import { UserContext, PerformanceRatingsContext } from "contexts";
import { flatten, groupBy, map, max, min, sumBy } from "lodash";
import { PerformanceRatingPeriodsOptions } from "..";
import { OperatorEvaluationDialog } from "./OperatorEvaluationDialog";
import { parseISO, startOfDay } from "date-fns";

interface DialogProps {
  open: boolean;
  evaluation: Evaluation | null;
}

export const PerformanceRatingOperatorScreen: React.FC = () => {
  const classes = useStyles();

  const [dialogData, setDialogData] = useState<DialogProps>({
    open: false,
    evaluation: null,
  });

  const { menuState, dispatch } = useContext(PerformanceRatingsContext);
  const { user, isAllowedTo } = useContext(UserContext);

  const prOperEventsParams =
    useMemo<PerformanceRatingOperatorEventParams>(() => {
      const isCustomPeriod =
        menuState.operatorPeriod.name === PeriodEnum.CUSTOM;

      return {
        operatorPractices: getPractices(menuState.operPractices),
        period: menuState.operatorPeriod.name,
        startDate: isCustomPeriod
          ? formatLocalizedDate(
              menuState.operatorPeriod.startDate,
              "yyyy-MM-dd"
            )
          : null,
        endDate: isCustomPeriod
          ? formatLocalizedDate(menuState.operatorPeriod.endDate, "yyyy-MM-dd")
          : null,
      };
    }, [
      menuState.operPractices,
      menuState.operatorPeriod.endDate,
      menuState.operatorPeriod.name,
      menuState.operatorPeriod.startDate,
    ]);

  const {
    data: last6ShiftsData,
    firstLoading: firstLoadingLast6ShiftsData,
    refetching: refetchingLast6ShiftsData,
    refetch: getLast6Shifts,
  } = useData<
    PerformanceRatingOperatorLast6Shifts,
    PerformanceRatingOperatorLastShiftParams
  >(
    {
      config: {
        url: `/performance-ratings/summary/${menuState.selectedOperator?.id}`,
        method: "GET",
        params: { operatorPractices: prOperEventsParams.operatorPractices },
      },
      options: {
        useCache: false,
        manual: true,
      },
    },
    ErrorMsg.GET_PR_OPERATORS_EVENTS,
    {}
  );

  const {
    data: operatorEventsData,
    firstLoading: firstLoadingOperatorEvents,
    refetching: refetchingOperatorEventsData,
    refetch: getOperatorEvents,
  } = useData<
    PerformanceRatingOperatorEvent[],
    PerformanceRatingOperatorEventParams
  >(
    {
      config: {
        url: `/performance-ratings/events/${menuState.selectedOperator?.id}`,
        method: "GET",
        params: prOperEventsParams,
      },
      options: {
        useCache: false,
        manual: true,
      },
    },
    ErrorMsg.GET_PR_OPERATOR_EVENTS
  );

  const { data: allEvaluations, refetch: refetchEvaluations } = useData<
    Evaluation[]
  >(
    {
      config: {
        url: `/performance-ratings/operators/${menuState.selectedOperator?.id}/evaluations`,
        method: "GET",
      },
      options: {
        useCache: false,
        manual: true,
      },
    },
    ErrorMsg.GET_EVALUATIONS
  );

  const plotData = useMemo(() => {
    if (menuState.operPractices.ALL) {
      //* Build new aggregated serie for All entries and return;
      const entries = flatten(Object.values(last6ShiftsData));
      const dateGrouped = groupBy(
        entries,
        (e) => `${e.weeklyShiftStartDate}_${e.weeklyShiftEndDate}`
      );
      const summary: PerformanceRatingOperatorLast6Shifts = {
        ALL: Object.values(dateGrouped).map((entries) => {
          const eventsCount = sumBy(entries, "eventsCount");
          return {
            eventsCount,
            meanDuration: eventsCount
              ? sumBy(entries, (e) => e.eventsCount * e.meanDuration) /
                eventsCount
              : 0,
            weeklyShiftStartDate: entries[0].weeklyShiftStartDate,
            weeklyShiftEndDate: entries[0].weeklyShiftEndDate,
          };
        }),
      };
      return summary;
    }
    return last6ShiftsData;
  }, [menuState.operPractices.ALL, last6ShiftsData]);

  const loadingOperatorsEvents =
    firstLoadingOperatorEvents || refetchingOperatorEventsData;

  const displayPlotDataLoader =
    (firstLoadingLast6ShiftsData || refetchingLast6ShiftsData) &&
    menuState.viewOption === DataViewOption.GRAPH;

  const onCloseDialog = useCallback(
    (e?: unknown, reason?: "backdropClick" | "escapeKeyDown") => {
      if (reason !== "backdropClick") {
        setDialogData({
          open: false,
          evaluation: null,
        });
      }
    },
    []
  );

  const onDialogComplete = useCallback(
    () => refetchEvaluations(),
    [refetchEvaluations]
  );

  const onEditEvaluationClick = useCallback(
    (id: number) => {
      setDialogData({
        open: true,
        evaluation:
          allEvaluations.find((evaluation) => evaluation.id === id) ?? null,
      });
    },
    [allEvaluations]
  );

  const onCreateEvaluationClick = useCallback(
    () =>
      setDialogData({
        open: true,
        evaluation: null,
      }),
    []
  );

  useEffect(() => {
    if (menuState.selectedOperator?.id) {
      getLast6Shifts();
      getOperatorEvents();
      refetchEvaluations();
    }
  }, [
    menuState.selectedOperator,
    getLast6Shifts,
    getOperatorEvents,
    refetchEvaluations,
  ]);

  useEffect(() => {
    // Analyze data and set suggested custom range
    const practiceData = Object.values(last6ShiftsData)[0];
    if (!practiceData) return;

    const firstShiftStartDate = min(map(practiceData, "weeklyShiftStartDate"));
    const lastShiftEndDate = max(map(practiceData, "weeklyShiftEndDate"));

    if (firstShiftStartDate && lastShiftEndDate) {
      dispatch({
        type: PerformanceRatingActionType.CHANGE_OPERATOR_RANGE,
        dates: {
          last6ShiftStartDate: parseISO(firstShiftStartDate),
          last6ShiftEndDate: min([
            startOfDay(new Date()),
            parseISO(lastShiftEndDate),
          ]) as Date,
        },
      });
    }
  }, [dispatch, last6ShiftsData]);

  return (
    <>
      <SimplerHeader backRoute={AppRoute.PERFORMANCE_RATINGS} />
      <ScreenContainer className={classes.root}>
        <PerformanceRatingKpis />
        <div className={classes.container}>
          <div className={classes.headerContent}>
            <div className={classes.headerTitle}>
              <Typography variant="h6">
                <b>
                  {menuState.viewOption === DataViewOption.GRAPH
                    ? `Demoras en los últimos 6 turnos | 
                ${menuState.selectedOperator?.name ?? ""}`
                    : `Detalles de demoras | ${
                        menuState.selectedOperator?.name ?? ""
                      }`}
                </b>
              </Typography>
              {displayPlotDataLoader && (
                <CircularProgress
                  className={classes.circularProgress}
                  size={30}
                  color="primary"
                />
              )}
            </div>

            {menuState.viewOption === DataViewOption.TABLE && (
              <PerformanceRatingPeriodsOptions screen={ScreenType.OPERATOR} />
            )}
          </div>
          <div className={classes.prContent}>
            <PerformanceRatingOperatorMenu />
            {menuState.viewOption === DataViewOption.GRAPH ? (
              <PerformanceRatingsLineGraph
                data={plotData}
                evaluations={allEvaluations}
                onEvaluationClick={onEditEvaluationClick}
              />
            ) : (
              <PerformanceRatingOperatorEventsTable
                loading={loadingOperatorsEvents}
                data={operatorEventsData}
              />
            )}
          </div>
          {user?.isOperationalSupervisor &&
            isAllowedTo(Can.WRITE, Module.PERFORMANCE_RATINGS) && (
              <Footer className={classes.footerPaper}>
                <Button
                  className={classes.createEvaluationButton}
                  color="primary"
                  variant="contained"
                  onClick={onCreateEvaluationClick}
                  disabled={!menuState.selectedOperator}
                >
                  AGREGAR EVALUACIÓN
                </Button>
              </Footer>
            )}
        </div>
      </ScreenContainer>

      <OperatorEvaluationDialog
        open={dialogData.open}
        onComplete={onDialogComplete}
        onClose={onCloseDialog}
        evaluation={dialogData.evaluation}
        operator={menuState.selectedOperator}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    overflowX: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    marginTop: 24,
  },
  headerCell: {
    width: "100%",
  },
  chipGroupRoot: {
    marginLeft: "auto",
  },
  chipExceedEvents: {
    width: "100%",
    height: 24,
    maxWidth: 48,
  },
  contentHeader: {
    display: "flex",
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
    alignItems: "center",
    height: 44,
  },
  prContent: {
    display: "flex",
    height: "100%",
  },
  footerPaper: {
    display: "flex",
    paddingTop: 10,
    marginTop: 10,
    justifyContent: "flex-end",
  },

  createEvaluationButton: {
    height: 42,
    width: 201,
    color: theme.palette.common.white,
  },
  headerTitle: {
    display: "flex",
  },
  circularProgress: {
    marginLeft: 10,
  },
}));
