import { useNavigate } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  CircularProgress,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import WarningRounded from "@material-ui/icons/WarningRounded";
import { AppRoute, parseTimeDelta } from "utils";
import {
  HeaderThemeButton,
  MenuDrawer,
  BhpLogo,
  SearchBar,
  Tooltip,
} from "components";
import { AppThemeContext } from "contexts";

interface HeaderProps {
  className?: string;
  elapsedMinutes?: number | null;
  searchValue?: string;
  onSearchQueryChange?: (value: string) => void;
  searchPlaceholder?: string;
  clearOnBlur?: boolean;
  loading?: boolean;
  minutesToShowWarning?: number;
  warningTooltipTexts?: string[];
  onUpdateStateMenu?: (openMenu: boolean)=>void;
  activeNavbar? : boolean;
}

export const Header: React.FC<HeaderProps> = ({
  elapsedMinutes,
  searchValue,
  onSearchQueryChange,
  searchPlaceholder,
  clearOnBlur,
  loading,
  minutesToShowWarning = 15,
  warningTooltipTexts,
  onUpdateStateMenu,
  activeNavbar = true
}) => {
  //Hooks
  const classes = useStyles();
  const { toggleMode } = useContext(AppThemeContext);

  const navigate = useNavigate();

  //Local states
  const [openedDrawer, setOpenedDrawer] = useState<boolean>(false);
  const [activeSidebar, setActiveSidebar] = useState<boolean>(activeNavbar);
  const [widthDimension, setWidthDimension] = useState<number>(
    window.innerWidth
  );
  useEffect(() => {
    onUpdateStateMenu && onUpdateStateMenu(openedDrawer)
    const handleResize = () => {
      setWidthDimension(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [openedDrawer, onUpdateStateMenu])
  
  const onMenuItemClick = useCallback(
    (route: AppRoute | string) => {
      setOpenedDrawer(false);
      navigate(route);
    },
    [setOpenedDrawer, navigate]
  );

  const toggleDrawer = useCallback(() => {
    setOpenedDrawer((prev) => !prev);
  }, [setOpenedDrawer]);

  const closeDrawer = useCallback(() => {
    setOpenedDrawer(false);
  }, [setOpenedDrawer]);

  return (
    <>
      <AppBar className={classes.root} position="fixed">
        <Toolbar className={classes.toolbar}>
          <IconButton
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <>
            <div className={classes.textContainer}>
              <BhpLogo className={classes.logo} onClick={() => navigate("/")} />
              <Divider className={classes.divider} orientation="vertical" />
              {loading ? (
                <>
                  <Typography className={classes.text} variant="body1">
                    Actualizando
                  </Typography>
                  <CircularProgress className={classes.circularLoader} />
                </>
              ) : elapsedMinutes != null ? (
                <>
                  <Typography className={classes.text} variant="body1">
                    {`Actualizado hace ${parseTimeDelta(elapsedMinutes)}`}
                  </Typography>
                  {elapsedMinutes >= minutesToShowWarning && (
                    <Tooltip
                      title={
                        warningTooltipTexts?.map((text) => (
                          <Typography key={text} variant="body1">
                            {text}
                          </Typography>
                        )) ?? ""
                      }
                      placement="bottom"
                      arrow
                    >
                      <WarningRounded className={classes.text} />
                    </Tooltip>
                  )}
                </>
              ) : (
                <Typography className={[classes.text, classes.mobileText].join(' ')} variant="body1">
                  Truck Utilisation Basics
                </Typography>
              )}
            </div>
            {onSearchQueryChange && (
              <SearchBar
                value={searchValue}
                onChange={onSearchQueryChange}
                placeholder={searchPlaceholder}
                clearOnBlur={clearOnBlur}
              />
            )}
            <div className={classes.buttonsContainer}>
              <HeaderThemeButton
                className={classes.themeIconButton}
                onClick={toggleMode}
              />
            </div>
          </>
        </Toolbar>
      </AppBar>
      {activeSidebar ? 
      <MenuDrawer
      open={openedDrawer}
      onClose={closeDrawer}
      onItemClick={onMenuItemClick}
    />
        : <>

        </>
  }
      
    </>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme;
  const { type } = palette;
  return {
    root: {
      backgroundColor: palette.primary.main,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    appBarShift: {
      marginLeft: 250,
      width: `calc(100% - ${250}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: "none",
    },
    toolbar: {},
    logo: {
      width: 91,
      height: 32,
      marginRight: "10px",
      fill: palette.common.white,
      [breakpoints.down(425)]: {
        width: 70,
        height: 25,
      },
      cursor: "pointer",
    },
    divider: {
      height: 32,
      background: palette.common.white,
      [breakpoints.down('sm')]: {
        display: 'none'
      },
    },
    textContainer: {
      display: "flex",
      padding: "0px",
      alignItems: "center",
      maxWidth: 380,
      width: "100%",
      [breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "flex-start",
        maxWidth: 200
      },
    },
    searchBarContainer: {
      display: "flex",
      width: "55%",
      flexDirection: "column",
      alignItems: "center",
      padding: "0 15px",
      [breakpoints.down(600)]: {
        display: "none",
      },
    },
    text: {
      color: palette.common.white,
      marginLeft: 10,
    },
    centerTitle: {
      width: "100%",
      textAlign: "center",
    },
    updateText: {
      marginLeft: 50,
      color: type === "light" ? palette.common.white : palette.common.black,
      [breakpoints.down(1440)]: {
        display: "none",
      },
    },
    buttonsContainer: {
      display: "flex",
      alignItems: "center",
      [breakpoints.down(950)]: {
        display: "none",
      },
      maxWidth: 434,
      width: "100%",
      flexDirection: "row-reverse",
      marginLeft: "auto",
    },
    pageButton: {},
    themeIconButton: {
      marginLeft: 10,
      color: palette.common.white,
    },
    menuButton: {
      marginRight: 12,
      color: palette.common.white,
    },
    circularLoader: {
      color: theme.palette.common.white,
      margin: "0 10px",
      maxHeight: 24,
      maxWidth: 24,
    },
    mobileText: {
      [theme.breakpoints.down('md')]: {
        display: "none"
      },
    }
  };
});
