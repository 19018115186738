import React, { createContext } from "react";
import {
  DataContextInterface,
  initialDataContextState,
  ErrorMsg,
} from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { ContextProviderProps } from "contexts";
import { AppRoute } from "utils";
import { StatusRelay } from "interfaces/relieve.interface";

export const OperatorsRelieveContext = createContext<DataContextInterface<StatusRelay[]>>(
  initialDataContextState
);

export const OperatorsRelieveProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {

  const { refetch, ...other } = useData<StatusRelay[]>(
    {
      config: "/operator-assignment/relay/status",
      options: { useCache: false },
    },
    ErrorMsg.GET_FILTERS_RELAY
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetch(), [AppRoute.RELIEVE]);

  return (
    <OperatorsRelieveContext.Provider value={{ ...other, refetch }}>
      {children}
    </OperatorsRelieveContext.Provider>
  );
};
