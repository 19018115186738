import React, { SVGAttributes, useMemo } from "react";
import { makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import StopIcon from "@material-ui/icons/Stop";
import { BarDatum, Bar as BarGraph, BarTooltipProps } from "@nivo/bar";
import { timeFormat } from "d3-time-format";
import { AxisTickProps } from "@nivo/axes";
import { animated } from "@react-spring/web";
import { isEqual } from "date-fns";
import { map, max } from "lodash";
import { AutoSizer, DottedLineIcon } from "components";
import { graphXValues, parseNumber } from "utils";
import { TruckAssignmentMetricsState } from "reducers";
import {
  metricUnit,
  metricTranslation,
  MetricData,
  MetricDataWithEvents,
} from "interfaces";
import { TargetLineLayer } from "./TargetLineLayer";
import { CustomLegend } from "./CustomLegend";
import { DataAndTargetTooltip } from "./DataAndTargetTooltip";

interface MetricsBarGraphProps {
  movementData: MetricDataWithEvents[];
  planData: MetricData[];
  menuState: TruckAssignmentMetricsState;
}

const timeFormatter = timeFormat("%H:%M");

export const MetricsBarGraph: React.FC<MetricsBarGraphProps> = ({
  movementData,
  planData,
  menuState: state,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const withoutUnit = ["CAEX"];

  const graphX = useMemo(() => graphXValues(1).map((val) => val), []);

  const formattedMovementData: BarDatum[] = useMemo(() => {
    return graphX.map((hour) => ({
      hour: hour.toString(),
      value:
        movementData.find(({ issueDate }) => isEqual(new Date(issueDate), hour))
          ?.value ?? 0,
    }));
  }, [movementData, graphX]);

  const formattedPlanData: BarDatum[] = useMemo(() => {
    // Allow only hours with planData entries related
    return graphX.reduce<BarDatum[]>((prev, currentHour) => {
      const planDataEntry = planData.find(({ issueDate }) =>
        isEqual(new Date(issueDate), currentHour)
      );
      if (planDataEntry)
        prev.push({
          hour: currentHour.toString(),
          value: planDataEntry.value,
        });
      return prev;
    }, []);
  }, [planData, graphX]);

  const maxValue =
    max(map(movementData, "value").concat(map(planData, "value"))) ?? 0;

  return (
    <>
      <div className={classes.graphAxisTitleContainer}>
        <div className={classes.graphAxisTitle}>
          <Typography className={classes.sectionTitle} variant="h6">
            <strong>{metricTranslation[state.selectedMetric]}</strong>
          </Typography>
          <Typography className={classes.sectionSubtitle} variant="h6">
            <strong>{`[${metricUnit[state.selectedMetric]}]`}</strong>
          </Typography>
        </div>
        <div className={classes.graphLegend}>
          <>
            <CustomLegend
              icon={<StopIcon fontSize="large" className={classes.line} />}
              text="Real"
            />
            <CustomLegend
              icon={<DottedLineIcon stroke={theme.palette.movementTarget} />}
              text="Esperado"
            />
          </>
        </div>
      </div>
      <div className={classes.graphContainer}>
        <AutoSizer>
          {({ width }) => (
            <BarGraph
              theme={theme.graph}
              colors={theme.palette.movementGraphBar}
              animate={false}
              height={420}
              width={width}
              data={formattedMovementData}
              keys={["value"]}
              indexBy={"hour"}
              margin={{ top: 25, bottom: 55, right: 25, left: 55 }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={theme.palette.common.black}
              valueFormat={(label) => `${parseNumber(label, 1)}`}
              axisTop={null}
              axisRight={null}
              maxValue={maxValue * 1.25}
              axisBottom={{
                tickValues: graphX,
                tickPadding: 15,
                tickRotation: 0,
                renderTick: (props) =>
                  renderBottomAxis({
                    ...props,
                    value: timeFormatter(new Date(props.value)),
                    show: new Date(props.value).getHours() % 2 === 0,
                    theme,
                  }),
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 6,
                tickRotation: 0,
                tickValues: 8,
              }}
              layers={[
                "grid",
                "axes",
                "bars",
                (props) => (
                  <TargetLineLayer
                    {...props}
                    planData={formattedPlanData}
                    color={theme.palette.movementTarget}
                  />
                ),
              ]}
              tooltip={({ value, indexValue }) => {
                const planData = formattedPlanData.find(
                  (val) => val.hour === indexValue
                );
                return renderTooltip({
                  value,
                  indexValue,
                  planData,
                  theme,
                  unit: !withoutUnit.includes(state.selectedMetric) 
                  ? metricUnit[state.selectedMetric] 
                  : "",
                });
              }}
            />
          )}
        </AutoSizer>
      </div>
    </>
  );
};

interface RenderAxisProps extends AxisTickProps<any> {
  theme: Theme;
  show: boolean;
}

const renderBottomAxis = ({
  value,
  textAnchor,
  textBaseline,
  animatedProps,
  theme,
  show,
}: RenderAxisProps) => {
  return show ? (
    <animated.g
      transform={animatedProps.transform}
      style={{
        opacity: animatedProps.opacity,
      }}
    >
      <animated.text
        dominantBaseline={
          textBaseline as SVGAttributes<SVGTextElement>["alignmentBaseline"]
        }
        textAnchor={textAnchor}
        transform={animatedProps.textTransform}
        style={theme.graph.axis?.ticks?.text}
      >
        {value}
      </animated.text>
    </animated.g>
  ) : (
    <></>
  );
};

interface CustomTooltipProps {
  value: BarTooltipProps<BarDatum>["value"];
  indexValue: BarTooltipProps<BarDatum>["indexValue"];
  unit: string;
  planData?: BarDatum;
  theme: Theme;
}

const renderTooltip: React.FC<CustomTooltipProps> = ({
  value,
  indexValue,
  planData,
  unit,
  theme,
}) => {
  const entries = [
    {
      color: theme.palette.movementGraphBar,
      label: "Real",
      value,
      unit,
    },
  ];
  if (planData) {
    entries.push({
      color: theme.palette.movementTarget,
      label: "Esperado",
      value: planData.value as number,
      unit,
    });
  }
  return (
    <DataAndTargetTooltip hourOfDay={new Date(indexValue)} entries={entries} />
  );
};

const useStyles = makeStyles((theme) => ({
  graphAxisTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  graphAxisTitle: {
    width: 150,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "25px"
  },
  sectionTitle: {
    fontSize: "16px",
    lineHeight: "28px",
    paddingRight: "20px",
  },
  sectionSubtitle: {
    fontSize: "16px",
    lineHeight: "28px",
    paddingRight: "20px",
  },
  graphContainer: {
    height: 406,
  },
  graphLegend: {
    display: "flex",
    alignItems: "center",
  },
  line: {
    color: theme.palette.movementGraphBar,
  },
}));
