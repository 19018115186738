import {
  TableCell,
  TableCellProps,
  makeStyles,
  Theme,
  TextField,
} from "@material-ui/core";
import React from "react";

interface UnplannedParkedTruckInputCellProps {
  value: string;
  maxWidth: UseStylesProps["maxWidth"];
  align: TableCellProps["align"];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UnplannedParkedTruckInputCell: React.FC<
  UnplannedParkedTruckInputCellProps
> = ({ value, maxWidth, align, onChange }) => {
  const classes = useStyles({ maxWidth });
  return (
    <TableCell
      className={classes.cell}
      component="td"
      align={align}
      scope="row"
    >
      <TextField
        classes={{ root: classes.input }}
        multiline
        maxRows={2}
        value={value}
        onChange={onChange}
      />
    </TableCell>
  );
};

interface UseStylesProps {
  maxWidth?: number;
}

const useStyles = makeStyles<Theme, UseStylesProps>(() => ({
  cell: {
    maxWidth: ({ maxWidth }) => (maxWidth ? maxWidth : "unset"),
    paddingTop: 5,
    paddingBottom: 5,
  },
  input: {
    width: "100%",
  },
}));
