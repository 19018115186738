import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const MetricsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.1847 15.7979C18.646 15.0513 18.9055 14.1785 18.8479 13.2185C18.7229 11.1336 17.0795 9.36864 15.0325 9.14561C12.4185 8.85469 10.2081 10.9008 10.2081 13.4803C10.2081 15.8949 12.1398 17.8441 14.5232 17.8441C15.3689 17.8441 16.1569 17.5919 16.8201 17.1653L19.1362 19.5023C19.511 19.8805 20.1261 19.8805 20.5009 19.5023C20.8757 19.1241 20.8757 18.5035 20.5009 18.1253L18.1847 15.7979ZM14.5328 15.9046C13.2065 15.9046 12.1301 14.8185 12.1301 13.4803C12.1301 12.1421 13.2065 11.056 14.5328 11.056C15.859 11.056 16.9354 12.1421 16.9354 13.4803C16.9354 14.8185 15.859 15.9046 14.5328 15.9046ZM14.1868 7.66192C13.4756 7.68131 12.7933 7.83647 12.1686 8.0983L11.64 7.29342L8.67999 12.1518C8.33401 12.7142 7.55556 12.7724 7.1327 12.2778L5.09528 9.8826L2.15447 14.6343C1.85655 15.1094 1.22226 15.2355 0.770564 14.9058C0.366924 14.6052 0.251598 14.0427 0.520691 13.6064L4.15345 7.7395C4.49943 7.18675 5.27788 7.12857 5.70074 7.62313L7.72855 10.0087L10.7847 4.99516C11.1499 4.39393 12.0148 4.37454 12.3992 4.96607L14.1868 7.66192ZM16.6759 8.14679C16.0608 7.87526 15.3977 7.71041 14.7058 7.67162L19.0689 0.699263C19.3668 0.224095 20.0011 0.107728 20.4432 0.437436C20.8565 0.738052 20.9622 1.31019 20.6931 1.73687L16.6759 8.14679Z"
      />
    </SvgIcon>
  );
};
