import { AutocompleteChangeReason } from "@material-ui/lab";
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { AutoComplete } from "./AutoComplete";
import {
  CloseOnClick,
  CustomDialog,
  CustomDialogActionProps,
} from "./CustomDialog";
import { map } from "lodash";
import { ErrorMsg, Truck } from "interfaces";
import { makeStyles } from "@material-ui/core";
import { OperatorAssignmentKpisContext } from "contexts";
import { useData } from "hooks";

interface AddTrucksCerroAltoDialogProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

type SelectTruck = Pick<Truck, "id" | "isCa">;

export const AddTrucksCerroAltoDialog: React.FC<
  AddTrucksCerroAltoDialogProps
> = ({ open, onClose, onComplete }) => {
  const classes = useStyles();
  const { refetch: refetchKpis } = useContext(OperatorAssignmentKpisContext);
  const {
    data: trucks,
    firstLoading,
    refetch: refetchTrucks,
  } = useData<SelectTruck[]>(
    {
      config: "/operator-assignment/trucks",
    },
    ErrorMsg.GET_CAEX_CA
  );

  const { refetch: updateTrucksCerroAlto } = useData<unknown, Truck["id"][]>(
    {
      config: {
        url: "/operator-assignment/trucks/cerro-alto",
        method: "PATCH",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.UPDATE_CAEX_CA
  );

  const [selectedTrucks, setSelectedTrucks] = useState<SelectTruck[]>([]);

  const areCerroAltoTrucks = useMemo(
    () => trucks?.some((t) => t.isCa),
    [trucks]
  );

  const handleOnChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: SelectTruck[],
      _reason: AutocompleteChangeReason
    ) => {
      setSelectedTrucks(value);
    },
    [setSelectedTrucks]
  );

  const handleOnComplete = useCallback(async () => {
    await updateTrucksCerroAlto({
      data: map(selectedTrucks, "id"),
    });
    refetchKpis();
    onComplete();
  }, [onComplete, refetchKpis, selectedTrucks, updateTrucksCerroAlto]);

  const onCancelDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "CANCELAR",
      onClick: () => null,
      closeOnClick: CloseOnClick.BEFORE,
    }),
    []
  );

  const onCompleteDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: areCerroAltoTrucks ? "GUARDAR" : "AGREGAR",
      onClick: handleOnComplete,
      closeOnClick: CloseOnClick.BEFORE,
    }),
    [areCerroAltoTrucks, handleOnComplete]
  );

  useEffect(() => {
    if (open) {
      refetchTrucks().then(({ data }) => {
        if (!data) return;
        const caTrucks = data.filter((truck) => truck.isCa);
        setSelectedTrucks(caTrucks);
      });
    }
  }, [open, refetchTrucks]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={`${areCerroAltoTrucks ? "Actualizar" : "Agregar"} CAEX Empresa Contratista`}
      onCancelInfo={onCancelDialogProp}
      onCompleteInfo={onCompleteDialogProp}
    >
      <div className={classes.contentRoot}>
        <AutoComplete
          value={selectedTrucks}
          options={trucks ?? []}
          onChange={handleOnChange}
          loading={firstLoading}
          loadingText="Cargando camiones"
          noOptionsText="No se encontraron camiones"
        />
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    contentRoot: {
      display: "flex",
      justifyContent: "center",
    },
  };
});
