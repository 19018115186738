import { makeStyles } from "@material-ui/core";
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  SpeedDialProps,
} from "@material-ui/lab";
import React, { ReactNode } from "react";

export interface DialAction {
  name: string;
  onClick: () => void;
  icon: React.ReactNode;
}

interface OperatorAssignmentSpeedDialProps extends SpeedDialProps {
  actions: DialAction[];
  speedDialBaseIcon: ReactNode;
  openIcon: ReactNode;
}

export const OperatorAssignmentSpeedDial: React.FC<
  OperatorAssignmentSpeedDialProps
> = ({
  actions,
  speedDialBaseIcon: SpeedDialBaseIcon,
  openIcon: OpenIcon,
  ...props
}) => {
  const classes = useStyles();

  if (!actions.length) return null;

  return (
    <SpeedDial
      className={classes.speedDial}
      icon={
        <SpeedDialIcon
          className={classes.speedDialIcon}
          icon={SpeedDialBaseIcon}
          openIcon={OpenIcon}
        />
      }
      {...props}
    >
      {actions.map((action) => (
        <SpeedDialAction
          className={classes.actions}
          key={action?.name}
          icon={action?.icon}
          tooltipTitle={action?.name}
          onClick={action?.onClick}
          TooltipClasses={{ tooltip: classes.actionsTooltips }}
        />
      ))}
    </SpeedDial>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  const { type } = palette;
  const cellBackground =
    type === "light" ? palette.secondary.main : palette.common.white;

  return {
    speedDial: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    speedDialIcon: {
      color: palette.common.white,
    },
    actions: {
      backgroundColor: palette.primary.dark,
      color: palette.common.white,
      "&:hover": {
        backgroundColor: palette.primary.dark,
      },
    },
    actionsTooltips: {
      fontSize: theme.typography.body1.fontSize,
      backgroundColor: cellBackground,
      color: palette.getContrastText(cellBackground),
    },
  };
});
