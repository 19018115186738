import React from "react";
import { Checkbox } from "@material-ui/core";
import { BaseElement } from "components/List";
export interface BalanceListCheckBoxCellProps {
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  indeterminate?: boolean;
  spacing: string[];
}

export const BalanceListCheckBoxCell: React.FC<
  BalanceListCheckBoxCellProps
> = ({ value, onChange, disabled = false, indeterminate = false, spacing }) => {
  return (
    <BaseElement classNames={spacing}>
      <Checkbox
        checked={value}
        onChange={onChange}
        color="primary"
        disabled={disabled}
        indeterminate={indeterminate}
      />
    </BaseElement>
  );
};
