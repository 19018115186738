import {
  Button,
  Grid,
  TextField,
  Typography,
  alpha,
  makeStyles,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";

interface IncrementButtonProps {
  onValueChange: (value: number) => void;
  initValue: number;
  title?: string;
  disabled: boolean;
}

export const IncrementButton = ({
  title,
  initValue = 1,
  onValueChange,
  disabled,
}: IncrementButtonProps) => {
  const classes = useStyles();
  const [count, setCount] = useState<number>(initValue);
  const [error, setError] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<number | undefined>(
    undefined
  );

  const handleIncrement = () => {
    if (isNaN(count)) {
      setCount(1);
      onValueChange(1);
    }
    if (count < 100) {
      setCount(count + 1);
      onValueChange(count + 1);
    }
  };
  const handleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
      onValueChange(count - 1);
    }
  };

  const inputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCount(parseInt(event.target.value));

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeoutId = window.setTimeout(() => {
      onValueChange(parseInt(event.target.value));
    }, 1000);

    setTypingTimeout(timeoutId);
  };

  useMemo(() => {
    if (!count) {
      setError(true);
    }
  }, [count]);

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      className={classes.container}
    >
      {title ? (
        <Typography variant="subtitle2" className={classes.title}>
          {title}
        </Typography>
      ) : null}
      {!disabled && (
        <Button className={classes.button} onClick={handleDecrement}>
          -
        </Button>
      )}
      <TextField
        className={classes.numberField}
        size="small"
        type="number"
        inputProps={{
          inputMode: "numeric",
          min: 1,
          max: 99,
          pattern: "[0-9]*",
        }}
        id="outlined-basic"
        variant="outlined"
        value={count}
        onChange={inputValueChange}
        disabled={disabled}
        error={error}
      />
      {!disabled && (
        <Button className={classes.button} onClick={handleIncrement}>
          +
        </Button>
      )}
    </Grid>
  );
};
const useStyles = makeStyles(({ palette }) => {
  return {
    title: {
      color: alpha(palette.text.secondary, 0.6),
      paddingRight: 10,
    },
    button: {
      height: 24,
      width: 24,
      minWidth: "unset",
      background:
        palette.type === "dark"
          ? palette.action.active
          : alpha(palette.action.active, 0.54),
      color:
        palette.type === "dark"
          ? palette.common.black
          : palette.secondary.contrastText,
      fontSize: 20,
      "&:hover": {
        background: alpha(palette.action.active, 0.6),
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      },
    },
    numberField: {
      width: "auto",
      height: 28,
      fontSize: 16,
      paddingLeft: 6,
      paddingRight: 6,
      "& input": {
        background:
          palette.type === "dark" ? palette.divider : palette.common.white,
        padding: 4,
        textAlign: "center",
      },
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
      '& input[type="number"]': {
        "-moz-appearance": "textfield",
      },
    },
    container: {
      flexWrap: "unset",
    },
  };
});
