import React from "react";
import { CustomDialog } from "./CustomDialog";
import { makeStyles, Typography } from "@material-ui/core";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const LegalTextDialog: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="Información legal"
      withCloseButton
    >
      <div className={classes.contentRoot}>
        <Typography variant="body1">
          Las Fábricas Digitales de BHP son parte de nuestro equipo global de
          Tecnología. Los productos de Digital Factory son desarrollados,
          diseñados y construidos para ser usados en beneficio de todas las
          operaciones globales de BHP.
        </Typography>
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    contentRoot: {
      display: "flex",
      justifyContent: "center",
    },
  };
});
