import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const EditDocumentIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4445_30061)">
        <path d="M14 19.88V22H16.12L21.29 16.83L19.17 14.71L14 19.88Z" />
        <path d="M20 8L14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H12V19.05L20 11.05V8ZM13 9V3.5L18.5 9H13Z" />
        <path d="M22.7099 14L21.9999 13.29C21.6099 12.9 20.9799 12.9 20.5899 13.29L19.8799 14L21.9999 16.12L22.7099 15.41C23.0999 15.02 23.0999 14.39 22.7099 14Z" />
      </g>
      <defs>
        <clipPath id="clip0_4445_30061">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
