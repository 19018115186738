import React, { createContext, useState, useMemo } from "react";
import {
  BalanceData,
  Group,
  ErrorMsg,
  MetricGroupType,
  TAParam,
} from "interfaces";
import { ContextProviderProps } from "contexts";
import { RefetchFunctionType, useData, usePathnameChange } from "hooks";
import { DATA_REFETCH_MINUTES } from "App";
import { AppRoute } from "utils";
import { mapValues } from "lodash";

const initialMetricSelectorOptions: Record<MetricGroupType, string[]> = {
  [MetricGroupType.EXCAV]: [],
  [MetricGroupType.VIRTUAL_REGION]: [],
  [MetricGroupType.PHASE]: [],
  [MetricGroupType.PIT]: [],
  [MetricGroupType.MINE]: [],
};

const dataDefaultValue: BalanceData = {
  table: [],
  warnings: {},
  updateTimes: [],
  params: {
    [TAParam.TA_WARNING_DELTA_CAEX]: Infinity,
    [TAParam.TA_ALERT_DELTA_CAEX]: Infinity,
    [TAParam.TA_LAST_MANAGEMENT_WARNING_ELAPSED_MINUTES]: Infinity,
  },
};

export const ExcavatorContext = createContext<{
  data: BalanceData;
  firstLoading: boolean;
  refetching: boolean;
  polling: boolean;
  refetch: RefetchFunctionType<BalanceData>;
  groupType: Group;
  setGroupType: React.Dispatch<React.SetStateAction<Group>>;
  metricSelectorOptions: Record<MetricGroupType, string[]>;
}>({
  data: dataDefaultValue,
  firstLoading: true,
  refetching: false,
  polling: false,
  refetch: () => new Promise(() => []),
  groupType: Group.VIRTUAL_REGION,
  setGroupType: () => {},
  metricSelectorOptions: initialMetricSelectorOptions,
});

export const ExcavatorProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [groupType, setGroupType] = useState<Group>(Group.VIRTUAL_REGION);

  const { data, refetch, manualCancel, ...other } = useData<BalanceData>(
    {
      config: {
        url: "/truck-assignment/balance",
        method: "GET",
        params: {
          groupType,
        },
      },
      options: {
        useCache: false,
      },
      polling: {
        minutes: DATA_REFETCH_MINUTES * 5,
        silent: true,
      },
    },
    ErrorMsg.GET_EXCAVATORS_INFO,
    dataDefaultValue
  );

  const metricSelectorOptions: Record<MetricGroupType, string[]> =
    useMemo(() => {
      const state: Record<MetricGroupType, Set<string>> = {
        [MetricGroupType.EXCAV]: new Set(),
        [MetricGroupType.VIRTUAL_REGION]: new Set(),
        [MetricGroupType.PHASE]: new Set(),
        [MetricGroupType.PIT]: new Set(),
        [MetricGroupType.MINE]: new Set(),
      };
      data?.table?.forEach((aggregatedExcav) => {
        aggregatedExcav.excavs.forEach((excav) => {
          state.excav.add(excav.id);
          state.virtual_region.add(excav.virtualRegion);
          state.phase.add(excav.phase);
          state.pit.add(excav.pit);
        });
      });

      return mapValues(state, (set) => Array.from(set).sort());
    }, [data]);

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetch(), [AppRoute.TA_BALANCE_SCREEN]);

  const contextState = useMemo(
    () => ({
      ...other,
      data,
      refetch,
      manualCancel,
      groupType,
      setGroupType,
      metricSelectorOptions,
    }),
    [other, data, refetch, manualCancel, groupType, metricSelectorOptions]
  );

  return (
    <ExcavatorContext.Provider value={contextState}>
      {children}
    </ExcavatorContext.Provider>
  );
};
