import React, { useMemo } from "react";
import {
  Avatar,
  makeStyles,
  Typography,
  Divider,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, Timer } from "@material-ui/icons";
import {
  KpiData,
  KpiParsers,
  KpiType,
  KpiTypeIcon,
  KpiTypeTranslation,
  KpiValue,
  ProjectionStatus,
  timeBasedKpis,
} from "interfaces";
import { getProjectionColor } from "styles";
import { KpiValue as KpiValueComponent } from "./KpiValue";

interface PropsCard extends KpiData {
  openMenu?: boolean;
}

export const KpiCard: React.FC<PropsCard> = ({
  type,
  category,
  mainValue,
  secondaryValue,
  projection,
  secondaryProjection,
  openMenu = false,
}) => {
  // Get screen size:
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between(0, 1548)
  );

  const classes = useStyles({
    projectionColorType: projection?.alertType,
    isOpenMenu: openMenu,
    smallScreen: isSmall,
  });

  const title = KpiTypeTranslation[type];
  const parsers = KpiParsers[type];
  const isTimeBased = useMemo(() => timeBasedKpis.has(type), [type]);
  const KpiIcon = KpiTypeIcon[type];

  interface KpiProjectionProps {
    type: KpiType;
    value: KpiValue;
    projection?: {
      diff: number;
      isBelowPlan: boolean;
      alertType: ProjectionStatus;
    };
    secondary?: boolean;
  }
  const KpiProjection = ({
    type,
    value,
    projection,
    secondary = false,
  }: KpiProjectionProps) => {
    return (
      <>
        <KpiValueComponent type={type} value={value} secondary={secondary} />
        {projection ? (
          <span className={classes.projection}>
            {projection.isBelowPlan ? <ArrowDropDown /> : <ArrowDropUp />}
            <Typography
              variant="h5"
              component="span"
              className={classes.projectionValue}
            >
              {parsers?.projection
                ? parsers.projection(projection.diff)
                : projection.diff}
            </Typography>
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      <div className={classes.kpiRoot}>
        <Avatar className={classes.avatar}>
          {KpiIcon ? <KpiIcon className={classes.icon} /> : ""}
        </Avatar>
        <div className={classes.kpiDetails}>
          <Typography>
            {isTimeBased && <Timer className={classes.miniIcon} />}
            {title}
          </Typography>
          <div className={classes.kpiInfoRoot}>
            <KpiProjection
              type={type}
              value={mainValue}
              projection={projection}
            />
            {secondaryValue ? (
              <>
                <Divider
                  className={classes.valueDivider}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <KpiProjection
                  type={type}
                  value={secondaryValue}
                  projection={secondaryProjection}
                  secondary={true}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Divider
        className={classes.divider}
        orientation="vertical"
        variant="middle"
        flexItem
        light
      />
    </>
  );
};

interface StyleProps {
  projectionColorType?: ProjectionStatus;
  isOpenMenu: boolean;
  smallScreen: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return {
    kpiRoot: {
      display: "flex",
      marginRight: "auto",
    },
    kpiDetails: {
      display: "flex",
      flexDirection: "column",
      minWidth: 120,
      width: "100%",
      [theme.breakpoints.between("md", "lg")]: {
        minWidth: 100,
      },
    },
    avatar: {
      width: 68,
      height: 68,
      backgroundColor: theme.palette.kpi.background,
      marginRight: 16,
      [theme.breakpoints.between("md", "lg")]: {
        width: 40,
        height: 40,
        marginRight: 8,
      },
      [theme.breakpoints.between(0, 1738)]: {
        display: "none",
      },
      display: ({ isOpenMenu }) => (isOpenMenu ? "none" : ""),
    },
    icon: {
      width: "auto",
      height: "auto",
      [theme.breakpoints.between("md", "lg")]: {
        width: 30,
        height: 30,
      },
    },
    miniIcon: {
      width: 15,
      height: 15,
      verticalAlign: "middle",
      marginRight: 5,
    },
    tooltip: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
    kpiInfoRoot: {
      display: "flex",
      alignItems: "center",
      fontSize: 10,
      [theme.breakpoints.down(1441)]: {
        flexWrap: "wrap",
      },
    },
    valueDivider: {
      backgroundColor: theme.palette.text.primary,
      height: 30,
      width: 3,
      alignSelf: "auto",
      [theme.breakpoints.between("md", "lg")]: {
        height: 20,
      },
    },
    projection: {
      color: ({ projectionColorType }) =>
        getProjectionColor(theme, projectionColorType),
      paddingLeft: 5,
    },
    projectionValue: {
      fontWeight: 500,
      fontSize: ({ isOpenMenu, smallScreen }) =>
        isOpenMenu && smallScreen ? "20px" : smallScreen ? "20px" : "22px",
    },
    divider: {
      "&:last-child": {
        display: "none",
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "6px",
        margin: "6px",
      },
    },
  };
});
