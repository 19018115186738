import { IconButton, makeStyles, Typography } from "@material-ui/core";
import {
  VisibilityRounded,
  CreateRounded,
  DeleteRounded,
} from "@material-ui/icons";
import { EditDocumentIcon, CustomTable, CustomDialog } from "components";
import { UserContext } from "contexts";
import { useData, RefetchFunctionType } from "hooks";
import {
  User,
  ErrorMsg,
  HeaderCellProps,
  NestedKeyOf,
  moduleTranslation,
  CellProps,
  Module,
  Can,
} from "interfaces";
import { useSnackbar } from "notistack";
import React, { useMemo, useCallback, useState, useContext } from "react";
import { UserEditDialog, DialogActions } from ".";
import { logError } from "services";
import clsx from "clsx";

interface ConfirmationDialogProps {
  open: boolean;
  user?: User;
}

interface EditDialogProps {
  open: boolean;
  user: User | null;
}

interface Props {
  data: User[];
  refetchUsers: RefetchFunctionType<User[], unknown>;
  loading: boolean;
}

export const UsersTable: React.FC<Props> = ({
  data,
  refetchUsers,
  loading,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user: actualUser, isAllowedTo } = useContext(UserContext);
  const canManageUsers = isAllowedTo(Can.WRITE, Module.ADMINISTRATION_PANEL);

  const [editDialogData, setEditDialogData] = useState<EditDialogProps>({
    open: false,
    user: null,
  });

  const { refetch: deleteUser } = useData<unknown, undefined>(
    {
      config: {
        method: "DELETE",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.DELETE_USER
  );

  const [deletionDialogData, setDeletionDialogData] =
    useState<ConfirmationDialogProps>({
      open: false,
    });

  const handleEditUser = useCallback(
    (user: User) =>
      setEditDialogData((prev) => ({
        user,
        open: !prev.open,
      })),
    []
  );

  const handleOnDeleteUser = useCallback(async () => {
    try {
      await deleteUser({
        url: `administration/users/${deletionDialogData?.user?.id ?? ""}`,
      });
      refetchUsers();
      setDeletionDialogData({ open: false });
      enqueueSnackbar(
        `Usuario '${deletionDialogData?.user?.email}' eliminado con éxito`,
        { variant: "success" }
      );
    } catch (error) {
      logError("AdminPanel", error);
      refetchUsers();
    }
  }, [deletionDialogData, deleteUser, refetchUsers, enqueueSnackbar]);

  const headerCells = useMemo<HeaderCellProps<NestedKeyOf<User>>[]>(
    () => [
      {
        dataKey: "email",
        label: "Correo electrónico",
        align: "left",
        sortable: true,
      },
      {
        dataKey: "role.name",
        label: "Rol",
        align: "left",
        sortable: true,
      },
      {
        dataKey: "crew",
        label: "Turno",
        align: "center",
        sortable: false,
      },
      ...Object.values(moduleTranslation).map(
        (moduleName): HeaderCellProps<NestedKeyOf<User>> => ({
          dataKey: "modulePermissions",
          label: moduleName,
          align: "center",
          sortable: false,
        })
      ),
      {
        dataKey: "updatedBy.name",
        label: "Creado por",
        align: "left",
        sortable: false,
      },
      ...(canManageUsers
        ? [
            {
              dataKey: "_accions",
              label: "Acciones",
              align: "center",
              sortable: false,
            } as HeaderCellProps<NestedKeyOf<User>>,
          ]
        : [{} as HeaderCellProps<NestedKeyOf<User>>]),
    ],
    [canManageUsers]
  );

  const getCellDetails = useCallback(
    (datum: User): CellProps<NestedKeyOf<User>>[] => {
      const crews = Object.entries(datum.crews)
        .map(([key, value]) => (value ? key : null))
        .filter(Boolean)
        .join(", ");

      return [
        {
          dataKey: "email",
          className: classes.cell,
          value: datum.email,
          align: "left",
        },
        {
          dataKey: "role.name",
          className: classes.cell,
          value: datum.role.name,
          align: "left",
        },
        {
          dataKey: "crews",
          className: classes.cell,
          value: crews !== "" ? crews : "-",
          align: "center",
        },
        ...Object.values(Module).map(
          (moduleValue): CellProps<NestedKeyOf<User>> => ({
            dataKey: "permissions",
            className: classes.cell,
            value: datum.permissions[moduleValue]?.CAN_WRITE ? (
              <EditDocumentIcon color="secondary" />
            ) : datum.permissions[moduleValue]?.CAN_READ ? (
              <VisibilityRounded color="secondary" />
            ) : (
              "-"
            ),
            align: "center",
          })
        ),
        {
          dataKey: "updatedBy",
          className: classes.cell,
          value: datum.updatedBy?.name ?? "-",
          align: "left",
        },
        {
          className: clsx(classes.cell, classes.smallCell),
          value: (
            <>
              {canManageUsers && (
                <>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEditUser(datum)}
                  >
                    <CreateRounded />
                  </IconButton>
                  {actualUser?.id !== datum.id ? (
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        setDeletionDialogData({ open: true, user: datum })
                      }
                    >
                      <DeleteRounded />
                    </IconButton>
                  ) : (
                    <div className={classes.emptyIcon}></div>
                  )}
                </>
              )}
            </>
          ),
          align: "center",
        },
      ];
    },
    [classes, handleEditUser, canManageUsers, actualUser]
  );

  const onCloseDialog = useCallback(
    (e?: unknown, reason?: "backdropClick" | "escapeKeyDown") => {
      if (reason !== "backdropClick") {
        setEditDialogData({ open: false, user: null });
      }
    },
    [setEditDialogData]
  );

  return (
    <>
      <CustomTable
        tableKey="users-info"
        data={data}
        headers={headerCells}
        renderCells={getCellDetails}
        loadingData={loading}
      />
      <CustomDialog
        title="Eliminar usuario"
        open={deletionDialogData.open}
        onCompleteInfo={{
          text: "OK",
          onClick: () => handleOnDeleteUser(),
        }}
        onCancelInfo={{
          text: "CANCELAR",
          onClick: () => setDeletionDialogData({ open: false }),
        }}
        onClose={() => setDeletionDialogData({ open: false })}
        classes={{
          actions: { completeActionButton: classes.button },
        }}
        keepMounted
      >
        <Typography className={classes.infoText}>
          ¿Estás seguro de eliminar a <i>{deletionDialogData.user?.email}</i>?
        </Typography>
      </CustomDialog>
      <UserEditDialog
        action={DialogActions.EDIT}
        user={editDialogData.user}
        open={editDialogData.open}
        onClose={onCloseDialog}
        refetchUsers={refetchUsers}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  cell: {
    paddingTop: 5,
    paddingBottom: 5,
    width: 139,
    borderBottom: 0,
  },
  smallCell: {
    width: 132,
  },
  button: {
    color: palette.common.white,
    minHeight: 42,
    marginLeft: "auto",
  },
  infoText: {
    marginBottom: 8,
  },
  emptyIcon: {
    display: "inline-flex",
    height: 24,
    width: 48,
  },
}));
