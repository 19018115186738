import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const DailyPlanIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 16.6667C0 17.2778 0.5 17.7778 1.11111 17.7778H6.66667V15.5556H1.11111C0.5 15.5556 0 16.0556 0 16.6667ZM0 3.33333C0 3.94444 0.5 4.44444 1.11111 4.44444H11.1111V2.22222H1.11111C0.5 2.22222 0 2.72222 0 3.33333ZM11.1111 18.8889V17.7778H18.8889C19.5 17.7778 20 17.2778 20 16.6667C20 16.0556 19.5 15.5556 18.8889 15.5556H11.1111V14.4444C11.1111 13.8333 10.6111 13.3333 10 13.3333C9.38889 13.3333 8.88889 13.8333 8.88889 14.4444V18.8889C8.88889 19.5 9.38889 20 10 20C10.6111 20 11.1111 19.5 11.1111 18.8889ZM4.44444 7.77778V8.88889H1.11111C0.5 8.88889 0 9.38889 0 10C0 10.6111 0.5 11.1111 1.11111 11.1111H4.44444V12.2222C4.44444 12.8333 4.94444 13.3333 5.55556 13.3333C6.16667 13.3333 6.66667 12.8333 6.66667 12.2222V7.77778C6.66667 7.16667 6.16667 6.66667 5.55556 6.66667C4.94444 6.66667 4.44444 7.16667 4.44444 7.77778ZM20 10C20 9.38889 19.5 8.88889 18.8889 8.88889H8.88889V11.1111H18.8889C19.5 11.1111 20 10.6111 20 10ZM14.4444 6.66667C15.0556 6.66667 15.5556 6.16667 15.5556 5.55556V4.44444H18.8889C19.5 4.44444 20 3.94444 20 3.33333C20 2.72222 19.5 2.22222 18.8889 2.22222H15.5556V1.11111C15.5556 0.5 15.0556 0 14.4444 0C13.8333 0 13.3333 0.5 13.3333 1.11111V5.55556C13.3333 6.16667 13.8333 6.66667 14.4444 6.66667Z" />
    </SvgIcon>
  );
};
