import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { SkeletonCardComponent } from "components/CardComponent/SkeletonCardComponent";

export interface KpisPaperProps {
  loading: boolean;
  children?: React.ReactNode;
  numberCards?: number;
}

export const CardsContainer: React.FC<KpisPaperProps> = ({
  loading: firstLoadingKpis,
  children,
  numberCards = 4,
}) => {
  const classes = useStyles();
  return (
    <>
      {firstLoadingKpis ? (
        <Grid container className={classes.mainContainer}>
          <Grid container spacing={3}>
            {Array.from(Array(numberCards).keys()).map((i) => (
              <SkeletonCardComponent
                key={`${i}-kpiCardSkeleton`}
                numberCards={numberCards}
              />
            ))}
          </Grid>
        </Grid>
      ) : (
        children
      )}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    mainContainer: {
      marginLeft: "15px",
      borderRadius: "4px",
      padding: "4px 8px 8px 8px",
    },
    gridContainer: {
      background: palette.background.default,
      boxShadow:
        " 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
      borderRadius: "4px",
      padding: "8px",
    },
  };
});
