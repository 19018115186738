import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePageVisibility } from "react-page-visibility";
import { useLocation, matchPath, useParams, Params } from "react-router-dom";
import { isEmpty } from "lodash";
import qs, { ParsedQs } from "qs";

import { TRACKING_DELAY_SECONDS } from "App";
import { useData, useDebouncedCallback } from "hooks";
import { AppRoute } from "utils";

interface Props {
  children?: React.ReactNode;
}

enum Visibility {
  VISIBLE = "VISIBLE",
  HIDDEN = "HIDDEN",
}

interface ClickEventData {
  route: string | undefined;
  searchStrings?: ParsedQs;
  urlParams?: Readonly<Params<string>>;
  cursorX: number;
  cursorY: number;
  pageWidth: number;
  pageHeight: number;
}

export const VisibilityTracker: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const isVisible = usePageVisibility();
  const params = useParams();

  const [unsentClickEvents, setUnsentClickEvents] = useState<ClickEventData[]>(
    []
  );

  const { refetch: sendVisibilityData } = useData({
    config: {
      url: "/tracking/visibility",
      method: "POST",
    },
    options: {
      manual: true,
    },
  });

  const { refetch: sendClickData } = useData({
    config: {
      url: "/tracking/click",
      method: "POST",
    },
    options: {
      manual: true,
    },
  });

  const currentRoute = useMemo(() => {
    if (location.pathname) {
      const matchedRoute = Object.entries(AppRoute).find((entry) =>
        matchPath(entry[1], location.pathname)
      );
      return matchedRoute?.[0];
    }
  }, [location]);

  const formatSearchString = useCallback(
    (searchString: string) =>
      searchString ? qs.parse(searchString.slice(1)) : undefined,
    []
  );

  // On Route change
  useEffect(() => {
    const intervalId = setInterval(
      () =>
        sendVisibilityData({
          data: {
            visibility: isVisible ? Visibility.VISIBLE : Visibility.HIDDEN,
            route: currentRoute,
            duration: TRACKING_DELAY_SECONDS,
            searchStrings: formatSearchString(location.search),
            urlParams: isEmpty(params) ? undefined : params,
          },
        }),
      1000 * TRACKING_DELAY_SECONDS
    );
    return () => clearInterval(intervalId);
  }, [
    currentRoute,
    formatSearchString,
    isVisible,
    location.search,
    params,
    sendVisibilityData,
  ]);

  const saveClickEvents = useDebouncedCallback(async () => {
    await sendClickData({
      data: {
        clickEvents: unsentClickEvents,
      },
    });
    setUnsentClickEvents([]);
  }, 2000);

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      const eventData: ClickEventData = {
        route: currentRoute,
        searchStrings: formatSearchString(location.search),
        urlParams: isEmpty(params) ? undefined : params,
        cursorX: e.pageX,
        cursorY: e.pageY,
        pageWidth: window.innerWidth,
        pageHeight: window.innerHeight,
      };
      setUnsentClickEvents((prevState) => [...prevState, eventData]);
      saveClickEvents();
    },
    [currentRoute, formatSearchString, location.search, params, saveClickEvents]
  );

  return (
    <span style={{ display: "contents" }} onClickCapture={clickHandler}>
      {children}
    </span>
  );
};
