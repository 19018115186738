import {
  PermissionHash,
  NotificationEnum,
  CrewEnum,
  Role,
  Module,
} from "interfaces";

export type NotificationsHash = {
  [key in NotificationEnum]: boolean;
};

export type CrewHash = {
  [key in CrewEnum]: boolean;
};

export interface User {
  id: number;
  name: string;
  email: string;
  role: Pick<Role, "id" | "name">;
  crews: CrewHash;
  updatedBy: {
    id: number;
    name: string;
  } | null;
  permissions: PermissionHash;
  notifications: NotificationsHash;
  isOperationalSupervisor?: boolean;
  allowedForNpe?: boolean;
}

export enum AppParamsType {
  INT = "INT",
  FLOAT = "FLOAT",
  STRING_ARRAY = "STRING_ARRAY",
}
export type AppParamsValue =
  | { type: AppParamsType.INT; value: number; unit: string }
  | { type: AppParamsType.FLOAT; value: number; unit: string }
  | {
      type: AppParamsType.STRING_ARRAY;
      value: string[];
      options: string[];
    };

export type AppParam = {
  id: number;
  name: string;
  title: string;
  module: Module;
  comparisonOperator?: string;
  allowDecimal: number;
  updatedAt: string;
  updatedBy: string;
} & AppParamsValue;

export type UpdateAppParamsBody = {
  id: number;
  value: string | string[];
}[];
