import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const CalculatorIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="57"
      height="40"
      viewBox="0 0 57 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5157_30124)">
        <path
          d="M1.5805 13.6756C1.01003 14.003 0.947684 14.7667 1.43711 15.3777L8.11443 23.7353L33.5487 9.04952L26.8745 0.660784C26.3851 0.0497872 25.5247 -0.177778 24.9574 0.149542L1.5805 13.6756Z"
          fill="#484853"
        />
        <path
          d="M0.171452 15.5367L1.2781 13.9531C1.01001 14.3366 1.05677 14.9039 1.43709 15.3809L8.11441 23.7384L29.7612 36.3293L29.7674 38.9074L9.2273 26.9555C8.49473 26.5284 7.8432 25.9798 7.30079 25.3283L0.330436 16.9645C-0.0529959 16.4875 -0.0966385 15.9202 0.171452 15.5367Z"
          fill="#282837"
        />
        <path
          d="M55.1643 24.9448C55.9655 24.4834 56.3645 23.8755 56.3614 23.2708L56.3676 25.8488C56.3676 26.4535 55.9686 27.0614 55.1705 27.5228L35.5095 38.9073L35.5033 36.3293L55.1643 24.9448Z"
          fill="#282837"
        />
        <path
          d="M29.7611 36.3262L29.7674 38.9042C31.3479 39.8238 33.9165 39.8238 35.5064 38.9042L35.5001 36.3262C33.9103 37.2427 31.3416 37.2427 29.7611 36.3262Z"
          fill="#282837"
        />
        <path
          d="M8.11444 23.7353L29.7612 36.3262C31.3417 37.2459 33.9104 37.2459 35.5002 36.3262L55.1612 24.9418C56.751 24.0221 56.7604 22.5352 55.1799 21.6156L33.5332 9.02466L8.11444 23.7353Z"
          fill="#5B5B6E"
        />
        <path
          d="M33.7078 11.986V12.8121C33.848 12.7342 33.9166 12.6282 33.9135 12.5253V11.6992C33.9135 11.8021 33.8418 11.9081 33.7078 11.986Z"
          fill="#282837"
        />
        <path
          d="M28.5766 13.2114V14.0375C28.5766 14.1404 28.6452 14.2433 28.7793 14.3212V13.4951C28.6421 13.4172 28.5766 13.3143 28.5766 13.2114Z"
          fill="#282837"
        />
        <path
          d="M28.7761 13.4919L28.7792 14.318L29.4463 14.7046L29.4432 13.8816L28.7761 13.4919Z"
          fill="#282837"
        />
        <path
          d="M30.4314 13.8817L30.4345 14.7046L33.7109 12.8124L33.7077 11.9863L30.4314 13.8817Z"
          fill="#282837"
        />
        <path
          d="M29.4433 13.8816V14.7077C29.7176 14.8667 30.1603 14.8667 30.4346 14.7077V13.8816C30.1603 14.0406 29.7145 14.0406 29.4433 13.8816Z"
          fill="#282837"
        />
        <path
          d="M28.7823 12.9214C28.508 13.0804 28.508 13.336 28.7761 13.4919L29.4432 13.8784C29.7144 14.0374 30.1571 14.0374 30.4314 13.8784L33.7077 11.9862C33.982 11.8272 33.9789 11.5716 33.7077 11.4158L33.0406 11.0292C32.7725 10.8733 32.3299 10.8702 32.0555 11.0292L28.7761 12.9214H28.7823Z"
          fill="#73738B"
        />
        <path
          d="M28.402 15.0505V15.8766C28.5423 15.7986 28.6109 15.6926 28.6109 15.5898V14.7637C28.6109 14.8665 28.5392 14.9725 28.402 15.0505Z"
          fill="#282837"
        />
        <path
          d="M23.2678 16.2756V17.1017C23.2678 17.2046 23.3363 17.3075 23.4704 17.3854V16.5593C23.3332 16.4814 23.2678 16.3785 23.2678 16.2756Z"
          fill="#282837"
        />
        <path
          d="M23.4705 16.5562L23.4736 17.3822L24.1376 17.7688L24.1344 16.9458L23.4705 16.5562Z"
          fill="#282837"
        />
        <path
          d="M25.1226 16.9459L25.1257 17.7689L28.4051 15.8766L28.402 15.0505L25.1226 16.9459Z"
          fill="#282837"
        />
        <path
          d="M24.1344 16.9458V17.7719C24.4087 17.9309 24.8514 17.9309 25.1257 17.7719V16.9458C24.8514 17.1048 24.4056 17.1048 24.1344 16.9458Z"
          fill="#282837"
        />
        <path
          d="M23.4735 15.9856C23.1992 16.1446 23.1992 16.4002 23.4673 16.5561L24.1344 16.9426C24.4056 17.1016 24.8483 17.1016 25.1226 16.9426L28.3989 15.0504C28.6732 14.8914 28.6732 14.6358 28.402 14.4768L27.7349 14.0903C27.4668 13.9344 27.021 13.9344 26.7498 14.0903L23.4735 15.9825V15.9856Z"
          fill="#73738B"
        />
        <path
          d="M23.0932 18.1147V18.9408C23.2335 18.8628 23.302 18.7568 23.302 18.654V17.8279C23.302 17.9308 23.2303 18.0367 23.0932 18.1147Z"
          fill="#282837"
        />
        <path
          d="M17.962 19.3367V20.1628C17.962 20.2656 18.0306 20.3685 18.1647 20.4464V19.6203C18.0275 19.5424 17.962 19.4395 17.962 19.3367Z"
          fill="#282837"
        />
        <path
          d="M18.1646 19.6204V20.4465L18.8318 20.833L18.8286 20.0069L18.1646 19.6204Z"
          fill="#282837"
        />
        <path
          d="M19.8168 20.0103L19.82 20.8333L23.0963 18.9411L23.0931 18.115L19.8168 20.0103Z"
          fill="#282837"
        />
        <path
          d="M18.8287 20.0068V20.8329C19.103 20.9919 19.5457 20.9919 19.8169 20.8329V20.0068C19.5425 20.1627 19.0999 20.1627 18.8287 20.0068Z"
          fill="#282837"
        />
        <path
          d="M18.1682 19.0501C17.897 19.2059 17.8939 19.4647 18.1651 19.6206L18.8322 20.0071C19.1034 20.1661 19.5461 20.1661 19.8173 20.0071L23.0967 18.1149C23.371 17.9559 23.371 17.7003 23.0998 17.5413L22.4327 17.1547C22.1646 16.9989 21.7188 16.9989 21.4476 17.1547L18.1713 19.047L18.1682 19.0501Z"
          fill="#73738B"
        />
        <path
          d="M17.7875 21.1791V22.0052C17.9247 21.9273 17.9933 21.8213 17.9933 21.7184V20.8923C17.9933 20.9952 17.9216 21.1012 17.7875 21.1791Z"
          fill="#282837"
        />
        <path
          d="M12.6564 22.4011V23.2272C12.6564 23.3301 12.725 23.433 12.8591 23.5109V22.6848C12.7219 22.6069 12.6564 22.504 12.6564 22.4011Z"
          fill="#282837"
        />
        <path
          d="M12.8559 22.6848L12.859 23.5109L13.523 23.8975V23.0714L12.8559 22.6848Z"
          fill="#282837"
        />
        <path
          d="M14.5112 23.0714V23.8975L17.7907 22.0053L17.7875 21.1792L14.5112 23.0714Z"
          fill="#282837"
        />
        <path
          d="M13.523 23.0715V23.8976C13.7973 24.0566 14.24 24.0566 14.5143 23.8976V23.0715C14.24 23.2305 13.7942 23.2305 13.523 23.0715Z"
          fill="#282837"
        />
        <path
          d="M12.8621 22.1146C12.5878 22.2736 12.5878 22.5292 12.8559 22.6851L13.523 23.0716C13.7942 23.2306 14.2369 23.2306 14.5112 23.0716L17.7906 21.1794C18.0618 21.0235 18.0618 20.7648 17.7906 20.6089L17.1235 20.2224C16.8554 20.0665 16.4097 20.0665 16.1385 20.2224L12.8621 22.1146Z"
          fill="#73738B"
        />
        <path
          d="M27.5323 17.0706V17.7127C27.5323 17.8 27.5915 17.8904 27.7069 17.9559V17.3137C27.5915 17.2482 27.5323 17.1578 27.5323 17.0706Z"
          fill="#282837"
        />
        <path
          d="M34.1286 17.3139V17.9561C34.247 17.8875 34.3063 17.8002 34.3063 17.7098V17.0676C34.3063 17.158 34.247 17.2453 34.1286 17.3139Z"
          fill="#282837"
        />
        <path
          d="M27.7068 17.3137V17.9559L30.4906 19.5738L30.4875 18.9316L27.7068 17.3137Z"
          fill="#282837"
        />
        <path
          d="M31.3292 18.9316L31.3323 19.5738L34.1317 17.9559L34.1286 17.3137L31.3292 18.9316Z"
          fill="#282837"
        />
        <path
          d="M30.4875 18.9316V19.5738C30.7213 19.7079 31.0985 19.7079 31.3323 19.5738V18.9316C31.0985 19.0657 30.7182 19.0657 30.4875 18.9316Z"
          fill="#282837"
        />
        <path
          d="M27.7068 16.8274C27.473 16.9614 27.473 17.1827 27.7068 17.3168L30.4875 18.9347C30.7182 19.0687 31.0954 19.0687 31.3292 18.9347L34.1285 17.3168C34.3623 17.1827 34.3623 16.9645 34.1285 16.8305L31.3479 15.2126C31.1172 15.0785 30.7369 15.0785 30.5062 15.2126L27.7068 16.8305V16.8274Z"
          fill="#73738B"
        />
        <path
          d="M22.9996 19.6887V20.3309C22.9996 20.4182 23.0589 20.5086 23.1742 20.574V19.9319C23.0589 19.8664 22.9996 19.776 22.9996 19.6887Z"
          fill="#282837"
        />
        <path
          d="M29.5959 19.9321V20.5742C29.7144 20.5056 29.7736 20.4184 29.7736 20.328V19.6858C29.7736 19.7762 29.7144 19.8635 29.5959 19.9321Z"
          fill="#282837"
        />
        <path
          d="M23.1711 19.9319L23.1742 20.5741L25.9549 22.192V21.5498L23.1711 19.9319Z"
          fill="#282837"
        />
        <path
          d="M26.7966 21.5467L26.7997 22.1888L29.599 20.5741L29.5959 19.9319L26.7966 21.5467Z"
          fill="#282837"
        />
        <path
          d="M25.9549 21.5498V22.192C26.1887 22.326 26.5659 22.326 26.7997 22.192V21.5498C26.5628 21.6838 26.1856 21.6838 25.9549 21.5498Z"
          fill="#282837"
        />
        <path
          d="M23.1743 19.4426C22.9405 19.5767 22.9405 19.798 23.1743 19.932L25.9549 21.5499C26.1856 21.684 26.5628 21.684 26.7966 21.5499L29.596 19.9351C29.8298 19.8011 29.8298 19.5798 29.5991 19.4457L26.8184 17.8278C26.5877 17.6938 26.2074 17.6938 25.9736 17.8278L23.1743 19.4426Z"
          fill="#73738B"
        />
        <path
          d="M18.467 22.3074V22.9495C18.467 23.0368 18.5263 23.1272 18.6416 23.1927V22.5505C18.5263 22.4851 18.467 22.3947 18.467 22.3074Z"
          fill="#282837"
        />
        <path
          d="M25.0634 22.5507V23.1929C25.1818 23.1243 25.241 23.037 25.241 22.9466V22.3044C25.241 22.3948 25.1818 22.4821 25.0634 22.5507Z"
          fill="#282837"
        />
        <path
          d="M18.6385 22.5505L18.6416 23.1927L21.4223 24.8075V24.1653L18.6385 22.5505Z"
          fill="#282837"
        />
        <path
          d="M22.264 24.1653L22.2671 24.8075L25.0665 23.1927L25.0633 22.5505L22.264 24.1653Z"
          fill="#282837"
        />
        <path
          d="M21.4223 24.1655V24.8077C21.6561 24.9417 22.0333 24.9417 22.2671 24.8077V24.1655C22.0333 24.2996 21.653 24.2996 21.4223 24.1655Z"
          fill="#282837"
        />
        <path
          d="M18.6417 22.0615C18.4079 22.1955 18.4079 22.4169 18.6417 22.5509L21.4223 24.1688C21.653 24.3029 22.0333 24.3029 22.264 24.1688L25.0634 22.554C25.2972 22.42 25.2972 22.1987 25.0665 22.0646L22.2858 20.4467C22.0551 20.3127 21.6748 20.3127 21.441 20.4467L18.6417 22.0615Z"
          fill="#73738B"
        />
        <path
          d="M13.9345 24.9231V25.5653C13.9345 25.6526 13.9937 25.743 14.1091 25.8084V25.1662C13.9937 25.1008 13.9345 25.0104 13.9345 24.9231Z"
          fill="#282837"
        />
        <path
          d="M20.5308 25.1662V25.8084C20.6492 25.7398 20.7084 25.6525 20.7084 25.5621V24.9199C20.7084 25.0103 20.6492 25.0976 20.5308 25.1662Z"
          fill="#282837"
        />
        <path
          d="M14.106 25.1663V25.8084L16.8898 27.4263L16.8866 26.7842L14.106 25.1663Z"
          fill="#282837"
        />
        <path
          d="M17.7314 26.7842V27.4263L20.5339 25.8084L20.5308 25.1663L17.7314 26.7842Z"
          fill="#282837"
        />
        <path
          d="M16.8866 26.7842V27.4264C17.1204 27.5604 17.4976 27.5604 17.7314 27.4264V26.7842C17.4976 26.9182 17.1173 26.9182 16.8866 26.7842Z"
          fill="#282837"
        />
        <path
          d="M14.109 24.677C13.8752 24.811 13.8752 25.0324 14.109 25.1664L16.8897 26.7843C17.1204 26.9183 17.5007 26.9183 17.7314 26.7843L20.5307 25.1664C20.7645 25.0324 20.7645 24.8141 20.5307 24.6801L17.7501 23.0622C17.5194 22.9282 17.1391 22.9282 16.9084 23.0622L14.109 24.6801V24.677Z"
          fill="#73738B"
        />
        <path
          d="M32.414 19.8887V20.5308C32.414 20.6181 32.4732 20.7085 32.5886 20.774V20.1318C32.4732 20.0664 32.414 19.976 32.414 19.8887Z"
          fill="#282837"
        />
        <path
          d="M39.0103 20.1318V20.7739C39.1287 20.7054 39.188 20.6181 39.188 20.5277V19.8855C39.188 19.9759 39.1287 20.0632 39.0103 20.1318Z"
          fill="#282837"
        />
        <path
          d="M32.5855 20.1318L32.5886 20.774L35.3693 22.3919L35.3661 21.7497L32.5855 20.1318Z"
          fill="#282837"
        />
        <path
          d="M36.2109 21.7497V22.3919L39.0103 20.774V20.1318L36.2109 21.7497Z"
          fill="#282837"
        />
        <path
          d="M35.3661 21.7498V22.3919C35.5999 22.526 35.9771 22.526 36.2109 22.3919V21.7498C35.9771 21.8838 35.5968 21.8838 35.3661 21.7498Z"
          fill="#282837"
        />
        <path
          d="M32.5855 19.6423C32.3517 19.7764 32.3517 19.9977 32.5855 20.1317L35.3661 21.7496C35.5968 21.8837 35.974 21.8837 36.2078 21.7496L39.0072 20.1317C39.241 19.9977 39.241 19.7795 39.0072 19.6454L36.2265 18.0275C35.9958 17.8935 35.6155 17.8935 35.3848 18.0275L32.5855 19.6454V19.6423Z"
          fill="#73738B"
        />
        <path
          d="M27.8783 22.5071V23.1493C27.8783 23.2365 27.9376 23.3269 28.0529 23.3924V22.7502C27.9376 22.6848 27.8783 22.5944 27.8783 22.5071Z"
          fill="#282837"
        />
        <path
          d="M34.4777 22.7504V23.3926C34.5961 23.324 34.6553 23.2367 34.6553 23.1463V22.5042C34.6553 22.5946 34.5961 22.6818 34.4777 22.7504Z"
          fill="#282837"
        />
        <path
          d="M28.0529 22.7502V23.3924L30.8367 25.0072L30.8336 24.365L28.0529 22.7502Z"
          fill="#282837"
        />
        <path
          d="M31.6784 24.365V25.0072L34.4777 23.3924V22.7502L31.6784 24.365Z"
          fill="#282837"
        />
        <path
          d="M30.8335 24.3652V25.0074C31.0673 25.1414 31.4445 25.1414 31.6783 25.0074V24.3652C31.4414 24.4993 31.0642 24.4993 30.8335 24.3652Z"
          fill="#282837"
        />
        <path
          d="M28.0561 22.261C27.8223 22.395 27.8223 22.6163 28.0561 22.7504L30.8367 24.3683C31.0674 24.5023 31.4446 24.5023 31.6784 24.3683L34.4778 22.7535C34.7116 22.6195 34.7116 22.3981 34.4809 22.2641L31.7002 20.6462C31.4695 20.5121 31.0892 20.5121 30.8554 20.6462L28.0561 22.261Z"
          fill="#73738B"
        />
        <path
          d="M23.3457 25.1223V25.7645C23.3457 25.8518 23.4049 25.9422 23.5203 26.0076V25.3655C23.4049 25.3 23.3457 25.2096 23.3457 25.1223Z"
          fill="#282837"
        />
        <path
          d="M29.942 25.3654V26.0076C30.0604 25.939 30.1197 25.8517 30.1197 25.7613V25.1191C30.1197 25.2095 30.0604 25.2968 29.942 25.3654Z"
          fill="#282837"
        />
        <path
          d="M23.5171 25.3655L23.5202 26.0076L26.3009 27.6255V26.9834L23.5171 25.3655Z"
          fill="#282837"
        />
        <path
          d="M27.1426 26.9834L27.1457 27.6255L29.9451 26.0076L29.9419 25.3655L27.1426 26.9834Z"
          fill="#282837"
        />
        <path
          d="M26.3009 26.9834V27.6256C26.5347 27.7596 26.9119 27.7596 27.1457 27.6256V26.9834C26.9119 27.1174 26.5316 27.1174 26.3009 26.9834Z"
          fill="#282837"
        />
        <path
          d="M23.5203 24.8762C23.2865 25.0102 23.2865 25.2316 23.5203 25.3656L26.3009 26.9835C26.5316 27.1176 26.9119 27.1176 27.1426 26.9835L29.942 25.3687C30.1758 25.2347 30.1758 25.0134 29.9451 24.8793L27.1644 23.2614C26.9337 23.1274 26.5534 23.1274 26.3196 23.2614L23.5203 24.8762Z"
          fill="#73738B"
        />
        <path
          d="M18.8131 27.741V28.3831C18.8131 28.4704 18.8724 28.5608 18.9877 28.6263V27.9841C18.8724 27.9187 18.8131 27.8283 18.8131 27.741Z"
          fill="#282837"
        />
        <path
          d="M25.4094 27.9843V28.6265C25.5278 28.5579 25.5871 28.4706 25.5871 28.3802V27.738C25.5871 27.8284 25.5278 27.9157 25.4094 27.9843Z"
          fill="#282837"
        />
        <path
          d="M18.9846 27.9841L18.9877 28.6263L21.7683 30.2411V29.602L18.9846 27.9841Z"
          fill="#282837"
        />
        <path
          d="M22.61 29.5989L22.6131 30.2411L25.4125 28.6263L25.4094 27.9841L22.61 29.5989Z"
          fill="#282837"
        />
        <path
          d="M21.7683 29.6021V30.2442C22.0021 30.3783 22.3793 30.3783 22.6131 30.2442V29.6021C22.3793 29.7361 21.999 29.7361 21.7683 29.6021Z"
          fill="#282837"
        />
        <path
          d="M18.9877 27.4949C18.7539 27.6289 18.7539 27.8502 18.9877 27.9843L21.7683 29.6022C21.999 29.7362 22.3793 29.7362 22.61 29.6022L25.4094 27.9843C25.6432 27.8502 25.6432 27.632 25.4094 27.498L22.6287 25.8801C22.398 25.746 22.0177 25.746 21.787 25.8801L18.9877 27.498V27.4949Z"
          fill="#73738B"
        />
        <path
          d="M37.4579 22.7815V23.4237C37.4579 23.5109 37.5171 23.6014 37.6324 23.6668V23.0246C37.5171 22.9592 37.4579 22.8688 37.4579 22.7815Z"
          fill="#282837"
        />
        <path
          d="M44.0541 23.0248V23.667C44.1726 23.5984 44.2318 23.5111 44.2318 23.4207V22.7786C44.2318 22.869 44.1726 22.9563 44.0541 23.0248Z"
          fill="#282837"
        />
        <path
          d="M37.6324 23.0247V23.6668L40.4162 25.2847L40.413 24.6426L37.6324 23.0247Z"
          fill="#282837"
        />
        <path
          d="M41.2547 24.6426L41.2579 25.2847L44.0572 23.6668L44.0541 23.0247L41.2547 24.6426Z"
          fill="#282837"
        />
        <path
          d="M40.4131 24.6426V25.2847C40.6469 25.4188 41.0241 25.4188 41.2579 25.2847V24.6426C41.0241 24.7766 40.6438 24.7766 40.4131 24.6426Z"
          fill="#282837"
        />
        <path
          d="M37.6324 22.5383C37.3986 22.6724 37.3986 22.8937 37.6324 23.0277L40.4131 24.6456C40.6438 24.7797 41.021 24.7797 41.2548 24.6456L44.0541 23.0277C44.2879 22.8937 44.2879 22.6755 44.0541 22.5414L41.2735 20.9235C41.0428 20.7895 40.6625 20.7895 40.4318 20.9235L37.6324 22.5414V22.5383Z"
          fill="#73738B"
        />
        <path
          d="M32.9252 25.4001V26.0423C32.9252 26.1296 32.9845 26.22 33.0998 26.2855V25.6433C32.9845 25.5778 32.9252 25.4874 32.9252 25.4001Z"
          fill="#282837"
        />
        <path
          d="M39.5215 25.6432V26.2854C39.64 26.2168 39.6992 26.1295 39.6992 26.0391V25.397C39.6992 25.4874 39.64 25.5747 39.5215 25.6432Z"
          fill="#282837"
        />
        <path
          d="M33.0967 25.6433L33.0998 26.2855L35.8805 27.9034V27.2612L33.0967 25.6433Z"
          fill="#282837"
        />
        <path
          d="M36.7222 27.2581L36.7253 27.9003L39.5246 26.2855L39.5215 25.6433L36.7222 27.2581Z"
          fill="#282837"
        />
        <path
          d="M35.8805 27.261V27.9032C36.1143 28.0372 36.4915 28.0372 36.7253 27.9032V27.261C36.4884 27.395 36.1112 27.395 35.8805 27.261Z"
          fill="#282837"
        />
        <path
          d="M33.0998 25.1538C32.866 25.2878 32.866 25.5092 33.0998 25.6432L35.8805 27.2611C36.1112 27.3951 36.4884 27.3951 36.7222 27.2611L39.5216 25.6463C39.7554 25.5123 39.7554 25.2909 39.5247 25.1569L36.744 23.539C36.5133 23.405 36.133 23.405 35.8992 23.539L33.0998 25.1538Z"
          fill="#73738B"
        />
        <path
          d="M28.3927 28.0183V28.6605C28.3927 28.7478 28.4519 28.8382 28.5672 28.9036V28.2615C28.4519 28.196 28.3927 28.1056 28.3927 28.0183Z"
          fill="#282837"
        />
        <path
          d="M34.989 28.2614V28.9036C35.1074 28.835 35.1666 28.7477 35.1666 28.6573V28.0151C35.1666 28.1055 35.1074 28.1928 34.989 28.2614Z"
          fill="#282837"
        />
        <path
          d="M28.5641 28.2615L28.5672 28.9036L31.3479 30.5184L31.3448 29.8762L28.5641 28.2615Z"
          fill="#282837"
        />
        <path
          d="M32.1896 29.8762V30.5184L34.9889 28.9036V28.2615L32.1896 29.8762Z"
          fill="#282837"
        />
        <path
          d="M31.3448 29.8762V30.5184C31.5786 30.6524 31.9558 30.6524 32.1896 30.5184V29.8762C31.9558 30.0103 31.5755 30.0103 31.3448 29.8762Z"
          fill="#282837"
        />
        <path
          d="M28.5673 27.7722C28.3335 27.9062 28.3335 28.1276 28.5673 28.2616L31.3479 29.8795C31.5786 30.0136 31.9589 30.0136 32.1896 29.8795L34.989 28.2647C35.2228 28.1307 35.2228 27.9094 34.9921 27.7753L32.2114 26.1574C31.9807 26.0234 31.6004 26.0234 31.3666 26.1574L28.5673 27.7722Z"
          fill="#73738B"
        />
        <path
          d="M42.3365 25.5994V26.2415C42.3365 26.3288 42.3957 26.4192 42.5111 26.4847V25.8425C42.3957 25.7771 42.3365 25.6867 42.3365 25.5994Z"
          fill="#282837"
        />
        <path
          d="M48.9359 25.8427V26.4849C49.0544 26.4163 49.1136 26.329 49.1136 26.2386V25.5964C49.1136 25.6837 49.0544 25.7741 48.9359 25.8427Z"
          fill="#282837"
        />
        <path
          d="M42.5111 25.8425V26.4847L45.2949 28.1026L45.2917 27.4604L42.5111 25.8425Z"
          fill="#282837"
        />
        <path
          d="M46.1334 27.4604L46.1365 28.1026L48.9359 26.4847V25.8425L46.1334 27.4604Z"
          fill="#282837"
        />
        <path
          d="M45.2917 27.4604V28.1026C45.5255 28.2367 45.9027 28.2367 46.1365 28.1026V27.4604C45.9027 27.5945 45.5224 27.5945 45.2917 27.4604Z"
          fill="#282837"
        />
        <path
          d="M42.5111 25.3533C42.2773 25.4873 42.2773 25.7086 42.5111 25.8427L45.2917 27.4606C45.5224 27.5946 45.8996 27.5946 46.1334 27.4606L48.9328 25.8427C49.1666 25.7086 49.1666 25.4904 48.9328 25.3564L46.1521 23.7385C45.9214 23.6044 45.5411 23.6044 45.3104 23.7385L42.5111 25.3564V25.3533Z"
          fill="#73738B"
        />
        <path
          d="M37.8039 28.2183V28.8604C37.8039 28.9477 37.8631 29.0381 37.9784 29.1036V28.4614C37.8631 28.396 37.8039 28.3055 37.8039 28.2183Z"
          fill="#282837"
        />
        <path
          d="M44.4001 28.4614V29.1035C44.5185 29.0349 44.5778 28.9477 44.5778 28.8573V28.2151C44.5778 28.3055 44.5185 28.3928 44.4001 28.4614Z"
          fill="#282837"
        />
        <path
          d="M37.9785 28.4614V29.1036L40.7622 30.7184L40.7591 30.0762L37.9785 28.4614Z"
          fill="#282837"
        />
        <path
          d="M41.6008 30.0762L41.6039 30.7184L44.4033 29.1036L44.4002 28.4614L41.6008 30.0762Z"
          fill="#282837"
        />
        <path
          d="M40.7591 30.0762V30.7183C40.9929 30.8524 41.3701 30.8524 41.6039 30.7183V30.0762C41.3669 30.2102 40.9897 30.2102 40.7591 30.0762Z"
          fill="#282837"
        />
        <path
          d="M37.9784 27.9719C37.7446 28.1059 37.7446 28.3273 37.9784 28.4613L40.7591 30.0792C40.9898 30.2133 41.367 30.2133 41.6008 30.0792L44.4001 28.4644C44.6339 28.3304 44.6339 28.1091 44.4032 27.975L41.6226 26.3571C41.3919 26.2231 41.0116 26.2231 40.7778 26.3571L37.9784 27.9719Z"
          fill="#73738B"
        />
        <path
          d="M32.0649 14.4675V15.1097C32.0649 15.197 32.1241 15.2874 32.2395 15.3529V14.7107C32.1241 14.6452 32.0649 14.5548 32.0649 14.4675Z"
          fill="#282837"
        />
        <path
          d="M38.6611 14.7106V15.3528C38.7796 15.2842 38.8388 15.1969 38.8388 15.1065V14.4644C38.8388 14.5548 38.7796 14.642 38.6611 14.7106Z"
          fill="#282837"
        />
        <path
          d="M32.2363 14.7107L32.2394 15.3529L35.0201 16.9708V16.3286L32.2363 14.7107Z"
          fill="#282837"
        />
        <path
          d="M35.8618 16.3286L35.8649 16.9708L38.6643 15.3529L38.6611 14.7107L35.8618 16.3286Z"
          fill="#282837"
        />
        <path
          d="M35.0201 16.3286V16.9708C35.2539 17.1048 35.6311 17.1048 35.8649 16.9708V16.3286C35.6311 16.4627 35.2508 16.4627 35.0201 16.3286Z"
          fill="#282837"
        />
        <path
          d="M32.2394 14.2243C32.0056 14.3584 32.0056 14.5797 32.2394 14.7138L35.0201 16.3317C35.2508 16.4657 35.628 16.4657 35.8618 16.3317L38.6611 14.7138C38.8949 14.5797 38.8949 14.3615 38.6611 14.2275L35.8805 12.6096C35.6498 12.4755 35.2695 12.4755 35.0388 12.6096L32.2394 14.2275V14.2243Z"
          fill="#73738B"
        />
        <path
          d="M36.9435 17.2856V17.9278C36.9435 18.0151 37.0027 18.1055 37.1181 18.171V17.5288C37.0027 17.4633 36.9435 17.3729 36.9435 17.2856Z"
          fill="#282837"
        />
        <path
          d="M43.5429 17.5287V18.1709C43.6613 18.1023 43.7206 18.015 43.7206 17.9246V17.2825C43.7206 17.3729 43.6613 17.4602 43.5429 17.5287Z"
          fill="#282837"
        />
        <path
          d="M37.118 17.5288V18.171L39.9018 19.7858L39.8987 19.1436L37.118 17.5288Z"
          fill="#282837"
        />
        <path
          d="M40.7404 19.1436L40.7435 19.7858L43.5429 18.171V17.5288L40.7404 19.1436Z"
          fill="#282837"
        />
        <path
          d="M39.8987 19.1436V19.7857C40.1325 19.9198 40.5097 19.9198 40.7435 19.7857V19.1436C40.5097 19.2776 40.1294 19.2776 39.8987 19.1436Z"
          fill="#282837"
        />
        <path
          d="M37.1181 17.0395C36.8843 17.1736 36.8843 17.3949 37.1181 17.529L39.8987 19.1468C40.1294 19.2809 40.5066 19.2809 40.7404 19.1468L43.5398 17.529C43.7736 17.3949 43.7736 17.1767 43.5398 17.0426L40.7591 15.4248C40.5284 15.2907 40.1481 15.2907 39.9174 15.4248L37.1181 17.0426V17.0395Z"
          fill="#73738B"
        />
        <path
          d="M41.9905 20.1787V20.8209C41.9905 20.9082 42.0497 20.9986 42.1651 21.064V20.4219C42.0497 20.3564 41.9905 20.266 41.9905 20.1787Z"
          fill="#C4C3D2"
        />
        <path
          d="M41.9905 20.1787V20.8209C41.9905 20.9082 42.0497 20.9986 42.1651 21.064V20.4219C42.0497 20.3564 41.9905 20.266 41.9905 20.1787Z"
          fill="#E4872E"
        />
        <path
          d="M48.7644 20.8175V20.1753C48.7644 20.2657 48.7052 20.353 48.5868 20.4216V21.0637C48.7052 20.9952 48.7644 20.9079 48.7644 20.8175Z"
          fill="#C4C3D2"
        />
        <path
          d="M48.5868 20.4216V21.0637C48.7052 20.9952 48.7644 20.9079 48.7644 20.8175V20.1753C48.7644 20.2657 48.7052 20.353 48.5868 20.4216Z"
          fill="#EFA42E"
        />
        <path
          d="M42.1619 20.4219L42.165 21.064L44.9457 22.6819V22.0398L42.1619 20.4219Z"
          fill="#E4872E"
        />
        <path
          d="M45.7874 22.0398V22.6819L48.5899 21.064L48.5867 20.4219L45.7874 22.0398Z"
          fill="#EFA42E"
        />
        <path
          d="M44.9457 22.0396V22.6817C45.1795 22.8158 45.5567 22.8158 45.7905 22.6817V22.0396C45.5567 22.1736 45.1764 22.1736 44.9457 22.0396Z"
          fill="#C4C3D2"
        />
        <path
          d="M44.9457 22.0396V22.6817C45.1795 22.8158 45.5567 22.8158 45.7905 22.6817V22.0396C45.5567 22.1736 45.1764 22.1736 44.9457 22.0396Z"
          fill="url(#paint0_linear_5157_30124)"
        />
        <path
          d="M42.165 19.9353C41.9312 20.0693 41.9312 20.2907 42.165 20.4247L44.9457 22.0426C45.1764 22.1766 45.5536 22.1766 45.7874 22.0426L48.5867 20.4247C48.8205 20.2907 48.8205 20.0724 48.5867 19.9384L45.8061 18.3205C45.5754 18.1865 45.1951 18.1865 44.9644 18.3205L42.165 19.9384V19.9353Z"
          fill="url(#paint1_linear_5157_30124)"
        />
        <path
          d="M46.8691 22.9966V23.6388C46.8691 23.726 46.9283 23.8164 47.0436 23.8819V23.2397C46.9283 23.1743 46.8691 23.0839 46.8691 22.9966Z"
          fill="#C4C3D2"
        />
        <path
          d="M46.8691 22.9966V23.6388C46.8691 23.726 46.9283 23.8164 47.0436 23.8819V23.2397C46.9283 23.1743 46.8691 23.0839 46.8691 22.9966Z"
          fill="#E4872E"
        />
        <path
          d="M53.6431 23.6353V22.9932C53.6431 23.0836 53.5838 23.1709 53.4654 23.2394V23.8816C53.5838 23.813 53.6431 23.7257 53.6431 23.6353Z"
          fill="#C4C3D2"
        />
        <path
          d="M53.4654 23.2394V23.8816C53.5838 23.813 53.6431 23.7257 53.6431 23.6353V22.9932C53.6431 23.0836 53.5838 23.1709 53.4654 23.2394Z"
          fill="#EFA42E"
        />
        <path
          d="M47.0437 23.2397V23.8819L49.8274 25.4967L49.8243 24.8576L47.0437 23.2397Z"
          fill="#E4872E"
        />
        <path
          d="M50.666 24.8576L50.6691 25.4967L53.4685 23.8819L53.4654 23.2397L50.666 24.8576Z"
          fill="#EFA42E"
        />
        <path
          d="M49.8243 24.8577V25.4998C50.0581 25.6339 50.4353 25.6339 50.6691 25.4998V24.8577C50.4353 24.9917 50.055 24.9917 49.8243 24.8577Z"
          fill="#C4C3D2"
        />
        <path
          d="M49.8243 24.8577V25.4998C50.0581 25.6339 50.4353 25.6339 50.6691 25.4998V24.8577C50.4353 24.9917 50.055 24.9917 49.8243 24.8577Z"
          fill="url(#paint2_linear_5157_30124)"
        />
        <path
          d="M47.0437 22.7502C46.8099 22.8843 46.8099 23.1056 47.0437 23.2396L49.8243 24.8575C50.055 24.9916 50.4322 24.9916 50.666 24.8575L53.4654 23.2396C53.6992 23.1056 53.6992 22.8874 53.4654 22.7533L50.6847 21.1354C50.454 21.0014 50.0737 21.0014 49.843 21.1354L47.0437 22.7533V22.7502Z"
          fill="url(#paint3_linear_5157_30124)"
        />
        <path
          d="M33.2713 30.8335V31.4757C33.2713 31.563 33.3305 31.6534 33.4459 31.7188V31.0766C33.3305 31.0112 33.2713 30.9208 33.2713 30.8335Z"
          fill="#282837"
        />
        <path
          d="M39.8676 31.0768V31.719C39.986 31.6504 40.0452 31.5631 40.0452 31.4727V30.8306C40.0452 30.921 39.986 31.0083 39.8676 31.0768Z"
          fill="#282837"
        />
        <path
          d="M33.4428 31.0767L33.4459 31.7188L36.2266 33.3367V32.6946L33.4428 31.0767Z"
          fill="#282837"
        />
        <path
          d="M37.0682 32.6946L37.0714 33.3367L39.8707 31.7188L39.8676 31.0767L37.0682 32.6946Z"
          fill="#282837"
        />
        <path
          d="M36.2265 32.6946V33.3368C36.4603 33.4708 36.8375 33.4708 37.0713 33.3368V32.6946C36.8375 32.8286 36.4572 32.8286 36.2265 32.6946Z"
          fill="#282837"
        />
        <path
          d="M33.4459 30.5874C33.2121 30.7214 33.2121 30.9428 33.4459 31.0768L36.2265 32.6947C36.4572 32.8287 36.8375 32.8287 37.0682 32.6947L39.8676 31.0799C40.1014 30.9459 40.1014 30.7245 39.8707 30.5905L37.09 28.9726C36.8593 28.8386 36.479 28.8386 36.2452 28.9726L33.4459 30.5874Z"
          fill="#73738B"
        />
        <path
          d="M23.857 30.634V31.2762C23.857 31.3635 23.9162 31.4539 24.0315 31.5194V30.8772C23.9162 30.8117 23.857 30.7213 23.857 30.634Z"
          fill="#C4C3D2"
        />
        <path
          d="M23.857 30.634V31.2762C23.857 31.3635 23.9162 31.4539 24.0315 31.5194V30.8772C23.9162 30.8117 23.857 30.7213 23.857 30.634Z"
          fill="#E4872E"
        />
        <path
          d="M35.5095 34.0909V33.4487C35.5095 33.5391 35.4503 33.6264 35.3318 33.695V34.3372C35.4503 34.2686 35.5095 34.1813 35.5095 34.0909Z"
          fill="#C4C3D2"
        />
        <path
          d="M35.3352 33.695V34.3372C35.4537 34.2686 35.5129 34.1813 35.5129 34.0909V33.4487C35.5129 33.5391 35.4537 33.6264 35.3352 33.695Z"
          fill="#EFA42E"
        />
        <path
          d="M24.0284 30.8772L24.0316 31.5194L31.694 35.9522L31.6908 35.31L24.0284 30.8772Z"
          fill="#E4872E"
        />
        <path
          d="M32.5325 35.3098L32.5356 35.952L35.335 34.3372V33.6951L32.5325 35.3098Z"
          fill="#EFA42E"
        />
        <path
          d="M31.6908 35.3098V35.952C31.9246 36.086 32.3018 36.086 32.5356 35.952V35.3098C32.3018 35.4439 31.9215 35.4439 31.6908 35.3098Z"
          fill="#C4C3D2"
        />
        <path
          d="M31.6908 35.3098V35.952C31.9246 36.086 32.3018 36.086 32.5356 35.952V35.3098C32.3018 35.4439 31.9215 35.4439 31.6908 35.3098Z"
          fill="url(#paint4_linear_5157_30124)"
        />
        <path
          d="M24.0315 30.3877C23.7977 30.5217 23.7977 30.7431 24.0315 30.8771L31.6939 35.3099C31.9246 35.444 32.3018 35.444 32.5356 35.3099L35.3349 33.692C35.5687 33.558 35.5687 33.3398 35.3349 33.2057L27.6725 28.7729C27.4419 28.6389 27.0615 28.6389 26.8309 28.7729L24.0315 30.3908V30.3877Z"
          fill="url(#paint5_linear_5157_30124)"
        />
        <path
          d="M4.4266 16.2069L7.81202 20.4964C8.32638 21.1385 9.23664 21.31 9.95051 20.8985L29.7643 9.44542C30.6496 8.93418 30.8709 7.75271 30.2319 6.95467L26.8464 2.66523C26.3321 2.02305 25.4218 1.8516 24.708 2.26309L4.8942 13.7162C4.00888 14.2274 3.78755 15.4089 4.4266 16.2069Z"
          fill="#161620"
        />
        <path
          d="M9.11823 20.699C9.33644 20.699 9.55154 20.6398 9.74169 20.5307L29.5523 9.07762C29.8672 8.89682 30.0885 8.58508 30.154 8.22659C30.2226 7.8681 30.129 7.50025 29.9015 7.21658L26.5129 2.92713C26.276 2.6341 25.9206 2.46265 25.5403 2.46265C25.3221 2.46265 25.107 2.52188 24.9169 2.63098L5.10622 14.0809C4.79137 14.2617 4.57004 14.5735 4.50458 14.932C4.436 15.2905 4.52952 15.6583 4.75708 15.942L8.14562 20.2314C8.38254 20.5245 8.73791 20.6959 9.11823 20.6959V20.699Z"
          fill="url(#paint6_linear_5157_30124)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5157_30124"
          x1="45.065"
          y1="22.7632"
          x2="45.565"
          y2="22.7632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4872E" />
          <stop offset="1" stopColor="#EFA42E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5157_30124"
          x1="48.065"
          y1="19.2629"
          x2="42.565"
          y2="20.7629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C94B" />
          <stop offset="1" stopColor="#EAA233" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5157_30124"
          x1="50.0651"
          y1="25.2629"
          x2="50.5651"
          y2="25.2629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4872E" />
          <stop offset="1" stopColor="#EFA42E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5157_30124"
          x1="53.065"
          y1="22.7629"
          x2="47.065"
          y2="23.7629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C94B" />
          <stop offset="1" stopColor="#EAA233" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5157_30124"
          x1="31.565"
          y1="35.7625"
          x2="32.565"
          y2="35.7625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4872E" />
          <stop offset="1" stopColor="#EFA42E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5157_30124"
          x1="34.565"
          y1="31.2627"
          x2="24.565"
          y2="32.2627"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4C94B" />
          <stop offset="1" stopColor="#EAA233" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5157_30124"
          x1="30.565"
          y1="4.76294"
          x2="6.065"
          y2="19.2629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CECECE" />
          <stop offset="1" stopColor="#E0E0E0" />
        </linearGradient>
        <clipPath id="clip0_5157_30124">
          <rect width="56.3707" height="39.5932" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
