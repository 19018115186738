import React, { createContext, useMemo, useState } from "react";
import { Operator } from "interfaces";
import { ContextProviderProps } from "contexts";

type OperatorAssignmentInterface = {
  selectedOperator: Operator | null;
  setSelectedOperator: React.Dispatch<React.SetStateAction<Operator | null>>;
};

export const OperatorAssignmentContext =
  createContext<OperatorAssignmentInterface>({
    selectedOperator: null,
    setSelectedOperator: () => null,
  });

export const OperatorAssignmentProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [selectedOperator, setSelectedOperator] = useState<Operator | null>(
    null
  );

  const contextState = useMemo<OperatorAssignmentInterface>(
    () => ({
      selectedOperator,
      setSelectedOperator,
    }),
    [selectedOperator, setSelectedOperator]
  );

  return (
    <OperatorAssignmentContext.Provider value={contextState}>
      {children}
    </OperatorAssignmentContext.Provider>
  );
};
