import React from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import {
  getPracticesTranslation,
  OperatorPracticeOptions,
  ScreenType,
} from "interfaces";
import { BarDatum, BarTooltipProps } from "@nivo/bar";

export const BarGraphTooltip: React.FC<
  Pick<BarTooltipProps<BarDatum>, "color" | "indexValue" | "value" | "id">
> = ({ color, indexValue, value, id }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.tooltip}>
      <div className={classes.circle} style={{ backgroundColor: color }}></div>
      <Typography className={classes.tooltipIndex} variant="subtitle2">
        {`${getPracticesTranslation(
          id as OperatorPracticeOptions,
          ScreenType.SUPERVISOR
        )} - ${indexValue} :`}
      </Typography>
      <Typography variant="subtitle2">
        <b>{`${value} minuto${value !== 1 ? "s" : ""}`}</b>
      </Typography>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    display: "flex",
    width: "max-content",
    alignItems: "center",
    padding: 5,
  },
  tooltipIndex: {
    marginRight: 5,
  },
  circle: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    marginRight: 5,
    borderRadius: 24,
  },
}));
