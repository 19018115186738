import React from "react";
import clsx from "clsx";
import { Container, ContainerProps, makeStyles } from "@material-ui/core";

interface Props extends ContainerProps {
  children: NonNullable<React.ReactNode>;
  className?: string;
}

export const ScreenContainer: React.FC<Props> = ({
  children,
  className,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Container
      className={clsx(classes.screenContainerRoot, className)}
      maxWidth="xl"
      disableGutters
      {...other}
    >
      {children}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  screenContainerRoot: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      maxWidth: 'sm'
    }
  },
}));
