import { makeStyles, Typography, Chip } from "@material-ui/core";
import { CustomDialog, CustomTable, SearchBar, Tooltip } from "components";
import {
  ShiftOperativeTruck,
  HeaderCellProps,
  NestedKeyOf,
  CellProps,
  ErrorMsg,
} from "interfaces";
import React, { useMemo, useCallback, useState } from "react";
import { CheckCircleRounded, WarningRounded } from "@material-ui/icons";
import { useData, useSearch } from "hooks";
import clsx from "clsx";
import { formatLocalizedDate, parseTimeDelta } from "utils";
import Fuse from "fuse.js";
import { STANDARD_POLLING } from "App";
import { round } from "lodash";
import { ShiftOperativeInfoDialog } from "./ShiftOperativeInfoDialog";

interface ShiftOperativeTrucksDialogProps {
  open: boolean;
  onClose: () => void;
}

const filterOptions: Fuse.IFuseOptions<ShiftOperativeTruck> = {
  keys: ["equipId", "operator.name"],
  threshold: 0.25,
};

export const ShiftOperativeTrucksDialog: React.FC<
  ShiftOperativeTrucksDialogProps
> = ({ open, onClose }) => {
  const classes = useStyles();

  const [openExplanatoryDialog, setOpenExplanatoryDialog] =
    useState<boolean>(false);

  const onChipClick = useCallback(() => setOpenExplanatoryDialog(true), []);

  const {
    data: operativeTrucks,
    firstLoading: loadingOperativeTrucks,
    polling: pollingOperativeTrucks,
    refetching: refetchingOperativeTrucks,
  } = useData<ShiftOperativeTruck[]>(
    {
      config: "/operator-assignment/trucks/shift-operative-assignments",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_CAEXS
  );

  const adherencePercentage = useMemo<number>(() => {
    const { numerator, denominator } = operativeTrucks.reduce(
      (prev, elem) => {
        return {
          numerator: prev.numerator + Number(elem.assignedWithTub),
          denominator: prev.denominator + Number(elem.includedForAdherence),
        };
      },
      {
        numerator: 0,
        denominator: 0,
      }
    );

    return denominator === 0 ? 0 : round((100 * numerator) / denominator);
  }, [operativeTrucks]);

  const [searchValue, setSearchValue, , searchResults, isTyping] =
    useSearch<ShiftOperativeTruck>(operativeTrucks, filterOptions);

  const getCellDetails = useCallback(
    (
      datum: ShiftOperativeTruck
    ): CellProps<NestedKeyOf<ShiftOperativeTruck>>[] => [
      {
        dataKey: "equipId",
        className: classes.cell,
        value: (
          <div className={classes.assignedMultipleTimes}>
            <Typography variant="body1">{datum.equipId}</Typography>
            {datum.assignedMultipleTimes && (
              <Tooltip
                title={
                  <Typography variant="body2">
                    Armado múltiples veces en el turno
                  </Typography>
                }
                placement="right"
                enterDelay={700}
                arrow
              >
                <WarningRounded className={classes.warningIcon} />
              </Tooltip>
            )}
          </div>
        ),
        align: "center",
      },
      {
        dataKey: "operator.name",
        className: classes.cell,
        value: datum.operator?.name ?? "-",
        align: "left",
      },
      {
        dataKey: "fromReason",
        className: classes.cell,
        value: datum.fromReason,
        align: "center",
      },
      {
        dataKey: "duration",
        className: classes.cell,
        value: parseTimeDelta(datum.duration),
        align: "center",
      },
      {
        dataKey: "operativeAt",
        className: classes.cell,
        value: formatLocalizedDate(new Date(datum.operativeAt), "HH:mm") ?? "-",
        align: "center",
      },
      {
        dataKey: "assignedWithTub",
        className: clsx(classes.cell, classes.checkCell),
        value: datum.assignedWithTub ? (
          <CheckCircleRounded className={classes.assignationIcon} />
        ) : null,
        align: "center",
      },
    ],
    [classes]
  );

  const headerCells = useMemo<
    HeaderCellProps<NestedKeyOf<ShiftOperativeTruck>>[]
  >(
    () => [
      {
        dataKey: "equipId",
        label: "CAEX",
        align: "center",
        sortable: true,
      },
      {
        dataKey: "operator.name",
        label: "Operador",
        align: "left",
        sortable: true,
      },
      {
        dataKey: "fromReason",
        label: "Código previo",
        align: "center",
        sortable: true,
      },
      {
        dataKey: "duration",
        label: "Duración",
        align: "center",
        sortable: true,
      },
      {
        dataKey: "operativeAt",
        label: "Hora Armado",
        align: "center",
        sortable: true,
      },
      {
        dataKey: "assignedWithTub",
        label: "Herramienta",
        align: "center",
        sortable: false,
      },
    ],
    []
  );

  const handleOnDialogClose = useCallback(
    (_e?: unknown, _reason?: "backdropClick" | "escapeKeyDown" | "click") =>
      setOpenExplanatoryDialog(false),
    []
  );

  const loadingData =
    loadingOperativeTrucks ||
    refetchingOperativeTrucks ||
    pollingOperativeTrucks ||
    (isTyping && searchValue.length > 2);

  return (
    <CustomDialog
      classes={{ dialog: { paper: classes.dialogPaper } }}
      open={open}
      onClose={onClose}
      title="Registro CAEX armados turno actual"
      countChip={searchResults.length}
      withCloseButton
    >
      <div className={classes.searchBarContainer}>
        <SearchBar
          classes={{
            searchBarRoot: classes.searchBarRoot,
            searchBarPaper: classes.searchBarPaper,
            clearAdornment: classes.searchAdornment,
            searchAdornment: classes.searchAdornment,
          }}
          value={searchValue}
          onChange={setSearchValue}
          placeholder={"Buscar por CAEX u operador"}
        />
        <div className={classes.summaryChipsContainer}>
          <Chip
            onClick={onChipClick}
            className={classes.summaryChip}
            label={
              <Typography variant="h6">
                <strong>{`Adherencia: ${adherencePercentage}%`}</strong>
              </Typography>
            }
          />
          <ShiftOperativeInfoDialog
            open={openExplanatoryDialog}
            onClose={handleOnDialogClose}
            chipDataPercentage={`${adherencePercentage}%`}
          />
        </div>
      </div>
      <CustomTable
        tableKey="shift-operative-trucks"
        data={searchResults}
        headers={headerCells}
        renderCells={getCellDetails}
        loadingData={loadingData}
        disableHeight
      />
    </CustomDialog>
  );
};

const useStyles = makeStyles(({ palette }) => {
  const cellBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;
  const paperBackground =
    palette.type === "light"
      ? palette.background.paper
      : palette.background.default;
  return {
    dialogPaper: {
      display: "flex",
      minHeight: 340,
      maxHeight: 650,
      justifyContent: "center",
      maxWidth: 950,
      backgroundColor: paperBackground,
    },
    searchBarContainer: {
      display: "flex",
      marginBottom: 24,
    },
    summaryChipsContainer: {
      display: "flex",
      alignItems: "center",
      marginLeft: 20,
    },
    summaryChip: {
      marginLeft: 8,
      marginRight: 8,
      paddingRight: 4,
      paddingLeft: 4,
    },
    assignationIcon: {
      color: palette.success.main,
    },
    cell: {
      paddingTop: 5,
      paddingBottom: 5,
      borderBottom: 0,
    },
    checkCell: {
      width: 96,
    },
    searchBarRoot: {
      margin: 0,
      padding: 0,
      alignSelf: "center",
      maxWidth: "unset",
    },
    searchBarPaper: {
      height: 48,
      maxWidth: "unset",
      boxShadow: "none",
      border: `1px solid ${cellBackground}`,
    },
    assignedMultipleTimes: {
      display: "flex",
      alignItems: "center",
    },
    searchAdornment: {
      color: palette.neutralText.primary,
    },
    warningIcon: {
      color: palette.alertText.warning,
      height: 18,
      width: 18,
      marginLeft: 1,
      marginBottom: 3,
    },
  };
});
