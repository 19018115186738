import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import { KpiCardSkeleton } from "components";
import clsx from "clsx";

interface KpisPaperClasses {
  paper?: string;
}
export interface KpisPaperProps {
  loading: boolean;
  skeletons?: React.ReactNode;
  children?: React.ReactNode;
  classes?: KpisPaperClasses;
}

/**
 * Standard wrapper component that generates standard KpiCards
 * It has an style and behaviours already defined.
 *
 * @param loading Used to determinate if show KpiCardSkeleton or ```skeletons``` prop when use.
 * @param skeletons Skeletons that are shown when the ```loading``` prop is set to 'true'.
 * @returns React.ReactElement
 */

export const KpisPaper: React.FC<KpisPaperProps> = ({
  loading: firstLoadingKpis,
  skeletons,
  classes: propClasses,
  children,
}) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={0}
      className={clsx(classes.kpiPaperRoot, propClasses?.paper)}
    >
      {firstLoadingKpis
        ? skeletons ??
          Array.from(Array(6).keys()).map((i) => (
            <KpiCardSkeleton key={`${i}-kpiSkeleton`} />
          ))
        : children}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    kpiPaperRoot: {
      display: "flex",
      padding: 16,
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.background.default
          : theme.palette.background.paper,
    },
  };
});
