import React, { useMemo } from "react";
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  Theme,
  alpha,
  Badge,
} from "@material-ui/core";
import { OperatorIcon, SmartToyIcon } from "components";
import { CardAlertType, TruckSummary } from "interfaces";
import { getAlertPaperColor, getAlertTextColor } from "styles";
import { LastOperatorTooltip } from "../LastOperatorTooltip";

interface OperatorInfoCardProp {
  truck: TruckSummary;
  alertType: CardAlertType;
}

export const PreAssembleOperatorInfoCard: React.FC<OperatorInfoCardProp> = ({
  truck,
  alertType,
}) => {
  const classes = useStyles({ alertType });
  const title = useMemo(
    () =>
      truck.preassembledOper?.name ??
      truck.currentOper?.name ??
      (truck.isCa ? "EMPRESA CONTRATISTA" : "-"),
    [truck]
  );
  const preAssembledOperLocation = truck.preassembledOper?.location;
  return (
    <LastOperatorTooltip truck={truck}>
      <Paper elevation={0} className={classes.operatorInfoPaper}>
        <Badge
          overlap="circular"
          classes={{
            badge: classes.operatorBadge,
          }}
          invisible={!preAssembledOperLocation?.isAutomatic}
          badgeContent={<SmartToyIcon className={classes.projectionIcon} />}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <OperatorIcon className={classes.icon} />
        </Badge>
        <Grid
          container
          direction="column"
          className={classes.operatorGridContainer}
        >
          <Grid item xs>
            <Typography
              className={classes.title}
              noWrap
              variant="body1"
              color="textPrimary"
            >
              {title ?? "-"}
            </Typography>

            <Typography
              className={classes.subTitle}
              noWrap
              variant="body2"
              color="textSecondary"
            >
              {preAssembledOperLocation?.name ?? "-"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </LastOperatorTooltip>
  );
};

interface StyleProps {
  alertType: CardAlertType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  operatorInfoPaper: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 54,
    alignItems: "center",
    marginRight: 4,
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: 8,
  },
  title: {
    fontWeight: "normal",
    color: theme.palette.text.primary
  },
  subTitle: {
    color: alpha(theme.palette.text.primary, 0.6),
  },
  boldTitle: {
    fontWeight: "bold",
  },
  operatorGridContainer: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
  },
  projectionIcon: {
    height: 11,
    width: 11,
    color: theme.palette.getContrastText(theme.palette.text.primary),
  },
  manualIcon: {
    color: theme.palette.getContrastText(theme.palette.text.primary),
    paddingTop: 1,
    paddingLeft: 1,
  },
  operatorBadge: {
    marginRight: 7,
    marginBottom: 3,
    height: 15,
    width: 15,
    minWidth: 5,
    backgroundColor: theme.palette.text.primary,
  },
}));
