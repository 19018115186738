import { IconButton, makeStyles, MenuItem, Select } from "@material-ui/core";
import {
  AutoComplete,
  CloseOnClick,
  CustomDialog,
  CustomDialogActionProps,
  CustomTable,
  DateTimePicker,
} from "components";
import {
  HeaderCellProps,
  NestedKeyOf,
  CellProps,
  PlannedMaintenanceTruck,
  PlannedMaintenanceType,
  Truck,
  plannedMaintenanceTypeTranslation,
  ErrorMsg,
} from "interfaces";
import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";
import { useData } from "hooks";
import clsx from "clsx";
import { formatLocalizedDate } from "utils";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { DeleteOutlineRounded, AccessTimeRounded } from "@material-ui/icons";
import { logError } from "services";
import { TrucksDashboardContext } from "contexts";
import { orderBy } from "lodash";
import { useSnackbar } from "notistack";

interface PlannedMaintenancesDialogProps {
  open: boolean;
  onClose: () => void;
}

type SelectableTruck = Pick<Truck, "id">;

export const PlannedMaintenancesDialog: React.FC<
  PlannedMaintenancesDialogProps
> = ({ open, onClose }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { refetch: refetchDashboardData } = useContext(TrucksDashboardContext);

  const { data: trucks, refetch: refetchAllTrucks } = useData<
    SelectableTruck[]
  >(
    {
      config: "/operator-assignment/trucks",
    },
    ErrorMsg.GET_CAEXS
  );

  const {
    data: pmData,
    firstLoading: loadingPmData,
    refetching: refetchingPmData,
    refetch: refetchPmData,
  } = useData<PlannedMaintenanceTruck[]>(
    {
      config: "/operator-assignment/trucks/planned-maintenances",
    },
    ErrorMsg.GET_PMS
  );

  const { refetch: updatePlannedMaintenance } = useData<
    unknown,
    PlannedMaintenanceTruck[]
  >(
    {
      config: {
        url: "/operator-assignment/trucks/planned-maintenances",
        method: "PATCH",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.UPDATE_PMS
  );

  const [trucksPMs, setTrucksPms] = useState<
    Record<string, PlannedMaintenanceTruck>
  >({});

  const loadingData = loadingPmData || refetchingPmData;

  const onDelete = useCallback(
    (id: string) =>
      setTrucksPms((prevState) => {
        const newState = {
          ...prevState,
        };
        delete newState[id];
        return newState;
      }),
    []
  );

  const onTimeChange = useCallback(
    (date: Date | null, id: string) =>
      setTrucksPms((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          startDate: date,
        },
      })),
    []
  );

  const onTypeChange = useCallback(
    (value: PlannedMaintenanceType, id: string) =>
      setTrucksPms((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          type: PlannedMaintenanceType[value],
        },
      })),
    []
  );

  const getCellDetails = useCallback(
    (
      datum: PlannedMaintenanceTruck
    ): CellProps<NestedKeyOf<PlannedMaintenanceTruck>>[] => [
      {
        dataKey: "id",
        className: classes.cell,
        value: datum.id,
        align: "center",
      },
      {
        dataKey: "type",
        className: classes.cell,
        value: (
          <Select
            id="pm-select"
            value={datum.type}
            onChange={(e) =>
              onTypeChange(e.target.value as PlannedMaintenanceType, datum.id)
            }
          >
            {Object.values(PlannedMaintenanceType).map((type) => (
              <MenuItem value={type}>
                {plannedMaintenanceTypeTranslation[type]}
              </MenuItem>
            ))}
          </Select>
        ),
        align: "center",
      },
      {
        dataKey: "startDate",
        className: classes.cell,
        value: (
          <DateTimePicker
            onChange={(date) => onTimeChange(date, datum.id)}
            value={datum.startDate ?? null}
            labelFunc={(date) => formatLocalizedDate(date, "HH:mm") ?? ""}
            icon={AccessTimeRounded}
            views={["hours", "minutes"]}
            openTo={"hours"}
          />
        ),
        align: "center",
      },
      {
        className: clsx(classes.cell, classes.smallCell),
        value: (
          <IconButton aria-label="delete" onClick={() => onDelete(datum.id)}>
            <DeleteOutlineRounded />
          </IconButton>
        ),
        align: "center",
      },
    ],
    [classes, onDelete, onTimeChange, onTypeChange]
  );

  const headerCells = useMemo<
    HeaderCellProps<NestedKeyOf<PlannedMaintenanceTruck>>[]
  >(
    () => [
      {
        dataKey: "id",
        label: "CAEX",
        align: "center",
        sortable: true,
      },
      {
        dataKey: "type",
        label: "Tipo",
        align: "center",
        sortable: true,
      },
      {
        dataKey: "startDate",
        label: "Hora",
        align: "center",
        sortable: true,
      },
      {
        dataKey: "_accions",
        label: "",
        align: "center",
        sortable: false,
      },
    ],
    []
  );

  const handleOnChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      values: SelectableTruck[],
      _reason: AutocompleteChangeReason
    ) => {
      setTrucksPms((prevState) => {
        // Sort new rows at the top of the table
        const sortedValues = orderBy(values, (v) => !!prevState[v.id], "asc");
        const hashEntries = sortedValues.map((v) => ({
          [v.id]: {
            id: v.id,
            type: prevState[v.id]?.type ?? PlannedMaintenanceType.PM,
            startDate: prevState[v.id]?.startDate ?? new Date(),
          },
        }));
        return Object.assign({}, ...hashEntries);
      });
    },
    []
  );

  const handleOnComplete = useCallback(async () => {
    try {
      await updatePlannedMaintenance({ data: Object.values(trucksPMs) });
      refetchPmData();
      refetchDashboardData();
      enqueueSnackbar(`PM's actualizadas exitosamente`, {
        variant: "success",
      });
    } catch (error) {
      logError("PlannedMaintenanceDialog", error);
    }
  }, [
    updatePlannedMaintenance,
    trucksPMs,
    refetchPmData,
    enqueueSnackbar,
    refetchDashboardData,
  ]);

  const handleOnCancel = useCallback(() => {
    refetchAllTrucks();
    refetchPmData();
  }, [refetchAllTrucks, refetchPmData]);

  const onCancelDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "CANCELAR",
      onClick: handleOnCancel,
      closeOnClick: CloseOnClick.BEFORE,
    }),
    [handleOnCancel]
  );

  const onCompleteDialogProp = useMemo<CustomDialogActionProps>(
    () => ({
      text: "GUARDAR",
      onClick: handleOnComplete,
      closeOnClick: CloseOnClick.BEFORE,
    }),
    [handleOnComplete]
  );

  useEffect(() => {
    setTrucksPms(
      Object.assign(
        {},
        ...pmData.map((pm) => ({
          [pm.id]: pm,
        }))
      )
    );
  }, [pmData]);

  return (
    <CustomDialog
      classes={{
        dialog: { paper: classes.dialogPaper },
      }}
      open={open}
      onClose={onClose}
      title={"Agregar CAEX a PM o Pre PM"}
      onCancelInfo={onCancelDialogProp}
      onCompleteInfo={onCompleteDialogProp}
    >
      <AutoComplete
        classes={{
          root: classes.autoCompleteRoot,
        }}
        value={Object.values(trucksPMs)}
        options={trucks}
        onChange={handleOnChange}
        loading={loadingData}
        loadingText="Cargando camiones"
        noOptionsText="No se encontraron camiones"
        label="Escribe CAEX"
      />
      <CustomTable
        tableKey="planned-maintenances"
        data={Object.values(trucksPMs)}
        headers={headerCells}
        renderCells={getCellDetails}
        loadingData={loadingData}
        disableHeight
      />
    </CustomDialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogPaper: {
    display: "flex",
    minHeight: 340,
    maxHeight: 636,
    justifyContent: "center",
    maxWidth: 560,
  },
  autoCompleteRoot: {
    width: "100%",
    marginBottom: 25,
  },
  timeInput: {
    maxWidth: 74,
    margin: "auto",
  },
  cell: {
    paddingTop: 5,
    paddingBottom: 5,
    width: 139,
    borderBottom: 0,
  },
  smallCell: {
    width: 77,
  },
}));
