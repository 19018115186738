import React, { useContext } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { DailyPlanContext } from "contexts";
import clsx from "clsx";
import { parseNumber } from "utils";

export const TruckAssignmentDailyPlanTotalRow: React.FC = () => {
  // Hooks
  const classes = useStyles();
  const { rowsTotals } = useContext(DailyPlanContext);
  const rowTotalsReducer = Object.values(rowsTotals).reduce(
    (accumulator, currentValue) => {
      return {
        sulfideCrusher:
          accumulator.sulfideCrusher + currentValue.sulfideCrusher,
        oxideCrusher: accumulator.oxideCrusher + currentValue.oxideCrusher,
        stack: accumulator.stack + currentValue.stack,
        dump: accumulator.dump + currentValue.dump,
        stock: accumulator.stock + currentValue.stock,
        total: accumulator.total + currentValue.total,
        assignedTrucks:
          accumulator.assignedTrucks + currentValue.assignedTrucks,
      };
    },
    {
      sulfideCrusher: 0,
      oxideCrusher: 0,
      stack: 0,
      dump: 0,
      stock: 0,
      total: 0,
      assignedTrucks: 0,
    }
  );
  return (
    <>
      {rowTotalsReducer.total !== 0 && (
        <div className={clsx(classes.totalRowContainer)}>
          <div className={clsx(classes.totalCell)}></div>
          <div className={clsx(classes.totalCell)}>
            <Typography>
              <strong>{"TOTAL:"}</strong>
            </Typography>
          </div>
          {Object.entries(rowTotalsReducer)
            .filter(
              ([name, _]) => name !== "priority" && name !== "assignedTrucks"
            )
            .map(([name, value]) => (
              <Typography className={clsx(classes.totalCell)} key={name}>
                <strong>{parseNumber(value, 2) + " [kt]"}</strong>
              </Typography>
            ))}
          <Typography className={clsx(classes.totalCell)}>
            <strong>{parseNumber(rowTotalsReducer.assignedTrucks, 2)}</strong>
          </Typography>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    totalRowContainer: {
      display: "flex",
      alignItems: "center",
      background: palette.collapsedRow.background,
    },
    totalCell: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "70px",
      width: "100%",
    },
    iconFilled: {
      color: "#BDBDBD",
    },
  };
});
