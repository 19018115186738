import {
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  Typography,
  alpha,
  makeStyles,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { IncrementButton } from "./IncrementButton";
import { Cancel } from "@material-ui/icons";
import ArrowCircleDown from "components/icons/ArrowCircleDown";
import ArrowCircleUp from "components/icons/ArrowCircleUp";

interface ItemData {
  id: string;
  title: string;
}

interface Item extends ItemData {
  index: number;
}
interface DataItemList {
  count: number;
  priority: number;
  id: string;
}

interface ItemListProps {
  selectedOptions: ItemData[];
  dataListItems: DataItemList[];
  setSelectedOptions: (id: string) => void;
  setDataOptions: (index: number, value: DataItemList) => void;
  setDataPriority: (
    indexUp: number,
    indexDown: number,
    upValue: DataItemList,
    downValue: DataItemList
  ) => void;
  disabled: boolean;
}

export const ItemList = ({
  selectedOptions,
  dataListItems,
  setSelectedOptions,
  setDataOptions,
  setDataPriority,
  disabled,
}: ItemListProps) => {
  const classes = useStyles();

  const dataSelector = useMemo(() => {
    return selectedOptions.sort((a, b) => {
      const priorityValueA =
        dataListItems.find((item) => item.id === a.id)?.priority ?? -1;
      const priorityValueB =
        dataListItems.find((item) => item.id === b.id)?.priority ?? -1;

      return priorityValueA - priorityValueB;
    });
  }, [dataListItems, selectedOptions]);

  const handleValueChange = (id: string, value: number) => {
    const index_ = dataListItems.findIndex((item) => item.id === id);
    const newValue = [...dataListItems];
    newValue[index_].count = value;
    setDataOptions(index_, newValue[index_]);
  };

  const handlePriority = (index: number, id: string, type: string) => {
    const findCurrentItem = dataListItems.find((item) => item.id === id);

    if (
      findCurrentItem?.priority &&
      ((findCurrentItem?.priority !== 1 && type === "UP") ||
        (findCurrentItem?.priority !== dataListItems.length && type === "DOWN"))
    ) {
      const newValue = [...dataListItems];

      const previous =
        type === "UP"
          ? findCurrentItem.priority - 1
          : findCurrentItem.priority + 1;
      //swap previous priority
      const findPrevious = dataListItems.find(
        (item) => item.priority === previous
      );
      const indexPrevious = dataListItems.findIndex(
        (item) => item.priority === previous
      );

      //change values
      newValue[indexPrevious].priority = findPrevious?.priority
        ? type === "UP"
          ? findPrevious?.priority + 1
          : findPrevious?.priority - 1
        : -1;

      newValue[index].priority =
        type === "UP"
          ? findCurrentItem.priority - 1
          : findCurrentItem.priority + 1;

      setDataPriority(
        index,
        indexPrevious,
        newValue[index],
        newValue[indexPrevious]
      );
    }
  };

  const handleDelete = (itemToDelete: string) => () => {
    setSelectedOptions(itemToDelete);
  };
  enum options {
    count = "count",
    priority = "priority",
  }

  const getValue = (id: string, type: options) => {
    const findItem = dataListItems?.find((item) => item.id === id);
    return findItem ? findItem[type] : 0;
  };

  const Item = ({ title, id, index }: Item) => {
    return (
      <>
        <ListItem style={{ width: "100%", paddingRight: 10, paddingLeft: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignContent="center" alignItems="center">
                <Grid item xs={1} style={{ textAlign: "center" }}>
                  <Typography>{`${getValue(
                    id,
                    options.priority
                  )}.`}</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Grid
                    container
                    className={classes.divider}
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={!disabled ? 3 : 5}
                      style={{ textAlign: "left", paddingLeft: 4 }}
                    >
                      <Chip
                        size="small"
                        label={
                          <Typography color="textPrimary" variant="subtitle2">
                            {title}
                          </Typography>
                        }                 
                        className={classes.chip}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <IncrementButton
                        onValueChange={(newValue: number) =>
                          handleValueChange(id, newValue)
                        }
                        initValue={getValue(id, options.count)}
                        title="N° CAEX"
                        disabled={disabled}
                      />
                    </Grid>
                    {!disabled && (
                      <Grid item xs={3}>
                        <Grid container style={{ justifyContent: "center" }}>
                          <Button
                            className={classes.priorityButton}
                            style={{ paddingRight: 10 }}
                            onClick={() => handlePriority(index, id, "UP")}
                            disabled={index === 0 ?? false}
                          >
                            <ArrowCircleUp
                              className={
                                index === 0
                                  ? classes.priorityButtonDisabledColor
                                  : classes.priorityButtonColor
                              }
                            />
                          </Button>
                          <Button
                            className={classes.priorityButton}
                            onClick={() => handlePriority(index, id, "DOWN")}
                            disabled={
                              index === dataListItems.length - 1 ?? false
                            }
                          >
                            <ArrowCircleDown
                              className={
                                index === dataListItems.length - 1
                                  ? classes.priorityButtonDisabledColor
                                  : classes.priorityButtonColor
                              }
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    {!disabled && (
                      <Grid item xs={1}>
                        <Button
                          onClick={handleDelete(id)}
                          className={classes.chipButton}
                        >
                          <Cancel className={classes.chipCancel} />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      </>
    );
  };

  return (
    <Grid container>
      <List style={{ width: "100%" }}>
        {dataSelector?.map(({ id, title }, index) => (
          <Item key={id} title={title} id={id} index={index} />
        ))}
      </List>
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    numberField: {
      width: 40,
      height: 28,
      fontSize: 16,
      "& input": {
        padding: 4,
        textAlign: "center",
      },
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          "-webkit-appearance": "none",
          margin: 0,
        },
      '& input[type="number"]': {
        "-moz-appearance": "textfield",
      },
    },
    chip: {
      background: palette.action.selected,
    },
    chipLabel: {
      background: "white",
      borderRadius: 6,
      marginRight: 2,
      padding: "0px 6px 0px 6px",
      width: "auto",
      "&&:hover,&&:focus": {
        fill: alpha(palette.action.active, 0.54),
      },
    },
    buttonCaex: {
      height: 24,
      width: 24,
      minWidth: "unset",
      background: alpha(palette.action.active, 0.54),
      color: palette.secondary.contrastText,
    },
    chipCancel: {
      cursor: "pointer",
      fill:
        palette.type === "light"
          ? alpha(palette.action.active, 0.54)
          : palette.action.active,
      height: 25,
      width: 25,
      "&&:hover,&&:focus": {
        fill:
          palette.type === "light"
            ? alpha(palette.action.active, 0.6)
            : palette.action.active,
      },
    },
    chipButton: {
      minWidth: "unset",
      padding: 0,
    },
    divider: {
      border: "1px solid",
      borderColor:
        palette.type === "light" ? palette.divider : palette.background.paper,
      borderRadius: 4,
      padding: 4,
    },
    priorityButton: {
      padding: "0px",
      background: "unset",
      minWidth: "unset",
    },
    priorityButtonDisabledColor: {
      fill:
        palette.type === "dark"
          ? palette.action.disabled
          : alpha(palette.action.disabled, 0.26),
    },
    priorityButtonColor: {
      fill:
        palette.type === "dark"
          ? palette.action.active
          : alpha(palette.action.active, 0.54),
      "&&:hover,&&:focus": {
        fill:
          palette.type === "light"
            ? alpha(palette.action.active, 0.6)
            : palette.action.active,
      },
    },

    scaleUpTop: {
      animation: "$scaleUpTop 0.4s",
    },
    "@keyframes scaleUpTop": {
      "0%": {
        transform: "scale(0.5)",
        transformOrigin: "center top",
      },
      "100%": {
        transform: "scale(1)",
        transformOrigin: "center top",
      },
    },
  };
});
