import { makeStyles, Typography } from "@material-ui/core";
import { CustomDialog } from "components";
import React from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  chipDataPercentage?: string;
}

export const ShiftOperativeInfoDialog: React.FC<Props> = ({
  open,
  onClose,
  chipDataPercentage,
}) => {
  const classes = useStyles();

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={"CAEX armados en la herramienta"}
      countChip={chipDataPercentage ?? ""}
      onCompleteInfo={{ text: "Ok", onClick: onClose }}
    >
      <div className={classes.contentRoot}>
        <Typography variant="body1">
          Este porcentaje mide los CAEX armados después de las 9 am/pm que
          estaban en los códigos:
        </Typography>
        <br />
        <Typography variant="body1">
          <strong>3000*</strong> | Mantención programada
          <br />
          <strong>5000*</strong> | Mantención no programada
          <br />
          <strong>5010*</strong> | Daño equipo
          <br />
          <strong>5020*</strong> | Mantención tecnológica
          <br />
          <strong>4000**</strong> | Sin operador
          <br />
          <strong>4190**</strong> | Espera no planificada
          <br />
        </Typography>
        <br />
        <Typography variant="caption">
          <i>* Eventos sobre 45 minutos</i>
          <br />
          <i>** Eventos sobre 5 minutos y menores a 8 horas</i>
        </Typography>
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    contentRoot: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    button: {
      width: 80,
      color: theme.palette.common.white,
      alignSelf: "flex-end",
    },
  };
});
