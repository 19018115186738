import React from "react";
import { Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CardAlertType } from "interfaces";
import { getAlertPaperColor } from "styles";

interface TruckCardTimeSectionSkeletonProps {
  alertType: CardAlertType;
}

export const TruckCardTimeSectionSkeleton: React.FC<
  TruckCardTimeSectionSkeletonProps
> = ({ alertType }) => {
  const classes = useStyles({ alertType });

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container direction="column">
        <Grid item>
          <Skeleton className={classes.field} variant="rect" width="70%" />
        </Grid>
        <Grid item>
          <Skeleton className={classes.field} variant="rect" width="35%" />
        </Grid>
        <Grid item>
          <Skeleton className={classes.field} variant="rect" width="70%" />
        </Grid>
        <Grid item>
          <Skeleton className={classes.field} variant="rect" width="35%" />
        </Grid>
      </Grid>
    </Paper>
  );
};

interface StyleProps {
  alertType: CardAlertType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  paper: {
    backgroundColor: ({ alertType }) => getAlertPaperColor(theme, alertType),
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  field: {
    marginLeft: 15,
    marginRight: 15,
    height: "0.8rem",
    marginBottom: 5,
    marginTop: 5,
    borderRadius: "5%",
  },
}));
