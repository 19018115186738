import React from "react";
import { Box, Typography, Paper, Chip, makeStyles } from "@material-ui/core";

interface TruckColumnProps {
  title: string;
  chipText: string;
  children?: React.ReactNode;
}

export const TruckColumn: React.FC<TruckColumnProps> = ({
  title,
  chipText,
  children,
}) => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.truckColumnRoot}>
      <Box className={classes.box}>
        <Typography noWrap variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Chip
          label={<Typography variant="h6">{chipText}</Typography>}
          className={classes.chip}
        />
      </Box>
      {children}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  const { type } = palette;
  return {
    truckColumnRoot: {
      height: "fit-content",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxWidth: 391,
      backgroundColor:
        type === "light"
          ? theme.palette.background.default
          : theme.palette.background.paper,
      marginTop: 12,
      marginBottom: 12,
      marginRight: 12,
      [theme.breakpoints.down(1441)]: {
        minWidth: 312,
        margin: 4,
      },
      "&:last-child": {
        marginLeft: 12,
        marginRight: 0,
      },
    },
    box: {
      margin: "10px 17px 20px 17px",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(1441)]: {
        margin: "10px 10px",
      },
    },
    title: {
      color:
        theme.palette.type === "light"
          ? theme.palette.text.secondary
          : theme.palette.common.white,
    },
    chip: {
      backgroundColor: theme.palette.background.paper,
    },
  };
});
