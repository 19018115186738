import React from "react";
import { CellProps } from "interfaces";
import { makeStyles, TableRow } from "@material-ui/core";
import { CustomTableCell } from "./CustomTableCell";
import clsx from "clsx";
import grey from "@material-ui/core/colors/grey";

interface CustomTableRowProps<T> {
  entity?: T;
  callBack?: (payload: T) => void;
  cellDetails: CellProps<T>[];
  className?: string;
  rowKey: string;
}

export const CustomTableRow = <T extends unknown>({
  entity,
  callBack,
  className,
  cellDetails,
  rowKey,
}: CustomTableRowProps<T>) => {
  //Local state
  const classes = useStyle();

  return (
    <TableRow key={rowKey} className={clsx(classes.row, className)}>
      {cellDetails.map((cell, i) => (
        <CustomTableCell
          key={`${rowKey}-${cell.dataKey}-${cell.value}-${i}`}
          align={cell.align}
          value={cell.value}
          className={cell.className}
        />
      ))}
    </TableRow>
  );
};

const useStyle = makeStyles(({ palette }) => {
  const evenRowBackgroundColor =
    palette.type === "light" ? grey[50] : palette.background.default;
  return {
    row: {
      height: 56,
      "&:nth-child(even)": {
        backgroundColor: evenRowBackgroundColor,
      },
    },
  };
});
