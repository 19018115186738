import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { KpiTypePerformanceTranslation, PhasesCard } from "interfaces";
import { KpiCard } from "./KpiCard";
import { Size } from "components/SquareChip";

interface CardProps {
  cardData: PhasesCard;
  icon: JSX.Element;
  defaultCards?: boolean; // 4 or 6
  prioritySize?: Size;
}

/**
 * returns a card with 2 rows and 6 columns. The number of cards depends on the number
 * of elements in the data array. The component is designed to generate a maximum of 4 cards.
 * @param cardData contains the date and data of each card
 * @param icon the first cell of the card contains an icon
 */
export const CardComponent: React.FC<CardProps> = ({
  cardData,
  icon,
  defaultCards = true,
  prioritySize = Size.small
}) => {
  const classes = useStyles();
  const { data } = cardData;

  return (
    <Grid container spacing={2}>
      {data?.map(({ id, columns }, index) => (
        <Grid key={index} item xs={defaultCards ? 3 : 2}>
          <Grid container className={classes.gridContainer}>
            <Grid
              item
              xs={12}
              sm={12}
              md={defaultCards ? 6 : 12}
              lg={defaultCards ? 4 : 6}
              xl={defaultCards ? 4 : 6}
              className={classes.gridCardContent}
            >
              <Card className={classes.cardContainerId}>
                <CardContent
                  style={{ padding: "4px 4px" }}
                  className={clsx(classes.cardContent, classes.idCard)}
                >
                  <Grid
                    container
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid
                      item
                      xs={4}
                      md={4}
                      lg={4}
                      style={{ alignItems: "center" }}
                      id="grid-icon"
                    >
                      <Avatar className={classes.avatar}>{icon}</Avatar>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      md={8}
                      lg={8}
                      style={{ alignItems: "center" }}
                      id="grid-text"
                    >
                      <Tooltip
                        title={id ?? ""}
                        classes={{
                          tooltip: classes.tooltip,
                          arrow: classes.arrow,
                        }}
                        arrow
                        placement="top"
                        interactive
                      >
                        <Typography
                          variant="body1"
                          style={{ whiteSpace: id.split(" ").length > 1 ? "unset" : "nowrap" }}
                          className={classes.adjustedTextSize}
                        
                        >
                          <strong>{id}</strong>
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {columns?.map((value, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={12}
                md={defaultCards ? 6 : 12}
                lg={defaultCards ? 4 : 6}
                xl={defaultCards ? 4 : 6}
                className={classes.gridCardContent}
              >
                <Card className={classes.cardContainer} elevation={4}>
                  <CardContent className={classes.cardContent}>
                    <Tooltip
                      title={KpiTypePerformanceTranslation[value.type] ?? ""}
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.arrow,
                      }}
                      arrow
                      placement="top"
                      interactive
                    >
                      <Typography
                        variant="caption"
                        className={classes.adjustedTextSize}
                      >
                        {KpiTypePerformanceTranslation[value.type]}
                      </Typography>
                    </Tooltip>
                    <KpiCard values={value} prioritySize={prioritySize}/>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    gridContainer: {
      background:
        palette.type === "light" ? palette.background.default : "#212932",
      boxShadow:
        palette.type === "light"
          ? "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)"
          : "unset",
      borderRadius: "4px",
      padding: "2px",
    },
    card: {
      background: "#FFFFFF",
      padding: "4px 8px",
    },
    cardContent: {
      height: "64px",
      padding: "4px 8px",
      paddingTop: "8px",
      "&.MuiCardContent-root": {
        paddingBottom: "4px",
      },
      boxShadow:
        "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
    },
    idCard: {
      justifyContent: "center",
      paddingBottom: "0px",
    },
    gridCardContent: {
      background:
        palette.type === "light" ? palette.background.default : "#212932",
      // boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
      borderRadius: "4px",
      padding: "2px",
    },
    cardContainer: {
      boxShadow: "unset",
    },
    cardContainerId: {
      // margin: "2px",
      boxShadow: "unset",
    },
    adjustedTextSize: {
      display: "block",
      width: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    avatar: {
      backgroundColor: palette.kpi.background,
      height: "35px",
      width: "35px",
    },
    tooltip: {
      background: palette.secondary.main,
      borderRadius: "4px",
      boxShadow:
        "0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
      fontSize: 16,
      padding: "6px",
    },
    arrow: {
      color: palette.secondary.main,
    },
  };
});
