import React, { useCallback, useMemo } from "react";
import {
  CustomLayer,
  DatumValue,
  ResponsiveLine,
  Serie,
  SliceTooltipProps,
} from "@nivo/line";
import { makeStyles, Typography, useTheme } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  metricUnit,
  MetricData,
  metricTranslation,
  Metric,
  TargetTooltipData,
  MetricDataWithEvents,
} from "interfaces";
import { TruckAssignmentMetricsState } from "reducers";
import { localizeNumber } from "utils";
import { graphXValues } from "utils/assignmentMetrics";
import { DottedLineIcon } from "components";
import { CustomLegend } from "./CustomLegend";
import { DataAndTargetTooltip } from "./DataAndTargetTooltip";
import { AnimatedCustomLine } from "components/Graphs/AnimatedCustomLine";

interface MetricsLineGraphProps {
  utilizationData: MetricDataWithEvents[];
  targetData: MetricData[];
  menuState: TruckAssignmentMetricsState;
}

export const MetricsDoubleLineGraph: React.FC<MetricsLineGraphProps> = ({
  utilizationData,
  targetData,
  menuState: state,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const graphData: Serie[] = useMemo(
    () => [
      {
        id: Metric.MINE_UTILIZATION,
        data: utilizationData.map(({ issueDate, value }) => ({
          x: new Date(issueDate),
          y: value,
        })),
      },
      {
        id: Metric.UTILIZATION_TARGET,
        data: targetData.map(({ issueDate, value }) => ({
          x: new Date(issueDate),
          y: value,
        })),
      },
    ],
    [utilizationData, targetData]
  );

  const formatY = useCallback(
    (value: DatumValue) => localizeNumber(Number(value), 4),
    []
  );

  const graphX = useMemo(() => graphXValues(), []);

  return (
    <>
      <div className={classes.graphAxisTitleContainer}>
        <div className={classes.graphAxisTitle}>
          <Typography className={classes.text} variant="h6">
            <strong>{metricTranslation[state.selectedMetric]}</strong>
          </Typography>
          <Typography className={classes.text} variant="h6">
            <strong>
              {"[" + (metricUnit[state.selectedMetric] || "") + "]"}
            </strong>
          </Typography>
        </div>
        <div className={classes.graphLegend}>
          <>
            <CustomLegend
              icon={<RemoveIcon fontSize="large" className={classes.line} />}
              text="Real"
            />
            <CustomLegend
              icon={<DottedLineIcon stroke={theme.palette.graphSeries[1]} />}
              text="Esperada"
            />
          </>
        </div>
      </div>
      <div className={classes.graphContainer}>
        <ResponsiveLine
          theme={theme.graph}
          colors={theme.palette.mineUtilizationColors}
          data={graphData}
          margin={{ top: 25, bottom: 55, right: 25, left: 55 }}
          xScale={{
            type: "time",
            format: "%H:%M",
            min: graphX[0],
            max: graphX[graphX.length - 1],
          }}
          yScale={{ type: "linear", max: 100, stacked: false }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickValues: graphX,
            tickPadding: 15,
            tickRotation: 0,
            format: "%H:%M",
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 6,
            tickRotation: 0,
            tickValues: 10,
            format: formatY,
          }}
          enablePoints={false}
          enableSlices={"x"}
          enableGridY={true}
          enableGridX={false}
          animate={false}
          sliceTooltip={({ slice }) =>
            renderTooltip({
              slice,
              unit: metricUnit[state.selectedMetric],
            })
          }
          layers={[
            "grid",
            "markers",
            "axes",
            "crosshair",
            "slices",
            LinesLayer,
          ]}
        />
      </div>
    </>
  );
};

interface CustomTooltipProps {
  slice: SliceTooltipProps["slice"];
  unit: string;
}

const renderTooltip: React.FC<CustomTooltipProps> = ({ slice, unit }) => {
  const { points } = slice;
  const entries: TargetTooltipData[] = [];

  const valuePoint = points.find((p) => p.serieId === Metric.MINE_UTILIZATION);
  const targetPoint = points.find(
    (p) => p.serieId === Metric.UTILIZATION_TARGET
  );

  if (valuePoint) {
    entries.push({
      color: valuePoint.serieColor,
      label: "Real",
      value: valuePoint.data.y as number,
      unit,
    });
  }
  if (targetPoint) {
    entries.push({
      color: targetPoint.serieColor,
      label: "Esperada",
      value: targetPoint.data.y as number,
      unit,
    });
  }

  return targetPoint || valuePoint ? (
    <DataAndTargetTooltip
      hourOfDay={(targetPoint ?? valuePoint)?.data.x as Date}
      entries={entries}
    />
  ) : (
    <></>
  );
};

const lineStyles = {
  dashed: {
    strokeDasharray: "12, 6",
    strokeWidth: 4,
  },
  continuous: {
    strokeWidth: 4,
  },
};

const LinesLayer: CustomLayer = ({ series, lineGenerator }) => {
  return series.map(({ id, data, color }) => {
    const points = data.map((d) => ({
      x: d.position.x ?? 0,
      y: d.position.y ?? 0,
    }));
    return (
      <AnimatedCustomLine
        key={id}
        id={id}
        lineGenerator={lineGenerator}
        points={points}
        color={color}
        style={
          id === Metric.UTILIZATION_TARGET
            ? lineStyles.dashed
            : lineStyles.continuous
        }
        x
      />
    );
  });
};

const useStyles = makeStyles((theme) => ({
  graphAxisTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  graphAxisTitle: {
    width: 150,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "25px"
  },
  text: {
    fontSize: "16px",
    lineHeight: "28px",
    paddingRight: "20px",
  },
  graphContainer: {
    height: 406,
  },
  graphLegend: {
    display: "flex",
    alignItems: "center",
  },
  line: {
    color: theme.palette.mineUtilizationColors[0],
  },
}));
