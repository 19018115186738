import { isAfter } from "date-fns";
import {
  CrewEnum,
  PerformanceRatingOperatorsTop10Params,
  PeriodEnum,
  Shift,
  Supervisor,
  PerformanceRatingOperator,
  OperatorPracticeOptions,
  ScreenType,
  PracticeEnum,
} from "interfaces";

export enum GraphDataType {
  AVG = "AVG",
  ACC = "ACC",
}

export enum DataViewOption {
  GRAPH = "Resumen",
  TABLE = "Detalle",
}

export interface PeriodOption {
  name: PeriodEnum;
  startDate: Date | null;
  endDate: Date | null;
  userHasChangedCustomPeriod: boolean;
}

export const screenTypesKeys: Record<
  ScreenType,
  keyof Pick<PerformanceRatingState, "operatorPeriod" | "supervisorPeriod">
> = {
  [ScreenType.OPERATOR]: "operatorPeriod",
  [ScreenType.SUPERVISOR]: "supervisorPeriod",
};

const allPracticesOptions = Object.values(OperatorPracticeOptions).filter(
  (op) => !["IMMOB"].includes(op)
);

export const graphDataTypeTranslation: Record<GraphDataType, string> = {
  [GraphDataType.AVG]: "Promedio",
  [GraphDataType.ACC]: "Acumulado",
};

export type OperatorPracticeCheckBoxHash = {
  [key in OperatorPracticeOptions]?: boolean;
};

export enum PerformanceRatingActionType {
  INIT = "INIT",
  RESET = "RESET",
  VIEW_OPTION_CHANGE = "VIEW_OPTION_CHANGE",
  GRAPH_DATA_TYPE_CHANGE = "GRAPH_DATA_TYPE_CHANGE",
  CREW_CHANGE = "CREW_CHANGE",
  SUPERVISOR_CHANGE = "SUPERVISOR_CHANGE",
  OPERATOR_SCREEN_PRACTICE_CHANGE = "OPERATOR_SCREEN_PRACTICE_CHANGE",
  SUPERVISOR_SCREEN_PRACTICE_CHANGE = "SUPERVISOR_SCREEN_PRACTICE_CHANGE",
  OPERATOR_CHANGE = "OPERATOR_CHANGE",
  CHECK_SUPERVISOR_OPTIONS = "CHECK_SUPERVISOR_OPTIONS",
  PERIOD_OPTION_CHANGE = "PERIOD_OPTION_CHANGE",
  CHANGE_DATES = "CHANGE_DATES",
  CHANGE_OPERATOR_RANGE = "CHANGE_OPERATOR_RANGE",
}

interface OperatorRangeDates {
  last6ShiftStartDate: PeriodOption["startDate"];
  last6ShiftEndDate: PeriodOption["endDate"];
}

export interface PerformanceRatingState {
  viewOption: DataViewOption;
  graphDataType: GraphDataType;
  crews: CrewEnum[];
  selectedSupervisor: PerformanceRatingOperatorsTop10Params["supervisorId"];
  selectedOperator: PerformanceRatingOperator | null;
  operPractices: OperatorPracticeCheckBoxHash;
  operatorPeriod: PeriodOption & OperatorRangeDates;
  supervisorPeriod: Omit<PeriodOption, "userHasChangedCustomPeriod">;
}

export type PerformanceRatingAction =
  | { type: PerformanceRatingActionType.INIT; payload: PerformanceRatingState }
  | { type: PerformanceRatingActionType.RESET; payload: PerformanceRatingState }
  | {
      type: PerformanceRatingActionType.VIEW_OPTION_CHANGE;
      option: PerformanceRatingState["viewOption"];
    }
  | {
      type: PerformanceRatingActionType.GRAPH_DATA_TYPE_CHANGE;
      option: PerformanceRatingState["graphDataType"] | null;
    }
  | {
      type: PerformanceRatingActionType.CREW_CHANGE;
      crews: PerformanceRatingState["crews"];
    }
  | {
      type: PerformanceRatingActionType.SUPERVISOR_CHANGE;
      selectedSupervisor: PerformanceRatingState["selectedSupervisor"];
    }
  | {
      type: PerformanceRatingActionType.CHECK_SUPERVISOR_OPTIONS;
      supervisors: Supervisor[];
    }
  | {
      type: PerformanceRatingActionType.OPERATOR_CHANGE;
      selectedOperator: PerformanceRatingState["selectedOperator"];
    }
  | {
      type: PerformanceRatingActionType.SUPERVISOR_SCREEN_PRACTICE_CHANGE;
      practice: OperatorPracticeOptions;
      value: boolean;
      currentShift: Shift;
    }
  | {
      type: PerformanceRatingActionType.OPERATOR_SCREEN_PRACTICE_CHANGE;
      practice: OperatorPracticeOptions;
      value: boolean;
      currentShift: Shift;
    }
  | {
      type: PerformanceRatingActionType.PERIOD_OPTION_CHANGE;
      periodName: PeriodEnum;
      screen: ScreenType;
    }
  | {
      type: PerformanceRatingActionType.CHANGE_DATES;
      dates: Partial<Pick<PeriodOption, "startDate" | "endDate">>;
      screen: ScreenType;
    }
  | {
      type: PerformanceRatingActionType.CHANGE_OPERATOR_RANGE;
      dates: OperatorRangeDates;
    };

export const initialPerformanceRatingState: PerformanceRatingState = {
  viewOption: DataViewOption.GRAPH,
  graphDataType: GraphDataType.AVG,
  crews: [CrewEnum.A],
  selectedSupervisor: null,
  selectedOperator: null,
  operPractices: allPracticesOptions.reduce(
    (prev, practice) => ({
      ...prev,
      [practice]: true,
      [OperatorPracticeOptions.ALL]: false,
    }),
    {}
  ),
  operatorPeriod: {
    name: PeriodEnum.CURRENT_DAILY_SHIFT,
    startDate: null,
    endDate: null,
    last6ShiftStartDate: null,
    last6ShiftEndDate: null,
    userHasChangedCustomPeriod: false,
  },
  supervisorPeriod: {
    name: PeriodEnum.CURRENT_DAILY_SHIFT,
    startDate: null,
    endDate: null,
  },
};

export const performanceRatingsReducer = (
  state: PerformanceRatingState,
  action: PerformanceRatingAction
): PerformanceRatingState => {
  const nextState: PerformanceRatingState = {
    ...state,
  };
  let practiceName, practiceValue, attr;
  switch (action.type) {
    case PerformanceRatingActionType.VIEW_OPTION_CHANGE:
      return {
        ...state,
        viewOption: action.option,
      };
    case PerformanceRatingActionType.GRAPH_DATA_TYPE_CHANGE:
      nextState.graphDataType = action.option ?? state.graphDataType;
      return nextState;
    case PerformanceRatingActionType.CHECK_SUPERVISOR_OPTIONS:
      const findedSupervisor = action.supervisors.find(
        (s) => s.id === state.selectedSupervisor
      );
      nextState.selectedSupervisor = findedSupervisor?.id ?? 0;
      return nextState;
    case PerformanceRatingActionType.CREW_CHANGE:
      //* Cannot unselect last crew option
      if (action.crews.length > 0) nextState.crews = [...action.crews];
      return nextState;
    case PerformanceRatingActionType.SUPERVISOR_CHANGE:
      nextState.selectedSupervisor = action.selectedSupervisor;
      return nextState;
    case PerformanceRatingActionType.SUPERVISOR_SCREEN_PRACTICE_CHANGE:
      practiceName = action.practice;
      practiceValue = action.value;

      if (practiceName === OperatorPracticeOptions.ALL) {
        for (const practice of Object.values(PracticeEnum)) {
          nextState.operPractices[practice] = practiceValue;
        }
        nextState.operPractices.ALL = practiceValue;
        if (!practiceValue) {
          // Ensure at least 1 option is selected
          nextState.operPractices.LUNCH = action.currentShift === Shift.DAY;
          nextState.operPractices.DINNER = action.currentShift === Shift.NIGHT;
        }
      } else {
        // Clicking in a single serie different than ALL
        // Cannot unselecting the last option
        const enabledOptions = Object.values(nextState.operPractices).reduce(
          (prev, value) => prev + Number(value),
          0
        );
        if (enabledOptions !== 1 || practiceValue) {
          nextState.operPractices[practiceName] = practiceValue;
        }
      }
      // Recalculate the ALL option status
      nextState.operPractices.ALL = Object.entries(nextState.operPractices)
        .filter(([key]) => key !== OperatorPracticeOptions.ALL)
        .every(([, val]) => val);
      nextState.operPractices = { ...nextState.operPractices };
      return nextState;
    case PerformanceRatingActionType.OPERATOR_SCREEN_PRACTICE_CHANGE:
      practiceName = action.practice;
      practiceValue = action.value;

      if (practiceName === OperatorPracticeOptions.ALL) {
        if (practiceValue)
          for (const practice of Object.values(PracticeEnum)) {
            nextState.operPractices[practice] = practiceValue;
          }
        nextState.operPractices.ALL = practiceValue;
      } else {
        // Clicking in a single serie different than ALL
        // Cannot unselecting the last option
        const enabledOptions = Object.values(nextState.operPractices).reduce(
          (prev, value) => prev + Number(value),
          0
        );
        if (enabledOptions !== 1 || practiceValue) {
          nextState.operPractices[practiceName] = practiceValue;
        }
      }
      nextState.operPractices = { ...nextState.operPractices };
      return nextState;
    case PerformanceRatingActionType.OPERATOR_CHANGE:
      nextState.selectedOperator = action.selectedOperator;
      return nextState;
    case PerformanceRatingActionType.PERIOD_OPTION_CHANGE:
      attr = screenTypesKeys[action.screen];
      nextState[attr].name = action.periodName;
      nextState[attr].startDate = nextState[attr].startDate ?? new Date();
      nextState[attr].endDate = nextState[attr].endDate ?? new Date();
      return nextState;
    case PerformanceRatingActionType.CHANGE_DATES:
      if (action.screen === ScreenType.OPERATOR)
        nextState.operatorPeriod.userHasChangedCustomPeriod = true;

      attr = screenTypesKeys[action.screen];
      nextState[attr].startDate =
        action.dates.startDate ?? nextState[attr].startDate;
      nextState[attr].endDate = action.dates.endDate ?? nextState[attr].endDate;

      // Avoid inconsistent date range
      if (
        !!nextState[attr].startDate &&
        !!nextState[attr].endDate &&
        isAfter(
          nextState[attr].startDate as Date,
          nextState[attr].endDate as Date
        )
      ) {
        if (action.dates.startDate) {
          // startDate was modified, so we fix the another one
          nextState[attr].endDate = nextState[attr].startDate;
        } else {
          // endDate was modified, so we fix the another one
          nextState[attr].startDate = nextState[attr].endDate;
        }
      }
      return nextState;
    case PerformanceRatingActionType.CHANGE_OPERATOR_RANGE:
      nextState.operatorPeriod = {
        ...nextState.operatorPeriod,
        ...action.dates,
      };
      // Update the custom range when updating last6Shifts range and user has not change it
      if (
        nextState.viewOption === DataViewOption.TABLE &&
        !nextState.operatorPeriod.userHasChangedCustomPeriod
      ) {
        nextState.operatorPeriod.startDate =
          nextState.operatorPeriod.last6ShiftStartDate;
        nextState.operatorPeriod.endDate =
          nextState.operatorPeriod.last6ShiftEndDate;
      }
      return nextState;
    case PerformanceRatingActionType.INIT:
      return action.payload;
    case PerformanceRatingActionType.RESET:
      return action.payload;
    default:
      return nextState;
  }
};
