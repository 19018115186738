import { SvgIcon, SvgIconProps } from '@material-ui/core'
import React from 'react'

export const PerformanceTruckIcon:React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
        width="67px"
        height="67px"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M16.8269 37.2514L28.1879 44.1753L30.364 45.5076V44.2029L30.5709 44.0787C30.0436 42.5048 29.1691 41.0482 28.0143 39.8816C27.7407 39.5985 27.7674 39.2465 27.5871 38.9979C27.0398 38.2593 26.9597 37.3757 26.7327 36.4576C26.666 36.1677 26.5859 35.8708 26.4257 35.6292C26.2655 35.3876 26.0385 35.2012 25.7982 35.0493C25.0439 34.5661 24.1361 34.3176 23.2617 34.4764C22.8879 34.5454 22.5207 34.6904 22.1469 34.7732C21.2458 34.9734 20.2846 34.6628 19.4235 35.0148C19.1899 35.1115 18.9763 35.2288 18.7694 35.3738C18.3955 35.6223 18.0551 35.9329 17.7347 36.2574C17.6546 36.3402 17.5745 36.4231 17.4944 36.5128C17.2675 36.7544 17.0472 37.0029 16.8269 37.2514V37.2514Z" fill="#401D00"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.854 46.6267C20.8103 45.4281 19.144 46.1487 19.144 48.2305C19.144 50.3124 20.8103 52.9876 22.854 54.1862C24.8965 55.3848 26.5628 54.6642 26.5628 52.583C26.5628 50.5005 24.8965 47.8253 22.854 46.6267Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.7571 48.2263C18.7134 47.0277 17.0471 47.7483 17.0471 49.8301C17.0471 51.912 18.7134 54.5872 20.7571 55.7858C22.7996 56.9844 24.4659 56.2638 24.4659 54.1826C24.4659 52.1001 22.7996 49.4249 20.7571 48.2263Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.7576 49.4973C19.4008 48.7017 18.2942 49.1796 18.2942 50.562C18.2942 51.9437 19.4008 53.72 20.7576 54.5162C22.1139 55.3118 23.2205 54.8332 23.2205 53.4515C23.2205 52.0691 22.1139 50.2928 20.7576 49.4973Z" fill="#353633"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.8609 49.681C19.6632 48.9783 18.686 49.4006 18.686 50.6209C18.686 51.8411 19.6632 53.4092 20.8609 54.1113C22.0581 54.814 23.0352 54.3917 23.0352 53.1714C23.0352 51.9517 22.0581 50.3831 20.8609 49.681Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.8603 49.9995C19.8356 49.3985 18.9995 49.7599 18.9995 50.8039C18.9995 51.8479 19.8356 53.1901 20.8603 53.791C21.885 54.392 22.7205 54.0306 22.7205 52.9866C22.7205 51.9426 21.885 50.601 20.8603 49.9995Z" fill="#8E4428"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.8606 50.3842C20.0426 49.9045 19.3755 50.1925 19.3755 51.026C19.3755 51.8588 20.0426 52.93 20.8606 53.4097C21.6781 53.8888 22.3452 53.6008 22.3452 52.7679C22.3452 51.9345 21.6781 50.8639 20.8606 50.3842Z" fill="#A34D2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.8603 50.5747C20.1456 50.1553 19.5632 50.4073 19.5632 51.1349C19.5632 51.8624 20.1456 52.7982 20.8603 53.217C21.5744 53.6358 22.1567 53.3838 22.1567 52.6562C22.1567 51.9287 21.5744 50.9935 20.8603 50.5747Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.2205 56.2325C23.7121 56.2149 24.5819 55.6029 25.8299 54.3965L25.0203 51.9056L23.7181 54.4722C22.8948 55.6633 22.7289 56.2501 23.2205 56.2325Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.4068 46.2238V46.2817L20.2227 47.7261L17.5327 48.2301C19.4488 46.7209 20.4068 46.03 20.4068 46.1572C20.4068 46.2844 20.4068 46.3066 20.4068 46.2238Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.8611 46.6267C42.8174 45.4281 41.1511 46.1487 41.1511 48.2305C41.1511 50.3124 42.8174 52.9876 44.8611 54.1862C46.9036 55.3848 48.5699 54.6642 48.5699 52.583C48.5699 50.5005 46.9036 47.8253 44.8611 46.6267Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.7656 48.2263C40.722 47.0277 39.0557 47.7483 39.0557 49.8301C39.0557 51.912 40.722 54.5872 42.7656 55.7858C44.8082 56.9844 46.4744 56.2638 46.4744 54.1826C46.4744 52.1001 44.8082 49.4249 42.7656 48.2263Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.7657 49.4971C41.4089 48.7016 40.3022 49.1795 40.3022 50.5619C40.3022 51.9436 41.4089 53.7199 42.7657 54.5161C44.1219 55.3116 45.2286 54.8331 45.2286 53.4514C45.2286 52.069 44.1219 50.2927 42.7657 49.4971Z" fill="#353633"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.8678 49.6805C41.67 48.9778 40.6929 49.4001 40.6929 50.6204C40.6929 51.8406 41.67 53.4087 42.8678 54.1108C44.0649 54.8136 45.0421 54.3912 45.0421 53.1709C45.0421 51.9513 44.0649 50.3826 42.8678 49.6805Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.8684 49.9995C41.8436 49.3985 41.0076 49.7599 41.0076 50.8039C41.0076 51.8479 41.8436 53.1901 42.8684 53.791C43.8931 54.392 44.7286 54.0306 44.7286 52.9866C44.7286 51.9426 43.8931 50.601 42.8684 49.9995Z" fill="#8E4428"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.8677 50.3838C42.0497 49.9041 41.3826 50.1922 41.3826 51.0256C41.3826 51.8584 42.0497 52.9297 42.8677 53.4094C43.6852 53.8885 44.3523 53.6004 44.3523 52.7676C44.3523 51.9341 43.6852 50.8635 42.8677 50.3838Z" fill="#A34D2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.8688 50.5756C42.1541 50.1562 41.5718 50.4082 41.5718 51.1357C41.5718 51.8633 42.1541 52.799 42.8688 53.2178C43.5829 53.6366 44.1653 53.3846 44.1653 52.6571C44.1653 51.9295 43.5829 50.9944 42.8688 50.5756Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.2283 56.2326C45.7199 56.215 46.5897 55.603 47.8377 54.3966L47.0281 51.9058L45.7259 54.4723C44.9026 55.6635 44.7367 56.2502 45.2283 56.2326Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.4146 46.2238V46.2817L42.2305 47.7261L39.5405 48.2301C41.4566 46.7209 42.4146 46.03 42.4146 46.1572C42.4146 46.2844 42.4146 46.3066 42.4146 46.2238Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.5418 48.4006L45.7944 50.0119V52.5187L48.5418 50.9128V48.4006Z" fill="#EB922D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.9895 46.4889L45.6636 49.9274L45.7942 50.0054L48.5416 48.3995L48.4063 48.3179L48.379 48.3019L42.7363 44.8777L39.9895 46.4889Z" fill="#E9B237"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.1774 49.7494V50.3048C48.1774 50.4101 48.1217 50.5071 48.0323 50.5598L46.4862 51.4902C46.4072 51.5363 46.3091 51.4778 46.3091 51.3849V51.3187L47.6961 50.4823C47.7849 50.4302 47.8413 50.3326 47.8413 50.2279V49.7388L48.0003 49.6459C48.0793 49.5997 48.1774 49.6583 48.1774 49.7494Z" fill="#121311"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.8413 49.7383V50.2275C47.8413 50.3322 47.7849 50.4297 47.6961 50.4818L46.3091 51.3182V50.8302C46.3091 50.7237 46.3648 50.6267 46.4542 50.5741L47.8413 49.7383Z" fill="#FFF3CE"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.1553 60.2342L31.2368 54.8583V48.2595L40.1553 53.6354V60.2342Z" fill="#A95C2E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.7228 49.7788L40.1558 53.6337V60.2325L46.7228 56.4018V49.7788Z" fill="#EEA32D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M31.2373 48.2559L40.154 53.6342L46.7222 49.8012L37.802 44.4016L31.2373 48.2559Z" fill="#F4D251"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.48 57.9546L35.7449 57.5246L35.2439 54.7067L36.48 55.4478V57.9546Z" fill="#D16F2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.9958 46.9546L31.3536 48.3384V54.5851L28.9958 53.2102V46.9546Z" fill="#502D1B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M27.394 54.3983L29.3251 53.2395V49.3214L27.394 50.4802V54.3983Z" fill="#8D4528"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.0366 49.0977L27.3944 50.4812V54.3993L25.0366 53.0247V49.0977Z" fill="#6D3822"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M29.3254 49.3217L27.5982 50.3586L27.3944 50.4816L25.0366 49.1058L25.248 48.978L25.2903 48.9532L26.9688 47.9387L29.3254 49.3217Z" fill="#F4D251"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M27.0567 51.1037L20.7556 47.3221L12.9971 38.1724L12.5698 35.7924L31.1127 46.9305L27.0567 51.1037Z" fill="#A95C2E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M29.688 47.1193L26.9134 49.9732L21.3293 46.6212L14.5526 38.6322L14.4319 37.9565L29.688 47.1193Z" fill="#874226"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.5186 40.1492L31.1129 46.9313L27.0569 51.1045L38.4626 44.3466L42.5186 40.1492Z" fill="#CA722E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.5708 35.7916L31.1119 46.9315L42.5182 40.1718L23.9742 29.01L12.5708 35.7916Z" fill="#ECC530"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.7704 40.1657L35.2478 43.4396L31.1129 45.8889L28.0323 44.0372L16.5197 37.1195L14.3164 35.7961L23.971 30.0552L40.7704 40.1657Z" fill="#E2832D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.3978 32.4346L16.5197 37.1195L14.3164 35.7961L23.971 30.0552L24.3978 32.4346Z" fill="#EEA32D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.248 43.4388L31.1131 45.8877L28.0325 44.0363L32.1587 41.5845L35.248 43.4388Z" fill="#EEA32D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.1573 41.5821L35.2477 43.4373L40.7709 40.1646L23.9709 30.0529L24.3983 32.4323L32.1573 41.5821Z" fill="#A95C2E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M31.1397 46.9499L30.209 46.3856V44.7861L31.1397 45.3504V46.9499Z" fill="#A95C2E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.0452 51.3863L33.7466 47.0149L36.329 44.6166L39.5426 46.5452L41.0452 51.3863Z" fill="#212220"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.4652 44.2445L39.5427 46.547L41.0453 51.3881L44.9678 49.0998L43.4652 44.2445Z" fill="#434341"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.3298 44.6161L39.5422 46.5456L43.4653 44.2565L40.2506 42.314L36.3298 44.6161Z" fill="#F4D251"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.6873 54.9589L46.1883 51.7252L46.3196 51.7992L40.6873 58.3707V54.9589Z" fill="#20211F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.6873 54.9592L40.8185 55.0332V58.4444L40.6873 58.3704V54.9592Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.8186 55.0345L46.3197 51.8008V55.1564L40.8186 58.4463V55.0345Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 52.1168V55.0495L45.3696 55.5109L45.2529 55.5807L43.8438 56.4236L43.7271 56.494L42.3145 57.3368L42.2007 57.4072L42.0898 57.4717L40.9983 58.1253V55.5653L41.9731 54.9945L42.2007 54.8632V54.7407L41.9731 54.8721L40.9983 55.4434V55.1376L41.9731 54.5668L42.3145 54.3651L43.5018 53.666L43.7271 53.5341L43.8438 53.4672L45.0282 52.7704L45.2529 52.6391L45.3696 52.5693L46.14 52.1168Z" fill="#0A0B09"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 53.3885V53.5104L45.3696 53.9594L45.2529 54.0297L45.0282 54.1611L43.8438 54.8513L43.7271 54.9217L43.5018 55.053L42.3145 55.7463L42.2007 55.8137L41.9731 55.945L40.9983 56.5164V56.394L41.9731 55.8226L42.2007 55.6913L42.3145 55.6244L43.5018 54.9312L43.7271 54.7993L43.8438 54.7295L45.0282 54.0392L45.2529 53.9079L45.3696 53.8375L46.14 53.3885Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2007 56.1774V56.2969L41.9731 56.4282L40.9983 56.999V56.8801L41.9731 56.3087L42.2007 56.1774Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 53.87V53.9918L45.3695 54.4408L45.2528 54.5112L45.0282 54.6425L43.8438 55.3328L43.7271 55.4032L43.5018 55.5345L42.3145 56.2277L42.2007 56.2952V56.1757L42.3145 56.1088L43.5018 55.412L43.7271 55.2807L43.8438 55.2139L45.0282 54.5201L45.2528 54.3887L45.3695 54.3219L46.14 53.87Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2007 56.656V56.7779L41.9731 56.9098L40.9983 57.4806V57.3587L41.9731 56.7873L42.2007 56.656Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2007 57.1331V57.2549L41.9731 57.3862L40.9983 57.9576V57.8352L41.9731 57.2644L42.2007 57.1331Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 54.832V54.9509L45.3695 55.4034L45.2528 55.4703L45.0282 55.6016L43.8438 56.2954L43.7271 56.3623L43.5018 56.4936L42.3145 57.1904L42.2007 57.2572V57.1354L42.3145 57.0679L43.5018 56.3747L43.7271 56.2434L43.8438 56.173L45.0282 55.4827L45.2528 55.3514L45.3695 55.281L46.14 54.832Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.0163 53.3422L46.14 53.3907L45.3696 53.8396L45.2529 53.91L45.0282 54.0413L43.8438 54.7316L43.7271 54.8014L43.5018 54.9333L42.3145 55.6266L42.2007 55.6934L41.9731 55.8247L40.9983 56.3961V56.2772L41.9731 55.7058L42.2007 55.5745L42.3145 55.5071L43.5018 54.8109L43.7271 54.6796L43.8438 54.6121L45.0282 53.9189L45.2529 53.7876L45.3696 53.7201L46.0163 53.3422Z" fill="#20211F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.0163 53.8236L46.14 53.8692L45.3696 54.3211L45.2529 54.3879L45.0282 54.5192L43.8438 55.2131L43.7271 55.2799L43.5018 55.4112L42.3145 56.108L42.2007 56.1748L41.9731 56.3062L40.9983 56.8775V56.7551L41.9731 56.1843L42.2007 56.053L42.3145 55.9856L43.5018 55.2923L43.7271 55.161L43.8438 55.0906L45.0282 54.4003L45.2529 54.269L45.3696 54.1986L46.0163 53.8236Z" fill="#20211F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2007 54.7421V54.8645L41.9731 54.9958L40.9983 55.5666V55.4448L41.9731 54.8734L42.2007 54.7421Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 52.4346V52.5564L45.3695 53.0083L45.2528 53.0758L45.0282 53.2071L43.8438 53.9003L43.7271 53.9678L43.5018 54.0991L42.3145 54.7953L42.2007 54.8627V54.7403L42.3145 54.6734L43.5018 53.9766L43.7271 53.8453L43.8438 53.7785L45.0282 53.0846L45.2528 52.9533L45.3695 52.8865L46.14 52.4346Z" fill="#2D2E2C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 52.9159V53.0354L45.3696 53.4873L45.2529 53.5547L45.0282 53.686L43.8438 54.3793L43.7271 54.4461L43.5018 54.578L42.3145 55.2742L42.2007 55.3411L41.9731 55.473L40.9983 56.0438V55.9219L41.9731 55.3505L42.2007 55.2192L42.3145 55.1518L43.5018 54.4585L43.7271 54.3272L43.8438 54.2568L45.0282 53.5665L45.2529 53.4352L45.3696 53.3648L46.14 52.9159Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.0163 52.3883L46.14 52.4339L45.3696 52.8858L45.2529 52.9526L45.0282 53.0839L43.8438 53.7778L43.7271 53.8446L43.5018 53.9759L42.3145 54.6727L42.2007 54.7395L41.9731 54.8709L40.9983 55.4422V55.3198L41.9731 54.749L42.2007 54.6177L42.3145 54.5503L43.5018 53.857L43.7271 53.7257L43.8438 53.6553L45.0282 52.965L45.2529 52.8337L45.3696 52.7633L46.0163 52.3883Z" fill="#20211F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.0163 52.8674L46.14 52.9159L45.3696 53.3649L45.2529 53.4353L45.0282 53.5666L43.8438 54.2569L43.7271 54.3273L43.5018 54.4586L42.3145 55.1518L42.2007 55.2193L41.9731 55.3506L40.9983 55.922V55.8025L41.9731 55.2311L42.2007 55.1004L42.3145 55.0329L43.5018 54.3361L43.7271 54.2048L43.8438 54.138L45.0282 53.4447L45.2529 53.3134L45.3696 53.246L46.0163 52.8674Z" fill="#20211F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.3696 52.5685V55.51L45.2529 55.5798V52.6383L45.3696 52.5685Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 52.6373V52.952L45.0283 52.9644V52.7686L45.253 52.6373Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 53.0745V53.4347L45.0283 53.4471V53.2058L45.253 53.0745Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 53.5558V53.9101L45.0283 53.9219V53.6871L45.253 53.5558Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 54.0327V54.39L45.0283 54.4024V54.164L45.253 54.0327Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 54.3506V54.4701L45.3695 54.922L45.2528 54.9894L45.0282 55.1207L43.8438 55.814L43.7271 55.8814L43.5018 56.0127L42.3145 56.7089L42.2007 56.7758V56.6539L42.3145 56.5865L43.5018 55.8932L43.7271 55.7619L43.8438 55.6915L45.0282 55.0012L45.2528 54.8699L45.3695 54.7995L46.14 54.3506Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 54.5098V54.87L45.0283 54.8824V54.6411L45.253 54.5098Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 54.9911V55.3513L45.0283 55.3637V55.1224L45.253 54.9911Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 55.4703V55.5804L45.0283 55.7146V55.6017L45.253 55.4703Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.8433 53.4658V56.4222L43.7266 56.4925V53.5327L43.8433 53.4658Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 53.5348V53.8465L43.502 53.8589V53.6667L43.7272 53.5348Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 53.9677V54.3279L43.502 54.3403V54.099L43.7272 53.9677Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 54.4468V54.8011L43.502 54.8135V54.5787L43.7272 54.4468Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 54.9216V55.2789L43.502 55.2913V55.0529L43.7272 54.9216Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 55.403V55.7632L43.502 55.7756V55.5343L43.7272 55.403Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 55.8822V56.2424L43.502 56.2543V56.0135L43.7272 55.8822Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 56.3613V56.4926L43.502 56.6269V56.4926L43.7272 56.3613Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.3145 54.3656V57.3373L42.2007 57.4077V54.433L42.3145 54.3656Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 54.4324V54.7405L41.9727 54.75V54.5666L42.2002 54.4324Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 54.8616V55.2188L41.9727 55.2307V54.9929L42.2002 54.8616Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 55.3401V55.6914L41.9727 55.7039V55.472L42.2002 55.3401Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 55.8157V56.1759L41.9727 56.1883V55.947L42.2002 55.8157Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 56.2939V56.6542L41.9727 56.666V56.4253L42.2002 56.2939Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 56.7775V57.1353L41.9727 57.1472V56.9094L42.2002 56.7775Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.9727 57.3902L42.2002 57.2589V57.4086L42.0894 57.473H41.9727V57.3902Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.6873 54.1127L46.1883 50.879L46.3196 50.953L40.6873 57.3246V54.1127Z" fill="#20211F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.6873 54.1125L40.8185 54.1865V57.3978L40.6873 57.3238V54.1125Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.8186 54.186L46.3197 50.9523V54.1079L40.8186 57.3978V54.186Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 51.2706V54.2027L45.3696 54.6641L45.2529 54.7339L43.8438 55.5774L43.7271 55.6472L43.5018 55.782L42.3145 56.4907L42.2007 56.5605L42.0898 56.6249L40.9983 57.2785V54.7191L41.9731 54.1477L42.2007 54.0164V53.894L41.9731 54.0253L40.9983 54.5967V54.2914L41.9731 53.7201L42.2007 53.5858L42.3145 53.5183L43.5018 52.8192L43.7271 52.6879L43.8438 52.6204L45.0282 51.9242L45.2529 51.7929L45.3696 51.7225L46.14 51.2706Z" fill="#0A0B09"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 52.5454V52.6679L45.3696 53.1162L45.2529 53.1866L45.0282 53.3179L43.8438 54.0082L43.7271 54.0786L43.5018 54.2099L42.3145 54.9031L42.2007 54.9706L41.9731 55.1019L40.9983 55.6733V55.5508L41.9731 54.98L42.2007 54.8481L42.3145 54.7813L43.5018 54.0881L43.7271 53.9567L43.8438 53.8864L45.0282 53.1961L45.2529 53.0648L45.3696 52.9944L46.14 52.5454Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2007 55.33V55.4494L41.9731 55.5808L40.9983 56.1521V56.0327L41.9731 55.4613L42.2007 55.33Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 53.0245V53.1464L45.3695 53.5953L45.2528 53.6657L45.0282 53.797L43.8438 54.4873L43.7271 54.5577L43.5018 54.689L42.3145 55.3823L42.2007 55.4497V55.3302L42.3145 55.2634L43.5018 54.5666L43.7271 54.4353L43.8438 54.3684L45.0282 53.6746L45.2528 53.5439L45.3695 53.4764L46.14 53.0245Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2007 55.8091V55.9315L41.9731 56.0628L40.9983 56.6336V56.5118L41.9731 55.9404L42.2007 55.8091Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2007 56.2882V56.4101L41.9731 56.5414L40.9983 57.1128V56.9903L41.9731 56.4195L42.2007 56.2882Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 53.9851V54.1046L45.3695 54.5565L45.2528 54.6239L45.0282 54.7552L43.8438 55.4485L43.7271 55.5153L43.5018 55.6466L42.3145 56.3434L42.2007 56.4103V56.2884L42.3145 56.221L43.5018 55.5278L43.7271 55.3964L43.8438 55.326L45.0282 54.6358L45.2528 54.5044L45.3695 54.4341L46.14 53.9851Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2007 53.8925V54.0149L41.9731 54.1462L40.9983 54.7176V54.5952L41.9731 54.0238L42.2007 53.8925Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 51.5891V51.711L45.3695 52.1629L45.2528 52.2303L45.0282 52.3616L43.8438 53.0549L43.7271 53.1223L43.5018 53.2536L42.3145 53.9498L42.2007 54.0172V53.8948L42.3145 53.828L43.5018 53.1312L43.7271 52.9998L43.8438 52.933L45.0282 52.2398L45.2528 52.1085L45.3695 52.041L46.14 51.5891Z" fill="#2D2E2C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 52.0706V52.19L45.3696 52.6419L45.2529 52.7094L45.0282 52.8407L43.8438 53.5339L43.7271 53.6014L43.5018 53.7327L42.3145 54.4289L42.2007 54.4963L41.9731 54.6276L40.9983 55.1984V55.0766L41.9731 54.5052L42.2007 54.3739L42.3145 54.3064L43.5018 53.6132L43.7271 53.4819L43.8438 53.4115L45.0282 52.7212L45.2529 52.5899L45.3696 52.5201L46.14 52.0706Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.3696 51.723V54.6646L45.2529 54.7344V51.7934L45.3696 51.723Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 51.7947V52.1094L45.0283 52.1212V51.926L45.253 51.7947Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 52.2297V52.59L45.0283 52.6024V52.3611L45.253 52.2297Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 52.709V53.0633L45.0283 53.0751V52.8403L45.253 52.709Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 53.1859V53.5438L45.0283 53.5556V53.3172L45.253 53.1859Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.14 53.506V53.6255L45.3695 54.0774L45.2528 54.1448L45.0282 54.2761L43.8438 54.9694L43.7271 55.0368L43.5018 55.1681L42.3145 55.8643L42.2007 55.9317V55.8093L42.3145 55.7419L43.5018 55.0486L43.7271 54.9173L43.8438 54.8469L45.0282 54.1566L45.2528 54.0253L45.3695 53.9555L46.14 53.506Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 53.6644V54.0247L45.0283 54.0371V53.7957L45.253 53.6644Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 54.1436V54.5038L45.0283 54.5162V54.2749L45.253 54.1436Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.253 54.625V54.7344L45.0283 54.8693V54.7563L45.253 54.625Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.8433 52.6228V55.5797L43.7266 55.6495V52.6902L43.8433 52.6228Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 52.6879V52.999L43.502 53.0114V52.8192L43.7272 52.6879Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 53.1208V53.4811L43.502 53.4935V53.2522L43.7272 53.1208Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 53.6021V53.9564L43.502 53.9682V53.7334L43.7272 53.6021Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 54.077V54.4343L43.502 54.4467V54.2083L43.7272 54.077Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 54.5582V54.9185L43.502 54.9309V54.6895L43.7272 54.5582Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 55.0367V55.397L43.502 55.4094V55.1681L43.7272 55.0367Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7272 55.516V55.6473L43.502 55.7822V55.6473L43.7272 55.516Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.3145 53.5182V56.4905L42.2007 56.5603V53.5856L42.3145 53.5182Z" fill="#333431"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 53.5854V53.8936L41.9727 53.9031V53.7197L42.2002 53.5854Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 54.0161V54.3734L41.9727 54.3858V54.1474L42.2002 54.0161Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 54.4952V54.846L41.9727 54.8584V54.6266L42.2002 54.4952Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 54.9701V55.3303L41.9727 55.3427V55.1014L42.2002 54.9701Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 55.4493V55.8096L41.9727 55.822V55.5807L42.2002 55.4493Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2002 55.9301V56.2873L41.9727 56.2992V56.0614L42.2002 55.9301Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.9727 56.5405L42.2002 56.4092V56.5588L42.0894 56.6233H41.9727V56.5405Z" fill="#0B0C0A"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.6257 61.0355L39.2251 60.7954V59.9827L39.6257 60.2228V61.0355Z" fill="#D16F2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.7827 55.4574L39.626 60.222V61.0347L47.7827 56.2719V55.4574Z" fill="#EEA32D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.2251 59.9814L39.6257 60.2215L47.7824 55.4587L47.7383 55.4321L47.7296 55.4274L47.3818 55.2168L39.2251 59.9814Z" fill="#F4D251"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M34.7509 47.5407L30.209 44.786V44.0591L34.7509 46.8131V47.5407Z" fill="#A95C2E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.7339 49.1792L16.603 41.864V41.1364L28.7339 48.4515V49.1792Z" fill="#A95C2E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.1296 40.0295L34.7512 46.8135V47.5411L46.1296 40.7572V40.0295Z" fill="#EEA32D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.209 44.0582L34.7138 46.7914L34.7509 46.8133L46.1293 40.0293L41.5874 37.2725L30.209 44.0582Z" fill="#F4D251"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.8017 41.2823L28.5023 48.3378L28.7038 48.1301L17.0403 41.0966L16.7378 41.2066L16.8017 41.2823Z" fill="#E9B237"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M34.9055 53.5498C32.8619 52.3512 31.1956 53.0718 31.1956 55.1536C31.1956 57.2355 32.8619 59.9107 34.9055 61.1093C36.948 62.3079 38.6143 61.5873 38.6143 59.5061C38.6143 57.4236 36.948 54.7484 34.9055 53.5498Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.8093 55.1452C30.7657 53.9466 29.0994 54.6672 29.0994 56.7491C29.0994 58.8309 30.7657 61.5062 32.8093 62.7048C34.8519 63.9034 36.5181 63.1828 36.5181 61.1015C36.5181 59.0191 34.8519 56.3438 32.8093 55.1452Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.8091 56.4147C31.4523 55.6192 30.3457 56.0971 30.3457 57.4795C30.3457 58.8612 31.4523 60.6375 32.8091 61.4337C34.1654 62.2292 35.272 61.7507 35.272 60.369C35.272 58.9866 34.1654 57.2103 32.8091 56.4147Z" fill="#353633"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.9122 56.598C31.7144 55.8952 30.7373 56.3176 30.7373 57.5379C30.7373 58.7581 31.7144 60.3262 32.9122 61.0283C34.1094 61.731 35.0865 61.3087 35.0865 60.0884C35.0865 58.8687 34.1094 57.3001 32.9122 56.598Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.9123 56.919C31.8876 56.3181 31.0515 56.6795 31.0515 57.7235C31.0515 58.7675 31.8876 60.1096 32.9123 60.7106C33.937 61.3115 34.7725 60.9501 34.7725 59.9061C34.7725 58.8621 33.937 57.5206 32.9123 56.919Z" fill="#8E4428"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.9117 57.302C32.0936 56.8223 31.4265 57.1104 31.4265 57.9438C31.4265 58.7767 32.0936 59.8479 32.9117 60.3276C33.7291 60.8067 34.3962 60.5186 34.3962 59.6858C34.3962 58.8524 33.7291 57.7817 32.9117 57.302Z" fill="#A34D2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.9123 57.4965C32.1976 57.0771 31.6152 57.3291 31.6152 58.0566C31.6152 58.7842 32.1976 59.7199 32.9123 60.1387C33.6264 60.5575 34.2087 60.3055 34.2087 59.578C34.2087 58.8504 33.6264 57.9153 32.9123 57.4965Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.272 63.1572C35.7636 63.1396 36.6334 62.5275 37.8814 61.3211L37.0718 58.8303L35.7696 61.3969C34.9463 62.588 34.7804 63.1748 35.272 63.1572Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.4583 53.1436V53.2015L32.2742 54.6459L29.5842 55.1499C31.5003 53.6407 32.4583 52.9498 32.4583 53.077C32.4583 53.2042 32.4583 53.2264 32.4583 53.1436Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.6743 52.3711L36.349 55.809L36.4791 55.8876L39.2264 54.2811L39.0911 54.1995L39.0644 54.1835L30.6743 51.9323V52.3711Z" fill="#D16F2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.2461 52.8731L30.6747 52.368L33.4215 50.7567L28.2461 52.4348V52.8731Z" fill="#D16F2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.6743 51.9295L36.349 55.3679L36.4791 55.446L39.2264 53.8401L39.0911 53.7584L39.0644 53.7425L33.4211 50.3182L30.6743 51.9295Z" fill="#E9B237"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.2461 52.4352L30.6747 51.9295L33.4215 50.3182L30.9923 50.824L28.2461 52.4352Z" fill="#A44C2C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.2265 53.8359L36.4797 55.4472V57.954L39.2265 56.3481V53.8359Z" fill="#EB922D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.7577 55.1714V55.7274C38.7577 55.8321 38.702 55.9297 38.6126 55.9818L37.0665 56.9122C36.9875 56.9584 36.8894 56.8998 36.8894 56.8075V56.7413L38.2764 55.9049C38.3653 55.8522 38.4216 55.7552 38.4216 55.65V55.1608L38.5807 55.0685C38.6596 55.0218 38.7577 55.0809 38.7577 55.1714Z" fill="#121311"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.4216 55.1605V55.6497C38.4216 55.755 38.3653 55.852 38.2764 55.9046L36.8894 56.741V56.253C36.8894 56.1466 36.9451 56.0496 37.0346 55.9969L38.4216 55.1605Z" fill="#FFF3CE"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.4792 57.9525L35.7442 57.5225L35.2432 54.7046L36.4792 55.4457V57.9525Z" fill="#D16F2D"/>
        <path d="M16.7334 37.196L16.8268 37.2581L28.1878 44.182L30.3639 45.5143V44.2096L30.5708 44.0854L37.7416 39.7215C37.6081 39.611 36.8307 39.248 36.6705 39.1859C35.5758 38.7372 35.2033 38.2729 34.389 37.3893C34.1019 37.0787 33.8216 36.7542 33.5079 36.4781C33.1341 36.1536 32.7002 35.9051 32.2863 35.629C30.9647 34.7316 29.8299 33.4683 28.308 33.0127C27.794 32.8608 27.2533 32.7987 26.766 32.564C26.4523 32.4121 26.1786 32.1843 25.8449 32.0877C25.5912 32.0186 25.3309 32.0255 25.0772 32.0877C24.7301 32.1705 24.383 32.3431 24.0893 32.5502C23.5754 32.9022 23.0881 33.344 22.494 33.5373C22.1068 33.6616 21.6997 33.6823 21.3058 33.7513C20.3046 33.9446 19.3767 34.5314 18.7626 35.3736C18.7626 35.3736 18.7626 35.3805 18.7559 35.3874C18.549 35.6704 18.3621 35.9949 18.0551 36.1536C17.9483 36.202 17.8415 36.2365 17.728 36.271C17.6612 36.2917 17.5811 36.3193 17.5211 36.3538C17.3408 36.4436 17.1272 36.5885 16.9937 36.7404C16.8736 36.8785 16.8001 37.0441 16.7334 37.2098V37.196Z" fill="#4A2500"/>
        <path d="M25.0842 31.9473C25.4981 32.4237 26.3391 32.6515 26.8131 32.9414C27.7609 33.5144 28.7755 33.9424 29.7701 34.4187C30.204 34.6258 30.7447 35.0054 31.0317 35.3989C31.3588 35.8476 31.4923 36.4137 31.8594 36.8555C31.9929 37.0143 32.1398 37.1661 32.1865 37.3663C32.28 37.7253 32.033 38.0843 32.0063 38.457C31.9929 38.6434 32.033 38.8367 31.9929 39.0231C31.9128 39.389 31.5457 39.603 31.272 39.8515C30.5845 40.4728 30.698 41.2114 30.5444 42.0398C30.3842 42.9096 30.1239 43.7242 30.2507 44.6216C30.2708 44.7734 30.3108 44.9322 30.3709 45.0772V44.0831L30.5778 43.9589L37.7416 39.6023C37.6081 39.4918 36.8306 39.1288 36.6704 39.0667C35.5757 38.618 35.2103 38.1464 34.396 37.2628C34.1089 36.9521 33.8286 36.6277 33.5148 36.3516C33.141 36.0271 32.7072 35.7786 32.2933 35.5025C30.9716 34.6051 29.8369 33.3418 28.315 32.8862C27.801 32.7343 27.2603 32.6722 26.773 32.4375C26.4593 32.2856 26.1856 32.0578 25.8519 31.9612C25.5982 31.8921 25.3379 31.899 25.0842 31.9612V31.9473Z" fill="#5E3A16"/>
        <path d="M19.6767 35.3858C19.4364 35.4134 19.2428 35.5998 19.0693 35.7792L18.8957 35.9518C18.8423 36.007 18.7889 36.0622 18.7622 36.1312C18.8824 36.145 19.0025 36.1519 19.1227 36.1657C19.3162 36.1795 19.5232 36.1934 19.6767 36.0691C19.7434 36.0139 19.7968 35.938 19.8703 35.9035C19.9704 35.8552 20.1973 35.8621 20.204 35.7102C20.204 35.4686 19.8569 35.3582 19.6834 35.3789L19.6767 35.3858Z" fill="#696969"/>
        <path d="M29.5355 35.9173C29.5355 35.8069 29.6757 35.7585 29.7624 35.7309C29.8893 35.6964 29.956 35.7654 30.0628 35.8414C30.1229 35.8828 30.183 35.9173 30.243 35.9587C30.3632 36.0484 30.49 36.3383 30.3365 36.4418C30.2831 36.4764 30.2163 36.4902 30.1496 36.504L29.8692 36.5592C29.5555 36.6213 29.5288 36.1313 29.5288 35.9104L29.5355 35.9173Z" fill="#9C9C9C"/>
        <path d="M24.7766 33.3154C24.7098 33.343 24.6498 33.274 24.6097 33.2257C24.5563 33.1567 24.5763 33.0946 24.5964 33.0117C24.603 32.9634 24.6097 32.9151 24.6164 32.8668C24.6364 32.7702 24.7699 32.6114 24.87 32.6805C24.9034 32.7012 24.9301 32.7426 24.9568 32.7771L25.0636 32.9358C25.1838 33.1153 24.9101 33.2602 24.7766 33.3223V33.3154Z" fill="#9C9C9C"/>
        <path d="M24.5361 37.3319C24.4694 37.3595 24.4093 37.2905 24.3692 37.2422C24.3158 37.1732 24.3359 37.1111 24.3559 37.0282C24.3626 36.9799 24.3692 36.9316 24.3759 36.8833C24.3959 36.7867 24.5294 36.6279 24.6296 36.6969C24.6629 36.7176 24.6896 36.759 24.7163 36.7936L24.8231 36.9523C24.9433 37.1318 24.6696 37.2767 24.5361 37.3388V37.3319Z" fill="#696969"/>
        <path d="M23.7418 35.1519C23.9421 35.1174 24.109 35.214 24.2224 35.3659C24.2425 35.3935 24.4628 35.6626 24.3893 35.7041C24.2892 35.7593 24.1757 35.7869 24.0622 35.78C23.9354 35.7662 23.8086 35.711 23.6884 35.7524C23.6551 35.7662 23.6083 35.78 23.5816 35.7593C23.5616 35.7455 23.5549 35.7179 23.5549 35.6902C23.5349 35.4901 23.5015 35.2002 23.7418 35.1519V35.1519Z" fill="#696969"/>
        <path d="M29.6559 39.5468C29.676 39.4847 29.7227 39.4364 29.7961 39.4087C29.9496 39.3466 30.4503 39.4226 30.4503 39.6503C30.4503 39.7608 30.3301 39.816 30.27 39.8919C30.2033 39.9816 30.1699 40.0989 30.1432 40.2094C30.1299 40.2577 30.1165 40.306 30.0831 40.3336C30.0498 40.3612 29.9964 40.3543 29.963 40.3336C29.9229 40.3129 29.8896 40.2853 29.8629 40.2508C29.7494 40.1128 29.5758 39.7608 29.6559 39.5468V39.5468Z" fill="#9C9C9C"/>
        <path d="M27.2796 35.2894C27.293 35.2549 27.333 35.2342 27.3731 35.2204C27.5667 35.1514 27.7736 35.1238 27.9738 35.1583L27.9538 35.2204C28.0406 35.2687 28.1207 35.3515 28.1674 35.4413C28.2075 35.5103 28.2208 35.6069 28.1674 35.6621C28.1274 35.6966 28.074 35.7035 28.0206 35.6966C27.9672 35.6897 27.9204 35.669 27.867 35.669C27.7402 35.669 27.6334 35.7864 27.5066 35.7726C27.4198 35.7657 27.3464 35.6966 27.313 35.6138C27.2796 35.531 27.2729 35.4413 27.2729 35.3515C27.2729 35.3308 27.2729 35.3032 27.2863 35.2825L27.2796 35.2894Z" fill="#9C9C9C"/>
        <path d="M24.0013 39.1198C24.0013 39.1198 23.9679 39.106 23.9545 39.0991C23.9145 39.0646 23.8811 39.0232 23.8411 38.9956L24.0013 39.1198V39.1198Z" fill="#696969"/>
        <path d="M23.3877 39.0846C23.4478 39.057 23.5146 39.057 23.5746 39.057C23.7148 39.057 23.8283 39.0708 23.9618 39.1329C24.0819 39.1881 24.2555 39.3607 24.1821 39.5125C24.1487 39.5885 24.0552 39.6299 23.9685 39.6299C23.8817 39.6299 23.8016 39.6023 23.7148 39.5954C23.5947 39.5885 23.4745 39.6161 23.3677 39.5609C23.2809 39.5194 23.2275 39.4159 23.2275 39.3193C23.2275 39.2227 23.2943 39.126 23.3811 39.0846H23.3877Z" fill="#696969"/>
        <path d="M20.1445 37.7188C20.1445 37.7188 20.1712 37.7395 20.1846 37.7464C20.218 37.7878 20.2447 37.8292 20.2847 37.8706L20.1445 37.7188V37.7188Z" fill="#696969"/>
        <path d="M20.7446 37.8642C20.6846 37.878 20.6178 37.8642 20.5511 37.8642C20.4109 37.8435 20.3041 37.809 20.1839 37.7193C20.0771 37.6434 19.937 37.4432 20.0304 37.3052C20.0771 37.2362 20.1773 37.2155 20.2574 37.2293C20.3375 37.2431 20.4176 37.2845 20.4977 37.3052C20.6111 37.3328 20.738 37.3328 20.8314 37.4018C20.9115 37.4639 20.9516 37.5675 20.9315 37.6641C20.9115 37.7607 20.8381 37.8435 20.7446 37.8642V37.8642Z" fill="#696969"/>
        <path d="M28.5749 43.0403C28.7151 42.8333 29.0622 42.8816 29.2024 43.0887C29.2825 43.2129 29.2758 43.4683 29.2625 43.6063C29.2625 43.6408 29.2491 43.6822 29.2224 43.7098C29.189 43.7374 29.1423 43.7374 29.1023 43.7305L28.5549 43.6753C28.5549 43.6753 28.5349 43.6753 28.5215 43.6753C28.4815 43.6615 28.4748 43.6132 28.4681 43.5718C28.4414 43.3854 28.4681 43.1922 28.5749 43.0403Z" fill="#696969"/>
        <path d="M26.3784 39.223C26.6187 39.223 26.7789 39.5543 26.6854 39.789C26.632 39.927 26.4184 40.0651 26.2983 40.1272C26.2649 40.1479 26.2315 40.1617 26.1982 40.1548C26.1581 40.141 26.1314 40.0996 26.1114 40.0651C26.0246 39.8994 25.9378 39.7338 25.8511 39.5681C25.8511 39.5612 25.8377 39.5474 25.8377 39.5405C25.831 39.4991 25.8577 39.4646 25.8911 39.437C26.0246 39.3127 26.1982 39.2299 26.3784 39.2299V39.223Z" fill="#696969"/>
        <path d="M32.5659 37.6775C32.6794 37.5257 32.9064 37.5533 32.9931 37.7258C33.0599 37.8708 33.0265 38.0433 32.9931 38.1952C32.9531 38.3539 32.7261 38.8025 32.5526 38.4851C32.4591 38.3125 32.4458 37.8432 32.5726 37.6775H32.5659Z" fill="#C2C2C2"/>
        <path d="M34.2082 40.204C34.1481 40.0659 34.1882 39.8106 34.3017 39.7139C34.3684 39.6518 34.4619 39.6242 34.5553 39.6173C34.5954 39.6173 34.6421 39.6173 34.6822 39.6311C34.7823 39.6656 34.8624 39.7554 34.9024 39.8589C34.9759 40.0935 34.8223 40.3627 34.6421 40.5077C34.6288 40.5215 34.6087 40.5353 34.5887 40.5353C34.5553 40.5353 34.522 40.5215 34.4952 40.5008C34.4285 40.4456 34.3618 40.3903 34.3017 40.3282C34.2683 40.2937 34.2349 40.2592 34.2216 40.2178L34.2082 40.204Z" fill="#C2C2C2"/>
        <path d="M31.8254 42.3225C31.7386 42.1362 31.6786 41.9429 31.6452 41.7428C31.6185 41.5633 31.5651 41.4184 31.772 41.4667C31.8655 41.4874 32.0056 41.5426 32.0791 41.6047C32.1058 41.6323 32.2326 41.8877 32.2126 41.8877C32.0791 41.8877 32.0257 42.2535 31.9322 42.3501C31.9122 42.3708 31.8922 42.3846 31.8655 42.3777C31.8388 42.3777 31.8254 42.3501 31.8187 42.3225H31.8254Z" fill="#C2C2C2"/>
        <path d="M32.3262 40.1136C32.3395 40.1481 32.3596 40.1895 32.393 40.1895C32.413 40.1895 32.4263 40.1757 32.4397 40.1619C32.5131 40.0929 32.5732 40.0032 32.6733 39.9825C32.7334 39.9687 32.8068 39.9825 32.8268 39.9204C32.8268 39.6029 32.413 39.7133 32.2261 39.7685C32.2327 39.8858 32.2795 39.9963 32.3195 40.1067L32.3262 40.1136Z" fill="#C2C2C2"/>
        <path d="M25.8237 41.7495C25.837 41.784 25.8571 41.8254 25.8904 41.8254C25.9105 41.8254 25.9238 41.8116 25.9372 41.7978C26.0106 41.7288 26.0707 41.639 26.1708 41.6183C26.2309 41.6045 26.311 41.6183 26.331 41.5562C26.331 41.2387 25.9171 41.3492 25.7302 41.4044C25.7369 41.5217 25.7836 41.6321 25.8237 41.7426V41.7495Z" fill="#696969"/>
        <path d="M34.3818 38.2086C34.1816 38.0706 34.2884 37.8773 34.4953 37.9325C34.7156 37.9877 34.8758 38.1189 35.0627 38.2431C35.1094 38.2707 35.1561 38.3052 35.1695 38.3604C35.1828 38.4364 35.1227 38.4985 35.0627 38.5399L34.9692 38.6158C34.9692 38.6158 34.9559 38.6227 34.9492 38.6227C34.9358 38.6227 34.9292 38.6158 34.9225 38.6089C34.8691 38.5399 34.8424 38.4433 34.769 38.3881C34.7156 38.3466 34.6421 38.3328 34.5754 38.3121C34.502 38.2914 34.4352 38.2569 34.3751 38.2155L34.3818 38.2086Z" fill="#C2C2C2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.3799 16.5909C43.3399 15.3909 41.6699 16.1109 41.6699 18.1909C41.6699 20.2709 43.3399 22.9509 45.3799 24.1509C47.4199 25.3509 49.0899 24.6309 49.0899 22.5509C49.0899 20.4709 47.4199 17.7909 45.3799 16.5909Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.2798 18.1809C41.2398 16.9809 39.5698 17.7009 39.5698 19.7809C39.5698 21.8609 41.2398 24.5409 43.2798 25.7409C45.3198 26.9409 46.9898 26.2209 46.9898 24.1409C46.9898 22.0609 45.3198 19.3809 43.2798 18.1809Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.2798 19.4609C41.9198 18.6609 40.8198 19.1409 40.8198 20.5209C40.8198 21.9009 41.9298 23.6809 43.2798 24.4709C44.6398 25.2709 45.7398 24.7909 45.7398 23.4109C45.7398 22.0309 44.6298 20.2509 43.2798 19.4609Z" fill="#353633"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3797 19.6409C42.1797 18.9409 41.2097 19.3609 41.2097 20.5809C41.2097 21.8009 42.1897 23.3709 43.3797 24.0709C44.5797 24.7709 45.5497 24.3509 45.5497 23.1309C45.5497 21.9109 44.5697 20.3409 43.3797 19.6409Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3798 19.9608C42.3598 19.3608 41.5198 19.7208 41.5198 20.7608C41.5198 21.8008 42.3598 23.1508 43.3798 23.7508C44.3998 24.3508 45.2398 23.9908 45.2398 22.9508C45.2398 21.9108 44.3998 20.5608 43.3798 19.9608Z" fill="#8E4428"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3799 20.3409C42.5599 19.8609 41.8899 20.1509 41.8899 20.9809C41.8899 21.8109 42.5599 22.8809 43.3799 23.3609C44.1999 23.8409 44.8599 23.5509 44.8599 22.7209C44.8599 21.8909 44.1899 20.8209 43.3799 20.3409Z" fill="#A34D2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3798 20.5309C42.6698 20.1109 42.0798 20.3609 42.0798 21.0909C42.0798 21.8209 42.6598 22.7509 43.3798 23.1709C44.0898 23.5909 44.6798 23.3409 44.6798 22.6109C44.6798 21.8809 44.0998 20.9509 43.3798 20.5309Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.7399 26.1908C46.2299 26.1708 47.0999 25.5608 48.3499 24.3508L47.5399 21.8608L46.2399 24.4308C45.4199 25.6208 45.2499 26.2108 45.7399 26.1908Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.9298 16.1809V16.2409L42.7498 17.6809L40.0598 18.1809C41.9798 16.6709 42.9298 15.9809 42.9298 16.1109C42.9298 16.2409 42.9298 16.2609 42.9298 16.1809Z" fill="black"/>
        <path d="M19.5698 17.61L22.0898 15.99L23.6298 17.07L21.1098 18.69L19.5698 17.61Z" fill="#F4D251"/>
        <path d="M20.7898 12.58L22.9698 11.29V13.32L20.9198 14.61L20.7898 12.58Z" fill="#E2832D"/>
        <path d="M34.4499 20.58L39.8799 21.96L37.4499 26.33L36.2099 25.53L34.4399 20.58H34.4499Z" fill="#6D3822"/>
        <path d="M38.9699 23.0001L43.5899 20.0601L42.0399 23.4201L37.4199 26.3601L38.9699 23.0001Z" fill="#3F2720"/>
        <path d="M36.4399 11.74L34.9799 4.5L32.1299 2.6L32.1699 2L37.1099 4.81L38.6099 8.49L54.9699 18.17L44.8399 17.08L36.4499 11.74H36.4399Z" fill="#F4D251"/>
        <path d="M37.6199 9.31006L29.1699 14.3001L34.9099 21.5501L44.7999 24.4801L54.4799 18.2701L43.6099 14.1301L37.6299 9.31006H37.6199Z" fill="#FCA84C"/>
        <path d="M37.6797 11.34L29.2297 16.33L34.9697 23.58L37.4197 24.76L47.4897 18.7L43.6697 16.16L37.6897 11.34H37.6797Z" fill="#CA722E"/>
        <path d="M28.2499 15.7L23.1299 13.79V18.58L28.6099 22.1L28.2499 15.7Z" fill="#B24426"/>
        <path d="M22.0798 15.68L24.5698 14.08V18.27L22.0798 19.87V15.68Z" fill="#3F2720"/>
        <path d="M34.5798 21.54L27.7598 17.15V19.44L34.5798 23.83V21.54Z" fill="#492A20"/>
        <path d="M34.6498 23.35L23.7998 16.38V18.52L34.6498 25.49V23.35Z" fill="#331F1E"/>
        <path d="M36.0397 12L34.5797 4.76002L31.7297 2.86002L31.7697 2.27002L36.8697 5.01002L38.1997 8.76002L54.5597 18.44L47.0197 18.98L36.0397 12Z" fill="#E2832D"/>
        <path d="M33.7399 21.56L30.9799 20.62L30.0299 19.99L28.5999 20.87L31.4599 25.56L34.4699 23.28L33.7399 21.56Z" fill="#A95C2E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.1299 16.83C24.1899 15.69 22.6099 16.38 22.6099 18.35C22.6099 20.32 24.1899 22.86 26.1299 24C28.0699 25.14 29.6499 24.45 29.6499 22.48C29.6499 20.51 28.0699 17.97 26.1299 16.83Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.1399 18.35C22.1999 17.21 20.6199 17.9 20.6199 19.87C20.6199 21.84 22.1999 24.38 24.1399 25.52C26.0799 26.66 27.6599 25.97 27.6599 24C27.6599 22.03 26.0799 19.49 24.1399 18.35Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.1398 19.55C22.8498 18.8 21.7998 19.25 21.7998 20.56C21.7998 21.87 22.8498 23.55 24.1398 24.31C25.4298 25.06 26.4798 24.61 26.4798 23.3C26.4798 21.99 25.4298 20.3 24.1398 19.55Z" fill="#353633"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.2399 19.7299C23.0999 19.0599 22.1799 19.4599 22.1799 20.6199C22.1799 21.7799 23.1099 23.2599 24.2399 23.9299C25.3799 24.5999 26.2999 24.1999 26.2999 23.0399C26.2999 21.8799 25.3699 20.3999 24.2399 19.7299Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.24 20.0299C23.27 19.4599 22.48 19.7999 22.48 20.7899C22.48 21.7799 23.27 23.0499 24.24 23.6199C25.21 24.1899 26 23.8499 26 22.8599C26 21.8699 25.21 20.5999 24.24 20.0299Z" fill="#8E4428"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.2398 20.39C23.4598 19.94 22.8298 20.21 22.8298 21C22.8298 21.79 23.4598 22.81 24.2398 23.26C25.0198 23.71 25.6498 23.44 25.6498 22.65C25.6498 21.86 25.0198 20.84 24.2398 20.39Z" fill="#A34D2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.2398 20.58C23.5598 20.18 23.0098 20.42 23.0098 21.11C23.0098 21.8 23.5598 22.69 24.2398 23.08C24.9198 23.48 25.4698 23.24 25.4698 22.55C25.4698 21.86 24.9198 20.97 24.2398 20.58Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.4798 25.94C26.9498 25.92 27.7698 25.34 28.9498 24.2L28.1798 21.84L26.9398 24.27C26.1598 25.4 25.9998 25.96 26.4698 25.94H26.4798Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.8098 16.4499V16.4999L23.6398 17.8699L21.0898 18.3499C22.9098 16.9199 23.8198 16.2599 23.8198 16.3799C23.8198 16.4999 23.8198 16.5199 23.8198 16.4399L23.8098 16.4499Z" fill="black"/>
        <path d="M33.7398 21.56L30.3098 19.61L29.5598 20.31L31.5198 22.74L31.4598 25.57L34.4698 23.29L33.7398 21.57V21.56Z" fill="#6D3822"/>
        <path d="M31.5099 22.72L28.5999 20.85V23.71L31.5099 25.58V22.72Z" fill="#8D4528"/>
        <path d="M24.9697 13.88L27.4597 12.28L28.3697 16.16L25.8797 17.76L24.9697 13.88Z" fill="#CA722E"/>
        <path d="M34.2798 10.4301L29.4798 7.31006L21.0498 12.7001L25.4298 15.5701L34.2698 10.4301H34.2798Z" fill="#F4D251"/>
        <path d="M25.4599 15.51L22.0999 13.36V15.34L25.8999 17.77L25.4599 15.52V15.51Z" fill="#E9B237"/>
        <path d="M25.1598 15.78L23.0298 14.41V15.66L25.4398 17.2L25.1598 15.77V15.78Z" fill="#E2832D"/>
        <path d="M25.1397 15.77L23.4797 14.71V15.68L25.3497 16.88L25.1297 15.77H25.1397Z" fill="#EEA32D"/>
        <path d="M25.1099 15.76L23.6899 14.85V15.69L25.2999 16.72L25.1099 15.77V15.76Z" fill="#514135"/>
        <path d="M22.5198 13.6201L21.0698 12.6901V19.1601L22.1098 19.7601L22.5198 13.6301V13.6201Z" fill="#E9B237"/>
        <path d="M22.1098 19.13L19.5898 17.51V18.23L22.1098 19.85V19.13Z" fill="#E9B237"/>
        <path d="M20.9198 12.6201L20.2498 12.1901V14.1901L20.9198 14.6201V12.6201Z" fill="#E9B237"/>
        <path d="M26.0598 11.97L24.0298 10.66V12.92L26.3598 14.42L26.0598 11.97Z" fill="#E9B237"/>
        <path d="M26.2099 11.8701L24.1799 10.5601V12.8201L26.5099 14.3201L26.2099 11.8701Z" fill="#CA722E"/>
        <path d="M26.3398 11.78L24.3098 10.47V12.73L26.6398 14.23L26.3398 11.78Z" fill="#212220"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M37.3599 22.82C35.3199 21.62 33.6499 22.34 33.6499 24.42C33.6499 26.5 35.3199 29.18 37.3599 30.38C39.3999 31.58 41.0699 30.86 41.0699 28.78C41.0699 26.7 39.3999 24.02 37.3599 22.82Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.2598 24.42C33.2198 23.22 31.5498 23.94 31.5498 26.02C31.5498 28.1 33.2198 30.78 35.2598 31.98C37.2998 33.18 38.9698 32.46 38.9698 30.38C38.9698 28.3 37.2998 25.62 35.2598 24.42Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.2598 25.69C33.8998 24.89 32.7998 25.37 32.7998 26.75C32.7998 28.13 33.9098 29.91 35.2598 30.7C36.6198 31.5 37.7198 31.02 37.7198 29.64C37.7198 28.26 36.6098 26.48 35.2598 25.69Z" fill="#353633"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.3697 25.88C34.1697 25.18 33.1997 25.6 33.1997 26.82C33.1997 28.04 34.1797 29.61 35.3697 30.31C36.5697 31.01 37.5397 30.59 37.5397 29.37C37.5397 28.15 36.5597 26.58 35.3697 25.88Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.3698 26.2C34.3498 25.6 33.5098 25.96 33.5098 27C33.5098 28.04 34.3498 29.39 35.3698 29.99C36.3898 30.59 37.2298 30.23 37.2298 29.19C37.2298 28.15 36.3898 26.8 35.3698 26.2Z" fill="#8E4428"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.3699 26.5801C34.5499 26.1001 33.8799 26.3901 33.8799 27.2201C33.8799 28.0501 34.5499 29.1201 35.3699 29.6001C36.1899 30.0801 36.8499 29.7901 36.8499 28.9601C36.8499 28.1301 36.1799 27.0601 35.3699 26.5801Z" fill="#A34D2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.3698 26.77C34.6598 26.35 34.0698 26.6 34.0698 27.33C34.0698 28.06 34.6498 28.99 35.3698 29.41C36.0798 29.83 36.6698 29.58 36.6698 28.85C36.6698 28.12 36.0898 27.19 35.3698 26.77Z" fill="#E59138"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M37.7299 32.43C38.2199 32.41 39.0899 31.8 40.3399 30.59L39.5299 28.1L38.2299 30.67C37.4099 31.86 37.2399 32.45 37.7299 32.43Z" fill="#121312"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M34.9098 22.4201V22.4801L34.7298 23.9201L32.0398 24.4201C33.9598 22.9101 34.9098 22.2201 34.9098 22.3501C34.9098 22.4801 34.9098 22.5001 34.9098 22.4201Z" fill="black"/>
        <path d="M26.0098 12.5301L24.7998 11.7601V10.6901L25.8598 11.3701L25.9998 12.5301H26.0098Z" fill="#212220" stroke="black"/>
        <path d="M26.0598 10.67L24.0298 9.35999V11.62L26.3598 13.12L26.0598 10.67Z" fill="#773800"/>
        <path d="M37.7098 13.08L36.1798 4.5L26.0098 10.65L28.4098 19.2L37.7098 13.08Z" fill="#EEA32D"/>
        <path d="M36.4497 4.40001L32.1697 2.01001L21.7097 8.21001L25.8397 11L36.4497 4.40001Z" fill="#F4D251"/>
        <path d="M27.1398 17.9401L24.9798 10.7501L21.7498 8.22005L22.2198 7.93005L27.1098 11.0701L29.3098 14.7001L37.2898 19.8001L44.9298 24.3501L44.4498 24.7601L27.1398 17.9401Z" fill="#F4D251"/>
        <path d="M26.7099 18.3L24.5499 11.11L21.6699 8.80996L21.7099 8.20996L26.6699 11.43L28.8699 15.06L36.8499 20.16L44.4099 24.74L37.2899 25.91L26.6999 18.3H26.7099Z" fill="#E9B237"/>
        <path d="M37.5399 25.41L27.1099 17.9L24.9499 10.73L24.7799 10.59L22.8899 9.35999L24.8099 10.9L26.9699 18.07L37.3899 25.58L43.7999 24.37L43.5199 24.2L37.5399 25.41Z" fill="#E2832D"/>
        <path d="M44.1899 24.13L44.7399 24.52L54.9599 18.18L54.3799 17.83L44.1799 24.12L44.1899 24.13Z" fill="#F4D251"/>
        <path d="M25.5899 7.58997L23.3599 9.03997L28.3099 9.35997V8.86997L25.5899 7.58997Z" fill="#F4D251"/>
        <path d="M21.5698 13.55C21.5698 13.44 21.4998 13.3 21.4098 13.24C21.3198 13.18 21.2498 13.23 21.2498 13.34C21.2498 13.45 21.3198 13.59 21.4098 13.65C21.4998 13.71 21.5698 13.66 21.5698 13.55Z" fill="#CA722E"/>
        <path d="M21.5198 13.5101C21.5198 13.4301 21.4698 13.3401 21.4098 13.3001C21.3498 13.2601 21.2998 13.3001 21.2998 13.3701C21.2998 13.4401 21.3498 13.5401 21.4098 13.5801C21.4698 13.6201 21.5198 13.5801 21.5198 13.5101Z" fill="#E9B237"/>
        <path d="M22.0398 8.77007C22.0398 8.69007 21.9898 8.59007 21.9298 8.55007C21.8698 8.51007 21.8098 8.55007 21.8098 8.62007C21.8098 8.69007 21.8598 8.80007 21.9198 8.84007C21.9798 8.88007 22.0398 8.84007 22.0398 8.77007Z" fill="#CA722E"/>
        <path d="M21.9998 8.73997C21.9998 8.68997 21.9698 8.61997 21.9198 8.58997C21.8798 8.55997 21.8398 8.58997 21.8398 8.63997C21.8398 8.69997 21.8698 8.75997 21.9198 8.78997C21.9598 8.81997 21.9998 8.78997 21.9998 8.73997Z" fill="#E9B237"/>
        <path d="M20.2498 12.2L22.3398 10.86L23.0098 11.29L20.9198 12.63L20.2498 12.2Z" fill="#F4D251"/>
        <path d="M25.8198 14.22L41.7698 24.73L40.8498 24.92L26.0898 15.1L25.8198 14.22Z" fill="#E2832D"/>
        <path d="M26.2497 14.5L41.7697 24.73L41.8197 24.55L25.9797 14.08L26.2497 14.5Z" fill="#F4D251"/>
    </SvgIcon>
  )
}
