import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";

interface Props {
  title?: string;
  subtitle?: string;
}
export const WithoutData: React.FC<Props> = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.infoContainer}>
      <Grid container style={{ textAlign: "center" }}>
        <Grid item xs={12}>
          <InfoOutlinedIcon color="disabled" className={classes.infoIcon} />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography
          color="textSecondary"
          variant="h6"
        >
          {title ? title : "No se encontraron datos."}
        </Typography>
      </Grid>
      {subtitle && (
        <Typography
          color="textSecondary"
          variant="h6"
          style={{ paddingBottom: 20 }}
        >
          {subtitle}
        </Typography>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  infoIcon: {
    width: 50,
    height: 50,
  },
}));
