import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

interface Props {
  icon: React.ReactNode;
  text: string;
}
export const CustomLegend: React.FC<Props> = ({ text, icon: Icon }) => {
  const classes = useStyles();
  return (
    <>
      {Icon}
      <Typography className={classes.text} variant="h6">
        {text}
      </Typography>
    </>
  );
};

const useStyles = makeStyles(() => ({
  text: {
    fontSize: "16px",
    lineHeight: "28px",
    paddingRight: "20px",
  },
}));
