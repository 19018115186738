import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  Footer,
  Header,
  PerformanceRatingKpis,
  ScreenContainer,
  SearchBar,
} from "components";
import {
  PerformanceRatingsBarGraph,
  PerformanceRatingSupervisorMenu,
  PerformanceRatingSupervisorTable,
} from ".";
import {
  PerformanceRatingEvent,
  PerformanceRatingOperatorsTop10Params,
  PeriodEnum,
  PerformanceRatingOperatorTop10,
  ErrorMsg,
  Can,
  Module,
  ScreenType,
} from "interfaces";
import { DataViewOption } from "reducers";
import { useData, useSearch } from "hooks";
import { formatLocalizedDate, getPractices } from "utils";
import { STANDARD_POLLING } from "App";
import { UserContext, PerformanceRatingsContext } from "contexts";
import Fuse from "fuse.js";
import { PerformanceRatingPeriodsOptions } from "..";
import { OperatorAssignmentDialog } from "./OperatorAssignmentDialog";

const filterOptions: Fuse.IFuseOptions<PerformanceRatingEvent> = {
  keys: ["operator.name"],
  threshold: 0.4,
};

export const PerformanceRatingSupervisorScreen: React.FC = () => {
  const classes = useStyles();

  const { menuState } = useContext(PerformanceRatingsContext);

  const { user, isAllowedTo } = useContext(UserContext);

  const [openOperatorAssignmentDialog, setOpenOperatorAssignmentDialog] =
    useState<boolean>(false);

  const prOpersEventsParams =
    useMemo<PerformanceRatingOperatorsTop10Params>(() => {
      const isCustomPeriod =
        menuState.supervisorPeriod.name === PeriodEnum.CUSTOM;

      return {
        operatorPractices: getPractices(menuState.operPractices),
        period: menuState.supervisorPeriod.name,
        startDate: isCustomPeriod
          ? formatLocalizedDate(
              menuState.supervisorPeriod.startDate,
              "yyyy-MM-dd"
            )
          : null,
        endDate: isCustomPeriod
          ? formatLocalizedDate(
              menuState.supervisorPeriod.endDate,
              "yyyy-MM-dd"
            )
          : null,
        supervisorId: menuState.selectedSupervisor || null,
        crews: menuState.crews,
      };
    }, [
      menuState.crews,
      menuState.operPractices,
      menuState.supervisorPeriod.endDate,
      menuState.supervisorPeriod.name,
      menuState.supervisorPeriod.startDate,
      menuState.selectedSupervisor,
    ]);

  const {
    data: top10Operators,
    firstLoading: firstLoadingtop10,
    refetching: refetchingTop10,
  } = useData<
    PerformanceRatingOperatorTop10[],
    PerformanceRatingOperatorsTop10Params
  >(
    {
      config: {
        url: "/performance-ratings/top10",
        method: "GET",
        params: prOpersEventsParams,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_PR_TOP_10_OPERATORS
  );

  const {
    data: prEvents,
    refetching: refetchingPrEvents,
    firstLoading: firstLoadingPrEvents,
  } = useData<PerformanceRatingEvent[], PerformanceRatingOperatorsTop10Params>(
    {
      config: {
        url: "/performance-ratings/events",
        method: "GET",
        params: prOpersEventsParams,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_PR_OPERATORS_EVENTS
  );

  const loadingPrEvents = refetchingPrEvents || firstLoadingPrEvents;

  const loadingTop10 = refetchingTop10 || firstLoadingtop10;

  const [searchValue, setSearchValue, , searchResults, isTyping] =
    useSearch<PerformanceRatingEvent>(prEvents ?? null, filterOptions);

  const onAssignOperToSupervisor = useCallback(
    () => setOpenOperatorAssignmentDialog(true),
    []
  );

  const handleOnDialogClose = useCallback(
    (e?: unknown, reason?: "backdropClick" | "escapeKeyDown") =>
      reason !== "backdropClick" && setOpenOperatorAssignmentDialog(false),
    []
  );

  return (
    <>
      <Header />
      <ScreenContainer className={classes.root}>
        <PerformanceRatingKpis />
        <div className={classes.container}>
          <div className={classes.headerContent}>
            <div className={classes.title}>
              <Typography variant="h6">
                <b>Demoras Prácticas Operacionales</b>
              </Typography>
              {loadingTop10 &&
                menuState.viewOption === DataViewOption.GRAPH && (
                  <CircularProgress
                    className={classes.circularProgress}
                    size={30}
                    color="primary"
                  />
                )}
            </div>
            {menuState.viewOption === DataViewOption.TABLE && (
              <SearchBar
                classes={{
                  searchBarPaper: classes.searchBarPaper,
                  searchBarRoot: classes.searchBar,
                  searchAdornment: classes.searchIcon,
                }}
                value={searchValue}
                onChange={setSearchValue}
                placeholder={"Buscar por nombre de operador"}
                clearOnBlur={false}
              />
            )}
            <PerformanceRatingPeriodsOptions screen={ScreenType.SUPERVISOR} />
          </div>
          <div className={classes.prContent}>
            <PerformanceRatingSupervisorMenu />
            {menuState.viewOption === DataViewOption.GRAPH ? (
              <PerformanceRatingsBarGraph
                data={top10Operators}
                loading={loadingTop10}
                keys={getPractices(menuState.operPractices)}
              />
            ) : (
              <PerformanceRatingSupervisorTable
                data={searchResults}
                loading={isTyping || loadingPrEvents}
              />
            )}
          </div>
          {user?.isOperationalSupervisor &&
            isAllowedTo(Can.WRITE, Module.PERFORMANCE_RATINGS) && (
              <Footer className={classes.footerPaper}>
                <Button
                  className={classes.assignOperToSupervisorButton}
                  color="primary"
                  variant="contained"
                  onClick={onAssignOperToSupervisor}
                >
                  ACTUALIZAR MIS OPERADORES
                </Button>
              </Footer>
            )}
        </div>
      </ScreenContainer>
      <OperatorAssignmentDialog
        open={openOperatorAssignmentDialog}
        onClose={handleOnDialogClose}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    overflowX: "hidden",
  },
  chipGroupRoot: {
    marginLeft: "auto",
  },
  performanceContent: {
    display: "flex",
    flex: "1 1 auto",
  },
  headerCell: {
    width: "100%",
  },
  chipExceedEvents: {
    width: "100%",
    height: 24,
    maxWidth: 48,
  },
  contentHeader: {
    display: "flex",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    marginTop: 24,
  },
  title: {
    maxWidth: 356,
    width: "100%",
    marginRight: 24,
    display: "flex",
    [breakpoints.between("md", "lg")]: {
      maxWidth: 310,
      marginRight: 10,
    },
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
    alignItems: "center",
    height: 44,
  },
  searchBarPaper: {
    boxShadow: "none",
    border: "1px solid",
  },
  searchBar: {
    maxWidth: 444,
    margin: 0,
    padding: 0,
    [breakpoints.between("md", "lg")]: {
      maxWidth: 400,
    },
  },
  searchIcon: {
    color: palette.neutralText.primary,
  },
  prContent: {
    display: "flex",
    height: "100%",
  },
  datePickerInput: {
    height: 40,
    width: "100%",
    maxWidth: 110,
  },
  datePickerRoot: {
    "&:first-child": {
      marginRight: 10,
    },
  },
  datePickersContainer: {
    margin: "0px 10px",
  },
  collapseDatePickers: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  collapseDatePickersWrapper: {
    display: "flex",
    marginBottom: 5,
  },
  footerPaper: {
    display: "flex",
    paddingTop: 10,
    marginTop: 10,
    justifyContent: "flex-end",
  },
  assignOperToSupervisorButton: {
    height: 42,
    color: palette.common.white,
  },
  circularProgress: {
    marginLeft: 10,
  },
}));
