import React, { useContext, useState } from "react";
import { TruckAssignmentMetrics } from "./TruckAssignmentMetrics";
import { Header, ScreenContainer, TruckAssignmentKpis } from "components";
import { TruckAssignmentMetricsContext } from "contexts";
import { makeStyles, Typography } from "@material-ui/core";
import FilterHdrOutlinedIcon from "@material-ui/icons/FilterHdrOutlined";

export const TruckAssignmentMetricsScreen: React.FC = () => {
  const classes = useStyles();
  // State
  const { lastMetricsUpdateMinutes, firstLoading, polling } = useContext(
    TruckAssignmentMetricsContext
  );

  //is open Menu
  const [stateMenu, setStateMenu] = useState<boolean | undefined>(false)
  const handleUpdateState = (openMenu: boolean) => {
    setStateMenu(openMenu)
  }

  return (
    <>
      <Header
        elapsedMinutes={lastMetricsUpdateMinutes}
        loading={firstLoading || polling}
        onUpdateStateMenu={handleUpdateState}
      />
      <ScreenContainer className={classes.screenContainer}>
        <TruckAssignmentKpis openMenu={stateMenu}/>
        <TruckAssignmentMetrics />
        <div className={classes.footer}>
          <FilterHdrOutlinedIcon className={classes.footerIcon} />
          <Typography noWrap variant="subtitle1">
            Todas las toneladas de la aplicación son secas y en origen.
            Utilización considera exclusivamente camiones UltraClass.
          </Typography>
        </div>
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles(() => ({
  footer: {
    display: "flex",
    marginTop: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  screenContainer: {
    overflowX: "hidden",
  },
  footerIcon: {
    marginRight: 10,
  },
}));
