import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ErrorDarkIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="420"
      height="420"
      viewBox="0 0 420 420"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8164_83441)">
        <path
          d="M209.996 400.666C325.973 400.666 419.991 352.012 419.991 291.994C419.991 231.976 325.973 183.321 209.996 183.321C94.0182 183.321 0 231.976 0 291.994C0 352.012 94.0182 400.666 209.996 400.666Z"
          fill="#363C42"
        />
        <path
          d="M109.965 29.855L101.943 34.4864C101.987 34.1281 102.013 33.7698 102.013 33.4203V31.0958C102.013 26.9625 99.1119 25.2847 95.5291 27.3557C94.0785 28.1946 92.7415 29.5229 91.6667 31.0696C91.0812 25.8702 87.0003 23.9302 82.0456 26.7965C76.6889 29.8899 72.3458 37.4138 72.3458 43.6007V47.0786C72.3458 48.6778 72.6429 50.0322 73.1672 51.1071L66.5085 54.952C64.5248 56.0968 62.9082 58.8844 62.9082 61.1826C62.9082 63.4808 64.5161 64.4071 66.5085 63.2624L109.965 38.1653C111.949 37.0206 113.565 34.233 113.565 31.9347C113.565 29.6365 111.958 28.7102 109.965 29.855Z"
          fill="#363C42"
        />
        <path
          d="M148.555 47.236L142.5 50.7314C142.526 50.4605 142.552 50.1896 142.552 49.9275V48.171C142.552 45.0514 140.359 43.7843 137.658 45.3485C136.566 45.9777 135.561 46.9826 134.74 48.1535C134.294 44.2299 131.218 42.7706 127.478 44.929C123.432 47.2622 120.155 52.9423 120.155 57.6086V60.2302C120.155 61.4361 120.382 62.4585 120.776 63.2712L115.751 66.1724C114.248 67.0375 113.033 69.1435 113.033 70.8737C113.033 72.604 114.248 73.303 115.751 72.4379L148.547 53.5015C150.05 52.6364 151.264 50.5304 151.264 48.8002C151.264 47.07 150.05 46.3709 148.547 47.236H148.555Z"
          fill="#363C42"
        />
      </g>
      <path
        d="M141.224 306.312L13.1091 228.211L31.0231 214.229L56.1531 196.279C57.5784 195.261 59.5002 195.289 60.8956 196.347L71.2203 204.18L208.84 253.614L141.224 306.312Z"
        fill="url(#paint0_linear_8164_83441)"
      />
      <path
        d="M50.6484 157.26L121.756 200.596L135.376 208.935V200.769L136.671 199.992C133.371 190.14 127.898 181.024 120.67 173.722C118.957 171.951 119.124 169.747 117.996 168.192C114.57 163.569 114.069 158.038 112.648 152.292C112.23 150.477 111.729 148.619 110.726 147.107C109.724 145.595 108.303 144.428 106.799 143.477C102.078 140.453 96.3963 138.898 90.9232 139.891C88.5836 140.323 86.2858 141.231 83.9462 141.749C78.306 143.002 72.2899 141.058 66.9004 143.261C65.4382 143.866 64.1012 144.601 62.8061 145.508C60.4665 147.064 58.3357 149.008 56.3304 151.039C55.829 151.557 55.3277 152.076 54.8263 152.637C53.4058 154.149 52.0271 155.705 50.6484 157.26Z"
        fill="#401D00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.3708 215.94C75.5796 208.438 65.1504 212.948 65.1504 225.979C65.1504 239.009 75.5796 255.753 88.3708 263.255C101.155 270.757 111.584 266.247 111.584 253.22C111.584 240.186 101.155 223.442 88.3708 215.94Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.2478 225.95C62.4566 218.449 52.0273 222.959 52.0273 235.989C52.0273 249.019 62.4566 265.763 75.2478 273.265C88.0318 280.767 98.461 276.257 98.461 263.23C98.461 250.197 88.0318 233.452 75.2478 225.95Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.2506 233.904C66.7582 228.925 59.832 231.916 59.832 240.568C59.832 249.217 66.7582 260.334 75.2506 265.317C83.7393 270.297 90.6655 267.302 90.6655 258.653C90.6655 250.001 83.7393 238.884 75.2506 233.904Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.8957 235.057C68.399 230.658 62.2832 233.302 62.2832 240.939C62.2832 248.577 68.399 258.391 75.8957 262.786C83.3887 267.184 89.5046 264.541 89.5046 256.903C89.5046 249.269 83.3887 239.451 75.8957 235.057Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.8946 237.046C69.4808 233.285 64.248 235.547 64.248 242.081C64.248 248.615 69.4808 257.016 75.8946 260.777C82.3084 264.538 87.5375 262.276 87.5375 255.742C87.5375 249.208 82.3084 240.811 75.8946 237.046Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.8931 239.456C70.773 236.454 66.5977 238.257 66.5977 243.473C66.5977 248.686 70.773 255.39 75.8931 258.393C81.0096 261.391 85.1849 259.589 85.1849 254.376C85.1849 249.159 81.0096 242.458 75.8931 239.456Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.8954 240.648C71.4221 238.023 67.7773 239.6 67.7773 244.154C67.7773 248.707 71.4221 254.564 75.8954 257.185C80.3651 259.807 84.0099 258.23 84.0099 253.676C84.0099 249.122 80.3651 243.269 75.8954 240.648Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.6649 276.063C93.7417 275.953 99.1857 272.122 106.997 264.571L101.93 248.981L93.7791 265.045C88.6262 272.501 87.5881 276.173 90.6649 276.063Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.053 213.418V213.78L71.9009 222.821L55.0645 225.975C67.0568 216.53 73.053 212.205 73.053 213.001C73.053 213.797 73.053 213.936 73.053 213.418Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M226.115 215.94C213.324 208.438 202.895 212.948 202.895 225.979C202.895 239.009 213.324 255.753 226.115 263.255C238.899 270.757 249.328 266.247 249.328 253.22C249.328 240.186 238.899 223.442 226.115 215.94Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.998 225.95C200.207 218.449 189.777 222.959 189.777 235.989C189.777 249.019 200.207 265.763 212.998 273.265C225.782 280.767 236.211 276.257 236.211 263.23C236.211 250.197 225.782 233.452 212.998 225.95Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.997 233.904C204.504 228.925 197.578 231.916 197.578 240.568C197.578 249.217 204.504 260.334 212.997 265.317C221.485 270.297 228.412 267.302 228.412 258.653C228.412 250.001 221.485 238.884 212.997 233.904Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.64 235.05C206.143 230.652 200.027 233.295 200.027 240.933C200.027 248.57 206.143 258.385 213.64 262.779C221.133 267.177 227.249 264.534 227.249 256.896C227.249 249.262 221.133 239.444 213.64 235.05Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.641 237.046C207.227 233.285 201.994 235.547 201.994 242.081C201.994 248.615 207.227 257.016 213.641 260.777C220.054 264.538 225.284 262.276 225.284 255.742C225.284 249.208 220.054 240.811 213.641 237.046Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.639 239.456C208.519 236.454 204.344 238.257 204.344 243.473C204.344 248.686 208.519 255.39 213.639 258.393C218.756 261.391 222.931 259.589 222.931 254.376C222.931 249.159 218.756 242.458 213.639 239.456Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.642 240.655C209.168 238.03 205.523 239.607 205.523 244.161C205.523 248.715 209.168 254.572 213.642 257.193C218.111 259.814 221.756 258.237 221.756 253.683C221.756 249.129 218.111 243.276 213.642 240.655Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.411 276.063C231.488 275.953 236.932 272.122 244.743 264.571L239.676 248.981L231.525 265.045C226.372 272.501 225.334 276.173 228.411 276.063Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.801 213.418V213.78L209.649 222.821L192.812 225.975C204.805 216.53 210.801 212.205 210.801 213.001C210.801 213.797 210.801 213.936 210.801 213.418Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.151 227.042L231.955 237.126V252.816L249.151 242.765V227.042Z"
        fill="#EB922D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.619 215.075L231.133 236.596L231.951 237.085L249.146 227.034L248.299 226.523L248.129 226.423L212.811 204.991L195.619 215.075Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.868 235.483V238.959C246.868 239.618 246.519 240.225 245.959 240.555L236.282 246.378C235.788 246.667 235.174 246.3 235.174 245.719V245.305L243.855 240.07C244.411 239.744 244.764 239.133 244.764 238.478V235.416L245.759 234.835C246.254 234.546 246.868 234.913 246.868 235.483Z"
        fill="#121311"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.764 235.415V238.476C244.764 239.132 244.411 239.742 243.855 240.068L235.174 245.303V242.249C235.174 241.582 235.523 240.975 236.082 240.646L244.764 235.415Z"
        fill="#FFF3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.658 301.106L140.838 267.458V226.157L196.658 259.805V301.106Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M237.763 235.669L196.66 259.797V301.098L237.763 277.122V235.669Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.842 226.136L196.651 259.798L237.761 235.808L181.93 202.012L140.842 226.136Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.656 286.841L169.056 284.149L165.92 266.512L173.656 271.151V286.841Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.812 217.992L141.57 226.653V265.751L126.812 257.145V217.992Z"
        fill="#502D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.789 264.583L128.875 257.331V232.808L116.789 240.06V264.583Z"
        fill="#8D4528"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.033 231.407L116.79 240.066V264.589L102.033 255.986V231.407Z"
        fill="#6D3822"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.877 232.808L118.066 239.298L116.79 240.068L102.033 231.457L103.356 230.657L103.621 230.502L114.127 224.152L128.877 232.808Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.678 243.962L75.2398 220.294L26.6804 163.026L24.0059 148.13L140.064 217.843L114.678 243.962Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.144 219.024L113.777 236.886L78.8267 215.906L36.4121 165.903L35.6562 161.675L131.144 219.024Z"
        fill="#874226"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.451 175.397L140.064 217.846L114.678 243.966L186.065 201.668L211.451 175.397Z"
        fill="#CA722E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0117 148.124L140.059 217.848L211.451 175.54L95.3846 105.679L24.0117 148.124Z"
        fill="#ECC530"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.513 175.501L165.948 195.992L140.067 211.322L120.786 199.732L48.73 156.435L34.9395 148.152L95.3671 112.22L200.513 175.501Z"
        fill="#E2832D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.038 127.112L48.73 156.435L34.9395 148.152L95.3671 112.22L98.038 127.112Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.945 195.986L140.065 211.313L120.783 199.725L146.609 184.38L165.945 195.986Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.603 184.366L165.946 195.979L200.515 175.495L95.3652 112.206L98.0398 127.099L146.603 184.366Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.231 217.963L134.406 214.431V204.42L140.231 207.952V217.963Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202.23 245.73L156.549 218.37L172.712 203.358L192.826 215.43L202.23 245.73Z"
        fill="#212220"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.377 201.029L192.826 215.44L202.231 245.74L226.781 231.418L217.377 201.029Z"
        fill="#434341"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.717 203.359L192.823 215.435L217.377 201.108L197.256 188.95L172.717 203.359Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.992 268.093L234.423 247.853L235.244 248.316L199.992 289.447V268.093Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.992 268.096L200.813 268.558V289.909L199.992 289.446V268.096Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.812 268.564L235.243 248.324V269.327L200.812 289.918V268.564Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 250.3V268.656L229.295 271.543L228.565 271.98L219.745 277.256L219.015 277.696L210.174 282.972L209.461 283.413L208.767 283.816L201.936 287.907V271.884L208.037 268.311L209.461 267.49V266.723L208.037 267.545L201.936 271.121V269.207L208.037 265.635L210.174 264.372L217.605 259.996L219.015 259.171L219.745 258.752L227.158 254.391L228.565 253.569L229.295 253.132L234.117 250.3Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 258.262V259.024L229.295 261.834L228.565 262.275L227.158 263.097L219.745 267.417L219.015 267.858L217.605 268.68L210.174 273.019L209.461 273.441L208.037 274.263L201.936 277.839V277.073L208.037 273.496L209.461 272.674L210.174 272.256L217.605 267.917L219.015 267.091L219.745 266.655L227.158 262.334L228.565 261.512L229.295 261.072L234.117 258.262Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.461 275.716V276.464L208.037 277.286L201.936 280.859V280.115L208.037 276.538L209.461 275.716Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 261.273V262.036L229.295 264.846L228.564 265.286L227.158 266.108L219.745 270.428L219.014 270.869L217.604 271.691L210.173 276.03L209.461 276.452V275.704L210.173 275.286L217.604 270.925L219.014 270.103L219.745 269.684L227.158 265.342L228.564 264.52L229.295 264.101L234.117 261.273Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.461 278.715V279.478L208.037 280.304L201.936 283.876V283.114L208.037 279.537L209.461 278.715Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.461 281.701V282.463L208.037 283.285L201.936 286.862V286.095L208.037 282.523L209.461 281.701Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 267.296V268.041L229.295 270.873L228.564 271.291L227.158 272.113L219.745 276.456L219.014 276.874L217.604 277.696L210.173 282.057L209.461 282.475V281.713L210.173 281.291L217.604 276.952L219.014 276.13L219.745 275.689L227.158 271.369L228.564 270.547L229.295 270.106L234.117 267.296Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.343 257.974L234.117 258.278L229.295 261.088L228.565 261.528L227.158 262.35L219.745 266.671L219.015 267.107L217.605 267.933L210.174 272.272L209.461 272.69L208.037 273.512L201.936 277.089V276.344L208.037 272.768L209.461 271.946L210.174 271.524L217.605 267.167L219.015 266.345L219.745 265.923L227.158 261.584L228.565 260.762L229.295 260.34L233.343 257.974Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.343 260.985L234.117 261.27L229.295 264.099L228.565 264.517L227.158 265.339L219.745 269.682L219.015 270.1L217.605 270.922L210.174 275.283L209.461 275.702L208.037 276.523L201.936 280.1V279.333L208.037 275.761L209.461 274.939L210.174 274.517L217.605 270.178L219.015 269.356L219.745 268.915L227.158 264.595L228.565 263.773L229.295 263.333L233.343 260.985Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.461 266.733V267.5L208.037 268.322L201.936 271.894V271.132L208.037 267.555L209.461 266.733Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 252.292V253.054L229.295 255.883L228.564 256.305L227.158 257.127L219.745 261.466L219.014 261.888L217.604 262.709L210.173 267.067L209.461 267.489V266.723L210.173 266.304L217.604 261.943L219.014 261.121L219.745 260.703L227.158 256.36L228.564 255.538L229.295 255.12L234.117 252.292Z"
        fill="#2D2E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 255.303V256.051L229.295 258.88L228.565 259.302L227.158 260.123L219.745 264.462L219.015 264.881L217.605 265.706L210.174 270.064L209.461 270.482L208.037 271.308L201.936 274.88V274.118L208.037 270.541L209.461 269.72L210.174 269.297L217.605 264.959L219.015 264.137L219.745 263.696L227.158 259.376L228.565 258.554L229.295 258.113L234.117 255.303Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.343 252.002L234.117 252.288L229.295 255.116L228.565 255.534L227.158 256.356L219.745 260.699L219.015 261.117L217.605 261.939L210.174 266.3L209.461 266.719L208.037 267.541L201.936 271.117V270.35L208.037 266.778L209.461 265.956L210.174 265.534L217.605 261.195L219.015 260.373L219.745 259.933L227.158 255.612L228.565 254.79L229.295 254.35L233.343 252.002Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.343 255.002L234.117 255.306L229.295 258.115L228.565 258.556L227.158 259.378L219.745 263.698L219.015 264.139L217.605 264.961L210.174 269.3L209.461 269.722L208.037 270.544L201.936 274.12V273.372L208.037 269.796L209.461 268.978L210.174 268.556L217.605 264.194L219.015 263.373L219.745 262.954L227.158 258.615L228.565 257.793L229.295 257.371L233.343 255.002Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.297 253.13V271.541L228.566 271.978V253.567L229.297 253.13Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 253.562V255.531L227.16 255.609V254.383L228.566 253.562Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 256.298V258.552L227.16 258.63V257.12L228.566 256.298Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 259.31V261.527L227.16 261.601V260.131L228.566 259.31Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 262.295V264.531L227.16 264.609V263.117L228.566 262.295Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 264.285V265.033L229.295 267.861L228.564 268.284L227.158 269.105L219.745 273.444L219.014 273.866L217.604 274.688L210.173 279.046L209.461 279.464V278.701L210.173 278.279L217.604 273.94L219.014 273.119L219.745 272.678L227.158 268.358L228.564 267.536L229.295 267.095L234.117 264.285Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 265.28V267.535L227.16 267.613V266.102L228.566 265.28Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 268.292V270.546L227.16 270.624V269.113L228.566 268.292Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 271.291V271.98L227.16 272.82V272.113L228.566 271.291Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.744 258.747V277.25L219.014 277.691V259.165L219.744 258.747Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 259.179V261.13L217.605 261.208V260.005L219.015 259.179Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 261.889V264.144L217.605 264.222V262.711L219.015 261.889Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 264.887V267.105L217.605 267.183V265.713L219.015 264.887Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 267.86V270.096L217.605 270.174V268.682L219.015 267.86Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 270.872V273.127L217.605 273.204V271.694L219.015 270.872Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 273.87V276.125L217.605 276.199V274.692L219.015 273.87Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 276.869V277.691L217.605 278.531V277.691L219.015 276.869Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.173 264.377V282.977L209.461 283.417V264.799L210.173 264.377Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 264.795V266.724L208.037 266.783V265.636L209.462 264.795Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 267.479V269.716L208.037 269.79V268.301L209.462 267.479Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 270.479V272.678L208.037 272.756V271.305L209.462 270.479Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 273.451V275.705L208.037 275.783V274.273L209.462 273.451Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 276.45V278.705L208.037 278.779V277.272L209.462 276.45Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 279.474V281.714L208.037 281.788V280.3L209.462 279.474Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208.037 283.308L209.462 282.486V283.422L208.768 283.826H208.037V283.308Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.992 262.795L234.423 242.556L235.244 243.019L199.992 282.898V262.795Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.992 262.793L200.813 263.256V283.355L199.992 282.892V262.793Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.812 263.254L235.243 243.015V262.765L200.812 283.357V263.254Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 245.004V263.356L229.295 266.244L228.565 266.681L219.745 271.96L219.015 272.397L217.605 273.241L210.174 277.676L209.461 278.113L208.767 278.517L201.936 282.608V266.588L208.037 263.012L209.461 262.19V261.424L208.037 262.245L201.936 265.822V263.911L208.037 260.335L209.461 259.495L210.174 259.073L217.605 254.697L219.015 253.875L219.745 253.453L227.158 249.095L228.565 248.273L229.295 247.833L234.117 245.004Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 252.984V253.751L229.295 256.557L228.565 256.998L227.158 257.819L219.745 262.14L219.015 262.58L217.605 263.402L210.174 267.741L209.461 268.163L208.037 268.985L201.936 272.562V271.795L208.037 268.223L209.461 267.397L210.174 266.979L217.605 262.64L219.015 261.818L219.745 261.377L227.158 257.057L228.565 256.235L229.295 255.794L234.117 252.984Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.461 270.413V271.161L208.037 271.983L201.936 275.559V274.811L208.037 271.235L209.461 270.413Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 255.984V256.747L229.295 259.556L228.564 259.997L227.158 260.819L219.745 265.139L219.014 265.58L217.604 266.402L210.173 270.741L209.461 271.163V270.415L210.173 269.997L217.604 265.635L219.014 264.814L219.745 264.395L227.158 260.053L228.564 259.234L229.295 258.812L234.117 255.984Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.461 273.412V274.178L208.037 275L201.936 278.572V277.81L208.037 274.234L209.461 273.412Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.461 276.41V277.173L208.037 277.995L201.936 281.571V280.805L208.037 277.232L209.461 276.41Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 261.994V262.741L229.295 265.57L228.564 265.992L227.158 266.814L219.745 271.153L219.014 271.571L217.604 272.393L210.173 276.754L209.461 277.173V276.41L210.173 275.988L217.604 271.649L219.014 270.827L219.745 270.387L227.158 266.066L228.564 265.244L229.295 264.804L234.117 261.994Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.461 261.418V262.184L208.037 263.006L201.936 266.583V265.816L208.037 262.24L209.461 261.418Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 247.001V247.764L229.295 250.593L228.564 251.015L227.158 251.837L219.745 256.175L219.014 256.598L217.604 257.419L210.173 261.777L209.461 262.199V261.433L210.173 261.014L217.604 256.653L219.014 255.831L219.745 255.413L227.158 251.074L228.564 250.252L229.295 249.83L234.117 247.001Z"
        fill="#2D2E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 250.013V250.761L229.295 253.589L228.565 254.012L227.158 254.833L219.745 259.172L219.015 259.594L217.605 260.416L210.174 264.774L209.461 265.196L208.037 266.018L201.936 269.59V268.828L208.037 265.251L209.461 264.429L210.174 264.007L217.605 259.668L219.015 258.847L219.745 258.406L227.158 254.086L228.565 253.264L229.295 252.827L234.117 250.013Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.297 247.839V266.25L228.566 266.687V248.28L229.297 247.839Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 248.285V250.254L227.16 250.328V249.107L228.566 248.285Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 251.008V253.263L227.16 253.341V251.83L228.566 251.008Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 254.006V256.224L227.16 256.298V254.828L228.566 254.006Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 256.992V259.232L227.16 259.306V257.814L228.566 256.992Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.117 258.996V259.743L229.295 262.572L228.564 262.994L227.158 263.816L219.745 268.155L219.014 268.577L217.604 269.399L210.173 273.756L209.461 274.178V273.412L210.173 272.99L217.604 268.651L219.014 267.829L219.745 267.388L227.158 263.068L228.564 262.246L229.295 261.809L234.117 258.996Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 259.991V262.246L227.16 262.324V260.813L228.566 259.991Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 262.989V265.243L227.16 265.321V263.811L228.566 262.989Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.566 266V266.685L227.16 267.529V266.822L228.566 266Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.744 253.47V271.977L219.014 272.414V253.892L219.744 253.47Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 253.876V255.824L217.605 255.902V254.698L219.015 253.876Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 256.587V258.842L217.605 258.919V257.409L219.015 256.587Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 259.598V261.816L217.605 261.89V260.42L219.015 259.598Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 262.57V264.806L217.605 264.884V263.392L219.015 262.57Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 265.582V267.836L217.605 267.914V266.403L219.015 265.582Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 268.58V270.834L217.605 270.912V269.401L219.015 268.58Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.015 271.579V272.401L217.605 273.245V272.401L219.015 271.579Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.173 259.073V277.677L209.461 278.114V259.495L210.173 259.073Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 259.493V261.422L208.037 261.481V260.333L209.462 259.493Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 262.19V264.427L208.037 264.504V263.012L209.462 262.19Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 265.188V267.384L208.037 267.462V266.01L209.462 265.188Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 268.161V270.416L208.037 270.494V268.983L209.462 268.161Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 271.16V273.414L208.037 273.492V271.982L209.462 271.16Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.462 274.171V276.408L208.037 276.482V274.993L209.462 274.171Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208.037 277.991L209.462 277.169V278.106L208.768 278.51H208.037V277.991Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.345 306.123L190.838 304.62V299.533L193.345 301.036V306.123Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.398 271.212L193.346 301.033V306.12L244.398 276.31V271.212Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.838 299.528L193.345 301.031L244.398 271.221L244.121 271.054L244.067 271.025L241.89 269.707L190.838 299.528Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.834 221.661L134.406 204.42V199.87L162.834 217.107V221.661Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.176 231.917L49.25 186.132V181.578L125.176 227.363V231.917Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.055 174.65L162.838 217.111V221.665L234.055 179.205V174.65Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.406 199.865L162.601 216.972L162.834 217.109L234.051 174.649L205.623 157.394L134.406 199.865Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.4915 182.491L123.725 226.651L124.986 225.351L51.985 181.329L50.0918 182.017L50.4915 182.491Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.802 259.271C151.011 251.769 140.582 256.279 140.582 269.31C140.582 282.34 151.011 299.084 163.802 306.586C176.586 314.088 187.016 309.578 187.016 296.551C187.016 283.517 176.586 266.773 163.802 259.271Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.683 269.258C137.892 261.756 127.463 266.266 127.463 279.296C127.463 292.326 137.892 309.07 150.683 316.572C163.467 324.074 173.897 319.564 173.897 306.538C173.897 293.504 163.467 276.76 150.683 269.258Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.682 277.204C142.19 272.225 135.264 275.216 135.264 283.868C135.264 292.516 142.19 303.634 150.682 308.617C159.171 313.597 166.097 310.602 166.097 301.953C166.097 293.301 159.171 282.184 150.682 277.204Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.327 278.35C143.831 273.952 137.715 276.595 137.715 284.233C137.715 291.87 143.831 301.685 151.327 306.079C158.82 310.478 164.936 307.834 164.936 300.197C164.936 292.563 158.82 282.745 151.327 278.35Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.326 280.359C144.912 276.598 139.68 278.86 139.68 285.394C139.68 291.928 144.912 300.329 151.326 304.09C157.74 307.851 162.969 305.589 162.969 299.055C162.969 292.521 157.74 284.124 151.326 280.359Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.325 282.755C146.205 279.752 142.029 281.555 142.029 286.772C142.029 291.984 146.205 298.689 151.325 301.692C156.441 304.69 160.617 302.887 160.617 297.675C160.617 292.458 156.441 285.757 151.325 282.755Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.327 283.973C146.854 281.348 143.209 282.925 143.209 287.479C143.209 292.033 146.854 297.89 151.327 300.511C155.797 303.132 159.441 301.555 159.441 297.001C159.441 292.447 155.797 286.594 151.327 283.973Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.097 319.401C169.173 319.291 174.617 315.46 182.428 307.909L177.362 292.319L169.211 308.383C164.058 315.839 163.02 319.511 166.097 319.401Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.485 256.729V257.091L147.333 266.132L130.496 269.286C142.488 259.841 148.485 255.516 148.485 256.312C148.485 257.108 148.485 257.247 148.485 256.729Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.32 251.895L172.838 273.413L173.652 273.905L190.847 263.85L190.001 263.339L189.833 263.239L137.32 249.148V251.895Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.119 255.036L137.32 251.875L154.511 241.79L122.119 252.293V255.036Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.32 249.132L172.838 270.652L173.652 271.141L190.847 261.09L190.001 260.579L189.833 260.479L154.512 239.047L137.32 249.132Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.119 252.297L137.32 249.132L154.511 239.047L139.307 242.212L122.119 252.297Z"
        fill="#A44C2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.844 261.065L173.652 271.15V286.84L190.844 276.788V261.065Z"
        fill="#EB922D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.913 269.418V272.898C187.913 273.553 187.564 274.164 187.004 274.49L177.327 280.314C176.833 280.602 176.219 280.236 176.219 279.658V279.244L184.9 274.009C185.456 273.679 185.809 273.072 185.809 272.413V269.351L186.804 268.774C187.298 268.481 187.913 268.852 187.913 269.418Z"
        fill="#121311"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.809 269.353V272.415C185.809 273.074 185.456 273.681 184.9 274.01L176.219 279.245V276.191C176.219 275.525 176.568 274.917 177.127 274.588L185.809 269.353Z"
        fill="#FFF3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.654 286.826L169.054 284.135L165.918 266.498L173.654 271.136V286.826Z"
        fill="#D16F2D"
      />
      <path
        d="M50.0625 156.913L50.6474 157.302L121.755 200.638L135.375 208.976V200.81L136.67 200.033L181.552 172.719C180.716 172.028 175.85 169.756 174.847 169.367C167.996 166.559 165.665 163.653 160.568 158.122C158.771 156.178 157.016 154.147 155.053 152.419C152.713 150.389 149.997 148.833 147.407 147.105C139.135 141.488 132.033 133.581 122.507 130.73C119.29 129.779 115.906 129.39 112.856 127.921C110.892 126.971 109.18 125.545 107.091 124.94C105.503 124.508 103.874 124.551 102.286 124.94C100.114 125.458 97.9411 126.539 96.1028 127.835C92.8858 130.038 89.836 132.803 86.1176 134.013C83.6945 134.791 81.146 134.921 78.681 135.353C72.4142 136.562 66.6069 140.235 62.7633 145.506C62.7633 145.506 62.7633 145.549 62.7215 145.593C61.4263 147.364 60.2565 149.395 58.3347 150.388C57.6662 150.691 56.9978 150.907 56.2875 151.123C55.8698 151.253 55.3684 151.425 54.9924 151.641C53.8644 152.203 52.5274 153.111 51.6919 154.061C50.9399 154.925 50.4803 155.962 50.0625 156.999V156.913Z"
        fill="#4A2500"
      />
      <path
        d="M102.33 124.061C104.92 127.042 110.185 128.468 113.151 130.283C119.083 133.869 125.434 136.548 131.659 139.529C134.374 140.825 137.759 143.202 139.555 145.664C141.602 148.473 142.438 152.016 144.736 154.781C145.571 155.775 146.49 156.725 146.783 157.978C147.368 160.225 145.822 162.472 145.655 164.805C145.571 165.971 145.822 167.181 145.571 168.348C145.07 170.638 142.772 171.977 141.059 173.533C136.756 177.421 137.466 182.044 136.505 187.229C135.502 192.673 133.873 197.771 134.667 203.388C134.792 204.339 135.043 205.332 135.419 206.24V200.018L136.714 199.24L181.552 171.973C180.716 171.281 175.85 169.01 174.847 168.621C167.996 165.812 165.709 162.861 160.612 157.33C158.815 155.386 157.06 153.355 155.097 151.627C152.757 149.596 150.042 148.041 147.451 146.312C139.179 140.696 132.077 132.789 122.551 129.937C119.334 128.987 115.95 128.598 112.9 127.129C110.937 126.178 109.224 124.752 107.135 124.148C105.547 123.715 103.918 123.759 102.33 124.148V124.061Z"
        fill="#5E3A16"
      />
      <path
        d="M68.4874 145.588C66.9833 145.761 65.7718 146.927 64.6855 148.05L63.5993 149.13C63.265 149.476 62.9308 149.821 62.7637 150.253C63.5157 150.34 64.2677 150.383 65.0197 150.469C66.2313 150.556 67.5265 150.642 68.4874 149.865C68.9052 149.519 69.2394 149.044 69.699 148.828C70.3256 148.525 71.7461 148.569 71.7879 147.618C71.7879 146.106 69.6154 145.415 68.5292 145.545L68.4874 145.588Z"
        fill="#696969"
      />
      <path
        d="M130.194 148.913C130.194 148.221 131.071 147.919 131.615 147.746C132.408 147.53 132.826 147.962 133.495 148.437C133.871 148.697 134.247 148.913 134.623 149.172C135.375 149.733 136.168 151.548 135.208 152.196C134.873 152.412 134.456 152.498 134.038 152.584L132.283 152.93C130.319 153.319 130.152 150.252 130.152 148.869L130.194 148.913Z"
        fill="#9C9C9C"
      />
      <path
        d="M100.407 132.626C99.9896 132.799 99.6136 132.367 99.3629 132.064C99.0287 131.632 99.154 131.243 99.2794 130.725C99.3211 130.423 99.3629 130.12 99.4047 129.818C99.53 129.213 100.366 128.22 100.992 128.652C101.201 128.781 101.368 129.04 101.535 129.256L102.204 130.25C102.956 131.373 101.243 132.28 100.407 132.669V132.626Z"
        fill="#9C9C9C"
      />
      <path
        d="M98.9015 157.766C98.4837 157.939 98.1077 157.507 97.857 157.205C97.5228 156.773 97.6482 156.384 97.7735 155.866C97.8153 155.563 97.857 155.261 97.8988 154.959C98.0242 154.354 98.8597 153.36 99.4864 153.792C99.6953 153.922 99.8624 154.181 100.03 154.397L100.698 155.391C101.45 156.514 99.7371 157.421 98.9015 157.81V157.766Z"
        fill="#696969"
      />
      <path
        d="M93.9308 144.118C95.1842 143.902 96.2286 144.507 96.9389 145.458C97.0642 145.63 98.4429 147.315 97.9833 147.574C97.3567 147.92 96.6464 148.093 95.9362 148.05C95.1424 147.963 94.3486 147.618 93.5966 147.877C93.3877 147.963 93.0952 148.05 92.9281 147.92C92.8028 147.834 92.761 147.661 92.761 147.488C92.6357 146.235 92.4268 144.421 93.9308 144.118Z"
        fill="#696969"
      />
      <path
        d="M130.946 171.63C131.072 171.242 131.364 170.939 131.824 170.766C132.785 170.378 135.918 170.853 135.918 172.278C135.918 172.97 135.166 173.315 134.79 173.79C134.372 174.352 134.163 175.086 133.996 175.778C133.913 176.08 133.829 176.382 133.62 176.555C133.411 176.728 133.077 176.685 132.868 176.555C132.617 176.426 132.409 176.253 132.241 176.037C131.531 175.173 130.445 172.97 130.946 171.63Z"
        fill="#9C9C9C"
      />
      <path
        d="M116.075 144.983C116.159 144.767 116.409 144.638 116.66 144.551C117.871 144.119 119.167 143.946 120.42 144.162L120.295 144.551C120.838 144.854 121.339 145.372 121.632 145.933C121.882 146.365 121.966 146.97 121.632 147.316C121.381 147.532 121.047 147.575 120.712 147.532C120.378 147.489 120.086 147.359 119.752 147.359C118.958 147.359 118.289 148.093 117.495 148.007C116.952 147.964 116.493 147.532 116.284 147.013C116.075 146.495 116.033 145.933 116.033 145.372C116.033 145.242 116.033 145.07 116.117 144.94L116.075 144.983Z"
        fill="#9C9C9C"
      />
      <path
        d="M95.5574 168.956C95.5574 168.956 95.3485 168.87 95.2649 168.827C95.0143 168.611 94.8054 168.352 94.5547 168.179L95.5574 168.956Z"
        fill="#696969"
      />
      <path
        d="M91.7136 168.738C92.0896 168.565 92.5074 168.565 92.8834 168.565C93.7608 168.565 94.471 168.651 95.3066 169.04C96.0586 169.386 97.1449 170.466 96.6853 171.416C96.4764 171.891 95.8915 172.15 95.3484 172.15C94.8053 172.15 94.3039 171.978 93.7608 171.934C93.0088 171.891 92.2568 172.064 91.5883 171.718C91.0452 171.459 90.7109 170.811 90.7109 170.206C90.7109 169.602 91.1287 168.997 91.6718 168.738H91.7136Z"
        fill="#696969"
      />
      <path
        d="M71.4121 160.187C71.4121 160.187 71.5792 160.317 71.6628 160.36C71.8717 160.619 72.0388 160.878 72.2895 161.137L71.4121 160.187Z"
        fill="#696969"
      />
      <path
        d="M75.17 161.097C74.794 161.184 74.3762 161.097 73.9584 161.097C73.0811 160.968 72.4126 160.752 71.6606 160.19C70.9921 159.715 70.1148 158.462 70.6997 157.598C70.9921 157.166 71.6188 157.037 72.1201 157.123C72.6215 157.209 73.1228 157.469 73.6242 157.598C74.3344 157.771 75.1282 157.771 75.7131 158.203C76.2145 158.592 76.4651 159.24 76.3398 159.844C76.2145 160.449 75.7549 160.968 75.17 161.097Z"
        fill="#696969"
      />
      <path
        d="M124.18 193.494C125.057 192.198 127.23 192.5 128.107 193.796C128.608 194.574 128.567 196.172 128.483 197.036C128.483 197.252 128.4 197.512 128.232 197.684C128.024 197.857 127.731 197.857 127.48 197.814L124.055 197.468C124.055 197.468 123.929 197.468 123.846 197.468C123.595 197.382 123.553 197.08 123.511 196.82C123.344 195.654 123.511 194.444 124.18 193.494Z"
        fill="#696969"
      />
      <path
        d="M110.435 169.602C111.939 169.602 112.942 171.675 112.357 173.144C112.023 174.008 110.686 174.872 109.934 175.261C109.725 175.39 109.516 175.477 109.307 175.433C109.057 175.347 108.89 175.088 108.764 174.872C108.221 173.835 107.678 172.798 107.135 171.762C107.135 171.718 107.051 171.632 107.051 171.589C107.01 171.33 107.177 171.114 107.386 170.941C108.221 170.163 109.307 169.645 110.435 169.645V169.602Z"
        fill="#696969"
      />
      <path
        d="M149.161 159.931C149.871 158.981 151.292 159.154 151.835 160.234C152.253 161.141 152.044 162.221 151.835 163.171C151.584 164.165 150.164 166.973 149.078 164.985C148.493 163.905 148.409 160.968 149.203 159.931H149.161Z"
        fill="#C2C2C2"
      />
      <path
        d="M159.44 175.743C159.064 174.879 159.314 173.28 160.025 172.676C160.442 172.287 161.027 172.114 161.612 172.071C161.863 172.071 162.155 172.071 162.406 172.157C163.033 172.373 163.534 172.935 163.785 173.583C164.244 175.052 163.283 176.736 162.155 177.643C162.072 177.73 161.946 177.816 161.821 177.816C161.612 177.816 161.403 177.73 161.236 177.6C160.818 177.255 160.401 176.909 160.025 176.52C159.816 176.304 159.607 176.088 159.523 175.829L159.44 175.743Z"
        fill="#C2C2C2"
      />
      <path
        d="M144.526 189.002C143.983 187.836 143.607 186.626 143.398 185.374C143.231 184.251 142.897 183.343 144.192 183.646C144.777 183.775 145.655 184.121 146.114 184.51C146.281 184.683 147.075 186.281 146.95 186.281C146.114 186.281 145.78 188.57 145.195 189.175C145.07 189.305 144.944 189.391 144.777 189.348C144.61 189.348 144.526 189.175 144.485 189.002H144.526Z"
        fill="#C2C2C2"
      />
      <path
        d="M147.66 175.176C147.743 175.392 147.869 175.651 148.078 175.651C148.203 175.651 148.287 175.564 148.37 175.478C148.83 175.046 149.206 174.484 149.832 174.355C150.208 174.268 150.668 174.355 150.793 173.966C150.793 171.979 148.203 172.67 147.033 173.016C147.075 173.75 147.367 174.441 147.618 175.132L147.66 175.176Z"
        fill="#C2C2C2"
      />
      <path
        d="M106.964 185.415C107.047 185.631 107.173 185.891 107.382 185.891C107.507 185.891 107.59 185.804 107.674 185.718C108.134 185.286 108.51 184.724 109.136 184.595C109.512 184.508 110.014 184.595 110.139 184.206C110.139 182.219 107.549 182.91 106.379 183.255C106.421 183.99 106.713 184.681 106.964 185.372V185.415Z"
        fill="#696969"
      />
      <path
        d="M160.525 163.252C159.272 162.388 159.94 161.178 161.235 161.524C162.614 161.869 163.617 162.69 164.786 163.468C165.079 163.641 165.371 163.857 165.455 164.202C165.538 164.677 165.162 165.066 164.786 165.325L164.202 165.801C164.202 165.801 164.118 165.844 164.076 165.844C163.993 165.844 163.951 165.801 163.909 165.757C163.575 165.325 163.408 164.721 162.948 164.375C162.614 164.116 162.154 164.029 161.737 163.9C161.277 163.77 160.859 163.554 160.483 163.295L160.525 163.252Z"
        fill="#C2C2C2"
      />
      <path
        d="M232.177 387.684L379.487 302.472C381.725 301.179 381.453 298.915 378.853 297.428L365.544 289.745C362.957 288.244 359.038 288.089 356.8 289.382L209.49 374.594C207.252 375.888 207.524 378.151 210.124 379.639L223.433 387.322C226.02 388.822 229.939 388.977 232.177 387.684Z"
        fill="url(#paint1_linear_8164_83441)"
      />
      <path
        d="M217.086 373.227V375.309C217.086 377.159 218.315 379.022 220.746 380.431C225.648 383.277 233.629 383.277 238.57 380.431C241.053 378.996 242.295 377.12 242.295 375.232V373.149C242.295 375.025 241.066 376.913 238.57 378.349C233.629 381.208 225.648 381.208 220.746 378.349C218.315 376.939 217.086 375.077 217.086 373.227Z"
        fill="#404040"
      />
      <path
        d="M220.824 368.027C215.883 370.886 215.857 375.503 220.759 378.349C225.661 381.194 233.642 381.194 238.583 378.349C243.524 375.503 243.55 370.886 238.634 368.027C233.732 365.182 225.752 365.182 220.824 368.027Z"
        fill="#737373"
      />
      <path
        d="M226.037 371.028C223.993 372.205 223.98 374.107 226.011 375.284C228.029 376.461 231.327 376.461 233.358 375.284C235.388 374.107 235.401 372.205 233.383 371.028C231.366 369.851 228.067 369.851 226.037 371.028Z"
        fill="#262626"
      />
      <path
        d="M217.099 373.243V375.326C217.099 377.175 218.328 379.025 220.76 380.435C223.217 381.87 226.464 382.582 229.697 382.569V380.486C226.464 380.486 223.217 379.788 220.76 378.352C218.328 376.942 217.099 375.093 217.099 373.243Z"
        fill="#262626"
      />
      <path
        d="M225.052 273.976V372.976C225.052 373.661 225.505 374.334 226.397 374.864C228.195 375.912 231.131 375.912 232.955 374.864C233.873 374.334 234.326 373.648 234.326 372.95V273.937C234.326 274.623 233.873 275.321 232.955 275.851C231.131 276.899 228.195 276.899 226.397 275.851C225.505 275.334 225.052 274.648 225.052 273.963V273.976Z"
        fill="#404040"
      />
      <path
        d="M225.053 273.984V372.984C225.053 373.669 225.493 374.355 226.398 374.872C227.304 375.403 228.494 375.661 229.684 375.661V276.648C228.494 276.648 227.304 276.39 226.398 275.872C225.493 275.342 225.053 274.669 225.053 273.984Z"
        fill="#262626"
      />
      <path
        d="M226.418 272.066C224.595 273.114 224.595 274.821 226.392 275.869C228.19 276.916 231.126 276.916 232.95 275.869C234.761 274.821 234.774 273.127 232.976 272.066C231.165 271.018 228.242 271.018 226.418 272.066Z"
        fill="#737373"
      />
      <path
        d="M226.31 268.255V273.326C226.31 273.83 226.634 274.322 227.293 274.697C228.613 275.46 230.76 275.46 232.092 274.697C232.765 274.309 233.088 273.804 233.088 273.3V268.229C233.088 268.734 232.752 269.238 232.092 269.626C230.76 270.39 228.613 270.39 227.293 269.626C226.634 269.251 226.31 268.747 226.31 268.255Z"
        fill="#404040"
      />
      <path
        d="M227.305 266.854C225.973 267.617 225.973 268.859 227.292 269.635C228.612 270.398 230.759 270.398 232.091 269.635C233.423 268.872 233.423 267.63 232.104 266.854C230.785 266.078 228.638 266.091 227.318 266.854H227.305Z"
        fill="#737373"
      />
      <path
        d="M226.311 268.249V273.332C226.311 273.823 226.635 274.328 227.307 274.703C227.967 275.091 228.833 275.285 229.713 275.285V270.202C228.833 270.202 227.98 270.008 227.307 269.633C226.647 269.257 226.311 268.753 226.311 268.249Z"
        fill="#262626"
      />
      <path
        d="M346.482 298.469V300.551C346.482 302.401 347.711 304.263 350.143 305.673C355.045 308.519 363.025 308.519 367.966 305.673C370.45 304.237 371.691 302.362 371.691 300.474V298.391C371.691 300.267 370.45 302.155 367.966 303.591C363.025 306.449 355.045 306.449 350.143 303.591C347.711 302.181 346.482 300.318 346.482 298.469Z"
        fill="#404040"
      />
      <path
        d="M350.22 293.259C345.279 296.118 345.253 300.735 350.156 303.581C355.058 306.426 363.038 306.426 367.979 303.581C372.92 300.735 372.946 296.118 368.031 293.259C363.129 290.414 355.148 290.414 350.22 293.259Z"
        fill="#737373"
      />
      <path
        d="M355.433 296.26C353.39 297.437 353.377 299.339 355.407 300.516C357.425 301.693 360.723 301.693 362.754 300.516C364.785 299.339 364.798 297.437 362.78 296.26C360.762 295.083 357.464 295.083 355.433 296.26Z"
        fill="#262626"
      />
      <path
        d="M346.496 298.475V300.558C346.496 302.407 347.724 304.257 350.156 305.667C352.614 307.102 355.86 307.814 359.094 307.801V305.718C355.86 305.718 352.614 305.02 350.156 303.584C347.724 302.174 346.496 300.325 346.496 298.475Z"
        fill="#262626"
      />
      <path
        d="M354.449 199.213V298.213C354.449 298.899 354.901 299.571 355.794 300.102C357.592 301.149 360.528 301.149 362.352 300.102C363.27 299.571 363.723 298.886 363.723 298.187V199.188C363.723 199.873 363.27 200.571 362.352 201.102C360.528 202.149 357.592 202.149 355.794 201.102C354.901 200.584 354.449 199.899 354.449 199.213Z"
        fill="#404040"
      />
      <path
        d="M354.459 199.216V298.216C354.459 298.901 354.899 299.587 355.805 300.104C356.71 300.634 357.9 300.893 359.09 300.893V201.88C357.9 201.88 356.71 201.622 355.805 201.104C354.899 200.574 354.459 199.901 354.459 199.216Z"
        fill="#262626"
      />
      <path
        d="M355.815 197.298C353.991 198.346 353.991 200.053 355.789 201.101C357.587 202.149 360.523 202.149 362.347 201.101C364.157 200.053 364.17 198.359 362.373 197.298C360.562 196.251 357.639 196.251 355.815 197.298Z"
        fill="#737373"
      />
      <path
        d="M355.707 193.487V198.558C355.707 199.062 356.03 199.554 356.69 199.929C358.009 200.692 360.156 200.692 361.488 199.929C362.161 199.541 362.484 199.036 362.484 198.532V193.461C362.484 193.966 362.148 194.47 361.488 194.858C360.156 195.621 358.009 195.621 356.69 194.858C356.03 194.483 355.707 193.979 355.707 193.487Z"
        fill="#404040"
      />
      <path
        d="M356.702 192.082C355.37 192.845 355.37 194.086 356.689 194.863C358.008 195.626 360.155 195.626 361.487 194.863C362.82 194.099 362.82 192.858 361.5 192.082C360.181 191.318 358.034 191.318 356.715 192.082H356.702Z"
        fill="#737373"
      />
      <path
        d="M355.706 193.494V198.577C355.706 199.069 356.029 199.573 356.702 199.948C357.361 200.336 358.228 200.53 359.107 200.53V195.434C358.228 195.434 357.374 195.24 356.702 194.865C356.042 194.49 355.706 193.985 355.706 193.481V193.494Z"
        fill="#262626"
      />
      <path
        d="M221.357 295.178L221.435 321.06C221.435 322.198 221.836 323 222.482 323.375L216.791 320.064C216.145 319.689 215.744 318.887 215.744 317.748L215.666 291.867L221.357 295.178Z"
        fill="#D9D9D9"
      />
      <path
        d="M219.274 285.635L369.275 199.026C370.284 198.444 371.189 198.392 371.849 198.78L377.553 202.079C376.893 201.691 375.988 201.755 374.979 202.324L224.966 288.933C222.974 290.084 221.357 292.891 221.357 295.18L215.666 291.869C215.666 289.58 217.27 286.773 219.274 285.622V285.635Z"
        fill="#F2F2F2"
      />
      <path
        d="M224.967 288.934C222.975 290.085 221.359 292.892 221.359 295.182L221.436 321.063C221.436 323.353 223.066 324.271 225.071 323.12L375.071 236.524C377.063 235.373 378.68 232.579 378.68 230.29L378.602 204.408C378.602 202.119 376.972 201.187 374.968 202.339L224.967 288.934Z"
        fill="#E6E6E6"
      />
      <path
        d="M352.41 215.363L378.68 229.435L378.628 213.681L366.547 207.214L352.41 215.363Z"
        fill="#DD5900"
      />
      <path
        d="M326.866 230.11L357.637 246.601L371.748 238.452L340.977 221.961L326.866 230.11Z"
        fill="#DD5900"
      />
      <path
        d="M301.303 244.866L332.074 261.345L346.186 253.196L315.415 236.718L301.303 244.866Z"
        fill="#DD5900"
      />
      <path
        d="M275.748 259.626L306.519 276.118L320.631 267.956L289.873 251.465L275.748 259.626Z"
        fill="#DD5900"
      />
      <path
        d="M250.186 274.37L280.957 290.874L295.068 282.713L264.31 266.221L250.186 274.37Z"
        fill="#DD5900"
      />
      <path
        d="M224.658 289.14L255.403 305.618L269.527 297.469L238.756 280.978L224.968 288.933C224.865 288.984 224.761 289.062 224.658 289.127V289.14Z"
        fill="#DD5900"
      />
      <path
        d="M221.384 300.118L221.423 315.846L229.856 320.373L243.941 312.225L221.384 300.118Z"
        fill="#DD5900"
      />
      <path
        d="M346.654 212.075L352.397 215.373L366.547 207.212L360.83 203.9L346.654 212.075Z"
        fill="#FF893A"
      />
      <path
        d="M321.107 226.835L326.837 230.12L341 221.946L335.283 218.647L321.107 226.835Z"
        fill="#FF893A"
      />
      <path
        d="M295.564 241.578L301.294 244.877L315.457 236.702L309.74 233.404L295.564 241.578Z"
        fill="#FF893A"
      />
      <path
        d="M270.017 256.338L275.734 259.624L289.91 251.462L284.18 248.151L270.017 256.338Z"
        fill="#FF893A"
      />
      <path
        d="M244.473 271.086L250.203 274.371L264.354 266.197L258.649 262.898L244.473 271.086Z"
        fill="#FF893A"
      />
      <path
        d="M218.926 285.836L224.669 289.121C224.772 289.057 224.863 288.979 224.966 288.927L238.793 280.934L233.076 277.636L219.275 285.616C219.159 285.681 219.042 285.759 218.926 285.836Z"
        fill="#FF893A"
      />
      <path
        d="M215.679 296.861L215.731 312.628L221.422 315.9L221.383 300.146L215.679 296.861Z"
        fill="#A42800"
      />
      <g clipPath="url(#clip1_8164_83441)">
        <path
          d="M199.065 391.403L165.544 410.759L132.232 391.525L165.753 372.178L199.065 391.403Z"
          fill="#A34D2D"
        />
        <path
          d="M131.139 393.937C131.139 393.937 130.676 392.425 132.231 391.525L165.542 410.759V413.791L131.139 393.937Z"
          fill="#8E4428"
        />
        <path
          d="M200.156 393.815C200.156 393.815 200.619 392.303 199.064 391.403L165.543 410.759V413.791L200.156 393.815Z"
          fill="#8E4428"
        />
        <path
          d="M170.838 340.44C170.838 342.091 168.514 343.437 165.648 343.437C162.781 343.437 160.457 342.091 160.457 340.44C160.457 338.788 162.781 337.442 165.648 337.442C168.514 337.442 170.838 338.788 170.838 340.44Z"
          fill="#E45D35"
        />
        <path
          d="M183.955 389.873L170.795 340.038H160.51L147.349 389.882C147.218 390.398 147.148 390.931 147.148 391.473C147.148 397.38 155.433 402.16 165.657 402.16C175.881 402.16 184.165 397.371 184.165 391.473C184.165 390.931 184.095 390.398 183.964 389.882L183.955 389.873Z"
          fill="#DD5900"
        />
        <path
          d="M168.68 340.44C168.68 341.41 167.325 342.187 165.648 342.187C163.97 342.187 162.615 341.401 162.615 340.44C162.615 339.478 163.97 338.692 165.648 338.692C167.325 338.692 168.68 339.478 168.68 340.44Z"
          fill="#492D18"
        />
        <path
          d="M152.251 371.313C152.251 375.586 158.246 379.047 165.647 379.047C173.049 379.047 179.043 375.586 179.043 371.313L181.411 380.261C181.411 385.286 174.351 389.367 165.647 389.367C156.944 389.367 149.883 385.295 149.883 380.261L152.251 371.313Z"
          fill="#F4F9F7"
        />
        <path
          d="M157.381 351.861C157.381 354.5 161.086 356.641 165.648 356.641C170.209 356.641 173.914 354.5 173.914 351.861L175.374 357.384C175.374 360.486 171.013 363.002 165.639 363.002C160.265 363.002 155.904 360.486 155.904 357.384L157.364 351.861H157.381Z"
          fill="#F4F9F7"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8164_83441"
          x1="110.107"
          y1="234.765"
          x2="11.3614"
          y2="191.509"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E242B" />
          <stop offset="1" stopColor="#1E242B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8164_83441"
          x1="356.969"
          y1="302.306"
          x2="241.621"
          y2="374.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E242B" />
          <stop offset="1" stopColor="#1E242B" stopOpacity="0.17" />
        </linearGradient>
        <clipPath id="clip0_8164_83441">
          <rect
            width="420"
            height="392.675"
            fill="white"
            transform="translate(0 8)"
          />
        </clipPath>
        <clipPath id="clip1_8164_83441">
          <rect
            width="69.1393"
            height="76.3573"
            fill="white"
            transform="translate(131.078 337.442)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
