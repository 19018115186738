import React from "react";
import clsx from "clsx";
import {
  Avatar,
  Chip as MuiChip,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { SelectorOptions } from ".";

export interface ChipSelectorOptions extends SelectorOptions {
  count?: number;
  disabled?: boolean;
}

interface Props {
  option: ChipSelectorOptions;
  value?: string | number;
  onChange: (value: string | number) => void;
}

export const Chip: React.FC<Props> = ({ option, value, onChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <MuiChip
      classes={{
        avatar: classes.avatar,
      }}
      key={option.id}
      className={clsx(
        classes.chip,
        value === option.id && classes.selectedChip
      )}
      label={option.name}
      onClick={() => onChange(option.id)}
      variant={theme.palette.type === "dark" ? "outlined" : "default"}
      avatar={option?.count ? <Avatar>{option.count}</Avatar> : <></>}
      disabled={option.disabled}
    />
  );
};

const useStyles = makeStyles(({ palette }) => {
  const selectedChipStyles = {
    color: palette.chip.contrastText,
    backgroundColor: palette.chip.focus,
    "& $avatar": {
      color:
        palette.type === "light"
          ? palette.chip.contrastText
          : palette.chip.text,
      backgroundColor: palette.chip.focusAvatar,
    },
  };
  return {
    chipGroupRoot: {
      display: "flex",
      gap: 10,
    },
    chip: {
      "&&:hover,&&:focus": {
        backgroundColor: palette.chip.focus,
        color: palette.chip.contrastText,
        "& $avatar": {
          backgroundColor: palette.chip.focusAvatar,
          color:
            palette.type === "light"
              ? palette.chip.contrastText
              : palette.chip.text,
        },
      },
      flexDirection: "row-reverse",
      "& $avatar": {
        marginLeft: -5,
        marginRight: 6,
        color: palette.chip.text,
        paddingLeft: 10,
        paddingRight: 10,
        width: "auto",
        borderRadius: 16,
        backgroundColor: palette.chip.avatar,
      },
    },
    selectedChip: {
      ...selectedChipStyles,
      "&&:hover,&&:focus": selectedChipStyles,
    },
    avatar: {
      marginLeft: -5,
      marginRight: 6,
      color: palette.primary.contrastText,
      backgroundColor: palette.chip.avatar,
    },
  };
});
