import React from "react";
import { Chip, Typography, makeStyles, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { 
  Operator,
  OperatorAlertType 
} from "interfaces";
import { Tooltip } from "components";
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';


interface Props {
  operator: Operator;
}

interface AlertProps {
  alert: string;
}

export const QualificationsTooltip: React.FC<Props> = ({ operator }) => {

  const classes = useStyles({});

  const AlertColor: React.FC<AlertProps> = (props) => {
    const classesAlert = useAlertStyles(props);
    return <AssignmentLateIcon className={classesAlert.color} fontSize="small"/>
  }

  const TextWhithIcon = () => {
    const models = operator.qualifs?.models ?? []
    const countAlerts = models.filter(( model ) => model.alert !== OperatorAlertType.OK).length
    return (
      models.map(({ model, alert }, index) => (
        <Typography key={index} variant="caption">
            <ListItem key={index} className={classes.listItem}>
              {countAlerts > 0 && 
                <ListItemIcon className={classes.icon}>
                  { alert !== OperatorAlertType.OK ? <AlertColor alert={ alert } /> : "" }
                </ListItemIcon>
              }
              <ListItemText primary={model} className={classes.listItem}/>
            </ListItem>
        </Typography>
      )) ?? ""
    )
  }

  return (
    <Tooltip
      title={TextWhithIcon()}
      arrow
      placement="right"
      interactive
    >
      <Chip
        size="small"
        label={
          <Typography variant="body2">
            <strong>{operator.qualifs?.count ?? "0"}</strong>
          </Typography>
        }
      />
    </Tooltip>
  );
};

const useAlertStyles = makeStyles((theme) => ({
  space: {
    whiteSpace: 'nowrap',
  },
  color: {
    color: (props: AlertProps) => 
      props.alert ===  OperatorAlertType.WARNING ? theme.palette.warning.main : theme.palette.error.main
  }
}));

// general themes
const useStyles = makeStyles((theme) => ({
  space: {
    whiteSpace: 'nowrap',
  },
  listItem: {
    padding: 0,
    margin: 1
  },
  icon: {
    minWidth: 30
  }
}));