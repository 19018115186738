import {
  Destination,
  DailyPlanEntry,
  DailyPlanData,
  DailyPlanRowsTotals,
  ShiftPlanData,
  FormattedDailyPlanEntry,
} from "interfaces";
import { formatLocalizedDate } from "./date";
import { parse } from "date-fns";

const isNumeric = (value: string): boolean => value !== "" && isFinite(+value);

export const validateDailyPlanEntry = (
  datum: DailyPlanEntry,
  dailyPlan: DailyPlanData
): FormattedDailyPlanEntry | null => {
  // Shift check
  const parsedDate = parse(datum.turno, "dd-MM-yyyy HH:mm", new Date());
  const formattedDate = formatLocalizedDate(
    parsedDate,
    "yyyy-MM-dd HH:mm:ssxxx"
  );
  if (formattedDate === null) return null;
  if (!dailyPlan.hasOwnProperty(formattedDate)) return null;
  // Excavator check
  if (!dailyPlan[formattedDate].hasOwnProperty(datum.pala)) return null;
  // Destiny Check
  const dailyPlanColumn = getDailyPlanColumn(datum.destino);
  if (dailyPlanColumn === null) return null;
  // Numeric values check
  if (!isNumeric(datum.tonelaje) || !isNumeric(datum.CAEX)) return null;
  return {
    shift: formattedDate,
    excavator: datum.pala.toLocaleUpperCase(),
    destiny: dailyPlanColumn,
    tons: +datum.tonelaje,
    assignedTrucks: +datum.CAEX,
  };
};

export const getDailyPlanColumn = (destination: string): Destination | null => {
  const formatted = destination.toLocaleUpperCase();
  if (formatted.startsWith("CH")) {
    if (formatted === "CH4") {
      return Destination.OXIDE_CRUSHER;
    }
    return Destination.SULFIDE_CRUSHER;
  }
  if (formatted.startsWith("S")) {
    return Destination.STOCK;
  }
  if (formatted.startsWith("B")) {
    return Destination.DUMP;
  }
  if (formatted.startsWith("P")) {
    return Destination.STACK;
  }
  return null;
};

export const getRowTotals = (plan: ShiftPlanData): DailyPlanRowsTotals => {
  return Object.fromEntries(
    Object.entries(plan).map(([excav, excavData]) => {
      const sulfideCrusher = excavData.sulfideCrusher ?? 0;
      const oxideCrusher = excavData.oxideCrusher ?? 0;
      const stack = excavData.stack ?? 0;
      const dump = excavData.dump ?? 0;
      const stock = excavData.stock ?? 0;
      return [
        [excav],
        {
          sulfideCrusher,
          oxideCrusher,
          stack,
          dump,
          stock,
          total: sulfideCrusher + oxideCrusher + stack + dump + stock,
          assignedTrucks: excavData.assignedTrucks ?? 0,
        },
      ];
    })
  );
};

const getExcavFormat = (excavs: ShiftPlanData): ShiftPlanData =>
  Object.values(excavs).reduce((prev, current) => {
    return {
      ...prev,
      [current.excav]: { excav: current.excav, phase: current.phase },
    };
  }, {});

export const generateEmptyPlan = (currentPlan: DailyPlanData): DailyPlanData =>
  Object.keys(currentPlan).reduce(
    (prev, current) => ({
      ...prev,
      [current]: getExcavFormat(currentPlan[current]),
    }),
    {}
  );
