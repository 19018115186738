import React, {
  useState,
  useMemo,
  createContext,
  useCallback,
  SetStateAction,
  useEffect,
  useContext,
} from "react";
import {
  DailyPlanAPI,
  DailyPlanData,
  DailyPlanEntry,
  DailyPlanRowsTotals,
  DataContextInterface,
  ErrorMsg,
  initialDataContextState,
  ShiftPlanData,
} from "interfaces";
import { ContextProviderProps, TruckAssignmentKpisContext } from "contexts";
import { useData, usePathnameChange, useMinutesCounter } from "hooks";
import {
  AppRoute,
  generateEmptyPlan,
  getRowTotals,
  validateDailyPlanEntry,
} from "utils";
import { useSnackbar } from "notistack";
import { get } from "lodash";

interface DailyPlanInterface
  extends Omit<
    DataContextInterface<DailyPlanAPI>,
    "polling" | "error" | "manualCancel"
  > {
  loading: boolean;
  lastShiftPlanUpdateMinutes: number | null;
  currentShiftOption: string;
  setCurrentShiftOption: React.Dispatch<SetStateAction<string>>;
  activeShiftPlan: DailyPlanData;
  setActiveShiftPlan: React.Dispatch<SetStateAction<DailyPlanData>>;
  saveDailyPlan: () => void;
  rowsTotals: DailyPlanRowsTotals;
  selectedPlan: ShiftPlanData;
  setDailyPlanCSV: (dailyPlanCSV: DailyPlanEntry[]) => void;
}

const initialState: DailyPlanAPI = {
  dailyShifts: [],
  currentShift: "",
  lastShiftPlanUpdate: null,
  activeShiftPlan: {},
};

export const DailyPlanContext = createContext<DailyPlanInterface>({
  ...initialDataContextState,
  data: initialState,
  loading: false,
  lastShiftPlanUpdateMinutes: null,
  currentShiftOption: "",
  setCurrentShiftOption: () => {},
  activeShiftPlan: initialState.activeShiftPlan,
  setActiveShiftPlan: () => {},
  saveDailyPlan: () => null,
  rowsTotals: {},
  selectedPlan: {},
  setDailyPlanCSV: () => {},
});

export const DailyPlanProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  // API CALLS
  const {
    data,
    firstLoading,
    refetching,
    refetch: refetchDailyPlan,
  } = useData<DailyPlanAPI>(
    {
      config: {
        url: "/truck-assignment/daily-plan",
        method: "GET",
      },
    },
    ErrorMsg.GENERIC_GET,
    initialState
  );

  const {
    refetching: updatingActiveInputsShiftPlan,
    refetch: updateActiveInputsShiftPlan,
  } = useData<unknown, DailyPlanData>(
    {
      config: {
        url: "/truck-assignment/daily-plan",
        method: "PATCH",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.GENERIC_UPDATE
  );

  // STATE
  const loading = firstLoading || refetching || updatingActiveInputsShiftPlan;

  const [shiftOption, setCurrentShiftOption] = useState<string>("");

  const [activeShiftPlan, setActiveShiftPlan] = useState<DailyPlanData>(
    data.activeShiftPlan
  );

  // MEMOS
  const selectedPlan: ShiftPlanData = useMemo(
    () => (shiftOption !== "" ? activeShiftPlan[shiftOption] : {}),
    [shiftOption, activeShiftPlan]
  );

  const rowsTotals: DailyPlanRowsTotals = useMemo(
    () => getRowTotals(selectedPlan),
    [selectedPlan]
  );

  // HOOKS
  const [lastShiftPlanUpdateMinutes] = useMinutesCounter(
    data.lastShiftPlanUpdate
  );
  const { enqueueSnackbar } = useSnackbar();
  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetchDailyPlan(), [AppRoute.TA_DAILY_PLAN_SCREEN]);

  const { refetch: refetchKpis } = useContext(TruckAssignmentKpisContext);

  // EFFECTS & CALLBACKS
  const setDailyPlanCSV = useCallback(
    (entries: DailyPlanEntry[]) => {
      let showWarningAlert = false;
      const newPlan = generateEmptyPlan(activeShiftPlan);
      entries.forEach((datum) => {
        const newEntry = validateDailyPlanEntry(datum, newPlan);
        if (newEntry !== null) {
          const { destiny, shift, tons, assignedTrucks, excavator } = newEntry;
          const existingEntry = get(newPlan, [shift, excavator, destiny], null);
          if (existingEntry !== null) {
            newPlan[shift][excavator][destiny] =
              get(newPlan, [shift, excavator, destiny], 0) + tons / 1000;
          } else {
            newPlan[shift][excavator][destiny] = tons / 1000;
            newPlan[shift][excavator].assignedTrucks = assignedTrucks;
          }
        } else {
          showWarningAlert = true;
        }
      });
      setActiveShiftPlan(newPlan);
      if (showWarningAlert) {
        enqueueSnackbar(ErrorMsg.DAILY_PLAN_CSV_PARSING, {
          variant: "warning",
        });
      } else {
        enqueueSnackbar("Plan diario cargado exitosamente", {
          variant: "success",
        });
      }
    },
    [activeShiftPlan, enqueueSnackbar]
  );

  const saveDailyPlan = useCallback(async () => {
    await updateActiveInputsShiftPlan({
      data: activeShiftPlan,
    });
    enqueueSnackbar("Cambios guardados exitosamente", { variant: "success" });
    await refetchKpis();
    await refetchDailyPlan();
  }, [
    updateActiveInputsShiftPlan,
    activeShiftPlan,
    enqueueSnackbar,
    refetchKpis,
    refetchDailyPlan,
  ]);

  // Set current shift on first render
  useEffect(() => {
    const shiftData = data.dailyShifts.find(
      (shiftData) => shiftData.shiftName === data.currentShift
    );
    setCurrentShiftOption(shiftData?.shiftStartDate ?? "");
  }, [data.currentShift, data.dailyShifts]);

  // Initialize edited shift plan
  useEffect(() => {
    if (data.activeShiftPlan) {
      setActiveShiftPlan(data.activeShiftPlan);
    }
  }, [data.activeShiftPlan]);

  const contextState = useMemo<DailyPlanInterface>(
    () => ({
      data,
      lastShiftPlanUpdateMinutes,
      firstLoading,
      loading,
      refetching,
      refetch: refetchDailyPlan,
      currentShiftOption: shiftOption,
      setCurrentShiftOption,
      activeShiftPlan,
      setActiveShiftPlan,
      saveDailyPlan,
      rowsTotals,
      selectedPlan,
      setDailyPlanCSV,
    }),
    [
      data,
      lastShiftPlanUpdateMinutes,
      firstLoading,
      loading,
      refetching,
      refetchDailyPlan,
      shiftOption,
      activeShiftPlan,
      saveDailyPlan,
      rowsTotals,
      selectedPlan,
      setDailyPlanCSV,
    ]
  );

  return (
    <DailyPlanContext.Provider value={contextState}>
      {children}
    </DailyPlanContext.Provider>
  );
};
