import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import { StopTruckDarkIcon } from "components/icons/StopTruckDarkIcon";
import { StopTruckIcon } from "components/icons/StopTruckIcon";
import React, { useMemo } from "react";

export const Loading = () => {
  const classes = useStyles();
  const theme = useTheme();
  
  const Icon = useMemo(() => {
    return theme.palette.type === "dark" ? StopTruckDarkIcon : StopTruckIcon;
  }, [theme.palette.type]); 

  return (
    <Grid container alignContent="center" style={{ height: 500 }}>
      <Grid container className={classes.infoContainer}>
        <Grid container style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <Icon className={classes.infoIcon} />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography
            color="textPrimary"
            variant="h6"
            style={{ fontWeight: 700, marginBottom: 20 }}
          >
            Cargando. Espere un momento...
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 100
  },
  infoIcon: {
    width: 187,
    height: 200,
    fill: theme.palette.kpi.background,
  },
  link: {
    color: "#0099CC",
  },
}));
