import { Typography, makeStyles } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { CustomDialog } from "components";
import { OperatorsRelieveContext } from "contexts/OperatorsRelieveContext";
import { RelieveFiltersContext } from "contexts/RelieveFiltersContext";
import { RelieveGroupsContext } from "contexts/RelieveGroupsContext";
import { RelieveRecommendationContext } from "contexts/RelieveRecommendationContext";
import { useData } from "hooks";
import { ErrorMsg } from "interfaces";
import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useState } from "react";
import { logError } from "services";

interface ConfirmationDialogProps {
  open: boolean;
  data?: { operator: string; caex: string | null }[];
  group?: number;
  relayId?: number;
}

interface Props {
  openModal: ConfirmationDialogProps;
  setOpenModal: (value: ConfirmationDialogProps) => void;
}

export const ModalDelete: React.FC<Props> = ({ openModal, setOpenModal }) => {
  const classes = useStyles();
  const { refetch: refetchFiltersNewGroup } = useContext(RelieveGroupsContext);
  const { refetch: refetchStatus } = useContext(OperatorsRelieveContext);
  const { refetch: refreshFilters } = useContext(RelieveFiltersContext);
  const { refetch: refreshRelay } = useContext(RelieveRecommendationContext);
  const [isDeleted, setIsDeleted] = useState<boolean | undefined>(false);
  const { enqueueSnackbar } = useSnackbar();

  const { refetch: deleteGroup } = useData(
    {
      config: {
        url: "/operator-assignment/relay/delete-relay",
        method: "DELETE",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.DELETED_RECOMMENDATION
  );

  const refeshData = useCallback(() => {
    refreshRelay();
    refreshFilters();
    refetchStatus();
    refetchFiltersNewGroup();
  }, [refreshFilters, refetchStatus, refreshRelay, refetchFiltersNewGroup]);

  const deleteGroups = useCallback(
    async (group: number, relay: number) => {
      try {
        setIsDeleted(true);
        await deleteGroup({
          params: { group: group, relayId: relay },
        });

        setOpenModal({ open: false });
        refeshData();
        enqueueSnackbar("Grupo de relevo eliminado exitosamente", {
          variant: "success",
        });
        setIsDeleted(false);
      } catch (e) {
        setOpenModal({ open: false });
        setIsDeleted(false);
        logError("DELETE-GROUP-RELAY", `Error: ${e}`);
      }
    },
    [deleteGroup, setOpenModal, enqueueSnackbar, refeshData]
  );
  return (
    <CustomDialog
      title={`Eliminar Grupo`}
      icon={<Warning fontSize="large" className={classes.icon} />}
      open={openModal.open}
      onCompleteInfo={{
        text: "ELIMINAR",
        onClick: () => {
          deleteGroups(openModal.group ?? 0, openModal.relayId ?? 0);
        },
        disabled: isDeleted,
      }}
      onCancelInfo={{
        text: "CANCELAR",
        onClick: () => setOpenModal({ open: false }),
        disabled: isDeleted,
      }}
      onClose={() => {}}
      keepMounted
      classes={{
        actions: {
          dialogTitle: classes.modalDialogTitle,
        },
        dialog: {
          paper: classes.paperModal,
        },
      }}
      backdropProps={{ backProps: { root: classes.backgroudModal } }}
    >
      <Typography variant="subtitle1">
        Esta a punto de <strong>eliminar de la programación</strong> a los
        siguientes operadores y CAEX.{" "}
        <strong>Esta acción no se puede revertir.</strong>
      </Typography>
      {openModal && (
        <ul>
          {openModal?.data?.map((item, index) => (
            <li key={index}>
              <Typography key={index} variant="subtitle1">
                {item.operator} {item.caex === null ? "" : "-"} {item.caex}
              </Typography>
            </li>
          ))}
        </ul>
      )}
    </CustomDialog>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    icon: {
      fill: palette.error.main,
      marginRight: 8,
    },
    modalDialogTitle: {
      color: palette.error.main,
    },
    backgroudModal: {
      backgroundColor: "rgba(0, 0, 0, 0.11)",
    },
    paperModal: {
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
    },
  };
});
