import { alpha } from "@material-ui/core/styles/colorManipulator";

export const commonColors = {
  neutralText: {
    primary: "#323441",
    secondary: alpha("#323441", 0.6),
  },
  alertText: {
    primary: "#323441",
    secondary: alpha("#323441", 0.6),
    warning: "#ED6C02",
    alert: "#D32F2F",
  },
  alertPaper: {
    alert: "#D32F2F",
    warning: "#ED6C02",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  divider: "rgba(0, 0, 0, 0.12)",
};
