import { Box, makeStyles } from "@material-ui/core";
import React from "react";


export const ProgressBar = () => {
  const classes = useStyles();
  return (
    <Box className={classes.progressContainer}>
      <Box className={classes.progressBar}>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
    progressContainer: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "12px", // Ajusta la altura de la barra
      backgroundColor: "rgba(#212932, 0.6)",
      marginTop: '60px'
    },
    progressBar: {
      height: "100%",
      backgroundColor: theme.palette.text.primary, // Color de la barra
      animation: `$load 14s linear forwards`, // Animación de 15 segundos
    },
    // Definir la animación
    "@keyframes load": {
      "0%": {
        width: "0%",
      },
      "100%": {
        width: "100%",
      },
    },
  }));