import { Theme } from "@material-ui/core";
import { ProjectionStatus } from "interfaces";

export const getProjectionColor = (
  theme: Theme,
  projectionStatus?: ProjectionStatus
) =>
  projectionStatus === ProjectionStatus.ON_PLAN
    ? theme.palette.success.main
    : projectionStatus === ProjectionStatus.WARNING
    ? theme.palette.error.main
    : theme.palette.text.primary;
