import { BreakpointOverrides } from "@material-ui/core/styles/createBreakpoints";
export const breakpoints: BreakpointOverrides = {
  values: {
    xs: 0,
    sm: 600,
    md: 1440,
    lg: 1680,
    xl: 1920,
  },
};
