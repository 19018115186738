import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import {
  makeUseAxios,
  Options,
  RefetchOptions,
  ResponseValues,
} from "axios-hooks";
import { UserContext } from "contexts";
import { isEqual } from "lodash";
import qs from "qs";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { usePreviousValue } from "./usePreviousValue";

const useBaseAxios = makeUseAxios({
  axios: axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    responseType: "json",
  }),
});

type ResponseType<TResponse, TBody, TError> = Omit<
  ResponseValues<TResponse, TBody, TError>,
  "loading"
> & {
  firstLoading: boolean;
  pollingLoading: boolean;
  refetchLoading: boolean;
};

export type RefetchFunctionType<TResponse, TBody = unknown> = (
  config?: AxiosRequestConfig<TBody>,
  options?: RefetchOptions
) => AxiosPromise<TResponse>;

export type UsePollingAxiosResult<
  TResponse = any,
  TBody = any,
  TError = any
> = [
  ResponseType<TResponse, TBody, TError>,
  RefetchFunctionType<TResponse, TBody>,
  () => void,
  Date | null
];

export type PollingConfig = {
  silent: boolean;
  minutes: number;
};

type PollingInternalConfig = {
  pollingActive: boolean;
  minutes: number;
};

export type UsePollingAxiosParams<TBody = any> = {
  config: AxiosRequestConfig<TBody> | string;
  options?: Options;
  polling?: number | PollingConfig;
};

const formatConfig = (
  data: number | PollingConfig | undefined
): PollingInternalConfig => {
  if (!data) return { pollingActive: false, minutes: -1 };
  if (typeof data === "number") {
    return { pollingActive: true, minutes: data };
  }
  return { pollingActive: true, ...data };
};

const parseAxiosConfig = (
  config: UsePollingAxiosParams["config"],
  token: string | null
): AxiosRequestConfig => {
  const standardFormatConfig =
    typeof config === "string" ? { url: config, method: "GET" } : config;
  return {
    ...standardFormatConfig,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const paramsSerializer = (params: unknown): string =>
  qs.stringify(params, {
    skipNulls: true,
    arrayFormat: "repeat",
  });

/**
 * UseAxios wrapper from axios-hooks with polling
 * @param config Axios configuration object
 * @param options Axios options object
 * @param polling Number of minutes until request is executed again
 * @returns Same response as base useAxios hook, with the latest refetch date
 */
export function useAxios<TResponse = any, TBody = any, TError = any>({
  config: externalConfig,
  options,
  polling,
}: UsePollingAxiosParams<TBody>): UsePollingAxiosResult<
  TResponse,
  TBody,
  TError
> {
  const { token } = useContext(UserContext);

  const [config, setConfig] = useState<AxiosRequestConfig<TBody>>({
    ...parseAxiosConfig(externalConfig, token),
    paramsSerializer,
  });
  const lastConfig = useRef(externalConfig);
  const lastToken = usePreviousValue(token);

  // External config is an object that changes it's reference on every father's render
  // Update the internal config only if there is a change with the previous value
  useEffect(() => {
    const hasChanged = !isEqual(externalConfig, lastConfig.current);
    if (hasChanged || lastToken !== token) {
      lastConfig.current = externalConfig;
      setConfig((prev) => ({
        ...prev,
        ...parseAxiosConfig(externalConfig, token),
      }));
    }
  }, [token, externalConfig, lastToken]);

  const { pollingActive, minutes } = formatConfig(polling);
  const [pollingLoading, setPollingLoading] = useState(false);
  const [manuallyExecutedFlag, setManuallyExecutedFlag] = useState(false);
  const [pollingIntervalTimer, setPollingIntervalTimer] =
    useState<NodeJS.Timeout | null | number>(null);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [lastExecuted, setLastExecuted] = useState<Date | null>(null);
  const [{ data, loading, error }, execute, manualCancel] = useBaseAxios<
    TResponse,
    TBody,
    TError
  >(config, options);

  const manualCancelWrapper = useCallback(() => {
    // Cancel any ongoing request
    manualCancel();
    // Turn down the flag
    setManuallyExecutedFlag(false);
    // Clean interval
    pollingIntervalTimer && clearInterval(pollingIntervalTimer);
    setPollingIntervalTimer(null);
  }, [manualCancel, pollingIntervalTimer]);

  const manualExecute = useCallback(
    async (
      execConfig?: AxiosRequestConfig<TBody>,
      options?: RefetchOptions
    ) => {
      if (!token && !execConfig?.headers?.Authorization) {
        throw new Error(
          "[AXIOS-HOOK] Cannot use manual execution without providing a token"
        );
      }
      setRefetchLoading(true);
      try {
        const data = await execute(execConfig, options);
        setRefetchLoading(false);
        setManuallyExecutedFlag(true);
        return data;
      } catch (e) {
        setRefetchLoading(false);
        throw e;
      }
    },
    [execute, token]
  );

  useEffect(() => {
    if (!token && !options?.manual) {
      manualCancel();
    }
  }, [manualCancel, options, token]);

  /*
    When config changes (for example the auth header was added)
    we must restart the setInterval of the polling to use the new execute() 
  */
  useEffect(() => {
    // Cancel any ongoing request
    // manualCancel();
    // Clean interval
    pollingIntervalTimer && clearInterval(pollingIntervalTimer);
    setPollingIntervalTimer(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  useEffect(() => {
    if (
      !pollingActive ||
      (options?.manual && !manuallyExecutedFlag) ||
      pollingIntervalTimer
    )
      return;
    if (typeof config !== "string" && config?.method?.toUpperCase() !== "GET")
      throw new Error("[AXIOS-HOOK] Cannot use polling with non GET methods");
    const timer = setInterval(async () => {
      setPollingLoading(true);
      await execute();
      setPollingLoading(false);
    }, minutes * 60000);
    setPollingIntervalTimer(timer);
  }, [
    execute,
    config,
    pollingActive,
    minutes,
    manuallyExecutedFlag,
    pollingIntervalTimer,
    options?.manual,
  ]);

  // Clear interval when unmounting if still alive
  useEffect(() => {
    return () => {
      pollingIntervalTimer && clearInterval(pollingIntervalTimer);
    };
  }, [pollingIntervalTimer]);

  useEffect(() => {
    setLastExecuted(new Date());
  }, [data]);

  return [
    {
      data,
      firstLoading: loading && !pollingLoading && !refetchLoading,
      pollingLoading,
      refetchLoading,
      error,
    },
    manualExecute,
    manualCancelWrapper,
    lastExecuted,
  ];
}
