import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  TableCellProps,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { UnplannedParkedTruck, Order } from "interfaces";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";

interface HeadCell {
  id: keyof UnplannedParkedTruck;
  label: string;
  align: TableCellProps["align"];
  sortable: boolean;
}

interface UnplannedParkedTruckTableHeaderProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof UnplannedParkedTruck
  ) => void;
  order: Order;
  orderBy?: keyof UnplannedParkedTruck;
  loading?: boolean;
}

export const UnplannedParkedTruckTableHeader: React.FC<
  UnplannedParkedTruckTableHeaderProps
> = ({ onRequestSort, order, orderBy, loading }) => {
  const classes = useStyles();

  return (
    <TableHead
      key="unplanned-parked-table-header-root"
      className={classes.tableHeadRoot}
    >
      <TableRow key="unplanned-parked-table-header-row">
        {headCells.map((headCell) => (
          <TableCell
            key={`${String(headCell.id)}-cell`}
            align={headCell.align}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCellHeader}
            variant="head"
          >
            {headCell.sortable ? (
              <TableSortLabel
                key={`${String(headCell.id)}-label`}
                active
                direction={orderBy === headCell.id ? order : "desc"}
                onClick={(e) => onRequestSort(e, headCell.id)}
                IconComponent={(props) => (
                  <ArrowDropDown
                    className={clsx(props.className, classes.sortIcon)}
                  />
                )}
              >
                <Typography className={classes.sortLabel} variant="subtitle1">
                  <b>{headCell.label}</b>
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography
                key={`${String(headCell.id)}-label`}
                className={classes.sortLabel}
                variant="subtitle1"
              >
                <b>{headCell.label}</b>
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
      <TableRow key="linear-progress-row">
        <TableCell
          key="linear-progress-cell"
          padding="none"
          colSpan={headCells.length}
        >
          {loading && <LinearProgress className={classes.linearLoader} />}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const headCells: HeadCell[] = [
  {
    id: "id",
    align: "center",
    label: "CAEX",
    sortable: true,
  },
  {
    id: "reasonId",
    align: "center",
    label: "Código",
    sortable: true,
  },
  {
    id: "finalLocation",
    align: "left",
    label: "Última Ubicación",
    sortable: true,
  },
  {
    id: "parkedAt",
    align: "center",
    label: "Hora últ. ubicación",
    sortable: true,
  },
  {
    id: "selectedLocation",
    align: "left",
    label: "Ubicación esperada",
    sortable: true,
  },
  {
    id: "distance",
    align: "left",
    label: "Distancia",
    sortable: true,
  },
  {
    id: "operativeAt",
    align: "center",
    label: "Hora armado",
    sortable: true,
  },
  {
    id: "lost",
    align: "center",
    label: "Perdido",
    sortable: false,
  },
  {
    id: "comment",
    align: "left",
    label: "Comentario",
    sortable: false,
  },
];

const useStyles = makeStyles(({ palette }: Theme) => {
  const cellBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;
  return createStyles({
    tableHeadRoot: {
      position: "sticky",
      zIndex: 1,
      top: 0,
    },
    tableCellHeader: {
      backgroundColor: cellBackground,
      color: palette.getContrastText(cellBackground),
      paddingTop: 10,
      paddingBottom: 9,
    },
    sortLabel: {
      color: palette.getContrastText(cellBackground),
    },
    sortIcon: {
      color: `${palette.getContrastText(cellBackground)} !important`,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    linearLoader: {
      height: 5,
      position: "absolute",
      width: "100%",
      zIndex: 2,
    },
  });
});
