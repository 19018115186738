import { alpha, createTheme } from "@material-ui/core/styles";
import { PracticeEnum } from "interfaces";
import { commonColors } from "styles";
import { breakpoints } from "styles/theme/breakpoints";
import { bhpTypography } from "../bhpTypography";
import { ColorStatus } from "interfaces/heatMap.interface";

export const darkTheme = createTheme({
  graph: {
    crosshair: {
      line: {
        stroke: commonColors.common.white,
      },
    },
    textColor: commonColors.common.white,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    tooltip: {
      container: {
        color: commonColors.neutralText.primary,
      },
    },
    labels: {
      text: {
        fontSize: "1rem",
        fill: commonColors.common.white,
      },
    },
    axis: {
      domain: {
        line: {
          stroke: alpha("#979797", 0.2),
          strokeWidth: 1,
        },
      },
      legend: {
        text: {
          fontSize: "1.1rem",
          fill: "#FFFFFF",
        },
      },
      ticks: {
        line: {
          stroke: alpha("#979797", 0.2),
          strokeWidth: 1,
        },
        text: {
          fontSize: "1rem",
          fill: "#FFFFFF",
        },
      },
    },
    grid: {
      line: {
        stroke: alpha("#979797", 0.2),
        strokeWidth: 1,
      },
    },
  },
  palette: {
    type: "dark",
    ...commonColors,
    avatar: {
      background: "#FFFFFF",
    },
    collapsedRow: {
      background: "#42474D",
    },
    chip: {
      background: "transparent",
      text: "#FFFFFF",
      avatar: "#42474D",
      focus: "#FFFFFF",
      contrastText: commonColors.neutralText.primary,
      focusAvatar: "#42474D",
    },
    alertCard: {
      default: "#1E242B",
      warning: "#FFD699",
      alert: "#EDACAC",
    },
    background: {
      default: "#1E242B",
      paper: "#2D3339",
    },
    primary: {
      main: "#EE8105",
      dark: "#B55300",
      light: "#FFB144",
      contrastText: "#000000",
    },
    secondary: {
      main: "#7A7A7A",
      dark: "#4E4E4E",
      light: "#A9A9A9",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#66BB6A",
      dark: "#388E3C",
      light: "#81C784",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#29B6F6",
      dark: "#01579B",
      light: "#03A9F4",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFA726",
      dark: "#F57C00",
      light: "#FFB74D",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#F44336",
      dark: "#D32F2F",
      light: "#E57373",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "rgba(255,255,255,0.7)",
      disabled: "rgba(255,255,255,0.5)",
      hint: "rgba(255,255,255,0.5)",
    },
    action: {
      active: "#FFFFFF",
      hover: "rgba(255,255,255,0.08)",
      selected: "rgba(255,255,255,0.16)",
      disabled: "rgba(255,255,255,0.3)",
      disabledBackground: "rgba(255,255,255,0.12)",
      focus: "rgba(255,255,255,0.12)",
    },
    graphSeries: ["#EE8105", "#45768B", "#D7EBFF", "#E0E9AF", "#CC6699"],
    graphColor: {
      blue: "#45768B",
      orange: "#EE8105"
    },
    performanceRatingsColors: {
      [PracticeEnum.LUNCH]: "#EE8105",
      [PracticeEnum.DINNER]: "#45768B",
      [PracticeEnum.FUEL_LOAD]: "#D7EBFF",
      // [PracticeEnum.IMMOB]: "#AFB42B",
      [PracticeEnum.LOST_TRUCK]: "#E0E9AF",
      ALL: "#CC6699",
    },
    movementGraphBar: alpha("#EE8105", 0.5),
    movementTarget: "#0A6CB4",
    mineUtilizationColors: ["#EE8105", "#0A6CB4"],
    kpi: {
      background: "#1E242B",
    },
    heatMap: {
      [ColorStatus.BAD]: "#E57373",
      [ColorStatus.REGULAR]: "#FFB74D",
      [ColorStatus.GOOD]: "#2D3339",
      [ColorStatus.VERY_GOOD]: "#66BB6A",
    },
    heatMapText: {
      [ColorStatus.BAD]: "#323441",
      [ColorStatus.REGULAR]: "#323441",
      [ColorStatus.GOOD]: "#FFFFFF",
      [ColorStatus.VERY_GOOD]: "#323441",
    },
    heatMapLegend: {
      other: "#4E4E4E"
    },
    shiftChange: {
      tableHeaderBackground: '#FFFFFF'
    }
  },
  typography: {
    ...bhpTypography,
  },
  breakpoints,
  planByTheme: {
    primary: {
      600: "blue",
      900: "#2D3339"
    },
    grey: { 300: "#d1d1d1" },
    white: "#fff",
    green: {
      300: "#2c7a7b"
    },
    scrollbar: {
      border: "#ffffff",
      thumb: {
        bg: "#e1e1e1"
      }
    },
    loader: {
      teal: "#5DDADB",
      purple: "#3437A2",
      pink: "#F78EB6",
      bg: "#171923db"
    },
    gradient: {
      blue: {
        300: "#002eb3",
        600: "#002360",
        900: "#051937"
      }
    },
  
    text: {
      grey: {
        300: "#a0aec0",
        500: "#718096"
      }
    },
  
    timeline: {
      divider: {
        bg: "#718096"
      }
    },
    entityColor: [
      "#DD5900",
      "#663366",
      "#1D4F91",
      "#01515F",
      "#035D55",
      "#2B5368",
      "#252525",
      "#F77F00",
      "#993399",
      "#0A6CB4",
      "#007E98",
      "#06AD96",
      "#45768B",
      "#666666",
      "#FCBA5E",
      "#CC6699",
      "#0099CC",
      "#6EC9DA",
      "#ABCC66",
      "#6BA4B8",
      "#B7B9BB",
    ]
  }
});
