import React from "react";
import { makeStyles, SvgIconProps, Typography } from "@material-ui/core";
import clsx from "clsx";

interface LabelClassesProps {
  root?: string;
  icon?: string;
  text?: string;
}

interface LabelProps {
  classes?: LabelClassesProps;
  icon?: React.FC<SvgIconProps<"svg", {}>>;
  text?: string;
}

export const Label: React.FC<LabelProps> = ({
  classes: propClasses,
  icon: Icon,
  text,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.labelRoot, propClasses?.root)}>
      {Icon && <Icon className={clsx(classes.labelIcon, propClasses?.icon)} />}
      {text && (
        <Typography
          className={clsx(classes.labelText, propClasses?.text)}
          noWrap
        >
          {text}
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    labelRoot: {
      display: "flex",
    },
    labelIcon: {
      marginRight: 10,
    },
    labelText: {},
  };
});
