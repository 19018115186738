import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const DottedLineIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="24px"
      height="24px"
      viewBox="16 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M 15 10 l 15 0"
          stroke={props.stroke}
          strokeDasharray="4, 2"
          strokeWidth="2"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
};
