import { Button, Grid, Paper, alpha, makeStyles } from "@material-ui/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Header,
  OperatorAssignmentKpis,
  Restricted,
  ScreenContainer,
  Tooltip,
} from "components";
import { RelieveMenu } from "./RelieveMenu";
import { Can, ErrorMsg, Module } from "interfaces";
import {
  FormStepProps,
  SelectedLocation,
  Status,
} from "interfaces/relieve.interface";
import { RelieveFilters } from "./RelieveFilters";
import { OperatorsRelieveContext } from "contexts/OperatorsRelieveContext";
import { useData } from "hooks";
import { Add, ArrowRight } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { RelieveRecommendationContext } from "contexts/RelieveRecommendationContext";
import { UserContext } from "contexts";
import { RelieveExcavs } from "./RelieveExcavs";
import { RelievePlanningView } from "./RelievePlanningView";
import { RelieveFiltersContext } from "contexts/RelieveFiltersContext";
import { RelieveRecommendationExport } from "./RelieveRecommendationExport";
import { ConfirmationDialogProps, ModalNewGroup } from "./ModalNewGroup";
import { SnackbarError } from "./SnackbarError";
import { NoDataPerformanceScreen } from "components/NoDataPerformanceScreen";
import { OperatorsRelieveStatusContext } from "contexts/OperatorsRelieveStatusContext";

export const RelieveScreen = () => {
  const classes = useStyles();
  const [locationData, setSelectedLocation] = useState<SelectedLocation>();

  //form steps
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedLocation, setcountLocation] = useState(0);
  const [formData, setFormData] = useState<FormStepProps[]>([]);
  const [currentChangeLocation, setCurrentChangeLocation] = useState<any>("");
  const [currentHour, setCurrentHour] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [switches, setSwitches] = useState<{ [key: string]: boolean }>({});
  const [openModal, setOpenModal] = useState<ConfirmationDialogProps>({
    open: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  const targetValuesForm = { operators: 1, caex: 2, excavs: 1 };
  const {
    data: status,
    refetch: refetchStatus,
    firstLoading: loadingStatus,
  } = useContext(OperatorsRelieveContext);
  const { refetch: refetchFilters, firstLoading: loadingFilter } = useContext(
    RelieveFiltersContext
  );
  const { data: relay, firstLoading: firstLoadingRecom } = useContext(
    RelieveRecommendationContext
  );

  const { user } = useContext(UserContext);

  const canWriteUser = useMemo(
    () => user?.permissions.OPERATOR_ASSIGNMENT.CAN_WRITE,
    [user]
  );

  const { refetch: deletePlanning } = useData<FormStepProps>(
    {
      config: {
        url: "/operator-assignment/relay",
        method: "DELETE",
        params: { status: Status.DELETED },
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.POST_DELETED_RELAY
  );

  const planByStatus = useMemo(() => {
    const findPlanStatus = relay?.find(
      (item) => item?.location?.id === formData[selectedLocation]?.location?.id
    );

    if (findPlanStatus) return findPlanStatus;
  }, [relay, formData, selectedLocation]);

  const handleEmptyForm = (location: number) => {
    let data = [...formData];
    data[location] = {
      ...data[location],
      excavs: [],
      operatorsOwn: [],
      operatorsOther: [],
      caex: [],
      statusForm: { hasChanges: false, status: Status.PENDING },
    };
    setFormData(data);
  };

  const deleteLocation = useCallback(
    async (id: string) => {
      const validation = formData?.find((form) => form.location.id === id);
      if (validation) {
        //Delete the item only if it exists in the DB
        const initStatus = status?.find((item) => item?.location?.id === id);
        if (initStatus) {
          const payload = {
            location: validation.location,
            status: Status.DELETED,
          };

          await deletePlanning({ data: payload });
          await refetchStatus();
          await refetchFilters();
          enqueueSnackbar("Comedor eliminado correctamente del formulario", {
            variant: "success",
          });
        }

        //Delete the item locally
        if (selectedLocation <= formData.length - 1 && selectedLocation > 0) {
          setcountLocation(selectedLocation - 1);
        } else {
          setcountLocation(0);
        }
        const newData = formData.filter((form) => form?.location?.id !== id);
        if (!newData.length) setCurrentStep(0);
        setFormData([...newData]);
      }
    },
    [
      formData,
      deletePlanning,
      selectedLocation,
      status,
      enqueueSnackbar,
      refetchStatus,
      refetchFilters,
    ]
  );

  useMemo(() => {
    return setIsLoading(loadingFilter || loadingStatus || firstLoadingRecom);
  }, [loadingFilter, loadingStatus, firstLoadingRecom]);

  //complete the form with the previously saved data
  useEffect(() => {
    if (status.length) {
      const newData: FormStepProps[] = [];
      status.forEach((location) => {
        const operators =
          location.status === "ACCEPTED" ? [] : location.operators;
        const caex = location.status === "ACCEPTED" ? [] : location.caex;
        const excavs = location.status === "ACCEPTED" ? [] : location.excavs;
        const status =
          location.status === "SAVED" ? "PENDING" : location.status;
        newData.push({
          shift: location.shift,
          location: location.location,
          statusForm: { hasChanges: false, status: Status[status] },
          excavs: excavs,
          operatorsOther: operators?.filter((op) => !op.isOwn),
          operatorsOwn: operators?.filter((op) => op.isOwn),
          caex: caex,
          relayHour1: location.relayHour1,
          relayHour2: location.relayHour2,
          relayHour3: location?.relayHour3 ? location?.relayHour3 : null,
          relayHour4: location?.relayHour4 ? location.relayHour4 : null,
          relayId: location.id,
          hasExtra: false,
        });
      });
      setFormData([...newData]);

      if (newData.length) {
        setCurrentStep(1);
      }
      //Find the first location to select
      if (!canWriteUser) {
        const initLocation = newData.findIndex(
          (item) =>
            item?.statusForm?.status === "FINISHED" ||
            item?.statusForm?.status === "ACCEPTED"
        );

        if (initLocation !== -1) setcountLocation(initLocation);
      }
    }
  }, [status, canWriteUser]);

  //updates the leftover items from the relay
  useMemo(() => {
    const currLocation = formData[selectedLocation];
    if (planByStatus && planByStatus?.data?.length && !currLocation.hasExtra) {
      const hasLeftover =
        planByStatus.extraCaexs?.length ||
        planByStatus.extraExcavs?.length ||
        planByStatus.extraOperators?.length;

      if (hasLeftover) {
        let data = [...formData];
        data[selectedLocation] = {
          ...data[selectedLocation],
          excavs: planByStatus.extraExcavs,
          caex: planByStatus.extraCaexs,
          operatorsOther: planByStatus?.extraOperators?.filter(
            (op) => !op.isOwn
          ),
          operatorsOwn: planByStatus?.extraOperators?.filter((op) => op.isOwn),
          hasExtra: true,
        };

        setFormData(data);
      }
    }
  }, [planByStatus, formData, selectedLocation, setFormData]);

  const { data: dataStatus } = useContext(OperatorsRelieveStatusContext);

  if (
    dataStatus &&
    (dataStatus.status === "NOT_DATA_AVAILABLE_BY_HOUR" ||
      dataStatus.status === "NOT_DATA_AVAILABLE")
  ) {
    return (
      <>
        <Header loading={true} />
        <ScreenContainer className={classes.screenContainer}>
          <NoDataPerformanceScreen
            status={dataStatus.status}
            viewName="Relevo"
            hours={1}
          />
        </ScreenContainer>
      </>
    );
  }

  return (
    <>
      <Header loading={false} />
      <ScreenContainer className={classes.screenContainer}>
        <OperatorAssignmentKpis />
        <Grid container alignItems="center">
          <Grid item xs={8} md={9} xl={9}>
            <RelieveFilters
              setCurrentStep={setCurrentStep}
              setcountLocation={setcountLocation}
              formData={formData ?? []}
              selectedLocation={selectedLocation}
              setDeleteLocation={deleteLocation}
              setCurrentChangeLocation={setCurrentChangeLocation}
              handleEmptyForm={handleEmptyForm}
              setCurrentHour={setCurrentHour}
              canWriteUser={canWriteUser}
              setSwitches={setSwitches}
            />
          </Grid>
          <Grid item xs={4} md={3} xl={3} style={{ textAlign: "right" }}>
            <RelieveRecommendationExport />
            <Restricted to={Can.WRITE} at={Module.OPERATOR_ASSIGNMENT}>
              <Button
                className={classes.download}
                style={{ marginLeft: "10px" }}
                onClick={() => setOpenModal({ open: true })}
                disabled={planByStatus === undefined ? true : false}
              >
                <Add style={{ marginRight: "10px" }} />
                NUEVO GRUPO
              </Button>
            </Restricted>
          </Grid>
        </Grid>

        <RelieveExcavs
          excavsData={planByStatus?.excavs ?? []}
          switches={switches}
          setSwitches={setSwitches}
        />
        <Grid container className={classes.mainContainer}>
          <Restricted to={Can.WRITE} at={Module.OPERATOR_ASSIGNMENT}>
            <Grid item xs={expanded ? 4 : 1}>
              {expanded ? (
                <RelieveMenu
                  setSelectedLocation={setSelectedLocation}
                  setCurrentStep={setCurrentStep}
                  currentStep={currentStep}
                  selectedLocation={selectedLocation}
                  targetValues={targetValuesForm}
                  formData={formData}
                  setFormData={setFormData}
                  currentChangeLocation={currentChangeLocation}
                  setCurrentChangeLocation={setCurrentChangeLocation}
                  currentHour={currentHour}
                  setCurrentHour={setCurrentHour}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              ) : (
                <>
                  <Grid
                    item
                    xs={4}
                    style={{ position: "relative", marginRight: 30 }}
                  >
                    <Paper elevation={3} className={classes.mainContainer2}>
                      <Grid style={{ maxHeight: 750 }}>
                        <div>
                          <Tooltip
                            title="Abrir formulario"
                            arrow
                            placement="bottom"
                            interactive
                          >
                            <Button
                              onClick={() => {
                                setExpanded(!expanded);
                              }}
                              className={classes.button}
                            >
                              <ArrowRight />
                            </Button>
                          </Tooltip>
                        </div>
                      </Grid>
                    </Paper>
                  </Grid>
                </>
              )}
            </Grid>
          </Restricted>
          <Grid item xs={!expanded ? 11 : !canWriteUser ? 12 : 8}>
            <RelievePlanningView
              currentLocation={locationData}
              targetValues={targetValuesForm}
              canWriteUser={canWriteUser}
              relay={relay}
              isLoading={isLoading}
              formData={formData}
              setFormData={setFormData}
              selectedLocation={selectedLocation}
              switches={switches}
              expanded={expanded}
            />
            <SnackbarError relay={planByStatus} />
          </Grid>
        </Grid>
        {planByStatus && (
          <ModalNewGroup
            openModal={openModal}
            setOpenModal={setOpenModal}
            relayId={planByStatus?.relayId}
            locationId={formData[selectedLocation]?.location?.id ?? undefined}
          />
        )}
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  screenContainer: {
    overflowX: "hidden",
  },
  mainContainer: {
    background:
      palette.type === "light" ? palette.grey[50] : palette.background.paper,
    padding: "12px 18px",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
  },
  mainContainer2: {
    background:
      palette.type === "light" ? palette.grey[100] : palette.background.default,
    minHeight: 700,
    marginRight: 3,
  },
  button: {
    width: 36,
    height: 36,
    borderRadius: "100%",
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translate(50%, -50%)",
    background: palette.background.paper,
    minWidth: "unset",
    filter:
      "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20))",
    "&:hover,&&:focus": {
      background: palette.background.paper,
    },
  },
  download: {
    background:
      palette.type === "light" ? palette.secondary.main : palette.common.white,
    color: palette.chip.contrastText,
    "&:hover": {
      background: alpha(palette.action.active, 0.6),
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    "&:disabled": {
      background: palette.action.disabled,
    },
  },
}));
