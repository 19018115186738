import {
  Grid,
  Switch,
  Theme,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { BarDatum } from "@nivo/bar";
import {
  GroupedBarGraph,
  LinesProps,
  TooltipProps,
} from "components/Graphs/GroupedBarGraph";
import { filter } from "lodash";
import React, { useMemo, useState } from "react";
import { CustomLegend } from "../TruckAssignmentMetrics/CustomLegend";
import StopIcon from "@material-ui/icons/Stop";
import { DottedLineIcon } from "components";
import { RoutesData } from "interfaces/velocity.interface";

interface Props {
  dataGraph: RoutesData;
}

enum DataTypes {
  speedFullReal = "speedFullReal",
  speedEmptyReal = "speedEmptyReal",
  speedFullTarget = "speedFullTarget",
  speedEmptyTarget = "speedEmptyTarget",
}

export const BarGraph: React.FC<Props> = ({ dataGraph }) => {
  const [switches, setSwitches] = useState<{ [key: string]: boolean }>({
    FULL: true,
    EMPTY: true,
  });
  const classes = useStyles({
    checkedEmpty: switches["EMPTY"],
    checkedFull: switches["FULL"],
  });
  const theme = useTheme();

  const tooltipGraphKeys = {
    title: "Velocidad Promedio [km/h]",
    items: [
      { id: "speedFullReal", tooltipText: "Cargado actual" },
      { id: "speedFullTarget", tooltipText: "Cargado comparación" },
      { id: "speedEmptyReal", tooltipText: "Vacío actual" },
      { id: "speedEmptyTarget", tooltipText: "Vacío comparación" },
    ],
  };

  const barsKeys = ["speedFullReal", "speedEmptyReal"];

  const removeFields = (arr: BarDatum, fields: string[]) => {
    const newObj = Object.fromEntries(
      Object.entries(arr).filter(([key]) => !fields.includes(key))
    );
    return newObj;
  };

  const data = useMemo(() => {
    //check filters
    const filters = Object.keys(switches).filter((key) => switches[key]);
    let copyData = [...dataGraph?.graph];

    if (filter.length === 0) return copyData;
    const notInlucudes: DataTypes[] = [];
    if (!filters.includes("FULL")) {
      notInlucudes.push(DataTypes.speedFullReal);
      notInlucudes.push(DataTypes.speedFullTarget);
    }
    if (!filters.includes("EMPTY")) {
      notInlucudes.push(DataTypes.speedEmptyTarget);
      notInlucudes.push(DataTypes.speedEmptyReal);
    }

    const newData = copyData.map((obj) => removeFields(obj, notInlucudes));

    return newData;
  }, [switches, dataGraph]);

  const maxValue = useMemo(() => {
    const allValues = dataGraph?.graph.flatMap((obj) => Object.values(obj));
    const numbers = allValues?.filter(
      (value) => typeof value === "number"
    ) as number[];
    return Math.max(...numbers) + 5 ?? 0;
  }, [dataGraph]);

  const linesData = useMemo(() => {
    const linesKeys = ["speedFullTarget", "speedEmptyTarget"];
    let linesFormat: LinesProps = { line1: [], line2: [] };
    linesKeys?.forEach((line, index) => {
      let findKey = data.map((point) => {
        return {
          x: point.id as number,
          y: point[line] as number,
        };
      });
      findKey = findKey.filter((item) => item.y !== undefined);

      linesFormat = {
        ...linesFormat,
        [`line${index + 1}`]: findKey,
      };
    });

    return linesFormat;
  }, [data]);

  const handleSwitchChange = (switchName: string) => {
    setSwitches((prevValues: any) => ({
      ...prevValues,
      [switchName]: !prevValues[switchName],
    }));
  };

  const Tooltip = ({ data }: TooltipProps) => {
    return (
      <Grid container className={classes.mainContainerTooltip}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
            {tooltipGraphKeys.title}
          </Typography>
        </Grid>
        <ul className={classes.list}>
          {tooltipGraphKeys?.items?.map((item, index) => {
            const valuePoint = data[item.id];
            if (valuePoint) {
              return (
                <li key={index}>
                  <Typography variant="subtitle1">
                    {`${item.tooltipText} : ${valuePoint}`}
                  </Typography>
                </li>
              );
            } else {
              return (
                <li key={index}>
                  <Typography variant="subtitle1">
                    {`${item.tooltipText} : -`}
                  </Typography>
                </li>
              );
            }
          })}
        </ul>
      </Grid>
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography className={classes.graphTitle} variant="h6">
          <strong>Velocidad Promedio / Tramo</strong>
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={2}>
          <div className={classes.graphAxisTitle}>
            <Typography className={classes.sectionTitle} variant="h6">
              <strong>Velocidad</strong>
            </Typography>

            <Typography className={classes.sectionSubtitle} variant="h6">
              <strong>[km/h]</strong>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={10} className={classes.graphLegend}>
          <>
            <CustomLegend
              icon={
                <StopIcon
                  fontSize="large"
                  style={{ color: theme.palette.graphColor.orange }}
                />
              }
              text="Cargado (Actual)"
            />
            <CustomLegend
              icon={<DottedLineIcon stroke={theme.palette.graphColor.orange} />}
              text="Cargado (Comparación)"
            />
            <Switch
              size="medium"
              onChange={() => handleSwitchChange("FULL")}
              checked={switches["FULL"] || false}
              className={classes.fullSwitch}
            />
            <div style={{ marginRight: "80px" }} />
            <CustomLegend
              icon={
                <StopIcon
                  fontSize="large"
                  style={{ color: theme.palette.graphColor.blue }}
                />
              }
              text="Vacío (Actual)"
            />
            <CustomLegend
              icon={<DottedLineIcon stroke={theme.palette.graphColor.blue} />}
              text="Vacío (Comparación)"
            />
            <Switch
              size="medium"
              onChange={() => handleSwitchChange("EMPTY")}
              checked={switches["EMPTY"] || false}
              className={classes.emptySwitch}
            />
          </>
        </Grid>
      </Grid>
      <GroupedBarGraph
        data={data}
        Tooltip={Tooltip}
        barsKeys={barsKeys}
        linesData={linesData}
        maxValue={maxValue}
      />
    </>
  );
};
interface StylesProps {
  checkedFull: boolean;
  checkedEmpty: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>(({ palette }) => ({
  mainContainerTooltip: {
    background: palette.secondary.main,
    boxShadow:
      "0px 1.38794px 4.16383px 0px rgba(0, 0, 0, 0.12), 0px 1.38794px 1.38794px 0px rgba(0, 0, 0, 0.14), 0px 2.77589px 1.38794px -1.38794px rgba(0, 0, 0, 0.20)",
    padding: "11px",
    maxWidth: "260px",
    color: palette.secondary.contrastText,
  },
  list: {
    paddingInlineStart: "30px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  graphTitle: {
    lineHeight: "24px",
    paddingLeft: "20px",
    paddingBottom: "25px",
  },
  graphLegend: {
    display: "flex",
    alignItems: "center",
  },
  line: {
    color: palette.graphColor.orange,
  },
  graphAxisTitle: {
    width: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "25px",
  },
  sectionSubtitle: {
    fontSize: "16px",
    lineHeight: "28px",
    paddingRight: "20px",
  },
  sectionTitle: {
    fontSize: "16px",
    lineHeight: "28px",
    paddingRight: "20px",
  },
  fullSwitch: {
    "&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked": {
      color: palette.graphColor.orange,
    },
    "&.MuiSwitch-root .MuiSwitch-switchBase": {
      color: palette.common.white,
    },
    "&.MuiSwitch-root .MuiSwitch-track": {
      backgroundColor: ({ checkedFull }) =>
        checkedFull ? palette.graphColor.orange : palette.secondary.main,
    },
  },
  emptySwitch: {
    "&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked": {
      color: palette.graphColor.blue,
    },
    "&.MuiSwitch-root .MuiSwitch-switchBase": {
      color: palette.common.white,
    },
    "&.MuiSwitch-root .MuiSwitch-track": {
      backgroundColor: ({ checkedEmpty }) =>
        checkedEmpty ? palette.graphColor.blue : palette.secondary.main,
    },
  },
}));
