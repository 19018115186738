import React from "react";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { addHours } from "date-fns";
import clsx from "clsx";

import { formatLocalizedDate, parseNumber } from "utils";
import { TargetTooltipData } from "interfaces";

interface Props {
  hourOfDay: Date;
  entries: TargetTooltipData[];
}

export const DataAndTargetTooltip: React.FC<Props> = ({
  hourOfDay,
  entries,
}) => {
  const classes = useStyles();
  const nextHour = addHours(hourOfDay, 1);
  return (
    <Paper className={classes.tooltip}>
      <div className={clsx(classes.row, classes.center)}>
        <Typography variant="subtitle2">
          {`${formatLocalizedDate(hourOfDay, "HH:mm")} - ${formatLocalizedDate(
            nextHour,
            "HH:mm"
          )}`}
        </Typography>
      </div>
      {entries.map(({ color, label, value, unit }, index) => (
        <div className={classes.row} key={index}>
          <div
            className={classes.circle}
            style={{ backgroundColor: color }}
          ></div>
          <Typography className={classes.tooltipIndex} variant="subtitle2">
            {`${label}: ${parseNumber(value, 1)} ${unit}`}
          </Typography>
        </div>
      ))}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    width: "max-content",
    alignItems: "center",
    padding: 10,
  },
  center: {
    justifyContent: "center",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  tooltipIndex: {
    marginRight: 5,
  },
  tooltipInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  circle: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    marginRight: 5,
    borderRadius: 24,
  },
}));
