import React, { createContext } from "react";
import {
  DataContextInterface,
  initialDataContextState,
  ErrorMsg,
} from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { AppRoute } from "utils";
import { SelectorGroup } from "interfaces/relieve.interface";
import { ContextProviderProps } from "contexts";
export const RelieveGroupsContext = createContext<DataContextInterface<SelectorGroup>>(
  initialDataContextState
);

export const RelieveGroupsProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
   const { refetch, ...other } = useData<SelectorGroup>(
    {
      config: {
        url: "/operator-assignment/relay/new-relay/filters",
        method: "GET",
      },
      options: {
        useCache: false,
      },
    },
    ErrorMsg.GET_FILTERS_RELAY
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(
    () => refetch(),
    [AppRoute.OPERATORS_SCREEN]
  );

  return (
    <RelieveGroupsContext.Provider value={{ ...other, refetch }}>
      {children}
    </RelieveGroupsContext.Provider>
  );
};
