import React from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Brightness4 } from "@material-ui/icons";

interface HeaderThemeButtonProps {
  className?: string;
  onClick: () => void;
}

export const HeaderThemeButton: React.FC<HeaderThemeButtonProps> = ({
  className,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <IconButton className={`${className} ${classes.root}`} onClick={onClick}>
      <Brightness4 className={classes.icon} />
    </IconButton>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color:
      theme.palette.type === "light"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
  icon: {},
}));
