import React from "react";
import { Paper, makeStyles, Typography } from "@material-ui/core";
import { PointTooltipProps } from "@nivo/line";
import {
  getPracticesTranslation,
  PointTypeEnum,
  OperatorPracticeOptions,
  ScreenType,
} from "interfaces";

export const LineGraphTooltip: React.FC<PointTooltipProps> = ({ point }) => {
  const classes = useStyles();
  if (point.serieId === PointTypeEnum.EVALUATION) return null;
  const value = Math.round(+point.data.yFormatted * 10) / 10;
  const parsedValue = String(value).replace(".", ",");
  return (
    <Paper className={classes.tooltip}>
      <div key={point.id} className={classes.tooltipInfoContainer}>
        <div
          className={classes.circle}
          style={{ backgroundColor: point.serieColor }}
        ></div>
        <Typography className={classes.tooltipIndex} variant="subtitle2">
          {`${getPracticesTranslation(
            point.serieId as OperatorPracticeOptions,
            ScreenType.OPERATOR
          )} - `}
          <b>{`${parsedValue} minuto${value !== 1 ? "s" : ""}`}</b>
        </Typography>
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    width: "max-content",
    alignItems: "center",
    padding: 5,
  },
  tooltipIndex: {
    marginRight: 5,
  },
  tooltipInfoContainer: {
    display: "flex",
    alignItems: "center",
  },
  circle: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    marginRight: 5,
    borderRadius: 24,
  },
}));
