import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { SkeletonMapsComponent } from "./SkeletonMapsComponent";

export interface KpisPaperProps {
  loading: boolean;
  children?: React.ReactNode;
}

export const KpiMapsContainer: React.FC<KpisPaperProps> = ({
  loading: firstLoadingKpis,
  children,
}) => {
  const classes = useStyles();
  return (
    <>
      {firstLoadingKpis ? (
        <Grid container className={classes.mainContainer}>
          <Grid container spacing={3}>
            <SkeletonMapsComponent  />
          </Grid>
        </Grid>
      ) : (
        children
      )}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    mainContainer: {
      background:
        palette.type === "light"
          ? palette.background.default
          : palette.background.paper,
      marginTop: "30px",
      marginBottom: "30px",
      borderRadius: "4px",
      padding: "4px 8px 8px 8px",
    },
  };
});
