import React, { useContext } from "react";
import { PreAssembleOperatorAssignmentKpisContext } from "contexts";
import { KpisPaper } from "./KpisPaper";
import { KpiCard } from ".";

export const PreAssembleOperatorAssignmentKpis: React.FC = () => {
  const { data, firstLoading } = useContext(PreAssembleOperatorAssignmentKpisContext);
  // WARNING:!:!:!:!:!:!: 
  // ESTA EL FILTRO DE I<4
  return (
    <KpisPaper loading={firstLoading}>
      {data.map((entry, i) => (
        i < 4 ?
        <KpiCard key={`${entry.type}-${i}`} {...entry} /> :
        <></>
      ))}
    </KpisPaper>
  );
};
