import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const RelieveMenuIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_10371_1831)">
        <path
          d="M11.3484 15.4337V14.4538C11.3484 13.4854 11.9363 12.6438 12.7664 12.2749C12.801 12.1135 12.8356 11.9521 12.8702 11.7907C12.9509 11.4679 13.0661 11.1451 13.1814 10.8223H2.12549V12.5516C2.12549 15.4683 4.6272 17.8317 7.71686 17.8317C9.21558 17.8317 10.5644 17.2668 11.5789 16.356C11.4521 16.0678 11.3599 15.7565 11.3599 15.4107L11.3484 15.4337Z"
        />
        <path
          d="M26.8429 13.8084H26.1973C26.1857 13.5086 26.1512 13.2089 26.105 12.9206C25.8975 11.7217 25.3557 10.6149 24.5372 9.72722C24.0299 9.16232 23.4189 8.70118 22.7387 8.36685C22.7041 8.34379 22.658 8.33226 22.6119 8.30921V10.9838C22.6119 11.2029 22.5312 11.4104 22.3813 11.5603C22.2314 11.7101 22.0239 11.8024 21.8164 11.8024C21.8049 11.8024 21.7818 11.8024 21.7703 11.8024C21.7588 11.8024 21.7472 11.7908 21.7357 11.7793C21.7357 11.7793 21.7126 11.7563 21.7126 11.7447C21.7126 11.7332 21.7126 11.7101 21.7126 11.6986V7.5022C21.7126 7.5022 21.7126 7.45609 21.7011 7.43303C21.7011 7.40997 21.6781 7.38692 21.655 7.37539C21.1939 7.12176 19.3839 7.11023 18.9919 7.35233C18.9688 7.37539 18.9458 7.39845 18.9343 7.43303C18.9227 7.46762 18.9112 7.5022 18.9112 7.52526C18.9112 9.28914 18.9112 10.027 18.9112 11.7101C18.9112 11.7332 18.9112 11.7563 18.8881 11.7793C18.8651 11.8024 18.842 11.8024 18.819 11.8024C18.5999 11.8024 18.3924 11.7101 18.2425 11.5603C18.0927 11.4104 18.0004 11.1914 18.0004 10.9723V8.36685C18.0004 8.36685 18.0004 8.34379 18.0004 8.33226C18.0004 8.33226 17.9889 8.30921 17.9774 8.30921C17.9774 8.30921 17.9543 8.30921 17.9428 8.30921C17.9313 8.30921 17.9197 8.30921 17.9082 8.30921L17.6776 8.41296C16.9167 8.78188 16.2481 9.31219 15.7062 9.98085C15.1759 10.638 14.7955 11.4104 14.588 12.2405C14.4612 12.7592 14.4035 13.2896 14.4035 13.8314H13.7694C13.412 13.8314 13.1123 14.1196 13.1123 14.4885V15.4685C13.1123 15.8259 13.4005 16.1256 13.7694 16.1256H26.889C27.2464 16.1256 27.5461 15.8374 27.5461 15.4685V14.4885C27.5461 14.1312 27.2579 13.8314 26.889 13.8314L26.8429 13.8084Z"
        />
        <path
          d="M14.7031 17.0479V18.7771C14.7031 21.6939 17.2048 24.0572 20.2945 24.0572C23.3842 24.0572 25.8859 21.6939 25.8859 18.7771V17.0479H14.7031Z"

        />
        <path
          d="M14.3228 8.87378C14.5073 8.64321 14.7032 8.42416 14.9223 8.22818C14.8992 7.88232 14.6225 7.61716 14.2767 7.61716H13.6311C13.6196 7.31742 13.585 7.01767 13.5389 6.72946C13.3313 5.53048 12.7895 4.42374 11.971 3.53603C11.4637 2.97113 10.8527 2.50999 10.1725 2.17566C10.1379 2.1526 10.0918 2.14107 10.0457 2.11801V4.7696C10.0457 4.98864 9.96499 5.19615 9.81511 5.34603C9.66524 5.4959 9.45773 5.58813 9.25021 5.58813C9.23868 5.58813 9.21563 5.58813 9.2041 5.58813C9.19257 5.58813 9.18104 5.5766 9.16951 5.56507C9.16951 5.56507 9.14646 5.54201 9.14646 5.53048C9.14646 5.51895 9.14646 5.4959 9.14646 5.48437V1.2649C9.14646 1.2649 9.14646 1.21878 9.13493 1.19573C9.13493 1.17267 9.11187 1.14961 9.08881 1.13808C8.62767 0.884454 6.81768 0.872925 6.4257 1.11503C6.40265 1.13808 6.37959 1.16114 6.36806 1.19573C6.35653 1.23031 6.345 1.2649 6.345 1.28796C6.345 3.05183 6.345 3.78966 6.345 5.47284C6.345 5.4959 6.345 5.51895 6.32195 5.54201C6.29889 5.56507 6.27583 5.56507 6.25277 5.56507C6.03373 5.56507 5.82622 5.47284 5.67634 5.32297C5.52647 5.1731 5.43424 4.95405 5.43424 4.73501V2.1526C5.43424 2.1526 5.43424 2.12954 5.43424 2.11801C5.43424 2.11801 5.42272 2.09496 5.41119 2.09496C5.41119 2.09496 5.38813 2.09496 5.3766 2.09496C5.36507 2.09496 5.35354 2.09496 5.34201 2.09496L5.11144 2.19872C4.35055 2.56763 3.6819 3.09795 3.14005 3.76661C2.60974 4.42374 2.22929 5.19615 2.02178 6.02621C1.89496 6.545 1.83732 7.07532 1.83732 7.61716H1.15713C0.799744 7.61716 0.5 7.90538 0.5 8.27429V9.25422C0.5 9.61161 0.788215 9.91136 1.15713 9.91136H13.6426C13.8386 9.55397 14.0692 9.20811 14.3228 8.89684V8.87378Z"

        />
      </g>
      <defs>
        <clipPath id="clip0_10371_1831">
          <rect
            width="27"
            height="23.1149"
            fill="white"
            transform="translate(0.5 0.942383)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
