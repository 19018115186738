import React, { useCallback, useContext, useMemo, useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { get, map, min } from "lodash";
import {
  Header,
  ScreenContainer,
  TruckAssignmentKpis,
} from "components";
import { BalanceList } from "./BalanceList";
import { ExcavatorContext, InfoSourceContext } from "contexts";
import { BalanceWarning, AssignationSource } from "interfaces";
import { useMinutesCounter } from "hooks";
import { parseTimeDelta } from "utils";
import { SicRegistryDialog } from "./SicRegistryDialog";

const BYPASS_OUTDATED_DATA =
  process.env.REACT_APP_BYPASS_OUTDATED_DATA === "true";

export const TruckAssignmentScreen: React.FC = () => {
  const classes = useStyles();
  //is open Menu
  const [stateMenu, setStateMenu] = useState<boolean | undefined>(false)
  const handleUpdateState = (openMenu: boolean) => {
    setStateMenu(openMenu)
  }

  const { magnetElapsedMinutes } = useContext(InfoSourceContext);
  const { data, firstLoading, refetching, polling } =
    useContext(ExcavatorContext);

  const [sicDialogOpened, setSicDialogOpen] = useState(false);

  const lastPowerViewUpdate = useMemo(() => {
    const powerViewUpdates = data.updateTimes.filter(
      (value) => value.source !== AssignationSource.MAGNET
    );
    return min(map(powerViewUpdates, "lastUpdate")) ?? new Date();
  }, [data.updateTimes]);

  const [lastPowerViewUpdateMinutes] = useMinutesCounter(lastPowerViewUpdate);

  const dataUpToDate = useMemo(
    () =>
      BYPASS_OUTDATED_DATA ||
      (magnetElapsedMinutes !== null &&
        magnetElapsedMinutes < 15 &&
        lastPowerViewUpdateMinutes !== null &&
        lastPowerViewUpdateMinutes < 50),
    [lastPowerViewUpdateMinutes, magnetElapsedMinutes]
  );

  const loading = useMemo(
    () => firstLoading || refetching || polling,
    [firstLoading, polling, refetching]
  );

  const tooltipTexts = useMemo(() => {
    if (lastPowerViewUpdateMinutes !== null && magnetElapsedMinutes !== null)
      return [
        "Últimas actualizaciones:",
        `- Métricas y tiempos por pala: ${parseTimeDelta(
          lastPowerViewUpdateMinutes
        )}`,
        `- Información de CAEX: ${parseTimeDelta(magnetElapsedMinutes)}`,
      ];
  }, [lastPowerViewUpdateMinutes, magnetElapsedMinutes]);

  const generalWarning = useMemo(() => {
    const warnings: BalanceWarning[] = get(data, "warnings.general", []);
    if (warnings) {
      return warnings[0];
    }
  }, [data]);

  // const openSicDialog = useCallback(() => setSicDialogOpen(true), []);
  const closeSicDialog = useCallback(() => setSicDialogOpen(false), []);

  return (
    <>
      <Header
        elapsedMinutes={dataUpToDate ? magnetElapsedMinutes : undefined}
        loading={loading}
        minutesToShowWarning={5}
        warningTooltipTexts={tooltipTexts}
        onUpdateStateMenu={handleUpdateState}
      />
      <ScreenContainer>
        <TruckAssignmentKpis openMenu={stateMenu} />
        {dataUpToDate || firstLoading ? (
          <BalanceList excavators={data.table} warning={generalWarning} />
        ) : (
          <div className={classes.infoContainer}>
            <InfoOutlinedIcon color="disabled" className={classes.infoIcon} />
            <Typography color="textSecondary" variant="h5">
              Las fuentes de datos están desactualizadas.
            </Typography>
            <Typography color="textSecondary" variant="h5">
              No es posible realizar sugerencias de asignación de CAEX.
            </Typography>
          </div>
        )}
        {/* <Footer className={classes.footer}>
          <Button
            className={classes.sicButton}
            color="primary"
            variant="contained"
            onClick={openSicDialog}
          >
            REGISTRO SIC
          </Button>
        </Footer> */}
        <SicRegistryDialog open={sicDialogOpened} onClose={closeSicDialog} />
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    paddingBottom: 24,
    paddingTop: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  infoIcon: {
    width: "4em",
    height: "4em",
    marginBottom: 15,
  },
  headerSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 23,
    marginBottom: 23,
    marginRight: 25,
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  subtitle: {
    marginRight: 16,
  },
  countingChip: {
    marginRight: 8,
    paddingRight: 4,
    paddingLeft: 4,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxShadow: "none",
  },
  sicButton: {
    marginLeft: 5,
    marginRight: 5,
    height: 42,
    width: 137,
    color: "white",
    [theme.breakpoints.between("md", "lg")]: {
      marginLeft: 2,
      fontSize: "13px",
    },
  },
}));
