import { CrewEnum, Operator, User } from ".";

export interface Supervisor extends Pick<User, "id" | "name" | "email"> {}

export enum ScreenType {
  OPERATOR = "OPERATOR",
  SUPERVISOR = "SUPERVISOR",
}

export enum PointTypeEnum {
  EVALUATION = "EVALUATION",
  STANDARD = "STANDARD",
}

export enum PeriodEnum {
  CURRENT_DAILY_SHIFT = "CURRENT_DAILY_SHIFT",
  CURRENT_WEEKLY_SHIFT = "CURRENT_WEEKLY_SHIFT",
  CUSTOM = "CUSTOM",
  LAST_3_MONTHS = "LAST_3_MONTHS",
}

export enum OperatorPracticeOptions {
  LUNCH = "LUNCH",
  DINNER = "DINNER",
  FUEL_LOAD = "FUEL_LOAD",
  // IMMOB = "IMMOB",
  LOST_TRUCK = "LOST_TRUCK",
  ALL = "ALL",
}

export enum PracticeEnum {
  LUNCH = "LUNCH",
  DINNER = "DINNER",
  FUEL_LOAD = "FUEL_LOAD",
  // IMMOB = "IMMOB",
  LOST_TRUCK = "LOST_TRUCK",
}

export type OperatorPracticeHash = {
  [key in PracticeEnum]?: number;
};

type PracticeTranslation = Record<
  OperatorPracticeOptions,
  { base: string; code?: string }
>;

const translationInfo: PracticeTranslation = {
  [OperatorPracticeOptions.LUNCH]: { base: "Colación día", code: "2010" },
  [OperatorPracticeOptions.DINNER]: { base: "Colación noche", code: "2011" },
  [OperatorPracticeOptions.FUEL_LOAD]: {
    base: "Combustible",
    code: "2020",
  },
  [OperatorPracticeOptions.LOST_TRUCK]: {
    base: "Camión perdido",
  },
  // [OperatorPracticeOptions.IMMOB]: {
  //   base: "Inmovilidad",
  // },
  [OperatorPracticeOptions.ALL]: { base: "Todas" },
};

const translationsByScreen: Record<ScreenType, PracticeTranslation> = {
  [ScreenType.SUPERVISOR]: translationInfo,
  [ScreenType.OPERATOR]: {
    ...translationInfo,
    [OperatorPracticeOptions.ALL]: { base: "Acumulado" },
  },
};

export const getPracticesTranslation = (
  practice: PracticeEnum | OperatorPracticeOptions,
  screenType: ScreenType,
  withCode?: boolean
): string => {
  const translations = translationsByScreen[screenType ?? ScreenType.OPERATOR];
  const codeSufix =
    withCode && translations[practice].code
      ? ` | ${translations[practice].code}`
      : "";

  return `${translations[practice].base}${codeSufix}`;
};

export const periodOptionsTranslation: Record<PeriodEnum, string> = {
  [PeriodEnum.CURRENT_DAILY_SHIFT]: "HOY",
  [PeriodEnum.CURRENT_WEEKLY_SHIFT]: "TURNO ACTUAL",
  [PeriodEnum.CUSTOM]: "OTRO PERÍODO",
  [PeriodEnum.LAST_3_MONTHS]: "ÚLTIMOS 3 MESES",
};

export interface PerformanceRatingOperatorsTop10Params {
  supervisorId: number | null;
  operatorPractices: PracticeEnum[];
  period: PeriodEnum;
  startDate: string | null;
  endDate: string | null;
  crews: CrewEnum[];
}

export interface PerformanceRatingOperatorTop10 extends OperatorPracticeHash {
  id: Operator["id"];
  name: Operator["name"];
}

export interface PerformanceRatingOperatorLastShiftParams {
  operatorPractices: PracticeEnum[];
}

export interface PerformanceRatingWeeklyShiftDatum {
  eventsCount: number;
  meanDuration: number;
  weeklyShiftStartDate: string;
  weeklyShiftEndDate: string;
}

export type PerformanceRatingOperatorLast6Shifts = {
  ALL?: PerformanceRatingWeeklyShiftDatum[];
  EVALUATIONS?: PerformanceRatingWeeklyShiftDatum[];
} & {
  [key in PracticeEnum]?: PerformanceRatingWeeklyShiftDatum[];
};

export interface PerformanceRatingOperatorEvent {
  duration: number;
  equipId: string;
  exceedTime: number;
  operatorPractice: PracticeEnum;
  startDate: string;
}

export interface PerformanceRatingOperatorEventParams {
  operatorPractices: PracticeEnum[];
  period: PeriodEnum;
  startDate: string | null;
  endDate: string | null;
}

export interface PerformanceRatingOperator
  extends Pick<Operator, "id" | "name"> {
  assignedToMe?: boolean;
}

export interface PerformanceRatingEvent {
  id: number;
  operator: PerformanceRatingOperator;
  operatorPractice: PracticeEnum;
  exceededEventsCount: number;
  averageEventDuration: number;
  accummulatedEventDuration: number;
  supervisorName: Supervisor["name"];
}

export interface Evaluation {
  id: number;
  content: string;
  createdBy: Supervisor;
  updatedBy: Supervisor | null;
  createdAt: string;
  updatedAt: string;
  weeklyShiftStartDate: string;
  weeklyShiftEndDate: string;
}

export type EvaluationRequestBody = Pick<Evaluation, "content">;
