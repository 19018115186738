export enum NotificationEnum {
  SOURCE_STATUS_NOTIFICATION = "SOURCE_STATUS_NOTIFICATION",
  ASSIGNMENT_PROTOCOL_NOTIFICATION = "ASSIGNMENT_PROTOCOL_NOTIFICATION",
  WEEKLY_VALUE_REPORT = "WEEKLY_VALUE_REPORT",
  UNPLANNED_PARKED_EQUIP_REPORT = "UNPLANNED_PARKED_EQUIP_REPORT",
  POTENTIAL_DOUBLE_ASSIGNMENT_ALERT = "POTENTIAL_DOUBLE_ASSIGNMENT_ALERT",
}
export const notificationTranslation: Record<NotificationEnum, string> = {
  [NotificationEnum.SOURCE_STATUS_NOTIFICATION]: "Estado de datos",
  [NotificationEnum.ASSIGNMENT_PROTOCOL_NOTIFICATION]: "Protocolo de armado",
  [NotificationEnum.WEEKLY_VALUE_REPORT]: "Reporte de valor mensual",
  [NotificationEnum.UNPLANNED_PARKED_EQUIP_REPORT]: "Reporte camión perdido",
  [NotificationEnum.POTENTIAL_DOUBLE_ASSIGNMENT_ALERT]:
    "Posible doble asignación",
};

export const shownNotifications = new Set([
  NotificationEnum.ASSIGNMENT_PROTOCOL_NOTIFICATION,
  NotificationEnum.UNPLANNED_PARKED_EQUIP_REPORT,
  NotificationEnum.POTENTIAL_DOUBLE_ASSIGNMENT_ALERT,
]);
