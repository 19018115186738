import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  FiltersProps,
  FormStepProps,
  Location,
} from "interfaces/relieve.interface";
import React, { useMemo } from "react";
import clsx from "clsx";

interface Step1Props {
  setSelectedLocations: (value: any) => void;
  setSelectedShift: (value: string, index: number) => void;
  dataLocation: FormStepProps;
  currentLocation: Location;
  allSelectedLocation: string[];
  filters: FiltersProps;
  hourRelay: string[];
}

export const RelieveMenuStep1: React.FC<Step1Props> = ({
  setSelectedLocations,
  setSelectedShift,
  dataLocation,
  currentLocation,
  allSelectedLocation,
  filters: filtersRelieve,
  hourRelay,
}) => {
  const classes = useStyles();

  const selected_ = useMemo(() => {
    if (dataLocation?.location?.id) return dataLocation?.location?.id;
    return currentLocation?.id ?? "";
  }, [dataLocation, currentLocation]);

  const orderByGroupID = (a: any, b: any) => {
    const groupA = a.name.toUpperCase();
    const groupB = b.name.toUpperCase();

    if (groupA === "" && groupB !== "") {
      return 1;
    }
    if (groupA !== "" && groupB === "") {
      return -1;
    }

    return groupA < groupB ? -1 : groupA > groupB ? 1 : 0;
  };
  //location selector
  const locationFilter: Location[] = useMemo(() => {
    const findSelLocat = filtersRelieve?.locations?.find(
      (item) => item.id === selected_
    );
    let locations = filtersRelieve?.locations?.filter(
      (item) => !allSelectedLocation.includes(item.id)
    );
    if (findSelLocat && !locations.includes(findSelLocat))
      locations.push(findSelLocat);
    const locF = locations?.map((value) => ({
      id: value.id,
      name: value?.alias
        ? value?.alias?.replace("COMEDOR", "").trim()
        : value?.name?.replace("COMEDOR", "").trim(),
    }));

    return locF?.sort(orderByGroupID);
  }, [filtersRelieve, allSelectedLocation, selected_]);

  //update delete chips
  const getName = (id: string) => {
    return locationFilter?.find((item) => item.id === id)?.name ?? "";
  };
  const handleLocationChange = (event: any) => {
    setSelectedLocations({ id: event.value, name: getName(event.value) });
  };
  const handleShiftChange = (event: any, index: number) => {
    setSelectedShift(event.value, index);
  };

  const getCurrentShif = (index: number) => {
    if (dataLocation?.location?.id) {
      switch (index) {
        case 0:
          return dataLocation?.relayHour1;
        case 1:
          return dataLocation?.relayHour2;
        case 2:
          return dataLocation?.relayHour3;
        case 3:
          return dataLocation?.relayHour4;

        default:
          return "";
      }
    } else {
      return hourRelay.length ? hourRelay[index] : "";
    }
  };

  return (
    <>
      <Grid item xs={12} className={classes.selectContainer}>
        <Grid item xs={12} className={classes.title}>
          <Typography variant="subtitle1">Comedor</Typography>
        </Grid>
        <FormControl fullWidth variant="standard">
          <Select
            className={classes.selectContainer}
            value={selected_}
            onChange={(e) => handleLocationChange(e.target)}
            label="Comedores"
            classes={{ root: classes.selector }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {locationFilter?.map(({ name, id }) => (
              <MenuItem key={`${name}-${id}`} value={id}>
                {name}
              </MenuItem>
            ))}
            {!locationFilter?.length && (
              <MenuItem>No se encontraron datos</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.selectContainer}>
        <Grid
          item
          xs={12}
          className={classes.title}
          style={{ paddingBottom: 7 }}
        >
          <Typography variant="subtitle1">Horario Relevo</Typography>
        </Grid>

        <Grid container>
          {filtersRelieve?.hourRelay?.map((item, index) => (
            <Grid item xs={6} key={`shift-${index}`}>
              <Grid item xs={12} className={classes.title}>
                <Typography variant="body2">{item?.name}</Typography>
              </Grid>
              <FormControl key={index} fullWidth>
                <Select
                  disabled={item.hours?.length <= 1 ? true : false}
                  className={clsx(
                    classes.disabledSelector,
                    classes.selectContainer
                  )}
                  value={getCurrentShif(index)}
                  onChange={(e) => handleShiftChange(e.target, index)}
                  label={item?.name}
                  classes={{ root: classes.selector }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {item?.hours?.map(({ name, id }) => (
                    <MenuItem key={`${name}-${id}`} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
const useStyles = makeStyles(({ palette }) => ({
  mainContainer: {
    background: palette.grey[100],
    minHeight: 700,
  },
  selectContainer: {
    margin: "10px 14px",
    // height:"100%"
  },
  title: {
    color: palette.text.primary,
    paddingLeft: 10,
  },
  selector: {
    "&&.MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      "&&:focus": {
        borderRadius: 4,
        border: "1px solid #ced4da",
      },
    },
    "&&.Mui-disabled": {
      color: palette.text.secondary,
    },
  },
  disabledSelector: {
    "&.Mui-disabled .MuiSelect-icon": {
      display: "none",
    },
  },
}));
