import { differenceInMinutes, isYesterday, isToday } from "date-fns";
import { floor, max } from "lodash";
import { formatInTimeZone } from "date-fns-tz";
import { es } from "date-fns/esm/locale";

const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatLocalizedDate = (
  date?: Date | string | null,
  format?: string
): string | null => {
  if (!date) return null;
  return formatInTimeZone(
    date,
    systemTimeZone,
    format ?? "yyyy-MM-dd'T'HH:mm",
    { locale: es }
  );
};

export const parseTimeDelta = (minutes: number): string =>
  minutes === 0
    ? `${minutes}′`
    : minutes % 60 === 0
    ? `${minutes / 60}h`
    : minutes > 60
    ? `${floor(minutes / 60)}h${minutes % 60}′`
    : `${minutes}′`;

export const getDifference = (
  targetDate: Date | string,
  countdown?: boolean
): number => {
  let diff: number;
  if (countdown) {
    diff = differenceInMinutes(new Date(targetDate), new Date());
  } else {
    diff = differenceInMinutes(new Date(), new Date(targetDate));
  }
  const ret = max([0, diff]) ?? 0;
  return ret;
};

export const formatToDayAndHours = (date: string) => {
  const parsedDate = new Date(date);
  if (isYesterday(parsedDate))
    return `Ayer, ${formatLocalizedDate(parsedDate, "HH:mm")} Hrs`;

  if (isToday(parsedDate))
    return `Hoy, ${formatLocalizedDate(parsedDate, "HH:mm")} Hrs`;

  return `${formatLocalizedDate(parsedDate, "eee dd, HH:mm")} Hrs`;
};

export const getDate = () => {
  const parsedDate = new Date();
  return formatLocalizedDate(parsedDate, "dd-MMM-yyyy")?.toUpperCase();
}