import {
  FormControl,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import { AxiosError } from "axios";
import { CustomDialog } from "components";
import { OperatorsRelieveContext } from "contexts/OperatorsRelieveContext";
import { RelieveFiltersContext } from "contexts/RelieveFiltersContext";
import { RelieveGroupsContext } from "contexts/RelieveGroupsContext";
import { RelieveRecommendationContext } from "contexts/RelieveRecommendationContext";
import { useData } from "hooks";
import { ErrorMsg, ErrorRelay } from "interfaces";
import {
  FormNewGroup,
  FormStepProps,
  SelectorProps,
} from "interfaces/relieve.interface";
import { isNull } from "lodash";
import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useMemo, useState } from "react";

interface ErrorMessage {
  message: ErrorRelay;
}
export interface ConfirmationDialogProps {
  open: boolean;
  data?: { operator: string; caex: string | null }[];
  group?: number;
  id?: number;
}

interface Props {
  openModal: ConfirmationDialogProps;
  setOpenModal: (value: ConfirmationDialogProps) => void;
  relayId: number;
  locationId?: string;
}

interface SelectorOptions {
  excavs?: SelectorProps;
  operators?: SelectorProps;
  caex1?: SelectorProps;
  caex2?: SelectorProps;
}

export const ModalNewGroup: React.FC<Props> = ({
  openModal,
  setOpenModal,
  relayId,
  locationId,
}) => {
  const classes = useStyles();
  const [newGroup, setNewGroup] = useState<SelectorOptions | null>(null);
  const [isLoading, setIsloading] = useState<boolean | undefined>(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    firstLoading,
    data: newGroupSelector,
    refetch: refetchGroupSelector,
  } = useContext(RelieveGroupsContext);
  const { refetch: refetchRecommendation } = useContext(
    RelieveRecommendationContext
  );
  const { refetch: refetchStatus } = useContext(OperatorsRelieveContext);
  const { refetch: refetchFilters } = useContext(RelieveFiltersContext);

  const { refetch: saveGroup } = useData<FormStepProps>({
    config: {
      url: "/operator-assignment/relay/new-relay",
      method: "POST",
    },
    options: {
      manual: true,
    },
  });

  const isValidForm = useMemo(() => {
    return (
      newGroup?.excavs !== undefined &&
      newGroup?.excavs !== null &&
      newGroup?.caex1 !== undefined &&
      newGroup?.caex1 !== null &&
      newGroup?.operators !== undefined &&
      newGroup?.operators !== null &&
      newGroup?.caex2 !== undefined &&
      newGroup?.caex2 !== null
    );
  }, [newGroup]);

  const refeshData = useCallback(() => {
    refetchRecommendation();
    refetchGroupSelector();
    refetchStatus();
    refetchFilters();
  }, [
    refetchRecommendation,
    refetchGroupSelector,
    refetchFilters,
    refetchStatus,
  ]);

  const saveForm = useCallback(async () => {
    if (
      newGroup?.excavs !== undefined &&
      newGroup?.caex1 !== undefined &&
      newGroup?.operators !== undefined &&
      newGroup?.caex2 !== undefined
    ) {
      try {
        setIsloading(true);
        const payload: FormNewGroup = {
          excav: newGroup?.excavs?.id,
          operator: newGroup?.operators?.id,
          caex1: newGroup?.caex1?.id,
          caex2: newGroup?.caex2?.id,
          relayId: relayId,
        };
        await saveGroup({ data: payload });
        setIsloading(false);
        setOpenModal({ open: false });
        enqueueSnackbar("Programación de relevo guardada exitosamente", {
          variant: "success",
        });
        refeshData();
        setNewGroup(null);
      } catch (e) {
        setIsloading(false);
        const error: ErrorMessage = (e as AxiosError)?.response
          ?.data as ErrorMessage;
        if (error && error?.message) {
          const err: string = error.message;
          const errorDetails: string | undefined =
            ErrorRelay[err as keyof typeof ErrorRelay];

          enqueueSnackbar(errorDetails ?? ErrorMsg.POST_FINISHED_RELAY, {
            variant: "error",
          });
        }
      }
    }
  }, [enqueueSnackbar, saveGroup, newGroup, setOpenModal, relayId, refeshData]);

  enum TypeSelector {
    excavs = "excavs",
    operators = "operators",
    caex1 = "caex1",
    caex2 = "caex2",
  }

  const handleOnChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: SelectorProps | string | null,
      reason: AutocompleteChangeReason,
      type: TypeSelector
    ) => {
      if (typeof value !== "string") {
        setNewGroup((prevValue) => {
          return {
            ...prevValue,
            [type]: isNull(value) ? null : { ...value },
          };
        });
      }
    },
    [setNewGroup]
  );

  interface AutocompleteProps {
    options: SelectorProps[];
    currentValue: SelectorProps | null;
    type: TypeSelector;
    loadingText: string;
    noOptionsText: string;
    textFieldLabel: string;
  }

  const AutocompleForm = ({
    options,
    currentValue,
    type,
    loadingText = "",
    noOptionsText = "",
    textFieldLabel = "",
  }: AutocompleteProps) => {
    return (
      <Autocomplete
        value={currentValue}
        options={options ?? []}
        onChange={(event, newValue, reason) =>
          handleOnChange(event, newValue, reason, type)
        }
        openText="Abrir"
        closeText="Cerrar"
        loading={firstLoading}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionDisabled={(option) => {
          return option?.disabled ?? false;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={currentValue === null ? textFieldLabel : ""}
            InputLabelProps={{
              classes: { outlined: classes.label, shrink: "false" },
            }}
          />
        )}
        classes={{ inputRoot: classes.autocomplete }}
      />
    );
  };

  const getLabelByShift = (type: TypeSelector) => {
    if (newGroupSelector.shift === "day") {
      return type === TypeSelector.caex1
        ? "Horario Colación — 1:30pm"
        : "Horario Colación — 2:30pm / 3:00pm";
    }
    return type === TypeSelector.caex1
      ? "Horario Colación — 1:30am / 4:30am"
      : "Horario Colación — 2:30am";
  };

  const caexFilter = useMemo(() => {
    if (newGroup === null) return newGroupSelector?.caex;
    const selectedCaex: string[] = [];
    if (newGroup?.caex1 !== undefined && newGroup?.caex1 !== null)
      selectedCaex.push(newGroup?.caex1?.id);
    if (newGroup?.caex2 !== undefined && newGroup?.caex2 !== null)
      selectedCaex.push(newGroup?.caex2?.id);

    const newCaexData = newGroupSelector?.caex?.map((item) => {
      return {
        id: item.id,
        label: item.label,
        disabled: selectedCaex?.includes(item.id) ? true : item.disabled,
      };
    });

    return newCaexData;
  }, [newGroup, newGroupSelector]);

  const excavSelector = useMemo(() => {
    return newGroupSelector?.excavs?.map((item) => {
      if (item.locationId && locationId === item.locationId) {
        return {
          ...item,
          disabled: false,
        };
      }
      return item;
    });
  }, [newGroupSelector, locationId]);

  return (
    <CustomDialog
      title={`Agregar Grupo`}
      open={openModal.open}
      onCompleteInfo={{
        text: "GUARDAR",
        onClick: () => {
          saveForm();
        },
        disabled: !isValidForm || isLoading,
      }}
      onCancelInfo={{
        text: "CANCELAR",
        onClick: () => {
          setOpenModal({ open: false });
          setNewGroup(null);
        },
        disabled: isLoading,
      }}
      onClose={() => {
        setOpenModal({ open: false });
      }}
      classes={{
        actions: {
          dialogTitle: classes.modalDialoglabel,
        },
      }}
    >
      <div className={classes.contentRoot}>
        <FormControl
          fullWidth
          variant="filled"
          classes={{ root: classes.form }}
        >
          <Typography variant="subtitle1" className={classes.title}>
            Pala
          </Typography>
          <AutocompleForm
            options={excavSelector}
            currentValue={newGroup?.excavs ?? null}
            loadingText="Cargando Palas"
            noOptionsText="No se encontraron palas"
            textFieldLabel="Pala"
            type={TypeSelector.excavs}
          />
        </FormControl>
        <FormControl
          fullWidth
          variant="filled"
          classes={{ root: classes.form }}
        >
          <Typography variant="subtitle1" className={classes.title}>
            Operador
          </Typography>
          <AutocompleForm
            options={newGroupSelector.operators}
            currentValue={newGroup?.operators ?? null}
            loadingText="Cargando Operadores"
            noOptionsText="No se encontraron operadores"
            textFieldLabel="Operador"
            type={TypeSelector.operators}
          />
        </FormControl>
        <FormControl
          fullWidth
          variant="filled"
          classes={{ root: classes.form }}
        >
          <Typography variant="subtitle1" className={classes.title}>
            {getLabelByShift(TypeSelector.caex1)}
          </Typography>
          <AutocompleForm
            options={caexFilter ?? []}
            currentValue={newGroup?.caex1 ?? null}
            loadingText="Cargando Caex"
            noOptionsText="No se encontraron Caex"
            textFieldLabel="Caex"
            type={TypeSelector.caex1}
          />
        </FormControl>
        <FormControl
          fullWidth
          variant="filled"
          classes={{ root: classes.form }}
        >
          <Typography variant="subtitle1" className={classes.title}>
            {getLabelByShift(TypeSelector.caex2)}
          </Typography>
          <AutocompleForm
            options={caexFilter ?? []}
            currentValue={newGroup?.caex2 ?? null}
            loadingText="Cargando Caex"
            noOptionsText="No se encontraron Caex"
            textFieldLabel="Caex"
            type={TypeSelector.caex2}
          />
        </FormControl>
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    icon: {
      fill: palette.error.main,
      marginRight: 8,
    },
    modalDialoglabel: {
      color: palette.text.primary,
    },
    contentRoot: {
      padding: "10px 50px 21px 50px",
    },
    autocomplete: {
      "&&.MuiOutlinedInput-root": {
        padding: "4px 12px",
      },
      "&&.MuiInputLabel-formControl": {
        margin: 0,
      },
    },
    form: {
      "&&.MuiInputLabel-formControl": {
        margin: 0,
        paddingLeft: "12px",
      },
      widht: "100%",
      marginBottom: "20px",
    },
    label: {
      transform: "translate(14px, 14px) scale(1)",
    },
    title: {
      fontWeight: 700,
      marginBottom: "4px",
    },
  };
});
