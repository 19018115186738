import React from "react";
import { Tooltip } from "components";
import { Badge, Typography, makeStyles } from "@material-ui/core";


interface Props {
  icon: any;
  tooltipText: string;
  showSup?: number;
}

export const IconWithTooltip: React.FC<Props> = ({ icon, tooltipText, showSup }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={<><Typography variant="body2"> {tooltipText} </Typography></>}
      arrow
      placement="top"
      interactive
    >
      <Badge 
        badgeContent={showSup ?? ""}
        overlap= "rectangular"
        classes={{
          badge: classes.alertBadge,
        }}>
          { icon }
      </Badge>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    alertBadge : {
      fontSize: 12
    }
  }})