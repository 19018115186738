import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Status, StatusForm } from "interfaces/relieve.interface";
import React from "react";

interface Props {
  steps: string[];
  optionalSteps?: number[];
  skipped?: number;
  activeStep: number;
  setActiveStep: (value: number) => void;
  setSkipped?: (value: Set<number>) => void;
  sendForm?: (value: Status) => void;
  setActionForm?: (values: StatusForm, type: string) => void;
  initLocation: (value?: boolean) => void;
  children?: React.ReactNode;
  canSendForm: boolean;
  canNextStep: boolean;
  statusForm: Status;
}

export const StepperComponent: React.FC<Props> = ({
  steps,
  optionalSteps,
  activeStep,
  setActiveStep,
  setActionForm,
  sendForm,
  initLocation,
  statusForm,
  canSendForm,
  canNextStep,
  children,
}) => {
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const classes = useStyles();

  const isStepOptional = (step: number) => {
    return optionalSteps?.includes(step);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(activeStep + 1);
    setSkipped(newSkipped);
  };

  const handleActionForm = (type: Status) => {
    setActionForm &&
      setActionForm({ status: Status[type], hasChanges: false }, "statusForm");
  };

  const handleSendForm = (type: Status) => {
    handleActionForm(type);
    if(activeStep === 1){
      sendForm && sendForm(type);
    }
    if(activeStep === 0){
      initLocation && initLocation(true);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Box sx={{ width: "100%", paddingRight: 20 }}>
      <Grid container style={{ padding: "10px 0px 10px 20px" }}>
        <Stepper
          activeStep={activeStep}
          className={classes.stepper}
          style={{ padding: 10, width: "100%", height: 70 }}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <>
        {/* BODY */}

        {children}

        {/* Footer */}
        {(statusForm !== "FINISHED" && statusForm !== "ACCEPTED" ) && (
          <Grid container className={classes.footer}>
            {activeStep > 0 && activeStep <= steps.length - 1 && (
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    handleBack();
                  }}
                  className={classes.prevButton}
                >
                  Anterior
                </Button>
              </Grid>
            )}
            {activeStep >= 0 && activeStep <= steps.length - 1 && (
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    handleSendForm(Status.SAVED);
                  }}
                  className={classes.prevButton}
                  disabled={!canNextStep && activeStep === 0 ? true : false}
                >
                  Guardar
                </Button>
              </Grid>
            )}
            {activeStep < steps.length - 1 && (
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    handleNext();
                    initLocation();
                  }}
                  color="primary"
                  variant="contained"
                  className={classes.nextButton}
                  disabled={!canNextStep}
                >
                  Siguiente
                </Button>
              </Grid>
            )}
            {activeStep === steps.length - 1 && (
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    handleNext();
                    handleSendForm(Status.FINISHED);
                  }}
                  color="primary"
                  variant="contained"
                  className={classes.successButton}
                  disabled={!canSendForm}
                >
                  Programar
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </>
    </Box>
  );
};

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  successButton: {
    height: 42,
    // maxWidth: 137,
    minWidth: 150,
    margin: "0px 10px 10px 0px",
    color: palette.common.white,
    float: "right",
    [breakpoints.down("md")]: {
      minWidth: 100,
    },
  },
  nextButton: {
    height: 42,
    width: "80%",
    minWidth: 120,
    margin: "0px 10px 10px 10px",
    color: palette.common.white,
    float: "left",
  },
  prevButton: {
    height: 42,
    maxWidth: 178,
    minWidth: 100,
    margin: "0px 10px 10px 10px",
    color: palette.primary.main,
    float: "left",
  },
  stepper: {
    "&& .MuiStepIcon-text": {
      fill: palette.common.white,
    },
  },
}));
