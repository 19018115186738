import { useNavigate } from "react-router-dom";
import React, { useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import {
  HeaderBackButton,
  HeaderThemeButton,
  SearchBar,
  BhpLogo,
} from "components";
import { AppThemeContext } from "contexts";

interface SimplerHeaderProps {
  backRoute: string;
  disableBackButton?: boolean;
  className?: string;
  searchValue?: string;
  onSearchQueryChange?: (value: string) => void;
  searchPlaceholder?: string;
  disableSearch?: boolean;
  logo?: boolean;
}

export const SimplerHeader: React.FC<SimplerHeaderProps> = ({
  searchValue,
  onSearchQueryChange,
  backRoute,
  disableBackButton,
  searchPlaceholder,
  disableSearch,
  logo = false,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { toggleMode } = useContext(AppThemeContext);

  const handleBackRoute = useCallback(
    () => navigate(backRoute),
    [navigate, backRoute]
  );

  return (
    <AppBar className={classes.root} position="fixed">
      <Toolbar>
        <HeaderBackButton
          onClick={handleBackRoute}
          disabled={disableBackButton}
        />
        {logo ? (
          <BhpLogo className={classes.logo} onClick={() => navigate("/")} />
        ) : (
          <></>
        )}
        <div className={classes.searchBarRoot}>
          {onSearchQueryChange && (
            <SearchBar
              value={searchValue}
              onChange={onSearchQueryChange}
              placeholder={searchPlaceholder}
              disabled={disableSearch}
            />
          )}
        </div>
        {logo ? (
          <div className={classes.buttonsContainer}>
            <HeaderThemeButton
              className={classes.themeIconButton}
              onClick={toggleMode}
            />
          </div>
        ) : (
          <></>
        )}
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme;
  return {
    root: {
      backgroundColor: palette.primary.main,
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: 36,
      color: palette.common.white,
    },
    searchBarRoot: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    logo: {
      width: 91,
      height: 32,
      marginRight: "10px",
      fill: palette.common.white,
      [breakpoints.down(425)]: {
        width: 70,
        height: 25,
      },
      cursor: "pointer",
    },
    buttonsContainer: {
      display: "flex",
      alignItems: "center",
      [breakpoints.down(950)]: {
        display: "none",
      },
      maxWidth: 434,
      width: "100%",
      flexDirection: "row-reverse",
      marginLeft: "auto",
    },
    themeIconButton: {
      marginLeft: 10,
      color: palette.common.white,
    },
  };
});
