import { Grid, Typography, makeStyles } from "@material-ui/core";
import { CardComponent } from "components/CardComponent/CardComponent";
import { KpisCardComponent } from "components/CardComponent/KpisCardComponent";
import { Size } from "components/SquareChip";
import { WithoutData } from "components/WithoutData";
import { GpsIcon } from "components/icons/GpsIcon";
import { TruckAssignmentVelocityContext } from "contexts/TruckAssignmentVelocityContext";
import React, { useContext } from "react";

export const RoutesSummary = () => {
  const classes = useStyles();

  const { data: cards, firstLoading } = useContext(TruckAssignmentVelocityContext);

  return (
    <KpisCardComponent loading={firstLoading} numberCards={6}>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h6">{"RESUMEN POR RUTA"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {cards?.data?.length ? (
            <CardComponent
              cardData={cards}
              icon={<GpsIcon className={classes.icon} />}
              defaultCards={false}
              prioritySize={Size.medium}
            />
          ) : (
            <WithoutData title="No se encontraron datos" />
          )}
        </Grid>
      </Grid>
    </KpisCardComponent>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    adjustedTextSize: {
      display: "block",
      width: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    icon: {
      height: "40px",
      width: "40px",
    },
    mainContainer: {
      background:
        palette.type === "light"
          ? palette.background.default
          : palette.background.paper,
      boxShadow:
        "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
      borderRadius: "4px",
      marginBottom: "25px",
      padding: "8px 14px 14px 14px",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
    },
    col: {
      minWidth: 160,
      maxWidth: 345,
      flexBasis: "calc(30% - 10px)",
      margin: 5,
      boxSizing: "border-box",
    },
    cardContent: {
      height: "64px",
      padding: "4px 8px",
      paddingTop: "8px",
      "&.MuiCardContent-root": {
        paddingBottom: "4px",
      },
      boxShadow:
        "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
    },
    cardContainer: {
      boxShadow: "unset",
    },
    title: {
      color:
        palette.type === "light"
          ? palette.text.secondary
          : palette.text.primary,
    },
    infoIconTooltip: {
      lineHeight: "24px",
      marginRight: 10,
      width: 20,
      height: 20,
    },
  };
});
