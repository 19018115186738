import React from "react";
import { Paper, Typography, makeStyles, alpha, Theme } from "@material-ui/core";
import { ArrowDropUp } from "@material-ui/icons";
import clsx from "clsx";
import { Tooltip } from "components/Tooltip";
import { KpiType, PrKpiDetailEnum } from "interfaces";

interface PrKpiDetailProps {
  headerTitle: string;
  value: string | number;
  excessOverLimit?: number;
  colored: boolean;
  practice: KpiType;
  kpi: PrKpiDetailEnum;
}

export const PrKpiCardDetail: React.FC<PrKpiDetailProps> = ({
  headerTitle: title,
  value,
  excessOverLimit,
  colored,
  practice,
  kpi,
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="top"
      title={
        kpi === PrKpiDetailEnum.TOTAL_OPERATORS &&
        practice === KpiType.LOST_TRUCK ? (
          <Typography variant="subtitle2">
            Operadores pertenecen al turno anterior
          </Typography>
        ) : (
          ""
        )
      }
      arrow
    >
      <Paper
        className={clsx(classes.projectionPaper, {
          [classes.colored]: colored,
        })}
        elevation={0}
      >
        <Typography variant="caption"> {title}</Typography>
        <div className={classes.projectionValues}>
          <Typography className={classes.kpiValue} variant="h5">
            <b>{value}</b>
            {excessOverLimit && (
              <>
                <ArrowDropUp className={classes.excessValue} />
                <Typography className={classes.excessValue} variant="body2">
                  <b>{excessOverLimit}</b>
                </Typography>
              </>
            )}
          </Typography>
        </div>
      </Paper>
    </Tooltip>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return {
    kpiValue: {
      paddingRight: 5,
      paddingLeft: 5,
      display: "flex",
      alignItems: "center",
    },
    excessValue: {
      color: theme.palette.error.main,
      display: "flex",
      flexDirection: "row",
    },
    projectionPaper: {
      padding: 4,
      minWidth: 82,
      maxWidth: 127,
      width: "100%",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.background.paper
          : theme.palette.background.default,
      [theme.breakpoints.between("md", "lg")]: {
        padding: 4,
        maxWidth: 80,
        minWidth: 75,
      },
    },
    colored: {
      backgroundColor: alpha(theme.palette.error.main, 0.2),
    },
    projectionValues: {
      display: "flex",
      justifyContent: "center",
    },
  };
});
