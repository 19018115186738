import React, { useCallback, useState } from "react";
import { makeStyles, SvgIconProps, IconButton, alpha } from "@material-ui/core";
import { Label } from "components";
import {
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import TodayRoundedIcon from "@material-ui/icons/TodayRounded";

interface DateTimePickerClasses {
  root?: string;
  timePickerForm?: string;
  label?: {
    itemLabelText: string;
  };
}

interface IDateTimePickerProps extends DateTimePickerProps {
  label?: string;
  labelIcon?: React.FC<SvgIconProps<"svg", {}>>;
  icon?: React.FC<SvgIconProps<"svg", {}>>;
  value: Date | null | string;
  onChange: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => void;
  classes?: DateTimePickerClasses;
}

export const DateTimePicker: React.FC<IDateTimePickerProps> = ({
  classes: propClasses,
  label,
  labelIcon: LabelIcon,
  icon: Icon,
  value,
  onChange,
  ...props
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpenDialog = useCallback(() => setOpen(true), []);
  const handleCloseDialog = useCallback(() => setOpen(false), []);

  return (
    <div className={clsx(classes.root, propClasses?.root)}>
      {label && (
        <Label
          classes={{
            text: clsx(
              classes.itemLabelText,
              propClasses?.label?.itemLabelText
            ),
          }}
          text={label}
          icon={LabelIcon ?? undefined}
        />
      )}
      <MUIDateTimePicker
        open={open}
        clearable
        showTodayButton
        todayLabel="Ahora"
        clearLabel="Limpiar"
        cancelLabel="Cancelar"
        disablePast
        value={value}
        ampm={false}
        minutesStep={5}
        onChange={onChange}
        minDateMessage=""
        className={clsx(classes.timePicker, propClasses?.timePickerForm)}
        DialogProps={{ className: classes.dialogPicker }}
        onClose={handleCloseDialog}
        onOpen={handleOpenDialog}
        {...props}
      />
      <IconButton
        onClick={handleOpenDialog}
        className={classes.calendarIconButton}
      >
        {Icon ? (
          <Icon className={classes.calendarIcon} />
        ) : (
          <TodayRoundedIcon className={classes.calendarIcon} />
        )}
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    root: {
      display: "flex",
      marginTop: 6,
      marginBottom: 6,
      alignItems: "center",
      marginRight: 60,
      "&:last-child": {
        marginRight: 0,
      },
    },
    itemLabelText: {
      fontWeight: 700,
      width: 125,
    },
    timePicker: {
      maxWidth: 240,
      maxHeight: 28,
    },
    dialogPicker: {
      "& .MuiPickersToolbar-toolbar": {
        backgroundColor: palette.primary.main,
      },
      "& .MuiPickersTimePickerToolbar-separator": {
        colo: alpha(palette.common.white, 0.5),
      },
      "& .MuiPickersToolbarText-toolbarTxt": {
        color: alpha(palette.common.white, 0.5),
      },
      "& .MuiPickersToolbarText-toolbarBtnSelected": {
        color: palette.common.white,
      },
    },
    calendarIconButton: {
      color: theme.palette.text.primary,
      marginLeft: 5,
      padding: 4,
    },
    calendarIcon: {
      width: 20,
      height: 20,
    },
  };
});
