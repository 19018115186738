import React from "react";
import { useState, useCallback, useContext } from "react";
import {
  UnplannedParkedTruck,
  UnplannedParkedEditableInfo,
  Can,
  Module,
} from "interfaces";
import { UserContext } from "contexts";
import { TableRow, TableCellProps } from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";
import { formatLocalizedDate } from "utils";
import { UnplannedParkedTruckTextCell } from "./UnplannedParkedTruckTextCell";
import { UnplannedParkedTruckCheckBoxCell } from "./UnplannedParkedTruckCheckBoxCell";
import { UnplannedParkedTruckInputCell } from "./UnplannedParkedTruckInputCell";
import { SelectorOptions } from "components";
import { UnplannedParkedTruckSelectorCell } from "./UnplannedParkedTruckSelectorCell";
import { AutocompleteChangeReason } from "@material-ui/lab";

enum CellType {
  TEXT = "text",
  INPUT = "input",
  CHECKBOX = "checkBox",
  SELECTOR = "selector",
}

interface CellDetails {
  key: string;
  maxWidth: number;
  type: CellType;
  value: string | boolean | number;
  align: TableCellProps["align"];
  options?: SelectorOptions[];
}
interface UnplannedParkedTruckTableRowProps {
  entry: UnplannedParkedTruck;
  locations: SelectorOptions[];
  onChange: (payload: UnplannedParkedEditableInfo) => void;
  isEditable: boolean;
}

export const UnplannedParkedTruckTableRow: React.FC<
  UnplannedParkedTruckTableRowProps
> = ({ entry, locations, onChange, isEditable }) => {
  const { isAllowedTo } = useContext(UserContext);
  const canManage = isAllowedTo(Can.WRITE, Module.OPERATOR_ASSIGNMENT);
  //Local state
  const [editableInfo, setEditableInfo] = useState<UnplannedParkedEditableInfo>(
    {
      id: entry.id,
      comment: entry.comment ?? "",
      lost: entry.lost ?? true,
      locationId:
        locations
          .find((op) => op.name === entry?.finalLocation?.name)
          ?.id.toString() ?? "",
    }
  );

  const onLostChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setEditableInfo((prev) => {
        const newState = {
          ...prev,
          lost: e.target.checked,
        };
        onChange(newState);
        return newState;
      }),
    [onChange]
  );

  const onCommentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setEditableInfo((prev) => {
        const newState = {
          ...prev,
          comment: e.target.value,
        };
        onChange(newState);
        return newState;
      }),
    [onChange]
  );

  const onLocationChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: SelectorOptions | null,
      _reason: AutocompleteChangeReason
    ) =>
      setEditableInfo((prev) => {
        const newState: UnplannedParkedEditableInfo = {
          ...prev,
          locationId: value?.id.toString(),
        };
        onChange(newState);
        return newState;
      }),
    [onChange]
  );

  const getCellDetails = useCallback(
    (entry: UnplannedParkedTruck): CellDetails[] => [
      {
        key: "id",
        maxWidth: 200,
        type: CellType.TEXT,
        value: entry.id,
        align: "center",
      },
      {
        key: "reasonId",
        maxWidth: 200,
        type: CellType.TEXT,
        value: String(entry.reasonId ?? "-"),
        align: "center",
      },
      {
        key: "finalLocation",
        maxWidth: 240,
        type: CellType.SELECTOR,
        value: editableInfo.locationId ?? "",
        options: locations,
        align: "left",
      },
      {
        key: "parkedAt",
        maxWidth: 200,
        type: CellType.TEXT,
        value: formatLocalizedDate(entry.parkedAt, "HH:mm") ?? "-",
        align: "center",
      },
      {
        key: "selectedLocation",
        maxWidth: 240,
        type: CellType.TEXT,
        value: entry.selectedLocation?.name ?? "-",
        align: "left",
      },
      {
        key: "distance",
        maxWidth: 240,
        type: CellType.TEXT,
        value: entry.distance
          ? `${(entry.distance / 1000).toFixed(1)} kms`
          : "-",
        align: "left",
      },
      {
        key: "operativeAt",
        maxWidth: 200,
        type: CellType.TEXT,
        value: formatLocalizedDate(entry.operativeAt, "HH:mm") ?? "-",
        align: "center",
      },
      {
        key: "lost",
        maxWidth: 160,
        type: CellType.CHECKBOX,
        value: editableInfo.lost,
        align: "center",
      },
      {
        key: "comment",
        maxWidth: 261,
        type: CellType.INPUT,
        value: editableInfo.comment ?? "",
        align: "left",
      },
    ],
    [
      editableInfo.comment,
      editableInfo.locationId,
      editableInfo.lost,
      locations,
    ]
  );

  return (
    <TableRow key={`${entry.id}-row`}>
      {getCellDetails(entry).map((detail) => {
        if (detail.type === CellType.CHECKBOX) {
          return canManage && isEditable ? (
            <UnplannedParkedTruckCheckBoxCell
              key={detail.key}
              value={Boolean(detail.value)}
              maxWidth={detail.maxWidth}
              align={detail.align}
              onChange={onLostChange}
            />
          ) : (
            <UnplannedParkedTruckTextCell
              key={detail.key}
              value={detail.value ? <CheckRounded /> : null}
              maxWidth={detail.maxWidth}
              align={detail.align}
            />
          );
        }
        if (detail.type === CellType.INPUT) {
          return canManage && isEditable ? (
            <UnplannedParkedTruckInputCell
              key={detail.key}
              value={detail.value.toString()}
              maxWidth={detail.maxWidth}
              align={detail.align}
              onChange={onCommentChange}
            />
          ) : (
            <UnplannedParkedTruckTextCell
              key={detail.key}
              value={detail.value.toString()}
              maxWidth={detail.maxWidth}
              align={detail.align}
            />
          );
        }
        if (detail.type === CellType.SELECTOR) {
          return canManage && isEditable ? (
            <UnplannedParkedTruckSelectorCell
              options={locations}
              key={detail.key}
              value={locations.find(({ id }) => id === detail.value)}
              maxWidth={detail.maxWidth}
              align={detail.align}
              onChange={onLocationChange}
            />
          ) : (
            <UnplannedParkedTruckTextCell
              key={detail.key}
              value={locations.find(({ id }) => id === detail.value)?.name}
              maxWidth={detail.maxWidth}
              align={detail.align}
            />
          );
        }
        return (
          <UnplannedParkedTruckTextCell
            key={detail.key}
            value={detail.value.toString()}
            maxWidth={detail.maxWidth}
            align={detail.align}
          />
        );
      })}
    </TableRow>
  );
};
