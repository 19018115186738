import { truncateWithDecimals } from "utils";
import { Point } from "@nivo/line";

type numberParser<T = number> = (value: number) => T;

export enum MetricGroupType {
  MINE = "mine",
  PIT = "pit",
  PHASE = "phase",
  VIRTUAL_REGION = "virtual_region",
  EXCAV = "excav",
}

export const metricsGroupTypeTranslation: Record<MetricGroupType, string> = {
  [MetricGroupType.MINE]: "Mina",
  [MetricGroupType.PIT]: "Rajo",
  [MetricGroupType.PHASE]: "Fase",
  [MetricGroupType.VIRTUAL_REGION]: "R. Virtual",
  [MetricGroupType.EXCAV]: "Pala",
};

export interface EntityFields {
  id: string;
}

export enum DisplayMetric {
  ORIGIN_QUEUE = "ORIGIN_QUEUE",
  EXCAV_HANG = "EXCAV_HANG",
  CYCLE_TIME = "CYCLE_TIME",
  SATURATION = "SATURATION",
  LOADTONS = "LOADTONS",
  MINE_UTILIZATION = "MINE_UTILIZATION",
  CAEX = "CAEX",
  CAEX_PLAN = "AVERAGE_CAEX_PLAN"
}

export enum AccumulatedDataEntry {
  AVERAGE_QUEUE_MINUTES = "AVERAGE_QUEUE_MINUTES",
  AVERAGE_HANG_MINUTES = "AVERAGE_HANG_MINUTES",
  AVERAGE_CYCLE_TIME = "AVERAGE_CYCLE_TIME",
  AVERAGE_SATURATION = "AVERAGE_SATURATION",
  ACCUMULATED_LOADTONS = "ACCUMULATED_LOADTONS",
  AVERAGE_UTILIZATION = "AVERAGE_UTILIZATION",
  AVERAGE_CAEX = "AVERAGE_ASSIGNMENT_CAEX",
  AVERAGE_CAEX_PLAN = "AVERAGE_ASSIGNMENT_CAEX_FROM_DAILY_PLAN"
}

export const accumulatedDataMapping: Record<
  DisplayMetric,
  AccumulatedDataEntry
> = {
  [DisplayMetric.ORIGIN_QUEUE]: AccumulatedDataEntry.AVERAGE_QUEUE_MINUTES,
  [DisplayMetric.EXCAV_HANG]: AccumulatedDataEntry.AVERAGE_HANG_MINUTES,
  [DisplayMetric.CYCLE_TIME]: AccumulatedDataEntry.AVERAGE_CYCLE_TIME,
  [DisplayMetric.SATURATION]: AccumulatedDataEntry.AVERAGE_SATURATION,
  [DisplayMetric.LOADTONS]: AccumulatedDataEntry.ACCUMULATED_LOADTONS,
  [DisplayMetric.MINE_UTILIZATION]: AccumulatedDataEntry.AVERAGE_UTILIZATION,
  [DisplayMetric.CAEX]: AccumulatedDataEntry.AVERAGE_CAEX,
  [DisplayMetric.CAEX_PLAN]: AccumulatedDataEntry.AVERAGE_CAEX_PLAN,
};

export const accumulatedDataParser: Record<AccumulatedDataEntry, numberParser> =
  {
    [AccumulatedDataEntry.AVERAGE_QUEUE_MINUTES]: (value: number) =>
      truncateWithDecimals(value, 1),
    [AccumulatedDataEntry.AVERAGE_HANG_MINUTES]: (value: number) =>
      truncateWithDecimals(value, 1),
    [AccumulatedDataEntry.AVERAGE_CYCLE_TIME]: (value: number) =>
      truncateWithDecimals(value, 1),
    [AccumulatedDataEntry.AVERAGE_SATURATION]: (value: number) =>
      truncateWithDecimals(value * 100, 1),
    [AccumulatedDataEntry.ACCUMULATED_LOADTONS]: (value: number) =>
      truncateWithDecimals(value / 1000, 1),
    [AccumulatedDataEntry.AVERAGE_UTILIZATION]: (value: number) =>
      truncateWithDecimals(value * 100, 1),
    [AccumulatedDataEntry.AVERAGE_CAEX]: (value: number) =>
    truncateWithDecimals(value, 1),
    [AccumulatedDataEntry.AVERAGE_CAEX_PLAN]: (value: number) =>
    truncateWithDecimals(value, 1),
  };

export enum Metric {
  EXCAV_QUEUE_TIME_MINUTES = "EXCAV_QUEUE_TIME_MINUTES",
  EXCAV_HANG_TIME_MINUTES = "EXCAV_HANG_TIME_MINUTES",
  FILTERED_CYCLE_TIME_MINUTES = "FILTERED_CYCLE_TIME_MINUTES",
  SATURATION_LEVEL = "SATURATION_LEVEL",
  LOADTONS = "LOADTONS",
  ACCUMULATED_EXCAV_QUEUE_TIME_MINUTES = "ACCUMULATED_EXCAV_QUEUE_TIME_MINUTES",
  ACCUMULATED_EXCAV_HANG_TIME_MINUTES = "ACCUMULATED_EXCAV_HANG_TIME_MINUTES",
  ACCUMULATED_LOADTONS = "ACCUMULATED_LOADTONS",
  MOVEMENT_TARGET_FROM_DAILY_PLAN = "MOVEMENT_TARGET_FROM_DAILY_PLAN",
  LOADTONS_PER_HOUR = "LOADTONS_PER_HOUR",
  MINE_UTILIZATION = "MINE_UTILIZATION",
  UTILIZATION_TARGET = "UTILIZATION_TARGET",
  CAEX = "ASSIGNMENT_CAEX",
  CAEX_DAILY_PLAN = "ASSIGNMENT_CAEX_FROM_DAILY_PLAN",
}

export const metricParser: Record<Metric, numberParser> = {
  [Metric.EXCAV_QUEUE_TIME_MINUTES]: (value: number) =>
    truncateWithDecimals(value, 1),
  [Metric.EXCAV_HANG_TIME_MINUTES]: (value: number) =>
    truncateWithDecimals(value, 1),
  [Metric.FILTERED_CYCLE_TIME_MINUTES]: (value: number) =>
    truncateWithDecimals(value, 1),
  [Metric.SATURATION_LEVEL]: (value: number) =>
    truncateWithDecimals(value * 100, 1),
  [Metric.LOADTONS]: (value: number) => truncateWithDecimals(value, 2),
  [Metric.ACCUMULATED_EXCAV_QUEUE_TIME_MINUTES]: (value: number) =>
    truncateWithDecimals(value, 1),
  [Metric.ACCUMULATED_EXCAV_HANG_TIME_MINUTES]: (value: number) =>
    truncateWithDecimals(value, 1),
  [Metric.ACCUMULATED_LOADTONS]: (value: number) =>
    truncateWithDecimals(value / 1000, 2),
  [Metric.MOVEMENT_TARGET_FROM_DAILY_PLAN]: (value: number) =>
    truncateWithDecimals(value / 1000, 3),
  [Metric.LOADTONS_PER_HOUR]: (value: number) =>
    truncateWithDecimals(value / 1000, 3),
  [Metric.MINE_UTILIZATION]: (value: number) =>
    truncateWithDecimals(value * 100, 1),
  [Metric.UTILIZATION_TARGET]: (value: number) =>
    truncateWithDecimals(value * 100, 1),
  [Metric.CAEX]: (value: number) =>
  truncateWithDecimals(value, 1),
  [Metric.CAEX_DAILY_PLAN]: (value: number) =>
  truncateWithDecimals(value, 1),
};

// VIEW OPTION = ACTUAL
export const metricsMapping: Record<DisplayMetric, Metric> = {
  [DisplayMetric.ORIGIN_QUEUE]: Metric.EXCAV_QUEUE_TIME_MINUTES,
  [DisplayMetric.EXCAV_HANG]: Metric.EXCAV_HANG_TIME_MINUTES,
  [DisplayMetric.CYCLE_TIME]: Metric.FILTERED_CYCLE_TIME_MINUTES,
  [DisplayMetric.SATURATION]: Metric.SATURATION_LEVEL,
  [DisplayMetric.LOADTONS]: Metric.LOADTONS_PER_HOUR,
  [DisplayMetric.MINE_UTILIZATION]: Metric.MINE_UTILIZATION,
  [DisplayMetric.CAEX]: Metric.CAEX,
  [DisplayMetric.CAEX_PLAN]: Metric.CAEX,
};

export const metricTranslation: Record<DisplayMetric, string> = {
  [DisplayMetric.ORIGIN_QUEUE]: "Cola Origen",
  [DisplayMetric.EXCAV_HANG]: "Espera Pala",
  [DisplayMetric.CYCLE_TIME]: "Tiempo de Ciclo",
  [DisplayMetric.SATURATION]: "Saturación Real",
  [DisplayMetric.LOADTONS]: "Movimiento",
  [DisplayMetric.MINE_UTILIZATION]: "Utilización Mina",
  [DisplayMetric.CAEX]: "Asignación CAEX",
  [DisplayMetric.CAEX_PLAN]: "Asignación CAEX",
};

export const metricUnit: Record<DisplayMetric, string> = {
  [DisplayMetric.ORIGIN_QUEUE]: "min/carga",
  [DisplayMetric.EXCAV_HANG]: "min/carga",
  [DisplayMetric.CYCLE_TIME]: "min/carga",
  [DisplayMetric.SATURATION]: "%",
  [DisplayMetric.LOADTONS]: "kt",
  [DisplayMetric.MINE_UTILIZATION]: "%",
  [DisplayMetric.CAEX]: "real/plan",
  [DisplayMetric.CAEX_PLAN]: "real/plan",
};

export enum ChangeType {
  TOOL = "TOOL",
  MODULAR = "MODULAR",
}

export const changeSwitchLabel: Record<ChangeType, string> = {
  [ChangeType.TOOL]: "Gestión en la Herramienta",
  [ChangeType.MODULAR]: "Gestión en Modular",
};

export interface MetricsAPI {
  metrics: Record<Metric, MetricData[]>;
  currentShift: string;
  lastMetricsUpdate: Date | null;
  changeEvents: ChangeEventData[];
}

export const emptyMetrics: MetricsAPI["metrics"] = Object.assign(
  {},
  ...Object.values(Metric).map((type) => ({
    [type]: [],
  }))
);

export interface AccumulatedMetric {
  metric: AccumulatedDataEntry;
  value: number;
}

export enum AttributeChange {
  INCLUSION = "INCLUSION",
  PRIORITY = "PRIORITY",
  BOTH = "BOTH",
}

export interface ToolChangeEvent {
  type: ChangeType.TOOL;
  excav: string;
  attributeChange: AttributeChange;
  priority: number;
  inclusion: number;
  prevPriority: number | null;
  prevInclusion: boolean | null;
  user: string;
  issueDate: Date;
}

export interface ModularChangeEvent {
  type: ChangeType.MODULAR;
  settingCategory: ModularChangeSettingCategory;
  setting: Setting;
  entity: string;
  virtualRegion: string;
  value: string;
  prevValue: string;
  issueDate: Date;
}

export type ChangeEventData = ToolChangeEvent | ModularChangeEvent;

export interface MetricData {
  issueDate: string;
  value: number;
  changeEvents: number[];
}

export interface MetricDataWithEvents extends Omit<MetricData, "changeEvents"> {
  changeEvents: MetricsAPI["changeEvents"];
}

export interface TruckAssignmentMetricsParams {
  groupType: MetricGroupType;
  entity: string;
}

export interface TargetTooltipData {
  color: string;
  label: string;
  value: number;
  unit: string;
}

export interface MetricsPoint extends Omit<Point, "data"> {
  data: {
    x: Date;
    xFormatted: string;
    y: number;
    yFormatted: string;
    changeEvents: ChangeEventData[];
  };
}

export type EnhancedPoint = Omit<Point, "data"> & {
  data: Point["data"] & {
    changeEvents: ChangeEventData[];
  };
};

export enum ModularChangeSettingCategory {
  SHOVEL_CONTROL = "SHOVEL_CONTROL",
  GLOBAL_PARAMETERS = "GLOBAL_PARAMETERS",
  REASIGNATION_BEACONS = "REASIGNATION_BEACONS",
  VIRTUAL_REGION_ASSIGNATION = "VIRTUAL_REGION_ASSIGNATION",
  DESTINATION_CONTROL = "DESTINATION_CONTROL",
  SHOVEL_DESTINATION = "SHOVEL_DESTINATION",
}

export enum Setting {
  max_excav_rate = "max_excav_rate",
  priority = "priority",
  disabled = "disabled",
  coverage_ratio = "coverage_ratio",
  match_trucks = "match_trucks",
  global_productivity_objective = "global_productivity_objective",
  equal_feed_constraint = "equal_feed_constraint",
  mix_flow = "mix_flow",
  two_way_dump_lock = "two_way_dump_lock",
  equal_split = "equal_split",
  direct_ranking = "direct_ranking",
  assign_by_priority = "assign_by_priority",
  pos = "pos",
  virtual_region = "virtual_region",
  max_queue = "max_queue",
  feed_rate = "feed_rate",
  max_feed_rate = "max_feed_rate",
  cycle_time = "cycle_time",
}

export const modularChangeDescriptionText: Partial<Record<Setting, string>> = {
  [Setting.match_trucks]:
    "se permite palas no utilizadas en déficit de camiones",
  [Setting.global_productivity_objective]:
    "se utiliza objetivo global de productividad de camiones",
  [Setting.equal_feed_constraint]:
    "se comparte proporcionalmente capacidad de procesamiento de materiales entre palas",
  [Setting.mix_flow]:
    "se permite flujo de camiones entre circuitos de mineral y desecho",
  [Setting.equal_split]:
    "se divide equitativamente tasa de producción de pala entre sus splits",
  [Setting.direct_ranking]: "se considera clasificación directa de prioridades",
  [Setting.assign_by_priority]: "se asigna por prioridades",
};

export const modularChangeTranslationType: Record<
  ModularChangeSettingCategory,
  string
> = {
  [ModularChangeSettingCategory.GLOBAL_PARAMETERS]: "Parámetros globales",
  [ModularChangeSettingCategory.SHOVEL_CONTROL]: "Control de palas",
  [ModularChangeSettingCategory.REASIGNATION_BEACONS]:
    "Balizas de reasignación",
  [ModularChangeSettingCategory.VIRTUAL_REGION_ASSIGNATION]: "Región virtual",
  [ModularChangeSettingCategory.DESTINATION_CONTROL]: "Control en destino",
  [ModularChangeSettingCategory.SHOVEL_DESTINATION]: "Destino de la pala",
};
