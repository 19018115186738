import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const PerformanceIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_9761_478)">
      <path d="M17.8899 6.20021C17.9399 6.08021 17.7899 5.97021 17.6899 6.05021C16.5599 6.96021 15.4799 7.92021 14.4599 8.94021C13.8399 9.40021 13.3599 9.90021 13.0399 10.4702C12.1799 10.3702 11.2799 10.7002 10.7199 11.4502C9.89992 12.5602 10.1299 14.1302 11.2399 14.9602C11.6899 15.2902 12.2099 15.4502 12.7299 15.4502C13.4999 15.4502 14.2499 15.1002 14.7499 14.4402C15.2999 13.7002 15.3699 12.7502 15.0299 11.9602C15.5799 11.5002 15.7399 10.7502 16.0699 10.1302C16.7499 8.86021 17.3599 7.54021 17.8999 6.20021H17.8899Z" />
      <path d="M25.994 15.01L19.564 15.86L20.774 17.44L15.564 21.45L13.494 18.95C13.074 18.44 12.324 18.35 11.784 18.74L4.50401 24.13C3.95401 24.54 3.83401 25.32 4.24401 25.88C4.49401 26.21 4.86401 26.39 5.25401 26.39C5.51401 26.39 5.77401 26.31 5.99401 26.14L12.324 21.45L14.404 23.96C14.834 24.48 15.594 24.56 16.124 24.15L22.294 19.41L23.504 20.99L25.974 15L25.994 15.01Z" />
      <path d="M4.36997 22.3101L5.92997 21.1501C5.87997 21.1101 5.81997 21.0701 5.75997 21.0201C4.52997 19.9601 3.58997 18.6601 2.95997 17.1701C0.719968 11.8301 3.23997 5.67009 8.56997 3.42009C11.16 2.33009 14.01 2.32009 16.61 3.38009C19.21 4.44009 21.23 6.45009 22.32 9.03009C22.93 10.4701 23.18 11.9901 23.11 13.5401L25.11 13.2701C25.14 11.5401 24.84 9.86009 24.16 8.25009C22.87 5.17009 20.45 2.78009 17.36 1.52009C14.27 0.260093 10.87 0.270093 7.78997 1.57009C1.44997 4.24009 -1.54003 11.5801 1.12997 17.9401C1.84997 19.6601 2.92997 21.1501 4.30997 22.3801C4.33997 22.3601 4.34997 22.3301 4.37997 22.3001L4.36997 22.3101Z" />
    </g>
    <defs>
      <clipPath id="clip0_9761_478">
        <rect
          width="25.7"
          height="25.82"
          fill="white"
          transform="translate(0.149902 0.590088)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
  );
};
