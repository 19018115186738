import React, { createContext } from "react";
import {
  DataContextInterface,
  initialDataContextState,
  ErrorMsg,
} from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { AppRoute } from "utils";
import { RelayRecommendationCSV } from "interfaces/relieve.interface";
import { ContextProviderProps } from "contexts";
import { DATA_REFETCH_MINUTES } from "App";
export const RelieveRecommendationExportContext = createContext<
  DataContextInterface<RelayRecommendationCSV[]>
>(initialDataContextState);

export const RelieveRecommendationExportProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { refetch, ...other } = useData<RelayRecommendationCSV[]>(
    {
      config: {
        url: "/operator-assignment/relay/recommendations/export",
        method: "GET",
      },
      options: {
        useCache: false,
      },
      polling: {
        minutes: DATA_REFETCH_MINUTES,
        silent: true,
      },
    },
    ErrorMsg.GET_RECOMMENDATION_RELAY
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetch(), [AppRoute.RELIEVE]);

  return (
    <RelieveRecommendationExportContext.Provider value={{ ...other, refetch }}>
      {children}
    </RelieveRecommendationExportContext.Provider>
  );
};
