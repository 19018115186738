import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useContext,
} from "react";
import {
  IconButton,
  makeStyles,
  Theme,
  Paper,
  Divider,
  alpha,
  Typography,
} from "@material-ui/core";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@material-ui/icons";
import {
  ExcavatorBalance,
  ExcavatorEditableData,
  Can,
  Module,
  SpacingData,
  MetricGroupType,
} from "interfaces";
import { BalanceListCheckBoxCell } from "./BalanceTableCheckBoxCell";
import { BalanceTableRatingCell } from "./BalanceTableRatingCell";
import { BalanceTableTruckCell } from "./BalanceTableTruckCell";
import { BalanceTableTendencyCell } from "./BalanceTableTendencyCell";
import { BalanceListOriginAggregatedCell } from "./BalanceListOriginAggregatedCell";
import { BalanceListOriginCell } from "./BalanceListOriginCell";
import { BaseElement } from "components/List";
import { UserContext } from "contexts";
import clsx from "clsx";
import { MetricsIcon } from "components";
import { AppRoute, parseNumber, parseTimeDelta } from "utils";
import { useNavigate } from "react-router-dom";
import { BalanceListMovementCell } from "./BalanceListMovementCell";
import { useMinutesCounter } from "hooks";
import { BalanceListProjectedCell } from "./BalanceListProjectedCell";

interface BalanceListRowProps {
  excavator: ExcavatorBalance;
  spacing: SpacingData<ExcavatorBalance>;
  isCollapsible?: boolean;
  open?: boolean;
  handleOpen?: () => void;
  onChange?: (data: ExcavatorEditableData) => void;
}

export const BalanceListRow: React.FC<BalanceListRowProps> = ({
  excavator,
  isCollapsible = false,
  open,
  handleOpen,
  onChange,
  spacing,
}) => {
  const [excavEditableInfo, setExcavEditableInfo] =
    useState<ExcavatorEditableData>({
      excav: excavator.id,
      inclusion: excavator.inclusion ?? false,
      priority: excavator.priority ?? (isCollapsible ? 0 : 1),
    });

  const { isAllowedTo } = useContext(UserContext);

  const grayOutRow = useMemo(
    () => excavator.isCa || (!isCollapsible && !excavEditableInfo.inclusion),
    [excavEditableInfo.inclusion, excavator.isCa, isCollapsible]
  );
  const classes = useRowStyles({
    isCollapsible,
    open: open ?? true,
    grayOutRow,
  });

  const rowDisabled = useMemo(
    () =>
      isCollapsible ||
      excavator.isCa ||
      !isAllowedTo(Can.WRITE, Module.TRUCK_ASSIGNMENT),
    [excavator.isCa, isAllowedTo, isCollapsible]
  );

  const trucksColumn = useMemo(() => {
    const assignedTrucks =
      excavator.assignedTrucks !== null
        ? parseNumber(excavator.assignedTrucks)
        : "-";
    if (excavator.plannedTrucks !== null) {
      return `${assignedTrucks} (${parseNumber(excavator.plannedTrucks)})`;
    }
    return `${assignedTrucks}`;
  }, [excavator.assignedTrucks, excavator.plannedTrucks]);

  const [minutesSinceModularChange] = useMinutesCounter(
    excavator.lastModularChangeAt
  );

  useEffect(() => {
    setExcavEditableInfo({
      excav: excavator.id,
      inclusion: excavator.inclusion ?? false,
      priority: excavator.priority ?? (isCollapsible ? 0 : 1),
    });
  }, [excavator, isCollapsible]);

  const onInclusionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setExcavEditableInfo((prev) => ({
        ...prev,
        inclusion: e.target.checked,
      }));
      onChange?.({ ...excavEditableInfo, inclusion: e.target.checked });
    },
    [onChange, excavEditableInfo]
  );

  const onRatingChange = useCallback(
    (_: React.ChangeEvent<{}>, value: number | null) => {
      if (value) {
        setExcavEditableInfo((prev) => ({
          ...prev,
          priority: value,
        }));
        onChange?.({ ...excavEditableInfo, priority: value });
      }
    },
    [onChange, excavEditableInfo]
  );

  const navigate = useNavigate();

  const onNavigateToMetricsClick = useCallback(() => {
    if (!isCollapsible) {
      // Entity is a excav if the row is not Collapsible
      navigate(
        AppRoute.TA_METRICS_SCREEN +
          `?groupType=${MetricGroupType.EXCAV}&entityId=${excavator.id}`
      );
    } else {
      navigate(
        AppRoute.TA_METRICS_SCREEN +
          `?groupType=${
            excavator.virtualRegion
              ? MetricGroupType.VIRTUAL_REGION
              : MetricGroupType.PHASE
          }&entityId=${excavator.id}`
      );
    }
  }, [navigate, excavator, isCollapsible]);

  return (
    <Paper
      className={clsx(
        classes.row,
        isCollapsible ? classes.aggregatedRow : classes.excavRow
      )}
      elevation={0}
      onClick={isCollapsible ? handleOpen : undefined}
    >
      <BalanceListCheckBoxCell
        value={excavEditableInfo.inclusion}
        onChange={onInclusionChange}
        disabled={rowDisabled}
        indeterminate={excavator.indeterminate}
        spacing={spacing.inclusion}
      />
      <BalanceTableRatingCell
        value={excavEditableInfo.priority}
        id={excavator.id}
        onChange={onRatingChange}
        readOnly={rowDisabled || !excavEditableInfo.inclusion}
        spacing={spacing.priority}
      />
      <Divider orientation="vertical" classes={{ vertical: classes.divider }} />
      {isCollapsible ? (
        <BalanceListOriginAggregatedCell
          pit={excavator.pit}
          aggregatedBy={excavator.virtualRegion ?? excavator.phase}
          spacing={spacing.origin}
        />
      ) : (
        <BalanceListOriginCell
          excav={excavator.id}
          reasonId={excavator.reasonId}
          isCa={excavator.isCa}
          spacing={spacing.origin}
          grayOut={grayOutRow}
        />
      )}
      <BaseElement classNames={spacing.assigned}>
        <Typography variant="subtitle1">{trucksColumn}</Typography>
      </BaseElement>
      <BalanceTableTruckCell
        value={excavator.leftOver ?? 0}
        minutesSinceModularChange={minutesSinceModularChange}
        variant="h6"
        spacing={spacing.leftOver}
        grayOut={grayOutRow}
      />
      <BalanceTableTruckCell
        value={excavator.fallShort ?? 0}
        minutesSinceModularChange={minutesSinceModularChange}
        variant="h6"
        spacing={spacing.fallShort}
        grayOut={grayOutRow}
      />
      <BaseElement classNames={spacing.lastModularChangeAt}>
        <Typography variant="subtitle1">
          <b>
            {minutesSinceModularChange
              ? parseTimeDelta(minutesSinceModularChange)
              : "-'"}
          </b>
        </Typography>
      </BaseElement>
      <BalanceTableTendencyCell
        data={excavator.originQueue}
        spacing={spacing.originQueue}
        grayOut={grayOutRow}
      />
      <BalanceTableTendencyCell
        data={excavator.originHang}
        spacing={spacing.originHang}
        grayOut={grayOutRow}
      />
      <BalanceListMovementCell
        totalMovement={excavator.accLoadtons}
        targetMovement={excavator.accLoadtonsHour}
        movementPerClient={excavator.movementPerClient}
        spacing={spacing.plan}
        grayOut={grayOutRow}
        status={excavator.accLoadtonsStatus}
      />
      <BalanceListProjectedCell 
        projectedLoadtons={excavator.projectedLoadtons}
        plannedLoadtons={excavator.plannedLoadtons}
        spacing={spacing.projectedLoadtons}
        status={excavator.projectedLoadtonsStatus}
      />
      <BaseElement classNames={spacing.metrics}>
        <IconButton
          size="small"
          onClick={onNavigateToMetricsClick}
          className={classes.iconButton}
        >
          <MetricsIcon className={classes.metricIcon} />
        </IconButton>
      </BaseElement>
      <BaseElement classNames={spacing.collapse}>
        {isCollapsible ? (
          <IconButton
            size="small"
            onClick={handleOpen}
            className={classes.iconButton}
          >
            {open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
          </IconButton>
        ) : (
          ""
        )}
      </BaseElement>
    </Paper>
  );
};

interface RowStylesProps {
  isCollapsible: boolean;
  open: boolean;
  grayOutRow: boolean;
}

const useRowStyles = makeStyles<Theme, RowStylesProps>(({ palette }) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    minHeight: "48px",
  },
  excavRow: {
    backgroundColor: palette.collapsedRow.background,
    margin: "0px 16px",
    borderBottom: "1px solid",
    borderBottomColor:
      palette.type === "dark"
        ? palette.secondary.dark
        : alpha(palette.secondary.dark, 0.08),
    "&:last-child": {
      borderBottom: "0px",
      borderRadius: "0px 0px 4px 4px",
      marginBottom: "10px",
    },
  },
  aggregatedRow: {
    backgroundColor: palette.background.paper,
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: ({ open }) => (open ? "0px" : "10px"),
    borderRadius: ({ open }) => (open ? "4px 4px 0px 0px" : "4px"),
    "&:nth-child(2)": {
      marginTop: "10px",
    },
  },
  divider: {
    height: "75%",
  },
  iconButton: {
    backgroundColor: "#EEEEEE",
    color: "black",
  },
  metricIcon: {
    padding: "3px",
  },
}));
