import React from "react";
import { makeStyles, SvgIconProps, TextField } from "@material-ui/core";
import { Label } from "components";
import clsx from "clsx";

interface TruckDetailItemTextFieldClassesProps {
  root?: string;
}

interface TruckDetailItemTextFieldProps {
  label: string;
  icon: React.FC<SvgIconProps<"svg", {}>>;
  value: string | null;
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
  classes?: TruckDetailItemTextFieldClassesProps;
  children?: React.ReactNode;
}

export const TruckDetailItemTextField: React.FC<
  TruckDetailItemTextFieldProps
> = ({
  label,
  icon: Icon,
  value,
  onChange,
  classes: propClasses,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.truckTextFileItemRoot, propClasses?.root)}>
      <Label
        classes={{ text: classes.itemLabelText }}
        text={label}
        icon={Icon}
      />
      <TextField
        classes={{ root: classes.input }}
        multiline
        maxRows={2}
        value={value ?? ""}
        onChange={onChange}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    truckTextFileItemRoot: {
      display: "flex",
      width: "100%",
      marginTop: 6,
      marginBottom: 10,
      alignItems: "center",
      marginRight: 60,
      "&:last-child": {
        marginRight: 0,
      },
    },
    itemLabelText: {
      fontWeight: 700,
      width: 125,
    },
    itemText: {},
    input: {
      maxWidth: 650,
      width: "100%",
    },
  };
});
