import {
  TableCell,
  TableCellProps,
  makeStyles,
  Theme,
  Checkbox,
} from "@material-ui/core";
import React from "react";

export interface UnplannedParkedTruckCheckBoxCellProps {
  value: boolean;
  maxWidth: UseStylesProps["maxWidth"];
  align: TableCellProps["align"];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UnplannedParkedTruckCheckBoxCell: React.FC<
  UnplannedParkedTruckCheckBoxCellProps
> = ({ value, maxWidth, align, onChange }) => {
  const classes = useStyles({ maxWidth });
  return (
    <TableCell
      className={classes.cell}
      component="td"
      align={align}
      scope="row"
    >
      <Checkbox checked={value} onChange={onChange} color="primary" />
    </TableCell>
  );
};

interface UseStylesProps {
  maxWidth?: number;
}

const useStyles = makeStyles<Theme, UseStylesProps>(() => ({
  cell: {
    maxWidth: ({ maxWidth }) => maxWidth ?? "unset",
    paddingTop: 5,
    paddingBottom: 5,
  },
}));
