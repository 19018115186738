import React, { useState, useCallback, useMemo, useContext } from "react";
import { Typography, Switch, makeStyles, Paper } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ReportProblemOutlined, InfoOutlined } from "@material-ui/icons";
import { filter } from "lodash";
import { useSnackbar } from "notistack";

import { BalanceListCollapsibleRow } from "./BalanceListCollapsibleRow";
import {
  ExcavatorBalance,
  Group,
  Pit,
  ExcavatorEditableData,
  ListHeaderCellProps,
  AggregatedExcavator,
  GroupTranslation,
  PitTranslation,
  ErrorMsg,
  BalanceWarning,
  BalanceWarningMapping,
} from "interfaces";
import { CustomList, useSpacingStyles } from "components/List";
import { Tooltip } from "components";
import { headToSpacing } from "utils/customList";
import { useData, useDebouncedCallback } from "hooks";
import { ExcavatorContext } from "contexts";

type PendingChanges = Record<ExcavatorBalance["id"], ExcavatorEditableData>;

type Props = {
  excavators: AggregatedExcavator[];
  warning?: BalanceWarning;
};

type InfoTooltipText = {
  section: string;
  text: string;
};

export const BalanceList: React.FC<Props> = ({ excavators, warning }) => {
  // Hooks
  const classes = useStyles();
  const spacingClasses = useSpacingStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    groupType,
    setGroupType,
    refetch: refetchExcavators,
    firstLoading,
    refetching,
    polling,
  } = useContext(ExcavatorContext);

  const { refetching: updateExcavLoading, refetch: updateExcavData } = useData<
    unknown,
    ExcavatorEditableData[]
  >(
    {
      config: {
        url: "/truck-assignment/excavator",
        method: "PATCH",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.UPDATE_EXCAVATOR
  );

  const [pitFilter, setPitFilter] = useState<Record<Pit, boolean>>({
    [Pit.ESCONDIDA]: true,
    [Pit.NORTE]: true,
  });
  const [, setPendingExcavUpdates] = useState<PendingChanges>({});

  const filterData = useCallback(
    (data: AggregatedExcavator[]) => {
      const pits = Object.keys(pitFilter).filter(
        (key) => pitFilter[key as Pit]
      );
      return filter(data, (elem) => pits.includes(elem.pit));
    },
    [pitFilter]
  );

  const formattedData = useMemo(
    () => filterData(excavators),
    [excavators, filterData]
  );

  const loading = useMemo(
    () => firstLoading || refetching || polling || updateExcavLoading,
    [firstLoading, polling, refetching, updateExcavLoading]
  );

  const headerTotals = useMemo(
    () =>
      formattedData.reduce(
        (accumulator, currentValue) => ({
          assignedTrucks:
            accumulator.assignedTrucks + (currentValue.assignedTrucks ?? 0),
          plannedTrucks:
            accumulator.plannedTrucks + (currentValue.plannedTrucks ?? 0),
          fallShort: accumulator.fallShort + (currentValue.fallShort ?? 0),
          leftOver: accumulator.leftOver + (currentValue.leftOver ?? 0),
        }),
        {
          assignedTrucks: 0,
          plannedTrucks: 0,
          fallShort: 0,
          leftOver: 0,
        }
      ),
    [formattedData]
  );

  const handleGroupChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newGroup: Group | null) => {
      if (newGroup !== null) {
        setGroupType(newGroup);
      }
    },
    [setGroupType]
  );

  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setPitFilter({ ...pitFilter, [event.target.name]: event.target.checked }),
    [pitFilter]
  );

  const saveChanges = useDebouncedCallback(async (changes: PendingChanges) => {
    await updateExcavData({
      data: Object.values(changes),
    });
    enqueueSnackbar("Cambios guardados exitosamente", { variant: "success" });
    setPendingExcavUpdates({});
    refetchExcavators();
  }, 2000);

  const handleChange = useCallback(
    (data: ExcavatorEditableData) => {
      setPendingExcavUpdates((prevState) => {
        const newState = {
          ...prevState,
          [data.excav]: {
            ...prevState[data.excav],
            ...data,
          },
        };
        saveChanges(newState);
        return newState;
      });
    },
    [saveChanges]
  );

  const headCells: ListHeaderCellProps<ExcavatorBalance>[] = useMemo(() => {
    const assignedTotal = headerTotals.plannedTrucks
      ? `${headerTotals.assignedTrucks} (${headerTotals.plannedTrucks})`
      : headerTotals.assignedTrucks;
    return [
      {
        dataKey: "inclusion",
        label: "Incluir",
        classNames: [spacingClasses.XS],
      },
      {
        dataKey: "priority",
        label: "Saturación",
        classNames: [spacingClasses.S],
      },
      {
        dataKey: "origin",
        label: "Origen",
        classNames: [spacingClasses.left, classes.originCell],
      },
      {
        dataKey: "assigned",
        label: `Asignados: ${assignedTotal}`,
        classNames: [spacingClasses.M, classes.assignedCell],
      },
      {
        dataKey: "leftOver",
        label: `Sobran: ${headerTotals.leftOver}`,
        classNames: [spacingClasses.M],
      },
      {
        dataKey: "fallShort",
        label: `Faltan: ${headerTotals.fallShort}`,
        classNames: [spacingClasses.M],
      },
      {
        dataKey: "lastModularChangeAt",
        label: "Últ. gestión hace",
        classNames: [spacingClasses.M],
      },
      {
        dataKey: "originQueue",
        label: "Cola origen",
        classNames: [spacingClasses.S],
      },
      {
        dataKey: "originHang",
        label: "Espera pala",
        classNames: [spacingClasses.S],
      },
      {
        dataKey: "plan",
        label: "Mov. hora [kt]",
        classNames: [spacingClasses.M],
      },
      {
        dataKey: "projectedLoadtons",
        label: "Proy. vs Plan [kt]",
        classNames: [spacingClasses.M],
      },
      {
        dataKey: "metrics",
        label: "Métricas",
        classNames: [spacingClasses.XS],
      },
      {
        dataKey: "collapse",
        label: "",
        classNames: [spacingClasses.XS],
      },
    ];
  }, [
    headerTotals.plannedTrucks,
    headerTotals.assignedTrucks,
    headerTotals.leftOver,
    headerTotals.fallShort,
    spacingClasses.XS,
    spacingClasses.M,
    spacingClasses.left,
    spacingClasses.S,
    classes.originCell,
    classes.assignedCell,
  ]);

  const spacingData = useMemo(() => headToSpacing(headCells), [headCells]);

  const infoTooltipTexts = useMemo<InfoTooltipText[]>(
    () => [
      {
        section: "Toneladas",
        text: "Secas y en origen. Excluye movimiento de desarrollo y sobrecargas",
      },
      {
        section: "Valores entre paréntesis",
        text: "Corresponden al Plan Diario",
      },
      {
        section: "Utilización",
        text: "Exclusivamente camiones UltraClass",
      },
      {
        section: "Proyección",
        text: "Se actualiza cada 60 minutos entre las 12:30-19:30 y 00:30-07:30",
      },
      {
        section: "Asignación",
        text: `Se consideran los códigos asociados a producción, detención de procesos programados y no programados 
        para la estimación del conteo de camiones por pala. Consultar la vista Métricas para el detalle de los códigos 
        considerados`,
      },
    ],
    []
  );

  const rowRenderer = useCallback(
    (entities: AggregatedExcavator[]) =>
      entities.map((entity) => (
        <BalanceListCollapsibleRow
          data={entity}
          onChange={handleChange}
          spacing={spacingData}
          key={`collapsible-${entity.phase}-${entity.virtualRegion}`}
        />
      )),
    [handleChange, spacingData]
  );

  return (
    <>
      <div className={classes.contentHeader}>
        <Typography className={classes.sectionTitle} variant="h6">
          <strong>Asignación</strong>
        </Typography>
        <Tooltip
          title={infoTooltipTexts.map(({ section, text }) => (
            <Typography key={text} variant="body1">
              <strong>{`${section}: `}</strong>
              {`${text}.`}
            </Typography>
          ))}
          arrow
          placement="right"
          maxWidth="920px"
        >
          <InfoOutlined className={classes.infoIcon} />
        </Tooltip>
        <ToggleButtonGroup
          value={groupType}
          exclusive
          onChange={handleGroupChange}
          classes={{ root: classes.buttonGroup }}
        >
          {Object.values(Group).map((group) => (
            <ToggleButton
              value={group}
              classes={{
                selected: classes.buttonSelected,
              }}
              key={group}
            >
              {GroupTranslation[group]}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {Object.values(Pit).map((pit) => (
          <React.Fragment key={pit}>
            <Switch
              checked={pitFilter[pit]}
              onChange={handleFilterChange}
              name={pit}
              color="primary"
            />
            {PitTranslation[pit]}
          </React.Fragment>
        ))}
        {warning && (
          <Paper className={classes.warningPaper}>
            <ReportProblemOutlined className={classes.warningIcon} />
            {BalanceWarningMapping[warning]}
          </Paper>
        )}
      </div>
      <CustomList
        classes={{ header: classes.header }}
        data={formattedData}
        headers={headCells}
        loadingData={loading}
        listKey="balance-list"
        renderRows={rowRenderer}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette }) => {
  const buttonBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;
  return {
    originCell: {
      paddingLeft: 20,
      minWidth: 220,
    },
    assignedCell: {
      minWidth: 160,
    },
    header: {
      padding: "10px 16px",
    },
    contentHeader: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: 23,
      marginBottom: 23,
    },
    sectionTitle: {
      lineHeight: "24px",
      paddingRight: 10,
    },
    infoIcon: {
      lineHeight: "24px",
      marginRight: 10,
      width: 20,
      height: 20,
    },
    buttonGroup: {
      height: 40,
    },
    buttonSelected: {
      backgroundColor: `${buttonBackground} !important`,
      color: `${palette.getContrastText(buttonBackground)} !important`,
    },
    warningIcon: {
      fontSize: 20,
      marginInlineEnd: 8,
    },
    warningPaper: {
      display: "flex",
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      marginLeft: "auto",
      padding: 10,
    },
  };
});
