import React from "react";
import { Typography, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import {
  KpiType,
  KpiValue as KpiValueType,
  KpiParsers,
  CardAlertType,
} from "interfaces";
import { Tooltip } from "components";
import { getAlertColor } from "styles";

type Props = {
  type: KpiType;
  value: KpiValueType;
  secondary?: boolean;
  openMenu?: boolean
};

export const KpiValue: React.FC<Props> = ({
  type,
  value,
  secondary = false,
  openMenu = false
}) => {

  // Get screen size:
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.between(0, 1548));
  
  const classes = useStyles({ 
    alertType: value?.alertType,
    isOpenMenu: openMenu,
    smallScreen: isSmall, 
  });
  const parsers = KpiParsers[type];
  const valueParser = secondary ? parsers?.secondaryValue : parsers?.mainValue;

  return (
    <Tooltip
      title={
        value.tooltipTexts?.map((text) => (
          <Typography key={text} variant="body1">
            {text}
          </Typography>
        )) ?? ""
      }
      placement="bottom"
      arrow
    >
      <span>
        <Typography variant="h4" component="span" className={classes.value}>
          <b>{valueParser ? valueParser(value.value) : value.value}</b>
        </Typography>
        {value.compareValue ? (
          <Typography variant="h5" component="span" className={classes.compare}>
            <b>
              {`(${
                parsers?.secondaryValue?.(value.compareValue) ?? value.compareValue
              })`}
            </b>
          </Typography>
        ) : null}
      </span>
    </Tooltip>
  );
};

interface StyleProps {
  alertType?: CardAlertType;
  isOpenMenu: boolean;
  smallScreen: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return {
    value: {
      color: ({ alertType }) => getAlertColor(theme, alertType),
      fontSize: ({isOpenMenu, smallScreen}) => 
        isOpenMenu && smallScreen 
        ? "24px" 
        : smallScreen
        ? "26px" 
        : "32px"
    },
    compare: { 
      paddingLeft: 5,
      fontSize: ({isOpenMenu, smallScreen}) => 
        isOpenMenu && smallScreen 
        ? "20px" 
        : smallScreen
        ? "20px" 
        : "22px"
    }, 
  };
});
