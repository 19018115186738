import React, { useMemo, useCallback, useState, useContext } from "react";
import { RefetchFunctionType, useData, useDebouncedCallback } from "hooks";
import {
  UnplannedParkedTruck,
  UnplannedParkedEditableInfo,
  Order,
  Location,
  ErrorMsg,
  Excavator,
  UnplannedParkedParams,
} from "interfaces";
import {
  makeStyles,
  Theme,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { UnplannedParkedTruckTableHeader } from "./UnplannedParkedTruckTableHeader";
import { UnplannedParkedTruckTableRow } from "./UnplannedParkedTruckTableRow";
import { orderBy as _orderBy } from "lodash";
import { AutoSizer, SelectorOptions } from "components";
import { OperatorAssignmentKpisContext } from "contexts";
import { logError } from "services";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";

interface UnplannedParkedTruckTableProps {
  data: UnplannedParkedTruck[];
  refetch: RefetchFunctionType<UnplannedParkedTruck[]>;
  loadingData: boolean;
  isEditable: boolean;
}

type UnsavedChanges = Record<
  UnplannedParkedEditableInfo["id"],
  UnplannedParkedParams
>;

export const UnplannedParkedTruckTable: React.FC<
  UnplannedParkedTruckTableProps
> = ({ data, refetch, loadingData, isEditable }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { data: locations } = useData<Location[]>(
    {
      config: {
        url: "/operator-assignment/locations",
        method: "GET",
      },
    },
    ErrorMsg.GET_LOCATIONS
  );

  const { data: excavs } = useData<Excavator[]>(
    {
      config: {
        url: "/operator-assignment/excavs",
        method: "GET",
      },
    },
    ErrorMsg.GET_LOCATIONS
  );

  const locationOptions = useMemo<SelectorOptions[]>(
    () =>
      locations
        .map(({ id, name }) => ({
          id: `LOCATION_${id}`,
          name,
        }))
        .concat(
          excavs.map(({ id, name }) => ({
            id: `EXCAV_${id}`,
            name,
          }))
        ),
    [excavs, locations]
  );

  const { refetch: refetchKpis } = useContext(OperatorAssignmentKpisContext);

  const [order, setOrder] = useState<Order>(Order.ASC);
  const [orderBy, setOrderBy] = useState<keyof UnplannedParkedTruck>();

  const [, setUnsavedChanges] = useState<UnsavedChanges>({});

  const handleRequestSort = useCallback(
    (
      _event: React.MouseEvent<unknown>,
      property: keyof UnplannedParkedTruck
    ) => {
      setOrder((prevOrder) =>
        orderBy === property && prevOrder === Order.ASC ? Order.DESC : Order.ASC
      );
      setOrderBy(property);
    },
    [orderBy]
  );

  const sortedData = useMemo(() => {
    if (!orderBy) return data;

    const isObjectPath = ["finalLocation", "selectedLocation"].includes(
      orderBy
    );
    const secondarySortingPath = `${orderBy}${isObjectPath ? ".name" : ""}`;

    // Keep NULLs at the bottom
    return _orderBy(
      data,
      [
        (d) => {
          const attribute = d[orderBy];
          const value = isObjectPath
            ? (attribute as Location)?.["name"] ?? null
            : attribute;
          return value === null;
        },
        secondarySortingPath,
      ],
      [Order.ASC, order]
    );
  }, [data, orderBy, order]);

  const {
    refetching: updateUplannedParkedLoading,
    refetch: updateUnplannedParkedTruck,
  } = useData<unknown, UnplannedParkedParams[]>(
    {
      config: {
        url: "/operator-assignment/lost-trucks",
        method: "PATCH",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.UPDATE_CAEXS
  );

  const saveChanges = useDebouncedCallback(async (changes: UnsavedChanges) => {
    try {
      await updateUnplannedParkedTruck({
        data: Object.values(changes),
      });
      enqueueSnackbar("Cambios guardados exitosamente", { variant: "success" });
      setUnsavedChanges({});
      await refetch();
      refetchKpis();
    } catch (e) {
      if (
        (e as AxiosError)?.code &&
        (e as AxiosError)?.code !== "ERR_CANCELED"
      ) {
        logError("UPDATE-UNPLANNED-PARKED-TRUCK-DETAILS", `Error: ${e}`);
      }
    }
  }, 800);

  const onChange = useCallback(
    (uplannedParkedTruck: UnplannedParkedEditableInfo) => {
      const [entity, id] = uplannedParkedTruck.locationId?.split("_") ?? [];

      setUnsavedChanges((prevState) => {
        const newState = {
          ...prevState,
          [uplannedParkedTruck.id]: {
            ...prevState[uplannedParkedTruck.id],
            ...uplannedParkedTruck,
            locationId: entity === "LOCATION" ? +id : undefined,
            locationTacticalAssetId: entity === "EXCAV" ? +id : undefined,
          },
        };
        saveChanges(newState);
        return newState;
      });
    },
    [saveChanges]
  );
  //TO-DO: Change table with virtualized table component
  return (
    <div className={classes.tableContainer}>
      <AutoSizer disableWidth>
        {({ height }) => (
          <TableContainer
            key="uplanned-parked-table-container"
            style={{ maxHeight: height }}
            component={Paper}
          >
            <Table
              key="uplanned-parked-table"
              className={classes.table}
              stickyHeader
              size="medium"
            >
              <UnplannedParkedTruckTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                loading={updateUplannedParkedLoading || loadingData}
              />
              <TableBody key="uplanned-parked-table-body">
                {sortedData.length > 0 ? (
                  sortedData.map((entry) => (
                    <UnplannedParkedTruckTableRow
                      key={entry.id}
                      entry={entry}
                      onChange={onChange}
                      locations={locationOptions}
                      isEditable={isEditable}
                    />
                  ))
                ) : (
                  <TableRow key="empty-row" className={classes.emptyRow} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AutoSizer>
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    flex: "1",
    paddingBottom: 24,
  },
  pulseAlertIcon: {
    width: 16,
    height: 16,
  },
  emptyRow: {
    height: 61,
  },
}));
