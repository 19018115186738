import React, { useState, useCallback } from "react";
import { BalanceListRow } from "./BalanceListRow";
import {
  AggregatedExcavator,
  ExcavatorBalance,
  ExcavatorEditableData,
  SpacingData,
} from "interfaces";
import { Collapse, makeStyles } from "@material-ui/core";

interface Props {
  data: AggregatedExcavator;
  onChange: (data: ExcavatorEditableData) => void;
  spacing: SpacingData<ExcavatorBalance>;
}

export const BalanceListCollapsibleRow: React.FC<Props> = ({
  data,
  onChange,
  spacing,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = useCallback(() => setOpen(!open), [open, setOpen]);

  return (
    <>
      <BalanceListRow
        excavator={data as ExcavatorBalance}
        key={`aggregated-${data.phase}-${data.virtualRegion}`}
        isCollapsible
        handleOpen={handleOpen}
        open={open}
        spacing={spacing}
      />
      <Collapse
        in={open}
        unmountOnExit
        timeout="auto"
        className={classes.collapseRoot}
      >
        {data.excavs?.map((excav) => (
          <BalanceListRow
            excavator={excav}
            key={excav.id}
            onChange={onChange}
            spacing={spacing}
          />
        ))}
      </Collapse>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  collapseRoot: {
    minHeight: "unset !important",
  },
}));
