import { addHours, subDays } from "date-fns";

export const graphXValues = (hourStep = 2) => {
  let now = new Date();
  if (now.getHours() < 8) {
    now = subDays(now, 1);
  }
  now.setHours(8);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  const allDates: Date[] = [];
  const iterTimes = 24 / hourStep;
  for (let i = 0; i <= iterTimes; i++) {
    allDates.push(now);
    now = addHours(now, hourStep);
  }
  return allDates;
};
