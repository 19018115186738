import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import clsx from "clsx";

interface SearchBarClasses {
  searchBarRoot?: string;
  searchBarPaper?: string;
  input?: string;
  clearAdornment?: string;
  searchAdornment?: string;
}
interface SearchBarProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  clearOnBlur?: boolean;
  disabled?: boolean;
  classes?: SearchBarClasses;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  classes: propClasses,
  value: externalValue,
  onChange: onChangeExternal,
  placeholder,
  clearOnBlur,
  disabled,
}) => {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");
  const value = externalValue ?? searchValue;

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if ([event.code, event.key].includes("Enter")) {
        event.preventDefault();
      }
    },
    []
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      onChangeExternal(event.target.value);
    },
    [setSearchValue, onChangeExternal]
  );

  const onClear = useCallback(() => {
    setSearchValue("");
    onChangeExternal("");
  }, [setSearchValue, onChangeExternal]);

  const onClearDelayed = useCallback(() => setTimeout(onClear, 200), [onClear]);

  return (
    <div className={clsx(classes.searchBarRoot, propClasses?.searchBarRoot)}>
      <Paper
        component="form"
        className={clsx(classes.searchBarPaper, propClasses?.searchBarPaper)}
      >
        <InputBase
          className={clsx(classes.input, propClasses?.input)}
          placeholder={placeholder}
          inputProps={{ "aria-label": "search caex" }}
          value={value}
          onChange={onChange}
          onBlur={clearOnBlur ? onClearDelayed : undefined}
          disabled={disabled}
          onKeyDown={onKeyDown}
          endAdornment={
            value ? (
              <InputAdornment position="end">
                <IconButton aria-label="clear search input" onClick={onClear}>
                  <HighlightOffRoundedIcon
                    color="primary"
                    className={propClasses?.clearAdornment}
                  />
                </IconButton>
              </InputAdornment>
            ) : (
              <InputAdornment position="end">
                <IconButton disabled={true}>
                  <SearchIcon
                    color="primary"
                    className={propClasses?.searchAdornment}
                  />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme;
  return {
    searchBarPaper: {
      padding: "2px 4px",
      display: "flex",
      height: 37,
      maxWidth: 600,
      width: "100%",
      borderRadius: 24,
      background: palette.common.white,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      color: palette.common.black,
    },
    searchBarRoot: {
      display: "flex",
      width: "100%",
      margin: "auto",
      flexDirection: "column",
      alignItems: "center",
      padding: "0 15px",
      [breakpoints.down(600)]: {
        display: "none",
      },
    },
  };
});
