import React, { useCallback, useMemo } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Theme,
} from "@material-ui/core";
import {
  CardAlertType,
  ColumnGroupType,
  ColumnType,
  TruckSummary,
} from "interfaces";
import { TruckInfoCard, TruckTimeInfoCard, OperatorInfoCard } from ".";
import { getAlertCardColor } from "styles";
import { useMinutesCounter } from "hooks";

interface TruckCardProp {
  truck: TruckSummary;
  type: ColumnType;
  groupType: ColumnGroupType;
  onClick: (truck: TruckSummary, groupType: ColumnGroupType) => void;
}

export const TruckCard: React.FC<TruckCardProp> = ({
  truck,
  type,
  groupType,
  onClick,
}) => {
  const [sinceMinutes] = useMinutesCounter(truck.issueDate);
  const [remainingMinutes] = useMinutesCounter(
    truck.releaseProjectionTime,
    true
  );

  const alertType = useMemo<CardAlertType>(
    () => truck.alertType ?? CardAlertType.DEFAULT,
    [truck.alertType]
  );

  // Logic
  const handleOnCardClick = useCallback(
    () => onClick(truck, groupType),
    [onClick, truck, groupType]
  );

  const classes = useStyles({ alertType });

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={handleOnCardClick}>
        <CardContent className={classes.cardContent}>
          <div className={classes.truckCardInfoRoot}>
            <TruckInfoCard
              truck={truck}
              alertType={alertType}
              showReasonId={groupType === ColumnGroupType.MAINTENANCE}
            />
            <OperatorInfoCard truck={truck} alertType={alertType} />
          </div>
          <div className={classes.truckProyectionInfoRoot}>
            <TruckTimeInfoCard
              sinceTime={sinceMinutes}
              remainingTime={remainingMinutes}
              columnType={type}
              alertType={alertType}
              releaseTime={truck.releaseProjectionTime}
              groupType={groupType}
              projectionType={truck.releaseProjectionType}
            />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

interface StyleProps {
  alertType: CardAlertType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: {
    backgroundColor: ({ alertType }) => getAlertCardColor(theme, alertType),
    maxWidth: 361,
    marginLeft: 16,
    marginRight: 15,
    marginBottom: 15,
    [theme.breakpoints.down(1441)]: {
      marginLeft: 7,
      marginRight: 7,
    },
  },
  cardContent: {
    display: "flex",
    padding: 4,
  },
  truckCardInfoRoot: {
    maxWidth: 212,
  },
  truckProyectionInfoRoot: {
    width: "100%",
    position: "relative",
  },
}));
