import React from "react";
import ReactDOM from 'react-dom';
import { App } from "./App";
import { ErrorBoundary } from "react-error-boundary";
import { AppThemeProvider } from "contexts";
import { ErrorScreen } from "components";
import "./index.css";
import {Buffer} from 'buffer';
import 'stream-browserify';
import 'browserify-zlib';
import util from 'util';

// const container = document.getElementById("root");
// const root = createRoot(container!);
ReactDOM.render(
  <AppThemeProvider>
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <App />
    </ErrorBoundary>
  </AppThemeProvider>,
  document.getElementById("root")
);
