import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import Fuse from "fuse.js";
import { map } from "lodash";
import { useDebouncedCallback } from "./useDebouncedCallback";

interface Entity {
  id: unknown;
}

export type UpdateSearchValueType = (value: string) => void;

type Results<T> = [string, UpdateSearchValueType, () => void, T[], boolean];

export const useSearch = <T extends Entity>(
  data: T[] | null,
  options: Fuse.IFuseOptions<T> = {},
  debounceTime = 800
): Results<T> => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const queryRef = useRef("");
  const fuse = useMemo(() => new Fuse(data ?? [], options), [data, options]);

  //Handle searching of elements depending of the data type
  const search = useCallback(
    (newValue: string) => {
      if (!data) return;
      if (newValue.length < 3) {
        setSearchResults(data);
        return;
      }
      const results = fuse.search(newValue);
      setSearchResults(map(results, "item"));
    },
    [data, fuse]
  );

  //Setting debounce for searching elements
  const executeSearch = useDebouncedCallback((newValue: string) => {
    search(newValue);
    setIsTyping(false);
  }, debounceTime);

  //Updating stopTyping when searching values change
  const handleSetSearchValue = useCallback(
    (query: string) => {
      queryRef.current = query;
      setIsTyping(true);
      setSearchValue(query);
      executeSearch(query);
    },
    [executeSearch]
  );

  const handleClearSearch = useCallback(() => {
    setSearchValue("");
    search("");
  }, [search]);

  useEffect(() => {
    if (!data) return;
    return setSearchResults((prevResults) =>
      queryRef.current
        ? data.filter((d) => prevResults.find((e) => e.id === d.id))
        : data
    );
  }, [data]);

  return [
    searchValue,
    handleSetSearchValue,
    handleClearSearch,
    searchResults,
    isTyping,
  ];
};
