import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Divider, makeStyles, Typography } from "@material-ui/core";
import { CustomDialog, CustomTable, Restricted } from "components";
import { Can, CellProps, ErrorMsg, Module, SicUpdatePayload } from "interfaces";
import { HeaderCellProps, NestedKeyOf, Sic, MineMovements } from "interfaces";
import { useData } from "hooks";
import { formatToDayAndHours, truncateWithDecimals } from "utils";
import { SicKpis } from "./SicKpis";
import { SicRegistryInput } from "./sicRegistryInput";
import { DATA_REFETCH_MINUTES } from "App";
import { ButtonGroupComponent } from "components/ButtonGroup";
import { CheckCircle } from "@material-ui/icons";
import { DoNotDisturbonRounded } from "components/icons/DoNotDisturbonRounded";
import { useSnackbar } from "notistack";

interface Props {
  open: boolean;
  onClose: () => void;
}

interface SelectedValue {
  id: number,
  status: string,
}

interface NewSelectedValue {
  id: number,
  currentStatus?: string,
  previousStatus?: string
}

export const SicRegistryDialog: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [followUp, setFollowUp] = useState<SelectedValue>();
  const { enqueueSnackbar } = useSnackbar();
  const prevFollowUp = useRef<SelectedValue>()

  const {
    data: entriesData,
    firstLoading,
    refetch,
  } = useData<Sic[]>(
    {
      config: "/truck-assignment/sic",
      polling: {
        minutes: DATA_REFETCH_MINUTES * 2,
        silent: true,
      },
    },
    ErrorMsg.GET_SIC
  );

  const { data: mineMovements } = useData<MineMovements[]>(
    {
      config: "/truck-assignment/balance/movements_per_origin",
      polling: {
        minutes: DATA_REFETCH_MINUTES * 2,
        silent: true,
      },
    },
    ErrorMsg.GET_MOVEMENTS_PER_CLIENT
  );
    
  const { refetch: updateSicRegistry, refetching: loadingUpdate } = useData<SicUpdatePayload>(
    {
      config:{ 
        url: `/truck-assignment/sic`,
        method: "PUT"
      },
      options: {
        manual: true
      },
    },
    ErrorMsg.UPDATE_SIC
  );

  const headerCells = useMemo<HeaderCellProps<NestedKeyOf<Sic>>[]>(
    () => [
      {
        dataKey: "createdAt",
        label: "Hora",
        align: "left",
        sortable: true,
        className: classes.headerCell,
      },
      {
        dataKey: "targetEntity",
        label: "Cliente/Pala",
        align: "left",
        sortable: false,
        className: classes.headerCell,
      },
      {
        dataKey: "risk",
        label: "Brecha / Riesgo",
        align: "left",
        sortable: false,
        className: classes.headerCell,
      },
      {
        dataKey: "action",
        label: "Acción",
        align: "left",
        sortable: false,
        className: classes.headerCell,
      },
      {
        dataKey: "followUp",
        label: "Seguimiento",
        align: "left",
        sortable: false,
        className: classes.headerCell,
      },
      {
        dataKey: "responsible",
        label: "Responsables",
        align: "left",
        sortable: true,
        className: classes.headerCell,
      },
    ],
    [classes.headerCell]
  );


  const handleUpdateSic = useCallback(async() => {
    if (followUp?.id && prevFollowUp.current !== followUp) {
      const payload: SicUpdatePayload = {
        follow_up: followUp?.status,
        id: followUp.id
      };

    await updateSicRegistry({data: payload});
    await refetch();
    enqueueSnackbar("Se actualizó exitosamente el registro SIC", {
      variant: "success",
    });
  }
  },[followUp, updateSicRegistry, enqueueSnackbar, refetch])
 
  useEffect(() => {
    const updateData = async() => {
      if(followUp?.id && followUp.status && prevFollowUp.current !== followUp){
        await handleUpdateSic();
      }
    }
    updateData();
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followUp])
  
  const onFollowUpChange = (item: NewSelectedValue) => {
    if(item.currentStatus === item.previousStatus) return;
    if(!item.id || !item.currentStatus) return;

    setFollowUp({id: item.id, status: item.currentStatus})
  }

  const rowDetails = useCallback(
    (datum: Sic): CellProps<NestedKeyOf<Sic>>[] => {
      const loadtons = truncateWithDecimals((datum.loadtons ?? 0) / 1000, 0);
      const target = truncateWithDecimals((datum.target ?? 0) / 1000, 0);

      const listOptions = [
        {id: "PENDING", text: "Pendiente", color: "primary", icon: undefined, changeOption: true },
        {id: "STARTED", text: "Iniciada", color: "info", icon: undefined, changeOption: true },
        {id: "RESOLVED", text: "Resuelta", color: "grey", icon: <CheckCircle className={classes.icon} />, changeOption: false },
        {id: "DISCARDED", text: "Descartada", color: "grey_disabled", icon: <DoNotDisturbonRounded className={classes.notDisturb} />, changeOption: false },
      ]
    
      return [
        {
          dataKey: "createdAt",
          value: (
            <Typography variant="subtitle1">
              {formatToDayAndHours(datum.createdAt)}
            </Typography>
          ),
          align: "center",
          className: classes.createdAt,
        },
        {
          dataKey: "targetEntity",
          value: (
            <div>
              <Typography variant="subtitle1">{datum.targetEntity}</Typography>
              {loadtons || target ? (
                <Typography variant="subtitle1">
                  {loadtons || "-"} {target ? `(${target}) ` : " "} kt
                </Typography>
              ) : (
                <></>
              )}
            </div>
          ),
          align: "left",
          className: classes.targetEntity,
        },
        {
          dataKey: "risk",
          value: <Typography variant="subtitle1">{datum.risk}</Typography>,
          align: "left",
          className: classes.textAreaCells,
        },
        {
          dataKey: "action",
          value: <Typography variant="subtitle1">{datum.action}</Typography>,
          align: "left",
          className: classes.textAreaCells,
        },
        {
          dataKey: "followUpStatus",
          value: <ButtonGroupComponent 
            options={ listOptions }
            selectedValue={{id:datum.id, status:datum.followUpStatus}}
            onStateChange= { onFollowUpChange }
            restrictedTo={Module.TRUCK_ASSIGNMENT}
            />,
          align: "left",
        },
        {
          dataKey: "responsible",
          value: (
            <Typography variant="subtitle1">{datum.responsible}</Typography>
          ),
          align: "left",
          className: classes.textCells,
        },
      ];
    },
    [
      classes.createdAt,
      classes.targetEntity,
      classes.textAreaCells,
      classes.textCells,
      classes.icon,
      classes.notDisturb
    ]
  );

  return (
    <CustomDialog
      withCloseButton
      classes={{
        dialog: { paper: classes.dialogPaper },
      }}
      open={open}
      onClose={onClose}
      title={"Registros SIC"}
    >
      <SicKpis movements={mineMovements} />
      <Restricted to={Can.WRITE} at={Module.TRUCK_ASSIGNMENT}>
        <SicRegistryInput onSuccess={refetch} movements={mineMovements} />
      </Restricted>
      <Divider className={classes.divider} />
      <CustomTable
        data={entriesData}
        headers={headerCells}
        loadingData={firstLoading || loadingUpdate}
        tableKey={"sic"}
        renderCells={rowDetails}
      />
    </CustomDialog>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
  dialogPaper: {
    display: "flex",
    flexDirection: "column",
    minHeight: 800,
    maxHeight: 1627,
    justifyContent: "center",
    maxWidth: 1400,
  },
  timeInput: {
    maxWidth: 74,
    margin: "auto",
  },
  cell: {
    paddingTop: 5,
    paddingBottom: 5,
    width: 139,
    borderBottom: 0,
  },
  smallCell: {
    width: 77,
  },
  headerCell: {
    width: "100%",
  },
  targetEntity: {
    textAlign: "center",
  },
  textCells: {
    textAlign: "left",
    whiteSpace: "pre-line",
  },
  textAreaCells: {
    textAlign: "left",
    whiteSpace: "pre-line",
    width: 455,
  },
  createdAt: {
    width: "150px",
    marginLeft: 10,
    marginRight: 10,
  },
  divider: {
    alignSelf: "auto",
    "&:last-child": {
      width: 0,
    },
    marginBottom: 20,
    height: 1,
    width: "auto",
  },
  icon: {
    color: palette.success.main,
    marginRight: "15px",
    marginLeft: "-5px",
  },
  notDisturb: {
    marginRight: "5px",
    fill: palette.secondary.light
  }
}
});
