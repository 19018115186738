import React, { createContext } from "react";

import { ContextProviderProps } from "contexts";
import { AssignmentAlertDialog } from "components";

interface AlertContextInterface {}

export const AlertContext = createContext<AlertContextInterface>({});

export const AlertsProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  return (
    <AlertContext.Provider value={{}}>
      {children}
      <AssignmentAlertDialog />
    </AlertContext.Provider>
  );
};
