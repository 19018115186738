import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Header, ScreenContainer, SimplerHeader } from 'components';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { OperatorsTable } from './OperatorsTable';
import {StatusInterface } from 'contexts/ShifChangeContext';
import TopInfo from './TopInfo';
import { IShiftChangeOperators, OperatorsInfo } from 'contexts/ShiftChangeOperatorsContext';
import { ShiftChangePageState } from 'reducers/shiftChange';
import { useData } from 'hooks';
import { ErrorMsg } from 'interfaces';
import { ProgressBar } from './ProgressBar';
import { STANDARD_POLLING } from 'App';
import { AppRoute } from 'utils';
import { GreenTicketIcon } from 'components/icons/GreenTicket';
import { ShovelIcon } from 'components/icons/ShovelIcon';
import { NoDataScreen } from './NoDataScreen';

const DUMMY_OPERATORSDATA = {
  name: "",
  bus: "",
  ubication: "",
  equipType: ""
}
export const ShiftChangeOperatorsScreen:React.FC = () => {
    const classes = useStyles();
    const {data, firstLoading} = useData<StatusInterface>(
      {
          config: "/shift-change/visualization/status",
          options: {
              useCache: false,
            },
            ...STANDARD_POLLING,
      },
      ErrorMsg.GET_STATUS_SHIFT_CHANGE
  );
    const { shift, dateFormat, maxPage, pages } = data;
    const [page, setPage] = useState<number>(1);
    const [referencialPage, setReferencialPage] = useState<number | null>(1);
    const [type, setType] = useState<string | null>('PALAS');
    const [progressKey, setProgressKey] = useState<number>(0);

    const params = useMemo<ShiftChangePageState>(
      () => ({
        page: page,
        referencialPage: referencialPage,
        type: type
      }),
      [page, referencialPage, type]
    );
    let first_table:OperatorsInfo[] = [];
    let second_table: OperatorsInfo[] = [];
    useEffect( () => {
      if ( pages && pages.length === 0) return;
      const getPageDetails = (page: number) => {
        if (page <= 0 || !pages || pages.length === 0) {
        return { referencialPage: null, type: null };
        }
        const foundPage = pages.find((item) => item.page === page);
        return foundPage
        ? { referencialPage: foundPage.referencialPage, type: foundPage.type }
        : { referencialPage: null, type: null };
      }
      const intervalId = setInterval( () => {
        setPage((prevPage) => {
          const newIndex = (prevPage + 1) % pages.length;
          const finalIndex = newIndex  === 0 ? pages.length : newIndex;
          setType(getPageDetails(finalIndex).type);
          setReferencialPage(getPageDetails(finalIndex).referencialPage);
          first_table = [];
          second_table = [];
          setProgressKey(prevKey => prevKey + 1);
          return finalIndex
        } )
      }, 15000)
      return () => clearInterval(intervalId)
    }, [pages, page])

    const { data: dataOperators, firstLoading: loadingOperators} = useData<IShiftChangeOperators>(
      {
        config: {
          url: "/shift-change/visualization/operators",
          method: "GET",
          params: params,
        },
        options: {
          useCache: false,
        }
      },
      ErrorMsg.GET_STATUS_SHIFT_CHANGE
    );
    const {actualPage, count, operatorsData} = dataOperators;


    first_table = operatorsData && operatorsData.length >0 ? operatorsData.slice(0,13): [];
    let restOperatorsDataFirst: OperatorsInfo[] = []
    let restOperatorsData: OperatorsInfo[] =[]
    if (first_table.length < 13){ 
      restOperatorsDataFirst = Array(13 - first_table.length).fill(DUMMY_OPERATORSDATA);
      first_table = [...first_table, ...restOperatorsDataFirst]
    }
    second_table = operatorsData && operatorsData.length >=13 ?operatorsData.slice(13): [];
    if (second_table.length < 13){ 
      restOperatorsData = Array(13 - second_table.length).fill(DUMMY_OPERATORSDATA);
      second_table = [...second_table, ...restOperatorsData]
    }

  if (data && (data.status === 'NO-ACTIVE' || data.status === 'UPDATING')) {
      return (
        <>
          <SimplerHeader
            backRoute={'/'}
            logo={true}
          />
          <ScreenContainer>
            <NoDataScreen status={data.status} />
          </ScreenContainer>
        </>
      )
    }
  return (
    <>
        <SimplerHeader
          backRoute={'/'}
          logo={true}
        />
        <ScreenContainer>
          {!loadingOperators &&
          <ProgressBar key={progressKey} />
        }
          <TopInfo 
            shift={shift}
            dateFormat={dateFormat}
            maxPage={maxPage}
            pages={pages}
            page={page}
            status={data.status}
          />
          <Grid container className={ classes.chipsInfo}>
          <Grid item style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flex: '1 1 0',
          }}>
            <Grid container className={classes.iconContainers}>
              <ShovelIcon height={24} width={24} />
              <Typography style={{ fontSize: '16px', fontWeight: 'normal' }}>
                Palero
              </Typography>
              <Grid item className={classes.line} />
              <GreenTicketIcon height={24} width={24} />
              <Typography style={{ fontSize: '16px', fontWeight: 'normal' }}>
                Backup
              </Typography>
            </Grid>
          </Grid>
          </Grid>
          <Grid container className={classes.container}>
            {operatorsData && operatorsData.length > 0 ?
            <>
              <Box className={classes.operatorsBox}>
              <OperatorsTable dataOperators={first_table} type={type}/>
            </Box>
            <Box className={classes.operatorsBox}>
              <OperatorsTable dataOperators={second_table} type={type}/>
            </Box>
            </>
            : <></>}
          </Grid>
        </ScreenContainer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;
  const { type, background } = palette;
  const cellBackground =
    type === "light" ? palette.secondary.main : palette.common.white;
  return {
    container: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: 'row',
      width: '100%',
      marginTop: 14,
      paddingLeft: 34,
      paddingRight: 22,
    },
    operatorsBox: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: type === "light" ? background.paper : background.default,
      paddingBottom: 34,
    },
    iconContainers : {
      width: '254px',
      height: '40px',
      borderRadius: '6px',
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor:
      theme.palette.type === "light"
      ? theme.palette.background.default
      : theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row',
      gap: '12px',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    line: {
      width: '0px',
      height: '30px',
      border: '0.5px solid rgba(0,0,0,0.25)',
    },
    chipsInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
      alignItems: 'center',
      paddingLeft: 34,
      paddingRight: 22,
      marginTop: 13,
    },
  }
});