import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const UnplannedWaitTruckIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="49"
      viewBox="0 0 52 49"
      fill="none"
      {...props}
    >
      <path
        d="M36.5176 48.3821L51.8426 39.5173C52.0754 39.3827 52.0471 39.1472 51.7767 38.9925L50.392 38.1932C50.1229 38.0371 49.7152 38.021 49.4824 38.1555L34.1574 47.0203C33.9246 47.1549 33.9529 47.3904 34.2233 47.5451L35.6079 48.3444C35.8771 48.5005 36.2848 48.5167 36.5176 48.3821Z"
        fill="url(#paint0_linear_4613_146624)"
      />
      <path
        d="M35.0386 46.9046V47.1212C35.0386 47.3136 35.1664 47.5074 35.4194 47.6541C35.9293 47.9501 36.7596 47.9501 37.2736 47.6541C37.532 47.5047 37.6611 47.3096 37.6611 47.1131V46.8965C37.6611 47.0916 37.5333 47.2881 37.2736 47.4374C36.7596 47.7348 35.9293 47.7348 35.4194 47.4374C35.1664 47.2907 35.0386 47.097 35.0386 46.9046Z"
        fill="#404040"
      />
      <path
        d="M35.4274 46.3636C34.9134 46.661 34.9107 47.1414 35.4207 47.4374C35.9307 47.7334 36.7609 47.7334 37.2749 47.4374C37.7889 47.1414 37.7916 46.661 37.2803 46.3636C36.7703 46.0676 35.9401 46.0676 35.4274 46.3636Z"
        fill="#737373"
      />
      <path
        d="M35.9697 46.6758C35.7571 46.7983 35.7558 46.9961 35.967 47.1185C36.1769 47.241 36.5201 47.241 36.7313 47.1185C36.9426 46.9961 36.9439 46.7983 36.734 46.6758C36.5241 46.5534 36.181 46.5534 35.9697 46.6758Z"
        fill="#262626"
      />
      <path
        d="M35.04 46.9062V47.1229C35.04 47.3153 35.1678 47.5077 35.4208 47.6544C35.6764 47.8038 36.0142 47.8778 36.3506 47.8764V47.6598C36.0142 47.6598 35.6764 47.5871 35.4208 47.4378C35.1678 47.2911 35.04 47.0987 35.04 46.9062Z"
        fill="#262626"
      />
      <path
        d="M35.8672 36.5792V46.8784C35.8672 46.9498 35.9143 47.0197 36.0072 47.0749C36.1942 47.1839 36.4997 47.1839 36.6894 47.0749C36.7849 47.0197 36.832 46.9484 36.832 46.8758V36.5752C36.832 36.6465 36.7849 36.7192 36.6894 36.7743C36.4997 36.8833 36.1942 36.8833 36.0072 36.7743C35.9143 36.7205 35.8672 36.6492 35.8672 36.5779V36.5792Z"
        fill="#404040"
      />
      <path
        d="M35.8674 36.5801V46.8793C35.8674 46.9506 35.9131 47.0219 36.0073 47.0757C36.1015 47.1309 36.2253 47.1578 36.3491 47.1578V36.8573C36.2253 36.8573 36.1015 36.8304 36.0073 36.7765C35.9131 36.7214 35.8674 36.6514 35.8674 36.5801Z"
        fill="#262626"
      />
      <path
        d="M36.0094 36.3806C35.8197 36.4896 35.8197 36.6672 36.0067 36.7762C36.1937 36.8852 36.4992 36.8852 36.6889 36.7762C36.8773 36.6672 36.8787 36.4909 36.6916 36.3806C36.5032 36.2716 36.1991 36.2716 36.0094 36.3806Z"
        fill="#737373"
      />
      <path
        d="M35.998 35.9841V36.5116C35.998 36.5641 36.0317 36.6152 36.1003 36.6542C36.2375 36.7336 36.4609 36.7336 36.5995 36.6542C36.6695 36.6139 36.7031 36.5614 36.7031 36.5089V35.9814C36.7031 36.0339 36.6681 36.0864 36.5995 36.1268C36.4609 36.2062 36.2375 36.2062 36.1003 36.1268C36.0317 36.0877 35.998 36.0353 35.998 35.9841Z"
        fill="#404040"
      />
      <path
        d="M36.1016 35.8384C35.963 35.9178 35.963 36.0469 36.1003 36.1277C36.2375 36.2071 36.4609 36.2071 36.5995 36.1277C36.7381 36.0483 36.7381 35.9191 36.6008 35.8384C36.4636 35.7576 36.2402 35.759 36.103 35.8384H36.1016Z"
        fill="#737373"
      />
      <path
        d="M35.9982 35.9834V36.5122C35.9982 36.5634 36.0318 36.6158 36.1018 36.6549C36.1704 36.6952 36.2606 36.7154 36.3521 36.7154V36.1866C36.2606 36.1866 36.1717 36.1664 36.1018 36.1274C36.0331 36.0884 35.9982 36.0359 35.9982 35.9834Z"
        fill="#262626"
      />
      <path
        d="M48.5 39.1272V39.3439C48.5 39.5363 48.6278 39.73 48.8808 39.8767C49.3908 40.1727 50.221 40.1727 50.735 39.8767C50.9934 39.7274 51.1226 39.5322 51.1226 39.3358V39.1191C51.1226 39.3143 50.9934 39.5107 50.735 39.6601C50.221 39.9574 49.3908 39.9574 48.8808 39.6601C48.6278 39.5134 48.5 39.3196 48.5 39.1272Z"
        fill="#404040"
      />
      <path
        d="M48.8888 38.5853C48.3748 38.8827 48.3721 39.3631 48.8821 39.6591C49.3921 39.9551 50.2223 39.9551 50.7363 39.6591C51.2504 39.3631 51.253 38.8827 50.7417 38.5853C50.2317 38.2893 49.4015 38.2893 48.8888 38.5853Z"
        fill="#737373"
      />
      <path
        d="M49.4312 38.8975C49.2185 39.02 49.2172 39.2178 49.4285 39.3402C49.6384 39.4627 49.9815 39.4627 50.1928 39.3402C50.404 39.2178 50.4054 39.02 50.1955 38.8975C49.9855 38.7751 49.6424 38.7751 49.4312 38.8975Z"
        fill="#262626"
      />
      <path
        d="M48.5014 39.1279V39.3446C48.5014 39.537 48.6292 39.7294 48.8822 39.8761C49.1379 40.0254 49.4756 40.0995 49.812 40.0981V39.8815C49.4756 39.8815 49.1379 39.8088 48.8822 39.6594C48.6292 39.5128 48.5014 39.3204 48.5014 39.1279Z"
        fill="#262626"
      />
      <path
        d="M49.3287 28.8015V39.1007C49.3287 39.172 49.3758 39.242 49.4686 39.2972C49.6556 39.4062 49.9611 39.4062 50.1508 39.2972C50.2464 39.242 50.2935 39.1707 50.2935 39.098V28.7988C50.2935 28.8701 50.2464 28.9428 50.1508 28.998C49.9611 29.107 49.6556 29.107 49.4686 28.998C49.3758 28.9442 49.3287 28.8728 49.3287 28.8015Z"
        fill="#404040"
      />
      <path
        d="M49.3298 28.8018V39.101C49.3298 39.1723 49.3755 39.2436 49.4697 39.2974C49.5639 39.3526 49.6877 39.3795 49.8115 39.3795V29.079C49.6877 29.079 49.5639 29.052 49.4697 28.9982C49.3755 28.943 49.3298 28.8731 49.3298 28.8018Z"
        fill="#262626"
      />
      <path
        d="M49.4708 28.6023C49.2811 28.7112 49.2811 28.8889 49.4681 28.9979C49.6552 29.1069 49.9606 29.1069 50.1503 28.9979C50.3387 28.8889 50.3401 28.7126 50.153 28.6023C49.9647 28.4933 49.6605 28.4933 49.4708 28.6023Z"
        fill="#737373"
      />
      <path
        d="M49.4595 28.2058V28.7333C49.4595 28.7858 49.4931 28.8369 49.5617 28.8759C49.699 28.9553 49.9223 28.9553 50.0609 28.8759C50.1309 28.8356 50.1646 28.7831 50.1646 28.7306V28.2031C50.1646 28.2556 50.1296 28.3081 50.0609 28.3484C49.9223 28.4278 49.699 28.4278 49.5617 28.3484C49.4931 28.3094 49.4595 28.2569 49.4595 28.2058Z"
        fill="#404040"
      />
      <path
        d="M49.5631 28.0595C49.4245 28.1389 49.4245 28.2681 49.5617 28.3488C49.699 28.4282 49.9223 28.4282 50.0609 28.3488C50.1995 28.2695 50.1995 28.1403 50.0623 28.0595C49.925 27.9802 49.7016 27.9802 49.5644 28.0595H49.5631Z"
        fill="#737373"
      />
      <path
        d="M49.4596 28.2064V28.7352C49.4596 28.7864 49.4932 28.8389 49.5632 28.8779C49.6318 28.9182 49.722 28.9384 49.8135 28.9384V28.4083C49.722 28.4083 49.6332 28.3881 49.5632 28.3491C49.4946 28.31 49.4596 28.2576 49.4596 28.2051V28.2064Z"
        fill="#262626"
      />
      <path
        d="M35.483 38.7849L35.4911 41.4774C35.4911 41.5959 35.5328 41.6793 35.6001 41.7183L35.008 41.3738C34.9408 41.3348 34.899 41.2514 34.899 41.133L34.891 38.4404L35.483 38.7849Z"
        fill="#D9D9D9"
      />
      <path
        d="M35.2663 37.7921L50.8712 28.782C50.9762 28.7214 51.0704 28.7161 51.139 28.7564L51.7324 29.0996C51.6638 29.0592 51.5696 29.0659 51.4646 29.1251L35.8584 38.1352C35.6512 38.255 35.483 38.547 35.483 38.7852L34.8909 38.4407C34.8909 38.2025 35.0578 37.9105 35.2663 37.7908V37.7921Z"
        fill="#F2F2F2"
      />
      <path
        d="M35.8585 38.1353C35.6513 38.2551 35.4831 38.5471 35.4831 38.7853L35.4912 41.4778C35.4912 41.716 35.6607 41.8115 35.8693 41.6918L51.4742 32.683C51.6814 32.5632 51.8496 32.2726 51.8496 32.0344L51.8415 29.3419C51.8415 29.1037 51.672 29.0068 51.4634 29.1266L35.8585 38.1353Z"
        fill="#E6E6E6"
      />
      <path
        d="M49.1167 30.4815L51.8496 31.9455L51.8442 30.3066L50.5874 29.6338L49.1167 30.4815Z"
        fill="#E2832D"
      />
      <path
        d="M46.4592 32.0157L49.6604 33.7313L51.1284 32.8836L47.9272 31.168L46.4592 32.0157Z"
        fill="#E2832D"
      />
      <path
        d="M43.8 33.5509L47.0012 35.2651L48.4692 34.4174L45.2681 32.7031L43.8 33.5509Z"
        fill="#E2832D"
      />
      <path
        d="M41.1413 35.0864L44.3425 36.802L45.8105 35.9529L42.6107 34.2373L41.1413 35.0864Z"
        fill="#E2832D"
      />
      <path
        d="M38.4821 36.6202L41.6833 38.3372L43.1514 37.4881L39.9515 35.7725L38.4821 36.6202Z"
        fill="#E2832D"
      />
      <path
        d="M35.8263 38.1567L39.0247 39.871L40.4941 39.0233L37.293 37.3076L35.8586 38.1352C35.8478 38.1405 35.837 38.1486 35.8263 38.1553V38.1567Z"
        fill="#E2832D"
      />
      <path
        d="M35.4858 39.2988L35.4898 40.9351L36.3672 41.406L37.8325 40.5583L35.4858 39.2988Z"
        fill="#E2832D"
      />
      <path
        d="M48.5179 30.1395L49.1153 30.4826L50.5874 29.6335L49.9926 29.2891L48.5179 30.1395Z"
        fill="#F4D251"
      />
      <path
        d="M45.8602 31.675L46.4563 32.0168L47.9297 31.1664L47.3349 30.8232L45.8602 31.675Z"
        fill="#F4D251"
      />
      <path
        d="M43.2029 33.2088L43.799 33.5519L45.2725 32.7015L44.6777 32.3584L43.2029 33.2088Z"
        fill="#F4D251"
      />
      <path
        d="M40.5452 34.7443L41.14 35.0861L42.6147 34.2371L42.0186 33.8926L40.5452 34.7443Z"
        fill="#F4D251"
      />
      <path
        d="M37.8879 36.2785L38.484 36.6203L39.9561 35.7699L39.3626 35.4268L37.8879 36.2785Z"
        fill="#F4D251"
      />
      <path
        d="M35.23 37.8131L35.8275 38.1549C35.8382 38.1481 35.8477 38.1401 35.8584 38.1347L37.2969 37.3031L36.7021 36.96L35.2664 37.7902C35.2543 37.7969 35.2421 37.805 35.23 37.8131Z"
        fill="#F4D251"
      />
      <path
        d="M34.8923 38.96L34.8977 40.6002L35.4897 40.9407L35.4857 39.3017L34.8923 38.96Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7113 19.7314C9.38379 18.3887 7.48608 19.1959 7.48608 21.528C7.48608 23.86 9.38379 26.8567 11.7113 28.1994C14.0374 29.542 15.9352 28.7348 15.9352 26.4035C15.9352 24.0707 14.0374 21.074 11.7113 19.7314Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.32377 21.522C6.99627 20.1794 5.09857 20.9866 5.09857 23.3186C5.09857 25.6507 6.99627 28.6474 9.32377 29.99C11.6499 31.3327 13.5476 30.5255 13.5476 28.1941C13.5476 25.8614 11.6499 22.8647 9.32377 21.522Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.32417 22.9454C7.7789 22.0541 6.51862 22.5896 6.51862 24.1383C6.51862 25.6864 7.7789 27.6765 9.32417 28.5685C10.8688 29.4598 12.1291 28.9237 12.1291 27.3756C12.1291 25.8269 10.8688 23.8368 9.32417 22.9454Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.44132 23.1513C8.07722 22.364 6.96439 22.8372 6.96439 24.2043C6.96439 25.5715 8.07722 27.3283 9.44132 28.1149C10.8048 28.9022 11.9176 28.4291 11.9176 27.0619C11.9176 25.6954 10.8048 23.9379 9.44132 23.1513Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.44126 23.5085C8.27421 22.8352 7.32205 23.2401 7.32205 24.4098C7.32205 25.5795 8.27421 27.0831 9.44126 27.7564C10.6083 28.4297 11.5598 28.0248 11.5598 26.8552C11.5598 25.6855 10.6083 24.1825 9.44126 23.5085Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.44122 23.9394C8.50956 23.4019 7.74982 23.7247 7.74982 24.6584C7.74982 25.5915 8.50956 26.7917 9.44122 27.3291C10.3722 27.8659 11.132 27.5432 11.132 26.6101C11.132 25.6763 10.3722 24.4768 9.44122 23.9394Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.44119 24.1536C8.62722 23.6838 7.96402 23.9661 7.96402 24.7812C7.96402 25.5963 8.62722 26.6447 9.44119 27.1139C10.2545 27.5831 10.9177 27.3008 10.9177 26.4857C10.9177 25.6706 10.2545 24.6228 9.44119 24.1536Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1289 30.4934C12.6888 30.4737 13.6794 29.788 15.1007 28.4364L14.1787 25.6458L12.6956 28.5212C11.7579 29.8558 11.569 30.5132 12.1289 30.4934Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.92416 19.2788V19.3436L8.71453 20.9619L5.65097 21.5265C7.8331 19.8357 8.92416 19.0615 8.92416 19.2041C8.92416 19.3466 8.92416 19.3715 8.92416 19.2788Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.776 19.7314C34.4485 18.3887 32.5508 19.1959 32.5508 21.528C32.5508 23.86 34.4485 26.8567 36.776 28.1994C39.1022 29.542 40.9999 28.7348 40.9999 26.4035C40.9999 24.0707 39.1022 21.074 36.776 19.7314Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3889 21.522C32.0614 20.1794 30.1637 20.9866 30.1637 23.3186C30.1637 25.6507 32.0614 28.6474 34.3889 29.99C36.7151 31.3327 38.6128 30.5255 38.6128 28.1941C38.6128 25.8614 36.7151 22.8647 34.3889 21.522Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3887 22.9454C32.8434 22.0541 31.5831 22.5896 31.5831 24.1383C31.5831 25.6864 32.8434 27.6765 34.3887 28.5685C35.9333 29.4598 37.1935 28.9237 37.1935 27.3756C37.1935 25.8269 35.9333 23.8368 34.3887 22.9454Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5058 23.15C33.1417 22.3627 32.0289 22.8359 32.0289 24.203C32.0289 25.5702 33.1417 27.327 34.5058 28.1136C35.8692 28.9009 36.9821 28.4277 36.9821 27.0606C36.9821 25.6941 35.8692 23.9366 34.5058 23.15Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5058 23.5085C33.3387 22.8352 32.3866 23.2401 32.3866 24.4098C32.3866 25.5795 33.3387 27.0831 34.5058 27.7564C35.6728 28.4297 36.6243 28.0248 36.6243 26.8552C36.6243 25.6855 35.6728 24.1825 34.5058 23.5085Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5057 23.9394C33.574 23.4019 32.8143 23.7247 32.8143 24.6584C32.8143 25.5915 33.574 26.7917 34.5057 27.3291C35.4367 27.8659 36.1964 27.5432 36.1964 26.6101C36.1964 25.6763 35.4367 24.4768 34.5057 23.9394Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5057 24.155C33.6917 23.6851 33.0285 23.9674 33.0285 24.7826C33.0285 25.5977 33.6917 26.6461 34.5057 27.1153C35.319 27.5845 35.9822 27.3022 35.9822 26.487C35.9822 25.6719 35.319 24.6242 34.5057 24.155Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1937 30.4934C37.7535 30.4737 38.7441 29.788 40.1654 28.4364L39.2434 25.6458L37.7603 28.5212C36.8227 29.8558 36.6338 30.5132 37.1937 30.4934Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9889 19.2788V19.3436L33.7793 20.9619L30.7157 21.5265C32.8978 19.8357 33.9889 19.0615 33.9889 19.2041C33.9889 19.3466 33.9889 19.3715 33.9889 19.2788Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.9668 21.7172L37.8379 23.5224V26.3309L40.9668 24.5317V21.7172Z"
        fill="#EB922D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2268 19.5772L37.6889 23.4295L37.8377 23.517L40.9666 21.7178L40.8125 21.6263L40.7815 21.6084L34.3551 17.772L31.2268 19.5772Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5516 23.2286V23.8509C40.5516 23.9688 40.4882 24.0775 40.3863 24.1365L38.6255 25.1789C38.5356 25.2306 38.4238 25.165 38.4238 25.0609V24.9867L40.0035 24.0497C40.1046 23.9914 40.1688 23.882 40.1688 23.7647V23.2167L40.35 23.1126C40.4399 23.0609 40.5516 23.1265 40.5516 23.2286Z"
        fill="#121311"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1688 23.2173V23.7654C40.1688 23.8827 40.1046 23.992 40.0035 24.0504L38.4238 24.9874V24.4407C38.4238 24.3214 38.4873 24.2127 38.5891 24.1537L40.1688 23.2173Z"
        fill="#FFF3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4155 34.9734L21.2585 28.9515V21.5597L31.4155 27.5817V34.9734Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8947 23.2627L31.4157 27.5809V34.9726L38.8947 30.6816V23.2627Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.259 21.5571L31.414 27.5817L38.8944 23.288L28.7354 17.2396L21.259 21.5571Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.23 32.4229L26.3929 31.9412L25.8222 28.7841L27.23 29.6144V32.4229Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7068 20.1008L21.392 21.6509V28.6483L18.7068 27.1082V20.1008Z"
        fill="#502D1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8831 28.4407L19.0823 27.1425V22.7527L16.8831 24.051V28.4407Z"
        fill="#8D4528"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1978 22.5018L16.883 24.0519V28.4416L14.1978 26.9015V22.5018Z"
        fill="#6D3822"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0822 22.7526L17.1151 23.9144L16.883 24.0522L14.1978 22.5108L14.4385 22.3676L14.4867 22.3398L16.3983 21.2032L19.0822 22.7526Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4988 24.7494L9.32254 20.5134L0.486658 10.2641L0 7.59802L21.118 20.0747L16.4988 24.7494Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4949 20.2856L16.335 23.4825L9.97535 19.7276L2.25759 10.7785L2.12006 10.0217L19.4949 20.2856Z"
        fill="#874226"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1076 12.4774L21.118 20.0746L16.4987 24.7493L29.4884 17.1793L34.1076 12.4774Z"
        fill="#CA722E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00134277 7.59654L21.1174 20.0752L34.1077 12.5032L12.9884 0L0.00134277 7.59654Z"
        fill="#ECC530"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1171 12.496L25.8276 16.1634L21.1183 18.907L17.6099 16.8327L4.49859 9.08378L1.98926 7.60132L12.9847 1.17047L32.1171 12.496Z"
        fill="#E2832D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4707 3.83585L4.49859 9.08378L1.98926 7.60132L12.9847 1.17047L13.4707 3.83585Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8274 16.1633L21.1182 18.9068L17.6098 16.8326L22.3091 14.0857L25.8274 16.1633Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3078 14.0838L25.8275 16.1621L32.1177 12.496L12.9846 1.16913L13.4713 3.83451L22.3078 14.0838Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1488 20.096L20.0888 19.4638V17.6718L21.1488 18.304V20.096Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4296 25.0669L24.1174 20.1702L27.0585 17.4836L30.7184 19.644L32.4296 25.0669Z"
        fill="#212220"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.1857 17.0659L30.7185 19.6451L32.4297 25.068L36.8969 22.5047L35.1857 17.0659Z"
        fill="#434341"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0596 17.483L30.7181 19.6448L35.186 17.0801L31.5248 14.9038L27.0596 17.483Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0224 29.067L38.2875 25.4447L38.4369 25.5275L32.0224 32.8888V29.067Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0224 29.0669L32.1719 29.1497V32.9715L32.0224 32.8887V29.0669Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1719 29.1498L38.437 25.5275V29.2863L32.1719 32.9716V29.1498Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2319 25.8824V29.1681L37.3544 29.685L37.2215 29.7632L35.6167 30.7076L35.4838 30.7864L33.8751 31.7308L33.7455 31.8096L33.6192 31.8819L32.3761 32.6142V29.746L33.4863 29.1065L33.7455 28.9594V28.8222L33.4863 28.9693L32.3761 29.6095V29.2669L33.4863 28.6274L33.8751 28.4014L35.2273 27.6181L35.4838 27.4703L35.6167 27.3954L36.9656 26.6147L37.2215 26.4676L37.3544 26.3894L38.2319 25.8824Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2319 27.3092V27.4457L37.3544 27.9487L37.2215 28.0276L36.9656 28.1747L35.6167 28.9481L35.4838 29.0269L35.2273 29.174L33.8751 29.9507L33.7455 30.0263L33.4863 30.1734L32.3761 30.8136V30.6764L33.4863 30.0362L33.7455 29.8891L33.8751 29.8142L35.2273 29.0375L35.4838 28.8897L35.6167 28.8115L36.9656 28.0382L37.2215 27.8911L37.3544 27.8122L38.2319 27.3092Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7455 30.4308V30.5647L33.4863 30.7118L32.3761 31.3513V31.2181L33.4863 30.578L33.7455 30.4308Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2318 27.847V27.9836L37.3544 28.4866L37.2215 28.5654L36.9656 28.7125L35.6167 29.4859L35.4838 29.5648L35.2272 29.7119L33.875 30.4886L33.7454 30.5641V30.4303L33.875 30.3554L35.2272 29.5747L35.4838 29.4276L35.6167 29.3527L36.9656 28.5754L37.2215 28.4282L37.3544 28.3533L38.2318 27.847Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7455 30.9688V31.1053L33.4863 31.2531L32.3761 31.8926V31.756L33.4863 31.1159L33.7455 30.9688Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7455 31.5052V31.6418L33.4863 31.7889L32.3761 32.4291V32.2919L33.4863 31.6524L33.7455 31.5052Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2318 28.9254V29.0586L37.3544 29.5656L37.2215 29.6405L36.9656 29.7876L35.6167 30.5649L35.4838 30.6398L35.2272 30.7869L33.875 31.5676L33.7454 31.6425V31.506L33.875 31.4304L35.2272 30.6537L35.4838 30.5066L35.6167 30.4278L36.9656 29.6544L37.2215 29.5073L37.3544 29.4284L38.2318 28.9254Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.091 27.2558L38.2319 27.3101L37.3544 27.8131L37.2215 27.892L36.9656 28.0391L35.6167 28.8125L35.4838 28.8907L35.2273 29.0385L33.8751 29.8151L33.7455 29.89L33.4863 30.0372L32.3761 30.6773V30.5441L33.4863 29.9039L33.7455 29.7568L33.8751 29.6813L35.2273 28.9013L35.4838 28.7542L35.6167 28.6786L36.9656 27.9019L37.2215 27.7548L37.3544 27.6793L38.091 27.2558Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.091 27.7963L38.2319 27.8474L37.3544 28.3537L37.2215 28.4285L36.9656 28.5757L35.6167 29.353L35.4838 29.4279L35.2273 29.575L33.8751 30.3557L33.7455 30.4306L33.4863 30.5777L32.3761 31.2178V31.0807L33.4863 30.4412L33.7455 30.294L33.8751 30.2185L35.2273 29.4418L35.4838 29.2947L35.6167 29.2158L36.9656 28.4425L37.2215 28.2953L37.3544 28.2165L38.091 27.7963Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7455 28.8226V28.9598L33.4863 29.1069L32.3761 29.7464V29.6099L33.4863 28.9698L33.7455 28.8226Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2318 26.2388V26.3753L37.3544 26.8817L37.2215 26.9572L36.9656 27.1043L35.6167 27.881L35.4838 27.9566L35.2272 28.1037L33.875 28.8837L33.7454 28.9592V28.822L33.875 28.7472L35.2272 27.9665L35.4838 27.8194L35.6167 27.7445L36.9656 26.9671L37.2215 26.82L37.3544 26.7451L38.2318 26.2388Z"
        fill="#2D2E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2319 26.7794V26.9132L37.3544 27.4195L37.2215 27.4951L36.9656 27.6422L35.6167 28.4189L35.4838 28.4938L35.2273 28.6415L33.8751 29.4215L33.7455 29.4964L33.4863 29.6442L32.3761 30.2837V30.1472L33.4863 29.507L33.7455 29.3599L33.8751 29.2844L35.2273 28.5077L35.4838 28.3606L35.6167 28.2817L36.9656 27.5083L37.2215 27.3612L37.3544 27.2823L38.2319 26.7794Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.091 26.1881L38.2319 26.2391L37.3544 26.7454L37.2215 26.8203L36.9656 26.9674L35.6167 27.7448L35.4838 27.8197L35.2273 27.9668L33.8751 28.7475L33.7455 28.8223L33.4863 28.9695L32.3761 29.6096V29.4725L33.4863 28.8329L33.7455 28.6858L33.8751 28.6103L35.2273 27.8336L35.4838 27.6865L35.6167 27.6076L36.9656 26.8342L37.2215 26.6871L37.3544 26.6083L38.091 26.1881Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.091 26.7246L38.2319 26.7789L37.3544 27.2819L37.2215 27.3608L36.9656 27.5079L35.6167 28.2813L35.4838 28.3602L35.2273 28.5073L33.8751 29.284L33.7455 29.3595L33.4863 29.5066L32.3761 30.1468V30.0129L33.4863 29.3728L33.7455 29.2263L33.8751 29.1508L35.2273 28.3701L35.4838 28.223L35.6167 28.1481L36.9656 27.3714L37.2215 27.2243L37.3544 27.1487L38.091 26.7246Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.3544 26.3896V29.6853L37.2215 29.7635V26.4678L37.3544 26.3896Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 26.467V26.8196L36.9659 26.8335V26.6142L37.2218 26.467Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 26.9568V27.3604L36.9659 27.3744V27.104L37.2218 26.9568Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 27.4947V27.8916L36.9659 27.9049V27.6418L37.2218 27.4947Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 28.0286V28.4288L36.9659 28.4428V28.1757L37.2218 28.0286Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2318 28.3876V28.5214L37.3544 29.0277L37.2215 29.1033L36.9656 29.2504L35.6167 30.0271L35.4838 30.1026L35.2272 30.2498L33.875 31.0298L33.7454 31.1046V30.9681L33.875 30.8926L35.2272 30.1159L35.4838 29.9688L35.6167 29.8899L36.9656 29.1165L37.2215 28.9694L37.3544 28.8906L38.2318 28.3876Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 28.5664V28.97L36.9659 28.9839V28.7135L37.2218 28.5664Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 29.1029V29.5065L36.9659 29.5204V29.25L37.2218 29.1029Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 29.6395V29.7627L36.9659 29.9132V29.7866L37.2218 29.6395Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6167 27.3959V30.7081L35.4838 30.787V27.4708L35.6167 27.3959Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 27.4706V27.8199L35.2275 27.8338V27.6184L35.4841 27.4706Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 27.9565V28.3601L35.2275 28.374V28.1036L35.4841 27.9565Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 28.493V28.8899L35.2275 28.9039V28.6408L35.4841 28.493Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 29.0269V29.4271L35.2275 29.441V29.174L35.4841 29.0269Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 29.5647V29.9683L35.2275 29.9822V29.7118L35.4841 29.5647Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 30.1025V30.5061L35.2275 30.5194V30.2497L35.4841 30.1025Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 30.6391V30.7862L35.2275 30.9367V30.7862L35.4841 30.6391Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.875 28.4009V31.7303L33.7454 31.8091V28.4764L33.875 28.4009Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 28.477V28.8223L33.4865 28.8329V28.6274L33.7457 28.477Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 28.9588V29.3591L33.4865 29.3723V29.1059L33.7457 28.9588Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 29.4953V29.8889L33.4865 29.9029V29.6431L33.7457 29.4953Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 30.0265V30.4301L33.4865 30.444V30.1736L33.7457 30.0265Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 30.5643V30.9679L33.4865 30.9812V30.7114L33.7457 30.5643Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 31.1049V31.5058L33.4865 31.519V31.2526L33.7457 31.1049Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4865 31.7885L33.7457 31.6414V31.809L33.6194 31.8813H33.4865V31.7885Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0224 28.1195L38.2875 24.4971L38.4369 24.58L32.0224 31.7173V28.1195Z"
        fill="#20211F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0224 28.1193L32.1719 28.2022V31.8L32.0224 31.7171V28.1193Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1719 28.2022L38.437 24.5798V28.1147L32.1719 31.8V28.2022Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2319 24.9349V28.2199L37.3544 28.7368L37.2215 28.815L35.6167 29.76L35.4838 29.8382L35.2273 29.9893L33.8751 30.7832L33.7455 30.8614L33.6192 30.9336L32.3761 31.6659V28.7984L33.4863 28.1583L33.7455 28.0111V27.874L33.4863 28.0211L32.3761 28.6612V28.3193L33.4863 27.6791L33.7455 27.5287L33.8751 27.4531L35.2273 26.6698L35.4838 26.5227L35.6167 26.4472L36.9656 25.6672L37.2215 25.52L37.3544 25.4412L38.2319 24.9349Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2319 26.3629V26.5001L37.3544 27.0024L37.2215 27.0813L36.9656 27.2284L35.6167 28.0018L35.4838 28.0806L35.2273 28.2278L33.8751 29.0044L33.7455 29.08L33.4863 29.2271L32.3761 29.8673V29.7301L33.4863 29.0906L33.7455 28.9428L33.8751 28.8679L35.2273 28.0912L35.4838 27.9441L35.6167 27.8653L36.9656 27.0919L37.2215 26.9448L37.3544 26.8659L38.2319 26.3629Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7455 29.4833V29.6171L33.4863 29.7643L32.3761 30.4044V30.2706L33.4863 29.6304L33.7455 29.4833Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2318 26.8995V27.036L37.3544 27.539L37.2215 27.6178L36.9656 27.765L35.6167 28.5383L35.4838 28.6172L35.2272 28.7643L33.875 29.541L33.7454 29.6165V29.4827L33.875 29.4078L35.2272 28.6271L35.4838 28.48L35.6167 28.4051L36.9656 27.6278L37.2215 27.4813L37.3544 27.4058L38.2318 26.8995Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7455 30.0198V30.157L33.4863 30.3041L32.3761 30.9436V30.8071L33.4863 30.167L33.7455 30.0198Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7455 30.5577V30.6942L33.4863 30.8413L32.3761 31.4815V31.3443L33.4863 30.7048L33.7455 30.5577Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2318 27.9778V28.1117L37.3544 28.618L37.2215 28.6936L36.9656 28.8407L35.6167 29.6174L35.4838 29.6922L35.2272 29.8394L33.875 30.62L33.7454 30.6949V30.5584L33.875 30.4829L35.2272 29.7062L35.4838 29.559L35.6167 29.4802L36.9656 28.7068L37.2215 28.5597L37.3544 28.4808L38.2318 27.9778Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7455 27.8737V28.0109L33.4863 28.158L32.3761 28.7982V28.661L33.4863 28.0208L33.7455 27.8737Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2318 25.2912V25.4277L37.3544 25.934L37.2215 26.0096L36.9656 26.1567L35.6167 26.9334L35.4838 27.0089L35.2272 27.156L33.875 27.936L33.7454 28.0116V27.8744L33.875 27.7995L35.2272 27.0189L35.4838 26.8717L35.6167 26.7969L36.9656 26.0202L37.2215 25.873L37.3544 25.7975L38.2318 25.2912Z"
        fill="#2D2E2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2319 25.8317V25.9656L37.3544 26.4719L37.2215 26.5474L36.9656 26.6946L35.6167 27.4712L35.4838 27.5468L35.2273 27.6939L33.8751 28.4739L33.7455 28.5495L33.4863 28.6966L32.3761 29.3361V29.1996L33.4863 28.5594L33.7455 28.4123L33.8751 28.3367L35.2273 27.56L35.4838 27.4129L35.6167 27.3341L36.9656 26.5607L37.2215 26.4136L37.3544 26.3354L38.2319 25.8317Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.3544 25.442V28.7376L37.2215 28.8158V25.5209L37.3544 25.442Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 25.5208V25.8733L36.9659 25.8866V25.6679L37.2218 25.5208Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 26.0093V26.4129L36.9659 26.4268V26.1564L37.2218 26.0093Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 26.5471V26.9441L36.9659 26.9573V26.6942L37.2218 26.5471Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 27.0809V27.4819L36.9659 27.4951V27.2281L37.2218 27.0809Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2318 27.4399V27.5738L37.3544 28.0801L37.2215 28.1557L36.9656 28.3028L35.6167 29.0795L35.4838 29.155L35.2272 29.3021L33.875 30.0821L33.7454 30.1577V30.0205L33.875 29.9449L35.2272 29.1683L35.4838 29.0211L35.6167 28.9423L36.9656 28.1689L37.2215 28.0218L37.3544 27.9436L38.2318 27.4399Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 27.6175V28.0211L36.9659 28.035V27.7646L37.2218 27.6175Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 28.1553V28.5589L36.9659 28.5728V28.3025L37.2218 28.1553Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2218 28.6932V28.8158L36.9659 28.9669V28.8403L37.2218 28.6932Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6167 26.4484V29.7612L35.4838 29.8394V26.5239L35.6167 26.4484Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 26.5231V26.8717L35.2275 26.8856V26.6702L35.4841 26.5231Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 27.0089V27.4125L35.2275 27.4264V27.156L35.4841 27.0089Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 27.5468V27.9437L35.2275 27.957V27.6939L35.4841 27.5468Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 28.0793V28.4796L35.2275 28.4935V28.2264L35.4841 28.0793Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 28.6171V29.0207L35.2275 29.0346V28.7642L35.4841 28.6171Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 29.155V29.5586L35.2275 29.5725V29.3021L35.4841 29.155Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4841 29.6915V29.8386L35.2275 29.9897V29.8386L35.4841 29.6915Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.875 27.4533V30.7834L33.7454 30.8616V27.5289L33.875 27.4533Z"
        fill="#333431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 27.5294V27.8747L33.4865 27.8853V27.6799L33.7457 27.5294Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 28.0112V28.4114L33.4865 28.4254V28.1583L33.7457 28.0112Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 28.5491V28.9421L33.4865 28.956V28.6962L33.7457 28.5491Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 29.0789V29.4825L33.4865 29.4964V29.226L33.7457 29.0789Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 29.6168V30.0203L33.4865 30.0343V29.7639L33.7457 29.6168Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7457 30.1573V30.5576L33.4865 30.5708V30.3044L33.7457 30.1573Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4865 30.8409L33.7457 30.6938V30.8614L33.6194 30.9337H33.4865V30.8409Z"
        fill="#0B0C0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8131 35.8746L30.3568 35.6056V34.695L30.8131 34.9641V35.8746Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1027 29.6261L30.8132 34.9642V35.8747L40.1027 30.5386V29.6261Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3568 34.6946L30.8131 34.9636L40.1025 29.6276L40.0523 29.5977L40.0424 29.5924L39.6463 29.3565L30.3568 34.6946Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2616 20.7584L20.0888 17.6722V16.8577L25.2616 19.9433V20.7584Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4093 22.5919L4.59378 14.3976V13.5825L18.4093 21.7768V22.5919Z"
        fill="#A95C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2205 12.344L25.2619 19.9432V20.7583L38.2205 13.1591V12.344Z"
        fill="#EEA32D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0888 16.8569L25.2192 19.9185L25.2616 19.943L38.2201 12.3439L33.0474 9.25568L20.0888 16.8569Z"
        fill="#F4D251"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.81935 13.7479L18.1449 21.6513L18.3744 21.4186L5.09111 13.5398L4.74661 13.6631L4.81935 13.7479Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4374 27.4883C23.1099 26.1456 21.2122 26.9528 21.2122 29.2849C21.2122 31.6169 23.1099 34.6136 25.4374 35.9563C27.7635 37.2989 29.6612 36.4917 29.6612 34.1603C29.6612 31.8276 27.7635 28.8309 25.4374 27.4883Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0504 29.278C20.7229 27.9354 18.8252 28.7425 18.8252 31.0746C18.8252 33.4066 20.7229 36.4034 23.0504 37.746C25.3765 39.0886 27.2742 38.2815 27.2742 35.9501C27.2742 33.6174 25.3765 30.6206 23.0504 29.278Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0503 30.7003C21.5051 29.809 20.2448 30.3445 20.2448 31.8932C20.2448 33.4413 21.5051 35.4313 23.0503 36.3233C24.5949 37.2147 25.8552 36.6785 25.8552 35.1305C25.8552 33.5817 24.5949 31.5917 23.0503 30.7003Z"
        fill="#353633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1675 30.9062C21.8034 30.1189 20.6906 30.5921 20.6906 31.9592C20.6906 33.3264 21.8034 35.0832 23.1675 35.8698C24.5309 36.6571 25.6437 36.1839 25.6437 34.8168C25.6437 33.4503 24.5309 31.6928 23.1675 30.9062Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1674 31.2647C22.0004 30.5914 21.0482 30.9964 21.0482 32.166C21.0482 33.3357 22.0004 34.8394 23.1674 35.5127C24.3345 36.186 25.286 35.781 25.286 34.6114C25.286 33.4417 24.3345 31.9387 23.1674 31.2647Z"
        fill="#8E4428"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1674 31.6956C22.2357 31.1582 21.476 31.4809 21.476 32.4147C21.476 33.3477 22.2357 34.5479 23.1674 35.0853C24.0984 35.6221 24.8581 35.2994 24.8581 34.3663C24.8581 33.4326 24.0984 32.2331 23.1674 31.6956Z"
        fill="#A34D2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1674 31.9112C22.3534 31.4413 21.6902 31.7236 21.6902 32.5387C21.6902 33.3539 22.3534 34.4022 23.1674 34.8714C23.9807 35.3406 24.6439 35.0583 24.6439 34.2432C24.6439 33.4281 23.9807 32.3803 23.1674 31.9112Z"
        fill="#E59138"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.855 38.2503C26.4148 38.2306 27.4054 37.5449 28.8267 36.1933L27.9048 33.4026L26.4216 36.2781C25.484 37.6126 25.2951 38.27 25.855 38.2503Z"
        fill="#121312"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6502 27.0344V27.0992L22.4406 28.7175L19.377 29.2821C21.5592 27.5913 22.6502 26.8172 22.6502 26.9597C22.6502 27.1022 22.6502 27.1271 22.6502 27.0344Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6187 26.1673L27.0814 30.0189L27.2296 30.1071L30.3585 28.3072L30.2044 28.2157L30.174 28.1978L20.6187 25.6756V26.1673Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8527 26.7321L20.6185 26.1662L23.7468 24.361L17.8527 26.241V26.7321Z"
        fill="#D16F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6187 25.675L27.0814 29.5273L27.2296 29.6148L30.3585 27.8155L30.2044 27.7241L30.174 27.7062L23.7469 23.8698L20.6187 25.675Z"
        fill="#E9B237"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8527 26.2416L20.6185 25.675L23.7468 23.8698L20.9802 24.4364L17.8527 26.2416Z"
        fill="#A44C2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.358 27.8097L27.2298 29.6149V32.4234L30.358 30.6242V27.8097Z"
        fill="#EB922D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8247 29.3046V29.9276C29.8247 30.0449 29.7612 30.1542 29.6594 30.2125L27.8986 31.2549C27.8086 31.3066 27.6969 31.241 27.6969 31.1377V31.0634L29.2766 30.1264C29.3777 30.0674 29.4419 29.9587 29.4419 29.8407V29.2927L29.623 29.1893C29.713 29.137 29.8247 29.2032 29.8247 29.3046Z"
        fill="#121311"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4419 29.2924V29.8405C29.4419 29.9584 29.3777 30.0671 29.2766 30.1261L27.6969 31.0632V30.5164C27.6969 30.3971 27.7604 30.2885 27.8622 30.2295L29.4419 29.2924Z"
        fill="#FFF3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2301 32.4228L26.393 31.941L25.8224 28.7839L27.2301 29.6143V32.4228Z"
        fill="#D16F2D"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4613_146624"
          x1="49.5"
          y1="39.5"
          x2="37.5"
          y2="47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2F2F2" />
          <stop offset="1" stopColor="#F2F2F2" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
