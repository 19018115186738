import React from "react";
import { Typography } from "@material-ui/core";
import { Tooltip } from "components";
import { ColumnType, TruckSummary } from "interfaces";

interface Props {
  truck: TruckSummary;
  children: React.ReactElement;
}

export const LastOperatorTooltip: React.FC<Props> = ({ truck, children }) => {
  if (truck.column !== ColumnType.PENDING) return children;
  return (
    <Tooltip
      title={
        <Typography variant="body1">
          Último operador: {truck.lastOper?.name ?? "sin información"}
        </Typography>
      }
      placement="right"
      enterDelay={500}
      arrow
    >
      {children}
    </Tooltip>
  );
};
