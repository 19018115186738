import React, { useCallback, useState, useContext, useMemo } from "react";
import { makeStyles, Typography, Chip } from "@material-ui/core";
import {
  Header,
  OperatorAssignmentKpis,
  Fab,
  CustomSnackbar,
  ScreenContainer,
  Restricted,
  Tooltip,
} from "components";
import {
  UnplannedParkedTruck,
  Can,
  Module,
  ErrorMsg,
  LostTruckScreenInfo,
} from "interfaces";
import { useData, useSearch } from "hooks";
import { UnplannedParkedTruckTable } from "./UnplannedParkedTruckTable";
import { AddUnplannedParkedTruckDialog } from "./AddUnplannedParkedTruckDialog";
import AddIcon from "@material-ui/icons/Add";
import Fuse from "fuse.js";
import { UserContext, InfoSourceContext } from "contexts";
import { STANDARD_POLLING } from "App";

const filterOptions: Fuse.IFuseOptions<UnplannedParkedTruck> = {
  keys: ["id"],
  threshold: 0.1,
  minMatchCharLength: 3,
};

export const UnplannedParkedTrucksScreen: React.FC = () => {
  const classes = useStyles();
  const { isEquipParkingDataActive, firstLoadingSourceInfo } =
    useContext(InfoSourceContext);
  const { isAllowedTo } = useContext(UserContext);

  const {
    data: unplannedParkedTrucks,
    firstLoading: loadingTrucks,
    refetching: refetchingTrucks,
    refetch: refetchUnplannedParkedTrucks,
  } = useData<UnplannedParkedTruck[]>(
    {
      config: "/operator-assignment/lost-trucks",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_CAEXS
  );

  const { data: info } = useData<LostTruckScreenInfo>(
    {
      config: "/operator-assignment/lost-trucks/misc",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_GENERAL_INFO,
    {
      isEditable: false,
      editionHelperText: "",
      mailingHelperText: "",
    }
  );

  const [searchValue, setSearchValue, , searchResults] =
    useSearch<UnplannedParkedTruck>(
      unplannedParkedTrucks ?? null,
      filterOptions
    );

  const [openDialog, setOpenDialog] = useState(false);

  const handleOnDialogClose = useCallback(
    (e?: unknown, reason?: "backdropClick" | "escapeKeyDown") =>
      reason !== "backdropClick" && setOpenDialog(false),
    [setOpenDialog]
  );

  const handleOnDialogComplete = useCallback(
    () => refetchUnplannedParkedTrucks(),
    [refetchUnplannedParkedTrucks]
  );

  const isDataOutdated = useMemo(
    () => !firstLoadingSourceInfo && !isEquipParkingDataActive,
    [firstLoadingSourceInfo, isEquipParkingDataActive]
  );

  const potentiallyLostTrucks = useMemo(
    () => unplannedParkedTrucks.filter((truck) => truck.lost).length,
    [unplannedParkedTrucks]
  );

  return (
    <>
      <Header
        searchValue={searchValue}
        onSearchQueryChange={setSearchValue}
        searchPlaceholder="Buscar por CAEX"
      />
      <ScreenContainer>
        <OperatorAssignmentKpis />
        <div className={classes.headerSection}>
          <Typography variant="h6">
            <strong>Camión Perdido</strong>
          </Typography>
          <div className={classes.subtitleContainer}>
            <Typography className={classes.subtitle} variant="subtitle1">
              CAEX potencialmente perdidos
            </Typography>
            <Tooltip
              title={<Typography variant="body1">Sin gestionar</Typography>}
              placement="right"
              arrow
            >
              <Chip
                className={classes.countingChip}
                label={
                  <Typography color="textPrimary" variant="subtitle1">
                    <strong>{potentiallyLostTrucks}</strong>
                  </Typography>
                }
              />
            </Tooltip>
          </div>
        </div>
        <UnplannedParkedTruckTable
          data={searchResults}
          refetch={refetchUnplannedParkedTrucks}
          loadingData={loadingTrucks || refetchingTrucks}
          isEditable={info.isEditable}
        />
        <Restricted
          to={
            isAllowedTo(Can.WRITE, Module.OPERATOR_ASSIGNMENT) &&
            info.isEditable
          }
        >
          <Fab
            onClick={() => setOpenDialog(true)}
            icon={AddIcon}
            variant="circular"
            color="primary"
          />
        </Restricted>

        <Typography variant="subtitle1" color="textPrimary">
          {info.mailingHelperText}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          {info.editionHelperText}
        </Typography>
      </ScreenContainer>

      <AddUnplannedParkedTruckDialog
        open={openDialog}
        onClose={handleOnDialogClose}
        onComplete={handleOnDialogComplete}
        unplannedParkedTrucks={unplannedParkedTrucks ?? []}
      />
      {isDataOutdated && (
        <CustomSnackbar
          open={true}
          onClose={() => {}}
          data={{
            severity: "error",
            text: "No hay datos del turno anterior",
          }}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  infoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    paddingBottom: 24,
    paddingTop: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  infoIcon: {
    width: "4em",
    height: "4em",
    marginBottom: 15,
  },
  headerSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 23,
    marginBottom: 23,
    marginRight: 25,
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  subtitle: {
    marginRight: 16,
  },
  countingChip: {
    marginRight: 8,
    paddingRight: 4,
    paddingLeft: 4,
  },
}));
