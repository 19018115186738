import React from "react";
import { Theme, makeStyles, Typography, Divider } from "@material-ui/core";
import { BaseElement } from "components/List";
import { Pit, PitTranslation } from "interfaces";

interface Props {
  pit: Pit;
  aggregatedBy: string;
  spacing: string[];
}

export const BalanceListOriginAggregatedCell: React.FC<Props> = ({
  pit,
  aggregatedBy,
  spacing,
}) => {
  const classes = useStyles();
  return (
    <BaseElement classNames={spacing}>
      <Typography variant="subtitle1" component="span">
        {PitTranslation[pit]}
      </Typography>
      <Divider orientation="vertical" className={classes.divider} flexItem />
      <Typography variant="subtitle1" className={classes.bold} component="span">
        {aggregatedBy}
      </Typography>
    </BaseElement>
  );
};

const useStyles = makeStyles<Theme>(() => ({
  bold: {
    fontWeight: 700,
  },
  divider: {
    margin: "5px 5px",
  },
}));
