import React, { useMemo, useCallback, useState } from "react";
import { HeaderCellProps, Order, RowProps } from "interfaces";
import { makeStyles, Paper, Theme } from "@material-ui/core";
import { orderBy as _orderBy } from "lodash";
import { AutoSizer, Grid, ColumnSizer } from "components";
import clsx from "clsx";
import { VirtualizedCell, VirtualizedTableHeader } from ".";
import { GridCellProps, CellMeasurerCache } from "react-virtualized";

export interface RenderRows<T> extends GridCellProps {
  data: T[];
}

interface Props<T, K> {
  data: T[];
  headers: HeaderCellProps<K>[];
  renderCells: (entity: T) => RowProps<K>;
  loadingData: boolean;
  noDataRender?: JSX.Element;
  columnMaxWidth?: number;
  columnMinWidth?: number;
  rowHeight?: number;
  tableElevation?: number;
}

export const VirtualizedTable = <T extends unknown, K>({
  data,
  headers,
  loadingData,
  renderCells,
  rowHeight = 52,
  columnMaxWidth,
  columnMinWidth = 150,
  noDataRender,
  tableElevation = 1,
}: Props<T, K>) => {
  const classes = useStyles({ rowHeight, noData: data.length === 0 });

  const [order, setOrder] = useState<Order>(Order.ASC);
  const [orderBy, setOrderBy] = useState<K | string>();

  const handleRequestSort = useCallback(
    (_event: React.MouseEvent<unknown>, property: K | string) => {
      setOrder((prevOrder) =>
        orderBy === property && prevOrder === Order.ASC ? Order.DESC : Order.ASC
      );
      setOrderBy(property);
    },
    [orderBy]
  );

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: rowHeight,
        minHeight: rowHeight,
      }),
    [rowHeight]
  );

  const sortedData = useMemo(() => {
    if (!orderBy) return data;
    return _orderBy(data, orderBy, [order]) as T[];
  }, [data, orderBy, order]);
  const columnCount = useMemo(() => headers.length ?? 0, [headers]);

  const rows = useCallback(
    ({ style, key, rowIndex, data, columnIndex, parent }: RenderRows<T>) => {
      const row = renderCells(data[rowIndex]);
      return (
        <VirtualizedCell
          key={key}
          refKey={key}
          style={style}
          parent={parent}
          cache={cache}
          align={row.cellsData[columnIndex].align}
          value={row.cellsData[columnIndex].value}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          className={row.cellsData[columnIndex].className}
          onClick={row.onClick ?? row.cellsData[columnIndex].onClick}
        />
      );
    },
    [cache, renderCells]
  );

  return (
    <div className={classes.root}>
      <Paper elevation={tableElevation} className={clsx(classes.tablePaper)}>
        <VirtualizedTableHeader
          loading={loadingData}
          headCells={headers}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
        />
        {!loadingData && sortedData.length === 0 ? (
          noDataRender ? (
            noDataRender
          ) : (
            <div key="none-content-row" className={classes.noContent}></div>
          )
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <ColumnSizer
                columnMaxWidth={columnMaxWidth}
                columnMinWidth={columnMinWidth}
                columnCount={columnCount}
                width={width}
              >
                {({ adjustedWidth, getColumnWidth, registerChild }) => (
                  <Grid
                    ref={registerChild}
                    className={classes.grid}
                    cellRenderer={(props) =>
                      rows({ data: sortedData, ...props })
                    }
                    columnWidth={getColumnWidth}
                    deferredMeasurementCache={cache}
                    overscanRowCount={16}
                    columnCount={columnCount}
                    height={height - rowHeight - 10}
                    rowCount={data.length}
                    rowHeight={cache.rowHeight}
                    width={adjustedWidth}
                  />
                )}
              </ColumnSizer>
            )}
          </AutoSizer>
        )}
      </Paper>
    </div>
  );
};

interface UseStylesProps {
  rowHeight: number;
  noData?: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>(({ palette }) => ({
  root: {
    width: "100%",
  },
  tablePaper: {
    height: ({ noData, rowHeight }) => (noData ? rowHeight + 60 : "100%"),
    width: "100%",
  },
  grid: {
    overflow: "overlay !important",
  },
  notFoundText: {
    textAlign: "center",
    marginTop: 20,
  },
  noContent: {
    height: ({ rowHeight }) => rowHeight,
  },
}));
