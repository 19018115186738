import React, { useContext, useEffect, useMemo } from "react";
import { Navigate } from "react-router";
import { UserContext } from "contexts";
import { getFirstRoute } from "utils";

export const FirstNavigate: React.FC = () => {
  const { user, loadingUser, logoutWithError, fetchedToken } =
    useContext(UserContext);

  const redirectRoute = useMemo(() => getFirstRoute(user), [user]);

  useEffect(() => {
    if (fetchedToken && !loadingUser && !redirectRoute) {
      logoutWithError({
        TAG: "FirstNavigate",
        errorMsg: `User ${user?.email} without permissions at any module`,
        snackbarMsg: "Usted no tiene ningún permiso",
      });
    }
  }, [loadingUser, logoutWithError, redirectRoute, user, fetchedToken]);

  return fetchedToken && !loadingUser && redirectRoute ? (
    <Navigate to={redirectRoute} />
  ) : null;
};
