import React, { useCallback, useMemo } from "react";
import {
  makeStyles,
  TextField,
  Checkbox,
  Chip,
  useTheme,
  Theme,
  Paper,
} from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteGetTagProps,
  FilterOptionsState,
} from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import clsx from "clsx";
import { List, AutoSizer } from "components";
import Fuse from "fuse.js";
import { map } from "lodash";

export interface Entity {
  id: string | number;
  name?: string;
}

interface AutoCompleteClasses {
  tag?: string;
  root?: string;
  inputRoot?: string;
}

interface AutoCompleteProps<T> {
  placeHolder?: string;
  label?: string;
  value?: T[];
  onChange: (
    event: React.ChangeEvent<{}>,
    value: T[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T> | undefined
  ) => void;
  options: T[];
  classes?: AutoCompleteClasses;
  variant?: "filled" | "outlined" | "standard";
  loading?: boolean;
  loadingText?: string;
  noOptionsText?: string;
  fuzzySearch?: boolean;
  withAccumulatedTags?: boolean;
  limitTags?: number;
  size?: "small" | "medium";
  disabled?: boolean;
}

interface ListChildProps {
  key: React.Key;
  index: number;
  style: React.CSSProperties;
}

export const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode }
>((props, ref) => {
  const { children, ...otherProps } = props;
  const itemData = React.Children.toArray(children);
  const Row = useCallback(
    ({ key, index, style }: ListChildProps) => {
      return React.cloneElement(<div>{itemData[index]}</div>, {
        style,
        key,
      });
    },
    [itemData]
  );

  return (
    <div ref={ref} {...otherProps}>
      <AutoSizer disableHeight>
        {({ width }) => {
          return (
            <List
              width={width}
              height={40 * Math.min(7, itemData.length)}
              rowHeight={40}
              rowRenderer={Row}
              rowCount={itemData.length}
              overscanRowCount={15}
            />
          );
        }}
      </AutoSizer>
    </div>
  );
});

const fuzzyOptions: Fuse.IFuseOptions<Entity> = {
  keys: ["id", "name"],
  threshold: 0.3,
  minMatchCharLength: 1,
  useExtendedSearch: true,
};

export const AutoComplete = <T extends Entity>({
  placeHolder,
  label,
  value,
  onChange,
  options,
  loading = false,
  loadingText,
  noOptionsText,
  withAccumulatedTags = false,
  classes: propClasses,
  variant = "outlined",
  fuzzySearch,
  limitTags,
  size,
  disabled = false,
}: AutoCompleteProps<T>) => {
  const classes = useStyles({ disabled });
  const theme = useTheme();
  const fuseInstance = useMemo(
    () => new Fuse<T>(options, fuzzyOptions),
    [options]
  );

  const filterOptions = useCallback(
    (_options: T[], { inputValue }: FilterOptionsState<T>): T[] =>
      inputValue ? map(fuseInstance.search(inputValue), "item") : _options,
    [fuseInstance]
  );

  const selectedOptions = useCallback(
    (options: T[]) =>
      options.filter((o) => value?.find(({ id }) => id === o.id)),
    [value]
  );

  const renderTags = useCallback(
    (tags: T[], getTagProps: AutocompleteGetTagProps) => {
      const variant = theme.palette.type === "dark" ? "outlined" : "default";

      if (!withAccumulatedTags) {
        return tags.map(({ id, name }, index) => (
          <Chip
            label={name ?? id.toString()}
            variant={variant}
            {...getTagProps({ index })}
          />
        ));
      }

      if (tags.length === 1) {
        return (
          <Chip
            label={tags[0].name ?? tags[0].id.toString()}
            variant={variant}
            {...getTagProps({ index: 0 })}
          />
        );
      }

      return (
        <Chip
          variant={variant}
          label={`${tags.length} seleccionados`}
          onDelete={
            disabled
              ? undefined
              : () => onChange?.({} as React.ChangeEvent, [], "remove-option")
          } // Delete all selected options
        />
      );
    },
    [disabled, onChange, theme.palette.type, withAccumulatedTags]
  );

  const handleOnChange = useCallback(
    (
      event: React.ChangeEvent<{}>,
      value: T[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<T> | undefined
    ) => {
      if (!disabled) onChange(event, value, reason, details);
    },
    [disabled, onChange]
  );

  return (
    <Autocomplete
      classes={{
        root: clsx(classes.root, propClasses?.root),
        tag: clsx(classes.tag, propClasses?.tag),
        option: classes.option,
        inputRoot: propClasses?.inputRoot,
      }}
      size={size}
      PaperComponent={({children}) => (
        <Paper elevation={6} className={classes.colorList}>
          {children}
        </Paper>
      )}
      value={value}
      onChange={handleOnChange}
      multiple
      limitTags={limitTags}
      disableClearable={withAccumulatedTags}
      disableCloseOnSelect
      options={options}
      filterOptions={
        disabled ? selectedOptions : fuzzySearch ? filterOptions : undefined
      }
      ListboxComponent={ListboxComponent}
      getOptionLabel={(option) => option.name ?? option.id.toString()}
      loading={loading}
      getOptionSelected={(option, value) => option.id === value.id}
      loadingText={loadingText ?? "Cargando..."}
      noOptionsText={noOptionsText ?? "Sin opciones"}
      renderTags={renderTags}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
            color="primary"
          />
          {option.name ?? option.id}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={placeHolder}
        />
      )}
    />
  );
};

interface UseStylesProps {
  disabled: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>(({ palette }) => {
  const tagBorderColor =
    palette.type === "dark" ? palette.common.white : "unset";
  return {
    tag: {
      borderColor: tagBorderColor,
    },
    root: {
      width: 500,
      marginTop: 8,
    },
    option: {
      paddingTop: 0,
      paddingBottom: 0,
      cursor: ({ disabled }) => (disabled ? "unset !important" : "pointer"),
    },
    colorList: {
      // background: "#3C444E"
    }
  };
});
