import "regenerator-runtime/runtime.js";
import { useEffect, useRef } from "react";
import debounce from "lodash/debounce";

export const useDebouncedCallback = (
  callback: (...args: any[]) => any,
  debounceTime: number
) => {
  const executableCallback = useRef(callback);
  useEffect(() => {
    executableCallback.current = callback;
  });
  const debounceRef = useRef(
    debounce(
      (...args) => {
        executableCallback.current(...args);
      },
      debounceTime,
      { leading: false }
    )
  );
  return debounceRef.current;
};
