import React from "react";

interface LinesAcc {
  lines: string[];
  currLine: string;
}

export const getTspanGroups = (
  value: string,
  maxLineLength: number,
  maxLines: number = 2
): JSX.Element[] => {
  const words = value.split(" ");

  //reduces the words into lines of maxLineLength
  const assembleLines: LinesAcc = words.reduce(
    (acc: LinesAcc, word: string) => {
      //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== "") {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word,
        };
      }
      //otherwise add the word to the current line
      return {
        ...acc,
        currLine: acc.currLine + " " + word,
      };
    },
    { lines: [], currLine: "" }
  );

  //add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  //for now, only take first 2 lines due to tick spacing and possible overflow
  const lines = allLines.slice(0, maxLines);

  return lines.map((lineText, i) => (
    <tspan x={0} dy={i === 0 ? 0 : 20} key={i}>
      {lineText}
    </tspan>
  ));
};
