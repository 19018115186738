import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ChartIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4086 24.4224C29.7835 24.4224 30.0904 24.7506 30.0904 25.1518V26.6105C30.0904 27.0117 29.7835 27.3399 29.4086 27.3399H1.68181C1.30681 27.3399 1 27.0117 1 26.6105V4.72937C1 4.32821 1.30681 4 1.68181 4H3.04542C3.42041 4 3.72722 4.32821 3.72722 4.72937V24.4224H29.4086ZM9.1823 20.7774V16.4012C9.1823 16 8.87549 15.6718 8.50049 15.6718H7.13688C6.76189 15.6718 6.45508 16 6.45508 16.4012V20.7774C6.45508 21.1786 6.76189 21.5068 7.13688 21.5068H8.50049C8.87549 21.5068 9.1823 21.1786 9.1823 20.7774ZM14.6361 8.61937V20.7756C14.6361 21.1767 14.3293 21.5049 13.9543 21.5049H12.5907C12.2157 21.5049 11.9089 21.1767 11.9089 20.7756V8.61937C11.9089 8.21821 12.2157 7.88999 12.5907 7.88999H13.9543C14.3293 7.88999 14.6361 8.21821 14.6361 8.61937ZM20.0905 20.7768V12.5106C20.0905 12.1095 19.7837 11.7813 19.4087 11.7813H18.0451C17.6701 11.7813 17.3633 12.1095 17.3633 12.5106V20.7768C17.3633 21.178 17.6701 21.5062 18.0451 21.5062H19.4087C19.7837 21.5062 20.0905 21.178 20.0905 20.7768ZM25.545 6.67435V20.7755C25.545 21.1767 25.2382 21.5049 24.8632 21.5049H23.4996C23.1246 21.5049 22.8178 21.1767 22.8178 20.7755V6.67435C22.8178 6.2732 23.1246 5.94498 23.4996 5.94498H24.8632C25.2382 5.94498 25.545 6.2732 25.545 6.67435Z"
      />
    </SvgIcon>
  );
};
