import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const OneHrReleaseTruckIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="54"
      viewBox="0 0 44 54"
      fill="none"
      {...props}
    >
      <path
        d="M0.693243 13.9445V38.0574L1.3139 38.4161L1.5275 38.537L1.63229 38.5974L1.84589 38.7183L16.8223 47.3995L17.0359 47.5204L17.1407 47.5768L17.3543 47.7018L18.4626 48.3466V24.2256L18.3094 24.1329L17.3543 23.5767L17.1407 23.4558L17.0359 23.3994L16.8223 23.2785L1.84589 14.6135L1.63229 14.4885L1.5275 14.4241L1.3139 14.3031L0.693243 13.9445Z"
        fill="#50504F"
      />
      <path
        d="M17.1411 4.77942V47.5766L17.3547 47.7015V4.77942H17.1411Z"
        fill="#393E3E"
      />
      <path
        d="M16.8227 4.77942V47.3992L17.0363 47.5201V4.77942H16.8227Z"
        fill="#737373"
      />
      <path
        d="M15.2024 5.99298V46.4566L15.416 46.5775V6.0091L15.2024 5.99298Z"
        fill="#393E3E"
      />
      <path
        d="M14.884 5.9729V46.2753L15.0976 46.4003V5.98499L14.884 5.9729Z"
        fill="#737373"
      />
      <path
        d="M13.2638 7.20984V45.3319L13.4774 45.4568V7.23805L13.2638 7.20984Z"
        fill="#393E3E"
      />
      <path
        d="M12.9454 7.16144V45.1545L13.159 45.2754V7.18965L12.9454 7.16144Z"
        fill="#737373"
      />
      <path
        d="M11.3251 8.4234V44.2119L11.5387 44.3329V8.46773L11.3251 8.4234Z"
        fill="#393E3E"
      />
      <path
        d="M11.0067 8.35095V44.0307L11.2203 44.1516V8.39931L11.0067 8.35095Z"
        fill="#737373"
      />
      <path
        d="M9.38682 9.63635V43.0874L9.60043 43.2083V9.69681L9.38682 9.63635Z"
        fill="#393E3E"
      />
      <path
        d="M9.06843 9.54352V42.9059L9.28203 43.0308V9.60397L9.06843 9.54352Z"
        fill="#737373"
      />
      <path
        d="M7.44817 10.8491V41.9626L7.66177 42.0875V10.9257L7.44817 10.8491Z"
        fill="#393E3E"
      />
      <path
        d="M7.12977 10.7322V41.7852L7.34337 41.9062V10.8088L7.12977 10.7322Z"
        fill="#737373"
      />
      <path
        d="M5.50951 12.0617V40.8417L5.72311 40.9626V12.1544L5.50951 12.0617Z"
        fill="#393E3E"
      />
      <path
        d="M5.19111 11.9208V40.6604L5.40471 40.7854V12.0135L5.19111 11.9208Z"
        fill="#737373"
      />
      <path
        d="M3.57084 13.2756V39.718L3.78445 39.8429V13.3844L3.57084 13.2756Z"
        fill="#393E3E"
      />
      <path
        d="M3.25244 13.1103V39.5406L3.46604 39.6615V13.2191L3.25244 13.1103Z"
        fill="#737373"
      />
      <path
        d="M1.63267 14.4882V38.5971L1.84628 38.718V14.6131L1.63267 14.4882Z"
        fill="#393E3E"
      />
      <path
        d="M1.31427 14.3028V38.4157L1.52787 38.5366V14.4237L1.31427 14.3028Z"
        fill="#737373"
      />
      <path
        d="M18.4625 24.2258V48.3468L19.2646 47.8793L19.4822 47.7503L19.583 47.6939L19.8006 47.5609L40.0929 35.6637V11.5507L39.5327 11.8772L39.311 12.0061L39.2143 12.0666L38.9926 12.1956L19.8006 23.4399L19.583 23.5689L19.4822 23.6294L19.2646 23.7583L18.4625 24.2258Z"
        fill="#737373"
      />
      <path
        d="M19.583 4.1424V47.6932L19.8006 47.5602V4.1424H19.583Z"
        fill="#939393"
      />
      <path
        d="M19.2646 4.1424V47.8786L19.4822 47.7496V4.1424H19.2646Z"
        fill="#5C5C5C"
      />
      <path
        d="M21.5535 4.92866V46.5328L21.7752 46.4039V4.91656L21.5535 4.92866Z"
        fill="#939393"
      />
      <path
        d="M21.235 4.94867V46.7221L21.4566 46.5932V4.93658L21.235 4.94867Z"
        fill="#5C5C5C"
      />
      <path
        d="M23.5284 5.71479V45.3764L23.746 45.2474V5.69061L23.5284 5.71479Z"
        fill="#939393"
      />
      <path
        d="M23.21 5.75502V45.5657L23.4276 45.4327V5.72681L23.21 5.75502Z"
        fill="#5C5C5C"
      />
      <path
        d="M25.4993 6.50405V44.2191L25.721 44.0861V6.46375L25.4993 6.50405Z"
        fill="#939393"
      />
      <path
        d="M25.1809 6.56032V44.4043L25.4026 44.2753V6.52002L25.1809 6.56032Z"
        fill="#5C5C5C"
      />
      <path
        d="M27.474 7.29025V43.0627L27.6916 42.9297V7.23785L27.474 7.29025Z"
        fill="#939393"
      />
      <path
        d="M27.1555 7.36263V43.2479L27.3731 43.1189V7.31024L27.1555 7.36263Z"
        fill="#5C5C5C"
      />
      <path
        d="M29.4448 8.07645V41.9023L29.6664 41.7733V8.01196L29.4448 8.07645Z"
        fill="#939393"
      />
      <path
        d="M29.1264 8.16934V42.0919L29.348 41.9589V8.10486L29.1264 8.16934Z"
        fill="#5C5C5C"
      />
      <path
        d="M31.4197 8.86173V40.745L31.6414 40.612V8.78113L31.4197 8.86173Z"
        fill="#939393"
      />
      <path
        d="M31.1014 8.97471V40.9345L31.319 40.8015V8.8941L31.1014 8.97471Z"
        fill="#5C5C5C"
      />
      <path
        d="M33.3904 9.64793V39.5886L33.612 39.4556V9.55524L33.3904 9.64793Z"
        fill="#939393"
      />
      <path
        d="M33.0718 9.78099V39.774L33.2935 39.6451V9.68829L33.0718 9.78099Z"
        fill="#5C5C5C"
      />
      <path
        d="M35.3652 10.4341V38.4282L35.5868 38.2992V10.3293L35.3652 10.4341Z"
        fill="#939393"
      />
      <path
        d="M35.0468 10.5833V38.6177L35.2684 38.4847V10.4785L35.0468 10.5833Z"
        fill="#5C5C5C"
      />
      <path
        d="M37.3362 11.2194V37.2709L37.5578 37.1419V11.1025L37.3362 11.2194Z"
        fill="#939393"
      />
      <path
        d="M37.0177 11.3887V37.4603L37.2394 37.3273V11.2718L37.0177 11.3887Z"
        fill="#5C5C5C"
      />
      <path
        d="M39.3106 12.0064V36.1153L39.5323 35.9823V11.8774L39.3106 12.0064Z"
        fill="#939393"
      />
      <path
        d="M38.9922 12.1957V36.3006L39.2139 36.1716V12.0668L38.9922 12.1957Z"
        fill="#5C5C5C"
      />
      <path
        d="M18.3095 23.7621V24.5561L8.96454e-05 13.9606V13.1707L18.3095 23.7621Z"
        fill="#BDCACF"
      />
      <path
        d="M18.3094 23.7623L40.7901 10.5955L22.4807 0L0 13.1708L18.3094 23.7623Z"
        fill="#DFE1EB"
      />
      <path
        d="M1.68457 13.2514L22.6217 0.975281L39.1054 10.5149L18.1723 22.7869L1.68457 13.2514Z"
        fill="#D3D3D9"
      />
      <path
        d="M22.6217 1.71282L38.4686 10.8856L39.1054 10.5149L22.6217 0.975281L1.68457 13.2514L2.32538 13.6181L22.6217 1.71282Z"
        fill="#9FA7AB"
      />
      <path
        d="M18.3094 23.7623V24.5563L40.7901 11.3855V10.5955L18.3094 23.7623Z"
        fill="#9FA7AB"
      />
      <path
        d="M22.6217 0.975281V1.71282L38.4686 10.8856L39.1054 10.5149L22.6217 0.975281Z"
        fill="#9FA7AB"
      />
      <path
        d="M7.85905 24.8991V27.4221L4.65903 25.5802V23.0573L7.85905 24.8991Z"
        fill="#C7C7C7"
      />
      <path
        d="M5.13453 25.3061V23.8834L7.38341 25.1731V26.5958L5.13453 25.3061Z"
        fill="#484848"
      />
      <path
        d="M5.46501 25.1125L5.13453 25.306L7.38341 26.5957V26.2128L5.46501 25.1125Z"
        fill="#E6F5FF"
      />
      <path
        d="M5.13445 23.3351V25.8581L2.32537 24.2298V21.7109L5.13445 23.3351Z"
        fill="#C7C7C7"
      />
      <path
        d="M2.80099 23.9558V22.5331L4.65893 23.6092V25.0319L2.80099 23.9558Z"
        fill="#484848"
      />
      <path
        d="M3.13147 23.7662L2.80099 23.9556L4.65893 25.0317V24.6488L3.13147 23.7662Z"
        fill="#E6F5FF"
      />
      <path
        d="M12.4375 27.5388V30.0618L9.23751 28.22V25.701L12.4375 27.5388Z"
        fill="#C7C7C7"
      />
      <path
        d="M9.71301 27.9459V26.5233L11.9619 27.8129V29.2356L9.71301 27.9459Z"
        fill="#484848"
      />
      <path
        d="M10.0435 27.7563L9.71301 27.9457L11.9619 29.2354V28.8565L10.0435 27.7563Z"
        fill="#E6F5FF"
      />
      <path
        d="M9.71294 25.9748V28.4978L6.90789 26.8695V24.3506L9.71294 25.9748Z"
        fill="#C7C7C7"
      />
      <path
        d="M7.3834 26.5956V25.1729L9.2373 26.249V27.6717L7.3834 26.5956Z"
        fill="#484848"
      />
      <path
        d="M7.71387 26.406L7.3834 26.5954L9.2373 27.6715V27.2886L7.71387 26.406Z"
        fill="#E6F5FF"
      />
      <path
        d="M16.8544 30.0896V32.6125L13.6544 30.7707V28.2518L16.8544 30.0896Z"
        fill="#C7C7C7"
      />
      <path
        d="M14.1298 30.4965V29.0739L16.3787 30.3635V31.7862L14.1298 30.4965Z"
        fill="#484848"
      />
      <path
        d="M14.4603 30.3069L14.1298 30.4964L16.3787 31.786V31.4072L14.4603 30.3069Z"
        fill="#E6F5FF"
      />
      <path
        d="M14.1302 28.5265V31.0495L11.3211 29.4212V26.9023L14.1302 28.5265Z"
        fill="#C7C7C7"
      />
      <path
        d="M11.7966 29.1472V27.7245L13.6546 28.8006V30.2233L11.7966 29.1472Z"
        fill="#484848"
      />
      <path
        d="M12.1271 28.9576L11.7966 29.147L13.6546 30.2231V29.8402L12.1271 28.9576Z"
        fill="#E6F5FF"
      />
      <path
        d="M33.6164 9.69687L29.4169 8.97949V12.1432L33.6164 9.69687Z"
        fill="#9FA7AB"
      />
      <path
        d="M20.4092 3.78027L24.6128 4.49766L33.6163 9.69667L29.4168 8.97929L20.4092 3.78027Z"
        fill="#E7E3E3"
      />
      <path
        d="M21.4894 4.06641L21.0783 4.16716L28.7519 8.5964L29.167 8.49967L21.4894 4.06641Z"
        fill="#E7E3E3"
      />
      <path
        d="M20.4093 6.94401L29.4169 12.143V8.97929L20.4093 3.78027V6.94401Z"
        fill="#BDCACF"
      />
      <path
        d="M29.4166 12.1438L25.213 11.4265V14.5942L29.4166 12.1438Z"
        fill="#9FA7AB"
      />
      <path
        d="M16.2096 6.22705L20.4092 6.94443L29.4167 12.1434L25.2132 11.4261L16.2096 6.22705Z"
        fill="#E7E3E3"
      />
      <path
        d="M17.2856 6.51733L16.8786 6.61406L24.5481 11.0433L24.9632 10.9466L17.2856 6.51733Z"
        fill="#E7E3E3"
      />
      <path
        d="M16.2096 9.39482L25.2131 14.5938V11.4261L16.2096 6.22705V9.39482Z"
        fill="#BDCACF"
      />
      <path
        d="M25.2131 14.5946L21.0096 13.8772V17.0409L25.2131 14.5946Z"
        fill="#9FA7AB"
      />
      <path
        d="M12.006 8.67804L16.2096 9.39542L25.2131 14.5944L21.0096 13.877L12.006 8.67804Z"
        fill="#E7E3E3"
      />
      <path
        d="M13.0862 8.96417L12.6751 9.06493L20.3447 13.4901L20.7598 13.3974L13.0862 8.96417Z"
        fill="#E7E3E3"
      />
      <path
        d="M12.0059 11.8414L21.0095 17.0404V13.8766L12.0059 8.67761V11.8414Z"
        fill="#BDCACF"
      />
      <path
        d="M21.0099 17.0404L16.8104 16.3231V19.4868L21.0099 17.0404Z"
        fill="#9FA7AB"
      />
      <path
        d="M7.80279 11.1237L12.0063 11.841L21.0099 17.04L16.8104 16.3227L7.80279 11.1237Z"
        fill="#E7E3E3"
      />
      <path
        d="M8.88288 11.4139L8.4718 11.5107L16.1454 15.9399L16.5605 15.8432L8.88288 11.4139Z"
        fill="#E7E3E3"
      />
      <path
        d="M7.80283 14.2873L16.8104 19.4863V16.3225L7.80283 11.1235V14.2873Z"
        fill="#BDCACF"
      />
      <path
        d="M7.83456 22.8191V25.338L4.63455 23.5002V20.9773L7.83456 22.8191Z"
        fill="#C7C7C7"
      />
      <path
        d="M5.10984 23.2258V21.8032L7.35871 23.0928V24.5155L5.10984 23.2258Z"
        fill="#484848"
      />
      <path
        d="M5.44032 23.0323L5.10984 23.2258L7.35871 24.5155V24.1326L5.44032 23.0323Z"
        fill="#E6F5FF"
      />
      <path
        d="M5.11025 21.2559V23.7748L2.30117 22.1506V19.6277L5.11025 21.2559Z"
        fill="#C7C7C7"
      />
      <path
        d="M2.77667 21.8765V20.4538L4.63461 21.5299V22.9525L2.77667 21.8765Z"
        fill="#484848"
      />
      <path
        d="M3.10715 21.6829L2.77667 21.8764L4.63461 22.9524V22.5696L3.10715 21.6829Z"
        fill="#E6F5FF"
      />
      <path
        d="M7.83434 20.8689V23.3918L4.63433 21.55V19.0271L7.83434 20.8689Z"
        fill="#C7C7C7"
      />
      <path
        d="M5.10983 21.2759V19.8532L7.3587 21.1429V22.5656L5.10983 21.2759Z"
        fill="#484848"
      />
      <path
        d="M5.44031 21.0823L5.10983 21.2758L7.3587 22.5655V22.1826L5.44031 21.0823Z"
        fill="#E6F5FF"
      />
      <path
        d="M5.11021 19.3049V21.8278L2.30114 20.1996V17.6807L5.11021 19.3049Z"
        fill="#C7C7C7"
      />
      <path
        d="M2.77668 19.9255V18.5029L4.63462 19.5789V21.0016L2.77668 19.9255Z"
        fill="#484848"
      />
      <path
        d="M3.10716 19.736L2.77668 19.9254L4.63462 21.0015V20.6186L3.10716 19.736Z"
        fill="#E6F5FF"
      />
      <path
        d="M12.4174 25.459V27.9819L9.21336 26.1401V23.6172L12.4174 25.459Z"
        fill="#C7C7C7"
      />
      <path
        d="M9.68913 25.8659V24.4432L11.938 25.7329V27.1555L9.68913 25.8659Z"
        fill="#484848"
      />
      <path
        d="M10.0196 25.6723L9.68913 25.8658L11.938 27.1554V26.7726L10.0196 25.6723Z"
        fill="#E6F5FF"
      />
      <path
        d="M9.68913 23.8948V26.4137L6.88409 24.7895V22.2706L9.68913 23.8948Z"
        fill="#C7C7C7"
      />
      <path
        d="M7.35958 24.5155V23.0928L9.21349 24.1689V25.5916L7.35958 24.5155Z"
        fill="#484848"
      />
      <path
        d="M7.69006 24.3259L7.35958 24.5153L9.21349 25.5914V25.2085L7.69006 24.3259Z"
        fill="#E6F5FF"
      />
      <path
        d="M12.4176 23.5088V26.0317L9.21354 24.1899V21.671L12.4176 23.5088Z"
        fill="#C7C7C7"
      />
      <path
        d="M9.68913 23.9159V22.4932L11.938 23.7829V25.2056L9.68913 23.9159Z"
        fill="#484848"
      />
      <path
        d="M10.0196 23.7263L9.68913 23.9157L11.938 25.2054V24.8265L10.0196 23.7263Z"
        fill="#E6F5FF"
      />
      <path
        d="M9.68891 21.9447V24.4677L6.88387 22.8395V20.3206L9.68891 21.9447Z"
        fill="#C7C7C7"
      />
      <path
        d="M7.35958 22.5655V21.1428L9.21349 22.2189V23.6416L7.35958 22.5655Z"
        fill="#484848"
      />
      <path
        d="M7.69006 22.3759L7.35958 22.5653L9.21349 23.6414V23.2626L7.69006 22.3759Z"
        fill="#E6F5FF"
      />
      <path
        d="M16.8303 28.0026V30.5255L13.6303 28.6837V26.1608L16.8303 28.0026Z"
        fill="#C7C7C7"
      />
      <path
        d="M14.1059 28.4097V26.9871L16.3548 28.2767V29.6994L14.1059 28.4097Z"
        fill="#484848"
      />
      <path
        d="M14.4364 28.2162L14.1059 28.4096L16.3548 29.6993V29.3164L14.4364 28.2162Z"
        fill="#E6F5FF"
      />
      <path
        d="M14.106 26.439V28.9579L11.2969 27.3337V24.8148L14.106 26.439Z"
        fill="#C7C7C7"
      />
      <path
        d="M11.7763 27.0595V25.6368L13.6302 26.7129V28.1355L11.7763 27.0595Z"
        fill="#484848"
      />
      <path
        d="M12.1027 26.8699L11.7763 27.0593L13.6302 28.1354V27.7525L12.1027 26.8699Z"
        fill="#E6F5FF"
      />
      <path
        d="M16.8303 26.052V28.5749L13.6303 26.7331V24.2142L16.8303 26.052Z"
        fill="#C7C7C7"
      />
      <path
        d="M14.1059 26.4589V25.0362L16.3548 26.3259V27.7486L14.1059 26.4589Z"
        fill="#484848"
      />
      <path
        d="M14.4364 26.2693L14.1059 26.4587L16.3548 27.7484V27.3695L14.4364 26.2693Z"
        fill="#E6F5FF"
      />
      <path
        d="M14.1058 24.4888V27.0117L11.2967 25.3835V22.8646L14.1058 24.4888Z"
        fill="#C7C7C7"
      />
      <path
        d="M11.7763 25.1095V23.6868L13.6302 24.7629V26.1856L11.7763 25.1095Z"
        fill="#484848"
      />
      <path
        d="M12.1027 24.9199L11.7763 25.1093L13.6302 26.1854V25.8025L12.1027 24.9199Z"
        fill="#E6F5FF"
      />
      <path
        d="M35.1395 21.129V34.1284L24.0355 40.5363V27.5369L35.1395 21.129Z"
        fill="#3D3D39"
      />
      <path
        d="M35.1394 32.9408V38.4544L24.0354 44.9965V39.3487V30.6775L35.1394 32.9408Z"
        fill="#909090"
      />
      <path
        d="M24.4425 27.7354L24.0358 27.473V25.3087H24.4425V27.7354Z"
        fill="#474747"
      />
      <path
        d="M34.9033 19L35.4477 19.8395L24.4421 26.2343L24.0354 25.3095L34.9033 19Z"
        fill="#909090"
      />
      <path
        d="M35.4476 19.8401V21.3355L24.442 27.7368V26.2349L35.4476 19.8401Z"
        fill="#636363"
      />
      <mask
        id="mask0_4613_144974"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="24"
        y="27"
        width="20"
        height="27"
      >
        <path
          d="M43.3416 31.8012V46.592L37.6529 53.9874L24 44.4136V38.7658V28.3879L24.4549 28.7293L26.8442 27.2502L43.3416 31.8012Z"
          fill="#909090"
        />
      </mask>
      <g mask="url(#mask0_4613_144974)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4698 37.9008C24.184 37.1591 23.1356 37.605 23.1356 38.8933C23.1356 40.1816 24.184 41.8371 25.4698 42.5788C26.7549 43.3205 27.8032 42.8746 27.8032 41.5867C27.8032 40.298 26.7549 38.6425 25.4698 37.9008Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1508 38.8902C22.865 38.1485 21.8166 38.5944 21.8166 39.8827C21.8166 41.171 22.865 42.8265 24.1508 43.5683C25.4358 44.31 26.4842 43.8641 26.4842 42.5761C26.4842 41.2875 25.4358 39.6319 24.1508 38.8902Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1511 39.6767C23.2974 39.1843 22.6012 39.4801 22.6012 40.3357C22.6012 41.1909 23.2974 42.2903 24.1511 42.7831C25.0044 43.2755 25.7006 42.9793 25.7006 42.1241C25.7006 41.2685 25.0044 40.1691 24.1511 39.6767Z"
          fill="#353633"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.2158 39.7905C23.4622 39.3555 22.8474 39.6169 22.8474 40.3722C22.8474 41.1275 23.4622 42.098 24.2158 42.5326C24.969 42.9675 25.5838 42.7061 25.5838 41.9508C25.5838 41.1959 24.969 40.225 24.2158 39.7905Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.2158 39.9878C23.571 39.6158 23.045 39.8395 23.045 40.4857C23.045 41.1319 23.571 41.9625 24.2158 42.3345C24.8605 42.7065 25.3861 42.4828 25.3861 41.8366C25.3861 41.1904 24.8605 40.3601 24.2158 39.9878Z"
          fill="#8E4428"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.2157 40.2258C23.7011 39.9289 23.2813 40.1072 23.2813 40.623C23.2813 41.1385 23.7011 41.8015 24.2157 42.0984C24.7301 42.3949 25.1498 42.2167 25.1498 41.7012C25.1498 41.1853 24.7301 40.5227 24.2157 40.2258Z"
          fill="#A34D2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.2157 40.3441C23.7661 40.0846 23.3997 40.2405 23.3997 40.6908C23.3997 41.1411 23.7661 41.7203 24.2157 41.9795C24.665 42.2387 25.0314 42.0828 25.0314 41.6325C25.0314 41.1822 24.665 40.6033 24.2157 40.3441Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.7005 43.8461C26.0098 43.8352 26.557 43.4564 27.3422 42.7097L26.8329 41.1681L26.0136 42.7566C25.4956 43.4939 25.3912 43.857 25.7005 43.8461Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.3168 37.9008C38.031 37.1591 36.9826 37.605 36.9826 38.8933C36.9826 40.1816 38.031 41.8371 39.3168 42.5788C40.6018 43.3205 41.6502 42.8746 41.6502 41.5867C41.6502 40.298 40.6018 38.6425 39.3168 37.9008Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.9981 38.8902C36.7123 38.1485 35.6639 38.5944 35.6639 39.8827C35.6639 41.171 36.7123 42.8265 37.9981 43.5683C39.2832 44.31 40.3315 43.8641 40.3315 42.5761C40.3315 41.2875 39.2832 39.6319 37.9981 38.8902Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.9981 39.6767C37.1445 39.1843 36.4482 39.4801 36.4482 40.3357C36.4482 41.1909 37.1445 42.2903 37.9981 42.7831C38.8514 43.2755 39.5477 42.9793 39.5477 42.1241C39.5477 41.2685 38.8514 40.1691 37.9981 39.6767Z"
          fill="#353633"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.0628 39.7897C37.3093 39.3548 36.6945 39.6162 36.6945 40.3715C36.6945 41.1267 37.3093 42.0973 38.0628 42.5318C38.8161 42.9668 39.4308 42.7054 39.4308 41.9501C39.4308 41.1952 38.8161 40.2243 38.0628 39.7897Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.0628 39.9878C37.4181 39.6158 36.8921 39.8395 36.8921 40.4857C36.8921 41.1319 37.4181 41.9625 38.0628 42.3345C38.7075 42.7065 39.2332 42.4828 39.2332 41.8366C39.2332 41.1904 38.7075 40.3601 38.0628 39.9878Z"
          fill="#8E4428"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.0628 40.2258C37.5481 39.9289 37.1284 40.1072 37.1284 40.623C37.1284 41.1385 37.5481 41.8015 38.0628 42.0984C38.5771 42.3949 38.9968 42.2167 38.9968 41.7012C38.9968 41.1853 38.5771 40.5227 38.0628 40.2258Z"
          fill="#A34D2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.0628 40.3449C37.6131 40.0853 37.2467 40.2413 37.2467 40.6916C37.2467 41.1419 37.6131 41.7211 38.0628 41.9803C38.5121 42.2395 38.8785 42.0835 38.8785 41.6332C38.8785 41.1829 38.5121 40.6041 38.0628 40.3449Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5475 43.8461C39.8568 43.8352 40.404 43.4564 41.1892 42.7097L40.6799 41.1681L39.8605 42.7566C39.3426 43.4939 39.2382 43.857 39.5475 43.8461Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.7771 37.6507V37.6865L37.6613 38.5805L35.9688 38.8925C37.1743 37.9584 37.7771 37.5307 37.7771 37.6095C37.7771 37.6882 37.7771 37.7019 37.7771 37.6507Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.6317 38.9979L39.9032 39.9951V41.5467L41.6317 40.5527V38.9979Z"
          fill="#EB922D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.251 37.8157L39.8209 39.9438L39.9031 39.9922L41.6316 38.9982L41.5465 38.9477L41.5294 38.9378L37.9792 36.8184L36.251 37.8157Z"
          fill="#E9B237"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.4024 39.8328V40.1765C41.4024 40.2417 41.3673 40.3018 41.3111 40.3343L40.3383 40.9102C40.2886 40.9388 40.2269 40.9025 40.2269 40.845V40.804L41.0996 40.2864C41.1554 40.2542 41.1909 40.1938 41.1909 40.129V39.8262L41.291 39.7687C41.3406 39.7402 41.4024 39.7764 41.4024 39.8328Z"
          fill="#121311"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.1909 39.8266V40.1294C41.1909 40.1942 41.1554 40.2546 41.0996 40.2868L40.2269 40.8045V40.5024C40.2269 40.4365 40.262 40.3765 40.3182 40.3439L41.1909 39.8266Z"
          fill="#FFF3CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3552 46.3211L30.7441 42.9943V38.9108L36.3552 42.2376V46.3211Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.4871 39.8516L36.3553 42.2371V46.3206L40.4871 43.9501V39.8516Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.7445 38.9093L36.3545 42.2375L40.4869 39.8655L34.8747 36.5241L30.7445 38.9093Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.043 44.9121L33.5805 44.646L33.2653 42.9019L34.043 43.3606V44.9121Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.3344 38.1047L30.8178 38.961V42.8267L29.3344 41.9758V38.1047Z"
          fill="#502D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3269 42.712L29.5418 41.9948V39.5697L28.3269 40.2869V42.712Z"
          fill="#8D4528"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.8434 39.4311L28.3269 40.2874V42.7125L26.8434 41.8616V39.4311Z"
          fill="#6D3822"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.5418 39.5696L28.4551 40.2114L28.3269 40.2876L26.8434 39.436L26.9764 39.3569L27.0031 39.3415L28.0591 38.7137L29.5418 39.5696Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1146 40.6727L24.1502 38.3326L19.2689 32.6705L19 31.1976L30.6665 38.0902L28.1146 40.6727Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.7698 38.2067L28.0241 39.9728L24.5108 37.8985L20.2472 32.9546L20.1712 32.5366L29.7698 38.2067Z"
          fill="#874226"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.8424 33.8932L30.6664 38.0902L28.1146 40.6727L35.2906 36.4907L37.8424 33.8932Z"
          fill="#CA722E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0009 31.1968L30.6662 38.0905L37.8426 33.9074L26.1754 27.0002L19.0009 31.1968Z"
          fill="#ECC530"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.7428 33.9035L33.2682 35.9295L30.6666 37.4451L28.7284 36.2992L21.4852 32.0184L20.099 31.1994L26.1733 27.6468L36.7428 33.9035Z"
          fill="#E2832D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4418 29.1193L21.4852 32.0184L20.099 31.1994L26.1733 27.6468L26.4418 29.1193Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.2681 35.9294L30.6666 37.4451L28.7284 36.2992L31.3244 34.7817L33.2681 35.9294Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3237 34.7806L33.2681 35.9287L36.7431 33.9035L26.1732 27.6461L26.4421 29.1185L31.3237 34.7806Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6834 38.102L30.0979 37.7528V36.7628L30.6834 37.1121V38.102Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.9154 40.8481L32.3234 38.143L33.9482 36.6588L35.9701 37.8523L36.9154 40.8481Z"
          fill="#212220"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.438 36.428L35.9701 37.8529L36.9155 40.8487L39.3833 39.4326L38.438 36.428Z"
          fill="#434341"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.9488 36.6585L35.9699 37.8527L38.4382 36.4359L36.4156 35.2336L33.9488 36.6585Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.6905 43.0579L40.1515 41.0568L40.2341 41.1026L36.6905 45.1692V43.0579Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.6905 43.0579L36.773 43.1036V45.2149L36.6905 45.1692V43.0579Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.773 43.1037L40.2341 41.1025V43.1791L36.773 45.215V43.1037Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 41.2986V43.1138L39.6361 43.3993L39.5627 43.4425L38.6761 43.9642L38.6027 44.0078L37.714 44.5295L37.6424 44.5731L37.5726 44.613L36.8859 45.0175V43.433L37.4992 43.0797L37.6424 42.9985V42.9227L37.4992 43.0039L36.8859 43.3576V43.1683L37.4992 42.815L37.714 42.6902L38.461 42.2575L38.6027 42.1758L38.6761 42.1345L39.4213 41.7032L39.5627 41.6219L39.6361 41.5787L40.1208 41.2986Z"
          fill="#0A0B09"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 42.0869V42.1623L39.6361 42.4401L39.5627 42.4837L39.4213 42.565L38.6761 42.9922L38.6027 43.0358L38.461 43.1171L37.714 43.5461L37.6424 43.5879L37.4992 43.6691L36.8859 44.0228V43.947L37.4992 43.5934L37.6424 43.5121L37.714 43.4707L38.461 43.0416L38.6027 42.96L38.6761 42.9168L39.4213 42.4896L39.5627 42.4083L39.6361 42.3647L40.1208 42.0869Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6424 43.8114V43.8854L37.4992 43.9666L36.8859 44.3199V44.2463L37.4992 43.8927L37.6424 43.8114Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 42.384V42.4594L39.636 42.7373L39.5626 42.7808L39.4213 42.8621L38.6761 43.2893L38.6027 43.3329L38.4609 43.4142L37.7139 43.8433L37.6423 43.885V43.811L37.7139 43.7697L38.4609 43.3384L38.6027 43.2571L38.6761 43.2158L39.4213 42.7863L39.5626 42.705L39.636 42.6637L40.1208 42.384Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6424 44.1085V44.1839L37.4992 44.2656L36.8859 44.6189V44.5434L37.4992 44.1898L37.6424 44.1085Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6424 44.4049V44.4803L37.4992 44.5616L36.8859 44.9153V44.8395L37.4992 44.4862L37.6424 44.4049Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 42.9797V43.0533L39.636 43.3334L39.5626 43.3748L39.4213 43.456L38.6761 43.8855L38.6027 43.9268L38.4609 44.0081L37.7139 44.4394L37.6423 44.4808V44.4053L37.7139 44.3636L38.4609 43.9345L38.6027 43.8533L38.6761 43.8097L39.4213 43.3824L39.5626 43.3012L39.636 43.2576L40.1208 42.9797Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.043 42.0574L40.1208 42.0874L39.6361 42.3653L39.5627 42.4088L39.4213 42.4901L38.6761 42.9173L38.6027 42.9605L38.461 43.0422L37.714 43.4713L37.6424 43.5126L37.4992 43.5939L36.8859 43.9476V43.874L37.4992 43.5203L37.6424 43.439L37.714 43.3973L38.461 42.9664L38.6027 42.8851L38.6761 42.8434L39.4213 42.4143L39.5627 42.333L39.6361 42.2913L40.043 42.0574Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.043 42.356L40.1208 42.3841L39.6361 42.6638L39.5627 42.7052L39.4213 42.7865L38.6761 43.2159L38.6027 43.2573L38.461 43.3386L37.714 43.7698L37.6424 43.8112L37.4992 43.8925L36.8859 44.2461V44.1704L37.4992 43.8171L37.6424 43.7358L37.714 43.6941L38.461 43.265L38.6027 43.1837L38.6761 43.1401L39.4213 42.7129L39.5627 42.6316L39.6361 42.5881L40.043 42.356Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6424 42.9229V42.9987L37.4992 43.08L36.8859 43.4333V43.3578L37.4992 43.0042L37.6424 42.9229Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 41.4955V41.571L39.636 41.8507L39.5626 41.8924L39.4213 41.9737L38.6761 42.4027L38.6027 42.4445L38.4609 42.5258L37.7139 42.9567L37.6423 42.9984V42.9226L37.7139 42.8812L38.4609 42.45L38.6027 42.3687L38.6761 42.3273L39.4213 41.8979L39.5626 41.8166L39.636 41.7752L40.1208 41.4955Z"
          fill="#2D2E2C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 41.7941V41.8681L39.6361 42.1478L39.5627 42.1895L39.4213 42.2708L38.6761 42.6999L38.6027 42.7412L38.461 42.8229L37.714 43.2538L37.6424 43.2951L37.4992 43.3768L36.8859 43.7301V43.6547L37.4992 43.301L37.6424 43.2197L37.714 43.178L38.461 42.7489L38.6027 42.6676L38.6761 42.6241L39.4213 42.1968L39.5627 42.1156L39.6361 42.072L40.1208 41.7941Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.043 41.4675L40.1208 41.4957L39.6361 41.7754L39.5627 41.8168L39.4213 41.8981L38.6761 42.3275L38.6027 42.3689L38.461 42.4501L37.714 42.8814L37.6424 42.9228L37.4992 43.0041L36.8859 43.3577V43.2819L37.4992 42.9286L37.6424 42.8474L37.714 42.8056L38.461 42.3766L38.6027 42.2953L38.6761 42.2517L39.4213 41.8245L39.5627 41.7432L39.6361 41.6996L40.043 41.4675Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.043 41.7639L40.1208 41.7939L39.6361 42.0718L39.5627 42.1154L39.4213 42.1966L38.6761 42.6239L38.6027 42.6675L38.461 42.7487L37.714 43.1778L37.6424 43.2195L37.4992 43.3008L36.8859 43.6545V43.5805L37.4992 43.2269L37.6424 43.1459L37.714 43.1042L38.461 42.6729L38.6027 42.5917L38.6761 42.5503L39.4213 42.1212L39.5627 42.04L39.6361 41.9982L40.043 41.7639Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6361 41.5789V43.3995L39.5626 43.4427V41.6221L39.6361 41.5789Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 41.6216V41.8163L39.4214 41.824V41.7029L39.5628 41.6216Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 41.8922V42.1152L39.4214 42.1229V41.9735L39.5628 41.8922Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 42.1893V42.4086L39.4214 42.4159V42.2706L39.5628 42.1893Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 42.4843V42.7054L39.4214 42.7131V42.5655L39.5628 42.4843Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 42.6826V42.7565L39.636 43.0362L39.5626 43.0779L39.4213 43.1592L38.6761 43.5883L38.6027 43.63L38.4609 43.7113L37.7139 44.1422L37.6423 44.1836V44.1082L37.7139 44.0664L38.4609 43.6374L38.6027 43.5561L38.6761 43.5125L39.4213 43.0853L39.5626 43.004L39.636 42.9604L40.1208 42.6826Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 42.7814V43.0043L39.4214 43.012V42.8626L39.5628 42.7814Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 43.0778V43.3007L39.4214 43.3084V43.159L39.5628 43.0778Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 43.3741V43.4422L39.4214 43.5253V43.4554L39.5628 43.3741Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6761 42.1348V43.9645L38.6027 44.0081V42.1761L38.6761 42.1348Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 42.176V42.369L38.4611 42.3766V42.2577L38.6028 42.176Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 42.4445V42.6674L38.4611 42.6751V42.5257L38.6028 42.4445Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 42.7408V42.9601L38.4611 42.9678V42.8225L38.6028 42.7408Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 43.0358V43.2569L38.4611 43.2646V43.117L38.6028 43.0358Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 43.3329V43.5558L38.4611 43.5635V43.4142L38.6028 43.3329Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 43.63V43.853L38.4611 43.8603V43.7113L38.6028 43.63Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 43.9264V44.0077L38.4611 44.0908V44.0077L38.6028 43.9264Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.7139 42.6899V44.5292L37.6423 44.5728V42.7317L37.7139 42.6899Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 42.732V42.9227L37.4993 42.9286V42.8151L37.6425 42.732Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 42.9982V43.2193L37.4993 43.2266V43.0794L37.6425 42.9982Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 43.2946V43.512L37.4993 43.5197V43.3762L37.6425 43.2946Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 43.588V43.811L37.4993 43.8187V43.6693L37.6425 43.588Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 43.8851V44.1081L37.4993 44.1154V43.9664L37.6425 43.8851Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 44.1837V44.4052L37.4993 44.4125V44.2654L37.6425 44.1837Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4993 44.5614L37.6425 44.4801V44.5727L37.5727 44.6126H37.4993V44.5614Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.6905 42.5344L40.1515 40.5333L40.2341 40.5791L36.6905 44.522V42.5344Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.6905 42.5344L36.773 42.5801V44.5677L36.6905 44.5219V42.5344Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.773 42.5802L40.2341 40.579V42.5318L36.773 44.5677V42.5802Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 40.7752V42.59L39.6361 42.8755L39.5627 42.9187L38.6761 43.4408L38.6027 43.484L38.461 43.5675L37.714 44.0061L37.6424 44.0493L37.5726 44.0892L36.8859 44.4937V42.9096L37.4992 42.5559L37.6424 42.4747V42.3989L37.4992 42.4801L36.8859 42.8338V42.6449L37.4992 42.2912L37.6424 42.2081L37.714 42.1664L38.461 41.7337L38.6027 41.6524L38.6761 41.6107L39.4213 41.1797L39.5627 41.0985L39.6361 41.0549L40.1208 40.7752Z"
          fill="#0A0B09"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 41.5641V41.6399L39.6361 41.9174L39.5627 41.9609L39.4213 42.0422L38.6761 42.4695L38.6027 42.513L38.461 42.5943L37.714 43.0234L37.6424 43.0651L37.4992 43.1464L36.8859 43.5V43.4242L37.4992 43.071L37.6424 42.9893L37.714 42.948L38.461 42.5189L38.6027 42.4376L38.6761 42.394L39.4213 41.9668L39.5627 41.8855L39.6361 41.842L40.1208 41.5641Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6424 43.2879V43.3619L37.4992 43.4431L36.8859 43.7968V43.7228L37.4992 43.3692L37.6424 43.2879Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 41.8605V41.9359L39.636 42.2138L39.5626 42.2573L39.4213 42.3386L38.6761 42.7658L38.6027 42.8094L38.4609 42.8907L37.7139 43.3198L37.6423 43.3615V43.2875L37.7139 43.2462L38.4609 42.8149L38.6027 42.7336L38.6761 42.6923L39.4213 42.2628L39.5626 42.1819L39.636 42.1402L40.1208 41.8605Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6424 43.5843V43.6601L37.4992 43.7413L36.8859 44.0946V44.0192L37.4992 43.6656L37.6424 43.5843Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6424 43.8814V43.9568L37.4992 44.0381L36.8859 44.3918V44.316L37.4992 43.9627L37.6424 43.8814Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 42.4562V42.5302L39.636 42.8099L39.5626 42.8516L39.4213 42.9329L38.6761 43.362L38.6027 43.4033L38.4609 43.4846L37.7139 43.9159L37.6423 43.9573V43.8818L37.7139 43.8401L38.4609 43.411L38.6027 43.3298L38.6761 43.2862L39.4213 42.8589L39.5626 42.7777L39.636 42.7341L40.1208 42.4562Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6424 42.3987V42.4745L37.4992 42.5558L36.8859 42.9095V42.8337L37.4992 42.48L37.6424 42.3987Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 40.972V41.0475L39.636 41.3272L39.5626 41.3689L39.4213 41.4502L38.6761 41.8792L38.6027 41.921L38.4609 42.0023L37.7139 42.4332L37.6423 42.4749V42.3991L37.7139 42.3577L38.4609 41.9265L38.6027 41.8452L38.6761 41.8038L39.4213 41.3748L39.5626 41.2935L39.636 41.2517L40.1208 40.972Z"
          fill="#2D2E2C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 41.2706V41.3446L39.6361 41.6243L39.5627 41.666L39.4213 41.7473L38.6761 42.1764L38.6027 42.2181L38.461 42.2994L37.714 42.7303L37.6424 42.772L37.4992 42.8533L36.8859 43.2066V43.1312L37.4992 42.7775L37.6424 42.6962L37.714 42.6545L38.461 42.2254L38.6027 42.1441L38.6761 42.1006L39.4213 41.6733L39.5627 41.5921L39.6361 41.5489L40.1208 41.2706Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6361 41.0554V42.876L39.5626 42.9192V41.0989L39.6361 41.0554Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 41.0989V41.2936L39.4214 41.301V41.1802L39.5628 41.0989Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 41.3687V41.5917L39.4214 41.5994V41.45L39.5628 41.3687Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 41.6658V41.8851L39.4214 41.8924V41.7471L39.5628 41.6658Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 41.9608V42.1822L39.4214 42.1896V42.042L39.5628 41.9608Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1208 42.1591V42.2331L39.636 42.5128L39.5626 42.5545L39.4213 42.6358L38.6761 43.0649L38.6027 43.1066L38.4609 43.1879L37.7139 43.6188L37.6423 43.6605V43.5847L37.7139 43.543L38.4609 43.1139L38.6027 43.0326L38.6761 42.9891L39.4213 42.5618L39.5626 42.4806L39.636 42.4374L40.1208 42.1591Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 42.2571V42.4801L39.4214 42.4878V42.3384L39.5628 42.2571Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 42.5543V42.7772L39.4214 42.7849V42.6355L39.5628 42.5543Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5628 42.8514V42.9192L39.4214 43.0026V42.9327L39.5628 42.8514Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6761 41.6113V43.4414L38.6027 43.4846V41.653L38.6761 41.6113Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 41.6526V41.8452L38.4611 41.8528V41.7339L38.6028 41.6526Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 41.921V42.1439L38.4611 42.1516V42.0022L38.6028 41.921Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 42.2181V42.4374L38.4611 42.4447V42.2994L38.6028 42.2181Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 42.5123V42.7334L38.4611 42.7411V42.5935L38.6028 42.5123Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 42.8094V43.0323L38.4611 43.04V42.8907L38.6028 42.8094Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 43.1065V43.3295L38.4611 43.3372V43.1878L38.6028 43.1065Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6028 43.4029V43.4842L38.4611 43.5676V43.4842L38.6028 43.4029Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.7139 42.1664V44.0061L37.6423 44.0493V42.2082L37.7139 42.1664Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 42.2085V42.3992L37.4993 42.4051V42.2916L37.6425 42.2085Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 42.4747V42.6958L37.4993 42.7035V42.5559L37.6425 42.4747Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 42.7718V42.9889L37.4993 42.9966V42.8531L37.6425 42.7718Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 43.0645V43.2875L37.4993 43.2952V43.1458L37.6425 43.0645Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 43.3616V43.5846L37.4993 43.5923V43.4429L37.6425 43.3616Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6425 43.6602V43.8813L37.4993 43.8887V43.7415L37.6425 43.6602Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4993 44.0379L37.6425 43.9567V44.0493L37.5727 44.0892H37.4993V44.0379Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.0224 46.8187L35.7703 46.6701V46.1671L36.0224 46.3157V46.8187Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.1543 43.3668L36.0225 46.3158V46.8188L41.1543 43.8709V43.3668Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7703 46.1668L36.0224 46.3154L41.1542 43.3676L41.1265 43.3511L41.121 43.3482L40.9022 43.2178L35.7703 46.1668Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.9555 38.4679L30.0979 36.763V36.313L32.9555 38.0176V38.4679Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.1701 39.4809L21.5378 34.954V34.5037L29.1701 39.0306V39.4809Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1145 33.8195L32.9557 38.0175V38.4679L40.1145 34.2698V33.8195Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.0979 36.3125L32.9321 38.0039L32.9555 38.0175L40.1143 33.8194L37.2567 32.1133L30.0979 36.3125Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.6624 34.5951L29.024 38.9612L29.1507 38.8327L21.8126 34.4801L21.6223 34.5482L21.6624 34.5951Z"
          fill="#E9B237"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.0527 42.186C31.7669 41.4443 30.7186 41.8902 30.7186 43.1785C30.7186 44.4668 31.7669 46.1223 33.0527 46.864C34.3378 47.6058 35.3861 47.1599 35.3861 45.8719C35.3861 44.5832 34.3378 42.9277 33.0527 42.186Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7343 43.1751C30.4485 42.4334 29.4001 42.8793 29.4001 44.1676C29.4001 45.4559 30.4485 47.1115 31.7343 47.8532C33.0193 48.5949 34.0677 48.149 34.0677 46.861C34.0677 45.5724 33.0193 43.9169 31.7343 43.1751Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7341 43.9607C30.8805 43.4683 30.1842 43.7641 30.1842 44.6197C30.1842 45.4749 30.8805 46.5743 31.7341 47.0671C32.5874 47.5595 33.2837 47.2633 33.2837 46.4081C33.2837 45.5525 32.5874 44.4531 31.7341 43.9607Z"
          fill="#353633"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7988 44.0745C31.0453 43.6396 30.4305 43.901 30.4305 44.6562C30.4305 45.4115 31.0453 46.382 31.7988 46.8166C32.5521 47.2515 33.1668 46.9901 33.1668 46.2349C33.1668 45.48 32.5521 44.5091 31.7988 44.0745Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7988 44.2725C31.1541 43.9006 30.6281 44.1243 30.6281 44.7704C30.6281 45.4166 31.1541 46.2473 31.7988 46.6192C32.4435 46.9912 32.9692 46.7675 32.9692 46.1213C32.9692 45.4752 32.4435 44.6448 31.7988 44.2725Z"
          fill="#8E4428"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7988 44.5106C31.2841 44.2137 30.8644 44.392 30.8644 44.9078C30.8644 45.4233 31.2841 46.0863 31.7988 46.3832C32.3131 46.6797 32.7328 46.5014 32.7328 45.986C32.7328 45.4701 32.3131 44.8075 31.7988 44.5106Z"
          fill="#A34D2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7988 44.6297C31.3491 44.3701 30.9827 44.5261 30.9827 44.9764C30.9827 45.4267 31.3491 46.0058 31.7988 46.265C32.2481 46.5242 32.6144 46.3683 32.6144 45.918C32.6144 45.4677 32.2481 44.8889 31.7988 44.6297Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.2834 48.1313C33.5927 48.1204 34.14 47.7416 34.9252 46.995L34.4158 45.4533L33.5965 47.0418C33.0785 47.7791 32.9742 48.1422 33.2834 48.1313Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.513 41.9352V41.971L31.3972 42.865L29.7048 43.177C30.9103 42.2429 31.513 41.8152 31.513 41.8939C31.513 41.9727 31.513 41.9864 31.513 41.9352Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.3906 41.456L33.9609 43.5838L34.0427 43.6325L35.7712 42.6382L35.6861 42.5877L35.6693 42.5778L30.3906 41.1844V41.456Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.8625 41.768L30.3905 41.4554L32.1187 40.4581L28.8625 41.4968V41.768Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.3906 41.1841L33.9609 43.3122L34.0427 43.3606L35.7712 42.3666L35.6861 42.3161L35.6693 42.3062L32.1188 40.1868L30.3906 41.1841Z"
          fill="#E9B237"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.8625 41.4971L30.3905 41.1841L32.1187 40.1868L30.5903 40.4998L28.8625 41.4971Z"
          fill="#A44C2C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.771 42.3633L34.0428 43.3606V44.9121L35.771 43.9182V42.3633Z"
          fill="#EB922D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.4764 43.1892V43.5333C35.4764 43.5981 35.4413 43.6585 35.385 43.6908L34.4123 44.2666C34.3626 44.2952 34.3009 44.2589 34.3009 44.2018V44.1608L35.1735 43.6432C35.2294 43.6106 35.2649 43.5505 35.2649 43.4854V43.1826L35.365 43.1255C35.4146 43.0966 35.4764 43.1332 35.4764 43.1892Z"
          fill="#121311"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.2649 43.1825V43.4853C35.2649 43.5504 35.2294 43.6105 35.1735 43.6431L34.3009 44.1607V43.8587C34.3009 43.7928 34.3359 43.7327 34.3922 43.7002L35.2649 43.1825Z"
          fill="#FFF3CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.043 44.9118L33.5806 44.6457L33.2653 42.9016L34.043 43.3603V44.9118Z"
          fill="#D16F2D"
        />
      </g>
    </SvgIcon>
  );
};
