import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { AppRoute } from "utils";
import { Module } from "interfaces";

export type DrawerItemData = {
  text: string;
  icon: React.ReactNode;
  route: AppRoute;
  module: Module;
  isModal?: boolean;
};

interface MenuDrawerItemProps {
  item: DrawerItemData;
  onClick: (route: AppRoute) => void;
  openModal?: () => void;
  isActive: boolean;
  isModal?: boolean;
}

export const MenuDrawerItem: React.FC<MenuDrawerItemProps> = ({
  item,
  onClick,
  isActive,
  isModal=false,
  openModal
}) => {
  const classes = useStyles({ isActive });
  return (
    <ListItem
      button
      key={item.text}
      onClick={
        isModal 
        ? openModal
        : () => onClick(item.route)}
      className={classes.item}
    >
      <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
      <ListItemText className={classes.icon} primary={item.text} />
    </ListItem>
  );
};

interface StyleProps {
  isActive: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  lastItem: {
    marginBottom: "auto",
  },
  item: {
    paddingLeft: 24,
  },
  icon: {
    color: ({ isActive }) => (isActive ? "#F77F00" : "#727272"),
  },
}));
