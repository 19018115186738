import React, { createContext, useContext, useEffect, useMemo } from "react";
import {
  ErrorMsg,
  SourceInfo,
  SourceInfoSourceType,
  InfoSourceModule,
} from "interfaces";
import { useData, useMinutesCounter } from "hooks";
import { UserContext, ContextProviderProps } from "contexts";
import { STANDARD_POLLING } from "App";

type InfoSourceInterface = {
  sourceData: SourceInfo[] | null;
  firstLoadingSourceInfo: boolean;
  refetchingSourceInfo: boolean;
  magnetElapsedMinutes: number | null;
  operatorsElapsedMinutes: number | null;
  isEquipParkingDataActive: boolean | null;
};

interface LatestDates {
  latestMagnetDate: Date | null;
  latestOperatorDate: Date | null;
}

export const InfoSourceContext = createContext<InfoSourceInterface>({
  sourceData: null,
  firstLoadingSourceInfo: false,
  refetchingSourceInfo: false,
  magnetElapsedMinutes: null,
  operatorsElapsedMinutes: null,
  isEquipParkingDataActive: false,
});

export const InfoSourceProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { token } = useContext(UserContext);
  const { data, refetch, firstLoading, refetching } = useData<SourceInfo[]>(
    {
      config: {
        url: "/info_source_status",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      options: {
        useCache: false,
        manual: true,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_SOURCE_INFO
  );

  const latestDates = useMemo<LatestDates>(() => {
    if (!data) return { latestMagnetDate: null, latestOperatorDate: null };
    const latestMagnetDate =
      data
        .find(({ module }) => module === InfoSourceModule.TRUCK_ASSIGNMENT)
        ?.data.find(({ source }) => source === SourceInfoSourceType.MAGNET)
        ?.lastCreation ?? null;

    const latestOperatorDate =
      data
        .find(({ module }) => module === InfoSourceModule.OPERATOR_ASSIGNMENT)
        ?.data.find(({ source }) => source === SourceInfoSourceType.OPERSTAT)
        ?.lastCreation ?? null;

    return {
      latestMagnetDate,
      latestOperatorDate,
    };
  }, [data]);

  const isEquipParkingDataActive = useMemo<boolean | null>(
    () =>
      data
        ?.find(({ module }) => module === InfoSourceModule.LOST_TRUCK)
        ?.data.find(
          ({ source }) => source === SourceInfoSourceType.END_OF_SHIFT_PARKING
        )?.upToDate ?? null,
    [data]
  );

  const [magnetElapsedMinutes] = useMinutesCounter(
    latestDates.latestMagnetDate
  );
  const [operatorsElapsedMinutes] = useMinutesCounter(
    latestDates.latestOperatorDate
  );

  useEffect(() => {
    if (token)
      refetch({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  }, [token, refetch]);

  const contextState = useMemo<InfoSourceInterface>(
    () => ({
      sourceData: data ?? null,
      firstLoadingSourceInfo: firstLoading,
      refetchingSourceInfo: refetching,
      magnetElapsedMinutes,
      operatorsElapsedMinutes,
      isEquipParkingDataActive,
    }),
    [
      data,
      firstLoading,
      refetching,
      magnetElapsedMinutes,
      operatorsElapsedMinutes,
      isEquipParkingDataActive,
    ]
  );

  return (
    <InfoSourceContext.Provider value={contextState}>
      {children}
    </InfoSourceContext.Provider>
  );
};
