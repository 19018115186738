import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { YellowTruckIcon } from "components";
import { KpiCard } from "components/CardComponent/KpiCard";
import { KpisCardComponent } from "components/CardComponent/KpisCardComponent";
import { WithoutData } from "components/WithoutData";
import { DetailsPhase, KpiTypePerformanceTranslation } from "interfaces";
import { DetailsCaex, RoutesData } from "interfaces/velocity.interface";
import React from "react";

interface Props {
  data: RoutesData;
  firstLoading: boolean;
}
export const Caex: React.FC<Props> = ({ data, firstLoading }) => {
  const classes = useStyles();

  interface PropsCards {
    title: string;
    data: DetailsCaex[];
  }
  const CaexCards = ({ title, data }: PropsCards) => {
    return (
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12}>
          <Typography className={classes.subTitle}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {data?.length ? (
            <Grid container spacing={2}>
              {data?.map(({ id, columns }, index) => (
                <Grid item xs={2} key={index}>
                  <Grid container className={classes.gridContainer}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      className={classes.gridCardContent}
                    >
                      <Card className={classes.cardContainer} elevation={4}>
                        <CardContent className={classes.cardContent}>
                          <Grid
                            container
                            alignItems="center"
                            style={{ height: "100%" }}
                          >
                            <Grid item xs={4}>
                              <YellowTruckIcon
                                style={{ height: "40px", width: "40px" }}
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                variant="body1"
                                style={{
                                  whiteSpace:
                                    id.split(" ").length > 1
                                      ? "unset"
                                      : "nowrap",
                                }}
                                className={classes.adjustedTextSize}
                              >
                                <strong>{id}</strong>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    {columns.map((value: DetailsPhase, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={classes.gridCardContent}
                      >
                        <Card className={classes.cardContainer} elevation={4}>
                          <CardContent className={classes.cardContent}>
                            <Typography
                              variant="caption"
                              className={classes.adjustedTextSize}
                            >
                              {KpiTypePerformanceTranslation[value.type]}
                            </Typography>
                            <KpiCard values={value} />
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}{" "}
                  </Grid>{" "}
                </Grid>
              ))}
            </Grid>
          ) : (
            <WithoutData title="No se encontraron datos" />
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Typography style={{fontSize: "18px", fontWeight: 700, marginBottom: "10px"}}>CAEX en la Ruta</Typography>
      <KpisCardComponent loading={firstLoading} numberCards={6}>
        <CaexCards title="Top lentos vs promedio ruta" data={data?.caexSlow} />
      </KpisCardComponent>
      <KpisCardComponent loading={firstLoading} numberCards={6}>
        <CaexCards title="Top rápidos vs promedio ruta" data={data?.caexFast} />
      </KpisCardComponent>
    </>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    adjustedTextSize: {
      display: "block",
      width: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    icon: {
      height: "40px",
      width: "40px",
    },
    gridContainer: {
      background:
        palette.type === "light" ? palette.background.default : "#212932",
      boxShadow:
        palette.type === "light"
          ? "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)"
          : "unset",
      borderRadius: "4px",
      padding: "2px",
    },
    mainContainer: {
      borderRadius: "4px",
      marginBottom: "25px",
    },

    cardContent: {
      height: "64px",
      padding: "4px 8px",
      paddingTop: "8px",
      "&.MuiCardContent-root": {
        paddingBottom: "4px",
      },
      boxShadow:
        "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
    },
    cardContainer: {
      boxShadow: "unset",
    },
    gridCardContent: {
      background:
        palette.type === "light" ? palette.background.default : "#212932",
      // boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
      borderRadius: "4px",
      padding: "2px",
    },
    subTitle: {
      padding: "10px 0px",
      fontWeight: 500,
      fontSize: "18px"
    },
  };
});
