import { Grid, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

interface Props {
  numberCards?: number;
}
export const SkeletonCardComponent: React.FC<Props> = ({numberCards=4}) => {
  const classes = useStyles();
  return (
    <Grid item xs={numberCards === 4 ? 3 : numberCards === 3 ? 4 : 2} >
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12}  >
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(({ palette }) => {
  return {
    gridContainer: {
      background: palette.background.default,
      boxShadow:" 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
      borderRadius: "4px",
      padding: "8px"
    }
  }
})