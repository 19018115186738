import React from 'react'
import { SkeletonCardComponent } from './SkeletonCardComponent'
import { Grid, makeStyles } from '@material-ui/core'

export interface KpisPaperProps {
  loading: boolean;
  children?: React.ReactNode;
  numberCards?: number;
}

export const KpisCardComponent: React.FC<KpisPaperProps> = ({
  loading: firstLoadingKpis,
  children,
  numberCards=4
}) => {
  const classes = useStyles();
  return (
    <>
      {firstLoadingKpis ?
        <Grid container className={classes.mainContainer}>
          <Grid container spacing={3}>
            {Array.from(Array(numberCards).keys()).map((i) => (
              <SkeletonCardComponent key={`${i}-kpiCardSkeleton`} numberCards={numberCards} />
            ))}
          </Grid>
        </Grid>
      : children}
    </>
  )
}

const useStyles = makeStyles(({ palette }) => {
  return {
    mainContainer: {
      background: palette.type === "light"
      ? palette.background.default
      : palette.background.paper,
      boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
      marginTop: "30px",
      marginBottom: "30px",
      borderRadius: "4px",
      padding: "4px 8px 8px 8px"
    },
  }
})