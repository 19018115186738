import React, { useCallback, useMemo, useContext } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  HeaderCellProps,
  NestedKeyOf,
  RowProps,
  DailyPlanAPIAttributes,
  DailyPlanTableAttributes,
  Can,
  Module,
  DailyPlanInputsAPI,
  Destination,
  DailyPlanData,
  destinationMapping,
} from "interfaces";
import { VirtualizedTable } from "components";
import { DailyPlanTableInputCell } from "./DailyPlanTableInputCell";
import { UserContext, DailyPlanContext } from "contexts";
import { parseNumber } from "utils";

interface Props {
  canEdit: boolean;
}
export const TruckAssignmentDailyPlanTable: React.FC<Props> = ({canEdit: editForm}) => {
  // Hooks
  const classes = useStyles();
  const { isAllowedTo } = useContext(UserContext);
  const {
    rowsTotals,
    loading: loadingActiveExcavShiftPlan,
    selectedPlan,
    setActiveShiftPlan,
    currentShiftOption,
  } = useContext(DailyPlanContext);

  const canEdit = useMemo(
    () => isAllowedTo(Can.WRITE, Module.TRUCK_ASSIGNMENT) && editForm,
    [isAllowedTo, editForm]
  );

  const handleInputChange = useCallback(
    (inputsAttributes: DailyPlanInputsAPI) =>
      setActiveShiftPlan((prevState): DailyPlanData => {
        const excavAttributes: DailyPlanTableAttributes = {
          ...prevState[currentShiftOption][inputsAttributes.excav],
          [inputsAttributes.targetType]: inputsAttributes.value,
        };
        if (inputsAttributes.value === null) {
          delete excavAttributes[inputsAttributes.targetType];
        }
        return {
          ...prevState,
          [currentShiftOption]: {
            ...prevState[currentShiftOption],
            [inputsAttributes.excav]: {
              ...excavAttributes,
            },
          },
        };
      }),
    [currentShiftOption, setActiveShiftPlan]
  );

  const headers = useMemo<
    HeaderCellProps<NestedKeyOf<DailyPlanTableAttributes>>[]
  >(
    () => [
      {
        dataKey: "phase",
        label: "Fase",
        align: "center",
        sortable: true,
        className: clsx(classes.headerCell),
      },
      {
        dataKey: "excav",
        label: "Pala",
        align: "center",
        sortable: true,
        className: clsx(classes.headerCell),
      },
      {
        dataKey: Destination.SULFIDE_CRUSHER,
        label: destinationMapping[Destination.SULFIDE_CRUSHER],
        align: "center",
        sortable: false,
        className: clsx(classes.headerCell),
      },
      {
        dataKey: Destination.OXIDE_CRUSHER,
        label: destinationMapping[Destination.OXIDE_CRUSHER],
        align: "center",
        sortable: false,
        className: clsx(classes.headerCell),
      },
      {
        dataKey: Destination.STACK,
        label: destinationMapping[Destination.STACK],
        align: "center",
        sortable: false,
        className: clsx(classes.headerCell),
      },
      {
        dataKey: Destination.DUMP,
        label: destinationMapping[Destination.DUMP],
        align: "center",
        sortable: false,
        className: clsx(classes.headerCell),
      },
      {
        dataKey: Destination.STOCK,
        label: destinationMapping[Destination.STOCK],
        align: "center",
        sortable: false,
        className: clsx(classes.headerCell),
      },
      {
        dataKey: "total",
        label: "Total",
        align: "center",
        sortable: false,
        className: clsx(classes.headerCell),
      },
      {
        dataKey: Destination.ASSIGNED_TRUCKS,
        label: destinationMapping[Destination.ASSIGNED_TRUCKS],
        align: "center",
        sortable: false,
        className: clsx(classes.headerCell),
      },
    ],
    [classes.headerCell]
  );

  const renderCells = useCallback(
    (
      excavAttributes: DailyPlanAPIAttributes
    ): RowProps<NestedKeyOf<DailyPlanTableAttributes>> => ({
      cellsData: [
        {
          dataKey: "phase",
          value: (
            <Typography>
              <strong>{excavAttributes.phase}</strong>
            </Typography>
          ),
          align: "center",
          className: clsx(classes.headerCell),
        },
        {
          dataKey: "excav",
          value: (
            <Typography>
              <strong>{excavAttributes.excav}</strong>
            </Typography>
          ),
          align: "center",
          className: clsx(classes.headerCell),
        },
        {
          dataKey: Destination.SULFIDE_CRUSHER,
          value: (
            <DailyPlanTableInputCell
              datum={selectedPlan[excavAttributes.excav]}
              attribute={Destination.SULFIDE_CRUSHER}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          ),
          align: "center",
          className: clsx(classes.headerCell),
        },
        {
          dataKey: Destination.OXIDE_CRUSHER,
          value: (
            <DailyPlanTableInputCell
              datum={selectedPlan[excavAttributes.excav]}
              attribute={Destination.OXIDE_CRUSHER}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          ),
          align: "center",
          className: clsx(classes.headerCell),
        },
        {
          dataKey: Destination.STACK,
          value: (
            <DailyPlanTableInputCell
              datum={selectedPlan[excavAttributes.excav]}
              attribute={Destination.STACK}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          ),
          align: "center",
          className: clsx(classes.headerCell),
        },
        {
          dataKey: Destination.DUMP,
          value: (
            <DailyPlanTableInputCell
              datum={selectedPlan[excavAttributes.excav]}
              attribute={Destination.DUMP}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          ),
          align: "center",
          className: clsx(classes.headerCell),
        },
        {
          dataKey: Destination.STOCK,
          value: (
            <DailyPlanTableInputCell
              datum={selectedPlan[excavAttributes.excav]}
              attribute={Destination.STOCK}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          ),
          align: "center",
          className: clsx(classes.headerCell),
        },
        {
          dataKey: "total",
          value: (
            <Typography>
              <strong>
                {rowsTotals[excavAttributes.excav].total !== 0
                  ? parseNumber(rowsTotals[excavAttributes.excav].total, 2)
                  : "-"}
              </strong>
            </Typography>
          ),
          align: "center",
          className: clsx(classes.headerCell),
        },
        {
          dataKey: Destination.ASSIGNED_TRUCKS,
          value: (
            <DailyPlanTableInputCell
              datum={selectedPlan[excavAttributes.excav]}
              attribute={Destination.ASSIGNED_TRUCKS}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          ),
          align: "center",
          className: clsx(classes.headerCell),
        },
      ],
    }),
    [selectedPlan, canEdit, classes.headerCell, handleInputChange, rowsTotals]
  );

  const flattenedData: DailyPlanTableAttributes[] = useMemo(() => {
    return Object.values(selectedPlan).reduce<DailyPlanTableAttributes[]>(
      (prev, curr) => [...prev, curr],
      []
    );
  }, [selectedPlan]);

  return (
    <VirtualizedTable
      loadingData={loadingActiveExcavShiftPlan}
      data={flattenedData}
      renderCells={renderCells}
      headers={headers}
      tableElevation={0}
    />
  );
};

const useStyles = makeStyles(() => ({
  headerCell: {
    width: "100%",
  },
}));
