import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const DobleArrowIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_11589_20358)">
        <path
          d="M11.625 3.75H8.25L12 9L8.25 14.25H11.625L15.375 9L11.625 3.75Z"
          fillOpacity="0.5"
        />
        <path
          d="M6.375 3.75H3L6.75 9L3 14.25H6.375L10.125 9L6.375 3.75Z"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_11589_20358">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
