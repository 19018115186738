import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography, LinearProgress, TableSortLabel } from "@material-ui/core";
import { HeaderCellProps, Order } from "interfaces";
import clsx from "clsx";
import { ArrowDropDown } from "@material-ui/icons";

interface Props<T> {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: T | string
  ) => void;
  order: Order;
  orderBy?: T | string;
  loading?: boolean;
  headCells: HeaderCellProps<T>[];
}

export const VirtualizedTableHeader = <T extends unknown>({
  onRequestSort,
  order,
  orderBy,
  loading,
  headCells,
}: Props<T>) => {
  const classes = useStyles();
  return (
    <div className={classes.headerContainer}>
      <div className={clsx(classes.headerRow)}>
        {headCells.map((headCell, i) => (
          <div
            key={`${i}-${headCell.dataKey}-headcell`}
            className={clsx(classes.headerCell, headCell.className)}
            style={{ justifyContent: headCell.align }}
          >
            {headCell.sortable ? (
              <TableSortLabel
                key={`${headCell.dataKey}-${i}-sortable-label`}
                active
                direction={orderBy === headCell.dataKey ? order : "desc"}
                onClick={(e) =>
                  headCell.sortable ? onRequestSort(e, headCell.dataKey) : null
                }
                IconComponent={(props) => (
                  <ArrowDropDown
                    className={clsx(props.className, classes.sortIcon)}
                  />
                )}
              >
                <Typography className={classes.sortLabel} variant="subtitle1">
                  <b>{headCell.label}</b>
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography
                key={`${headCell.dataKey}-${i}-label`}
                className={classes.sortLabel}
                variant="subtitle1"
              >
                <b>{headCell.label}</b>
              </Typography>
            )}
          </div>
        ))}
      </div>
      <div className={classes.loaderContainer}>
        {loading && <LinearProgress className={classes.linearLoader} />}
      </div>
    </div>
  );
};

const useStyles = makeStyles(({ palette }) => {
  const cellBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;
  return createStyles({
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
    headerRow: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: cellBackground,
      color: palette.getContrastText(cellBackground),
      position: "sticky",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      top: 0,
      zIndex: 1,
    },
    headerCell: {
      display: "flex",
      padding: 16,
    },
    loaderContainer: {
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
    linearLoader: {
      height: 5,
      position: "absolute",
      width: "100%",
      zIndex: 2,
    },
    sortLabel: {
      color: palette.getContrastText(cellBackground),
    },
    sortIcon: {
      color: `${palette.getContrastText(cellBackground)} !important`,
    },
  });
});
