//Form

export interface ExcavFormProps {
  id: string;
  priority: number;
  count: number;
}
export interface OperatorFormProps {
  id: string;
  hasPriority?: boolean;
  title?: string;
}
export interface CaexFormProps {
  id: string;
  hasPriority?: boolean;
}

export interface Location {
  id: string;
  name: string;
  alias?: string;
}

export enum Status {
  FINISHED = "FINISHED",
  SAVED = "SAVED",
  CANCELED = "CANCELED",
  PENDING = "PENDING",
  DELETED = "DELETED",
  ACCEPTED = "ACCEPTED",
}

export interface StatusForm {
  hasChanges: boolean;
  status: Status;
}


export interface SelectorProps {
  id: string;
  label: string;
  disabled?: boolean;
  locationId?: string | null;
}
export interface SelectorGroup {
  excavs: SelectorProps[];
  operators: SelectorProps[];
  caex: SelectorProps[];
  shift: string;
}

export interface FormNewGroup {
  excav: string;
  operator: string;
  caex1: string;
  caex2: string;
  relayId: number;
}

export interface FormStepProps {
  location: Location;
  excavs: ExcavFormProps[];
  operatorsOwn: OperatorFormProps[];
  operatorsOther: OperatorFormProps[];
  caex: CaexFormProps[];
  statusForm: StatusForm;
  relayHour1: string;
  relayHour2: string;
  relayHour3: string | null;
  relayHour4: string | null;
  relayId?: number;
  shift: string;
  hasExtra: boolean;
}

export interface FormRequestProps {
  location: Location;
  excavs: ExcavFormProps[];
  operators: OperatorFormProps[];
  caex: CaexFormProps[];
  status: Status;
  relayHour1: string;
  relayHour2: string;
  relayHour3: string | null;
  relayHour4: string | null;
  relayId?: number | null;
  shift: string;
}

//Filters

export interface Excav {
  id: string;
  virtualRegion?: string;
  disabled?: boolean;
}

export interface VirtualRegion {
  virtualRegion: string;
  countCaex: number;
}

export interface Operator {
  id: string;
  name: string;
  location?: Location;
  area?: string;
  isOwn?: boolean;
  disabled?: boolean;
}

export interface CAEX {
  id: string;
  hasPriority?: boolean;
  fuel?: number;
  virtualRegion?: string;
  type?: string;
  disabled?: boolean;
}

export interface ShiftSelector {
  name: string;
  hours: { name: string; id: string }[];
}

export interface FiltersProps {
  excavs: Excav[];
  virtualRegion: VirtualRegion[];
  operatorsOwn: Operator[];
  operatorsOther: Operator[];
  caex: CAEX[];
  locations: Location[];
  hourRelay: ShiftSelector[];
  id: string; // day or night
}

export interface StatusRelay {
  id: number;
  shift: string;
  location: Location;
  status: Status;
  operators: Operator[];
  excavs: ExcavFormProps[];
  caex: CAEX[];
  extraOperators: Operator[];
  extraCaexs: CAEX[];
  extraExcavs: ExcavFormProps[];
  relayHour1: string;
  relayHour2: string;
  relayHour3?: string;
  relayHour4?: string;
}

export interface DataTaget {
  operators: { current: number };
  caex: { current: number };
  excavs: { current: number };
}

export interface SelectedLocation {
  location: Location;
  statusForm: StatusForm;
  step: string;
  validationForm: DataTaget;
  allLocations: FormStepProps[];
}

//PLANBY
interface Entity {
  id: string;
  title: string;
  group: number;
  excav: string;
  color?: string;
}

interface DetailsRelay {
  id: string;
  title: string;
  since: string;
  till: string;
  imageId: string;
}

interface RelayData {
  entity: Entity;
  details: DetailsRelay[];
}

export interface Relay {
  location: Location;
  dates: { start: string; end: string };
  status: string;
  excavs: string[];
  data: RelayData[];
  extraOperators: Operator[];
  extraCaexs: CAEX[];
  extraExcavs: ExcavFormProps[];
  relayId: number;
  error:{msg: string, list:string[]}[]
}

interface PlanbyChannel {
  uuid: string;
  title: string;
  group: number;
  color?: string;
 relayId: number;
}

interface PlanbyEpg {
  title: string;
  since: string;
  till: string;
  channelUuid: string;
  description: string;
  image: string;
  id: string;
}

export interface PlanByData {
  channels: PlanbyChannel[];
  epg: PlanbyEpg[];
}

export interface RelayRecommendationCSV {
  groupId: number;
  excavId: string;
  dinner: string;
  operator: string;
  truck1: string;
  operNameOut1: string;
  truck2: string;
  operNameOut2: string;
  
}
