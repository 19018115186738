import {
  User,
  Role,
  Can,
  NotificationEnum,
  CrewEnum,
  CrewHash,
  PermissionHash,
  NotificationsHash,
  Module,
} from "interfaces";

export enum UserActionType {
  INIT = "INIT",
  RESET = "RESET",
  NAME_CHANGE = "NAME_CHANGE",
  EMAIL_CHANGE = "EMAIL_CHANGE",
  ROL_CHANGE = "ROL_CHANGE",
  CREW_CHANGE = "CREW_CHANGE",
  PERMISSION_CHANGE = "PERMISSION_CHANGE",
  NOTIFICATION_CHANGE = "NOTIFICATION_CHANGE",
}

export type UserAction =
  | { type: UserActionType.INIT; payload: User }
  | { type: UserActionType.RESET; payload: User }
  | { type: UserActionType.ROL_CHANGE; role: Role }
  | { type: UserActionType.EMAIL_CHANGE; email: string }
  | { type: UserActionType.NAME_CHANGE; name: string }
  | {
      type: UserActionType.PERMISSION_CHANGE;
      module: Module;
      can: Can;
      value: boolean;
    }
  | {
      type: UserActionType.NOTIFICATION_CHANGE;
      notification: NotificationEnum;
      value: boolean;
    }
  | {
      type: UserActionType.CREW_CHANGE;
      crew: CrewEnum;
      value: boolean;
    };

export const initialUserInfoState: User = {
  id: 0,
  name: "",
  updatedBy: null,
  email: "",
  role: {
    id: 7,
    name: "INVITADO",
  },
  crews: {
    ...Object.keys(CrewEnum).reduce(
      (prevCrew, currentCrew) => ({
        ...prevCrew,
        [currentCrew]: false,
      }),
      {} as CrewHash
    ),
  },
  permissions: {
    ...Object.keys(Module).reduce(
      (prevPermission, currentModuleKey) => ({
        ...prevPermission,
        [currentModuleKey]: {
          CAN_READ: !(currentModuleKey === Module.ADMINISTRATION_PANEL),
          CAN_WRITE: false,
        },
      }),
      {} as PermissionHash
    ),
  },
  notifications: {
    ...Object.keys(NotificationEnum).reduce(
      (prev, currType) => ({
        ...prev,
        [currType]: false,
      }),
      {} as NotificationsHash
    ),
  },
};

export const userInfoReducer = (state: User, action: UserAction) => {
  switch (action.type) {
    case UserActionType.EMAIL_CHANGE:
      return {
        ...state,
        email: action.email,
      };
    case UserActionType.NAME_CHANGE:
      return {
        ...state,
        name: action.name,
      };
    case UserActionType.ROL_CHANGE:
      const { permissions, notifications, ...role } = action.role;
      return {
        ...state,
        role,
        permissions,
        notifications,
      };
    case UserActionType.PERMISSION_CHANGE:
      const CAN_READ_VALUE =
        action.can === Can.READ
          ? action.value
          : state.permissions[action.module][Can.READ] || action.value;
      const CAN_WRITE_VALUE =
        action.can === Can.WRITE
          ? action.value
          : state.permissions[action.module][Can.READ] && action.value;
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.module]: {
            CAN_WRITE: CAN_WRITE_VALUE,
            CAN_READ: CAN_READ_VALUE,
          },
        },
      };
    case UserActionType.NOTIFICATION_CHANGE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.notification]: action.value,
        },
      };
    case UserActionType.CREW_CHANGE:
      return {
        ...state,
        crews: {
          ...state.crews,
          [action.crew]: action.value,
        },
      };
    case UserActionType.INIT:
      return action.payload;
    case UserActionType.RESET:
      return action.payload;
    default:
      return state;
  }
};
