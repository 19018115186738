import { Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import {
  FormStepProps,
  PlanByData,
  Relay,
  SelectedLocation,
} from "interfaces/relieve.interface";
import { PlanBy } from "components/planBy/PlanBy";
// import { InitView } from "components/planBy/InitView";
import { Target, ValidationView } from "components/planBy/ValidationView";
import { InitView } from "components/planBy/InitView";
import { WithoutProgramming } from "components/planBy/WithoutProgramming";
import { NotFoundRelays } from "components/planBy/NotFoundRelays";
import { Loading } from "components/planBy/Loading";
import { WithoutData } from "components/WithoutData";
import { ModalDelete } from "./ModalDelete";
import { Restricted } from "components";
import { Can, Module } from "interfaces";
import { NotFoundRelaysViewer } from "components/planBy/NotFoundRelaysViewer";

interface Props {
  currentLocation?: SelectedLocation;
  targetValues: Target;
  canWriteUser?: boolean;
  expanded?: boolean;
  relay: Relay[];
  isLoading: boolean;
  formData: FormStepProps[];
  selectedLocation: number;
  switches: { [key: string]: boolean };
  setFormData: (value: FormStepProps[]) => void;
}

interface ConfirmationDialogProps {
  open: boolean;
  data?: { operator: string; caex: string | null }[];
  group?: number;
  id?: number;
}

export const RelievePlanningView: React.FC<Props> = ({
  currentLocation,
  targetValues,
  canWriteUser,
  expanded,
  relay,
  isLoading,
  formData,
  switches,
  selectedLocation,
}) => {
  const theme = useTheme();

  const [openModal, setOpenModal] = useState<ConfirmationDialogProps>({
    open: false,
  });

  //Adjust planby size
  const xs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between(1400, 1677)
  );
  const isMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between(1677, 1840)
  );
  const isLarge = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between(1840, 1900)
  );
  const xl = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between(1900, 1980)
  );
  const xxl = useMediaQuery((theme: Theme) => theme.breakpoints.up(1980));

  const size = useMemo(() => {
    if (canWriteUser && !expanded && isMedium) return 1.1;
    if (canWriteUser && expanded && isMedium) return 0.84;
    if (canWriteUser && !expanded && isLarge) return 1.3;
    if (canWriteUser && expanded && isLarge) return 0.92;
    if (canWriteUser && !expanded && xl) return 1.25;
    if (canWriteUser && expanded && xl) return 0.95;
    if (canWriteUser && !expanded && xxl) return 1.4;
    if (canWriteUser && expanded && xxl) return 1;

    if (!canWriteUser && xs) return 1.1;
    if (!canWriteUser && isMedium) return 1.3;
    if (!canWriteUser && isLarge) return 1.4;
    if (!canWriteUser && xl) return 1.45;
    if (!canWriteUser && xxl) return 1.5;
    return 1;
  }, [canWriteUser, expanded, isLarge, xl, xxl, xs, isMedium]);

  //current location
  const location = useMemo(() => {
    const findRelay = relay?.find(
      (item) => item?.location?.id === formData[selectedLocation]?.location?.id
    );

    return findRelay;
  }, [relay, formData, selectedLocation]);

  const getGroup = (groupId: number) => {
    const newData = location?.data?.filter(
      (item) => item.entity.group === groupId
    );

    let deleteData: { operator: string; caex: string | null }[] = [];
    newData?.forEach((item) => {
      let newModalData: { operator: string; caex: string | null } = {
        operator: item.entity.title,
        caex: "",
      };
      const trucks = item?.details?.filter(
        (detail) => detail.imageId === "truck"
      );
      const lunch = item?.details?.filter(
        (detail) => detail.imageId === "lunch"
      );

      let auxCaex = null;

      if (lunch.length) {
        const firstLunch = lunch?.reduce((prev, current) => {
          const currentDate = new Date(current.since).getTime();
          const minDate = prev ? new Date(prev.since).getTime() : Infinity;

          return currentDate < minDate ? current : prev;
        });

        trucks?.forEach((element) => {
          if (new Date(element?.since) < new Date(firstLunch?.since)) {
            auxCaex = element?.title;
          }
        });
      }
      newModalData.caex = auxCaex;
      deleteData.push(newModalData);
    });

    return deleteData;
  };

  const dataPlanby = useMemo(() => {
    const uniqueValuesGroup: any = {};
    const excavsFilter = Object.keys(switches).filter((key) => switches[key]);
    let colors = [...theme.planByTheme.entityColor];
    const data: PlanByData = { channels: [], epg: [] };
    if (location?.data?.length) {
      location?.data?.forEach((ent) => {
        if (!colors.length) {
          colors = [...theme.planByTheme.entityColor];
        }
        if (!uniqueValuesGroup[ent?.entity?.group]) {
          uniqueValuesGroup[ent?.entity?.group] = colors?.shift();
        }
        ent.entity.color = uniqueValuesGroup[ent?.entity?.group];

        if (excavsFilter.includes(ent.entity.excav)) {
          data.channels.push({
            uuid: ent.entity.id,
            title: ent.entity.title,
            group: ent.entity.group,
            color: ent.entity.color,
            relayId: location.relayId ?? 0,
          });
          ent.details.forEach((det) => {
            data.epg.push({
              id: det.id,
              title: det.title,
              since: det.since,
              till: det.till,
              channelUuid: ent.entity.id,
              description: "",
              image: det.imageId,
            });
          });
        }
      });

      return {
        channels: data.channels.sort((a: any, b: any) => a.group - b.group),
        epg: data.epg,
      };
    }
    return {
      channels: data.channels,
      epg: data.epg,
    };
  }, [location, switches, theme]);

  return (
    <Grid container style={{ height: "100%" }}>
      <Grid item xs={12}>
        {isLoading ? (
          <Loading />
        ) : formData[selectedLocation]?.statusForm?.status === "ACCEPTED" ||
          location?.data?.length ? (
          <Grid container style={{ width: "100%", height: "700px" }}>
            {dataPlanby?.channels?.length ? (
              <PlanBy
                dataPlanby={dataPlanby}
                dataRelay={location}
                size={size}
                getGroup={getGroup}
                openModal={openModal}
                setOpenModal={setOpenModal}
                Modal={
                  <Restricted to={Can.WRITE} at={Module.OPERATOR_ASSIGNMENT}>
                    <ModalDelete
                      openModal={openModal}
                      setOpenModal={setOpenModal}
                    />
                  </Restricted>
                }
              />
            ) : Object.keys(switches).length ? (
              <Grid container>
                <Grid item xs={12} style={{ alignSelf: "center" }}>
                  <WithoutData
                    title={`No se encontraron datos para generar esta visualización.`}
                    subtitle={"Intente modificar los filtros seleccionados"}
                  />
                </Grid>
              </Grid>
            ) : (
              <NotFoundRelays />
            )}
          </Grid>
        ) : formData[selectedLocation]?.statusForm?.status === "FINISHED" ? (
          <WithoutProgramming />
        ) : currentLocation?.statusForm?.status === "PENDING" &&
          currentLocation.step === "Selección" ? (
          <ValidationView
            formData={currentLocation.validationForm}
            targetValues={targetValues}
          />
        ) : canWriteUser ? (
          <InitView />
        ) : (
          <NotFoundRelaysViewer />
        )}
      </Grid>
    </Grid>
  );
};
