import React, { SVGAttributes, useCallback, useMemo } from "react";
import {
  makeStyles,
  Paper,
  Theme,
  useTheme,
  Typography,
} from "@material-ui/core";
import {
  Bar as BarGraph,
  BarDatum,
  BarTooltipProps,
  ComputedDatum,
} from "@nivo/bar";
import { AxisProps, AxisTickProps } from "@nivo/axes";
import { AutoSizer } from "components";
import {
  PracticeEnum,
  PerformanceRatingOperatorTop10,
  PerformanceRatingOperator,
} from "interfaces";
import { AppRoute, getTspanGroups } from "utils";
import { animated } from "@react-spring/web";
import { BarGraphTooltip } from ".";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";

interface PerformanceRatingsBarGraphProps {
  keys?: PracticeEnum[];
  data: PerformanceRatingOperatorTop10[];
  loading: boolean;
}

export const PerformanceRatingsBarGraph: React.FC<
  PerformanceRatingsBarGraphProps
> = ({ data, keys, loading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const leftAxisProps: AxisProps = useMemo(
    () => ({
      tickSize: 8.5,
      tickPadding: 5,
      tickRotation: 0,
      tickValues: 2,
      renderTick: (props) => renderLeftAxis({ ...props, theme }),
    }),
    [theme]
  );

  const bottomAxisProps: AxisProps = useMemo(
    () => ({
      tickSize: 0,
      tickPadding: 16,
      tickRotation: 0,
      renderTick: (props) =>
        renderBottomAxis({
          ...props,
          theme,
        }),
    }),
    [theme]
  );

  const onBarClick = useCallback(
    (e: ComputedDatum<BarDatum>) =>
      navigate({
        pathname: AppRoute.PERFORMANCE_RATINGS_SUMMARY,
        search: `?${createSearchParams({
          operatorId: (e.data.operator as unknown as PerformanceRatingOperator)
            .id,
        })}`,
      }),
    [navigate]
  );

  return (
    <Paper elevation={1} className={classes.graphContainer}>
      {!loading && data.length === 0 ? (
        <div className={clsx(classes.graphContainer, classes.noData)}>
          <InfoOutlinedIcon color="disabled" className={classes.infoIcon} />
          <Typography color="textSecondary" variant="h5">
            No hay datos para esta selección en este período
          </Typography>
        </div>
      ) : (
        <>
          <div className={classes.graphHeader}>
            <Typography variant="subtitle1">
              <b>
                Promedio
                <br />
                (minutos)
              </b>
            </Typography>
          </div>
          <AutoSizer>
            {({ width, height }) => (
              <BarGraph
                height={height}
                width={width}
                theme={theme.graph}
                colors={({ id }) =>
                  theme.palette.performanceRatingsColors[id as PracticeEnum]
                }
                data={data as unknown as BarDatum[]}
                keys={keys}
                borderRadius={4}
                indexBy="operator.name"
                margin={{ top: 10, bottom: 118, right: 24, left: 61 }}
                padding={0.4}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                axisBottom={bottomAxisProps}
                axisLeft={leftAxisProps}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={theme.palette.getContrastText(
                  theme.palette.text.primary
                )}
                tooltip={renderTooltip}
                onClick={onBarClick}
              />
            )}
          </AutoSizer>
        </>
      )}
    </Paper>
  );
};

interface RenderAxisProps extends AxisTickProps<any> {
  theme: Theme;
}

const renderLeftAxis = ({
  value,
  textAnchor,
  textBaseline,
  animatedProps,
  theme,
}: RenderAxisProps) => {
  if (value === 0) return <></>;
  return (
    <animated.g
      transform={animatedProps.transform}
      style={{
        opacity: animatedProps.opacity,
      }}
    >
      <animated.text
        dominantBaseline={
          textBaseline as SVGAttributes<SVGTextElement>["alignmentBaseline"]
        }
        textAnchor={textAnchor}
        transform={animatedProps.textTransform}
        style={theme.graph.axis?.ticks?.text}
      >
        {value}
      </animated.text>
    </animated.g>
  );
};

const renderBottomAxis = ({
  value,
  textAnchor,
  textBaseline,
  animatedProps,
  theme,
  onClick,
}: RenderAxisProps) => {
  return (
    <animated.g
      transform={animatedProps.transform}
      style={{
        opacity: animatedProps.opacity,
      }}
    >
      <animated.text
        dominantBaseline={
          textBaseline as SVGAttributes<SVGTextElement>["alignmentBaseline"]
        }
        textAnchor={textAnchor}
        transform={animatedProps.textTransform}
        style={theme.graph.axis?.ticks?.text}
      >
        {getTspanGroups(value, 13, 5)}
      </animated.text>
    </animated.g>
  );
};

const renderTooltip = ({
  id,
  value,
  indexValue,
  color,
}: BarTooltipProps<BarDatum>) => (
  <BarGraphTooltip
    color={color}
    indexValue={indexValue}
    value={value}
    id={id}
  />
);

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    flex: "1 1 auto",
    maxHeight: 500,
    padding: 24,
  },
  graphHeader: {
    display: "flex",
    alignItems: "end",
    marginBottom: 10,
  },
  loading: {
    justifyContent: "center",
    alignItems: "center",
  },
  noData: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  infoIcon: {
    width: "4em",
    height: "4em",
    marginBottom: 15,
  },
}));
