import React from "react";
import { SvgIconProps } from "@material-ui/core";
import {
  AssignedTruckIcon,
  ExcavatorIcon,
  FullTruckIcon,
  FillTruckIcon,
  NoOperatorTruckIcon,
  OneHrReleaseTruckIcon,
  OperatorIcon,
  StockpileIcon,
  UnplannedWaitTruckIcon,
  YellowTruckIcon,
  LunchIcon,
  DinnerIcon,
  FuelIcon,
  ImmobTruckIcon,
  LostTruckIcon,
  CalculatorIcon,
  PlannedStockPileIcon,
  PerformanceTruckIcon,
  PostPublicationAssignedIcon
} from "components/icons";
import { RefetchFunctionType } from "hooks";
import { CardAlertType } from "./truckInfo.interface";
import { parseNumber } from "utils";
import { CycleTimeIcon } from "components/icons/CycleTimeIcon";
import { YellowTruckAdherenceIcon } from "components/icons/YellowTruckAdherenceIcon";
import { CycleTimeKpis } from "./heatMap.interface";
import { KpiTypeVelocity, Level } from "./velocity.interface";
import { FullTruckVelocityIcon } from "components/icons/FullTruckVelocityIcon";
import { EmptyTruckVelocityIcon } from "components/icons/EmptyTruckVelocityIcon";

export enum KpiType {
  ASSIGNED_TRUCKS = "ASSIGNED_TRUCKS",
  AVAILABLE_TRUCKS = "AVAILABLE_TRUCKS",
  MAINTENANCE_TRUCKS = "MAINTENANCE_TRUCKS",
  ONE_HR_RELEASE_MAINTENANCE_TRUCKS = "ONE_HR_RELEASE_MAINTENANCE_TRUCKS",
  NO_OPERATOR_TRUCKS = "NO_OPERATOR_TRUCKS",
  UNPLANNED_WAIT_TRUCKS = "UNPLANNED_WAIT_TRUCKS",
  AVAILABLE_OPERS = "AVAILABLE_OPERS",
  EXCAV_QUEUE_AVG_MINUTES = "EXCAV_QUEUE_AVG_MINUTES",
  EXCAV_HANG_AVG_MINUTES = "EXCAV_HANG_AVG_MINUTES",
  CRUSHER_QUEUE_AVG_MINUTES = "CRUSHER_QUEUE_AVG_MINUTES",
  LOADTONS = "LOADTONS",
  PROJECTED_LOADTONS = "PROJECTED_LOADTONS",
  LUNCH = "LUNCH",
  DINNER = "DINNER",
  FUEL_LOAD = "FUEL_LOAD",
  IMMOB = "IMMOB",
  LOST_TRUCK = "LOST_TRUCK",
  TOTAL = "TOTAL",
  TRUCK_UTILIZATION = "TRUCK_UTILIZATION",
  UTILIZATION = "UTILIZATION",
  AVAILABILITY = "AVAILABILITY",
  PERFORMANCE_TRUCKS = "PERFORMANCE_TRUCKS",
  CYCLE_TIME = "CYCLE_TIME",
  ADHERENCE = "ADHERENCE",
  FULFILLMENT = "FULFILLMENT",
  AVAILABLE_CAEX = "AVAILABLE_CAEX",
  TRUCKS_ASSIGNED = "TRUCKS_ASSIGNED",
}

export enum KpiTypePerformance {
  FULL_MOVEMENT = "FULL_MOVEMENT",
  CONTRIBUTION_MOVEMENT = "CONTRIBUTION_MOVEMENT",
  CYCLE_TIME = "CYCLE_TIME",
  IMPACT_TC = "IMPACT_TC",
  ADHERENCE = "ADHERENCE",
  ORIGIN = "ORIGIN",
  DESTINATION = "DESTINATION",
  GAP = "GAP",
  REAL = "REAL",
  TARGET = "TARGET",
  VELOCITY_FULL = "VELOCITY_FULL",
  VELOCITY_EMPTY = "VELOCITY_EMPTY",
  CONGESTION_LEVEL = "CONGESTION_LEVEL",
  TRUCK = "TRUCK",
  OPERATOR = "OPERATOR",
  CONGESTION = "CONGESTION",
  TRACE_CONGESTION = "TRACE_CONGESTION",
  EMPTY_VELOCITY = "EMPTY_VELOCITY",
  FULL_VELOCITY = "FULL_VELOCITY",
  CAEX_NUMBER = "CAEX_NUMBER",
}

export enum KpiCategory {
  EQUIPMENT = "EQUIPMENT",
  OPERATOR = "OPERATOR",
  LOADING_TRUCK = "LOADING_TRUCK",
  FULL_TRUCK = "FULL_TRUCK",
  EXCAVATOR = "EXCAVATOR",
  STOCKPILE = "STOCKPILE",
  PLANNED_STOCKPILE = "PLANNED_STOCKPILE",
}

export const timeBasedKpis: Set<KpiType> = new Set([
  KpiType.EXCAV_QUEUE_AVG_MINUTES,
  KpiType.EXCAV_HANG_AVG_MINUTES,
  KpiType.CRUSHER_QUEUE_AVG_MINUTES,
]);

export const KpiTypeTranslation: Record<KpiType, string> = {
  [KpiType.AVAILABLE_TRUCKS]: "CAEX Disponibles",
  [KpiType.ASSIGNED_TRUCKS]: "CAEX Armados",
  [KpiType.MAINTENANCE_TRUCKS]: "CAEX fuera de servicio",
  [KpiType.ONE_HR_RELEASE_MAINTENANCE_TRUCKS]: "CAEX por salir en 1h",
  [KpiType.NO_OPERATOR_TRUCKS]: "CAEX sin oper. | 4000",
  [KpiType.UNPLANNED_WAIT_TRUCKS]: "CAEX por armar | 4190",
  [KpiType.AVAILABLE_OPERS]: "Oper. disponibles",
  [KpiType.EXCAV_QUEUE_AVG_MINUTES]: "Cola Origen",
  [KpiType.EXCAV_HANG_AVG_MINUTES]: "Espera Pala",
  [KpiType.CRUSHER_QUEUE_AVG_MINUTES]: "Cola Chancado | Otro",
  [KpiType.LOADTONS]: "Mov. acumulado Hora | Expit Kt",
  [KpiType.PROJECTED_LOADTONS]: "Proyectado Total | Expit Kt",
  [KpiType.LUNCH]: "Colación día",
  [KpiType.DINNER]: "Colación noche",
  [KpiType.FUEL_LOAD]: "Combustible",
  [KpiType.IMMOB]: "Inmovilidad",
  [KpiType.LOST_TRUCK]: "Camión perdido",
  [KpiType.TOTAL]: "Total",
  [KpiType.TRUCK_UTILIZATION]: "Utilización",
  [KpiType.UTILIZATION]: "Utilización",
  [KpiType.AVAILABILITY]: "Disponibilidad",
  [KpiType.PERFORMANCE_TRUCKS]: "Rendimiento Ton/h",
  [KpiType.CYCLE_TIME]: "Tiempo de ciclo",
  [KpiType.ADHERENCE]: "Adherencia al plan",
  [KpiType.FULFILLMENT]: "Cumplimiento Mov.",
  [KpiType.AVAILABLE_CAEX]: "CAEX disponibles",
  [KpiType.TRUCKS_ASSIGNED]: "Asig. post publicación",
};

export const KpiTypePerformanceTranslation: Record<KpiTypePerformance, string> =
  {
    [KpiTypePerformance.FULL_MOVEMENT]: "Movimiento total (kt)",
    [KpiTypePerformance.CONTRIBUTION_MOVEMENT]: "Aporte al mov. Real",
    [KpiTypePerformance.CYCLE_TIME]: "Tiempo de ciclo (TC)",
    [KpiTypePerformance.IMPACT_TC]: "Impacto Real al TC",
    [KpiTypePerformance.ADHERENCE]: "Adherencia al plan",
    [KpiTypePerformance.ORIGIN]: "Origen",
    [KpiTypePerformance.DESTINATION]: "Destino",
    [KpiTypePerformance.REAL]: "Valor actual",
    [KpiTypePerformance.GAP]: "Brecha",
    [KpiTypePerformance.TARGET]: "Target",
    [KpiTypePerformance.VELOCITY_FULL]: "Vel. cargado [km/h]",
    [KpiTypePerformance.VELOCITY_EMPTY]: "Vel. vacío [km/h]",
    [KpiTypePerformance.CONGESTION_LEVEL]: "Congestión",
    [KpiTypePerformance.OPERATOR]: "Operador",
    [KpiTypePerformance.TRUCK]: "",
    [KpiTypePerformance.TRACE_CONGESTION]: "Tramo | Congestión",
    [KpiTypePerformance.CONGESTION]: "Congestión",
    [KpiTypePerformance.EMPTY_VELOCITY]: "Vel. vacío [km/h]",
    [KpiTypePerformance.FULL_VELOCITY]: "Vel. cargado [km/h]",
    [KpiTypePerformance.CAEX_NUMBER]: "Cantidad CAEX",
  };

export const KpiTypeIcon: Record<
  KpiType,
  React.FC<SvgIconProps<"svg", {}>> | undefined
> = {
  [KpiType.AVAILABLE_TRUCKS]: YellowTruckIcon,
  [KpiType.ASSIGNED_TRUCKS]: AssignedTruckIcon,
  [KpiType.MAINTENANCE_TRUCKS]: YellowTruckIcon,
  [KpiType.ONE_HR_RELEASE_MAINTENANCE_TRUCKS]: OneHrReleaseTruckIcon,
  [KpiType.NO_OPERATOR_TRUCKS]: NoOperatorTruckIcon,
  [KpiType.UNPLANNED_WAIT_TRUCKS]: UnplannedWaitTruckIcon,
  [KpiType.AVAILABLE_OPERS]: OperatorIcon,
  [KpiType.EXCAV_QUEUE_AVG_MINUTES]: FillTruckIcon,
  [KpiType.EXCAV_HANG_AVG_MINUTES]: ExcavatorIcon,
  [KpiType.CRUSHER_QUEUE_AVG_MINUTES]: FullTruckIcon,
  [KpiType.LOADTONS]: StockpileIcon,
  [KpiType.PROJECTED_LOADTONS]: PlannedStockPileIcon,
  [KpiType.LUNCH]: LunchIcon,
  [KpiType.DINNER]: DinnerIcon,
  [KpiType.FUEL_LOAD]: FuelIcon,
  [KpiType.IMMOB]: ImmobTruckIcon,
  [KpiType.LOST_TRUCK]: LostTruckIcon,
  [KpiType.TOTAL]: CalculatorIcon,
  [KpiType.TRUCK_UTILIZATION]: YellowTruckIcon,
  [KpiType.UTILIZATION]: FullTruckIcon,
  [KpiType.AVAILABILITY]: YellowTruckIcon,
  [KpiType.PERFORMANCE_TRUCKS]: PerformanceTruckIcon,
  [KpiType.CYCLE_TIME]: CycleTimeIcon,
  [KpiType.ADHERENCE]: YellowTruckAdherenceIcon,
  [KpiType.FULFILLMENT]: StockpileIcon,
  [KpiType.AVAILABLE_CAEX]: YellowTruckIcon,
  [KpiType.TRUCKS_ASSIGNED]: PostPublicationAssignedIcon,

};

export enum ProjectionStatus {
  ON_PLAN = "ON_PLAN",
  WARNING = "WARNING",
}

export type KpiValue = {
  value: number | null;
  tooltipTexts?: string[];
  alertType?: CardAlertType;
  compareValue?: number;
};

export interface KpiData {
  type: KpiType;
  category?: KpiCategory;
  mainValue: KpiValue;
  secondaryValue?: KpiValue;
  projection?: {
    diff: number;
    isBelowPlan: boolean;
    alertType: ProjectionStatus;
  };
  secondaryProjection?: {
    diff: number;
    isBelowPlan: boolean;
    alertType: ProjectionStatus;
  };
}

interface MainValue {
  value: string | number | null;
  idIcon?: string;
}

interface Projection {
  diff: number | null;
  isBelowPlan: boolean;
  alertType: ProjectionStatus;
}

interface PriorityChip {
  level: Level;
  labelChip: string;
  idIcon?: string;
}

export interface DetailsPhase {
  type: KpiTypePerformance | KpiTypeVelocity;
  mainValue?: MainValue;
  projection?: Projection;
  priorityLabel?: Level;
  priorityChip?: PriorityChip;
  countValue?: MainValue;
}

export interface PhasesCard {
  currentShift?: string;
  lastUpdate?: Date | string;
  data: {
    id: string;
    columns: DetailsPhase[];
  }[];
}

export interface CaexCard {
  id: string;
  columns: DetailsPhase[];
}

export type ParserFunction = (value: number | null) => string;

const formatTimeValue: ParserFunction = (value: number | null) =>
  value !== null ? `${parseNumber(value, 1)}'` : "-";

// const formatTimeIntegerValue: ParserFunction = (value: number | null) =>
//   value !== null ? `${parseNumber(value, 0)}'` : "-";

const formatPercentage: ParserFunction = (value: number | null) =>
  value != null ? `${parseNumber(value * 100, 1)}%` : "-";

const formatNumber: ParserFunction = (value: number | null) =>
  value != null ? `${parseNumber(value, 0)}` : "-";

const formatFloat: ParserFunction = (value: number | null) =>
  value != null ? `${parseNumber(value, 1)}` : "-";

interface KpiParser {
  mainValue?: ParserFunction;
  secondaryValue?: ParserFunction;
  projection?: ParserFunction;
}

const loadtonsParser = (value: number | null) =>
  value != null ? `${parseNumber(value / 1000, 0)}` : "-";

export const KpiParsers: Record<KpiType, KpiParser | undefined> = {
  [KpiType.ASSIGNED_TRUCKS]: undefined,
  [KpiType.AVAILABLE_CAEX]: undefined,
  [KpiType.TRUCKS_ASSIGNED]: undefined,
  [KpiType.AVAILABLE_TRUCKS]: undefined,
  [KpiType.MAINTENANCE_TRUCKS]: undefined,
  [KpiType.ONE_HR_RELEASE_MAINTENANCE_TRUCKS]: undefined,
  [KpiType.NO_OPERATOR_TRUCKS]: { secondaryValue: formatTimeValue },
  [KpiType.UNPLANNED_WAIT_TRUCKS]: { secondaryValue: formatTimeValue },
  [KpiType.AVAILABLE_OPERS]: undefined,
  [KpiType.EXCAV_QUEUE_AVG_MINUTES]: {
    mainValue: formatTimeValue,
    projection: formatTimeValue,
  },
  [KpiType.EXCAV_HANG_AVG_MINUTES]: {
    mainValue: formatTimeValue,
    projection: formatTimeValue,
  },
  [KpiType.CRUSHER_QUEUE_AVG_MINUTES]: {
    mainValue: formatTimeValue,
    secondaryValue: formatTimeValue,
    projection: formatTimeValue,
  },
  [KpiType.LOADTONS]: {
    mainValue: loadtonsParser,
    secondaryValue: loadtonsParser,
    projection: loadtonsParser,
  },
  [KpiType.PROJECTED_LOADTONS]: {
    mainValue: loadtonsParser,
    secondaryValue: loadtonsParser,
  },
  [KpiType.LUNCH]: undefined,
  [KpiType.DINNER]: undefined,
  [KpiType.FUEL_LOAD]: undefined,
  [KpiType.IMMOB]: undefined,
  [KpiType.LOST_TRUCK]: undefined,
  [KpiType.TOTAL]: undefined,
  [KpiType.TRUCK_UTILIZATION]: { mainValue: formatPercentage },
  [KpiType.UTILIZATION]: { mainValue: formatPercentage },
  [KpiType.AVAILABILITY]: { mainValue: formatPercentage },
  [KpiType.PERFORMANCE_TRUCKS]: {
    mainValue: formatFloat,
    secondaryValue: formatFloat,
  },
  [KpiType.CYCLE_TIME]: {
    mainValue: formatTimeValue,
    projection: formatTimeValue,
  },
  [KpiType.ADHERENCE]: { mainValue: formatPercentage },
  [KpiType.FULFILLMENT]: { mainValue: formatPercentage },
};

export const KpiTypePerformanceParsers: Record<
  KpiTypePerformance,
  KpiParser | undefined
> = {
  //phases
  [KpiTypePerformance.FULL_MOVEMENT]: {
    mainValue: formatFloat,
    projection: formatFloat,
  },
  [KpiTypePerformance.CONTRIBUTION_MOVEMENT]: { mainValue: formatPercentage },
  [KpiTypePerformance.CYCLE_TIME]: {
    mainValue: formatTimeValue,
    projection: formatTimeValue,
  },
  [KpiTypePerformance.IMPACT_TC]: {
    projection: formatTimeValue,
  },
  [KpiTypePerformance.ADHERENCE]: { mainValue: formatPercentage },
  [KpiTypePerformance.ORIGIN]: undefined,
  [KpiTypePerformance.DESTINATION]: undefined,
  [KpiTypePerformance.GAP]: undefined,
  [KpiTypePerformance.CONGESTION_LEVEL]: undefined,
  [KpiTypePerformance.REAL]: {
    mainValue: formatTimeValue,
    projection: formatTimeValue,
  },
  [KpiTypePerformance.TARGET]: { mainValue: formatTimeValue },
  [KpiTypePerformance.VELOCITY_FULL]: {
    mainValue: formatFloat,
    projection: formatFloat,
  },
  [KpiTypePerformance.VELOCITY_EMPTY]: {
    mainValue: formatFloat,
    projection: formatFloat,
  },
  [KpiTypePerformance.OPERATOR]: undefined,
  [KpiTypePerformance.CONGESTION]: undefined,
  [KpiTypePerformance.EMPTY_VELOCITY]: {
    mainValue: formatFloat,
    projection: formatFloat,
  },
  [KpiTypePerformance.FULL_VELOCITY]: {
    mainValue: formatFloat,
    projection: formatFloat,
  },
  [KpiTypePerformance.TRUCK]: undefined,
  [KpiTypePerformance.TRACE_CONGESTION]: undefined,
  [KpiTypePerformance.CAEX_NUMBER]: { mainValue: formatNumber },
};

export const HeatMapPerformaceParser: Record<CycleTimeKpis, KpiParser> = {
  [CycleTimeKpis.ADHERENCE]: { mainValue: formatPercentage },
  [CycleTimeKpis.IMPACT_TC]: {
    mainValue: formatFloat,
    projection: formatNumber,
  },
  [CycleTimeKpis.MOVEMENT]: {
    mainValue: formatFloat,
    projection: formatNumber,
  },
  [CycleTimeKpis.CYCLE_TIME]: { mainValue: formatFloat },
  [CycleTimeKpis.DUMPING_TIME]: { mainValue: formatFloat },
  [CycleTimeKpis.IDLE_TIME]: { mainValue: formatFloat },
  [CycleTimeKpis.FULL_HAUL_DISTANCE]: { mainValue: formatFloat },
  [CycleTimeKpis.LOADING_TIME]: { mainValue: formatFloat },
  [CycleTimeKpis.SPOT_TIME]: { mainValue: formatFloat },
  [CycleTimeKpis.QUEUE_TIME]: { mainValue: formatFloat },
};

export enum PrKpiDetailEnum {
  AVG_TIME = "AVG_TIME",
  TOTAL_OPERATORS = "TOTAL_OPERATORS",
  ACC_TIME = "ACC_TIME",
}

export type PrKpiDetailsHash = {
  [key in PrKpiDetailEnum]: number;
};

export const prKpiDetailTranslation: Record<PrKpiDetailEnum, string> = {
  [PrKpiDetailEnum.AVG_TIME]: "Promedio",
  [PrKpiDetailEnum.TOTAL_OPERATORS]: "Operadores",
  [PrKpiDetailEnum.ACC_TIME]: "Acumulado",
};

export interface PrKpiData {
  practice: KpiType;
  details: PrKpiDetailsHash;
  maxLimitTime?: number;
  excessOverLimit?: number;
}

export const orderHash: Record<KpiType, number> = Object.assign(
  {},
  ...Object.values(KpiType).map((key, idx) => ({ [key]: idx }))
);

export type RefetchKpiFunctionType = RefetchFunctionType<KpiData[]>;

interface PropsIcons {
  icons: { [key: string]: React.FC<SvgIconProps<"svg", {}>> };
}
export const CardIcons: PropsIcons = {
  icons: {
    FULL_TRUCK: FullTruckVelocityIcon,
    EMPTY_TRUCK: EmptyTruckVelocityIcon,
    TRUCK: EmptyTruckVelocityIcon,
  },
};
