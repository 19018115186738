import React, { createContext } from "react";
import { DataContextInterface, ErrorMsg, initialDataContextState } from "interfaces";
import { ContextProviderProps } from "contexts";
import { useData, usePathnameChange } from "hooks";
import { STANDARD_POLLING } from "App";
import { AppRoute } from "utils";

interface StatusInterface {
    status: string
}

export const OperatorsRelieveStatusContext = createContext<
    DataContextInterface<StatusInterface>
>(initialDataContextState);

export const OperatorsRelieveStatusProvider: React.FC<ContextProviderProps> = ({
    children,
  }) => {
    const { refetch, ...other } = useData<StatusInterface>(
      {
        config: "/operator-assignment/relay/status-view",
        options: {
          useCache: false,
        },
        ...STANDARD_POLLING,
      },
      ErrorMsg.GET_STATUS_RELAY_VIEW,
    );

    usePathnameChange(() => refetch(), [AppRoute.TA_PERFORMANCE_SCREEN]);

  return (
    <OperatorsRelieveStatusContext.Provider value={{ refetch, ...other }}>
      {children}
    </OperatorsRelieveStatusContext.Provider>
  );
};