import { Can, User, Module } from "interfaces";
import { AppRoute } from "./routes";

export const firstRouteMapping: Record<Module, AppRoute> = {
  [Module.OPERATOR_ASSIGNMENT]: AppRoute.TRUCKS_DASHBOARD,
  [Module.TRUCK_ASSIGNMENT]: AppRoute.TA_BALANCE_SCREEN,
  [Module.PERFORMANCE_RATINGS]: AppRoute.PERFORMANCE_RATINGS,
  [Module.ADMINISTRATION_PANEL]: AppRoute.ADMIN,
  [Module.SHIFT_CHANGE]: AppRoute.SC_SHIFT_CHANGE_OPERATOR_SCREEN,
};

export const getFirstRoute = (user?: User | null) =>
  (Object.keys(firstRouteMapping) as Module[]).reduce<AppRoute | null>(
    (result, moduleName) =>
      result ??
      (user?.permissions[moduleName][Can.READ]
        ? firstRouteMapping[moduleName]
        : null),
    null
  );
