import React, { useCallback, useContext } from "react";
import { ChipGroup } from "components";
import { DailyPlanContext } from "contexts";

interface ShiftAttributes {
  id: string;
  name: string;
}

export const TruckAssignmentDailyPlanShiftOptions: React.FC = () => {
  const { currentShiftOption, setCurrentShiftOption, data } =
    useContext(DailyPlanContext);

  const shiftOptions: ShiftAttributes[] = data.dailyShifts.map((shift) => ({
    id: shift.shiftStartDate,
    name: shift.shiftName,
  }));
  const onChipOptionChange = useCallback(
    (value: string | number) => {
      setCurrentShiftOption(value as string);
    },
    [setCurrentShiftOption]
  );

  return (
    <ChipGroup
      onChange={onChipOptionChange}
      value={currentShiftOption}
      options={shiftOptions}
    />
  );
};
