import React, { createContext } from "react";
import {
  ErrorMsg,
  KpiData,
  DataContextInterface,
  initialDataContextState,
} from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { STANDARD_POLLING } from "App";
import { ContextProviderProps } from ".";
import { AppRoute } from "utils";

export const OperatorAssignmentKpisContext = createContext<
  DataContextInterface<KpiData[]>
>(initialDataContextState);

export const OperatorAssignmentKpisProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { refetch, ...other } = useData<KpiData[]>(
    {
      config: "/operator-assignment/kpi",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_KPIS
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(
    () => refetch(),
    [
      AppRoute.TRUCKS_DASHBOARD,
      AppRoute.OPERATORS_SCREEN,
      AppRoute.UNPLANNED_LOCATIONS_SCREEN,
    ]
  );

  return (
    <OperatorAssignmentKpisContext.Provider value={{ refetch, ...other }}>
      {children}
    </OperatorAssignmentKpisContext.Provider>
  );
};
