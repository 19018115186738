import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const DataBaseIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 18 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3414 2.85715V4.64285C17.3414 6.21652 13.4576 7.5 8.67068 7.5C3.88373 7.5 0 6.21652 0 4.64285V2.85715C0 1.28348 3.88373 0 8.67068 0C13.4576 0 17.3414 1.28348 17.3414 2.85715ZM17.3414 6.875V10.8929C17.3414 12.4665 13.4576 13.75 8.67068 13.75C3.88373 13.75 0 12.4665 0 10.8929V6.875C1.86284 8.16965 5.2724 8.77234 8.67068 8.77234C12.069 8.77234 15.4785 8.16965 17.3414 6.875ZM17.3414 17.1429V13.125C15.4785 14.4196 12.069 15.0223 8.67068 15.0223C5.2724 15.0223 1.86284 14.4196 0 13.125V17.1429C0 18.7165 3.88373 20 8.67068 20C13.4576 20 17.3414 18.7165 17.3414 17.1429Z"
      />
    </SvgIcon>
  );
};
