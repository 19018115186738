import React, { createContext } from "react";
import {
  DataContextInterface,
  initialDataContextState,
  Operator,
  ErrorMsg,
} from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { ContextProviderProps } from "contexts";
import { STANDARD_POLLING } from "App";
import { AppRoute } from "utils";
import { useParams } from "react-router";


export const OperatorPreArmadoFilterByTruckContext = createContext<DataContextInterface<Operator[]>>(
  initialDataContextState
);

export const OperatorsPreArmadoFilterProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  
  const { equipId } = useParams<"equipId">();

  const { refetch, ...other } = useData<Operator[]>(
    {
      config: `/operator-assignment/shift-crew/pre-assembled-operators/${equipId}`,
      options: { useCache: false },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_OPERATORS
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(
    () => refetch(),
    [AppRoute.TRUCK_DETAILS, AppRoute.PRE_ASSEMBLE_TRUCK_DETAILS]
  );

  return (
    <OperatorPreArmadoFilterByTruckContext.Provider value={{ ...other, refetch }} >
      {children}
    </OperatorPreArmadoFilterByTruckContext.Provider>
  );
};
