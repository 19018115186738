export enum Module {
  OPERATOR_ASSIGNMENT = "OPERATOR_ASSIGNMENT",
  TRUCK_ASSIGNMENT = "TRUCK_ASSIGNMENT",
  PERFORMANCE_RATINGS = "PERFORMANCE_RATINGS",
  ADMINISTRATION_PANEL = "ADMINISTRATION_PANEL",
  SHIFT_CHANGE = "SHIFT_CHANGE",
}

export enum Can {
  READ = "CAN_READ",
  WRITE = "CAN_WRITE",
}

export const moduleTranslation: Record<Module, string> = {
  [Module.OPERATOR_ASSIGNMENT]: "Armado",
  [Module.TRUCK_ASSIGNMENT]: "Asignación",
  [Module.PERFORMANCE_RATINGS]: "Prácticas",
  [Module.ADMINISTRATION_PANEL]: "Administración",
  [Module.SHIFT_CHANGE]: "Cambio de Turno",
};
