import React from "react";
import { BarCustomLayerProps, BarDatum } from "@nivo/bar";
import { useAnimatedPath } from "@nivo/core";
import { line } from "d3-shape";
import { animated } from "@react-spring/web";

interface TargetLayerProps extends BarCustomLayerProps<BarDatum> {
  planData: BarDatum[];
  color: string;
}

export const TargetLineLayer: React.FC<TargetLayerProps> = ({
  bars,
  xScale,
  yScale,
  planData,
  color,
}) => {
  const lineGenerator = line<BarDatum>()
    .x((data) => {
      return xScale(data.hour as unknown as number) + bars[0].width / 2;
    })
    .y((data) => yScale((data.value as number) ?? 0));

  const animatedPath = useAnimatedPath(lineGenerator(planData) ?? "");
  return (
    <animated.path
      d={animatedPath}
      fill="none"
      stroke={color}
      strokeDasharray={"10,10"}
      style={{ pointerEvents: "none", strokeWidth: 4 }}
    />
  );
};
