import {
  AutoSizer as _AutoSizer,
  List as _List,
  ListProps,
  AutoSizerProps,
  Grid as _Grid,
  GridProps,
  CellMeasurer as _CellMeasurer,
  CellMeasurerProps,
  ColumnSizer as _ColumnSizer,
  ColumnSizerProps,
} from "react-virtualized";

//! This fix is temporary and it needs to be done each time you use an component from 'react-virtualized'
export const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>;
export const List = _List as unknown as React.FC<ListProps>;
export const Grid = _Grid as unknown as React.FC<GridProps>;
export const CellMeasurer =
  _CellMeasurer as unknown as React.FC<CellMeasurerProps>;
export const ColumnSizer =
  _ColumnSizer as unknown as React.FC<ColumnSizerProps>;
