import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { acquireToken, logout as msalLogout } from "utils";
import { User, Module, Can, ShiftInfo, ErrorMsg } from "interfaces";
import { logError } from "services";
import { ContextProviderProps } from "contexts";
import { useAxios, useData } from "hooks";
import { useSnackbar } from "notistack";
// import { DATA_REFETCH_MINUTES } from "App";

type UserStateInterface = {
  user: User | null;
  shiftInfo: ShiftInfo | null;
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  isAllowedTo: (to: Can, at: Module) => boolean;
  loadingUser: boolean;
  loadingShiftInfo: boolean;
  logout: () => void;
  logoutWithError: (params: LogoutWithErrorParams) => void;
  fetchedToken: boolean;
};

interface LogoutWithErrorParams {
  TAG: string;
  errorMsg: string;
  snackbarMsg: string;
}

export const UserContext = createContext<UserStateInterface>({
  user: null,
  shiftInfo: null,
  token: null,
  setToken: () => null,
  isAllowedTo: (to: Can, at: Module) => false,
  loadingUser: true,
  loadingShiftInfo: false,
  logout: () => null,
  logoutWithError: (params: LogoutWithErrorParams) => null,
  fetchedToken: true,
});

export const UserProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const [fetchedToken, setFetchedToken] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [
    { data: userInfo, refetchLoading, firstLoading, error },
    checkUserInfoQuery,
  ] = useAxios<User>({
    config: {
      url: "/me",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    options: {
      manual: true,
    },
  });

  const {
    data: shiftInfo,
    refetching: refetchinfShiftInfo,
    firstLoading: firstLoadingShiftInfo,
    refetch: getShiftInfo,
  } = useData<ShiftInfo, unknown>(
    {
      config: {
        url: "/shift-info",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.GET_SHIFT_INFO
  );

  const logout = useCallback(() => {
    setToken(null);
    msalLogout(instance, account);
  }, [account, instance, setToken]);

  const logoutWithError = useCallback(
    ({ TAG, errorMsg, snackbarMsg }: LogoutWithErrorParams) => {
      logError(TAG, errorMsg);
      enqueueSnackbar(snackbarMsg, {
        variant: "error",
      });
      setInterval(() => {
        logout();
      }, 3000);
    },
    [enqueueSnackbar, logout]
  );

  const isAllowedTo = useCallback(
    (to: Can, at: Module) => Boolean(userInfo?.permissions[at][to]),
    [userInfo]
  );

  useEffect(() => {
    if (token) {
      const headerParams = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      getShiftInfo(headerParams);
    }
  }, [getShiftInfo, token]);

  useEffect(() => {
    if (!error) return;
    if (error.response?.status === 401) {
      logoutWithError({
        TAG: "UserContext",
        errorMsg: `El usuario ${account?.username} no tiene autorización para usar la plataforma`,
        snackbarMsg: `Usted no tiene acceso a la plataforma`,
      });
    } else {
      logoutWithError({
        TAG: "UserContext",
        errorMsg: `Falló la consulta checkUserInfo a la API`,
        snackbarMsg: `Ocurrió un error inesperado`,
      });
    }
  }, [account?.username, error, logoutWithError]);

  const userState = useMemo<UserStateInterface>(
    () => ({
      user: userInfo ?? null,
      shiftInfo: shiftInfo ?? null,
      token,
      setToken,
      isAllowedTo,
      loadingUser: refetchLoading || firstLoading,
      loadingShiftInfo: refetchinfShiftInfo || firstLoadingShiftInfo,
      logout,
      logoutWithError,
      fetchedToken,
    }),
    [
      userInfo,
      shiftInfo,
      token,
      isAllowedTo,
      refetchLoading,
      firstLoading,
      refetchinfShiftInfo,
      firstLoadingShiftInfo,
      logout,
      logoutWithError,
      fetchedToken,
    ]
  );

  useEffect(() => {
    if (token)
      checkUserInfoQuery({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  }, [token, checkUserInfoQuery]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!instance || !account) return;
      const tokenResponse = await acquireToken(instance, account);
      if (!tokenResponse?.accessToken) {
        logoutWithError({
          TAG: "UserContext",
          errorMsg: `El usuario ${account.username} no pudo refrescar el token`,
          snackbarMsg: `Ocurrió un error al autenticar la sesión`,
        });
        return;
      }
      const { accessToken } = tokenResponse;
      if (accessToken !== token) {
        setToken(accessToken);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [account, instance, token, enqueueSnackbar, logoutWithError]);

  useEffect(() => {
    if (token) setFetchedToken(true);
  }, [token]);

  return (
    <UserContext.Provider value={userState}>{children}</UserContext.Provider>
  );
};
