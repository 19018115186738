import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const CycleTimeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon 
      width="69" 
      height="68" 
      viewBox="0 0 69 68" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      >
      <g clipPath="url(#clip0_9393_100431)">
      <path d="M31.6719 18.4997C24.0186 22.9095 17.7806 31.7475 18.0056 41.3481C18.0875 44.8186 18.9002 47.5452 21.8741 50.1323C26.6187 54.2593 28.5678 54.6183 28.5678 54.6183C28.5678 54.6183 27.928 51.583 31.2534 49.6654C38.9068 45.2538 45.1671 34.4276 45.1671 25.6063C45.1671 22.6844 47.3003 20.235 47.3003 20.235L43.8484 18.2114C40.3723 16.1637 35.9254 16.0447 31.6719 18.496V18.4997Z" 
        fill="#E2832D"/>
      <path d="M37.166 21.0394C29.5126 25.451 23.2523 36.2773 23.2523 45.0986C23.2523 53.9199 29.5145 57.5281 37.166 53.1165C44.8193 48.7049 51.0796 37.8786 51.0796 29.0573C51.0796 20.2359 44.8174 16.6278 37.166 21.0394Z" 
        fill="#EEA32D"/>
      <path d="M37.4449 22.7206C30.7271 26.6375 25.2293 36.2492 25.2293 44.0811C25.2293 51.913 30.7271 55.1176 37.4449 51.2007C44.1627 47.2838 49.6605 37.672 49.6605 29.8402C49.6605 22.0083 44.1627 18.8037 37.4449 22.7206Z" 
        fill="#FFFAE9"/>
      <path d="M40.4133 21.365C41.3823 22.838 41.9346 24.8541 41.9346 27.3351C41.9346 35.167 36.4369 44.7806 29.7191 48.6956C28.6645 49.3113 27.6397 49.7502 26.6614 50.0236C28.7482 53.1984 32.7655 53.8475 37.3557 51.173C44.0735 47.2561 49.5712 37.6443 49.5712 29.8125C49.5712 23.2099 45.6655 19.8975 40.4133 21.365Z" 
        fill="#FFE592"/>
      <path d="M37.6607 34.6558C36.7624 35.1802 36.0258 36.4654 36.0258 37.5144C36.0258 38.5633 36.7624 38.9911 37.6607 38.4666C38.559 37.9421 39.2955 36.657 39.2955 35.608C39.2955 34.559 38.559 34.1313 37.6607 34.6558Z" 
        fill="#874226"/>
      <g opacity="0.23">
      <path d="M43.8001 21.0938C46.4504 22.1669 48.141 25.0832 48.141 29.4241C48.141 37.256 42.6433 46.8677 35.9254 50.7846C33.6006 52.1386 31.4246 52.6408 29.5684 52.3952C31.6998 53.2582 34.4487 52.9309 37.4431 51.1845C44.1609 47.2676 49.6587 37.6558 49.6587 29.8239C49.6587 24.7019 47.3059 21.5587 43.7982 21.0938H43.8001Z" 
        fill="#A7A7DB"/>
      </g>
      <path d="M36.9576 18.609L39.4145 19.9276V15.7894L36.9576 14.4727V18.609Z" 
        fill="#DFE2FC"/>
      <path d="M32.8659 20.3719L35.7952 21.9416V17.0111L32.8659 15.4414V20.3719Z" 
        fill="#CA722E"/>
      <path d="M35.7952 17.0095L39.5447 15.0566V19.9871L35.7952 21.94V17.0095Z" 
        fill="#EEA32D"/>
      <path d="M29.8176 14.5461L33.0259 16.3948L42.6488 10.8487L39.4406 9L29.8176 14.5461Z" 
        fill="#E2832D"/>
      <path d="M29.8176 17.0131V14.5469L33.0259 16.3956V18.8618L29.8176 17.0131Z" 
        fill="#CA722E"/>
      <path d="M42.6488 10.8496V13.3139L33.0259 18.8619V16.3957L42.6488 10.8496Z" 
        fill="#EEA32D"/>
      <path d="M38.0233 26.5913L37.6607 26.8684V23.781L38.0233 23.5039V26.5913Z" 
        fill="#CA722E"/>
      <path d="M36.6303 49.2085L36.2695 49.4874V46.4001L36.6303 46.1211V49.2085Z" 
        fill="#CA722E"/>
      <path d="M28.5808 39.844L28.6701 39.4199L25.6999 41.0752L25.6143 41.5271L28.5808 39.844Z" 
        fill="#CA722E"/>
      <path d="M48.7399 30.1369L48.8292 29.7129L45.859 31.37L45.7734 31.8201L48.7399 30.1369Z" 
        fill="#CA722E"/>
      <path d="M39.0946 39.36C39.0946 39.36 37.6383 27.9516 37.6011 28.0297C37.564 28.1078 36.5875 40.833 36.5875 40.833L39.0946 39.36Z" 
        fill="#874226"/>
      <path d="M38.0643 34.9237C37.2924 35.3738 36.6619 36.4767 36.6619 37.3769C36.6619 38.277 37.2924 38.6434 38.0643 38.1952C38.8361 37.7451 39.4666 36.6422 39.4666 35.742C39.4666 34.8419 38.8361 34.4755 38.0643 34.9237Z" 
        fill="#E2832D"/>
      </g>
      <defs>
      <clipPath id="clip0_9393_100431">
        <rect width="33.0814" height="46" 
          fill="white" transform="translate(18.0001 9)"/>
      </clipPath>
      </defs>
    </SvgIcon>
  );
};
