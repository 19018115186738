import React, { createContext } from "react";
import {
  DataContextInterface,
  initialDataContextState,
  ErrorMsg,
} from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { AppRoute } from "utils";
import { Relay } from "interfaces/relieve.interface";
import { ContextProviderProps } from "contexts";
import { DATA_REFETCH_MINUTES } from "App";
export const RelieveRecommendationContext = createContext<
  DataContextInterface<Relay[]>
>(initialDataContextState);

export const RelieveRecommendationProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const { refetch, ...other } = useData<Relay[]>(
    {
      config: {
        url: "/operator-assignment/relay/recommendations",
        method: "GET",
      },
      options: {
        useCache: false,
      },
      polling: {
        minutes: DATA_REFETCH_MINUTES,
        silent: true,
      },
    },
    ErrorMsg.GET_RECOMMENDATION_RELAY
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(() => refetch(), [AppRoute.RELIEVE]);

  return (
    <RelieveRecommendationContext.Provider value={{ ...other, refetch }}>
      {children}
    </RelieveRecommendationContext.Provider>
  );
};
