import React from "react";
import { PointSymbolProps } from "@nivo/line";
import { useTheme } from "@material-ui/core";

export const CustomComboSquareTriangleSymbol: React.FC<PointSymbolProps> = ({
  size,
}) => {
  const theme = useTheme();
  const defaultSizePx = 11;
  // Use nivo line chart's size argument as scaling factor for the default svg size
  const scaleFactor = size * 0.8;
  const translation = -(scaleFactor * defaultSizePx) / 1.1;
  return (
    <g
      transform={`translate(${translation},${translation}) scale(${scaleFactor})`}
    >
      <path
        d="M3.5 15.3333V3.66667C3.5 3.02614 4.02614 2.5 4.66667 2.5H16.3333C16.9739 2.5 17.5 3.02614 17.5 3.66667V15.3333C17.5 15.9739 16.9739 16.5 16.3333 16.5H4.66667C4.02614 16.5 3.5 15.9739 3.5 15.3333Z"
        fill={theme.palette.common.white}
        stroke={
          theme.palette.type === "light"
            ? theme.palette.secondary.main
            : theme.palette.common.white
        }
      />
      <path
        d="M6.4375 13.0205L10.2292 6.52051L14.5625 13.0205H6.4375Z"
        fill={theme.palette.movementTarget}
        stroke={theme.palette.movementTarget}
        strokeLinejoin="round"
      />
    </g>
  );
};
