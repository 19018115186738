import React, { useMemo } from "react";
import { Paper, Typography, makeStyles, Theme, alpha } from "@material-ui/core";
import {
  CardAlertType,
  ColumnType,
  ColumnGroupType,
  TruckProjectionType,
} from "interfaces";
import {
  getAlertPaperColor,
  getAlertTextColor,
  getAlertTimeTextColor,
} from "styles";
import { parseTimeDelta } from "utils";
import format from "date-fns/format";
import { CornerLabel } from "./CornerLabel";

enum TimeType {
  SINCE = "since",
  REMAINING = "remaining",
}

interface TruckTimeInfoCardProps {
  columnType: ColumnType;
  sinceTime: number | null;
  remainingTime: number | null;
  releaseTime: string | null;
  alertType: CardAlertType;
  projectionType: TruckProjectionType;
  groupType: ColumnGroupType;
}

export const TruckTimeInfoCard: React.FC<TruckTimeInfoCardProps> = ({
  sinceTime,
  remainingTime,
  releaseTime,
  columnType,
  alertType,
  projectionType,
  groupType,
}) => {
  const classes = useStyles({ alertType });

  const showCornerLabel = useMemo(
    () => groupType === ColumnGroupType.MAINTENANCE && projectionType != null,
    [projectionType, groupType]
  );

  const remainingTimeDetail = useMemo(
    () => ({
      showInfo: showTimeDetail(columnType, TimeType.REMAINING, false),
      text:
        projectionType === TruckProjectionType.MANUAL
          ? "Hora de salida"
          : "Proyección",
    }),
    [columnType, projectionType]
  );

  const remainingTimeTextDetail = useMemo(
    () =>
      `${releaseTime ? format(new Date(releaseTime), "HH':'mm") : "-"} ${
        remainingTime === 0
          ? ""
          : ` | ${remainingTime !== null ? parseTimeDelta(remainingTime) : "-"}`
      }`,
    [releaseTime, remainingTime]
  );

  const sinceTimeDetail = useMemo(
    () => ({
      showInfo: showTimeDetail(columnType, TimeType.SINCE, false),
      text: "Hace",
    }),
    [columnType]
  );

  return (
    <Paper elevation={0} className={classes.timeInfoPaper}>
      {sinceTimeDetail.showInfo && (
        <div className={classes.timeInfoSection}>
          <div>
            <Typography className={classes.title} noWrap variant="body2">
              {sinceTimeDetail.text}
            </Typography>
            <Typography className={classes.title} noWrap variant="h6">
              {sinceTime !== null ? parseTimeDelta(sinceTime) : "-"}
            </Typography>
          </div>
        </div>
      )}
      {remainingTimeDetail.showInfo && (
        <div>
          <Typography className={classes.title} noWrap variant="body2">
            {remainingTimeDetail.text}
          </Typography>
          <Typography variant="h6" className={classes.timeTitle} noWrap>
            <b>{remainingTimeTextDetail}</b>
          </Typography>
        </div>
      )}
      {showCornerLabel && (
        <CornerLabel alertType={alertType} type={projectionType} />
      )}
    </Paper>
  );
};

interface StyleProps {
  alertType: CardAlertType;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  timeTitle: {
    color: ({ alertType }) => getAlertTimeTextColor(theme, alertType),
  },
  title: {
    color: ({ alertType }) => alpha(getAlertTextColor(theme, alertType), 0.6),
  },
  timeInfoPaper: {
    display: "flex",
    backgroundColor: ({ alertType }) => getAlertPaperColor(theme, alertType),
    flexDirection: "column",
    padding: 4,
    height: "100%",
  },
  timeInfoSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  onlyRemainingTimeAlarmRoot: {
    display: "flex",
    flexDirection: "column-reverse",
    height: "100%",
    alignSelf: "flex-end",
  },
}));

const showTimeDetail = (
  columnType: ColumnType,
  timeType: TimeType,
  showAlert: boolean
): boolean => {
  if (columnType === ColumnType.PENDING) {
    return showAlert || !!timeType;
  }
  if (columnType === ColumnType.PRE_ASSEMBLED) return true;
  if (
    [ColumnType.NO_OPERATOR, ColumnType.UNPLANNED_WAIT].includes(columnType)
  ) {
    return timeType === TimeType.SINCE;
  }
  return true;
};
