import { useEffect, useState } from "react";
import { getDifference } from "utils";

export const useMinutesCounter = (
  targetDate: string | Date | null,
  countdown?: boolean
): [number | null] => {
  const [minutes, setMinutes] = useState<number | null>(
    targetDate ? getDifference(targetDate, countdown) : null
  );

  useEffect(() => {
    if (!targetDate) return;
    setMinutes(getDifference(targetDate, countdown));
    const interval = setInterval(
      () => setMinutes(getDifference(targetDate, countdown)),
      60000
    );
    return () => clearInterval(interval);
  }, [targetDate, countdown]);
  return [minutes];
};
