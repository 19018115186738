import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RelieveMenuStep2 } from "./RelieveMenuStep2";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  FormRequestProps,
  FormStepProps,
  Location,
  SelectedLocation,
  Status,
} from "interfaces/relieve.interface";
import { RelieveMenuStep1 } from "./RelieveMenuStep1";
import { StepperComponent } from "components/Stepper/StepperComponent";
import { useData } from "hooks";
import { ErrorMsg } from "interfaces";
import { Target } from "components/planBy/ValidationView";
import { DataChipList } from "components/selectorComponent/CustomMultipleSelector";
import { useSnackbar } from "notistack";
import { OperatorsRelieveContext } from "contexts/OperatorsRelieveContext";
import { ArrowLeft } from "@material-ui/icons";
import { RelieveFiltersContext } from "contexts/RelieveFiltersContext";
import { Tooltip } from "components";

/**
 * Menu Steps:
 *  0- Choose Location,
 *  1- Excavs, Operators and CAEX Form
 */

interface Props {
  setSelectedLocation: (value: SelectedLocation) => void;
  setCurrentStep: (value: number) => void;
  currentStep: number;
  selectedLocation: number;
  targetValues: Target;
  formData: FormStepProps[];
  setFormData: (value: FormStepProps[]) => void;
  setCurrentChangeLocation: (value: any) => void;
  setCurrentHour: (value: string[]) => void;
  currentHour: string[];
  currentChangeLocation: any;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

export const RelieveMenu: React.FC<Props> = ({
  currentStep,
  selectedLocation,
  targetValues,
  formData,
  currentHour,
  expanded,
  isLoading,
  currentChangeLocation,
  setSelectedLocation,
  setCurrentStep,
  setCurrentHour,
  setFormData,
  setCurrentChangeLocation,
  setExpanded,
  setIsLoading,
}) => {
  //form steps
  const { refetch: refetchStatus } = useContext(OperatorsRelieveContext);
  const { data: filtersRelieve, refetch: refreshFilters, refetching } = useContext(
    RelieveFiltersContext
  );
  const [canSendForm, setCanSendForm] = useState<boolean>(false);
  const [canNextStep, setCanNextStep] = useState<boolean>(false);
  const [isFirtRender, setIsFirstRender] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const { refetch: savePlanning } = useData<FormStepProps>(
    {
      config: {
        url: "/operator-assignment/relay",
        method: "POST",
        params: { status: Status.SAVED },
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.CREATE_RELAY
  );

  const { refetch: finishPlanning } = useData<FormStepProps>(
    {
      config: {
        url: "/operator-assignment/relay",
        method: "POST",
        params: { status: Status.FINISHED },
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.POST_FINISHED_RELAY
  );

  const reloadFilters = useCallback(() => {
    return refreshFilters();
  }, [refreshFilters]);

  //complete shift
  useEffect(() => {
    if (!currentHour.length && filtersRelieve?.hourRelay) {
      const newData: string[] = [];
      filtersRelieve.hourRelay.forEach((shift) => {
        //disabled Value
        if (shift.hours.length === 1) {
          newData.push(shift.hours[0].id);
        } else {
          newData.push("");
        }
      });
      setCurrentHour(newData);
    }
  }, [filtersRelieve, currentHour, setCurrentHour]);

  useEffect(() => {
    const firstRender = async () => {
      if (isFirtRender) {
        await reloadFilters();
        setIsFirstRender(false);
      }
    };
    firstRender();
  }, [isFirtRender, reloadFilters, setIsLoading]);

  const handleSelectedItems = (newValues: any, type: string) => {
    let data = [...formData];
    data[selectedLocation] = {
      ...data[selectedLocation],
      [type]: newValues,
    };

    data[selectedLocation].statusForm.hasChanges = true;
    setFormData(data);
  };

  const handleChangeLocation = (newValue: Location) => {
    setCurrentChangeLocation(newValue);

    if (
      formData.length &&
      formData[selectedLocation] &&
      formData[selectedLocation].location?.id !== newValue?.id
    ) {
      handleSelectedItems(newValue, "location");
      setCurrentChangeLocation("");
    }
  };

  const allSelectedLocation: string[] = useMemo(() => {
    return formData.map((item) => item?.location?.id);
  }, [formData]);

  const stepsForm = ["Comedor", "Selección"];

  useEffect(() => {
    const steps = ["Comedor", "Selección"];
    const currentData = formData[selectedLocation];

    setSelectedLocation({
      location: currentData?.location,
      allLocations: formData,
      statusForm: currentData?.statusForm,
      step: steps[currentStep],
      validationForm: {
        operators: {
          current:
            currentData?.operatorsOwn?.length +
            currentData?.operatorsOther?.length,
        },
        caex: {
          current: currentData?.caex?.length,
        },
        excavs: {
          current: currentData?.excavs?.length,
        },
      },
    });
  }, [selectedLocation, formData, setSelectedLocation, currentStep]);

  const handleSelectedShift = (value: string, index: number) => {
    const newValue = [...currentHour];
    newValue[index] = value;
    //DUDAS
    setCurrentHour(newValue);
    if (
      formData.length &&
      formData[selectedLocation] &&
      formData[selectedLocation].relayHour2 !== value
    ) {
      switch (index) {
        case 0:
          handleSelectedItems(value, "relayHour1");
          break;
        case 1:
          handleSelectedItems(value, "relayHour2");
          break;
        case 2:
          handleSelectedItems(value, "relayHour3");
          break;
        case 3:
          handleSelectedItems(value, "relayHour4");
          break;

        default:
          break;
      }
      handleSelectedItems(value, "relayHour2");
    }
  };

  useMemo(() => {
    return (currentChangeLocation !== "" ||
      formData[selectedLocation]?.location?.id) &&
      ((currentHour.length && currentHour.every((item) => item !== "")) ||
        (formData[selectedLocation]?.shift === "day" &&
          formData[selectedLocation]?.relayHour2 !== ""))
      ? setCanNextStep(true)
      : setCanNextStep(false);
  }, [currentChangeLocation, formData, selectedLocation, currentHour]);

  useMemo(() => {
    const dataCurrentLocation = formData[selectedLocation];
    setCanSendForm(false);
    if (dataCurrentLocation) {
      const operators =
        dataCurrentLocation?.operatorsOther?.length +
        dataCurrentLocation?.operatorsOwn?.length;
      const oper = operators >= targetValues.operators;
      const caex = dataCurrentLocation?.caex?.length >= targetValues.caex;
      const excavs = dataCurrentLocation?.excavs?.length >= targetValues.excavs;
      const excavCaex = dataCurrentLocation?.excavs?.some((item) =>
        isNaN(item.count)
      );

      if (oper && caex && excavs && !excavCaex) setCanSendForm(true);
    }
  }, [formData, selectedLocation, targetValues]);

  //Send current location
  const sendForm = useCallback(
    async (statusForm: Status) => {
      try {
        setIsLoading(true);
        const data = { ...formData[selectedLocation] };
        const operOwn = data.operatorsOwn.map((item) => ({
          ...item,
          isOwn: true,
        }));
        const operOther = data.operatorsOther.map((item) => ({
          ...item,
          isOwn: false,
        }));
        const operators = operOther.concat(operOwn);
        const operatorsData = operators?.map((item) => {
          return { id: item.id, name: item.title, isOwn: item.isOwn };
        });
        const excavsData = data.excavs.map((item) => {
          return { id: item.id, count: item.count, priority: item.priority };
        });
        const caexData = data.caex.map((item) => {
          return { id: item.id, hasPriority: item.hasPriority };
        });

        let location = filtersRelieve.locations.find(
          (item: any) => item.id === formData[selectedLocation].location.id
        );
        location = location ? location : { id: "", name: "" };

        const payload: FormRequestProps = {
          location: location,
          operators: operatorsData,
          caex: caexData,
          excavs: excavsData,
          status: statusForm,
          relayHour1: data.relayHour1,
          relayHour2: data.relayHour2,
          relayHour3: data.relayHour3 ? data.relayHour3 : null,
          relayHour4: data.relayHour4 ? data.relayHour4 : null,
          relayId: data.relayId ?? null,
          shift: data.shift
        };
        let msg = "";
        if (statusForm === Status.SAVED) {
          await savePlanning({ data: payload });
          msg = "Formulario guardado exitosamente";
        }
        if (statusForm === Status.FINISHED) {
          await finishPlanning({ data: payload });
          msg = "Programación de relevo guardada exitosamente";
        }
        await refreshFilters();
        await refetchStatus();
        setIsLoading(false);

        enqueueSnackbar(msg, {
          variant: "success",
        });
      } catch (error) {
        setIsLoading(false);
        await refetchStatus();
      }
    },
    [
      savePlanning,
      formData,
      selectedLocation,
      enqueueSnackbar,
      filtersRelieve,
      refreshFilters,
      refetchStatus,
      finishPlanning,
      setIsLoading,
    ]
  );

  //init data
  const initLocation = useCallback(
    async (sendInitForm: boolean = false) => {
      const findLocation = formData.find(
        (item) => item.location.id === currentChangeLocation.id
      );
      if (!findLocation && currentChangeLocation !== "") {
        setIsLoading(true);
        const defOperArray: DataChipList[] = [];

        if (currentChangeLocation?.id) {
          const operOwn = filtersRelieve?.operatorsOwn?.filter(
            (op) => op.location?.id === currentChangeLocation.id
          );
          operOwn?.forEach((element) => {
            defOperArray.push({
              id: element.id,
            });
          });
        }

        let location = filtersRelieve.locations.find(
          (item: any) => item.id === currentChangeLocation.id
        );
        location = location ? location : { id: "", name: "" };

        const newData: FormStepProps = {
          shift: filtersRelieve.id,
          location: location,
          excavs: [],
          operatorsOwn: defOperArray,
          operatorsOther: [],
          caex: [],
          statusForm: { hasChanges: false, status: Status.PENDING },
          relayHour1: currentHour[0],
          relayHour2: currentHour[1],
          relayHour3: currentHour[2] ? currentHour[2] : null,
          relayHour4: currentHour[3] ? currentHour[3] : null,
          hasExtra: false
        };
        setFormData([...formData, newData]);

        if (sendInitForm) {
          try {
            setIsLoading(true);
            const payload: FormRequestProps = {
              shift: newData.shift,
              location: newData.location,
              operators: [],
              caex: [],
              excavs: [],
              status: Status.SAVED,
              relayHour1: newData.relayHour1,
              relayHour2: newData.relayHour2,
              relayHour3: newData.relayHour3,
              relayHour4: newData.relayHour4,
              relayId: newData.relayId ?? null,
            };
            await savePlanning({ data: payload });
            setIsLoading(false);
            enqueueSnackbar("Formulario guardado exitosamente", {
              variant: "success",
            });
          } catch (error) {
            enqueueSnackbar("Error al guardar formulario", {
              variant: "success",
            });
          }
        }

        setCurrentChangeLocation("");
        setIsLoading(false);
      } else {
        // location exists
        if (sendInitForm) {
          sendForm(Status.SAVED);
        }
      }
    },
    [
      currentChangeLocation,
      formData,
      currentHour,
      setFormData,
      setCurrentChangeLocation,
      filtersRelieve,
      savePlanning,
      sendForm,
      enqueueSnackbar,
      setIsLoading,
    ]
  );

  return (
    <Grid item xs={12} style={{ position: "relative", marginRight: 20 }}>
      <Paper elevation={3} className={classes.mainContainer}>
        <Grid container style={{ maxHeight: 750 }}>
          {isLoading || refetching ? (
            <Grid container alignItems="center" style={{ paddingTop: 200 }}>
              <Grid item xs={12} className={classes.infoContainer}>
                <CircularProgress size={120} color="primary" />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography>
                  {" "}
                  Actualizando formulario para programación de relevo.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              {formData[selectedLocation]?.statusForm?.status !== "FINISHED" &&
              formData[selectedLocation]?.statusForm?.status !== "ACCEPTED" ? (
                <StepperComponent
                  steps={stepsForm}
                  activeStep={currentStep}
                  setActiveStep={setCurrentStep}
                  sendForm={sendForm}
                  initLocation={initLocation}
                  canSendForm={canSendForm}
                  canNextStep={canNextStep}
                  statusForm={
                    formData[selectedLocation]?.statusForm?.status ??
                    Status.PENDING
                  }
                  setActionForm={handleSelectedItems}
                >
                  {currentStep === 0 && (
                    <RelieveMenuStep1
                      setSelectedLocations={handleChangeLocation}
                      setSelectedShift={handleSelectedShift}
                      dataLocation={formData[selectedLocation]}
                      currentLocation={currentChangeLocation}
                      hourRelay={currentHour}
                      allSelectedLocation={allSelectedLocation}
                      filters={filtersRelieve}
                    />
                  )}
                  {currentStep === 1 && (
                    <RelieveMenuStep2
                      dataLocation={formData[selectedLocation]}
                      setItems={handleSelectedItems}
                      disabled={false}
                      filters={filtersRelieve}
                    />
                  )}
                </StepperComponent>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    style={{ padding: "16px 0px 12px 10px", fontWeight: 700 }}
                  >
                    Resumen
                  </Typography>
                  <RelieveMenuStep2
                    dataLocation={formData[selectedLocation]}
                    setItems={handleSelectedItems}
                    disabled={true}
                    filters={filtersRelieve}
                  />
                </>
              )}
            </>
          )}
          <div>
            <Tooltip
              title="Ocultar formulario"
              arrow
              placement="bottom"
              interactive
            >
              <Button
                onClick={() => {
                  setExpanded(!expanded);
                }}
                className={classes.button}
              >
                <ArrowLeft />
              </Button>
            </Tooltip>
          </div>
        </Grid>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  mainContainer: {
    background:
      palette.type === "light" ? palette.grey[100] : palette.background.default,
    minHeight: 700,
  },
  newLocation: {
    fill: palette.primary.main,
  },
  chipLocations: {
    marginRight: 4,
  },
  infoContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    paddingBottom: 24,
    paddingTop: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    width: 36,
    height: 36,
    borderRadius: "100%",
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translate(50%, -50%)",
    background: palette.background.paper,
    minWidth: "unset",
    filter:
      "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20))",
    "&:hover,&&:focus": {
      background: palette.background.paper,
    },
  },
}));
