import React from "react";
import { CellMeasurerCache } from "react-virtualized";
import { CellMeasurer } from "components/react-virtualized";
import { CellProps } from "interfaces";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { MeasuredCellParent } from "react-virtualized/dist/es/CellMeasurer";
import { grey } from "@material-ui/core/colors";

interface Props<T> extends Pick<CellProps<T>, "value" | "align" | "className"> {
  cache: CellMeasurerCache;
  columnIndex: number;
  parent: MeasuredCellParent;
  rowIndex: number;
  style: React.CSSProperties | undefined;
  refKey: React.Key;
  onClick?: () => void;
}

export const VirtualizedCell = <T extends unknown>({
  className,
  value,
  align,
  refKey,
  cache,
  parent,
  rowIndex,
  style,
  columnIndex,
  onClick,
}: Props<T>) => {
  const classes = useStyles({ align, rowIndex, withAction: Boolean(onClick) });

  return (
    <CellMeasurer
      cache={cache}
      columnIndex={columnIndex}
      key={refKey}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div
        className={clsx(className, classes.cell)}
        style={style}
        onClick={onClick}
      >
        {typeof value === "string" || typeof value === "number" ? (
          <Typography variant="body1">{value}</Typography>
        ) : (
          value
        )}
      </div>
    </CellMeasurer>
  );
};

interface UseStylesProps {
  align?: string;
  withAction?: boolean;
  rowIndex: number;
}

const useStyles = makeStyles<Theme, UseStylesProps>(({ palette }) => {
  const evenRowBackgroundColor =
    palette.type === "light" ? grey[50] : palette.background.default;

  return {
    cell: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: ({ align }) => align ?? "left",
      padding: "14px 16px",
      borderBottom: 0,
      cursor: ({ withAction }) => (withAction ? "pointer" : "inherit"),
      backgroundColor: ({ rowIndex }) =>
        rowIndex % 2 === 0 ? evenRowBackgroundColor : palette.background.paper,
    },
  };
});
