import {
  Grid,
  Theme,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import {
  DetailsPhase,
  KpiTypePerformanceParsers,
  ProjectionStatus,
  CardIcons,
} from "interfaces";
import { getProjectionColor } from "styles";
import React from "react";
import clsx from "clsx";
import { Level } from "interfaces/velocity.interface";
import { Size, SquareChip } from "components/SquareChip";

interface KpiProps {
  values: DetailsPhase;
  prioritySize?: Size;
}

export const KpiCard: React.FC<KpiProps> = ({
  values,
  prioritySize = Size.small,
}) => {
  const { mainValue, projection, priorityLabel, priorityChip, countValue } =
    values;
  const classes = useStyles({
    alertType: projection?.alertType,
    diff: projection?.diff,
    priority: priorityLabel,
  });
  const parsers = KpiTypePerformanceParsers[values.type];
  const valueParser = parsers?.mainValue;
  const valueParserProj = parsers?.projection;

  const fontSize =
    typeof (mainValue && mainValue.value) === "number" ? "h6" : "body1";

  const PriorityIcon =
    priorityChip && priorityChip?.idIcon
      ? CardIcons.icons[priorityChip?.idIcon]
      : undefined;

  const CountIcon =
    countValue && countValue?.idIcon
      ? CardIcons.icons[countValue?.idIcon]
      : undefined;
  const MainIcon =
    mainValue && mainValue?.idIcon
      ? CardIcons.icons[mainValue?.idIcon]
      : undefined;

  return (
    <Grid container alignItems="center" className={classes.mainContainer}>
      {mainValue && typeof mainValue.value === "number" && valueParser ? (
        <>
          <Typography
            variant={fontSize}
            component="span"
            className={classes.number}
          >
            {valueParser(mainValue.value)}
          </Typography>
        </>
      ) : (
        <Tooltip
          title={mainValue?.value ?? ""}
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          placement="top"
          interactive
          arrow
        >
          <Grid container alignItems="center">
            {" "}
            {MainIcon ? <MainIcon className={classes.icon} /> : ""}
            <Typography
              variant={fontSize}
              className={clsx(classes.text, classes.adjustedTextSize)}
            >
              {mainValue?.value === null ? "-" : mainValue?.value ?? ""}
            </Typography>
          </Grid>
        </Tooltip>
      )}
      {projection ? (
        <Grid className={classes.value}>
          {projection.diff !== null ? (
            projection.isBelowPlan ? (
              <ArrowDropDown />
            ) : (
              <ArrowDropUp />
            )
          ) : (
            ""
          )}
          <Typography
            variant="body1"
            component="span"
            className={classes.number}
          >
            <b>
              {projection.diff !== null
                ? valueParserProj
                  ? valueParserProj(projection.diff)
                  : projection.diff
                : mainValue
                ? ""
                : "-"}
            </b>
          </Typography>
        </Grid>
      ) : (
        ""
      )}
      {priorityLabel ? (
        <Typography variant="h6" className={classes.priority}>
          {priorityLabel === Level.HIGH
            ? "Alta"
            : priorityLabel === Level.MEDIUM
            ? "Media"
            : "Despejada"}
        </Typography>
      ) : (
        ""
      )}
      {priorityChip ? (
        <Grid container justifyContent="center" alignItems="center">
          {PriorityIcon ? <PriorityIcon className={classes.icon} /> : ""}
          <SquareChip
            title={priorityChip.labelChip}
            level={priorityChip.level}
            size={prioritySize}
          />
        </Grid>
      ) : (
        ""
      )}
      {countValue && typeof countValue?.value === "number" ? (
        <Grid container justifyContent="center" alignItems="center">
          {CountIcon && <CountIcon className={classes.icon} />}
          <Typography
            variant="h6"
            className={classes.number}
          >
            {countValue?.value}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

interface StyleProps {
  alertType?: ProjectionStatus;
  diff?: number | null;
  priority?: Level;
}

const getColor = (priority: Level | undefined, theme: Theme) => {
  switch (priority) {
    case Level.HIGH:
      return theme.palette.error.main;

    case Level.MEDIUM:
      return theme.palette.warning.main;

    default:
      return theme.palette.success.main;
  }
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
  return {
    value: {
      color: ({ alertType, diff }) =>
        diff !== null
          ? getProjectionColor(theme, alertType)
          : theme.palette.text.primary,
      display: "flex",
      alignItems: "center",
    },
    mainContainer: {
      height: "32px",
    },
    text: {
      fontWeight: 400,
    },
    number: {
      fontWeight: 700,
    },
    adjustedTextSize: {
      display: "block",
      width: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    tooltip: {
      background: theme.palette.secondary.main,
      borderRadius: "4px",
      boxShadow:
        "0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
      padding: "6px",
      fontSize: 16,
    },
    arrow: {
      color: theme.palette.secondary.main,
    },
    priority: {
      color: ({ priority }) => getColor(priority, theme),
    },
    icon: {
      marginRight: "20px",
      width: "30px",
      height: "28px",
    },
  };
});
