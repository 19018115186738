import React, { createContext, useCallback, useMemo, useState } from "react";
import { ContextProviderProps } from "contexts";
import { CustomDialogActionProps, CustomDialog } from "components";
import { Typography } from "@material-ui/core";

interface ConfirmationDialogContextInterface {
  openConfirmationDialog: (props: ConfirmationDialogProps) => void;
}

interface ConfirmationDialogProps {
  title: string;
  content: string;
  onCancelInfo: CustomDialogActionProps;
  onCompleteInfo: CustomDialogActionProps;
}

export const ConfirmationDialogContext =
  createContext<ConfirmationDialogContextInterface>({
    openConfirmationDialog: () => null,
  });

export const ConfirmationDialogProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [dialogProps, setDialogProps] =
    useState<ConfirmationDialogProps | null>(null);

  const openConfirmationDialog = useCallback(
    (props: ConfirmationDialogProps) => {
      setDialogProps(props);
    },
    [setDialogProps]
  );

  const closeDialog = useCallback(() => {
    setDialogProps(null);
  }, [setDialogProps]);

  const contextState = useMemo<ConfirmationDialogContextInterface>(
    () => ({ openConfirmationDialog }),
    [openConfirmationDialog]
  );

  return (
    <ConfirmationDialogContext.Provider value={contextState}>
      {children}
      <CustomDialog
        open={!!dialogProps}
        onClose={closeDialog}
        title={dialogProps?.title || ""}
        onCancelInfo={dialogProps?.onCancelInfo}
        onCompleteInfo={dialogProps?.onCompleteInfo}
      >
        <Typography variant="subtitle1">{dialogProps?.content}</Typography>
      </CustomDialog>
    </ConfirmationDialogContext.Provider>
  );
};
