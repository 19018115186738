import { Grid, Snackbar, Typography, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { RelieveRecommendationContext } from "contexts/RelieveRecommendationContext";
import { useData } from "hooks";
import { ErrorMsg } from "interfaces";
import { Relay } from "interfaces/relieve.interface";
import React, { useCallback, useContext, useMemo, useState } from "react";

interface Props {
  relay?: Relay;
}

export const SnackbarError: React.FC<Props> = ({ relay }) => {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const { refetch: refetchRec } = useContext(
    RelieveRecommendationContext
  );

  useMemo(() => {
    if (relay?.error && relay?.error?.length > 0) {
      const dataWithErrors = relay?.error?.filter(
        (element) => element?.list?.length > 0
      );

      setOpenSnackbar(false);
      if (dataWithErrors?.length) {
        setOpenSnackbar(true);
      }
    }

    if (relay === undefined || relay?.error?.length === 0) {
      setOpenSnackbar(false);
    }
  }, [relay]);

  const { refetch: finishAlert } = useData(
    {
      config: {
        url: "/operator-assignment/relay/stop-alert",
        method: "PUT",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.GENERIC_UPDATE
  );

  const handleClose = useCallback(
    async (relay: number | undefined) => {
      try {
        setOpenSnackbar(false);
        await finishAlert({ data: { relayId: relay } });
        refetchRec();
      } catch (e) {}
    },
    [finishAlert, refetchRec]
  );
  return (
    <Grid item xs={12}>
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        classes={{ root: classes.snackbar }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => handleClose(relay?.relayId)}
          className={classes.alert}
          icon={false}
        >
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: "12px" }}>
              <Typography variant="subtitle1">
                Observaciones de la programación de relevos sugerida.
              </Typography>
            </Grid>
            {relay?.error?.map((error, index) => (
              <React.Fragment key={index}>
                {error?.list?.length ? (
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{error?.msg}:</Typography>
                    <ul className={classes.list}>
                      {error?.list?.map((item, index) => (
                        <li key={index}>
                          <Typography variant="subtitle1">{item}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                ) : (
                  ""
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Alert>
      </Snackbar>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  alert: {
    width: "100%",
    "& .MuiAlert-message": {
      width: "100%",
    },
  },
  snackbar: {
    width: "60%",
  },
  list: {
    paddingInlineStart: "25px",
    marginTop: "0px",
    marginBottom: "5px",
  },
}));
