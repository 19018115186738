import { Button, makeStyles, TextField } from "@material-ui/core";
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useData } from "hooks";
import { ExcavatorContext } from "contexts/ExcavatorContext";
import {
  ErrorMsg,
  SicClients,
  ValueWithContext,
  MineMovements,
  sicRegistrySelectableClients,
  SicRegistryPayload,
} from "interfaces";
import { useSnackbar } from "notistack";
import { map, flatten, sortBy } from "lodash";

interface Props {
  onSuccess: () => void;
  movements: MineMovements[];
}

export const SicRegistryInput: React.FC<Props> = ({ onSuccess, movements }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data: balanceData, firstLoading } = useContext(ExcavatorContext);

  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const [responsible, setResponsible] = useState("");
  const [risk, setRisk] = useState("");
  const [action, setAction] = useState("");

  const { refetch: createSicRegistry } = useData<SicRegistryPayload>(
    {
      config: {
        url: "/truck-assignment/sic",
        method: "POST",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.CREATE_SIC
  );

  const autocompleteOptions = useMemo<ValueWithContext[]>(() => {
    const clients: ValueWithContext[] = Object.entries(
      sicRegistrySelectableClients
    ).map(([key, label]) => {
      const relatedData = movements.find(({ origin }) => origin === key);
      return {
        label,
        loadtons: relatedData?.loadtons ?? null,
        target: relatedData?.target ?? null,
      };
    });

    const sicClients = Object.values(SicClients).map((client) => ({
      label: client,
      loadtons: null,
      target: null,
    }));

    //remove duplicates
    const flattenExcavs = flatten(map(balanceData.table, (agg) => agg.excavs)).filter((obj, index, self) => {
      return index === self.findIndex((o) => o.id === obj.id )
    })
  
    const excavs = sortBy(
      flattenExcavs,
      "id"
    ).map((excav) => {
      // This data comes from the API in kt, we must send it in tons
      const loadtons =
        excav.accLoadtons !== null ? excav.accLoadtons * 1000 : null;
      const target =
        excav.plannedLoadtons !== null ? excav.plannedLoadtons * 1000 : null;
      return {
        label: excav.id,
        loadtons,
        target,
      };
    });
    return [...clients, ...sicClients, ...excavs];
  }, [balanceData.table, movements]);

  const handleOnChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: string | null,
      _reason: AutocompleteChangeReason
    ) => {
      setSelectedLabel(value ?? null);
    },
    []
  );

  const missingInputs = useMemo(
    () => !risk || !action || !responsible || !selectedLabel,
    [action, responsible, risk, selectedLabel]
  );

  const clearInputs = useCallback(() => {
    setSelectedLabel(null);
    setResponsible("");
    setAction("");
    setRisk("");
  }, []);

  const handleCreateSic = useCallback(async () => {
    const contextValues = autocompleteOptions.find(
      (val) => val.label === selectedLabel
    );
    // Note that this callback can only be called when
    // selectedLabel is not null
    const payload: SicRegistryPayload = {
      targetEntity: selectedLabel ?? "",
      responsible,
      risk,
      action,
      loadtons: contextValues?.loadtons,
      target: contextValues?.target,
    };
    await createSicRegistry({ data: payload });
    clearInputs();
    enqueueSnackbar("Se agregó exitosamente el registro SIC", {
      variant: "success",
    });
    onSuccess();
  }, [
    action,
    autocompleteOptions,
    clearInputs,
    createSicRegistry,
    enqueueSnackbar,
    onSuccess,
    responsible,
    risk,
    selectedLabel,
  ]);

  return (
    <div className={classes.container}>
      <div className={classes.registryContainer}>
        <div className={classes.leftRegistry}>
          <Autocomplete
            className={classes.autoCompleteRoot}
            size="small"
            value={selectedLabel}
            onChange={handleOnChange}
            loading={firstLoading}
            noOptionsText="No se encontraron opciones"
            placeholder="Escribe o selecciona"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Cliente / Pala"
                variant="outlined"
                required
              />
            )}
            options={autocompleteOptions.map((val) => val.label)}
          />
          <TextField
            className={classes.responsibleTextField}
            value={responsible}
            onChange={({ target }) => setResponsible(target.value)}
            placeholder="Escribe el nombre"
            label="Responsable"
            variant="outlined"
            size="small"
            required
          />
        </div>
        <TextField
          className={classes.risk}
          value={risk}
          onChange={({ target }) => setRisk(target.value)}
          placeholder="Escribe una brecha o riesgo"
          label="Brecha / Riesgo"
          variant="outlined"
          size="small"
          minRows={4}
          maxRows={4}
          multiline
          required
        />
        <TextField
          className={classes.actions}
          value={action}
          onChange={({ target }) => setAction(target.value)}
          placeholder="Escribe el plan de acción"
          variant="outlined"
          label="Acción"
          size="small"
          minRows={4}
          maxRows={4}
          multiline
          required
        />
      </div>
      <Button
        className={classes.successButton}
        color="primary"
        variant="contained"
        disabled={firstLoading || missingInputs}
        onClick={handleCreateSic}
      >
        Guardar
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 15,
  },
  registryContainer: {
    marginRight: 15,
    marginTop: 15,
    marginBottom: 15,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: 20,
  },
  leftRegistry: {
    width: "30%",
  },
  autoCompleteRoot: {
    width: "100%",
    marginBottom: 15,
  },
  responsibleTextField: {
    width: "100%",
  },
  risk: {
    width: "35%",
  },
  actions: {
    width: "35%",
  },
  successButton: {
    height: 42,
    width: 137,
    color: theme.palette.common.white,
    float: "right",
  },
}));
