import { Epg, Layout, useEpg } from "planby";
import React from "react";
import { ProgramItem } from "./ProgramItem";
import { ChannelItem } from "./ChannelItem";
import { Timeline } from "./Timeline";
import { useTheme } from "@material-ui/core";

interface ConfirmationDialogProps {
  open: boolean;
  data?: { operator: string; caex: string | null }[];
  group?: number;
  id?: number;
}

interface Props {
  dataPlanby: any;
  dataRelay: any;
  size?: number;
  getGroup: (value: number) => { operator: string; caex: string | null }[];
  openModal: ConfirmationDialogProps;
  setOpenModal: (value: ConfirmationDialogProps) => void;
  Modal?: React.JSX.Element;
}
export const PlanBy: React.FC<Props> = ({ dataPlanby, dataRelay, size = 1, getGroup, setOpenModal, Modal }) => {
  const epg = dataPlanby?.epg;
  const theme = useTheme();
  const { getEpgProps, getLayoutProps } = useEpg({
    epg,
    channels: dataPlanby?.channels,
    startDate: dataRelay?.dates?.start, // or 2022-02-02T00:00:00
    endDate: dataRelay?.dates?.end,
    isBaseTimeFormat: true,
    dayWidth: 1000 * size, //ajusta tamaño de celdas
    isLine: false,
    theme: theme.planByTheme,
  });

  return (
    <Epg {...getEpgProps()}>
      <Layout
        {...getLayoutProps()}
        renderProgram={({ program, ...rest }) => (
          <ProgramItem key={program.data.id} program={program} {...rest} />
        )}
        renderChannel={({ channel }) => (
          <ChannelItem key={channel.uuid} channel={channel} getGroup={getGroup} setOpenModal={setOpenModal} Modal={Modal}/>
        )}
        renderTimeline={(props) => <Timeline {...props} />}
      />
    </Epg>
  );
};
