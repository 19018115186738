import React, { useMemo } from "react";
import { Theme, makeStyles, Typography } from "@material-ui/core";
import { Tooltip } from "components";
import { TrendIconMapping, WaitData } from "interfaces";
import { BaseElement } from "components/List";
import { parseTrendNumber } from "utils";

interface Props {
  data: WaitData;
  spacing: string[];
  grayOut: boolean;
}

export const BalanceTableTendencyCell: React.FC<Props> = ({
  data,
  spacing,
  grayOut,
}) => {
  const classes = useStyles();
  const value = useMemo(() => parseTrendNumber(data.value), [data.value]);
  const accValue = useMemo(
    () => parseTrendNumber(data.accValue),
    [data.accValue]
  );

  const tooltipTexts = useMemo(
    () => [`Últimos 60': ${value}`, `Acumulado: ${accValue}`],
    [value, accValue]
  );
  const Icon = useMemo(
    () => data.trend && TrendIconMapping[data.trend],
    [data.trend]
  );

  return (
    <BaseElement classNames={[...spacing, grayOut ? classes.grayOut : ""]}>
      <Tooltip
        title={tooltipTexts.map((text) => (
          <Typography key={text} variant="body2">
            {text}
          </Typography>
        ))}
        arrow
        placement="right"
        TransitionProps={{ enter: false, exit: false }}
      >
        <div>
          <Typography
            variant="subtitle1"
            component="span"
            className={classes.text}
          >
            {value}
          </Typography>
          {data.trend && Icon && <Icon className={classes.icon} />}
        </div>
      </Tooltip>
    </BaseElement>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  text: {
    verticalAlign: "middle",
    marginRight: "5px",
  },
  icon: {
    verticalAlign: "middle",
  },
  grayOut: {
    opacity: 0.6,
  },
}));
