import React, { useCallback, useMemo } from "react";
import {
  HeaderCellProps,
  NestedKeyOf,
  PerformanceRatingOperatorEvent,
  getPracticesTranslation,
  RowProps,
  ScreenType,
} from "interfaces";
import { makeStyles } from "@material-ui/core";
import { formatLocalizedDate, parseTimeDelta } from "utils";
import { VirtualizedTable } from "components";

interface Props {
  data: PerformanceRatingOperatorEvent[];
  loading: boolean;
}

export const PerformanceRatingOperatorEventsTable: React.FC<Props> = ({
  data,
  loading,
}) => {
  const classes = useStyles();

  const headCells = useMemo<
    HeaderCellProps<NestedKeyOf<PerformanceRatingOperatorEvent>>[]
  >(
    () => [
      {
        dataKey: "operatorPractice",
        label: "Tipo de evento",
        align: "center",
        sortable: true,
        className: classes.headerCell,
      },
      {
        dataKey: "equipId",
        label: "CAEX",
        align: "center",
        sortable: true,
        className: classes.headerCell,
      },
      {
        dataKey: "startDate",
        label: "Fecha de inicio",
        align: "center",
        sortable: true,
        className: classes.headerCell,
      },

      {
        dataKey: "duration",
        label: "Duración total",
        align: "center",
        sortable: true,
        className: classes.headerCell,
      },
      {
        dataKey: "exceedTime",
        label: "Demora",
        align: "center",
        sortable: true,
        className: classes.headerCell,
      },
    ],
    [classes]
  );

  const rowDetails = useCallback(
    (
      datum: PerformanceRatingOperatorEvent
    ): RowProps<NestedKeyOf<PerformanceRatingOperatorEvent>> => ({
      cellsData: [
        {
          dataKey: "operatorPractice",
          value: getPracticesTranslation(
            datum.operatorPractice,
            ScreenType.OPERATOR
          ),
          align: "center",
        },
        {
          dataKey: "equipId",
          value: datum.equipId ?? "-",
          align: "center",
        },
        {
          dataKey: "startDate",
          value: datum.startDate
            ? formatLocalizedDate(datum.startDate, "yyyy-MM-dd HH:mm")
            : "-",
          align: "center",
        },
        {
          dataKey: "duration",
          value: datum.duration ? parseTimeDelta(datum.duration) : "-",
          align: "center",
        },
        {
          dataKey: "exceedTime",
          value: datum.exceedTime ? parseTimeDelta(datum.exceedTime) : "-",
          align: "center",
        },
      ],
    }),
    []
  );

  return (
    <VirtualizedTable
      loadingData={loading}
      data={data}
      renderCells={rowDetails}
      headers={headCells}
    />
  );
};

const useStyles = makeStyles(() => ({
  headerCell: {
    width: "100%",
  },
  chipExceedEvents: {
    width: "100%",
    height: 24,
    maxWidth: 48,
  },
}));
