import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button, ButtonGroup, Menu, MenuItem, Theme, makeStyles} from '@material-ui/core';
import { ArrowDropDown } from "@material-ui/icons"
import clsx from "clsx";
import { Restricted } from './Restricted';
import { Can, Module } from 'interfaces';
import { UserContext } from 'contexts';

interface ButtonGroupOptionsProps {
  id: string,
  text: string,
  color?: string,
  icon?: JSX.Element,
  changeOption?: boolean
}

interface SelectedValue {
  id: number,
  status?: string
}

interface NewSelectedValue {
  id: number,
  currentStatus?: string,
  previousStatus?: string
}

interface Props {
  options: ButtonGroupOptionsProps[],
  selectedValue: SelectedValue,
  onStateChange: (item: NewSelectedValue) => void,
  restrictedTo: Module
}

/**
 * ButtonGroup can also be used to create a split button. The dropdown can change the button action
 * @param options  value list. It must contain an id (mandatory), a text (mandatory), 
 * color: it is a color code that must be declared in this component (optional), 
 * icon (optional) and an option change: Allows you to determine whether or not
 *  you can go to other state (optional)
 * @param selectedValue its value must match some id of the list of options
 * @param onStateChange function that updates the state in the parent component
 */
export const ButtonGroupComponent: React.FC<Props>= ({
  options,
  selectedValue,
  onStateChange,
  restrictedTo,
}) => {

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedItem, setSelectedItem] = useState<ButtonGroupOptionsProps>(options?.find((item)=>item.id === selectedValue.status) ?? options[0]);
    const { isAllowedTo } = useContext(UserContext);

    const classes = useStyles({
      buttonColor: selectedItem.color, 
      minSize: selectedItem.changeOption,
      permission: isAllowedTo(Can.WRITE, restrictedTo)
    });

    useEffect(() => {
      setSelectedItem(options?.find((item)=>item.id === selectedValue.status) ?? options[0])
    }, [selectedValue, options])
    
    const handleButtonClick = (item: ButtonGroupOptionsProps) => {
      setSelectedItem(item);
      onStateChange({id:selectedValue.id, currentStatus:item.id, previousStatus:selectedValue.status});
      setOpen(false);
    };
    
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event: Event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return;
      setOpen(false);
    };

    //remove selected option of the list
    const filterOpcions = useMemo(() => {
        if (!options) return [];
        return options.filter((item) => item.id !== selectedItem.id)
      }, [options, selectedItem]
    )

  return (
    <>
    <ButtonGroup className={classes.customButtonGroup} variant="contained" ref={anchorRef} aria-label="split button">
      <Button className={clsx(classes.selectedButton) } onClick={() => handleButtonClick(selectedItem) }>
        {selectedItem ? selectedItem.icon : "" } {selectedItem.text}
      </Button>
      {selectedItem && selectedItem.changeOption ?
      <Restricted to={Can.WRITE} at={restrictedTo}>
        <Button
          onClick={handleToggle}
          className={classes.dropDownColor}
        >
          <ArrowDropDown />
        </Button> 
      </Restricted>
       : ""}
    </ButtonGroup>
     <Menu
      id="split-button"
      anchorEl={anchorRef.current}
      open={open}
      onClose={handleClose}
      className={classes.customMenu}
      MenuListProps={{
        'aria-labelledby': 'split-button',
        style: {
        top:'100%',
        minWidth: '130px'
      }
      }}
      >
        {filterOpcions.map((item) => (
          <MenuItem
            key={item.text}
            onClick={() => handleButtonClick(item)}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

interface StyleProps {
  buttonColor?: string,
  minSize?: boolean,
  permission?: boolean,
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => {
    const { palette } = theme;

    interface props {
      color?: string
    }

    interface dic {
      [key: string]: string
    }

    const colors: dic = {
      primary_main: palette.primary.main,
      info_main: palette.info.main,
      primary_dark: palette.primary.dark,
      info_dark: palette.info.dark,
      grey_main: palette.type === "dark" ? palette.grey[700]: palette.grey[200],
      grey_disabled_main: palette.type === "dark" ? palette.grey[700]: palette.grey[200],
      primary_text: palette.info.contrastText,
      info_text: palette.info.contrastText,
      grey_disabled_text: palette.text.disabled,
      grey_text: palette.text.primary,
      default:  palette.secondary.main
    }

    const setColor = ({color}: props) => {
      return !color ? colors["default"] : colors[color] ?? colors["default"]
    }

    return {
      customButtonGroup: {
        position: "relative",
        '& .MuiButtonGroup-groupedContainedHorizontal:not(:first-child)':{
          border: "unset"
        },
        '& .MuiButtonGroup-groupedContainedHorizontal:not(:last-child)':{
          border: "unset"
        }
      },
      dropDownColor: ({buttonColor, minSize}) => ({
        backgroundColor: setColor({color: `${buttonColor}_dark`}),
        width: minSize ? '40px' : '',
        color: palette.info.contrastText,
        borderRadius: "0px 4px 4px 0px",
        '&:hover': {
          backgroundColor: setColor({color: `${buttonColor}_dark`}),
          boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
        minWidth: 'unset',
      }),
      selectedButton: ({buttonColor, minSize, permission}) => ({
        backgroundColor: setColor({color: `${buttonColor}_main`}),
        borderRight: 'unset',
        width: !permission ? '140px' : minSize ? '100px' : '140px',
        height: !permission ? '36px' : '36px',
        fontSize: '13px',
        letterSpacing: '0.46px',
        color: setColor({color: `${buttonColor}_text`}),
        pointerEvents: "none",
        '&:hover': {
          backgroundColor: setColor({color: `${buttonColor}_main`})
        }
      }),
      customMenu: {
        position: 'absolute',
        top:'100%',
        marginTop: '44px'
      }
    }
})