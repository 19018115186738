import {
  TableCell,
  Typography,
  TableCellProps,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";

interface UnplannedParkedTruckTextCellProps {
  value: React.ReactNode;
  maxWidth: UseStylesProps["maxWidth"];
  align: TableCellProps["align"];
}

export const UnplannedParkedTruckTextCell: React.FC<
  UnplannedParkedTruckTextCellProps
> = ({ value, maxWidth, align }) => {
  const classes = useStyles({ maxWidth });
  return (
    <TableCell
      className={classes.cell}
      component="td"
      align={align}
      scope="row"
    >
      <Typography variant="body1">{value}</Typography>
    </TableCell>
  );
};

interface UseStylesProps {
  maxWidth?: number;
}

const useStyles = makeStyles<Theme, UseStylesProps>(() => ({
  cell: {
    maxWidth: ({ maxWidth }) => maxWidth ?? "unset",
    paddingTop: 5,
    paddingBottom: 5,
  },
}));
