import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ShiftChangeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      {...props}
    >
      <path d="M6.5 24L0.5 18L6.5 12L8.6375 14.1L6.2375 16.5H12.5V19.5H6.2375L8.6 21.9L6.5 24ZM17 19.5C16.575 19.5 16.2188 19.3563 15.9313 19.0688C15.6438 18.7812 15.5 18.425 15.5 18C15.5 17.575 15.6438 17.2188 15.9313 16.9313C16.2188 16.6438 16.575 16.5 17 16.5C17.425 16.5 17.7812 16.6438 18.0688 16.9313C18.3563 17.2188 18.5 17.575 18.5 18C18.5 18.425 18.3563 18.7812 18.0688 19.0688C17.7812 19.3563 17.425 19.5 17 19.5ZM23 19.5C22.575 19.5 22.2188 19.3563 21.9313 19.0688C21.6438 18.7812 21.5 18.425 21.5 18C21.5 17.575 21.6438 17.2188 21.9313 16.9313C22.2188 16.6438 22.575 16.5 23 16.5C23.425 16.5 23.7812 16.6438 24.0688 16.9313C24.3563 17.2188 24.5 17.575 24.5 18C24.5 18.425 24.3563 18.7812 24.0688 19.0688C23.7812 19.3563 23.425 19.5 23 19.5ZM21.5 12L19.3625 9.9L21.7625 7.5H15.5V4.5H21.7625L19.4 2.1L21.5 0L27.5 6L21.5 12ZM5 7.5C4.575 7.5 4.21875 7.35625 3.93125 7.06875C3.64375 6.78125 3.5 6.425 3.5 6C3.5 5.575 3.64375 5.21875 3.93125 4.93125C4.21875 4.64375 4.575 4.5 5 4.5C5.425 4.5 5.78125 4.64375 6.06875 4.93125C6.35625 5.21875 6.5 5.575 6.5 6C6.5 6.425 6.35625 6.78125 6.06875 7.06875C5.78125 7.35625 5.425 7.5 5 7.5ZM11 7.5C10.575 7.5 10.2188 7.35625 9.93125 7.06875C9.64375 6.78125 9.5 6.425 9.5 6C9.5 5.575 9.64375 5.21875 9.93125 4.93125C10.2188 4.64375 10.575 4.5 11 4.5C11.425 4.5 11.7812 4.64375 12.0688 4.93125C12.3563 5.21875 12.5 5.575 12.5 6C12.5 6.425 12.3563 6.78125 12.0688 7.06875C11.7812 7.35625 11.425 7.5 11 7.5Z" />
    </SvgIcon>
  );
};
