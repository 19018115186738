import React, { useCallback, useState } from "react";
import { makeStyles, SvgIconProps, IconButton, alpha } from "@material-ui/core";
import { Label } from "components";
import { DateTimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import TodayRoundedIcon from "@material-ui/icons/TodayRounded";
import { formatLocalizedDate } from "utils";
import { isToday, isTomorrow } from "date-fns";

interface TruckDetailItemDateTimePickerClassesProps {
  root?: string;
}

interface TruckDetailItemDateTimePickerProps {
  label: string;
  icon: React.FC<SvgIconProps<"svg", {}>>;
  value: Date | null | string;
  onChange: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => void;
  classes?: TruckDetailItemDateTimePickerClassesProps;
  children?: React.ReactNode;
}

export const TruckDetailItemDateTimePicker: React.FC<
  TruckDetailItemDateTimePickerProps
> = ({
  label,
  icon: Icon,
  value,
  onChange,
  classes: propClasses,
  children,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = useCallback(() => setOpen(true), []);
  const handleCloseDialog = useCallback(() => setOpen(false), []);

  return (
    <div className={clsx(classes.truckSelectorItemRoot, propClasses?.root)}>
      <Label
        classes={{ text: classes.itemLabelText }}
        text={label}
        icon={Icon}
      />
      <DateTimePicker
        clearable
        showTodayButton
        todayLabel="Ahora"
        clearLabel="Limpiar"
        cancelLabel="Cancelar"
        disablePast
        value={value}
        ampm={false}
        minutesStep={5}
        onChange={onChange}
        open={open}
        minDateMessage=""
        className={classes.timePicker}
        DialogProps={{ className: classes.dialogPicker }}
        views={["hours", "minutes", "date", "month"]}
        openTo={"hours"}
        labelFunc={customFormatDate}
        // onAccept={}
        onClose={handleCloseDialog}
        onOpen={handleOpenDialog}
      />
      <IconButton
        onClick={handleOpenDialog}
        className={classes.calendarIconButton}
      >
        <TodayRoundedIcon className={classes.calendarIcon} />
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    truckSelectorItemRoot: {
      display: "flex",
      marginTop: 6,
      marginBottom: 6,
      alignItems: "center",
      marginRight: 60,
      "&:last-child": {
        marginRight: 0,
      },
    },
    itemLabelText: {
      fontWeight: 700,
      width: 125,
    },
    itemText: {},
    timePicker: {
      minWidth: 205,
      maxWidth: 240,
      maxHeight: 28,
    },
    dialogPicker: {
      "& .MuiPickersToolbar-toolbar": {
        backgroundColor: palette.primary.main,
      },
      "& .MuiPickersTimePickerToolbar-separator": {
        colo: alpha(palette.common.white, 0.5),
      },
      "& .MuiPickersToolbarText-toolbarTxt": {
        color: alpha(palette.common.white, 0.5),
      },
      "& .MuiPickersToolbarText-toolbarBtnSelected": {
        color: palette.common.white,
      },
    },
    calendarIconButton: {
      color: theme.palette.text.primary,
      marginLeft: 5,
      padding: 4,
    },
    calendarIcon: {
      width: 20,
      height: 20,
    },
  };
});

const customFormatDate = (date: MaterialUiPickersDate, _: string): string => {
  if (!date) return "";
  if (isToday(date)) {
    return `Hoy a las ${formatLocalizedDate(date, "HH:mm")} hrs.`;
  }
  if (isTomorrow(date)) {
    return `Mañana a las ${formatLocalizedDate(date, "HH:mm")} hrs.`;
  }
  return formatLocalizedDate(date, "dd 'de' MMMM, HH:mm 'hrs.'") ?? "";
};
