import { CustomLayer } from "@nivo/line";
import { EnhancedPoint } from "interfaces";
import React from "react";

export const CurrentSlicePointsLayer: CustomLayer = ({ currentSlice }) => {
  if (currentSlice === null) return null;

  return (
    <g>
      {currentSlice?.points?.map(
        (point: EnhancedPoint) =>
          point.data?.changeEvents?.length === 0 && (
            <circle
              key={`graphPoint-${point.id}`}
              r={6}
              fill={point.serieColor}
              fillOpacity={1}
              cx={point.x}
              cy={point.y}
            />
          )
      )}
    </g>
  );
};
