import { create } from "zustand";
import { AppParam } from "interfaces";
import { omit } from "lodash";

export type AppParamsPendingChanges = Record<AppParam["id"], string | string[]>;

interface AppStateInterface {
  pendingChanges: AppParamsPendingChanges;
  setPendingChanges: (newPendingChanges: AppParamsPendingChanges) => void;
  removePendingChanges: (id: number) => void;
  resetPendingChanges: () => void;
}

export const useAppParams = create<AppStateInterface>()((set) => {
  return {
    pendingChanges: {},
    setPendingChanges: (pendingChange: AppParamsPendingChanges) =>
      set((state) => ({
        ...state,
        pendingChanges: {
          ...state.pendingChanges,
          ...pendingChange,
        },
      })),
    removePendingChanges: (id: number) =>
      set((state) => ({
        ...state,
        pendingChanges: omit(state.pendingChanges, id),
      })),
    resetPendingChanges: () =>
      set((state) => ({
        ...state,
        pendingChanges: {},
      })),
  };
});
