import React from "react";
import { makeStyles, Typography, Theme } from "@material-ui/core";
import { clientMapping, MineMovements } from "interfaces";
import { parseNumber } from "utils";

export const SicKpiDetail: React.FC<MineMovements> = ({
  target,
  origin,
  loadtons,
}) => {
  const classes = useStyles();
  const parsedLoadtons = parseNumber(loadtons / 1000, 0);
  const parsedTarget = parseNumber((target ?? 0) / 1000, 0);
  return (
    <div className={classes.kpiDetails}>
      <Typography
        className={classes.targetEntity}
      >{`${clientMapping[origin]} [kt]`}</Typography>
      <span>
        <Typography
          variant="h5"
          component="span"
          className={classes.loadtonsValue}
        >
          {target ? `${parsedLoadtons} (${parsedTarget})` : parsedLoadtons}
        </Typography>
      </span>
    </div>
  );
};
const useStyles = makeStyles<Theme>((theme) => {
  return {
    kpiDetails: {
      display: "flex",
      flexDirection: "column",
      minWidth: 100,
      width: "100%",
      [theme.breakpoints.between("md", "lg")]: {
        minWidth: 100,
      },
    },
    targetEntity: {
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.15px",
      textAlign: "left",
    },
    loadtonsValue: {
      width: 100,
      fontFamily: "Roboto",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "0.15px",
      textAlign: "left",
    },
    divider: {
      margin: 10,
    },
  };
});
