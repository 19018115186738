import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ChipGroup, YellowTruckIcon } from "components";
import {
  InfoTooltipText,
  TableHeatMapComponent,
} from "components/HeatMapCustom/TableHeatMapComponent";
import { WithoutData } from "components/WithoutData";
import { TruckAssigmentCycleTimeContext } from "contexts/TruckAssigmentCycleTimeContext";
import { EntityFields, HeatMapPerformaceParser } from "interfaces";
import { HeatMapData, FiltersAutocomplete } from "interfaces/heatMap.interface";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CardComponent } from "components/CardComponent/CardComponent";
import { TruckPerformanceContext } from "contexts/TruckPerformanceContext";
import { TruckPerformanceActionType } from "reducers/truck-performace";

interface FilterPeriod {
  id: string;
  name: string;
}
export const TruckAssigmentCycleTime: React.FC = () => {
  const classes = useStyles();

  //Filters
  //Get filters of phases to "Select" Component
  const { data: phasesFilter } = useContext(TruckAssigmentCycleTimeContext);
  const {
    dispatch,
    data: dataTable,
    firstLoading,
    refetching,
  } = useContext(TruckPerformanceContext);
  const [phaseFilter, setPhaseFilter] = useState<string>("");

  const PeriodType: FilterPeriod[] = [
    { id: "SHIFT", name: "Turno actual" },
    { id: "HOURS", name: "Últ. 2 horas" },
  ];

  const [periodFilter, setPeriodFilter] = useState<string>(PeriodType[0].id);
  const periodOptions = Object.values(PeriodType).map((value) => ({
    id: value.id,
    name: value.name,
  }));

  useEffect(() => {
    if (phasesFilter && phasesFilter?.phases?.length) {
      if (phaseFilter === "") {
        setPhaseFilter(phasesFilter.phases[0]);
        dispatch({
          type: TruckPerformanceActionType.PHASE_CHANGE,
          phase: phasesFilter.phases[0],
        });
      }
    }
  }, [phasesFilter, phaseFilter, dispatch]);

  const { table, caex } = dataTable;
  const loadingData = firstLoading || refetching;

  //Table Config
  const initialColumns: string[] = ["Pala", "Destino"]; //Adds columns to table

  const columnConfig = [
    { name: "Cola origen" },
    { name: "Aculatamiento" },
    { name: "T. Carguío" },
    { name: "Cola Destino" },
    { name: "Descarga Destino" },
    { name: "Viaje Lleno + Vacio" },
    { name: "Tiempo de Ciclo", hasSpaceBefore: true },
    { name: "Movimiento [kt]" },
    { name: "Impacto TC Global", hasSpaceBefore: true },
    { name: "Cumplimiento" },
  ];

  const filters: FiltersAutocomplete[] = [
    { id: 0, name: "0 - 0,2 Min", label: "A" },
    { id: 1, name: "0,2 - 0,5 Min", label: "B" },
    { id: 2, name: "> 0,5 Min", label: "C" },
    { id: 3, name: "Menor TC", label: "D" },
    { id: 4, name: "Sin envío", label: "E" },
  ];
  const [selectedFilters, setSelectedFilters] = useState<FiltersAutocomplete[]>(
    []
  );

  const sortData = (data: HeatMapData[]) => {
    return data.sort((a, b) => {
      // if it has no value it is sent to the end of the list
      const valueA =
        a.data.find((item) => item.id === "IMPACT_TC")?.value ?? -100;
      const valueB =
        b.data.find((item) => item.id === "IMPACT_TC")?.value ?? -100;
      return valueB - valueA;
    });
  };

  const formatData = (data: HeatMapData[]) => {
    const newData = data.map((objeto) => {
      const format = objeto.data.map((item) => {
        const parser = HeatMapPerformaceParser[item.id];
        const valueParser = parser?.mainValue;
        return {
          ...item,
          value: valueParser ? valueParser(item.value) : item.value,
        };
      });
      return {
        ...objeto,
        data: format,
      };
    });
    return newData as HeatMapData[];
  };

  const filterData: HeatMapData[] = useMemo(() => {
    if (!table?.length) return [];
    if (!selectedFilters.length) return sortData(table);

    let filteredData: HeatMapData[] = [];
    table?.forEach((excav) => {
      let insertedValue: boolean = false;
      for (let filter of selectedFilters) {
        let findMov = excav.data.find((item) => item.id === "MOVEMENT");
        let findTC = excav.data.find((item) => item.id === "IMPACT_TC");
        switch (filter.name) {
          case "Sin envío": // Adherence = 0
            if (findMov && findMov.value === 0) {
              filteredData?.push({ ...excav });
              insertedValue = true;
            }
            break;
          case "0,2 - 0,5 Min": // TC < 0
            if (
              findTC &&
              findTC.value &&
              findTC.value >= 0.2 &&
              findTC.value < 0.5
            ) {
              filteredData?.push({ ...excav });
              insertedValue = true;
            }
            break;
          case "0 - 0,2 Min":
            if (
              findTC &&
              findTC.value !== null &&
              findTC.value >= 0 &&
              findTC.value < 0.2
            ) {
              filteredData?.push({ ...excav });
              insertedValue = true;
            }
            break;
          case "> 0,5 Min":
            if (findTC && findTC.value && findTC.value >= 0.5) {
              filteredData?.push({ ...excav });
              insertedValue = true;
            }
            break;
          case "Menor TC":
            if (findTC && findTC.value && findTC.value < 0) {
              filteredData?.push({ ...excav });
              insertedValue = true;
            }
            break;
        }
        if (insertedValue) break;
      }
    });
    return sortData(filteredData);
  }, [selectedFilters, table]);

  const infoTooltipTexts = useMemo<InfoTooltipText>(
    () => ({
      title: "Tiempos del Plan:",
      values: [
        {
          section: "Cola Origen",
          text: `1,3 min`,
        },
        {
          section: "Aculatamiento",
          text: `1 min`,
        },
        {
          section: "T Carguío",
          text: `1,7 min`,
        },
        {
          section: "Cola CH",
          text: `1,3 min`,
        },
        {
          section: "Cola otros destinos",
          text: `0 min`,
        },
        {
          section: "Residencia CH Sulfuro",
          text: `3,2 min o 190 seg`,
        },
        {
          section: "Residencia Pilas/Bot/Stock",
          text: `2,7 min o 145 seg`,
        },
        {
          section: "* Plan Referencial",
          text: `Plan obtenido del comportamiento histórico del mismo origen-destino`,
        },
      ],
      footer: 
      {
        section: "** Residencia",
        text: `Tiempo que ingresa a baliza hasta la siguiente asignación`,
      }
    }),
    []
  );

  const onFilterPhaseChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      const newValue = e.target.value as EntityFields["id"];
      setPhaseFilter(newValue);
      dispatch({
        type: TruckPerformanceActionType.PHASE_CHANGE,
        phase: newValue,
      });
    },
    [dispatch]
  );

  const onChipChange = useCallback(
    (value: string | number) => {
      setPeriodFilter(value.toString());
      dispatch({
        type: TruckPerformanceActionType.PERIOD_CHANGE,
        period: value.toString(),
      });
    },
    [dispatch]
  );

  return (
    <Grid container className={classes.cycleTimeContainer}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.cycleTimeTitle}>
                  <b>Detalle del Tiempo de Ciclo por Fase</b>
                  <FormControl
                    variant="standard"
                    className={classes.phasesFilter}
                  >
                    <Select
                      value={phaseFilter}
                      onChange={onFilterPhaseChange}
                      label="Fases"
                      MenuProps={{ classes: { paper: classes.select } }}
                    >
                      {phasesFilter["phases"]?.map((phase) => (
                        <MenuItem key={phase} value={phase}>
                          {phase}
                        </MenuItem>
                      ))}
                      <MenuItem key={'ALL'} value={'ALL'}>
                        Todas las fases
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item style={{ display: "contents" }} xs={4}>
                <ChipGroup
                  classes={{ chipGroupRoot: classes.chip }}
                  value={periodFilter}
                  options={periodOptions}
                  onChange={onChipChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loadingData ? (
        <div className={classes.infoContainer}>
          <CircularProgress
            style={{ marginBottom: 24 }}
            size={120}
            color="primary"
          />
        </div>
      ) : (
        <>
          <TableHeatMapComponent
            dataTable={formatData(filterData)}
            columnConfig={columnConfig}
            initialColumns={initialColumns}
            filtersAutocomplete={filters}
            selectedFilters={selectedFilters}
            updateSelectedFilters={setSelectedFilters}
            filtersTitle={"Filtros Impacto TC"}
            title="Pala - Destino"
            tooltipTitle={infoTooltipTexts}
          />
          <Typography
            variant="h6"
            className={classes.cycleTimeTitle}
            style={{ paddingTop: 24 }}
          >
            <b>Brechas CAEX</b>
          </Typography>
          {caex?.data?.length ? (
            <CardComponent
              cardData={caex}
              icon={<YellowTruckIcon className={classes.icon} />}
            />
          ) : (
            <WithoutData
              title={`No se encontraron brechas existentes para generar esta visualización.`}
              subtitle={"Intente modificar los filtros seleccionados"}
            />
          )}
        </>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  cycleTimeContainer: {
    background:
      palette.type === "light"
        ? palette.background.default
        : palette.background.paper,
    borderRadius: "4px",
    padding: "16px",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)"
  },
  cycleTimeTitle: {
    paddingBottom: "18px",
    fontSize: 18,
  },
  phasesFilter: {
    alignSelf: "flex-end",
    minWidth: 150,
    paddingLeft: 30,
  },
  select: {
    marginTop: 10,
  },
  chip: {
    //maxWidth: 91
  },
  infoContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    paddingBottom: 30,
    paddingTop: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    height: "40px",
    width: "40px",
  },
}));
