import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { HeaderCellProps, Order } from "interfaces";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";

interface CustomTableHeaderProps<T> {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: T | string
  ) => void;
  order: Order;
  orderBy?: T | string;
  loading?: boolean;
  headerKey: string;
  headCells: HeaderCellProps<T>[];
}

export const CustomTableHeader = <T extends unknown>({
  onRequestSort,
  order,
  orderBy,
  loading,
  headerKey,
  headCells,
}: CustomTableHeaderProps<T>) => {
  const classes = useStyles();

  return (
    <TableHead
      key={`${headerKey}-header-root`}
      className={classes.tableHeadRoot}
    >
      <TableRow key={`${headerKey}-header-row`}>
        {headCells.map((headCell, i) => (
          <TableCell
            key={`${headerKey}-${headCell.dataKey}-${i}-cell`}
            align={headCell.align}
            padding="normal"
            sortDirection={orderBy === headCell.dataKey ? order : false}
            className={classes.tableCellHeader}
            variant="head"
          >
            {headCell.sortable ? (
              <TableSortLabel
                key={`${headerKey}-${headCell.dataKey}-${i}-sortable-label`}
                active
                direction={orderBy === headCell.dataKey ? order : "desc"}
                onClick={(e) =>
                  headCell.sortable ? onRequestSort(e, headCell.dataKey) : null
                }
                IconComponent={(props) => (
                  <ArrowDropDown
                    className={clsx(props.className, classes.sortIcon)}
                  />
                )}
              >
                <Typography className={classes.sortLabel} variant="subtitle1">
                  <b>{headCell.label}</b>
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography
                key={`${headerKey}-${headCell.dataKey}-${i}-label`}
                className={classes.sortLabel}
                variant="subtitle1"
              >
                <b>{headCell.label}</b>
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
      <TableRow key={`${headerKey}-linear-progress-row`}>
        <TableCell
          key={`${headerKey}-linear-progress-cell`}
          padding="none"
          colSpan={headCells.length}
        >
          {loading && <LinearProgress className={classes.linearLoader} />}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles(({ palette }: Theme) => {
  const cellBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;
  return createStyles({
    tableHeadRoot: {
      position: "sticky",
      zIndex: 1,
      top: 0,
    },
    tableCellHeader: {
      backgroundColor: cellBackground,
      color: palette.getContrastText(cellBackground),
      paddingTop: 10,
      paddingBottom: 9,
      borderBottom: 0,
    },
    sortLabel: {
      color: palette.getContrastText(cellBackground),
    },
    sortIcon: {
      color: `${palette.getContrastText(cellBackground)} !important`,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    linearLoader: {
      height: 5,
      position: "absolute",
      width: "100%",
      zIndex: 2,
    },
  });
});
