import React, { Dispatch, SetStateAction, useCallback } from "react";
import {
  FormControlLabel,
  FormGroup,
  makeStyles,
  Switch,
  Theme,
} from "@material-ui/core";
import { ChangeType, changeSwitchLabel } from "interfaces";
import { StopRounded, PlayArrowRounded } from "@material-ui/icons";

interface FilterControlProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: string;
}

const MetricFilterControl: React.FC<FilterControlProps> = ({
  checked,
  onChange,
  name,
  label,
}) => {
  const classes = useSwitchStyles({ checked });
  return (
    <div className={classes.filterContainer}>
      <div className={classes.iconContainer}>
        {name === ChangeType.TOOL ? (
          <StopRounded className={classes.squareStyle} />
        ) : (
          <PlayArrowRounded className={classes.triangleStyle} />
        )}
      </div>
      <FormControlLabel
        className={classes.switchContainer}
        control={
          <Switch
            className={
              name === ChangeType.TOOL
                ? classes.toolManagementSwitch
                : classes.modularManagementSwitch
            }
            checked={checked}
            onChange={onChange}
            name={name}
          />
        }
        label={label}
        labelPlacement="start"
      />
    </div>
  );
};

interface MetricsFiltersProps {
  state: Record<ChangeType, boolean>;
  changeTypeCounter: Record<ChangeType, number>;
  shownChangeTypes: ChangeType[];
  onChange: Dispatch<SetStateAction<Record<ChangeType, boolean>>>;
}

export const MetricsFilters: React.FC<MetricsFiltersProps> = ({
  state,
  changeTypeCounter,
  shownChangeTypes,
  onChange,
}) => {
  const classes = useStyles();

  const onFiltersChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      onChange({ ...state, [event.target.name]: event.target.checked });
    },
    [onChange, state]
  );

  return (
    <>
      <form noValidate autoComplete="off">
        <div className={classes.formGroup}>
          <FormGroup row>
            {shownChangeTypes.map((type) => (
              <MetricFilterControl
                key={type}
                checked={state[type]}
                name={type}
                onChange={onFiltersChange}
                label={`${changeSwitchLabel[type]} (${changeTypeCounter[type]})`}
              />
            ))}
          </FormGroup>
        </div>
      </form>
    </>
  );
};

interface UseStylesProps {
  checked: boolean;
}

const useSwitchStyles = makeStyles<Theme, UseStylesProps>((theme) => {
  const lightMode = theme.palette.type === "light";

  return {
    filterContainer: {
      display: "flex",
      flexDirection: "row",
      padding: "0 15px",
    },
    switchContainer: {
      marginLeft: 0,
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      marginRight: "5px",
    },
    squareStyle: {
      color:
        theme.palette.type === "light"
          ? theme.palette.secondary.main
          : theme.palette.common.white,
      transform: "scale(1.25)",
      marginBottom: "3px",
    },
    triangleStyle: {
      color: theme.palette.movementTarget,
      transform: "scale(1.4) rotate(-90deg)",
    },
    toolManagementSwitch: {
      "&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked": {
        color: lightMode
          ? theme.palette.secondary.main
          : theme.palette.common.white,
      },
      "&.MuiSwitch-root .MuiSwitch-switchBase": {
        color: lightMode
          ? theme.palette.common.white
          : theme.palette.secondary.dark,
      },
      "&.MuiSwitch-root .MuiSwitch-track": {
        backgroundColor: ({ checked }) =>
          !lightMode
            ? checked
              ? theme.palette.common.white
              : theme.palette.secondary.main
            : theme.palette.secondary.main,
      },
    },
    modularManagementSwitch: {
      "&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked": {
        color: theme.palette.movementTarget,
      },
      "&.MuiSwitch-root .MuiSwitch-switchBase": {
        color: lightMode
          ? theme.palette.common.white
          : theme.palette.secondary.dark,
      },
      "&.MuiSwitch-root .MuiSwitch-track": {
        backgroundColor: ({ checked }) =>
          checked ? theme.palette.movementTarget : theme.palette.secondary.main,
      },
    },
  };
});

const useStyles = makeStyles((theme) => ({
  formGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    userSelect: "none",
  },
}));
