import {
  Button,
  Grid,
  SvgIconProps,
  Typography,
  makeStyles,
  styled,
} from "@material-ui/core";
import React from "react";
import { Cancel } from "@material-ui/icons";
import { alpha } from "@material-ui/core/styles";
import { Tooltip } from "components/Tooltip";

interface ChipData {
  id: string;
  title: string;
  label?: string | null;
  iconLabel?: React.FC<SvgIconProps<"svg", {}>>;
  icon?: React.FC<SvgIconProps<"svg", {}>> | null;
  locationName?: string;
  iconPriority?: React.FC<SvgIconProps<"svg", {}>> | null;
}

interface ChipsProps {
  selectedOptions: ChipData[];
  setSelectedOptions: (id: string) => void;
  disabled: boolean;
}

interface CustomChipProps {
  id: string;
  title: string;
  label?: string | null;
  iconLabel?: React.FC<SvgIconProps<"svg", {}>>;
  icon?: React.FC<SvgIconProps<"svg", {}>> | null;
  locationName?: string;
  disabled: boolean;
  iconPriority?: React.FC<SvgIconProps<"svg", {}>> | null;
}

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Paper = styled("ul")(({ theme }) => ({
  display: "flex",
  listStyle: "none",
  flexWrap: "wrap",
  paddingInlineStart: "10px",
}));

export const ChipsArray = ({
  selectedOptions,
  setSelectedOptions,
  disabled
}: ChipsProps) => {

  const handleDelete = (chipToDelete: string) => () => {
    setSelectedOptions(chipToDelete);
  };
  const classes = useStyles();

  const CustomChip = ({
    title,
    id,
    label,
    iconLabel: IconLabel,
    icon: Icon,
    locationName,
    disabled,
    iconPriority: IconP
  }: CustomChipProps) => {

    return (
      <Grid
        container
        key={label}
        alignContent="center"
        alignItems="center"
        className={classes.chipContainer}
      >
        {Icon && locationName && (
          <Tooltip title={locationName} arrow placement="top">
            <Icon className={classes.chipIcon} />
          </Tooltip>
        )}
        {IconP && (
           <IconP className={classes.chipIcon} />
        )}
        <Typography variant="body2" className={classes.chipTitle}>
          {title}
        </Typography>
        {label ? (
          <Grid
            container
            alignContent="center"
            alignItems="center"
            className={classes.chipLabel}
          >
            {IconLabel && <IconLabel className={classes.iconLabel} />}
            <Typography
              variant="caption"
              style={{ fontWeight: 700, lineHeight: "unset" }}
            >
              {label}
            </Typography>
          </Grid>
        ) : (
          ""
        )}
        {!disabled && <Button onClick={handleDelete(id)} className={classes.chipButton}>
          <Cancel className={classes.chipCancel} />
        </Button>}
      </Grid>
    );
  };

  return (
    <Paper>
      {selectedOptions?.map((data) => {
        return (
          <React.Fragment key={data.id}>
            <ListItem key={data.id}>
              <CustomChip
                title={data.title}
                id={data.id}
                icon={data.icon}
                label={data.label}
                iconLabel={data.iconLabel}
                locationName={data.locationName}
                disabled={disabled}
                iconPriority={data.iconPriority}
              />
            </ListItem>
          </React.Fragment>
        );
      })}
    </Paper>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    chipContainer: {
      alignItems: "center",
      borderRadius: 100,
      background: palette.action.selected,
      cursor: "default",
      display: "inline-flex",
      padding: "4px 10px 4px 10px",
      "&&:hover,&&:focus": {
        color: alpha(palette.action.active, 0.54),
      },
    },
    chipTitle: {
      color: palette.type === "light" ? palette.common.black : palette.secondary.contrastText,
      fontSize: 13,
      paddingLeft: 5,
      paddingRight: 10,
    },
    chipCancel: {
      cursor: "pointer",
      fill: palette.type === "light" ? alpha(palette.action.active, 0.54): palette.action.active,
      height: 20,
      width: 20,
      "&&:hover,&&:focus": {
        fill: alpha(palette.action.active, 0.6),
      },
    },
    chipButton: {
      minWidth: "unset",
      padding: 0,
    },
    chipIcon: {
      height: 20,
      fill: alpha(palette.action.active, 0.54),
      width: 20,
    },
    chipLabel: {
      background: "white",
      borderRadius: 6,
      marginRight: 2,
      padding: "0px 6px 0px 6px",
      width: "auto",
      "&&:hover,&&:focus": {
        fill: alpha(palette.action.active, 0.54),
      },
    },
    iconLabel: {
      height: 15,
      width: 15,
    },
    
  };
});
