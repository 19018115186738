import { Grid, Typography, makeStyles } from "@material-ui/core";
import {
  ChipProps,
  CustomMultipleSelector,
  DataItemList,
  ModeAutocomplete,
  OptionsProps,
} from "components/selectorComponent/CustomMultipleSelector";
import React, { useCallback, useMemo, useState } from "react";
import {
  LocationOnOutlined,
  Star,
  LocalGasStationOutlined,
  BuildOutlined,
  Warning,
} from "@material-ui/icons";
import {
  CAEX,
  CaexFormProps,
  ExcavFormProps,
  FiltersProps,
  FormStepProps,
  OperatorFormProps,
} from "interfaces/relieve.interface";
import { BasicTruckIcon } from "components/icons/BasicTruckIcon";
import { parseNumber } from "utils";

interface Step2Props {
  dataLocation: FormStepProps;
  setItems: (values: OperatorFormProps[], type: string) => void;
  disabled: boolean;
  filters: FiltersProps;
}

export const RelieveMenuStep2: React.FC<Step2Props> = ({
  dataLocation,
  setItems,
  disabled,
  filters: filtersRelieve,
}) => {
  const classes = useStyles();
  const [excavsForm, setExcavsForm] = useState<ExcavFormProps[]>([]);

  const handleExcavsChange = (excavs: ExcavFormProps[]) => {
    setExcavsForm([...excavs]);
    setItems([...excavs], "excavs");
  };
  const handleExcavsChangeCount = (index: number, value: any) => {
    const newValue = [...excavsForm];
    newValue[index] = value;
    setExcavsForm(newValue);
    setItems(newValue, "excavs");
  };

  const handleUpPriority = (
    indexUp: number,
    indexDown: number,
    upValue: DataItemList,
    downValue: DataItemList
  ) => {
    const copyExcavx = [...excavsForm];
    copyExcavx[indexUp] = upValue;
    copyExcavx[indexDown] = downValue;

    const sortExcavs = copyExcavx.sort((a, b) => a.priority - b.priority);
    setExcavsForm(sortExcavs);
    setItems(sortExcavs, "excavs");
  };

  const groupBy = (array: any, key: string) => {
    return array?.reduce((result: any, currentValue: any) => {
      const groupKey = currentValue[key].group;
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {});
  };

  const orderByGroupID = (a: any, b: any, priority: (string | undefined)[]) => {
    const groupA = a.group.group.toUpperCase();
    const groupB = b.group.group.toUpperCase();

    if (priority?.includes(groupA) && !priority?.includes(groupB)) {
      return -1;
    }
    if (!priority?.includes(groupA) && priority?.includes(groupB)) {
      return 1;
    }
    if (groupA === "SIN CLASIFICAR" && groupB !== "SIN CLASIFICAR") return 1;
    if (groupA !== "SIN CLASIFICAR" && groupB === "SIN CLASIFICAR") return -1;

    return groupA < groupB ? -1 : groupA > groupB ? 1 : 0;
  };

  const orderByFuel = (a: any, b: any) => {
    if (a.disabled && !b.disabled) return 1;
    if (!a.disabled && b.disabled) return -1;

    const groupTypeA =
      a?.chipList?.find((item: ChipProps) => item.id === "type")?.value ?? 0;
    const groupTypeB =
      b.chipList.find((item: ChipProps) => item.id === "type")?.value ?? 0;
    if (groupTypeA === "PPM" && groupTypeB !== "PPM") return 1;
    if (groupTypeA !== "PPM" && groupTypeB === "PPM") return -1;

    if (groupTypeA === "PM" && groupTypeB !== "PM") return 1;
    if (groupTypeA !== "PM" && groupTypeB === "PM") return -1;

    let groupA =
      a?.chipList?.find((item: ChipProps) => item.id === "fuel")?.value ?? 0;
    let groupB =
      b.chipList.find((item: ChipProps) => item.id === "fuel")?.value ?? 0;

    groupA = Math.round(groupA);
    groupB = Math.round(groupB);

    return groupA < groupB ? 1 : groupA > groupB ? -1 : 0;
  };

  const orderByDisabled = (a: any, b: any) => {
    if (a.disabled && !b.disabled) return 1;
    if (!a.disabled && b.disabled) return -1;

    return a.title.toUpperCase() < b.title.toUpperCase()
      ? -1
      : a.title.toUpperCase() > b.title.toUpperCase()
      ? 1
      : 0;
  };

  const handleOperatorsChange = (
    operators: OperatorFormProps[],
    type: string
  ) => {
    setItems([...operators], type);
  };

  const handleCaexChange = (caex: CaexFormProps[]) => {
    setItems([...caex], "caex");
  };

  const orderGroup = useCallback((array: OptionsProps[]) => {
    const groupedData = groupBy(array, "group");

    let sortedOptions = Object.entries(groupedData ?? {})?.map(
      ([group, items]: any) => {
        items?.sort((a: OptionsProps, b: OptionsProps) =>
          orderByDisabled(a, b)
        );
        return { group: { group: group }, items: items };
      }
    );
    sortedOptions = sortedOptions?.sort((a: any, b: any) =>
      orderByGroupID(a, b, [])
    );

    let sortedOptions_ = sortedOptions?.map((item) => item.items);
    return sortedOptions_.reduce((acc, curr) => acc.concat(curr), []);
  }, []);

  //selectors config
  const operatorOwnFilter = useMemo(() => {
    let operOF = filtersRelieve?.operatorsOwn?.map((value) => {
      let isDisabled = value?.disabled ? value?.disabled : false;
      if (dataLocation?.operatorsOwn?.find((item) => item.id === value.id))
        isDisabled = false;

      return {
        id: value.id,
        title: value.name,
        group: { group: value.area ?? "SIN CLASIFICAR" },
        chipList: value.location?.alias
          ? [{ icon: LocationOnOutlined, value: value.location?.alias ?? "" }]
          : [],
        locationId: value.location?.id,
        locationName: value.location?.alias,
        icon:
          value?.location?.id !== dataLocation?.location?.id
            ? Warning
            : undefined,
        disabled: isDisabled,
      };
    });

    let sortedOptions = orderGroup(operOF);

    return sortedOptions;
  }, [filtersRelieve, dataLocation, orderGroup]);

  const otherOperatorFilter = useMemo(() => {
    let operOF = filtersRelieve?.operatorsOther?.map((value) => {
      let isDisabled = value?.disabled ? value?.disabled : false;
      if (dataLocation?.operatorsOther?.find((item) => item.id === value.id))
        isDisabled = false;

      return {
        id: value.id,
        title: value.name,
        chipList: value.location?.alias
          ? [{ icon: LocationOnOutlined, value: value.location?.alias ?? "" }]
          : [],
        group: { group: value.area ?? "SIN CLASIFICAR" },
        disabled: isDisabled,
        icon:
          value?.location?.id !== dataLocation?.location?.id
            ? Warning
            : undefined,
      };
    });

    let sortedOptions = orderGroup(operOF);

    return sortedOptions ?? [];
  }, [filtersRelieve, dataLocation, orderGroup]);

  const addChipCaex = (caex: CAEX) => {
    let newChips: ChipProps[] = [];
    if (caex.fuel !== undefined) {
      newChips.push({
        icon: LocalGasStationOutlined,
        value: parseNumber(caex.fuel * 100, 0),
        unit: "%",
        id: "fuel",
      });
    }
    if (caex.type !== undefined && caex.type !== "") {
      newChips.push({
        icon: BuildOutlined,
        value: caex.type?.toString(),
        id: "type",
      });
    }
    return newChips;
  };

  const caexFilter = useMemo(() => {
    let caexF = filtersRelieve?.caex?.map((value) => {
      let isDisabled = value?.disabled ? value?.disabled : false;
      if (dataLocation?.caex?.find((item) => item.id === value.id))
        isDisabled = false;

      return {
        id: value.id,
        title: value.id,
        iconPriority: value.hasPriority ? Star : null,
        group: { group: value.virtualRegion ?? "SIN CLASIFICAR" },
        placeHolder: "CAEX",
        chipList: addChipCaex(value),
        disabled: isDisabled,
      };
    });

    const priorityCaex = dataLocation?.excavs?.map((item) => {
      return item.id;
    });

    const priorityVR = filtersRelieve?.excavs
      ?.filter((value) => priorityCaex?.includes(value.id))
      ?.map((exc) => exc.virtualRegion);

    const groupedData = groupBy(caexF, "group");

    let sortedOptions = Object.entries(groupedData ?? {})?.map(
      ([group, items]: any) => {
        items?.sort((a: OptionsProps, b: OptionsProps) => orderByFuel(a, b));
        return { group: { group: group }, items: items };
      }
    );

    sortedOptions = sortedOptions?.sort((a: any, b: any) =>
      orderByGroupID(a, b, priorityVR)
    );

    let sortedOptions_ = sortedOptions?.map((item) => item.items);
    sortedOptions_ = sortedOptions_.reduce((acc, curr) => acc.concat(curr), []);

    return sortedOptions_ ?? [];
  }, [filtersRelieve, dataLocation]);

  const excavsFilter = useMemo(() => {
    let excavsF = filtersRelieve?.excavs?.map((value) => {
      let isDisabled = value?.disabled ? value?.disabled : false;
      if (dataLocation?.excavs?.find((item) => item.id === value.id))
        isDisabled = false;

      return {
        id: value.id,
        title: value.id,
        group: {
          group: value.virtualRegion ?? "SIN CLASIFICAR",
          icon: BasicTruckIcon,
          value:
            filtersRelieve?.virtualRegion?.find(
              (item) => item.virtualRegion === value.virtualRegion
            )?.countCaex ?? 0,
        },
        placeHolder: "Palas",
        disabled: isDisabled,
      };
    });
    let sortedOptions = orderGroup(excavsF);

    return sortedOptions ?? [];
  }, [filtersRelieve, orderGroup, dataLocation]);

  // -----------------------Init values--------------------------------
  const initOperatorsOther = useMemo(() => {
    if (!dataLocation?.operatorsOther?.length) return [];
    return otherOperatorFilter.filter((item: any) =>
      dataLocation.operatorsOther.some((op) => op.id === item.id)
    );
  }, [otherOperatorFilter, dataLocation]);

  const initOperatorsOwn = useMemo(() => {
    if (!dataLocation?.operatorsOwn?.length) return [];
    return operatorOwnFilter.filter((item: any) =>
      dataLocation.operatorsOwn.some((op) => op.id === item.id)
    );
  }, [operatorOwnFilter, dataLocation]);

  const initCaex = useMemo(() => {
    if (!dataLocation?.caex?.length) return [];
    return caexFilter.filter((item) =>
      dataLocation.caex.some((op) => op.id === item.id)
    );
  }, [caexFilter, dataLocation]);

  const initExcavs = useMemo(() => {
    if (!dataLocation?.excavs?.length) return [];
    setExcavsForm(dataLocation.excavs);
    return excavsFilter.filter((item: any) =>
      dataLocation.excavs.some((op) => op.id === item.id)
    );
  }, [excavsFilter, dataLocation]);

  const hasData = useMemo(() => {
    return (
      !initCaex.length &&
      !initExcavs.length &&
      !initOperatorsOther.length &&
      !initOperatorsOwn.length
    );
  }, [initCaex, initExcavs, initOperatorsOther, initOperatorsOwn]);
  // -----------------------END Init values--------------------------------
  return (
    <Grid container className={classes.mainContainer}>
      {hasData && disabled && (
        <Grid item xs={12} style={{ textAlign: "center", paddingTop: 50 }}>
          <Typography color="textPrimary" variant="h6">
            Sugerencia de relevo sin sobrantes.
          </Typography>
        </Grid>
      )}
      {!disabled ? (
        <Grid item xs={12} className={classes.title}>
          <Typography variant="subtitle1">Palas</Typography>
        </Grid>
      ) : disabled && initExcavs.length ? (
        <Grid item xs={12} className={classes.title}>
          <Typography variant="subtitle1">Palas</Typography>
        </Grid>
      ) : null}

      <CustomMultipleSelector
        options={excavsFilter}
        placeHolder={"Agregar por SHE0000"}
        mode={ModeAutocomplete.ITEM_LIST}
        setListItems={handleExcavsChange}
        setListItemsChangeCount={handleExcavsChangeCount}
        setListItemsChangePriority={handleUpPriority}
        initState={initExcavs}
        currentListItems={dataLocation?.excavs ? [...dataLocation?.excavs] : []}
        classIcon={classes.classIconTruck}
        disabled={disabled}
        hasValueGroup={true}
      />

      <Grid container>
        {!disabled ? (
          <Grid item xs={12} className={classes.title}>
            <Typography variant="subtitle1">Banca</Typography>
          </Grid>
        ) : disabled && initOperatorsOwn.length ? (
          <Grid item xs={12} className={classes.title}>
            <Typography variant="subtitle1">Banca</Typography>
          </Grid>
        ) : null}

        <CustomMultipleSelector
          options={operatorOwnFilter}
          placeHolder={"Agregar por Apellido, Nombre"}
          setChipItems={(operators) =>
            handleOperatorsChange(operators, "operatorsOwn")
          }
          initState={initOperatorsOwn}
          currentChipItems={
            dataLocation?.operatorsOwn ? [...dataLocation?.operatorsOwn] : []
          }
          disabled={disabled}
        />
        {!disabled ? (
          <Grid item xs={12} className={classes.title}>
            <Typography variant="subtitle1">Prestados</Typography>
          </Grid>
        ) : disabled && initOperatorsOther.length ? (
          <Grid item xs={12} className={classes.title}>
            <Typography variant="subtitle1">Prestados</Typography>
          </Grid>
        ) : null}

        <CustomMultipleSelector
          options={otherOperatorFilter}
          placeHolder={"Agregar por Apellido, Nombre"}
          setChipItems={(operators) =>
            handleOperatorsChange(operators, "operatorsOther")
          }
          initState={initOperatorsOther}
          currentChipItems={
            dataLocation?.operatorsOther
              ? [...dataLocation?.operatorsOther]
              : []
          }
          disabled={disabled}
        />

        {!disabled ? (
          <Grid item xs={12} className={classes.title}>
            <Typography variant="subtitle1">CAEX</Typography>
          </Grid>
        ) : disabled && initCaex.length ? (
          <Grid item xs={12} className={classes.title}>
            <Typography variant="subtitle1">CAEX</Typography>
          </Grid>
        ) : null}

        <CustomMultipleSelector
          options={caexFilter}
          placeHolder={"Agregar por TKD0000"}
          setChipItems={handleCaexChange}
          initState={initCaex}
          currentChipItems={dataLocation?.caex ? [...dataLocation?.caex] : []}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: "flex",
    height: "100vh",
    overflowX: "hidden",
  },
  mainContainer: {
    overflowY: "auto",
    maxHeight: 540,
    paddingLeft: 10,
  },
  selectContainer: {
    padding: "0px 10px 10px 10px",
  },
  title: {
    color: palette.text.primary,
    paddingLeft: 10,
  },
  classIconTruck: {
    height: 18,
    width: 20,
    marginRight: 5,
  },
}));
