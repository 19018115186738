import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

export type BaseElementProps = {
  children: React.ReactNode;
  classNames?: string[];
};

export const BaseElement: React.FC<BaseElementProps> = ({
  children,
  classNames = [],
}) => {
  const classes = useStyles();
  return <div className={clsx([classes.base, ...classNames])}>{children}</div>;
};

const useStyles = makeStyles(() => {
  return {
    base: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
  };
});

export const useSpacingStyles = makeStyles(() => ({
  XS: {
    maxWidth: "5%",
  },
  S: {
    maxWidth: "7%",
  },
  M: {
    maxWidth: "15%",
  },
  L: {
    maxWidth: "25%",
  },
  XL: {
    maxWidth: "30%",
  },
  left: {
    justifyContent: "left",
  },
}));
