import {
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import { CustomTable, NoOperatorTruckIcon, Tooltip } from "components";
import { KpiCard } from "components/CardComponent/KpiCard";
import { EmptyTruckVelocityIcon } from "components/icons/EmptyTruckVelocityIcon";
import { FullTruckVelocityIcon } from "components/icons/FullTruckVelocityIcon";
import { useData } from "hooks";
import { CellProps, ErrorMsg, HeaderCellProps, NestedKeyOf } from "interfaces";
import { CaexVelocity, KpiTypeVelocity } from "interfaces/velocity.interface";
import React, { useCallback, useMemo } from "react";
import { TruckRouteState } from "reducers/truck-velocity";

interface Props {
  listCaex: string[];
  availableModels: string;
  notAvailableModels: string;
  loadingCaex: boolean;
  params: TruckRouteState;
  selectedCaex: string | null;
  setSelectedCaex: (value: string | null) => void;
}

export const CaexSearch: React.FC<Props> = ({
  listCaex,
  availableModels,
  notAvailableModels,
  loadingCaex,
  params,
  selectedCaex,
  setSelectedCaex,
}) => {
  const classes = useStyles();

  const {
    data: entriesData,
    refetch: searchCaex,
    refetching,
  } = useData<CaexVelocity[]>(
    {
      config: {
        url: "/truck-assignment-velocity/caexFilter",
        method: "GET",
      },
      options: {
        manual: true,
      },
    },
    ErrorMsg.GET_SIC,
    []
  );

  const headerCells = useMemo<HeaderCellProps<NestedKeyOf<CaexVelocity>>[]>(
    () => [
      {
        dataKey: "section",
        label: "Ruta",
        align: "left",
        sortable: false,
        className: classes.headerCell,
      },
      {
        dataKey: "hour",
        label: "Hora",
        align: "left",
        sortable: false,
        className: classes.headerCell,
      },
      {
        dataKey: "operator",
        label: "Operador",
        align: "left",
        sortable: false,
        className: classes.headerCell,
      },
      {
        dataKey: "fullVelocity",
        label: "Velocidad Cargado Promedio [Km/h]",
        align: "left",
        sortable: false,
        className: classes.headerCell,
      },
      {
        dataKey: "emptyVelocity",
        label: "Velocidad Vacío Promedio [Km/h]",
        align: "left",
        sortable: false,
        className: classes.headerCell,
      },
    ],
    [classes.headerCell]
  );

  const handleOnChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: string | null,
      _reason: AutocompleteChangeReason
    ) => {
      setSelectedCaex(value);
      let paramsSearch = { ...params, caex: value };
      delete paramsSearch.route;
      searchCaex({ params: paramsSearch });
    },
    [setSelectedCaex, searchCaex, params]
  );

  const rowDetails = useCallback(
    (datum: CaexVelocity): CellProps<NestedKeyOf<CaexVelocity>>[] => {
      return [
        {
          dataKey: "section",
          value: (
            <div>
              <Typography variant="subtitle1">
                <strong>{datum.section}</strong>
              </Typography>
            </div>
          ),
          align: "left",
          className: classes.createdAt,
        },
        {
          dataKey: "hour",
          value: (
            <Typography variant="subtitle1">
              <strong>{datum.hour}</strong>
            </Typography>
          ),
          align: "left",
          className: classes.createdAt,
        },
        {
          dataKey: "operator",
          value: (
            <Typography variant="subtitle1">
              {datum.operator !== null ? datum.operator : "-"}
            </Typography>
          ),
          align: "left",
          className: classes.createdAt,
        },

        {
          dataKey: "fullVelocity",
          value: (
            <Grid container style={{ display: "flex", flexFlow: "nowrap" }}>
              <FullTruckVelocityIcon style={{ marginRight: 10 }} />
              <KpiCard
                values={{
                  type: KpiTypeVelocity.FULL_VELOCITY,
                  mainValue: datum?.fullVelocity?.main,
                  projection: datum?.fullVelocity?.projection,
                }}
              />
            </Grid>
          ),
          align: "left",
          className: classes.createdAt,
        },
        {
          dataKey: "emptyVelocity",
          value: (
            <Grid container style={{ display: "flex", flexFlow: "nowrap" }}>
              <EmptyTruckVelocityIcon style={{ marginRight: 10 }} />
              <KpiCard
                values={{
                  type: KpiTypeVelocity.EMPTY_VELOCITY,
                  mainValue: datum?.emptyVelocity?.main,
                  projection: datum?.emptyVelocity?.projection,
                }}
              />
            </Grid>
          ),
          align: "left",
          className: classes.createdAt,
        },
      ];
    },
    [classes.createdAt]
  );

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <Grid container alignItems="center">
          <div className={classes.contentHeader}>
            <Typography style={{ fontSize: "18px", marginRight: "10px" }}>
              <strong>Buscador de CAEX operativos</strong>
            </Typography>
            <Tooltip
              title={
                <>
                  <Typography variant="body1">
                    Se incluyen CAEX modelos {availableModels} y código 
                    1000. Modelos no disponibles {notAvailableModels}
                  </Typography>
                </>
              }
              arrow
              placement="top"
              maxWidth="620px"
            >
              <InfoOutlined className={classes.infoIconTooltip} />
            </Tooltip>
            <div className={classes.contentRoot}>
              <FormControl
                fullWidth
                variant="filled"
                classes={{ root: classes.form }}
              >
                <Autocomplete
                  value={selectedCaex}
                  options={listCaex ?? []}
                  onChange={handleOnChange}
                  loading={loadingCaex}
                  loadingText="Cargando Caex"
                  noOptionsText="No se encontraron Caex"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="CAEX"
                      InputLabelProps={{ classes: { outlined: classes.label } }}
                    />
                  )}
                  classes={{ inputRoot: classes.autocomplete }}
                />
              </FormControl>
            </div>
          </div>
        </Grid>
      </Grid>

      {selectedCaex !== null ? (
        <>
          {refetching ? (
            <Grid item xs={12} style={{ height: "200px" }}>
              <div className={classes.infoContainer}>
                <CircularProgress
                  style={{ marginBottom: 10, marginTop: 20 }}
                  size={120}
                  color="primary"
                />
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} style={{ height: "500px" }}>
              <CustomTable
                data={entriesData}
                headers={headerCells}
                loadingData={false}
                tableKey={"CaexSearch"}
                renderCells={rowDetails}
              />
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12} style={{ height: "150px" }}>
          <Grid
            container
            alignContent="center"
            className={classes.infoContainer}
          >
            <Grid item xs={12}>
              <NoOperatorTruckIcon className={classes.infoIcon} />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography color="textPrimary" variant="subtitle1">
                Seleccione un CAEX para mostrar el detalle de su velocidad.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    avatar: {
      backgroundColor: palette.kpi.background,
      height: "80px",
      width: "80px",
    },
    infoContainer: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    infoIcon: {
      width: 68,
      height: 68,
    },
    headerCell: {
      width: "100%",
    },
    createdAt: {
      width: "150px",
      marginLeft: 10,
      marginRight: 10,
    },
    contentRoot: {
      display: "flex",
      justifyContent: "center",
      width: "200px",
      marginLeft: "50px",
    },
    autocomplete: {
      "&&.MuiOutlinedInput-root": {
        padding: "0px",
      },
      "&&.MuiInputLabel-formControl": {
        margin: 0,
      },
    },
    form: {
      "&&.MuiInputLabel-formControl": {
        margin: 0,
      },
    },
    label: {
      transform: "translate(14px, 10px) scale(1)",
    },
    infoIconTooltip: {
      lineHeight: "24px",
      marginRight: 10,
      width: 20,
      height: 20,
    },
    contentHeader: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: 13,
      marginBottom: 23,
    },
  };
});
