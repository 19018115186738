import React from "react";
import { useTheme } from "@material-ui/core";
import { PointSymbolProps } from "@nivo/line";

export const CustomSquareSymbol: React.FC<PointSymbolProps> = ({
  size,
}: PointSymbolProps) => {
  const theme = useTheme();
  const defaultSizePx = 11;
  // Use nivo line chart's size argument as scaling factor for the default svg size
  const scaleFactor = size;
  const translation = -(scaleFactor * defaultSizePx) / 2;

  return (
    <g
      transform={`translate(${translation},${translation}) scale(${scaleFactor})`}
    >
      <path
        d="M0.75 1.91667V10.0833C0.75 10.725 1.275 11.25 1.91667 11.25H10.0833C10.725 11.25 11.25 10.725 11.25 10.0833V1.91667C11.25 1.275 10.725 0.75 10.0833 0.75H1.91667C1.275 0.75 0.75 1.275 0.75 1.91667Z"
        fill={
          theme.palette.type === "light"
            ? theme.palette.secondary.main
            : theme.palette.common.white
        }
      />
    </g>
  );
};
