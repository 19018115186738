import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useData } from 'hooks';
import { ErrorMsg } from 'interfaces';
import React, { useRef } from 'react'
import { CSVLink } from "react-csv";
import { formatLocalizedDate } from 'utils';

interface IDataExport {
    TYPE: string
    NAME: string
    EQUIPMENT: string
    DESTINATION: string
    TRANSPORTATION: string
}

const ShiftChangeCsvExport = () => {
    const classes = useStyles();
    const {data, firstLoading} = useData<IDataExport[]>(
        {
            config: "/shift-change/visualization/all-data",
            options: {
                useCache: false,
              }
        },
        ErrorMsg.GET_STATUS_SHIFT_CHANGE
    );

    const headers = [
        {label: "Tipo", key:"TYPE"},
        {label: "Nombre", key:"NAME"},
        {label: "Equipo", key:"EQUIPMENT"},
        {label: "Ubicación", key:"DESTINATION"},
        {label: "Bus", key:"TRANSPORTATION"}
    ]
    let timestamp = formatLocalizedDate(new Date());
    const FILENAME = `Asginacion_Operadores_${timestamp}.csv`;
    const csvLinkEl = useRef(null)
    return (
        <>
            <CSVLink
                    headers={headers}
                    filename={FILENAME}
                    data={data}
                    separator={";"}
                    enclosingCharacter={`'`}
                    asyncOnClick={true}
                    ref={csvLinkEl}
            >
                <Typography className={classes.csvLink}>
                    Descargar CSV
                </Typography>
            </CSVLink>
        </>
    )
}

export default ShiftChangeCsvExport

const useStyles = makeStyles((theme) => {
    const { palette } = theme;
    const { type } = palette;
    return {
        csvLink: { 
            textAlign: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            fontSize: "15px",
            fontStyle: "normal",
            fontFamily: "Roboto",
            fontWeight: "normal",
            color: palette.text.primary,
            [theme.breakpoints.down('md')]: {
                fontSize: "13px",
            }
        }
    }
})