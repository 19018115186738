import React from "react";
import {
  FabProps as MaterialFabProps,
  Fab as MaterialFab,
  makeStyles,
  SvgIconProps,
  Theme,
  Zoom,
  useTheme,
} from "@material-ui/core";

interface FabProps extends Omit<MaterialFabProps, "children"> {
  icon?: React.FC<SvgIconProps<"svg", {}>>;
  children?: React.ReactNode;
}

export const Fab: React.FC<FabProps> = ({ icon: Icon, children, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ hasChildren: Boolean(children) });

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Zoom
      timeout={transitionDuration}
      style={{
        transitionDelay: `${transitionDuration.exit}ms`,
      }}
      in={true}
    >
      <MaterialFab {...props} className={classes.absolute}>
        {Icon && <Icon className={classes.icon} />}
        {children}
      </MaterialFab>
    </Zoom>
  );
};

interface StyleProps {
  hasChildren: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  icon: {
    marginRight: ({ hasChildren }) => (hasChildren ? theme.spacing(1) : 0),
    color: theme.palette.common.white,
  },
  absolute: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    color: theme.palette.common.white,
  },
}));
