import React from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

interface LoaderProps {
  open: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop
      className="flex justify-center"
      open={open}
      classes={{ root: classes.backdrop }}
    >
      <CircularProgress size={120} color="primary" />
    </Backdrop>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 10000,
    display: "flex",
    justifyContent: "center",
  },
}));
