import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const FillTruckIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="66"
      height="68"
      viewBox="0 0 66 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4613_99840)">
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M-16.4138 37.9097L-3.57621 30.3083L63.3103 69.4227L50.4727 77.0241L-16.4138 37.9097Z"
            fill="url(#paint0_linear_4613_99840)"
          />
        </g>
        <path
          d="M8.97388 38.1476L19.5805 44.5181L21.6121 45.7439V44.5435L21.8053 44.4292C21.3129 42.9811 20.4966 41.6409 19.4185 40.5675C19.163 40.3071 19.1879 39.9832 19.0196 39.7545C18.5086 39.0749 18.4338 38.262 18.2219 37.4172C18.1596 37.1505 18.0848 36.8774 17.9353 36.6551C17.7857 36.4328 17.5738 36.2613 17.3495 36.1215C16.6453 35.6769 15.7978 35.4483 14.9814 35.5944C14.6324 35.6579 14.2896 35.7913 13.9407 35.8675C13.0994 36.0517 12.202 35.7659 11.3981 36.0898C11.18 36.1787 10.9805 36.2867 10.7873 36.4201C10.4384 36.6487 10.1205 36.9345 9.82141 37.233C9.74663 37.3093 9.67184 37.3855 9.59706 37.468C9.38518 37.6903 9.17953 37.919 8.97388 38.1476Z"
          fill="#401D00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6006 46.7729C12.6926 45.6701 11.137 46.333 11.137 48.2485C11.137 50.164 12.6926 52.6254 14.6006 53.7281C16.5075 54.8309 18.0631 54.168 18.0631 52.253C18.0631 50.337 16.5075 47.8756 14.6006 46.7729Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6431 48.2442C10.7351 47.1414 9.17944 47.8044 9.17944 49.7198C9.17944 51.6353 10.7351 54.0967 12.6431 55.1995C14.55 56.3022 16.1056 55.6393 16.1056 53.7244C16.1056 51.8084 14.55 49.347 12.6431 48.2442Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6434 49.4128C11.3766 48.6808 10.3435 49.1205 10.3435 50.3924C10.3435 51.6637 11.3766 53.298 12.6434 54.0305C13.9096 54.7625 14.9427 54.3223 14.9427 53.0509C14.9427 51.7791 13.9096 50.1448 12.6434 49.4128Z"
          fill="#353633"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7397 49.5825C11.6215 48.936 10.7092 49.3245 10.7092 50.4473C10.7092 51.57 11.6215 53.0128 12.7397 53.6588C13.8574 54.3053 14.7696 53.9167 14.7696 52.794C14.7696 51.6718 13.8574 50.2285 12.7397 49.5825Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7394 49.8755C11.7827 49.3226 11.0022 49.6551 11.0022 50.6157C11.0022 51.5762 11.7827 52.8111 12.7394 53.364C13.6961 53.917 14.4761 53.5844 14.4761 52.6239C14.4761 51.6633 13.6961 50.429 12.7394 49.8755Z"
          fill="#8E4428"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7393 50.2292C11.9756 49.7878 11.3528 50.0528 11.3528 50.8196C11.3528 51.5859 11.9756 52.5715 12.7393 53.0129C13.5025 53.4537 14.1253 53.1887 14.1253 52.4224C14.1253 51.6556 13.5025 50.6705 12.7393 50.2292Z"
          fill="#A34D2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7395 50.4046C12.0722 50.0187 11.5286 50.2506 11.5286 50.92C11.5286 51.5894 12.0722 52.4503 12.7395 52.8356C13.4062 53.2209 13.9499 52.9891 13.9499 52.3197C13.9499 51.6503 13.4062 50.7899 12.7395 50.4046Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9427 55.6119C15.4016 55.5957 16.2137 55.0326 17.3788 53.9226L16.623 51.6309L15.4072 53.9923C14.6386 55.0882 14.4837 55.6281 14.9427 55.6119Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3158 46.402V46.4552L12.1439 47.7842L9.63257 48.2479C11.4214 46.8594 12.3158 46.2236 12.3158 46.3407C12.3158 46.4577 12.3158 46.4782 12.3158 46.402Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.147 46.7729C33.239 45.6701 31.6833 46.333 31.6833 48.2485C31.6833 50.164 33.239 52.6254 35.147 53.7281C37.0539 54.8309 38.6095 54.168 38.6095 52.253C38.6095 50.337 37.0539 47.8756 35.147 46.7729Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.1904 48.2442C31.2825 47.1414 29.7268 47.8044 29.7268 49.7198C29.7268 51.6353 31.2825 54.0967 33.1904 55.1995C35.0973 56.3022 36.653 55.6393 36.653 53.7244C36.653 51.8084 35.0973 49.347 33.1904 48.2442Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.1903 49.4128C31.9235 48.6808 30.8904 49.1205 30.8904 50.3924C30.8904 51.6637 31.9235 53.298 33.1903 54.0305C34.4565 54.7625 35.4896 54.3223 35.4896 53.0509C35.4896 51.7791 34.4565 50.1448 33.1903 49.4128Z"
          fill="#353633"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.2861 49.5815C32.1679 48.935 31.2556 49.3236 31.2556 50.4463C31.2556 51.569 32.1679 53.0118 33.2861 53.6578C34.4038 54.3043 35.316 53.9157 35.316 52.793C35.316 51.6708 34.4038 50.2275 33.2861 49.5815Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.2863 49.8755C32.3296 49.3226 31.5491 49.6551 31.5491 50.6157C31.5491 51.5762 32.3296 52.8111 33.2863 53.364C34.243 53.917 35.023 53.5844 35.023 52.6239C35.023 51.6633 34.243 50.429 33.2863 49.8755Z"
          fill="#8E4428"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.2862 50.2292C32.5225 49.7878 31.8997 50.0528 31.8997 50.8196C31.8997 51.5859 32.5225 52.5715 33.2862 53.0129C34.0494 53.4537 34.6722 53.1887 34.6722 52.4224C34.6722 51.6556 34.0494 50.6705 33.2862 50.2292Z"
          fill="#A34D2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.2864 50.4055C32.6191 50.0197 32.0754 50.2515 32.0754 50.9209C32.0754 51.5903 32.6191 52.4513 33.2864 52.8366C33.9531 53.2219 34.4967 52.9901 34.4967 52.3207C34.4967 51.6513 33.9531 50.7909 33.2864 50.4055Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.4896 55.6119C35.9485 55.5957 36.7605 55.0326 37.9257 53.9226L37.1699 51.6309L35.9541 53.9923C35.1854 55.0882 35.0306 55.6281 35.4896 55.6119Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8627 46.402V46.4552L32.6908 47.7842L30.1794 48.2479C31.9683 46.8594 32.8627 46.2236 32.8627 46.3407C32.8627 46.4577 32.8627 46.4782 32.8627 46.402Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.5832 48.4049L36.0183 49.8874V52.1938L38.5832 50.7162V48.4049Z"
          fill="#EB922D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.5984 46.6462L35.8957 49.8097L36.0177 49.8816L38.5826 48.404L38.4563 48.3289L38.4309 48.3142L33.1628 45.1637L30.5984 46.6462Z"
          fill="#E9B237"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.2426 49.646V50.157C38.2426 50.2539 38.1905 50.3431 38.1071 50.3916L36.6636 51.2476C36.5899 51.2901 36.4983 51.2362 36.4983 51.1507V51.0898L37.7932 50.3203C37.8762 50.2724 37.9287 50.1826 37.9287 50.0862V49.6362L38.0773 49.5507C38.151 49.5083 38.2426 49.5622 38.2426 49.646Z"
          fill="#121311"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.9287 49.636V50.0861C37.9287 50.1824 37.8762 50.2722 37.7932 50.3201L36.4983 51.0896V50.6406C36.4983 50.5427 36.5503 50.4534 36.6338 50.405L37.9287 49.636Z"
          fill="#FFF3CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.7533 59.293L22.427 54.3468V48.2755L30.7533 53.2217V59.293Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.8847 49.674L30.7537 53.2207V59.292L36.8847 55.7676V49.674Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4275 48.2723L30.7521 53.2207L36.8842 49.6941L28.5563 44.7261L22.4275 48.2723Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3222 57.1968L26.636 56.8012L26.1682 54.2085L27.3222 54.8904V57.1968Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3347 47.0756L22.5359 48.3487V54.0962L20.3347 52.8312V47.0756Z"
          fill="#502D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8396 53.924L20.6424 52.8579V49.2529L18.8396 50.3191V53.924Z"
          fill="#8D4528"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6384 49.0477L18.8396 50.3207V53.9256L16.6384 52.6608V49.0477Z"
          fill="#6D3822"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6425 49.2534L19.0299 50.2074L18.8396 50.3206L16.6384 49.0547L16.8357 48.9372L16.8753 48.9143L18.4423 47.981L20.6425 49.2534Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5247 50.8929L12.642 47.4136L5.3987 38.9952L4.99976 36.8054L22.3114 47.0533L18.5247 50.8929Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9807 47.2274L18.3903 49.8532L13.177 46.7691L6.85029 39.4186L6.73755 38.797L20.9807 47.2274Z"
          fill="#874226"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.9597 40.814L22.3113 47.054L18.5247 50.8936L29.173 44.6759L32.9597 40.814Z"
          fill="#CA722E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.00085 36.8047L22.3109 47.0542L32.9598 40.8348L15.647 30.5652L5.00085 36.8047Z"
          fill="#ECC530"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3281 40.8294L26.1722 43.8416L22.3118 46.0951L19.4357 44.3914L8.68765 38.0267L6.63062 36.809L15.6442 31.527L31.3281 40.8294Z"
          fill="#E2832D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0426 33.7162L8.68765 38.0267L6.63062 36.809L15.6442 31.527L16.0426 33.7162Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.1718 43.8407L22.3114 46.0938L19.4353 44.3903L23.2876 42.1345L26.1718 43.8407Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2865 42.1327L26.1718 43.8397L31.3282 40.8285L15.6438 31.525L16.0427 33.7143L23.2865 42.1327Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.3364 47.0709L21.4675 46.5517V45.0801L22.3364 45.5993V47.0709Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.5842 51.1531L24.7703 47.1311L27.1813 44.9244L30.1814 46.6989L31.5842 51.1531Z"
          fill="#212220"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.8434 44.5822L30.1814 46.7006L31.5842 51.1548L35.2462 49.0494L33.8434 44.5822Z"
          fill="#434341"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.1819 44.9246L30.181 46.6999L33.8435 44.5938L30.8423 42.8065L27.1819 44.9246Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.2502 54.4405L36.3861 51.4652L36.5086 51.5333L31.2502 57.5795V54.4405Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.2502 54.4399L31.3727 54.508V57.6465L31.2502 57.5785V54.4399Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3728 54.5086L36.5086 51.5333V54.6207L31.3728 57.6476V54.5086Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 51.8251V54.5233L35.6213 54.9478L35.5123 55.0121L34.1968 55.7876L34.0879 55.8523L32.7691 56.6279L32.6628 56.6926L32.5593 56.7519L31.5403 57.3533V54.9979L32.4504 54.4727L32.6628 54.3519V54.2393L32.4504 54.3601L31.5403 54.8858V54.6044L32.4504 54.0793L32.7691 53.8937L33.8776 53.2504L34.0879 53.129L34.1968 53.0675L35.3026 52.4264L35.5123 52.3056L35.6213 52.2414L36.3406 51.8251Z"
          fill="#0A0B09"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 52.9958V53.108L35.6213 53.521L35.5123 53.5858L35.3026 53.7066L34.1968 54.3417L34.0879 54.4065L33.8776 54.5273L32.7691 55.1651L32.6628 55.2272L32.4504 55.348L31.5403 55.8737V55.7611L32.4504 55.2353L32.6628 55.1145L32.7691 55.053L33.8776 54.4152L34.0879 54.2938L34.1968 54.2296L35.3026 53.5945L35.5123 53.4737L35.6213 53.4089L36.3406 52.9958Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6628 55.5603V55.6702L32.4504 55.7911L31.5403 56.3162V56.2068L32.4504 55.6811L32.6628 55.5603Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 53.4384V53.5505L35.6213 53.9635L35.5123 54.0283L35.3026 54.1491L34.1968 54.7842L34.0879 54.849L33.8776 54.9698L32.7691 55.6076L32.6628 55.6697V55.5597L32.7691 55.4982L33.8776 54.8571L34.0879 54.7363L34.1968 54.6748L35.3026 54.0365L35.5123 53.9156L35.6213 53.8541L36.3406 53.4384Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6628 56.0017V56.1138L32.4504 56.2352L31.5403 56.7604V56.6482L32.4504 56.1225L32.6628 56.0017Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6628 56.4413V56.5534L32.4504 56.6742L31.5403 57.1999V57.0873L32.4504 56.5621L32.6628 56.4413Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 54.3232V54.4326L35.6213 54.849L35.5123 54.9105L35.3026 55.0313L34.1968 55.6697L34.0879 55.7312L33.8776 55.852L32.7691 56.4931L32.6628 56.5546V56.4425L32.7691 56.3804L33.8776 55.7426L34.0879 55.6218L34.1968 55.557L35.3026 54.9219L35.5123 54.8011L35.6213 54.7363L36.3406 54.3232Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.2251 52.9521L36.3406 52.9968L35.6213 53.4098L35.5123 53.4746L35.3026 53.5954L34.1968 54.2305L34.0879 54.2948L33.8776 54.4161L32.7691 55.0539L32.6628 55.1154L32.4504 55.2363L31.5403 55.762V55.6526L32.4504 55.1269L32.6628 55.0061L32.7691 54.944L33.8776 54.3035L34.0879 54.1826L34.1968 54.1206L35.3026 53.4828L35.5123 53.362L35.6213 53.2999L36.2251 52.9521Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.2251 53.3955L36.3406 53.4374L35.6213 53.8532L35.5123 53.9147L35.3026 54.0355L34.1968 54.6739L34.0879 54.7354L33.8776 54.8562L32.7691 55.4973L32.6628 55.5588L32.4504 55.6796L31.5403 56.2053V56.0927L32.4504 55.5675L32.6628 55.4467L32.7691 55.3847L33.8776 54.7468L34.0879 54.626L34.1968 54.5612L35.3026 53.9261L35.5123 53.8053L35.6213 53.7405L36.2251 53.3955Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6628 54.2396V54.3523L32.4504 54.4731L31.5403 54.9983V54.8862L32.4504 54.3604L32.6628 54.2396Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 52.1178V52.2299L35.6213 52.6457L35.5123 52.7077L35.3026 52.8286L34.1968 53.4664L34.0879 53.5284L33.8776 53.6492L32.7691 54.2898L32.6628 54.3518V54.2392L32.7691 54.1777L33.8776 53.5366L34.0879 53.4158L34.1968 53.3543L35.3026 52.7159L35.5123 52.5951L35.6213 52.5336L36.3406 52.1178Z"
          fill="#2D2E2C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 52.5602V52.6701L35.6213 53.0859L35.5123 53.1479L35.3026 53.2688L34.1968 53.9066L34.0879 53.9681L33.8776 54.0895L32.7691 54.73L32.6628 54.7915L32.4504 54.9129L31.5403 55.438V55.3259L32.4504 54.8002L32.6628 54.6794L32.7691 54.6174L33.8776 53.9795L34.0879 53.8587L34.1968 53.7939L35.3026 53.1588L35.5123 53.038L35.6213 52.9732L36.3406 52.5602Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.2251 52.075L36.3406 52.1169L35.6213 52.5326L35.5123 52.5941L35.3026 52.715L34.1968 53.3533L34.0879 53.4148L33.8776 53.5357L32.7691 54.1767L32.6628 54.2382L32.4504 54.3591L31.5403 54.8848V54.7721L32.4504 54.247L32.6628 54.1261L32.7691 54.0641L33.8776 53.4263L34.0879 53.3054L34.1968 53.2407L35.3026 52.6056L35.5123 52.4848L35.6213 52.42L36.2251 52.075Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.2251 52.5155L36.3406 52.5601L35.6213 52.9732L35.5123 53.038L35.3026 53.1588L34.1968 53.7939L34.0879 53.8587L33.8776 53.9795L32.7691 54.6173L32.6628 54.6793L32.4504 54.8002L31.5403 55.3259V55.216L32.4504 54.6902L32.6628 54.57L32.7691 54.5079L33.8776 53.8668L34.0879 53.746L34.1968 53.6845L35.3026 53.0467L35.5123 52.9258L35.6213 52.8638L36.2251 52.5155Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6214 52.2402V54.9467L35.5125 55.0109V52.3045L35.6214 52.2402Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 52.3044V52.594L35.303 52.6054V52.4253L35.5127 52.3044Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 52.7061V53.0375L35.303 53.0489V52.8269L35.5127 52.7061Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 53.1486V53.4746L35.303 53.4854V53.2694L35.5127 53.1486Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 53.5872V53.9159L35.303 53.9273V53.708L35.5127 53.5872Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 53.8809V53.9908L35.6213 54.4066L35.5123 54.4686L35.3026 54.5894L34.1968 55.2273L34.0879 55.2893L33.8776 55.4101L32.7691 56.0507L32.6628 56.1122V56.0001L32.7691 55.938L33.8776 55.3002L34.0879 55.1794L34.1968 55.1146L35.3026 54.4795L35.5123 54.3587L35.6213 54.2939L36.3406 53.8809Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 54.0277V54.3591L35.303 54.3706V54.1485L35.5127 54.0277Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 54.4691V54.8006L35.303 54.812V54.5899L35.5127 54.4691Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 54.9097V55.0109L35.303 55.1344V55.0305L35.5127 54.9097Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.1966 53.0669V55.7869L34.0876 55.8517V53.1284L34.1966 53.0669Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 53.1292V53.416L33.8777 53.4274V53.2505L34.088 53.1292Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 53.5278V53.8593L33.8777 53.8707V53.6486L34.088 53.5278Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 53.9684V54.2944L33.8777 54.3058V54.0897L34.088 53.9684Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 54.4069V54.7356L33.8777 54.747V54.5277L34.088 54.4069Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 54.8484V55.1798L33.8777 55.1912V54.9692L34.088 54.8484Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 55.2899V55.6214L33.8777 55.6322V55.4107L34.088 55.2899Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 55.7305V55.8513L33.8777 55.9748V55.8513L34.088 55.7305Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.7691 53.8934V56.6276L32.6628 56.6924V53.9555L32.7691 53.8934Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 53.9547V54.2383L32.4504 54.247V54.0782L32.6629 53.9547Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 54.3505V54.6792L32.4504 54.6901V54.4713L32.6629 54.3505Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 54.791V55.1143L32.4504 55.1257V54.9124L32.6629 54.791Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 55.2277V55.5591L32.4504 55.5705V55.3485L32.6629 55.2277Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 55.6692V56.0006L32.4504 56.0115V55.79L32.6629 55.6692Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 56.1125V56.4418L32.4504 56.4527V56.2339L32.6629 56.1125Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.4504 56.6759L32.6629 56.5551V56.6927L32.5594 56.7521H32.4504V56.6759Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.2502 53.6615L36.3861 50.6863L36.5086 50.7543L31.2502 56.6166V53.6615Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.2502 53.661L31.3727 53.729V56.6836L31.2502 56.6156V53.661Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3728 53.7287L36.5086 50.7534V53.6568L31.3728 56.6838V53.7287Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 51.0461V53.7439L35.6213 54.1684L35.5123 54.2326L34.1968 55.0086L34.0879 55.0729L33.8776 55.1969L32.7691 55.8489L32.6628 55.9132L32.5593 55.9725L31.5403 56.5738V54.219L32.4504 53.6933L32.6628 53.5724V53.4598L32.4504 53.5806L31.5403 54.1063V53.8255L32.4504 53.2998L32.6628 53.1762L32.7691 53.1142L33.8776 52.4709L34.0879 52.3501L34.1968 52.2881L35.3026 51.6475L35.5123 51.5267L35.6213 51.4619L36.3406 51.0461Z"
          fill="#0A0B09"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 52.2189V52.3315L35.6213 52.744L35.5123 52.8088L35.3026 52.9296L34.1968 53.5647L34.0879 53.6295L33.8776 53.7503L32.7691 54.3882L32.6628 54.4502L32.4504 54.571L31.5403 55.0967V54.9841L32.4504 54.4589L32.6628 54.3375L32.7691 54.276L33.8776 53.6382L34.0879 53.5174L34.1968 53.4526L35.3026 52.8175L35.5123 52.6967L35.6213 52.6319L36.3406 52.2189Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6628 54.7814V54.8913L32.4504 55.0121L31.5403 55.5378V55.4279L32.4504 54.9022L32.6628 54.7814Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 52.6594V52.7715L35.6213 53.1846L35.5123 53.2494L35.3026 53.3702L34.1968 54.0053L34.0879 54.0701L33.8776 54.1909L32.7691 54.8287L32.6628 54.8907V54.7808L32.7691 54.7193L33.8776 54.0782L34.0879 53.9574L34.1968 53.8959L35.3026 53.2575L35.5123 53.1373L35.6213 53.0752L36.3406 52.6594Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6628 55.2228V55.3354L32.4504 55.4563L31.5403 55.9814V55.8693L32.4504 55.3436L32.6628 55.2228Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6628 55.6633V55.7754L32.4504 55.8963L31.5403 56.422V56.3093L32.4504 55.7841L32.6628 55.6633Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 53.5443V53.6542L35.6213 54.07L35.5123 54.1321L35.3026 54.2529L34.1968 54.8907L34.0879 54.9522L33.8776 55.073L32.7691 55.7141L32.6628 55.7756V55.6635L32.7691 55.6015L33.8776 54.9637L34.0879 54.8428L34.1968 54.7781L35.3026 54.143L35.5123 54.0221L35.6213 53.9574L36.3406 53.5443Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6628 53.4597V53.5724L32.4504 53.6932L31.5403 54.2189V54.1063L32.4504 53.5805L32.6628 53.4597Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 51.3389V51.451L35.6213 51.8668L35.5123 51.9288L35.3026 52.0496L34.1968 52.6875L34.0879 52.7495L33.8776 52.8703L32.7691 53.5109L32.6628 53.5729V53.4603L32.7691 53.3988L33.8776 52.7577L34.0879 52.6368L34.1968 52.5753L35.3026 51.9375L35.5123 51.8167L35.6213 51.7547L36.3406 51.3389Z"
          fill="#2D2E2C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 51.7832V51.8931L35.6213 52.3089L35.5123 52.371L35.3026 52.4918L34.1968 53.1296L34.0879 53.1917L33.8776 53.3125L32.7691 53.953L32.6628 54.0151L32.4504 54.1359L31.5403 54.6611V54.549L32.4504 54.0232L32.6628 53.9024L32.7691 53.8404L33.8776 53.2025L34.0879 53.0817L34.1968 53.017L35.3026 52.3819L35.5123 52.261L35.6213 52.1968L36.3406 51.7832Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6214 51.4633V54.1697L35.5125 54.2339V51.528L35.6214 51.4633Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 51.5275V51.817L35.303 51.8279V51.6483L35.5127 51.5275Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 51.9281V52.2595L35.303 52.271V52.0489L35.5127 51.9281Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 52.3696V52.6956L35.303 52.7065V52.4904L35.5127 52.3696Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 52.8092V53.1385L35.303 53.1493V52.93L35.5127 52.8092Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3406 53.1039V53.2138L35.6213 53.6296L35.5123 53.6916L35.3026 53.8125L34.1968 54.4503L34.0879 54.5123L33.8776 54.6332L32.7691 55.2737L32.6628 55.3357V55.2231L32.7691 55.1611L33.8776 54.5232L34.0879 54.4024L34.1968 54.3376L35.3026 53.7025L35.5123 53.5817L35.6213 53.5175L36.3406 53.1039Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 53.2498V53.5812L35.303 53.5926V53.3706L35.5127 53.2498Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 53.6902V54.0216L35.303 54.033V53.811L35.5127 53.6902Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5127 54.1327V54.2334L35.303 54.3575V54.2535L35.5127 54.1327Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.1966 52.2899V55.0105L34.0876 55.0747V52.352L34.1966 52.2899Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 52.3502V52.6365L33.8777 52.6479V52.471L34.088 52.3502Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 52.7489V53.0803L33.8777 53.0918V52.8697L34.088 52.7489Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 53.1914V53.5174L33.8777 53.5283V53.3122L34.088 53.1914Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 53.6289V53.9576L33.8777 53.969V53.7497L34.088 53.6289Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 54.0704V54.4019L33.8777 54.4133V54.1913L34.088 54.0704Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 54.512V54.8434L33.8777 54.8548V54.6328L34.088 54.512Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.088 54.9525V55.0733L33.8777 55.1974V55.0733L34.088 54.9525Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.7691 53.1145V55.8492L32.6628 55.9135V53.1765L32.7691 53.1145Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 53.1768V53.4603L32.4504 53.469V53.3003L32.6629 53.1768Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 53.5725V53.9012L32.4504 53.9127V53.6933L32.6629 53.5725Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 54.014V54.3368L32.4504 54.3482V54.1349L32.6629 54.014Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 54.4497V54.7811L32.4504 54.7926V54.5705L32.6629 54.4497Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 54.8912V55.2227L32.4504 55.2341V55.0121L32.6629 54.8912Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.6629 55.3346V55.6633L32.4504 55.6742V55.4554L32.6629 55.3346Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.4504 55.896L32.6629 55.7751V55.9128L32.5594 55.9722H32.4504V55.896Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.259 60.0307L29.885 59.8098V59.062L30.259 59.283V60.0307Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.8741 54.8989L30.259 59.2827V60.0304L37.8741 55.6483V54.8989Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.885 59.061L30.259 59.2819L37.8741 54.8998L37.8329 54.8753L37.8248 54.871L37.5001 54.6772L29.885 59.061Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.7079 47.6143L21.4675 45.0799V44.411L25.7079 46.9449V47.6143Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0907 49.1222L8.76538 42.3917V41.7222L20.0907 48.4527V49.1222Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3311 40.704L25.7083 46.9457V47.6152L36.3311 41.3735V40.704Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4675 44.4102L25.6732 46.9249L25.7079 46.9451L36.3308 40.7034L32.0904 38.1669L21.4675 44.4102Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.95049 41.8571L19.8742 48.3486L20.0623 48.1576L9.17327 41.6862L8.89087 41.7874L8.95049 41.8571Z"
          fill="#E9B237"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.8521 53.1438C23.9441 52.041 22.3884 52.704 22.3884 54.6195C22.3884 56.5349 23.9441 58.9963 25.8521 60.0991C27.7589 61.2019 29.3146 60.5389 29.3146 58.624C29.3146 56.708 27.7589 54.2466 25.8521 53.1438Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.895 54.6127C21.987 53.5099 20.4314 54.1729 20.4314 56.0883C20.4314 58.0038 21.987 60.4652 23.895 61.568C25.8019 62.6708 27.3576 62.0078 27.3576 60.0929C27.3576 58.1769 25.8019 55.7155 23.895 54.6127Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8948 55.7802C22.6281 55.0482 21.595 55.488 21.595 56.7598C21.595 58.0311 22.6281 59.6655 23.8948 60.398C25.161 61.13 26.1942 60.6897 26.1942 59.4184C26.1942 58.1465 25.161 56.5122 23.8948 55.7802Z"
          fill="#353633"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9912 55.9499C22.8729 55.3034 21.9607 55.692 21.9607 56.8147C21.9607 57.9374 22.8729 59.3802 23.9912 60.0262C25.1089 60.6727 26.0211 60.2841 26.0211 59.1614C26.0211 58.0392 25.1089 56.5959 23.9912 55.9499Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9909 56.244C23.0342 55.691 22.2537 56.0235 22.2537 56.9841C22.2537 57.9447 23.0342 59.1795 23.9909 59.7324C24.9476 60.2854 25.7276 59.9528 25.7276 58.9923C25.7276 58.0317 24.9476 56.7974 23.9909 56.244Z"
          fill="#8E4428"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9908 56.5976C23.2271 56.1562 22.6042 56.4212 22.6042 57.1881C22.6042 57.9543 23.2271 58.9399 23.9908 59.3813C24.754 59.8221 25.3768 59.5571 25.3768 58.7908C25.3768 58.024 24.754 57.0389 23.9908 56.5976Z"
          fill="#A34D2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9909 56.7759C23.3237 56.3901 22.78 56.6219 22.78 57.2913C22.78 57.9607 23.3237 58.8217 23.9909 59.207C24.6577 59.5923 25.2013 59.3604 25.2013 58.691C25.2013 58.0216 24.6577 57.1612 23.9909 56.7759Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.1941 61.9828C26.6531 61.9666 27.4651 61.4035 28.6303 60.2936L27.8745 58.0018L26.6587 60.3632C25.89 61.4592 25.7352 61.9991 26.1941 61.9828Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5673 52.7701V52.8233L23.3954 54.1523L20.884 54.616C22.6728 53.2274 23.5673 52.5917 23.5673 52.7087C23.5673 52.8257 23.5673 52.8462 23.5673 52.7701Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9021 52.0587L27.2 55.2218L27.3214 55.2941L29.8863 53.816L29.76 53.7409L29.7351 53.7262L21.9021 51.6549V52.0587Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6345 52.5207L21.9019 52.056L24.4663 50.5735L19.6345 52.1175V52.5207Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9021 51.6534L27.2 54.817L27.3214 54.8888L29.8863 53.4112L29.76 53.3361L29.7351 53.3214L24.4665 50.1709L21.9021 51.6534Z"
          fill="#E9B237"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6345 52.1187L21.9019 51.6534L24.4663 50.1709L22.1984 50.6362L19.6345 52.1187Z"
          fill="#A44C2C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.8859 53.4072L27.3215 54.8897V57.1961L29.8859 55.7186V53.4072Z"
          fill="#EB922D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.4486 54.6343V55.1459C29.4486 55.2422 29.3966 55.332 29.3131 55.3799L27.8697 56.236C27.7959 56.2784 27.7043 56.2245 27.7043 56.1396V56.0787L28.9993 55.3091C29.0822 55.2607 29.1348 55.1715 29.1348 55.0746V54.6245L29.2833 54.5396C29.357 54.4966 29.4486 54.551 29.4486 54.6343Z"
          fill="#121311"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.1348 54.6248V55.0748C29.1348 55.1717 29.0822 55.261 28.9993 55.3094L27.7043 56.0789V55.6299C27.7043 55.532 27.7564 55.4427 27.8399 55.3943L29.1348 54.6248Z"
          fill="#FFF3CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3217 57.1949L26.6355 56.7992L26.1677 54.2065L27.3217 54.8885V57.1949Z"
          fill="#D16F2D"
        />
      </g>
      <path
        d="M8.88672 37.0969L8.97396 37.154L19.5806 43.5245L21.6122 44.7503V43.5499L21.8053 43.4356L28.5 39.4205C28.3754 39.3188 27.6496 38.9849 27.5 38.9277C26.478 38.5149 26.1303 38.0877 25.37 37.2747C25.102 36.9889 24.8403 36.6904 24.5474 36.4363C24.1984 36.1378 23.7933 35.9092 23.4069 35.6551C22.173 34.8294 21.1136 33.6671 19.6927 33.2479C19.2129 33.1082 18.7081 33.051 18.2532 32.8351C17.9603 32.6954 17.7048 32.4858 17.3932 32.3968C17.1564 32.3333 16.9133 32.3397 16.6765 32.3968C16.3525 32.4731 16.0284 32.6319 15.7542 32.8224C15.2744 33.1463 14.8194 33.5528 14.2648 33.7306C13.9034 33.845 13.5232 33.864 13.1555 33.9275C12.2208 34.1054 11.3545 34.6452 10.7812 35.4201C10.7812 35.4201 10.7812 35.4265 10.775 35.4328C10.5818 35.6932 10.4073 35.9917 10.1206 36.1378C10.0209 36.1823 9.92121 36.214 9.81526 36.2458C9.75295 36.2648 9.67816 36.2903 9.62208 36.322C9.45382 36.4046 9.2544 36.538 9.12976 36.6777C9.01759 36.8047 8.94904 36.9572 8.88672 37.1096V37.0969Z"
        fill="#4A2500"
      />
      <path
        d="M16.6831 32.2679C17.0695 32.7061 17.8547 32.9157 18.2972 33.1825C19.1821 33.7096 20.1293 34.1034 21.0579 34.5417C21.4629 34.7322 21.9677 35.0816 22.2357 35.4436C22.541 35.8564 22.6657 36.3772 23.0084 36.7837C23.1331 36.9298 23.2702 37.0695 23.3138 37.2537C23.401 37.584 23.1705 37.9143 23.1455 38.2572C23.1331 38.4287 23.1705 38.6066 23.1331 38.7781C23.0583 39.1147 22.7155 39.3116 22.46 39.5402C21.8182 40.1119 21.9241 40.7915 21.7808 41.5536C21.6312 42.3539 21.3882 43.1034 21.5066 43.929C21.5253 44.0688 21.5626 44.2149 21.6187 44.3482V43.4336L21.8119 43.3193L28.5 39.3109C28.3754 39.2093 27.6496 38.8753 27.5 38.8182C26.478 38.4053 26.1368 37.9714 25.3765 37.1585C25.1086 36.8726 24.8468 36.5741 24.5539 36.3201C24.205 36.0216 23.7999 35.7929 23.4135 35.5389C22.1796 34.7132 21.1202 33.5509 19.6993 33.1317C19.2195 32.9919 18.7147 32.9348 18.2598 32.7188C17.9669 32.5791 17.7114 32.3695 17.3998 32.2806C17.163 32.2171 16.9199 32.2234 16.6831 32.2806V32.2679Z"
        fill="#5E3A16"
      />
      <path
        d="M11.635 35.4328C11.4107 35.4582 11.2299 35.6297 11.0679 35.7948L10.9059 35.9535C10.856 36.0043 10.8062 36.0551 10.7812 36.1186C10.8934 36.1313 11.0056 36.1377 11.1178 36.1504C11.2985 36.1631 11.4917 36.1758 11.635 36.0615C11.6973 36.0107 11.7472 35.9408 11.8157 35.9091C11.9092 35.8646 12.1211 35.871 12.1273 35.7313C12.1273 35.509 11.8033 35.4074 11.6412 35.4265L11.635 35.4328Z"
        fill="#696969"
      />
      <path
        d="M20.8392 35.9217C20.8392 35.8201 20.9701 35.7756 21.0511 35.7502C21.1695 35.7185 21.2318 35.782 21.3316 35.8518C21.3876 35.8899 21.4437 35.9217 21.4998 35.9598C21.612 36.0423 21.7304 36.3091 21.5871 36.4043C21.5372 36.4361 21.4749 36.4488 21.4126 36.4615L21.1508 36.5123C20.8579 36.5694 20.833 36.1185 20.833 35.9153L20.8392 35.9217Z"
        fill="#9C9C9C"
      />
      <path
        d="M16.3963 33.5275C16.334 33.5529 16.2779 33.4894 16.2405 33.4449C16.1906 33.3814 16.2093 33.3243 16.228 33.2481C16.2343 33.2036 16.2405 33.1592 16.2467 33.1147C16.2654 33.0258 16.3901 32.8798 16.4835 32.9433C16.5147 32.9623 16.5396 33.0004 16.5645 33.0322L16.6643 33.1782C16.7764 33.3433 16.5209 33.4767 16.3963 33.5338V33.5275Z"
        fill="#9C9C9C"
      />
      <path
        d="M16.1717 37.2232C16.1094 37.2486 16.0533 37.1851 16.0159 37.1406C15.966 37.0771 15.9847 37.02 16.0034 36.9438C16.0096 36.8993 16.0159 36.8549 16.0221 36.8104C16.0408 36.7215 16.1654 36.5754 16.2589 36.6389C16.2901 36.658 16.315 36.6961 16.3399 36.7278L16.4396 36.8739C16.5518 37.039 16.2963 37.1724 16.1717 37.2295V37.2232Z"
        fill="#696969"
      />
      <path
        d="M15.4302 35.217C15.6171 35.1853 15.7729 35.2742 15.8789 35.4139C15.8976 35.4393 16.1032 35.6869 16.0347 35.725C15.9412 35.7758 15.8352 35.8012 15.7293 35.7949C15.6109 35.7822 15.4925 35.7314 15.3803 35.7695C15.3492 35.7822 15.3055 35.7949 15.2806 35.7758C15.2619 35.7631 15.2557 35.7377 15.2557 35.7123C15.237 35.5282 15.2058 35.2615 15.4302 35.217Z"
        fill="#696969"
      />
      <path
        d="M20.9517 39.2613C20.9704 39.2041 21.014 39.1597 21.0826 39.1343C21.2259 39.0771 21.6933 39.147 21.6933 39.3565C21.6933 39.4581 21.5811 39.5089 21.525 39.5788C21.4627 39.6613 21.4315 39.7693 21.4066 39.8709C21.3942 39.9153 21.3817 39.9598 21.3505 39.9852C21.3194 40.0106 21.2695 40.0043 21.2384 39.9852C21.201 39.9662 21.1698 39.9408 21.1449 39.909C21.0389 39.782 20.8769 39.4581 20.9517 39.2613Z"
        fill="#9C9C9C"
      />
      <path
        d="M18.7333 35.3439C18.7457 35.3122 18.7831 35.2931 18.8205 35.2804C19.0013 35.2169 19.1944 35.1915 19.3814 35.2233L19.3627 35.2804C19.4437 35.3249 19.5185 35.4011 19.5621 35.4836C19.5995 35.5471 19.612 35.636 19.5621 35.6868C19.5247 35.7186 19.4749 35.7249 19.425 35.7186C19.3752 35.7122 19.3315 35.6932 19.2817 35.6932C19.1633 35.6932 19.0636 35.8011 18.9452 35.7884C18.8642 35.7821 18.7956 35.7186 18.7644 35.6424C18.7333 35.5662 18.7271 35.4836 18.7271 35.4011C18.7271 35.382 18.7271 35.3566 18.7395 35.3376L18.7333 35.3439Z"
        fill="#9C9C9C"
      />
      <path
        d="M15.673 38.8681C15.673 38.8681 15.6418 38.8554 15.6294 38.849C15.592 38.8173 15.5608 38.7792 15.5234 38.7538L15.673 38.8681Z"
        fill="#696969"
      />
      <path
        d="M15.0998 38.8359C15.1558 38.8105 15.2182 38.8105 15.2743 38.8105C15.4051 38.8105 15.5111 38.8232 15.6357 38.8804C15.7479 38.9312 15.9099 39.09 15.8414 39.2297C15.8102 39.2995 15.7229 39.3376 15.6419 39.3376C15.5609 39.3376 15.4861 39.3122 15.4051 39.3059C15.2929 39.2995 15.1808 39.3249 15.0811 39.2741C15.0001 39.236 14.9502 39.1408 14.9502 39.0519C14.9502 38.963 15.0125 38.874 15.0935 38.8359H15.0998Z"
        fill="#696969"
      />
      <path
        d="M12.0713 37.5791C12.0713 37.5791 12.0962 37.5982 12.1087 37.6045C12.1398 37.6426 12.1648 37.6807 12.2022 37.7188L12.0713 37.5791Z"
        fill="#696969"
      />
      <path
        d="M12.6319 37.7127C12.5759 37.7254 12.5135 37.7127 12.4512 37.7127C12.3204 37.6936 12.2206 37.6619 12.1085 37.5793C12.0088 37.5095 11.8779 37.3253 11.9651 37.1983C12.0088 37.1348 12.1022 37.1158 12.177 37.1285C12.2518 37.1412 12.3266 37.1793 12.4014 37.1983C12.5073 37.2237 12.6257 37.2237 12.713 37.2872C12.7877 37.3444 12.8251 37.4396 12.8064 37.5285C12.7877 37.6174 12.7192 37.6936 12.6319 37.7127Z"
        fill="#696969"
      />
      <path
        d="M19.9422 42.4752C20.0731 42.2847 20.3971 42.3292 20.528 42.5197C20.6028 42.634 20.5965 42.869 20.5841 42.996C20.5841 43.0277 20.5716 43.0658 20.5467 43.0912C20.5155 43.1166 20.4719 43.1166 20.4345 43.1103L19.9235 43.0595C19.9235 43.0595 19.9048 43.0595 19.8923 43.0595C19.855 43.0468 19.8487 43.0023 19.8425 42.9642C19.8176 42.7928 19.8425 42.615 19.9422 42.4752Z"
        fill="#696969"
      />
      <path
        d="M17.892 38.963C18.1163 38.963 18.2659 39.2678 18.1786 39.4837C18.1288 39.6107 17.9294 39.7378 17.8172 39.7949C17.786 39.814 17.7549 39.8267 17.7237 39.8203C17.6863 39.8076 17.6614 39.7695 17.6427 39.7378C17.5617 39.5853 17.4807 39.4329 17.3997 39.2805C17.3997 39.2742 17.3872 39.2615 17.3872 39.2551C17.381 39.217 17.4059 39.1853 17.437 39.1599C17.5617 39.0456 17.7237 38.9694 17.892 38.9694V38.963Z"
        fill="#696969"
      />
      <path
        d="M23.6686 37.5413C23.7746 37.4016 23.9865 37.427 24.0675 37.5858C24.1298 37.7191 24.0986 37.8779 24.0675 38.0176C24.0301 38.1636 23.8182 38.5764 23.6562 38.2843C23.5689 38.1255 23.5565 37.6937 23.6749 37.5413H23.6686Z"
        fill="#C2C2C2"
      />
      <path
        d="M25.2017 39.8656C25.1457 39.7386 25.183 39.5036 25.289 39.4147C25.3513 39.3576 25.4385 39.3322 25.5258 39.3258C25.5632 39.3258 25.6068 39.3258 25.6442 39.3385C25.7377 39.3703 25.8125 39.4528 25.8498 39.5481C25.9184 39.764 25.7751 40.0116 25.6068 40.145C25.5943 40.1577 25.5756 40.1704 25.557 40.1704C25.5258 40.1704 25.4946 40.1577 25.4697 40.1386C25.4074 40.0878 25.3451 40.037 25.289 39.9799C25.2578 39.9481 25.2267 39.9164 25.2142 39.8783L25.2017 39.8656Z"
        fill="#C2C2C2"
      />
      <path
        d="M22.9772 41.8148C22.8962 41.6433 22.8401 41.4655 22.8089 41.2813C22.784 41.1162 22.7342 40.9829 22.9273 41.0273C23.0146 41.0464 23.1455 41.0972 23.214 41.1543C23.2389 41.1797 23.3573 41.4147 23.3387 41.4147C23.214 41.4147 23.1642 41.7513 23.0769 41.8402C23.0582 41.8592 23.0395 41.8719 23.0146 41.8656C22.9897 41.8656 22.9772 41.8402 22.971 41.8148H22.9772Z"
        fill="#C2C2C2"
      />
      <path
        d="M23.4446 39.7824C23.457 39.8142 23.4757 39.8523 23.5069 39.8523C23.5256 39.8523 23.538 39.8396 23.5505 39.8269C23.619 39.7634 23.6751 39.6808 23.7686 39.6618C23.8247 39.6491 23.8932 39.6618 23.9119 39.6046C23.9119 39.3125 23.5256 39.4141 23.3511 39.4649C23.3573 39.5729 23.4009 39.6745 23.4383 39.7761L23.4446 39.7824Z"
        fill="#C2C2C2"
      />
      <path
        d="M17.3744 41.2876C17.3868 41.3193 17.4055 41.3574 17.4367 41.3574C17.4554 41.3574 17.4678 41.3447 17.4803 41.332C17.5488 41.2685 17.6049 41.1859 17.6984 41.1669C17.7545 41.1542 17.8293 41.1669 17.848 41.1097C17.848 40.8176 17.4616 40.9192 17.2871 40.97C17.2933 41.078 17.337 41.1796 17.3744 41.2812V41.2876Z"
        fill="#696969"
      />
      <path
        d="M25.3637 38.0296C25.1768 37.9026 25.2765 37.7248 25.4697 37.7756C25.6753 37.8264 25.8249 37.9471 25.9994 38.0614C26.043 38.0868 26.0866 38.1185 26.0991 38.1693C26.1116 38.2392 26.0555 38.2963 25.9994 38.3344L25.9121 38.4043C25.9121 38.4043 25.8997 38.4107 25.8934 38.4107C25.881 38.4107 25.8747 38.4043 25.8685 38.398C25.8187 38.3344 25.7937 38.2455 25.7252 38.1947C25.6753 38.1566 25.6068 38.1439 25.5445 38.1249C25.4759 38.1058 25.4136 38.0741 25.3575 38.036L25.3637 38.0296Z"
        fill="#C2C2C2"
      />
      <path
        d="M30.6699 29.0431L28.252 27.6714V27.2968L30.6699 28.6684V29.0431Z"
        fill="#373C3F"
      />
      <path
        d="M30.6699 28.668L28.252 27.2963L28.5386 27.1312L30.994 28.5854L30.6699 28.668Z"
        fill="#505453"
      />
      <path
        d="M30.994 28.9605L30.6699 29.043V28.6684L30.994 28.5858V28.9605Z"
        fill="#1C2225"
      />
      <path
        d="M31.617 28.5926L29.1616 27.1383V26.7637L31.617 28.2179V28.5926Z"
        fill="#373C3F"
      />
      <path
        d="M31.617 28.2175L29.1616 26.7633L29.4483 26.5981L31.9036 28.0524L31.617 28.2175Z"
        fill="#505453"
      />
      <path
        d="M31.9034 28.4274L31.6167 28.5925V28.2178L31.9034 28.0527V28.4274Z"
        fill="#1C2225"
      />
      <path
        d="M32.5272 28.0523L30.0781 26.5981V26.2297L32.5272 27.6776V28.0523Z"
        fill="#373C3F"
      />
      <path
        d="M32.5272 27.6778L30.0781 26.2299L30.3586 26.0585L32.8139 27.5127L32.5272 27.6778Z"
        fill="#505453"
      />
      <path
        d="M32.814 27.8876L32.5273 28.0527V27.678L32.814 27.5129V27.8876Z"
        fill="#1C2225"
      />
      <path
        d="M33.4369 27.5199L30.9878 26.0657V25.691L33.4369 27.1453V27.5199Z"
        fill="#373C3F"
      />
      <path
        d="M33.4369 27.1451L30.9878 25.6909L31.2682 25.5258L33.7236 26.98L33.4369 27.1451Z"
        fill="#505453"
      />
      <path
        d="M33.7237 27.3544L33.437 27.5195V27.1448L33.7237 26.9797V27.3544Z"
        fill="#1C2225"
      />
      <path
        d="M34.3466 26.9796L31.8975 25.5318V25.1571L34.3466 26.605V26.9796Z"
        fill="#373C3F"
      />
      <path
        d="M34.3466 26.6052L31.8975 25.1573L32.1779 24.9858L34.6332 26.4401L34.3466 26.6052Z"
        fill="#505453"
      />
      <path
        d="M34.6333 26.8146L34.3467 26.9797V26.605L34.6333 26.4399V26.8146Z"
        fill="#1C2225"
      />
      <path
        d="M35.2567 26.4462L32.8076 24.992V24.6173L35.2567 26.0715V26.4462Z"
        fill="#373C3F"
      />
      <path
        d="M35.2567 26.0711L32.8076 24.6169L33.0881 24.4518L35.5434 25.906L35.2567 26.0711Z"
        fill="#505453"
      />
      <path
        d="M35.5435 26.2806L35.2568 26.4457V26.071L35.5435 25.9059V26.2806Z"
        fill="#1C2225"
      />
      <path
        d="M36.1664 25.9066L33.7173 24.4588V24.0841L36.1664 25.532V25.9066Z"
        fill="#373C3F"
      />
      <path
        d="M36.1664 25.5316L33.7173 24.0837L33.9977 23.9186L36.4531 25.3665L36.1664 25.5316Z"
        fill="#505453"
      />
      <path
        d="M36.4532 25.7406L36.1665 25.9057V25.5311L36.4532 25.366V25.7406Z"
        fill="#1C2225"
      />
      <path
        d="M37.0761 25.3742L34.627 23.92V23.5453L37.0761 24.9995V25.3742Z"
        fill="#373C3F"
      />
      <path
        d="M37.0761 24.999L34.627 23.5447L34.9074 23.3796L37.3627 24.8339L37.0761 24.999Z"
        fill="#505453"
      />
      <path
        d="M37.3628 25.2087L37.0762 25.3738V24.9991L37.3628 24.834V25.2087Z"
        fill="#1C2225"
      />
      <path
        d="M37.9862 24.8337L35.5371 23.3858V23.0111L37.9862 24.459V24.8337Z"
        fill="#373C3F"
      />
      <path
        d="M37.9862 24.4584L35.5371 23.0106L35.8175 22.8455L38.2729 24.2933L37.9862 24.4584Z"
        fill="#505453"
      />
      <path
        d="M38.273 24.6678L37.9863 24.8329V24.4582L38.273 24.2931V24.6678Z"
        fill="#1C2225"
      />
      <path
        d="M38.8959 24.3005L36.4468 22.8462V22.4716L38.8959 23.9258V24.3005Z"
        fill="#373C3F"
      />
      <path
        d="M38.8959 23.9254L36.4468 22.4711L36.7272 22.306L39.1826 23.7603L38.8959 23.9254Z"
        fill="#505453"
      />
      <path
        d="M39.1827 24.1347L38.896 24.2998V23.9251L39.1827 23.76V24.1347Z"
        fill="#1C2225"
      />
      <path
        d="M39.8056 23.761L37.3564 22.3131V21.9385L39.8056 23.3863V23.761Z"
        fill="#373C3F"
      />
      <path
        d="M39.8056 23.3859L37.3564 21.9381L37.6431 21.7729L40.0922 23.2208L39.8056 23.3859Z"
        fill="#505453"
      />
      <path
        d="M40.0923 23.5948L39.8057 23.7599V23.3852L40.0923 23.2201V23.5948Z"
        fill="#1C2225"
      />
      <path
        d="M40.7157 23.2287L38.2666 21.7744V21.3998L40.7157 22.854V23.2287Z"
        fill="#373C3F"
      />
      <path
        d="M40.7157 22.8536L38.2666 21.3994L38.5533 21.2343L41.0024 22.6885L40.7157 22.8536Z"
        fill="#505453"
      />
      <path
        d="M41.0025 23.0628L40.7158 23.2279V22.8532L41.0025 22.6881V23.0628Z"
        fill="#1C2225"
      />
      <path
        d="M41.6254 22.6883L39.1763 21.2404V20.8657L41.6254 22.3199V22.6883Z"
        fill="#373C3F"
      />
      <path
        d="M41.6254 22.3195L39.1763 20.8653L39.4629 20.7002L41.9121 22.1481L41.6254 22.3195Z"
        fill="#505453"
      />
      <path
        d="M41.9122 22.5219L41.6255 22.687V22.3187L41.9122 22.1472V22.5219Z"
        fill="#1C2225"
      />
      <path
        d="M42.5355 22.153L40.0864 20.6988V20.3241L42.5355 21.7783V22.153Z"
        fill="#373C3F"
      />
      <path
        d="M42.5355 21.7779L40.0864 20.3237L40.3731 20.1586L42.8222 21.6128L42.5355 21.7779Z"
        fill="#505453"
      />
      <path
        d="M42.8223 21.9878L42.5356 22.1529V21.7783L42.8223 21.6132V21.9878Z"
        fill="#1C2225"
      />
      <path
        d="M26.7065 25.8878C26.7065 24.6812 27.336 23.5699 28.3642 22.9666L40.3605 15.8987C40.8155 15.632 41.3327 15.6764 41.7253 15.9305L44.3302 17.5244L29.5296 28.3009L27.5416 27.1324C27.4232 27.0816 27.311 27.0181 27.2113 26.9419C26.9122 26.7006 26.7128 26.3323 26.7128 25.8878H26.7065Z"
        fill="#3B3F40"
      />
      <path
        d="M43.5077 21.5248L41.0586 20.0706L40.9714 19.7784L43.4267 21.2327L43.5077 21.5248Z"
        fill="#373C3F"
      />
      <path
        d="M43.4267 21.2324L40.9714 19.7781L41.258 19.613L43.6635 21.0228L43.4267 21.2324Z"
        fill="#505453"
      />
      <path
        d="M43.7505 21.3149L43.5075 21.5245L43.4265 21.2324L43.6633 21.0228L43.7505 21.3149Z"
        fill="#1C2225"
      />
      <path
        d="M44.2745 20.7957L41.8254 19.3478V18.9731L44.1062 20.4718L44.2745 20.7957Z"
        fill="#373C3F"
      />
      <path
        d="M44.1062 20.4714L41.8254 18.9727L42.1058 18.8076L44.343 20.1539L44.1062 20.4714Z"
        fill="#505453"
      />
      <path
        d="M44.5112 20.4841L44.2744 20.7953L44.1061 20.4714L44.343 20.1539L44.5112 20.4841Z"
        fill="#1C2225"
      />
      <path
        d="M44.648 19.4812L42.1989 18.027V17.6523L44.648 19.1066V19.4812Z"
        fill="#373C3F"
      />
      <path
        d="M44.6478 19.1074L42.5041 17.8373L42.6163 17.7611L45.0654 19.2153L44.6478 19.1074Z"
        fill="#505453"
      />
      <path
        d="M44.9846 19.5444L44.592 19.4682L44.6481 19.1062L45.0656 19.2142L44.9846 19.5444Z"
        fill="#1C2225"
      />
      <path
        d="M44.7416 18.6481L42.3859 17.1558V16.7875L44.7416 18.2735V18.6481Z"
        fill="#373C3F"
      />
      <path
        d="M44.7416 18.2736L42.3859 16.7877L42.6664 16.6162L45.1217 18.0704L44.7416 18.2736Z"
        fill="#505453"
      />
      <path
        d="M45.1215 18.445L44.7414 18.6482V18.2735L45.1215 18.0703V18.445Z"
        fill="#1C2225"
      />
      <path
        d="M44.4301 17.6883L41.9748 16.2341L41.738 15.9928L44.1933 17.4407L44.4301 17.6883Z"
        fill="#373C3F"
      />
      <path
        d="M44.1933 17.4406L41.738 15.9928L41.9436 15.7705L44.3927 17.2247L44.1933 17.4406Z"
        fill="#505453"
      />
      <path
        d="M44.6293 17.4663L44.4299 17.6885L44.1931 17.4409L44.3925 17.225L44.6293 17.4663Z"
        fill="#1C2225"
      />
      <path
        d="M43.489 17.1751L41.0337 15.7272V15.3525L43.489 16.8004V17.1751Z"
        fill="#373C3F"
      />
      <path
        d="M43.489 16.8004L41.0337 15.3525L41.3453 15.416L43.7944 16.8639L43.489 16.8004Z"
        fill="#505453"
      />
      <path
        d="M43.7941 17.2381L43.4888 17.1746V16.7999L43.7941 16.8634V17.2381Z"
        fill="#1C2225"
      />
      <path
        d="M30.7949 24.433L42.7912 17.3651C43.6699 16.8508 44.7667 17.4921 44.7667 18.5272C44.7667 19.7338 44.1373 20.8451 43.109 21.4484L31.1127 28.5163C30.234 29.0307 29.1372 28.3893 29.1372 27.3542C29.1372 26.1476 29.7666 25.0363 30.7949 24.433Z"
        fill="#1C2225"
      />
      <path
        d="M30.4582 28.0473C30.5579 28.0473 30.6577 28.0219 30.7511 27.9648L42.7474 20.8968C43.5451 20.4269 44.0437 19.5506 44.0437 18.6107C44.0437 18.2361 43.7321 18.0011 43.4516 18.0011C43.3519 18.0011 43.2522 18.0265 43.1588 18.0837L31.1624 25.1516C30.3648 25.6215 29.8662 26.4978 29.8662 27.4377C29.8662 27.6536 29.9659 27.7933 30.0469 27.8695C30.1591 27.9775 30.3087 28.041 30.4645 28.041L30.4582 28.0473Z"
        fill="black"
      />
      <path
        d="M41.0525 20.4517C41.7318 20.0516 42.1867 19.3595 42.3114 18.5847L43.1464 18.0894C43.2399 18.0322 43.3396 18.0068 43.4393 18.0068C43.726 18.0068 44.0314 18.2418 44.0314 18.6165C44.0314 19.5563 43.5328 20.4327 42.7351 20.9026L30.7388 27.9705C30.6453 28.0276 30.5456 28.053 30.4459 28.053C30.2963 28.053 30.1406 27.9895 30.0284 27.8816C29.9474 27.799 29.8477 27.6593 29.8477 27.4498C29.8477 27.3101 29.8601 27.1703 29.885 27.0306L41.0401 20.4581L41.0525 20.4517Z"
        fill="url(#paint1_linear_4613_99840)"
      />
      <path
        d="M30.1651 27.4054C30.2025 27.4054 30.2399 27.3927 30.2773 27.3736L42.2736 20.3057C42.9591 19.8993 43.3891 19.15 43.3891 18.3435C43.3891 18.1657 43.2271 18.1022 43.1586 18.1022C43.1212 18.1022 43.0838 18.1149 43.0464 18.1339L31.0501 25.2018C30.3646 25.6083 29.9346 26.3576 29.9346 27.1641C29.9346 27.2085 29.9408 27.272 30.0031 27.3292C30.0467 27.3736 30.1091 27.399 30.1714 27.399L30.1651 27.4054Z"
        fill="#A85D00"
      />
      <path
        d="M31.6172 24.98L31.8041 25.0879L41.7689 19.2457L41.5819 19.1377L31.6172 24.98Z"
        fill="#E58919"
      />
      <path
        d="M31.8042 25.0888V26.0223L41.7689 20.18V19.2465L31.8042 25.0888Z"
        fill="#CC7A16"
      />
      <path
        d="M31.6172 24.9795L31.8041 25.0874V26.0209L31.6172 25.9066V24.9795Z"
        fill="#AB6613"
      />
      <path
        d="M30.7073 24.4865L28.252 23.0323V22.6576L30.7073 24.1118V24.4865Z"
        fill="#373C3F"
      />
      <path
        d="M30.7073 24.1114L28.252 22.6572L28.5386 22.4921L30.994 23.9463L30.7073 24.1114Z"
        fill="#505453"
      />
      <path
        d="M30.9937 24.3395L30.707 24.5046V24.1109L30.9937 23.9458V24.3395Z"
        fill="#1C2225"
      />
      <path
        d="M31.617 23.9451L29.1616 22.4972V22.1226L31.617 23.5704V23.9451Z"
        fill="#373C3F"
      />
      <path
        d="M31.617 23.57L29.1616 22.1221L29.4483 21.957L31.9036 23.4049L31.617 23.57Z"
        fill="#505453"
      />
      <path
        d="M31.9034 23.7987L31.6167 23.9702V23.5701L31.9034 23.405V23.7987Z"
        fill="#1C2225"
      />
      <path
        d="M32.5272 23.4118L30.0781 21.9576V21.5829L32.5272 23.0371V23.4118Z"
        fill="#373C3F"
      />
      <path
        d="M32.5272 23.0367L30.0781 21.5825L30.3586 21.4174L32.8139 22.8716L32.5272 23.0367Z"
        fill="#505453"
      />
      <path
        d="M32.814 23.2657L32.5273 23.4308V23.0371L32.814 22.8719V23.2657Z"
        fill="#1C2225"
      />
      <path
        d="M33.4369 22.8723L30.9878 21.4245V21.0498L33.4369 22.4977V22.8723Z"
        fill="#373C3F"
      />
      <path
        d="M33.4369 22.4973L30.9878 21.0494L31.2682 20.8843L33.7236 22.3322L33.4369 22.4973Z"
        fill="#505453"
      />
      <path
        d="M33.7237 22.7321L33.437 22.8972V22.4971L33.7237 22.332V22.7321Z"
        fill="#1C2225"
      />
      <path
        d="M34.3466 22.3391L31.8975 20.8849V20.5103L34.3466 21.9645V22.3391Z"
        fill="#373C3F"
      />
      <path
        d="M34.3466 21.9643L31.8975 20.5101L32.1779 20.345L34.6332 21.7992L34.3466 21.9643Z"
        fill="#505453"
      />
      <path
        d="M34.6333 22.1927L34.3467 22.3578V21.9641L34.6333 21.799V22.1927Z"
        fill="#1C2225"
      />
      <path
        d="M35.2567 21.7987L32.8076 20.3509V19.9762L35.2567 21.4304V21.7987Z"
        fill="#373C3F"
      />
      <path
        d="M35.2567 21.4301L32.8076 19.9759L33.0881 19.8108L35.5434 21.2587L35.2567 21.4301Z"
        fill="#505453"
      />
      <path
        d="M35.5435 21.6581L35.2568 21.8232V21.4295L35.5435 21.2581V21.6581Z"
        fill="#1C2225"
      />
      <path
        d="M36.1664 21.2663L33.7173 19.812V19.4374L36.1664 20.8916V21.2663Z"
        fill="#373C3F"
      />
      <path
        d="M36.1664 20.8912L33.7173 19.437L33.9977 19.2719L36.4531 20.7261L36.1664 20.8912Z"
        fill="#505453"
      />
      <path
        d="M36.4532 21.1197L36.1665 21.2848V20.8911L36.4532 20.726V21.1197Z"
        fill="#1C2225"
      />
      <path
        d="M37.0761 20.7331L34.627 19.2788V18.9042L37.0761 20.3584V20.7331Z"
        fill="#373C3F"
      />
      <path
        d="M37.0761 20.358L34.627 18.9038L34.9074 18.7386L37.3627 20.1865L37.0761 20.358Z"
        fill="#505453"
      />
      <path
        d="M37.3628 20.5862L37.0762 20.7513V20.3576L37.3628 20.1862V20.5862Z"
        fill="#1C2225"
      />
      <path
        d="M37.9862 20.1933L35.5371 18.739V18.3644L37.9862 19.8186V20.1933Z"
        fill="#373C3F"
      />
      <path
        d="M37.9862 19.8181L35.5371 18.3638L35.8175 18.1987L38.2729 19.653L37.9862 19.8181Z"
        fill="#505453"
      />
      <path
        d="M38.273 20.0468L37.9863 20.2119V19.8182L38.273 19.6531V20.0468Z"
        fill="#1C2225"
      />
      <path
        d="M38.8959 19.6592L36.4468 18.205V17.8303L38.8959 19.2845V19.6592Z"
        fill="#373C3F"
      />
      <path
        d="M38.8959 19.284L36.4468 17.8298L36.7272 17.6647L39.1826 19.1189L38.8959 19.284Z"
        fill="#505453"
      />
      <path
        d="M39.1827 19.5127L38.896 19.6778V19.2841L39.1827 19.119V19.5127Z"
        fill="#1C2225"
      />
      <path
        d="M39.8056 19.1197L37.3564 17.6654V17.2908L39.8056 18.745V19.1197Z"
        fill="#373C3F"
      />
      <path
        d="M39.8056 18.7446L37.3564 17.2904L37.6431 17.1252L40.0922 18.5795L39.8056 18.7446Z"
        fill="#505453"
      />
      <path
        d="M40.0923 18.9728L39.8057 19.1379V18.7442L40.0923 18.5791V18.9728Z"
        fill="#1C2225"
      />
      <path
        d="M40.7157 18.5876L38.2666 17.1333V16.7587L40.7157 18.2129V18.5876Z"
        fill="#373C3F"
      />
      <path
        d="M40.7157 18.2125L38.2666 16.7582L38.5533 16.5931L41.0024 18.0474L40.7157 18.2125Z"
        fill="#505453"
      />
      <path
        d="M41.0025 18.4407L40.7158 18.6058V18.2121L41.0025 18.047V18.4407Z"
        fill="#1C2225"
      />
      <path
        d="M41.6254 18.0473L39.1763 16.5931V16.2247L41.6254 17.6726V18.0473Z"
        fill="#373C3F"
      />
      <path
        d="M41.6254 17.6728L39.1763 16.2249L39.4629 16.0535L41.9121 17.5077L41.6254 17.6728Z"
        fill="#505453"
      />
      <path
        d="M41.9122 17.9009L41.6255 18.066V17.6723L41.9122 17.5072V17.9009Z"
        fill="#1C2225"
      />
      <path
        d="M42.5355 17.5138L40.0864 16.0596V15.6849L42.5355 17.1392V17.5138Z"
        fill="#373C3F"
      />
      <path
        d="M42.5355 17.1387L40.0864 15.6845L40.3731 15.5194L42.8222 16.9736L42.5355 17.1387Z"
        fill="#505453"
      />
      <path
        d="M42.8223 17.3669L42.5356 17.532V17.1383L42.8223 16.9731V17.3669Z"
        fill="#1C2225"
      />
      <path
        d="M29.5108 25.7991L27.0554 24.3448L26.8311 24.3766L29.2802 25.8308L29.5108 25.7991Z"
        fill="#373C3F"
      />
      <path
        d="M29.2055 26.0785L26.7563 24.6306L26.8311 24.3766L29.2802 25.8308L29.2055 26.0785Z"
        fill="#505453"
      />
      <path
        d="M29.5109 25.7994L29.405 26.0471L29.2056 26.0789L29.2804 25.8312L29.5109 25.7994Z"
        fill="#1C2225"
      />
      <path
        d="M29.9347 25.177L27.4856 23.7227L27.3423 23.5005L29.7914 24.9547L29.9347 25.177Z"
        fill="#373C3F"
      />
      <path
        d="M29.7914 24.9554L27.3423 23.5012L27.5978 23.2281L30.0469 24.676L29.7914 24.9554Z"
        fill="#505453"
      />
      <path
        d="M30.1903 24.8974L29.9348 25.1768L29.7915 24.9546L30.047 24.6752L30.1903 24.8974Z"
        fill="#1C2225"
      />
      <path
        d="M29.2121 26.6058L26.7629 25.1516L26.5137 25.2342L28.9628 26.6884L29.2121 26.6058Z"
        fill="#373C3F"
      />
      <path
        d="M28.9314 26.9807L26.4761 25.5328L26.5135 25.2344L28.9626 26.6886L28.9314 26.9807Z"
        fill="#505453"
      />
      <path
        d="M29.2116 26.6053L29.168 26.8848L28.9312 26.98L28.9623 26.6879L29.2116 26.6053Z"
        fill="#1C2225"
      />
      <path
        d="M29.9533 28.5871L27.3671 27.0947L26.9932 27.3614L29.5856 28.8982L29.9533 28.5871Z"
        fill="#373C3F"
      />
      <path
        d="M29.9531 28.5865L30.271 28.6818L29.8783 29.0247L29.5854 28.8977L29.9531 28.5865Z"
        fill="#1C2225"
      />
      <path
        d="M29.1993 27.7041L26.7439 26.2499L26.4946 26.3324L28.9437 27.7867L29.1993 27.7041Z"
        fill="#373C3F"
      />
      <path
        d="M29.1058 28.1805L26.6504 26.7262L26.4946 26.3325L28.9437 27.7867L29.1058 28.1805Z"
        fill="#505453"
      />
      <path
        d="M29.1994 27.704L29.3427 28.085L29.1059 28.1803L28.9438 27.7865L29.1994 27.704Z"
        fill="#1C2225"
      />
      <path
        d="M43.1589 18.7248C43.1589 19.0995 42.8972 19.5567 42.5731 19.7409C42.249 19.9314 41.9873 19.779 41.9873 19.4106C41.9873 19.0423 42.249 18.5788 42.5731 18.3946C42.8972 18.2041 43.1589 18.3565 43.1589 18.7248Z"
        fill="#E0B350"
      />
      <path
        d="M43.0652 18.7757C43.0652 19.0868 42.8471 19.4742 42.5729 19.6329C42.3049 19.7917 42.0806 19.6647 42.0806 19.3535C42.0806 19.0424 42.2987 18.655 42.5729 18.4962C42.8471 18.3375 43.0652 18.4645 43.0652 18.7757Z"
        fill="#A85D00"
      />
      <path
        d="M42.9343 18.8519C42.9343 19.0806 42.7723 19.3663 42.5729 19.4806C42.3735 19.5949 42.2114 19.506 42.2114 19.2774C42.2114 19.0488 42.3735 18.763 42.5729 18.6487C42.7723 18.5344 42.9343 18.6233 42.9343 18.8519Z"
        fill="#CD8B19"
      />
      <path
        d="M42.6731 18.6112C42.6419 18.6176 42.6045 18.6303 42.5734 18.6493C42.3739 18.7636 42.2119 19.0494 42.2119 19.278C42.2119 19.3415 42.2244 19.3923 42.2431 19.4304L42.5734 19.3605C42.7042 19.2462 42.7167 19.1192 42.7915 18.9541L42.6731 18.6049V18.6112Z"
        fill="#B86A0B"
      />
      <path
        d="M42.6792 19.2201C42.7041 19.1757 42.7228 19.1249 42.7415 19.0741C42.6044 19.0169 42.4735 18.9471 42.3613 18.8455C42.299 18.9344 42.2554 19.0296 42.2305 19.1312C42.3863 19.1312 42.5358 19.163 42.6792 19.2201Z"
        fill="#DE9B16"
      />
      <path
        d="M42.9344 19.0237C42.9344 19.1888 42.8222 19.3856 42.6788 19.4682C42.5355 19.5508 42.4233 19.4873 42.4233 19.3221C42.4233 19.157 42.5355 18.9602 42.6788 18.8776C42.8222 18.7951 42.9344 18.8586 42.9344 19.0237Z"
        fill="#B86A0B"
      />
      <path
        d="M42.8538 19.1254C42.8538 19.2144 42.7915 19.3223 42.7167 19.3668C42.6419 19.4112 42.5796 19.3731 42.5796 19.2906C42.5796 19.208 42.6419 19.0937 42.7167 19.0492C42.7915 19.0048 42.8538 19.0365 42.8538 19.1254Z"
        fill="#E0981B"
      />
      <path
        d="M31.5739 25.5952C31.5739 25.9698 31.3122 26.4271 30.9881 26.6112C30.6641 26.8017 30.4023 26.6493 30.4023 26.281C30.4023 25.9127 30.6641 25.4491 30.9881 25.265C31.3122 25.0744 31.5739 25.2269 31.5739 25.5952Z"
        fill="#E0B350"
      />
      <path
        d="M31.4802 25.6518C31.4802 25.9629 31.2621 26.3503 30.9879 26.509C30.72 26.6678 30.4956 26.5408 30.4956 26.2296C30.4956 25.9185 30.7137 25.5311 30.9879 25.3723C31.2559 25.2136 31.4802 25.3406 31.4802 25.6518Z"
        fill="#A85D00"
      />
      <path
        d="M31.3494 25.728C31.3494 25.9567 31.1873 26.2424 30.9879 26.3567C30.7885 26.471 30.6265 26.3821 30.6265 26.1535C30.6265 25.9249 30.7885 25.6391 30.9879 25.5248C31.1873 25.4105 31.3494 25.4994 31.3494 25.728Z"
        fill="#CD8B19"
      />
      <path
        d="M31.0881 25.4814C31.0569 25.4878 31.0196 25.5005 30.9884 25.5195C30.789 25.6339 30.627 25.9196 30.627 26.1482C30.627 26.2117 30.6394 26.2625 30.6581 26.3006L30.9884 26.2308C31.1193 26.1165 31.1317 25.9895 31.2065 25.8244L31.0881 25.4751V25.4814Z"
        fill="#B86A0B"
      />
      <path
        d="M31.0942 26.0904C31.1191 26.0459 31.1378 25.9951 31.1565 25.9443C31.0194 25.8872 30.8885 25.8173 30.7764 25.7157C30.7141 25.8046 30.6704 25.8999 30.6455 26.0015C30.8013 26.0015 30.9509 26.0332 31.0942 26.0904Z"
        fill="#DE9B16"
      />
      <path
        d="M31.3494 25.894C31.3494 26.0592 31.2372 26.256 31.0939 26.3386C30.9506 26.4211 30.8384 26.3576 30.8384 26.1925C30.8384 26.0274 30.9506 25.8305 31.0939 25.748C31.2372 25.6654 31.3494 25.7289 31.3494 25.894Z"
        fill="#B86A0B"
      />
      <path
        d="M31.2683 25.9955C31.2683 26.0844 31.206 26.1924 31.1312 26.2368C31.0565 26.2813 30.9941 26.2495 30.9941 26.1606C30.9941 26.0717 31.0565 25.9637 31.1312 25.9193C31.206 25.8748 31.2683 25.9129 31.2683 25.9955Z"
        fill="#E0981B"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M43.1522 18.6477C43.1148 18.6477 43.0774 18.6604 43.04 18.6794L31.0437 25.7473C30.4766 26.0839 30.0903 26.6554 29.9718 27.3032C29.9345 27.2524 29.9282 27.2016 29.9282 27.1698C29.9282 26.3633 30.352 25.6076 31.0437 25.2076L43.04 18.1397C43.0774 18.1143 43.1148 18.1079 43.1522 18.1079C43.227 18.1079 43.3828 18.1651 43.3828 18.3492C43.3828 18.4889 43.3641 18.6223 43.3392 18.762C43.2893 18.6858 43.1958 18.6604 43.146 18.6604L43.1522 18.6477Z"
          fill="#8C7900"
        />
      </g>
      <path
        d="M30.7886 26.4338V25.3606L40.8343 20.5216L48.7675 25.1573L38.7217 31.0631C38.7217 31.0631 35.5185 26.7068 30.7886 26.4274V26.4338Z"
        fill="#131314"
      />
      <path
        d="M30.7886 25.3611L40.8343 19.4553L48.7675 24.0911L38.7217 29.9969C38.7217 29.9969 35.5185 25.6405 30.7886 25.3611Z"
        fill="#313131"
      />
      <path
        d="M40.9899 35.1141L38.5781 33.7425V33.3678L40.9899 34.7395V35.1141Z"
        fill="#373C3F"
      />
      <path
        d="M40.9899 34.7389L38.5781 33.3673L38.8648 33.2021L41.3139 34.6564L40.9899 34.7389Z"
        fill="#505453"
      />
      <path
        d="M41.3138 35.0248L40.9897 35.1137V34.739L41.3138 34.6565V35.0248Z"
        fill="#1C2225"
      />
      <path
        d="M41.9369 34.6573L39.4878 33.203V32.8284L41.9369 34.2826V34.6573Z"
        fill="#373C3F"
      />
      <path
        d="M41.9369 34.2822L39.4878 32.828L39.7745 32.6628L42.2236 34.1171L41.9369 34.2822Z"
        fill="#505453"
      />
      <path
        d="M42.2237 34.4914L41.937 34.6565V34.2818L42.2237 34.1167V34.4914Z"
        fill="#1C2225"
      />
      <path
        d="M42.8471 34.1242L40.3979 32.67V32.2953L42.8471 33.7495V34.1242Z"
        fill="#373C3F"
      />
      <path
        d="M42.8471 33.7491L40.3979 32.2949L40.6846 32.1298L43.1337 33.584L42.8471 33.7491Z"
        fill="#505453"
      />
      <path
        d="M43.1338 33.9583L42.8472 34.1234V33.7487L43.1338 33.5836V33.9583Z"
        fill="#1C2225"
      />
      <path
        d="M43.7567 33.5839L41.3076 32.1297V31.7614L43.7567 33.2092V33.5839Z"
        fill="#373C3F"
      />
      <path
        d="M43.7567 33.2094L41.3076 31.7615L41.5943 31.5901L44.0434 33.0443L43.7567 33.2094Z"
        fill="#505453"
      />
      <path
        d="M44.0435 33.4185L43.7568 33.5836V33.2089L44.0435 33.0438V33.4185Z"
        fill="#1C2225"
      />
      <path
        d="M44.6664 33.0514L42.2173 31.5972V31.2225L44.6664 32.6768V33.0514Z"
        fill="#373C3F"
      />
      <path
        d="M44.6664 32.6763L42.2173 31.2221L42.5039 31.057L44.9531 32.5112L44.6664 32.6763Z"
        fill="#505453"
      />
      <path
        d="M44.9532 32.8854L44.6665 33.0505V32.6758L44.9532 32.5107V32.8854Z"
        fill="#1C2225"
      />
      <path
        d="M45.5766 32.5092L43.1274 31.0613V30.6866L45.5766 32.1345V32.5092Z"
        fill="#373C3F"
      />
      <path
        d="M45.5766 32.1347L43.1274 30.6868L43.4141 30.5154L45.8632 31.9696L45.5766 32.1347Z"
        fill="#505453"
      />
      <path
        d="M45.8633 32.3446L45.5767 32.5097V32.1351L45.8633 31.97V32.3446Z"
        fill="#1C2225"
      />
      <path
        d="M46.4862 31.9767L44.0371 30.5225V30.1478L46.4862 31.6021V31.9767Z"
        fill="#373C3F"
      />
      <path
        d="M46.4862 31.6016L44.0371 30.1474L44.3238 29.9823L46.7729 31.4365L46.4862 31.6016Z"
        fill="#505453"
      />
      <path
        d="M46.773 31.8116L46.4863 31.9767V31.602L46.773 31.4369V31.8116Z"
        fill="#1C2225"
      />
      <path
        d="M47.3959 31.4372L44.9468 29.9893V29.6146L47.3959 31.0625V31.4372Z"
        fill="#373C3F"
      />
      <path
        d="M47.3959 31.0621L44.9468 29.6142L45.2334 29.4491L47.6826 30.897L47.3959 31.0621Z"
        fill="#505453"
      />
      <path
        d="M47.6827 31.2715L47.396 31.4366V31.062L47.6827 30.8969V31.2715Z"
        fill="#1C2225"
      />
      <path
        d="M48.3061 30.9041L45.8569 29.4499V29.0752L48.3061 30.5294V30.9041Z"
        fill="#373C3F"
      />
      <path
        d="M48.3061 30.5291L45.8569 29.0749L46.1436 28.9098L48.5927 30.364L48.3061 30.5291Z"
        fill="#505453"
      />
      <path
        d="M48.5928 30.7384L48.3062 30.9035V30.5289L48.5928 30.3638V30.7384Z"
        fill="#1C2225"
      />
      <path
        d="M49.2157 30.3637L46.7666 28.9158V28.5411L49.2157 29.989V30.3637Z"
        fill="#373C3F"
      />
      <path
        d="M49.2157 29.9887L46.7666 28.5408L47.0533 28.3757L49.5024 29.8236L49.2157 29.9887Z"
        fill="#505453"
      />
      <path
        d="M49.5025 30.1977L49.2158 30.3628V29.9881L49.5025 29.823V30.1977Z"
        fill="#1C2225"
      />
      <path
        d="M50.1321 29.8302L47.6768 28.376V28.0013L50.1321 29.4556V29.8302Z"
        fill="#373C3F"
      />
      <path
        d="M50.1321 29.4551L47.6768 28.0009L47.9634 27.8358L50.4125 29.29L50.1321 29.4551Z"
        fill="#505453"
      />
      <path
        d="M50.4123 29.6647L50.1318 29.8298V29.4551L50.4123 29.29V29.6647Z"
        fill="#1C2225"
      />
      <path
        d="M51.0418 29.2917L48.5864 27.8438V27.4691L51.0418 28.917V29.2917Z"
        fill="#373C3F"
      />
      <path
        d="M51.0418 28.9166L48.5864 27.4687L48.8731 27.3036L51.3222 28.7515L51.0418 28.9166Z"
        fill="#505453"
      />
      <path
        d="M51.3219 29.1258L51.0415 29.2909V28.9162L51.3219 28.7511V29.1258Z"
        fill="#1C2225"
      />
      <path
        d="M51.9519 28.7582L49.4966 27.304V26.9293L51.9519 28.3835V28.7582Z"
        fill="#373C3F"
      />
      <path
        d="M51.9519 28.383L49.4966 26.9288L49.7832 26.7637L52.2324 28.2179L51.9519 28.383Z"
        fill="#505453"
      />
      <path
        d="M52.2321 28.5927L51.9517 28.7578V28.3831L52.2321 28.218V28.5927Z"
        fill="#1C2225"
      />
      <path
        d="M52.8615 28.2176L50.4062 26.7697V26.395L52.8615 27.8492V28.2176Z"
        fill="#373C3F"
      />
      <path
        d="M52.8615 27.8487L50.4062 26.3945L50.6929 26.2294L53.142 27.6772L52.8615 27.8487Z"
        fill="#505453"
      />
      <path
        d="M53.1417 28.0517L52.8613 28.2168V27.8485L53.1417 27.677V28.0517Z"
        fill="#1C2225"
      />
      <path
        d="M37.0269 31.9582C37.0269 30.7516 37.6563 29.6403 38.6845 29.037L50.6808 21.9691C51.1358 21.7024 51.653 21.7469 52.0456 22.0009L54.6505 23.5948L39.8499 34.3713L37.8619 33.2028C37.7435 33.152 37.6313 33.0885 37.5316 33.0123C37.2325 32.771 37.0331 32.4027 37.0331 31.9582H37.0269Z"
        fill="#3B3F40"
      />
      <path
        d="M53.8337 27.5894L51.3784 26.1415L51.2974 25.843L53.7465 27.2972L53.8337 27.5894Z"
        fill="#373C3F"
      />
      <path
        d="M53.7465 27.2968L51.2974 25.8426L51.5778 25.6775L53.9833 27.0873L53.7465 27.2968Z"
        fill="#505453"
      />
      <path
        d="M54.0706 27.3854L53.8338 27.5886L53.7466 27.2965L53.9834 27.0869L54.0706 27.3854Z"
        fill="#1C2225"
      />
      <path
        d="M54.5941 26.8647L52.145 25.4104V25.0358L54.4259 26.5408L54.5941 26.8647Z"
        fill="#373C3F"
      />
      <path
        d="M54.4259 26.5404L52.145 25.0353L52.4317 24.8702L54.6627 26.2229L54.4259 26.5404Z"
        fill="#505453"
      />
      <path
        d="M54.8308 26.5466L54.594 26.8642L54.4258 26.5403L54.6626 26.2228L54.8308 26.5466Z"
        fill="#1C2225"
      />
      <path
        d="M54.9744 25.5435L52.519 24.0956V23.7209L54.9744 25.1688V25.5435Z"
        fill="#373C3F"
      />
      <path
        d="M54.9742 25.1695L52.8242 23.8994L52.9364 23.8296L55.3917 25.2838L54.9742 25.1695Z"
        fill="#505453"
      />
      <path
        d="M55.3047 25.6129L54.9121 25.5303L54.9744 25.1683L55.392 25.2826L55.3047 25.6129Z"
        fill="#1C2225"
      />
      <path
        d="M55.0674 24.7116L52.7056 23.2256V22.851L55.0674 24.3433V24.7116Z"
        fill="#373C3F"
      />
      <path
        d="M55.0674 24.3429L52.7056 22.8505L52.9922 22.6854L55.4413 24.1396L55.0674 24.3429Z"
        fill="#505453"
      />
      <path
        d="M55.4413 24.5148L55.0674 24.7117V24.3433L55.4413 24.1401V24.5148Z"
        fill="#1C2225"
      />
      <path
        d="M54.7499 23.7532L52.3008 22.2989L52.064 22.0576L54.5131 23.5118L54.7499 23.7532Z"
        fill="#373C3F"
      />
      <path
        d="M54.5131 23.5118L52.064 22.0576L52.2634 21.8353L54.7187 23.2896L54.5131 23.5118Z"
        fill="#505453"
      />
      <path
        d="M54.9556 23.5305L54.75 23.7528L54.5132 23.5114L54.7188 23.2892L54.9556 23.5305Z"
        fill="#1C2225"
      />
      <path
        d="M53.809 23.2442L51.3599 21.79V21.4153L53.809 22.8695V23.2442Z"
        fill="#373C3F"
      />
      <path
        d="M53.809 22.8695L51.3599 21.4153L51.6652 21.4788L54.1143 22.933L53.809 22.8695Z"
        fill="#505453"
      />
      <path
        d="M54.1144 23.308L53.8091 23.2445V22.8699L54.1144 22.9334V23.308Z"
        fill="#1C2225"
      />
      <path
        d="M41.1147 30.4969L53.111 23.429C53.9897 22.9146 55.0865 23.556 55.0865 24.5911C55.0865 25.7977 54.4571 26.909 53.4288 27.5122L41.4325 34.5801C40.5538 35.0945 39.457 34.4531 39.457 33.418C39.457 32.2115 40.0865 31.1002 41.1147 30.4969Z"
        fill="#1C2225"
      />
      <path
        d="M40.7781 34.1169C40.8778 34.1169 40.9775 34.0915 41.071 34.0343L53.0673 26.9664C53.8649 26.4965 54.3635 25.6202 54.3635 24.6803C54.3635 24.3056 54.0519 24.0707 53.7715 24.0707C53.6718 24.0707 53.572 24.0961 53.4786 24.1532L41.4823 31.2211C40.6846 31.6911 40.186 32.5674 40.186 33.5073C40.186 33.7232 40.2857 33.8629 40.3668 33.9391C40.4789 34.047 40.6285 34.1105 40.7843 34.1105L40.7781 34.1169Z"
        fill="black"
      />
      <path
        d="M51.3724 26.5156C52.0516 26.1155 52.5065 25.4233 52.6312 24.6486L53.4663 24.1532C53.5597 24.0961 53.6594 24.0707 53.7592 24.0707C54.0458 24.0707 54.3512 24.3056 54.3512 24.6803C54.3512 25.6202 53.8526 26.4965 53.0549 26.9664L41.0586 34.0343C40.9652 34.0915 40.8654 34.1169 40.7657 34.1169C40.6162 34.1169 40.4604 34.0534 40.3482 33.9454C40.2672 33.8629 40.1675 33.7232 40.1675 33.5136C40.1675 33.3739 40.1799 33.2342 40.2049 33.0945L51.3599 26.5219L51.3724 26.5156Z"
        fill="url(#paint2_linear_4613_99840)"
      />
      <path
        d="M40.485 33.4749C40.5224 33.4749 40.5598 33.4621 40.5971 33.4431L52.5935 26.3752C53.279 25.9688 53.709 25.2194 53.709 24.4129C53.709 24.2351 53.5469 24.1716 53.4784 24.1716C53.441 24.1716 53.4036 24.1843 53.3662 24.2034L41.3699 31.2713C40.6844 31.6777 40.2544 32.427 40.2544 33.2335C40.2544 33.278 40.2606 33.3415 40.3229 33.3986C40.3666 33.4431 40.4289 33.4685 40.4912 33.4685L40.485 33.4749Z"
        fill="#A85D00"
      />
      <path
        d="M41.9375 31.0424L42.1245 31.1567L52.0892 25.3144L51.9085 25.2064L41.9375 31.0424Z"
        fill="#E58919"
      />
      <path
        d="M42.1245 31.1575V32.0846L52.0892 26.2487V25.3152L42.1245 31.1575Z"
        fill="#CC7A16"
      />
      <path
        d="M41.9375 31.0428L42.1245 31.1572V32.0843L41.9375 31.9763V31.0428Z"
        fill="#AB6613"
      />
      <path
        d="M41.0272 30.5495L38.5781 29.1016V28.7269L41.0272 30.1748V30.5495Z"
        fill="#373C3F"
      />
      <path
        d="M41.0272 30.1743L38.5781 28.7264L38.8648 28.5613L41.3139 30.0092L41.0272 30.1743Z"
        fill="#505453"
      />
      <path
        d="M41.314 30.4025L41.0273 30.574V30.1739L41.314 30.0088V30.4025Z"
        fill="#1C2225"
      />
      <path
        d="M41.9369 30.0161L39.4878 28.5619V28.1873L41.9369 29.6415V30.0161Z"
        fill="#373C3F"
      />
      <path
        d="M41.9369 29.6408L39.4878 28.1866L39.7745 28.0215L42.2236 29.4757L41.9369 29.6408Z"
        fill="#505453"
      />
      <path
        d="M42.2237 29.8696L41.937 30.0347V29.6409L42.2237 29.4758V29.8696Z"
        fill="#1C2225"
      />
      <path
        d="M42.8471 29.4756L40.3979 28.0277V27.6531L42.8471 29.101V29.4756Z"
        fill="#373C3F"
      />
      <path
        d="M42.8471 29.1003L40.3979 27.6524L40.6846 27.4873L43.1337 28.9352L42.8471 29.1003Z"
        fill="#505453"
      />
      <path
        d="M43.1338 29.3287L42.8472 29.5001V29.1L43.1338 28.9349V29.3287Z"
        fill="#1C2225"
      />
      <path
        d="M43.7567 28.9434L41.3076 27.4892V27.1145L43.7567 28.5687V28.9434Z"
        fill="#373C3F"
      />
      <path
        d="M43.7567 28.5683L41.3076 27.1141L41.5943 26.949L44.0434 28.4032L43.7567 28.5683Z"
        fill="#505453"
      />
      <path
        d="M44.0435 28.7966L43.7568 28.9617V28.5679L44.0435 28.4028V28.7966Z"
        fill="#1C2225"
      />
      <path
        d="M44.6664 28.403L42.2173 26.9551V26.5804L44.6664 28.0283V28.403Z"
        fill="#373C3F"
      />
      <path
        d="M44.6664 28.0279L42.2173 26.58L42.5039 26.4149L44.9531 27.8628L44.6664 28.0279Z"
        fill="#505453"
      />
      <path
        d="M44.9532 28.262L44.6665 28.4271V28.027L44.9532 27.8619V28.262Z"
        fill="#1C2225"
      />
      <path
        d="M45.5766 27.8678L43.1274 26.4136V26.0389L45.5766 27.4932V27.8678Z"
        fill="#373C3F"
      />
      <path
        d="M45.5766 27.4927L43.1274 26.0385L43.4141 25.8734L45.8632 27.3276L45.5766 27.4927Z"
        fill="#505453"
      />
      <path
        d="M45.8633 27.7218L45.5767 27.887V27.4932L45.8633 27.3281V27.7218Z"
        fill="#1C2225"
      />
      <path
        d="M46.4862 27.3283L44.0371 25.8804V25.5057L46.4862 26.96V27.3283Z"
        fill="#373C3F"
      />
      <path
        d="M46.4862 26.9595L44.0371 25.5053L44.3238 25.3402L46.7729 26.7881L46.4862 26.9595Z"
        fill="#505453"
      />
      <path
        d="M46.773 27.1882L46.4863 27.3533V26.9595L46.773 26.7881V27.1882Z"
        fill="#1C2225"
      />
      <path
        d="M47.3959 26.7959L44.9468 25.3417V24.967L47.3959 26.4213V26.7959Z"
        fill="#373C3F"
      />
      <path
        d="M47.3959 26.4208L44.9468 24.9666L45.2334 24.8015L47.6826 26.2557L47.3959 26.4208Z"
        fill="#505453"
      />
      <path
        d="M47.6827 26.6497L47.396 26.8148V26.4211L47.6827 26.256V26.6497Z"
        fill="#1C2225"
      />
      <path
        d="M48.3061 26.2619L45.8569 24.8077V24.433L48.3061 25.8872V26.2619Z"
        fill="#373C3F"
      />
      <path
        d="M48.3061 25.8868L45.8569 24.4326L46.1436 24.2675L48.5927 25.7153L48.3061 25.8868Z"
        fill="#505453"
      />
      <path
        d="M48.5928 26.1153L48.3062 26.2804V25.8867L48.5928 25.7152V26.1153Z"
        fill="#1C2225"
      />
      <path
        d="M49.2157 25.7224L46.7666 24.2682V23.8936L49.2157 25.3478V25.7224Z"
        fill="#373C3F"
      />
      <path
        d="M49.2157 25.3475L46.7666 23.8933L47.0533 23.7281L49.5024 25.1824L49.2157 25.3475Z"
        fill="#505453"
      />
      <path
        d="M49.5025 25.576L49.2158 25.7411V25.3474L49.5025 25.1823V25.576Z"
        fill="#1C2225"
      />
      <path
        d="M50.1321 25.1892L47.6768 23.735V23.3604L50.1321 24.8146V25.1892Z"
        fill="#373C3F"
      />
      <path
        d="M50.1321 24.8143L47.6768 23.3601L47.9634 23.1949L50.4125 24.6492L50.1321 24.8143Z"
        fill="#505453"
      />
      <path
        d="M50.4123 25.0428L50.1318 25.2079V24.8142L50.4123 24.649V25.0428Z"
        fill="#1C2225"
      />
      <path
        d="M51.0418 24.6504L48.5864 23.1962V22.8215L51.0418 24.2758V24.6504Z"
        fill="#373C3F"
      />
      <path
        d="M51.0418 24.2753L48.5864 22.8211L48.8731 22.656L51.3222 24.1102L51.0418 24.2753Z"
        fill="#505453"
      />
      <path
        d="M51.3219 24.5039L51.0415 24.6691V24.2753L51.3219 24.1102V24.5039Z"
        fill="#1C2225"
      />
      <path
        d="M51.9514 24.1162L49.4961 22.662V22.2874L51.9514 23.7416V24.1162Z"
        fill="#373C3F"
      />
      <path
        d="M51.9514 23.7412L49.4961 22.2869L49.7828 22.1218L52.2319 23.576L51.9514 23.7412Z"
        fill="#505453"
      />
      <path
        d="M52.2316 23.9698L51.9512 24.1349V23.7412L52.2316 23.576V23.9698Z"
        fill="#1C2225"
      />
      <path
        d="M52.8615 23.5758L50.4062 22.1216V21.7533L52.8615 23.2012V23.5758Z"
        fill="#373C3F"
      />
      <path
        d="M52.8615 23.2011L50.4062 21.7532L50.6929 21.5818L53.142 23.036L52.8615 23.2011Z"
        fill="#505453"
      />
      <path
        d="M53.1417 23.4299L52.8613 23.595V23.2012L53.1417 23.0361V23.4299Z"
        fill="#1C2225"
      />
      <path
        d="M39.8311 31.8628L37.382 30.4149L37.1514 30.4467L39.6068 31.9009L39.8311 31.8628Z"
        fill="#373C3F"
      />
      <path
        d="M39.5258 32.1487L37.0767 30.6944L37.1514 30.4468L39.6068 31.901L39.5258 32.1487Z"
        fill="#505453"
      />
      <path
        d="M39.8312 31.8625L39.7315 32.1102L39.5259 32.1483L39.6069 31.9007L39.8312 31.8625Z"
        fill="#1C2225"
      />
      <path
        d="M40.2612 31.2411L37.8058 29.7932L37.6625 29.5709L40.1178 31.0251L40.2612 31.2411Z"
        fill="#373C3F"
      />
      <path
        d="M40.1178 31.0244L37.6625 29.5702L37.918 29.2908L40.3733 30.745L40.1178 31.0244Z"
        fill="#505453"
      />
      <path
        d="M40.5164 30.9673L40.2609 31.2403L40.1176 31.0244L40.3731 30.745L40.5164 30.9673Z"
        fill="#1C2225"
      />
      <path
        d="M39.5381 32.6692L37.0827 31.2213L36.8334 31.3039L39.2826 32.7517L39.5381 32.6692Z"
        fill="#373C3F"
      />
      <path
        d="M39.2514 33.0504L36.8022 31.5962L36.8334 31.3041L39.2825 32.752L39.2514 33.0504Z"
        fill="#505453"
      />
      <path
        d="M39.5381 32.6688L39.4883 32.9546L39.2515 33.0498L39.2826 32.7514L39.5381 32.6688Z"
        fill="#1C2225"
      />
      <path
        d="M40.2798 34.6569L37.6874 33.1582L37.3135 33.4313L39.9059 34.968L40.2798 34.6569Z"
        fill="#373C3F"
      />
      <path
        d="M40.2797 34.6575L40.5975 34.7527L40.1987 35.0956L39.9058 34.9686L40.2797 34.6575Z"
        fill="#1C2225"
      />
      <path
        d="M39.5196 33.767L37.0704 32.3127L36.8149 32.3953L39.2703 33.8495L39.5196 33.767Z"
        fill="#373C3F"
      />
      <path
        d="M39.4261 34.2498L36.977 32.7956L36.8149 32.3955L39.2703 33.8497L39.4261 34.2498Z"
        fill="#505453"
      />
      <path
        d="M39.5193 33.7673L39.6626 34.1547L39.4258 34.25L39.27 33.8499L39.5193 33.7673Z"
        fill="#1C2225"
      />
      <path
        d="M53.4851 24.7883C53.4851 25.163 53.2233 25.6202 52.8993 25.8043C52.5752 25.9885 52.3135 25.8424 52.3135 25.4741C52.3135 25.1058 52.5752 24.6422 52.8993 24.4581C53.2233 24.2676 53.4851 24.42 53.4851 24.7883Z"
        fill="#E0B350"
      />
      <path
        d="M53.3914 24.845C53.3914 25.1562 53.1733 25.5435 52.8991 25.7023C52.6311 25.861 52.4067 25.734 52.4067 25.4229C52.4067 25.1117 52.6249 24.7243 52.8991 24.5656C53.167 24.4068 53.3914 24.5338 53.3914 24.845Z"
        fill="#A85D00"
      />
      <path
        d="M53.2546 24.9213C53.2546 25.1499 53.0926 25.4357 52.8932 25.55C52.6938 25.6643 52.5317 25.5754 52.5317 25.3468C52.5317 25.1181 52.6938 24.8324 52.8932 24.7181C53.0926 24.6038 53.2546 24.6927 53.2546 24.9213Z"
        fill="#CD8B19"
      />
      <path
        d="M52.9929 24.6747C52.9617 24.681 52.9243 24.6937 52.8932 24.7128C52.6938 24.8271 52.5317 25.1129 52.5317 25.3415C52.5317 25.405 52.5442 25.4558 52.5629 25.4939L52.8932 25.424C53.0241 25.3097 53.0365 25.1827 53.1113 25.0176L52.9929 24.6683V24.6747Z"
        fill="#B86A0B"
      />
      <path
        d="M53.0053 25.2836C53.0303 25.2392 53.049 25.1884 53.0677 25.1375C52.9306 25.0804 52.7997 25.0105 52.6875 24.9089C52.6252 24.9978 52.5816 25.0931 52.5566 25.1947C52.7124 25.1947 52.862 25.2265 53.0053 25.2836Z"
        fill="#DE9B16"
      />
      <path
        d="M53.2547 25.0873C53.2547 25.2524 53.1425 25.4492 52.9992 25.5318C52.8558 25.6144 52.7437 25.5509 52.7437 25.3857C52.7437 25.2206 52.8558 25.0238 52.9992 24.9412C53.1425 24.8587 53.2547 24.9222 53.2547 25.0873Z"
        fill="#B86A0B"
      />
      <path
        d="M53.1736 25.1889C53.1736 25.2778 53.1113 25.3858 53.0365 25.4302C52.9617 25.4747 52.8994 25.4429 52.8994 25.354C52.8994 25.2651 52.9617 25.1572 53.0365 25.1127C53.1113 25.0683 53.1736 25.1 53.1736 25.1889Z"
        fill="#E0981B"
      />
      <path
        d="M41.9001 31.6651C41.9001 32.0398 41.6384 32.497 41.3143 32.6812C40.9903 32.8717 40.7285 32.7193 40.7285 32.351C40.7285 31.9826 40.9903 31.5191 41.3143 31.3349C41.6384 31.1444 41.9001 31.2968 41.9001 31.6651Z"
        fill="#E0B350"
      />
      <path
        d="M41.8064 31.716C41.8064 32.0271 41.5883 32.4145 41.3141 32.5733C41.0399 32.732 40.8218 32.605 40.8218 32.2938C40.8218 31.9827 41.0399 31.5953 41.3141 31.4365C41.5883 31.2778 41.8064 31.4048 41.8064 31.716Z"
        fill="#A85D00"
      />
      <path
        d="M41.6697 31.7923C41.6697 32.0209 41.5076 32.3066 41.3082 32.4209C41.1088 32.5352 40.9468 32.4463 40.9468 32.2177C40.9468 31.9891 41.1088 31.7034 41.3082 31.589C41.5076 31.4747 41.6697 31.5636 41.6697 31.7923Z"
        fill="#CD8B19"
      />
      <path
        d="M41.4079 31.5448C41.3768 31.5512 41.3394 31.5639 41.3082 31.5829C41.1088 31.6972 40.9468 31.983 40.9468 32.2116C40.9468 32.2751 40.9592 32.3259 40.9779 32.364L41.3082 32.2941C41.4391 32.1798 41.4516 32.0528 41.5263 31.8877L41.4079 31.5385V31.5448Z"
        fill="#B86A0B"
      />
      <path
        d="M41.4204 32.1604C41.4453 32.116 41.464 32.0652 41.4827 32.0144C41.3456 31.9572 41.2147 31.8874 41.1025 31.7858C41.0402 31.8747 40.9966 31.9699 40.9717 32.0715C41.1275 32.0715 41.277 32.1033 41.4204 32.1604Z"
        fill="#DE9B16"
      />
      <path
        d="M41.6697 31.964C41.6697 32.1291 41.5575 32.326 41.4142 32.4085C41.2709 32.4911 41.1587 32.4276 41.1587 32.2625C41.1587 32.0973 41.2709 31.9005 41.4142 31.8179C41.5575 31.7354 41.6697 31.7989 41.6697 31.964Z"
        fill="#B86A0B"
      />
      <path
        d="M41.5945 32.0581C41.5945 32.147 41.5322 32.2549 41.4574 32.2994C41.3826 32.3438 41.3203 32.3121 41.3203 32.2232C41.3203 32.1343 41.3826 32.0263 41.4574 31.9819C41.5322 31.9374 41.5945 31.9692 41.5945 32.0581Z"
        fill="#E0981B"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M53.4725 24.7114C53.4351 24.7114 53.3977 24.7241 53.3603 24.7432L41.364 31.8111C40.7969 32.1476 40.4106 32.7192 40.2922 33.3669C40.2548 33.3161 40.2485 33.2653 40.2485 33.2335C40.2485 32.427 40.6723 31.6714 41.364 31.2713L53.3603 24.2034C53.3977 24.178 53.4351 24.1716 53.4725 24.1716C53.5473 24.1716 53.7031 24.2288 53.7031 24.4129C53.7031 24.5527 53.6844 24.686 53.6595 24.8257C53.6096 24.7495 53.5161 24.7241 53.4663 24.7241L53.4725 24.7114Z"
          fill="#8C7900"
        />
      </g>
      <path
        d="M40.3483 25.6146V29.8376L31.4492 24.5605C31.094 24.3509 30.8696 23.9572 30.8696 23.5381V21.0932C30.8696 20.6042 31.3869 20.2994 31.8044 20.5471L40.3483 25.6083V25.6146Z"
        fill="#FFAA00"
      />
      <path
        d="M57.8222 15.7459L57.9094 15.8412L57.0245 15.8158L44.0747 23.4679L35.4935 18.3813C35.4935 18.3813 35.1756 18.2289 34.8267 18.4702L35.1756 18.1908L47.5272 10.8943C48.2376 10.4752 49.1225 10.4752 49.833 10.8943L57.2862 15.3078C57.4857 15.4284 57.6664 15.5745 57.8222 15.7396V15.7459Z"
        fill="#FFAA00"
      />
      <path
        d="M49.7212 11.6316L49.8458 11.7078V11.2124L49.7212 11.1362V11.6316Z"
        fill="#C27100"
      />
      <path
        d="M49.8457 11.707L49.9516 11.6434V11.0275L49.8457 11.0846V11.707Z"
        fill="#DA7F00"
      />
      <path
        d="M48.8921 11.1428L48.9918 11.0857L48.998 10.5078L48.8921 10.5713V11.1428Z"
        fill="#DA7F00"
      />
      <path
        d="M48.7676 11.0722L48.8922 11.142V10.5324L48.7676 10.4625V11.0722Z"
        fill="#C27100"
      />
      <path
        d="M48.7739 10.4626L49.8458 11.0849L49.9517 11.0277L48.8799 10.399L48.7739 10.4626Z"
        fill="#DA7F00"
      />
      <path
        d="M48.7676 10.4625V10.5832L49.8457 11.2119V11.0849L48.7676 10.4625Z"
        fill="#C27100"
      />
      <path
        d="M50.7061 12.2028L50.8307 12.2727V11.7774L50.7061 11.7075V12.2028Z"
        fill="#C27100"
      />
      <path
        d="M50.8306 12.2725L50.9365 12.2153V11.593L50.8306 11.6565V12.2725Z"
        fill="#DA7F00"
      />
      <path
        d="M49.877 11.7141L49.9829 11.6506V11.0791L49.877 11.1363V11.7141Z"
        fill="#DA7F00"
      />
      <path
        d="M49.7524 11.6377L49.8771 11.7139V11.0979L49.7524 11.0281V11.6377Z"
        fill="#C27100"
      />
      <path
        d="M49.7589 11.028L50.8308 11.6567L50.9367 11.5932L49.8649 10.9645L49.7589 11.028Z"
        fill="#DA7F00"
      />
      <path
        d="M49.7524 11.0281V11.1487L50.8305 11.7774V11.6568L49.7524 11.0281Z"
        fill="#C27100"
      />
      <path
        d="M51.6904 12.769L51.8151 12.8452V12.3499L51.6904 12.2737V12.769Z"
        fill="#C27100"
      />
      <path
        d="M51.8149 12.8444L51.9209 12.7809V12.1649L51.8149 12.2221V12.8444Z"
        fill="#DA7F00"
      />
      <path
        d="M50.8613 12.2866L50.9673 12.2231V11.6453L50.8613 11.7088V12.2866Z"
        fill="#DA7F00"
      />
      <path
        d="M50.7368 12.2092L50.8615 12.2854V11.6695L50.7368 11.5933V12.2092Z"
        fill="#C27100"
      />
      <path
        d="M50.7432 11.5937L51.815 12.2223L51.921 12.1652L50.8491 11.5365L50.7432 11.5937Z"
        fill="#DA7F00"
      />
      <path
        d="M50.7368 11.5933V11.7203L51.8149 12.3489V12.2219L50.7368 11.5933Z"
        fill="#C27100"
      />
      <path
        d="M52.6753 13.3399L52.7999 13.4098V12.9145L52.6753 12.8383V13.3399Z"
        fill="#C27100"
      />
      <path
        d="M52.7998 13.409L52.9057 13.3518V12.7295L52.7998 12.793V13.409Z"
        fill="#DA7F00"
      />
      <path
        d="M51.8462 12.8511L51.9521 12.7939V12.2097L51.8462 12.2732V12.8511Z"
        fill="#DA7F00"
      />
      <path
        d="M51.7217 12.7802L51.8463 12.85V12.234L51.7217 12.1578V12.7802Z"
        fill="#C27100"
      />
      <path
        d="M51.7281 12.1646L52.8 12.7933L52.906 12.7298L51.8341 12.1011L51.7281 12.1646Z"
        fill="#DA7F00"
      />
      <path
        d="M51.7217 12.1578V12.2848L52.7998 12.9135V12.7929L51.7217 12.1578Z"
        fill="#C27100"
      />
      <path
        d="M53.6597 13.9056L53.7843 13.9818V13.4801L53.6659 13.4103L53.6597 13.9056Z"
        fill="#C27100"
      />
      <path
        d="M53.7842 13.9816L53.8901 13.9181V13.2958L53.7842 13.3593V13.9816Z"
        fill="#DA7F00"
      />
      <path
        d="M52.8306 13.4232L52.9365 13.3661V12.7819L52.8306 12.839V13.4232Z"
        fill="#DA7F00"
      />
      <path
        d="M52.7061 13.3532L52.8307 13.423V12.8007L52.7123 12.7308L52.7061 13.3532Z"
        fill="#C27100"
      />
      <path
        d="M52.7124 12.7309L53.7843 13.3595L53.8902 13.296L52.8183 12.6674L52.7124 12.7309Z"
        fill="#DA7F00"
      />
      <path
        d="M52.7124 12.7308V12.8515L53.7843 13.4802V13.3595L52.7124 12.7308Z"
        fill="#C27100"
      />
      <path
        d="M54.6445 14.4764L54.7692 14.5463V14.0509L54.6508 13.9747L54.6445 14.4764Z"
        fill="#C27100"
      />
      <path
        d="M54.769 14.5455L54.875 14.4883V13.866L54.769 13.9231V14.5455Z"
        fill="#DA7F00"
      />
      <path
        d="M53.8154 13.9939L53.9214 13.9304V13.3462L53.8154 13.4033V13.9939Z"
        fill="#DA7F00"
      />
      <path
        d="M53.6909 13.923L53.8156 13.9928V13.3705L53.6971 13.2943L53.6909 13.923Z"
        fill="#C27100"
      />
      <path
        d="M53.6974 13.2948L54.7693 13.9235L54.8752 13.8664L53.8033 13.2377L53.6974 13.2948Z"
        fill="#DA7F00"
      />
      <path
        d="M53.6973 13.2943V13.415L54.7691 14.05V13.923L53.6973 13.2943Z"
        fill="#C27100"
      />
      <path
        d="M55.6353 15.0421L55.7537 15.1183V14.6166L55.6353 14.5468V15.0421Z"
        fill="#C27100"
      />
      <path
        d="M55.7534 15.1181L55.8594 15.0546V14.4323L55.7534 14.4958V15.1181Z"
        fill="#DA7F00"
      />
      <path
        d="M54.8003 14.5667L54.9062 14.5032V13.9126L54.8003 13.9761V14.5667Z"
        fill="#DA7F00"
      />
      <path
        d="M54.6816 14.4893L54.8 14.5655V13.9368L54.6816 13.8606V14.4893Z"
        fill="#C27100"
      />
      <path
        d="M54.6816 13.861L55.7535 14.496L55.8595 14.4325L54.7876 13.8038L54.6816 13.861Z"
        fill="#DA7F00"
      />
      <path
        d="M54.6816 13.8606V13.9876L55.7535 14.6163V14.4956L54.6816 13.8606Z"
        fill="#C27100"
      />
      <path
        d="M56.6196 15.6129L56.738 15.6827V15.1874L56.6196 15.1112V15.6129Z"
        fill="#C27100"
      />
      <path
        d="M56.7378 15.6829L56.8437 15.6257V15.0034L56.7378 15.0606V15.6829Z"
        fill="#DA7F00"
      />
      <path
        d="M55.7847 15.1374L55.8906 15.0739V14.4769L55.7847 14.5404V15.1374Z"
        fill="#DA7F00"
      />
      <path
        d="M55.666 15.0604L55.7844 15.1366V14.5016L55.666 14.4318V15.0604Z"
        fill="#C27100"
      />
      <path
        d="M55.666 14.4319L56.7379 15.0606L56.8438 15.0034L55.772 14.3684L55.666 14.4319Z"
        fill="#DA7F00"
      />
      <path
        d="M55.666 14.4318V14.5524L56.7379 15.1875V15.0604L55.666 14.4318Z"
        fill="#C27100"
      />
      <path
        d="M55.9155 16.179L55.7909 16.2552V15.7599L55.9155 15.6837V16.179Z"
        fill="#C27100"
      />
      <path
        d="M55.7909 16.2544L55.6849 16.1909V15.575L55.7909 15.6321V16.2544Z"
        fill="#DA7F00"
      />
      <path
        d="M56.7445 15.6903L56.6448 15.6268L56.6385 15.0553L56.7445 15.1188V15.6903Z"
        fill="#DA7F00"
      />
      <path
        d="M56.8691 15.6129L56.7445 15.6891V15.0795L56.8691 15.0033V15.6129Z"
        fill="#C27100"
      />
      <path
        d="M56.8628 15.0099L55.7909 15.6322L55.6849 15.5751L56.7568 14.9464L56.8628 15.0099Z"
        fill="#DA7F00"
      />
      <path
        d="M56.869 15.0033V15.1303L55.7909 15.759V15.632L56.869 15.0033Z"
        fill="#C27100"
      />
      <path
        d="M54.9186 16.7569L54.7939 16.8268V16.3315L54.9186 16.2616V16.7569Z"
        fill="#C27100"
      />
      <path
        d="M54.7939 16.8266L54.6942 16.7694L54.688 16.1471L54.7939 16.2106V16.8266Z"
        fill="#DA7F00"
      />
      <path
        d="M55.7538 16.2682L55.6478 16.2047L55.6416 15.6332L55.7475 15.6903L55.7538 16.2682Z"
        fill="#DA7F00"
      />
      <path
        d="M55.8722 16.1918L55.7538 16.268L55.7476 15.652L55.8722 15.5822V16.1918Z"
        fill="#C27100"
      />
      <path
        d="M55.8658 15.5822L54.7939 16.2109L54.688 16.1474L55.7599 15.5187L55.8658 15.5822Z"
        fill="#DA7F00"
      />
      <path
        d="M55.8721 15.5822V15.7028L54.7939 16.3315V16.2108L55.8721 15.5822Z"
        fill="#C27100"
      />
      <path
        d="M53.9215 17.3278L53.8031 17.404L53.7969 16.9087L53.9215 16.8325V17.3278Z"
        fill="#C27100"
      />
      <path
        d="M53.8031 17.4034L53.6971 17.3399L53.6909 16.7239L53.7969 16.781L53.8031 17.4034Z"
        fill="#DA7F00"
      />
      <path
        d="M54.7563 16.8391L54.6504 16.782V16.2041L54.7501 16.2676L54.7563 16.8391Z"
        fill="#DA7F00"
      />
      <path
        d="M54.8751 16.7681L54.7567 16.8379L54.7505 16.2283L54.8751 16.1521V16.7681Z"
        fill="#C27100"
      />
      <path
        d="M54.875 16.1527L53.7969 16.7814L53.6909 16.7243L54.769 16.0956L54.875 16.1527Z"
        fill="#DA7F00"
      />
      <path
        d="M54.875 16.1521V16.2791L53.7969 16.9078V16.7808L54.875 16.1521Z"
        fill="#C27100"
      />
      <path
        d="M52.924 17.9063L52.8055 17.9762L52.7993 17.4809L52.924 17.4047V17.9063Z"
        fill="#C27100"
      />
      <path
        d="M52.8056 17.9754L52.6997 17.9119V17.2959L52.7994 17.3594L52.8056 17.9754Z"
        fill="#DA7F00"
      />
      <path
        d="M53.7593 17.4175L53.6533 17.354V16.7761L53.7593 16.8396V17.4175Z"
        fill="#DA7F00"
      />
      <path
        d="M53.8777 17.3466L53.7593 17.4164V16.8004L53.8777 16.7242V17.3466Z"
        fill="#C27100"
      />
      <path
        d="M53.8775 16.7307L52.8056 17.3594L52.6997 17.2959L53.7716 16.6672L53.8775 16.7307Z"
        fill="#DA7F00"
      />
      <path
        d="M53.8774 16.7242V16.8512L52.7993 17.4799L52.8055 17.3593L53.8774 16.7242Z"
        fill="#C27100"
      />
      <path
        d="M51.9275 18.4768L51.8091 18.553V18.0513L51.9275 17.9814V18.4768Z"
        fill="#C27100"
      />
      <path
        d="M51.8091 18.5528L51.7031 18.4893V17.8669L51.8091 17.9304V18.5528Z"
        fill="#DA7F00"
      />
      <path
        d="M52.7627 17.9944L52.6567 17.9309V17.353L52.7627 17.4102V17.9944Z"
        fill="#DA7F00"
      />
      <path
        d="M52.8811 17.918L52.7627 17.9942V17.3719L52.8811 17.302V17.918Z"
        fill="#C27100"
      />
      <path
        d="M52.8809 17.302L51.8091 17.9307L51.7031 17.8672L52.775 17.2385L52.8809 17.302Z"
        fill="#DA7F00"
      />
      <path
        d="M52.881 17.302V17.4227L51.8091 18.0513V17.9307L52.881 17.302Z"
        fill="#C27100"
      />
      <path
        d="M50.9299 19.0488L50.8115 19.125V18.6297L50.9299 18.5535V19.0488Z"
        fill="#C27100"
      />
      <path
        d="M50.8115 19.1242L50.7056 19.0607V18.4447L50.8115 18.5019V19.1242Z"
        fill="#DA7F00"
      />
      <path
        d="M51.7651 18.5664L51.6592 18.5093V17.925L51.7651 17.9886V18.5664Z"
        fill="#DA7F00"
      />
      <path
        d="M51.8835 18.4954L51.7651 18.5652V17.9493L51.8835 17.873V18.4954Z"
        fill="#C27100"
      />
      <path
        d="M51.8834 17.8736L50.8115 18.5022L50.7056 18.4451L51.7774 17.8164L51.8834 17.8736Z"
        fill="#DA7F00"
      />
      <path
        d="M51.8834 17.873V18.0001L50.8115 18.6287V18.5017L51.8834 17.873Z"
        fill="#C27100"
      />
      <path
        d="M49.9391 19.6266L49.8145 19.6964V19.2011L49.9329 19.1312L49.9391 19.6266Z"
        fill="#C27100"
      />
      <path
        d="M49.8144 19.6962L49.7085 19.6391V19.0167L49.8144 19.0802V19.6962Z"
        fill="#DA7F00"
      />
      <path
        d="M50.768 19.1448L50.6621 19.0876V18.4971L50.768 18.5606V19.1448Z"
        fill="#DA7F00"
      />
      <path
        d="M50.8927 19.0738L50.7681 19.1436V18.5213L50.8865 18.4451L50.8927 19.0738Z"
        fill="#C27100"
      />
      <path
        d="M50.8863 18.4518L49.8144 19.0805L49.7085 19.017L50.7804 18.3883L50.8863 18.4518Z"
        fill="#DA7F00"
      />
      <path
        d="M50.8863 18.4451V18.5721L49.8145 19.2008V19.0801L50.8863 18.4451Z"
        fill="#C27100"
      />
      <path
        d="M48.942 20.1976L48.8174 20.2738V19.7785L48.9358 19.7023L48.942 20.1976Z"
        fill="#C27100"
      />
      <path
        d="M48.8174 20.274L48.7114 20.2105V19.5945L48.8174 19.6516V20.274Z"
        fill="#DA7F00"
      />
      <path
        d="M49.771 19.7216L49.665 19.6581V19.0739L49.771 19.131V19.7216Z"
        fill="#DA7F00"
      />
      <path
        d="M49.8956 19.6452L49.771 19.7214V19.0927L49.8894 19.0228L49.8956 19.6452Z"
        fill="#C27100"
      />
      <path
        d="M49.8892 19.0229L48.8174 19.6515L48.7114 19.5944L49.7833 18.9594L49.8892 19.0229Z"
        fill="#DA7F00"
      />
      <path
        d="M49.8893 19.0228V19.1435L48.8174 19.7785V19.6515L49.8893 19.0228Z"
        fill="#C27100"
      />
      <path
        d="M47.945 20.7771L47.8203 20.8469V20.3516L47.945 20.2754V20.7771Z"
        fill="#C27100"
      />
      <path
        d="M47.8203 20.8461L47.7144 20.789V20.1666L47.8203 20.2238V20.8461Z"
        fill="#DA7F00"
      />
      <path
        d="M48.7739 20.3009L48.668 20.2374V19.6469L48.7739 19.7104V20.3009Z"
        fill="#DA7F00"
      />
      <path
        d="M48.8986 20.2237L48.7739 20.2999V19.6712L48.8986 19.595V20.2237Z"
        fill="#C27100"
      />
      <path
        d="M48.8922 19.5954L47.8203 20.224L47.7144 20.1669L48.7862 19.5382L48.8922 19.5954Z"
        fill="#DA7F00"
      />
      <path
        d="M48.8984 19.595V19.722L47.8203 20.3507V20.2237L48.8984 19.595Z"
        fill="#C27100"
      />
      <path
        d="M46.9479 21.3484L46.8232 21.4246V20.9229L46.9479 20.853V21.3484Z"
        fill="#C27100"
      />
      <path
        d="M46.8232 21.4244L46.7173 21.3609V20.7385L46.8232 20.802V21.4244Z"
        fill="#DA7F00"
      />
      <path
        d="M47.7768 20.873L47.6709 20.8158V20.2189L47.7768 20.2824V20.873Z"
        fill="#DA7F00"
      />
      <path
        d="M47.9015 20.8019L47.7769 20.8718V20.2431L47.9015 20.1669V20.8019Z"
        fill="#C27100"
      />
      <path
        d="M47.8951 20.1736L46.8232 20.8023L46.7173 20.7388L47.7892 20.1101L47.8951 20.1736Z"
        fill="#DA7F00"
      />
      <path
        d="M47.9014 20.1669V20.2939L46.8232 20.9226V20.8019L47.9014 20.1669Z"
        fill="#C27100"
      />
      <path
        d="M45.9508 21.9249L45.8262 21.9947V21.4994L45.9508 21.4232V21.9249Z"
        fill="#C27100"
      />
      <path
        d="M45.8262 21.9949L45.7202 21.9378V21.3154L45.8262 21.3726V21.9949Z"
        fill="#DA7F00"
      />
      <path
        d="M46.7798 21.4489L46.6801 21.3854L46.6738 20.7948L46.7798 20.852V21.4489Z"
        fill="#DA7F00"
      />
      <path
        d="M46.9044 21.3725L46.7798 21.4487V20.8136L46.9044 20.7438V21.3725Z"
        fill="#C27100"
      />
      <path
        d="M46.898 20.7438L45.8262 21.3725L45.7202 21.3153L46.7921 20.6803L46.898 20.7438Z"
        fill="#DA7F00"
      />
      <path
        d="M46.9043 20.7438V20.8644L45.8262 21.4995V21.3725L46.9043 20.7438Z"
        fill="#C27100"
      />
      <path
        d="M50.7306 12.0374L50.8178 12.1327L49.9329 12.1073L36.9831 19.7594L34.4031 18.1782C34.4031 18.1782 34.0853 18.0258 33.7363 18.2671L34.0853 17.9877L46.4368 10.6912C47.1473 10.2721 48.0322 10.2721 48.7426 10.6912L50.1946 11.6056C50.3941 11.7263 50.5748 11.8723 50.7306 12.0374Z"
        fill="#F2C91F"
      />
      <path
        d="M34.266 21.398L34.1102 23.7604L30.8696 21.8299V20.9408C30.8696 20.4518 31.2872 20.0581 31.7608 20.1153H31.7982C32.4151 20.2105 34.2598 21.398 34.2598 21.398H34.266Z"
        fill="#505453"
      />
      <path
        d="M33.8047 22.0199C33.8047 22.0199 31.9601 20.8324 31.3431 20.7435C31.3306 20.7435 31.3182 20.7435 31.3057 20.7435C31.1562 20.7245 31.0128 20.7562 30.8882 20.8197C30.9567 20.3943 31.3306 20.0767 31.7669 20.1275H31.8043C32.4212 20.2228 34.2658 21.4103 34.2658 21.4103L34.1101 23.7726L33.705 23.5313L33.8047 22.0326V22.0199Z"
        fill="#747776"
      />
      <path
        d="M18.1316 30.778L18.8545 30.2128C18.8545 30.2128 20.0635 29.6222 21.0045 29.1459C21.9455 28.676 24.1765 25.812 24.1765 25.812L24.8932 26.1549C24.8932 26.1549 24.4632 27.1773 23.9023 28.4601C24.7374 27.5456 25.5164 26.5359 25.5164 26.5359L26.233 26.8789C26.233 26.8789 25.909 27.6409 25.4603 28.676C26.1209 27.914 26.6693 27.2154 26.6693 27.2154L27.3859 27.5583C27.3859 27.5583 25.2858 32.537 24.351 34.296C23.4162 36.0551 23.2417 36.6647 23.2417 36.6647L19.4777 34.5056L17.3651 32.5561L18.1254 30.7653L18.1316 30.778Z"
        fill="#BA6300"
      />
      <path
        d="M23.2043 36.6579C23.3352 36.3213 23.5408 35.3307 24.1453 34.1939C24.9555 32.6699 26.6692 28.6501 27.0805 27.6849L26.7378 27.5198C26.5446 27.7674 26.1208 28.2881 25.641 28.8406L25.2484 28.5866C25.5537 27.8754 25.803 27.2975 25.9276 26.999L25.5849 26.8339C25.3356 27.1514 24.7187 27.9135 24.0768 28.6184L23.6904 28.358C24.0955 27.4245 24.4382 26.6307 24.5878 26.2687L24.245 26.1036C23.7652 26.7069 21.9704 28.9232 21.1104 29.355C20.2442 29.7932 19.1474 30.3266 18.9791 30.4092L18.3248 30.9235L17.6517 32.5048L19.7082 34.6448L23.2043 36.6579Z"
        fill="#823600"
      />
      <path
        d="M18.1316 30.7771L21.8894 32.9362L21.1291 34.727L17.3651 32.5679L18.1316 30.7771Z"
        fill="#C26700"
      />
      <path
        d="M20.5246 11.2368H15.3958C15.3958 11.2368 13.5948 11.9163 15.3834 13.669C16.4739 14.7422 17.1532 15.0216 17.7078 15.1613C18.2625 15.301 26.6007 15.1232 27.9218 15.4979C29.2368 15.8789 31.742 18.019 32.6705 21.0608C33.5991 24.1026 33.2999 25.6076 33.2999 25.6076L36.1915 27.3158L37.6061 24.1026L34.914 18.8826C34.914 18.8826 35.2256 14.2342 34.9451 13.8786C34.8766 13.796 32.3901 12.4243 32.2281 12.2973C31.6983 11.8782 30.5455 11.3194 28.0963 11.3257C24.8869 11.3257 20.5246 11.2305 20.5246 11.2305V11.2368Z"
        fill="#7E1F00"
      />
      <path
        d="M30.8132 17.2128C32.1281 17.5938 34.6333 19.7339 35.5619 22.7757C36.4904 25.8175 36.1913 27.3225 36.1913 27.3225L33.2997 25.6143C33.2997 25.6143 33.5988 24.1093 32.6703 21.0675C32.1717 19.4418 31.2245 18.0765 30.2523 17.0922C30.4954 17.1366 30.6885 17.1747 30.8194 17.2128H30.8132Z"
        fill="#9E4700"
      />
      <path
        d="M15.3957 11.2374H20.5245C20.5245 11.2374 24.8868 11.339 28.0962 11.3327C30.5453 11.3327 31.6982 11.8852 32.2279 12.3043C32.3962 12.4376 34.8765 13.803 34.945 13.8855C34.353 13.4918 33.2001 13.0346 30.9878 13.0409C27.7784 13.0409 23.4161 12.9457 23.4161 12.9457H18.2873C18.2873 12.9457 16.3243 14.4126 17.7015 15.1619C17.1469 15.0222 16.4676 14.7428 15.377 13.6696C13.5885 11.9169 15.3895 11.2311 15.3895 11.2311L15.3957 11.2374Z"
        fill="#F8D64B"
      />
      <path
        d="M17.8076 12.564L18.2937 12.945L17.4649 14.9961L15.9755 14.3484L16.8355 12.6338L17.8076 12.564Z"
        fill="#F79900"
      />
      <path
        d="M27.0742 12.6342L22.606 12.3675L21.721 12.0373H17.4024L17.016 11.6182H21.5839L22.3567 11.6055L26.7875 12.0373C26.7875 12.0373 27.7223 12.3167 27.0742 12.6342Z"
        fill="#C56000"
      />
      <path
        d="M28.2208 10.5511L27.8282 10.2399L26.426 10.0685L25.3417 11.4656L27.6911 13.0404L28.2208 10.5511Z"
        fill="#DB8318"
      />
      <path
        d="M28.2208 10.5511L27.8282 10.2399L26.426 10.0685L25.3417 11.4211L25.7405 11.7259L28.2208 10.5511Z"
        fill="#FAB03E"
      />
      <path
        d="M28.9874 11.7262L28.2209 10.5513L26.8249 10.3735L25.7406 11.7262H28.9874Z"
        fill="#E07500"
      />
      <path
        d="M13.9751 19.9568L18.3498 33.0194L20.2256 34.1498L17.6768 17.5627L16.2622 13.8732L16.312 12.9968L16.4865 9.82803L14.6169 8.72942C14.6169 8.72942 13.5451 8.53256 12.8533 10.9584C12.8533 10.9774 12.8409 10.9965 12.8346 11.0155C12.1429 13.5049 13.9751 19.9504 13.9751 19.9504V19.9568Z"
        fill="#F79900"
      />
      <path
        d="M12.8533 10.9647L14.9597 12.203L16.3182 12.9968L16.4927 9.82803L14.6169 8.72942C14.6169 8.72942 13.5451 8.53256 12.8533 10.9584V10.9647Z"
        fill="#F8D64B"
      />
      <path
        d="M21.1293 34.7338L23.2419 36.6833C23.2419 36.6833 24.6877 36.8167 25.6162 35.0577C26.551 33.2986 28.6511 28.3136 28.6511 28.3136L27.9345 27.9707C27.9345 27.9707 26.9498 29.2344 26.015 30.2187C25.8842 30.3648 25.7471 30.4981 25.6224 30.6188C25.2921 30.9363 24.993 31.1903 24.7624 31.3046C24.75 31.3046 24.7437 31.3173 24.7313 31.3237C24.563 31.4126 24.3823 31.5015 24.2016 31.5904C23.4039 31.9905 22.6187 32.3715 22.6187 32.3715L21.8958 32.9367L21.1355 34.7338H21.1293Z"
        fill="#9C4300"
      />
      <path
        d="M29.2744 11.829C29.2744 11.829 29.5548 11.9623 29.4925 12.3878C29.4489 12.6799 29.0874 12.699 29.0874 12.699L22.519 11.8544C22.519 11.8544 22.7683 11.8036 22.8306 11.4416C22.893 11.0669 22.6998 10.9844 22.6998 10.9844L29.2681 11.829H29.2744Z"
        fill="#D17000"
      />
      <path
        d="M22.4817 11.0803C22.3571 11.2327 22.3134 11.5121 22.382 11.7026C22.4505 11.8931 22.6063 11.9249 22.7372 11.7661C22.8618 11.6137 22.9055 11.3343 22.8369 11.1438C22.7684 10.9533 22.6126 10.9215 22.4817 11.0803Z"
        fill="#E0981B"
      />
      <path
        d="M16.094 10.3621L15.8945 10.7621L22.4567 11.6131C22.4567 11.6131 22.6686 11.6385 22.681 11.4162C22.6872 11.2321 22.5688 11.2003 22.5688 11.2003L16.1002 10.3557L16.094 10.3621Z"
        fill="#B2B1AD"
      />
      <path
        d="M14.7106 12.1136C15.4086 9.62428 16.4929 9.82749 16.4929 9.82749L17.8141 12.5645H17.7144C17.7144 12.5645 15.3276 14.4315 17.7268 15.0665C18.3189 15.2253 18.7613 15.2634 19.1477 15.3332L19.316 15.6825L20.9674 32.822L20.232 34.1556L16.8731 21.6708C16.8731 21.6708 14.0189 14.6093 14.7169 12.1263L14.7106 12.1136Z"
        fill="#B85F00"
      />
      <path
        d="M16.7483 14.4825C16.8355 15.0414 16.9664 16.0257 17.2157 16.2733C17.6083 16.6607 19.4155 16.7496 19.4155 16.7496L19.3096 15.6764C19.3096 15.6764 19.3096 15.6447 19.3096 15.6256C19.0105 15.5621 18.6677 15.4859 18.2689 15.3779C17.8825 15.2763 17.6208 15.143 17.4525 14.9906L16.7358 14.4825H16.7483Z"
        fill="#A14B00"
      />
      <path
        d="M23.4163 12.9456H18.2875C18.2875 12.9456 15.8758 14.7427 18.275 15.3777C20.0075 15.835 20.6868 15.8159 21.2414 15.9556C21.796 16.0953 29.4924 16.832 30.8073 17.2066C32.1222 17.5813 34.6274 19.7277 35.556 22.7695C36.4845 25.8113 36.1854 27.3163 36.1854 27.3163L38.0425 25.2207L37.8057 20.5977C37.8057 20.5977 35.7429 14.6919 35.4562 14.3426C35.1696 13.9934 34.1974 13.0345 30.988 13.0408C27.7786 13.0408 23.4163 12.9456 23.4163 12.9456Z"
        fill="#E07500"
      />
      <path
        d="M31.5676 13.8348L31.2373 13.9999L33.9045 19.6517L35.5124 21.8235L31.5676 13.8348Z"
        fill="#9E4700"
      />
      <path
        d="M17.0162 22.2112L15.0843 21.0936L13.6697 20.3633L12.6602 13.0985L14.6357 13.7907L15.0096 21.011L17.0162 22.2112Z"
        fill="#F79900"
      />
      <path
        d="M17.0165 22.211L15.0098 21.0108L14.6172 13.5365L16.2562 18.4453L17.0165 22.211Z"
        fill="#B85F00"
      />
      <path
        d="M37.4507 24.53C37.4507 24.53 37.5068 24.8157 37.1827 24.9809C36.8649 25.1396 36.7839 24.9936 36.7839 24.9936L33.8237 18.9481L34.5217 18.5289L37.4507 24.5363V24.53Z"
        fill="#FFAA00"
      />
      <path
        d="M34.3408 18.4336C34.16 18.4336 33.9357 18.5606 33.836 18.7194C33.7363 18.8781 33.8111 19.0115 33.9918 19.0178C34.1725 19.0178 34.3969 18.8908 34.4966 18.7321C34.59 18.5733 34.5215 18.4399 34.3408 18.4336Z"
        fill="#F8D64B"
      />
      <path
        d="M31.9042 13.6458L31.7048 13.6712L31.5739 13.8363L33.9732 18.7641C33.9732 18.7641 34.0667 18.8467 34.2038 18.7641C34.3222 18.6943 34.3097 18.5736 34.3097 18.5736L31.9042 13.6458Z"
        fill="#B2B1AD"
      />
      <path
        d="M31.8169 13.5946C31.7297 13.5946 31.6237 13.6518 31.5801 13.728C31.5365 13.8042 31.5677 13.8677 31.6549 13.8677C31.7422 13.8677 31.8481 13.8105 31.8917 13.7343C31.9353 13.6581 31.9042 13.5946 31.8169 13.5946Z"
        fill="#D3D2D0"
      />
      <path
        d="M19.9078 13.752C19.9078 14.1775 19.6087 14.6982 19.241 14.9141C18.8733 15.13 18.5742 14.9585 18.5742 14.5331C18.5742 14.1076 18.8733 13.5869 19.241 13.371C19.6087 13.1551 19.9078 13.3265 19.9078 13.752Z"
        fill="#CD6E19"
      />
      <path
        d="M19.428 13.3009C19.3657 13.3136 19.3096 13.339 19.241 13.3771C18.8733 13.593 18.5742 14.1137 18.5742 14.5392C18.5742 14.6535 18.5991 14.7488 18.6365 14.825L19.241 14.698C19.4778 14.4884 19.509 14.2535 19.6461 13.9486L19.428 13.3073V13.3009Z"
        fill="#B84A0B"
      />
      <path
        d="M19.4466 14.4252C19.4903 14.3427 19.5276 14.2538 19.565 14.1522C19.3095 14.0505 19.0727 13.9108 18.8671 13.733C18.7549 13.8918 18.6676 14.0759 18.624 14.2538C18.9107 14.2538 19.1911 14.3173 19.4529 14.4189L19.4466 14.4252Z"
        fill="#DE7D16"
      />
      <path
        d="M19.9077 14.0629C19.9077 14.3677 19.6958 14.736 19.4341 14.8884C19.1723 15.0408 18.9604 14.9201 18.9604 14.6217C18.9604 14.3232 19.1723 13.9485 19.4341 13.7961C19.6958 13.6437 19.9077 13.7644 19.9077 14.0629Z"
        fill="#B84A0B"
      />
      <path
        d="M19.7644 14.2472C19.7644 14.4123 19.6523 14.6092 19.5089 14.6917C19.3656 14.7743 19.2534 14.7108 19.2534 14.5457C19.2534 14.3805 19.3656 14.1837 19.5089 14.1011C19.6523 14.0186 19.7644 14.0821 19.7644 14.2472Z"
        fill="#E0781B"
      />
      <path
        d="M16.7482 11.3777C16.7482 11.8032 16.449 12.3239 16.0814 12.5398C15.7137 12.7557 15.4146 12.5843 15.4146 12.1588C15.4146 11.7333 15.7137 11.2126 16.0814 10.9967C16.449 10.7808 16.7482 10.9522 16.7482 11.3777Z"
        fill="#CD6E19"
      />
      <path
        d="M16.2683 10.9264C16.206 10.9391 16.1499 10.9645 16.0814 11.0026C15.7137 11.2185 15.4146 11.7392 15.4146 12.1647C15.4146 12.279 15.4395 12.3743 15.4769 12.4505L16.0814 12.3235C16.3182 12.1139 16.3493 11.8789 16.4864 11.5741L16.2683 10.9327V10.9264Z"
        fill="#B84A0B"
      />
      <path
        d="M16.287 12.0507C16.3306 11.9682 16.368 11.8792 16.4054 11.7776C16.1499 11.676 15.913 11.5363 15.7074 11.3585C15.5952 11.5173 15.508 11.7014 15.4644 11.8792C15.751 11.8792 16.0315 11.9427 16.2932 12.0444L16.287 12.0507Z"
        fill="#DE7D16"
      />
      <path
        d="M16.748 11.6888C16.748 11.9936 16.5361 12.3619 16.2744 12.5143C16.0127 12.6667 15.8008 12.5461 15.8008 12.2413C15.8008 11.9365 16.0127 11.5681 16.2744 11.4157C16.5361 11.2633 16.748 11.384 16.748 11.6888Z"
        fill="#B84A0B"
      />
      <path
        d="M16.6048 11.8727C16.6048 12.0378 16.4926 12.2346 16.3493 12.3172C16.2059 12.3998 16.0938 12.3363 16.0938 12.1711C16.0938 12.006 16.2059 11.8092 16.3493 11.7266C16.4926 11.6441 16.6048 11.7076 16.6048 11.8727Z"
        fill="#E0781B"
      />
      <path
        d="M23.9149 30.4158C23.9149 30.619 23.7716 30.8667 23.5971 30.9683C23.4226 31.0699 23.2793 30.9873 23.2793 30.7905C23.2793 30.5936 23.4226 30.3396 23.5971 30.238C23.7716 30.1364 23.9149 30.2189 23.9149 30.4158Z"
        fill="#CD6E19"
      />
      <path
        d="M23.6844 30.2002C23.6844 30.2002 23.6283 30.2192 23.5971 30.2383C23.4226 30.3399 23.2793 30.5876 23.2793 30.7908C23.2793 30.8479 23.2918 30.8924 23.3105 30.9241L23.5971 30.8606C23.7093 30.759 23.7218 30.6511 23.7903 30.505L23.6844 30.2002Z"
        fill="#B84A0B"
      />
      <path
        d="M23.6906 30.7328C23.7093 30.6947 23.728 30.6502 23.7467 30.6058C23.6283 30.5613 23.5161 30.4915 23.4164 30.4089C23.3603 30.4851 23.3229 30.5677 23.3042 30.6566C23.4413 30.6566 23.5722 30.6884 23.6968 30.7328H23.6906Z"
        fill="#DE7D16"
      />
      <path
        d="M23.915 30.5618C23.915 30.7079 23.8153 30.8793 23.6907 30.9555C23.566 31.0318 23.4663 30.9682 23.4663 30.8285C23.4663 30.6888 23.566 30.511 23.6907 30.4348C23.8153 30.3586 23.915 30.4221 23.915 30.5618Z"
        fill="#B84A0B"
      />
      <path
        d="M23.8462 30.6508C23.8462 30.727 23.7901 30.8223 23.7278 30.8604C23.6592 30.8985 23.6094 30.8667 23.6094 30.7905C23.6094 30.7143 23.6655 30.6191 23.7278 30.581C23.7963 30.5429 23.8462 30.5746 23.8462 30.6508Z"
        fill="#E0781B"
      />
      <path
        d="M44.9472 17.4283H45.2588V16.2408H44.9472V17.4283Z"
        fill="#272727"
      />
      <path
        d="M44.6421 16.343V16.1906H44.7418C44.7418 16.1906 44.7605 16.1779 44.773 16.1715C44.9412 16.0699 45.2092 16.0699 45.3774 16.1715C45.3899 16.1779 45.4024 16.1906 45.4086 16.1969H45.4959V16.3493C45.4959 16.4129 45.4522 16.4764 45.3712 16.5272C45.203 16.6288 44.935 16.6288 44.7667 16.5272C44.6857 16.4764 44.6421 16.4129 44.6421 16.3493V16.343Z"
        fill="#1D1D1D"
      />
      <path
        d="M44.6421 16.1899L44.7169 15.4342H45.4273L45.4959 16.1772C45.4959 16.247 45.4522 16.3105 45.3712 16.3614C45.203 16.463 44.935 16.463 44.7667 16.3614C44.6857 16.3105 44.6421 16.247 44.6421 16.1835V16.1899Z"
        fill="#C97735"
      />
      <path
        d="M45.3214 16.038C45.1843 15.9554 44.9537 15.9554 44.8166 16.038C44.6795 16.1205 44.6733 16.2539 44.8166 16.3365C44.9537 16.419 45.1843 16.419 45.3214 16.3365C45.4647 16.2539 45.4647 16.1205 45.3214 16.038Z"
        fill="#BC5705"
      />
      <path
        d="M44.885 15.6437C44.9723 15.485 45.1219 15.485 45.2153 15.6437C45.3088 15.8025 45.3088 16.0628 45.2153 16.2216C45.1281 16.3804 44.9785 16.3804 44.885 16.2216C44.7916 16.0628 44.7916 15.8025 44.885 15.6437Z"
        fill="#BD4D00"
      />
      <path
        d="M45.3212 16.3169C45.2526 16.355 45.1716 16.3804 45.0906 16.3868C45.0906 16.3868 45.0844 16.3804 45.0781 16.3741C45.0657 16.355 45.0532 16.3359 45.0345 16.3169C44.9161 16.101 44.9099 15.7581 45.0345 15.5485C45.0657 15.4977 45.0968 15.4596 45.1342 15.4342H45.3648C45.3648 15.4342 45.3773 15.4406 45.3835 15.4469L45.4458 16.1327C45.4458 16.1327 45.4458 16.1327 45.4458 16.1391C45.4458 16.2026 45.4022 16.2661 45.3212 16.3169Z"
        fill="#E07705"
      />
      <path
        d="M45.097 15.7268C45.1655 15.6125 45.2714 15.6125 45.3338 15.7268C45.4023 15.8411 45.4023 16.0317 45.3338 16.146C45.2652 16.2603 45.1593 16.2603 45.097 16.146C45.0346 16.0317 45.0284 15.8411 45.097 15.7268Z"
        fill="#E09C05"
      />
      <path
        d="M45.3214 15.2884C45.1843 15.2058 44.9599 15.2058 44.8228 15.2884C44.6857 15.3709 44.6795 15.5043 44.8228 15.5868C44.9599 15.6694 45.1843 15.6694 45.3214 15.5868C45.4585 15.5043 45.4647 15.3709 45.3214 15.2884Z"
        fill="#EDA94D"
      />
      <path
        d="M44.0747 27.6596L57.6165 19.6646C57.9468 19.4677 58.1525 19.1121 58.1525 18.7247V16.3434C58.1525 15.8036 57.5791 15.467 57.1242 15.7401L44.0809 23.443V27.666L44.0747 27.6596Z"
        fill="#B85F00"
      />
      <path
        d="M36.8647 23.9443L50.4689 15.9112L50.9363 15.9366V12.6217C50.9363 12.0819 50.3629 11.7453 49.908 12.0184L36.8647 19.7213V23.9443Z"
        fill="#E07500"
      />
      <path
        d="M50.936 14.419L40.0054 20.7693V19.296L50.9298 12.933C50.9298 12.933 50.9298 12.9393 50.9298 12.9457V14.419H50.936Z"
        fill="#BAA58E"
      />
      <path
        d="M35.9673 27.2339L36.9332 16.6479L39.9868 14.8571C40.4667 14.5777 41.0151 14.6729 41.3828 14.9968L45.7077 17.5623L41.8003 19.0673V23.811L35.9735 27.2339H35.9673Z"
        fill="#F8D64B"
      />
      <path
        d="M44.3615 17.4545L41.3079 19.2453C41.3079 19.2453 39.3387 21.8362 40.342 29.8312L46.1688 26.4084V18.5213C46.1688 17.5751 45.1655 16.9782 44.3553 17.4545H44.3615Z"
        fill="#B85F00"
      />
      <path
        d="M41.4574 17.8546C41.5446 17.8546 41.6319 17.8292 41.7067 17.7847L42.953 17.0544C43.0154 17.0163 43.059 16.9465 43.059 16.8703C43.059 16.7941 43.0216 16.7242 42.953 16.6861L40.7532 15.3907C40.6784 15.3462 40.5912 15.3208 40.5039 15.3208C40.4167 15.3208 40.3294 15.3462 40.2547 15.3907L39.0083 16.1209C38.946 16.159 38.9023 16.2289 38.9023 16.3051C38.9023 16.3813 38.9397 16.4512 39.0083 16.4893L41.2081 17.7847C41.2829 17.8292 41.3702 17.8546 41.4574 17.8546Z"
        fill="black"
      />
      <path
        d="M39.0771 16.242L40.3234 15.5117C40.4418 15.4419 40.5852 15.4419 40.7036 15.5117L41.5199 15.9944L42.9034 16.8072C42.9533 16.839 42.9533 16.9152 42.9034 16.9469L41.657 17.6772C41.5386 17.7471 41.3953 17.7471 41.2769 17.6772L40.0056 16.9279L39.0833 16.3817C39.0334 16.35 39.0334 16.2738 39.0833 16.242H39.0771Z"
        fill="url(#paint3_linear_4613_99840)"
      />
      <path
        d="M43.8383 17.7657L41.3144 19.2517C41.3144 19.2517 39.2828 21.6839 40.1179 28.3009L42.567 26.834C44.2808 25.8053 45.334 23.9319 45.334 21.9062V18.6484C45.334 17.861 44.4989 17.372 43.8321 17.7657H43.8383Z"
        fill="#272727"
      />
      <path
        d="M36.9271 16.6547L41.308 19.2519C41.308 19.2519 39.3388 21.8429 40.3421 29.8379L35.9611 27.2406C34.9516 19.2456 36.9271 16.6547 36.9271 16.6547Z"
        fill="#393939"
      />
      <path
        d="M40.1802 28.2627C40.1802 28.2627 38.1985 27.3229 37.2201 26.7196C36.2417 26.1163 35.793 25.6146 35.793 25.6146L35.9612 27.2403L40.3422 29.8376C40.3422 29.8376 40.255 28.9803 40.174 28.2691L40.1802 28.2627Z"
        fill="#FFAA00"
      />
      <path
        d="M36.2541 24.2053C36.2604 24.3958 36.4224 25.0054 37.6625 25.6151C38.697 26.1167 39.5196 26.0151 39.5196 25.6786C39.5071 22.6622 39.987 20.8142 40.3858 19.8109C40.4855 19.5569 40.3858 19.2648 40.1553 19.125C40.0556 19.0679 39.9496 19.0044 39.8312 18.9282C39.1332 18.5154 38.1237 17.9185 37.5566 17.5819C37.5067 17.5502 37.4569 17.5375 37.407 17.5311C37.2138 17.4994 37.0144 17.6073 36.9459 17.8105C36.5719 18.8456 36.1108 20.846 36.2604 24.2053H36.2541Z"
        fill="url(#paint4_linear_4613_99840)"
      />
      <path
        d="M40.6227 23.913L41.6634 20.0901C41.707 19.9377 41.788 19.8044 41.9064 19.7028C41.9438 19.671 41.9875 19.6393 42.0311 19.6139L43.5454 18.7121C43.5454 18.7121 43.6015 18.6867 43.6264 18.6804C43.7947 18.6359 43.963 18.7375 44.0128 18.909C44.019 18.9344 44.0253 18.9661 44.0253 18.9979V21.646C44.0253 21.7222 44.0003 21.8746 44.0003 21.8873C43.9318 22.2683 43.7075 22.6049 43.3709 22.8017L40.9841 24.2115C40.7909 24.3258 40.5604 24.1416 40.6227 23.9257V23.913Z"
        fill="url(#paint5_linear_4613_99840)"
      />
      <path
        d="M42.5479 24.1031L41.0648 24.9795C40.8778 25.0874 40.7656 25.2907 40.7656 25.5066V26.4972C40.7656 26.6814 40.965 26.7957 41.1208 26.7068L42.5978 25.8304C42.7536 25.7415 42.8471 25.5701 42.8471 25.3859V24.2746C42.8471 24.1158 42.6788 24.0206 42.5479 24.0968V24.1031Z"
        fill="url(#paint6_linear_4613_99840)"
      />
      <path
        d="M36.7962 21.3787L39.183 19.9689C39.5195 19.7721 39.7438 19.4355 39.8124 19.0545C39.8124 19.0545 39.8186 18.991 39.8248 18.9275C39.1269 18.5147 38.1173 17.9178 37.5502 17.5812C37.5004 17.5494 37.4505 17.5367 37.4007 17.5304L36.4285 21.0929C36.3724 21.3088 36.603 21.493 36.7962 21.3787Z"
        fill="url(#paint7_linear_4613_99840)"
      />
      <path
        d="M38.3604 21.2842L36.8773 22.1605C36.6903 22.2685 36.5781 22.4717 36.5781 22.6876V23.6783C36.5781 23.8624 36.7775 23.9767 36.9333 23.8878L38.4103 23.0115C38.5661 22.9226 38.6596 22.7511 38.6596 22.5669V21.4556C38.6596 21.2969 38.4913 21.2016 38.3604 21.2778V21.2842Z"
        fill="url(#paint8_linear_4613_99840)"
      />
      <path
        d="M38.3916 27.755V28.1296C38.3916 28.1995 38.429 28.263 38.4913 28.3011L39.5258 28.9234C39.5757 28.9552 39.6442 28.9171 39.6442 28.8536V28.8091L38.7157 28.2503C38.6533 28.2122 38.6159 28.1487 38.6159 28.0788V27.7486L38.51 27.6851C38.4602 27.6534 38.3916 27.6915 38.3916 27.755Z"
        fill="#7E1F00"
      />
      <path
        d="M38.6157 27.7488V28.079C38.6157 28.1488 38.6531 28.2124 38.7154 28.2505L39.644 28.8093V28.4854C39.644 28.4156 39.6066 28.3521 39.5443 28.314L38.6157 27.7551V27.7488Z"
        fill="#FFF2D1"
      />
      <path
        d="M30.1719 11.8663L29.555 11.4536L28.1591 11.2758L27.0747 12.6347L27.6917 13.0411L30.1719 11.8663Z"
        fill="#FAB03E"
      />
      <path
        d="M30.932 13.0412L30.1717 11.8664L28.7757 11.6886L27.6914 13.0412H30.932Z"
        fill="#E07500"
      />
      <path
        d="M42.5732 24.8343L41.3081 24.0913V20.6621L42.5732 21.4051V24.8343Z"
        fill="#111111"
      />
      <path
        d="M41.3765 23.9887V21.0548L42.2178 21.5501V24.4776L41.3765 23.9887Z"
        fill="#242424"
      />
      <path
        d="M41.3081 20.6621L41.3767 21.0558L42.218 21.5512L42.5732 21.4051L41.3081 20.6621Z"
        fill="#212121"
      />
      <path
        d="M41.8753 23.2269L41.6011 23.0681V23.5825L41.8753 23.7476L41.9376 23.7095V23.1888L41.8753 23.2269Z"
        fill="#161714"
      />
      <path
        d="M41.6011 23.4426V22.9219L41.8753 23.087V23.6014L41.6011 23.4426Z"
        fill="#111111"
      />
      <path
        d="M41.6011 22.9215L41.6634 22.8834L41.9376 23.0485L41.8753 23.0866L41.6011 22.9215Z"
        fill="#212121"
      />
      <path
        d="M41.9373 23.5697V23.049L41.875 23.0871V23.6014L41.9373 23.5697Z"
        fill="#030303"
      />
      <path
        d="M41.6571 23.4049V20.09L41.3268 19.836L41.3766 19.6709L41.8129 20.0011V23.3985C41.7817 23.335 41.6882 23.335 41.6571 23.3985V23.4049Z"
        fill="black"
      />
      <path
        d="M23.6845 28.3643L24.1955 31.5902C24.3762 31.5013 24.5569 31.4124 24.7252 31.3235L24.0646 28.6246L23.6782 28.3643H23.6845Z"
        fill="#994500"
      />
      <path
        d="M25.2983 28.6058L25.6224 30.6189C25.7533 30.4919 25.8841 30.3585 26.015 30.2188L25.6847 28.8662L25.2983 28.6058Z"
        fill="#994500"
      />
      <path
        d="M27.7911 27.8311L26.4076 29.4313L24.831 30.9046L21.8397 32.3271L21.5156 32.7208L21.8895 32.9367L22.6124 32.3715L25.0242 31.184L26.5385 29.7806L27.9344 27.9708L27.7911 27.8311Z"
        fill="#BA6300"
      />
      <path
        d="M26.1272 30.9864L25.3295 31.7484C24.9307 32.1295 24.4758 32.4406 23.9772 32.6692L23.0674 33.0884C22.843 33.19 22.6685 33.3868 22.6 33.6281L22.5314 33.8567C22.3819 34.3394 22.6374 34.8601 23.111 35.0252L23.5098 35.1649C23.9585 35.3237 24.4509 35.1522 24.7126 34.7521L25.61 33.3741L26.1958 32.1422C26.3079 31.9008 26.3765 31.6405 26.3952 31.3738L26.4077 31.1261C26.4139 30.98 26.2394 30.8975 26.1335 30.9991L26.1272 30.9864Z"
        fill="#823600"
      />
      <path
        d="M30.9943 12.8829H27.6914V13.4227H30.9943V12.8829Z"
        fill="#E07500"
      />
      <path
        d="M52.6934 18.947L57.0307 16.3942L57.1367 16.4513L52.6934 21.6395V18.947Z"
        fill="#964900"
      />
      <path
        d="M52.6934 18.9474L52.7931 19.0045V21.6399L52.6934 21.5129V18.9474Z"
        fill="#0F0C0B"
      />
      <path
        d="M52.793 19.0038L57.1366 16.4509V19.099L52.793 21.6963V19.0038Z"
        fill="#A95200"
      />
      <path
        d="M56.9935 16.7051V19.0166L56.389 19.3849L56.2955 19.4357L56.1148 19.5437L55.18 20.1025L55.0927 20.1597L54.912 20.2676L53.9772 20.8264L53.8838 20.8772L53.7965 20.928L52.9365 21.4488V19.423L53.7093 18.9785L53.8838 18.8705V18.7753L53.7093 18.8769L52.9365 19.3278V19.0864L53.7093 18.6356L53.8838 18.534L53.9772 18.4768L54.912 17.9243L55.0927 17.8227L55.18 17.7719L56.1148 17.2195L56.2955 17.1178L56.389 17.0607L56.9935 16.7051Z"
        fill="black"
      />
      <path
        d="M56.9935 17.7091V17.8044L56.389 18.16L56.2955 18.2171L56.1148 18.3187L55.18 18.8649L55.0927 18.922L54.912 19.0236L53.9772 19.5697L53.8838 19.6269L53.7093 19.7285L52.9365 20.1794V20.0841L53.7093 19.6333L53.8838 19.5253L53.9772 19.4745L54.912 18.9284L55.0927 18.8268L55.18 18.7696L56.1148 18.2235L56.2955 18.1219L56.389 18.0647L56.9935 17.7091Z"
        fill="#A95200"
      />
      <path
        d="M53.8838 19.9062V20.0015L53.7093 20.1095L52.9365 20.5603V20.4651L53.7093 20.0142L53.8838 19.9062Z"
        fill="#A95200"
      />
      <path
        d="M56.9935 18.0902V18.1855L56.389 18.5411L56.2955 18.5982L56.1148 18.6998L55.18 19.246L55.0928 19.2968L54.912 19.4047L53.9773 19.9509L53.8838 20.0017V19.9064L53.9773 19.8556L54.912 19.3095L55.0928 19.2015L55.18 19.1507L56.1148 18.6046L56.2955 18.4966L56.389 18.4458L56.9935 18.0902Z"
        fill="#A95200"
      />
      <path
        d="M53.8838 20.2875V20.3827L53.7093 20.4907L52.9365 20.9416V20.84L53.7093 20.3891L53.8838 20.2875Z"
        fill="#A95200"
      />
      <path
        d="M53.8838 20.6686V20.7638L53.7093 20.8654L52.9365 21.3163V21.2211L53.7093 20.7702L53.8838 20.6686Z"
        fill="#A95200"
      />
      <path
        d="M56.9935 18.8467V18.9419L56.389 19.2976L56.2955 19.3547L56.1148 19.4563L55.18 20.0024L55.0928 20.0596L54.912 20.1612L53.9773 20.7073L53.8838 20.7645V20.6692L53.9773 20.6121L54.912 20.0659L55.0928 19.9643L55.18 19.9072L56.1148 19.3611L56.2955 19.2595L56.389 19.2023L56.9935 18.8467Z"
        fill="#A95200"
      />
      <path
        d="M56.9 17.6716L56.9935 17.7097L56.389 18.0654L56.2955 18.1225L56.1148 18.2241L55.18 18.7702L55.0927 18.8274L54.912 18.929L53.9772 19.4751L53.8838 19.5259L53.7093 19.6339L52.9365 20.0848V19.9895L53.7093 19.5386L53.8838 19.437L53.9772 19.3799L54.912 18.8337L55.0927 18.7258L55.18 18.675L56.1148 18.1289L56.2955 18.0272L56.389 17.9701L56.9 17.6716Z"
        fill="#964900"
      />
      <path
        d="M56.9 18.0527L56.9935 18.0908L56.389 18.4465L56.2955 18.4973L56.1148 18.6052L55.18 19.1513L55.0927 19.2021L54.912 19.3101L53.9772 19.8562L53.8838 19.907L53.7093 20.015L52.9365 20.4659V20.3706L53.7093 19.9197L53.8838 19.8118L53.9772 19.761L54.912 19.2148L55.0927 19.1069L55.18 19.0561L56.1148 18.51L56.2955 18.4084L56.389 18.3512L56.9 18.0527Z"
        fill="#964900"
      />
      <path
        d="M56.9 18.4329L56.9935 18.471L56.389 18.8266L56.2955 18.8774L56.1148 18.9853L55.18 19.5251L55.0927 19.5823L54.912 19.6839L53.9772 20.2364L53.8838 20.2872L53.7093 20.3888L52.9365 20.8396V20.7444L53.7093 20.2935L53.8838 20.1919L53.9772 20.1347L54.912 19.5886L55.0927 19.487L55.18 19.4299L56.1148 18.8837L56.2955 18.7821L56.389 18.725L56.9 18.4329Z"
        fill="#964900"
      />
      <path
        d="M53.8838 18.7765V18.8717L53.7093 18.9797L52.9365 19.4242V19.329L53.7093 18.8781L53.8838 18.7765Z"
        fill="#A95200"
      />
      <path
        d="M56.9935 16.9537V17.0553L56.389 17.411L56.2955 17.4618L56.1148 17.5634L55.18 18.1158L55.0928 18.1666L54.912 18.2683L53.9773 18.8207L53.8838 18.8715V18.7763L53.9773 18.7255L54.912 18.173L55.0928 18.0714L55.18 18.0142L56.1148 17.4681L56.2955 17.3665L56.389 17.3157L56.9935 16.9537Z"
        fill="#A34F00"
      />
      <path
        d="M56.9935 17.3347V17.43L56.389 17.7856L56.2955 17.8427L56.1148 17.9443L55.18 18.4905L55.0927 18.5476L54.912 18.6492L53.9772 19.1954L53.8838 19.2525L53.7093 19.3541L52.9365 19.805V19.7097L53.7093 19.2589L53.8838 19.1573L53.9772 19.1001L54.912 18.554L55.0927 18.4524L55.18 18.3952L56.1148 17.8491L56.2955 17.7475L56.389 17.6903L56.9935 17.3347Z"
        fill="#A95200"
      />
      <path
        d="M56.9 16.9219L56.9935 16.9536L56.389 17.3156L56.2955 17.3664L56.1148 17.468L55.18 18.0141L55.0927 18.0713L54.912 18.1729L53.9772 18.7254L53.8838 18.7762L53.7093 18.8778L52.9365 19.3286V19.2334L53.7093 18.7825L53.8838 18.6809L53.9772 18.6238L54.912 18.0776L55.0927 17.976L55.18 17.9189L56.1148 17.3727L56.2955 17.2711L56.389 17.214L56.9 16.9219Z"
        fill="#964900"
      />
      <path
        d="M56.9 17.2963L56.9935 17.3344L56.389 17.69L56.2955 17.7471L56.1148 17.8487L55.18 18.3949L55.0927 18.452L54.912 18.5536L53.9772 19.0998L53.8838 19.1569L53.7093 19.2585L52.9365 19.7094V19.6141L53.7093 19.1633L53.8838 19.0617L53.9772 19.0045L54.912 18.4584L55.0927 18.3568L55.18 18.2996L56.1148 17.7535L56.2955 17.6519L56.389 17.5947L56.9 17.2963Z"
        fill="#964900"
      />
      <path
        d="M56.9 18.8082L56.9935 18.8463L56.389 19.2019L56.2955 19.2591L56.1148 19.3607L55.18 19.9068L55.0927 19.964L54.912 20.0656L53.9772 20.6117L53.8838 20.6689L53.7093 20.7705L52.9365 21.2213V21.1261L53.7093 20.6752L53.8838 20.5736L53.9772 20.5165L54.912 19.9703L55.0927 19.8687L55.18 19.8116L56.1148 19.2655L56.2955 19.1638L56.389 19.1067L56.9 18.8082Z"
        fill="#964900"
      />
      <path
        d="M56.3889 17.0614V19.3856L56.2954 19.4364V17.1186L56.3889 17.0614Z"
        fill="#A95200"
      />
      <path
        d="M56.2955 17.1191V17.3668L56.1147 17.3732V17.2207L56.2955 17.1191Z"
        fill="#0F0C0B"
      />
      <path
        d="M56.2955 17.4618V17.7476L56.1147 17.7539V17.5634L56.2955 17.4618Z"
        fill="#0F0C0B"
      />
      <path
        d="M56.2955 17.8429V18.1223L56.1147 18.1287V17.9445L56.2955 17.8429Z"
        fill="#0F0C0B"
      />
      <path
        d="M56.2955 18.2173V18.4967L56.1147 18.5094V18.3189L56.2955 18.2173Z"
        fill="#0F0C0B"
      />
      <path
        d="M56.9935 18.4713V18.5666L56.389 18.9222L56.2955 18.973L56.1148 19.0746L55.18 19.6271L55.0928 19.6779L54.912 19.7795L53.9773 20.332L53.8838 20.3828V20.2875L53.9773 20.2367L54.912 19.6842L55.0928 19.5826L55.18 19.5255L56.1148 18.9857L56.2955 18.8777L56.389 18.8269L56.9935 18.4713Z"
        fill="#A95200"
      />
      <path
        d="M56.2955 18.5984V18.8778L56.1147 18.8905V18.7L56.2955 18.5984Z"
        fill="#0F0C0B"
      />
      <path
        d="M56.2955 18.9728V19.2585L56.1147 19.2649V19.0744L56.2955 18.9728Z"
        fill="#0F0C0B"
      />
      <path
        d="M56.2955 19.3539V19.4364L56.1147 19.5444V19.4555L56.2955 19.3539Z"
        fill="#0F0C0B"
      />
      <path
        d="M55.18 17.7727V20.1033L55.0928 20.1604V17.8235L55.18 17.7727Z"
        fill="#A95200"
      />
      <path
        d="M55.0928 17.8236V18.0713L54.9121 18.0776V17.9252L55.0928 17.8236Z"
        fill="#0F0C0B"
      />
      <path
        d="M55.0928 18.1672V18.453L54.9121 18.4594V18.2688L55.0928 18.1672Z"
        fill="#0F0C0B"
      />
      <path
        d="M55.0928 18.5474V18.8268L54.9121 18.8331V18.649L55.0928 18.5474Z"
        fill="#0F0C0B"
      />
      <path
        d="M55.0928 18.9227V19.2021L54.9121 19.2148V19.0243L55.0928 18.9227Z"
        fill="#0F0C0B"
      />
      <path
        d="M55.0928 19.2961V19.5819L54.9121 19.5946V19.4041L55.0928 19.2961Z"
        fill="#0F0C0B"
      />
      <path
        d="M55.0928 19.6772V19.963L54.9121 19.9694V19.7789L55.0928 19.6772Z"
        fill="#0F0C0B"
      />
      <path
        d="M55.0928 20.0593V20.1609L54.9121 20.2689V20.1609L55.0928 20.0593Z"
        fill="#0F0C0B"
      />
      <path
        d="M53.9773 18.4781V20.8278L53.8838 20.8786V18.5353L53.9773 18.4781Z"
        fill="#A95200"
      />
      <path
        d="M53.884 18.5349V18.7762L53.7095 18.7826V18.6365L53.884 18.5349Z"
        fill="#0F0C0B"
      />
      <path
        d="M53.884 18.8707V19.1565L53.7095 19.1628V18.9787L53.884 18.8707Z"
        fill="#0F0C0B"
      />
      <path
        d="M53.884 19.2518V19.5249L53.7095 19.5376V19.3534L53.884 19.2518Z"
        fill="#0F0C0B"
      />
      <path
        d="M53.884 19.6272V19.9066L53.7095 19.9193V19.7288L53.884 19.6272Z"
        fill="#0F0C0B"
      />
      <path
        d="M53.884 20.0016V20.2874L53.7095 20.3001V20.1095L53.884 20.0016Z"
        fill="#0F0C0B"
      />
      <path
        d="M53.884 20.3827V20.6685L53.7095 20.6748V20.4906L53.884 20.3827Z"
        fill="#0F0C0B"
      />
      <path
        d="M53.7095 20.8654L53.884 20.7638V20.8781L53.7967 20.9289H53.7095V20.8654Z"
        fill="#0F0C0B"
      />
      <path
        d="M47.5898 22.0139L51.9334 19.4611L52.0332 19.5182L47.5898 24.7064V22.0139Z"
        fill="#964900"
      />
      <path
        d="M47.5898 22.0144L47.6958 22.0716V24.7069L47.5898 24.5799V22.0144Z"
        fill="#0F0C0B"
      />
      <path
        d="M47.6958 22.0708L52.0332 19.5179V22.166L47.6958 24.7633V22.0708Z"
        fill="#A95200"
      />
      <path
        d="M51.8958 19.772V22.0835L51.2851 22.4518L51.1916 22.5026L51.0171 22.6106L50.0823 23.1694L49.9889 23.2265L49.8144 23.3345L48.8734 23.8933L48.7861 23.9441L48.6989 23.9949L47.8389 24.5157V22.4899L48.6054 22.039L48.7861 21.9374V21.8422L48.6054 21.9438L47.8389 22.3947V22.1533L48.6054 21.7025L48.7861 21.6009L48.8734 21.5437L49.8144 20.9912L49.9889 20.8896L50.0823 20.8388L51.0171 20.2863L51.1916 20.1847L51.2851 20.1276L51.8958 19.772Z"
        fill="black"
      />
      <path
        d="M51.8958 20.7759V20.8711L51.2851 21.2268L51.1916 21.2839L51.0171 21.3855L50.0823 21.9316L49.9889 21.9888L49.8144 22.0904L48.8734 22.6365L48.7861 22.6937L48.6054 22.7953L47.8389 23.2462V23.1509L48.6054 22.7L48.7861 22.5921L48.8734 22.5413L49.8144 21.9951L49.9889 21.8935L50.0823 21.8364L51.0171 21.2903L51.1916 21.1887L51.2851 21.1315L51.8958 20.7759Z"
        fill="#A95200"
      />
      <path
        d="M48.7861 22.9731V23.0684L48.6054 23.17L47.8389 23.6209V23.532L48.6054 23.0811L48.7861 22.9731Z"
        fill="#A95200"
      />
      <path
        d="M51.8958 21.1571V21.2524L51.2851 21.608L51.1916 21.6588L51.0171 21.7667L50.0824 22.3129L49.9889 22.3637L49.8144 22.4716L48.8734 23.0177L48.7861 23.0686V22.9733L48.8734 22.9225L49.8144 22.37L49.9889 22.2684L50.0824 22.2176L51.0171 21.6715L51.1916 21.5635L51.2851 21.5127L51.8958 21.1571Z"
        fill="#A95200"
      />
      <path
        d="M48.7861 23.3542V23.4495L48.6054 23.5511L47.8389 24.002V23.9067L48.6054 23.4559L48.7861 23.3542Z"
        fill="#A95200"
      />
      <path
        d="M48.7861 23.7354V23.8306L48.6054 23.9322L47.8389 24.3831V24.2878L48.6054 23.837L48.7861 23.7354Z"
        fill="#A95200"
      />
      <path
        d="M51.8958 21.9135V22.0087L51.2851 22.3643L51.1916 22.4215L51.0171 22.5231L50.0824 23.0692L49.9889 23.12L49.8144 23.228L48.8734 23.7741L48.7861 23.8312V23.736L48.8734 23.6788L49.8144 23.1327L49.9889 23.0311L50.0824 22.974L51.0171 22.4278L51.1916 22.3262L51.2851 22.2691L51.8958 21.9135Z"
        fill="#A95200"
      />
      <path
        d="M51.7961 20.7384L51.8958 20.7765L51.2851 21.1321L51.1916 21.1893L51.0171 21.2909L50.0823 21.837L49.9889 21.8942L49.8144 21.9958L48.8734 22.5419L48.7861 22.5927L48.6054 22.7007L47.8389 23.1515V23.0563L48.6054 22.6054L48.7861 22.5038L48.8734 22.4466L49.8144 21.9005L49.9889 21.7926L50.0823 21.7418L51.0171 21.1956L51.1916 21.0877L51.2851 21.0369L51.7961 20.7384Z"
        fill="#964900"
      />
      <path
        d="M51.7961 21.1195L51.8958 21.1576L51.2851 21.5132L51.1916 21.564L51.0171 21.672L50.0823 22.2181L49.9889 22.2689L49.8144 22.3705L48.8734 22.923L48.7861 22.9738L48.6054 23.0818L47.8389 23.5326V23.431L48.6054 22.9802L48.7861 22.8785L48.8734 22.8277L49.8144 22.2816L49.9889 22.1737L50.0823 22.1229L51.0171 21.5767L51.1916 21.4688L51.2851 21.418L51.7961 21.1195Z"
        fill="#964900"
      />
      <path
        d="M51.7961 21.4996L51.8958 21.5377L51.2851 21.8934L51.1916 21.9442L51.0171 22.0521L50.0823 22.5919L49.9889 22.649L49.8144 22.7506L48.8734 23.3031L48.7861 23.3539L48.6054 23.4555L47.8389 23.9064V23.8111L48.6054 23.3603L48.7861 23.2587L48.8734 23.2015L49.8144 22.6554L49.9889 22.5538L50.0823 22.4966L51.0171 21.9505L51.1916 21.8489L51.2851 21.7917L51.7961 21.4996Z"
        fill="#964900"
      />
      <path
        d="M48.7861 21.8433V21.9385L48.6054 22.0401L47.8389 22.491V22.3957L48.6054 21.9449L48.7861 21.8433Z"
        fill="#A95200"
      />
      <path
        d="M51.8958 20.0204V20.122L51.2851 20.4776L51.1916 20.5284L51.0171 20.63L50.0824 21.1825L49.9889 21.2333L49.8144 21.3349L48.8734 21.8874L48.7861 21.9382V21.8429L48.8734 21.7921L49.8144 21.2396L49.9889 21.138L50.0824 21.0809L51.0171 20.5348L51.1916 20.4332L51.2851 20.3824L51.8958 20.0204Z"
        fill="#A34F00"
      />
      <path
        d="M51.8958 20.4016V20.4969L51.2851 20.8525L51.1916 20.9096L51.0171 21.0112L50.0823 21.5574L49.9889 21.6082L49.8144 21.7161L48.8734 22.2623L48.7861 22.3194L48.6054 22.421L47.8389 22.8719V22.7766L48.6054 22.3258L48.7861 22.2242L48.8734 22.167L49.8144 21.6209L49.9889 21.5193L50.0823 21.4621L51.0171 20.916L51.1916 20.8144L51.2851 20.7572L51.8958 20.4016Z"
        fill="#A95200"
      />
      <path
        d="M51.7961 19.9885L51.8958 20.0203L51.2851 20.3822L51.1916 20.433L51.0171 20.5347L50.0823 21.0808L49.9889 21.1379L49.8144 21.2395L48.8734 21.792L48.7861 21.8428L48.6054 21.9444L47.8389 22.3953V22.3L48.6054 21.8492L48.7861 21.7476L48.8734 21.6904L49.8144 21.1443L49.9889 21.0427L50.0823 20.9855L51.0171 20.4394L51.1916 20.3378L51.2851 20.2806L51.7961 19.9885Z"
        fill="#964900"
      />
      <path
        d="M51.7961 20.363L51.8958 20.4011L51.2851 20.7568L51.1916 20.8139L51.0171 20.9155L50.0823 21.4616L49.9889 21.5188L49.8144 21.6204L48.8734 22.1665L48.7861 22.2237L48.6054 22.3253L47.8389 22.7762V22.6809L48.6054 22.23L48.7861 22.1284L48.8734 22.0713L49.8144 21.5251L49.9889 21.4172L50.0823 21.3664L51.0171 20.8203L51.1916 20.7187L51.2851 20.6615L51.7961 20.363Z"
        fill="#964900"
      />
      <path
        d="M51.7961 21.875L51.8958 21.9131L51.2851 22.2687L51.1916 22.3259L51.0171 22.4275L50.0823 22.9736L49.9889 23.0308L49.8144 23.1324L48.8734 23.6785L48.7861 23.7356L48.6054 23.8372L47.8389 24.2881V24.1929L48.6054 23.742L48.7861 23.6404L48.8734 23.5832L49.8144 23.0371L49.9889 22.9355L50.0823 22.8784L51.0171 22.3322L51.1916 22.2306L51.2851 22.1735L51.7961 21.875Z"
        fill="#964900"
      />
      <path
        d="M51.2849 20.1282V22.4524L51.1914 22.5032V20.1853L51.2849 20.1282Z"
        fill="#A95200"
      />
      <path
        d="M51.1916 20.186V20.4337L51.0171 20.44V20.2876L51.1916 20.186Z"
        fill="#0F0C0B"
      />
      <path
        d="M51.1916 20.5286V20.8143L51.0171 20.8207V20.6302L51.1916 20.5286Z"
        fill="#0F0C0B"
      />
      <path
        d="M51.1916 20.9097V21.1891L51.0171 21.1954V21.0113L51.1916 20.9097Z"
        fill="#0F0C0B"
      />
      <path
        d="M51.1916 21.2841V21.5635L51.0171 21.5762V21.3857L51.1916 21.2841Z"
        fill="#0F0C0B"
      />
      <path
        d="M51.8958 21.5382V21.6335L51.2851 21.9891L51.1916 22.0399L51.0171 22.1415L50.0824 22.694L49.9889 22.7448L49.8144 22.8464L48.8734 23.3989L48.7861 23.4497V23.3544L48.8734 23.3036L49.8144 22.7511L49.9889 22.6495L50.0824 22.5924L51.0171 22.0526L51.1916 21.9446L51.2851 21.8938L51.8958 21.5382Z"
        fill="#A95200"
      />
      <path
        d="M51.1916 21.6594V21.9452L51.0171 21.9579V21.7674L51.1916 21.6594Z"
        fill="#0F0C0B"
      />
      <path
        d="M51.1916 22.0396V22.3253L51.0171 22.3317V22.1412L51.1916 22.0396Z"
        fill="#0F0C0B"
      />
      <path
        d="M51.1916 22.4207V22.5032L51.0171 22.6112V22.5223L51.1916 22.4207Z"
        fill="#0F0C0B"
      />
      <path
        d="M50.0822 20.8395V23.17L49.9888 23.2272V20.8903L50.0822 20.8395Z"
        fill="#A95200"
      />
      <path
        d="M49.9889 20.8905V21.1382L49.8145 21.1445V20.9921L49.9889 20.8905Z"
        fill="#0F0C0B"
      />
      <path
        d="M49.9889 21.234V21.5198L49.8145 21.5261V21.3356L49.9889 21.234Z"
        fill="#0F0C0B"
      />
      <path
        d="M49.9889 21.6074V21.8932L49.8145 21.8995V21.7154L49.9889 21.6074Z"
        fill="#0F0C0B"
      />
      <path
        d="M49.9889 21.9895V22.2689L49.8145 22.2816V22.0911L49.9889 21.9895Z"
        fill="#0F0C0B"
      />
      <path
        d="M49.9889 22.3629V22.6487L49.8145 22.6614V22.4709L49.9889 22.3629Z"
        fill="#0F0C0B"
      />
      <path
        d="M49.9889 22.744V23.0298L49.8145 23.0361V22.8456L49.9889 22.744Z"
        fill="#0F0C0B"
      />
      <path
        d="M49.9889 23.1194V23.2273L49.8145 23.3353V23.2273L49.9889 23.1194Z"
        fill="#0F0C0B"
      />
      <path
        d="M48.8734 21.5449V23.8945L48.7861 23.9453V21.6021L48.8734 21.5449Z"
        fill="#A95200"
      />
      <path
        d="M48.7862 21.6017V21.843L48.6055 21.8493V21.7033L48.7862 21.6017Z"
        fill="#0F0C0B"
      />
      <path
        d="M48.7862 21.9375V22.2233L48.6055 22.2296V22.0391L48.7862 21.9375Z"
        fill="#0F0C0B"
      />
      <path
        d="M48.7862 22.3187V22.5918L48.6055 22.6045V22.4203L48.7862 22.3187Z"
        fill="#0F0C0B"
      />
      <path
        d="M48.7862 22.694V22.9734L48.6055 22.9861V22.7956L48.7862 22.694Z"
        fill="#0F0C0B"
      />
      <path
        d="M48.7862 23.0684V23.3541L48.6055 23.3605V23.17L48.7862 23.0684Z"
        fill="#0F0C0B"
      />
      <path
        d="M48.7862 23.4495V23.7352L48.6055 23.7416V23.5511L48.7862 23.4495Z"
        fill="#0F0C0B"
      />
      <path
        d="M48.6055 23.9322L48.7862 23.8306V23.9449L48.6989 23.9957H48.6055V23.9322Z"
        fill="#0F0C0B"
      />
      <path
        d="M56.6695 18.4128L55.7659 15.5043L56.0837 15.3583L56.844 18.1778L56.8253 23.6264L56.682 23.5375L56.6695 18.4128Z"
        fill="#A64B0A"
      />
      <path
        d="M56.8191 18.362L55.9155 15.4535L56.0837 15.3583L56.9873 18.2667L56.9936 23.5311L56.8253 23.6264L56.8191 18.362Z"
        fill="#D15F0C"
      />
      <path
        d="M56.651 21.7532C56.651 21.7532 56.7258 21.7278 56.7632 21.8104C56.7943 21.8866 56.7445 21.9374 56.7445 21.9374L55.5479 22.6168L55.4482 22.5597V22.4327L56.651 21.7532Z"
        fill="#CE5D0C"
      />
      <path
        d="M56.651 23.0803C56.651 23.0803 56.7258 23.0549 56.7632 23.1375C56.7943 23.2137 56.7445 23.2645 56.7445 23.2645L55.5479 23.944L55.4482 23.8868V23.7598L56.651 23.0803Z"
        fill="#CE5D0C"
      />
      <path
        d="M56.713 20.4828C56.713 20.4828 56.7878 20.4574 56.8252 20.54C56.8563 20.6162 56.8065 20.667 56.8065 20.667L55.61 21.3465L55.5103 21.2893V21.1623L56.713 20.4828Z"
        fill="#CE5D0C"
      />
      <path
        d="M56.7818 19.3645C56.7818 19.3645 56.8566 19.3391 56.894 19.4217C56.9252 19.4979 56.8753 19.5487 56.8753 19.5487L55.6788 20.2282L55.5791 20.171V20.044L56.7818 19.3645Z"
        fill="#CE5D0C"
      />
      <path
        d="M56.6944 18.3367C56.6944 18.3367 56.7692 18.3113 56.8066 18.3938C56.8378 18.4701 56.7879 18.5209 56.7879 18.5209L55.5914 19.2003L55.4917 19.1432V19.0162L56.6944 18.3367Z"
        fill="#CE5D0C"
      />
      <path
        d="M56.358 17.2819C56.358 17.2819 56.4328 17.2565 56.4702 17.339C56.5014 17.4152 56.4515 17.466 56.4515 17.466L55.255 18.1455L55.1553 18.0884V17.9614L56.358 17.2819Z"
        fill="#CE5D0C"
      />
      <path
        d="M56.0651 16.3676C56.0651 16.3676 56.1398 16.3422 56.1772 16.4247C56.2084 16.5009 56.1585 16.5517 56.1585 16.5517L54.962 17.2312L54.8623 17.1741V17.0471L56.0651 16.3676Z"
        fill="#CE5D0C"
      />
      <path
        d="M55.8155 15.4909C55.8155 15.4909 55.8903 15.4655 55.9277 15.548C55.9589 15.6242 55.909 15.675 55.909 15.675L54.7125 16.3545L54.6128 16.2974V16.1704L55.8155 15.4909Z"
        fill="#CE5D0C"
      />
      <path
        d="M55.3172 19.181L54.4136 16.2725L54.7314 16.1265L55.4917 18.946L55.473 24.3946L55.3297 24.3057L55.3172 19.181Z"
        fill="#A64B0A"
      />
      <path
        d="M55.4666 19.1302L54.563 16.2217L54.7313 16.1265L55.6349 19.0349L55.6411 24.2993L55.4728 24.3946L55.4666 19.1302Z"
        fill="#D15F0C"
      />
      <path
        d="M37.438 16.6481L36.9893 15.74L37.4566 15.4669C37.4566 15.4669 37.4691 15.4606 37.4753 15.4542H37.4878C37.5501 15.4224 37.6311 15.4351 37.7184 15.486C37.9178 15.6003 38.0736 15.8797 38.0736 16.1083C38.0736 16.2099 38.0424 16.2861 37.9864 16.3242H37.9739C37.9739 16.3242 37.9614 16.3369 37.9552 16.3432L37.4317 16.6417L37.438 16.6481Z"
        fill="url(#paint9_linear_4613_99840)"
      />
      <path
        d="M36.8398 15.9872C36.8398 16.2158 37.0019 16.4952 37.1951 16.6095C37.3945 16.7238 37.5503 16.6349 37.5503 16.4063C37.5503 16.1777 37.3882 15.8983 37.1951 15.784C36.9956 15.6697 36.8398 15.7586 36.8398 15.9872Z"
        fill="#454545"
      />
      <path
        d="M36.8833 16.0195C36.8833 16.2228 37.0266 16.4641 37.1949 16.5657C37.3694 16.6673 37.5065 16.5847 37.5065 16.3879C37.5065 16.191 37.3632 15.9433 37.1949 15.8417C37.0204 15.7401 36.8833 15.8227 36.8833 16.0195Z"
        fill="#6ED4E6"
      />
      <path
        d="M36.896 16.1087C37.0144 16.0579 37.2574 16.0198 37.513 16.4008C37.5067 16.585 37.3696 16.6612 37.2014 16.5596C37.0518 16.4707 36.9334 16.2802 36.896 16.1024V16.1087Z"
        fill="#F0F4FB"
      />
      <path
        d="M37.1387 16.1085C37.1387 16.172 37.1823 16.2546 37.2384 16.2864C37.2945 16.3181 37.3381 16.2927 37.3381 16.2292C37.3381 16.1657 37.2945 16.0831 37.2384 16.0514C37.1823 16.0196 37.1387 16.045 37.1387 16.1085Z"
        fill="white"
      />
      <path
        d="M41.4327 19.0227L40.984 18.1146L41.4514 17.8415C41.4514 17.8415 41.4639 17.8352 41.4701 17.8288H41.4826C41.5449 17.7971 41.6259 17.8098 41.7131 17.8606C41.9126 17.9749 42.0683 18.2543 42.0683 18.4829C42.0683 18.5845 42.0372 18.6607 41.9811 18.6988H41.9686C41.9686 18.6988 41.9562 18.7115 41.9499 18.7179L41.4265 19.0163L41.4327 19.0227Z"
        fill="url(#paint10_linear_4613_99840)"
      />
      <path
        d="M40.8345 18.3685C40.8345 18.5972 40.9965 18.8766 41.1897 18.9909C41.3891 19.1052 41.5449 19.0163 41.5449 18.7877C41.5449 18.5591 41.3829 18.2796 41.1897 18.1653C40.9903 18.051 40.8345 18.1399 40.8345 18.3685Z"
        fill="#454545"
      />
      <path
        d="M40.8779 18.3933C40.8779 18.5965 41.0213 18.8378 41.1895 18.9395C41.3578 19.0411 41.5011 18.9585 41.5011 18.7616C41.5011 18.5648 41.3578 18.3171 41.1895 18.2155C41.0213 18.1139 40.8779 18.1965 40.8779 18.3933Z"
        fill="#6ED4E6"
      />
      <path
        d="M40.8906 18.4825C41.009 18.4317 41.2521 18.3936 41.5076 18.7746C41.5013 18.9588 41.3642 19.035 41.196 18.9334C41.0464 18.8445 40.928 18.6539 40.8906 18.4761V18.4825Z"
        fill="#F0F4FB"
      />
      <path
        d="M41.1333 18.4823C41.1333 18.5458 41.1769 18.6284 41.233 18.6601C41.2891 18.6919 41.3327 18.6665 41.3327 18.603C41.3327 18.5395 41.2891 18.4569 41.233 18.4252C41.1769 18.3934 41.1333 18.4188 41.1333 18.4823Z"
        fill="white"
      />
      <path
        d="M13.6699 20.3628L14.3804 21.1566L17.0164 22.2107L15.0285 20.8137L13.6699 20.3628Z"
        fill="#D97700"
      />
      <path
        d="M25.3355 27.6147C25.2857 27.5766 25.2233 27.5511 25.1673 27.5257C24.9803 27.4495 24.812 27.3416 24.6376 27.2336C24.326 27.0368 24.0144 26.8399 23.7028 26.6494C23.2105 26.3446 22.7244 26.0334 22.2508 25.7032C22.0451 25.5571 21.8457 25.4111 21.6151 25.3095C21.154 25.1063 20.6305 25.1126 20.1631 24.9285L20.9171 32.3774C20.9171 32.3774 21.0916 32.479 21.154 32.5488C21.2724 32.6949 21.4967 32.6886 21.665 32.6124C21.8332 32.5362 21.9641 32.3964 22.1137 32.2821C22.5997 31.9265 23.2354 31.8884 23.7963 31.6789C24.6438 31.374 25.3106 30.6946 25.9462 30.0405C26.1332 29.85 26.2952 29.6595 26.4199 29.4245C26.557 29.1705 26.5258 29.1006 26.2329 28.9609C26.1644 28.9292 26.0896 28.8974 26.0522 28.8339C26.021 28.7831 26.021 28.726 26.0023 28.6752C25.9026 28.4338 25.6409 28.5354 25.5474 28.256C25.4726 28.0401 25.5474 27.7734 25.348 27.6147H25.3355Z"
        fill="#4A2500"
      />
      <path
        d="M22.7433 30.8926C23.1047 30.3528 23.2294 29.686 23.4475 29.0764C23.6158 28.6064 23.8401 28.1556 24.1143 27.7364C24.1642 27.6602 24.2203 27.5777 24.295 27.5269C24.376 27.4697 24.4758 27.438 24.5755 27.4189C24.6939 27.3935 24.7998 27.3872 24.9058 27.3935C24.993 27.4443 25.0802 27.4888 25.1737 27.5269C25.2298 27.5523 25.2921 27.5777 25.342 27.6158C25.5414 27.7745 25.4729 28.0476 25.5414 28.2572C25.6349 28.5366 25.8966 28.435 25.9963 28.6763C26.015 28.7271 26.0213 28.7906 26.0462 28.835C26.0836 28.8986 26.1646 28.9303 26.2269 28.9621C26.526 29.1018 26.551 29.1716 26.4139 29.4256C26.2892 29.6606 26.1272 29.8511 25.9402 30.0416C25.3046 30.6957 24.644 31.3688 23.7903 31.68C23.2294 31.8832 22.5875 31.9213 22.1077 32.2833C21.9581 32.3912 21.8272 32.5309 21.659 32.6135C21.4907 32.696 21.2664 32.6897 21.1479 32.55C21.6153 31.9658 22.3195 31.5339 22.7433 30.8926Z"
        fill="#401D00"
      />
      <path
        d="M20.8364 31.5781L20.2817 26.1232C20.4625 25.7676 20.7055 25.4374 20.9735 25.1326C21.1916 25.177 21.4097 25.2215 21.6154 25.3104C21.8459 25.412 22.0454 25.558 22.251 25.7041C22.5501 25.9137 22.8617 26.1169 23.1733 26.3137C23.0611 26.3836 22.949 26.4534 22.8368 26.5296C22.4442 26.7964 22.064 27.0948 21.8023 27.4949C21.3287 28.2252 21.3536 29.165 21.2414 30.035C21.1729 30.5938 21.067 31.0828 20.8364 31.5845V31.5781Z"
        fill="#401D00"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4613_99840"
          x1="-1.95405"
          y1="39.1067"
          x2="41.1387"
          y2="65.8322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4BF9B" />
          <stop offset="0.757501" stopColor="#D4BF9B" />
          <stop offset="1" stopColor="#D4BF9B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4613_99840"
          x1="38.0051"
          y1="24.8334"
          x2="36.0959"
          y2="21.6835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C7C7C" />
          <stop offset="0.14" stopColor="#575757" />
          <stop offset="0.29" stopColor="#373737" />
          <stop offset="0.45" stopColor="#1F1F1F" />
          <stop offset="0.61" stopColor="#0D0D0D" />
          <stop offset="0.79" stopColor="#030303" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4613_99840"
          x1="48.325"
          y1="30.9036"
          x2="46.4158"
          y2="27.7537"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C7C7C" />
          <stop offset="0.14" stopColor="#575757" />
          <stop offset="0.29" stopColor="#373737" />
          <stop offset="0.45" stopColor="#1F1F1F" />
          <stop offset="0.61" stopColor="#0D0D0D" />
          <stop offset="0.79" stopColor="#030303" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4613_99840"
          x1="40.5166"
          y1="17.3089"
          x2="41.6616"
          y2="15.6014"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#597E90" />
          <stop offset="0.94" stopColor="#6F797B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4613_99840"
          x1="37.1266"
          y1="25.0181"
          x2="39.4338"
          y2="18.4597"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#597E90" />
          <stop offset="0.42" stopColor="#4C6875" />
          <stop offset="0.94" stopColor="#3C4B52" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4613_99840"
          x1="42.9035"
          y1="23.2653"
          x2="41.5949"
          y2="19.4127"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#305F76" />
          <stop offset="0.44" stopColor="#284859" />
          <stop offset="0.94" stopColor="#1F2B36" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4613_99840"
          x1="42.1179"
          y1="26.3512"
          x2="41.4861"
          y2="24.4658"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#305F76" />
          <stop offset="0.94" stopColor="#2A4861" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4613_99840"
          x1="38.3105"
          y1="20.6421"
          x2="37.2791"
          y2="17.6119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92C6B9" />
          <stop offset="0.94" stopColor="#6F797B" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4613_99840"
          x1="37.9242"
          y1="23.5322"
          x2="36.6734"
          y2="19.7613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92C6B9" />
          <stop offset="0.94" stopColor="#6F797B" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4613_99840"
          x1="37.8306"
          y1="16.4258"
          x2="37.3115"
          y2="15.5516"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.16" stopColor="#535353" />
          <stop offset="0.56" stopColor="#606060" />
          <stop offset="0.87" stopColor="#777777" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4613_99840"
          x1="41.8253"
          y1="18.8004"
          x2="41.3062"
          y2="17.9262"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.16" stopColor="#535353" />
          <stop offset="0.56" stopColor="#606060" />
          <stop offset="0.87" stopColor="#777777" />
        </linearGradient>
        <clipPath id="clip0_4613_99840">
          <rect
            y="0.0146484"
            width="65.6552"
            height="67.0145"
            rx="32.8276"
            fill="white"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
