import React from "react";
import {
  alpha,
  Divider,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  notificationTranslation,
  User,
  NotificationEnum,
  shownNotifications,
} from "interfaces";
import { UserAction, UserActionType } from "reducers";

interface UserEditNotificationSwitchsProps {
  userEditableInfo: User;
  dispatch: React.Dispatch<UserAction>;
}

export const UserEditNotificationSwitches: React.FC<
  UserEditNotificationSwitchsProps
> = ({ userEditableInfo, dispatch }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridItemSubtitle} item xs={6}>
      <Grid className={classes.gridPermissionsSwitchesContainer} item xs={12}>
        <Typography variant="body1">
          <strong>Notificaciones</strong>
        </Typography>
      </Grid>
      {Object.values(NotificationEnum)
        .filter((n) => shownNotifications.has(n))
        .map((notificationType) => {
          return (
            <React.Fragment key={notificationTranslation[notificationType]}>
              <Grid
                className={classes.gridPermissionsSwitchesContainer}
                item
                xs={12}
              >
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    className={classes.notificationSwitch}
                  >
                    {notificationTranslation[notificationType]}
                  </Typography>
                </Grid>
                <Grid className={classes.gridItemInputs} item xs={2}>
                  <Switch
                    color="primary"
                    checked={userEditableInfo.notifications[notificationType]}
                    onChange={(e) =>
                      dispatch({
                        type: UserActionType.NOTIFICATION_CHANGE,
                        notification: notificationType,
                        value: e.target.checked,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid className={classes.gridItem} item xs={12}>
                <Divider />
              </Grid>
            </React.Fragment>
          );
        })}
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  gridPermissionsSwitchesContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    height: 28,
  },

  gridItemPermisssionsTitle: {
    paddingTop: "0px !important",
  },
  gridItemPermisssionsTitleIcon: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "0px !important",
  },
  gridItemSubtitle: {
    marginBottom: 8,
    paddingBottom: "0px !important",
  },
  gridItem: {
    marginBottom: 7,
  },
  gridItemInputs: {
    paddingTop: "0px !important",
  },
  notificationSwitch: {
    color: alpha(palette.text.primary, 0.8),
  },
}));
