import React, { useMemo } from "react";
import { Theme, makeStyles, Typography, Chip } from "@material-ui/core";
import { Tooltip } from "components";
import { BaseElement } from "components/List";
import { parseNumber } from "utils";
import {
  clientMapping,
  clientOrder,
  Client,
  ExcavatorBalance,
  LoadStatus,
} from "interfaces";
import { loadStatusColor } from "styles/sicStatusColor";

interface Props {
  totalMovement: number | null;
  targetMovement: number | null;
  movementPerClient: ExcavatorBalance["movementPerClient"];
  spacing: string[];
  grayOut: boolean;
  status: LoadStatus | null;
}

export const BalanceListMovementCell: React.FC<Props> = ({
  totalMovement,
  targetMovement,
  movementPerClient,
  spacing,
  grayOut,
  status
}) => {
  const classes = useStyles({
    colorChip: status
  });

  const movementText = useMemo(() => {
    const loadTons = totalMovement === null ||  totalMovement === 0 ? "-" : parseNumber(totalMovement);
    if (targetMovement && targetMovement !== null) {
      if(status === null || loadTons === "-"){
        return (
          <Typography variant="subtitle1">
            {loadTons} ({parseNumber(targetMovement)})
          </Typography>
        )
      } 
      return (
        <div className={classes.container}>
          <Chip 
            label={
              <Typography variant="subtitle1">
                {loadTons}
                </Typography>
            } 
            classes={{root: classes.statusChip}} />
          <Typography className={classes.space} variant="subtitle1">
            ({parseNumber(targetMovement)})
          </Typography>
        </div>
      )
    }
    return (
      <Typography variant="subtitle1">
        {loadTons}
      </Typography>
    );
  }, [totalMovement, targetMovement, classes.statusChip, status, classes.space, classes.container]);

  const tooltipComponents = useMemo(() => {
    if (movementPerClient === null) return "";
    return Object.entries(movementPerClient)
      .sort(
        ([clientA, _], [clientB, __]) =>
          clientOrder[clientA] - clientOrder[clientB]
      )
      .map(([client, { loadtons, target }]) => {
        const total = loadtons ? parseNumber(loadtons / 1000, 1) : "-";
        const totalWithTarget = target
          ? `${total} (${parseNumber(target / 1000, 1)})`
          : total;
        return (
          <Typography key={client} >
            <>
              <strong>{`${clientMapping[client as Client]}: `}</strong>
              {totalWithTarget}
            </>
          </Typography>
        );
      });
  }, [movementPerClient]);

  return (
    <BaseElement
      classNames={[...spacing, classes.root, grayOut ? classes.grayOut : ""]}
    >
      <Tooltip
        title={tooltipComponents}
        arrow
        placement="right"
        TransitionProps={{ enter: false, exit: false }}
      >
        {movementText}
      </Tooltip>
    </BaseElement>
  );
};

interface StyleProps {
  colorChip: LoadStatus | null;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  icon: {
    verticalAlign: "middle",
  },
  number: {
    verticalAlign: "middle",
    margin: "0 10px",
  },
  root: {
    alignItems: "center",
  },
  allignmentDiv: {
    width: "41px",
    display: "flex",
    alignItems: "center",
  },
  grayOut: {
    opacity: 0.6,
  },
  space: {
    paddingRight: 10,
  },
  statusChip:{
    background: ({colorChip}) =>
    loadStatusColor({
      theme,
      statusType: colorChip,
      color: false,
      opacityColor: true
    }), 
    color: ({colorChip}) =>
      loadStatusColor({
        theme,
        statusType: colorChip,
        color: true
    }), 
    marginRight: 5, 
    "& .MuiChip-label": {
      paddingLeft: 8,
      paddingRight: 8,
    }
  },
  container : {
    display: "flex",
    alignItems: "center"
  }
}));
