import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const BalanceIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon 
      width="24" 
      height="21" 
      viewBox="0 0 24 21" 
      fill="none" xmlns="http://www.w3.org/2000/svg"
      {...props}
      >
      <path 
        d="M13.15 6.44368C14.1275 6.1121 14.9095 5.36053 15.2545 4.42105H18.9L15.45 12.1579C15.45 13.9926 17.2555 15.4737 19.475 15.4737C21.6945 15.4737 23.5 13.9926 23.5 12.1579L20.05 4.42105H22.35V2.21053H15.2545C14.783 0.917369 13.5065 0 12 0C10.4935 0 9.217 0.917369 8.7455 2.21053H1.65V4.42105H3.95L0.5 12.1579C0.5 13.9926 2.3055 15.4737 4.525 15.4737C6.7445 15.4737 8.55 13.9926 8.55 12.1579L5.1 4.42105H8.7455C9.0905 5.36053 9.8725 6.1121 10.85 6.44368V18.7895H0.5V21H23.5V18.7895H13.15V6.44368ZM21.6255 12.1579H17.3245L19.475 7.33895L21.6255 12.1579ZM6.6755 12.1579H2.3745L4.525 7.33895L6.6755 12.1579ZM12 4.42105C11.3675 4.42105 10.85 3.92368 10.85 3.31579C10.85 2.70789 11.3675 2.21053 12 2.21053C12.6325 2.21053 13.15 2.70789 13.15 3.31579C13.15 3.92368 12.6325 4.42105 12 4.42105Z" 
        fillRule="evenodd"
        clipRule="evenodd"
        />
    </SvgIcon>
  );
};
