import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { formatLocalizedDate, localizeNumber } from "utils";
import { Remove } from "@material-ui/icons";
import { EnhancedPoint } from "interfaces";

interface Props {
  point: EnhancedPoint;
  measurement: string | null;
}

export const LineGraphPointTooltipContent: React.FC<Props> = ({
  point: { serieId, data },
  measurement,
}) => {
  const classes = useStyles();

  // The display of the metric datum changes
  // depending on the existence of change events

  return (
    <>
      {data.changeEvents.length > 0 ? (
        <>
          <div className={classes.metricContent}>
            <Typography variant="subtitle2">
              {formatLocalizedDate(data.x as Date, "HH:mm 'hrs.'")}
            </Typography>
          </div>
          <div className={classes.row}>
            <Remove className={classes.lineStyle} />
            <Typography className={classes.tooltipTitle} variant="subtitle2">
              {serieId}
            </Typography>
          </div>
          <Typography className={classes.eventDescription} variant="body2">
            {localizeNumber(data.y as number, 4)} {measurement}
          </Typography>
        </>
      ) : (
        <>
          <div className={classes.row}>
            <Remove className={classes.lineStyle} />
            <Typography className={classes.tooltipTitle} variant="subtitle2">
              {`${serieId} | ${formatLocalizedDate(
                data.x as Date,
                "HH:mm 'hrs.'"
              )}`}
            </Typography>
          </div>
          <div className={classes.metricContent}>
            <Typography className={classes.tooltipText} variant="subtitle2">
              {localizeNumber(data.y as number, 4)} {measurement}
            </Typography>
          </div>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  tooltipTitle: {
    marginTop: 2,
  },
  tooltipIndex: {
    marginRight: 5,
  },
  tooltipText: {
    marginLeft: 5,
    marginRight: 5,
  },
  lineStyle: {
    width: 25,
    height: 25,
    color: theme.palette.primary.main,
  },
  metricContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  eventDescription: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    marginLeft: 25,
  },
}));
