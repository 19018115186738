import React, { useEffect, useState } from "react";
import { Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AlertOperatorType, Operator, OperatorAlertType } from "interfaces";
import { IconWithTooltip } from "./IconWithTooltip";
import { QualificationsTooltip } from "./QualificationsTooltip";
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { operatorAlertColors } from "styles";

interface Props {
  operator: Operator;
  truckModel?: string;
  loadingData?: boolean;
}

export const OperatorAlert: React.FC<Props> = ({ operator, truckModel, loadingData }) => {

  const [alertText, setAlertText] = useState<string>();
  const [alertType, setAlertType] = useState<OperatorAlertType>(OperatorAlertType.OK);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showSup, setShowSup] = useState<number>();

  const classes = useStyles({
    alertColor: alertType
  });
  
  useEffect(
    () => {
      const statusWithAlert = [OperatorAlertType.WARNING, OperatorAlertType.UNABLE];
      const currentAlertByModel = operator?.qualifs?.models.find((model)=>model.model === truckModel)
      const alertConfig = AlertOperatorType.find((alert)=> alert.alert === operator?.qualifs?.maxAlert?.type);
      
      if (truckModel && currentAlertByModel) {
        const currentAlertType =  AlertOperatorType.find((alert)=> alert.alert === currentAlertByModel?.alert);
        setAlertText(currentAlertType?.textOperatorAssig.replace('{model}', truckModel ?? "") )
        setAlertType(currentAlertType?.alert ?? OperatorAlertType.OK)
        setShowSup(undefined)
      }else{
        const countAlerts = operator?.qualifs?.models.filter((model)=> statusWithAlert.includes(model.alert)).length
        setAlertType(operator?.qualifs?.maxAlert?.type ?? OperatorAlertType.OK) 
        setAlertText(alertConfig?.textOperator)
        if (countAlerts && countAlerts > 1) setShowSup(countAlerts)
      }
      setShowAlert(alertConfig?.alert !== OperatorAlertType.OK && alertType !== OperatorAlertType.OK && !loadingData)
    },
    [operator, truckModel, alertType, loadingData]
  ); 

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          { showAlert ? 
            <IconWithTooltip 
              icon={<AssignmentLateIcon classes={{ root: classes.alertColor}}/>} 
              tooltipText={ alertText ?? "" } 
              showSup={ showSup }
              /> 
              : ""
          }
        </Grid>
        <Grid item >
          <QualificationsTooltip operator={operator} />
        </Grid>
      </Grid>
    </>
  )
};

  interface StyleProps {
      alertColor?: OperatorAlertType;
  }
    
  const useStyles = makeStyles<Theme, StyleProps>((theme) => {

  return {
      alertColor: {
      color: ({ alertColor }) =>
          operatorAlertColors({
          theme,
          statusType: alertColor,
          color: true,
          }),
      },
  };
  });