import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  ChipGroup,
  ChipSelectorOptions,
  Header,
  ScreenContainer,
} from "components";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useData } from "hooks";
import { ErrorMsg } from "interfaces";
import { StatusInterface } from "contexts/ShifChangeContext";
import TopInfo from "./TopInfo";
import { BusTable } from "./BusTable";
import { STANDARD_POLLING } from "App";
import { GreenTicketIcon } from 'components/icons/GreenTicket';
import { ShovelIcon } from 'components/icons/ShovelIcon';
import { NoDataScreen } from "./NoDataScreen";

export type IData = IBuses[];

export interface IBuses {
  bus: string;
  busData: BusData[];
}

export interface BusData {
  TYPE: string;
  NAME: string;
  EQUIPMENT: string;
  DESTINATION: string;
  TRANSPORTATION: string;
}

export interface IObjectNotAsigned {
  type: string
  data: IDataNotAsigned[]
}

interface IDataNotAsigned {
  name: string
  bus: string
  ubication: string
  equipType: string
  orderColumn: number
}

export const ShiftChangeScreen: React.FC = () => {
  const classes = useStyles();
  const [selectedBus, setSelectedBus] = useState<string>("Todos los buses");
  const [filteredDataOperators, setFilteredDataOperators] = useState<IData>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const { data, firstLoading, refetch: refetchStatus } = useData<StatusInterface>(
    {
      config: "/shift-change/visualization/status",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_STATUS_SHIFT_CHANGE
  );
  
  const {data: dataOp, firstLoading: loadingPDF} = useData<IObjectNotAsigned[]>(
    {
        config: "/shift-change/visualization/all-data-not-asigned",
        options: {
            useCache: false,
        },
        ...STANDARD_POLLING,
    },
    ErrorMsg.GET_STATUS_SHIFT_CHANGE
);

  const { data: dataOperators, firstLoading: operatorsLoading } =
    useData<IData>(
      {
        config: "/shift-change/visualization/not-operators",
        options: {
          useCache: false,
        },
        ...STANDARD_POLLING,
      },
      ErrorMsg.GET_STATUS_SHIFT_CHANGE
    );

  const { shift, dateFormat, maxPage, pages } = data;

  const initial_value_buses = useMemo(
    () => ({
      id: "Todos los buses",
      name: "Todos los buses",
      count: 0,
    }),
    []
  );

  const search = useCallback((data: IData, searchTerm: string): IData => {
    return data
      .map((parent) => {
        const matchingItems = parent.busData.filter(
          (child) =>
            child.NAME.toLowerCase().includes(searchTerm.toLowerCase()) ||
            child.EQUIPMENT.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (
          matchingItems.length > 0 ||
          parent.bus.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return {
            ...parent,
            busData: matchingItems.length > 0 ? matchingItems : parent.busData,
          };
        }

        return null;
      })
      .filter((parent): parent is IBuses => parent !== null);
  }, []);

  useEffect(() => {
    if (dataOperators) {
      let filtered: IData = [];
      if (searchValue === "") {
        if (selectedBus !== initial_value_buses.name) {
          filtered = dataOperators.filter((dat) => dat.bus === selectedBus);
        } else {
          filtered = dataOperators;
        }
      } else {
        filtered = search(dataOperators, searchValue);
      }
      setFilteredDataOperators(filtered);
    }
  }, [selectedBus, searchValue, dataOperators, initial_value_buses, search]);

  const buses: ChipSelectorOptions[] = useMemo(() => {
    const busOptions: ChipSelectorOptions[] = [initial_value_buses];
    dataOperators?.forEach((datum) => {
      if (parseInt(datum.bus)) {
        busOptions.push({
          id: datum.bus,
          name: `Bus ${datum.bus}`,
        });
      }
    });
    return busOptions;
  }, [dataOperators, initial_value_buses]);

  return (
    <>
      <Header
        elapsedMinutes={null}
        activeNavbar={true}
        loading={firstLoading && operatorsLoading}
        searchPlaceholder={"Buscar por operador o equipo"}
        searchValue={searchValue}
        onSearchQueryChange={setSearchValue}
      />
      <ScreenContainer>
        <TopInfo
          shift={shift}
          dateFormat={dateFormat}
          maxPage={maxPage}
          pages={pages}
          paginator={false}
          dataOperators={dataOperators}
          data={dataOp}
          status={data.status}
          refetchStatus={refetchStatus}
        />
        {
          data && data.status === 'UPDATING' ?
            <NoDataScreen status={data.status} />
            :
          <>
            {data && data.pages && data.pages.length > 0 ?
            <Grid container className={classes.chipsInfo}>
              <Grid item>
                <ChipGroup
                  value={selectedBus}
                  options={buses}
                  onChange={(value) => setSelectedBus(value.toString())}
                />
              </Grid>
              <Grid item style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flex: '1 1 0',
              }}>
                <Grid container className={classes.iconContainers}>
                  <ShovelIcon height={24} width={24} />
                  <Typography style={{ fontSize: '16px', fontWeight: 'normal' }}>
                    Palero
                  </Typography>
                  <Grid item className={classes.line} />
                  <GreenTicketIcon height={24} width={24} />
                  <Typography style={{ fontSize: '16px', fontWeight: 'normal' }}>
                    Disponible
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
              : <></>
          }
            <Grid container className={classes.operatorsBox}>
              {filteredDataOperators.map((bus, index) => (
                <BusTable key={index} bus={bus.bus} busData={bus.busData} />
              ))}
            </Grid>
          
          </>
        }
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  chipsInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: 'center',
    paddingLeft: 34,
    paddingRight: 22,
    marginTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  operatorsBox: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 34,
    paddingRight: 22,
    flexDirection: "row",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.background.paper
        : theme.palette.background.default,
    flex: '1 1 0',
    paddingBottom: 34,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  iconContainers : {
    width: '260px',
    height: '40px',
    borderRadius: '6px',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor:
    theme.palette.type === "light"
    ? theme.palette.background.default
    : theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: 248
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  line: {
    width: '0px',
    height: '30px',
    border: '0.5px solid rgba(0,0,0,0.25)',
  }
}));
