import React, { createContext } from "react";
import {
  DataContextInterface,
  initialDataContextState,
  ErrorMsg,
} from "interfaces";
import { useData, usePathnameChange } from "hooks";
import { AppRoute } from "utils";
import { FiltersProps } from "interfaces/relieve.interface";
import { ContextProviderProps } from "contexts";
export const RelieveFiltersContext = createContext<DataContextInterface<FiltersProps>>(
  initialDataContextState
);

export const RelieveFiltersProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
   const { refetch, ...other } = useData<FiltersProps>(
    {
      config: {
        url: "/operator-assignment/relay/filters",
        method: "GET",
      },
      options: {
        useCache: false,
        manual: true,
      },
    },
    ErrorMsg.GET_FILTERS_RELAY
  );

  // Proactively keep data updated when entering on every page that renders it
  usePathnameChange(
    () => refetch(),
    [AppRoute.OPERATORS_SCREEN]
  );

  return (
    <RelieveFiltersContext.Provider value={{ ...other, refetch }}>
      {children}
    </RelieveFiltersContext.Provider>
  );
};
