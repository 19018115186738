import Papa from "papaparse";
import React, { useCallback, useContext } from "react";
import { Typography, makeStyles, Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import { useSnackbar } from "notistack";
import { isEqual } from "lodash";

import { TruckAssignmentDailyPlanTable } from "./TruckAssignmentDailyPlanTable";
import { TruckAssignmentDailyPlanShiftOptions } from "./TruckAssignmentDailyPlanShiftOptions";
import { TruckAssignmentDailyPlanTotalRow } from "./TruckAssignmentDailyPlanTotalRow";
import { DailyPlanContext } from "contexts";
import { Can, DailyPlanEntry, Module } from "interfaces";
import { logError } from "services";
import { Restricted } from "components";

interface Props {
  canEdit: boolean;
}
export const TruckAssignmentDailyPlan: React.FC<Props> = ({canEdit}) => {

  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data, activeShiftPlan, saveDailyPlan, setDailyPlanCSV, loading } =
    useContext(DailyPlanContext);

  const onCSVRead = useCallback(
    ({ data, errors }: Papa.ParseResult<DailyPlanEntry>) => {
      if (Object.keys(errors).length) {
        enqueueSnackbar("Ocurrió un error al cargar el plan", {
          variant: "error",
        });
        logError("DAILY_PLAN_CSV", errors);
      } else {
        // Filter completely empty rows
        const filtered = data.filter(
          (entry) => !Object.values(entry).every((val) => val === "")
        );
        setDailyPlanCSV(filtered);
      }
    },
    [enqueueSnackbar, setDailyPlanCSV]
  );

  const handleLoadCSV = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        Papa.parse(e.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: onCSVRead,
        });
      }
      // Reset target value to accept a new file
      e.target.value = "";
    },
    [onCSVRead]
  );

  const handleDownloadTemplate = useCallback(() => {
    const link = document.createElement("a");
    link.href = require("../../../assets/files/daily_plan_template.csv");
    link.download = "daily_plan_template.csv";
    link.target = "_blank";
    link.rel = "noreferrer";
    link.click();
  }, []);
  return (
    <>
      <div className={classes.contentHeader}>
        <Typography className={classes.sectionTitle} variant="h6">
          <strong>Plan diario</strong>
        </Typography>
        <TruckAssignmentDailyPlanShiftOptions />
      </div>
      <div className={classes.tableContainer}>
        <TruckAssignmentDailyPlanTable canEdit={canEdit} />
      </div>
      <TruckAssignmentDailyPlanTotalRow />
      {canEdit && <Restricted to={Can.WRITE} at={Module.TRUCK_ASSIGNMENT}>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.buttonContainer}
            size="large"
            variant="text"
            color="inherit"
            startIcon={<GetAppIcon className={classes.textButtonText} />}
            onClick={handleDownloadTemplate}
          >
            <Typography className={classes.textButtonText} variant="h6">
              Plantilla Plan
            </Typography>
          </Button>
          <Button
            className={classes.buttonContainer}
            size="large"
            variant="text"
            color="inherit"
            component="label"
            startIcon={<PublishIcon className={classes.textButtonText} />}
          >
            <Typography className={classes.textButtonText} variant="h6">
              Cargar Plan
            </Typography>
            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              hidden
              onChange={handleLoadCSV}
            />
          </Button>
          <Button
            className={classes.buttonContainer}
            size="large"
            variant="contained"
            color="primary"
            onClick={saveDailyPlan}
            disabled={
              loading ||
              isEqual(activeShiftPlan, {}) ||
              isEqual(activeShiftPlan, data.activeShiftPlan)
            }
          >
            <Typography className={classes.containedButtonText} variant="h6">
              Guardar
            </Typography>
          </Button>
        </div>
      </Restricted>}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    contentHeader: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: 23,
      marginBottom: 23,
    },
    sectionTitle: {
      lineHeight: "24px",
      paddingRight: "30px",
    },
    tableContainer: {
      display: "flex",
      height: "100%",
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "25px",
    },
    buttonContainer: {
      minWidth: 150,
      width: "auto",
      marginLeft: "15px",
    },
    textButtonText: {
      color: palette.primary.main,
    },
    containedButtonText: {
      color: palette.common.white,
    },
    downloadLink: {
      textDecoration: "none",
    },
  };
});
