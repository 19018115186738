import grey from "@material-ui/core/colors/grey";
import { alpha, createTheme } from "@material-ui/core/styles";
import { PracticeEnum } from "interfaces";
import { commonColors } from "styles";
import { bhpTypography } from "../bhpTypography";
import { breakpoints } from "styles/theme/breakpoints";
import { ColorStatus } from "interfaces/heatMap.interface";
export const lightTheme = createTheme({
  graph: {
    crosshair: {
      line: {
        stroke: commonColors.common.black,
      },
    },
    textColor: commonColors.neutralText.primary,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    labels: {
      text: {
        fontSize: "1rem",
        fontWeight: 400,
        fill: commonColors.neutralText.primary,
      },
    },
    axis: {
      domain: {
        line: {
          stroke: alpha("#979797", 0.2),
          strokeWidth: 1,
        },
      },
      legend: {
        text: {
          fontSize: "1.1rem",
          fill: "rgba(50, 52, 65, 1)",
        },
      },
      ticks: {
        line: {
          stroke: alpha("#979797", 0.2),
          strokeWidth: 1,
        },
        text: {
          fontSize: "1rem",
          fill: "rgba(50, 52, 65, 1)",
        },
      },
    },
    grid: {
      line: {
        stroke: alpha("#979797", 0.2),
        strokeWidth: 1,
      },
    },
  },
  palette: {
    type: "light",
    ...commonColors,
    collapsedRow: {
      background: grey[200],
    },
    avatar: {
      background: "#2D3339",
    },
    chip: {
      background: grey[300],
      text: commonColors.neutralText.primary,
      contrastText: "#FFFFFF",
      avatar: "#ADAEB3",
      focus: "#4A4B58",
      focusAvatar: "#22232F",
    },
    alertCard: {
      default: "#FFFFFF",
      warning: "#ED6C02",
      alert: "#D32F2F",
    },
    background: {
      default: "#F5F5F5",
      paper: "#FFFFFF",
    },
    primary: {
      main: "#DD5900",
      dark: "#A42800",
      light: "#FF893A",
      contrastText: "#000000",
    },
    secondary: {
      main: "#4A4B58",
      dark: "#22232F",
      light: "#767785",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#2E7D32",
      dark: "#1B5E20",
      light: "#4CAF50",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#0288D1",
      dark: "#01579B",
      light: "#03A9F4",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#ED6C02",
      dark: "#E65100",
      light: "#FF9800",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#D32F2F",
      dark: "#C62828",
      light: "#EF5350",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "rgba(50, 52, 65, 1)",
      secondary: "rgba(50, 52, 65, 0.6)",
      disabled: "rgba(50, 52, 65, 0.38)",
      hint: "rgba(50, 52, 65, 0.38)",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      focus: "rgba(0, 0, 0, 0.12)",
    },
    graphSeries: ["#DD5900", "#1D4F91", "#0099CC", "#035D55", "#663366"],
    graphColor: {
      blue: "#0288D1",
      orange: "#DD5900"
    },
    performanceRatingsColors: {
      [PracticeEnum.LUNCH]: "#DD5900",
      [PracticeEnum.DINNER]: "#1D4F91",
      [PracticeEnum.FUEL_LOAD]: "#0099CC",
      // [PracticeEnum.IMMOB]: "#9E9D24",
      [PracticeEnum.LOST_TRUCK]: "#035D55",
      ALL: "#663366",
    },
    movementGraphBar: alpha("#DD5900", 0.5),
    movementTarget: "#0A6CB4",
    mineUtilizationColors: ["#DD5900", "#0A6CB4"],
    kpi: {
      background: "#FFFFFF",
    },
    heatMap: {
      [ColorStatus.BAD]: "#EF5350",
      [ColorStatus.REGULAR]: alpha("#ED6C02", 0.55),
      [ColorStatus.GOOD]: "#FFFFFF",
      [ColorStatus.VERY_GOOD]: alpha("#2E7D32", 0.75),
    },
    heatMapText: {
      [ColorStatus.BAD]: "#323441",
      [ColorStatus.REGULAR]: "#323441",
      [ColorStatus.GOOD]: "#323441",
      [ColorStatus.VERY_GOOD]: "#323441",
    },
    heatMapLegend: {
      other: alpha("#4A4B58", 0.08),
    },
    shiftChange: {
      tableHeaderBackground: '#2D3339'
    }
  },
  typography: {
    ...bhpTypography,
  },
  breakpoints,
  planByTheme: {
    primary: {
      600: "black",
      900: grey[50],
    },
    grey: { 300: "#d1d1d1" },
    white: grey[600],
    green: {
      300: "#2c7a7b",
    },
    scrollbar: {
      thumb: {
        bg: grey[400],
      },
     
    },
    loader: {
      teal: "#5DDADB",
      purple: "#3437A2",
      pink: "#F78EB6",
      bg: "#171923db",
    },
    gradient: {
      blue: {
        300: "#002eb3",
        600: "#002360",
        900: "#051937",
      },
    },

    text: {
      grey: {
        300: "##323441",
        500: "##323441",
      },
    },

    timeline: {
      divider: {
        bg: "#718096",
      },
    },
    entityColor: [
      "#DD5900",
      "#663366",
      "#1D4F91",
      "#01515F",
      "#035D55",
      "#2B5368",
      "#252525",
      "#F77F00",
      "#993399",
      "#0A6CB4",
      "#007E98",
      "#06AD96",
      "#45768B",
      "#666666",
      "#FCBA5E",
      "#CC6699",
      "#0099CC",
      "#6EC9DA",
      "#ABCC66",
      "#6BA4B8",
      "#B7B9BB",
    ]
  },
});
