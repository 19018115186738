import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

interface ChipGroupSkeletonProps {
  chipNumber: number;
}

export const ChipGroupSkeleton: React.FC<ChipGroupSkeletonProps> = ({
  chipNumber,
}) => {
  const classes = useStyles();

  return (
    <>
      {Array.from(Array(chipNumber).keys()).map((i) => (
        <Skeleton
          className={classes.chip}
          variant="rect"
          key={`${i}-chipGroupSkeleton`}
          height={32}
        />
      ))}
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  chip: {
    marginRight: 10,
    borderRadius: 20,
    maxWidth: 85,
    width: "100%",
    "&:last-child": {
      marginRight: 0,
    },
  },
}));
