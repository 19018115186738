import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ChipGroup, Footer, Header, ScreenContainer } from "components";
import { User, Can, Module, ErrorMsg, UpdateAppParamsBody } from "interfaces";
import Fuse from "fuse.js";
import { Button, makeStyles } from "@material-ui/core";
import { useAppParams, useData, useSearch } from "hooks";
import { AppParamsContext, UserContext } from "contexts";
import { UserEditDialog, AppParamsTable, UsersTable } from ".";
import { STANDARD_POLLING } from "App";
import { logError } from "services";
import { useSnackbar } from "notistack";
import { includes } from "lodash";

const filterOptions: Fuse.IFuseOptions<User> = {
  keys: ["email", "role.name"],
  threshold: 0.2,
  minMatchCharLength: 3,
};

enum ViewType {
  MANAGE_USERS = "USUARIOS",
  APP_PARAMS = "PARAMETROS",
}

export enum DialogActions {
  ADD = "Agregar",
  EDIT = "Editar",
}

export interface DialogProps {
  open: boolean;
  user: User | null;
}

const viewOptions = Object.values(ViewType).map((value) => ({
  id: value,
  name: value,
}));

export const AdministrationScreen: React.FC = () => {
  const classes = useStyles();

  const [viewType, setViewType] = useState<ViewType>(ViewType.MANAGE_USERS);
  const { isAllowedTo } = useContext(UserContext);
  const { loadingAppParams, refetchAppParams } = useContext(AppParamsContext);
  const { enqueueSnackbar } = useSnackbar();

  const [dialogData, setDialogData] = useState<DialogProps>({
    open: false,
    user: null,
  });

  const {
    pendingChanges,

    resetPendingChanges,
  } = useAppParams();

  const canManageUsers = isAllowedTo(Can.WRITE, Module.ADMINISTRATION_PANEL);

  const {
    data: users,
    firstLoading: loadingUsers,
    refetching: refetchingUsers,
    refetch: refetchUsers,
  } = useData<User[]>(
    {
      config: "/administration/users",
      options: {
        useCache: false,
      },
      ...STANDARD_POLLING,
    },
    ErrorMsg.GET_USERS
  );

  const { refetch: updateAppParams, error } = useData<UpdateAppParamsBody>({
    config: {
      url: "/administration/params",
      method: "PATCH",
    },
    options: {
      manual: true,
    },
  });

  const [searchValue, setSearchValue, , searchResults] = useSearch<User>(
    users ?? null,
    filterOptions
  );

  const newAppParams = useMemo<UpdateAppParamsBody>(
    () =>
      Object.entries(pendingChanges).map(([key, value]) => ({
        id: +key,
        value: value,
      })),
    [pendingChanges]
  );

  const saveButtonDisabled = useMemo(
    () =>
      includes(Object.values(pendingChanges), "") ||
      newAppParams.length === 0 ||
      loadingAppParams,
    [loadingAppParams, newAppParams.length, pendingChanges]
  );

  const handleUpdateAppParamsClick = useCallback(async () => {
    try {
      await updateAppParams({ data: newAppParams });
      enqueueSnackbar(`Cambios guardados`, { variant: "success" });
      resetPendingChanges();
      refetchAppParams();
    } catch (e) {
      logError("ADMIN-PANEL-APP-PARAMS", `Error: ${e}`);
    }
  }, [
    enqueueSnackbar,
    newAppParams,
    refetchAppParams,
    resetPendingChanges,
    updateAppParams,
  ]);

  const onAddUserClick = useCallback(
    () => setDialogData((prev) => ({ ...prev, open: true })),
    []
  );

  const onCloseDialog = useCallback(
    (e?: unknown, reason?: "backdropClick" | "escapeKeyDown") => {
      if (reason !== "backdropClick") {
        setDialogData({ open: false, user: null });
      }
    },
    [setDialogData]
  );

  const onChipChange = useCallback(
    (value: string | number) => {
      setViewType(value as ViewType);
      resetPendingChanges();
    },
    [resetPendingChanges]
  );

  useEffect(() => {
    if (error && error?.code !== "ERR_CANCELED") {
      const errorMessage =
        error?.code === "ERR_BAD_REQUEST"
          ? "Los códigos TUM no pueden repetirse entre las 3 columnas"
          : ErrorMsg.UPDATE_APP_PARAMS;
      enqueueSnackbar(errorMessage, { variant: "error" });
      logError("USE-DATA", errorMessage);
    }
  }, [enqueueSnackbar, error]);

  return (
    <>
      <Header
        searchValue={searchValue}
        onSearchQueryChange={setSearchValue}
        searchPlaceholder="Buscar por correo o rol"
      />
      <ScreenContainer className={classes.screenContainer}>
        <ChipGroup
          classes={{ chipGroupRoot: classes.chip }}
          value={viewType}
          options={viewOptions}
          onChange={onChipChange}
        />
        {viewType === ViewType.MANAGE_USERS ? (
          <UsersTable
            data={searchResults}
            refetchUsers={refetchUsers}
            loading={loadingUsers || refetchingUsers}
          />
        ) : (
          <AppParamsTable />
        )}
        <UserEditDialog
          action={DialogActions.ADD}
          user={dialogData.user}
          open={dialogData.open}
          onClose={onCloseDialog}
          refetchUsers={refetchUsers}
        />
      </ScreenContainer>
      {canManageUsers && (
        <Footer className={classes.footer}>
          {viewType === ViewType.MANAGE_USERS ? (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={onAddUserClick}
            >
              AGREGAR USUARIO
            </Button>
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleUpdateAppParamsClick}
              disabled={saveButtonDisabled}
            >
              GUARDAR
            </Button>
          )}
        </Footer>
      )}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  screenContainer: {
    marginBottom: 64,
    flex: "1 1 auto",
  },
  footer: {
    position: "fixed",
    left: 0,
    margin: 0,
    right: 0,
    bottom: 0,
  },
  chip: {
    maxWidth: 91,
    marginBottom: 28,
  },
  button: {
    color: palette.common.white,
    minHeight: 42,
    marginLeft: "auto",
  },
}));
