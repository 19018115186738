import React, { useMemo, useCallback, useState } from "react";
import { CellProps, HeaderCellProps, NestedKeyOf, Order } from "interfaces";
import {
  makeStyles,
  Theme,
  Table,
  TableContainer,
  TableRow,
  Paper,
  TableBody,
} from "@material-ui/core";
import { orderBy as _orderBy } from "lodash";
import { AutoSizer } from "components";
import { CustomTableHeader, CustomTableRow } from ".";

interface CustomTableProps<T, K> {
  data: T[];
  headers: HeaderCellProps<K>[];
  renderCells?: (entity: T) => CellProps<K>[];
  renderRows?: (entities: T[]) => React.ReactNode | React.ReactNode[];
  loadingData: boolean;
  disableHeight?: boolean;
  tableKey: string;
}

export const CustomTable = <T extends unknown, K extends NestedKeyOf<T>>({
  data,
  headers,
  loadingData,
  renderCells = () => [],
  renderRows,
  disableHeight,
  tableKey,
}: CustomTableProps<T, K>) => {
  const classes = useStyles();

  const [order, setOrder] = useState<Order>(Order.ASC);
  const [orderBy, setOrderBy] = useState<K | string>();

  const handleRequestSort = useCallback(
    (_event: React.MouseEvent<unknown>, property: K | string) => {
      setOrder((prevOrder) =>
        orderBy === property && prevOrder === Order.ASC ? Order.DESC : Order.ASC
      );
      setOrderBy(property);
    },
    [orderBy]
  );

  const sortedData = useMemo(() => {
    if (!orderBy) return data;
    return _orderBy(data, orderBy, [order]);
  }, [data, orderBy, order]);

  const rows = useMemo(
    () => sortedData.map((data) => renderCells(data)),
    [sortedData, renderCells]
  );

  return (
    <div className={classes.tableContainer}>
      <AutoSizer disableWidth disableHeight={disableHeight}>
        {({ height }) => (
          <TableContainer
            key={`${tableKey}-container`}
            style={{ maxHeight: height }}
            component={Paper}
          >
            <Table key={tableKey} stickyHeader size="medium">
              <CustomTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                loading={loadingData}
                headCells={headers}
                headerKey={tableKey}
                key={`${tableKey}-header`}
              />
              <TableBody key={`${tableKey}-body`}>
                {renderRows ? (
                  renderRows(sortedData)
                ) : rows.length > 0 ? (
                  rows.map((cellDetails, i) => (
                    <CustomTableRow
                      key={`${tableKey}-row-${i}`}
                      cellDetails={cellDetails}
                      rowKey={`${tableKey}-row-${i}`}
                    />
                  ))
                ) : (
                  <TableRow
                    key={`${tableKey}-empty-row`}
                    className={classes.emptyRow}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AutoSizer>
    </div>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => {
  const cellBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;
  return {
    tableContainer: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      flex: "1",
      paddingBottom: 24,
    },
    tableHeadRoot: {
      position: "sticky",
      zIndex: 1,
      top: 0,
    },
    tableCellHeader: {
      backgroundColor: cellBackground,
      color: palette.getContrastText(cellBackground),
      paddingTop: 10,
      paddingBottom: 9,
    },
    sortLabel: {
      color: palette.getContrastText(cellBackground),
    },
    sortIcon: {
      color: `${palette.getContrastText(cellBackground)} !important`,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    linearLoader: {
      height: 5,
      position: "absolute",
      width: "100%",
      zIndex: 2,
    },
    cell: {
      paddingTop: 5,
      paddingBottom: 5,
    },
    emptyRow: {
      height: 53,
    },
  };
});
