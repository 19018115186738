import {
    Typography,
    Link,
    makeStyles,
    useTheme,
    Button,
  } from "@material-ui/core";
  import React, { useMemo } from "react";
  import { ErrorDarkIcon, ErrorLightIcon } from "../../components/icons";
import { UpdatingDarkIcon } from "components/icons/UpdatingLightDark";
import { UpdatingLightIcon } from "components/icons/UpdatingLightIcon";
  
  const FRONT_HOST = process.env.REACT_APP_FRONT_HOST;
  
  interface IProps {
    status: string
  }

  export const NoDataScreen: React.FC<IProps> = ({
    status,
  }) => {
    const classes = useStyles();
    const theme = useTheme();
  
    const Icon = useMemo(() => {
      if (status === 'UPDATING') {
        return theme.palette.type === "dark" ? UpdatingDarkIcon : UpdatingLightIcon;
      }
      return theme.palette.type === "dark" ? ErrorDarkIcon : ErrorLightIcon;
    }, [theme.palette.type, status]);
  
    return (
      <div className={classes.layout}>
        <Icon className={classes.icon} />
        {status === "NO-ACTIVE" ?
            <>
                <Typography className={classes.title} variant="h4">
                    <b>Vista Temporalmente Desactivada </b>
                </Typography>
                <Typography variant="h5" className={classes.contactText}>
                La vista se encontrará activa nuevamente en el horario de cambio de turno (7:10hrs o 19:10hrs)
                </Typography>
            </> :
            status === 'UPDATING' ? 
            <>
            <Typography className={classes.title} variant="h4">
                <b>Datos de Asignación No Disponibles </b>
            </Typography>
            <Typography variant="h5" className={classes.contactText}>
            Espera mientras se asignan los buses o puedes regresar más tarde.
            </Typography>
        </> :
            <>
                <Typography variant="h5" className={classes.contactText}>
                    Contacta a tu equipo de soporte interno ó a <b>Service Desk</b> al +188
                    800 201 253 / +56 44 208 1228
                </Typography>
                <Link
                    className={classes.link}
                    variant="h5"
                    href="https://bhp.service-now.com/sp?id=sc_cat_item&sys_id=4b63e79adb6a43004b46fc1dbf9619ec"
                    target={"_blank"}
                >
                    Crear un ticket en Service Now
                </Link>
            </>
        }
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          href={FRONT_HOST}
        >
          <Typography variant="h6">Volver al inicio</Typography>
        </Button>
      </div>
    );
  };
  
  const useStyles = makeStyles(({ palette }) => ({
    layout: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "80vh",
      justifyContent: "center",
      backgroundColor:
        palette.type === "light"
          ? palette.background.paper
          : palette.background.default,
    },
    icon: {
      width: 420,
      height: 420,
    },
    title: {
      padding: 15,
    },
    contactText: {
      color:
        palette.type === "light" ? palette.text.secondary : palette.common.white,
    },
    link: {
      color: "#0099CC",
    },
    button: {
      margin: 40,
      minHeight: 36,
      color: palette.common.white,
      marginBottom: 70,
    },
  }));
  