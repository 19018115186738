import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "styles";
import { ContextProviderProps } from "contexts";

interface AppThemeInterface {
  toggleMode: () => void;
}

export const AppThemeContext = createContext<AppThemeInterface>({
  toggleMode: () => null,
});

export const AppThemeProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [darkMode, setDarkMode] = useState<boolean>(
    () => localStorage.getItem("darkMode") === "true"
  );

  const toggleMode = useCallback(() => {
    setDarkMode((prev: boolean) => !prev);
  }, []);

  useEffect(
    () => localStorage.setItem("darkMode", darkMode.toString()),
    [darkMode]
  );

  const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode]);

  const appThemeState = useMemo(
    () => ({
      toggleMode,
    }),
    [toggleMode]
  );

  return (
    <AppThemeContext.Provider value={appThemeState}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AppThemeContext.Provider>
  );
};
