import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ExcavatorIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="52"
      height="48"
      viewBox="0 0 52 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.6786 23.1285L34.407 21.6121V21.1979L31.6786 22.7143V23.1285Z"
        fill="#373C3F"
      />
      <path
        d="M31.6784 22.7141L34.4068 21.1977L34.0834 21.0151L31.3128 22.6228L31.6784 22.7141Z"
        fill="#505453"
      />
      <path
        d="M31.3127 23.0372L31.6784 23.1285V22.7143L31.3127 22.623V23.0372Z"
        fill="#1C2225"
      />
      <path
        d="M30.6097 22.6285L33.3803 21.0209V20.6067L30.6097 22.2143V22.6285Z"
        fill="#373C3F"
      />
      <path
        d="M30.6097 22.2139L33.3803 20.6062L33.0569 20.4237L30.2863 22.0314L30.6097 22.2139Z"
        fill="#505453"
      />
      <path
        d="M30.286 22.4463L30.6095 22.6288V22.2146L30.286 22.0321V22.4463Z"
        fill="#1C2225"
      />
      <path
        d="M29.5826 22.0347L32.3462 20.4271V20.0199L29.5826 21.6205V22.0347Z"
        fill="#373C3F"
      />
      <path
        d="M29.5826 21.62L32.3461 20.0194L32.0297 19.8298L29.2591 21.4375L29.5826 21.62Z"
        fill="#505453"
      />
      <path
        d="M29.2586 21.8522L29.5821 22.0347V21.6205L29.2586 21.438V21.8522Z"
        fill="#1C2225"
      />
      <path
        d="M28.5557 21.4445L31.3193 19.8368V19.4226L28.5557 21.0303V21.4445Z"
        fill="#373C3F"
      />
      <path
        d="M28.5557 21.0299L31.3193 19.4223L31.0028 19.2397L28.2322 20.8474L28.5557 21.0299Z"
        fill="#505453"
      />
      <path
        d="M28.2326 21.2628L28.5561 21.4454V21.0312L28.2326 20.8486V21.2628Z"
        fill="#1C2225"
      />
      <path
        d="M27.5289 20.8466L30.2924 19.246V18.8318L27.5289 20.4324V20.8466Z"
        fill="#373C3F"
      />
      <path
        d="M27.5289 20.432L30.2924 18.8314L29.976 18.6418L27.2054 20.2495L27.5289 20.432Z"
        fill="#505453"
      />
      <path
        d="M27.2053 20.6646L27.5288 20.8471V20.4329L27.2053 20.2504V20.6646Z"
        fill="#1C2225"
      />
      <path
        d="M26.5028 20.2594L29.2664 18.6517V18.2375L26.5028 19.8452V20.2594Z"
        fill="#373C3F"
      />
      <path
        d="M26.5028 19.8446L29.2664 18.237L28.95 18.0544L26.1794 19.6621L26.5028 19.8446Z"
        fill="#505453"
      />
      <path
        d="M26.1796 20.0752L26.5031 20.2577V19.8435L26.1796 19.661V20.0752Z"
        fill="#1C2225"
      />
      <path
        d="M25.4755 19.6601L28.2391 18.0595V17.6453L25.4755 19.2459V19.6601Z"
        fill="#373C3F"
      />
      <path
        d="M25.4755 19.2454L28.2391 17.6448L27.9227 17.4623L25.1521 19.0629L25.4755 19.2454Z"
        fill="#505453"
      />
      <path
        d="M25.152 19.4773L25.4755 19.6598V19.2456L25.152 19.0631V19.4773Z"
        fill="#1C2225"
      />
      <path
        d="M24.4498 19.0695L27.2134 17.4618V17.0476L24.4498 18.6553V19.0695Z"
        fill="#373C3F"
      />
      <path
        d="M24.45 18.6549L27.2136 17.0473L26.8972 16.8647L24.1266 18.4724L24.45 18.6549Z"
        fill="#505453"
      />
      <path
        d="M24.1263 18.8878L24.4498 19.0704V18.6562L24.1263 18.4736V18.8878Z"
        fill="#1C2225"
      />
      <path
        d="M23.4225 18.476L26.1861 16.8754V16.4612L23.4225 18.0618V18.476Z"
        fill="#373C3F"
      />
      <path
        d="M23.4225 18.0614L26.1861 16.4607L25.8696 16.2782L23.099 17.8788L23.4225 18.0614Z"
        fill="#505453"
      />
      <path
        d="M23.099 18.2931L23.4225 18.4756V18.0614L23.099 17.8789V18.2931Z"
        fill="#1C2225"
      />
      <path
        d="M22.3958 17.886L25.1593 16.2783V15.8641L22.3958 17.4718V17.886Z"
        fill="#373C3F"
      />
      <path
        d="M22.3959 17.4715L25.1595 15.8638L24.843 15.6813L22.0724 17.2889L22.3959 17.4715Z"
        fill="#505453"
      />
      <path
        d="M22.0722 17.7027L22.3957 17.8852V17.471L22.0722 17.2885V17.7027Z"
        fill="#1C2225"
      />
      <path
        d="M21.3693 17.2873L24.1328 15.6867V15.2725L21.3693 16.8731V17.2873Z"
        fill="#373C3F"
      />
      <path
        d="M21.3695 16.8726L24.133 15.272L23.8096 15.0895L21.046 16.6901L21.3695 16.8726Z"
        fill="#505453"
      />
      <path
        d="M21.0457 17.1039L21.3692 17.2864V16.8722L21.0457 16.6897V17.1039Z"
        fill="#1C2225"
      />
      <path
        d="M20.3423 16.6998L23.1059 15.0922V14.678L20.3423 16.2856V16.6998Z"
        fill="#373C3F"
      />
      <path
        d="M20.3423 16.2852L23.1059 14.6775L22.7824 14.495L20.0188 16.1026L20.3423 16.2852Z"
        fill="#505453"
      />
      <path
        d="M20.0189 16.5169L20.3424 16.6994V16.2852L20.0189 16.1027V16.5169Z"
        fill="#1C2225"
      />
      <path
        d="M19.3158 16.1017L22.0793 14.5011V14.0869L19.3158 15.6946V16.1017Z"
        fill="#373C3F"
      />
      <path
        d="M19.3158 15.6941L22.0793 14.0865L21.7559 13.9039L18.9923 15.5046L19.3158 15.6941Z"
        fill="#505453"
      />
      <path
        d="M18.9924 15.9181L19.3159 16.1006V15.6935L18.9924 15.5039V15.9181Z"
        fill="#1C2225"
      />
      <path
        d="M18.2888 15.5111L21.0523 13.9035V13.4893L18.2888 15.0969V15.5111Z"
        fill="#373C3F"
      />
      <path
        d="M18.2889 15.0965L21.0525 13.4888L20.729 13.3063L17.9655 14.9139L18.2889 15.0965Z"
        fill="#505453"
      />
      <path
        d="M17.9656 15.3286L18.2891 15.5112V15.097L17.9656 14.9144V15.3286Z"
        fill="#1C2225"
      />
      <path
        d="M36.1505 19.6403C36.1505 18.3064 35.4402 17.0778 34.2799 16.4109L20.7434 8.5973C20.23 8.30245 19.6464 8.35159 19.2034 8.6324L16.264 10.3945L32.965 22.308L35.2082 21.0162C35.3418 20.9601 35.4683 20.8899 35.5809 20.8056C35.9184 20.5389 36.1434 20.1317 36.1434 19.6403H36.1505Z"
        fill="#3B3F40"
      />
      <path
        d="M17.1918 14.8174L19.9554 13.2098L20.0538 12.8868L17.2832 14.4945L17.1918 14.8174Z"
        fill="#373C3F"
      />
      <path
        d="M17.2832 14.494L20.0538 12.8864L19.7303 12.7039L17.016 14.2624L17.2832 14.494Z"
        fill="#505453"
      />
      <path
        d="M16.9179 14.5855L17.1922 14.8172L17.2836 14.4942L17.0164 14.2626L16.9179 14.5855Z"
        fill="#1C2225"
      />
      <path
        d="M16.3273 14.0088L19.0908 12.4082V11.994L16.5171 13.6508L16.3273 14.0088Z"
        fill="#373C3F"
      />
      <path
        d="M16.5172 13.6505L19.0909 11.9937L18.7745 11.8112L16.25 13.2995L16.5172 13.6505Z"
        fill="#505453"
      />
      <path
        d="M16.06 13.6643L16.3273 14.0082L16.5171 13.6502L16.2499 13.2992L16.06 13.6643Z"
        fill="#1C2225"
      />
      <path
        d="M15.9049 12.5558L18.6685 10.9481V10.5339L15.9049 12.1416V12.5558Z"
        fill="#373C3F"
      />
      <path
        d="M15.9054 12.1413L18.3244 10.7372L18.1978 10.653L15.4342 12.2606L15.9054 12.1413Z"
        fill="#505453"
      />
      <path
        d="M15.5256 12.6265L15.9687 12.5422L15.9054 12.1421L15.4342 12.2614L15.5256 12.6265Z"
        fill="#1C2225"
      />
      <path
        d="M15.7992 11.6374L18.4573 9.98762V9.58044L15.7992 11.2232V11.6374Z"
        fill="#373C3F"
      />
      <path
        d="M15.7992 11.2228L18.4573 9.58005L18.1408 9.3905L15.3702 10.9982L15.7992 11.2228Z"
        fill="#505453"
      />
      <path
        d="M15.3703 11.4115L15.7993 11.6362V11.222L15.3703 10.9973V11.4115Z"
        fill="#1C2225"
      />
      <path
        d="M16.151 10.5728L18.9216 8.96514L19.1888 8.69836L16.4182 10.299L16.151 10.5728Z"
        fill="#373C3F"
      />
      <path
        d="M16.4184 10.3003L19.189 8.69969L18.9569 8.45398L16.1933 10.0616L16.4184 10.3003Z"
        fill="#505453"
      />
      <path
        d="M15.9259 10.3299L16.1509 10.5756L16.4181 10.3018L16.1931 10.0631L15.9259 10.3299Z"
        fill="#1C2225"
      />
      <path
        d="M17.2128 10.004L19.9834 8.40333V7.98914L17.2128 9.58977V10.004Z"
        fill="#373C3F"
      />
      <path
        d="M17.2128 9.58977L19.9834 7.98914L19.6318 8.05934L16.8682 9.65997L17.2128 9.58977Z"
        fill="#505453"
      />
      <path
        d="M16.868 10.0747L17.2126 10.0045V9.59033L16.868 9.66054V10.0747Z"
        fill="#1C2225"
      />
      <path
        d="M31.5373 18.0321L18.0007 10.2185C17.0092 9.64984 15.7716 10.3589 15.7716 11.5032C15.7716 12.8371 16.4818 14.0656 17.6421 14.7325L31.1787 22.5462C32.1702 23.1148 33.4078 22.4057 33.4078 21.2614C33.4078 19.9276 32.6976 18.699 31.5373 18.0321Z"
        fill="#1C2225"
      />
      <path
        d="M31.9169 22.0269C31.8044 22.0269 31.6919 21.9988 31.5864 21.9356L18.0498 14.122C17.1497 13.6025 16.5872 12.6337 16.5872 11.5947C16.5872 11.1805 16.9388 10.9208 17.2552 10.9208C17.3677 10.9208 17.4802 10.9489 17.5857 11.012L31.1223 18.8257C32.0224 19.3452 32.5849 20.314 32.5849 21.353C32.5849 21.5917 32.4724 21.7461 32.381 21.8303C32.2544 21.9497 32.0857 22.0199 31.9099 22.0199L31.9169 22.0269Z"
        fill="black"
      />
      <path
        d="M19.9629 13.6306C19.1964 13.1883 18.6831 12.4231 18.5424 11.5666L17.6001 11.019C17.4947 10.9558 17.3821 10.9277 17.2696 10.9277C16.9462 10.9277 16.6016 11.1875 16.6016 11.6017C16.6016 12.6407 17.1641 13.6095 18.0642 14.129L31.6008 21.9426C31.7063 22.0058 31.8188 22.0339 31.9313 22.0339C32.1001 22.0339 32.2759 21.9637 32.4025 21.8443C32.4939 21.7531 32.6064 21.5986 32.6064 21.3669C32.6064 21.2125 32.5923 21.058 32.5642 20.9036L19.9769 13.6376L19.9629 13.6306Z"
        fill="url(#paint0_linear_4613_98989)"
      />
      <path
        d="M32.2477 21.3176C32.2055 21.3176 32.1633 21.3036 32.1211 21.2825L18.5845 13.4689C17.811 13.0196 17.3258 12.1912 17.3258 11.2996C17.3258 11.103 17.5087 11.0328 17.586 11.0328C17.6282 11.0328 17.6704 11.0469 17.7126 11.0679L31.2491 18.8815C32.0227 19.3308 32.5079 20.1592 32.5079 21.0508C32.5079 21.1 32.5008 21.1702 32.4305 21.2334C32.3813 21.2825 32.311 21.3106 32.2407 21.3106L32.2477 21.3176Z"
        fill="#A85D00"
      />
      <path
        d="M30.6095 18.6375L30.3985 18.7569L19.1544 12.2982L19.3654 12.1788L30.6095 18.6375Z"
        fill="#E58919"
      />
      <path
        d="M30.3985 18.7565V19.7885L19.1544 13.3298V12.2979L30.3985 18.7565Z"
        fill="#CC7A16"
      />
      <path
        d="M30.6095 18.6354L30.3986 18.7547V19.7867L30.6095 19.6603V18.6354Z"
        fill="#AB6613"
      />
      <path
        d="M31.6364 18.09L34.407 16.4823V16.0681L31.6364 17.6758V18.09Z"
        fill="#373C3F"
      />
      <path
        d="M31.6362 17.6756L34.4068 16.0679L34.0834 15.8854L31.3128 17.493L31.6362 17.6756Z"
        fill="#505453"
      />
      <path
        d="M31.3133 17.9277L31.6368 18.1102V17.675L31.3133 17.4924V17.9277Z"
        fill="#1C2225"
      />
      <path
        d="M30.6101 17.4957L33.3807 15.895V15.4808L30.6101 17.0815V17.4957Z"
        fill="#373C3F"
      />
      <path
        d="M30.6101 17.081L33.3807 15.4804L33.0572 15.2979L30.2866 16.8985L30.6101 17.081Z"
        fill="#505453"
      />
      <path
        d="M30.2869 17.3311L30.6103 17.5207V17.0784L30.2869 16.8959V17.3311Z"
        fill="#1C2225"
      />
      <path
        d="M29.583 16.9058L32.3465 15.2981V14.8839L29.583 16.4916V16.9058Z"
        fill="#373C3F"
      />
      <path
        d="M29.583 16.4911L32.3466 14.8835L32.0301 14.7009L29.2595 16.3086L29.583 16.4911Z"
        fill="#505453"
      />
      <path
        d="M29.2594 16.7425L29.5829 16.925V16.4898L29.2594 16.3073V16.7425Z"
        fill="#1C2225"
      />
      <path
        d="M28.5561 16.3054L31.3197 14.7047V14.2905L28.5561 15.8912V16.3054Z"
        fill="#373C3F"
      />
      <path
        d="M28.5561 15.8906L31.3197 14.29L31.0033 14.1074L28.2327 15.7081L28.5561 15.8906Z"
        fill="#505453"
      />
      <path
        d="M28.2325 16.151L28.556 16.3335V15.8913L28.2325 15.7087V16.151Z"
        fill="#1C2225"
      />
      <path
        d="M27.5296 15.7162L30.2931 14.1085V13.6943L27.5296 15.302V15.7162Z"
        fill="#373C3F"
      />
      <path
        d="M27.5296 15.3015L30.2931 13.6939L29.9767 13.5114L27.2061 15.119L27.5296 15.3015Z"
        fill="#505453"
      />
      <path
        d="M27.2061 15.5551L27.5295 15.7377V15.3024L27.2061 15.1199V15.5551Z"
        fill="#1C2225"
      />
      <path
        d="M26.5031 15.1208L29.2666 13.5202V13.106L26.5031 14.7136V15.1208Z"
        fill="#373C3F"
      />
      <path
        d="M26.5031 14.7132L29.2666 13.1055L28.9502 12.923L26.1796 14.5236L26.5031 14.7132Z"
        fill="#505453"
      />
      <path
        d="M26.1801 14.9643L26.5035 15.1468V14.7115L26.1801 14.522V14.9643Z"
        fill="#1C2225"
      />
      <path
        d="M25.4763 14.5308L28.2399 12.9231V12.5089L25.4763 14.1166V14.5308Z"
        fill="#373C3F"
      />
      <path
        d="M25.4765 14.1161L28.24 12.5085L27.9236 12.3259L25.153 13.9336L25.4765 14.1161Z"
        fill="#505453"
      />
      <path
        d="M25.1528 14.3684L25.4762 14.5509V14.1156L25.1528 13.9331V14.3684Z"
        fill="#1C2225"
      />
      <path
        d="M24.4494 13.9416L27.2129 12.334V11.9198L24.4494 13.5275V13.9416Z"
        fill="#373C3F"
      />
      <path
        d="M24.4494 13.527L27.2129 11.9193L26.8965 11.7368L24.1259 13.3374L24.4494 13.527Z"
        fill="#505453"
      />
      <path
        d="M24.1259 13.7798L24.4494 13.9623V13.5271L24.1259 13.3375V13.7798Z"
        fill="#1C2225"
      />
      <path
        d="M23.423 13.3457L26.1866 11.7381V11.3239L23.423 12.9315V13.3457Z"
        fill="#373C3F"
      />
      <path
        d="M23.4232 12.9308L26.1867 11.3232L25.8703 11.1406L23.0997 12.7483L23.4232 12.9308Z"
        fill="#505453"
      />
      <path
        d="M23.0994 13.1825L23.4229 13.365V12.9297L23.0994 12.7472V13.1825Z"
        fill="#1C2225"
      />
      <path
        d="M22.396 12.7541L25.1595 11.1465V10.7323L22.396 12.34V12.7541Z"
        fill="#373C3F"
      />
      <path
        d="M22.396 12.3395L25.1595 10.7318L24.8431 10.5493L22.0725 12.157L22.396 12.3395Z"
        fill="#505453"
      />
      <path
        d="M22.0722 12.5925L22.3956 12.775V12.3398L22.0722 12.1572V12.5925Z"
        fill="#1C2225"
      />
      <path
        d="M21.3695 12.1578L24.133 10.5502V10.136L21.3695 11.7436V12.1578Z"
        fill="#373C3F"
      />
      <path
        d="M21.3695 11.7432L24.133 10.1355L23.8096 9.953L21.046 11.5607L21.3695 11.7432Z"
        fill="#505453"
      />
      <path
        d="M21.0461 11.9967L21.3696 12.1792V11.7439L21.0461 11.5614V11.9967Z"
        fill="#1C2225"
      />
      <path
        d="M20.3423 11.5674L23.1059 9.95973V9.54553L20.3423 11.1532V11.5674Z"
        fill="#373C3F"
      />
      <path
        d="M20.3423 11.1527L23.1059 9.54508L22.7824 9.36255L20.0188 10.9702L20.3423 11.1527Z"
        fill="#505453"
      />
      <path
        d="M20.0189 11.4046L20.3424 11.5871V11.1519L20.0189 10.9694V11.4046Z"
        fill="#1C2225"
      />
      <path
        d="M19.3168 10.9722L22.0804 9.36458V8.9574L19.3168 10.558V10.9722Z"
        fill="#373C3F"
      />
      <path
        d="M19.3166 10.5575L22.0802 8.95688L21.7567 8.76733L18.9932 10.375L19.3166 10.5575Z"
        fill="#505453"
      />
      <path
        d="M18.9933 10.8099L19.3168 10.9924V10.5572L18.9933 10.3746V10.8099Z"
        fill="#1C2225"
      />
      <path
        d="M18.29 10.3805L21.0536 8.77284V8.35864L18.29 9.96629V10.3805Z"
        fill="#373C3F"
      />
      <path
        d="M18.2898 9.96584L21.0533 8.35819L20.7299 8.17566L17.9663 9.78331L18.2898 9.96584Z"
        fill="#505453"
      />
      <path
        d="M17.9664 10.2199L18.2899 10.4025V9.9672L17.9664 9.78467V10.2199Z"
        fill="#1C2225"
      />
      <path
        d="M32.9863 19.5419L35.7569 17.9342L36.01 17.9693L33.2465 19.577L32.9863 19.5419Z"
        fill="#373C3F"
      />
      <path
        d="M33.3305 19.8515L36.0941 18.2509L36.0097 17.9701L33.2461 19.5777L33.3305 19.8515Z"
        fill="#505453"
      />
      <path
        d="M32.9858 19.5419L33.1054 19.8157L33.3304 19.8508L33.246 19.577L32.9858 19.5419Z"
        fill="#1C2225"
      />
      <path
        d="M32.5084 18.8515L35.272 17.2439L35.4337 16.9982L32.6701 18.6058L32.5084 18.8515Z"
        fill="#373C3F"
      />
      <path
        d="M32.67 18.6059L35.4335 16.9983L35.1452 16.6964L32.3817 18.297L32.67 18.6059Z"
        fill="#505453"
      />
      <path
        d="M32.22 18.5422L32.5083 18.8511L32.67 18.6054L32.3817 18.2965L32.22 18.5422Z"
        fill="#1C2225"
      />
      <path
        d="M33.3245 20.4339L36.088 18.8263L36.3693 18.9176L33.6058 20.5252L33.3245 20.4339Z"
        fill="#373C3F"
      />
      <path
        d="M33.6407 20.8479L36.4113 19.2473L36.3692 18.9174L33.6056 20.525L33.6407 20.8479Z"
        fill="#505453"
      />
      <path
        d="M33.3242 20.4336L33.3734 20.7425L33.6407 20.8478L33.6055 20.5249L33.3242 20.4336Z"
        fill="#1C2225"
      />
      <path
        d="M32.4874 22.6241L35.4056 20.9744L35.8275 21.2692L32.9022 22.9681L32.4874 22.6241Z"
        fill="#373C3F"
      />
      <path
        d="M32.487 22.6237L32.1284 22.729L32.5714 23.1081L32.9019 22.9677L32.487 22.6237Z"
        fill="#1C2225"
      />
      <path
        d="M33.338 21.6493L36.1086 20.0416L36.3899 20.1329L33.6263 21.7405L33.338 21.6493Z"
        fill="#373C3F"
      />
      <path
        d="M33.4435 22.1765L36.2141 20.5688L36.3899 20.1335L33.6264 21.7412L33.4435 22.1765Z"
        fill="#505453"
      />
      <path
        d="M33.3384 21.6498L33.1766 22.071L33.4438 22.1763L33.6267 21.741L33.3384 21.6498Z"
        fill="#1C2225"
      />
      <path
        d="M17.586 11.7229C17.586 12.1371 17.8814 12.6426 18.247 12.8462C18.6127 13.0568 18.9081 12.8883 18.9081 12.4811C18.9081 12.0739 18.6127 11.5615 18.247 11.3579C17.8814 11.1473 17.586 11.3157 17.586 11.7229Z"
        fill="#E0B350"
      />
      <path
        d="M17.6911 11.7784C17.6911 12.1224 17.9372 12.5507 18.2466 12.7262C18.549 12.9017 18.8021 12.7613 18.8021 12.4173C18.8021 12.0733 18.556 11.645 18.2466 11.4695C17.9372 11.294 17.6911 11.4344 17.6911 11.7784Z"
        fill="#A85D00"
      />
      <path
        d="M17.8389 11.8634C17.8389 12.1161 18.0218 12.432 18.2468 12.5584C18.4718 12.6848 18.6546 12.5865 18.6546 12.3337C18.6546 12.081 18.4718 11.7651 18.2468 11.6387C18.0218 11.5124 17.8389 11.6106 17.8389 11.8634Z"
        fill="#CD8B19"
      />
      <path
        d="M18.1343 11.5969C18.1694 11.6039 18.2116 11.6179 18.2468 11.639C18.4718 11.7654 18.6546 12.0813 18.6546 12.334C18.6546 12.4042 18.6406 12.4604 18.6195 12.5025L18.2468 12.4253C18.0991 12.2989 18.085 12.1585 18.0007 11.976L18.1343 11.5898V11.5969Z"
        fill="#B86A0B"
      />
      <path
        d="M18.1273 12.2702C18.0992 12.221 18.0781 12.1649 18.057 12.1087C18.2117 12.0455 18.3594 11.9683 18.4859 11.856C18.5563 11.9542 18.6055 12.0595 18.6336 12.1719C18.4578 12.1719 18.289 12.207 18.1273 12.2702Z"
        fill="#DE9B16"
      />
      <path
        d="M17.839 12.0529C17.839 12.2354 17.9656 12.4531 18.1273 12.5443C18.289 12.6356 18.4156 12.5654 18.4156 12.3829C18.4156 12.2003 18.289 11.9827 18.1273 11.8914C17.9656 11.8002 17.839 11.8704 17.839 12.0529Z"
        fill="#B86A0B"
      />
      <path
        d="M17.9303 12.1662C17.9303 12.2645 18.0006 12.3839 18.085 12.433C18.1694 12.4821 18.2397 12.44 18.2397 12.3488C18.2397 12.2575 18.1694 12.1311 18.085 12.082C18.0006 12.0328 17.9303 12.0679 17.9303 12.1662Z"
        fill="#E0981B"
      />
      <path
        d="M30.6591 19.3166C30.6591 19.7308 30.9544 20.2362 31.3201 20.4398C31.6858 20.6504 31.9811 20.4819 31.9811 20.0747C31.9811 19.6676 31.6858 19.1551 31.3201 18.9515C30.9544 18.7409 30.6591 18.9094 30.6591 19.3166Z"
        fill="#E0B350"
      />
      <path
        d="M30.7641 19.3799C30.7641 19.7239 31.0103 20.1521 31.3197 20.3276C31.622 20.5031 31.8752 20.3627 31.8752 20.0187C31.8752 19.6747 31.6291 19.2465 31.3197 19.071C31.0173 18.8955 30.7641 19.0359 30.7641 19.3799Z"
        fill="#A85D00"
      />
      <path
        d="M30.912 19.4649C30.912 19.7177 31.0948 20.0336 31.3198 20.1599C31.5449 20.2863 31.7277 20.188 31.7277 19.9353C31.7277 19.6826 31.5449 19.3667 31.3198 19.2403C31.0948 19.1139 30.912 19.2122 30.912 19.4649Z"
        fill="#CD8B19"
      />
      <path
        d="M31.2073 19.1913C31.2425 19.1984 31.2847 19.2124 31.3198 19.2335C31.5449 19.3598 31.7277 19.6757 31.7277 19.9285C31.7277 19.9987 31.7136 20.0548 31.6925 20.097L31.3198 20.0197C31.1722 19.8934 31.1581 19.753 31.0737 19.5704L31.2073 19.1843V19.1913Z"
        fill="#B86A0B"
      />
      <path
        d="M31.2004 19.8638C31.1722 19.8146 31.1511 19.7585 31.13 19.7023C31.2847 19.6391 31.4324 19.5619 31.559 19.4496C31.6293 19.5479 31.6785 19.6532 31.7067 19.7655C31.5309 19.7655 31.3621 19.8006 31.2004 19.8638Z"
        fill="#DE9B16"
      />
      <path
        d="M30.9121 19.6475C30.9121 19.83 31.0386 20.0477 31.2004 20.1389C31.3621 20.2302 31.4887 20.16 31.4887 19.9775C31.4887 19.7949 31.3621 19.5773 31.2004 19.486C31.0386 19.3948 30.9121 19.465 30.9121 19.6475Z"
        fill="#B86A0B"
      />
      <path
        d="M31.0038 19.7586C31.0038 19.8568 31.0741 19.9762 31.1585 20.0253C31.2429 20.0745 31.3132 20.0394 31.3132 19.9411C31.3132 19.8428 31.2429 19.7235 31.1585 19.6743C31.0741 19.6252 31.0038 19.6673 31.0038 19.7586Z"
        fill="#E0981B"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M17.593 11.6365C17.6352 11.6365 17.6774 11.6506 17.7196 11.6716L31.2562 19.4852C31.8961 19.8573 32.3321 20.4891 32.4657 21.2052C32.5079 21.149 32.5149 21.0929 32.5149 21.0578C32.5149 20.1662 32.0367 19.3308 31.2562 18.8885L17.7196 11.0749C17.6774 11.0468 17.6352 11.0398 17.593 11.0398C17.5086 11.0398 17.3328 11.103 17.3328 11.3066C17.3328 11.461 17.3539 11.6084 17.3821 11.7629C17.4383 11.6786 17.5438 11.6506 17.6001 11.6506L17.593 11.6365Z"
          fill="#8C7900"
        />
      </g>
      <path
        d="M31.5436 20.2447V19.0582L20.2081 13.7087L11.2564 18.8336L22.5919 25.3625C22.5919 25.3625 26.2064 20.5465 31.5436 20.2376V20.2447Z"
        fill="#131314"
      />
      <path
        d="M31.5436 19.0594L20.2081 12.5305L11.2564 17.6553L22.5919 24.1842C22.5919 24.1842 26.2064 19.3683 31.5436 19.0594Z"
        fill="#313131"
      />
      <path
        d="M20.0325 29.8437L22.7538 28.3273V27.9131L20.0325 29.4295V29.8437Z"
        fill="#373C3F"
      />
      <path
        d="M20.0325 29.429L22.7538 27.9126L22.4304 27.7301L19.6668 29.3378L20.0325 29.429Z"
        fill="#505453"
      />
      <path
        d="M19.6666 29.7451L20.0322 29.8434V29.4292L19.6666 29.3379V29.7451Z"
        fill="#1C2225"
      />
      <path
        d="M18.9634 29.3379L21.727 27.7302V27.316L18.9634 28.9237V29.3379Z"
        fill="#373C3F"
      />
      <path
        d="M18.9635 28.9234L21.7271 27.3157L21.4036 27.1332L18.64 28.7408L18.9635 28.9234Z"
        fill="#505453"
      />
      <path
        d="M18.6399 29.1546L18.9633 29.3371V28.9229L18.6399 28.7404V29.1546Z"
        fill="#1C2225"
      />
      <path
        d="M17.937 28.7469L20.7005 27.1393V26.7251L17.937 28.3328V28.7469Z"
        fill="#373C3F"
      />
      <path
        d="M17.9371 28.3325L20.7006 26.7249L20.3772 26.5424L17.6136 28.15L17.9371 28.3325Z"
        fill="#505453"
      />
      <path
        d="M17.6134 28.5645L17.9369 28.747V28.3328L17.6134 28.1503V28.5645Z"
        fill="#1C2225"
      />
      <path
        d="M16.9101 28.1528L19.6737 26.5451V26.1379L16.9101 27.7386V28.1528Z"
        fill="#373C3F"
      />
      <path
        d="M16.9102 27.7381L19.6738 26.1374L19.3503 25.9479L16.5867 27.5555L16.9102 27.7381Z"
        fill="#505453"
      />
      <path
        d="M16.5866 27.9695L16.91 28.152V27.7378L16.5866 27.5553V27.9695Z"
        fill="#1C2225"
      />
      <path
        d="M15.8837 27.5619L18.6472 25.9542V25.54L15.8837 27.1477V27.5619Z"
        fill="#373C3F"
      />
      <path
        d="M15.8838 27.1472L18.6474 25.5396L18.3239 25.3571L15.5603 26.9647L15.8838 27.1472Z"
        fill="#505453"
      />
      <path
        d="M15.5601 27.3777L15.8836 27.5602V27.146L15.5601 26.9635V27.3777Z"
        fill="#1C2225"
      />
      <path
        d="M14.857 26.9639L17.6206 25.3633V24.9491L14.857 26.5497V26.9639Z"
        fill="#373C3F"
      />
      <path
        d="M14.857 26.5502L17.6206 24.9496L17.2971 24.76L14.5336 26.3677L14.857 26.5502Z"
        fill="#505453"
      />
      <path
        d="M14.5336 26.7819L14.8571 26.9644V26.5502L14.5336 26.3677V26.7819Z"
        fill="#1C2225"
      />
      <path
        d="M13.8302 26.3732L16.5938 24.7655V24.3513L13.8302 25.959V26.3732Z"
        fill="#373C3F"
      />
      <path
        d="M13.8302 25.9585L16.5938 24.3509L16.2703 24.1683L13.5067 25.776L13.8302 25.9585Z"
        fill="#505453"
      />
      <path
        d="M13.5068 26.1919L13.8303 26.3744V25.9602L13.5068 25.7777V26.1919Z"
        fill="#1C2225"
      />
      <path
        d="M12.8039 25.7787L15.5675 24.1781V23.7639L12.8039 25.3645V25.7787Z"
        fill="#373C3F"
      />
      <path
        d="M12.8041 25.3648L15.5677 23.7642L15.2442 23.5817L12.4806 25.1823L12.8041 25.3648Z"
        fill="#505453"
      />
      <path
        d="M12.4803 25.5954L12.8038 25.7779V25.3637L12.4803 25.1812V25.5954Z"
        fill="#1C2225"
      />
      <path
        d="M11.7766 25.1885L14.5402 23.5808V23.1666L11.7766 24.7743V25.1885Z"
        fill="#373C3F"
      />
      <path
        d="M11.7768 24.7739L14.5404 23.1663L14.2169 22.9838L11.4533 24.5914L11.7768 24.7739Z"
        fill="#505453"
      />
      <path
        d="M11.4531 25.0051L11.7766 25.1877V24.7735L11.4531 24.5909V25.0051Z"
        fill="#1C2225"
      />
      <path
        d="M10.75 24.591L13.5135 22.9904V22.5762L10.75 24.1768V24.591Z"
        fill="#373C3F"
      />
      <path
        d="M10.75 24.1763L13.5135 22.5757L13.1901 22.3932L10.4265 23.9938L10.75 24.1763Z"
        fill="#505453"
      />
      <path
        d="M10.4266 24.408L10.7501 24.5905V24.1763L10.4266 23.9938V24.408Z"
        fill="#1C2225"
      />
      <path
        d="M9.71717 24.0027L12.4878 22.395V21.9808L9.71717 23.5885V24.0027Z"
        fill="#373C3F"
      />
      <path
        d="M9.71695 23.5878L12.4875 21.9801L12.1641 21.7976L9.40051 23.4053L9.71695 23.5878Z"
        fill="#505453"
      />
      <path
        d="M9.40066 23.8192L9.7171 24.0018V23.5876L9.40066 23.405V23.8192Z"
        fill="#1C2225"
      />
      <path
        d="M8.6903 23.4045L11.4609 21.8038V21.3896L8.6903 22.9903V23.4045Z"
        fill="#373C3F"
      />
      <path
        d="M8.69009 22.9907L11.4607 21.39L11.1372 21.2075L8.37365 22.8082L8.69009 22.9907Z"
        fill="#505453"
      />
      <path
        d="M8.37381 23.2211L8.69025 23.4036V22.9894L8.37381 22.8069V23.2211Z"
        fill="#1C2225"
      />
      <path
        d="M7.66369 22.8147L10.4343 21.207V20.7928L7.66369 22.4005V22.8147Z"
        fill="#373C3F"
      />
      <path
        d="M7.66369 22.4L10.4343 20.7924L10.1108 20.6099L7.34726 22.2175L7.66369 22.4Z"
        fill="#505453"
      />
      <path
        d="M7.34741 22.6333L7.66385 22.8158V22.4016L7.34741 22.2191V22.6333Z"
        fill="#1C2225"
      />
      <path
        d="M6.63717 22.2206L9.40778 20.62V20.2058L6.63717 21.8135V22.2206Z"
        fill="#373C3F"
      />
      <path
        d="M6.63717 21.813L9.40778 20.2054L9.08431 20.0228L6.32074 21.6235L6.63717 21.813Z"
        fill="#505453"
      />
      <path
        d="M6.3205 22.0361L6.63693 22.2187V21.8115L6.3205 21.6219V22.0361Z"
        fill="#1C2225"
      />
      <path
        d="M24.5047 26.3534C24.5047 25.0195 23.7945 23.791 22.6342 23.124L9.09766 15.3104C8.58432 15.0156 8.00067 15.0647 7.55765 15.3455L4.61829 17.1076L21.3192 29.0211L23.5624 27.7294C23.6961 27.6732 23.8226 27.603 23.9351 27.5188C24.2727 27.252 24.4977 26.8448 24.4977 26.3534H24.5047Z"
        fill="#3B3F40"
      />
      <path
        d="M5.53987 21.5263L8.31047 19.9257L8.40189 19.5957L5.63832 21.2034L5.53987 21.5263Z"
        fill="#373C3F"
      />
      <path
        d="M5.63832 21.2029L8.40189 19.5952L8.08544 19.4127L5.3711 20.9712L5.63832 21.2029Z"
        fill="#505453"
      />
      <path
        d="M5.2723 21.3013L5.53952 21.5259L5.63797 21.203L5.37076 20.9713L5.2723 21.3013Z"
        fill="#1C2225"
      />
      <path
        d="M4.68214 20.7222L7.44571 19.1145V18.7003L4.872 20.3641L4.68214 20.7222Z"
        fill="#373C3F"
      />
      <path
        d="M4.87218 20.3643L7.44589 18.7005L7.12242 18.5179L4.60497 20.0133L4.87218 20.3643Z"
        fill="#505453"
      />
      <path
        d="M4.41493 20.3705L4.68215 20.7215L4.87201 20.3635L4.6048 20.0125L4.41493 20.3705Z"
        fill="#1C2225"
      />
      <path
        d="M4.2525 19.2643L7.0231 17.6637V17.2495L4.2525 18.8501V19.2643Z"
        fill="#373C3F"
      />
      <path
        d="M4.25216 18.8498L6.67819 17.4458L6.55162 17.3685L3.78102 18.9762L4.25216 18.8498Z"
        fill="#505453"
      />
      <path
        d="M3.87965 19.3408L4.32266 19.2495L4.25234 18.8494L3.7812 18.9757L3.87965 19.3408Z"
        fill="#1C2225"
      />
      <path
        d="M4.14805 18.3433L6.81317 16.7006V16.2864L4.14805 17.9362V18.3433Z"
        fill="#373C3F"
      />
      <path
        d="M4.14787 17.9368L6.81299 16.287L6.48952 16.1045L3.72595 17.7121L4.14787 17.9368Z"
        fill="#505453"
      />
      <path
        d="M3.72609 18.1249L4.14801 18.3425V17.9353L3.72609 17.7107V18.1249Z"
        fill="#1C2225"
      />
      <path
        d="M4.50657 17.2813L7.27014 15.6736L7.53735 15.4069L4.77378 17.0145L4.50657 17.2813Z"
        fill="#373C3F"
      />
      <path
        d="M4.77378 17.0151L7.53735 15.4075L7.31233 15.1617L4.54173 16.7694L4.77378 17.0151Z"
        fill="#505453"
      />
      <path
        d="M4.27444 17.037L4.5065 17.2827L4.77371 17.016L4.54166 16.7703L4.27444 17.037Z"
        fill="#1C2225"
      />
      <path
        d="M5.56779 16.723L8.33136 15.1154V14.7012L5.56779 16.3088V16.723Z"
        fill="#373C3F"
      />
      <path
        d="M5.56779 16.3088L8.33136 14.7012L7.98679 14.7714L5.22322 16.379L5.56779 16.3088Z"
        fill="#505453"
      />
      <path
        d="M5.22315 16.7926L5.56772 16.7224V16.3082L5.22315 16.3784V16.7926Z"
        fill="#1C2225"
      />
      <path
        d="M19.8925 24.7391L6.3559 16.9255C5.36439 16.3569 4.12676 17.0659 4.12676 18.2102C4.12676 19.5441 4.83699 20.7726 5.99727 21.4396L19.5338 29.2532C20.5253 29.8218 21.763 29.1128 21.763 27.9685C21.763 26.6346 21.0527 25.4061 19.8925 24.7391Z"
        fill="#1C2225"
      />
      <path
        d="M20.2716 28.7392C20.1591 28.7392 20.0466 28.7111 19.9411 28.6479L6.40453 20.8343C5.50443 20.3148 4.94188 19.346 4.94188 18.307C4.94188 17.8928 5.29348 17.6331 5.60992 17.6331C5.72243 17.6331 5.83494 17.6611 5.94042 17.7243L19.477 25.5379C20.3771 26.0574 20.9396 27.0262 20.9396 28.0652C20.9396 28.3039 20.8271 28.4584 20.7357 28.5426C20.6091 28.662 20.4404 28.7322 20.2646 28.7322L20.2716 28.7392Z"
        fill="black"
      />
      <path
        d="M8.31715 20.3359C7.55067 19.8936 7.03734 19.1284 6.8967 18.2719L5.95441 17.7243C5.84893 17.6611 5.73642 17.6331 5.6239 17.6331C5.30043 17.6331 4.95587 17.8928 4.95587 18.307C4.95587 19.346 5.51843 20.3148 6.41852 20.8343L19.9551 28.6479C20.0606 28.7111 20.1731 28.7392 20.2856 28.7392C20.4544 28.7392 20.6302 28.669 20.7567 28.5496C20.8482 28.4584 20.9607 28.3039 20.9607 28.0723C20.9607 27.9178 20.9466 27.7634 20.9185 27.6089L8.33122 20.3429L8.31715 20.3359Z"
        fill="url(#paint1_linear_4613_98989)"
      />
      <path
        d="M20.6024 28.0307C20.5602 28.0307 20.518 28.0167 20.4759 27.9956L6.93929 20.182C6.16577 19.7327 5.68056 18.9043 5.68056 18.0127C5.68056 17.8162 5.8634 17.746 5.94075 17.746C5.98294 17.746 6.02513 17.76 6.06732 17.7811L19.6039 25.5947C20.3774 26.044 20.8626 26.8724 20.8626 27.764C20.8626 27.8131 20.8556 27.8833 20.7853 27.9465C20.736 27.9956 20.6657 28.0237 20.5954 28.0237L20.6024 28.0307Z"
        fill="#A85D00"
      />
      <path
        d="M18.9637 25.3416L18.7528 25.4679L7.50861 19.0092L7.71254 18.8899L18.9637 25.3416Z"
        fill="#E58919"
      />
      <path
        d="M18.7527 25.4676V26.4926L7.5086 20.0409V19.0089L18.7527 25.4676Z"
        fill="#CC7A16"
      />
      <path
        d="M18.9638 25.3413L18.7528 25.4677V26.4926L18.9638 26.3733V25.3413Z"
        fill="#AB6613"
      />
      <path
        d="M19.99 24.7949L22.7535 23.1942V22.78L19.99 24.3807V24.7949Z"
        fill="#373C3F"
      />
      <path
        d="M19.99 24.3802L22.7535 22.7796L22.4301 22.597L19.6665 24.1977L19.99 24.3802Z"
        fill="#505453"
      />
      <path
        d="M19.6664 24.6347L19.9899 24.8243V24.382L19.6664 24.1995V24.6347Z"
        fill="#1C2225"
      />
      <path
        d="M18.9631 24.2068L21.7267 22.5991V22.1849L18.9631 23.7926V24.2068Z"
        fill="#373C3F"
      />
      <path
        d="M18.9631 23.792L21.7267 22.1844L21.4032 22.0018L18.6396 23.6095L18.9631 23.792Z"
        fill="#505453"
      />
      <path
        d="M18.64 24.0435L18.9634 24.2261V23.7908L18.64 23.6083V24.0435Z"
        fill="#1C2225"
      />
      <path
        d="M17.937 23.608L20.7006 22.0073V21.5931L17.937 23.1938V23.608Z"
        fill="#373C3F"
      />
      <path
        d="M17.9371 23.1932L20.7007 21.5926L20.3772 21.41L17.6136 23.0107L17.9371 23.1932Z"
        fill="#505453"
      />
      <path
        d="M17.6134 23.4465L17.9369 23.636V23.1938L17.6134 23.0112V23.4465Z"
        fill="#1C2225"
      />
      <path
        d="M16.9098 23.0178L19.6733 21.4102V20.996L16.9098 22.6036V23.0178Z"
        fill="#373C3F"
      />
      <path
        d="M16.9098 22.604L19.6733 20.9964L19.3499 20.8138L16.5863 22.4215L16.9098 22.604Z"
        fill="#505453"
      />
      <path
        d="M16.5867 22.8576L16.9101 23.0402V22.6049L16.5867 22.4224V22.8576Z"
        fill="#1C2225"
      />
      <path
        d="M15.8836 22.423L18.6472 20.8224V20.4082L15.8836 22.0088V22.423Z"
        fill="#373C3F"
      />
      <path
        d="M15.8836 22.0092L18.6472 20.4086L18.3237 20.2261L15.5601 21.8267L15.8836 22.0092Z"
        fill="#505453"
      />
      <path
        d="M15.5601 22.2683L15.8836 22.4509V22.0086L15.5601 21.826V22.2683Z"
        fill="#1C2225"
      />
      <path
        d="M14.8569 21.8333L17.6204 20.2256V19.8114L14.8569 21.4191V21.8333Z"
        fill="#373C3F"
      />
      <path
        d="M14.857 21.4187L17.6205 19.8111L17.2971 19.6285L14.5335 21.2362L14.857 21.4187Z"
        fill="#505453"
      />
      <path
        d="M14.5333 21.6718L14.8568 21.8544V21.4191L14.5333 21.2366V21.6718Z"
        fill="#1C2225"
      />
      <path
        d="M13.83 21.2344L16.5936 19.6338V19.2196L13.83 20.8273V21.2344Z"
        fill="#373C3F"
      />
      <path
        d="M13.8301 20.8269L16.5937 19.2193L16.2702 19.0367L13.5066 20.6374L13.8301 20.8269Z"
        fill="#505453"
      />
      <path
        d="M13.5064 21.0792L13.8299 21.2618V20.8265L13.5064 20.637V21.0792Z"
        fill="#1C2225"
      />
      <path
        d="M12.803 20.6471L15.5666 19.0394V18.6252L12.803 20.2329V20.6471Z"
        fill="#373C3F"
      />
      <path
        d="M12.803 20.2324L15.5666 18.6248L15.2431 18.4423L12.4795 20.0499L12.803 20.2324Z"
        fill="#505453"
      />
      <path
        d="M12.4796 20.4853L12.803 20.6678V20.2326L12.4796 20.05V20.4853Z"
        fill="#1C2225"
      />
      <path
        d="M11.7763 20.0573L14.5399 18.4496V18.0354L11.7763 19.6431V20.0573Z"
        fill="#373C3F"
      />
      <path
        d="M11.7764 19.6426L14.54 18.0349L14.2165 17.8524L11.4529 19.453L11.7764 19.6426Z"
        fill="#505453"
      />
      <path
        d="M11.4527 19.895L11.7762 20.0776V19.6423L11.4527 19.4528V19.895Z"
        fill="#1C2225"
      />
      <path
        d="M10.75 19.4596L13.5136 17.8519V17.4377L10.75 19.0454V19.4596Z"
        fill="#373C3F"
      />
      <path
        d="M10.75 19.0449L13.5136 17.4373L13.1901 17.2548L10.4265 18.8624L10.75 19.0449Z"
        fill="#505453"
      />
      <path
        d="M10.4267 19.2984L10.7501 19.4809V19.0457L10.4267 18.8632V19.2984Z"
        fill="#1C2225"
      />
      <path
        d="M9.71778 18.8717L12.4884 17.2641V16.8499L9.71778 18.4575V18.8717Z"
        fill="#373C3F"
      />
      <path
        d="M9.71778 18.4579L12.4884 16.8503L12.1649 16.6677L9.40133 18.2754L9.71778 18.4579Z"
        fill="#505453"
      />
      <path
        d="M9.40109 18.7099L9.71753 18.8924V18.4572L9.40109 18.2747V18.7099Z"
        fill="#1C2225"
      />
      <path
        d="M8.68948 18.2732L11.4601 16.6655V16.2513L8.68948 17.859V18.2732Z"
        fill="#373C3F"
      />
      <path
        d="M8.68969 17.8584L11.4603 16.2508L11.1368 16.0682L8.37326 17.6759L8.68969 17.8584Z"
        fill="#505453"
      />
      <path
        d="M8.37341 18.1135L8.68985 18.296V17.8608L8.37341 17.6782V18.1135Z"
        fill="#1C2225"
      />
      <path
        d="M7.66263 17.6838L10.4332 16.0762V15.662L7.66263 17.2696V17.6838Z"
        fill="#373C3F"
      />
      <path
        d="M7.66284 17.2693L10.4334 15.6617L10.11 15.4791L7.3464 17.0868L7.66284 17.2693Z"
        fill="#505453"
      />
      <path
        d="M7.34613 17.5233L7.66257 17.7058V17.2705L7.34613 17.088V17.5233Z"
        fill="#1C2225"
      />
      <path
        d="M6.63556 17.0886L9.40616 15.4809V15.0737L6.63556 16.6744V17.0886Z"
        fill="#373C3F"
      />
      <path
        d="M6.63556 16.674L9.40616 15.0733L9.08269 14.8838L6.31912 16.4914L6.63556 16.674Z"
        fill="#505453"
      />
      <path
        d="M6.3197 16.9259L6.63614 17.1084V16.6731L6.3197 16.4906V16.9259Z"
        fill="#1C2225"
      />
      <path
        d="M21.3407 26.2486L24.1043 24.6479L24.3644 24.6831L21.5938 26.2907L21.3407 26.2486Z"
        fill="#373C3F"
      />
      <path
        d="M21.6853 26.5653L24.4489 24.9576L24.3645 24.6838L21.5939 26.2915L21.6853 26.5653Z"
        fill="#505453"
      />
      <path
        d="M21.3407 26.2482L21.4532 26.522L21.6853 26.5641L21.5939 26.2903L21.3407 26.2482Z"
        fill="#1C2225"
      />
      <path
        d="M20.8556 25.5589L23.6262 23.9582L23.7879 23.7125L21.0173 25.3202L20.8556 25.5589Z"
        fill="#373C3F"
      />
      <path
        d="M21.0173 25.3201L23.7879 23.7125L23.4996 23.4036L20.729 25.0112L21.0173 25.3201Z"
        fill="#505453"
      />
      <path
        d="M20.5666 25.258L20.855 25.5599L21.0167 25.3212L20.7284 25.0123L20.5666 25.258Z"
        fill="#1C2225"
      />
      <path
        d="M21.6709 27.1373L24.4415 25.5366L24.7228 25.6279L21.9592 27.2285L21.6709 27.1373Z"
        fill="#373C3F"
      />
      <path
        d="M21.9942 27.5591L24.7578 25.9515L24.7227 25.6285L21.9591 27.2292L21.9942 27.5591Z"
        fill="#505453"
      />
      <path
        d="M21.6709 27.1381L21.7271 27.454L21.9943 27.5593L21.9592 27.2293L21.6709 27.1381Z"
        fill="#1C2225"
      />
      <path
        d="M20.8344 29.3365L23.7598 27.6797L24.1817 27.9816L21.2564 29.6805L20.8344 29.3365Z"
        fill="#373C3F"
      />
      <path
        d="M20.8342 29.3379L20.4755 29.4432L20.9256 29.8223L21.2561 29.6819L20.8342 29.3379Z"
        fill="#1C2225"
      />
      <path
        d="M21.6922 28.353L24.4558 26.7454L24.7441 26.8366L21.9735 28.4443L21.6922 28.353Z"
        fill="#373C3F"
      />
      <path
        d="M21.7977 28.8872L24.5613 27.2796L24.7441 26.8373L21.9735 28.4449L21.7977 28.8872Z"
        fill="#505453"
      />
      <path
        d="M21.6922 28.3538L21.5305 28.782L21.7977 28.8873L21.9735 28.445L21.6922 28.3538Z"
        fill="#1C2225"
      />
      <path
        d="M5.93369 18.4279C5.93369 18.8421 6.22904 19.3475 6.5947 19.5511C6.96036 19.7547 7.25571 19.5933 7.25571 19.1861C7.25571 18.7789 6.96036 18.2664 6.5947 18.0628C6.22904 17.8522 5.93369 18.0207 5.93369 18.4279Z"
        fill="#E0B350"
      />
      <path
        d="M6.03897 18.4902C6.03897 18.8342 6.28509 19.2625 6.5945 19.438C6.89687 19.6135 7.15002 19.4731 7.15002 19.1291C7.15002 18.7851 6.9039 18.3568 6.5945 18.1813C6.29212 18.0058 6.03897 18.1462 6.03897 18.4902Z"
        fill="#A85D00"
      />
      <path
        d="M6.19381 18.5761C6.19381 18.8289 6.37665 19.1448 6.60167 19.2712C6.82669 19.3975 7.00952 19.2992 7.00952 19.0465C7.00952 18.7938 6.82669 18.4779 6.60167 18.3515C6.37665 18.2251 6.19381 18.3234 6.19381 18.5761Z"
        fill="#CD8B19"
      />
      <path
        d="M6.48916 18.3017C6.52432 18.3087 6.56651 18.3228 6.60167 18.3438C6.82669 18.4702 7.00952 18.7861 7.00952 19.0388C7.00952 19.109 6.99546 19.1652 6.97436 19.2073L6.60167 19.1301C6.454 19.0037 6.43993 18.8633 6.35555 18.6808L6.48916 18.2947V18.3017Z"
        fill="#B86A0B"
      />
      <path
        d="M6.47475 18.975C6.44662 18.9258 6.42552 18.8697 6.40443 18.8135C6.55913 18.7503 6.7068 18.6731 6.83338 18.5608C6.9037 18.6591 6.95292 18.7644 6.98105 18.8767C6.80525 18.8767 6.63649 18.9118 6.47475 18.975Z"
        fill="#DE9B16"
      />
      <path
        d="M6.19389 18.7587C6.19389 18.9412 6.32046 19.1589 6.48219 19.2501C6.64393 19.3414 6.77051 19.2712 6.77051 19.0887C6.77051 18.9061 6.64393 18.6885 6.48219 18.5972C6.32046 18.506 6.19389 18.5762 6.19389 18.7587Z"
        fill="#B86A0B"
      />
      <path
        d="M6.28517 18.8711C6.28517 18.9694 6.35549 19.0887 6.43987 19.1378C6.52425 19.187 6.59457 19.1519 6.59457 19.0536C6.59457 18.9553 6.52425 18.836 6.43987 18.7868C6.35549 18.7377 6.28517 18.7728 6.28517 18.8711Z"
        fill="#E0981B"
      />
      <path
        d="M19.0061 26.0303C19.0061 26.4445 19.3015 26.95 19.6671 27.1535C20.0328 27.3642 20.3282 27.1957 20.3282 26.7885C20.3282 26.3813 20.0328 25.8688 19.6671 25.6652C19.3015 25.4546 19.0061 25.6231 19.0061 26.0303Z"
        fill="#E0B350"
      />
      <path
        d="M19.1116 26.0858C19.1116 26.4298 19.3577 26.858 19.6671 27.0335C19.9765 27.2091 20.2227 27.0686 20.2227 26.7246C20.2227 26.3807 19.9765 25.9524 19.6671 25.7769C19.3577 25.6014 19.1116 25.7418 19.1116 26.0858Z"
        fill="#A85D00"
      />
      <path
        d="M19.2664 26.1716C19.2664 26.4243 19.4493 26.7402 19.6743 26.8666C19.8993 26.993 20.0822 26.8947 20.0822 26.642C20.0822 26.3892 19.8993 26.0733 19.6743 25.947C19.4493 25.8206 19.2664 25.9189 19.2664 26.1716Z"
        fill="#CD8B19"
      />
      <path
        d="M19.5618 25.8973C19.5969 25.9043 19.6391 25.9183 19.6743 25.9394C19.8993 26.0658 20.0822 26.3817 20.0822 26.6344C20.0822 26.7046 20.0681 26.7608 20.047 26.8029L19.6743 26.7257C19.5266 26.5993 19.5126 26.4589 19.4282 26.2764L19.5618 25.8903V25.8973Z"
        fill="#B86A0B"
      />
      <path
        d="M19.5474 26.5775C19.5193 26.5284 19.4982 26.4722 19.4771 26.4161C19.6318 26.3529 19.7795 26.2757 19.906 26.1633C19.9764 26.2616 20.0256 26.3669 20.0537 26.4792C19.8779 26.4792 19.7091 26.5143 19.5474 26.5775Z"
        fill="#DE9B16"
      />
      <path
        d="M19.2665 26.3603C19.2665 26.5428 19.3931 26.7604 19.5549 26.8517C19.7166 26.943 19.8432 26.8728 19.8432 26.6902C19.8432 26.5077 19.7166 26.2901 19.5549 26.1988C19.3931 26.1075 19.2665 26.1777 19.2665 26.3603Z"
        fill="#B86A0B"
      />
      <path
        d="M19.3504 26.4649C19.3504 26.5632 19.4207 26.6826 19.5051 26.7317C19.5895 26.7809 19.6598 26.7458 19.6598 26.6475C19.6598 26.5492 19.5895 26.4298 19.5051 26.3807C19.4207 26.3316 19.3504 26.3667 19.3504 26.4649Z"
        fill="#E0981B"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M5.94731 18.3427C5.9895 18.3427 6.03169 18.3567 6.07388 18.3778L19.6104 26.1914C20.2504 26.5635 20.6863 27.1953 20.8199 27.9114C20.8621 27.8552 20.8692 27.7991 20.8692 27.764C20.8692 26.8724 20.391 26.037 19.6104 25.5947L6.07388 17.7811C6.03169 17.753 5.9895 17.746 5.94731 17.746C5.86292 17.746 5.68712 17.8092 5.68712 18.0127C5.68712 18.1672 5.70822 18.3146 5.73635 18.4691C5.7926 18.3848 5.89808 18.3567 5.95434 18.3567L5.94731 18.3427Z"
          fill="#8C7900"
        />
      </g>
      <path
        d="M20.7569 19.34V24.0085L30.7986 18.1746C31.1994 17.9429 31.4526 17.5077 31.4526 17.0443V14.3415C31.4526 13.8009 30.8689 13.464 30.3978 13.7377L20.7569 19.3329V19.34Z"
        fill="#FFAA00"
      />
      <path
        d="M1.03911 8.43075L0.940662 8.53606L1.9392 8.50798L16.5517 16.9675L26.2347 11.3442C26.2347 11.3442 26.5933 11.1757 26.9871 11.4425L26.5933 11.1336L12.6559 3.06723C11.8543 2.60389 10.8558 2.60389 10.0541 3.06723L1.64386 7.94635C1.41884 8.07974 1.21491 8.2412 1.03911 8.42373V8.43075Z"
        fill="#FFAA00"
      />
      <path
        d="M10.1805 3.88071L10.0398 3.96496V3.41737L10.1805 3.33313V3.88071Z"
        fill="#C27100"
      />
      <path
        d="M10.0399 3.96614L9.9204 3.89594V3.21497L10.0399 3.27815V3.96614Z"
        fill="#DA7F00"
      />
      <path
        d="M11.1158 3.34095L11.0033 3.27776L10.9963 2.63892L11.1158 2.70912V3.34095Z"
        fill="#DA7F00"
      />
      <path
        d="M11.2563 3.26306L11.1157 3.34028V2.66633L11.2563 2.58911V3.26306Z"
        fill="#C27100"
      />
      <path
        d="M11.2489 2.5901L10.0394 3.27809L9.91983 3.21491L11.1293 2.5199L11.2489 2.5901Z"
        fill="#DA7F00"
      />
      <path
        d="M11.2563 2.58911V2.7225L10.0398 3.41751V3.2771L11.2563 2.58911Z"
        fill="#C27100"
      />
      <path
        d="M9.06912 4.51279L8.92848 4.59002V4.04243L9.06912 3.96521V4.51279Z"
        fill="#C27100"
      />
      <path
        d="M8.92862 4.59126L8.80908 4.52808V3.84009L8.92862 3.91029V4.59126Z"
        fill="#DA7F00"
      />
      <path
        d="M10.0045 3.97303L9.88494 3.90282V3.271L10.0045 3.33418V3.97303Z"
        fill="#DA7F00"
      />
      <path
        d="M10.145 3.88904L10.0044 3.97328V3.29231L10.145 3.21509V3.88904Z"
        fill="#C27100"
      />
      <path
        d="M10.1375 3.21498L8.92798 3.90999L8.80844 3.83979L10.0179 3.14478L10.1375 3.21498Z"
        fill="#DA7F00"
      />
      <path
        d="M10.145 3.21509V3.34847L8.92846 4.04349V3.9101L10.145 3.21509Z"
        fill="#C27100"
      />
      <path
        d="M7.95816 5.14146L7.81752 5.2257V4.67812L7.95816 4.59387V5.14146Z"
        fill="#C27100"
      />
      <path
        d="M7.81766 5.22505L7.69812 5.15485V4.47388L7.81766 4.53706V5.22505Z"
        fill="#DA7F00"
      />
      <path
        d="M8.89352 4.60602L8.77398 4.53582V3.89697L8.89352 3.96718V4.60602Z"
        fill="#DA7F00"
      />
      <path
        d="M9.03403 4.52289L8.89338 4.60713V3.92616L9.03403 3.84192V4.52289Z"
        fill="#C27100"
      />
      <path
        d="M9.02658 3.84114L7.81708 4.53615L7.69754 4.47297L8.90704 3.77795L9.02658 3.84114Z"
        fill="#DA7F00"
      />
      <path
        d="M9.03403 3.84192V3.98233L7.81749 4.67734V4.53693L9.03403 3.84192Z"
        fill="#C27100"
      />
      <path
        d="M6.8472 5.76945L6.70656 5.84667V5.29909L6.8472 5.21484V5.76945Z"
        fill="#C27100"
      />
      <path
        d="M6.7067 5.847L6.58715 5.78382V5.09583L6.7067 5.16603V5.847Z"
        fill="#DA7F00"
      />
      <path
        d="M7.78259 5.22797L7.66304 5.16479V4.51892L7.78259 4.58912V5.22797Z"
        fill="#DA7F00"
      />
      <path
        d="M7.9231 5.15174L7.78246 5.22896V4.54799L7.9231 4.46375V5.15174Z"
        fill="#C27100"
      />
      <path
        d="M7.91568 4.47023L6.70618 5.16524L6.58664 5.09504L7.79614 4.40002L7.91568 4.47023Z"
        fill="#DA7F00"
      />
      <path
        d="M7.9231 4.46375V4.60415L6.70656 5.29916V5.16578L7.9231 4.46375Z"
        fill="#C27100"
      />
      <path
        d="M5.73587 6.39353L5.59523 6.47778V5.92317L5.72884 5.84595L5.73587 6.39353Z"
        fill="#C27100"
      />
      <path
        d="M5.59537 6.47975L5.47582 6.40955V5.72156L5.59537 5.79176V6.47975Z"
        fill="#DA7F00"
      />
      <path
        d="M6.67126 5.86078L6.55172 5.7976V5.15173L6.67126 5.21492V5.86078Z"
        fill="#DA7F00"
      />
      <path
        d="M6.81177 5.78369L6.67113 5.86092V5.17293L6.80473 5.0957L6.81177 5.78369Z"
        fill="#C27100"
      />
      <path
        d="M6.80435 5.09608L5.59486 5.79109L5.47531 5.72089L6.68481 5.02588L6.80435 5.09608Z"
        fill="#DA7F00"
      />
      <path
        d="M6.80475 5.0957V5.22909L5.59525 5.9241V5.79071L6.80475 5.0957Z"
        fill="#C27100"
      />
      <path
        d="M4.62411 7.02445L4.48348 7.10168V6.55409L4.61709 6.46985L4.62411 7.02445Z"
        fill="#C27100"
      />
      <path
        d="M4.48361 7.10103L4.36407 7.03784V6.34985L4.48361 6.41304V7.10103Z"
        fill="#DA7F00"
      />
      <path
        d="M5.55994 6.48988L5.44039 6.41967V5.7738L5.55994 5.83699V6.48988Z"
        fill="#DA7F00"
      />
      <path
        d="M5.70044 6.41376L5.5598 6.49098V5.80299L5.69341 5.71875L5.70044 6.41376Z"
        fill="#C27100"
      />
      <path
        d="M5.69312 5.71785L4.48361 6.41286L4.36407 6.34967L5.57357 5.65466L5.69312 5.71785Z"
        fill="#DA7F00"
      />
      <path
        d="M5.69299 5.71875V5.85214L4.48349 6.55417V6.41376L5.69299 5.71875Z"
        fill="#C27100"
      />
      <path
        d="M3.50729 7.65L3.37368 7.73425V7.17964L3.50729 7.10242V7.65Z"
        fill="#C27100"
      />
      <path
        d="M3.37381 7.73634L3.25427 7.66614V6.97815L3.37381 7.04835V7.73634Z"
        fill="#DA7F00"
      />
      <path
        d="M4.44968 7.12434L4.33013 7.05413V6.40125L4.44968 6.47145V7.12434Z"
        fill="#DA7F00"
      />
      <path
        d="M4.58319 7.03937L4.44958 7.12362V6.4286L4.58319 6.34436V7.03937Z"
        fill="#C27100"
      />
      <path
        d="M4.58316 6.34529L3.37366 7.04732L3.25412 6.97712L4.46362 6.2821L4.58316 6.34529Z"
        fill="#DA7F00"
      />
      <path
        d="M4.58319 6.34436V6.48477L3.37369 7.17978V7.04639L4.58319 6.34436Z"
        fill="#C27100"
      />
      <path
        d="M2.39636 8.28214L2.26275 8.35937V7.81178L2.39636 7.72754V8.28214Z"
        fill="#C27100"
      />
      <path
        d="M2.26285 8.35957L2.14331 8.29639V7.6084L2.26285 7.67158V8.35957Z"
        fill="#DA7F00"
      />
      <path
        d="M3.33871 7.75648L3.21917 7.68628V7.02637L3.33871 7.09657V7.75648Z"
        fill="#DA7F00"
      />
      <path
        d="M3.47223 7.67316L3.33862 7.7574V7.05537L3.47223 6.97815V7.67316Z"
        fill="#C27100"
      />
      <path
        d="M3.47226 6.97731L2.26276 7.67232L2.14322 7.60914L3.35272 6.9071L3.47226 6.97731Z"
        fill="#DA7F00"
      />
      <path
        d="M3.47223 6.97815V7.11154L2.26273 7.81357V7.67316L3.47223 6.97815Z"
        fill="#C27100"
      />
      <path
        d="M3.19078 8.91026L3.33142 8.9945V8.44691L3.19078 8.36267V8.91026Z"
        fill="#C27100"
      />
      <path
        d="M3.33139 8.99385L3.45093 8.92365V8.24268L3.33139 8.30586V8.99385Z"
        fill="#DA7F00"
      />
      <path
        d="M2.25552 8.36866L2.36803 8.29845L2.37506 7.66663L2.25552 7.73683V8.36866Z"
        fill="#DA7F00"
      />
      <path
        d="M2.11491 8.28552L2.25555 8.36977V7.69582L2.11491 7.61157V8.28552Z"
        fill="#C27100"
      />
      <path
        d="M2.12188 7.61781L3.33139 8.3058L3.45093 8.24262L2.24143 7.54761L2.12188 7.61781Z"
        fill="#DA7F00"
      />
      <path
        d="M2.11489 7.61157V7.75198L3.33142 8.44699V8.30658L2.11489 7.61157Z"
        fill="#C27100"
      />
      <path
        d="M4.3156 9.54685L4.45624 9.62408V9.07649L4.3156 8.99927V9.54685Z"
        fill="#C27100"
      />
      <path
        d="M4.4562 9.6252L4.56871 9.56201L4.57574 8.87402L4.4562 8.94423V9.6252Z"
        fill="#DA7F00"
      />
      <path
        d="M3.3733 9.00623L3.49284 8.93603L3.49988 8.3042L3.38034 8.36738L3.3733 9.00623Z"
        fill="#DA7F00"
      />
      <path
        d="M3.23973 8.92297L3.37334 9.00722L3.38037 8.32625L3.23973 8.24902V8.92297Z"
        fill="#C27100"
      />
      <path
        d="M3.24679 8.24855L4.45629 8.94356L4.57584 8.87336L3.36633 8.17834L3.24679 8.24855Z"
        fill="#DA7F00"
      />
      <path
        d="M3.2397 8.24902V8.38241L4.45624 9.07742V8.94403L3.2397 8.24902Z"
        fill="#C27100"
      />
      <path
        d="M5.44167 10.1784L5.57527 10.2627L5.58231 9.7151L5.44167 9.63086V10.1784Z"
        fill="#C27100"
      />
      <path
        d="M5.57521 10.263L5.69475 10.1928L5.70178 9.51184L5.58224 9.57502L5.57521 10.263Z"
        fill="#DA7F00"
      />
      <path
        d="M4.49981 9.63782L4.61935 9.57464V8.93579L4.50684 9.00599L4.49981 9.63782Z"
        fill="#DA7F00"
      />
      <path
        d="M4.36577 9.55988L4.49938 9.6371L4.50641 8.96315L4.36577 8.87891V9.55988Z"
        fill="#C27100"
      />
      <path
        d="M4.36553 8.87971L5.58206 9.57472L5.7016 9.51154L4.48507 8.81653L4.36553 8.87971Z"
        fill="#DA7F00"
      />
      <path
        d="M4.36578 8.87891V9.01931L5.58231 9.71432V9.57392L4.36578 8.87891Z"
        fill="#C27100"
      />
      <path
        d="M6.56591 10.8185L6.69951 10.8957L6.70654 10.3482L6.56591 10.2639V10.8185Z"
        fill="#C27100"
      />
      <path
        d="M6.69949 10.8961L6.81903 10.8259V10.1449L6.70652 10.2151L6.69949 10.8961Z"
        fill="#DA7F00"
      />
      <path
        d="M5.62362 10.2779L5.74316 10.2077V9.56885L5.62362 9.63905V10.2779Z"
        fill="#DA7F00"
      />
      <path
        d="M5.48963 10.2L5.62323 10.2772V9.59621L5.48963 9.51196V10.2Z"
        fill="#C27100"
      />
      <path
        d="M5.48999 9.52003L6.69949 10.215L6.81903 10.1448L5.60953 9.44983L5.48999 9.52003Z"
        fill="#DA7F00"
      />
      <path
        d="M5.49001 9.51196V9.65237L6.70654 10.3474L6.69951 10.214L5.49001 9.51196Z"
        fill="#C27100"
      />
      <path
        d="M7.69113 11.4474L7.82474 11.5316V10.977L7.69113 10.8998V11.4474Z"
        fill="#C27100"
      />
      <path
        d="M7.82467 11.5327L7.94421 11.4625V10.7745L7.82467 10.8447V11.5327Z"
        fill="#DA7F00"
      />
      <path
        d="M6.7488 10.9138L6.86835 10.8436V10.2047L6.7488 10.2679V10.9138Z"
        fill="#DA7F00"
      />
      <path
        d="M6.6148 10.8305L6.74841 10.9148V10.2268L6.6148 10.1495V10.8305Z"
        fill="#C27100"
      />
      <path
        d="M6.61535 10.1499L7.82485 10.8449L7.9444 10.7747L6.7349 10.0797L6.61535 10.1499Z"
        fill="#DA7F00"
      />
      <path
        d="M6.61524 10.1495V10.2829L7.82474 10.9779V10.8445L6.61524 10.1495Z"
        fill="#C27100"
      />
      <path
        d="M8.8164 12.0831L8.95001 12.1674V11.6198L8.8164 11.5355V12.0831Z"
        fill="#C27100"
      />
      <path
        d="M8.94998 12.1668L9.06952 12.0966V11.4156L8.94998 11.4788V12.1668Z"
        fill="#DA7F00"
      />
      <path
        d="M7.87365 11.5478L7.99319 11.4846V10.8387L7.87365 10.9089V11.5478Z"
        fill="#DA7F00"
      />
      <path
        d="M7.74054 11.4716L7.87415 11.5488V10.8678L7.74054 10.7836V11.4716Z"
        fill="#C27100"
      />
      <path
        d="M7.74026 10.7828L8.94976 11.4778L9.06931 11.4146L7.8598 10.7196L7.74026 10.7828Z"
        fill="#DA7F00"
      />
      <path
        d="M7.74051 10.7836V10.924L8.95001 11.619V11.4786L7.74051 10.7836Z"
        fill="#C27100"
      />
      <path
        d="M9.93416 12.7207L10.0748 12.7979V12.2503L9.94119 12.1731L9.93416 12.7207Z"
        fill="#C27100"
      />
      <path
        d="M10.0748 12.799L10.1943 12.7358V12.0479L10.0748 12.1181V12.799Z"
        fill="#DA7F00"
      />
      <path
        d="M8.9989 12.187L9.11844 12.1238V11.4709L8.9989 11.5412V12.187Z"
        fill="#DA7F00"
      />
      <path
        d="M8.85829 12.1099L8.99893 12.1872V11.4992L8.86532 11.4149L8.85829 12.1099Z"
        fill="#C27100"
      />
      <path
        d="M8.86526 11.4221L10.0748 12.1171L10.1943 12.0469L8.98481 11.3519L8.86526 11.4221Z"
        fill="#DA7F00"
      />
      <path
        d="M8.8653 11.4149V11.5553L10.0748 12.2503V12.1169L8.8653 11.4149Z"
        fill="#C27100"
      />
      <path
        d="M11.0593 13.3537L11.2 13.438V12.8904L11.0664 12.8062L11.0593 13.3537Z"
        fill="#C27100"
      />
      <path
        d="M11.1999 13.4373L11.3195 13.3671V12.6862L11.1999 12.7493V13.4373Z"
        fill="#DA7F00"
      />
      <path
        d="M10.1241 12.8272L10.2436 12.757V12.1111L10.1241 12.1743V12.8272Z"
        fill="#DA7F00"
      />
      <path
        d="M9.98348 12.743L10.1241 12.8273V12.1323L9.99051 12.0551L9.98348 12.743Z"
        fill="#C27100"
      />
      <path
        d="M9.99044 12.0552L11.1999 12.7502L11.3195 12.687L10.11 11.985L9.99044 12.0552Z"
        fill="#DA7F00"
      />
      <path
        d="M9.99049 12.0551V12.1884L11.2 12.8905V12.7501L9.99049 12.0551Z"
        fill="#C27100"
      />
      <path
        d="M12.1846 13.9915L12.3252 14.0687V13.5211L12.1846 13.4369V13.9915Z"
        fill="#C27100"
      />
      <path
        d="M12.3252 14.069L12.4447 14.0059V13.3179L12.3252 13.3811V14.069Z"
        fill="#DA7F00"
      />
      <path
        d="M11.2493 13.4641L11.3688 13.3939V12.741L11.2493 12.8112V13.4641Z"
        fill="#DA7F00"
      />
      <path
        d="M11.1087 13.3799L11.2493 13.4642V12.7692L11.1087 12.6849V13.3799Z"
        fill="#C27100"
      />
      <path
        d="M11.1158 12.6851L12.3253 13.3801L12.4449 13.317L11.2354 12.6219L11.1158 12.6851Z"
        fill="#DA7F00"
      />
      <path
        d="M11.1087 12.6849V12.8253L12.3252 13.5204V13.3799L11.1087 12.6849Z"
        fill="#C27100"
      />
      <path
        d="M13.3102 14.622L13.4509 14.7063V14.1517L13.3102 14.0745V14.622Z"
        fill="#C27100"
      />
      <path
        d="M13.4508 14.7084L13.5704 14.6382V13.9502L13.4508 14.0204V14.7084Z"
        fill="#DA7F00"
      />
      <path
        d="M12.3749 14.0964L12.4945 14.0332V13.3733L12.3749 13.4435V14.0964Z"
        fill="#DA7F00"
      />
      <path
        d="M12.2344 14.0201L12.375 14.0974V13.4024L12.2344 13.3181V14.0201Z"
        fill="#C27100"
      />
      <path
        d="M12.2412 13.3242L13.4507 14.0192L13.5702 13.949L12.3607 13.254L12.2412 13.3242Z"
        fill="#DA7F00"
      />
      <path
        d="M12.2343 13.3181V13.4585L13.4509 14.1535V14.0201L12.2343 13.3181Z"
        fill="#C27100"
      />
      <path
        d="M14.435 15.2604L14.5757 15.3376V14.7901L14.435 14.7058V15.2604Z"
        fill="#C27100"
      />
      <path
        d="M14.5756 15.337L14.6952 15.2738V14.5858L14.5756 14.649V15.337Z"
        fill="#DA7F00"
      />
      <path
        d="M13.4998 14.7338L13.6123 14.6636L13.6193 14.0107L13.4998 14.0739V14.7338Z"
        fill="#DA7F00"
      />
      <path
        d="M13.3591 14.6497L13.4998 14.734V14.0319L13.3591 13.9547V14.6497Z"
        fill="#C27100"
      />
      <path
        d="M13.3661 13.9548L14.5756 14.6499L14.6952 14.5867L13.4857 13.8846L13.3661 13.9548Z"
        fill="#DA7F00"
      />
      <path
        d="M13.3591 13.9547V14.0881L14.5757 14.7901V14.6497L13.3591 13.9547Z"
        fill="#C27100"
      />
      <path
        d="M9.04128 4.33056L8.94283 4.43587L9.94138 4.40779L24.5538 12.8673L27.4651 11.1192C27.4651 11.1192 27.8237 10.9507 28.2175 11.2175L27.8237 10.9086L13.8863 2.84226C13.0847 2.37892 12.0861 2.37892 11.2845 2.84226L9.64603 3.85318C9.42101 3.98657 9.21708 4.14804 9.04128 4.33056Z"
        fill="#F2C91F"
      />
      <path
        d="M27.6201 14.6778L27.7959 17.2893L31.4526 15.1551V14.1723C31.4526 13.6317 30.9814 13.1965 30.447 13.2597H30.4048C29.7086 13.365 27.6272 14.6778 27.6272 14.6778H27.6201Z"
        fill="#505453"
      />
      <path
        d="M28.1402 15.3655C28.1402 15.3655 30.2216 14.0527 30.9178 13.9545C30.9319 13.9545 30.9459 13.9545 30.96 13.9545C31.1287 13.9334 31.2905 13.9685 31.4311 14.0387C31.3538 13.5683 30.9319 13.2173 30.4396 13.2735H30.3974C29.7013 13.3788 27.6198 14.6916 27.6198 14.6916L27.7956 17.3031L28.2527 17.0364L28.1402 15.3796V15.3655Z"
        fill="#747776"
      />
      <path
        d="M45.8254 25.048L45.0097 24.4232C45.0097 24.4232 43.6455 23.7703 42.5837 23.2438C41.5219 22.7243 39.0044 19.5581 39.0044 19.5581L38.1957 19.9372C38.1957 19.9372 38.6809 21.0675 39.3138 22.4856C38.3715 21.4747 37.4925 20.3584 37.4925 20.3584L36.6839 20.7375C36.6839 20.7375 37.0495 21.58 37.5558 22.7243C36.8104 21.8818 36.1916 21.1096 36.1916 21.1096L35.3829 21.4887C35.3829 21.4887 37.7527 26.9926 38.8075 28.9372C39.8623 30.8819 40.0592 31.5558 40.0592 31.5558L44.3065 29.1689L46.6904 27.0137L45.8325 25.034L45.8254 25.048Z"
        fill="#BA6300"
      />
      <path
        d="M40.1014 31.5472C39.9537 31.1751 39.7217 30.0799 39.0396 28.8233C38.1254 27.1384 36.1916 22.6945 35.7275 21.6275L36.1143 21.4449C36.3322 21.7187 36.8104 22.2944 37.3519 22.9051L37.7949 22.6243C37.4503 21.8381 37.169 21.1992 37.0284 20.8693L37.4152 20.6867C37.6964 21.0377 38.3926 21.8802 39.1169 22.6594L39.5529 22.3716C39.0958 21.3396 38.7091 20.4621 38.5403 20.0619L38.927 19.8794C39.4685 20.5463 41.4937 22.9964 42.4641 23.4738C43.4416 23.9582 44.6792 24.5479 44.8691 24.6392L45.6074 25.2078L46.3669 26.9559L44.0463 29.3217L40.1014 31.5472Z"
        fill="#823600"
      />
      <path
        d="M45.8254 25.0479L41.5851 27.4348L42.4431 29.4145L46.6904 27.0276L45.8254 25.0479Z"
        fill="#C26700"
      />
      <path
        d="M43.1252 3.44623H48.9125C48.9125 3.44623 50.9448 4.1974 48.9266 6.13501C47.696 7.32144 46.9295 7.63034 46.3037 7.78478C45.6778 7.93923 36.269 7.74266 34.7783 8.15686C33.2945 8.57808 30.4677 10.9439 29.4199 14.3067C28.3721 17.6694 28.7097 19.3332 28.7097 19.3332L25.4468 21.2217L23.8506 17.6694L26.8884 11.8987C26.8884 11.8987 26.5368 6.75982 26.8532 6.36668C26.9306 6.27542 29.7363 4.75903 29.9192 4.61862C30.5169 4.15528 31.8178 3.53749 34.5814 3.54451C38.2028 3.54451 43.1252 3.43921 43.1252 3.43921V3.44623Z"
        fill="#7E1F00"
      />
      <path
        d="M31.5153 10.052C30.0315 10.4732 27.2047 12.839 26.1569 16.2018C25.1091 19.5645 25.4467 21.2283 25.4467 21.2283L28.7095 19.3398C28.7095 19.3398 28.372 17.676 29.4198 14.3133C29.9823 12.5161 31.0512 11.0067 32.1482 9.91858C31.8739 9.96772 31.6559 10.0098 31.5083 10.052H31.5153Z"
        fill="#9E4700"
      />
      <path
        d="M48.9126 3.44672H43.1253C43.1253 3.44672 38.2029 3.55904 34.5814 3.55202C31.8178 3.55202 30.5169 4.16279 29.9192 4.62613C29.7293 4.77356 26.9306 6.28293 26.8533 6.37419C27.5213 5.93893 28.8222 5.43347 31.3186 5.44049C34.94 5.44049 39.8624 5.33518 39.8624 5.33518H45.6498C45.6498 5.33518 47.8648 6.95688 46.3108 7.78527C46.9366 7.63083 47.7031 7.32193 48.9337 6.1355C50.9519 4.19789 48.9196 3.4397 48.9196 3.4397L48.9126 3.44672Z"
        fill="#F8D64B"
      />
      <path
        d="M46.1913 4.91296L45.6428 5.33418L46.5781 7.60174L48.2587 6.88567L47.2883 4.99019L46.1913 4.91296Z"
        fill="#F79900"
      />
      <path
        d="M35.7348 4.99105L40.7767 4.6962L41.7752 4.33114H46.6484L47.0844 3.8678H41.9299L41.058 3.85376L36.0582 4.33114C36.0582 4.33114 35.0034 4.64003 35.7348 4.99105Z"
        fill="#C56000"
      />
      <path
        d="M34.4407 2.68808L34.8837 2.34409L36.4659 2.15454L37.6895 3.69901L35.0384 5.44005L34.4407 2.68808Z"
        fill="#DB8318"
      />
      <path
        d="M34.4407 2.68808L34.8837 2.34409L36.4659 2.15454L37.6895 3.64987L37.2394 3.98684L34.4407 2.68808Z"
        fill="#FAB03E"
      />
      <path
        d="M33.5754 3.9869L34.4404 2.68815L36.0155 2.49158L37.2391 3.9869H33.5754Z"
        fill="#E07500"
      />
      <path
        d="M50.5158 13.0847L45.5793 27.5255L43.4627 28.7752L46.3388 10.4381L47.935 6.35928L47.8788 5.39048L47.6819 1.88734L49.7915 0.672823C49.7915 0.672823 51.001 0.455193 51.7815 3.13695C51.7815 3.15802 51.7956 3.17908 51.8026 3.20014C52.5832 5.9521 50.5158 13.0777 50.5158 13.0777V13.0847Z"
        fill="#F79900"
      />
      <path
        d="M51.7813 3.14397L49.4045 4.51293L47.8715 5.39047L47.6746 1.88734L49.7912 0.672823C49.7912 0.672823 51.0007 0.455193 51.7813 3.13695V3.14397Z"
        fill="#F8D64B"
      />
      <path
        d="M42.4433 29.4211L40.0595 31.5763C40.0595 31.5763 38.4281 31.7238 37.3803 29.7791C36.3255 27.8345 33.9557 22.3236 33.9557 22.3236L34.7644 21.9445C34.7644 21.9445 35.8755 23.3415 36.9303 24.4296C37.0779 24.5911 37.2326 24.7385 37.3733 24.8719C37.746 25.2229 38.0835 25.5038 38.3437 25.6301C38.3577 25.6301 38.3648 25.6442 38.3788 25.6512C38.5687 25.7495 38.7726 25.8478 38.9766 25.946C39.8767 26.3883 40.7627 26.8095 40.7627 26.8095L41.5784 27.4343L42.4363 29.4211H42.4433Z"
        fill="#9C4300"
      />
      <path
        d="M33.2522 4.10045C33.2522 4.10045 32.9358 4.24788 33.0061 4.71824C33.0553 5.04117 33.4632 5.06223 33.4632 5.06223L40.8749 4.12853C40.8749 4.12853 40.5936 4.07237 40.5233 3.67221C40.453 3.25801 40.6709 3.16675 40.6709 3.16675L33.2592 4.10045H33.2522Z"
        fill="#D17000"
      />
      <path
        d="M40.9171 3.27033C41.0578 3.43882 41.107 3.74771 41.0296 3.95832C40.9523 4.16893 40.7765 4.20403 40.6288 4.02853C40.4882 3.86004 40.439 3.55115 40.5163 3.34054C40.5937 3.12993 40.7695 3.09482 40.9171 3.27033Z"
        fill="#E0981B"
      />
      <path
        d="M48.1251 2.47772L48.3502 2.92L40.9455 3.86073C40.9455 3.86073 40.7064 3.88881 40.6923 3.6431C40.6853 3.43951 40.8189 3.40441 40.8189 3.40441L48.1181 2.4707L48.1251 2.47772Z"
        fill="#B2B1AD"
      />
      <path
        d="M49.6863 4.41418C48.8987 1.66222 47.6752 1.88687 47.6752 1.88687L46.1844 4.91263H46.2969C46.2969 4.91263 48.9901 6.9766 46.2828 7.67863C45.6148 7.85414 45.1155 7.89626 44.6795 7.97348L44.4897 8.3596L42.6262 27.3074L43.456 28.7817L47.2462 14.9798C47.2462 14.9798 50.4669 7.17317 49.6793 4.42823L49.6863 4.41418Z"
        fill="#B85F00"
      />
      <path
        d="M47.3867 7.03333C47.2882 7.65111 47.1406 8.73926 46.8593 9.01305C46.4163 9.44129 44.377 9.53958 44.377 9.53958L44.4965 8.35314C44.4965 8.35314 44.4965 8.31804 44.4965 8.29698C44.8341 8.22678 45.2208 8.14253 45.6709 8.02319C46.1069 7.91086 46.4022 7.76344 46.5921 7.59495L47.4008 7.03333H47.3867Z"
        fill="#A14B00"
      />
      <path
        d="M39.8627 5.33496H45.65C45.65 5.33496 48.3714 7.32171 45.6641 8.02374C43.7092 8.5292 42.9427 8.50814 42.3169 8.66259C41.691 8.81704 33.0065 9.63139 31.5228 10.0456C30.039 10.4598 27.2122 12.8327 26.1644 16.1954C25.1166 19.5581 25.4542 21.2219 25.4542 21.2219L23.3586 18.9052L23.6259 13.7944C23.6259 13.7944 25.9534 7.26555 26.2769 6.87943C26.6004 6.49331 27.6974 5.43325 31.3189 5.44027C34.9403 5.44027 39.8627 5.33496 39.8627 5.33496Z"
        fill="#E07500"
      />
      <path
        d="M30.6645 6.31726L31.0372 6.49979L28.0275 12.7479L26.2133 15.1488L30.6645 6.31726Z"
        fill="#9E4700"
      />
      <path
        d="M47.0844 15.5772L49.2643 14.3416L50.8605 13.5343L51.9997 5.50305L49.7706 6.26827L49.3487 14.2504L47.0844 15.5772Z"
        fill="#F79900"
      />
      <path
        d="M47.0842 15.5769L49.3485 14.2501L49.7915 5.98718L47.9421 11.4139L47.0842 15.5769Z"
        fill="#B85F00"
      />
      <path
        d="M24.0268 18.138C24.0268 18.138 23.9635 18.4539 24.3292 18.6364C24.6878 18.8119 24.7793 18.6505 24.7793 18.6505L28.1194 11.9671L27.3319 11.5038L24.0268 18.145V18.138Z"
        fill="#FFAA00"
      />
      <path
        d="M27.5355 11.397C27.7395 11.397 27.9926 11.5374 28.1051 11.7129C28.2177 11.8884 28.1333 12.0358 27.9293 12.0428C27.7254 12.0428 27.4723 11.9024 27.3597 11.7269C27.2543 11.5514 27.3316 11.404 27.5355 11.397Z"
        fill="#F8D64B"
      />
      <path
        d="M30.285 6.10425L30.51 6.13233L30.6577 6.31486L27.9504 11.7626C27.9504 11.7626 27.8449 11.8539 27.6902 11.7626C27.5566 11.6854 27.5706 11.552 27.5706 11.552L30.285 6.10425Z"
        fill="#B2B1AD"
      />
      <path
        d="M30.3837 6.04846C30.4822 6.04846 30.6017 6.11164 30.6509 6.19589C30.7002 6.28013 30.665 6.35034 30.5666 6.35034C30.4681 6.35034 30.3486 6.28715 30.2993 6.20291C30.2501 6.11867 30.2853 6.04846 30.3837 6.04846Z"
        fill="#D3D2D0"
      />
      <path
        d="M43.8212 6.22586C43.8212 6.69622 44.1587 7.27189 44.5736 7.51058C44.9885 7.74927 45.326 7.55972 45.326 7.08936C45.326 6.619 44.9885 6.04333 44.5736 5.80464C44.1587 5.56595 43.8212 5.7555 43.8212 6.22586Z"
        fill="#CD6E19"
      />
      <path
        d="M44.3628 5.72742C44.4331 5.74146 44.4964 5.76954 44.5738 5.81166C44.9887 6.05035 45.3262 6.62602 45.3262 7.09638C45.3262 7.22274 45.2981 7.32805 45.2559 7.41229L44.5738 7.27189C44.3066 7.04022 44.2714 6.78046 44.1167 6.44349L44.3628 5.73444V5.72742Z"
        fill="#B84A0B"
      />
      <path
        d="M44.3415 6.9698C44.2923 6.87854 44.2501 6.78026 44.2079 6.66793C44.4962 6.55561 44.7634 6.40116 44.9955 6.20459C45.1221 6.3801 45.2205 6.58369 45.2697 6.78026C44.9463 6.78026 44.6298 6.85046 44.3345 6.96278L44.3415 6.9698Z"
        fill="#DE7D16"
      />
      <path
        d="M43.8214 6.56976C43.8214 6.90674 44.0605 7.31391 44.3558 7.4824C44.6512 7.65089 44.8903 7.5175 44.8903 7.18755C44.8903 6.85759 44.6512 6.4434 44.3558 6.27491C44.0605 6.10642 43.8214 6.23981 43.8214 6.56976Z"
        fill="#B84A0B"
      />
      <path
        d="M43.9832 6.77275C43.9832 6.95528 44.1097 7.17291 44.2715 7.26418C44.4332 7.35544 44.5598 7.28524 44.5598 7.10271C44.5598 6.92018 44.4332 6.70255 44.2715 6.61129C44.1097 6.52002 43.9832 6.59023 43.9832 6.77275Z"
        fill="#E0781B"
      />
      <path
        d="M47.3865 3.60098C47.3865 4.07134 47.724 4.64701 48.1389 4.8857C48.5538 5.12439 48.8914 4.93484 48.8914 4.46448C48.8914 3.99412 48.5538 3.41845 48.1389 3.17976C47.724 2.94107 47.3865 3.13062 47.3865 3.60098Z"
        fill="#CD6E19"
      />
      <path
        d="M47.928 3.10278C47.9983 3.11682 48.0616 3.14491 48.1389 3.18703C48.5538 3.42572 48.8914 4.00138 48.8914 4.47174C48.8914 4.59811 48.8632 4.70342 48.821 4.78766L48.1389 4.64725C47.8717 4.41558 47.8366 4.15583 47.6819 3.81886L47.928 3.1098V3.10278Z"
        fill="#B84A0B"
      />
      <path
        d="M47.9067 4.34517C47.8575 4.25391 47.8153 4.15562 47.7731 4.0433C48.0614 3.93097 48.3286 3.77652 48.5607 3.57996C48.6872 3.75546 48.7857 3.95905 48.8349 4.15562C48.5114 4.15562 48.195 4.22582 47.8996 4.33815L47.9067 4.34517Z"
        fill="#DE7D16"
      />
      <path
        d="M47.3866 3.94501C47.3866 4.28199 47.6256 4.68917 47.921 4.85765C48.2163 5.02614 48.4554 4.89275 48.4554 4.55578C48.4554 4.2188 48.2163 3.81163 47.921 3.64314C47.6256 3.47465 47.3866 3.60804 47.3866 3.94501Z"
        fill="#B84A0B"
      />
      <path
        d="M47.5483 4.148C47.5483 4.33053 47.6749 4.54816 47.8366 4.63942C47.9984 4.73068 48.1249 4.66048 48.1249 4.47795C48.1249 4.29542 47.9984 4.07779 47.8366 3.98653C47.6749 3.89527 47.5483 3.96547 47.5483 4.148Z"
        fill="#E0781B"
      />
      <path
        d="M39.3003 24.6476C39.3003 24.8723 39.4621 25.1461 39.6589 25.2584C39.8558 25.3707 40.0176 25.2795 40.0176 25.0618C40.0176 24.8442 39.8558 24.5634 39.6589 24.4511C39.4621 24.3387 39.3003 24.43 39.3003 24.6476Z"
        fill="#CD6E19"
      />
      <path
        d="M39.5605 24.41C39.5605 24.41 39.6238 24.4311 39.6589 24.4522C39.8558 24.5645 40.0176 24.8383 40.0176 25.0629C40.0176 25.1261 40.0035 25.1752 39.9824 25.2103L39.6589 25.1401C39.5324 25.0278 39.5183 24.9085 39.441 24.747L39.5605 24.41Z"
        fill="#B84A0B"
      />
      <path
        d="M39.5534 24.9981C39.5323 24.9559 39.5112 24.9068 39.4901 24.8576C39.6237 24.8085 39.7503 24.7313 39.8628 24.64C39.9261 24.7243 39.9683 24.8155 39.9893 24.9138C39.8346 24.9138 39.687 24.9489 39.5463 24.9981H39.5534Z"
        fill="#DE7D16"
      />
      <path
        d="M39.3001 24.8104C39.3001 24.9718 39.4126 25.1614 39.5532 25.2456C39.6939 25.3299 39.8064 25.2597 39.8064 25.1052C39.8064 24.9508 39.6939 24.7542 39.5532 24.67C39.4126 24.5857 39.3001 24.6559 39.3001 24.8104Z"
        fill="#B84A0B"
      />
      <path
        d="M39.3777 24.9084C39.3777 24.9927 39.4409 25.098 39.5113 25.1401C39.5886 25.1822 39.6449 25.1471 39.6449 25.0629C39.6449 24.9786 39.5816 24.8733 39.5113 24.8312C39.4339 24.7891 39.3777 24.8242 39.3777 24.9084Z"
        fill="#E0781B"
      />
      <path
        d="M15.5672 10.2917H15.2156V8.97895H15.5672V10.2917Z"
        fill="#272727"
      />
      <path
        d="M15.912 9.0902V8.92171H15.7995C15.7995 8.92171 15.7784 8.90767 15.7643 8.90065C15.5745 8.78832 15.2721 8.78832 15.0822 8.90065C15.0682 8.90767 15.0541 8.92171 15.0471 8.92873H14.9486V9.09722C14.9486 9.16742 14.9979 9.23762 15.0893 9.29379C15.2791 9.40611 15.5815 9.40611 15.7714 9.29379C15.8628 9.23762 15.912 9.16742 15.912 9.09722V9.0902Z"
        fill="#1D1D1D"
      />
      <path
        d="M15.912 8.92343L15.8276 8.08801H15.026L14.9486 8.90939C14.9486 8.98661 14.9979 9.05682 15.0893 9.11298C15.2791 9.2253 15.5815 9.2253 15.7714 9.11298C15.8628 9.05682 15.912 8.98661 15.912 8.91641V8.92343Z"
        fill="#C97735"
      />
      <path
        d="M15.1458 8.75351C15.3005 8.66224 15.5607 8.66224 15.7154 8.75351C15.8701 8.84477 15.8772 8.9922 15.7154 9.08346C15.5607 9.17473 15.3005 9.17473 15.1458 9.08346C14.9841 8.9922 14.9841 8.84477 15.1458 8.75351Z"
        fill="#BC5705"
      />
      <path
        d="M15.6377 8.31767C15.5392 8.14216 15.3705 8.14216 15.265 8.31767C15.1595 8.49317 15.1595 8.78101 15.265 8.95651C15.3634 9.13202 15.5322 9.13202 15.6377 8.95651C15.7432 8.78101 15.7432 8.49317 15.6377 8.31767Z"
        fill="#BD4D00"
      />
      <path
        d="M15.1451 9.06384C15.2225 9.10596 15.3139 9.13404 15.4053 9.14106C15.4053 9.14106 15.4124 9.13404 15.4194 9.12702C15.4335 9.10596 15.4475 9.0849 15.4686 9.06384C15.6022 8.82515 15.6093 8.44605 15.4686 8.21438C15.4335 8.15822 15.3983 8.11609 15.3561 8.08801H15.0959C15.0959 8.08801 15.0819 8.09503 15.0748 8.10205L15.0045 8.86025C15.0045 8.86025 15.0045 8.86025 15.0045 8.86727C15.0045 8.93747 15.0537 9.00767 15.1451 9.06384Z"
        fill="#E07705"
      />
      <path
        d="M15.3988 8.40959C15.3214 8.28323 15.2019 8.28323 15.1316 8.40959C15.0542 8.53596 15.0542 8.74657 15.1316 8.87293C15.2089 8.9993 15.3285 8.9993 15.3988 8.87293C15.4691 8.74657 15.4761 8.53596 15.3988 8.40959Z"
        fill="#E09C05"
      />
      <path
        d="M15.1454 7.92538C15.3001 7.83412 15.5533 7.83412 15.708 7.92538C15.8627 8.01665 15.8697 8.16407 15.708 8.25534C15.5533 8.3466 15.3001 8.3466 15.1454 8.25534C14.9907 8.16407 14.9837 8.01665 15.1454 7.92538Z"
        fill="#EDA94D"
      />
      <path
        d="M16.5514 21.6005L1.2709 12.762C0.8982 12.5443 0.666144 12.1512 0.666144 11.723V9.09034C0.666144 8.49361 1.31309 8.12154 1.82642 8.42341L16.5444 16.9391V21.6076L16.5514 21.6005Z"
        fill="#B85F00"
      />
      <path
        d="M24.6874 17.494L9.33663 8.61334L8.80923 8.64142V4.97681C8.80923 4.38009 9.45616 4.00801 9.9695 4.30988L24.6874 12.8255V17.494Z"
        fill="#E07500"
      />
      <path
        d="M8.80925 6.96343L21.1433 13.9837V12.355L8.81627 5.32068C8.81627 5.32068 8.81627 5.3277 8.81627 5.33472V6.96343H8.80925Z"
        fill="#BAA58E"
      />
      <path
        d="M25.7003 21.1297L24.6103 9.42684L21.1647 7.44711C20.6232 7.13821 20.0044 7.24352 19.5895 7.60156L14.7093 10.4378L19.1184 12.1016V17.3458L25.6933 21.1297H25.7003Z"
        fill="#F8D64B"
      />
      <path
        d="M16.2285 10.3178L19.6742 12.2976C19.6742 12.2976 21.8963 15.1619 20.7641 24.0004L14.1892 20.2165V11.4973C14.1892 10.4512 15.3214 9.79132 16.2355 10.3178H16.2285Z"
        fill="#B85F00"
      />
      <path
        d="M19.5051 10.7619C19.4066 10.7619 19.3082 10.7338 19.2238 10.6847L17.8174 9.87736C17.7471 9.83524 17.6979 9.75802 17.6979 9.67377C17.6979 9.58953 17.74 9.5123 17.8174 9.47018L20.2997 8.03804C20.3841 7.9889 20.4825 7.96082 20.581 7.96082C20.6794 7.96082 20.7779 7.9889 20.8622 8.03804L22.2686 8.84538C22.339 8.8875 22.3882 8.96472 22.3882 9.04896C22.3882 9.13321 22.346 9.21043 22.2686 9.25255L19.7864 10.6847C19.702 10.7338 19.6035 10.7619 19.5051 10.7619Z"
        fill="black"
      />
      <path
        d="M22.1911 8.97756L20.7847 8.17022C20.6511 8.093 20.4893 8.093 20.3557 8.17022L19.4345 8.70377L17.8734 9.60237C17.8172 9.63747 17.8172 9.72171 17.8734 9.75681L19.2798 10.5641C19.4134 10.6414 19.5752 10.6414 19.7088 10.5641L21.1433 9.73575L22.184 9.13201C22.2403 9.0969 22.2403 9.01266 22.184 8.97756H22.1911Z"
        fill="url(#paint2_linear_4613_98989)"
      />
      <path
        d="M16.8191 10.6629L19.667 12.3056C19.667 12.3056 21.9595 14.9944 21.0172 22.3096L18.2536 20.6879C16.3198 19.5506 15.1314 17.4796 15.1314 15.2401V11.6387C15.1314 10.7682 16.0737 10.2276 16.8261 10.6629H16.8191Z"
        fill="#272727"
      />
      <path
        d="M24.6171 9.43335L19.6736 12.3047C19.6736 12.3047 21.8957 15.1689 20.7635 24.0075L25.707 21.1362C26.8462 12.2976 24.6171 9.43335 24.6171 9.43335Z"
        fill="#393939"
      />
      <path
        d="M20.9467 22.266C20.9467 22.266 23.1829 21.227 24.2869 20.56C25.3909 19.8931 25.8972 19.3385 25.8972 19.3385L25.7074 21.1357L20.7639 24.007C20.7639 24.007 20.8623 23.0593 20.9538 22.273L20.9467 22.266Z"
        fill="#FFAA00"
      />
      <path
        d="M25.3766 17.7798C25.3696 17.9904 25.1867 18.6644 23.7874 19.3383C22.6201 19.8929 21.6918 19.7806 21.6918 19.4085C21.7059 16.0739 21.1644 14.0309 20.7144 12.9217C20.6019 12.6409 20.7144 12.318 20.9746 12.1635C21.0871 12.1004 21.2066 12.0302 21.3402 11.9459C22.1278 11.4896 23.267 10.8297 23.9069 10.4576C23.9632 10.4225 24.0194 10.4085 24.0757 10.4014C24.2937 10.3663 24.5187 10.4857 24.5961 10.7103C25.018 11.8546 25.5383 14.066 25.3696 17.7798H25.3766Z"
        fill="url(#paint3_linear_4613_98989)"
      />
      <path
        d="M20.4471 17.4584L19.2727 13.2322C19.2235 13.0637 19.1321 12.9162 18.9985 12.8039C18.9563 12.7688 18.9071 12.7337 18.8578 12.7056L17.1491 11.7087C17.1491 11.7087 17.0858 11.6807 17.0576 11.6736C16.8678 11.6245 16.6779 11.7368 16.6217 11.9264C16.6146 11.9545 16.6076 11.9896 16.6076 12.0247V14.9521C16.6076 15.0364 16.6357 15.2049 16.6357 15.2189C16.7131 15.6401 16.9662 16.0122 17.346 16.2298L20.0392 17.7883C20.2572 17.9147 20.5174 17.7111 20.4471 17.4724V17.4584Z"
        fill="url(#paint4_linear_4613_98989)"
      />
      <path
        d="M18.2747 17.668L19.9484 18.6368C20.1593 18.7562 20.2859 18.9808 20.2859 19.2195V20.3147C20.2859 20.5183 20.0609 20.6447 19.8851 20.5464L18.2185 19.5776C18.0427 19.4793 17.9372 19.2897 17.9372 19.0861V17.8576C17.9372 17.6821 18.1271 17.5768 18.2747 17.661V17.668Z"
        fill="url(#paint5_linear_4613_98989)"
      />
      <path
        d="M24.7654 14.6575L22.0721 13.099C21.6924 12.8814 21.4392 12.5093 21.3619 12.0881C21.3619 12.0881 21.3549 12.0179 21.3478 11.9477C22.1354 11.4913 23.2746 10.8314 23.9145 10.4594C23.9707 10.4243 24.027 10.4102 24.0833 10.4032L25.1802 14.3416C25.2435 14.5803 24.9834 14.7839 24.7654 14.6575Z"
        fill="url(#paint6_linear_4613_98989)"
      />
      <path
        d="M22.9997 14.5516L24.6734 15.5204C24.8843 15.6397 25.0109 15.8644 25.0109 16.1031V17.1982C25.0109 17.4018 24.7859 17.5282 24.6101 17.4299L22.9435 16.4611C22.7677 16.3628 22.6622 16.1733 22.6622 15.9697V14.7411C22.6622 14.5656 22.8521 14.4603 22.9997 14.5446V14.5516Z"
        fill="url(#paint7_linear_4613_98989)"
      />
      <path
        d="M22.9646 21.7053V22.1195C22.9646 22.1967 22.9224 22.2669 22.8521 22.3091L21.6848 22.9971C21.6286 23.0322 21.5512 22.99 21.5512 22.9198V22.8707L22.599 22.2529C22.6693 22.2108 22.7115 22.1406 22.7115 22.0634V21.6983L22.831 21.6281C22.8873 21.593 22.9646 21.6351 22.9646 21.7053Z"
        fill="#7E1F00"
      />
      <path
        d="M22.7112 21.6975V22.0626C22.7112 22.1398 22.6691 22.21 22.5987 22.2521L21.551 22.8699V22.5119C21.551 22.4346 21.5932 22.3644 21.6635 22.3223L22.7112 21.7045V21.6975Z"
        fill="#FFF2D1"
      />
      <path
        d="M32.2402 4.14044L32.9363 3.68412L34.5115 3.48755L35.7351 4.9899L35.0389 5.4392L32.2402 4.14044Z"
        fill="#FAB03E"
      />
      <path
        d="M31.3821 5.43808L32.24 4.13932L33.8151 3.94275L35.0387 5.43808H31.3821Z"
        fill="#E07500"
      />
      <path
        d="M18.246 18.4756L19.6735 17.6543V13.8633L18.246 14.6847V18.4756Z"
        fill="#111111"
      />
      <path
        d="M19.5965 17.5418V14.2985L18.6471 14.846V18.0824L19.5965 17.5418Z"
        fill="#242424"
      />
      <path
        d="M19.6735 13.8633L19.5961 14.2985L18.6468 14.8461L18.246 14.6847L19.6735 13.8633Z"
        fill="#212121"
      />
      <path
        d="M19.0336 16.7L19.343 16.5245V17.0932L19.0336 17.2757L18.9633 17.2336V16.6579L19.0336 16.7Z"
        fill="#161714"
      />
      <path
        d="M19.343 16.9383V16.3627L19.0336 16.5452V17.1138L19.343 16.9383Z"
        fill="#111111"
      />
      <path
        d="M19.343 16.3618L19.2727 16.3197L18.9633 16.5022L19.0336 16.5444L19.343 16.3618Z"
        fill="#212121"
      />
      <path
        d="M18.9637 17.0792V16.5035L19.034 16.5457V17.1143L18.9637 17.0792Z"
        fill="#030303"
      />
      <path
        d="M19.2798 16.8961V13.2315L19.6525 12.9507L19.5962 12.7682L19.104 13.1332V16.8891C19.1392 16.8189 19.2446 16.8189 19.2798 16.8891V16.8961Z"
        fill="black"
      />
      <path
        d="M39.5601 22.3788L38.9835 25.9451C38.7796 25.8468 38.5757 25.7485 38.3858 25.6503L39.1312 22.6666L39.5672 22.3788H39.5601Z"
        fill="#994500"
      />
      <path
        d="M37.7389 22.6467L37.3732 24.8722C37.2256 24.7318 37.0779 24.5843 36.9302 24.4299L37.3029 22.9346L37.7389 22.6467Z"
        fill="#994500"
      />
      <path
        d="M34.9257 21.7897L36.4868 23.5588L38.2659 25.1875L41.6413 26.7601L42.0069 27.1953L41.585 27.434L40.7693 26.8092L38.0479 25.4964L36.3391 23.9449L34.764 21.9441L34.9257 21.7897Z"
        fill="#BA6300"
      />
      <path
        d="M36.804 25.278L37.7041 26.1205C38.1541 26.5417 38.6675 26.8857 39.23 27.1384L40.2567 27.6017C40.5099 27.7141 40.7067 27.9317 40.7841 28.1985L40.8615 28.4512C41.0302 28.9847 40.7419 29.5604 40.2075 29.7429L39.7574 29.8974C39.2511 30.0729 38.6956 29.8833 38.4003 29.4411L37.3876 27.9177L36.7266 26.5557C36.6001 26.2889 36.5227 26.0011 36.5016 25.7063L36.4876 25.4325C36.4805 25.271 36.6774 25.1797 36.797 25.2921L36.804 25.278Z"
        fill="#823600"
      />
      <path
        d="M31.3117 5.26465H35.0387V5.86138H31.3117V5.26465Z"
        fill="#E07500"
      />
      <path
        d="M6.82657 11.9678L1.9323 9.14563L1.81276 9.20881L6.82657 14.9444V11.9678Z"
        fill="#964900"
      />
      <path
        d="M6.82657 11.9679L6.71405 12.0311V14.9445L6.82657 14.8041V11.9679Z"
        fill="#0F0C0B"
      />
      <path
        d="M6.71408 12.0308L1.81279 9.20862V12.1361L6.71408 15.0074V12.0308Z"
        fill="#A95200"
      />
      <path
        d="M1.97473 9.48877V12.0442L2.65683 12.4513L2.76231 12.5075L2.96624 12.6269L4.02103 13.2446L4.11948 13.3078L4.32341 13.4272L5.37821 14.045L5.48369 14.1011L5.58213 14.1573L6.55255 14.7329V12.4935L5.68058 12.002L5.48369 11.8827V11.7774L5.68058 11.8897L6.55255 12.3882V12.1214L5.68058 11.6229L5.48369 11.5106L5.37821 11.4474L4.32341 10.8367L4.11948 10.7243L4.02103 10.6682L2.96624 10.0574L2.76231 9.94509L2.65683 9.88191L1.97473 9.48877Z"
        fill="black"
      />
      <path
        d="M1.97436 10.5991V10.7044L2.65647 11.0976L2.76194 11.1607L2.96587 11.2731L4.02067 11.8768L4.11911 11.94L4.32304 12.0523L5.37784 12.6561L5.48332 12.7193L5.68022 12.8316L6.55219 13.33V13.2247L5.68022 12.7263L5.48332 12.6069L5.37784 12.5508L4.32304 11.947L4.11911 11.8347L4.02067 11.7715L2.96587 11.1678L2.76194 11.0554L2.65647 10.9923L1.97436 10.5991Z"
        fill="#A95200"
      />
      <path
        d="M5.48332 13.0293V13.1346L5.68022 13.2539L6.55219 13.7524V13.6471L5.68022 13.1486L5.48332 13.0293Z"
        fill="#A95200"
      />
      <path
        d="M1.97435 11.0203V11.1256L2.65645 11.5187L2.76193 11.5819L2.96586 11.6942L4.02065 12.298L4.1191 12.3541L4.32303 12.4735L5.37783 13.0772L5.48331 13.1334V13.0281L5.37783 12.9719L4.32303 12.3682L4.1191 12.2488L4.02065 12.1927L2.96586 11.5889L2.76193 11.4696L2.65645 11.4134L1.97435 11.0203Z"
        fill="#A95200"
      />
      <path
        d="M5.48332 13.4495V13.5548L5.68022 13.6741L6.55219 14.1726V14.0602L5.68022 13.5618L5.48332 13.4495Z"
        fill="#A95200"
      />
      <path
        d="M5.48332 13.8715V13.9768L5.68022 14.0891L6.55219 14.5875V14.4822L5.68022 13.9838L5.48332 13.8715Z"
        fill="#A95200"
      />
      <path
        d="M1.97435 11.8572V11.9625L2.65645 12.3556L2.76193 12.4188L2.96586 12.5311L4.02065 13.1349L4.1191 13.1981L4.32303 13.3104L5.37783 13.9141L5.48331 13.9773V13.872L5.37783 13.8088L4.32303 13.2051L4.1191 13.0928L4.02065 13.0296L2.96586 12.4258L2.76193 12.3135L2.65645 12.2503L1.97435 11.8572Z"
        fill="#A95200"
      />
      <path
        d="M2.07984 10.5563L1.97436 10.5984L2.65647 10.9915L2.76194 11.0547L2.96587 11.167L4.02067 11.7708L4.11911 11.834L4.32304 11.9463L5.37784 12.55L5.48332 12.6062L5.68022 12.7256L6.55219 13.224V13.1187L5.68022 12.6202L5.48332 12.5079L5.37784 12.4447L4.32304 11.841L4.11911 11.7216L4.02067 11.6655L2.96587 11.0617L2.76194 10.9494L2.65647 10.8862L2.07984 10.5563Z"
        fill="#964900"
      />
      <path
        d="M2.07984 10.9774L1.97436 11.0195L2.65647 11.4127L2.76194 11.4688L2.96587 11.5882L4.02067 12.1919L4.11911 12.2481L4.32304 12.3674L5.37784 12.9712L5.48332 13.0273L5.68022 13.1467L6.55219 13.6451V13.5398L5.68022 13.0414L5.48332 12.922L5.37784 12.8659L4.32304 12.2621L4.11911 12.1428L4.02067 12.0866L2.96587 11.4829L2.76194 11.3706L2.65647 11.3074L2.07984 10.9774Z"
        fill="#964900"
      />
      <path
        d="M2.07984 11.3993L1.97436 11.4414L2.65647 11.8346L2.76194 11.8907L2.96587 12.0101L4.02067 12.6068L4.11911 12.67L4.32304 12.7823L5.37784 13.3931L5.48332 13.4492L5.68022 13.5615L6.55219 14.06V13.9547L5.68022 13.4562L5.48332 13.3439L5.37784 13.2807L4.32304 12.677L4.11911 12.5647L4.02067 12.5015L2.96587 11.8977L2.76194 11.7854L2.65647 11.7222L2.07984 11.3993Z"
        fill="#964900"
      />
      <path
        d="M5.48332 11.7775V11.8828L5.68022 12.0021L6.55219 12.4935V12.3882L5.68022 11.8898L5.48332 11.7775Z"
        fill="#A95200"
      />
      <path
        d="M1.97435 9.76404V9.87636L2.65645 10.2695L2.76193 10.3257L2.96586 10.438L4.02065 11.0488L4.1191 11.1049L4.32303 11.2172L5.37783 11.828L5.48331 11.8842V11.7789L5.37783 11.7227L4.32303 11.1119L4.1191 10.9996L4.02065 10.9364L2.96586 10.3327L2.76193 10.2204L2.65645 10.1642L1.97435 9.76404Z"
        fill="#A34F00"
      />
      <path
        d="M1.97436 10.186V10.2913L2.65647 10.6845L2.76194 10.7477L2.96587 10.86L4.02067 11.4637L4.11911 11.5269L4.32304 11.6392L5.37784 12.243L5.48332 12.3062L5.68022 12.4185L6.55219 12.9169V12.8116L5.68022 12.3132L5.48332 12.2009L5.37784 12.1377L4.32304 11.5339L4.11911 11.4216L4.02067 11.3584L2.96587 10.7547L2.76194 10.6424L2.65647 10.5792L1.97436 10.186Z"
        fill="#A95200"
      />
      <path
        d="M2.07984 9.72815L1.97436 9.76325L2.65647 10.1634L2.76194 10.2196L2.96587 10.3319L4.02067 10.9356L4.11911 10.9988L4.32304 11.1112L5.37784 11.7219L5.48332 11.7781L5.68022 11.8904L6.55219 12.3888V12.2835L5.68022 11.7851L5.48332 11.6728L5.37784 11.6096L4.32304 11.0058L4.11911 10.8935L4.02067 10.8303L2.96587 10.2266L2.76194 10.1143L2.65647 10.0511L2.07984 9.72815Z"
        fill="#964900"
      />
      <path
        d="M2.07984 10.1431L1.97436 10.1852L2.65647 10.5783L2.76194 10.6415L2.96587 10.7538L4.02067 11.3576L4.11911 11.4208L4.32304 11.5331L5.37784 12.1368L5.48332 12.2L5.68022 12.3123L6.55219 12.8108V12.7055L5.68022 12.207L5.48332 12.0947L5.37784 12.0315L4.32304 11.4278L4.11911 11.3155L4.02067 11.2523L2.96587 10.6485L2.76194 10.5362L2.65647 10.473L2.07984 10.1431Z"
        fill="#964900"
      />
      <path
        d="M2.07984 11.8134L1.97436 11.8555L2.65647 12.2486L2.76194 12.3118L2.96587 12.4241L4.02067 13.0279L4.11911 13.0911L4.32304 13.2034L5.37784 13.8071L5.48332 13.8703L5.68022 13.9826L6.55219 14.4811V14.3758L5.68022 13.8773L5.48332 13.765L5.37784 13.7018L4.32304 13.0981L4.11911 12.9857L4.02067 12.9226L2.96587 12.3188L2.76194 12.2065L2.65647 12.1433L2.07984 11.8134Z"
        fill="#964900"
      />
      <path
        d="M2.65618 9.88306V12.4525L2.76166 12.5087V9.94624L2.65618 9.88306Z"
        fill="#A95200"
      />
      <path
        d="M2.76171 9.94702V10.2208L2.96564 10.2278V10.0593L2.76171 9.94702Z"
        fill="#0F0C0B"
      />
      <path
        d="M2.76171 10.3252V10.6411L2.96564 10.6481V10.4375L2.76171 10.3252Z"
        fill="#0F0C0B"
      />
      <path
        d="M2.76171 10.7471V11.056L2.96564 11.063V10.8594L2.76171 10.7471Z"
        fill="#0F0C0B"
      />
      <path
        d="M2.76171 11.1603V11.4692L2.96564 11.4832V11.2726L2.76171 11.1603Z"
        fill="#0F0C0B"
      />
      <path
        d="M1.97435 11.4413V11.5466L2.65645 11.9397L2.76193 11.9959L2.96586 12.1082L4.02065 12.719L4.1191 12.7751L4.32303 12.8875L5.37783 13.4982L5.48331 13.5544V13.4491L5.37783 13.3929L4.32303 12.7822L4.1191 12.6698L4.02065 12.6067L2.96586 12.0099L2.76193 11.8906L2.65645 11.8344L1.97435 11.4413Z"
        fill="#A95200"
      />
      <path
        d="M2.76171 11.5823V11.8912L2.96564 11.9052V11.6946L2.76171 11.5823Z"
        fill="#0F0C0B"
      />
      <path
        d="M2.76171 11.9955V12.3114L2.96564 12.3184V12.1078L2.76171 11.9955Z"
        fill="#0F0C0B"
      />
      <path
        d="M2.76171 12.4174V12.5086L2.96564 12.628V12.5297L2.76171 12.4174Z"
        fill="#0F0C0B"
      />
      <path
        d="M4.02054 10.6692V13.2456L4.11899 13.3088V10.7254L4.02054 10.6692Z"
        fill="#A95200"
      />
      <path
        d="M4.11904 10.7252V10.999L4.32297 11.006V10.8375L4.11904 10.7252Z"
        fill="#0F0C0B"
      />
      <path
        d="M4.11904 11.1052V11.4211L4.32297 11.4282V11.2176L4.11904 11.1052Z"
        fill="#0F0C0B"
      />
      <path
        d="M4.11904 11.5254V11.8343L4.32297 11.8413V11.6377L4.11904 11.5254Z"
        fill="#0F0C0B"
      />
      <path
        d="M4.11904 11.9403V12.2492L4.32297 12.2632V12.0526L4.11904 11.9403Z"
        fill="#0F0C0B"
      />
      <path
        d="M4.11904 12.3535V12.6694L4.32297 12.6835V12.4729L4.11904 12.3535Z"
        fill="#0F0C0B"
      />
      <path
        d="M4.11904 12.7754V13.0913L4.32297 13.0983V12.8877L4.11904 12.7754Z"
        fill="#0F0C0B"
      />
      <path
        d="M4.11904 13.1965V13.3089L4.32297 13.4282V13.3089L4.11904 13.1965Z"
        fill="#0F0C0B"
      />
      <path
        d="M5.37783 11.4501V14.0476L5.48331 14.1038V11.5133L5.37783 11.4501Z"
        fill="#A95200"
      />
      <path
        d="M5.48337 11.5114V11.7781L5.68027 11.7851V11.6237L5.48337 11.5114Z"
        fill="#0F0C0B"
      />
      <path
        d="M5.48337 11.8843V12.2002L5.68027 12.2072V12.0036L5.48337 11.8843Z"
        fill="#0F0C0B"
      />
      <path
        d="M5.48337 12.3044V12.6063L5.68027 12.6204V12.4168L5.48337 12.3044Z"
        fill="#0F0C0B"
      />
      <path
        d="M5.48337 12.7185V13.0274L5.68027 13.0414V12.8308L5.48337 12.7185Z"
        fill="#0F0C0B"
      />
      <path
        d="M5.48337 13.1335V13.4495L5.68027 13.4635V13.2529L5.48337 13.1335Z"
        fill="#0F0C0B"
      />
      <path
        d="M5.48337 13.5546V13.8705L5.68027 13.8775V13.6739L5.48337 13.5546Z"
        fill="#0F0C0B"
      />
      <path
        d="M5.68027 14.0871L5.48337 13.9747V14.1011L5.58182 14.1573H5.68027V14.0871Z"
        fill="#0F0C0B"
      />
      <path
        d="M12.5854 15.3588L7.68406 12.5366L7.57156 12.5998L12.5854 18.3354V15.3588Z"
        fill="#964900"
      />
      <path
        d="M12.5855 15.359L12.466 15.4222V18.3356L12.5855 18.1952V15.359Z"
        fill="#0F0C0B"
      />
      <path
        d="M12.466 15.4209L7.57177 12.5988V15.5262L12.466 18.3975V15.4209Z"
        fill="#A95200"
      />
      <path
        d="M7.72672 12.8798V15.4352L8.41585 15.8423L8.52133 15.8985L8.71823 16.0178L9.77302 16.6356L9.8785 16.6988L10.0754 16.8182L11.1372 17.4359L11.2357 17.4921L11.3341 17.5483L12.3045 18.1239V15.8845L11.4396 15.386L11.2357 15.2737V15.1684L11.4396 15.2807L12.3045 15.7792V15.5124L11.4396 15.0139L11.2357 14.9016L11.1372 14.8384L10.0754 14.2277L9.8785 14.1153L9.77302 14.0592L8.71823 13.4484L8.52133 13.3361L8.41585 13.2729L7.72672 12.8798Z"
        fill="black"
      />
      <path
        d="M7.72623 13.9899V14.0952L8.41536 14.4883L8.52084 14.5515L8.71774 14.6638L9.77253 15.2676L9.87801 15.3307L10.0749 15.4431L11.1367 16.0468L11.2352 16.11L11.4391 16.2223L12.304 16.7208V16.6155L11.4391 16.117L11.2352 15.9977L11.1367 15.9415L10.0749 15.3378L9.87801 15.2254L9.77253 15.1623L8.71774 14.5585L8.52084 14.4462L8.41536 14.383L7.72623 13.9899Z"
        fill="#A95200"
      />
      <path
        d="M11.2352 16.4191V16.5244L11.4391 16.6367L12.304 17.1351V17.0369L11.4391 16.5384L11.2352 16.4191Z"
        fill="#A95200"
      />
      <path
        d="M7.72624 14.4117V14.517L8.41537 14.9102L8.52085 14.9663L8.71775 15.0857L9.77255 15.6894L9.87803 15.7456L10.0749 15.8649L11.1368 16.4687L11.2352 16.5249V16.4196L11.1368 16.3634L10.0749 15.7526L9.87803 15.6403L9.77255 15.5841L8.71775 14.9804L8.52085 14.861L8.41537 14.8049L7.72624 14.4117Z"
        fill="#A95200"
      />
      <path
        d="M11.2352 16.8411V16.9464L11.4391 17.0587L12.304 17.5571V17.4518L11.4391 16.9534L11.2352 16.8411Z"
        fill="#A95200"
      />
      <path
        d="M11.2352 17.2629V17.3682L11.4391 17.4806L12.304 17.979V17.8737L11.4391 17.3753L11.2352 17.2629Z"
        fill="#A95200"
      />
      <path
        d="M7.72624 15.246V15.3513L8.41537 15.7444L8.52085 15.8076L8.71775 15.9199L9.77255 16.5237L9.87803 16.5798L10.0749 16.6992L11.1368 17.3029L11.2352 17.3661V17.2608L11.1368 17.1976L10.0749 16.5939L9.87803 16.4815L9.77255 16.4184L8.71775 15.8146L8.52085 15.7023L8.41537 15.6391L7.72624 15.246Z"
        fill="#A95200"
      />
      <path
        d="M7.83874 13.9478L7.72623 13.9899L8.41536 14.383L8.52084 14.4462L8.71774 14.5585L9.77253 15.1623L9.87801 15.2255L10.0749 15.3378L11.1367 15.9415L11.2352 15.9977L11.4391 16.117L12.304 16.6155V16.5102L11.4391 16.0117L11.2352 15.8994L11.1367 15.8362L10.0749 15.2325L9.87801 15.1131L9.77253 15.057L8.71774 14.4532L8.52084 14.3339L8.41536 14.2777L7.83874 13.9478Z"
        fill="#964900"
      />
      <path
        d="M7.83874 14.3689L7.72623 14.411L8.41536 14.8042L8.52084 14.8603L8.71774 14.9797L9.77253 15.5834L9.87801 15.6396L10.0749 15.7519L11.1367 16.3627L11.2352 16.4188L11.4391 16.5382L12.304 17.0366V16.9243L11.4391 16.4258L11.2352 16.3135L11.1367 16.2574L10.0749 15.6536L9.87801 15.5343L9.77253 15.4781L8.71774 14.8744L8.52084 14.755L8.41536 14.6989L7.83874 14.3689Z"
        fill="#964900"
      />
      <path
        d="M7.83874 14.7899L7.72623 14.832L8.41536 15.2252L8.52084 15.2813L8.71774 15.4007L9.77253 15.9974L9.87801 16.0606L10.0749 16.1729L11.1367 16.7837L11.2352 16.8398L11.4391 16.9522L12.304 17.4506V17.3453L11.4391 16.8469L11.2352 16.7345L11.1367 16.6714L10.0749 16.0676L9.87801 15.9553L9.77253 15.8921L8.71774 15.2884L8.52084 15.176L8.41536 15.1129L7.83874 14.7899Z"
        fill="#964900"
      />
      <path
        d="M11.2352 15.1699V15.2752L11.4391 15.3876L12.304 15.886V15.7807L11.4391 15.2822L11.2352 15.1699Z"
        fill="#A95200"
      />
      <path
        d="M7.72624 13.1547V13.267L8.41537 13.6601L8.52085 13.7163L8.71775 13.8286L9.77255 14.4394L9.87803 14.4955L10.0749 14.6079L11.1368 15.2186L11.2352 15.2748V15.1695L11.1368 15.1133L10.0749 14.5026L9.87803 14.3902L9.77255 14.3271L8.71775 13.7233L8.52085 13.611L8.41537 13.5548L7.72624 13.1547Z"
        fill="#A34F00"
      />
      <path
        d="M7.72623 13.5775V13.6828L8.41536 14.076L8.52084 14.1391L8.71774 14.2515L9.77253 14.8552L9.87801 14.9114L10.0749 15.0307L11.1367 15.6345L11.2352 15.6976L11.4391 15.81L12.304 16.3084V16.2031L11.4391 15.7047L11.2352 15.5923L11.1367 15.5292L10.0749 14.9254L9.87801 14.8131L9.77253 14.7499L8.71774 14.1462L8.52084 14.0338L8.41536 13.9707L7.72623 13.5775Z"
        fill="#A95200"
      />
      <path
        d="M7.83877 13.1196L7.72626 13.1547L8.41539 13.5549L8.52087 13.6111L8.71777 13.7234L9.77256 14.3271L9.87805 14.3903L10.0749 14.5026L11.1368 15.1134L11.2352 15.1696L11.4391 15.2819L12.3041 15.7803V15.675L11.4391 15.1766L11.2352 15.0643L11.1368 15.0011L10.0749 14.3973L9.87805 14.285L9.77256 14.2218L8.71777 13.6181L8.52087 13.5057L8.41539 13.4426L7.83877 13.1196Z"
        fill="#964900"
      />
      <path
        d="M7.83874 13.5337L7.72623 13.5758L8.41536 13.969L8.52084 14.0321L8.71774 14.1445L9.77253 14.7482L9.87801 14.8114L10.0749 14.9237L11.1367 15.5275L11.2352 15.5906L11.4391 15.703L12.304 16.2014V16.0961L11.4391 15.5977L11.2352 15.4853L11.1367 15.4222L10.0749 14.8184L9.87801 14.6991L9.77253 14.6429L8.71774 14.0392L8.52084 13.9268L8.41536 13.8636L7.83874 13.5337Z"
        fill="#964900"
      />
      <path
        d="M7.83874 15.2058L7.72623 15.2479L8.41536 15.6411L8.52084 15.7043L8.71774 15.8166L9.77253 16.4203L9.87801 16.4835L10.0749 16.5958L11.1367 17.1996L11.2352 17.2628L11.4391 17.3751L12.304 17.8735V17.7682L11.4391 17.2698L11.2352 17.1575L11.1367 17.0943L10.0749 16.4905L9.87801 16.3782L9.77253 16.315L8.71774 15.7113L8.52084 15.5989L8.41536 15.5358L7.83874 15.2058Z"
        fill="#964900"
      />
      <path
        d="M8.41552 13.2728V15.8423L8.521 15.8984V13.336L8.41552 13.2728Z"
        fill="#A95200"
      />
      <path
        d="M8.5206 13.3376V13.6114L8.7175 13.6185V13.45L8.5206 13.3376Z"
        fill="#0F0C0B"
      />
      <path
        d="M8.5206 13.7167V14.0326L8.7175 14.0396V13.829L8.5206 13.7167Z"
        fill="#0F0C0B"
      />
      <path
        d="M8.5206 14.1378V14.4467L8.7175 14.4537V14.2501L8.5206 14.1378Z"
        fill="#0F0C0B"
      />
      <path
        d="M8.5206 14.5519V14.8608L8.7175 14.8748V14.6642L8.5206 14.5519Z"
        fill="#0F0C0B"
      />
      <path
        d="M7.72624 14.8329V14.9382L8.41537 15.3313L8.52085 15.3875L8.71775 15.4998L9.77255 16.1106L9.87803 16.1667L10.0749 16.2791L11.1368 16.8898L11.2352 16.946V16.8407L11.1368 16.7845L10.0749 16.1738L9.87803 16.0614L9.77255 15.9983L8.71775 15.4015L8.52085 15.2822L8.41537 15.226L7.72624 14.8329Z"
        fill="#A95200"
      />
      <path
        d="M8.5206 14.965V15.2809L8.7175 15.2949V15.0843L8.5206 14.965Z"
        fill="#0F0C0B"
      />
      <path
        d="M8.5206 15.387V15.7029L8.7175 15.7099V15.4993L8.5206 15.387Z"
        fill="#0F0C0B"
      />
      <path
        d="M8.5206 15.808V15.8992L8.7175 16.0186V15.9203L8.5206 15.808Z"
        fill="#0F0C0B"
      />
      <path
        d="M9.77239 14.0607V16.6371L9.87787 16.7003V14.1168L9.77239 14.0607Z"
        fill="#A95200"
      />
      <path
        d="M9.87793 14.1158V14.3896L10.0748 14.3967V14.2282L9.87793 14.1158Z"
        fill="#0F0C0B"
      />
      <path
        d="M9.87793 14.4949V14.8108L10.0748 14.8178V14.6072L9.87793 14.4949Z"
        fill="#0F0C0B"
      />
      <path
        d="M9.87793 14.9099V15.2258L10.0748 15.2328V15.0293L9.87793 14.9099Z"
        fill="#0F0C0B"
      />
      <path
        d="M9.87793 15.3309V15.6398L10.0748 15.6539V15.4433L9.87793 15.3309Z"
        fill="#0F0C0B"
      />
      <path
        d="M9.87793 15.745V16.0609L10.0748 16.075V15.8643L9.87793 15.745Z"
        fill="#0F0C0B"
      />
      <path
        d="M9.87793 16.1661V16.4821L10.0748 16.4891V16.2785L9.87793 16.1661Z"
        fill="#0F0C0B"
      />
      <path
        d="M9.87793 16.5802V16.6995L10.0748 16.8189V16.6995L9.87793 16.5802Z"
        fill="#0F0C0B"
      />
      <path
        d="M11.1368 14.8398V17.4374L11.2352 17.4935V14.903L11.1368 14.8398Z"
        fill="#A95200"
      />
      <path
        d="M11.2352 14.9011V15.1679L11.4391 15.1749V15.0134L11.2352 14.9011Z"
        fill="#0F0C0B"
      />
      <path
        d="M11.2352 15.2749V15.5908L11.4391 15.5978V15.3872L11.2352 15.2749Z"
        fill="#0F0C0B"
      />
      <path
        d="M11.2352 15.696V15.9979L11.4391 16.012V15.8084L11.2352 15.696Z"
        fill="#0F0C0B"
      />
      <path
        d="M11.2352 16.1101V16.419L11.4391 16.433V16.2224L11.2352 16.1101Z"
        fill="#0F0C0B"
      />
      <path
        d="M11.2352 16.5232V16.8391L11.4391 16.8461V16.6355L11.2352 16.5232Z"
        fill="#0F0C0B"
      />
      <path
        d="M11.2352 16.9443V17.2602L11.4391 17.2673V17.0567L11.2352 16.9443Z"
        fill="#0F0C0B"
      />
      <path
        d="M11.4391 17.4785L11.2352 17.3662V17.4926L11.3337 17.5487H11.4391V17.4785Z"
        fill="#0F0C0B"
      />
      <path
        d="M2.34022 11.378L3.35986 8.16269L3.00123 8.00122L2.14333 11.1182L2.16442 17.1417L2.32616 17.0434L2.34022 11.378Z"
        fill="#A64B0A"
      />
      <path
        d="M2.17128 11.3218L3.19092 8.10653L3.00106 8.00122L1.98142 11.2165L1.97439 17.0364L2.16425 17.1417L2.17128 11.3218Z"
        fill="#D15F0C"
      />
      <path
        d="M2.36139 15.0711C2.36139 15.0711 2.27701 15.043 2.23482 15.1343C2.19966 15.2185 2.25592 15.2747 2.25592 15.2747L3.60606 16.0259L3.71857 15.9627V15.8223L2.36139 15.0711Z"
        fill="#CE5D0C"
      />
      <path
        d="M2.36139 16.5392C2.36139 16.5392 2.27701 16.5112 2.23482 16.6024C2.19966 16.6867 2.25592 16.7428 2.25592 16.7428L3.60606 17.494L3.71857 17.4308V17.2904L2.36139 16.5392Z"
        fill="#CE5D0C"
      />
      <path
        d="M2.29093 13.6669C2.29093 13.6669 2.20654 13.6388 2.16435 13.7301C2.12919 13.8143 2.18545 13.8705 2.18545 13.8705L3.53559 14.6217L3.6481 14.5585V14.4181L2.29093 13.6669Z"
        fill="#CE5D0C"
      />
      <path
        d="M2.2139 12.431C2.2139 12.431 2.12952 12.4029 2.08733 12.4941C2.05217 12.5784 2.10842 12.6345 2.10842 12.6345L3.45857 13.3857L3.57108 13.3225V13.1821L2.2139 12.431Z"
        fill="#CE5D0C"
      />
      <path
        d="M2.31238 11.2937C2.31238 11.2937 2.228 11.2657 2.18581 11.3569C2.15065 11.4412 2.20691 11.4973 2.20691 11.4973L3.55705 12.2485L3.66956 12.1853V12.0449L2.31238 11.2937Z"
        fill="#CE5D0C"
      />
      <path
        d="M2.69187 10.1286C2.69187 10.1286 2.60748 10.1005 2.56529 10.1918C2.53013 10.276 2.58639 10.3322 2.58639 10.3322L3.93653 11.0833L4.04904 11.0202V10.8798L2.69187 10.1286Z"
        fill="#CE5D0C"
      />
      <path
        d="M3.02277 9.11748C3.02277 9.11748 2.93839 9.0894 2.8962 9.18066C2.86104 9.26491 2.91729 9.32107 2.91729 9.32107L4.26743 10.0722L4.37994 10.0091V9.86865L3.02277 9.11748Z"
        fill="#CE5D0C"
      />
      <path
        d="M3.30378 8.14836C3.30378 8.14836 3.21939 8.12028 3.1772 8.21155C3.14204 8.29579 3.1983 8.35195 3.1983 8.35195L4.54844 9.10313L4.66095 9.03994V8.89954L3.30378 8.14836Z"
        fill="#CE5D0C"
      />
      <path
        d="M3.86628 12.2285L4.88593 9.01315L4.52729 8.85168L3.66939 11.9687L3.69049 17.9921L3.85222 17.8938L3.86628 12.2285Z"
        fill="#A64B0A"
      />
      <path
        d="M3.69801 12.1723L4.71765 8.95699L4.52778 8.85168L3.50815 12.067L3.50112 17.8868L3.69098 17.9921L3.69801 12.1723Z"
        fill="#D15F0C"
      />
      <path
        d="M24.0405 9.42795L24.5468 8.42404L24.0194 8.12217C24.0194 8.12217 24.0053 8.11515 23.9983 8.10813H23.9842C23.9139 8.07302 23.8225 8.08707 23.724 8.14323C23.499 8.26959 23.3232 8.57849 23.3232 8.83122C23.3232 8.94354 23.3584 9.02779 23.4217 9.06991H23.4357C23.4357 9.06991 23.4498 9.08395 23.4568 9.09097L24.0475 9.42092L24.0405 9.42795Z"
        fill="url(#paint8_linear_4613_98989)"
      />
      <path
        d="M24.7157 8.69687C24.7157 8.9496 24.5329 9.2585 24.3149 9.38486C24.0899 9.51123 23.9141 9.41294 23.9141 9.16021C23.9141 8.90748 24.0969 8.59859 24.3149 8.47222C24.5399 8.34586 24.7157 8.44414 24.7157 8.69687Z"
        fill="#454545"
      />
      <path
        d="M24.6663 8.73212C24.6663 8.95677 24.5045 9.22354 24.3147 9.33586C24.1178 9.44819 23.9631 9.35692 23.9631 9.1393C23.9631 8.92167 24.1248 8.64787 24.3147 8.53555C24.5116 8.42322 24.6663 8.51449 24.6663 8.73212Z"
        fill="#6ED4E6"
      />
      <path
        d="M24.6524 8.8314C24.5188 8.77523 24.2446 8.73311 23.9563 9.15433C23.9633 9.35792 24.118 9.44216 24.3079 9.32984C24.4766 9.23155 24.6102 9.02094 24.6524 8.82437V8.8314Z"
        fill="#F0F4FB"
      />
      <path
        d="M24.378 8.83098C24.378 8.90118 24.3288 8.99245 24.2655 9.02755C24.2022 9.06265 24.153 9.03457 24.153 8.96437C24.153 8.89416 24.2022 8.8029 24.2655 8.7678C24.3288 8.7327 24.378 8.76078 24.378 8.83098Z"
        fill="white"
      />
      <path
        d="M19.5333 12.0504L20.0396 11.0465L19.5122 10.7446C19.5122 10.7446 19.4982 10.7376 19.4911 10.7306H19.4771C19.4068 10.6955 19.3153 10.7095 19.2169 10.7657C18.9919 10.892 18.8161 11.2009 18.8161 11.4537C18.8161 11.566 18.8512 11.6502 18.9145 11.6923H18.9286C18.9286 11.6923 18.9427 11.7064 18.9497 11.7134L19.5404 12.0434L19.5333 12.0504Z"
        fill="url(#paint9_linear_4613_98989)"
      />
      <path
        d="M20.2086 11.3272C20.2086 11.58 20.0258 11.8889 19.8078 12.0152C19.5827 12.1416 19.4069 12.0433 19.4069 11.7906C19.4069 11.5379 19.5898 11.229 19.8078 11.1026C20.0328 10.9762 20.2086 11.0745 20.2086 11.3272Z"
        fill="#454545"
      />
      <path
        d="M20.1591 11.3559C20.1591 11.5805 19.9974 11.8473 19.8076 11.9596C19.6177 12.072 19.456 11.9807 19.456 11.7631C19.456 11.5454 19.6177 11.2717 19.8076 11.1593C19.9974 11.047 20.1591 11.1383 20.1591 11.3559Z"
        fill="#6ED4E6"
      />
      <path
        d="M20.1451 11.4549C20.0115 11.3988 19.7373 11.3566 19.449 11.7779C19.456 11.9815 19.6107 12.0657 19.8006 11.9534C19.9693 11.8551 20.1029 11.6445 20.1451 11.4479V11.4549Z"
        fill="#F0F4FB"
      />
      <path
        d="M19.8707 11.4538C19.8707 11.524 19.8215 11.6153 19.7582 11.6504C19.6949 11.6855 19.6457 11.6574 19.6457 11.5872C19.6457 11.517 19.6949 11.4257 19.7582 11.3906C19.8215 11.3555 19.8707 11.3836 19.8707 11.4538Z"
        fill="white"
      />
      <path
        d="M50.8604 13.5328L50.0587 14.4104L47.0842 15.5757L49.3274 14.0313L50.8604 13.5328Z"
        fill="#D97700"
      />
      <path
        d="M37.6971 21.5514C37.7534 21.5093 37.8237 21.4812 37.887 21.4531C38.0979 21.3689 38.2878 21.2495 38.4847 21.1302C38.8363 20.9125 39.1879 20.6949 39.5395 20.4843C40.095 20.1473 40.6435 19.8033 41.1779 19.4383C41.41 19.2768 41.635 19.1153 41.8952 19.003C42.4156 18.7784 43.0063 18.7854 43.5337 18.5818L42.6828 26.8166C42.6828 26.8166 42.4859 26.9289 42.4156 27.0062C42.282 27.1676 42.0288 27.1606 41.839 27.0764C41.6491 26.9921 41.5014 26.8377 41.3326 26.7113C40.7842 26.3182 40.0669 26.2761 39.434 26.0444C38.4777 25.7074 37.7252 24.9562 37.008 24.2331C36.797 24.0225 36.6142 23.8119 36.4736 23.5522C36.3188 23.2714 36.354 23.1941 36.6845 23.0397C36.7619 23.0046 36.8462 22.9695 36.8884 22.8993C36.9236 22.8431 36.9236 22.7799 36.9447 22.7238C37.0572 22.457 37.3525 22.5693 37.458 22.2604C37.5424 22.0217 37.458 21.7269 37.6831 21.5514H37.6971Z"
        fill="#4A2500"
      />
      <path
        d="M40.6217 25.1742C40.2138 24.5775 40.0732 23.8403 39.8271 23.1664C39.6372 22.6469 39.3841 22.1484 39.0747 21.6851C39.0184 21.6009 38.9551 21.5096 38.8707 21.4534C38.7793 21.3902 38.6668 21.3551 38.5543 21.3341C38.4207 21.306 38.3011 21.299 38.1816 21.306C38.0831 21.3622 37.9847 21.4113 37.8792 21.4534C37.8159 21.4815 37.7456 21.5096 37.6894 21.5517C37.4643 21.7272 37.5417 22.0291 37.4643 22.2608C37.3588 22.5697 37.0635 22.4573 36.951 22.7241C36.9299 22.7803 36.9229 22.8505 36.8947 22.8996C36.8525 22.9698 36.7611 23.0049 36.6908 23.04C36.3533 23.1945 36.3251 23.2717 36.4799 23.5525C36.6205 23.8123 36.8033 24.0229 37.0143 24.2335C37.7315 24.9566 38.4769 25.7007 39.4403 26.0447C40.0732 26.2694 40.7975 26.3115 41.339 26.7116C41.5077 26.831 41.6554 26.9854 41.8453 27.0767C42.0351 27.168 42.2883 27.1609 42.4219 27.0065C41.8945 26.3606 41.0999 25.8832 40.6217 25.1742Z"
        fill="#401D00"
      />
      <path
        d="M42.7739 25.9315L43.3997 19.9011C43.1958 19.5079 42.9215 19.1429 42.6192 18.8059C42.3731 18.8551 42.1269 18.9042 41.8949 19.0025C41.6347 19.1148 41.4097 19.2763 41.1776 19.4377C40.8401 19.6694 40.4885 19.8941 40.1369 20.1117C40.2635 20.1889 40.39 20.2661 40.5166 20.3504C40.9596 20.6452 41.3886 20.9752 41.6839 21.4175C42.2183 22.2248 42.1902 23.2638 42.3168 24.2256C42.3941 24.8434 42.5137 25.3839 42.7739 25.9385V25.9315Z"
        fill="#401D00"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M31.7697 35.6612C32.1212 35.2536 32.4614 34.8235 32.6655 34.3254C32.9433 33.6348 32.7336 32.8763 32.0816 32.4745C31.6507 32.2084 31.1347 32.1235 30.6358 32.05C28.4473 31.7047 26.2589 31.3651 24.0647 31.0707C23.2653 30.9632 21.9613 30.516 21.1789 30.6575C20.4305 30.7877 20.2094 31.3594 19.8408 31.9877C19.6027 32.3896 19.3249 32.7631 19.1208 33.1763C18.5822 34.2801 18.6389 35.565 18.7012 36.7876C18.7182 37.1272 18.7409 37.4781 18.8713 37.7951C19.0187 38.1573 19.2965 38.446 19.5914 38.7007C20.5269 39.5271 21.6664 40.1328 22.8798 40.4441C23.0158 40.5912 23.1972 40.4554 23.4014 40.4441C24.3142 40.4158 25.4651 40.478 26.3212 40.1158C26.6047 39.9969 26.8201 39.7705 27.098 39.6347C27.5402 39.4196 28.1695 39.3007 28.5494 38.995C28.8669 38.7403 29.1504 38.3611 29.4565 38.0781C30.273 37.3196 31.0497 36.5102 31.7754 35.6555L31.7697 35.6612Z"
          fill="url(#paint10_linear_4613_98989)"
        />
      </g>
      <path
        d="M40.8977 37.4103C40.1947 36.8613 39.5086 36.2896 38.8113 35.7293C38.2953 35.3161 37.7737 34.8972 37.3882 34.3595C36.9516 33.7538 36.6965 33.018 36.1522 32.5086C35.7157 32.0954 35.143 31.8746 34.5817 31.6709C33.5555 31.303 32.518 30.969 31.4748 30.669C30.8228 30.4822 30.1424 30.3068 29.4734 30.4426C28.7137 30.5954 28.09 31.1218 27.37 31.4049C26.6102 31.7048 25.7371 31.7275 25.0454 32.1633C24.6259 32.4293 24.3084 32.8256 23.9909 33.2048C23.1744 34.1727 22.2843 35.0897 21.3432 35.933C20.7762 36.4368 18.8145 37.45 19.183 38.3216C19.3361 38.6839 20.1469 38.9895 20.4587 39.2273C20.8272 39.5046 21.1731 39.8159 21.4906 40.1499C21.9044 40.5857 22.2786 41.0725 22.7719 41.4234C23.2538 41.7631 23.8264 41.9555 24.3821 42.1366C25.2665 42.431 26.151 42.7196 27.0411 43.014C27.9426 43.314 28.8554 43.6139 29.6548 44.129C30.1481 44.446 30.596 44.8422 31.1176 45.1026C32.5747 45.8271 34.4513 45.346 35.5966 44.1913C35.9424 43.846 36.2373 43.4385 36.6568 43.1838C36.9346 43.0196 37.2521 42.9347 37.564 42.8442C38.6525 42.5385 39.7468 42.1763 40.6879 41.548C41.4874 41.0159 42.9274 39.9688 42.5816 38.8311C42.3831 38.1688 41.5667 37.9311 41.0678 37.5462C41.0111 37.5009 40.9544 37.4556 40.8977 37.416V37.4103Z"
        fill="#401D00"
      />
      <path
        d="M31.0837 31.5133C30.3863 31.1284 29.638 30.8397 28.8669 30.6473C29.0596 30.5624 29.2638 30.4888 29.4735 30.4435C30.1369 30.3133 30.8229 30.4832 31.4749 30.6699C32.5238 30.9699 33.5613 31.3039 34.5819 31.6718C35.1432 31.8756 35.7158 32.0963 36.1524 32.5095C36.2147 32.5661 36.2714 32.6284 36.3281 32.6963C36.4472 32.8661 36.5719 33.0359 36.691 33.2057C36.9234 33.5906 37.1219 33.9982 37.3827 34.3604C37.7682 34.8981 38.2898 35.317 38.8057 35.7302C39.5031 36.2905 40.1891 36.8622 40.8922 37.4113C40.9489 37.4565 41.0056 37.5018 41.0623 37.5415C41.5612 37.9263 42.3719 38.1641 42.576 38.8263C42.9219 39.964 41.4818 41.0112 40.6824 41.5432C39.7412 42.1715 38.647 42.5281 37.5584 42.8394C37.2466 42.93 36.9291 43.0149 36.6513 43.179C36.2317 43.4281 35.9369 43.8356 35.5911 44.1866C34.7803 45.0073 33.6067 45.4827 32.4671 45.4375C32.2233 45.0526 32.0646 44.645 31.8321 44.2375C31.6053 43.8469 31.2878 43.5583 31.095 43.1394C30.5961 42.0413 30.6528 40.7282 30.7208 39.5508C30.7605 38.7754 30.8116 37.9999 30.9476 37.2358C31.061 36.5905 31.2935 35.9623 31.3899 35.317C31.4579 34.8415 31.662 34.0434 31.5486 33.5793C31.5089 33.4265 31.4296 33.285 31.3785 33.1378C31.2425 32.7416 31.3332 32.3057 31.3275 31.8812C31.3275 31.8133 31.3218 31.7397 31.2878 31.6775C31.2425 31.5982 31.1631 31.5473 31.0837 31.5077V31.5133Z"
        fill="#5E3A16"
      />
      <path
        d="M37.0825 46.065C37.3717 45.2839 36.3115 44.8537 35.7048 45.199C35.4894 45.3235 34.9224 46.2461 35.3533 46.2801C35.7388 46.3084 36.13 46.3423 36.5156 46.3706C36.663 46.382 36.8331 46.3876 36.9465 46.2914C37.0145 46.2348 37.0542 46.1442 37.0825 46.0593V46.065Z"
        fill="#4A2500"
      />
      <path
        d="M32.116 47.7805C32.4788 47.0503 31.1635 46.8805 30.8006 47.4013C30.7836 47.4296 30.7666 47.4579 30.7723 47.4862C30.7836 47.5314 30.829 47.5484 30.8686 47.5598C31.2258 47.6673 31.5887 47.7635 31.9572 47.8428C31.9856 47.8484 32.0196 47.8541 32.0479 47.8428C32.0763 47.8314 32.099 47.8031 32.1103 47.7748L32.116 47.7805Z"
        fill="#4A2500"
      />
      <path
        d="M22.7496 43.36C22.3867 43.3034 21.9502 43.2695 21.7517 43.6317C21.7404 43.6544 21.729 43.677 21.7234 43.6997C21.7177 43.7449 21.7347 43.7902 21.7631 43.8242C21.9161 44.0619 22.2336 44.1072 22.5115 44.1355L22.7836 44.1638C22.9027 44.1751 23.0161 44.1864 23.1351 44.1638C23.2485 44.1412 23.3619 44.0732 23.4073 43.9657C23.481 43.8072 23.3846 43.6148 23.2428 43.5129C23.1011 43.411 22.9197 43.3827 22.7496 43.36Z"
        fill="#4A2500"
      />
      <path
        d="M26.7069 45.409C26.8657 45.4656 27.0244 45.5053 27.1832 45.4769C27.3816 45.443 26.7239 44.9449 26.7013 44.9166C26.5538 44.7411 26.0492 44.5657 25.8225 44.6732C25.624 44.7694 25.556 45.1147 25.8111 45.2109C25.8962 45.2449 25.9925 45.2336 26.0889 45.2336C26.2987 45.2392 26.5028 45.3354 26.7013 45.4034L26.7069 45.409Z"
        fill="#4A2500"
      />
      <path
        d="M27.9824 46.9649C27.5459 46.7951 27.3645 46.7498 26.9222 46.8687C26.8032 46.897 26.1852 46.9932 26.3779 47.2366C26.4687 47.3555 26.5707 47.4743 26.7125 47.5196C26.7691 47.5366 26.8315 47.5366 26.8882 47.5592C27.0129 47.5989 27.0923 47.7121 27.1944 47.797C27.2964 47.8819 27.4382 47.9441 27.5459 47.8762C27.6253 47.8253 27.6536 47.729 27.7103 47.6555C27.7954 47.5536 27.9258 47.5083 28.0335 47.4291C28.1412 47.3555 28.2319 47.2083 28.1695 47.0951C28.1299 47.0272 28.0562 46.9989 27.9881 46.9706L27.9824 46.9649Z"
        fill="#4A2500"
      />
      <path
        d="M24.4169 34.7628C24.2128 34.7854 24.0484 34.9383 23.9009 35.0854L23.7535 35.2269C23.7082 35.2722 23.6628 35.3175 23.6401 35.3741C23.7422 35.3854 23.8442 35.3911 23.9463 35.4024C24.1107 35.4137 24.2865 35.425 24.4169 35.3232C24.4736 35.2779 24.5189 35.2156 24.5813 35.1873C24.6663 35.1477 24.8591 35.1534 24.8648 35.0288C24.8648 34.8307 24.57 34.7402 24.4225 34.7571L24.4169 34.7628Z"
        fill="#696969"
      />
      <path
        d="M32.7908 35.199C32.7908 35.1085 32.9099 35.0688 32.9836 35.0462C33.0913 35.0179 33.148 35.0745 33.2387 35.1368C33.2898 35.1707 33.3408 35.199 33.3918 35.233C33.4939 35.3066 33.6016 35.5443 33.4712 35.6292C33.4258 35.6575 33.3691 35.6688 33.3124 35.6801L33.0743 35.7254C32.8078 35.7764 32.7852 35.3745 32.7852 35.1934L32.7908 35.199Z"
        fill="#9C9C9C"
      />
      <path
        d="M28.7488 33.064C28.6921 33.0866 28.6411 33.03 28.607 32.9904C28.5617 32.9338 28.5787 32.8829 28.5957 32.815C28.6014 32.7753 28.607 32.7357 28.6127 32.6961C28.6297 32.6168 28.7431 32.4867 28.8282 32.5433C28.8565 32.5602 28.8792 32.5942 28.9019 32.6225L28.9926 32.7527C29.0946 32.8999 28.8622 33.0187 28.7488 33.0697V33.064Z"
        fill="#9C9C9C"
      />
      <path
        d="M28.5443 36.3589C28.4876 36.3816 28.4365 36.325 28.4025 36.2853C28.3572 36.2287 28.3742 36.1778 28.3912 36.1099C28.3968 36.0703 28.4025 36.0306 28.4082 35.991C28.4252 35.9118 28.5386 35.7816 28.6236 35.8382C28.652 35.8552 28.6747 35.8891 28.6973 35.9174L28.7881 36.0476C28.8901 36.1948 28.6577 36.3136 28.5443 36.3646V36.3589Z"
        fill="#696969"
      />
      <path
        d="M27.8697 34.5706C28.0398 34.5423 28.1815 34.6215 28.2779 34.746C28.2949 34.7687 28.482 34.9894 28.4197 35.0234C28.3346 35.0687 28.2382 35.0913 28.1418 35.0856C28.0341 35.0743 27.9264 35.029 27.8243 35.063C27.796 35.0743 27.7563 35.0856 27.7336 35.0687C27.7166 35.0573 27.711 35.0347 27.711 35.0121C27.6939 34.8479 27.6656 34.6102 27.8697 34.5706Z"
        fill="#696969"
      />
      <path
        d="M32.893 38.1756C32.91 38.1246 32.9497 38.085 33.0121 38.0624C33.1425 38.0114 33.5677 38.0737 33.5677 38.2605C33.5677 38.351 33.4657 38.3963 33.4146 38.4586C33.3579 38.5322 33.3296 38.6284 33.3069 38.7189C33.2956 38.7586 33.2842 38.7982 33.2559 38.8208C33.2275 38.8435 33.1822 38.8378 33.1538 38.8208C33.1198 38.8038 33.0915 38.7812 33.0688 38.7529C32.9724 38.6397 32.825 38.351 32.893 38.1756Z"
        fill="#9C9C9C"
      />
      <path
        d="M30.8746 34.683C30.886 34.6547 30.92 34.6377 30.954 34.6264C31.1184 34.5698 31.2942 34.5471 31.4643 34.5754L31.4473 34.6264C31.521 34.666 31.589 34.7339 31.6287 34.8075C31.6627 34.8641 31.674 34.9433 31.6287 34.9886C31.5947 35.0169 31.5493 35.0226 31.504 35.0169C31.4586 35.0113 31.4189 34.9943 31.3736 34.9943C31.2658 34.9943 31.1751 35.0905 31.0674 35.0792C30.9937 35.0735 30.9313 35.0169 30.903 34.949C30.8746 34.8811 30.869 34.8075 30.869 34.7339C30.869 34.7169 30.869 34.6943 30.8803 34.6773L30.8746 34.683Z"
        fill="#9C9C9C"
      />
      <path
        d="M28.0908 37.8247C28.0908 37.8247 28.0625 37.8133 28.0512 37.8077C28.0171 37.7794 27.9888 37.7454 27.9548 37.7228L28.0908 37.8247Z"
        fill="#696969"
      />
      <path
        d="M27.5692 37.7956C27.6202 37.7729 27.6769 37.7729 27.728 37.7729C27.847 37.7729 27.9434 37.7843 28.0568 37.8352C28.1588 37.8805 28.3063 38.022 28.2439 38.1465C28.2155 38.2088 28.1362 38.2427 28.0625 38.2427C27.9888 38.2427 27.9207 38.2201 27.847 38.2144C27.745 38.2088 27.6429 38.2314 27.5522 38.1861C27.4785 38.1522 27.4331 38.0673 27.4331 37.988C27.4331 37.9088 27.4898 37.8295 27.5635 37.7956H27.5692Z"
        fill="#696969"
      />
      <path
        d="M24.8138 36.6754C24.8138 36.6754 24.8364 36.6924 24.8478 36.6981C24.8761 36.732 24.8988 36.766 24.9328 36.7999L24.8138 36.6754Z"
        fill="#696969"
      />
      <path
        d="M25.3242 36.7955C25.2731 36.8069 25.2164 36.7955 25.1597 36.7955C25.0407 36.7786 24.95 36.7503 24.8479 36.6767C24.7572 36.6144 24.6381 36.4503 24.7175 36.3371C24.7572 36.2805 24.8422 36.2635 24.9103 36.2748C24.9783 36.2861 25.0463 36.3201 25.1144 36.3371C25.2108 36.3597 25.3185 36.3597 25.3979 36.4163C25.4659 36.4672 25.4999 36.5521 25.4829 36.6314C25.4659 36.7106 25.4035 36.7786 25.3242 36.7955Z"
        fill="#696969"
      />
      <path
        d="M31.9744 41.0381C32.0935 40.8683 32.3883 40.9079 32.5074 41.0777C32.5754 41.1796 32.5698 41.389 32.5584 41.5022C32.5584 41.5305 32.5471 41.5645 32.5244 41.5871C32.496 41.6098 32.4564 41.6098 32.4223 41.6041L31.9574 41.5588C31.9574 41.5588 31.9404 41.5588 31.9291 41.5588C31.8951 41.5475 31.8894 41.5079 31.8837 41.4739C31.8611 41.3211 31.8837 41.1626 31.9744 41.0381Z"
        fill="#696969"
      />
      <path
        d="M30.1093 37.9092C30.3134 37.9092 30.4494 38.1809 30.3701 38.3733C30.3247 38.4865 30.1433 38.5997 30.0412 38.6507C30.0129 38.6677 29.9845 38.679 29.9562 38.6733C29.9222 38.662 29.8995 38.628 29.8825 38.5997C29.8088 38.4639 29.7351 38.328 29.6614 38.1922C29.6614 38.1865 29.65 38.1752 29.65 38.1696C29.6443 38.1356 29.667 38.1073 29.6954 38.0846C29.8088 37.9828 29.9562 37.9148 30.1093 37.9148V37.9092Z"
        fill="#696969"
      />
      <path
        d="M35.3649 36.6411C35.4612 36.5166 35.654 36.5392 35.7277 36.6807C35.7844 36.7996 35.7561 36.9411 35.7277 37.0656C35.6937 37.1958 35.5009 37.5637 35.3535 37.3034C35.2741 37.1619 35.2628 36.777 35.3705 36.6411H35.3649Z"
        fill="#C2C2C2"
      />
      <path
        d="M36.7596 38.7135C36.7086 38.6003 36.7426 38.3909 36.839 38.3117C36.8957 38.2607 36.9751 38.2381 37.0545 38.2324C37.0885 38.2324 37.1282 38.2324 37.1622 38.2437C37.2472 38.272 37.3153 38.3456 37.3493 38.4305C37.4116 38.623 37.2812 38.8437 37.1282 38.9626C37.1168 38.9739 37.0998 38.9852 37.0828 38.9852C37.0545 38.9852 37.0261 38.9739 37.0034 38.9569C36.9467 38.9117 36.89 38.8664 36.839 38.8154C36.8107 38.7871 36.7823 38.7588 36.771 38.7249L36.7596 38.7135Z"
        fill="#C2C2C2"
      />
      <path
        d="M34.7354 40.4509C34.6617 40.2981 34.6107 40.1396 34.5823 39.9754C34.5597 39.8283 34.5143 39.7094 34.6901 39.749C34.7694 39.766 34.8885 39.8113 34.9509 39.8622C34.9735 39.8849 35.0813 40.0943 35.0643 40.0943C34.9509 40.0943 34.9055 40.3943 34.8261 40.4735C34.8091 40.4905 34.7921 40.5018 34.7694 40.4962C34.7468 40.4962 34.7354 40.4735 34.7298 40.4509H34.7354Z"
        fill="#C2C2C2"
      />
      <path
        d="M35.161 38.6394C35.1723 38.6677 35.1894 38.7017 35.2177 38.7017C35.2347 38.7017 35.246 38.6904 35.2574 38.6791C35.3198 38.6225 35.3708 38.5489 35.4558 38.5319C35.5068 38.5206 35.5692 38.5319 35.5862 38.481C35.5862 38.2206 35.2347 38.3111 35.076 38.3564C35.0816 38.4527 35.1213 38.5432 35.1553 38.6338L35.161 38.6394Z"
        fill="#C2C2C2"
      />
      <path
        d="M29.6386 39.9815C29.6499 40.0098 29.667 40.0437 29.6953 40.0437C29.7123 40.0437 29.7237 40.0324 29.735 40.0211C29.7974 39.9645 29.8484 39.8909 29.9334 39.8739C29.9845 39.8626 30.0525 39.8739 30.0695 39.823C30.0695 39.5626 29.718 39.6532 29.5592 39.6985C29.5649 39.7947 29.6046 39.8853 29.6386 39.9758V39.9815Z"
        fill="#696969"
      />
      <path
        d="M36.907 37.0769C36.7369 36.9637 36.8276 36.8052 37.0034 36.8505C37.1905 36.8957 37.3266 37.0033 37.4853 37.1052C37.525 37.1278 37.5647 37.1561 37.576 37.2014C37.5874 37.2637 37.5363 37.3146 37.4853 37.3486L37.4059 37.4108C37.4059 37.4108 37.3946 37.4165 37.3889 37.4165C37.3776 37.4165 37.3719 37.4108 37.3662 37.4052C37.3209 37.3486 37.2982 37.2693 37.2358 37.224C37.1905 37.1901 37.1281 37.1788 37.0714 37.1618C37.0091 37.1448 36.9524 37.1165 36.9013 37.0825L36.907 37.0769Z"
        fill="#C2C2C2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4613_98989"
          x1="23.4015"
          y1="18.4746"
          x2="25.4919"
          y2="14.9543"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C7C7C" />
          <stop offset="0.14" stopColor="#575757" />
          <stop offset="0.29" stopColor="#373737" />
          <stop offset="0.45" stopColor="#1F1F1F" />
          <stop offset="0.61" stopColor="#0D0D0D" />
          <stop offset="0.79" stopColor="#030303" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4613_98989"
          x1="11.7558"
          y1="25.1869"
          x2="13.8462"
          y2="21.6667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C7C7C" />
          <stop offset="0.14" stopColor="#575757" />
          <stop offset="0.29" stopColor="#373737" />
          <stop offset="0.45" stopColor="#1F1F1F" />
          <stop offset="0.61" stopColor="#0D0D0D" />
          <stop offset="0.79" stopColor="#030303" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4613_98989"
          x1="20.5667"
          y1="10.157"
          x2="19.3109"
          y2="8.24553"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#597E90" />
          <stop offset="0.94" stopColor="#6F797B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4613_98989"
          x1="24.3921"
          y1="18.6784"
          x2="21.8821"
          y2="11.3958"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#597E90" />
          <stop offset="0.42" stopColor="#4C6875" />
          <stop offset="0.94" stopColor="#3C4B52" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4613_98989"
          x1="17.8733"
          y1="16.7423"
          x2="19.2966"
          y2="12.4655"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#305F76" />
          <stop offset="0.44" stopColor="#284859" />
          <stop offset="0.94" stopColor="#1F2B36" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4613_98989"
          x1="18.7599"
          y1="20.1532"
          x2="19.447"
          y2="18.0605"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#305F76" />
          <stop offset="0.94" stopColor="#2A4861" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4613_98989"
          x1="23.0566"
          y1="13.8432"
          x2="24.1784"
          y2="10.4793"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92C6B9" />
          <stop offset="0.94" stopColor="#6F797B" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4613_98989"
          x1="23.492"
          y1="17.0368"
          x2="24.8521"
          y2="12.8513"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92C6B9" />
          <stop offset="0.94" stopColor="#6F797B" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4613_98989"
          x1="23.5975"
          y1="9.18223"
          x2="24.1656"
          y2="8.20559"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.16" stopColor="#535353" />
          <stop offset="0.56" stopColor="#606060" />
          <stop offset="0.87" stopColor="#777777" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4613_98989"
          x1="19.0903"
          y1="11.8047"
          x2="19.6585"
          y2="10.828"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.16" stopColor="#535353" />
          <stop offset="0.56" stopColor="#606060" />
          <stop offset="0.87" stopColor="#777777" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4613_98989"
          x1="25.7734"
          y1="35.3717"
          x2="19.7291"
          y2="32.1718"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DADADA" />
          <stop offset="1" stopColor="#DADADA" stopOpacity="0.29" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
