import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const MoonIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_11632_19943"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="20"
        height="21"
      >
        <circle
          cx="16"
          cy="16.167"
          r="10"
          fill="url(#paint0_radial_11632_19943)"
        />
      </mask>
      <g mask="url(#mask0_11632_19943)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.10341 20.6497C13.4836 20.6497 17.0344 17.0989 17.0344 12.7187C17.0344 10.1345 15.7985 7.83896 13.8855 6.39097C14.5671 6.24424 15.2745 6.16699 16 6.16699C21.5228 6.16699 26 10.6441 26 16.167C26 21.6898 21.5228 26.167 16 26.167C11.9677 26.167 8.49286 23.7804 6.91089 20.3428C7.60722 20.5427 8.34281 20.6497 9.10341 20.6497Z"
          fill="url(#paint1_radial_11632_19943)"
        />
        <circle cx="20.1378" cy="23.0635" r="1.03448" fill="#757575" />
        <circle cx="21.1723" cy="18.5808" r="1.37931" fill="#757575" />
        <circle cx="14.2759" cy="24.7877" r="3.44828" fill="#757575" />
        <circle cx="19.4483" cy="14.098" r="1.72414" fill="#757575" />
        <circle cx="24.6207" cy="15.1325" r="2.06897" fill="#757575" />
        <circle cx="19.4483" cy="9.27046" r="1.72414" fill="#757575" />
        <circle cx="16.0001" cy="18.9257" r="1.03448" fill="#757575" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4483 15.8222C20.4005 15.8222 21.1724 15.0502 21.1724 14.098C21.1724 13.732 21.0583 13.3925 20.8638 13.1134C20.5847 12.9189 20.2454 12.8049 19.8794 12.8049C18.9272 12.8049 18.1553 13.5769 18.1553 14.5291C18.1553 14.8951 18.2694 15.2346 18.4639 15.5137C18.743 15.7082 19.0823 15.8222 19.4483 15.8222Z"
          fill="#BDBDBD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6207 17.2015C25.7633 17.2015 26.6896 16.2752 26.6896 15.1325C26.6896 14.6934 26.5528 14.2862 26.3195 13.9513C25.9845 13.7177 25.5772 13.5808 25.1378 13.5808C23.9952 13.5808 23.0688 14.5071 23.0688 15.6498C23.0688 16.0889 23.2057 16.4961 23.439 16.831C23.774 17.0645 24.1813 17.2015 24.6207 17.2015Z"
          fill="#BDBDBD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1378 24.098C20.7091 24.098 21.1723 23.6349 21.1723 23.0635C21.1723 22.8438 21.1038 22.6401 20.987 22.4726C20.8196 22.356 20.616 22.2877 20.3965 22.2877C19.8252 22.2877 19.3621 22.7508 19.3621 23.3221C19.3621 23.5418 19.4305 23.7455 19.5473 23.9131C19.7148 24.0297 19.9183 24.098 20.1378 24.098Z"
          fill="#BDBDBD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1723 19.9601C21.9341 19.9601 22.5517 19.3426 22.5517 18.5808C22.5517 18.2879 22.4604 18.0164 22.3047 17.7931C22.0815 17.6375 21.81 17.5463 21.5172 17.5463C20.7555 17.5463 20.1379 18.1639 20.1379 18.9256C20.1379 19.2185 20.2292 19.49 20.3849 19.7134C20.6081 19.8689 20.8796 19.9601 21.1723 19.9601Z"
          fill="#BDBDBD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2759 28.236C16.1803 28.236 17.7241 26.6921 17.7241 24.7877C17.7241 24.0556 17.496 23.3768 17.1069 22.8185C16.5487 22.4296 15.87 22.2015 15.138 22.2015C13.2335 22.2015 11.6897 23.7453 11.6897 25.6498C11.6897 26.3818 11.9178 27.0606 12.3069 27.6189C12.8651 28.0079 13.5439 28.236 14.2759 28.236Z"
          fill="#BDBDBD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4483 10.9946C20.4005 10.9946 21.1724 10.2227 21.1724 9.27045C21.1724 8.90438 21.0583 8.56496 20.8638 8.2858C20.5847 8.09136 20.2454 7.97736 19.8794 7.97736C18.9272 7.97736 18.1553 8.74928 18.1553 9.70149C18.1553 10.0676 18.2694 10.407 18.4639 10.6861C18.743 10.8806 19.0823 10.9946 19.4483 10.9946Z"
          fill="#BDBDBD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 19.9601C16.5714 19.9601 17.0345 19.497 17.0345 18.9256C17.0345 18.7061 16.9661 18.5025 16.8494 18.335C16.6819 18.2182 16.4783 18.1498 16.2586 18.1498C15.6873 18.1498 15.2241 18.6129 15.2241 19.1843C15.2241 19.4038 15.2925 19.6074 15.4092 19.7749C15.5767 19.8917 15.7804 19.9601 16 19.9601Z"
          fill="#BDBDBD"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_11632_19943"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 16.167) rotate(15.4222) scale(10.3735)"
        >
          <stop stopColor="#ECECEC" />
          <stop offset="1" stopColor="#2E2E2E" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_11632_19943"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.4554 16.167) rotate(90) scale(10 9.54455)"
        >
          <stop stopColor="#C7C7C7" />
          <stop offset="0.416667" stopColor="#A9A9A9" />
          <stop offset="0.770833" stopColor="#979494" />
          <stop offset="0.942708" stopColor="#7A7A7A" />
          <stop offset="1" stopColor="#7D7D7D" />
        </radialGradient>
      </defs>
    </SvgIcon>
  );
};
