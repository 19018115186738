import {
  Checkbox,
  Grid,
  SvgIcon,
  SvgIconProps,
  TextField,
  Theme,
  Typography,
  alpha,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { ChipsArray } from "./ChipsArray";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { ItemList } from "./ItemList";

export interface ChipProps {
  icon?: React.FC<SvgIconProps<"svg", {}>> | null;
  value?: string | number;
  unit?: string;
  id?: string;
  disabled?: boolean;
}

export interface OptionsProps {
  id: string;
  title: string;
  label?: string | null;
  group?: {
    group: string | null;
    icon?: React.FC<SvgIconProps<"svg", {}>>;
    value?: number | string;
  };
  iconLabel?: React.FC<SvgIconProps<"svg", {}>>;
  icon?: React.FC<SvgIconProps<"svg", {}>> | null;
  iconPriority?: React.FC<SvgIconProps<"svg", {}>> | null;
  iconOptionLabel?: React.FC<SvgIconProps<"svg", {}>> | null;
  valueOptionLabel?: string;
  chipList?: ChipProps[];
  locationId?: string;
  locationName?: string;
  disabled?: boolean;
}
export interface DataItemList {
  count: number;
  priority: number;
  id: string;
}
export interface DataChipList {
  hasPriority?: boolean;
  id: string;
}
export enum ModeAutocomplete {
  CHIP_SELECTOR = "CHIP_SELECTOR",
  ITEM_LIST = "ITEM_LIST",
}
interface SelectorProps {
  options: OptionsProps[];
  placeHolder: string;
  mode?: ModeAutocomplete;
  setListItems?: (items: DataItemList[]) => void;
  setListItemsChangeCount?: (index: number, value: any) => void;
  setListItemsChangePriority?: (
    indexUp: number,
    indexDown: number,
    upValue: DataItemList,
    downValue: DataItemList
  ) => void;
  setChipItems?: (items: DataChipList[]) => void;
  initState?: OptionsProps[];
  currentListItems?: DataItemList[];
  currentChipItems?: DataChipList[];
  classIcon?: string;
  disabled: boolean;
  hasValueGroup?: boolean;
}

export const CustomMultipleSelector: React.FC<SelectorProps> = ({
  options,
  placeHolder,
  mode = ModeAutocomplete.CHIP_SELECTOR,
  setListItems,
  setListItemsChangeCount,
  setListItemsChangePriority,
  setChipItems,
  currentListItems,
  currentChipItems,
  initState,
  classIcon,
  disabled,
  hasValueGroup = false,
}) => {
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = useState<OptionsProps[]>([]);
  useEffect(() => {
    if (initState && initState?.length) {
      setSelectedOptions([...initState]);
    } else {
      setSelectedOptions([]);
    }
  }, [initState, setChipItems]);

  const handleListItemsChange = (index: number, value: any) => {
    setListItemsChangeCount && setListItemsChangeCount(index, value);
  };

  const handleChangePriority = (
    indexUp: number,
    indexDown: number,
    upValue: DataItemList,
    downValue: DataItemList
  ) => {
    setListItemsChangePriority &&
      setListItemsChangePriority(indexUp, indexDown, upValue, downValue);
  };

  const handleDeleteOptionList = (id: string) => {
    setSelectedOptions(selectedOptions.filter((item) => item.id !== id));

    if (currentListItems?.length) {
      const newOptionList = [
        ...(currentListItems?.filter((item) => item.id !== id) ?? []),
      ];

      if (newOptionList.length) {
        for (let index = 0; index < newOptionList.length; index++) {
          newOptionList[index].priority = index + 1;
        }
      }
      setListItems && setListItems(newOptionList);
    }
  };

  const clearAutocomplete = () => {
    if (mode === "CHIP_SELECTOR") setChipItems && setChipItems([]);
    if (mode === "ITEM_LIST") setListItems && setListItems([]);
  };

  const handleOptionChange = (event: any, newValues: any, reason: any) => {
    if (reason === "remove-option" && event?.key === "Backspace") {
      return;
    }
    newValues = newValues.filter((item: OptionsProps) => !item.disabled);
    setSelectedOptions(newValues);

    if (reason === "clear") clearAutocomplete();

    if (mode === "CHIP_SELECTOR" && reason !== "clear") {
      const formatChipValue: DataChipList[] = newValues.map(
        (item: OptionsProps) => {
          return {
            id: item.id,
            hasPriority: item.iconPriority ? true : false,
            title: item.title,
          };
        }
      );
      setChipItems && setChipItems([...formatChipValue]);
    }

    //init state
    if (mode === "ITEM_LIST" && currentListItems && reason !== "clear") {
      const newData = newValues.map((element: OptionsProps, index: number) => {
        const currValue = currentListItems.find(
          (item) => item.id === element.id
        );
        return {
          id: element.id,
          count: currValue?.count ? currValue.count : 1,
          priority: index + 1,
        };
      });
      setListItems && setListItems(newData);
    }
  };

  const handleDeleteOptionChip = (id: string) => {
    setSelectedOptions(selectedOptions.filter((item) => item.id !== id));
    setChipItems &&
      setChipItems(currentChipItems?.filter((item) => item.id !== id) ?? []);
  };

  const classes = useStyles();

  const ChipItem = ({ icon, value, unit = undefined, disabled }: ChipProps) => {
    const newValue = unit && value ? `${value}${unit}` : value ? value : "0";
    return (
      <Grid container className={classes.chipLabel}>
        {icon && (
          <>
            <SvgIcon
              component={icon}
              viewBox={classIcon ? "0 0 16 16" : "0 0 24 24"}
              className={classIcon ? classIcon : classes.iconLabel}
              style={{
                fill:
                  disabled && theme.palette.type === "dark"
                    ? theme.palette.secondary.light
                    : disabled && theme.palette.type === "light"
                    ? theme.palette.action.disabled
                    : theme.palette.type === "light"
                    ? theme.palette.common.black
                    : theme.palette.chip.contrastText,
              }}
            />
            <Typography
              variant="caption"
              style={{
                fontWeight: 700,
                color:
                  disabled && theme.palette.type === "dark"
                    ? theme.palette.secondary.light
                    : disabled && theme.palette.type === "light"
                    ? theme.palette.action.disabled
                    : theme.palette.type === "light"
                    ? theme.palette.common.black
                    : theme.palette.chip.contrastText,
              }}
            >
              {newValue}
            </Typography>
          </>
        )}
      </Grid>
    );
  };

  const getGroupValues = (id: string) => {
    if (hasValueGroup) {
      const findGroup = options.find((values) => values.group?.group === id);
      if (findGroup) {
        return (
          <Grid container>
            <ChipItem
              icon={findGroup.group?.icon}
              value={findGroup.group?.value}
            />
          </Grid>
        );
      }
    }
    return <></>;
  };

  return (
    <>
      {!disabled && (
        <Grid item xs={12} className={classes.selectContainer}>
          <Autocomplete
            classes={{
              root: classes.root,
              groupUl: classes.group,
              option: classes.group,
            }}
            clearText="Limpiar"
            openText="Abrir"
            closeText="Cerrar"
            multiple
            size="small"
            options={options}
            noOptionsText="No se encontraron datos"
            disableCloseOnSelect={true}
            value={selectedOptions}
            onChange={(event, newValue, reason) =>
              handleOptionChange(event, newValue, reason)
            }
            getOptionLabel={(option) => option.title}
            getOptionSelected={(option, value) => option.id === value.id}
            groupBy={(option) => option.group?.group ?? ""}
            renderInput={(params) => (
              <TextField {...params} label={placeHolder} variant="outlined" />
            )}
            renderGroup={(params) => (
              <React.Fragment key={params.key}>
                <Grid container className={classes.containerGroup}>
                  <Grid item xs={5}>
                    {params.group}
                  </Grid>
                  <Grid item xs={7}>
                    {getGroupValues(params.group)}
                  </Grid>
                </Grid>
                <div>{params.children}</div>
              </React.Fragment>
            )}
            renderTags={() => null}
            renderOption={(option, { selected }) => (
              <Grid
                container
                alignItems="center"
                style={{ cursor: option.disabled ? "default" : "pointer" }}
              >
                <Grid item xs={2} md={1}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{
                      marginRight: 8,
                    }}
                    checked={selected}
                    color="primary"
                    disabled={option.disabled}
                    aria-disabled
                  />
                </Grid>
                <Grid item xs={10} md={11}>
                  <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                    className={classes.container}
                  >
                    <Grid
                      item
                      xs={5}
                      md={8}
                      style={{
                        color: option.disabled
                          ? theme.palette.text.disabled
                          : theme.palette.text.primary,
                      }}
                    >
                      {option.title ?? option.id}
                    </Grid>
                    <Grid item xs={7} md={4}>
                      <Grid container className={classes.chipLabelContainer}>
                        {option.iconPriority && (
                          <SvgIcon
                            component={option.iconPriority}
                            className={classes.chipIcon}
                            style={{
                              fill: option.disabled
                                ? theme.palette.action.disabled
                                : theme.palette.action.active,
                            }}
                          />
                        )}
                        {option.chipList?.map((item, index) => (
                          <ChipItem
                            key={index}
                            icon={item.icon}
                            value={item.value}
                            unit={item.unit}
                            disabled={option.disabled}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      )}
      {mode === ModeAutocomplete.CHIP_SELECTOR ? (
        <ChipsArray
          selectedOptions={selectedOptions}
          setSelectedOptions={handleDeleteOptionChip}
          disabled={disabled}
        />
      ) : (
        <ItemList
          selectedOptions={selectedOptions}
          setSelectedOptions={handleDeleteOptionList}
          setDataOptions={handleListItemsChange}
          setDataPriority={handleChangePriority}
          dataListItems={currentListItems ?? []}
          disabled={disabled}
        />
      )}
    </>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    root: {
      height: 40,
      marginTop: 8,
    },
    container: {
      width: "100%",
      "&&:hover $chipLabel": {
        background: palette.common.white,
      },
    },
    containerGroup: {
      padding: "6px 16px 6px 10px",
    },
    chipLabel: {
      background: palette.grey[100],
      borderRadius: 6,
      padding: "0px 6px 0px 6px",
      width: "auto",
      marginRight: 2,
      "&&:hover": {
        fill: alpha(palette.action.active, 0.54),
        background: palette.common.white,
      },
    },
    iconLabel: {
      height: 16,
      width: 16,
      marginRight: 2,
    },
    chipLabelContainer: {
      alignItems: "center",
    },
    group: {
      paddingLeft: "0px !important",
    },
    chipIcon: {
      height: 20,
      fill: alpha(palette.action.active, 0.54),
      width: 20,
    },
    selectContainer: {
      padding: "0px 10px 10px 10px",
    },
  };
});
