import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const ArrowCircleDown: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_10639_12583)">
        <path
          d="M10.6666 3.33354C14.3416 3.33354 17.3333 6.3252 17.3333 10.0002C17.3333 13.6752 14.3416 16.6669 10.6666 16.6669C6.99165 16.6669 3.99998 13.6752 3.99998 10.0002C3.99998 6.3252 6.99165 3.33354 10.6666 3.33354ZM10.6666 1.66687C6.06665 1.66687 2.33331 5.4002 2.33331 10.0002C2.33331 14.6002 6.06665 18.3335 10.6666 18.3335C15.2666 18.3335 19 14.6002 19 10.0002C19 5.4002 15.2666 1.66687 10.6666 1.66687ZM11.5 10.0002V6.66687H9.83331V10.0002H7.33331L10.6666 13.3335L14 10.0002H11.5Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_10639_12583">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.666626 0.000183105)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ArrowCircleDown;
