import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react';
import { CircleChangeIcon } from 'components/icons/CircleChangeIcon';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { CalendarIcon } from 'components/icons/CalendarIcon';

interface ISmallBox {
    icon: string;
    title: string;
    small: boolean;
    value: string;
}

const SmallBox: React.FC<ISmallBox> = ({
    icon, title, small, value
}) => {
    const classes = useStyles();

    const iconType = (icon: string) => {
        if (icon === 'SHIFT'){
            return <CircleChangeIcon className={classes.shiftIcon}
            />
        }
        if (icon === 'DATE') {
            return <CalendarIcon className={classes.dateIcon}
            />
        }
        if (icon === 'HOUR') {
            return <AccessAlarmIcon className={classes.hourIcon}
            />
        }
        else {
            return <></>
        }
    }
  return (
    <>
    {small ?
        <Paper className={icon === 'HOUR'? classes.operatorPaperHour : classes.operatorPaper}>
            <Grid container className= {classes.container}>
                <Grid item>
                        {iconType(icon)}
                </Grid>
                        <Typography className={classes.titleText}>
                            {title}
                        </Typography>
                <Grid item>
                    <Grid className={classes.line}/>
                </Grid>
                <Grid item>
                    <Typography className={classes.valueText}>
                        { value }
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
        :
        <Paper className={classes.operatorPaperBig}>
        <Grid container className= {classes.container}>
            <Grid item>
                    {iconType(icon)}
            </Grid>
                    <Typography className={classes.titleText}>
                        {title}
                    </Typography>
            <Grid item>
                <Grid className={classes.line}/>
            </Grid>
            <Grid item>
                <Typography className={classes.valueText}>
                    {value}
                </Typography>
            </Grid>
        </Grid>
    </Paper>
    }
    </>

  )
}

export default SmallBox

const useStyles = makeStyles((theme) => {
    const { palette } = theme;
    const { type } = palette;
    return {
      operatorPaper: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        width: 230,
        height: 79,
        paddingTop: 22,
        paddingBottom: 19,
        backgroundColor:
          type === "light"
            ? theme.palette.background.default
            : theme.palette.background.paper,
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
          height: 50,
          width: 150
        }
      },
      operatorPaperHour : {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        width: 280,
        height: 79,
        paddingTop: 22,
        paddingBottom: 19,
        backgroundColor:
          type === "light"
            ? theme.palette.background.default
            : theme.palette.background.paper,
        boxShadow: 'none',
        [theme.breakpoints.down('md')]: {
          width: 220
        },
        [theme.breakpoints.down('sm')]: {
          height: 50
        }
      },
      operatorPaperBig: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        width: 524,
        height: 79,
        paddingTop: 22,
        paddingBottom: 19,
        backgroundColor:
          type === "light"
            ? theme.palette.background.default
            : theme.palette.background.paper,
        boxShadow: 'none',
        [theme.breakpoints.down('md')]: {
          width: 500
        },
        [theme.breakpoints.down('sm')]: {
          height: 50,
          width: 330
        }
      },
      container: {
        display: 'flex',
        displayFlex: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      },
      titleText: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        [theme.breakpoints.down('md')]: {
          fontSize: '16px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px'
        }
      },
      valueText: {
        fontSize: '20px',
        fontStyle: 'normal',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 4,
        [theme.breakpoints.down('md')]: {
          fontSize: '18px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '16px'
        }
      },
      iconText: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 4
      },
      line: {
        width: '0px',
        height: '60px',
        border: '0.5px solid rgba(0,0,0,0.25)',
        [theme.breakpoints.down('sm')]: {
          height: '40px'
        }
      },
      dateIcon: {
        width:'36px',
        height:'42px',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
          width: 18,
          height: 21
        }
      },
      hourIcon: {
        color: '#EE8105',
        width:'37px',
        height:'38px',
        [theme.breakpoints.down('sm')]: {
          width: 21,
          height: 21
        }
      },
      shiftIcon: {
        width:'42px',
        height:'41px',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
          width: 24,
          height: 24
        }
      }
    }
});