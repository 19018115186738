import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const TruckDetailsCardSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.truckDetailsPaper} elevation={0}>
      <div className={classes.leftDetails}>
        <Skeleton className={classes.truckIcon} variant="circle" />
        <Skeleton
          className={classes.truckCodeAndFleet}
          variant="rect"
          height={32}
        />
      </div>
      <Paper className={classes.rightDetails} elevation={0}>
        <div className={classes.detailSection}>
          <Skeleton
            className={classes.truckDetail}
            variant="rect"
            height={28}
          />
          <Skeleton
            className={classes.truckDetail}
            variant="rect"
            height={28}
          />
          <Skeleton
            className={classes.truckDetail}
            variant="rect"
            height={28}
          />
        </div>
        <div className={classes.detailSection}>
          <Skeleton
            className={classes.truckDetail}
            variant="rect"
            height={28}
          />
          <Skeleton
            className={classes.truckDetail}
            variant="rect"
            height={28}
          />
        </div>
        <div className={classes.detailSection}>
          <Skeleton
            className={classes.truckDetail}
            variant="rect"
            height={28}
          />
        </div>
      </Paper>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  truckDetailsPaper: {
    display: "flex",
    padding: 16,
    backgroundColor: theme.palette.background.default,
  },
  leftDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 300,
  },
  rightDetails: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    padding: "0px 21px 0px 21px",
  },
  truckIcon: {
    width: 80,
    height: 80,
    marginBottom: 16,
  },
  truckCodeAndFleet: {
    maxWidth: 198,
    width: "100%",
  },
  truckDetail: {
    width: "100%",
    maxWidth: 283,
    marginTop: 6,
    marginBottom: 10,
    alignItems: "center",
    marginRight: 60,
    "&:last-child": {
      marginRight: 0,
    },
    borderRadius: 4,
  },
  detailSection: {
    display: "flex",
    flexWrap: "wrap",
  },
}));
