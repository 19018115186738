import React from 'react';
import { Badge, Box, Grid, makeStyles, Paper, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core'
import { BusIcon } from 'components/icons/BusIcon';
import { GreenTicketIcon } from 'components/icons/GreenTicket';
import { ShovelIcon } from 'components/icons/ShovelIcon';
import { UbicationIcon } from 'components/icons/UbicationIcon';
import { BusData } from './ShiftChangeScreen';
import WarningIcon from '@material-ui/icons/Warning';

interface IDataBus {
    bus: string;
    busData: BusData[]
}

export const BusTable: React.FC<IDataBus> = ({bus, busData}) => {
const classes = useStyles();

    const iconInfo = (type: string) => {
        if(type === 'BUS') {
        return <BusIcon className={classes.icon} />
        }
        else {
        return <Grid className={classes.icon}/>
        }
    }

    const palaIcon = (equipType: string, equipment:string, ubication: string) => {
        if (equipType === 'Palas') {
          return <ShovelIcon height={24} width={24} />
        }
        else {
          if (equipment === 'NINGUNA' || ubication === 'NINGUNA'){
            return <GreenTicketIcon height={24} width={24} />
          }
          return <></>
        }
      }
      const not_asigned_oper = (destination: string) => {
        if (destination === 'NINGUNA'){
          return (
          <Grid container className={classes.chipIcon}>
            <WarningIcon style={{width: '20px', height: '20px', color: '#ED6C02'}} />
            <Typography style={{color: '#ED6C02', fontSize: '14px', fontWeight: 'bold', paddingLeft: '16px'}} >
              NINGUNA
            </Typography>
          </Grid>)
        }else {
          return destination
        }
    }
  const iconTitle = (title: string, type: string) => {
    return (
      <Box className={classes.headerBox}>
          <Badge
              overlap="circular"
              classes={{
                  badge: classes.operatorBadge,
              }}
              anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
              }}
              >
              {iconInfo(type)}
          </Badge>
          <Typography
            className={classes.subTitle}
            noWrap
            variant="body2"
            color="textPrimary"
          >
              {title} {bus}
          </Typography>
          </Box>
    )
  }
    return (
        <Paper elevation={0} className={classes.operatorPaper}>
        <Grid container className={classes.titleGrid}>
          <Grid item>
          {iconTitle('BUS DE TRASLADO', 'BUS')}
          </Grid>
        </Grid>
        <Grid container>
          <TableContainer className={classes.table}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow className={classes.tableRowHead}>
                        <TableCell className={classes.tableCellHead}>
                            #
                        </TableCell>
                        <TableCell className={classes.tableCellHead}>
                            Nombre Operador
                        </TableCell>
                        <TableCell className={classes.tableCellHead}>
                            Equipo
                        </TableCell>
                        <TableCell className={classes.tableCellHead}>
                            Ubicación
                        </TableCell>
                    </TableRow>
                </TableHead>
              <TableBody>
                {busData.map( (data, index) => (
                  <TableRow key={ `${data.NAME}-${data.TRANSPORTATION}-${data.DESTINATION}-${index}` } 
                  className={classes.tableRow}
                  >
                    <TableCell className={classes.tableCell}
                    style={{width: '5%'}}
                  >{index + 1}</TableCell>
                  <TableCell className={classes.tableCell}
                    style={{width: '45%'}}
                  >
                    <Grid container spacing={1}>
                      <Grid item>
                      {palaIcon(data.TYPE, data.EQUIPMENT, data.DESTINATION)}
                      </Grid>
                      <Grid item>
                  	    {data.NAME}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    style={{width: '25%'}}
                  >
                  	{data.EQUIPMENT}
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    style={{width: '25%'}}
                  >
                    {not_asigned_oper(data.DESTINATION)}
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        
    </Paper> 
    )
};

const useStyles = makeStyles((theme) => {
    const { palette } = theme;
    const { type } = palette;
    return {
      operatorPaper: {
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: 815,
        paddingTop: 12,
        backgroundColor:
          type === "light"
            ? theme.palette.background.default
            : theme.palette.background.paper,
        marginTop: 12,
        marginBottom: 12,
        [theme.breakpoints.down('md')]: {
          width: 600
        },
        [theme.breakpoints.down(1441)]: {
          minWidth: 312,
          margin: 4,
        },
        "&:last-child": {
          marginLeft: 12,
          marginRight: 0,
        },
      },
      titleGrid: {
        dsplay: "flex",
        flexDirection: "row",
        justifyContent: "left",
        width: '100%',
        paddingBottom: 30,
        paddingTop: 10,
        height: 88,
        paddingLeft: 14
      },
      headerBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        alignItems: "center",
      },
      operatorBadge: {
        marginRight: 7,
        marginBottom: 3,
        height: 15,
        width: 15,
        minWidth: 5,
        backgroundColor: palette.text.primary,
      },
      icon: {
        width: 60,
        height: 60,
        marginRight: 8,
      },
      subTitle: {
        fontWeight: "bold",
        fontSize: '18px'
      },
      chipIcon: {
        borderRadius: '20px',
        backgroundColor: '#FDF0E5',
        paddingBottom: 2,
        paddingTop: 2,
        width: 154,
        paddingLeft: 6,
        paddingRight: 6,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      },
      table: {
        paddingBottom: 0
      },
      tableRow: {
        height: 43,
        maxHeight: 43,
        margin: 0,
        padding: 0,
        borderLeft: `2px solid ${palette.background.default}`,
        borderRight: `2px solid ${palette.background.default}`,
        '&:nth-of-type(even)': {
          backgroundColor: palette.background.default
        }
      },
      tableCell: {
        border: `2px solid ${palette.background.default}`,
        fontSize: '20px',
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
        },
      },
      tableCellEquipment: {
        border: `2px solid ${palette.background.default}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
        },
      },
      tableRowHead: {
        backgroundColor: palette.shiftChange.tableHeaderBackground,
        color: type === 'dark' ? palette.primary.contrastText :
              palette.secondary.contrastText
      },
      tableCellHead: {
        backgroundColor: palette.shiftChange.tableHeaderBackground,
        color: type === 'dark' ? palette.primary.contrastText :
              palette.secondary.contrastText
      }
    }
});