import React, { useContext } from "react";
import { TruckAssignmentKpisContext } from "contexts";
import { KpisPaper } from "./KpisPaper";
import { KpiCard } from ".";

interface PropsTruckAssignmentKpis {
  openMenu?: boolean
}
export const TruckAssignmentKpis: React.FC<PropsTruckAssignmentKpis> = (
  {openMenu = false}
) => {
  const { data, firstLoading } = useContext(TruckAssignmentKpisContext);

  return (
    <KpisPaper loading={firstLoading}>
      {data.map((entry, i) => (
        <KpiCard key={`${entry.type}-${i}`} {...entry} openMenu={openMenu} />
      ))}
    </KpisPaper>
  );
};
