import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  ToggleButtonGroup,
  ToggleButton,
  AutocompleteChangeReason,
  Autocomplete,
} from "@material-ui/lab";
import {
  ErrorMsg,
  PerformanceRatingOperator,
  OperatorPracticeOptions,
  getPracticesTranslation,
  ScreenType,
} from "interfaces";
import { PerformanceRatingActionType, DataViewOption } from "reducers";
import clsx from "clsx";
import { useData } from "hooks";
import { UserContext, PerformanceRatingsContext } from "contexts";
import { ListboxComponent } from "components";
import { useSearchParams } from "react-router-dom";
import { uniqBy } from "lodash";

export const PerformanceRatingOperatorMenu: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    menuState,
    dispatch,
    onViewOptionChange,
    onOperatorScreenPracticeChange,
  } = useContext(PerformanceRatingsContext);

  const { data: operators, firstLoading: loadingOperators } = useData<
    PerformanceRatingOperator[]
  >(
    {
      config: "/performance-ratings/supervisors/operators",
      options: {
        useCache: false,
      },
    },
    ErrorMsg.GET_OPERATORS
  );

  const availableOperators = useMemo<PerformanceRatingOperator[]>(() => {
    const filteredOperators = user?.isOperationalSupervisor
      ? operators.filter((op) => op.assignedToMe)
      : operators;

    return uniqBy(
      [
        ...filteredOperators,
        ...(menuState.selectedOperator ? [menuState.selectedOperator] : []),
      ],
      "id"
    );
  }, [user?.isOperationalSupervisor, operators, menuState.selectedOperator]);

  const onOperatorChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: PerformanceRatingOperator | null,
      _reason: AutocompleteChangeReason
    ) => {
      searchParams.set("operatorId", value?.id ?? "");
      setSearchParams(searchParams);
      dispatch({
        type: PerformanceRatingActionType.OPERATOR_CHANGE,
        selectedOperator: value || null,
      });
    },
    [dispatch, searchParams, setSearchParams]
  );

  useEffect(() => {
    const selectedOperator = operators.find(
      (op) => op.id === searchParams.get("operatorId")
    );
    if (selectedOperator) {
      dispatch({
        type: PerformanceRatingActionType.OPERATOR_CHANGE,
        selectedOperator,
      });
    }
  }, [dispatch, operators, searchParams]);

  return (
    <Grid container className={classes.menuContainer}>
      <Grid className={classes.viewOptionContainer} item xs={12}>
        <ToggleButtonGroup
          value={menuState.viewOption}
          onChange={onViewOptionChange}
          size="small"
          exclusive
        >
          {Object.values(DataViewOption).map((option, x) => (
            <ToggleButton
              key={x}
              classes={{
                root: clsx(classes.toggleButton, classes.toggleButtonView),
                selected: classes.toggleButtonSelected,
              }}
              value={option}
            >
              {option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>
      {availableOperators.length > 0 && (
        <Grid className={classes.operatorContainer} container>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              classes={{
                option: classes.option,
              }}
              value={menuState.selectedOperator ?? availableOperators[0]}
              options={availableOperators}
              onChange={onOperatorChange}
              loading={loadingOperators}
              autoHighlight
              disableClearable
              size="small"
              noOptionsText="No se encontraron operadores"
              ListboxComponent={ListboxComponent}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => <>{option.name}</>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Operador"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      )}
      <Grid container>
        <Divider className={classes.divider} />
      </Grid>
      <FormGroup className={classes.practicesContainer}>
        {(
          Object.keys(OperatorPracticeOptions) as OperatorPracticeOptions[]
        ).map((operPracticesKey) => (
          <FormControlLabel
            key={operPracticesKey}
            control={
              <Checkbox
                className={classes.practicesCheck}
                checked={menuState.operPractices[operPracticesKey]}
                onChange={onOperatorScreenPracticeChange}
                name={operPracticesKey}
                style={{
                  color:
                    theme.palette.performanceRatingsColors[operPracticesKey],
                }}
              />
            }
            label={
              <Typography key={`${operPracticesKey}-label`} variant="body1">
                {getPracticesTranslation(
                  operPracticesKey,
                  ScreenType.OPERATOR,
                  true
                )}
              </Typography>
            }
          />
        ))}
      </FormGroup>
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => {
  const toggleButtonBackground =
    palette.type === "light" ? palette.secondary.main : palette.common.white;

  return {
    menuContainer: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      backgroundColor:
        palette.type === "light"
          ? palette.background.default
          : palette.background.paper,
      maxWidth: 356,
      padding: "24px 29px !important",
      alignItems: "center",
      marginRight: 24,
      borderRadius: 4,
      maxHeight: 540,
      alignContent: "start",
    },
    performanceContent: {
      display: "flex",
    },
    viewOptionContainer: {
      display: "flex",
      margin: "0px 0px 12px 0px",
    },
    toggleButton: {
      padding: "8px 12px",
      "&$toggleButtonSelected": {
        backgroundColor: toggleButtonBackground,
        color: palette.getContrastText(toggleButtonBackground),
        "&:hover": {
          backgroundColor: toggleButtonBackground,
        },
      },
    },
    //!This most go in order to '&$toggleButtonSelected' works
    toggleButtonSelected: {},
    toggleButtonView: {
      width: 150,
    },
    toggleButtonCrew: {
      width: 60,
    },
    toggleCrewContainer: {
      alignItems: "center",
      margin: "12px 0px",
    },
    operatorContainer: {
      width: "100%",
      margin: "12px 0px",
    },
    operatorSelector: {
      height: 40,
    },
    divider: {
      width: "100%",
      margin: "12px 0px",
    },
    practicesContainer: {
      width: "100%",
      margin: "0px",
      marginBottom: "12px",
    },
    practicesCheck: {
      padding: 7,
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
    },
  };
});
