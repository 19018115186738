import React, { useContext, useMemo } from "react";
import { UserContext, PerformanceRatingKpisContext } from "contexts";
import { KpisPaper } from "..";
import { PrKpiCard } from "./PrKpiCard";
import { KpiType, orderHash, PrKpiData } from "interfaces";
import { PrKpiCardSkeleton } from "./PrKpiCardSkeleton";
import { makeStyles, Typography } from "@material-ui/core";

export const PerformanceRatingKpis: React.FC = () => {
  const classes = useStyles();
  const { data, firstLoading } = useContext(PerformanceRatingKpisContext);

  const { shiftInfo } = useContext(UserContext);

  const sortedData = useMemo<PrKpiData[]>(
    () =>
      data
        .filter((d) => !["IMMOB"].includes(d.practice))
        .map((kpi) => kpi)
        .sort((a, b) => orderHash[a.practice] - orderHash[b.practice]),
    [data]
  );

  const title = useMemo(
    () =>
      "Indicadores de hoy" +
      (shiftInfo?.crew ? ` - Turno ${shiftInfo.crew}` : ""),
    [shiftInfo]
  );

  return (
    <KpisPaper
      classes={{ paper: classes.paper }}
      loading={firstLoading}
      skeletons={
        <>
          <Typography>
            <b>{title}</b>
          </Typography>
          <div className={classes.kpiCards}>
            {Array.from(Array(5).keys()).map((i) => (
              <PrKpiCardSkeleton key={`${i}-PrKpiSkeleton`} />
            ))}
          </div>
        </>
      }
    >
      <Typography>
        <b>{title}</b>
      </Typography>
      <div className={classes.kpiCards}>
        {sortedData?.map((kpi, i) => (
          <PrKpiCard
            key={`${kpi}-${i}`}
            iconColored={kpi.practice === KpiType.TOTAL}
            {...kpi}
          />
        ))}
      </div>
    </KpisPaper>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    flexDirection: "column",
    overflow: "auto",
  },
  kpiCards: {
    display: "flex",
    marginTop: 10,
  },
}));
