import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "contexts";
import clsx from "clsx";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const FRONT_HOST_PROD = process.env.REACT_APP_FRONT_HOST_PROD;

export const EnvironmentAlert: React.FC = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const onClose = useCallback(
    (_event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {
      if (reason !== "backdropClick") setOpen(false);
    },
    []
  );

  useEffect(() => {
    if (user) setOpen(ENVIRONMENT !== "PROD");
  }, [user]);

  return (
    <Dialog maxWidth="sm" disableEscapeKeyDown onClose={onClose} open={open}>
      <DialogTitle>Nueva Dirección</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary" variant="body1">
          Esta URL corresponde a un ambiente de desarrollo. El link para el
          ambiente productivo es:{" "}
          <Link className={classes.linkContainer} href={FRONT_HOST_PROD}>
            {FRONT_HOST_PROD}
          </Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {user?.allowedForNpe && (
          <Button className={classes.button} onClick={onClose}>
            <Typography variant="button" color="primary">
              Seguir aquí
            </Typography>
          </Button>
        )}
        <Button
          color="primary"
          className={clsx(classes.button, classes.confimationColor)}
          variant="contained"
          href={FRONT_HOST_PROD}
        >
          <Typography variant="button">Ir a nueva dirección</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const useStyles = makeStyles(({ palette }) => ({
  linkContainer: {
    color: "#0099CC",
  },
  button: {
    minHeight: 36,
  },
  confimationColor: {
    color: palette.common.white,
  },
}));
