import { Chip, Grid, Typography, makeStyles } from "@material-ui/core";
import { Header, ScreenContainer } from "components";
import React, { useContext, useMemo, useState } from "react";
import FilterHdrOutlinedIcon from "@material-ui/icons/FilterHdrOutlined";
import { TruckPerformancetKpis } from "components/kpi/TruckPerformanceKpis";
import { TruckPerformanceKpi } from "./TruckPerformanceKpi";
import { TruckAssigmentCycleTime } from "./TruckAssigmentCycleTime";
import { TruckPerformanceContext } from "contexts/TruckPerformanceContext";
import { TruckPerformancePhasesContext } from "contexts/TruckPerformancePhasesContext";
import { ErrorLoadData } from "components/ErrorLoadData";
import { TruckAssignmentPerformanceStatusContext } from "contexts/TruckAssignmentPerformanceStatusContext";
import { NoDataPerformanceScreen } from "components/NoDataPerformanceScreen";

export const TruckAssigmentPerformanceScreen = () => {
  const classes = useStyles();

  const [datePerformace, setDatePerformace] = useState<string | null>(null);
  const [firstLoading, setFirstLoading] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<number | null>(null);

  const { data: dataTable, firstLoading: loadingCycle } = useContext(
    TruckPerformanceContext
  );
  const { data: dataPhases, firstLoading: loadingPhases } = useContext(
    TruckPerformancePhasesContext
  );
  const {data: dataStatus, firstLoading: loadingStatus} = useContext(
    TruckAssignmentPerformanceStatusContext
  )

  const dataUpdate = useMemo(
    () =>
      !dataTable?.table?.length &&
      !dataTable?.caex?.data?.length &&
      !dataPhases?.data?.length &&
      !dataStatus.status &&
      !loadingStatus &&
      !loadingCycle &&
      !loadingPhases,
    [dataTable, dataPhases, loadingCycle, loadingPhases, dataStatus, loadingStatus]
  );

  if(dataStatus && 
    (dataStatus.status === "NOT_DATA_AVAILABLE_BY_HOUR" ||
    dataStatus.status === "NOT_DATA_AVAILABLE")
  ) {
    return (
      <>
        <Header loading={firstLoading} />
        <ScreenContainer className={classes.screenContainer}>
          <NoDataPerformanceScreen status={dataStatus.status} viewName="Rendimiento"/>
        </ScreenContainer>
      </>
    )
  }

  return (
    <>
      <Header elapsedMinutes={lastUpdate} loading={firstLoading} />
      <ScreenContainer className={classes.screenContainer}>
        <TruckPerformancetKpis />
        {!dataUpdate || firstLoading ? (
          <Grid container>
            <Grid item xs={12} className={classes.sectionTitle}>
              <Typography variant="h6">
                <b>Rendimiento | {datePerformace}</b>
                <Chip label="Tiempo de ciclo" className={classes.chip} />
              </Typography>
            </Grid>
            <TruckPerformanceKpi
              dateReport={setDatePerformace}
              setFirstLoading={setFirstLoading}
              setLastUpdate={setLastUpdate}
            />
            <TruckAssigmentCycleTime />
          </Grid>
        ) : (
          <ErrorLoadData />
        )}
        <div className={classes.footer}>
          <FilterHdrOutlinedIcon className={classes.footerIcon} />
          <Typography noWrap variant="subtitle1">
            Todas las toneladas de la aplicación son secas y en destino.
            Utilización considera exclusivamente camiones UltraClass.
          </Typography>
        </div>
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  footer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "auto",
    paddingTop: 24,
  },
  screenContainer: {
    overflowX: "hidden",
  },
  footerIcon: {
    marginRight: 10,
  },
  sectionTitle: {
    color: palette.text.primary,
    marginTop: "23px",
    marginBottom: "16px",
  },
  chip: {
    background:
      palette.type === "light" ? palette.secondary.main : palette.common.white,
    color:
      palette.type === "light"
        ? palette.common.white
        : palette.background.default,
    fontSize: "14px",
    marginLeft: "24px",
  },
}));
