import React, { useMemo } from "react";
import { addMinutes, format, set } from "date-fns";
import {
  KpiTypeTranslation,
  timeBasedKpis,
  KpiTypeIcon,
  PrKpiData,
  prKpiDetailTranslation,
  PrKpiDetailEnum,
  KpiType,
} from "interfaces";
import {
  Avatar,
  Typography,
  Divider,
  makeStyles,
  Theme,
  alpha,
} from "@material-ui/core";
import { Timer } from "@material-ui/icons";
import { PrKpiCardDetail } from "./PrKpiCardDetail";
import { parseTimeDelta } from "utils";
import clsx from "clsx";

interface Props extends PrKpiData {
  iconColored: Boolean;
}

export const PrKpiCard: React.FC<Props> = ({
  details,
  maxLimitTime,
  excessOverLimit,
  practice,
  iconColored,
}) => {
  const classes = useStyles();
  const title = KpiTypeTranslation[practice];
  const isTimeBased = useMemo(() => timeBasedKpis.has(practice), [practice]);
  const KpiIcon = KpiTypeIcon[practice];

  const sortedKeys = useMemo<PrKpiDetailEnum[]>(() => {
    const orderHash: Record<PrKpiDetailEnum, number> = Object.assign(
      {},
      ...Object.values(PrKpiDetailEnum).map((key, idx) => ({ [key]: idx }))
    );
    return (Object.keys(details) as PrKpiDetailEnum[]).sort(
      (a, b) => orderHash[a] - orderHash[b]
    );
  }, [details]);

  const subtitle = useMemo(() => {
    if (maxLimitTime !== 0 && !maxLimitTime) return "";
    if (practice === KpiType.LOST_TRUCK) {
      const dailyShiftStartDate = set(new Date(), { hours: 8, minutes: 0 });
      const parsedDailyTime = format(
        addMinutes(dailyShiftStartDate, maxLimitTime),
        "HH:mm"
      );
      const nightlyShiftStartDate = set(new Date(), { hours: 20, minutes: 0 });
      const parsedNightlyTime = format(
        addMinutes(nightlyShiftStartDate, maxLimitTime),
        "HH:mm"
      );
      return `> ${parsedDailyTime} / ${parsedNightlyTime}`;
    }
    return `> ${maxLimitTime} min`;
  }, [maxLimitTime, practice]);

  return (
    <>
      <div className={classes.kpiRoot}>
        <div className={classes.firstSeccion}>
          <Avatar
            className={clsx(classes.avatar, {
              [classes.colored]: iconColored,
            })}
          >
            {KpiIcon ? <KpiIcon className={classes.icon} /> : ""}
          </Avatar>
          <div className={classes.kpiDetails}>
            <Typography>
              {isTimeBased && <Timer className={classes.miniIcon} />}
              {title}
            </Typography>
            <div className={classes.kpiInfoRoot}>
              {subtitle && (
                <Typography>
                  <b>{subtitle}</b>
                </Typography>
              )}
            </div>
          </div>
        </div>
        <div className={classes.secondSeccion}>
          {sortedKeys.map((kpi) => (
            <PrKpiCardDetail
              key={kpi}
              kpi={kpi}
              practice={practice}
              headerTitle={prKpiDetailTranslation[kpi as PrKpiDetailEnum]}
              value={
                kpi === PrKpiDetailEnum.ACC_TIME
                  ? parseTimeDelta(details[kpi])
                  : details[kpi]
              }
              excessOverLimit={
                kpi === PrKpiDetailEnum.AVG_TIME && details[kpi] > 0
                  ? excessOverLimit
                  : undefined
              }
              colored={practice === KpiType.TOTAL}
            />
          ))}
        </div>
      </div>
      <Divider
        className={classes.divider}
        orientation="vertical"
        variant="middle"
        flexItem
        light
      />
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return {
    kpiRoot: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxWidth: 300,
      minWidth: 250,
      [theme.breakpoints.between("md", "lg")]: {
        maxWidth: 250,
        minWidth: 230,
      },
    },
    firstSeccion: {
      display: "flex",
      marginRight: "auto",
      marginBottom: 8,
    },
    secondSeccion: {
      display: "flex",
      gap: "8px",
      [theme.breakpoints.between("md", "lg")]: {
        width: 55,
        height: 55,
        gap: "4px",
      },
    },
    maxLimitTime: {
      marginRight: 3,
    },
    secondaryValue: {
      marginRight: 8,
    },
    kpiDetails: {
      display: "flex",
      flexDirection: "column",
      minWidth: 130,
      width: "100%",
    },
    projectionPaper: {
      width: "100%",
      marginRight: 8,
      padding: 4,
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.background.paper
          : theme.palette.background.default,
    },
    projectionValues: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    avatar: {
      width: 68,
      height: 68,
      backgroundColor: theme.palette.kpi.background,
      marginRight: 16,
      [theme.breakpoints.down("lg")]: {
        width: 58,
        height: 58,
        marginRight: 10,
      },
      [theme.breakpoints.between("md", "lg")]: {
        width: 40,
        height: 40,
        marginRight: 8,
      },
    },
    colored: {
      backgroundColor: alpha(theme.palette.error.dark, 0.1),
    },
    icon: {
      width: "auto",
      height: "auto",
      [theme.breakpoints.between("md", "lg")]: {
        width: 30,
        height: 30,
      },
    },
    miniIcon: {
      width: 15,
      height: 15,
      verticalAlign: "middle",
      marginRight: 5,
    },
    tooltip: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
    kpiInfoRoot: {
      display: "flex",
      alignItems: "center",
    },
    projection: {
      // color: ({ status }) => getProjectionColor(theme, status),
      paddingLeft: 5,
    },
    projectionValue: {
      fontWeight: 500,
    },
    divider: {
      "&:last-child": {
        display: "none",
      },
    },
  };
});
