export interface TruckPerformanceState {
  period: string;
  phase: string;
};

export enum TruckPerformanceActionType {
  PHASE_CHANGE = "PHASE_CHANGE",
  PERIOD_CHANGE = "PERIOD_CHANGE",
};

export enum FiltersPerfomance {
	HOURS = "HOURS",
	SHIFT = "SHIFT"
};

export const initialPerformanceState: TruckPerformanceState = {
  phase: "",
  period: FiltersPerfomance.SHIFT,
};

export type TruckPerformaceAction =
  | {
      type: TruckPerformanceActionType.PHASE_CHANGE;
      phase: string;
    }
  | {
      type: TruckPerformanceActionType.PERIOD_CHANGE;
      period: string;
    };

export const truckPerformaceReducer = (
  state: TruckPerformanceState,
  action: TruckPerformaceAction
): TruckPerformanceState => {
  switch (action.type) {
    case TruckPerformanceActionType.PERIOD_CHANGE: {
      return {
        ...state,
        period: action.period,
      };
    }
		case TruckPerformanceActionType.PHASE_CHANGE: {
      return {
        ...state,
        phase: action.phase,
      };
    }
    default:
      return state;
  }
};
