import React, { useCallback, useContext, useMemo, useState } from "react";
import { AppParam, AppParamsType } from "interfaces";
import {
  TextField,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { AutoComplete, Entity } from "components";
import { AppParamsPendingChanges, useAppParams } from "hooks";
import { isEqual, sortBy } from "lodash";
import { AppParamsContext } from "contexts";

interface Props {
  data: AppParam;
  disabled: boolean;
}

export const AppParamsTableInput: React.FC<Props> = ({ data, disabled }) => {
  const classes = useStyles();
  const { appParams } = useContext(AppParamsContext);
  const { setPendingChanges, removePendingChanges } = useAppParams();

  const [inputsState, setInputsState] = useState<string>();
  const [autoCompleteState, setAutoCompleteState] = useState<
    Record<number, string[]>
  >({});

  const updatePendingParams = useCallback(
    (paramId: AppParam["id"], newValue: AppParamsPendingChanges) => {
      const oldValue = appParams?.find(({ id }) => id === paramId);

      if (hasParamChanged(newValue[paramId], oldValue)) {
        setPendingChanges(newValue);
        return;
      }
      removePendingChanges(paramId);
    },
    [appParams, removePendingChanges, setPendingChanges]
  );

  const onAutoCompleteChange = useCallback(
    (id: AppParam["id"], value: string[]) => {
      const newValue = {
        [id]: value,
      };
      setAutoCompleteState((prev) => ({ ...prev, ...newValue }));
      updatePendingParams(id, newValue);
    },
    [updatePendingParams]
  );

  const onInputChange = useCallback(
    (paramId: AppParam["id"], value: string) => {
      setInputsState(value);
      updatePendingParams(paramId, {
        [paramId]: value,
      });
    },
    [updatePendingParams]
  );

  const values: Entity[] = useMemo(() => {
    if (data.type === AppParamsType.STRING_ARRAY) {
      const values = autoCompleteState[data.id] ?? data.value;
      return values.map((v) => ({ id: v, name: v }));
    }
    return [];
  }, [autoCompleteState, data.id, data.type, data.value]);

  const options: Entity[] = useMemo(() => {
    if (data.type === AppParamsType.STRING_ARRAY) {
      const options = data.options.map((o) => ({
        id: o,
        name: o,
      }));
      return sortBy(
        options,
        (o) => !values.find(({ id }) => id === o.id),
        "name"
      );
    }
    return [];
  }, [data, values]);

  if (data.type === AppParamsType.STRING_ARRAY) {
    return (
      <AutoComplete
        classes={{
          root: classes.autoComplete,
        }}
        options={options}
        value={values}
        onChange={(_event: React.ChangeEvent<{}>, value: Entity[]) => {
          onAutoCompleteChange(
            data.id,
            value.map((v) => v.name ?? "")
          );
        }}
        disabled={disabled}
        fuzzySearch
        withAccumulatedTags
        size="small"
      />
    );
  }

  return disabled ? (
    <Typography>{`${data.value} ${data.unit}`}</Typography>
  ) : (
    <TextField
      classes={{ root: classes.textRoot }}
      variant="outlined"
      error={inputsState === ""}
      value={inputsState ?? data.value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{data.unit}</InputAdornment>
        ),
        labelWidth: 0,
        classes: { root: classes.textInputRoot, input: classes.input },
      }}
      type="number"
      onChange={(e) => onInputChange(data.id, e.target.value)}
      helperText={inputsState === "" ? "No puede estar vacío" : ""}
    />
  );
};

const hasParamChanged = (
  newValue: string | string[],
  oldVersion?: AppParam
) => {
  if (oldVersion?.type === AppParamsType.STRING_ARRAY) {
    return !isEqual(
      oldVersion.value.sort(),
      (newValue as Array<string>).sort()
    );
  }
  return oldVersion?.value !== +newValue;
};

const useStyles = makeStyles(() => ({
  textRoot: {
    width: 300,
    margin: "auto",
  },
  textInputRoot: {
    height: 40,
  },
  input: {
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    height: 36,
  },
  autoComplete: {
    width: 300,
    margin: "auto",
  },
}));
