import React from "react";
import {
  alpha,
  Divider,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import { VisibilityRounded } from "@material-ui/icons";
import { EditDocumentIcon } from "components";
import { moduleTranslation, Can, User, Module } from "interfaces";
import { UserAction, UserActionType } from "reducers";

interface UserEditPermissionSwitchsProps {
  userEditableInfo: User;
  dispatch: React.Dispatch<UserAction>;
}

export const UserEditPermissionSwitches: React.FC<
  UserEditPermissionSwitchsProps
> = ({ userEditableInfo, dispatch }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridItemSubtitle} item xs={6}>
      <Grid className={classes.gridPermissionsSwitchesContainer} item xs={12}>
        <Grid className={classes.gridItemPermisssionsTitle} item xs={8}>
          <Typography variant="body1">
            <strong>Permisos</strong>
          </Typography>
        </Grid>
        <Grid className={classes.gridItemPermisssionsTitleIcon} item xs={2}>
          <VisibilityRounded color="secondary" />
        </Grid>
        <Grid className={classes.gridItemPermisssionsTitleIcon} item xs={2}>
          <EditDocumentIcon color="secondary" />
        </Grid>
      </Grid>
      {Object.values(Module).map((moduleValue) => (
        <React.Fragment key={moduleTranslation[moduleValue]}>
          <Grid
            className={classes.gridPermissionsSwitchesContainer}
            item
            xs={12}
          >
            <Grid item xs={8}>
              <Typography variant="body1" className={classes.moduleSwitch}>
                {moduleTranslation[moduleValue]}
              </Typography>
            </Grid>
            <Grid className={classes.gridItemInputs} item xs={2}>
              <Switch
                color="primary"
                checked={userEditableInfo.permissions[moduleValue].CAN_READ}
                onChange={(e) =>
                  dispatch({
                    type: UserActionType.PERMISSION_CHANGE,
                    module: moduleValue,
                    can: Can.READ,
                    value: e.target.checked,
                  })
                }
              />
            </Grid>
            <Grid className={classes.gridItemInputs} item xs={2}>
              <Switch
                color="primary"
                checked={userEditableInfo.permissions[moduleValue].CAN_WRITE}
                onChange={(e) =>
                  dispatch({
                    type: UserActionType.PERMISSION_CHANGE,
                    module: moduleValue,
                    can: Can.WRITE,
                    value: e.target.checked,
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid className={classes.gridItem} item xs={12}>
            <Divider />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  gridPermissionsSwitchesContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    height: 28,
  },
  gridItemPermisssionsTitle: {
    paddingTop: "0px !important",
  },
  gridItemPermisssionsTitleIcon: {
    paddingLeft: 10,
    display: "flex",
    justifyContent: "center",
    paddingTop: "0px !important",
  },
  gridItemSubtitle: {
    marginBottom: 8,
    paddingBottom: "0px !important",
  },
  gridItem: {
    marginBottom: 7,
  },
  gridItemInputs: {
    paddingTop: "0px !important",
  },
  moduleSwitch: {
    color: alpha(palette.text.primary, 0.8),
  },
}));
