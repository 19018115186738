import React, { createContext, useMemo } from "react";
import { ContextProviderProps } from "contexts";
import { AppParam, ErrorMsg } from "interfaces";
import { RefetchFunctionType, useData } from "hooks";

interface AppParamsStateInterface {
  appParams: AppParam[] | null;
  loadingAppParams: boolean;
  refetchAppParams: RefetchFunctionType<AppParam[] | null, unknown>;
}

export const AppParamsContext = createContext<AppParamsStateInterface>({
  appParams: null,
  loadingAppParams: false,
  refetchAppParams: () => new Promise(() => []),
});

export const AppParamsProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const {
    data: appParams,
    firstLoading: firstLoadingAppParams,
    refetching: refetchingAppParams,
    refetch: refetchAppParams,
  } = useData<AppParam[]>(
    {
      config: {
        url: "/administration/params",
        method: "GET",
      },
      options: {
        useCache: false,
      },
    },
    ErrorMsg.GET_APP_PARAMS
  );

  const loadingAppParams = firstLoadingAppParams || refetchingAppParams;

  const appParamsState = useMemo<AppParamsStateInterface>(
    () => ({
      appParams,
      loadingAppParams,
      refetchAppParams,
    }),
    [appParams, loadingAppParams, refetchAppParams]
  );

  return (
    <AppParamsContext.Provider value={appParamsState}>
      {children}
    </AppParamsContext.Provider>
  );
};
