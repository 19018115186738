import {
  DetailsPhase,
  KpiTypePerformance,
  ProjectionStatus,
} from "./kpi.interface";
import { BarDatum } from "@nivo/bar";

export enum Level {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  DISABLED = "DISABLED"
}

interface Item {
  variableName: string;
  value: string | number | null;
}

export interface Tooltip {
  title: string;
  subtitle: string;
  items: Item[];
}

interface Congestion {
  title: string;
  level: Level;
  tooltip?: Tooltip;
}

interface MainValue {
  value: string | number | null;
}

interface Projection {
  diff: number | null;
  isBelowPlan: boolean;
  alertType: ProjectionStatus;
}

export interface DetailsCard {
  type: KpiTypePerformance;
  mainValue?: MainValue;
  projection?: Projection;
  chipValue?: Congestion;
  priorityLabel?: Level;
}

export interface DetailsCaex {
  id: string;
  columns: DetailsPhase[];
}

export interface RoutesData {
  graph: BarDatum[];
  congestion: { full: Congestion[]; empty: Congestion[] };
  cards: DetailsCard[];
  caexFast: DetailsCaex[];
  caexSlow: DetailsCaex[];
  caexRoute: string[];
}

export enum KpiTypeVelocity {
  OPERATOR = "OPERATOR",
  CONGESTION = "CONGESTION",
  EMPTY_VELOCITY = "EMPTY_VELOCITY",
  FULL_VELOCITY = "FULL_VELOCITY",
}
export const KpiTypeTranslation: Record<KpiTypeVelocity, string> = {
  [KpiTypeVelocity.OPERATOR]: "Operador",
  [KpiTypeVelocity.CONGESTION]: "Tramo | Congestión",
  [KpiTypeVelocity.EMPTY_VELOCITY]: "Velocidad vacío [km/h]",
  [KpiTypeVelocity.FULL_VELOCITY]: "Velocidad lleno [km/h]",
};

// export interface CaexVelocity {
//   hour: string;
//   section: string;
//   operator: string;
//   fullVelocity: string;
//   emptyVelocity: string;
//   congestion: string;
// }

export interface CaexVelocity {
  hour: string;
  section: string;
  operator: string;
  fullVelocity: {main: MainValue, projection: Projection};
  emptyVelocity: {main: MainValue, projection: Projection};
  congestion: Level;
}