import React from "react";
import { Theme, makeStyles } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { StarBorder } from "@material-ui/icons";
import { BaseElement } from "components/List";

interface Props {
  value: number;
  id: string;
  onChange: (e: React.ChangeEvent<{}>, value: number | null) => void;
  readOnly: boolean;
  spacing: string[];
}

export const BalanceTableRatingCell: React.FC<Props> = ({
  value,
  id,
  onChange,
  readOnly,
  spacing,
}) => {
  const classes = useStyles({ readOnly });
  return (
    <BaseElement classNames={spacing}>
      <Rating
        name={id}
        max={3}
        precision={readOnly ? 0.5 : 1}
        value={value}
        onChange={onChange}
        emptyIcon={<StarBorder fontSize="inherit" />}
        readOnly={readOnly}
        classes={{ iconFilled: classes.iconFilled }}
      />
    </BaseElement>
  );
};

interface UseStylesProps {
  readOnly?: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>(({ palette }) => ({
  iconFilled: {
    color: ({ readOnly }) => (readOnly ? "#BDBDBD" : palette.primary.main),
  },
}));
