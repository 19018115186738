import { RefetchFunctionType } from "hooks";
import { Destination, ExcavatorBalance } from "./excavator.interface";

type ShiftStartDate = string;

interface PlanShifts {
  shiftStartDate: ShiftStartDate;
  shiftName: string;
}

export interface DailyPlanAPI {
  dailyShifts: PlanShifts[];
  currentShift: string;
  activeShiftPlan: DailyPlanData;
  lastShiftPlanUpdate: Date | null;
}

export type RefetchDailyPlanFunctionType = RefetchFunctionType<DailyPlanAPI>;

export const destinationMapping: Record<Destination, string> = {
  [Destination.SULFIDE_CRUSHER]: "Ch. Súlfuro",
  [Destination.OXIDE_CRUSHER]: "Ch. Óxido",
  [Destination.STACK]: "Pilas",
  [Destination.DUMP]: "Lastre",
  [Destination.STOCK]: "Stock",
  [Destination.ASSIGNED_TRUCKS]: "CAEX",
};

export type DailyPlanInputs = {
  [key in Destination]?: number;
};

export interface DailyPlanAPIAttributes extends DailyPlanInputs {
  phase: string;
  excav: string;
}

export interface DailyPlanTableAttributes extends DailyPlanAPIAttributes {
  total?: number;
}

export interface DailyPlanInputsAPI {
  excav: string;
  targetType: keyof DailyPlanInputs;
  value: number | null;
}

export type ShiftPlanData = Record<
  ExcavatorBalance["id"],
  DailyPlanTableAttributes
>;

export type DailyPlanData = Record<ShiftStartDate, ShiftPlanData>;

export type PendingInputsChanges = Record<
  ExcavatorBalance["id"],
  DailyPlanInputsAPI
>;

export type PendingDailyPlanChanges = Record<
  ShiftStartDate,
  PendingInputsChanges
>;

export type DailyPlanChangesAPI = Record<ShiftStartDate, DailyPlanInputsAPI[]>;

export type DailyPlanRowsTotals = Record<
  ExcavatorBalance["id"],
  {
    [key in Destination]: number;
  } & {
    total: number;
  }
>;

export interface DailyPlanEntry {
  turno: string;
  pala: string;
  destino: string;
  tonelaje: string;
  CAEX: string;
}

export interface FormattedDailyPlanEntry {
  shift: string;
  excavator: string;
  destiny: Destination;
  tons: number;
  assignedTrucks: number;
}
