import React from "react";
import { makeStyles, Theme, Box, Paper } from "@material-ui/core";
import { CardAlertType } from "interfaces";
import { TruckCardSkeleton } from ".";
import { Skeleton } from "@material-ui/lab";

interface TruckGroupSkeletonProps {
  children?: React.ReactNode;
}

export const TruckGroupSkeleton: React.FC<TruckGroupSkeletonProps> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.childrenBox}>
      <Paper elevation={0} className={classes.truckColumnRoot}>
        <Box className={classes.box}>
          <Skeleton className={classes.groupSkeletonText} variant="text" />
          <Skeleton variant="circle" width={30} height={30} />
        </Box>
        <TruckCardSkeleton alertType={CardAlertType.DEFAULT} />
        <TruckCardSkeleton alertType={CardAlertType.DEFAULT} />
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  truckGroupRoot: {
    width: "100%",
  },
  childrenBox: {
    display: "flex",
    width: "100%",
  },
  truckColumnRoot: {
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 391,
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.background.default
        : theme.palette.background.paper,
    margin: 12,
    [theme.breakpoints.down(1441)]: {
      minWidth: 312,
      margin: 4,
    },
  },
  box: {
    margin: "10px 17px 20px 17px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(1441)]: {
      margin: "10px 10px",
    },
  },
  groupSkeletonText: {
    width: "calc(100% - 100px)",
  },
}));
