import React, { useCallback, useMemo } from "react";
import { makeStyles, Typography, useTheme } from "@material-ui/core";
import {
  metricUnit,
  metricTranslation,
  MetricDataWithEvents,
  ChangeType,
  ChangeEventData,
} from "interfaces";
import {
  DatumValue,
  PointSymbolProps,
  ResponsiveLine,
  Serie,
} from "@nivo/line";
import { LineGraphTooltip } from "./LineGraphTooltip";
import { graphXValues } from "utils/assignmentMetrics";
import { TruckAssignmentMetricsState } from "reducers";
import { localizeNumber } from "utils";
import {
  CustomTriangleSymbol,
  CustomSquareSymbol,
  CustomComboSquareTriangleSymbol,
} from "components/icons";
import { CurrentSlicePointsLayer } from "./CurrentSlicePointsLayer";
import { groupBy } from "lodash";

interface MetricsLineGraphProps {
  data: MetricDataWithEvents[];
  menuState: TruckAssignmentMetricsState;
  unassociatedChanges: ChangeEventData[];
}

export const TruckAssignmentMetricsLineGraph: React.FC<
  MetricsLineGraphProps
> = ({ data, menuState: state, unassociatedChanges }) => {
  const classes = useStyles();
  const theme = useTheme();

  const graphData: Serie[] = useMemo(
    () => [
      {
        id: metricTranslation[state.selectedMetric],
        data: data.map(({ issueDate, value, changeEvents }) => ({
          x: new Date(issueDate),
          y: value,
          changeEvents,
        })),
      },
      {
        id: "CHANGES",
        data: Object.entries(groupBy(unassociatedChanges, "issueDate")).map(
          ([issueDate, changeEvents]) => ({
            x: new Date(issueDate),
            y: 0,
            changeEvents,
          })
        ),
      },
    ],
    [data, state.selectedMetric, unassociatedChanges]
  );

  const formatY = useCallback(
    (value: DatumValue) => localizeNumber(Number(value), 4),
    []
  );

  const graphX = useMemo(() => graphXValues(), []);

  const maxValue = Math.max(...data.map((metric) => metric.value));

  const renderPointSymbol = useCallback((args: PointSymbolProps) => {
    // We know that every points comes with change events
    const changeEvents: MetricDataWithEvents["changeEvents"] =
      args.datum.changeEvents;

    // Do not show any icon when there are no changes
    if (!changeEvents.length) return null;

    const thereAreModularChanges = changeEvents.some(
      ({ type }) => type === ChangeType.MODULAR
    );

    const thereAreToolChanges = changeEvents.some(
      ({ type }) => type === ChangeType.TOOL
    );

    if (thereAreModularChanges && thereAreToolChanges)
      return <CustomComboSquareTriangleSymbol {...args} />;

    if (thereAreModularChanges) return <CustomTriangleSymbol {...args} />;
    if (thereAreToolChanges) return <CustomSquareSymbol {...args} />;
    return null;
  }, []);

  return (
    <>
      <div className={classes.graphAxisTitle}>
        <Typography className={classes.sectionTitle} variant="h6">
          <strong>{metricTranslation[state.selectedMetric]}</strong>
        </Typography>
        <Typography className={classes.sectionSubtitle} variant="h6">
          <strong>
            {"[" + (metricUnit[state.selectedMetric] || "") + "]"}
          </strong>
        </Typography>
      </div>
      <div className={classes.graphContainer}>
        <ResponsiveLine
          theme={theme.graph}
          colors={[theme.palette.graphSeries[0], "transparent"]}
          data={graphData}
          margin={{ top: 25, bottom: 55, right: 25, left: 55 }}
          xScale={{
            type: "time",
            format: "%H:%M",
            // useUTC: true,
            // precision: "hour",
            min: graphX[0],
            max: graphX[graphX.length - 1],
            // nice: false,
          }}
          // xFormat="%H:%M"
          yScale={{ type: "linear", max: maxValue * 1.25, stacked: false }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickValues: graphX,
            tickPadding: 15,
            tickRotation: 0,
            format: "%H:%M",
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 6,
            tickRotation: 0,
            tickValues: 10,
            format: formatY,
          }}
          pointSymbol={renderPointSymbol}
          pointSize={1}
          pointColor={{ from: "color" }}
          enableArea={true}
          enableSlices={"x"}
          enableGridY={false}
          enableGridX={true}
          animate={false}
          sliceTooltip={(data) => (
            <LineGraphTooltip
              {...data}
              groupType={state.groupType}
              measurement={metricUnit[state.selectedMetric]}
            />
          )}
          layers={[
            "grid",
            "markers",
            "areas",
            "lines",
            "axes",
            "crosshair",
            "points",
            "legends",
            CurrentSlicePointsLayer,
            "slices",
          ]}
        />
      </div>
    </>
  );
};
const useStyles = makeStyles(() => ({
  graphAxisTitle: {
    width: 150,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "25px"
  },
  sectionTitle: {
    fontSize: "16px",
    lineHeight: "28px",
    paddingRight: "20px",
  },
  sectionSubtitle: {
    fontSize: "16px",
    lineHeight: "28px",
    paddingRight: "20px",
  },
  graphContainer: {
    height: 406,
  },
}));
