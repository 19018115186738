import React, { useContext, useState } from "react";
import { Header, ScreenContainer, TruckAssignmentKpis } from "components";
import { DailyPlanContext } from "contexts";
import { TruckAssignmentDailyPlan } from "./TruckAssignmentDailyPlan";

export const TruckAssignmentDailyPlanScreen: React.FC = () => {
  //--------------------Disabled edit view -------------------------
  const canEdit = false;

  const { lastShiftPlanUpdateMinutes, loading } = useContext(DailyPlanContext);
  //is open Menu
  const [stateMenu, setStateMenu] = useState<boolean | undefined>(false)
  const handleUpdateState = (openMenu: boolean) => {
    setStateMenu(openMenu)
  }

  return (
    <>
      <Header 
        elapsedMinutes={lastShiftPlanUpdateMinutes} 
        loading={loading} 
        onUpdateStateMenu={handleUpdateState}/>
      <ScreenContainer>
        <TruckAssignmentKpis openMenu={stateMenu}/>
        <TruckAssignmentDailyPlan canEdit={canEdit} />
      </ScreenContainer>
    </>
  );
};
