import React, { useCallback } from "react";
import { TextField, alpha, makeStyles } from "@material-ui/core";
import {
  Destination,
  DailyPlanInputsAPI,
  DailyPlanTableAttributes,
} from "interfaces";

export interface Props {
  datum: DailyPlanTableAttributes;
  attribute: Destination;
  onChange: (data: DailyPlanInputsAPI) => void;
  disabled: boolean;
}

export const DailyPlanTableInputCell: React.FC<Props> = ({
  datum,
  attribute,
  onChange,
  disabled,
}) => {
  // Hooks:
  const classes = useStyles();
  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      onChange({
        excav: datum.excav,
        targetType: attribute,
        value: value === "" ? null : +value,
      });
    },
    [onChange, attribute, datum.excav]
  );

  return (
    <TextField
      value={datum[attribute] ?? ""}
      type="number"
      placeholder={"-"}
      onChange={onInputChange}
      variant="outlined"
      size="small"
      color="primary"
      disabled={disabled}
      InputProps={{
        classes: { input: classes.input, disabled: classes.disabled },
        inputProps: { min: 0, step: "any" },
      }}
      onWheel={(event) => event.currentTarget.querySelector("input")?.blur()}
    />
  );
};

const useStyles = makeStyles(({ palette }) => {
  return {
    input: {
      textAlign: "center",
      "&[type=number]": {
        "-moz-appearance": "textfield",
      },
      "&[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    disabled: {
      color: palette.text.primary,
      background: alpha(palette.action.disabled, 0.02)
    },
  };
});
