import {
  TableCell,
  TableCellProps,
  makeStyles,
  Theme,
  TextField,
} from "@material-ui/core";
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import { SelectorOptions, ListboxComponent } from "components";
import React from "react";

interface Props {
  value?: SelectorOptions;
  maxWidth: UseStylesProps["maxWidth"];
  align: TableCellProps["align"];
  onChange: (
    _event: React.ChangeEvent<{}>,
    value: SelectorOptions | null,
    _reason: AutocompleteChangeReason
  ) => void;
  options: SelectorOptions[];
}

export const UnplannedParkedTruckSelectorCell: React.FC<Props> = ({
  value,
  maxWidth,
  align,
  options,
  onChange,
}) => {
  const classes = useStyles({ maxWidth });
  return (
    <TableCell
      className={classes.cell}
      component="td"
      align={align}
      scope="row"
    >
      <Autocomplete
        classes={{
          option: classes.option,
        }}
        value={value}
        options={options}
        onChange={onChange}
        loading={false}
        autoHighlight
        size="small"
        noOptionsText="No se encontraron locaciones"
        ListboxComponent={ListboxComponent}
        getOptionLabel={(option) => option.name}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </TableCell>
  );
};

interface UseStylesProps {
  maxWidth?: number;
}

const useStyles = makeStyles<Theme, UseStylesProps>(() => ({
  cell: {
    maxWidth: ({ maxWidth }) => (maxWidth ? maxWidth : "unset"),
    paddingTop: 5,
    paddingBottom: 5,
  },
  selector: {
    width: "inherit",
  },
  selectorRoot: {
    width: "100%",
    maxWidth: 270,
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));
