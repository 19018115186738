import React from "react";
import { SvgIconProps } from "@material-ui/core";
import { TrendingDown, TrendingFlat, TrendingUp } from "@material-ui/icons";

export enum Trend {
  UP = "UP",
  DOWN = "DOWN",
  SAME = "SAME",
}

export enum Group {
  PHASE = "phase",
  VIRTUAL_REGION = "virtual_region",
}

export enum Destination {
  SULFIDE_CRUSHER = "sulfideCrusher",
  OXIDE_CRUSHER = "oxideCrusher",
  STACK = "stack",
  DUMP = "dump",
  STOCK = "stock",
  ASSIGNED_TRUCKS = "assignedTrucks",
}

export const GroupTranslation: Record<Group, string> = {
  [Group.PHASE]: "Fase",
  [Group.VIRTUAL_REGION]: "R. Virtual",
};

export enum Pit {
  ESCONDIDA = "MEL",
  NORTE = "ENORTE",
}

export const PitTranslation: Record<Pit, string> = {
  [Pit.ESCONDIDA]: "ESC",
  [Pit.NORTE]: "ESC N",
};

export const TrendIconMapping: Record<Trend, React.FC<SvgIconProps>> = {
  [Trend.UP]: TrendingUp,
  [Trend.DOWN]: TrendingDown,
  [Trend.SAME]: TrendingFlat,
};

export interface WaitData {
  value: number | null;
  prevValue: number | null;
  accValue: number | null;
  trend: Trend | null;
}

export interface ExcavatorEditableData {
  excav: string;
  inclusion: boolean;
  priority: number;
}

export enum BalanceWarning {
  WARNING_C1 = "WARNING_C1",
  WARNING_C2 = "WARNING_C2",
  WARNING_C3 = "WARNING_C3",
  WARNING_C4 = "WARNING_C4",
  WARNING_C5 = "WARNING_C5",
  WARNING_C6 = "WARNING_C6",
  WARNING_C7 = "WARNING_C7",
  WARNING_C8 = "WARNING_C8",
  WARNING_C9 = "WARNING_C9",
  WARNING_C10 = "WARNING_C10",
}

export const BalanceWarningMapping: Record<BalanceWarning, string> = {
  [BalanceWarning.WARNING_C1]: "",
  [BalanceWarning.WARNING_C2]: "",
  [BalanceWarning.WARNING_C3]: "",
  [BalanceWarning.WARNING_C4]:
    "Ninguna pala fue incluída en los cálculos de asignación. Para un correcto funcionamiento seleccione al menos dos palas",
  [BalanceWarning.WARNING_C5]:
    "Solo una pala fue considerada en los cálculos de asignación. Para un correcto funcionamiento seleccione al menos dos palas",
  [BalanceWarning.WARNING_C6]:
    "Las palas seleccionadas no tienen CAEX asignados",
  [BalanceWarning.WARNING_C7]: "",
  [BalanceWarning.WARNING_C8]: "",
  [BalanceWarning.WARNING_C9]: "",
  [BalanceWarning.WARNING_C10]: "",
};

export enum AssignationSource {
  MAGNET = "MAGNET",
  LOADS = "LOADS",
  METRICS = "METRICS",
}

interface SourceUpdate {
  lastUpdate: Date | null;
  source: AssignationSource;
}

export enum TAParam {
  TA_WARNING_DELTA_CAEX = "TA_WARNING_DELTA_CAEX",
  TA_ALERT_DELTA_CAEX = "TA_ALERT_DELTA_CAEX",
  TA_LAST_MANAGEMENT_WARNING_ELAPSED_MINUTES = "TA_LAST_MANAGEMENT_WARNING_ELAPSED_MINUTES",
}

export interface BalanceData {
  table: AggregatedExcavator[];
  warnings: Record<string, BalanceWarning[]>;
  updateTimes: SourceUpdate[];
  params: Record<TAParam, number>;
}

export interface Excavator {
  id: number;
  name: string;
}

export enum OtherClient {
  PENDING = "pending",
  REHANDLE = "rehandle",
}

export type Client = Destination | OtherClient;

type ClientMovements = {
  [key in Client]: MovementData;
};

export const clientMapping: Record<Client, string> = {
  [Destination.SULFIDE_CRUSHER]: "Ch. Súlfuro",
  [Destination.OXIDE_CRUSHER]: "Ch. Óxido",
  [Destination.STACK]: "Pilas",
  [Destination.DUMP]: "Lastre",
  [Destination.STOCK]: "Stock",
  [Destination.ASSIGNED_TRUCKS]: "CAEX",
  [OtherClient.PENDING]: "Pendiente",
  [OtherClient.REHANDLE]: "Remanejo",
};

export const sicRegistrySelectableClients = Object.fromEntries(
  Object.entries(clientMapping).filter(
    ([key, _]) =>
      ![OtherClient.PENDING, Destination.ASSIGNED_TRUCKS].includes(
        key as Client
      )
  )
);

export const clientOrder = Object.fromEntries(
  Object.keys(clientMapping).map((client, idx) => [client, idx])
);

type MovementData = {
  loadtons: number;
  target: number;
};

export enum LoadStatus {
  UP = "UP",
  DOWN = "DOWN",
}

export interface ExcavatorBalance {
  id: string;
  priority: number | null;
  inclusion: boolean | null;
  phase: string;
  virtualRegion: string;
  pit: Pit;
  reasonId: number;
  lastModularChangeAt: string | null;
  isOffline: boolean;
  assignedTrucks: number | null;
  plannedTrucks: number | null;
  fallShort: number | null;
  leftOver: number | null;
  originQueue: WaitData;
  originHang: WaitData;
  isCa: boolean;
  indeterminate?: boolean;
  accLoadtons: number | null;
  accLoadtonsHour: number | null;
  accLoadtonsStatus: LoadStatus | null;
  plannedLoadtons: number | null;
  projectedLoadtonsStatus: LoadStatus | null;
  projectedLoadtons: number | null;
  movementPerClient: ClientMovements | null;
}

// This field is only used on the CollapsibleRow
export interface AggregatedExcavator extends ExcavatorBalance {
  excavs: ExcavatorBalance[];
}
