import React from "react";
import { Chip, makeStyles } from "@material-ui/core";

interface StatusChipProps {
  active: boolean;
}

interface StatusChipStyleProps {
  active: boolean;
}

export const StatusChip: React.FC<StatusChipProps> = ({ active }) => {
  const classes = useStyles({ active });

  return (
    <Chip
      label={active ? "DISPONIBLE" : "NO DISPONIBLE"}
      classes={{ root: classes.chip }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: ({ active }: StatusChipStyleProps) =>
      active ? theme.palette.success.light : theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));
